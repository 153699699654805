import React, { useEffect, useState } from 'react'
import { Button, Card } from 'antd'
import styled from 'styled-components'
import _findLast from 'lodash/findLast'
import _sortBy from 'lodash/sortBy'
import I18n from 'i18n-js'

import { PolicyContent } from './LearnerPolicy'
import { renderContent } from '../../components/LearnerCourse/common'
import { ListHeader, ListHeaderPanel } from '../../components/common'
import PrintPolicyDocumentButton from '../../components/Policies/PrintPolicyDocumentButton'
import ViewPolicyVersionField from '../../components/Policies/ViewPolicyVersionField'
import PolicyPDFViewer from '../../components/Policies/PolicyPDFViewer'
import { formatDateTime } from '../../helpers/datetime'

const trOpt = { scope: 'uPolicy.viewPolicyDocuments' }

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const DocumentCard = styled(Card)`
  flex: 1;
  overflow-y: auto;

  .ant-card-body {
    height: 100%;
    overflow: auto;
  }
`

const PolicyPDFCard = styled(DocumentCard)`
  border: none;

  .ant-card-body {
    padding: 0;
  }
`

const PublishedAtField = styled.div`
  align-items: center;
  display: flex;
  height: 32px;

  label {
    margin-right: 10px;
  }
`

const ViewPolicyDocuments = ({ policy, version = 'live', setVersion = () => {} }) => {
  const [documentsById, setDocumentsById] = useState({})
  const [publishedAtById, setPublishedAtById] = useState({})

  useEffect(() => {
    let documentsById = {}
    let publishedAtById = {}

    if (policy && policy.template) {
      const { type, document, file, draftType, draftDocument, draftFile } = policy
      documentsById = {
        live: { type, document, file },
        draft: { type: draftType, document: draftDocument, file: draftFile }
      }
    } else if (policy) {
      const documents = _sortBy((policy.documents || []), ['majorVersion', 'minorVersion'])
      const lastMajor = _findLast(documents, doc => doc.major)
      const dataById = documents.reduce((acc, policyDocument) => {
        const { id, type, document, file, status, publishedAt } = policyDocument
        const isLastMajor = lastMajor && lastMajor.id === id
        let value = status
        if (isLastMajor && status !== 'live') {
          value = 'major'
        } else if (status === 'archived') {
          value = id
        }
        acc.documentsById[value] = { type, document, file }
        if (publishedAt) {
          acc.publishedAtById[value] = formatDateTime(publishedAt)
        }
        return acc
      }, { documentsById, publishedAtById })
      documentsById = dataById.documentsById
      publishedAtById = dataById.publishedAtById
    }

    setDocumentsById(documentsById)
    setPublishedAtById(publishedAtById)
  }, [policy])

  const { type, document, file } = documentsById[version] || {}

  const publishedAt = publishedAtById[version]

  return (
    <ViewContainer>
      <ListHeader>
        <ListHeaderPanel>
          <ViewPolicyVersionField {...{ policy, version, setVersion }} />
          {
            publishedAt &&
              <PublishedAtField>
                <label>{I18n.t('published', trOpt)}:</label>
                <span>{publishedAt}</span>
              </PublishedAtField>
          }
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          {type === 'editor' && <PrintPolicyDocumentButton policy={policy} documentId={version} />}
          {type === 'pdf' && file && (
            <Button type='primary' icon='folder-open' href={file} target='_blank'>{I18n.t('openDocument', trOpt)}</Button>
          )}
        </ListHeaderPanel>
      </ListHeader>
      {type === 'editor' && (
        <DocumentCard>
          {
            document
              ? <PolicyContent id={`${policy.id}-${version}`} dangerouslySetInnerHTML={{ __html: renderContent(JSON.stringify(document)) }} />
              : <h4>{I18n.t('noDocument', trOpt)}</h4>
          }
        </DocumentCard>
      )}
      {type === 'pdf' && (
        <PolicyPDFCard>
          <PolicyPDFViewer file={file} height='100%' hidePrint />
        </PolicyPDFCard>
      )}
    </ViewContainer>
  )
}

export default ViewPolicyDocuments
