import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _get from 'lodash/get'

import { ContentBox, CardTitle } from './common'
import LearnerCourseSlate from './LearnerCourseSlate'
import { ProgressButton } from '../common'
import { withTimer } from '../../hocs'
import { BREAKPOINT } from '../../constants/style'
import LearnerCourseCard from './LearnerCourseCard'
import { LearnerCourseBase } from './LearnerCourseBase'

const FlipCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column;
  }
`

const StyledFlipCard = styled(LearnerCourseCard)`
  box-sizing: border-box;
  padding: 0 2.5px 5px;
  width: calc(100% / 4);

  @media (max-width: ${BREAKPOINT}) {
    padding: 0 0 10px;
    width: 100%;
  }
`

export class LearnerCourseCards extends LearnerCourseBase {
  constructor (props) {
    super(props)

    this.completedTimer = null
    this.canProgress = this.canProgress.bind(this)
  }

  canProgress () {
    return !this.props.timerActive
  }

  get cards () {
    return _get(this.props, 'slide.content.cards', []).filter(card => card.title && card.background)
  }

  componentDidMount () {
    this.startCompletionTimer()
  }

  componentDidUpdate (prevProps) {
    const { slide: { id } = {}, resetTimer } = this.props
    const { slide: { id: prevId } = {} } = prevProps

    if (id !== prevId) {
      this.startCompletionTimer()
    }

    // Reset timer
    if (id && prevId && id !== prevId) {
      resetTimer()
    }
  }

  componentWillUnmount () {
    this.props.resetTimer()
    clearTimeout(this.completedTimer)
  }

  render () {
    const { slide, canGoPrevSlide, goToNextSlide, goToPrevSlide, waitMS, timerActive } = this.props
    const { id, title, content } = slide
    const { intro } = content || {}

    return (
      <ContentBox
        material innerKey={id}
        buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
        buttonsRight={<ProgressButton {...{ timerActive, waitMS }} onClick={goToNextSlide} />}
      >
        <CardTitle>{title}</CardTitle>
        <LearnerCourseSlate content={intro} />
        <FlipCardContainer>
          {this.cards.map((card, index) => (
            <StyledFlipCard key={`card-${index}`} {...card} />
          ))}
        </FlipCardContainer>
      </ContentBox>
    )
  }
}

LearnerCourseCards.propTypes = {
  slide: PropTypes.object,
  goToNextSlide: PropTypes.func,
  resetTimer: PropTypes.func,
  timerActive: PropTypes.bool,
  update: PropTypes.func,
  waitMS: PropTypes.number
}

LearnerCourseCards.defaultProps = {
  slide: {},
  goToNextSlide: () => {},
  resetTimer: () => {},
  timerActive: false,
  update: () => {},
  waitMS: -1
}

export default withTimer(LearnerCourseCards)
