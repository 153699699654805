import I18n from 'i18n-js'
import isoLanguages from '@cospired/i18n-iso-languages'
import isoCountries from 'i18n-iso-countries'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _uniq from 'lodash/uniq'

import { BCP_47_REGEX, DEFAULT_LANGUAGE, TRANSLATED_LANGUAGE_CODES } from '../constants/languages'
import en from './en'
import es from './es'
import fr from './fr'
import cs from './cs'
import zh from './zh'
import de from './de'
import nl from './nl'
import it from './it'
import enUS from './en_US'
import pt from './pt'
import sv from './sv'
import no from './no'

// Import moment localisations
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/fr-ca'
import 'moment/locale/cs'
import 'moment/locale/zh-cn'
import 'moment/locale/de'
import 'moment/locale/nl'
import 'moment/locale/it'
import 'moment/locale/en-au'
import 'moment/locale/da'
import 'moment/locale/fi'
import 'moment/locale/pt'
import 'moment/locale/sv'
import 'moment/locale/ro'
import 'moment/locale/pl'
import 'moment/locale/fr-ch'
import 'moment/locale/de-ch'
import 'moment/locale/en-nz'
import 'moment/locale/en-ie'

import { setProductNames } from '../helpers/locale'

// Set default moment locale
moment.locale('en-gb')

I18n.defaultLocale = DEFAULT_LANGUAGE
I18n.locale = DEFAULT_LANGUAGE
I18n.fallbacks = true

I18n.translations = {
  en,
  es,
  fr,
  cs,
  zh,
  de,
  nl,
  it,
  'en-US': enUS,
  pt,
  sv,
  no
}

// Chinese Pluralisation - use "other" for any count value
I18n.pluralization.zh = function () {
  return ['other']
}

setProductNames() // Set default product names

// Show the key instead of the copy
I18n.locales.debugKeys = [] // Empty array means no fallback chain
// Override of I18n's missingTranslation method to provide additional functionality
I18n.missingTranslation = function (scope, options) {
  // guess intended string
  if (this.missingBehaviour === 'guess') {
    // get only the last portion of the scope
    var s = scope.split('.').slice(-1)[0]
    // replace underscore with space && camelcase with space and lowercase letter
    return (this.missingTranslationPrefix.length > 0 ? this.missingTranslationPrefix : '') +
        s.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g,
          function (match, p1, p2) { return p1 + ' ' + p2.toLowerCase() })
  }

  var fullScope = this.getFullScope(scope, options)
  var localeForTranslation = (options != null && options.locale != null) ? options.locale : this.currentLocale()
  // usecure - Return for key if using debugKeys locale
  if (localeForTranslation === 'debugKeys') {
    return `[${fullScope}]`
  }

  // usecure - Custom returnMissingAsNull option to return a null value if the translation is missing
  if (options?.returnMissingAsNull === true) {
    return null
  }

  var fullScopeWithLocale = [localeForTranslation, fullScope].join(options.separator || this.defaultSeparator)
  return '[missing "' + fullScopeWithLocale + '" translation]'
}

/**
 * Returns a translation from I18n with tokens for the default product names
 * options can used to added client specific product names
 * @param {String} scope I18n.t scope e.g. full or partial translation key id
 * @param {Object} options I18n.t options
 * @param {String} options.uLearnProductName Product name override for uLearn
 * @param {String} options.uPhishProductName Product name override for uPhish
 * @param {String} options.uBreachProductName Product name override for uBreach
 * @param {String} options.uBreachProProductName Product name override for uBreach Pro
 * @param {String} options.uPolicyProductName Product name override for uPolicy
 * @param {String} options.uServiceProductName Product name override for uService
 * @returns {String|Array|Object}
 */
I18n.tWithProductNames = function (scope, options) {
  const productNameOpt = { scope: 'common', locale: options?.locale }
  return this.t(scope, {
    ...options,
    uLearnProductName: this.t('uLearn', productNameOpt),
    uPhishProductName: this.t('uPhish', productNameOpt),
    uBreachProductName: this.t('uBreach', productNameOpt),
    uBreachProProductName: this.t('uBreachPro', productNameOpt),
    uPolicyProductName: this.t('uPolicy', productNameOpt),
    uServiceProductName: this.t('uService', productNameOpt)
  })
}

// I18n.tWithoutDefaultFallback - Leaves I18n.defaultLocale out of the fallback process unless locale matches DEFAULT_LANGUAGE or one of its regional variants
I18n.tWithoutDefaultFallback = function (scope, options) {
  const self = this
  // Call I18n.t as normal
  let translation = this.t(scope, options)
  if (_isObject(translation)) {
    // Translation is object, run keys through this function
    return Object.keys(translation).reduce((acc, key) => {
      acc[key] = self.tWithoutDefaultFallback(key, {
        ...options, scope: `${options?.scope ? `${options.scope}.` : ''}${scope}`
      })
      return acc
    }, {})
  } else if (_isArray(translation)) {
    // Translation is array, map array using this function
    return translation.map((trValue, index) => {
      return self.tWithoutDefaultFallback(index, {
        ...options, scope: `${options?.scope ? `${options.scope}.` : ''}${scope}`
      })
    })
  } else if (_isString(translation)) {
    // Return a missing translation value if the locale is not the default language or a regional variant of the default language
    const locale = options?.locale ?? this.locale
    if (locale && this.defaultLocale !== locale && !locale.startsWith(`${this.defaultLocale}-`)) {
      const defaultTranslation = this.t(scope, { ...options, locale: this.defaultLocale })
      if (defaultTranslation === translation) {
        translation = this.missingTranslation(scope, options)
      }
    }
  }

  return translation
}

// Load language and country name translations for App Languages
const LANGUAGES_SKIP_ISO_LANG_NAME = ['fil'] // This array contains supported locales that aren't covered by i18n-iso-languages
const LANGUAGES_SKIP_ISO_CTRY_NAME = ['fil'] // This array contains supported locales that aren't covered by i18n-iso-countries
_uniq(
  TRANSLATED_LANGUAGE_CODES.map(locale => {
    if (BCP_47_REGEX.test(locale)) {
      return locale.substring(0, 2)
    }
    return locale
  })
)
  .forEach(locale => {
    if (!LANGUAGES_SKIP_ISO_LANG_NAME.includes(locale)) {
      try {
        isoLanguages.registerLocale(require(`@cospired/i18n-iso-languages/langs/${locale}.json`))
      } catch (e) {
        console.error(`Error loading language name translation for ${locale}`, e)
      }
    }
    if (!LANGUAGES_SKIP_ISO_CTRY_NAME.includes(locale)) {
      try {
        isoCountries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`))
      } catch (e) {
        console.error(`Error loading country name translation for ${locale}`, e)
      }
    }
  })
