import React from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'

const LearnerActions = ({ learnerId, actions = [], performAction = () => {}, renderSingleActionAsButton = false }) => {
  if (renderSingleActionAsButton && actions.length === 1) {
    const action = actions[0]
    const handleButtonClick = () => performAction(action.key, learnerId)
    return (
      <Button disabled={Boolean(action.disabled)} onClick={handleButtonClick}>
        {action.label}
      </Button>
    )
  }

  const handleMenuClick = e => performAction(e.key, learnerId)
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item key={action.key} disabled={Boolean(action.disabled)}>{action.label}</Menu.Item>
        ))
      }

    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['hover']}>
      <Button>
        <Icon type='right' />
      </Button>
    </Dropdown>
  )
}

export default LearnerActions
