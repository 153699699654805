import React from 'react'
import I18n from 'i18n-js'

import { ErrorAlerts } from '../common'

const SyncSetupFailure = ({ error, errorMessage: defaultError, footer, trOpt = { scope: 'syncSetup.syncSetupFailure' } }) => (
  <div>
    <h3>{I18n.t('title', trOpt)}</h3>
    <p>
      {I18n.t('intro', trOpt)}
      <br />
      {I18n.t('closeTab', trOpt)}
    </p>
    <ErrorAlerts {...{ error, defaultError }} />
  </div>
)

export default SyncSetupFailure
