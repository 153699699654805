import React from 'react'
import { generatePath } from 'react-router-dom'
import EndUserProtectedRoute from './EndUserProtectedRoute'
import _get from 'lodash/get'
import routes from '../constants/routes'
const OutstandingActivitiesProtectedRoute = props => {
  return (
    <EndUserProtectedRoute {...props} redirect={generatePath(routes.OUTSTANDING_ACTIVITIES_LOGIN, { companyId: _get(props, 'computedMatch.params.companyId') })} />
  )
}
export default OutstandingActivitiesProtectedRoute
