/* global localStorage */
import React, { useEffect, useCallback, useState } from 'react'
import { generatePath, useParams, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { Button } from 'antd'
import _isNil from 'lodash/isNil'

import { withAppTitleAndIcons } from '../../hocs'
import { creators as sessionActions } from '../../state/actions/session'
import { creators as settingsActions } from '../../state/actions/settings'
import { creators as viewActions } from '../../state/actions/view'
import { END_USER_SIGN_IN } from '../../components/Queries/Learners'
import routes from '../../constants/routes'
import { getSessionAndSettingsFromLearnerMe } from '../../helpers/getLearnerMe'
import endUserClient from '../../apollo-client/endUserClient'
import { captureSentryError } from '../../helpers/sentry'
import { ErrorAlerts, ShortLoader } from '../../components/common'
import { UsecureError, addDelay } from '../../helpers'
import { LoadingMessage } from '../../components/EndUserPortal/SignIn'
import { SignInHeader } from '../../components/SignIn'
import { useRouteCompany, useGlobalState } from '../../hooks'

const CentreContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`
const trOpt = { scope: 'endUserPortal.magicLink' }
const MagicLinkRedirect = () => {
  const { loading: queryLoader, error: companyError, company, settings } = useRouteCompany()
  const [endUserSignIn] = useMutation(END_USER_SIGN_IN)
  const [status, setStatus] = useState('init')
  const [error, setError] = useState(null)
  const history = useHistory()
  const routeParams = useParams()
  const { updateSettings, endUserSessionUpdate, setLoadingVisible, setTheme } = useGlobalState(
    undefined,
    useCallback(dispatch => ({
      endUserSessionUpdate: session => {
        dispatch(sessionActions.endUserUpdate(session))
        dispatch(viewActions.loading(false))
      },
      updateSettings: settings => dispatch(settingsActions.update(settings)),
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    }), [])
  )

  const callEndUserSignIn = useCallback(async (shortLifeToken, companyId) => {
    setStatus('waiting')
    addDelay({
      delay: 1500,
      action: async () => {
        const res = await endUserSignIn({ variables: { shortLifeToken } })
        const { token } = res?.data?.endUserSignIn || {}
        if (!token) throw new Error('No session token returned')

        setStatus('success')
        // Set token
        localStorage.setItem('endUserToken', token)
        // get session and settings
        const { session, settings } = await getSessionAndSettingsFromLearnerMe(endUserClient)
        return { session, settings }
      },
      complete: ({ session, settings }) => {
        // Set session
        endUserSessionUpdate(session)
        updateSettings(settings)
        history.push(generatePath(routes.PORTAL_HOME, { companyId }))
      },
      failure (e) {
        captureSentryError(e, { msg: 'End User Portal - Magic Link Auth - ERROR' })
        setStatus('fail')
      }
    })
  }, [endUserSessionUpdate, endUserSignIn, updateSettings, history])

  useEffect(() => {
    if (companyError) {
      setError(new UsecureError(I18n.t('common.noCompanyWithTheSuppliedIdExists')))
      setStatus('fail')
    }
  }, [companyError])

  useEffect(() => {
    if (status !== 'init') return

    const { token: shortLifeToken, companyId } = routeParams ?? {}
    if (!shortLifeToken || !companyId) {
      // This means the URL is malformed which shouldn't happen with correct routing in place
      setStatus('fail')
    } else if (settings && !_isNil(company)) {
      setTheme({
        ...(settings || {}),
        parentDefaultSettings: company.parentCompany?.settings?.defaultTenantSettings
      })
      updateSettings({ ...settings, ready: true })
      setLoadingVisible(false)
      setStatus('ready')
    }
  }, [status, routeParams, settings, company, updateSettings, setTheme, setLoadingVisible])

  useEffect(() => {
    if (status !== 'ready') return

    const { token: shortLifeToken, companyId } = routeParams ?? {}
    callEndUserSignIn(shortLifeToken, companyId)
  }, [status, routeParams, callEndUserSignIn])

  useEffect(() => {
    setLoadingVisible(queryLoader)
  }, [queryLoader, setLoadingVisible])

  const onLoginRetryClick = useCallback(() => {
    history.push(generatePath(routes.PORTAL_HOME, { companyId: routeParams?.companyId }))
  }, [history, routeParams])

  if (status === 'init') return null

  return (
    <CentreContainer>
      <SignInHeader bottomMargin='2em' allowThemeLogo={!_isNil(settings)}>
        {I18n.t('common.portalLogin')}
      </SignInHeader>
      {status !== 'fail' && (
        <>
          <LoadingMessage>{I18n.t('loadingMsg', trOpt)}</LoadingMessage>
          <ShortLoader />
        </>
      )}
      {status === 'fail' && (
        <>
          <ErrorAlerts error={error} defaultError={I18n.t('magicLinkErrorMsg', trOpt)} forceDefaultError />
          {routeParams?.companyId && <Button onClick={onLoginRetryClick} icon='redo'>{I18n.t('endUserPortal.signIn.loginRetryButton')}</Button>}
        </>
      )}
    </CentreContainer>
  )
}

export default withAppTitleAndIcons({ isSignInPage: true })(MagicLinkRedirect)
