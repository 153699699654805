import React from 'react'
import I18n from 'i18n-js'

import { MicrosoftSyncActions } from '../MicrosoftSync'
import { SettingsFormElement } from './SettingsForm'
import { permissions } from '../../constants/permissions'
import { useHasSessionPermission } from '../../hooks'
import { getRequiredUpdatePermissions } from '../../helpers/settings'

const trOpt = { scope: 'settings.office365' }

const Office365 = React.forwardRef(({ disableSubmit, defaultTenant }, ref) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const requiredUpdatePermissions = getRequiredUpdatePermissions({
    defaultTenant,
    requiredPermissions: [permissions.SETTINGS_SYNC_UPDATE]
  })
  const isUpdateAllowed = hasAllSessionPermissions(requiredUpdatePermissions)

  return (
    <SettingsFormElement
      headerId='settings-m365-sync-header'
      title={I18n.t('office365.common.office365Integration')}
      desc={<p>{I18n.t('description', trOpt)}</p>}
      form={<MicrosoftSyncActions {...{ isUpdateAllowed }} />}
      hasFooter={false}
      disableSubmit={disableSubmit || !isUpdateAllowed}
      isUpdateAllowed={isUpdateAllowed}
    />
  )
})

export default Office365
