import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Tag } from 'antd'
import styled, { withTheme } from 'styled-components'
import I18n from 'i18n-js'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'
import DeleteUserConfirm from '../Modals/DeleteUserConfirm'
import { ROLE_LABELS } from '../../constants/users'
import useGlobalState from '../../hooks/useGlobalState'

const trOpt = { scope: 'users.usersTable' }

const ButtonActions = styled.div`
  text-align: right;
  &> * {
    margin-left: 10px;
    &:first-child {
      margin-left: 0px;
    }
  }
`

const YouTag = withTheme(({ theme }) => (
  <Tag color={theme.primary}>{I18n.t('you', trOpt)}</Tag>
))

const UserActions = ({ user, onEdit: onEditProp = () => {}, refetchUsers = () => {} }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const onEdit = useCallback(() => onEditProp(user.key), [user, onEditProp])
  const onDelete = useCallback(() => DeleteUserConfirm(user.key, refetchUsers), [user, refetchUsers])

  return (
    <ButtonActions>
      {hasAllSessionPermissions([permissions.USER_UPDATE]) &&
        <Button onClick={onEdit} icon='edit'>{I18n.t('common.edit')}</Button>}
      {hasAllSessionPermissions([permissions.USER_DELETE]) &&
        <Button disabled={user.currentUser} onClick={onDelete} type='danger' icon='user-delete'>{I18n.t('common.delete')}</Button>}
    </ButtonActions>
  )
}

const UsersTable = ({ columns = [], users = [], partner = false, refetchUsers = () => {}, showActions = true, onEdit = () => {} }) => {
  const tableColumns = [...columns]
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const { uLearnEnabled, uPhishEnabled, uBreachEnabled, uPolicyEnabled } = useGlobalState(useCallback((state) => ({
    uLearnEnabled: state?.settings?.uLearn,
    uPhishEnabled: state?.settings?.uPhish,
    uBreachEnabled: state?.settings?.uBreachEnabled,
    uPolicyEnabled: state?.settings?.uPolicy
  }), []))
  tableColumns.push({
    title: I18n.t('users.common.rolesColumn'),
    dataIndex: 'roles',
    key: 'roles',
    render: roles => (roles ?? []).filter((role) => {
      if (role.includes('ulearn')) {
        return uLearnEnabled
      }
      if (role.includes('uphish')) {
        return uPhishEnabled
      }
      if (role.includes('ubreach')) {
        return uBreachEnabled
      }
      if (role.includes('upolicy')) {
        return uPolicyEnabled
      }
      return true
    }).map(role => ROLE_LABELS[role] ?? role).join(', ')
  })

  if (partner || hasAllSessionPermissions([permissions.USER_SUPER_LIST])) {
    tableColumns.push({
      title: I18n.t('commsType', trOpt),
      dataIndex: 'commsType',
      key: 'commsType'
    })
  }
  tableColumns.push({
    title: I18n.t('2FAEnabled', trOpt),
    key: 'is2FAEnabled',
    render: ({ is2FAEnabled }) => is2FAEnabled ? I18n.t('common.yes') : I18n.t('common.no')
  })
  if (showActions) {
    tableColumns.push({
      title: '',
      dataIndex: 'actions',
      align: 'right',
      render: (index, user) => <UserActions {...{ user, onEdit, refetchUsers }} />
    })
  }

  return (
    <Table dataSource={users} columns={tableColumns} />
  )
}

UsersTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  showActions: PropTypes.bool,
  refetchUsers: PropTypes.func
}

UsersTable.defaultProps = {
  columns: [{
    get title () { return I18n.t('common.fields.name') },
    dataIndex: 'name',
    key: 'name',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => {
      return a.name.localeCompare(b.name)
    },
    render: (text, row) => { return row.currentUser ? <span key={row.id}>{row.name} <YouTag /></span> : row.name }
  }, {
    get title () { return I18n.t('common.fields.email') },
    dataIndex: 'email',
    key: 'email'
  }],
  users: [],
  showActions: true,
  refetchUsers: () => {}
}

export default UsersTable
