import React, { useCallback } from 'react'
import { Alert, Button } from 'antd'
import uuid from 'uuid/v4'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import I18n from 'i18n-js'

import SettingsForm from '../SettingsForm'
import routes from '../../../constants/routes'
import { DescriptionContainer, ActionContainer, CopyToClipboard } from '../common'
import { connect } from '../../../hocs'
import { getAppOrigin } from '../../../state/selectors/settings'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.policyExternalLink' }

const ChangeAlert = styled(Alert)`
  margin-bottom: 10px;
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: -4px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }

  .ant-btn {
    border-color: #faad14;
    color: #faad14;
    margin-left: 10px;
  }
`

export const PolicyPublicKeyField = connect(state => ({
  appOrigin: getAppOrigin(state)
}))(
  React.forwardRef(({
    id, value, defaultValue, onChange = () => {}, changed = false, appOrigin, disabled
  }, ref) => {
    const link = value ? `${appOrigin}${generatePath(routes.EXTERNAL_POLICIES, { key: value })}` : null

    const updateKey = useCallback(key => {
      onChange(id, key)
    }, [onChange, id])
    const generateKey = useCallback(() => updateKey(uuid()), [updateKey])
    const removeKey = useCallback(() => updateKey(null), [updateKey])
    const resetKey = useCallback(() => updateKey(defaultValue), [updateKey, defaultValue])

    const hasChanged = value !== defaultValue && changed

    return (
      <div>
        {
          hasChanged &&
            <ChangeAlert
              showIcon type='warning'
              message={
                <>
                  <span>{I18n.t('changedMessage', trOpt)}</span>
                  <Button icon='undo' ghost onClick={resetKey}>{I18n.t('resetChanges', trOpt)}</Button>
                </>
              }
            />
        }
        {
          link
            ? (
              <div>
                <DescriptionContainer>
                  <p>{I18n.t('hasLinkDescription1', trOpt)}</p>
                  <a href={link} target='_blank' rel='noopener noreferrer'>{link}</a>
                  <p>{I18n.t('hasLinkDescription2', trOpt)}</p>
                </DescriptionContainer>
                <ActionContainer>
                  <CopyToClipboard value={link} />
                  <Button icon='reload' ghost type='primary' onClick={generateKey} {...{ disabled }}>{I18n.t('regenerateLink', trOpt)}</Button>
                  <Button icon='delete' ghost type='danger' onClick={removeKey} {...{ disabled }}>{I18n.t('removeLink', trOpt)}</Button>
                </ActionContainer>
              </div>
            ) : (
              <div>
                <DescriptionContainer>
                  <p>{I18n.t('noLinkDescription', trOpt)}</p>
                </DescriptionContainer>
                <ActionContainer>
                  <Button icon='link' type='primary' onClick={generateKey} {...{ disabled }}>{I18n.t('generateLink', trOpt)}</Button>
                </ActionContainer>
              </div>
            )
        }
      </div>
    )
  })
)

class PolicyExternalLink extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'uPolicyPublicPageKey'
    this.defaultValue = null
    this.useValuesObjectName = false
    this.requiredUpdatePermissions = [permissions.SETTINGS_UPOLICY_UPDATE]
  }

  get headerId () {
    return 'settings-external-policy-link-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const { settings = {} } = this.props
    const defaultKey = this.defaultValue
    const {
      uPolicyPublicPageKey = defaultKey
    } = settings
    const { disabled } = this

    return [
      {
        id: 'setting',
        type: 'custom',
        component: PolicyPublicKeyField,
        defaultValue: uPolicyPublicPageKey,
        companyId: this.props.companyId,
        disabled
      }
    ]
  }
}

export default PolicyExternalLink
