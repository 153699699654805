import React from 'react'
import { withLogout } from '../hocs'
import { Modal } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import routes from '../constants/routes'
import { generatePath } from 'react-router-dom'
import { useHasSessionPermission } from '../hooks'
import { permissions } from '../constants/permissions'

const EndUserSignOut = ({ className, logOut, session }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const trOpt = { scope: 'common.logout' }
  const onClickLogout = () => {
    const isLimitedEndUser = hasAllSessionPermissions([permissions.END_USER_ACCESS_LIMITED])
    const priorityRoute = isLimitedEndUser ? generatePath(routes.OUTSTANDING_ACTIVITIES_LOGIN, { companyId: _get(session, 'companyId') }) : null

    Modal.confirm({
      title: I18n.t('confirmation', trOpt),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk () {
        logOut(priorityRoute)
      }
    })
  }
  return (
    <div {...{ className }} onClick={() => onClickLogout()}>
      {I18n.t('label', trOpt)}
    </div>
  )
}
export default withLogout({ redirectRoute: routes.PORTAL_HOME, sessionType: 'endUser' })(EndUserSignOut)
