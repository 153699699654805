import { useEffect } from 'react'
import _pick from 'lodash/pick'

import useGlobalState from './useGlobalState'
import selectors from '../state/selectors'
import { clearSentryUser, setSentryUserFromAdminUser, setSentryUserFromLearner } from '../helpers/sentry'

const useUpdateSentryUser = () => {
  const { userId, learnerId } = useGlobalState(
    state => _pick(selectors.session.get(state), ['userId', 'learnerId'])
  )

  useEffect(() => {
    if (userId) {
      setSentryUserFromAdminUser(userId)
    } else if (learnerId) {
      setSentryUserFromLearner(learnerId)
    } else {
      clearSentryUser()
    }
  }, [userId, learnerId])
}

export default useUpdateSentryUser
