import React, { useMemo } from 'react'
import { Tabs } from 'antd'
import _uniq from 'lodash/uniq'
import styled from 'styled-components'
import I18n from 'i18n-js'

import CourseTable from './CourseTable'
import { COURSE_SUBJECTS, COURSE_SUBJECT_IDS } from '../../constants/courses'

const { TabPane } = Tabs
const trOpt = { scope: 'learner.courseTabs' }

const NoCourses = styled.p`
  text-align: center;
`

const CourseTabs = ({ data = [], downloadCertificate, openUnenrolGapAnalysis }) => {
  const subjects = useMemo(() => {
    return _uniq(data.map(({ subject }) => subject))
      .sort((a, z) => COURSE_SUBJECT_IDS.indexOf(a) - COURSE_SUBJECT_IDS.indexOf(z))
      .map(subject => ({
        name: COURSE_SUBJECTS[subject] || subject,
        data: data.filter(result => result.subject === subject)
      }))
  }, [data])

  if (subjects.length === 0) {
    return (<NoCourses>{I18n.t('thisUserHasNotBeenEnrolledOnAnyCourses', trOpt)}</NoCourses>)
  }

  return (
    <Tabs defaultActiveKey='1' animated={false}>
      {
        subjects.map(({ name, data }, index) => (
          <TabPane tab={name} key={`${index + 1}`}>
            <CourseTable {...{ data, downloadCertificate, openUnenrolGapAnalysis }} />
          </TabPane>
        ))
      }
    </Tabs>
  )
}

export default CourseTabs
