import React, { Component } from 'react'
import { Button, Card, Icon, Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isBoolean from 'lodash/isBoolean'
import _isNil from 'lodash/isNil'
import _keyBy from 'lodash/keyBy'
import _pick from 'lodash/pick'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { LoadingBlock } from '../../components/common'
import { SyncSetupComplete, SyncSetupFailure, SyncSetupQuestion, SyncSetupUserEmailBlacklist, SyncSetupIntro, SyncSetupGroups } from './index'
import { GET_GROUP_TREE } from '../../components/Queries/Groups'
import { withConsumer, withRefreshSessionState } from '../../hocs'
import { showErrors } from '../../helpers'

const ContentWrap = styled.div`
  height: 100%;
  padding: 1rem 50px 5.809rem;
`

const ContentCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StepContainer = styled.div`
  flex-grow: 1;
  overflow: ${({ freezeScrolling }) => freezeScrolling ? 'hidden' : 'auto'};
`

const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;

  .ant-btn {
    margin-right: 5px;
  }
`

const SELECT_ALL_TO_GROUP = {
  selectAllGroups: 'inGroups',
  selectAllGroupMembWhitelist: 'inGroupMemb'
}
const GROUP_TO_SELECT_ALL = {
  inGroups: 'selectAllGroups',
  inGroupMemb: 'selectAllGroupMembWhitelist'
}

class SyncSetup extends Component {
  constructor (props) {
    super(props)

    const {
      settings: {
        configured = false,
        autoSync = false, includeGroups = false,
        groupType = 'all',
        selectAllGroups = true,
        includeGroupMembWhitelist = false,
        selectAllGroupMembWhitelist = true,
        includeGroupMembMapping = false,
        includeUserEmailBlacklist = false,
        userEmailBlacklist = [],
        excludeUsersWithoutAssignedLicenses = false,
        emailMethod: currentEmailMethod,
        excludeUsersWithoutMailboxSetup = true,
        excludeSuspendedUsers = true,
        excludeArchivedUsers = true,
        syncManagers = false
      } = {},
      trOptScopeKey = 'syncSetup'
    } = props

    this.trOpt = { scope: trOptScopeKey }

    let emailMethod = currentEmailMethod
    if (configured && !emailMethod) {
      emailMethod = 'original'
    } else if (!emailMethod) {
      emailMethod = 'userPrincipalNameOnly'
    }

    this.state = {
      loading: true,
      error: null,
      errorMessage: null,
      step: 0,
      autoSync,
      includeGroups,
      selectAllGroups,
      includeGroupMembWhitelist,
      selectAllGroupMembWhitelist,
      includeGroupMembMapping,
      groupType,
      viewedGroups: false,
      groups: [],
      usecureGroups: [],
      includeUserEmailBlacklist,
      userEmailBlacklist,
      excludeUsersWithoutAssignedLicenses,
      emailMethod,
      excludeUsersWithoutMailboxSetup,
      excludeSuspendedUsers,
      excludeArchivedUsers,
      syncManagers
    }

    this.goToComplete = this.goToComplete.bind(this)
    this.handleGroupsClick = this.handleGroupsClick.bind(this)
    this.handleRestartClick = this.handleRestartClick.bind(this)
    this.handleUsersClick = this.handleUsersClick.bind(this)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handleCompleteClick = this.handleCompleteClick.bind(this)
    this.handleGroupChange = this.handleGroupChange.bind(this)
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this)
    this.handleQuestionChange = this.handleQuestionChange.bind(this)
    this.handleSelectAllChange = this.handleSelectAllChange.bind(this)
    this.handleSkipToCompleteClick = this.handleSkipToCompleteClick.bind(this)
    this.handleUserEmailBlacklistChange = this.handleUserEmailBlacklistChange.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
  }

  get step () {
    const { steps = [] } = this.props
    return steps[this.state.step]
  }

  get prevStep () {
    const { steps = [] } = this.props
    return steps[this.state.step - 1]
  }

  get nextStep () {
    const { steps = [] } = this.props
    return steps[this.state.step + 1]
  }

  get hasUsecureGroups () {
    const { usecureGroups } = this.state
    return _isArray(usecureGroups) && usecureGroups.length > 0
  }

  updateState (stateUpdate) {
    return new Promise(resolve => this.setState(stateUpdate, resolve))
  }

  async loadUsecureGroups () {
    try {
      const { data: { groupTree: usecureGroups = [] } = {} } = await this.props.client.query({ query: GET_GROUP_TREE })
      this.setState({ usecureGroups })
    } catch (e) {
      console.error('SyncSetup.loadUsecureGroups - Error', e)
      this.handleError(e, I18n.t('common.groupsLoadError'))
    }
  }

  async setupGroupConfig () {
    let { includeGroupMembWhitelist, includeGroupMembMapping } = this.state
    if (!includeGroupMembWhitelist) {
      includeGroupMembMapping = false
    }
    await this.updateState({ loading: true, includeGroupMembMapping })
    await this.loadSyncGroups()
    this.setState({ loading: false })
  }

  async loadSyncGroupsUsingSingleQuery (variables) {
    const { data: { [this.props.groupsQueryKey]: groups = [] } = {} } = await this.props.client.query({ query: this.props.groupsQuery, variables })
    return groups
  }

  async loadSyncGroupUsingPagination (variables) {
    const { data: { [this.props.groupsQueryKey]: response } = {} } = await this.props.client.query({ query: this.props.groupsQuery, variables })
    const { groups = [], nextPageUrl, accessToken } = response || {}

    if (nextPageUrl) {
      const pageGroups = await this.loadSyncGroupUsingPagination({ ...variables, pageUrl: nextPageUrl, accessToken })
      return [...groups, ...pageGroups]
    }
    return groups
  }

  async loadSyncGroups (type = this.state.groupType) {
    try {
      let rawGroups = []
      if (this.props.usePaginatedGroupLoad) {
        rawGroups = await this.loadSyncGroupUsingPagination({ type })
      } else {
        rawGroups = await this.loadSyncGroupsUsingSingleQuery({ type })
      }

      const { includeGroups, includeGroupMembWhitelist, includeGroupMembMapping, groups: stateGroups = [] } = this.state
      const {
        settings: {
          selectAllGroups, groupWhitelist = [], selectAllGroupMembWhitelist, groupMembWhitelist = [],
          includeGroups: prevIncludeGroups, includeGroupMembWhitelist: prevIncludeGroupMembWhitelist
        } = {}
      } = this.props

      const updIncludeGroups = _isBoolean(prevIncludeGroups) && !prevIncludeGroups && includeGroups
      const updIncludeGroupMembWhitelist = _isBoolean(prevIncludeGroupMembWhitelist) && !prevIncludeGroupMembWhitelist && includeGroupMembWhitelist

      const groupMembMap = this.getGroupMembershipMap()
      const hasGroupConfig = _isBoolean(selectAllGroups)
      const hasGroupMembConfig = _isBoolean(selectAllGroupMembWhitelist)
      const stateGroupsMap = _keyBy(stateGroups, 'id')
      const groups = rawGroups.map(syncGroup => {
        const group = {
          ...syncGroup,
          inGroups: includeGroups && (!hasGroupConfig || updIncludeGroups || selectAllGroups === true || groupWhitelist.includes(syncGroup.id)),
          inGroupMemb: includeGroupMembWhitelist && (!hasGroupMembConfig || updIncludeGroupMembWhitelist || selectAllGroupMembWhitelist === true || groupMembWhitelist.includes(syncGroup.id)),
          groupMembMap: (includeGroupMembWhitelist && includeGroupMembMapping && _isArray(groupMembMap[syncGroup.id])) ? groupMembMap[syncGroup.id] : []
        }
        const stateGroup = stateGroupsMap[group.id]
        if (stateGroup) {
          group.inGroups = stateGroup.inGroups
          group.inGroupMemb = stateGroup.inGroupMemb
          group.groupMembMap = stateGroup.groupMembMap
        }

        return group
      })
      groups.sort((a, b) => a.name.localeCompare(b.name))

      this.setState({
        groups,
        selectAllGroups: groups.every(group => group.inGroups === true),
        selectAllGroupMembWhitelist: groups.every(group => group.inGroupMemb === true),
        viewedGroups: true
      })
    } catch (e) {
      console.error('SyncSetup.loadSyncGroups - Error', e)
      this.handleError(e, I18n.t('groupsLoadError', this.trOpt))
    }
  }

  getGroupMembershipMap () {
    if (!this.state.includeGroupMembMapping) {
      return {}
    }

    const { settings: { groupMembMap = {} } = {} } = this.props
    const groupIds = Object.keys(groupMembMap)
    const hasGroupMembMap = groupIds.length > 0 && groupIds.some(groupId => _isArray(groupMembMap[groupId]) && groupMembMap[groupId].length > 0)
    if (!hasGroupMembMap) {
      return {}
    }

    const usecureGroupIds = this.getUsecureGroupIds(this.state.usecureGroups, true)

    return groupIds.reduce((map, groupId) => {
      let groupMap = groupMembMap[groupId]
      if (_isArray(groupMap) && groupMap.length > 0) {
        groupMap = groupMap.filter(usecureGroupId => usecureGroupIds.includes(usecureGroupId))
        if (groupMap.length > 0) {
          map[groupId] = groupMap
        }
      }
      return map
    }, {})
  }

  getUsecureGroupIds (groups, excludeSyncGroups = false) {
    const groupIds = groups.reduce((groupIds, { id, name, syncRecordId, syncType, children = [] }) => {
      if (!excludeSyncGroups || _isNil(syncRecordId) || _isNil(syncType)) {
        groupIds.push(id)
      }
      return [
        ...groupIds,
        ...this.getUsecureGroupIds(children, excludeSyncGroups)
      ]
    }, [])

    return groupIds
  }

  handleNextClick () {
    this.goToStep(this.state.step + 1)
  }

  handleBackClick () {
    this.goToStep(this.state.step - 1)
  }

  goToStep (step) {
    const { steps = [] } = this.props
    const { step: currentStep, includeGroups, includeGroupMembWhitelist, includeGroupMembMapping, includeUserEmailBlacklist } = this.state
    const max = steps.length - 1
    if (step < 0) {
      step = 0
    } else if (step > max) {
      step = max
    }
    const diff = step - currentStep
    if (diff === 0) {
      return
    }

    // Change step based on selected values
    const { id, type } = steps[step] || {}
    const skip = (id === 'includeGroupMembMapping' && (!this.hasUsecureGroups || !includeGroupMembWhitelist)) ||
      (
        (type === 'groups' || id === 'groupType') &&
        !(includeGroups || includeGroupMembWhitelist ||
          (includeGroupMembWhitelist && includeGroupMembMapping)
        )
      ) ||
      (type === 'users' && !includeUserEmailBlacklist)

    if (skip) {
      if (diff < 0) {
        step -= 1
      } else if (diff > 0) {
        step += 1
      }
      return this.goToStep(step)
    }

    this.setState({ step })
  }

  handleRestartClick () {
    this.setState({ step: 0 })
  }

  handleGroupsClick () {
    this.goToStepByType('groups')
  }

  handleUsersClick () {
    this.goToStepByType('users')
  }

  goToComplete () {
    this.goToStepByType('complete')
  }

  goToStepByType (typeName) {
    const { steps = [] } = this.props
    const step = steps.findIndex(({ type }) => type === typeName)
    if (step) {
      this.setState({ step })
    }
  }

  goToFailure () {
    this.setState({ step: -1, loading: false })
  }

  handleError (e, errorMessage) {
    showErrors(e, errorMessage)
    this.setState({ error: e, errorMessage })
    this.goToFailure()
  }

  async handleStepChange () {
    const { type } = this.step || {}

    if (type === 'groups') {
      return this.setupGroupConfig()
    } else if (type === 'complete') {
      return this.saveSettings()
    }
  }

  async saveSettings () {
    try {
      const { syncType, updateSettingsMutation } = this.props
      const {
        autoSync, includeGroups, groupType,
        selectAllGroups,
        includeGroupMembWhitelist,
        selectAllGroupMembWhitelist,
        includeGroupMembMapping,
        groups = [],
        viewedGroups,
        includeUserEmailBlacklist,
        userEmailBlacklist = [],
        excludeUsersWithoutAssignedLicenses,
        emailMethod,
        excludeUsersWithoutMailboxSetup,
        excludeSuspendedUsers,
        excludeArchivedUsers,
        syncManagers
      } = this.state

      let settings = {
        configured: true,
        autoSync,
        groupType,
        includeGroups,
        selectAllGroups,
        includeGroupMembWhitelist,
        selectAllGroupMembWhitelist,
        includeGroupMembMapping: includeGroupMembWhitelist && includeGroupMembMapping,
        includeUserEmailBlacklist,
        userEmailBlacklist: includeUserEmailBlacklist ? userEmailBlacklist : [],
        syncManagers
      }

      let groupWhitelist = []
      let groupMembWhitelist = []
      let groupMembMap = {}

      if (viewedGroups && ((includeGroups && !selectAllGroups) || (includeGroupMembWhitelist && !selectAllGroupMembWhitelist) || includeGroupMembMapping)) {
        groups.forEach(group => {
          const { id, inGroups, inGroupMemb, groupMembMap: membMap = [] } = group
          if (includeGroups && !selectAllGroups && inGroups) {
            groupWhitelist.push(id)
          }
          if (includeGroupMembWhitelist && !selectAllGroupMembWhitelist && inGroupMemb) {
            groupMembWhitelist.push(id)
          }
          if (includeGroupMembMapping && (inGroups ? false : inGroupMemb) && membMap.length > 0) {
            groupMembMap[id] = membMap
          }
        })
      } else if (!viewedGroups) {
        if (includeGroups) {
          groupWhitelist = _get(this, 'props.settings.groupWhitelist', [])
        }
        if (includeGroupMembWhitelist) {
          groupMembWhitelist = _get(this, 'props.settings.groupMembWhitelist', [])
        }
        if (includeGroupMembMapping) {
          groupMembMap = _get(this, 'props.settings.groupMembMap', {})
        }
      }

      // Settings specific to the Microsoft 365 Sync
      // Corresponds to the MicrosoftSyncSettings input in the GraphQL Schema
      if (syncType === 'microsoft') {
        settings = {
          ...settings,
          excludeUsersWithoutAssignedLicenses,
          emailMethod
        }
      }

      // Settings specific to the Google Workspace Sync
      // Corresponds to the GoogleSyncSettings input in the GraphQL Schema
      if (syncType === 'google') {
        settings = {
          ...settings,
          excludeUsersWithoutMailboxSetup,
          excludeSuspendedUsers,
          excludeArchivedUsers
        }
      }

      settings = {
        ...settings,
        groupWhitelist,
        groupMembWhitelist,
        groupMembMap
      }

      await this.props.client.mutate({
        mutation: updateSettingsMutation,
        variables: { settings }
      })

      return this.updateSessionAndState()
    } catch (e) {
      console.error('SyncSetup.loadSyncGroups - Error', e)
      this.handleError(e, I18n.t('saveError', this.trOpt))
    }
  }

  // It would be nice if we had a HOC to do this
  async updateSessionAndState () {
    await this.props.refreshSessionState()
  }

  handleQuestionChange (questionId, value) {
    const { steps = [] } = this.props
    if (steps.some(step => step.type === 'question' && step.id === questionId)) {
      this.setState({ [questionId]: value })
    }
  }

  handleGroupChange (id, prop, value) {
    const { groups = [] } = this.state
    const groupIndex = groups.findIndex(group => group.id === id)
    if (groupIndex !== -1) {
      groups[groupIndex][prop] = value
      const stateUpdate = { groups: [...groups] }
      const selectAllProp = GROUP_TO_SELECT_ALL[prop]
      if (selectAllProp) {
        stateUpdate[selectAllProp] = groups.every(group => group[prop] === true)
      }
      this.setState(stateUpdate)
    }
  }

  handleUserEmailBlacklistChange (userEmailBlacklist = []) {
    this.setState({ userEmailBlacklist })
  }

  handleSelectAllChange (prop, value) {
    const groupProp = SELECT_ALL_TO_GROUP[prop]
    if (!groupProp) {
      return
    }

    let { groups = [] } = this.state
    groups = groups.map(group => ({ ...group, [groupProp]: value }))

    this.setState({ groups, [prop]: value })
  }

  handleMultiSelectChange (ids, prop, value) {
    const { groups = [] } = this.state
    const stateUpdate = {
      groups: groups.map(group => {
        return ids.includes(group.id) ? { ...group, [prop]: value } : group
      })
    }
    const selectAllProp = GROUP_TO_SELECT_ALL[prop]
    if (selectAllProp) {
      stateUpdate[selectAllProp] = stateUpdate.groups.every(group => group[prop] === true)
      console.log(`${selectAllProp}: ${stateUpdate[selectAllProp]}`)
    }

    this.setState(stateUpdate)
  }

  handleCompleteClick () {
    this.goToCompleteConfirm()
  }

  handleSkipToCompleteClick () {
    this.goToCompleteConfirm(I18n.t('skipPrompt', this.trOpt))
  }

  goToCompleteConfirm (title = I18n.t('savePrompt', this.trOpt)) {
    Modal.confirm({
      title,
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk: this.goToComplete
    })
  }

  renderStep () {
    const { syncType, trOptScopeKey } = this.props
    const step = this.step
    if (step) {
      switch (step.type) {
        case 'intro':
          return <SyncSetupIntro {...{ syncType }} trOpt={{ scope: `${trOptScopeKey}.setupIntro` }} />
        case 'question':
          return (
            <SyncSetupQuestion
              id={step.id}
              value={this.state[step.id]}
              onChange={this.handleQuestionChange}
              {...{
                ..._pick(this.state, ['autoSync', 'includeGroups', 'includeGroupMembWhitelist', 'includeGroupMembMapping', 'includeUserEmailBlacklist', 'emailMethod']),
                ..._pick(step, ['question', 'description', 'options', 'verticalOptions', 'sideBySide'])
              }}
            />
          )
        case 'groups':
          return (
            <SyncSetupGroups
              onChange={this.handleGroupChange}
              onSelectAllChange={this.handleSelectAllChange}
              onMultiSelectChange={this.handleMultiSelectChange}
              trOpt={{ scope: `${trOptScopeKey}.setupGroups` }}
              groupTypes={this.props.groupTypes}
              {..._pick(this.state, ['loading', 'groups', 'usecureGroups', 'includeGroups', 'selectAllGroups', 'includeGroupMembMapping', 'selectAllGroupMembWhitelist', 'includeGroupMembWhitelist'])}
            />
          )
        case 'users':
          return (
            <SyncSetupUserEmailBlacklist
              onChange={this.handleUserEmailBlacklistChange}
              list={this.state.userEmailBlacklist}
              trOpt={{ scope: `${trOptScopeKey}.setupUserEmailBlacklist` }}
            />
          )
        case 'complete':
          return (
            <SyncSetupComplete
              includeGroups={this.state.includeGroups}
              {...{ syncType }}
              trOpt={{ scope: `${trOptScopeKey}.setupComplete` }}
            />
          )
        default:
          break
      }
    }

    return <SyncSetupFailure {..._pick(this.state, ['error', 'errorMessage'])} trOpt={{ scope: `${trOptScopeKey}.setupFailure` }} />
  }

  renderFooter () {
    const { settings: { configured = false } = {} } = this.props
    const { type, id } = this.step || {}
    const { type: nextType } = this.nextStep || {}
    if (!type) {
      return null
    }

    const { includeGroups, includeGroupMembWhitelist, includeGroupMembMapping, includeUserEmailBlacklist } = this.state
    const intro = type === 'intro'
    const complete = type === 'complete'
    const showSkipToGroups = type === 'intro' && configured && (includeGroups || includeGroupMembWhitelist || (includeGroupMembWhitelist && includeGroupMembMapping))
    const showSkipToUsers = type === 'intro' && configured && includeUserEmailBlacklist
    const showComplete = nextType === 'complete' || (id === 'includeUserEmailBlacklist' && !includeUserEmailBlacklist)
    const showSkipToComplete = configured && !complete && !showComplete
    const enableSkipToComplete = configured && (type === 'groups' || id === 'autoSync' || id === 'emailMethod')
    const showContinue = !showComplete && !complete && !intro

    return (
      <ButtonContainer>
        {!intro && !complete && <Button icon='left-circle' onClick={this.handleBackClick}>{I18n.t('common.back')}</Button>}
        {showSkipToGroups && <Button icon='forward' onClick={this.handleGroupsClick}>{I18n.t('goToGroupConfiguration', this.trOpt)}</Button>}
        {showSkipToUsers && <Button icon='forward' onClick={this.handleUsersClick}>{I18n.t('goToUserEmailBlacklist', this.trOpt)}</Button>}
        {intro &&
          <Button icon='play-circle' onClick={this.handleNextClick} type='primary'>{I18n.t('common.start')}</Button>}
        {showContinue &&
          <Button onClick={this.handleNextClick} type='primary'>{I18n.t('common.next')}<Icon type='right-circle' /></Button>}
        {showComplete && <Button type='primary' icon='check-circle' onClick={this.handleCompleteClick}>{I18n.t('common.finish')}</Button>}
        {showSkipToComplete && <Button type='primary' ghost icon='check-circle' disabled={!enableSkipToComplete} onClick={this.handleSkipToCompleteClick}>{I18n.t('common.finish')}</Button>}
        {complete && <Button icon='fast-backward' onClick={this.handleRestartClick}>{I18n.t('goBackToTheStart', this.trOpt)}</Button>}
      </ButtonContainer>
    )
  }

  async handleAuthCode () {
    const { handleAuthCode, syncSetupRoute } = this.props
    try {
      await handleAuthCode()
      this.props.history.push(syncSetupRoute)
    } catch (e) {
      console.error('SyncSetup.handleAuthCode - Error', e)
      this.handleError(e, I18n.t('authError', this.trOpt))
    }
  }

  async componentDidMount () {
    await this.handleAuthCode()
    await this.loadUsecureGroups()
    this.setState({ loading: false })
  }

  async componentDidUpdate (prevProps, prevState) {
    const { step } = this.state
    const { step: prevStep } = prevState

    if (step !== prevStep) {
      this.handleStepChange()
    }
  }

  render () {
    const { loading } = this.state
    const { headerId } = this.props
    const { type } = this.step || {}

    return (
      <ContentWrap>
        <LoadingBlock loading={loading} />
        <ContentCard>
          <ContentContainer>
            <h1 id={headerId}>{I18n.t('setup', this.trOpt)}</h1>
            <StepContainer freezeScrolling={type === 'groups' || type === 'users'}>
              {this.renderStep()}
            </StepContainer>
            {this.renderFooter()}
          </ContentContainer>
        </ContentCard>
      </ContentWrap>
    )
  }
}

export default compose(
  withConsumer,
  withRouter,
  withRefreshSessionState
)(SyncSetup)
