import React, { useImperativeHandle, useState } from 'react'

import { DELETE_SCHEDULED_REPORTS } from '../Queries/Reports'
import DeleteConfirm from './DeleteConfirm'

const trOpt = { scope: 'modals.deleteScheduledReportConfirm' }

const DeleteScheduledReportConfirm = React.forwardRef(({
  refetchQueries,
  companyId
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [ids, setIds] = useState([])

  useImperativeHandle(ref, () => ({
    open: async (scheduledReports) => {
      setVisible(true)
      setIds(scheduledReports.map(({ id }) => id))
    }
  }))

  return (
    <DeleteConfirm
      {...{ visible, setVisible, refetchQueries, trOpt, ids }}
      mutation={DELETE_SCHEDULED_REPORTS}
      variables={{ ids, companyId }}
    />
  )
})

export default DeleteScheduledReportConfirm
