import React from 'react'
import styled from 'styled-components'
import { Alert } from 'antd'

const _BannerMessage = ({ className, type, message }) => (
  <Alert
    className={className}
    type={type} showIcon banner
    message={message}
  />
)

const BannerMessage = styled(_BannerMessage)`
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`

export default BannerMessage
