import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'

import { LoadingBlock } from '../components/common'
import { GET_LEARNER_THEME_DATA } from '../components/Queries/CourseResults'
import connect from './connect'
import { creators as settingsActions } from '../state/actions/settings'
import { creators as sessionActions } from '../state/actions/session'

const defaultGetLearnerId = props => props.learnerId
const defaultSkipQuery = learnerId => !learnerId

const LearnerThemeDispatcher = connect(
  undefined,
  dispatch => ({
    setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
    updateSession: session => dispatch(sessionActions.noAuthUpdate(session))
  })
)(
  ({ data, setTheme, updateSession }) => {
    useEffect(() => {
      if (!data) return
      updateSession({ mspName: _get(data, 'getLearnerThemeData.mspName') })
      setTheme(_get(data, 'getLearnerThemeData.settings') || {})
    }, [data, setTheme, updateSession])

    return null
  }
)

const withLearnerThemeDataQuery = ({ getLearnerId = defaultGetLearnerId, skipQuery = defaultSkipQuery } = {}) => Component => {
  const WithLearnerThemeDataQuery = props => {
    const { client, forwardRef, ...restOfProps } = props
    const learnerId = getLearnerId(props)
    const skip = skipQuery(learnerId, props)
    const { loading, data } = useQuery(GET_LEARNER_THEME_DATA, { variables: { id: learnerId }, client, skip })

    return (
      <>
        <LearnerThemeDispatcher {...{ data }} />
        {
          loading
            ? <LoadingBlock loading />
            : <Component {...restOfProps} client={client} ref={forwardRef} />
        }
      </>
    )
  }

  return React.forwardRef((props, ref) => (
    <WithLearnerThemeDataQuery {...props} forwardRef={ref} />
  ))
}

export default withLearnerThemeDataQuery
