import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Button, Form, Input, message, Upload } from 'antd'
import I18n from 'i18n-js'

import { apiUrl } from '../../apollo-client/common'
import EditCourseListItem from './EditCourseListItem'
import { getSessionToken } from '../../helpers/session'

const trOpt = { scope: 'editCourse.editCourseCard' }

const ImageWrap = styled.div`
  margin-right: 10px;
  width: ${({ width = '400px' }) => width};

  img {
    width: 100%;
  }
`

const ImagePlaceholder = styled.div`
  align-items: center;
  background-color: #f4f4f4;
  display: flex;
  height: ${({ height = '224px' }) => height};
  justify-content: center;
`

const CardImage = ({ background, title, width, height }) => (
  <ImageWrap {...{ width }}>
    {
      background
        ? (
          <img src={background} alt={title} />
        )
        : (
          <ImagePlaceholder height={height}>
            <span>{I18n.t('editCourse.common.noImage')}</span>
          </ImagePlaceholder>
        )
    }
  </ImageWrap>
)

const CardItem = ({ item }) => {
  const { title, background } = item

  return (
    <div style={{ display: 'flex' }}>
      <CardImage {...{ title, background }} width='150px' height='85px' />
      <span style={{ fontStyle: (title ? 'normal' : 'italic') }}>{title || I18n.t('editCourse.common.noTitleAvailable')}</span>
    </div>
  )
}

const CardForm = ({ item, onChange: onChangeProp }) => {
  const { title, background } = item
  const onChange = useCallback(event => {
    const { name, type, value, checked } = event.target
    onChangeProp(name, type === 'checkbox' ? checked : value)
  }, [onChangeProp])
  const onImageUpload = useCallback((info) => {
    if (info.file.status === 'done') {
      message.success(I18n.t('editCourse.common.fileUploadedSuccessfully', { name: info.file.name }))
      onChangeProp('background', info.file.response.url)
    } else if (info.file.status === 'error') {
      message.error(I18n.t('editCourse.common.fileUploadedFailed', { name: info.file.name }))
    }
  }, [onChangeProp])

  return (
    <>
      <Form.Item label={I18n.t('text', trOpt)}>
        <Input
          name='title'
          onChange={onChange}
          value={title}
        />
      </Form.Item>
      <Form.Item label={I18n.t('background', trOpt)}>
        <CardImage {...{ title, background }} />
        <Upload name='courseWysiwyg' onChange={onImageUpload} showUploadList={false} action={`${apiUrl}/upload-course-wysiwyg`} headers={{ 'x-token': getSessionToken() }}>
          <Button id='courseWysiwyg'>{I18n.t('editCourse.common.chooseImage')}</Button>
        </Upload>
      </Form.Item>
    </>
  )
}

const EditCourseCard = props => {
  return (
    <EditCourseListItem
      {...props}
      modalProps={{
        width: '90%',
        style: { maxWidth: 1100 }
      }}
      itemComponent={CardItem}
      formComponent={CardForm}
    />
  )
}

export default EditCourseCard
