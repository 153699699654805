export const getExpiredPlanHoldType = ({ planName }) => {
// enum based on planName
  const AVAILABLE_HOLD_TYPES = {
    premium: 'expiredPaidPlan',
    freeTrial: 'expiredFreeTrial',
    custom: 'expiredPaidPlan'
  }

  return AVAILABLE_HOLD_TYPES[planName] || 'accessDenied'
}
