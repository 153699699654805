import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import I18n from 'i18n-js'

import DragAndDropList from './DragAndDropList'
import EditCourseCard from './EditCourseCard'
import EditCourseSlate from './EditCourseSlate'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const trOpt = { scope: 'editCourse.editCourseCards' }
class EditCourseCards extends Component {
  constructor (props) {
    super(props)

    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.updateCards = this.updateCards.bind(this)
    this.updateIntro = this.updateIntro.bind(this)

    this.cardTemplate = {
      id: null,
      title: '',
      background: null
    }
  }

  handleTitleChange (event) {
    this.props.updateTitle(event.target.value)
  }

  updateIntro ({ intro }) {
    this.props.updateContent({ ...this.props.content, intro })
  }

  updateContent (update) {
    this.props.updateContent({ ...this.props.content, ...update })
  }

  updateCards (cards) {
    this.updateContent({ cards })
  }

  render () {
    const { title, content, locale, slideId, updateId } = this.props
    const { cards } = content || {}

    return (
      <div>
        <Form.Item label={I18n.t('editCourse.common.title')}>
          <Input
            onChange={this.handleTitleChange}
            value={title}
          />
        </Form.Item>

        <Form.Item label={I18n.t('editCourse.common.intro')}>
          <EditCourseSlate updateContent={this.updateIntro} {...{ locale, content, slideId, updateId }} />
        </Form.Item>

        <DragAndDropList
          list={cards || []}
          title={I18n.t('cards', trOpt)}
          itemName={I18n.t('card', trOpt)}
          itemComponent={EditCourseCard}
          itemTemplate={this.cardTemplate}
          updateList={this.updateCards}
        />
      </div>
    )
  }
}

EditCourseCards.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseCards.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseCards
