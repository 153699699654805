import _get from 'lodash/get'

import { CLIENT_ENV, APP_SUBDOMAIN } from '../../constants/environment'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const inProd = CLIENT_ENV === 'production'

export const get = state => state.settings
export const getSettings = get
export const getAppDomain = state => _get(get(state), ['appDomain', DEFAULT_LANGUAGE])
export const getAppOrigin = state => {
  const appDomain = inProd ? getAppDomain(state) : null
  return appDomain ? `https://${APP_SUBDOMAIN}.${appDomain}` : window.location.origin
}

export default {
  get,
  getSettings,
  getAppDomain,
  getAppOrigin
}
