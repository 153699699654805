import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Main from './Main'
import PreSignInSwitch from './SignIn/PreSignInSwitch'

import authenticatedClient from '../apollo-client/authenticatedClient'
import routes from '../constants/routes'
import ProtectedRoute from '../components/ProtectedRoute'
import SessionDispatcher from '../components/SessionDispatcher'
import { withProvider } from '../hocs'

const SessionLinkedSwitch = () => (
  <>
    <SessionDispatcher />
    <Switch>
      <ProtectedRoute
        exact
        path={routes.MAIN}
        component={Main}
        fallbackComponent={PreSignInSwitch}
      />
      <Route
        exact
        path={routes.PRE_SIGN_IN}
        component={PreSignInSwitch}
      />
    </Switch>
  </>
)

export default withProvider(authenticatedClient)(SessionLinkedSwitch)
