import gql from 'graphql-tag'

export const GET_COURSES = gql`
    query (
      $restrictToOwn: Boolean, $withCompany: Boolean = false, $restrictToPlan: Boolean, $excludeGapAnalysis: Boolean, $inCourseLibrary: Boolean = false,
      $forUpdate: Boolean = false, $includeGlobal: Boolean = false
    ) {
        courses (restrictToOwn: $restrictToOwn, restrictToPlan: $restrictToPlan, excludeGapAnalysis: $excludeGapAnalysis, forUpdate: $forUpdate) {
            id
            displayName
            name
            icon
            difficulty
            category
            subject
            locale
            additionalLocales
            availableLocales
            supportedLocales
            global @include(if: $includeGlobal)
            company @include(if: $withCompany) {
              id
              name
            }
            description @include(if: $inCourseLibrary)
            updateAllowed
            deleteAllowed
        }
    }
`

export const GET_DEFAULT_AUTO_ENROL_COURSES = gql`
    query {
      getDefaultAutoEnrolCourses {
        id
        displayName
        difficulty
      }
    }
`

export const COURSES_UPDATED_SUBSCRIPTION = gql`
  subscription onCoursesUpdated ($withCompany: Boolean = false) {
    courseUpdated {
      id
      course {
        id
        displayName
        name
        icon
        difficulty
        category
        subject
        description
        backgroundImage
        questionCount
        randomizeQuestionOrder
        randomizeAnswerOrder
        companyId
        global
        includedInAutoEnrol
        company @include(if: $withCompany) {
          id
          name
        }
        companies {
          id
          name
        }
      }
      deleted
      change
    }
  }
`

export const CREATE_COURSE = gql`
  mutation(
    $name: String!, $locale: LanguageCode!, $additionalLocales: [LanguageCode!], $difficulty: Int, $subject: String, $category: String, $description: String, $icon: String, $backgroundImage: String, 
    $questionCount: Int, $randomizeQuestionOrder: Boolean, $randomizeAnswerOrder: Boolean, $global: Boolean, $companies: [ID!], $companyId: ID, $includedInAutoEnrol: Boolean
  ) {
    createCourse(
      name: $name, locale: $locale, additionalLocales: $additionalLocales, difficulty: $difficulty, subject: $subject, category: $category, description: $description, icon: $icon, backgroundImage: $backgroundImage,
      questionCount: $questionCount, randomizeQuestionOrder: $randomizeQuestionOrder, randomizeAnswerOrder: $randomizeAnswerOrder, global: $global, companies: $companies, companyId: $companyId, includedInAutoEnrol: $includedInAutoEnrol
    ) {
      id
      name
      nameObject
      displayName
      locale
      additionalLocales
      difficulty
      global
      subject
      description
      descriptionObject
      icon
      backgroundImage
      questionCount
      randomizeQuestionOrder
      randomizeAnswerOrder
      includedInAutoEnrol
      companyId
      companies {
        id
        name
      },
      updateAllowed,
      deleteAllowed
    }
  }
`

export const UPDATE_COURSE = gql`
  mutation(
    $courseId: ID!, $locale: LanguageCode!, $additionalLocales: [LanguageCode!], $name: String, $difficulty: Int, $subject: String, $category: String, $description: String, $icon: String, $backgroundImage: String,
    $questionCount: Int, $randomizeQuestionOrder: Boolean, $randomizeAnswerOrder: Boolean, $global: Boolean, $companies: [ID], $companyId: ID, $includedInAutoEnrol: Boolean
  ) {
    updateCourse(
      id: $courseId, name: $name, locale: $locale, additionalLocales: $additionalLocales, difficulty: $difficulty, subject: $subject, category: $category, description: $description, icon: $icon, backgroundImage: $backgroundImage,
      questionCount: $questionCount, randomizeQuestionOrder: $randomizeQuestionOrder, randomizeAnswerOrder: $randomizeAnswerOrder, global: $global, companies: $companies, companyId: $companyId, includedInAutoEnrol: $includedInAutoEnrol
    ) {
      id
      name
      nameObject
      displayName
      locale
      additionalLocales
      difficulty
      global
      subject
      description
      descriptionObject
      icon
      backgroundImage
      questionCount
      randomizeQuestionOrder
      randomizeAnswerOrder
      includedInAutoEnrol
      companyId
      companies {
        id
        name
      },
      updateAllowed,
      deleteAllowed
    }
  }
`

export const GET_COURSE = gql`
    query($courseId: ID!, $forUpdate: Boolean = false) {
        course(id: $courseId, forUpdate: $forUpdate) {
            id
            name
            nameObject
            displayName
            locale
            additionalLocales
            difficulty
            global
            subject
            category
            description
            descriptionObject
            icon
            backgroundImage
            questionCount
            randomizeQuestionOrder
            randomizeAnswerOrder
            includedInAutoEnrol
            companyId
            company {
              id
              name
              accountType
              parentCompanyId
            }
            companies {
              id
              name
              accountType
              parentCompanyId
            },
            updateAllowed,
            deleteAllowed
        }
    }
`

export const GET_COURSE_BASIC = gql`
    query($courseId: ID!, $withCompany: Boolean = false) {
        course(id: $courseId) {
            id
            name
            displayName
            icon
            difficulty
            subject
            category
            companyId
            company @include(if: $withCompany) {
              id
              name
            }
            supportedLocales
        }
    }
`

export const GET_COURSE_SLIDES = gql`
    query ($courseId: ID!, $locale: LanguageCode, $slideId: ID, $includeSiblingSlides: Boolean = false, $forUpdate: Boolean = false) {
      course (id: $courseId, restrictToOwn: true, forUpdate: $forUpdate) {
        id
        name
        locale
        slides (slideLocale: $locale, getSourceSlides: true) {
          id
          slideId
          title
          type
          sortOrder
        }
        siblingSlides: slides (slideId: $slideId) @include(if: $includeSiblingSlides) {
          locale
        }
        randomizeAnswerOrder
      }
    }
`

export const GET_COURSE_SLIDE_LOCALES = gql`
  query ($courseId: ID!) {
    course (id: $courseId, restrictToOwn: true) {
      id
      name
      slideLocales
    }
  }
`

export const UPDATE_COURSE_SLIDES = gql`
    mutation ($courseId: ID!, $locale: LanguageCode!, $slides: [CourseSlideStub]) {
      updateCourseSlides (courseId: $courseId, locale: $locale, slides: $slides) {
        id
        name
        locale
        slides (slideLocale: $locale, getSourceSlides: true) {
          id
          slideId
          title
          type
          sortOrder
        },
        updateAllowed,
        deleteAllowed
      }
    }
`

export const GET_COURSE_SLIDE = gql`
    query ($id: ID, $courseId: ID, $slideId: ID, $locale: LanguageCode, $forUpdate: Boolean = false) {
      courseSlide (id: $id, courseId: $courseId, slideId: $slideId, locale: $locale, restrictToOwn: true, forUpdate: $forUpdate) {
        id
        slideId
        locale
        type
        title
        content
        sortOrder
      }
    }
`

export const UPDATE_COURSE_SLIDE = gql`
    mutation ($id: ID, $courseId: ID, $slideId: ID, $locale: LanguageCode, $title: String, $content: String) {
      updateCourseSlide (id: $id, courseId: $courseId, slideId: $slideId, locale: $locale, title: $title, content: $content) {
        id
        slideId
        locale
        type
        title
        content
        sortOrder
      }
    }
`

export const CLONE_SOURCE_LOCALE_SLIDES = gql`
  mutation($courseId: ID!, $sourceLocale: LanguageCode!, $targetLocale: LanguageCode!, $ids: [ID!], $deleteExistingSlides: Boolean, $overwriteSlides: Boolean) {
    cloneCourseSlidesForLocale(courseId: $courseId, sourceLocale: $sourceLocale, targetLocale: $targetLocale, ids: $ids, deleteExistingSlides: $deleteExistingSlides, overwriteSlides: $overwriteSlides) {
      id
      name
      locale
      slides (slideLocale: $targetLocale, getSourceSlides: true) {
        id
        slideId
        title
        type
        sortOrder
      }
    }
  }
`

export const GET_COURSE_DIFFICULTIES = gql`
    query {
        courses {
            difficulty
        }
    }
`

export const GET_COURSE_CATEGORIES = gql`
    query {
        courses {
            category
        }
    }
`

export const GET_COURSE_SUBJECTS = gql`
    query {
        courses {
            subject
        }
    }
`

export const GET_COURSE_SUBJECTS_CATEGORIES = gql`
  query {
    courses {
      id
      subject
      category
    }
  }
`

export const DUPLICATE_COURSE = gql`
  mutation ($courseId: ID!, $courseName: String, $companyId: ID) {
    duplicateCourse (id: $courseId, name: $courseName, companyId: $companyId) {
      id
      name
      difficulty
      subject
    }
  }
`

export const DELETE_COURSES = gql`
  mutation ($courseIds: [ID!]!) {
    deleteCourses (courseIds: $courseIds)
  }
`

export const RUN_AUTO_ENROL_NOW = gql`
  mutation {
    runAutoEnrolNow
  }
`
export const REGENERATE_COURSE_SCHEDULE = gql`
  mutation {
    regenerateCourseSchedule
  }
`

export const SEND_COURSE_REMINDERS_NOW = gql`
  mutation {
    sendCourseReminders
  }
`

export const SEND_GAP_ANALYSIS_REMINDERS_NOW = gql`
  mutation {
    sendGapAnalysisReminders
  }
`

export const UNENROLL_LEARNERS_FROM_COURSES = gql`
  mutation($courseIds: [ID!]!, $learnerIds: [ID!]!) {
    unenrollLearnersFromCourses(courseIds: $courseIds, learnerIds: $learnerIds)
  }
`

export const ENROL_ON_COURSE = gql`
  mutation($courseIds:[ID!]!, $learnerIds:[ID!]!) {
    enrollLearnersOnCourses (courseIds: $courseIds, learnerIds: $learnerIds) {
      success
      courseIds
      learnerIds
      completed {
        id
        learners {
          id,
          reason,
          action
        }
      }
      failures {
        id
        learners {
          id,
          reason,
          action
        }
      }
    }
  }
`

export const TRANSLATE_COURSE = gql`
  mutation($courseId: ID, $slideId: ID, $languages: [CourseLanguages]) {
    translateCourse(courseId: $courseId, slideId: $slideId, languages: $languages)
  }
`

export const ENROL_ON_GAP_ANALYSIS = gql`
mutation($learnerIds: [ID!]!) {
  enrollLearnersOnGapAnalysis (learnerIds: $learnerIds) {
    success
    learnerIds
    completed {
      learnerId
      reason
      action
      executionTime
    }
    failures {
      learnerId
      reason
      action
      executionTime
    }
    errors
  }
}
`

export const UNENROLL_FROM_GAP_ANALYSIS = gql`
  mutation($learnerIds: [ID!]!) {
    unenrollLearnersFromGapAnalysis(learnerIds: $learnerIds)
  }
`

export const GET_GAP_ANALYSIS_LOCALES = gql`
  query {
    getGapAnalysisLocales
  }
`

export const ADD_COMPANY_COURSES = gql`
  mutation ($courseIds: [ID!]!, $companyIds: [ID!]!) {
    addCompanyCourses(courseIds: $courseIds, companyIds: $companyIds)
}
`

export const REMOVE_COMPANY_COURSES = gql`
  mutation ($courseIds: [ID!]!, $companyIds: [ID!]!) {
    removeCompanyCourses(courseIds: $courseIds, companyIds: $companyIds)
  }
`
export const GET_COURSES_OWNER = gql`
  query ($courseIds: [ID!]!) {
    getCoursesOwner(courseIds: $courseIds) {
      companyId,
      managedByUsecure
  }
}
`
