import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Card, Row, Col, Divider, Tag, Button } from 'antd'
import styled, { withTheme } from 'styled-components'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import I18n from 'i18n-js'
import { useQuery } from '@apollo/react-hooks'
import { compose } from 'recompose'
import { NetworkStatus } from 'apollo-client'

import { ContentWrap, LoadingBlock, ErrorAlerts, DownloadCertificateBtn } from '../components/common'
import { UlearnPerformanceRadar } from '../components/Graphs/uLearnRadar'
import { GET_LEARNER_DATA } from '../components/Queries/Learners'
import { CourseTabs, SyncIndicator, UpcomingCourses } from '../components/Learner'
import RiskScoreIndicator from '../components/Learners/RiskScoreIndicator'
import { connect } from '../hocs'
import selectors from '../state/selectors'
import { COURSE_CATEGORIES, COURSE_SUBJECTS } from '../constants/courses'
import { QUERY_POLL_INTERVAL } from '../constants/apolloClient'
import { permissions } from '../constants/permissions'
import { WRITABLE_LEARNER_COURSE_ACTIONS, WRITABLE_LEARNER_GAP_ANALYSIS_ACTIONS } from '../constants/actions'
import { processListActions } from '../helpers/listPages'
import { creators as viewActions } from '../state/actions/view'
import UnenrolLearnersFromGapAnalysisConfirm from '../components/Modals/UnenrolLearnersFromGapAnalysisConfirm'
import { generateCertificate } from '../helpers/certificates'
import { useHasSessionPermission } from '../hooks'

import { formatDate } from '../helpers/datetime'

const trOpt = { scope: 'learner' }

const UlearnPerformanceRadarContainer = styled.div`
  height: 500px;
  position: relative;
`

const LearnerCard = styled(Card)`
  height: 100%;
`
const LearnerModalCard = styled(Card)`
  min-height: 100%;
`

const LearnerIdentifier = styled.div`
  h4 {
    display: inline-block;
    margin-bottom: 0;
  }
  & > * {
    margin-left: 5px;
  }
  & > *:first-child {
    margin-left: 0;
  }
`

const getActions = (record, disabledActions, omittedActions) => {
  const recordOmittedActions = [...omittedActions]
  const actions = []
  const isGapAnalysis = record.key === 'gapAnalysis'

  if (record.finishDate && !isGapAnalysis) {
    actions.push({ key: 'downloadCertificate', label: I18n.t('common.downloadCertificate') })
  } else if (!record.finishDate) {
    actions.push(
      { key: isGapAnalysis ? 'sendGapAnalysisReminder' : 'sendReminder', label: I18n.t('sendCourseReminder', trOpt) },
      { key: isGapAnalysis ? 'unenrolGapAnalysis' : 'unenrolCourse', label: I18n.t('unEnrolFromCourse', trOpt) }
    )
  }

  return processListActions({
    actions,
    omittedActions: recordOmittedActions,
    disabledActions
  })
}

const Learner = ({ data, enableRiskScore, setLoadingVisible, theme, refetch = () => {}, loading = false, planValid, locale, setPollingEnabled }) => {
  const learner = _get(data, 'learnerData.learner') || {}
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const downloadCertificate = useCallback(async courseResult => {
    try {
      setLoadingVisible(true)
      const { name: courseTitle, finishDate: dateOfCompletion, score: scorePercentage } = courseResult
      await generateCertificate({
        logoSrc: theme.appThemeLogo,
        courseTitle,
        dateOfCompletion,
        scorePercentage,
        presentedToName: learner.name,
        ribbonColour: theme.primary,
        locale
      })
    } catch (e) {
      console.error('Learner.downloadCertificate - ERROR', e)
    } finally {
      setLoadingVisible(false)
    }
  }, [setLoadingVisible, theme, learner, locale])

  const { disabledActions, omittedActions } = useMemo(() => {
    const omittedActions = []
    if (!hasAllSessionPermissions([permissions.COURSE_REMINDER_SEND])) {
      omittedActions.push('sendReminder', 'sendGapAnalysisReminder')
    }
    if (!hasAllSessionPermissions([permissions.COURSE_ENROLMENT_DELETE])) {
      omittedActions.push('unenrolCourse', 'unenrolGapAnalysis')
    }
    if (!hasAllSessionPermissions([permissions.COURSE_DOWNLOAD_CERTIFICATE])) {
      omittedActions.push('downloadCertificate')
    }

    return {
      disabledActions: !planValid ? [...WRITABLE_LEARNER_COURSE_ACTIONS, ...WRITABLE_LEARNER_GAP_ANALYSIS_ACTIONS] : null,
      omittedActions
    }
  }, [planValid, hasAllSessionPermissions])

  const { chartData, enrolledCourseData, completedCourses } = useMemo(() => {
    const { learner, uLearnPerformance, categories } = _get(data, 'learnerData') || {}
    let enrolledCourseData = []
    let completedCourses = []
    if (learner) {
      const { id: learnerId, name: learnerName } = learner
      const courses = learner.courseResults.filter(result => result.course.difficulty !== 1)

      completedCourses = courses.reduce((result, current) => {
        if (current.finishDate != null && current.startDate !== null && current.level !== 1) {
          result.push({
            id: current.id,
            courseId: current.course.id,
            name: current.course.name,
            subject: COURSE_SUBJECTS[current.course.subject],
            difficulty: current.course.difficulty,
            score: current.score,
            finishDate: current.finishDate,
            finished: current.finished,
            learnerName: learnerName
          })
        }
        return result
      }, [])

      enrolledCourseData = courses.map(result => {
        const { id: key, enrollDate, startDate, finishDate, score, auto, course: { name, difficulty, subject, id: courseId } } = result
        return {
          key,
          name,
          level: difficulty,
          enrollDate,
          enrollDateStr: enrollDate ? formatDate(enrollDate) : null,
          startDate,
          startDateStr: startDate ? formatDate(startDate) : null,
          finishDate,
          finishDateStr: finishDate ? formatDate(finishDate) : null,
          score,
          auto: auto === true,
          subject,
          learnerId,
          courseId,
          actions: getActions(result, disabledActions, omittedActions)
        }
      })

      const gapAnalysisCourses = learner.courseResults.filter(result => result.course.difficulty === 1)

      if (gapAnalysisCourses.length >= 1) {
        const gapAnalysisStarted = gapAnalysisCourses.some(({ startDate }) => !_isNil(startDate))
        const gapAnalysisFinished = gapAnalysisCourses.every(({ finishDate }) => !_isNil(finishDate))

        const enrollDate = moment.min(gapAnalysisCourses.map(({ enrollDate }) => moment(enrollDate, 'YYYY-MM-DD')))
        const startDate = (
          gapAnalysisStarted
            ? (
              moment.min(
                gapAnalysisCourses
                  .filter(({ startDate }) => !_isNil(startDate))
                  .map(({ startDate }) => moment(startDate, 'YYYY-MM-DD'))
              )
            ) : null
        )
        const finishDate = (
          gapAnalysisFinished
            ? (
              moment.max(gapAnalysisCourses.map(({ finishDate }) => moment(finishDate, 'YYYY-MM-DD')))
            ) : null
        )

        const gapAnalysis = {
          key: 'gapAnalysis',
          name: I18n.t('courses.common.difficulties.gapAnalysis'),
          level: 1,
          learnerId,
          enrollDate: enrollDate ? enrollDate.format('YYYY-MM-DD') : null,
          enrollDateStr: enrollDate ? formatDate(enrollDate) : null,
          startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
          startDateStr: startDate ? formatDate(startDate) : null,
          finishDate: finishDate ? finishDate.format('YYYY-MM-DD') : null,
          finishDateStr: finishDate ? formatDate(finishDate) : null,
          score: (
            gapAnalysisFinished
              ? Math.round(
                (gapAnalysisCourses.reduce((score, course) => course.score + score, 0) / (gapAnalysisCourses.length * 100)) * 100
              ) : null
          ),
          subject: 'InfoSec',
          auto: gapAnalysisCourses.every(c => c.auto === true)
        }

        enrolledCourseData.push({
          ...gapAnalysis,
          actions: getActions(gapAnalysis, disabledActions, omittedActions)
        })
      }
    }

    const chartData = (categories || []).map(category => {
      const { score } = uLearnPerformance[category] || {}
      return {
        category: COURSE_CATEGORIES[category] || category,
        value: score || 0
      }
    })

    return {
      enrolledCourseData,
      chartData,
      completedCourses
    }
  }, [data, disabledActions, omittedActions])

  const showRiskScore = enableRiskScore && learner.riskScoreLevel

  const unenrolGapAnalysisRef = useRef(null)
  const openUnenrolGapAnalysis = useCallback(learnerId => {
    if (unenrolGapAnalysisRef.current) {
      unenrolGapAnalysisRef.current.open([learnerId])
    }
  }, [unenrolGapAnalysisRef])

  const handleRefreshClick = useCallback(() => refetch(), [refetch])

  return (
    <>
      <UnenrolLearnersFromGapAnalysisConfirm
        ref={unenrolGapAnalysisRef}
        refetchQueries={[{
          query: GET_LEARNER_DATA,
          variables: { id: learner.id }
        }]}
      />
      <Row type='flex'>
        <Col span={showRiskScore ? 18 : 24}>
          <h1>{learner.name} {learner.inactive ? <Tag>{I18n.t('common.inactive')}</Tag> : null}</h1>
        </Col>
        {
          showRiskScore
            ? (
              <Col span={6} style={{ textAlign: 'right' }}>
                <RiskScoreIndicator value={learner.riskScoreLevel} size='large' />
              </Col>
            ) : null
        }
      </Row>
      <Row type='flex' justify='space-between'>
        <LearnerIdentifier>
          <h4>{learner.email || learner.learnerId}</h4>
          <SyncIndicator syncRecordId={learner.syncRecordId} syncType={learner.syncType} />
        </LearnerIdentifier>
        <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
      </Row>
      <Divider><h1>{I18n.t('common.uLearn')}</h1></Divider>
      {hasAllSessionPermissions([permissions.COURSE_REPORT]) && (
        <Row>
          <Col span={24}>
            <UlearnPerformanceRadarContainer>
              <UlearnPerformanceRadar data={chartData} theme={theme} />
            </UlearnPerformanceRadarContainer>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Row type='flex' justify='space-between'>
            <h1>{I18n.t('enrolledCourses', trOpt)}</h1>
            {hasAllSessionPermissions([permissions.COURSE_DOWNLOAD_CERTIFICATE]) && !_isEmpty(completedCourses) && <DownloadCertificateBtn btnTextKey='downloadAllCertificates' logoSrc={theme.appThemeLogo} ribbonColour={theme.primary} courses={completedCourses} type='primary' />}
          </Row>
          <CourseTabs data={enrolledCourseData} {...{ downloadCertificate, openUnenrolGapAnalysis }} />
        </Col>
      </Row>
      {learner.courseScheduleData && (
        <Row>
          <Col span={24}>
            <UpcomingCourses courseScheduleData={learner.courseScheduleData} />
          </Col>
        </Row>
      )}
    </>
  )
}

const LearnerQuery = props => {
  const id = _get(props, 'match.params.learnerId', props.id)
  const { modal = false, enableRiskScore } = props

  // Delay rendering of content until modal is open - avoids squashed graphs
  const [ready, updateReady] = useState(!modal)
  useEffect(() => {
    if (modal) {
      setTimeout(() => updateReady(true), 500)
    }
  }, [modal, updateReady])

  const [pollingEnabled, setPollingEnabled] = useState(true)
  const { loading: queryLoading, error, data, refetch, networkStatus } = useQuery(GET_LEARNER_DATA, {
    variables: { id, withRiskScore: enableRiskScore },
    pollInterval: pollingEnabled ? QUERY_POLL_INTERVAL : 0,
    notifyOnNetworkStatusChange: true
  })
  const loading = queryLoading && networkStatus !== NetworkStatus.poll

  let content = null
  if (!ready) {
    content = <LoadingBlock fullScreen={!modal} loading />
  } else if (error) {
    content = (
      <div>
        <h1>{I18n.t('viewUser', trOpt)}</h1>
        <ErrorAlerts {...{ error }} defaultError={I18n.t('failedToFindAUser', trOpt)} />
      </div>
    )
  } else {
    content = (
      <>
        <LoadingBlock fullScreen={!modal} loading={loading} />
        <Learner {...props} {...{ loading, data, refetch, setPollingEnabled }} />
      </>
    )
  }

  // Exclude wrap from modal view
  if (modal) {
    return (
      <LearnerModalCard>{content}</LearnerModalCard>
    )
  }

  return (
    <ContentWrap>
      <LearnerCard>{content}</LearnerCard>
    </ContentWrap>
  )
}

export default compose(
  withTheme,
  connect(
    state => ({
      ..._pick(selectors.settings.get(state), ['enableRiskScore']),
      ..._pick(selectors.session.get(state), ['planValid', 'locale'])
    }),
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    })
  )
)(LearnerQuery)
