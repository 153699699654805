import React from 'react'
import I18n from 'i18n-js'
import { Switch } from 'antd'

const IncludeClientContentToggle = ({ includeClientContent, setIncludeClientContent }) => {
  return (
    <div>
      <Switch checked={includeClientContent} onChange={setIncludeClientContent} />
      <span style={{ marginLeft: 10 }}>{I18n.t('common.accessControl.includeClientContentToggle')}</span>
    </div>
  )
}

export default IncludeClientContentToggle
