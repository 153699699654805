import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'

import DeleteConfirm from './DeleteConfirm'

const trOpt = { scope: 'modals.deleteCustomReportConfirm' }

const DeleteCustomReportConfirm = React.forwardRef(({
  companyId,
  refetchQueries,
  onClose: onCloseProp = () => {},
  deleteMutation
}, ref) => {
  const [recordIds, setRecordIds] = useState([])

  const deleteConfirmRef = useRef(null)
  useImperativeHandle(ref, () => ({
    open: reports => {
      setRecordIds(reports.map(({ recordIds }) => recordIds).flat())
      if (deleteConfirmRef.current) {
        deleteConfirmRef.current.open(reports.map(r => r.id))
      }
    }
  }), [deleteConfirmRef])

  const onClose = useCallback(() => {
    setRecordIds([])
    onCloseProp()
  }, [onCloseProp])

  return (
    <DeleteConfirm
      mutation={deleteMutation}
      ref={deleteConfirmRef}
      variableName={null}
      variables={{ companyId, recordIds }}
      {...{ refetchQueries, onClose, trOpt }}
    />
  )
})

export default DeleteCustomReportConfirm
