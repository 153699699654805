import { useMemo } from 'react'
import _isFunction from 'lodash/isFunction'

import { useStateValue } from '../state'

// This is a hook version of the connect HOC to interact with the global state
// To reduce the change volume from this hook, the _mapStateToProps & _mapDispatchToProps functions should either be wrapped by useCallback or defined outside the component using the hook.
// It will work if you don't do but you may see unwanted side effects in code consumes what this hooks provides.
const useGlobalState = (mapStateToProps, mapDispatchToProps) => {
  const [state, dispatch] = useStateValue()

  // This hook will reexecute every time the full global state changes even if the result of _mapStateToProps(state) hasn't changed
  // Downstream hooks in the components using useGlobalState should use specific properties rather than whole sub-states (e.g. session) to reduce re-executions.
  const stateProps = useMemo(
    () => _isFunction(mapStateToProps) ? mapStateToProps(state) : {},
    [state, mapStateToProps]
  )
  // This hook will re-execute fairly infrequently as dispatch shouldn't change very often especially if mapDispatchToProps is static
  const dispatchProps = useMemo(
    () => _isFunction(mapDispatchToProps) ? mapDispatchToProps(dispatch) : {},
    [dispatch, mapDispatchToProps]
  )

  return { ...stateProps, ...dispatchProps }
}

export default useGlobalState
