import React, { useMemo } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { BasicTooltip } from '@nivo/tooltip'

import useIsPrinting from '../../hooks/useIsPrinting'
import { trendColors } from '../common/PerformanceReport'
import { BrandColours, generateYScaleMinMax } from '../../helpers/Graphs'

const LineChart = ({
  data = [], // Data should be formated in a way that Nivo expects
  trend = 'neutral', // should match one of the keys in `trendColors`. Will be used to colour the chart
  isStatic = true, // should be true if this chart is being printed
  colors = BrandColours,
  axisFormat = {}
}) => {
  const isPrinting = useIsPrinting()

  const chartProps = useMemo(() => {
    // Nivo charts by default overshoot the y axis in most instances.
    // generateYScaleMinMax will provide a max value we can use in yScale
    // to prevent this
    const { max } = generateYScaleMinMax(data)

    // Get the axisFormat.left, with a fallback if not present
    const { left: axisFormatLeft = v => v } = axisFormat

    return {
      theme: {
        background: '#ffffff',
        color: trendColors[trend]
      },
      animate: isPrinting ? false : isStatic,
      isInteractive: isPrinting ? false : isStatic,
      colors,
      lineWidth: 2,
      margin: {
        top: 5,
        right: 50,
        bottom: 60,
        left: 50
      },
      yScale: { type: 'linear', stacked: false, max },
      pointSize: 10,
      pointBorderWidth: 2,
      pointBorderColor: { from: 'seriesColor' },
      pointLabel: 'y',
      pointLabelYOffset: -12,
      useMesh: true,
      axisLeft: { tickValues: 5, format: axisFormatLeft },
      legends: [
        {
          anchor: 'bottom',
          direction: 'row',
          translateX: 0,
          translateY: 60,
          itemsSpacing: 5,
          itemWidth: 140,
          itemHeight: 18,
          itemTextColor: '#999',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ],
      tooltip: ({ point }) => (
        <BasicTooltip
          id={
            <span>{point.data.xFormatted}: {axisFormatLeft(point.data.yFormatted)}</span>
          }
          enableChip
          color={point.serieColor}
        />
      ),
      ...(isPrinting ? {
        width: 724 / 0.9,
        height: 190 / 0.9
      } : {})
    }
  }, [isStatic, trend, data, axisFormat, isPrinting, colors])
  return (
    <ResponsiveLine
      data={data}
      {...chartProps}
    />
  )
}

export default LineChart
