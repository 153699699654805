import React, { useCallback, useMemo } from 'react'
import { Radio } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'

import RecommendedTag from '../common/RecommendedTag'

const VerticalRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper {
    display: flex;
    margin-bottom: 5px;
    white-space: normal;
  }
`
const SideBySideContainer = styled.div`
  display: flex;

  ${VerticalRadioGroup} {
    max-width: 600px;
  }
`
const DescContainer = styled.div`
  padding-left: 10px;

  ul {
    padding: 0 0 0 20px;
  }
`

const TaggedOptionLabel = styled.div`
  position: relative;
  top: -5px;
`
const RecommendedLabel = styled.span`
  font-weight: 500;
`

const SyncSetupSetupQuestion = ({
  id, question, description, value = true,
  onChange: onChangeProp = () => {},
  options: optionsProp = [{ value: true, label: I18n.t('common.yes') }, { value: false, label: I18n.t('common.no') }],
  verticalOptions = false, sideBySide = false,
  autoSync, includeGroups, includeGroupMembWhitelist, includeGroupMembMapping, includeUserEmailBlacklist, emailMethod
}) => {
  let desc = null
  if (_isString(description)) {
    desc = <p>{description}</p>
  } else if (React.isValidElement(description)) {
    desc = description
  } else if (_isFunction(description)) {
    const DescComponent = description
    desc = <DescComponent {...{ autoSync, includeGroups, includeGroupMembWhitelist, includeGroupMembMapping, includeUserEmailBlacklist, emailMethod }} />
  }
  const onChange = useCallback(event => {
    onChangeProp(id, event.target.value)
  }, [id, onChangeProp])

  const options = useMemo(() =>
    optionsProp.map(({ label: labelText, recommended, ...rest }) => {
      let label = labelText
      if (recommended) {
        label = (
          <TaggedOptionLabel>
            <RecommendedLabel>{labelText}</RecommendedLabel>
            <RecommendedTag />
          </TaggedOptionLabel>
        )
      }
      return {
        ...rest,
        label
      }
    })
  , [optionsProp])

  let body = null
  if (sideBySide && desc) {
    body = (
      <SideBySideContainer>
        <VerticalRadioGroup {...{ value, options, onChange }} />
        <DescContainer>{desc}</DescContainer>
      </SideBySideContainer>
    )
  } else {
    body = (
      <>
        {
          verticalOptions
            ? <VerticalRadioGroup {...{ value, options, onChange }} />
            : <Radio.Group {...{ value, options, onChange }} />
        }
        {desc}
      </>
    )
  }

  return (
    <div>
      <h3>{question}</h3>
      {body}
    </div>
  )
}

export default SyncSetupSetupQuestion
