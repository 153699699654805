export const HIGH_WEIGHTED_BREACH_DATA_CLASSES = [
  'biometricData',
  'historicalPasswords',
  'passwords',
  'pins',
  'personallyIdentifiableInfo'
]
export const MEDIUM_WEIGHTED_BREACH_DATA_CLASSES = [
  'authTokens',
  'bankAccountNumbers',
  'creditCardCVV',
  'creditCards',
  'encryptedKeys',
  'governmentIssuedIDs',
  'passportNumbers',
  'passwordHints',
  'passwordStrengths'
]

export const SEVERITY_OPTIONS = [
  'high',
  'medium',
  'low'
]

export const UBREACH_PRO_UPGRADE_HELP_CENTRE_URL = 'https://help.usecure.io/articles/8386073'
