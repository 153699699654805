import React, { useRef } from 'react'
import I18n from 'i18n-js'
import { Link, generatePath } from 'react-router-dom'
import { Table, Input, Button, Icon } from 'antd'
import moment from 'moment'
import _startCase from 'lodash/startCase'
import _pick from 'lodash/pick'
import _isInteger from 'lodash/isInteger'
import _isNumber from 'lodash/isNumber'

import routes from '../../../constants/routes'
import { connect } from '../../../hocs'
import selectors from '../../../state/selectors'
import { LIST_PAGINATION_PROPS } from '../../../constants/list'
import { dateTimeColumnSorter, numberColumnSorter, textColumnSorter } from '../../../helpers/listPages'
import { courseResultDurationColumnSorter } from '../../../helpers/uLearnReport'
import { formatDate } from '../../../helpers/datetime'

const trOpt = { scope: 'reports.uLearnReport' }

const CourseUserTable = (props) => {
  const searchInput = useRef(null)

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={I18n.t('searchUserName', trOpt)}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {I18n.t('search', trOpt)}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          {I18n.t('reset', trOpt)}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    }
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
  }

  const handleReset = clearFilters => {
    clearFilters()
  }

  const columns = [
    {
      title: I18n.t('userName', trOpt),
      dataIndex: 'learnerName',
      key: 'learnerName',
      sorter: (a, b) => textColumnSorter('learnerName', a, b),
      ...getColumnSearchProps('learnerName')
    },
    {
      title: I18n.t('score', trOpt),
      key: 'score',
      dataIndex: 'score',
      sorter: (a, b) => numberColumnSorter('score', a, b),
      render: (score) => {
        if (!_isNumber(score)) return <span>{I18n.t('common.na')}</span>
        return <span>{score}%</span>
      }
    },
    {
      title: I18n.t('retries', trOpt),
      key: 'retries',
      dataIndex: 'retries',
      sorter: (a, b) => numberColumnSorter('retries', a, b)
    },
    {
      title: I18n.t('enrollDate', trOpt),
      key: 'enrollDate',
      dataIndex: 'enrollDate',
      sorter: (a, b) => dateTimeColumnSorter('enrollDate', a, b),
      render: (enrollDate) => {
        return <div>{enrollDate ? formatDate(enrollDate) : I18n.t('common.na')}</div>
      }
    },
    {
      title: I18n.t('startDate', trOpt),
      key: 'startDate',
      dataIndex: 'startDate',
      sorter: (a, b) => dateTimeColumnSorter('startDate', a, b),
      render: (startDate) => {
        return <div>{startDate ? formatDate(startDate) : I18n.t('common.na')}</div>
      }
    },
    {
      title: I18n.t('finishDate', trOpt),
      key: 'finishDate',
      dataIndex: 'finishDate',
      sorter: (a, b) => dateTimeColumnSorter('finishDate', a, b),
      render: (finishDate) => {
        return <div>{finishDate ? formatDate(finishDate) : I18n.t('common.na')}</div>
      }
    },
    {
      title: I18n.t('completionTimeFromStart', trOpt),
      key: 'timeToCompleteFromStart',
      dataIndex: 'timeToCompleteFromStart',
      sorter: (a, b) => courseResultDurationColumnSorter('timeToCompleteFromStart', a, b),
      render: (timeToCompleteFromStart, { startDate }) => {
        if (!startDate) return <span>{I18n.t('notStarted', trOpt)}</span>
        if (!_isInteger(timeToCompleteFromStart)) return <span>{I18n.t('notCompleted', trOpt)}</span>
        return <span>{_startCase(moment.duration(timeToCompleteFromStart, 'minutes').humanize())}</span>
      }
    },
    {
      title: I18n.t('completionTimeFromEnrollment', trOpt),
      key: 'timeToCompleteFromEnrollment',
      dataIndex: 'timeToCompleteFromEnrollment',
      sorter: (a, b) => courseResultDurationColumnSorter('timeToCompleteFromEnrollment', a, b),
      render: (totalMinutesFromEnrollment, { startDate }) => {
        if (!startDate) return <span>{I18n.t('notStarted', trOpt)}</span>
        if (!_isInteger(totalMinutesFromEnrollment)) return <span>{I18n.t('notCompleted', trOpt)}</span>
        return <span>{_startCase(moment.duration(totalMinutesFromEnrollment, 'minutes').humanize())}</span>
      }
    }]

  if (!props.isGapAnalysis) {
    columns.push({
      title: I18n.t('action', trOpt),
      key: 'action',
      render: (learner) => {
        return <Link to={generatePath(routes.LEARNER_REPORT, { learnerId: learner.learnerId })}> {I18n.t('view', trOpt)}</Link>
      }
    })
  }

  return (
    <Table
      columns={columns}
      dataSource={props.learnerTableData}
      pagination={LIST_PAGINATION_PROPS}
    />
  )
}

export default connect(state => _pick(selectors.session.get(state), ['locale']))(CourseUserTable)
