import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router'
import { generatePath, Link, withRouter, matchPath } from 'react-router-dom'
import { Button, Dropdown, Layout, Menu, message } from 'antd'
import styled from 'styled-components'
import uuid from 'uuid/v4'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import routes from '../../constants/routes'
import { LanguageDropdown } from '../common'
import { showErrors } from '../../helpers'
import { ChangedIndicator, CONTENT_TYPE_NAMES } from './common'
import DragAndDropList from './DragAndDropList'
import SaveConfirmModal from './SaveConfirmModal'
import CourseContentEditorSlide from './CourseContentEditorSlide'
import CloneSourceLocaleCourseSlidesModal from '../Modals/CloneSourceLocaleCourseSlidesModal'

const trOpt = { scope: 'editCourse.courseContentEditor' }
const { Header } = Layout

export const CourseContentEditorLayout = styled(Layout)`
    height: 100%;
    padding: 2rem 5.1543rem 2.5rem;
`
export const CourseContentEditorHeader = styled(Header)`
    background-color: transparent;
    height: auto;
    line-height: unset;
    padding: 0 10px;
`
export const CourseContentEditorHeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
`
export const CourseTitle = styled.h1`
    padding-right: 20px;
`
export const CourseHeaderLink = styled(Link)`
    white-space: nowrap;
`
const CourseHeaderLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &> ${CourseHeaderLink}, &> .ant-btn {
    margin-bottom: 10px;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
`

export const CourseContentEditorContent = styled.div`
    display: block;
    flex: auto;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 ${({ padHorizontal }) => padHorizontal || '0'};
`

export const CourseContentControls = styled.div`
    background-color: transparent;
    display: flex;
    padding: 0 0 12px;
`
export const CourseContentEditorPanel = styled.div`
    display: flex;
    justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};
    width: 50%;
    &> *, &> .ant-btn-group + .ant-btn {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
`

const AddSlideMenuItem = ({ add, type }) => {
  const onClick = () => add(type)
  const name = CONTENT_TYPE_NAMES[type] || '<TYPE_NAME>'
  return (
    <div onClick={onClick}>{name}</div>
  )
}

class CourseContentEditor extends Component {
  constructor (props) {
    super(props)

    this.list = React.createRef()
    this.cloneSlidesModalRef = React.createRef()

    this.state = {
      changed: false,
      postSavePath: null,
      saveConfirmOpen: false
    }

    this.addSlide = this.addSlide.bind(this)
    this.modifyDuplicateItem = this.modifyDuplicateItem.bind(this)
    this.handlePreviewClick = this.handlePreviewClick.bind(this)
    this.handleSlidePreviewClick = this.handleSlidePreviewClick.bind(this)
    this.showSlidePreviewOption = this.showSlidePreviewOption.bind(this)
    this.onPrompt = this.onPrompt.bind(this)
    this.handleSaveConfirmModalCancel = this.handleSaveConfirmModalCancel.bind(this)
    this.handleSaveConfirmModalNo = this.handleSaveConfirmModalNo.bind(this)
    this.handleSaveConfirmModalYes = this.handleSaveConfirmModalYes.bind(this)
    this.handleSlideEditClick = this.handleSlideEditClick.bind(this)
    this.handleUpdateClick = this.handleUpdateClick.bind(this)
    this.handleOpenCloneSlideClick = this.handleOpenCloneSlideClick.bind(this)
    this.updateSlides = this.updateSlides.bind(this)
    this.onUnload = this.onUnload.bind(this)
  }

  get courseId () {
    return this.props.courseId
  }

  get courseName () {
    return this.props.courseName
  }

  get changed () {
    return this.state.changed
  }

  _updateState (stateUpdate) {
    return new Promise(resolve => {
      this.setState(stateUpdate, async () => {
        resolve()
      })
    })
  }

  updateChanged (changed) {
    return this._updateState({ changed })
  }

  async updateSlides (slides) {
    await this.updateChanged(true)
    return this.props.setSlides(slides)
  }

  generateSlideId () {
    const { slides } = this.props
    let id

    while (!id) {
      const possibleId = uuid()
      const alreadyUsed = slides.some(slide => slide.slideId === possibleId)
      if (!alreadyUsed) {
        id = possibleId
      }
    }

    return id
  }

  async addSlide (type = 'material') {
    const { locale, slides } = this.props
    await this.updateSlides([
      ...slides,
      {
        id: uuid(), // Temporary id which will replaced with DB primary key when saved
        slideId: this.generateSlideId(),
        type,
        locale,
        newSlide: true
      }
    ])
    this.scrollListToTheBottom()
  }

  modifyDuplicateItem (newItem, originalItem) {
    newItem.slideId = this.generateSlideId()
    newItem.newSlide = true
    // These ids identify the new slide as a duplicate and tell the back end to copy the source's content
    // If the source slide is also an unsaved duplicate then the its source is passed on to the new duplicate
    newItem.sourceId = originalItem.sourceId || originalItem.id
    newItem.sourceSlideId = originalItem.sourceSlideId || originalItem.slideId
    // Prefix title to make it easier to tell which slide is the duplicate
    if (newItem.title) {
      newItem.title = I18n.t('common.duplicateDefaultName', { name: `"${newItem.title}"`, locale: this.props.locale })
    } else if (newItem.type) {
      const slideType = CONTENT_TYPE_NAMES[newItem.type]
      if (slideType) {
        newItem.title = I18n.t('common.duplicateDefaultName', { name: `"${slideType}"`, locale: this.props.locale })
      }
    }
  }

  scrollListToTheBottom () {
    const list = this.list ? this.list.current : null
    if (list) {
      list.scrollTop = list.scrollHeight
    }
  }

  async save ({ background = false } = {}) {
    const start = Date.now()
    const delay = 800
    let success = false
    if (!background) this.props.setLoadingVisible(true)
    try {
      const { courseId, locale, slides } = this.props
      await this.props.saveSlides({
        variables: {
          courseId,
          locale,
          slides: slides.map((rawSlide, index) => {
            const slide = _pick(rawSlide, ['slideId', 'type', 'sortOrder'])
            // Add id and title if this slide is a duplicate
            if (rawSlide.sourceId) {
              slide.sourceId = rawSlide.sourceId
              slide.title = rawSlide.title
            }
            slide.sortOrder = index
            if (!slide.newSlide) {
              slide.id = rawSlide.id
            }
            return slide
          })
        }
      })
      success = true
    } catch (e) {
      showErrors(e, I18n.t('editCourse.common.anErrorOccurredUpdatingThisCourse'))
    }
    if (success) {
      await this.updateChanged(false)
      await this.props.refetchSlides()
      if (!background) {
        // Artificial delay on hiding the loading screen unless mutation took longer than artificial delay
        const mutationTime = Date.now() - start
        if (mutationTime >= delay) {
          this.props.setLoadingVisible(false)
        } else {
          setTimeout(() => {
            this.props.setLoadingVisible(false)
            message.success(I18n.t('editCourse.common.successMessage'))
          }, delay - mutationTime)
        }
      }
    } else if (!background) {
      this.props.setLoadingVisible(false)
    }
    return success
  }

  async saveOnPromptForNewSlide (postSavePath) {
    this.props.setLoadingVisible(true)
    const success = await this.save({ background: true })
    this.props.setLoadingVisible(false)
    if (success) {
      this.navigate(postSavePath)
    }
  }

  async showUnloadSaveConfirm (postSavePath) {
    this.setState({
      postSavePath,
      saveConfirmOpen: true
    })
  }

  async handleSaveConfirmModalYes () {
    const { postSavePath } = this.state
    const success = await this.save()
    this.handleSaveConfirmModalCancel()
    if (success) {
      this.navigate(postSavePath)
    }
  }

  async handleSaveConfirmModalNo () {
    const { postSavePath } = this.state
    await this.updateChanged(false)
    this.handleSaveConfirmModalCancel()

    if (postSavePath) {
      if (this.isRoutePathForNewSlide(postSavePath)) {
        // Attempting to navigate to new, unsaved slide, reset page
        await this.props.refetchSlides()
        return
      }
      this.navigate(postSavePath)
    }
  }

  handleSaveConfirmModalCancel () {
    this.setState({
      postSavePath: null,
      saveConfirmOpen: false
    })
  }

  navigate (path) {
    if (path) {
      this.props.history.push(path)
    }
  }

  getSlideIdFromRoutePath (routePath) {
    let slideId
    ['BUILDER_EDIT_SLIDE', 'BUILDER_EDIT_SLIDE_LOCALE'].some(routeId => {
      const match = matchPath(routePath, { path: routes[routeId], exact: true })
      if (match) {
        slideId = _get(match, 'params.slide_id')
        return true
      }
      return false
    })
    return slideId
  }

  isSlideNew (newSlideId) {
    return newSlideId && !this.props.currentSlides.some(slide => slide.slideId === newSlideId)
  }

  isRoutePathForNewSlide (routePath) {
    const navSlideId = this.getSlideIdFromRoutePath(routePath)
    return navSlideId ? this.isSlideNew(navSlideId) : false
  }

  onUnload (event) {
    if (this.state.changed) {
      // Chrome won't display this message, setting event.returnValue trigger the unload behaviour
      event.returnValue = I18n.t('youHaveUnsavedChangesOnThisCourse', trOpt)
    }
  }

  // Do not make this async as the promise returned is treated as truth by react-router's Prompt component
  onPrompt (nextLocation) {
    if (this.state.changed) {
      if (this.isRoutePathForNewSlide(nextLocation.pathname)) {
        this.saveOnPromptForNewSlide(nextLocation.pathname)
      } else {
        this.showUnloadSaveConfirm(nextLocation.pathname)
      }
      return false
    }
    return true
  }

  async handleUpdateClick () {
    return this.save()
  }

  handleSlideEditClick (slide) {
    const editPath = generatePath(routes.BUILDER_EDIT_SLIDE_LOCALE, { course_id: this.courseId, slide_id: slide.slideId, locale: this.props.locale })
    this.props.history.push(editPath)
  }

  handlePreviewClick () {
    window.open(generatePath(routes.BUILDER_PREVIEW_COURSE_LOCALE, { course_id: this.courseId, locale: this.props.locale }), '_blank')
  }

  handleSlidePreviewClick (slide) {
    window.open(generatePath(routes.BUILDER_PREVIEW_SLIDE_LOCALE, { course_id: this.courseId, slide_id: slide.slideId, locale: this.props.locale }), '_blank')
  }

  handleOpenCloneSlideClick () {
    if (this.cloneSlidesModalRef.current) {
      this.cloneSlidesModalRef.current.open()
    }
  }

  showSlidePreviewOption (slide) {
    return !slide.newSlide
  }

  componentDidMount () {
    window.addEventListener('beforeunload', this.onUnload)
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  render () {
    const { locale, onLocaleChange, slides, sourceLocale } = this.props
    const { changed, saveConfirmOpen } = this.state
    const editPath = generatePath(routes.BUILDER_EDIT, { course_id: this.courseId })

    return (
      <>
        <Prompt
          when={changed}
          message={this.onPrompt}
        />
        <SaveConfirmModal
          visible={saveConfirmOpen} item='slide'
          onYes={this.handleSaveConfirmModalYes}
          onNo={this.handleSaveConfirmModalNo}
          onCancel={this.handleSaveConfirmModalCancel}
        />
        <CloneSourceLocaleCourseSlidesModal
          ref={this.cloneSlidesModalRef}
          courseName={this.courseName}
          courseSourceLocale={sourceLocale}
          courseId={this.courseId}
          refetchSlides={this.props.refetchSlides}
          {...{ locale }}
        />
        <CourseContentEditorLayout>
          <CourseContentEditorHeader>
            <CourseContentEditorHeaderWrap>
              <CourseTitle>
                {
                  this.courseName && (
                    <>
                      <ChangedIndicator changed={this.changed} tooltip={I18n.t('changedIndicatorTooltip', trOpt)} />
                      {I18n.t('common.uLearn')} - {I18n.t('common.editItemName', { name: this.courseName })}
                    </>
                  )
                }
              </CourseTitle>
              <CourseHeaderLinks>
                <CourseHeaderLink to={editPath}>
                  <Button type='primary' icon='edit'>{I18n.t('courses.common.editCourse')}</Button>
                </CourseHeaderLink>
                <Button type='primary' icon='eye' onClick={this.handlePreviewClick}>{I18n.t('courses.previewCourse')}</Button>
                <CourseHeaderLink to={routes.BUILDER}>
                  <Button type='primary'>{I18n.t('editCourse.common.backToCourseBuilder')}</Button>
                </CourseHeaderLink>
              </CourseHeaderLinks>
            </CourseContentEditorHeaderWrap>
            <CourseContentControls>
              <CourseContentEditorPanel align='left'>
                <Dropdown
                  overlay={
                    <Menu>
                      {
                        Object.keys(CONTENT_TYPE_NAMES).map(type => (
                          <Menu.Item key={type}>
                            <AddSlideMenuItem add={this.addSlide} type={type} />
                          </Menu.Item>
                        ))
                      }
                    </Menu>
                  }
                  placement='topLeft'
                  trigger={['click']}
                >
                  <Button type='primary' icon='plus'>{I18n.t('addContent', trOpt)}</Button>
                </Dropdown>
                <LanguageDropdown value={locale} onChange={onLocaleChange} />
              </CourseContentEditorPanel>
              <CourseContentEditorPanel align='right'>
                {locale && sourceLocale && (
                  <Button type='primary' icon='copy' onClick={this.handleOpenCloneSlideClick}>{I18n.t('cloneSourceSlides', trOpt)}</Button>
                )}
                <Button type='primary' icon='save' onClick={this.handleUpdateClick}>{I18n.t('courses.common.updateCourse')}</Button>
              </CourseContentEditorPanel>
            </CourseContentControls>
          </CourseContentEditorHeader>
          <CourseContentEditorContent ref={this.list}>
            <DragAndDropList
              courseId={this.courseId}
              list={slides}
              itemComponent={CourseContentEditorSlide}
              showDuplicate
              updateList={this.updateSlides}
              onEditClick={this.handleSlideEditClick}
              showPreview
              showTranslate={false}
              onPreviewClick={this.handleSlidePreviewClick}
              locale={locale}
              showAdd={false}
              itemMargin='10px'
              modifyDuplicateItem={this.modifyDuplicateItem}
              showItemPreviewOption={this.showSlidePreviewOption}
            />
          </CourseContentEditorContent>
        </CourseContentEditorLayout>
      </>
    )
  }
}

CourseContentEditor.propTypes = {
  courseId: PropTypes.string,
  courseName: PropTypes.string,
  sourceLocale: PropTypes.string,
  locale: PropTypes.string,
  onLocaleChange: PropTypes.func,
  slides: PropTypes.arrayOf(PropTypes.object),
  currentSlides: PropTypes.arrayOf(PropTypes.object),
  setSlides: PropTypes.func,
  saveSlides: PropTypes.func,
  refetchSlides: PropTypes.func
}

CourseContentEditor.defaultProps = {
  courseId: null,
  courseName: null,
  sourceLocale: null,
  locale: null,
  onLocaleChange: () => {},
  slides: [],
  currentSlides: [],
  setSlides: () => {},
  saveSlides: () => {},
  refetchSlides: () => {}
}

export default withRouter(CourseContentEditor)
