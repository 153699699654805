import { createLocaleCopyObjectFromKeys, createLocaleCopyObjectFromText } from '../helpers/locale'

export const DEFAULT_EMAIL_SETTINGS = {
  default: {
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'noreply'
      },
      subdomain: {
        en: ''
      }
    },
    themeLogo: {
      en: 'https://usecure.s3-eu-west-1.amazonaws.com/images/usecure-logo-blue-email.png'
    },
    themePrimaryColor: {
      en: '#0f75bc'
    },
    themeSecondaryColor: {
      en: '#ffffff'
    },
    omitEmailHeader: false
  },
  course: {
    sender: {
      name: createLocaleCopyObjectFromKeys('settings.courseEmail.defaultValues.senderName'),
      prefix: {
        en: 'userawareness'
      },
      subdomain: {
        en: ''
      }
    },
    inviteSubject: createLocaleCopyObjectFromKeys('settings.courseEmail.defaultValues.inviteSubject'),
    simSubject: createLocaleCopyObjectFromKeys('settings.courseEmail.defaultValues.simSubject'),
    reminderSubject: createLocaleCopyObjectFromKeys('settings.courseEmail.defaultValues.reminderSubject'),
    useDefaultTheme: true
  },
  dummySimulationEmails: {
    sender: {
      name: createLocaleCopyObjectFromKeys('settings.dummySimulationEmail.defaultValues.senderName'),
      prefix: {
        en: 'phishing-test'
      },
      subdomain: {
        en: ''
      }
    }
  },
  gapAnalysis: {
    sender: {
      name: createLocaleCopyObjectFromKeys('settings.courseEmail.defaultValues.senderName'),
      prefix: {
        en: 'userawareness'
      },
      subdomain: {
        en: ''
      }
    },
    inviteSubject: createLocaleCopyObjectFromKeys('settings.gapAnalysisEmail.defaultValues.inviteSubject'),
    reminderSubject: createLocaleCopyObjectFromKeys('settings.gapAnalysisEmail.defaultValues.reminderSubject'),
    useDefaultTheme: true
  },
  googleSyncReport: {
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'noreply'
      },
      subdomain: {
        en: ''
      }
    },
    subject: createLocaleCopyObjectFromKeys('settings.googleSyncEmail.defaultValues.subject'),
    useDefaultTheme: true
  },
  office365Report: {
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'noreply'
      },
      subdomain: {
        en: ''
      }
    },
    subject: createLocaleCopyObjectFromKeys('settings.office365Email.defaultValues.subject'),
    useDefaultTheme: true
  },
  policy: {
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'policies'
      },
      subdomain: {
        en: ''
      }
    },
    newSubject: createLocaleCopyObjectFromKeys('settings.policyEmail.defaultValues.newSubject'),
    newSubjectMultiple: createLocaleCopyObjectFromKeys('settings.policyEmail.defaultValues.newSubjectMultiple'),
    reminderSubject: createLocaleCopyObjectFromKeys('settings.policyEmail.defaultValues.reminderSubject'),
    reminderSubjectMultiple: createLocaleCopyObjectFromKeys('settings.policyEmail.defaultValues.reminderSubjectMultiple'),
    useDefaultTheme: true
  },
  weeklySummary: {
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'noreply'
      },
      subdomain: {
        en: ''
      }
    },
    adminSubject: createLocaleCopyObjectFromKeys('settings.weeklySummaryEmail.defaultValues.adminSubject'),
    managerSubject: createLocaleCopyObjectFromKeys('settings.weeklySummaryEmail.defaultValues.managerSubject'),
    learnerManagerSubject: createLocaleCopyObjectFromKeys('settings.weeklySummaryEmail.defaultValues.learnerManagerSubject'),
    useDefaultTheme: true
  },
  riskReportParent: {
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'riskreport'
      },
      subdomain: {
        en: ''
      }
    },
    subject: createLocaleCopyObjectFromKeys('settings.riskReportParentEmail.defaultValues.subject'),
    useDefaultTheme: true
  },
  riskReport: {
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'riskreport'
      },
      subdomain: {
        en: ''
      }
    },
    domainCompleteSubject: createLocaleCopyObjectFromKeys('settings.riskReportEmail.defaultValues.domainCompleteSubject'),
    uBreachCompleteSubject: createLocaleCopyObjectFromKeys('settings.riskReportEmail.defaultValues.uBreachCompleteSubject'),
    uPhishCompleteSubject: createLocaleCopyObjectFromKeys('settings.riskReportEmail.defaultValues.uPhishCompleteSubject'),
    useDefaultTheme: true
  },
  reports: {
    subject: createLocaleCopyObjectFromKeys('settings.reportEmail.defaultValues.subject'),
    mspSubject: createLocaleCopyObjectFromKeys('settings.reportEmail.defaultValues.mspSubject'),
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'noreply'
      },
      subdomain: {
        en: ''
      }
    },
    useDefaultTheme: true
  },
  uBreachPro: {
    adminSubject: createLocaleCopyObjectFromKeys('settings.breachAlertEmail.defaultValues.adminSubject'),
    learnerSubject: createLocaleCopyObjectFromKeys('settings.breachAlertEmail.defaultValues.learnerSubject'),
    mspSubject: createLocaleCopyObjectFromKeys('settings.breachAlertEmail.defaultValues.mspSubject'),
    sender: {
      name: createLocaleCopyObjectFromText('usecure'),
      prefix: {
        en: 'noreply'
      },
      subdomain: {
        en: ''
      }
    },
    useDefaultTheme: true
  }
}

export const SETTING_DELETE_VALUE = '%DELETE%'
