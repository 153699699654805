import React, { useState, useImperativeHandle, useCallback, useRef } from 'react'
import I18n from 'i18n-js'
import { Modal, message } from 'antd'

import MutationForm from '../MutationForm/MutationForm'
import { CREATE_BREACH_DOMAIN_SCAN_REPORT } from '../Queries/Reports'
import { validateEmail, validateDomain } from '../../helpers'
import useTheme from '../../hooks/useTheme'

const trOpt = { scope: 'modals.createDomainScanReportModal' }

const CreateDomainScanReportModal = React.forwardRef(({ company }, ref) => {
  const theme = useTheme()
  const [visible, setVisible] = useState(false)

  const form = useRef(null)

  useImperativeHandle(ref, () => ({
    open: async () => {
      setVisible(true)
    }
  }), [])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(async () => {
    setVisible(false)
    if (form && form.current) {
      await form.current.reset()
    }
  }, [form])

  const onSuccess = useCallback((result) => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  return (
    <Modal
      visible={visible}
      title={I18n.t('title', trOpt)}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
    >
      <MutationForm
        ref={form}
        onSuccess={onSuccess}
        mutation={CREATE_BREACH_DOMAIN_SCAN_REPORT}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('submitLabel', trOpt)}
        submitIcon='save'
        skipResetFieldsOnSubmit
        fields={[{
          id: 'name',
          label: I18n.t('companyName', trOpt)
        }, {
          id: 'domain',
          label: I18n.t('companyDomain', trOpt),
          validate: (field, value, errors) => {
            if (!validateDomain(value)) {
              errors.push(I18n.t('common.invalidDomainError'))
            }
          },
          required: true
        }, {
          id: 'logo',
          type: 'image',
          label: I18n.t('companyLogo', trOpt),
          required: true,
          defaultValue: theme.appThemeLogo
        },
        {
          id: 'documentLogo',
          type: 'image',
          label: I18n.t('documentLogo', trOpt),
          required: true,
          defaultValue: theme.reportDocumentLogo
        },
        {
          id: 'targetEmails',
          label: I18n.t('targetEmails', trOpt),
          type: 'textTags',
          validateTag: value => {
            if (!validateEmail(value)) {
              return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
            }
          },
          defaultValue: [],
          required: false,
          extra: I18n.t('targetEmailsExtra', trOpt),
          maxValues: 5
        }]}
      />
    </Modal>
  )
})

export default CreateDomainScanReportModal
