import React, { useState, useImperativeHandle, useCallback } from 'react'
import I18n from 'i18n-js'
import { Modal, Button, DatePicker, message } from 'antd'
import moment from 'moment'

import { openReport } from '../../helpers/reports'
import { Column } from '../EndUserPortal/GridFlexiLayout'

const { RangePicker } = DatePicker

const trOpt = { scope: 'modals.openReportModal' }

const OpenReportModal = React.forwardRef(({ companyId, useCompanyId = false, reportStartDate, reportType }, ref) => {
  const [visible, setVisible] = useState(false)
  const [period, setPeriod] = useState(null)

  useImperativeHandle(ref, () => ({
    open: () => {
      const oneMonthAgo = moment().subtract(1, 'month')
      setPeriod([moment(oneMonthAgo).startOf('month'), moment(oneMonthAgo).endOf('month')]) // Default to last month
      setVisible(true)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    setPeriod(null)
  }, [])

  const onChange = useCallback((dates) => setPeriod(dates), [])

  const onCreateClick = useCallback(() => {
    const [fromDateMo, toDateMo] = period || []
    if (!(moment.isMoment(fromDateMo) && moment.isMoment(toDateMo))) {
      message.error(I18n.t('noPeriodError', trOpt))
      return
    }
    const reportParams = { fromDate: fromDateMo.format('YYYY-MM-DD'), toDate: toDateMo.format('YYYY-MM-DD'), reportType }
    if (useCompanyId && companyId) {
      reportParams.companyId = companyId
    }
    openReport(reportParams)
  }, [period, companyId, useCompanyId, reportType])

  return (
    <Modal
      visible={visible}
      title={I18n.t(`${reportType}.title`, trOpt)}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={[
        <Button key='back' onClick={closeModal}>
          {I18n.t('common.cancel')}
        </Button>,
        <Button key='submit' type='primary' onClick={onCreateClick}>
          {I18n.t('reports.common.viewReport')}
        </Button>
      ]}
    >
      <Column>
        <div>{I18n.t('reports.common.reportPeriod')}</div>
        <RangePicker
          value={period}
          separator='-'
          onChange={onChange}
          format={['l', 'YYYY-MM-DD']}
          disabledDate={current => current && !current.isBetween(reportStartDate, moment().endOf('month'), 'day', '[]')}
        />
      </Column>
    </Modal>
  )
})

export default OpenReportModal
