import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Alert, Button, Card, Form } from 'antd'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import I18n from 'i18n-js'

import { LoadingBlock, ErrorAlerts, BannerMessage } from '../components/common'
import { CREATE_STRIPE_SETUP_INTENT, GET_PAYMENT_DETAILS, CREATE_STRIPE_CUSTOMER_PORTAL_SESSION } from '../components/Queries/Companies'
import { GET_ME } from '../components/Queries/Users'
import { STRIPE_APIKEY, CURRENCIES_INFO } from '../constants/billing'
import { renderToString, showErrors } from '../helpers'
import { withRefreshSessionState } from '../hocs'
import { permissions } from '../constants/permissions'
import { useHasSessionPermission } from '../hooks'

const stripe = loadStripe(STRIPE_APIKEY)

const trOpt = { scope: 'paymentSettings' }

const PaymentSettingsWrap = styled.div`
  position: relative;
`

const PaymentSettingsSection = styled.div`
  margin-bottom: 30px;
`

const CardDetailsPlaceholder = styled.div`
  height: 75px;
`

const CardDetailsAlert = styled(Alert)`
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`
const CardDetailsFormContainer = styled.div`
  width: 50%;
  max-width: 460px;
`

const CardDetailsFormButtons = styled.div`
  .ant-btn {
    margin-right: 10px;
  }
`

const AccessStripePortalSection = ({ hasBillingUpdatePermission }) => {
  const [customerPortalSession] = useMutation(CREATE_STRIPE_CUSTOMER_PORTAL_SESSION, {
    refetchQueries: [
      { query: GET_ME }
    ]
  })

  const handleStripePortalClick = async (e) => {
    e.preventDefault()

    try {
      const returnUrl = `${window.location.origin}/account/payment`
      const { data } = await customerPortalSession({
        variables: { returnUrl }
      })
      const { createStripeCustomerPortalSession } = data
      window.open(createStripeCustomerPortalSession.url, '_blank').focus()
    } catch (error) {
      console.error(error)
      showErrors(e, I18n.t('accessStripeCustomerPortalErrorMessage', { ...trOpt }))
    }
  }

  return (
    <>
      {hasBillingUpdatePermission && (
        <div>
          <h4>{I18n.t('accessStripeCustomerPortal', { ...trOpt })}</h4>
          <p>{I18n.t('accessStripeCustomerPortalDescription', { ...trOpt })}</p>
          <Button type='primary' onClick={handleStripePortalClick}>{I18n.t('accessStripeCustomerPortalButtonLabel', { ...trOpt })}</Button>
        </div>
      )}
    </>
  )
}

const CardDetailsForm = withRefreshSessionState(({ currency, paymentMethodState, handleCloseCardForm, refreshSessionState, hasBillingUpdatePermission }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)

  const [executeAddPayment] = useMutation(CREATE_STRIPE_SETUP_INTENT, {
    refetchQueries: [
      { query: GET_ME },
      { query: GET_PAYMENT_DETAILS }
    ]
  })

  useEffect(() => {
    if (currency && elements) {
      elements.update({
        currency
      })
    }
  }, [currency, elements])

  const isUpdate = paymentMethodState === 'update'
  const allowSubmit = !isLoading && hasBillingUpdatePermission

  const handleSubmit = async (e) => {
    e.preventDefault()

    setErrorMessage(undefined)
    setIsLoading(true)

    const submitResult = await elements.submit()

    if (submitResult.error) {
      setIsLoading(false)
      console.error(submitResult.error)
      return
    }

    const confirmationResult = await stripe.createConfirmationToken({ elements })

    if (confirmationResult.error) {
      setErrorMessage(confirmationResult.error.message)
      setIsLoading(false)
      console.error(confirmationResult.error)
      return
    }

    let intent

    try {
      intent = await executeAddPayment({
        variables: { confirmationTokenId: confirmationResult.confirmationToken.id }
      })
    } catch (error) {
      setErrorMessage(error.message)
      setIsLoading(false)
      console.error(error)
      return
    }

    const { createStripeSetupIntent } = intent.data

    if (createStripeSetupIntent.status === 'requires_action') {
      const { error } = await stripe.handleNextAction({
        clientSecret: createStripeSetupIntent.clientSecret
      })

      if (error) {
        setErrorMessage(error.message)
        setIsLoading(false)
        console.error(error)
        return
      }
    }

    await refreshSessionState()
    handleCloseCardForm(true)
  }
  return (
    <CardDetailsFormContainer>
      <Form onSubmit={handleSubmit}>
        <Form.Item validateStatus={errorMessage ? 'error' : undefined} help={errorMessage}>
          <PaymentElement onChange={() => setErrorMessage(undefined)} />
        </Form.Item>
        <CardDetailsFormButtons>
          <Button
            type='primary'
            loading={!allowSubmit}
            htmlType='submit'
            disabled={!allowSubmit}
          >
            {I18n.t(isUpdate ? 'updateCard' : 'saveCard', trOpt)}
          </Button>

          {isUpdate && (
            <Button
              type='danger'
              disabled={!allowSubmit}
              onClick={() => handleCloseCardForm()}
            >
              {I18n.t('common.cancel')}
            </Button>
          )}
        </CardDetailsFormButtons>
      </Form>
    </CardDetailsFormContainer>
  )
})

const CardDetails = ({ queryLoading, currency, defaultPaymentMethod, reloadPaymentMethod, hasBillingUpdatePermission }) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const handleCloseCardForm = (didUpdatePaymentMethod) => {
    setIsUpdating(false)

    if (didUpdatePaymentMethod) {
      reloadPaymentMethod()
    }
  }

  const paymentMethodState = (() => {
    if (isUpdating) {
      return 'update'
    } else if (defaultPaymentMethod) {
      return 'view'
    } else {
      return 'new'
    }
  })()

  if (queryLoading) {
    return <CardDetailsPlaceholder />
  }

  if (paymentMethodState === 'view') {
    const { brand, last4, expiryDate } = defaultPaymentMethod

    return (
      <div>
        <p>{I18n.t('endingIn', { ...trOpt, brand: brand.toUpperCase(), last4 })}</p>
        <p>{I18n.t('expires', trOpt)} {expiryDate}</p>
        <Button onClick={() => setIsUpdating(true)} disabled={!hasBillingUpdatePermission}>{I18n.t('common.update')}</Button>
      </div>
    )
  }

  return (
    <CardDetailsForm {...{ currency, paymentMethodState, handleCloseCardForm, hasBillingUpdatePermission }} />
  )
}

const PaymentSettings = () => {
  const { loading: queryLoading, error, data: { paymentDetails: { defaultPaymentMethod, currency } = {} } = {}, refetch } = useQuery(GET_PAYMENT_DETAILS)
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasBillingUpdatePermission = hasAllSessionPermissions([permissions.BILLING_UPDATE])

  const reloadPaymentMethod = () => {
    refetch()
  }

  let willExpire = false
  let hasExpired = false
  if (defaultPaymentMethod?.expiryDate) {
    const now = moment()
    const expiry = moment(defaultPaymentMethod.expiryDate, 'MM/YYYY')
    willExpire = now.isSame(expiry, 'month')
    hasExpired = now.isAfter(expiry, 'month')
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        mode: 'setup',
        currency: 'gbp',
        paymentMethodTypes: ['card'],
        appearance: {
          theme: 'stripe',
          variables: {
            fontFamily: '"Hind Guntur", sans-serif, Georgia,Cambria, "Times New Roman", Time',
            fontSizeBase: '14px',
            colorDanger: 'rgb(237, 32, 36)'
          }
        }
      }}
    >
      <PaymentSettingsWrap>
        <LoadingBlock loading={queryLoading} fullScreen={false} />

        {!hasBillingUpdatePermission && <BannerMessage type='info' message={I18n.t('settings.settingsForm.readOnlyBanner')} />}

        {hasExpired && (
          <CardDetailsAlert
            message={I18n.t('yourCardHasExpired', trOpt)}
            type='error'
            showIcon
            banner
          />
        )}

        {willExpire && (
          <CardDetailsAlert
            message={I18n.t('yourCardWillExpireThisMonth', trOpt)}
            type='warning'
            showIcon
          />
        )}

        <Card>
          <h1>{I18n.t('paymentSettings', trOpt)}</h1>
          {error ? (
            <ErrorAlerts {...{ error }} defaultError={I18n.t('yourPaymentSettingsCouldNotBeLoaded', trOpt)} />
          ) : (
            <>
              <PaymentSettingsSection>
                <CardDetails {...{ queryLoading, currency, defaultPaymentMethod, reloadPaymentMethod, hasBillingUpdatePermission }} />
              </PaymentSettingsSection>

              <PaymentSettingsSection>
                <h4>{I18n.t('uService.currency')}</h4>
                {currency ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: I18n.t('yourBillingCurrencyIs', {
                        ...trOpt,
                        label: renderToString(<strong>{CURRENCIES_INFO[currency].label}</strong>)
                      })
                    }}
                  />
                ) : (
                  <p>{I18n.t('youDoNotHaveACurrencySet', trOpt)}</p>
                )}
                <p>{I18n.t('ifYouWishToChangeYourBillingCurrency', trOpt)}</p>
              </PaymentSettingsSection>
            </>
          )}
          <AccessStripePortalSection {...{ hasBillingUpdatePermission }} />
        </Card>
      </PaymentSettingsWrap>
    </Elements>
  )
}

export default PaymentSettings
