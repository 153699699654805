import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Alert, Badge, Button, Input, Modal, Popover, message } from 'antd'
import styled, { withTheme, css } from 'styled-components'
import { useApolloClient } from '@apollo/react-hooks'
import CSVReader from 'react-csv-reader'
import I18n from 'i18n-js'
import _isEmpty from 'lodash/isEmpty'
import _uniq from 'lodash/uniq'

import { LoadingBlock } from '../common'
import { showErrors } from '../../helpers'
import RiskScoreIndicator from '../Learners/RiskScoreIndicator'
import LearnersView from '../Learners/LearnersView'
import TooltipIcon from '../common/TooltipIcon'
import { GET_ENROL_COURSE_LEARNER, VALIDATE_ENROL_COURSE_UPLOAD } from '../Queries/CourseResults'
import { ENROL_ON_COURSE, ENROL_ON_GAP_ANALYSIS } from '../Queries/Courses'

const trOpt = { scope: 'modals.bulkEnrolment' }

const ViewModal = styled(Modal)`
  max-width: 1200px;
  top: 20px;

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-content {
    height: 100%;
  }
`

const ContentContainer = styled.div`
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;

  p {
    margin-bottom: 0;
    margin-top: 3px;
  }
`

const RecipientLine = styled.span`
  display: block;
  font-size: ${({ sub }) => sub ? '12px' : 'inherit'};
`

const InputContainer = styled.div`
  label {
    display: block;
  }

  p {
    margin-top: 5px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .ant-btn {
    margin-left: 10px;
  }
`

const ErrorList = styled.div`
  ${({ modal }) => modal ? css`
  max-height: 500px;
  overflow: auto;
  ` : ''}

  &> .ant-alert {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
`

const ErrorButtonBadge = styled(Badge)`
  margin-right: 5px;
  .ant-scroll-number-only {
    position: relative;
    top: -1px;
  }
`

const DeleteLearnerButton = ({ id, removeLearner = () => {} }) => {
  const onClick = useCallback(() => removeLearner(id), [removeLearner, id])

  return <Button type='danger' icon='delete' onClick={onClick} />
}

const LearnerSearchContainer = styled.div`
  display: flex;

  .ant-btn {
    margin-left: 5px;
  }
`

const LearnerSearchInputContainer = styled.div`
  max-width: 350px;

  .ant-form-explain {
    margin-top: 5px;
  }
`

const StatusTitleCell = styled.div`
  width: 60px;

  .anticon {
    color: #bfbfbf;
    font-size: 12px;
    margin-left: 5px;
  }

  h4 {
    font-size: 3px;
  }
`

const _StatusText = withTheme(({ className }) => (
  <div {...{ className }}>
    <h3>{I18n.t('title', trOpt)}</h3>
    <h4>
      <RiskScoreIndicator text={I18n.t('newEnrolment.label', trOpt)} value='green' />
    </h4>
    <p>{I18n.t('newEnrolment.description', trOpt)}</p>
    <h4>
      <RiskScoreIndicator text={I18n.t('reminder.label', trOpt)} value='amber' />
    </h4>
    <p>{I18n.t('reminder.description', trOpt)}</p>
    <h4>
      <RiskScoreIndicator text={I18n.t('reEnrolment.label', trOpt)} value='red' />
    </h4>
    <p>{I18n.t('reEnrolment.description', trOpt)}</p>
  </div>
))

const StatusText = styled(_StatusText)`
  display: inline-block;
  width: 600px;

  h3 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  h4 {
    font-size: 14px;
    margin-bottom: 0;
  }
  p {
    font-family: ${props => props.theme.baseFont};
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const LearnerSearchInputPanel = ({
  courseId,
  addLearner = () => {}
}) => {
  const client = useApolloClient()
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = async (e) => {
    e.preventDefault()

    const email = value ? value.trim().toLowerCase() : null
    const errorMessage = I18n.t('noUserFoundError', { ...trOpt, value: value.trim() })

    try {
      const { data: { learner } } = await client.query({
        query: GET_ENROL_COURSE_LEARNER,
        variables: {
          courseId,
          email
        },
        fetchPolicy: 'no-cache'
      })

      if (learner) {
        setValue(null)
        setError(null)
        addLearner(learner)
        message.success(I18n.t('addSuccessMessage', { ...trOpt, name: learner.name }))
      } else {
        message.error(errorMessage)
      }
    } catch (e) {
      showErrors(e, errorMessage, {
        processor: function () {
          // Always include default message if there are errors present
          if (!_isEmpty(this.errors)) {
            this.errors.unshift(errorMessage)
          }
        }
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <label>{I18n.t('common.addUser')}</label>
      <LearnerSearchContainer>
        <LearnerSearchInputContainer className={error ? 'has-error' : undefined}>
          <Input
            type='email'
            placeholder={I18n.t('userEmail', trOpt)}
            required
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={!courseId || loading}
          />
          <div className='ant-form-explain'>{error}</div>
        </LearnerSearchInputContainer>
        <Button
          icon='plus'
          type='primary'
          htmlType='submit'
          loading={loading}
          disabled={!courseId || loading}
        >
          {I18n.t('common.add')}
        </Button>

      </LearnerSearchContainer>
    </form>
  )
}

const LearnerSearchInputPanelStyled = styled(LearnerSearchInputPanel)`
  align-self: flex-start;
`

const StatusTitle = () => (
  <StatusTitleCell>
    <span>{I18n.t('common.status')}</span>
    <Popover content={<StatusText status='all' />}>
      <span><TooltipIcon /></span>
    </Popover>
  </StatusTitleCell>
)

const EnrolCourseUploadModal = ({
  courses,
  courseId,
  visible = false,
  setVisible = () => {}
}) => {
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState(false)
  const [learners, setLearners] = useState([])
  const [learnerErrors, setLearnerErrors] = useState([])
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [containerHeight, setContainerHeight] = useState('auto')

  const uploader = useRef(null)

  const course = courses.find(({ id }) => id === courseId)

  const clearFileInput = () => {
    if (uploader.current) {
      const fileInput = uploader.current.querySelector('#csvUpload')
      if (fileInput) {
        fileInput.value = ''
      }
    }
  }

  const removeLearner = (id) => {
    setLearners(learners => learners.filter(learner => learner.id !== id))
  }

  const reset = () => {
    setLearners([])
    setLearnerErrors([])
    clearFileInput()
  }

  const onUploadClick = () => {
    Modal.confirm({
      title: I18n.t('uploadConfirmPrompt', { ...trOpt, count: learners.length }),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      async onOk () {
        return enrolLearners()
      }
    })
  }

  const addLearnerFromSearch = (learner) => {
    if (learners.find(({ id }) => id === learner.id) !== undefined) return
    setLearners(learners => [...learners, learner])
  }

  const showErrorModal = (learnerErrors) => {
    Modal.error({
      title: I18n.t('uploadIssues', trOpt),
      content: (
        <ErrorList modal>
          {learnerErrors.map((error, index) => <Alert key={index} type='error' showIcon message={error} />)}
        </ErrorList>
      ),
      width: 800
    })
  }

  const enrolLearners = async () => {
    const isGapAnalysis = course.difficulty === 1
    const courseName = course.name
    const learnerIds = _uniq(learners.map(({ id }) => id))
    const learnerCount = learnerIds.length
    const noneEnrolledErrorMessage = I18n.t('noneEnrolled', { ...trOpt, count: learnerCount, courseName })

    setIsLoading(true)

    try {
      let success, completed, completedCount

      if (isGapAnalysis) {
        ({ data: { enrollLearnersOnGapAnalysis: { success, completed } } } = await client.mutate({
          mutation: ENROL_ON_GAP_ANALYSIS,
          variables: { learnerIds }
        }))
        completedCount = completed.length
      } else {
        ({ data: { enrollLearnersOnCourses: { success, completed } } } = await client.mutate({
          mutation: ENROL_ON_COURSE,
          variables: { courseIds: [courseId], learnerIds }
        }))
        completedCount = completed.find(({ id }) => id === courseId)?.learners.length || 0
      }

      if (success) {
        setVisible(false)

        if (completedCount === learnerCount) {
          message.success(I18n.t('allEnrolled', { ...trOpt, count: completedCount, courseName }))
        } else if (completedCount === 0) {
          message.error(noneEnrolledErrorMessage)
        } else {
          message.warning(I18n.t('partiallyEnrolled', { ...trOpt, completedCount, count: learnerCount, courseName }))
        }
      } else {
        message.error(noneEnrolledErrorMessage)
      }
    } catch (e) {
      showErrors(e, noneEnrolledErrorMessage, {
        processor: function () {
          // Always include default message if there are errors present
          if (!_isEmpty(this.errors)) {
            this.errors.unshift(noneEnrolledErrorMessage)
          }
        }
      })
    } finally {
      setIsLoading(false)
    }
  }

  const uploadCsvData = async (data) => {
    try {
      setIsLoading(true)

      const { data: { validateEnrolCourseUpload: { learners, errors } } } = await client.query({
        query: VALIDATE_ENROL_COURSE_UPLOAD,
        variables: {
          courseId,
          data
        },
        fetchPolicy: 'no-cache'
      })

      setLearners(learners)
      setLearnerErrors(errors)

      if (errors.length > 0) {
        showErrorModal(errors)
      }
    } catch (error) {
      clearFileInput()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const adjustContainerHeight = () => {
      setContainerHeight(window.innerHeight - 123)
    }

    adjustContainerHeight()
    window.addEventListener('resize', adjustContainerHeight)

    return () => {
      window.removeEventListener('resize', adjustContainerHeight)
    }
  }, [])

  const columns = [
    {
      title: I18n.t('common.recipient'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      render (name, learner, index) {
        return (
          <div>
            <RecipientLine>{name}</RecipientLine>
            <RecipientLine sub>{learner.email || learner.learnerId}</RecipientLine>
          </div>
        )
      }
    },
    {
      title: StatusTitle,
      dataIndex: 'enrollmentStatus',
      key: 'enrollmentStatus',
      render (statusId, { courseResults }) {
        const latestResult = courseResults
          .filter((result) => result.courseId === courseId)
          .sort((a, b) => {
            if (new Date(a.enrollDate) > new Date(b.enrollDate)) {
              return 1
            } else if (new Date(a.enrollDate) < new Date(b.enrollDate)) {
              return -1
            } else {
              return 0
            }
          })[0]

        const { text, value } = (() => {
          if (latestResult === undefined) {
            return { text: 'newEnrolment', value: 'green' }
          } else if (latestResult.finishDate === null) {
            return { text: 'reminder', value: 'amber' }
          } else {
            return { text: 'reEnrolment', value: 'red' }
          }
        })()

        return <RiskScoreIndicator text={I18n.t(`${text}.label`, trOpt)} value={value} />
      }
    },
    {
      title: I18n.t('common.delete'),
      key: 'delete',
      render (text, learner) {
        return <DeleteLearnerButton id={learner.id} removeLearner={removeLearner} />
      }
    }
  ]

  if (course === undefined) return null

  return (
    <ViewModal
      title={`${I18n.t('title', trOpt)} - ${course.name}`}
      width='90%'
      bodyStyle={{ paddingTop: 5 }}
      visible={visible}
      onCancel={() => setVisible(false)}
      afterClose={() => reset()}
      footer={null}
      destroyOnClose
      keyboard
    >
      {isLoading && <LoadingBlock fullScreen={false} loading />}

      <ContentContainer style={{ maxHeight: containerHeight }}>
        <LearnersView
          showActions={false}
          showGroups={false}
          useSelection={false}
          size='small'
          {...{
            learners,
            columns,
            searchFilterText,
            updateSearchFilterText
          }}
          panelLeft={[
            <InputContainer key='learnerCsvUpload' ref={uploader}>
              <label>{I18n.t('addUsersViaCSVUpload', trOpt)}</label>
              <CSVReader
                onFileLoaded={(data) => uploadCsvData(data)}
                inputId='csvUpload'
                disabled={courseId === null}
              />
              <p className='base-font'>{I18n.t('common.downloadTemplateCopy')}: <a href='/upload-template/upload-bulk-enrollment.csv' download>{I18n.t('common.downloadTemplate')}</a></p>
            </InputContainer>,
            <LearnerSearchInputPanelStyled
              key='learnerLookup'
              {...{
                courseId,
                addLearner: addLearnerFromSearch
              }}
            />
          ]}
          panelRight={['search']}
        />

        <ButtonContainer>
          {learnerErrors.length > 0 && <Button ghost type='danger' onClick={() => showErrorModal(learnerErrors)}><ErrorButtonBadge count={learnerErrors.length} overflowCount={Infinity} style={{ backgroundColor: 'transparent', color: '#ff4d4f', border: 'solid 1px #ff4d4f' }} /> {I18n.t('showIssues', trOpt)}</Button>}
          <Button type='danger' icon='delete' onClick={reset} disabled={_isEmpty(learners)}>{I18n.t('common.reset')}</Button>
          <Button type='primary' icon='file-done' onClick={onUploadClick} disabled={_isEmpty(learners)}>{I18n.t('enrolUsers', trOpt)}</Button>
        </ButtonContainer>
      </ContentContainer>
    </ViewModal>
  )
}

export default withTheme(EnrolCourseUploadModal)
