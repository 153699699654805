import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { syncOffice365 } from '../../helpers'
import { connect } from '../../hocs'
import { getSettings } from '../../state/selectors/settings'
import {
  AuthModal, AuthModalWrap, AuthModalTitle, AuthModalContent, AuthModalFooter,
  AuthRadioOption
} from '../Sync/SelectAuthFlow'

const trOpt = { scope: 'modals.microsoft365SyncSelectAuthFlowModal' }
const USE_DELEGATED_AUTH = window.__USECURE_CONFIG__.REACT_APP_OFFICE_365_DELEGATED_AUTH === 'true'

const Microsoft365SyncSelectAuthFlowModal = React.forwardRef(({ settings }, ref) => {
  const [visible, setVisible] = useState(false)
  const [authType, setAuthType] = useState('admin')
  const [existingAuthType, setExistingAuthType] = useState(null)

  useImperativeHandle(ref, () => ({
    open: async () => {
      let authType = _get(settings, 'office365.authType')
      let existingAuthType
      if (authType) {
        existingAuthType = authType
      } else {
        authType = USE_DELEGATED_AUTH ? 'delegated' : 'admin'
      }
      setAuthType(authType)
      setExistingAuthType(existingAuthType)
      setVisible(true)
    }
  }), [settings])

  const closeModal = useCallback(() => setVisible(false), [])
  const openAuthUrl = useCallback(() => {
    let authUrl
    if (authType === 'delegated') {
      authUrl = syncOffice365.getAuthorizeUrl()
    } else if (authType === 'admin') {
      authUrl = syncOffice365.getAdminConsentUrl()
    }
    if (authUrl) {
      window.open(authUrl, '_blank', ['noopener'])
    }
    closeModal()
  }, [authType, closeModal])

  const afterClose = () => {
    setVisible(false)
    setAuthType('admin')
  }

  return (
    <AuthModal
      width='70%'
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      maskClosable={false}
      destroyOnClose
      afterClose={afterClose}
    >
      <AuthModalWrap>
        <AuthModalTitle>{I18n.t('title', trOpt)}</AuthModalTitle>
        <AuthModalContent>
          <AuthRadioOption
            type='admin'
            checked={authType === 'admin'}
            isExistingValue={existingAuthType === 'admin'}
            recommended={!USE_DELEGATED_AUTH}
            onChange={setAuthType}
            trOpt={trOpt}
          />
          <AuthRadioOption
            type='delegated'
            checked={authType === 'delegated'}
            isExistingValue={existingAuthType === 'delegated'}
            recommended={USE_DELEGATED_AUTH}
            onChange={setAuthType}
            trOpt={trOpt}
          />
        </AuthModalContent>
        <AuthModalFooter>
          <Button onClick={closeModal}>{I18n.t('common.cancel')}</Button>
          <Button type='primary' onClick={openAuthUrl}>{I18n.t('common.continue')}</Button>
        </AuthModalFooter>
      </AuthModalWrap>
    </AuthModal>
  )
})

export default connect(state => ({ settings: getSettings(state) }))(Microsoft365SyncSelectAuthFlowModal)
