import I18n from 'i18n-js'
import _isString from 'lodash/isString'

import { PRODUCT_NAMES } from '../../constants/productNames'
import { InputWithReset } from './common'
import SettingsForm from './SettingsForm'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { LANGUAGE_CODES } from '../../constants/languages'

const trOpt = { scope: 'settings.productNames' }

class ProductNames extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'productNames'

    this.defaultValue = {
      uPhish: PRODUCT_NAMES.uPhish,
      uLearn: PRODUCT_NAMES.uLearn,
      uBreach: PRODUCT_NAMES.uBreach,
      uPolicy: PRODUCT_NAMES.uPolicy,
      get uBreachPro () { return I18n.t('common.uBreachProDefaultName') }
    }

    this.setInitialFormValuesOnMount = true
  }

  get headerId () {
    return this.props.accountType === 'msp'
      ? 'settings-product-names-msp-header'
      : 'settings-product-names-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    const desc = I18n.t('description', trOpt)
    if (this.props.accountType === 'msp') {
      return [
        desc,
        I18n.t('mspDescription', trOpt)
      ]
    }
    return desc
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const { uLearnEnabled, uPhishEnabled, uPolicyEnabled, uBreachEnabled, uBreachProEnabled } = this.props
    return [{
      id: 'uPhish',
      type: 'text',
      label: I18n.t('uPhish', trOpt),
      required: true,
      component: InputWithReset,
      defaultValue: this.defaultValue.uPhish,
      defaultSetting: this.defaultValue.uPhish,
      visible: uPhishEnabled
    }, {
      id: 'uLearn',
      type: 'text',
      label: I18n.t('uLearn', trOpt),
      required: true,
      component: InputWithReset,
      defaultValue: this.defaultValue.uLearn,
      defaultSetting: this.defaultValue.uLearn,
      visible: uLearnEnabled
    }, {
      id: 'uBreach',
      type: 'text',
      label: I18n.t('uBreach', trOpt),
      required: true,
      component: InputWithReset,
      defaultValue: this.defaultValue.uBreach,
      defaultSetting: this.defaultValue.uBreach,
      visible: uBreachEnabled
    }, {
      id: 'uBreachPro',
      type: 'text',
      label: I18n.t('uBreachPro', trOpt),
      required: true,
      component: InputWithReset,
      defaultValue: this.defaultValue.uBreachPro,
      defaultSetting: this.defaultValue.uBreachPro,
      visible: uBreachEnabled && uBreachProEnabled
    }, {
      id: 'uPolicy',
      type: 'text',
      label: I18n.t('uPolicy', trOpt),
      required: true,
      component: InputWithReset,
      defaultValue: this.defaultValue.uPolicy,
      defaultSetting: this.defaultValue.uPolicy,
      visible: uPolicyEnabled
    }]
  }

  // mutateValues - remove entries if product matches the default name
  mutateValues (values) {
    const mutatedValues = Object.entries(PRODUCT_NAMES).reduce((acc, [productId, defaultProductName]) => {
      const value = _isString(values[productId]) ? values[productId].trim() : undefined
      if (value && value !== defaultProductName) {
        acc[productId] = value
      }
      return acc
    }, {})

    // Only set mutatedValues.uBreachPro if it doesn't match a translation
    const uBreachPro = (values.uBreachPro || '').trim()
    if (
      uBreachPro &&
      !LANGUAGE_CODES.some(locale => I18n.t('common.uBreachProDefaultName', { locale }) === uBreachPro)
    ) {
      mutatedValues.uBreachPro = uBreachPro
    }

    return Object.keys(mutatedValues).length > 0 ? mutatedValues : null
  }
}

export default connect(
  state => {
    const { uLearn: uLearnEnabled, uPhish: uPhishEnabled, uPolicy: uPolicyEnabled, uBreachEnabled, uBreachProEnabled } = selectors.settings.get(state) || {}
    return { uLearnEnabled, uPhishEnabled, uPolicyEnabled, uBreachEnabled, uBreachProEnabled }
  }
)(ProductNames)
