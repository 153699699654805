import I18n from 'i18n-js'
import React from 'react'
import { Divider } from 'antd'

import SyncButton from '../Sync/SyncButton'
import { renderListFragmentFromArray, renderListFromArray } from '../../helpers/renderFromArray'

const SyncSetupComplete = ({ includeGroups, syncType, trOpt = { scope: 'syncSetup.syncSetupComplete' } }) => (
  <div>
    <h3>{I18n.t('title', trOpt)}</h3>
    <p className='base-font'>{I18n.t('intro', trOpt)}</p>
    <Divider />
    <h4>{I18n.t('sync.common.testSync')}</h4>
    <ul>
      {renderListFragmentFromArray(I18n.t('testSyncList', trOpt))}
      <li>{I18n.t('emailIntro', trOpt)}
        <ul>
          {includeGroups && renderListFragmentFromArray(I18n.t('groupsList', trOpt))}
          {renderListFragmentFromArray(I18n.t('usersList', trOpt))}
          <li>{I18n.t('errorsIncluded', trOpt)}</li>
        </ul>
      </li>
    </ul>
    <SyncButton dummy force block={false} {...{ syncType }}>{I18n.t('runTestSyncNow', trOpt)}</SyncButton>
    <Divider />
    <h4>{I18n.t('sync.common.manualSync')}</h4>
    {renderListFromArray(I18n.t('manualSyncList', trOpt))}
    <SyncButton force block={false} {...{ syncType }}>{I18n.t('runManualSyncNow', trOpt)}</SyncButton>
  </div>
)

export default SyncSetupComplete
