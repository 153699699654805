import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Alert, Badge, Button, Divider, Empty, Icon, Input, Modal, Tag, message } from 'antd'
import styled, { withTheme, css } from 'styled-components'
import { useQuery, useMutation } from '@apollo/react-hooks'
import CSVReader from 'react-csv-reader'
import I18n from 'i18n-js'
import _findLastIndex from 'lodash/findLastIndex'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _sortBy from 'lodash/sortBy'
import _uniq from 'lodash/uniq'

import { GET_POLICY, GET_SIGN_POLICY_OFFLINE_LEARNER, VALIDATE_SIGN_POLICY_OFFLINE_UPLOAD, SIGN_POLICY_OFFLINE } from '../Queries/uPolicy'
import { LoadingBlock, ErrorAlerts } from '../common'
import { showErrors, validateEmail, invalidatePoliciesQueryCache } from '../../helpers'
import { getPolicyRecipientStatusText } from '../../helpers/Policies'
import authenticatedClient from '../../apollo-client/authenticatedClient'

import { StatusTitleAll } from '../Policies/ViewPolicyUsersStatusInfo'
import { PolicyVersionTag } from '../Policies/ViewPolicyVersionField'
import RiskScoreIndicator from '../Learners/RiskScoreIndicator'
import LearnersView from '../Learners/LearnersView'
import getAllVersionsPolicyData from '../../helpers/getAllVersionsPolicyData'

const trOpt = { scope: 'modals.uploadPolicySignaturesModal' }
const policyUsersTrOpt = { scope: 'uPolicy.viewPolicyUsers' }
const POLICY_STATUS = ['signed', 'visited', 'sent', 'pending', 'notSent']

const ViewModal = styled(Modal)`
  max-width: 1200px;
  top: 20px;

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-content {
    height: 100%;
  }
`

const ViewPolicyContentContainer = styled.div`
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;

  .learner-view-header-panel-right {
    align-self: flex-end;

    .learner-search {
      margin-bottom: 25px;
    }
  }
`

const RecipientLine = styled.span`
  display: block;
  font-size: ${({ sub }) => sub ? '12px' : 'inherit'};
`

const StatusContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
  width: 70px;
`

const UploaderContainer = styled.div`
  padding-bottom: 6px;

  p {
    margin-bottom: 0;
    margin-top: 3px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .ant-btn {
    margin-left: 10px;
  }
`

const ErrorContainer = styled.div`
  padding-bottom: 30px;
`
const ErrorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
const ErrorList = styled.div`
  ${({ modal }) => modal ? css`
  max-height: 500px;
  overflow: auto;
  ` : ''}

  &> .ant-alert {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
`

const ErrorEmpty = styled(Empty)`
  margin-top: 15px;
`
const ErrorEmptyIcon = styled(Icon)`
  color: ${({ theme }) => theme.green};
  font-size: 100px;
`

const ErrorButtonBadge = styled(Badge)`
  margin-right: 5px;
  .ant-scroll-number-only {
    position: relative;
    top: -1px;
  }
`

const DeleteLearnerButton = ({ id, removeLearner = () => {} }) => {
  const onClick = useCallback(() => removeLearner(id), [removeLearner, id])

  return <Button type='danger' icon='delete' onClick={onClick} />
}

const LearnerSearchContainer = styled.div`
  display: flex;

  .ant-btn {
    margin-left: 5px;
  }
`

const LearnerSearchInputContainer = styled.div`
  max-width: 350px;

  .ant-form-explain {
    margin-top: 5px;
  }
`

const LearnerSearchInputPanel = ({ policyId, isLearnerPresent = () => {}, addLearners = () => {}, client = authenticatedClient }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  const onInputChange = useCallback(e => {
    setValue(e.currentTarget.value || null)
    setError(null)
  }, [setValue, setError])
  const onAddClick = useCallback(async () => {
    let newValue = value ? value.trim() : null
    if (!newValue) {
      return setError(I18n.t('addValueInvalidError', trOpt))
    }

    const isEmail = validateEmail(newValue)
    const searchProp = isEmail ? 'email' : 'learnerId'
    if (isEmail) {
      newValue = newValue.toLowerCase()
    }
    if (isLearnerPresent(searchProp, newValue)) {
      return setError(I18n.t('userInListError', trOpt))
    }

    const errorMessage = I18n.t('noUserFoundError', { ...trOpt, value: value.trim() })
    try {
      const { data: { learner } } = await client.query({
        query: GET_SIGN_POLICY_OFFLINE_LEARNER,
        variables: {
          policyId,
          [searchProp]: newValue
        },
        fetchPolicy: 'no-cache'
      })

      if (learner) {
        setValue(null)
        setError(null)
        addLearners([learner])
        message.success(I18n.t('addSuccessMessage', { ...trOpt, name: learner.name }))
      } else {
        message.error(errorMessage)
      }
    } catch (e) {
      showErrors(e, errorMessage, {
        processor: function () {
          // Always include default message if there are errors present
          if (!_isEmpty(this.errors)) {
            this.errors.unshift(errorMessage)
          }
        }
      })
    } finally {
      setLoading(false)
    }
  }, [client, value, setValue, setError, addLearners, isLearnerPresent, policyId])

  return (
    <>
      <label>{I18n.t('common.addUser')}</label>
      <LearnerSearchContainer>
        <LearnerSearchInputContainer className={error ? 'has-error' : undefined}>
          <Input placeholder={I18n.t('userEmailOrId', trOpt)} allowClear onChange={onInputChange} value={value} onPressEnter={onAddClick} disabled={!policyId || loading} />
          <div className='ant-form-explain'>{error}</div>
        </LearnerSearchInputContainer>
        <Button icon='plus' type='primary' onClick={onAddClick} loading={loading}>{I18n.t('common.add')}</Button>
      </LearnerSearchContainer>
    </>
  )
}

const UploadPolicySignaturesModal = ({
  policyId: policyIdProp, policy: policyProp,
  visible = false, setVisible = () => {}, afterClose: afterCloseProp = () => {},
  refetchQueries,
  client = authenticatedClient,
  theme,
  useErrorList = false
}) => {
  const [errors, setErrors] = useState([])
  const [learners, setLearners] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [searchFilterText, updateSearchFilterText] = useState('')

  const uploader = useRef(null)

  const closeModal = useCallback(() => setVisible(false), [setVisible])

  const { loading: policyLoading, error, data } = useQuery(GET_POLICY, {
    variables: {
      id: policyIdProp,
      includeLivePolicyDocument: true,
      includePolicyDocuments: true
    },
    skip: policyProp || !(visible && policyIdProp)
  })
  const [policy, setPolicy] = useState({})
  useEffect(() => {
    const policy = policyProp || _get(data, 'policy', {})
    setPolicy(policy)
  }, [data, policyProp])
  const { id: policyId, name: policyName } = policy || {}

  const [signPolicyOffline] = useMutation(SIGN_POLICY_OFFLINE, { update: invalidatePoliciesQueryCache, refetchQueries })
  const uploadPolicySignatures = useCallback(async () => {
    const learnerIds = _uniq(learners.map(({ id }) => id))
    const count = learnerIds.length

    setLoading(true)
    const errorMessage = I18n.t('errorMessage', { ...trOpt, name: policyName, count })
    try {
      const success = await signPolicyOffline({ variables: { policyId: policyId, learnerIds } })
      if (success) {
        closeModal()
        message.success(I18n.t('successMessage', { ...trOpt, name: policyName, count }))
      } else {
        message.error(errorMessage)
      }
    } catch (e) {
      showErrors(e, errorMessage, {
        processor: function () {
          // Always include default message if there are errors present
          if (!_isEmpty(this.errors)) {
            this.errors.unshift(errorMessage)
          }
        }
      })
    } finally {
      setLoading(false)
    }
  }, [learners, policyId, policyName, closeModal, signPolicyOffline])

  const [documents, setDocuments] = useState([])
  const [lastMajorIndex, setLastMajorIndex] = useState(-1)
  useEffect(() => {
    const documents = _sortBy((policy.documents || []).filter(({ status }) => status === 'archived' || status === 'live'), ['majorVersion', 'minorVersion'])
    const lastMajorIndex = _findLastIndex(documents, doc => doc.major)

    setDocuments(documents)
    setLastMajorIndex(lastMajorIndex)
  }, [policy])

  const addLearners = useCallback(newLearners => {
    newLearners = newLearners.map(learner => {
      const { policyResults: learnerResults } = learner

      const sentVersions = learnerResults.map(({ versionSent }) => versionSent).filter(v => !_isNil(v))
      const visitedVersions = learnerResults.map(({ versionVisited }) => versionVisited).filter(v => !_isNil(v))
      const signedVersions = learnerResults.map(({ versionSigned }) => versionSigned).filter(v => !_isNil(v))
      const policyData = getAllVersionsPolicyData({ documents, learnerResults, lastMajorIndex, sentVersions, visitedVersions, signedVersions })

      return {
        ...learner,
        policyStatus: _get(policyData, 'status', 'notSent'),
        lastSigned: _get(policyData, 'lastSigned'),
        lastSignedStr: _get(policyData, 'lastSignedStr'),
        policyData
      }
    })

    const newLearnerIds = newLearners.map(({ id }) => id)
    const existingLearners = learners.filter(learner => !newLearnerIds.includes(learner.id))

    setLearners([...newLearners, ...existingLearners])
  }, [learners, documents, lastMajorIndex])

  const showErrorModal = useCallback((title = I18n.t('uploadIssues', trOpt), errorData = errors) => {
    Modal.error({
      title,
      content: (
        <ErrorList modal>
          {errorData.map((error, index) => <Alert key={index} type='error' showIcon message={error} />)}
        </ErrorList>
      ),
      width: 800
    })
  }, [errors])
  const onShowErrorClick = useCallback(() => showErrorModal(), [showErrorModal])

  const clearUpload = useCallback(() => {
    // Clear file input
    const { current: uploadDiv } = uploader
    if (uploadDiv) {
      const fileInput = uploadDiv.querySelector('#csvUpload')
      if (fileInput) {
        fileInput.value = ''
      }
    }
  }, [uploader])

  const updateCsvData = useCallback(async csvData => {
    setLoading(true)
    setErrors([])

    try {
      const { data: { validateSignPolicyOfflineUpload: { learners = [], errors = [] } = {} } = {} } = await client.query({
        query: VALIDATE_SIGN_POLICY_OFFLINE_UPLOAD,
        variables: {
          policyId,
          data: csvData
        },
        fetchPolicy: 'no-cache'
      })

      addLearners(learners)
      setErrors(errors)
      if (errors.length > 0) {
        if (useErrorList) {
          message.error(I18n.t('validateUploadError', trOpt))
        } else {
          showErrorModal(I18n.t('validateUploadErrorTitle', trOpt), errors)
        }
      }

      clearUpload()
      setUploaded(true)
    } catch (e) {
      showErrors(e, I18n.t('validateUploadFailedError', trOpt))
    } finally {
      setLoading(false)
    }
  }, [clearUpload, policyId, setErrors, addLearners, showErrorModal, useErrorList, client])

  const isLearnerPresent = useCallback((prop, value) => {
    return learners.some(learner => learner[prop] === value)
  }, [learners])

  const removeAllErrors = useCallback(() => setErrors([]), [])
  const removeLearner = useCallback(id => setLearners(learners.filter(learner => learner.id !== id)), [learners])
  const reset = useCallback(() => {
    setLearners([])
    setErrors([])
    setUploaded(false)
    clearUpload()
  }, [clearUpload])

  const onUploadClick = useCallback(() => {
    Modal.confirm({
      title: I18n.t('uploadConfirmPrompt', { ...trOpt, name: policy.name, count: learners.length }),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      async onOk () {
        return uploadPolicySignatures()
      }
    })
  }, [uploadPolicySignatures, learners, policy])
  const onResetClick = useCallback(() => {
    Modal.confirm({
      title: I18n.t('resetConfirmPrompt', trOpt),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk () {
        reset()
      }
    })
  }, [reset])

  const afterClose = useCallback(() => {
    reset()
    afterCloseProp()
  }, [afterCloseProp, reset])

  const columns = useMemo(() => {
    const statusFilters = [
      { text: I18n.t('uPolicy.common.signed'), value: 'signed' },
      { text: I18n.t('notSigned', policyUsersTrOpt), value: 'notSigned' },
      { text: I18n.t('common.visited'), value: 'visited' },
      { text: I18n.t('common.sent'), value: 'sent' },
      { text: I18n.t('common.pending'), value: 'pending' }
    ]

    return [
      {
        title: I18n.t('common.recipient'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name)
        },
        render (name, learner, index) {
          return (
            <div>
              <RecipientLine>{name}</RecipientLine>
              <RecipientLine sub>{learner.email || learner.learnerId}</RecipientLine>
            </div>
          )
        }
      }, {
        title: StatusTitleAll,
        dataIndex: 'policyStatus',
        key: 'policyStatus',
        render (statusId, learner) {
          const statusText = getPolicyRecipientStatusText(statusId)
          let status = <span>{statusText}</span>
          const tags = []
          if (learner.policyData) {
            const { versionNo, archived, major, rag, offline } = learner.policyData
            if (versionNo) {
              tags.push(<PolicyVersionTag key='version' {...{ versionNo, archived, major }} />)
            }
            if (statusText === 'signed' && offline) {
              tags.push(<Tag key='offline' color={theme.orange}>{I18n.t('manual', policyUsersTrOpt)}</Tag>)
            }
            if (rag) {
              status = (
                <StatusContainer>
                  <RiskScoreIndicator text={statusText} value={rag} />
                </StatusContainer>
              )
            }
          }

          return (
            <>
              {status}
              {tags}
            </>
          )
        },
        filterMultiple: true,
        onFilter: (status, learner) => {
          if (status === 'notSigned') {
            return learner.policyStatus !== 'signed'
          } else if (status === 'sent') {
            return learner.policyStatus !== 'notSent'
          } else {
            return learner.policyStatus === status
          }
        },
        filters: statusFilters,
        sorter: (a, b) => {
          return POLICY_STATUS.indexOf(b.policyStatus) - POLICY_STATUS.indexOf(a.policyStatus)
        }
      }, {
        title: I18n.t('uPolicy.common.lastSignedAt'),
        dataIndex: 'lastSignedStr',
        key: 'lastSignedStr',
        sorter: (a, b) => {
          if (a.lastSigned && b.lastSigned) {
            if (a.lastSigned.isAfter(b.lastSigned)) {
              return 1
            } else if (a.lastSigned.isBefore(b.lastSigned)) {
              return -1
            }
          } else {
            if (a.lastSigned && !b.lastSigned) {
              return 1
            } else if (!a.lastSigned && b.lastSigned) {
              return -1
            }
          }
          return 0
        }
      }, {
        key: 'delete',
        render (text, learner) {
          return <DeleteLearnerButton id={learner.id} removeLearner={removeLearner} />
        }
      }
    ]
  }, [theme, removeLearner])

  const [containerHeight, setContainerHeight] = useState('auto')
  useEffect(() => {
    const adjustContainerHeight = () => {
      setContainerHeight(window.innerHeight - 123)
    }

    adjustContainerHeight()
    window.addEventListener('resize', adjustContainerHeight)
    return () => {
      window.removeEventListener('resize', adjustContainerHeight)
    }
  }, [])

  return (
    <ViewModal
      title={I18n.t(policyName ? 'titleWithName' : 'title', { ...trOpt, name: policyName })}
      width='90%'
      bodyStyle={{ paddingTop: 5 }}
      visible={visible}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
      destroyOnClose
      keyboard={false}
    >
      {(loading || policyLoading) && <LoadingBlock fullScreen={false} loading />}
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('uPolicy.common.policyLoadError')} />
          : (
            <ViewPolicyContentContainer style={{ maxHeight: containerHeight }}>
              <LearnersView
                showActions={false}
                showGroups={false}
                useSelection={false}
                size='small'
                {...{
                  learners,
                  columns,
                  policy,
                  refetchQueries,
                  searchFilterText,
                  updateSearchFilterText
                }}
                panelLeft={[
                  <UploaderContainer key='learnerCsvUpload' ref={uploader}>
                    <label>{I18n.t('addUsersViaCSVUpload', trOpt)}</label>
                    <CSVReader
                      onFileLoaded={updateCsvData}
                      inputId='csvUpload'
                    />
                    <p className='base-font'>{I18n.t('common.downloadTemplateCopy')}: <a href='/upload-template/upload-policy-signatures.csv' download>{I18n.t('common.downloadTemplate')}</a></p>
                  </UploaderContainer>,
                  <LearnerSearchInputPanel key='learnerLookup' {...{ policyId, isLearnerPresent, addLearners, client }} />
                ]}
                panelRight={['search']}
              />
              <ButtonContainer>
                {!useErrorList && uploaded && !_isEmpty(errors) && <Button ghost type='danger' onClick={onShowErrorClick}><ErrorButtonBadge count={errors.length} style={{ backgroundColor: 'transparent', color: '#ff4d4f', border: 'solid 1px #ff4d4f' }} /> {I18n.t('showIssues', trOpt)}</Button>}
                <Button type='danger' icon='delete' onClick={onResetClick} disabled={_isEmpty(learners)}>{I18n.t('resetUserList', trOpt)}</Button>
                <Button type='primary' icon='file-done' onClick={onUploadClick} disabled={_isEmpty(learners)}>{I18n.t('uPolicy.common.uploadSignatures')}</Button>
              </ButtonContainer>
              {
                useErrorList && uploaded &&
                  <ErrorContainer>
                    <Divider />
                    <ErrorHeader>
                      <h4>Upload Errors</h4>
                      <Button type='danger' onClick={removeAllErrors} icon='delete' disabled={_isEmpty(errors)}>{I18n.t('clearAll', trOpt)}</Button>
                    </ErrorHeader>
                    {
                      _isEmpty(errors)
                        ? (
                          <ErrorEmpty
                            image={<ErrorEmptyIcon type='check-circle' />}
                            description='No Errors'
                          />
                        )
                        : <ErrorList>{errors.map((error, index) => <Alert key={index} type='error' showIcon message={error} />)}</ErrorList>
                    }
                  </ErrorContainer>
              }
            </ViewPolicyContentContainer>
          )
      }
    </ViewModal>
  )
}

export default withTheme(UploadPolicySignaturesModal)
