import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import styled, { withTheme } from 'styled-components'
import { ResponsiveBar } from '@nivo/bar'
import I18n from 'i18n-js'
import _times from 'lodash/times'

import { formatDate } from '../../helpers/datetime'

const trOpt = { scope: 'uPhish.interactionsChart' }

const INTERACTION_COLORS = {
  opens: 'simulationOpen',
  visits: 'simulationVisit',
  compromises: 'simulationCompromise',
  reports: 'simulationReport'
}

const getThemeColorForInteraction = (id, theme) => {
  const themeColorId = INTERACTION_COLORS[id]
  return theme[themeColorId] || theme.primary || 'blue'
}

const InteractionChartContainer = styled.div`
  height: 100%;
`

const InteractionLegend = styled.div`
  display: flex;
  justify-content: center;
  
  & > * {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const StyledInteractionLegendItem = styled.div`
  align-items: center;
  display: flex;
  font-size: 11px;

  .legend__swatch {
    background-color: ${({ id, theme }) => getThemeColorForInteraction(id, theme)};
    display: inline-block;
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }
  .legend__label {
    display: inline-block;
    margin-top: 5px;
    vertical-align: top;
  }
`

const InteractionLegendItem = ({ id }) => {
  return (
    <StyledInteractionLegendItem id={id}>
      <div className='legend__swatch' />
      <span className='legend__label'>{I18n.t(id, { scope: 'uPhish.common.charts' })}</span>
    </StyledInteractionLegendItem>
  )
}

const InteractionsChart = ({ simulation, theme, showPhishAlert = false }) => {
  const { startDate: startDateStr, results = [] } = simulation
  // Create data entries
  // Work out duration of phish - start to latest event
  const events = useMemo(() =>
    results.reduce((events, { open, compromise, visit, report }) => {
      if (open) {
        events.open.push(open)
      }
      if (visit) {
        events.visit.push(visit)
      }
      if (compromise) {
        events.compromise.push(compromise)
      }
      if (report) {
        events.report.push(report)
      }

      const sortedEvents = [open, compromise, visit]
        .filter(event => event) // Filter open, visit, compromise by non null
        // Sort by date desc
        .sort((a, b) => {
          const aMoment = moment(a)
          const bMoment = moment(b)
          if (aMoment.isAfter(bMoment)) {
            return -1
          } else if (aMoment.isBefore(bMoment)) {
            return 1
          }
          return 0
        })

      // Compare first element to last event - replace if after
      if (sortedEvents[0] && (!events.last || moment(sortedEvents[0]).isAfter(events.last))) {
        events.last = sortedEvents[0]
      }

      return events
    }, {
      open: [],
      visit: [],
      compromise: [],
      report: [],
      last: null
    })
  , [results])

  const eventTypes = ['open', 'visit', 'compromise']
  if (showPhishAlert) eventTypes.push('report')
  const maxValue = eventTypes.some(event => events[event].length > 0) ? undefined : 1
  const data = useMemo(() => {
    const startDate = moment(startDateStr)
    const lastEvent = events.last || moment(startDate).add(1, 'week') // Default to one week if no events are present

    // Determine interval i.e. minute, hour, day, week, month
    const targetTickCount = 15
    const intervals = ['minute', 'hour', 'day', 'week', 'month']
    const interval = intervals.find(interval => {
      const intervalDuration = Math.ceil(moment(lastEvent).diff(startDate, interval, true))
      return intervalDuration > 0 && intervalDuration <= targetTickCount
    }) || 'minute'

    // Loop through each tick - Loop through results incrementing event count if they fit within the tick
    const duration = Math.ceil(moment(lastEvent).diff(startDate, interval, true))
    const scaleFactor = duration > targetTickCount ? (Math.floor(duration / targetTickCount) + 1) : 1
    // tick count is inflated slightly to artificially increase the size of the chart
    const tickCount = Math.ceil(duration / scaleFactor) + 1

    return _times(tickCount, tick => {
      const lower = moment(startDate).add(scaleFactor * tick, interval)
      const upper = moment(startDate).add(scaleFactor * (tick + 1), interval)

      // Includes lower, excludes upper
      const betweenFilter = event => moment(event).isBetween(lower, upper, null, '[)')

      return {
        time: tick > 0 ? moment.duration(tick, interval).humanize() : formatDate(startDate),
        opens: events.open.filter(betweenFilter).length,
        visits: events.visit.filter(betweenFilter).length,
        compromises: events.compromise.filter(betweenFilter).length,
        reports: events.report.filter(betweenFilter).length
      }
    })
  }, [startDateStr, events])

  const colors = useCallback(e => getThemeColorForInteraction(e.id, theme), [theme])
  const tooltipLabel = useCallback(e => `${e.indexValue} - ${I18n.t(e.id, { scope: 'uPhish.common.charts' })}`, [])
  const keys = ['opens', 'visits', 'compromises']
  if (showPhishAlert) keys.push('reports')

  return (
    <InteractionChartContainer>
      <InteractionLegend>
        {keys.map(key => <InteractionLegendItem key={key} id={key} />)}
      </InteractionLegend>
      <ResponsiveBar
        data={data}
        enableLabel={false}
        axisBottom={{ legend: I18n.t('timeSinceSimulationStart', trOpt), legendPosition: 'middle', legendOffset: 45 }}
        animate={false}
        margin={{ top: 20, right: 0, bottom: 75, left: 25 }}
        indexBy='time'
        keys={keys}
        padding={0.65}
        colors={colors}
        tooltipLabel={tooltipLabel}
        maxValue={maxValue}
        // The nivo legends component doesn't allow to set the order of the legends
        // it's driven by the order in which bars are rendered in order to pull their colour
        // So if we're matching v1 then it's not fit for purpose, hence the custom job above
        // legends={[
        //   {
        //     dataFrom: 'keys',
        //     anchor: 'top',
        //     direction: 'row',
        //     justify: false,
        //     translateX: 0,
        //     translateY: -50,
        //     itemsSpacing: 2,
        //     itemWidth: 120,
        //     itemHeight: 20,
        //     itemDirection: 'left-to-right',
        //     itemOpacity: 0.85,
        //     symbolSize: 21
        //   }
        // ]}
        theme={{
          axis: {
            ticks: {
              text: {
                // fill: theme.primary // In case we're asked to make the axis label blue
                fontFamily: 'inherit'
              }
            },
            legend: {
              text: {
                // fill: theme.primary // In case we're asked to make the axis label blue
                fontFamily: 'inherit'
              }
            }
          }
        }}
      />
    </InteractionChartContainer>
  )
}

export default withTheme(InteractionsChart)
