import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import useGlobalState from '../../../hooks/useGlobalState'

import MutationForm from '../../MutationForm/MutationForm'
import { GET_COMPANIES } from '../../Queries/Companies'
import { GET_COURSES_OWNER, ADD_COMPANY_COURSES, REMOVE_COMPANY_COURSES } from '../../Queries/Courses'
import { LoadingBlock } from '../../common'
import { modalConfirmAsync, showErrors, UsecureError } from '../../../helpers'
import { buildCompanyOptions } from '../../../helpers/company'
import { useHasSessionPermission } from '../../../hooks'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'modals.updateCourseAccessControlModal' }

const EditModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const UpdateCourseAccessControlModal = React.forwardRef(({ refetchQueries = [], onClose = () => { } }, ref) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const { accountType } = useGlobalState(
    state => ({ accountType: state.session?.accountType })
  )
  const hasSuperAccessControlPermission = hasAllSessionPermissions([permissions.COURSE_SUPER_ACCESS_CONTROL])

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companyOptions, setCompanyOptions] = useState([])
  const [action, setAction] = useState('')
  const [courseIds, setCourseIds] = useState([])
  const [companyCourseMutation, setCompanyCourseMutation] = useState(null)
  const client = useApolloClient()

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const keyPrefix = accountType === 'msp' ? 'msp.' : ''
  useImperativeHandle(ref, () => ({
    open: async (courseIds, action) => {
      try {
        setLoading(true)
        setVisible(true)
        setAction(action)
        setCourseIds(courseIds)
        setCompanyCourseMutation(() => {
          if (action === 'add') {
            return ADD_COMPANY_COURSES
          } else if (action === 'remove') {
            return REMOVE_COMPANY_COURSES
          }
        })
        let getCompaniesQueryVars
        if (hasSuperAccessControlPermission) {
          const coursesOwnerResult = await client.query({
            query: GET_COURSES_OWNER,
            variables: { courseIds }
          })
          const coursesOwner = _get(coursesOwnerResult, 'data.getCoursesOwner')
          // Get companies that are descendants of company that owns courses
          if (coursesOwner.companyId) {
            getCompaniesQueryVars = {
              ancestorCompanyId: coursesOwner.companyId,
              descendants: true
            }
          } else if (coursesOwner.managedByUsecure) {
            // Get every company
            getCompaniesQueryVars = {}
          }
        } else {
          // Get companies with decendants
          getCompaniesQueryVars = {
            descendants: true
          }
        }
        const companiesThatCanAccessCourseResult = await client.query({
          query: GET_COMPANIES,
          variables: getCompaniesQueryVars
        })
        const companies = _get(companiesThatCanAccessCourseResult, 'data.companies')
        setCompanyOptions(buildCompanyOptions(companies ?? []))
        setLoading(false)
      } catch (e) {
        setVisible(false)
        showErrors(e, I18n.t('initialisationError', trOpt))
      }
    }
  }), [client, hasSuperAccessControlPermission])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setCompanyOptions([])
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(async () => {
    const confirm = await modalConfirmAsync()
    if (!confirm) {
      throw new UsecureError(I18n.t('cancelledMessage', trOpt), { level: 'info' })
    }
  }, [])

  const onSuccess = useCallback(() => {
    const keyPrefix = accountType === 'msp' ? 'msp.' : ''
    message.success(I18n.t(`${keyPrefix}${action}SuccessMessage`, trOpt))
    closeModal()
  }, [closeModal, accountType, action])

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={I18n.t(`${keyPrefix}${action}Title`, trOpt)}
      afterClose={afterClose}
      width='50%'
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <MutationForm
        mutation={companyCourseMutation}
        variables={{ courseIds }}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        failureMessage={I18n.t(`${keyPrefix}${action}ErrorMessage`, trOpt)}
        submitLabel={I18n.t(`${keyPrefix}${action}Button`, trOpt)}
        fields={[{
          id: 'companyIds',
          label: accountType === 'msp' ? I18n.t('msp.customerAccessLabel', trOpt) : I18n.t('common.accessControl.companyAccessLabel'),
          type: 'multiSelect',
          options: companyOptions,
          required: true
        }]}
        ref={form}
        refetchQueries={refetchQueries}
      />
    </EditModal>
  )
})

export default UpdateCourseAccessControlModal
