import React, { useRef } from 'react'
import I18n from 'i18n-js'
import { Link, generatePath } from 'react-router-dom'
import { Table, Input, Button, Icon } from 'antd'
import _startCase from 'lodash/startCase'
import _pick from 'lodash/pick'
import _isNumber from 'lodash/isNumber'
import moment from 'moment'
import routes from '../../../constants/routes'
import { connect } from '../../../hocs'
import selectors from '../../../state/selectors'
import { LIST_PAGINATION_PROPS } from '../../../constants/list'
import { numberColumnSorter, textColumnSorter } from '../../../helpers/listPages'
import { coursePercentageColumnSorter } from '../../../helpers/uLearnReport'

const trOpt = { scope: 'reports.uLearnReport' }

const UserTable = (props) => {
  const searchInput = useRef(null)

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={I18n.t('searchUserName', trOpt)}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {I18n.t('search', trOpt)}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          {I18n.t('reset', trOpt)}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    }
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
  }

  const handleReset = clearFilters => {
    clearFilters()
  }

  const columns = [
    {
      title: I18n.t('userName', trOpt),
      dataIndex: 'learnerName',
      key: 'learnerName',
      sorter: (a, b) => textColumnSorter('learnerName', a, b),
      ...getColumnSearchProps('learnerName')
    },
    {
      title: I18n.t('coursesEnrolled', trOpt),
      dataIndex: 'totalCourseResults',
      key: 'totalCourseResults',
      sorter: (a, b) => numberColumnSorter('totalCourseResults', a, b)
    },
    {
      title: `${I18n.t('coursesStarted', trOpt)} %`,
      key: 'totalCoursesStarted',
      dataIndex: 'totalCoursesStarted',
      sorter: (a, b) => coursePercentageColumnSorter('totalCoursesStarted', 'totalCourseResults', a, b),
      render: (totalCoursesStarted, { totalCourseResults }) => {
        return <span>{Math.floor(totalCoursesStarted * 100 / totalCourseResults)}%</span>
      }
    },
    {
      title: `${I18n.t('coursesFinished', trOpt)} %`,
      key: 'totalCoursesFinished',
      dataIndex: 'totalCoursesFinished',
      sorter: (a, b) => coursePercentageColumnSorter('totalCoursesFinished', 'totalCourseResults', a, b),
      render: (totalCoursesFinished, { totalCourseResults }) => {
        return <span>{Math.floor(totalCoursesFinished * 100 / totalCourseResults)}%</span>
      }
    },
    {
      title: I18n.t('averageCompletionTimeFromStart', trOpt),
      key: 'averageTimeFromStart',
      dataIndex: 'averageTimeFromStart',
      sorter: (a, b) => numberColumnSorter('averageTimeFromStart', a, b),
      render: (averageTimeFromStart) => {
        if (_isNumber(averageTimeFromStart)) {
          return _startCase(moment.duration(averageTimeFromStart, 'minutes').humanize())
        } else {
          return I18n.t('common.na')
        }
      }
    },
    {
      title: I18n.t('averageCompletionTimeFromEnrollment', trOpt),
      key: 'averageTimeFromEnrollment',
      dataIndex: 'averageTimeFromEnrollment',
      sorter: (a, b) => numberColumnSorter('averageTimeFromEnrollment', a, b),
      render: (averageTimeFromEnrollment) => {
        if (_isNumber(averageTimeFromEnrollment)) {
          return _startCase(moment.duration(averageTimeFromEnrollment, 'minutes').humanize())
        } else {
          return I18n.t('common.na')
        }
      }
    },
    {
      title: I18n.t('totalRetries', trOpt),
      key: 'totalRetries',
      dataIndex: 'totalRetries',
      sorter: (a, b) => numberColumnSorter('totalRetries', a, b)
    }, {
      title: I18n.t('averageRetries', trOpt),
      key: 'averageRetries',
      dataIndex: 'averageRetries',
      sorter: (a, b) => numberColumnSorter('averageRetries', a, b),
      render: (averageRetries) => {
        return _isNumber(averageRetries) ? averageRetries : I18n.t('common.na')
      }
    },
    {
      title: I18n.t('averageScore', trOpt),
      key: 'averageScore',
      dataIndex: 'averageScore',
      sorter: (a, b) => numberColumnSorter('averageScore', a, b),
      render: (averageScore) => {
        return _isNumber(averageScore) ? `${averageScore}%` : I18n.t('common.na')
      }
    },
    {
      title: I18n.t('action', trOpt),
      key: 'action',
      render: (learner) => {
        return <Link to={generatePath(routes.LEARNER_REPORT, { learnerId: learner.learnerId })}>{I18n.t('view', trOpt)}</Link>
      }
    }]

  return (
    <Table
      columns={columns}
      dataSource={props.learnerTableData}
      pagination={LIST_PAGINATION_PROPS}
    />
  )
}

export default connect(state => _pick(selectors.session.get(state), ['locale']))(UserTable)
