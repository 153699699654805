import React, { useState, useCallback } from 'react'
import { Alert, Button } from 'antd'
import { useQuery } from 'react-apollo'
import I18n from 'i18n-js'
import { useHistory } from 'react-router-dom'

import { GET_RECOVERY_KEYS } from '../Queries/Users'
import { showErrors } from '../../helpers'
import RecoveryKeysCard from '../AccountSettings/RecoveryKeysCard'
import { navigateToApp } from '../../helpers/signIn'

const trOpt = { scope: 'accountSettings.recoveryKeys' }

const RecoveryKeysRegenOnLastKey = ({ targetPath }) => {
  const history = useHistory()
  const [recoveryKeys, setRecoveryKeys] = useState([])
  const [allowNextStep, setAllowNextStep] = useState(false)
  const { loading: getRecoveryKeysLoading } = useQuery(GET_RECOVERY_KEYS, {
    fetchPolicy: 'no-cache',
    onCompleted: useCallback(async data => {
      if (data?.getRecoveryKeys) {
        const keys = data.getRecoveryKeys.map(item => item.recoveryKey)
        setRecoveryKeys(keys)
      }
    }, []),
    onError: e => showErrors(e, I18n.t('common.somethingWentWrong'))
  })

  const onOkClick = useCallback(() => {
    navigateToApp({ history, targetPath })
  }, [history, targetPath])

  return (
    <div>
      <RecoveryKeysCard
        title={I18n.t('generateNewRecoveryKeys', trOpt)}
        alerts={
          <Alert
            description={I18n.t('youHaveUsedAllOfYourRecoveryKeys', trOpt)}
            type='warning'
            style={{ margin: '20px 0' }}
          />
        }
        recoveryKeys={recoveryKeys}
        loading={getRecoveryKeysLoading}
        setAllowNextStep={setAllowNextStep}
      />
      <Button type='primary' onClick={onOkClick} style={{ marginTop: '25px' }} disabled={!allowNextStep}>{I18n.t('common.ok')}</Button>
    </div>
  )
}

export default React.memo(RecoveryKeysRegenOnLastKey)
