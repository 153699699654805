import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'
import queryString from 'query-string'

import { SyncSetup } from '../components/SyncSetup'
import { GOOGLE_SYNC_GROUPS_PAGE } from '../components/Queries/Learners'
import { UPDATE_GOOGLE_SYNC_SETTINGS } from '../components/Queries/Companies'
import { connect } from '../hocs'
import selectors from '../state/selectors'
import { GoogleSync, renderListFromArray, renderListFragmentFromArray } from '../helpers'
import routes from '../constants/routes'
import { GOOGLE_SYNC_GROUP_TYPES } from '../constants/google'

const trOpt = { scope: 'googleSyncSetup' }
const autoSyncTrOpt = { scope: 'googleSyncSetup.autoSync' }
const groupsTrOpt = { scope: 'googleSyncSetup.includeGroups' }
const groupUsersTrOpt = { scope: 'googleSyncSetup.includeGroupUsers' }
const groupMapTrOpt = { scope: 'googleSyncSetup.includeGroupMapping' }
const usersWithoutMailboxTrOpt = { scope: 'googleSyncSetup.excludeUsersWithoutMailboxSetup' }
const suspendedUsers = { scope: 'googleSyncSetup.excludeSuspendedUsers' }
const archivedUsers = { scope: 'googleSyncSetup.excludeArchivedUsers' }
const syncManagersTrOpt = { scope: 'syncSetup.syncManagers' }

const QuestionPoints = styled.ul`
  padding: 15px 0 0 20px;
`

const GoogleSyncSetup = ({ googleSync }) => {
  const handleAuthCode = useCallback(async () => {
    const { code: authCode, error: errorMessage } = queryString.parse(window.location.search)
    if (errorMessage) {
      throw new Error(I18n.t('authError', trOpt))
    }

    if (!authCode) {
      return
    }
    const syncGoogle = new GoogleSync()
    const authResult = await syncGoogle.updateAuthConfig({ authType: 'delegated', authCode })
    if (!authResult) {
      throw new Error(I18n.t('authConfigFailed', trOpt))
    }
  }, [])

  const steps = useMemo(() => [
    { type: 'intro' },
    {
      type: 'question',
      id: 'autoSync',
      question: I18n.t('question', autoSyncTrOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', autoSyncTrOpt))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'includeGroups',
      question: I18n.t('question', groupsTrOpt),
      description: ({ includeGroups = false }) => (
        includeGroups
          ? (
            <QuestionPoints>
              <li>{I18n.t('groupsIncluded', groupsTrOpt)}</li>
              <li>{I18n.t('groupsChanges', groupsTrOpt)}</li>
              <li>
                <span>{I18n.t('mergeExistingGroupsIntro', groupsTrOpt)}</span>
                {renderListFromArray(I18n.t('mergeExistingGroupsPoints', groupsTrOpt))}
              </li>
              <li>{I18n.t('groupConfigurationScreen', groupsTrOpt)}</li>
            </QuestionPoints>
          )
          : (
            <QuestionPoints>
              <li>{I18n.t('groupsNotIncluded', groupsTrOpt)}</li>
            </QuestionPoints>
          )
      )
    },
    {
      type: 'question',
      id: 'includeGroupMembWhitelist',
      question: I18n.t('question', groupUsersTrOpt),
      description: ({ includeGroups = false, includeGroupMembWhitelist = false }) => (
        <QuestionPoints>
          {
            includeGroupMembWhitelist
              ? (
                <>
                  <li>{I18n.t('ableToSelectGroups', groupUsersTrOpt)}</li>
                  {
                    includeGroups
                      ? (
                        <li>
                          <span>{I18n.t('usersWillBeIncluded', groupUsersTrOpt)}:</span>
                          <ul>
                            <li>{I18n.t('syncedGroup', groupUsersTrOpt)}</li>
                            <li>{I18n.t('nonSyncedGroup', groupUsersTrOpt)}</li>
                          </ul>
                        </li>
                      )
                      : (
                        <>
                          <li>{I18n.t('groupUsersIncluded', groupUsersTrOpt)}</li>
                          <li>{I18n.t('syncedUsersWillNotAppear', groupUsersTrOpt)}</li>
                        </>
                      )
                  }
                </>
              )
              : (
                <>
                  <li>{I18n.t('unableToSelectGroups', groupUsersTrOpt)}</li>
                  {
                    includeGroups
                      ? (
                        <>
                          <li>{I18n.t('allUsersWillBeIncluded', groupUsersTrOpt)}</li>
                          <li>{I18n.t('usersFromNonSyncedGroups', groupUsersTrOpt)}</li>
                        </>
                      )
                      : (
                        <>
                          <li>{I18n.t('allUsersWillBeIncluded', groupUsersTrOpt)}</li>
                          <li>{I18n.t('usersWillNotAppearInGroup', groupUsersTrOpt)}</li>
                        </>
                      )
                  }
                </>
              )
          }
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'includeGroupMembMapping',
      question: I18n.t('question', groupMapTrOpt),
      description: ({ includeGroups = false, includeGroupMembMapping = false }) => (
        <QuestionPoints>
          {
            includeGroupMembMapping
              ? (
                <>
                  <li>{I18n.t('mapExistingGroups', groupMapTrOpt)}</li>
                  {includeGroups && <li>{I18n.t('syncedGroupsCannotBeMapped', groupMapTrOpt)}</li>}
                  <li>{includeGroups ? I18n.t('usersFromSelectedNonSyncedMapped', groupMapTrOpt) : I18n.t('usersFromSelectedMapped', groupMapTrOpt)}</li>
                  <li>{includeGroups ? I18n.t('usersFromSelectedNonSyncedUnmapped', groupMapTrOpt) : I18n.t('usersFromSelectedUnmapped', groupMapTrOpt)}</li>
                </>
              )
              : (
                <>
                  <li>{I18n.t('existingGroupsNotMapped', groupMapTrOpt)}</li>
                  <li>{includeGroups ? I18n.t('usersFromSelectedNonSyncedGroups', groupMapTrOpt) : I18n.t('usersFromSelectedGroups', groupMapTrOpt)}</li>
                </>
              )
          }
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'groupType',
      question: I18n.t('groupTypeQuestion', trOpt),
      options: ['group', 'orgUnit', 'all'].map(value => ({ value, label: GOOGLE_SYNC_GROUP_TYPES[value] }))
    },
    { type: 'groups' },
    {
      type: 'question',
      id: 'excludeUsersWithoutMailboxSetup',
      question: I18n.t('question', usersWithoutMailboxTrOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', usersWithoutMailboxTrOpt))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'excludeSuspendedUsers',
      question: I18n.t('question', suspendedUsers),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', suspendedUsers))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'excludeArchivedUsers',
      question: I18n.t('question', archivedUsers),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', archivedUsers))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'syncManagers',
      question: I18n.t('question', syncManagersTrOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', syncManagersTrOpt))}
          {renderListFragmentFromArray(I18n.t('syncManagersInfoPoints', trOpt))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'includeUserEmailBlacklist',
      question: I18n.t('excludeUsers.question', trOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('excludeUsers.infoPoints', trOpt))}
          {renderListFragmentFromArray(I18n.t('googleSync.common.emailBlacklistPoints'))}
        </QuestionPoints>
      )
    },
    { type: 'users' },
    { type: 'complete' }
  ], [])

  return (
    <SyncSetup
      headerId='google-sync-header'
      trOptScopeKey='googleSyncSetup'
      settings={googleSync}
      syncType='google'
      usePaginatedGroupLoad
      groupsQuery={GOOGLE_SYNC_GROUPS_PAGE}
      groupsQueryKey='googleSyncGroupsPage'
      groupTypes={GOOGLE_SYNC_GROUP_TYPES}
      updateSettingsMutation={UPDATE_GOOGLE_SYNC_SETTINGS}
      syncSetupRoute={routes.GOOGLE_SYNC_SETUP}
      {...{ steps, handleAuthCode }}
    />
  )
}

export default connect(
  state => _pick(selectors.settings.get(state), ['googleSync'])
)(GoogleSyncSetup)
