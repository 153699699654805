import React, { Component, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Button, Form, Input, Tooltip, Divider as _Divider } from 'antd'
import I18n from 'i18n-js'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'

import MutationFormErrors from '../MutationForm/MutationFormErrors'
import { MutationFormInput } from '../MutationForm'
import { validateEmail } from '../../helpers'

export const SettingsContainer = styled.div`
  max-width: 1100px;
  padding-bottom: 15px;
`

export const DescriptionContainer = styled.div`
  padding-bottom: 15px;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ActionContainer = styled.div`
  button {
    margin-right: 15px;
  }
`

export const CopyToClipboard = ({
  className,
  value,
  label = I18n.t('settings.common.copyToClipboard'),
  copiedMessage = I18n.t('settings.common.linkCopied'),
  iconOnly = false
}) => {
  const [showCompletedTooltip, setShowCompletedTooltip] = useState(false)
  const onClick = useCallback(() => {
    if (!value) {
      return
    }
    navigator.clipboard.writeText(value)

    setShowCompletedTooltip(true)
    setTimeout(() => setShowCompletedTooltip(false), 1500)
  }, [value])

  let copyButton = <Button {...{ className }} icon='copy' type='primary' onClick={onClick}>{iconOnly ? null : label}</Button>
  if (iconOnly && !showCompletedTooltip) {
    copyButton = <Tooltip title={label}>{copyButton}</Tooltip>
  }

  return (
    <Tooltip title={copiedMessage} visible={showCompletedTooltip}>
      {copyButton}
    </Tooltip>
  )
}

const InputWithActionContainer = styled.div`
  display: flex;

  .ant-btn {
    margin-left: 10px;
  }
`
export class InputWithAction extends MutationFormInput {
  constructor (props) {
    super(props)

    this.handleActionClick = this.handleActionClick.bind(this)
  }

  handleActionClick () {
    if (_isFunction(this.props.onAction)) {
      this.props.onAction.apply(this)
    }
  }

  get actionIcon () {
    return this.props.actionIcon
  }

  get actionLabel () {
    const { actionLabel = 'common.ok' } = this.props
    return I18n.t(actionLabel)
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, extra, formItemStyle, errors = [], required, className, value, showCopyButton, disabled } = this.props
    const showErrors = errors.length > 0

    return (
      <Form.Item
        className={className} label={label} extra={extra} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <InputWithActionContainer>
          {this.renderInput()}
          {showCopyButton && <CopyToClipboard value={value} />}
          <Button type='primary' icon={this.actionIcon} onClick={this.handleActionClick} {...{ disabled }}>{this.actionLabel}</Button>
        </InputWithActionContainer>
      </Form.Item>
    )
  }
}

export class InputWithReset extends InputWithAction {
  handleActionClick () {
    const { id, defaultSetting } = this.props
    if (defaultSetting) {
      this.props.onChange(id, defaultSetting)
    }
  }

  get actionIcon () {
    return 'undo'
  }

  get actionLabel () {
    return I18n.t('common.reset')
  }
}

const emailTrOpt = { scope: 'settings.emailSettingsForm' }

const SenderAddressInputContainer = styled.div`
  display: flex;
  max-width: 800px;
  
  .ant-btn {
    margin-left: 10px;
  }
`
const SenderAddressFieldAtSign = styled.div`
  height: 32px;
  text-align: center;

  span {
    display: inline-block;
    line-height: 21px;
    padding: 4px 10px;
    vertical-align: top;
  }
`
const SenderAddressFieldDomain = styled.div`
  height: 32px;
  text-align: left;

  span {
    display: inline-block;
    line-height: 21px;
    padding: 4px 0 4px 10px;
    vertical-align: top;
    white-space: nowrap;
  }
`
export class SenderAddressField extends Component {
  constructor (props) {
    super(props)

    this.handleDomainChange = this.handleDomainChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.validate = this.validate.bind(this)
  }

  validate (value = this.props.value, errors = []) {
    const { appDomain: domain } = this.props
    const { prefix, subdomain } = value || {}
    if (!prefix) {
      errors.push(I18n.t('emptyUsernameError', emailTrOpt))
    } else {
      const email = `${prefix.trim()}@${subdomain ? `${subdomain.trim()}.` : ''}${domain.trim()}`
      if (!validateEmail(email)) {
        errors.push(I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError'))
      }
    }
    return errors
  }

  handleInputChange (event) {
    const { id, value: { domain, prefix, subdomain } = {}, onChange } = this.props
    const { name, value } = event.target
    onChange(id, {
      domain,
      prefix,
      subdomain,
      [name]: value
    })
  }

  handleDomainChange (domain) {
    const { id, value: { prefix, subdomain } = {}, onChange } = this.props
    onChange(id, {
      domain,
      prefix,
      subdomain
    })
  }

  async handleResetClick () {
    await this.props.onChange(this.props.id, this.props.resetValue)
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { appDomain, value: { prefix, subdomain } = {}, errors = [], label, resetValue, required, extra, className, formItemStyle, disabled } = this.props
    const showErrors = errors.length > 0

    return (
      <Form.Item
        className={className}
        label={label || I18n.t('senderEmailAddress', emailTrOpt)}
        extra={extra || I18n.t('senderEmailAddressInfo', { ...emailTrOpt, domainTitle: I18n.t('settings.preferredDomain.title') })}
        style={formItemStyle}
        required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <SenderAddressInputContainer>
          <Input name='prefix' value={prefix} onChange={this.handleInputChange} disabled={disabled} />
          <SenderAddressFieldAtSign>
            <span>@</span>
          </SenderAddressFieldAtSign>
          <Input name='subdomain' value={subdomain} onChange={this.handleInputChange} disabled={disabled} />
          <SenderAddressFieldDomain>
            <span>.{appDomain}</span>
          </SenderAddressFieldDomain>
          {resetValue && <Button type='primary' icon='undo' onClick={this.handleResetClick} disabled={disabled}>{I18n.t('common.reset')}</Button>}
        </SenderAddressInputContainer>
      </Form.Item>
    )
  }
}

const Divider = styled(_Divider)`
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.primary};
  }
`

const DividerLrg = styled(_Divider)`
  :not(:first-child) {
    padding-top: 16px;
  }
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.primary};
    font-size: 1.6rem;
  }
`

/**
 * @typedef {Object} SectionDividerProps
 * @property {boolean} visible
 * @property {string} title
 * @property {string} description
 * @property {('large' | 'default')} size
 * @property {React.ReactNode} children
 */

/**
 * @param {SectionDividerProps} props
 * @returns {React.ReactNode}
 */
export const SectionDivider = ({ visible, title, description, size, children }) => (
  <>
    {size === 'large' ? <DividerLrg>{title}</DividerLrg> : <Divider>{title}</Divider>}
    {_isString(description) && <p>{description}</p>}
    {children}
  </>
)

export const SectionDividerField = React.forwardRef(({ visible, title, description, size, children }, ref) => {
  if (!visible) {
    return null
  }

  return (
    <SectionDivider {...{ title, description, size, children }} />
  )
})
