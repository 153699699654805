import React, { useCallback, useEffect, useState } from 'react'
import { Popover, Select, Tag } from 'antd'
import styled, { css, withTheme } from 'styled-components'
import I18n from 'i18n-js'
import _findLast from 'lodash/findLast'
import _isEmpty from 'lodash/isEmpty'
import _last from 'lodash/last'
import _sortBy from 'lodash/sortBy'
import TooltipIcon from '../common/TooltipIcon'

const trOpt = { scope: 'uPolicy.policyVersion' }
const { Option } = Select

const VersionField = styled.div`
  label {
    margin-right: 10px;
  }

  .ant-select {
    width: ${({ width = 90 }) => width}px;
  }
`

const _VersionTag = ({ color, useColor, children, ...props }) => <Tag {...props} color={useColor ? color : null}>{children}</Tag>
const VersionTag = styled(_VersionTag)`
  ${
    ({ useColor = true, color }) => useColor ? '' : css`
    background-color: transparent;
    border-color: ${color};
    color: ${color};
    `
  }
`

export const PolicyVersionTag = withTheme(({ className, major = false, draft = false, archived = false, versionNo, theme }) => {
  let color = theme.primary
  if (draft) {
    color = theme.orange
  } else if (major) {
    color = theme.green
  }

  return (
    <VersionTag className={className} color={color} useColor={!archived}>{versionNo}</VersionTag>
  )
})

const TagInfo = styled.div`
  margin-bottom: 10px;
`
const PolicyVersionTagInfo = ({ template, showInDraft, showArchived = true, showDraft = true }) => (
  template
    ? (
      <>
        <TagInfo><PolicyVersionTag versionNo={I18n.t('uPolicy.common.live')} />{I18n.t('liveTemplate', trOpt)}</TagInfo>
        {showDraft && <TagInfo><PolicyVersionTag versionNo={I18n.t('uPolicy.common.inDraft')} draft />{I18n.t('draftTemplate', trOpt)}</TagInfo>}
      </>
    ) : (
      <>
        {showArchived && (
          <>
            <TagInfo><PolicyVersionTag versionNo='vX.X' major archived />{I18n.t('archivedMajorVersion', trOpt)}</TagInfo>
            <TagInfo><PolicyVersionTag versionNo='vX.X' archived />{I18n.t('archivedMinorVersion', trOpt)}</TagInfo>
          </>
        )}
        <TagInfo><PolicyVersionTag versionNo='vX.X' major />{I18n.t('liveOrLastMajorVersion', trOpt)}</TagInfo>
        <TagInfo><PolicyVersionTag versionNo='vX.X' />{I18n.t('liveMinorVersion', trOpt)}</TagInfo>
        {showDraft && <TagInfo><PolicyVersionTag versionNo={showInDraft ? I18n.t('uPolicy.common.inDraft') : I18n.t('draft', trOpt)} draft />{I18n.t('draftPolicy', trOpt)}</TagInfo>}
      </>
    )
)

export const ViewPolicyVersionFieldInfo = (props) => {
  const { template } = props || {}
  return (
    <Popover
      title={I18n.t(template ? 'templateVersionTags' : 'policyVersionTags', trOpt)}
      content={<PolicyVersionTagInfo {...props} />}
    >
      <span><TooltipIcon /></span>
    </Popover>
  )
}

export const getPolicyVersionOptions = (policy, includeAggregatorOptions = false, reverse = true, draft = true) => {
  let versionOptions = []

  if (policy && policy.template) {
    const { document, draftDocument, file, draftFile } = policy
    if (document || file) {
      versionOptions.push({ value: 'live', label: <PolicyVersionTag versionNo={I18n.t('uPolicy.common.live')} /> })
    }
    if (draftDocument || draftFile) {
      versionOptions.push({ value: 'draft', label: <PolicyVersionTag draft versionNo={I18n.t('draft', trOpt)} /> })
    }
  } else if (policy) {
    const documents = _sortBy((policy.documents || []), ['majorVersion', 'minorVersion'])
    const lastMajor = _findLast(documents, doc => doc.major)
    versionOptions = documents.map(policyDocument => {
      const { id, status, major, majorVersion, minorVersion } = policyDocument
      const isLastMajor = lastMajor && lastMajor.id === id
      let value = status
      if (isLastMajor && status !== 'live') {
        value = 'major'
      } else if (status === 'archived') {
        value = id
      }
      return {
        value,
        id,
        label: (
          <PolicyVersionTag
            draft={status === 'draft'}
            major={major}
            archived={status === 'archived' && !isLastMajor}
            versionNo={status === 'draft' ? I18n.t('draft', trOpt) : `v${majorVersion}.${minorVersion}`}
          />
        )
      }
    })
    if (reverse) {
      versionOptions.reverse()
    }
    if (!draft) {
      versionOptions = versionOptions.filter(option => option.value !== 'draft')
    }

    if (includeAggregatorOptions) {
      versionOptions.unshift({
        value: 'all', label: I18n.t('uPolicy.common.allVersions')
      })
    }
  }

  return versionOptions
}

const ViewPolicyVersionField = ({ policy, width, includeAggregatorOptions = false, reverse = true, draft = true, version, setVersion = () => {} }) => {
  const [versionOptions, setVersionOptions] = useState([])
  const onVersionChange = useCallback(version => setVersion(version), [setVersion])

  useEffect(() => {
    setVersionOptions(getPolicyVersionOptions(policy, includeAggregatorOptions, reverse, draft))
  }, [policy, includeAggregatorOptions, reverse, draft])

  useEffect(() => {
    const values = versionOptions.map(({ value }) => value)
    if (policy && !_isEmpty(policy) && !_isEmpty(values) && (!version || !values.includes(version))) {
      if (version !== 'major' && values.includes('all')) {
        setVersion('all')
      } else if (values.includes('live')) {
        setVersion('live')
      } else if (values.includes('draft')) {
        setVersion('draft')
      } else {
        const lastOption = _last(versionOptions)
        setVersion(lastOption ? lastOption.value : null)
      }
    }
  }, [policy, version, versionOptions, setVersion])

  return (
    <VersionField {...{ width }}>
      <label>{I18n.t('uPolicy.common.version')} <ViewPolicyVersionFieldInfo template={policy ? policy.template : false} showDraft={draft} /> :</label>
      <Select value={version} onChange={onVersionChange}>
        {
          versionOptions.map(({ value, label }) => (<Option key={value} value={value}>{label}</Option>))
        }
      </Select>
    </VersionField>
  )
}

export default ViewPolicyVersionField
