import React from 'react'
import { Modal, Button } from 'antd'
import { useMutation } from 'react-apollo'
import styled, { withTheme } from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { ActionContainer, DescriptionContainer, SettingsContainer as _SettingsContainer, CopyToClipboard } from './common'
import { ErrorAlerts, LoadingBlock } from '../common'
import IntercomHeader from '../IntercomHeader'

const SettingsContainer = styled(_SettingsContainer)`
  padding-right: 25px;
`

const ApiKeyContainer = styled.div`
  padding: 10px;
  word-break: break-all;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secondary};
  margin-bottom: 10px;
  
  p {
    margin-bottom: 0
  }
`

const RegenerateApiKeyContainer = styled.div`
  margin-top: 10px;
`

const ApiContainerContent = ({ apiKey, mutation, trOpt }) => {
  const [generateApiKey, { loading, error }] = useMutation(mutation)

  const showConfirmRegenerateKey = () => {
    Modal.confirm({
      title: I18n.t('regenerateKeyConfirmDialog.title', trOpt),
      content: I18n.t('regenerateKeyConfirmDialog.content', trOpt),
      async onOk () {
        await generateApiKey()
      }
    })
  }

  if (loading) return <LoadingBlock fullScreen={false} loading />
  if (error) return <ErrorAlerts {...{ error }} defaultError={I18n.t('regenerateKeyError', trOpt)} />

  if (apiKey) {
    return (
      <>
        <ActionContainer>
          <p>{I18n.t('hasKeyDescription1', trOpt)}</p>
          <ApiKeyContainer>
            <p>{apiKey}</p>
          </ApiKeyContainer>
          <CopyToClipboard value={apiKey} />
        </ActionContainer>
        <RegenerateApiKeyContainer>
          <p>{I18n.t('hasKeyDescription2', trOpt)}</p>
          <Button type='primary' icon='ReloadOutlined' onClick={showConfirmRegenerateKey}>{I18n.t('regenerateApiKey', trOpt)}</Button>
        </RegenerateApiKeyContainer>
      </>
    )
  } else {
    return (
      <ActionContainer>
        <p>{I18n.t('noKeyDescription', trOpt)}</p>
        <Button type='primary' icon='ReloadOutlined' onClick={generateApiKey}>{I18n.t('generateApiKey', trOpt)}</Button>
      </ActionContainer>
    )
  }
}

const UpdateAPIKey = ({ settings, keyId, trOpt, mutation }) => {
  const apiKey = _get(settings, ['api', keyId])

  return (
    <SettingsContainer>
      <IntercomHeader Size='h1' id='settings-update-api-key-header'>{I18n.t('title', trOpt)}</IntercomHeader>
      <DescriptionContainer>
        <p>{I18n.t('description', trOpt)}</p>
      </DescriptionContainer>
      <ApiContainerContent {...{ apiKey, mutation, trOpt }} />
    </SettingsContainer>
  )
}

export default withTheme(UpdateAPIKey)
