import I18n from 'i18n-js'

import authenticatedClient from '../apollo-client/authenticatedClient'
import { UPDATE_GOOGLE_AUTH, UPDATE_GOOGLE_SYNC_SETTINGS, GET_GOOGLE_AUTHORISE_URL } from '../components/Queries/Companies'
import { showErrors } from './ErrorHandler'
import routes from '../constants/routes'

const trOpt = { scope: 'googleSync.helpers' }

class Google {
  constructor ({ app, redirectUri, client = authenticatedClient } = {}) {
    this.app = app
    this.redirectUri = redirectUri
    this.client = client
  }

  async getAuthorizeUrl (app = this.app) {
    const { data: { getGoogleAuthUrl: url } } = await this.client.query({
      query: GET_GOOGLE_AUTHORISE_URL,
      variables: { app, redirectUri: this.redirectUri },
      fetchPolicy: 'network-only'
    })
    return url
  }

  async updateAuthConfig ({
    app = this.app, authType, authCode, serviceAccountDelegatedUserEmail, serviceAccountCredentials, redirectUri = this.redirectUri
  } = {}) {
    try {
      return this.client.mutate({
        mutation: UPDATE_GOOGLE_AUTH,
        variables: {
          app,
          authType,
          authCode,
          redirectUri,
          serviceAccountDelegatedUserEmail,
          serviceAccountCredentials
        }
      })
    } catch (e) {
      console.error('Google.updateAuthConfig - error', typeof e, e)
      showErrors(e, I18n.t('theGoogleAuthenticationProcessFailed', trOpt))
    }
  }
}

export class GoogleSync extends Google {
  constructor () {
    super({
      app: 'sync',
      redirectUri: `${window.location.origin}${routes.GOOGLE_SYNC_SETUP}`
    })
  }

  async updateSettings (settings) {
    try {
      return this.client.mutate({
        mutation: UPDATE_GOOGLE_SYNC_SETTINGS,
        variables: { settings }
      })
    } catch (e) {
      console.error('GoogleSync.updateSettings - error', typeof e, e)
      showErrors(e, I18n.t('yourGoogleIntegrationSettings', trOpt))
    }
  }
}

export default Google
