import styled from 'styled-components'

// This provides an anchor point for Ant D tooltips, popovers etc.
// It means that they scroll with the element they're assoicated with rather than get stuck.
// Extending this styled component also makes it possible to style those elements e.g. menus
const PopUpContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
`

export default PopUpContainer
