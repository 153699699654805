import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Card, Button, Modal, Statistic, Tag } from 'antd'
import styled, { withTheme } from 'styled-components'
import { rgba } from 'polished'
import moment from 'moment'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import _sortBy from 'lodash/sortBy'
import _mean from 'lodash/mean'
import _min from 'lodash/min'
import _max from 'lodash/max'

import { FontAwesomeIcon } from '../common'
import { getDurationString } from '../../helpers/duration'
import { isLocaleEnglish } from '../../helpers/locale'
import { sessionLocale } from '../../state/selectors/session'
import { connect } from '../../hocs'
import { getSimEventStartingPoint } from '../../helpers/riskReport'
import { formatDate } from '../../helpers/datetime'
import { BreachCard, BreachCardContainer, BreachCardScroller } from '../Reports/RiskReport'

const InfoModal = styled(Modal)`
  max-width: 520px;

  .ant-modal-header {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
  }
  .ant-modal-content {
    border-radius: 8px;
  }

  &.domain-scan-info {
    .ant-modal-body {
      padding-top: 0px;
    }
    &-apps {
      max-width: 800px;
      &&--narrow {
        max-width: 520px;
      }
    }
  }
  &.sim-event-info {
    max-width: 800px;
  }
  &.breach-category-info {
    height: calc(100% - 150px);
    max-width: 1200px;

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .ant-modal-body {
      flex: 1;
      min-height: 0;
    }
  }
`

const _DomainScanInfoIcon = ({ className, children }) => <div {...{ className }}>{children}</div>
const DomainScanInfoIcon = styled(_DomainScanInfoIcon)`
  align-items: center;
  background-color: ${({ color }) => rgba(color, 0.2)};
  border-radius: 45px;
  color: ${({ color }) => color};
  display: flex;
  flex: 0 0 45px;
  height: 45px;
  justify-content: center;
  margin-right: 15px;
  width: 45px;

  i {
    font-size: 18px;
  }
`

const dsiTrOpt = { count: 0, scope: 'riskReport.domainScan.title' }
const _DomainScanInfoTitle = withTheme(({ type, className, theme }) => {
  let title
  let color
  switch (type) {
    case 'domainScanDomains':
      title = I18n.t('domains', dsiTrOpt)
      color = theme.defaultPrimary
      break
    case 'domainScanServices':
      title = I18n.t('services', dsiTrOpt)
      color = theme.cta
      break
    case 'domainScanLookalikes':
      title = I18n.t('lookalikeDomains', dsiTrOpt)
      color = '#8606a6'
      break
    case 'domainScanApps':
      title = I18n.t('applications', dsiTrOpt)
      color = '#e41e2a'
      break
    default:
      title = '<TITLE>'
      color = theme.defaultPrimary
      break
  }

  return (
    <div {...{ className }}>
      <DomainScanInfoIcon {...{ color }}>
        <FontAwesomeIcon icon='comment-alt' />
      </DomainScanInfoIcon>
      <span>{title}</span>
    </div>
  )
})
const DomainScanInfoTitle = styled(_DomainScanInfoTitle)`
  align-items: center;
  display: flex;

  span {
    font-size: 22px;
    margin-top: 6px;
  }
`

const AppCard = styled(Card)`
  border-radius: 8px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 5px;
  }

  .ant-card-body {
    padding-bottom: 12px;
  }

  .domain-info-app-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    h4 {
      margin-bottom: 0;
    }


    .ant-btn {
      i {
        top: 0;
      }
      span {
        margin-top: 7px;
      }
    }
  }

  p {
    font-family: unset;
  }

  .ant-tag {
    margin-bottom: 8px;
  }
`

const DomainInfoContainer = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  max-height: 500px;
  overflow: auto;
  padding: 10px 20px;
`

const DomainScanInfo = ({ type, riskReport, locale }) => {
  let content
  const inEng = isLocaleEnglish(locale)

  switch (type) {
    case 'domainScanDomains':
      content = (_get(riskReport, 'domainScan.domains') || []).map((domain, index) => (
        <div key={index}>{domain}</div>
      ))
      break
    case 'domainScanServices':
      content = (_get(riskReport, 'domainScan.services') || []).map((service, index) => (
        <div key={index}>{service}</div>
      ))
      break
    case 'domainScanLookalikes':
      content = (_get(riskReport, 'domainScan.lookalikeDomains') || []).map((domain, index) => (
        <div key={index}>{domain}</div>
      ))
      break
    case 'domainScanApps':
      content = _sortBy(_get(riskReport, 'domainScan.applications') || [], 'name').map((application, index) => {
        const { name, description, link, categories, tag } = application
        const tags = []
        if (tag) {
          tags.push(tag)
        }
        if (_isArray(categories)) {
          tags.push(...categories)
        }
        // BuiltWith Technology descriptions and categories tags are only available in English and have been hidden in other languages
        // The UI adapts to maximise the number of cards displayed per row
        return (
          <AppCard
            key={index}
          >
            <div className='domain-info-app-title'>
              <h4>{name}</h4>
              <Button type='primary' ghost href={link} target='_blank' icon='info-circle' size='small'>{I18n.t('riskReport.domainScan.appMoreInfo')}</Button>
            </div>
            {inEng && (
              <>
                <p>{description}</p>
                <div>
                  {tags.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                  ))}
                </div>
              </>
            )}
          </AppCard>
        )
      })
      break
    default:
      content = null
      break
  }

  return (
    <DomainInfoContainer>{content}</DomainInfoContainer>
  )
}

const EVENT_TYPES = ['open', 'visit', 'compromise']
const EVENT_TYPE_MAP = {
  simEventOpen: 'open',
  simEventVisit: 'visit',
  simEventCompromise: 'compromise'
}
const DEFAULT_EVENT_STATS = {
  total: 0,
  count: { open: 0, visit: 0, compromise: 0 },
  avg: { open: 0, visit: 0, compromise: 0 },
  min: { open: 0, visit: 0, compromise: 0 },
  max: { open: 0, visit: 0, compromise: 0 }
}

const EventInfo = styled.div`
  margin-bottom: 30px;
  text-align: center;

  .ant-statistic {
    display: inline-block;

    & + .ant-statistic {
      margin-left: 15px;

      .ant-statistic-content {
        &:before {
          content: '(';
          font-size: 14px;
        }
        &:after {
          content: ')';
          font-size: 14px;
        }
        &-value {
          font-size: 14px;
        }
        &-suffix {
          font-size: 10px;
        }
      }

    }
  }
`

const EventInfoContainer = styled.div`
  display: flex;
  justify-content: space-around;

  ${EventInfo} {
    min-width: 50%;
  }
`

const simEvtTrOpt = { scope: 'riskReport.uPhish.eventInfo' }
const _SimulationEventInfo = ({ className, type, riskReport }) => {
  const eventType = EVENT_TYPE_MAP[type]
  const prevEventType = eventType ? EVENT_TYPES[EVENT_TYPES.indexOf(eventType) - 1] : undefined

  const [eventStats, setEventStats] = useState(DEFAULT_EVENT_STATS)

  useEffect(() => {
    const allResults = _get(riskReport, 'simulation.results') || []
    if (allResults.length === 0) {
      setEventStats(DEFAULT_EVENT_STATS)
    }

    const { count, avg, min, max } = EVENT_TYPES.reduce((acc, eventType) => {
      const results = allResults.filter(({ [eventType]: eventAt }) => !_isNil(eventAt))
      const eventDiffs = results.map(result => {
        const { [getSimEventStartingPoint(eventType)]: startedAt, [eventType]: eventAt } = result
        return moment(eventAt).diff(startedAt)
      })

      acc.count[eventType] = results.length
      acc.avg[eventType] = _mean(eventDiffs)
      acc.min[eventType] = _min(eventDiffs)
      acc.max[eventType] = _max(eventDiffs)

      return acc
    }, {
      count: { open: 0, visit: 0, compromise: 0 },
      avg: { open: 0, visit: 0, compromise: 0 },
      min: { open: 0, visit: 0, compromise: 0 },
      max: { open: 0, visit: 0, compromise: 0 }
    })

    setEventStats({
      total: allResults.length,
      count,
      avg,
      min,
      max
    })
  }, [type, riskReport])

  const { total, count, avg, min, max } = eventStats

  const durationStrOpt = { maxUnits: 2 }
  const avgDuration = getDurationString(avg[eventType], durationStrOpt)
  const minDuration = getDurationString(min[eventType], durationStrOpt)
  const maxDuration = getDurationString(max[eventType], durationStrOpt)

  const eventPercentage = eventType && total > 0 ? Math.round((count[eventType] / total) * 100) : 0
  const prevEventPercentage = prevEventType && count[prevEventType] > 0 ? Math.round((count[eventType] / count[prevEventType]) * 100) : 0

  const eventTitle = I18n.t(`eventTitle.${eventType}`, { ...simEvtTrOpt, defaultValue: I18n.t('eventTitle.default', simEvtTrOpt) })
  const prevEventTitle = I18n.t(`prevEventTitle.${eventType}`, { ...simEvtTrOpt, defaultValue: I18n.t('prevEventTitle.default', simEvtTrOpt) })
  const durationSuffix = I18n.t(`durationSuffix.${eventType}`, { ...simEvtTrOpt, defaultValue: I18n.t('durationSuffix.default', simEvtTrOpt) })

  return (
    <div {...{ className }}>
      <EventInfoContainer>
        {
          prevEventType && (
            <EventInfo>
              <h4>{prevEventTitle}</h4>
              <Statistic value={prevEventPercentage} suffix='%' />
              <Statistic value={count[eventType]} suffix={`/ ${count[prevEventType]}`} />
            </EventInfo>
          )
        }
        <EventInfo>
          <h4>{eventTitle}</h4>
          <Statistic value={eventPercentage} suffix='%' />
          <Statistic value={count[eventType]} suffix={`/ ${total}`} />
        </EventInfo>
      </EventInfoContainer>
      <EventInfoContainer>
        <EventInfo>
          <Statistic title={<h4>{I18n.t('averageTime', { ...simEvtTrOpt, durationSuffix })}</h4>} value={avgDuration} />
        </EventInfo>
      </EventInfoContainer>
      <EventInfoContainer>
        <EventInfo>
          <Statistic title={<h4>{I18n.t('minTime', { ...simEvtTrOpt, durationSuffix })}</h4>} value={minDuration} />
        </EventInfo>
        <EventInfo>
          <Statistic title={<h4>{I18n.t('maxTime', { ...simEvtTrOpt, durationSuffix })}</h4>} value={maxDuration} />
        </EventInfo>
      </EventInfoContainer>
    </div>
  )
}
const SimulationEventInfo = styled(_SimulationEventInfo)`
  & > .ant-statistic {
    margin-bottom: 30px;

  }
  .ant-statistic-content {
    color: ${({ theme }) => theme.defaultPrimary};

    &-value {
      font-size: 30px;
    }
    &-suffix {
      font-size: 20px;
    }
  }
`

const BREACH_CATEGORY_MAP = {
  breachCategoryLow: 'low',
  breachCategoryMedium: 'medium',
  breachCategoryHigh: 'high'
}

const BreachCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .ant-statistic {
    margin-bottom: 30px;
  }
  .ant-statistic-content {
    color: ${({ theme }) => theme.defaultPrimary};

    &-value {
      font-size: 30px;
    }
    &-suffix {
      font-size: 20px;
    }
  }
`

const DEFAULT_CATEGORY_INFO_DATA = { count: 0, value: 0, totalLearners: 0, services: [] }
const breachTrOpt = { scope: 'riskReport.uBreach.categoryInfo' }
const BreachCategory = withTheme(({ type, riskReport, locale, theme }) => {
  const [data, setData] = useState(DEFAULT_CATEGORY_INFO_DATA)
  useEffect(() => {
    const category = BREACH_CATEGORY_MAP[type]
    const breached = _get(riskReport, 'uBreachStats.breached') || 0
    if (category && breached > 0) {
      const services = (_get(riskReport, 'uBreachStats.services') || []).reduce((acc, service) => {
        if (service.category === category) {
          acc.push({
            ...service,
            title: service.title || service.name,
            breachDate: formatDate(service.breachDate),
            addedDate: formatDate(service.addedDate),
            modifiedDate: formatDate(service.modifiedDate)
          })
        }
        return acc
      }, [])
        // Sort by count desc, title asc
        .sort((a, b) => {
          if (a.count < b.count) {
            return 1
          }
          if (a.count > b.count) {
            return -1
          }
          return a.title.localeCompare(b.title)
        })
      const notBreached = _get(riskReport, 'uBreachStats.notBreached') || 0
      const totalLearners = breached + notBreached
      const count = _get(riskReport, `uBreachStats.breachedByCategory.${category}`) || 0
      const value = count > 0 && totalLearners > 0 ? Math.round((count / totalLearners) * 100) : 0

      setData({ count, totalLearners, value, services })
    } else {
      setData(DEFAULT_CATEGORY_INFO_DATA)
    }
  }, [type, riskReport])

  const inEng = isLocaleEnglish(locale)
  const { count = 0, value = 0, totalLearners = 0, services = [] } = data || {}

  // HIBP Breach descriptions are only available in English
  return (
    <BreachCategoryContainer>
      <EventInfoContainer>
        <EventInfo>
          <h4>{I18n.t('usersAffected', breachTrOpt)}</h4>
          <Statistic value={value} suffix='%' />
          <Statistic value={count} suffix={`/ ${totalLearners}`} />
        </EventInfo>
        <EventInfo>
          <h4>{I18n.t('breachesDetected', breachTrOpt)}</h4>
          <Statistic value={services.length} />
        </EventInfo>
      </EventInfoContainer>
      <BreachCardScroller>
        <BreachCardContainer>
          {
            services.map((service, index) => (
              <BreachCard
                key={index}
                {
                  ...{
                    service,
                    inEng,
                    theme
                  }
                }
              />
            ))
          }
        </BreachCardContainer>
      </BreachCardScroller>
    </BreachCategoryContainer>
  )
})

const RiskReportInfoModal = React.forwardRef(({ riskReport, locale }, ref) => {
  const [visible, setVisible] = useState(false)
  const [type, setType] = useState(null)

  const closeModal = useCallback(() => setVisible(false), [])
  useImperativeHandle(ref, () => ({
    open: type => {
      if (type) {
        setType(type)
        setVisible(true)
      }
    },
    close: closeModal
  }), [closeModal])

  const afterClose = useCallback(() => {
    setVisible(false)
    setType(null)
  }, [])

  const { modalProps, content } = useMemo(() => {
    const modalProps = { width: '90%' }
    let content = null
    if (type) {
      const contentProps = { type, riskReport, locale }
      if (type.indexOf('domainScan') !== -1) {
        modalProps.className = [type === 'domainScanApps' ? 'domain-scan-info-apps' : 'domain-scan-info']
        if (!isLocaleEnglish(locale)) {
          modalProps.className.push('domain-scan-info-apps--narrow')
        }
        modalProps.title = <DomainScanInfoTitle {...{ type }} />
        content = <DomainScanInfo {...contentProps} />
      } else if (type.indexOf('simEvent') !== -1) {
        modalProps.className = 'sim-event-info'
        modalProps.title = I18n.t('riskReport.uPhish.eventInfo.title', {
          label: I18n.t(`riskReport.uPhish.interactions.label.${EVENT_TYPE_MAP[type]}`, { defaultValue: 'Event' })
        })
        content = <SimulationEventInfo {...contentProps} />
      } else if (type.indexOf('breachCategory') !== -1) {
        modalProps.className = 'breach-category-info'
        modalProps.title = I18n.t(`riskReport.uBreach.categoryInfo.title.${BREACH_CATEGORY_MAP[type]}`)
        content = <BreachCategory {...contentProps} />
      }
    }

    return {
      modalProps,
      content
    }
  }, [riskReport, locale, type])

  return (
    <InfoModal
      visible={visible}
      footer={null}
      destroyOnClose
      onCancel={closeModal}
      afterClose={afterClose}
      {...modalProps}
    >
      {content}
    </InfoModal>
  )
})

export default connect(
  state => ({ locale: sessionLocale(state) })
)(RiskReportInfoModal)
