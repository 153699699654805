import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { generatePath, Link, withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Button, Card, Icon, Layout, Tooltip, message } from 'antd'
import compose from 'recompose/compose'
import styled from 'styled-components'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'
import _isNil from 'lodash/isNil'
import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _set from 'lodash/set'
import _uniq from 'lodash/uniq'
import _throttle from 'lodash/throttle'
import I18n from 'i18n-js'

import routes from '../../constants/routes'
import { CREATE_COURSE, GET_COURSE, UPDATE_COURSE } from '../Queries/Courses'
import { MutationFormInput, MutationFormTextArea } from '../MutationForm'
import MutationForm from '../MutationForm/MutationForm'
import { GET_COMPANIES } from '../Queries/Companies'
import { ErrorAlerts, FontAwesomeIcon, ListHeader, ListHeaderPanel, LoadingBlock } from '../common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import {
  buildCompanyOptions,
  getCompanyAccessExtra,
  getCompanyAccessLabel,
  getCompanyAccessPlaceholder,
  getGlobalExtra,
  getGlobalLabel,
  managedByUsecure
} from '../../helpers/company'
import { base64EncodeJSON, renderToString } from '../../helpers'
import LanguageDropdown from '../common/LanguageDropdown'
import { getCourseCategoryOptionsByLocale, getCourseSubjectOptionsByLocale } from '../../constants/courses'
import { DEFAULT_LANGUAGE, LANGUAGE_NAMES_BY_CODE, LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { getCoursesRefetchQueries } from '../../helpers/courses'
import IntercomHeader from '../IntercomHeader'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const Content = Layout.Content

const trOpt = { scope: 'editCourse.editCourseForm' }

const isIncludedInAutoEnrolAllowed = values => {
  return managedByUsecure(values) &&
    [1, 2, 3, 4].includes(values.difficulty) &&
    values.subject === 'InfoSec' &&
    values.global === true
}

const isLocaleFieldValueEmpty = localeValue => !(_isString(localeValue) && !_isEmpty(localeValue.replace(/\s/g, '')))
const mutateLocaleFieldValue = (values, fieldId) => base64EncodeJSON(_get(values, `details.${fieldId}`) || {})

const nameAndDescriptionValidator = (value, errors, opt, values) => {
  const { locale: sourceLocale = DEFAULT_LANGUAGE } = values || {}
  const locales = _uniq([sourceLocale, ...Object.keys(value.name || {}), ...Object.keys(value.description || {})])
  if (locales.length > 0) {
    locales.forEach(locale => {
      const isSource = locale === sourceLocale
      const localeName = _get(value, `name.${locale}`)
      const nameValid = !isLocaleFieldValueEmpty(localeName)
      const localeDescription = _get(value, `description.${locale}`)
      const descriptionValid = !isLocaleFieldValueEmpty(localeDescription)
      if ((!nameValid && descriptionValid) || (isSource && !nameValid) || (localeName && !nameValid)) {
        errors.push({ fieldId: 'name', text: I18n.t('pleaseAddACourseTitle', { ...trOpt, language: LANGUAGE_NAMES_BY_CODE[locale] }) })
      }
      if ((nameValid && !descriptionValid) || (isSource && !descriptionValid) || (localeDescription && !descriptionValid)) {
        errors.push({ fieldId: 'description', text: I18n.t('pleaseAddACourseDescription', { ...trOpt, language: LANGUAGE_NAMES_BY_CODE[locale] }) })
      }
    })
  }
}

const NameAndDescriptionFieldContainer = styled.div`
  margin-bottom: 24px;

  .ant-form-item {
    margin-bottom: 0;
  }
`

const NameMutationFormInput = styled(MutationFormInput)`
  &> .ant-form-item-label {
    display: block;

    &> label {
      display: block;

      &::after {
        display: none;
      }

      .ant-form-item-label {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`

const NameAndDescriptionField = React.forwardRef(({
  id, value, visible, onChange = () => {}, errors = [], sourceLocale = DEFAULT_LANGUAGE
}, ref) => {
  const [locale, setLocale] = useState(sourceLocale)
  const name = _get(value, `name.${locale}`)
  const description = _get(value, `description.${locale}`)

  useImperativeHandle(ref, () => ({
    validate: nameAndDescriptionValidator
  }), [])

  const onFieldChange = useCallback((fieldId, fieldValue) => {
    _set(value, `${fieldId}.${locale}`, fieldValue)
    onChange(id, value)
  }, [id, locale, value, onChange])

  useEffect(() => {
    setLocale(sourceLocale)
  }, [sourceLocale])

  return (
    <NameAndDescriptionFieldContainer>
      <NameMutationFormInput {...{
        id: 'name',
        label: (
          <div className='ant-col ant-form-item-label'>
            <label className='ant-form-item-required' title={I18n.t('common.courseTitle')}>{I18n.t('common.courseTitle')}</label>
            <LanguageDropdown value={locale} onChange={setLocale} />
          </div>
        ),
        placeholder: I18n.t('common.courseTitle'),
        value: name,
        formItemStyle: { maxWidth: 600 },
        onChange: onFieldChange,
        visible,
        errors: errors.filter(e => e.fieldId === 'name').map(e => e.text)
      }}
      />
      <MutationFormTextArea {...{
        id: 'description',
        label: <label className='ant-form-item-required' title={I18n.t('courseDescription', trOpt)}>{I18n.t('courseDescription', trOpt)}</label>,
        value: description,
        type: 'textarea',
        formItemStyle: { maxWidth: 600 },
        textAreaStyle: { minHeight: 140 },
        onChange: onFieldChange,
        visible,
        errors: errors.filter(e => e.fieldId === 'description').map(e => e.text)
      }}
      />
    </NameAndDescriptionFieldContainer>
  )
})

const EditCourseMutationForm = styled(MutationForm)`
  .edit-course-icon {
    max-width: 800px;

    .ant-input {
      max-width: 250px;
    }
  }
`

const EditCourseIconExtra = styled.div`
  &> span {
    display: block;
  }
`

// Awkward method of detection for font-awesome icon support by whether i rendered with a height > 0px
// Ideally we'd check against a list of icon ids
let intv
const resetIconHeightCheck = () => {
  if (intv) {
    clearInterval(intv)
    intv = null
  }
}
const checkIconHeight = _throttle((iconRef, setValid) => {
  const start = Date.now()
  resetIconHeightCheck()
  intv = setInterval(() => {
    const { current: iconEl } = iconRef
    if (iconEl?.offsetHeight > 0 || Date.now() - start > 1000) {
      setValid(iconEl?.offsetHeight > 0)
      resetIconHeightCheck()
    }
  }, 100)
}, 500)

const _CourseIconPreview = ({ className, icon }) => {
  const [valid, setValid] = useState(false)
  const iconRef = useRef(null)

  useEffect(() => {
    if (icon) {
      setValid(true)
      checkIconHeight(iconRef, setValid, icon)
    }
  }, [iconRef, icon])

  return (
    <div className={className}>
      <FontAwesomeIcon ref={iconRef} icon={icon} />
      {!valid && (
        <Tooltip title={I18n.t('courseIconNotSupported', trOpt)} placement='bottom'>
          <Icon type='question-circle' />
        </Tooltip>
      )}
    </div>
  )
}
const CourseIconPreview = styled(_CourseIconPreview)`
  align-items: center;
  border: dashed 1px #e5e5e5;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 5px;
  width: 50px;

  i {
    font-size: 20px;

    &.anticon {
      color: ${({ theme }) => theme.red};
    }
  }
`

const EditCourseForm = ({
  courseId, companyId, accountType, locale, companyLocale: companyDefaultLocale,
  history, onSuccess: onSuccessProp, onFailure
}) => {
  const [accessControlOpened, setAccessControlOpened] = useState(false)
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const isUpdate = !_isNil(courseId)

  const hasSuperAccessControlPermission = hasAllSessionPermissions([permissions.COURSE_SUPER_ACCESS_CONTROL])
  const hasAccessControlPermission = hasAllSessionPermissions([permissions.COURSE_ACCESS_CONTROL])

  const canUseAccessControl = hasSuperAccessControlPermission || (hasAccessControlPermission && ['distributor', 'msp'].includes(accountType))

  const hasSuperPermission = hasAllSessionPermissions(isUpdate ? [permissions.COURSE_SUPER_UPDATE] : [permissions.COURSE_SUPER_CREATE])

  const { loading: courseLoading, error: courseError, data: courseData = {} } = useQuery(GET_COURSE, {
    variables: { courseId, forUpdate: isUpdate },
    skip: !isUpdate,
    fetchPolicy: 'no-cache'
  })
  const { loading: companiesLoading, error: companiesError, data: companiesData } = useQuery(GET_COMPANIES, {
    variables: {
      withAccountType: hasSuperAccessControlPermission,
      withParentCompanyId: hasSuperAccessControlPermission,
      descendants: !hasSuperAccessControlPermission
    },
    skip: !accessControlOpened || !canUseAccessControl
  })

  const { course } = courseData || {}

  const form = useRef(null)
  useEffect(() => {
    if (
      course && (
        // COURSE_SUPER_UPDATE is required to edit a course owned by another company
        (!hasSuperPermission && course.companyId !== companyId)
      )) {
      message.error(I18n.t('editCourse.common.youAreNotAllowedToEditThisCourse'))
      history.push(routes.BUILDER)
    }
  }, [course, hasSuperPermission, companyId, history])
  const editContentPath = isUpdate
    ? generatePath(routes.BUILDER_EDIT_SLIDES, { course_id: courseId })
    : null

  useEffect(() => {
    if (isUpdate && course && form?.current?.setInitialValues) {
      form.current.setInitialValues({
        ...course,
        details: {
          name: course.nameObject ?? {},
          description: course.descriptionObject ?? {}
        },
        includedInAutoEnrol: course.includedInAutoEnrol === true,
        companyId: course.companyId ?? (hasSuperAccessControlPermission ? 'usecure' : companyId),
        companies: course.companies ? course.companies.map(({ id }) => id) : []
      })
    }
  }, [isUpdate, course, form, hasSuperAccessControlPermission, companyId])

  const { companyMap, companySelect } = useMemo(() => {
    const queryCompanies = companiesData?.companies ?? []
    let companies = []
    if (course && (companiesError || queryCompanies.length === 0)) {
      // Use the course's company and company access list as a basis for company select field options to reduce the likelihood of UUIDs being visible
      // This applies either until the company query has loaded or until it fails i.e. companiesError is populated
      // In the event of an error, the corresponding fields will be disabled if companiesError is not empty
      if (course.company) {
        companies.push(course.company)
      }
      if (course.companies) {
        companies.push(...course.companies)
      }
    } else {
      // Otherwise use companies loaded from query
      companies = queryCompanies
    }

    const companyMap = _keyBy(companies, 'id')
    let companySelect = []
    if (hasSuperAccessControlPermission) {
      companySelect = buildCompanyOptions(
        companies.map(company => {
          const linkFieldValue = ['usecure', null]
          if (company.parentCompanyId) {
            linkFieldValue.push(company.parentCompanyId)
            const parentCompany = companyMap[company.parentCompanyId]
            if (parentCompany && parentCompany.accountType === 'msp' && parentCompany.parentCompanyId) {
              linkFieldValue.push(parentCompany.parentCompanyId)
            }
          }
          return { ...company, linkFieldValue }
        })
      )
    } else {
      companySelect = buildCompanyOptions(companies)
    }

    return {
      companyMap,
      companySelect
    }
  }, [companiesData, hasSuperAccessControlPermission, companiesError, course])

  const subjects = useMemo(() => getCourseSubjectOptionsByLocale(locale), [locale])
  const categories = useMemo(() => getCourseCategoryOptionsByLocale(locale), [locale])

  const onTabChange = id => {
    if (id === 'access') {
      setAccessControlOpened(true)
    }
  }

  const onSuccess = useCallback(result => {
    if (_isFunction(onSuccessProp)) {
      onSuccessProp(result)
    }
    if (isUpdate) {
      message.success(I18n.t('successfullyEditedCourse', trOpt))
    } else {
      message.success(I18n.t('successfullyCreatedCourse', trOpt))
    }
  }, [isUpdate, onSuccessProp])

  const getAccessType = useCallback(values => {
    if (managedByUsecure(values)) {
      return 'usecure'
    }
    const viewAccountType = hasSuperAccessControlPermission ? _get(companyMap, `${values.companyId}.accountType`) : accountType
    return viewAccountType || null
  }, [companyMap, hasSuperAccessControlPermission, accountType])

  const isGlobalAllowed = useCallback(values => {
    return ['usecure', 'distributor', 'msp'].includes(getAccessType(values))
  }, [getAccessType])

  const isCompanyAccessAllowed = useCallback(values => {
    return values.global !== true && ['usecure', 'distributor', 'msp'].includes(getAccessType(values))
  }, [getAccessType])

  const { tabs, fields, refetchQueries } = useMemo(() => {
    const disableCompanyFields = !_isNil(companiesError)
    const tabs = [{
      id: 'content',
      title: I18n.t('common.courseLabel')
    }]

    const fields = [
      {
        id: 'details',
        component: NameAndDescriptionField,
        type: 'custom',
        defaultValue: {
          name: {},
          description: {}
        },
        sourceLocale: course?.locale || companyDefaultLocale || DEFAULT_LANGUAGE,
        tab: 'content'
      },
      {
        label: I18n.t('courses.common.locale'),
        id: 'locale',
        type: 'select',
        required: true,
        defaultValue: companyDefaultLocale || DEFAULT_LANGUAGE,
        options: LANGUAGE_SELECT_OPTIONS,
        sortOptions: true,
        tab: 'content'
      },
      {
        label: I18n.t('courses.common.additionalLocales'),
        id: 'additionalLocales',
        type: 'multiSelect',
        defaultValue: [],
        options: LANGUAGE_SELECT_OPTIONS,
        sortOptions: true,
        extra: I18n.t('additionalLocalesExtra', trOpt),
        tab: 'content'
      }
    ]

    if (hasSuperPermission) {
      fields.push({
        label: I18n.t('courseDifficulty', trOpt),
        id: 'difficulty',
        required: true,
        visible: managedByUsecure,
        type: 'number',
        tab: 'content'
      },
      {
        label: I18n.t('courseType', trOpt),
        id: 'subject',
        placeholder: I18n.t('courseType', trOpt),
        required: true,
        visible: managedByUsecure,
        type: 'select',
        options: subjects,
        formItemStyle: { maxWidth: 150 },
        tab: 'content'
      },
      {
        label: I18n.t('courseCategory', trOpt),
        id: 'category',
        placeholder: I18n.t('courseCategory', trOpt),
        required: false,
        visible: managedByUsecure,
        type: 'select',
        options: categories,
        formItemStyle: { maxWidth: 250 },
        tab: 'content'
      })
    }
    fields.push({
      label: I18n.t('courseIcon', trOpt),
      id: 'icon',
      required: true,
      extra: values => (
        <EditCourseIconExtra>
          <span
            dangerouslySetInnerHTML={{
              __html: I18n.t('chooseFromAnyIconListed', {
                ...trOpt,
                fontAwesomeLink: renderToString(<a href='https://fontawesome.com/v5/search?m=free' target='_blank' rel='noopener noreferrer'>{I18n.t('chooseFromAnyIconListedLink', trOpt)}</a>)
              })
            }}
          />
          <span>{I18n.t('courseIconExtraHelp', trOpt)}</span>
          <CourseIconPreview icon={values.icon} />
        </EditCourseIconExtra>
      ),
      className: 'edit-course-icon',
      tab: 'content'
    },
    {
      label: I18n.t('courseBackgroundImage', trOpt),
      id: 'backgroundImage',
      required: false,
      type: 'image',
      tab: 'content'
    },
    {
      label: I18n.t('randomizeAnswerOrder', trOpt),
      id: 'randomizeAnswerOrder',
      required: false,
      type: 'checkbox',
      defaultValue: true,
      extra: I18n.t('randomizeAnswerOrderExtra', trOpt),
      tab: 'content'
    },
    {
      label: I18n.t('randomizeQuestionOrder', trOpt),
      id: 'randomizeQuestionOrder',
      required: false,
      type: 'checkbox',
      defaultValue: false,
      extra: I18n.t('randomizeQuestionOrderExtra', trOpt),
      tab: 'content'
    },
    {
      label: I18n.t('questionLimit', trOpt),
      id: 'questionCount',
      required: false,
      type: 'number',
      visible: ({ randomizeQuestionOrder }) => randomizeQuestionOrder,
      tab: 'content'
    })

    if (hasSuperPermission) {
      fields.push({
        label: I18n.t('includedInAutoEnrol', trOpt),
        id: 'includedInAutoEnrol',
        type: 'checkbox',
        mutateValue: (value, values) => value && isIncludedInAutoEnrolAllowed(values),
        visible: values => isIncludedInAutoEnrolAllowed(values),
        tab: 'content'
      })
    }

    if (canUseAccessControl) {
      tabs.push({
        id: 'access',
        title: I18n.t('common.accessControl.tabTitle'),
        visible: hasSuperAccessControlPermission ? true : values => isGlobalAllowed(values) || isCompanyAccessAllowed(values)
      })
      fields.push({
        label: I18n.t('common.accessControl.ownedBy'),
        id: 'companyId',
        type: 'select',
        required: true,
        options: [
          { value: 'usecure', label: I18n.t('common.managedByUsecure') },
          ...companySelect
        ],
        defaultValue: hasSuperAccessControlPermission ? 'usecure' : companyId,
        mutateValue: value => value === 'usecure' ? null : value,
        visible: hasSuperAccessControlPermission,
        disabled: disableCompanyFields,
        formItemStyle: { maxWidth: 400 },
        tab: 'access'
      }, {
        label: values => getGlobalLabel(getAccessType(values), I18n.t('common.course'), hasSuperAccessControlPermission),
        id: 'global',
        required: false,
        type: 'checkbox',
        defaultValue: false,
        mutateValue: (value, values) => isGlobalAllowed(values) ? value : false,
        visible: isGlobalAllowed,
        extra: values => getGlobalExtra(getAccessType(values), I18n.t('common.course'), hasSuperAccessControlPermission),
        tab: 'access'
      }, {
        id: 'companies',
        label: values => getCompanyAccessLabel(getAccessType(values)),
        type: 'multiSelect',
        required: false,
        placeholder: values => getCompanyAccessPlaceholder(getAccessType(values)),
        extra: values => getCompanyAccessExtra(getAccessType(values), I18n.t('common.course')),
        options: companySelect,
        defaultValue: [],
        mutateValue: (value, values) => isCompanyAccessAllowed(values) ? (value || []) : [],
        visible: isCompanyAccessAllowed,
        disabled: disableCompanyFields,
        formItemStyle: { maxWidth: 800 },
        linkField: hasSuperAccessControlPermission ? 'companyId' : null,
        linkFieldValue: hasSuperAccessControlPermission ? 'ancestors' : null,
        tab: 'access'
      })
    }

    return {
      tabs,
      fields,
      refetchQueries: getCoursesRefetchQueries(hasSuperPermission)
    }
  }, [
    course, companySelect, subjects, categories, companyId, companyDefaultLocale,
    isGlobalAllowed, isCompanyAccessAllowed, getAccessType,
    hasSuperPermission, hasSuperAccessControlPermission, canUseAccessControl,
    companiesError
  ])

  const mutateValues = formValues => {
    return {
      ..._omit(formValues, ['details']),
      name: mutateLocaleFieldValue(formValues, 'name'),
      description: mutateLocaleFieldValue(formValues, 'description'),
      questionCount: formValues.randomizeQuestionOrder ? formValues.questionCount : null
    }
  }

  return (
    <>
      <LoadingBlock loading={courseLoading || companiesLoading} fullScreen={false} showMenu />
      <Card>
        <ListHeader vAlign='top'>
          <ListHeaderPanel>
            {
              isUpdate
                ? <IntercomHeader Size='h1' id='course-update-header'>{I18n.t('common.uLearn')} - {course?.name ? I18n.t('common.editItemName', { name: course.name }) : I18n.t('courses.common.editCourse')}</IntercomHeader>
                : <IntercomHeader Size='h1' id='course-create-header'>{I18n.t('common.uLearn')} - {I18n.t('courses.createCourse')}</IntercomHeader>
            }
          </ListHeaderPanel>
          <ListHeaderPanel align='right'>
            <Link to={routes.BUILDER}>
              <Button type='primary'>{I18n.t('editCourse.common.backToCourseBuilder')}</Button>
            </Link>
            {
              isUpdate
                ? (
                  <Link to={editContentPath}>
                    <Button icon='play-square' type='primary'>{I18n.t('editCourseSlides', trOpt)}</Button>
                  </Link>
                ) : null
            }
          </ListHeaderPanel>
        </ListHeader>
        {courseError && <ErrorAlerts error={courseError} defaultError={I18n.t('errorLoadingCourse', trOpt)} />}
        {companiesError && <ErrorAlerts error={companiesError} defaultError={I18n.t('common.companiesLoadError')} />}
        {(!isUpdate || (course && !courseError && !courseLoading)) && (
          <Layout>
            <Content>
              <EditCourseMutationForm
                ref={form}
                mutation={isUpdate ? UPDATE_COURSE : CREATE_COURSE}
                onTabChange={onTabChange}
                onSuccess={onSuccess}
                onFailure={onFailure}
                mutateValues={mutateValues}
                failureMessage={isUpdate ? I18n.t('failedToUpdateCourse', trOpt) : I18n.t('failedToCreateCourse', trOpt)}
                submitIcon='save'
                submitLabel={isUpdate ? I18n.t('courses.common.updateCourse') : I18n.t('courses.createCourse')}
                tabs={tabs}
                fields={fields}
                variables={{ courseId }}
                skipResetFieldsOnSubmit={!isUpdate}
                disableSubmitOnEnter
                disableSubmitIfInvalid={false}
                refetchQueries={refetchQueries}
              />
            </Content>
          </Layout>
        )}
      </Card>
    </>
  )
}

export default compose(
  withRouter,
  connect(state => _pick(selectors.session.get(state), ['companyId', 'accountType', 'locale', 'companyLocale']))
)(EditCourseForm)
