import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.office365Email' }

class Office365Email extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'office365Report'
    this.showSentTestButton = true
    this.generateSubjectFields([{ id: 'subject', label: 'common.fields.emailSubject' }])
    this.requiredUpdatePermissions = [permissions.SETTINGS_SYNC_UPDATE, permissions.SETTINGS_EMAIL_UPDATE]
  }

  get headerId () {
    return 'settings-m365-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(Office365Email)
