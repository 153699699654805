
import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { ExcludeInactiveToggle } from '../../../components/common'
import IntercomHeader from '../../../components/IntercomHeader'

const trOpt = { scope: 'uPhish.simulations' }

const UPhishHeaderStyled = styled.div`
height: 100%;
background-color: white;
padding: 40px;
display: flex;
flex-direction: row;
justify-content: space-between;
`
const UPhishHeader = () => {
  return (
    <UPhishHeaderStyled style={{ backgroundColor: 'white', gridArea: 'topbar' }}>
      <IntercomHeader Size='h2' id='uPhish-report-header' style={{ margin: 0 }}>{I18n.t('common.uPhish')} - {I18n.t('simulations', trOpt)}</IntercomHeader>
      <div>
        <ExcludeInactiveToggle />
      </div>
    </UPhishHeaderStyled>
  )
}

export default UPhishHeader
