import gql from 'graphql-tag'

export const START_MESSAGE_INJECTION_TEST = gql`
  mutation ($recipient: String!, $service: MessageInjectionService!) {
    startMessageInjectionTest(recipient: $recipient, service: $service)
  }
`

export const GET_MESSAGE_INJECTION_TEST_STATUS = gql`
  query ($jobId: Int!) {
    messageInjectionTestStatus(jobId: $jobId) {
      complete
      status
      jobId
      jobState
      errorMessages
    }
  }
`
