import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import I18n from 'i18n-js'
import _isNil from 'lodash/isNil'
import _isNumber from 'lodash/isNumber'

const getSelectWidth = ({ width }) => {
  if (_isNil(width)) return 'auto'
  if (_isNumber(width)) return `${width}px`
  return width
}

const _FilterField = ({ className, width, maxWidth, ...props }) => <div {...{ className }} style={{ maxWidth }} {...props} />
export const FilterField = styled(_FilterField)`
  label {
    display: block;
  }
  
  .ant-select {
    min-width: ${getSelectWidth};
  }
`

const _FilterResetButton = ({ className, onClick }) => (
  <Button ghost type='primary' icon='undo' {...{ className, onClick }}>{I18n.t('common.reset')}</Button>
)
export const FilterResetButton = styled(_FilterResetButton)`
  margin-right: 10px !important;
`

export default FilterField
