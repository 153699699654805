export const PAGE_X_PAD_MOBILE_REM = 1.5
export const PAGE_X_PAD_DESKTOP_REM = 3.5
export const BREAKPOINT = '768px'
export const MIN_BREAKPOINT = '769px'

export const BOOTSTRAP_BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}
