import React from 'react'
import styled from 'styled-components'
import { Card } from 'antd'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  gap: ${({ gap }) => gap || '15px'};
  flex: ${({ flex }) => flex};
  &.grow-me {
    flex-grow: ${({ grow }) => grow || 1};
  }
  &.shrink-me {
    flex-shrink: ${({ shrink }) => shrink || 1};
  }
  &.justify {
    justify-content: ${({ justify }) => justify};
  }
  &.align{
    align-content: ${({ alignContent }) => alignContent};
    align-items: ${({ alignItems }) => alignItems};
  }
  &.full-height {
    height: 100%;
  }
  &.padded-bottom {
    margin-bottom: 1em;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap || '15px'};
  &.grow-me {
    flex-grow: ${({ grow }) => grow || 1};
  }
  &.shrink-me {
    flex-shrink: ${({ shrink }) => shrink || 1};
  }
  &.justify {
    justify-content: ${({ justify }) => justify};
  }
  &.align{
    align-content: ${({ alignContent }) => alignContent};
    align-items: ${({ alignItems }) => alignItems};
  }
  &.full-height {
    height: 100%;
  }
`
export const ConditionalFlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ isRow }) => isRow ? 'row' : 'column'};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap || '15px'};
  &.grow-me {
    flex-grow: ${({ grow }) => grow || 1};
  }
  &.shrink-me {
    flex-shrink: ${({ shrink }) => shrink || 1};
  }
  &.justify {
    justify-content: ${({ justify }) => justify};
  }
  &.align{
    align-content: ${({ alignContent }) => alignContent};
    align-items: ${({ alignItems }) => alignItems};
  }
  &.full-height {
    height: 100%;
  }
`
export const RoundedCard = styled(Card)`
  border-radius: 10px;
  flex: ${({ flex }) => flex};
  &.order-me {
    order: ${({ order }) => order};
  }
  &.grow-me {
    flex-grow: ${({ grow }) => grow};
  }
  &.shrink-me {
    flex-shrink: ${({ shrink }) => shrink};
  }
  &.align-me {
    align-self: ${({ align }) => align};
  }
  &.fit-context {
    height: fit-content;
  }
  &.phishStats {
    height: 100%;
    .ant-card-body {
      padding: 15px 24px !important;
    }
  }
  &.bg-color{
    background-color: ${({ bgcolor }) => bgcolor};
  }
  @media print {
    break-inside: avoid;
  }
`
export const ContentHeader = styled.div`
  display: flex; 
  flex-flow: row nowrap;
  justify-content: space-between;
  flex: ${({ flex }) => flex || 1};
  span {
    font-weight: bold;
    flex-grow: 1;
  }
  &.anticon {
    font-size: 24px;
    color: ${({ theme }) => theme.secondary};
  }
`
export const ConditionalLayout = ({ className, row = null, column = null, isRow, children }) => {
  if (isRow) {
    return <Row {...{ className, ...row }}>{children}</Row>
  } else {
    return <Column {...{ className, ...column }}>{children}</Column>
  }
}
export const GridWrapper = styled.div`
  height: 100%;
  ${({ screenType }) => {
    if (screenType === 'xs' || screenType === 'sm') {
      return {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '1rem'
      }
    } else {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${screenType === 'md' ? 4 : 6}, 1fr)`,
        gridTemplateRows: 'auto',
        gridGap: '1rem',
        gridAutoFlow: 'dense'
      }
    }
  }}
`
export const GridLayoutWrap = styled.div`
  ${({ screenType, xs, sm, md, lg, mobilePriorityOrder }) => {
    const css = {}
    switch (screenType) {
      case 'xs':
        if (mobilePriorityOrder) css.order = mobilePriorityOrder
        css.gridColumn = xs
        break
      case 'sm':
        if (mobilePriorityOrder) css.order = mobilePriorityOrder
        css.gridColumn = sm
        break
      case 'md':
        if (mobilePriorityOrder) css.order = mobilePriorityOrder
        css.gridColumn = md
        break
      default:
        css.gridColumn = lg
        break
    }
    return css
  }}
`
