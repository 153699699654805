import {message, Modal} from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import {DELETE_USER} from '../Queries/Users'
import {showErrors} from '../../helpers'

const confirm = Modal.confirm
const trOpt = { scope: 'modals.deleteUserConfirm' }

const deleteUserConfirm = async (userId, refetchUsers = () => {}, client = authenticatedClient) => {
  const count = 1
  confirm({
    title: I18n.t('title', { ...trOpt, count }),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        await client.mutate({
          mutation: DELETE_USER,
          variables: { userId }
        })

        refetchUsers()
        message.success(I18n.t('successMessage', { ...trOpt, count }))
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', { ...trOpt, count }))
      }
    }
  })
}

export default deleteUserConfirm
