import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Button, Dropdown, Menu, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { generatePath, Link } from 'react-router-dom'
import compose from 'recompose/compose'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'
import _isFunction from 'lodash/isFunction'
import { NetworkStatus } from 'apollo-client'

import DashboardStatistic from '../../components/Dashboard/DashboardStatistic'
import UPolicyHeader from '../Reports/uPolicy/uPolicyHeader'
import PoliciesTable from './PolicyTable'
import { ContentWrap, ListHeader, ListHeaderPanel, ListHeaderTitle, SearchBar, ErrorAlerts, ListViewActions } from '../../components/common'
import { GET_POLICIES, GET_POLICY_TEMPLATES } from '../../components/Queries/uPolicy'
import { getPolicyActions } from '../../helpers/Policies'
import DuplicatePolicyModal from '../../components/Modals/DuplicatePolicyModal'
import SendPolicyModal from '../../components/Modals/SendPolicyModal'
import UploadPolicySignaturesModal from '../../components/Modals/UploadPolicySignaturesModal'
import { PublishDraftPolicyModal, DiscardDraftPolicyModal } from '../../components/Modals/SavePolicyModal'
import routes from '../../constants/routes'
import { WRITABLE_POLICY_ACTIONS } from '../../constants/actions'
import DeletePolicyConfirm from '../../components/Modals/DeletePolicyConfirm'
import { connect, withConsumer } from '../../hocs'
import selectors from '../../state/selectors'
import ExportServiceDownloadButton from '../../components/common/ExportServiceDownloadButton'
import { togglePollingBasedOnSelectedIds, updateSelectedIdsOnRecordSetUpdate, disableActions } from '../../helpers/listPages'
import { QUERY_POLL_INTERVAL } from '../../constants/apolloClient'
import UpdatePolicyTemplateLanguagesModal from '../../components/Modals/UpdatePolicyTemplateLanguagesModal'
import ExportServiceDownloadModal from '../../components/Modals/ExportServiceDownloadModal'

import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'
import useLocalStorage from '../../hooks/useLocalStorage'
import IncludeClientContentToggle from '../../components/common/IncludeClientContentToggle'
import { cleanUpContentCompanyNameListFilter } from '../../helpers/company'

const trOpt = { scope: 'uPolicy.uPolicy' }
const commonTrOpt = { scope: 'uPolicy.common' }

const updateReportStateHook = (setter, value) => {
  if (_isFunction(setter)) {
    setter(value)
  }
}

export const PolicyView = ({
  loading = false, error,
  policies: rawPolicies = [],
  setTotalRecipients,
  setTotalSigned,
  setTotalVisited,
  setTotalDraft,
  setTotalPolicies,
  setTotalLive,
  setPoliciesWithRecipients,
  searchFilterText = '',
  updateSearchFilterText = () => { },
  panelLeft = [],
  panelRight = ['search', 'actions', 'add'],
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  history,
  type,
  builder,
  refetchQueries,
  routePolicyId,
  companyId: userCompanyId,
  viewStorageId,
  companyPolicySignatureSettings,
  externalAccess = false,
  externalAccessKey,
  setPollingEnabled,
  planValid,
  useSupportedLocales = false,
  hasSuperPermission,
  includeClientContent,
  setIncludeClientContent
}) => {
  const [showSendPolicyModal, updateShowSendPolicyModal] = useState(false)
  const [showDuplicatePolicyModal, updateShowDuplicatePolicyModal] = useState(false)
  const [showDeletePolicyModal, updateShowDeletePolicyModal] = useState(false)
  const [showPublishDraftPolicyModal, updateShowPublishDraftPolicyModal] = useState(false)
  const [showDiscardDraftPolicyModal, updateShowDiscardDraftPolicyModal] = useState(false)
  const [showUploadPolicySignaturesModal, updateShowUploadPolicySignaturesModal] = useState(false)
  const [activePolicyId, setActivePolicyId] = useState(null)
  const [activePolicyIds, setActivePolicyIds] = useState([])
  const [activePolicy, setActivePolicy] = useState(null)
  const [selectedPolicyIds, updateSelectedPolicyIds] = useState([])
  const [activePolicies, setActivePolicies] = useState([])
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const [showMultiSelectActions, setShowMultiSelectActions] = useState(true)

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const [allPolicies, setAllPolicies] = useState([])
  const [policies, setPolicies] = useState([])

  const disabledActions = useMemo(() => !planValid ? WRITABLE_POLICY_ACTIONS : null, [planValid])

  useEffect(() => {
    let totalRecipientsStat = 0
    let totalVisitedStat = 0
    let totalSignedStat = 0
    let totalLive = 0
    let totalDraft = 0
    let policiesWithRecipients = 0

    const allPolicies = rawPolicies.map((policy) => {
      const actions = getPolicyActions({ policy, builder, hasSuperPermission, userCompanyId, disabledActions, hasAllSessionPermissions })
      const { id, name, results = [], template, category, companyId, majorVersion, minorVersion } = policy
      const isLive = !_isNil(_get(policy, 'livePolicyDocument.id'))
      const hasDraft = !_isNil(_get(policy, 'draftPolicyDocument.id'))
      let versionNo = null
      if (isLive && template) {
        versionNo = I18n.t('uPolicy.common.live')
      } else if (isLive) {
        versionNo = `v${majorVersion}.${minorVersion}`

        totalLive++
      } else if (hasDraft) {
        versionNo = I18n.t('uPolicy.common.inDraft')

        // Update total drafts
        totalDraft++
      }

      const lastMajor = _get(policy, 'lastMajorPolicyDocument')

      let signatureType = 'none'
      let useDefaultSignatureType = false
      // Signature Types only apply for policy's company and have no effect when shared
      if (!policy.template && (policy.companyId === userCompanyId || hasSuperPermission)) {
        useDefaultSignatureType = _get(policy, 'signatureSettings.useDefault')
        signatureType = useDefaultSignatureType ? _get(companyPolicySignatureSettings, 'type') : _get(policy, 'signatureSettings.type')
      }

      let policyRow = {
        id,
        key: id,
        name,
        companyId: _get(policy, 'company.id') || 'usecure',
        companyName: _get(policy, 'company.name') || I18n.t('common.managedByUsecure'),
        template,
        category: category || 'none',
        actions: actions,
        type: _isNil(companyId) ? 'core' : 'custom',
        versionNo,
        lastMajorVersionNo: lastMajor ? `v${lastMajor.majorVersion}.${lastMajor.minorVersion}` : null,
        isLive,
        hasDraft,
        isOwner: (hasSuperPermission || userCompanyId === companyId),
        signatureType: signatureType || 'none',
        useDefaultSignatureType: useDefaultSignatureType || false,
        locales: (useSupportedLocales ? policy?.supportedLocales : policy?.locales) ?? []
      }

      if (type === 'policy') {
        const recipients = results.length
        const { signed, visited } = results.reduce((acc, result) => {
          if (result.visit) acc.visited += 1
          if (result.signed) acc.signed += 1
          return acc
        }, {
          signed: 0, visited: 0
        })
        if (recipients > 0) {
          policiesWithRecipients += 1
        }

        totalRecipientsStat += recipients
        totalVisitedStat += visited
        totalSignedStat += signed

        policyRow = {
          ...policyRow,
          recipients,
          visited,
          signed
        }
      }

      if (type === 'template' && hasSuperPermission) {
        policyRow.global = policy.global === true
      }

      return policyRow
    })

    allPolicies.sort((a, b) => a.name.localeCompare(b.name))

    setAllPolicies(allPolicies)
    updateReportStateHook(setTotalPolicies, allPolicies.length)
    updateReportStateHook(setTotalRecipients, totalRecipientsStat)
    updateReportStateHook(setTotalVisited, totalVisitedStat)
    updateReportStateHook(setTotalSigned, totalSignedStat)
    updateReportStateHook(setTotalDraft, totalDraft)
    updateReportStateHook(setTotalLive, totalLive)
    updateReportStateHook(setPoliciesWithRecipients, policiesWithRecipients)
  }, [
    rawPolicies, builder, userCompanyId, type, companyPolicySignatureSettings,
    setPoliciesWithRecipients, setTotalDraft, setTotalLive, setTotalPolicies, setTotalRecipients, setTotalSigned, setTotalVisited, disabledActions, hasSuperPermission, useSupportedLocales, hasAllSessionPermissions
  ])

  useEffect(() => {
    updateSelectedIdsOnRecordSetUpdate(updateSelectedPolicyIds, allPolicies)
  },
  [allPolicies])
  // Suspend query polling if rows are selected
  useEffect(
    // activePolicyIds isn't reset on completion/cancellation of every action
    // This means query polling may remain suspended after an action is triggered
    () => togglePollingBasedOnSelectedIds(activePolicyIds.length > 0 ? activePolicyIds : selectedPolicyIds, setPollingEnabled),
    [selectedPolicyIds, activePolicyIds, setPollingEnabled]
  )

  const applyPolicyFilters = useCallback((searchFilterText) => {
    let policies = [...allPolicies]

    if (searchFilterText) {
      const filterFields = ['name']
      policies = policies.filter(policy =>
        filterFields.some(field => {
          const value = policy[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setPolicies(policies)
  }, [allPolicies])
  const debouncedApplyPolicyFilters = useCallback(_debounce(applyPolicyFilters, 500), [applyPolicyFilters])
  useEffect(() => {
    debouncedApplyPolicyFilters(searchFilterText)
  }, [debouncedApplyPolicyFilters, searchFilterText])

  const updateActivePolicy = useCallback(policyIds => {
    policyIds = policyIds || []
    const [id] = policyIds
    setActivePolicyId(id || null)
    let activePolicy = null
    if (id) {
      activePolicy = policies.find(policy => policy.id === id)
    }
    setActivePolicy(activePolicy)

    setActivePolicyIds(policyIds)
    setActivePolicies(policyIds.map(id => policies.find(policy => policy.id === id)))
  }, [policies, setActivePolicy, setActivePolicyId, setActivePolicies])
  const updateActivePolicyId = useCallback(id => updateActivePolicy(id ? [id] : undefined), [updateActivePolicy])

  const openSendModal = useCallback(id => {
    updateActivePolicy(id)
    updateShowSendPolicyModal(true)
  }, [updateShowSendPolicyModal, updateActivePolicy])
  const openDuplicateModal = useCallback(id => {
    updateActivePolicyId(id)
    updateShowDuplicatePolicyModal(true)
  }, [updateShowDuplicatePolicyModal, updateActivePolicyId])
  const openDeleteModal = useCallback(ids => {
    updateActivePolicy(ids)
    updateShowDeletePolicyModal(true)
  }, [updateShowDeletePolicyModal, updateActivePolicy])
  const openPublishDraftModal = useCallback(id => {
    updateActivePolicyId(id)
    updateShowPublishDraftPolicyModal(true)
  }, [updateShowPublishDraftPolicyModal, updateActivePolicyId])
  const openDiscardDraftModal = useCallback(id => {
    updateActivePolicyId(id)
    updateShowDiscardDraftPolicyModal(true)
  }, [updateShowDiscardDraftPolicyModal, updateActivePolicyId])
  const openUploadPolicySignaturesModal = useCallback(id => {
    updateActivePolicyId(id)
    updateShowUploadPolicySignaturesModal(true)
  }, [updateShowUploadPolicySignaturesModal, updateActivePolicyId])

  const afterModalClose = useCallback(() => updateActivePolicyId(null), [updateActivePolicyId])

  const updateLanguagesModalRef = useRef(null)
  const openUpdateLanguagesModal = useCallback((ids, action) => {
    if (updateLanguagesModalRef.current) {
      updateLanguagesModalRef.current.open(ids, action)
    }
  }, [updateLanguagesModalRef])

  const exportServiceDownloadModalRef = useRef(null)
  const downloadReportViaExportServiceModal = useCallback(async ({ fileName, fileType, jobData }) => {
    if (exportServiceDownloadModalRef.current) {
      return exportServiceDownloadModalRef.current.open({ fileName, fileType, jobData })
    }
  }, [exportServiceDownloadModalRef])

  const performAction = useCallback(async (action, policyIds) => {
    updateActivePolicy(policyIds)

    switch (action) {
      case 'editPolicy':
        history.push(generatePath(type === 'template' ? routes.UPOLICY_TEMPLATE_EDIT : routes.UPOLICY_EDIT, { policyId: policyIds[0] }))
        break
      case 'editPolicyDraft':
        history.push(generatePath(type === 'template' ? routes.UPOLICY_TEMPLATE_EDIT_DRAFT : routes.UPOLICY_EDIT_DRAFT, { policyId: policyIds[0] }))
        break
      case 'publishPolicyDraft':
        openPublishDraftModal(policyIds[0])
        break
      case 'discardPolicyDraft':
        openDiscardDraftModal(policyIds[0])
        break
      case 'sendPolicy':
        openSendModal(policyIds)
        break
      case 'duplicatePolicy':
        openDuplicateModal(policyIds[0])
        break
      case 'deletePolicy':
        await openDeleteModal(policyIds)
        break
      case 'exportPolicyData':
        await downloadReportViaExportServiceModal({
          fileType: 'xlsx',
          fileName: 'policy_reports-{{ts}}.xlsx',
          jobData: { reportType: 'policyDataExport', exportType: 'selectedPolicies', policyIds }
        })
        break
      case 'uploadPolicySignatures':
        openUploadPolicySignaturesModal(policyIds[0])
        break
      case 'addLanguages':
        openUpdateLanguagesModal(policyIds, 'add')
        break
      case 'removeLanguages':
        openUpdateLanguagesModal(policyIds, 'remove')
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [history, openSendModal, openDuplicateModal, openDeleteModal, openPublishDraftModal, openDiscardDraftModal, type, updateActivePolicy, openUploadPolicySignaturesModal, openUpdateLanguagesModal, downloadReportViaExportServiceModal])

  const actions = useMemo(() => {
    let actions = []

    if (selectedPolicyIds.length === 1) {
      const policy = rawPolicies.find(({ id }) => id === selectedPolicyIds[0])
      if (policy) {
        actions = getPolicyActions({ policy, builder, hasSuperPermission, userCompanyId, disabledActions, hasAllSessionPermissions })
      }
    } else if (selectedPolicyIds.length > 1) {
      if ((type === 'policy' || (type === 'template' && builder)) &&
        // usecure-admin or every policy belongs to user's company
        (
          hasSuperPermission ||
          selectedPolicyIds.every(selectedPolicyId => _get(rawPolicies.find(({ id }) => id === selectedPolicyId), 'companyId') === userCompanyId)
        )
      ) {
        if (type === 'template') {
          if (hasAllSessionPermissions([permissions.POLICY_TEMPLATE_UPDATE])) {
            actions.push(
              { key: 'addLanguages', label: I18n.t('common.addLanguages'), icon: 'global', theme: 'outlined' },
              { key: 'removeLanguages', label: I18n.t('common.removeLanguages'), icon: 'global', theme: 'outlined' }
            )
          }
          if (hasAllSessionPermissions([permissions.POLICY_TEMPLATE_DELETE])) {
            actions.push(
              { key: 'deletePolicy', label: I18n.t('deleteTemplates', trOpt), icon: 'delete' }
            )
          }
        }
        if (type === 'policy' && hasAllSessionPermissions([permissions.POLICY_DELETE])) {
          actions.push(
            { key: 'deletePolicy', label: I18n.t('deletePolicies', trOpt), icon: 'delete' }
          )
        }
      }
      if (type === 'policy') {
        if (hasAllSessionPermissions([permissions.POLICY_RESULT_CREATE])) {
          actions.unshift({ key: 'sendPolicy', label: I18n.t('uPolicy.common.sendPolicies'), icon: 'mail' })
        }
        if (hasAllSessionPermissions([permissions.POLICY_REPORT])) {
          actions.push(
            { key: 'exportPolicyData', label: I18n.t('common.exportPolicyData'), icon: 'file' }
          )
        }
      }
    }

    return disableActions(actions, disabledActions)
  }, [selectedPolicyIds, type, builder, rawPolicies, userCompanyId, disabledActions, hasSuperPermission, hasAllSessionPermissions])

  const handleAddMenuClick = useCallback(e => {
    let path = routes.UPOLICY_CREATE
    if (type === 'template') {
      path = routes.UPOLICY_TEMPLATE_CREATE
    } else if (e.key === 'template') {
      path = routes.UPOLICY_CREATE_FROM_TEMPLATE
    }
    history.push(path)
  }, [type, history])

  useEffect(() => {
    // Checks if any policies have actions available, if so show the multi select actions allowing actions dropdown to show as disabled unless a policy is selected
    if (allPolicies.some(policy => policy.actions.length > 0)) {
      return setShowMultiSelectActions(true)
    }

    // If no policies have actions available, check if any mutli select actions are available and update accordingly
    actions > 0 ? setShowMultiSelectActions(true) : setShowMultiSelectActions(false)
  }, [allPolicies, actions, setShowMultiSelectActions])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    return (
      <ListHeaderPanel align={align}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'search':
                return (
                  <SearchBar
                    key={`searchBar-${index}`}
                    placeholder={I18n.t(type === 'template' ? 'searchForTemplate' : 'searchForPolicy', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                  />
                )

              case 'actions':
                return (
                  <ListViewActions
                    key={index}
                    actions={actions} selectedIds={selectedPolicyIds}
                    performAction={performAction}
                    onEmptyActions='disable'
                  />
                )
              case 'add':
                if (type === 'template' && hasAllSessionPermissions([permissions.POLICY_TEMPLATE_CREATE])) {
                  return (
                    <Link to={routes.UPOLICY_TEMPLATE_CREATE} key={`addButton-${index}`}>
                      <Button disabled={!planValid} type='primary' icon='plus'>{I18n.t('createTemplate', commonTrOpt)}</Button>
                    </Link>
                  )
                } else if (hasAllSessionPermissions([permissions.POLICY_CREATE])) {
                  return (
                    <Link to={routes.UPOLICY_CREATE} key={`addButton-${index}`}>
                      <Button disabled={!planValid} type='primary' icon='plus'>{I18n.t('createPolicy', commonTrOpt)}</Button>
                    </Link>
                  )
                }
                break
              case 'add-dropdown':
                if (type === 'policy' && hasAllSessionPermissions([permissions.POLICY_CREATE])) {
                  return (
                    <Dropdown
                      key={`addDropdown-${index}`}
                      disabled={!planValid}
                      trigger={['hover']}
                      overlay={
                        <Menu onClick={handleAddMenuClick}>
                          <Menu.Item key='template'>{I18n.t('createFromTemplate', trOpt)}</Menu.Item>
                          <Menu.Item key='blank'>{I18n.t('createFromScratch', trOpt)}</Menu.Item>
                        </Menu>
                      }
                    >
                      <Button type='primary' icon='plus'>{I18n.t('createPolicy', commonTrOpt)}</Button>
                    </Dropdown>
                  )
                }
                break
              case 'outstandingPolicyExportBtn':
                if (hasAllSessionPermissions([permissions.POLICY_REPORT])) {
                  return (
                    <ExportServiceDownloadButton
                      key={`exportDownload-${index}`}
                      icon='download'
                      fileName='outstanding-policy-report.xlsx'
                      fileType='xlsx'
                      jobData={{ reportType: 'policyDataExport', exportType: 'outstandingPolicies' }}
                    >
                      {I18n.t('downloadOutstandingPolicyReport', commonTrOpt)}
                    </ExportServiceDownloadButton>
                  )
                }
                break
              case 'include-client-content-toggle':
                return (
                  <IncludeClientContentToggle
                    key={index}
                    {...{ includeClientContent, setIncludeClientContent }}
                  />
                )
              default:
                return (
                  <div key={index}>{panelElem}</div>
                )
            }
            return null
          })
        }
      </ListHeaderPanel>
    )
  }, [
    searchFilterText, onSearchChange, performAction, selectedPolicyIds, actions, type, handleAddMenuClick, planValid, hasAllSessionPermissions,
    includeClientContent, setIncludeClientContent
  ])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      <ListHeader>
        {panelLeftComponent}
        {panelRightComponent}
        {
          !externalAccess &&
            <>
              <SendPolicyModal
                policyIds={activePolicyIds}
                policies={activePolicies}
                visible={showSendPolicyModal}
                setVisible={updateShowSendPolicyModal}
                afterClose={afterModalClose}
                refetchQueries={refetchQueries}
              />
              <DuplicatePolicyModal
                policy={activePolicy}
                {...{ type, refetchQueries }}
                visible={showDuplicatePolicyModal} setVisible={updateShowDuplicatePolicyModal}
                afterClose={afterModalClose}
              />
              <UploadPolicySignaturesModal
                policyId={activePolicyId}
                {...{ refetchQueries }}
                visible={showUploadPolicySignaturesModal} setVisible={updateShowUploadPolicySignaturesModal}
                afterClose={afterModalClose}
              />
              <DeletePolicyConfirm
                policyIds={activePolicyIds}
                {...{ type, refetchQueries }}
                visible={showDeletePolicyModal} setVisible={updateShowDeletePolicyModal}
                afterClose={afterModalClose}
              />
              <PublishDraftPolicyModal
                id={activePolicyId}
                visible={showPublishDraftPolicyModal}
                setVisible={updateShowPublishDraftPolicyModal}
                afterClose={afterModalClose}
                refetchQueries={refetchQueries}
                isTemplate={type === 'template'}
              />
              <DiscardDraftPolicyModal
                id={activePolicyId}
                visible={showDiscardDraftPolicyModal}
                setVisible={updateShowDiscardDraftPolicyModal}
                afterClose={afterModalClose}
                refetchQueries={refetchQueries}
                isTemplate={type === 'template'}
              />
              <UpdatePolicyTemplateLanguagesModal
                ref={updateLanguagesModalRef}
                {...{ refetchQueries }}
                afterClose={afterModalClose}
              />
              <ExportServiceDownloadModal ref={exportServiceDownloadModalRef} />
            </>
        }
      </ListHeader>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('yourPoliciesCouldNotBeLoaded', trOpt)} />
          : (
            <PoliciesTable
              {...{
                loading,
                policies,
                sorter,
                updateSorter,
                filters,
                updateFilters,
                pagination,
                updatePagination,
                selectedPolicyIds,
                updateSelectedPolicyIds,
                type,
                builder,
                performAction,
                activePolicyId,
                updateActivePolicyId,
                routePolicyId,
                history,
                viewStorageId,
                externalAccess,
                externalAccessKey,
                showMultiSelectActions,
                hasSuperPermission
              }}
            />
          )
      }
    </>
  )
}

const UPolicyReportGrid = styled.div`
  display: ${props => props.type === 'policy' && props.canViewReport ? 'grid' : 'block'};
  grid-template-areas:
    "topbar topbar topbar topbar"
    "active recipients outstanding signed"
    "table table table table";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto 260px minmax(728px, auto);
  grid-gap: 25px;
`

const UPolicyItem = styled.div`
background-color: white;
padding: 30px 40px;
`

const UPolicy = ({
  history, title, storageId: viewStorageId, type = 'policy', builder = false, userId, companyId, match,
  policySignatureSettings: companyPolicySignatureSettings, planValid, id, excludeInactiveUsersInReports, useSupportedLocales
}) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasSuperPermission = type === 'template' && builder && hasAllSessionPermissions([permissions.POLICY_TEMPLATE_SUPER_LIST, permissions.POLICY_TEMPLATE_SUPER_READ])
  const routePolicyId = _get(match, 'params.policyId')

  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [filters, updateFilters] = useState(hasSuperPermission ? { companyName: ['usecure'] } : null)
  const [includeClientContent, setIncludeClientContent] = useState(false)

  let panelLeft = []
  if (type === 'policy' && !builder && hasAllSessionPermissions([permissions.POLICY_REPORT])) {
    panelLeft = ['outstandingPolicyExportBtn']
  }
  const panelRight = ['search']
  if (type === 'policy' || builder) {
    panelRight.push('actions')
  }
  if (type === 'policy') {
    panelRight.push('add-dropdown')
  } else if (type === 'template' && builder) {
    panelRight.push('add')
  }
  // Use localStorage to cache pagination and filters
  const storageId = useMemo(() => viewStorageId ? `${viewStorageId}|${companyId}|${userId}` : null, [viewStorageId, userId, companyId])
  const { localStorageInitialised, updateFromLocalStorage, updateLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      searchFilterText: updateSearchFilterText,
      filters: updateFilters,
      sorter: updateSorter,
      pagination: updatePagination
    })
  }, [updateFromLocalStorage, storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const [pollingEnabled, setPollingEnabled] = useState(true)
  const query = type === 'template' ? GET_POLICY_TEMPLATES : GET_POLICIES
  let variables = {
    // These variables apply to all uses of this view
    includeDocument: false,
    includeDraftDocument: false
  }
  if (type === 'policy') {
    // View Policies page
    variables = {
      withResults: true,
      includeDocument: false,
      includeDraftDocument: false,
      excludeInactiveUsers: excludeInactiveUsersInReports,
      includeLivePolicyDocument: true,
      includeDraftPolicyDocument: true,
      includeLastMajorPolicyDocument: true,
      includePolicyDocuments: true,
      ...variables
    }
  } else if (type === 'template' && builder) {
    // Template Builder
    variables = {
      restrictToPlan: false,
      restrictToOwn: !hasSuperPermission || !includeClientContent,
      withCompany: hasSuperPermission,
      publishedOnly: false,
      includeLivePolicyDocument: true,
      includeDraftPolicyDocument: true,
      forUpdate: true,
      withAccess: hasSuperPermission,
      ...variables
    }
  } else if (type === 'template') {
    // Template Library
    variables = {
      restrictToPlan: true,
      restrictToOwn: false,
      withCompany: false,
      publishedOnly: true,
      includeLivePolicyDocument: false,
      includeDraftPolicyDocument: false,
      forUpdate: false,
      ...variables
    }
  }

  const { loading: queryLoading, error, data, refetch, networkStatus } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    pollInterval: pollingEnabled ? QUERY_POLL_INTERVAL : 0
  })
  const [totalRecipients, setTotalRecipients] = useState(0)
  const [totalVisited, setTotalVisited] = useState(0)
  const [totalSigned, setTotalSigned] = useState(0)
  const [totalDraft, setTotalDraft] = useState(0)
  const [totalPolicies, setTotalPolicies] = useState(0)
  const [totalLive, setTotalLive] = useState(0)
  const [policiesWithRecipients, setPoliciesWithRecipients] = useState(0)

  const handleRefreshClick = useCallback(() => refetch(), [refetch])

  const loading = queryLoading && networkStatus !== NetworkStatus.poll
  const policies = _get(data, type === 'template' ? 'policyTemplates' : 'policies', [])

  const sectionData = [
    {
      title: I18n.t('livePolicies', trOpt),
      gridArea: 'active',
      value: totalLive,
      extraTitle: I18n.t('drafts', trOpt),
      extraData: totalDraft,
      hoverText: I18n.t('totalNumberOfLivePolicies', trOpt),
      isValue: true
    },
    {
      title: I18n.t('totalRecipients', trOpt),
      gridArea: 'recipients',
      value: totalRecipients,
      extraTitle: I18n.t('policiesWithRecipients', trOpt),
      extraData: policiesWithRecipients,
      hoverText: I18n.t('aTotalOfPoliciesReceived', { count: totalRecipients, policiesHaveReceived: I18n.t('policiesHaveReceived', { count: policiesWithRecipients, ...trOpt }), ...trOpt }),
      isValue: true
    },
    {
      title: I18n.t('totalVisited', trOpt),
      gridArea: 'outstanding',
      percentage: Math.round(totalVisited * 100 / totalRecipients),
      percentageDescription: I18n.t('ofSentPoliciesHaveBeenViewed', trOpt),
      extraTitle: I18n.t('total', trOpt),
      extraData: totalVisited,
      hoverText: I18n.t('aTotalOfPoliciesViewed', { count: totalVisited - totalSigned, ...trOpt })
    },
    {
      title: I18n.t('signed', commonTrOpt),
      gridArea: 'signed',
      percentage: Math.round(totalSigned * 100 / totalRecipients),
      percentageDescription: I18n.t('ofRecipeintsHaveSigned', trOpt),
      extraTitle: 'Total',
      extraData: totalSigned,
      hoverText: I18n.t('aTotalOfPoliciesSigned', { count: totalSigned, totalUnsignedPolicies: totalRecipients - totalSigned, ...trOpt })
    }

  ]
  const hasPolicyReportPermission = hasAllSessionPermissions([permissions.POLICY_REPORT])

  // Remove values that aren't in the policy template dataset from companyName filter
  // This code assumes that the companyName column and include client content toggle will only be visible on the Polilcy Template Builder.
  // The assignment for hasSuperPermission incorporated this condition when the effect hook was written as policy super permissions have been removed.
  useEffect(() => {
    cleanUpContentCompanyNameListFilter({
      records: data?.policyTemplates, filters, updateFilters, includeClientContent, hasSuperPermission, localStorageInitialised
    })
  }, [data, filters, includeClientContent, hasSuperPermission, localStorageInitialised])

  return (
    <ContentWrap>
      {!hasPolicyReportPermission && type === 'policy' && <UPolicyHeader />}
      <UPolicyReportGrid type={type} canViewReport={hasPolicyReportPermission}>
        {hasPolicyReportPermission && type === 'policy' && (
          <>
            <UPolicyHeader />
            {sectionData.map((item, index) => {
              return (
                <UPolicyItem key={index} style={{ gridArea: `${item.gridArea}` }}>
                  <DashboardStatistic isValue={item.isValue} hoverText={item.hoverText} title={item.title} link={item.link} percentage={item.percentage} percentageChange={item.percentageChange} percentageDescription={item.percentageDescription} extraTitle={item.extraTitle} extraData={item.extraData} value={item.value} loading={loading} />
                </UPolicyItem>
              )
            })}
          </>
        )}
        <UPolicyItem style={{ gridArea: 'table' }}>
          <ListHeader>
            <ListHeaderPanel align='left'>
              <ListHeaderTitle id={id || ''}>{title ? `${I18n.t('common.uPolicy')} - ${title}` : ''}</ListHeaderTitle>
            </ListHeaderPanel>
            <ListHeaderPanel align='right'>
              <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
            </ListHeaderPanel>
          </ListHeader>
          <PolicyView
            {...{
              loading,
              error,
              policies,
              totalLive,
              setTotalLive,
              policiesWithRecipients,
              setPoliciesWithRecipients,
              totalRecipients,
              setTotalRecipients,
              totalSigned,
              setTotalSigned,
              totalVisited,
              setTotalVisited,
              totalDraft,
              setTotalDraft,
              totalPolicies,
              setTotalPolicies,
              history,
              searchFilterText,
              updateSearchFilterText,
              sorter,
              updateSorter,
              pagination,
              updatePagination,
              filters,
              updateFilters,
              type,
              builder,
              panelLeft,
              panelRight,
              routePolicyId,
              companyId,
              viewStorageId,
              companyPolicySignatureSettings,
              setPollingEnabled,
              planValid,
              useSupportedLocales,
              hasSuperPermission,
              includeClientContent,
              setIncludeClientContent
            }}
            panelLeft={hasSuperPermission ? ['include-client-content-toggle'] : []}
            refetchQueries={[{ query, variables }]}
          />
        </UPolicyItem>
      </UPolicyReportGrid>
    </ContentWrap>
  )
}

export default compose(
  withConsumer,
  connect(state => ({
    ..._pick(selectors.session.get(state), ['userId', 'companyId', 'planValid']),
    ..._pick(selectors.settings.get(state), ['policySignatureSettings']),
    ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
  }))
)(UPolicy)
