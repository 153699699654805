/* global localStorage */
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import { compose } from 'recompose'
import _get from 'lodash/get'
import I18n from 'i18n-js'

import { LoadingBlock } from '../../components/common'
import { MainWrap, TitleWrap, TitleLogo, CourseTitle } from '../../components/LearnerCourse/LearnerCourse'
import { PolicyView } from './uPolicy'
import { GET_PUBLIC_POLICIES } from '../../components/Queries/uPolicy'
import { withProvider, withLoadingBuffer, connect, withAppTitleAndIcons } from '../../hocs'
import { getBrowserLocale } from '../../helpers/locale'
import { creators as sessionActions } from '../../state/actions/session'
import { creators as settingsActions } from '../../state/actions/settings'
import { sessionLocale } from '../../state/selectors/session'
import { captureSentryError } from '../../helpers/sentry'

const ExternalAccessPolicies = ({
  history, storageId: viewStorageId, type = 'policy', builder = false, match, locale, setLocale, setTheme, updateSession
}) => {
  const externalAccessKey = _get(match, 'params.key')
  const routePolicyId = _get(match, 'params.policyId')

  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [filters, updateFilters] = useState(null)

  // Set locale from browser locale on mount
  useEffect(() => setLocale(getBrowserLocale()), [setLocale])

  // Use localStorage to cache pagination and filters
  const storageId = `ExternalAccessPolicies|${externalAccessKey}`
  useEffect(() => {
    try {
      if (!storageId) {
        return
      }
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        const listFilter = JSON.parse(listFilterString)
        Object.keys(listFilter).forEach(key => {
          const value = listFilter[key]
          switch (key) {
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'filters':
              updateFilters(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    if (!storageId) {
      return
    }

    let viewFilter = {}
    try {
      const viewFilterString = localStorage.getItem(storageId)
      if (viewFilterString) {
        viewFilter = JSON.parse(viewFilterString)
      }
      viewFilter = { ...viewFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(viewFilter))
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const query = GET_PUBLIC_POLICIES
  const variables = {
    key: externalAccessKey
  }
  const { loading, error, data } = useQuery(query, {
    variables,
    onError: error => {
      captureSentryError(error, { msg: 'External Access Policies Load - ERROR' })
    }
  })

  const companyName = _get(data, 'publicPolicies.companyName')
  const policies = _get(data, 'publicPolicies.policies', [])
  const companyPolicySignatureSettings = _get(data, 'publicPolicies.settings.policySignatureSettings', {})

  useEffect(() => {
    if (data) {
      setTheme(_get(data, 'publicPolicies.settings'))
      updateSession({ mspName: _get(data, 'publicPolicies.mspName'), locale })
    }
  }, [data, locale, setTheme, updateSession])

  return (
    <>
      <LoadingBlock loading={loading} />
      <div>
        <TitleWrap>
          <TitleLogo loading={loading} />
          <CourseTitle>{companyName ? I18n.t('uPolicy.externalAccessPoliciesTitle', { companyName }) : I18n.t('uPolicy.common.policies')}</CourseTitle>
        </TitleWrap>
        <MainWrap>
          <Card>
            <PolicyView
              {...{
                loading,
                error,
                policies,
                history,
                searchFilterText,
                updateSearchFilterText,
                sorter,
                updateSorter,
                pagination,
                updatePagination,
                filters,
                updateFilters,
                type,
                builder,
                routePolicyId,
                viewStorageId,
                companyPolicySignatureSettings,
                externalAccessKey
              }}
              refetchQueries={[{ query, variables }]}
              panelRight={['search']}
              externalAccess
              canAccessAllPolicies={false}
            />
          </Card>
        </MainWrap>
      </div>
    </>
  )
}

export default compose(
  withLoadingBuffer,
  withProvider(),
  connect(
    state => ({ locale: sessionLocale(state) }),
    dispatch => ({
      setLocale: locale => dispatch(sessionActions.updateLocaleOnly(locale)),
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      updateSession: session => dispatch(sessionActions.noAuthUpdate(session))
    })
  ),
  withAppTitleAndIcons({ noAuth: true })
)(ExternalAccessPolicies)
