import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, TreeSelect } from 'antd'
import _isArray from 'lodash/isArray'

import MutationFormErrors from './MutationFormErrors'

import I18n from 'i18n-js'

const formTrOpt = { scope: 'mutationForm.mutationForm' }

class MutationFormTreeSelect extends Component {
  constructor (props) {
    super(props)

    this.state = {
      touched: false
    }

    this.div = React.createRef()

    this.handleChange = this.handleChange.bind(this)
    this.validate = this.validate.bind(this)
    this.reset = this.reset.bind(this)
  }

  validate (value = this.props.value, errors = [], { submitting = false } = {}) {
    const { required, multiple, requiredError = I18n.t('requiredFieldError', formTrOpt) } = this.props
    if (required && (multiple ? !(_isArray(value) && value.length > 0) : value)) {
      errors.push(requiredError)
    }

    if (submitting) {
      this.updateTouched()
    }

    return errors
  }

  updateTouched () {
    if (!this.state.touched) {
      this.setState({ touched: true })
    }
  }

  reset () {
    this.setState({ touched: false })
  }

  handleChange (value) {
    value = value || (this.props.multiple ? [] : null)
    this.props.onChange(this.props.id, value)
    this.updateTouched()
  }

  get searchInputs () {
    if (this.div.current) {
      return this.div.current.querySelectorAll('input.ant-select-search__field')
    }
    return null
  }

  componentDidMount () {
    if (this.searchInputs) {
      this.searchInputs.forEach(el => el.addEventListener('keypress', this.preventSearchSubmit))
    }
  }

  componentWillUnmount () {
    if (this.searchInputs) {
      this.searchInputs.forEach(el => el.removeEventListener('keypress', this.preventSearchSubmit))
    }
  }

  preventSearchSubmit (event) {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, label, treeData, multiple, value, dropdownStyle, showSearch, allowClear, errors = [], required, disabled } = this.props
    const showErrors = this.state.touched && errors.length > 0

    return (
      <Form.Item
        label={label} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <div ref={this.div}>
          <TreeSelect
            multiple={multiple}
            name={id}
            treeData={treeData}
            showSearch={showSearch}
            treeNodeFilterProp='title'
            value={value}
            treeDefaultExpandAll
            dropdownStyle={dropdownStyle}
            onChange={this.handleChange}
            allowClear={allowClear}
            disabled={disabled}
          />
        </div>
      </Form.Item>
    )
  }
}

MutationFormTreeSelect.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]))
  ]),
  onChange: PropTypes.func,
  treeData: PropTypes.array,
  multiple: PropTypes.bool,
  dropdownStyle: PropTypes.object,
  showSearch: PropTypes.bool,
  allowClear: PropTypes.bool
}

MutationFormTreeSelect.defaultProps = {
  id: '',
  label: null,
  required: false,
  value: null,
  onChange: () => {},
  treeData: {},
  multiple: false,
  dropdownStyle: { maxHeight: 400, overflow: 'auto' },
  showSearch: true,
  allowClear: false
}

export default MutationFormTreeSelect
