import _get from 'lodash/get'

import { ENABLE_MESSAGE_INJECTION } from '../constants/environment'

export const isMessageInjectionAuthorisedForM365 = settings => {
  if (!ENABLE_MESSAGE_INJECTION) return false
  const { microsoft } = _get(settings, 'messageInjection') || {}
  return microsoft?.authType === 'admin' && microsoft?.tenantId === true
}
export const isMessageInjectionAuthorisedforGoogle = settings => {
  if (!ENABLE_MESSAGE_INJECTION) return false
  const { google } = _get(settings, 'messageInjection') || {}
  return google?.authType === 'serviceAccount' && google?.serviceAccountClientEmail === true && google?.serviceAccountPrivateKey === true
}

export const isMessageInjectionAuthorised = settings => {
  return isMessageInjectionAuthorisedForM365(settings) || isMessageInjectionAuthorisedforGoogle(settings)
}
