import { useCallback } from 'react'
import { useSubscription } from '@apollo/react-hooks'
import { get as _get } from 'lodash/object'

import { creators as settingsActions } from '../../state/actions/settings'
import { connect } from '../../hocs'
import { getSettingsFromCompany } from '../../helpers/getMe'
import { SETTINGS_SUBSCRIPTION } from '../Queries/Companies'

const SettingsSubscription = ({ updateSettings }) => {
  const updateSettingsState = useCallback(({ subscriptionData }) => {
    const company = _get(subscriptionData, 'data.settingsUpdated', {})
    const settings = getSettingsFromCompany(company)
    updateSettings(settings)
  }, [updateSettings])

  useSubscription(SETTINGS_SUBSCRIPTION, {
    onSubscriptionData: updateSettingsState
  })

  return null
}

export default connect(
  undefined,
  dispatch => ({
    updateSettings: settings => dispatch(settingsActions.replace(settings))
  })
)(SettingsSubscription)
