import I18n from 'i18n-js'

import SettingsForm from './SettingsForm'

const trOpt = { scope: 'settings.features' }

const UBREACH_PRO_TOGGLE_USECURE_ADMIN_ONLY = window.__USECURE_CONFIG__.REACT_APP_UBREACH_PRO_TOGGLE_USECURE_ADMIN_ONLY === 'true'

class Features extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'uLearn',
      'uPhish',
      'uBreach',
      'uPolicy',
      'uBreachPro'
    ]
    this.defaultValue = {
      uLearn: true,
      uPhish: true,
      uBreachEnabled: true,
      uPolicy: true,
      uBreachPro: false
    }
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  mutateValues (values) {
    if (!values.uBreachEnabled) {
      return {
        ...values,
        uBreachPro: false
      }
    }
    return values
  }

  get _fields () {
    const { uBreachProEnabled, settings = {} } = this.props
    const {
      uLearn,
      uPhish,
      uBreachEnabled,
      uPolicy,
      uBreachPro
    } = {
      ...this.defaultValue,
      ...settings
    }

    const fields = [
      {
        id: 'uLearn',
        type: 'switch',
        label: I18n.t('common.uLearn'),
        defaultValue: uLearn
      }, {
        id: 'uPhish',
        type: 'switch',
        label: I18n.t('common.uPhish'),
        defaultValue: uPhish
      }, {
        id: 'uBreachEnabled',
        type: 'switch',
        label: I18n.t('common.uBreach'),
        defaultValue: uBreachEnabled
      }, {
        id: 'uPolicy',
        type: 'switch',
        label: I18n.t('common.uPolicy'),
        defaultValue: uPolicy
      }
    ]
    if (uBreachProEnabled && !UBREACH_PRO_TOGGLE_USECURE_ADMIN_ONLY) {
      fields.push({
        id: 'uBreachPro',
        type: 'switch',
        label: I18n.t('common.uBreachPro'),
        defaultValue: uBreachPro,
        disabled: values => values.uBreachEnabled === false
      })
    }
    return fields
  }
}

export default Features
