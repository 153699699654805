import { Modal, message } from 'antd'
import I18n from 'i18n-js'

import { EXTEND_TRIAL } from '../Queries/Companies'
import authenticatedClient from '../../apollo-client/authenticatedClient'
import { showErrors, invalidateCompaniesQueryCache } from '../../helpers'

const { confirm } = Modal
const trOpt = { scope: 'modals.extendCompanyFreeTrial' }

const extendCompanyFreeTrialConfirm = async ({ companyId, refetchQueries, client = authenticatedClient }) => {
  confirm({
    title: I18n.t('prompt', trOpt),
    content: I18n.t('expiryInfo', trOpt),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        await client.mutate({
          mutation: EXTEND_TRIAL,
          variables: { companyId },
          refetchQueries,
          update: invalidateCompaniesQueryCache
        })
        message.success(I18n.t('successMessage', trOpt))
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      }
    }
  })
}

export default extendCompanyFreeTrialConfirm
