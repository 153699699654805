import { ApolloLink } from 'apollo-link'
import { getTimezoneHeader } from '../helpers/timezone'

export default new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    headers = {
      ...headers,
      ...getTimezoneHeader()
    }
    return { headers }
  })

  return forward(operation)
})
