import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { RoundedCard } from '../EndUserPortal/GridFlexiLayout'

const NoDataContainer = styled(RoundedCard)`
  color: rgb(117, 117, 117);
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
`

const NoDataCard = () => (
  <NoDataContainer>{I18n.t('reports.noChartData')}</NoDataContainer>
)

export default NoDataCard
