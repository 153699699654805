/* global localStorage */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Card, Select, Table, Button, Tabs, Alert } from 'antd'
import { compose } from 'recompose'
import styled, { withTheme } from 'styled-components'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import _debounce from 'lodash/debounce'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _isInteger from 'lodash/isInteger'
import I18n from 'i18n-js'

import selectors from '../state/selectors'
import withConsumer from '../hocs/withConsumer'
import connect from '../hocs/connect'
import { displayMoney } from '../helpers'
import { CURRENCIES } from '../constants/billing'
import { ContentWrap, ErrorAlerts, ListHeader, ListHeaderPanel, SearchBar } from '../components/common'
import { GET_COMPANY_FOR_INVOICE, USERVICE_INVOICES_DISTRI } from '../components/Queries/Companies'
import AddBillingMessage from '../components/uService/AddBillingDetailsMessage'
import UServiceInvoiceDownload from '../components/uService/uServiceInvoiceDownload'
import UServiceInvoiceSummary from '../components/uService/uServiceInvoiceSummary'
import { LIST_PAGINATION_PROPS } from '../constants/list'
import { formatDate, formatDateTime } from '../helpers/datetime'
import IntercomHeader from '../components/IntercomHeader'
const trOpt = { scope: 'uServiceInvoice' }

const { Option } = Select

const PeriodField = styled.div`
  label {
    margin-right: 10px;
  }

  .ant-select > .ant-select-selection {
    width: 200px;
  }
`

const TableContainer = styled.div`
  .ant-table-row-level-0 td:first-child span,
  .ant-table-row-level-1 td:first-child span {
    display: none;
  }
  .name-container {
    display: inline;
  }
  .ant-table-row-level-1 .name-container {
    display: block;
    margin-left: 25px;
  }
`

const { TabPane } = Tabs

const TabContainer = styled.div`
  position: relative;
`

const CompaniesView = ({
  client,
  loading = false, error,
  periods = [], companiesMap = {}, invoicePeriods = {},
  searchFilterText = '',
  updateSearchFilterText = () => {},
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  companyId,
  userId,
  accountType,
  userAccountType,
  period,
  updatePeriod = () => {},
  company,
  expandedRows = [],
  setExpandedRows = () => {}
}) => {
  const [tabKey, setTabKey] = useState('platformUsage')
  const onTabChange = useCallback(tabKey => setTabKey(tabKey), [setTabKey])
  const editAccountType = useMemo(() => {
    if (userAccountType === 'distributor') {
      return 'msp'
    } else if (userAccountType === 'msp') {
      return 'tenant'
    }

    return accountType
  }, [userAccountType, accountType])
  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const onPeriodChange = useCallback(period => updatePeriod(period), [updatePeriod])
  const periodOptions = useMemo(() => {
    return periods.map(({ startDate, endDate }) => ({
      value: startDate,
      label: `${formatDate(startDate)} - ${formatDate(endDate)}`
    }))
  }, [periods])

  const { currency, totalPlatformCost, tax, totalCost, totalUsers, currentPeriod, allCompanies, invoiceCompanies, lastUpdatedAt, cost, costIsEstimate, totalBreachDomains, totalBreachProCost, subTotal } = useMemo(() => {
    const { subTotal = null, totalPlatformCost = null, tax = null, totalCost = null, totalUsers = null, currency = null, updatedAt = null, usage = [], costPerUser: cost = null, costPerUserIsEstimate: costIsEstimate = false, totalBreachDomains, totalBreachProCost } = invoicePeriods[period] || {}

    const now = moment()
    let { value: currentPeriod = null } = periodOptions.find(({ value }) => now.isBetween(value, moment(value).add(1, 'months'), 'day', '[]')) || {}
    if (!currentPeriod) {
      currentPeriod = _get(periodOptions, '0.value', null)
    }

    const hasCost = _isInteger(cost) && CURRENCIES.includes(currency)
    const hasTax = hasCost && _isInteger(tax)

    const allCompanies = usage.map(usage => {
      const { companyId: id, users: maxUsers, cost, costInclTax, updatedAt, children: childrensUsage = [], exemptFromDistributorBilling, uBreachDomains, uBreachProCost, uBreachProCostInclTax } = usage
      const { name } = companiesMap[id]
      const costStr = hasCost ? displayMoney(cost, currency) : I18n.t('common.na')
      const costInclTaxStr = hasTax ? displayMoney(costInclTax, currency) : I18n.t('common.na')
      const uBreachProEnabled = (_isInteger(uBreachDomains) && (uBreachProCost || uBreachProCostInclTax)) ?? false

      const children = childrensUsage.map(cUsage => {
        const { companyId: childId, users: maxUsers, cost, costInclTax, updatedAt, exemptFromDistributorBilling, billableUsers } = cUsage
        const parent = companiesMap[id]
        const childIndex = _findIndex(parent.tenants, ['id', childId])
        const { name } = parent.tenants[childIndex]
        const costStr = hasCost ? displayMoney(cost, currency) : I18n.t('common.na')
        const costInclTaxStr = hasTax ? displayMoney(costInclTax, currency) : I18n.t('common.na')

        return {
          key: childId,
          id: childId,
          name,
          maxUsers,
          cost,
          costStr,
          costInclTax,
          costInclTaxStr,
          exemptFromDistributorBilling,
          billableUsers,
          lastUpdatedAt: updatedAt ? moment(updatedAt) : null
        }
      })

      children.sort((a, b) => b.maxUsers - a.maxUsers) // Highest usercount first

      return {
        key: id,
        id,
        name,
        maxUsers,
        cost,
        costStr,
        costInclTax,
        costInclTaxStr,
        lastUpdatedAt: updatedAt ? moment(updatedAt) : null,
        exemptFromDistributorBilling,
        uBreachDomains,
        uBreachProCost,
        uBreachProCostInclTax,
        uBreachProEnabled,
        totalBreachDomains,
        totalBreachProCost,
        children
      }
    })

    allCompanies.sort((a, b) => b.maxUsers - a.maxUsers) // Highest user count first

    const companyUpdatedAt = updatedAt ? moment(updatedAt) : null
    const childLastUpdatedAt = allCompanies.reduce((childLastUpdatedAt, { lastUpdatedAt }) => {
      if (lastUpdatedAt && (!childLastUpdatedAt || lastUpdatedAt.isAfter(childLastUpdatedAt))) {
        childLastUpdatedAt = lastUpdatedAt
      }
      return childLastUpdatedAt
    }, null)

    const lastUpdatedAt = companyUpdatedAt && childLastUpdatedAt ? moment.max(companyUpdatedAt, childLastUpdatedAt) : (companyUpdatedAt || childLastUpdatedAt)

    const invoiceCompanies = [...allCompanies]

    return { currency, totalPlatformCost, tax, totalCost, totalUsers, currentPeriod, allCompanies, invoiceCompanies, lastUpdatedAt, cost, costIsEstimate, totalBreachDomains, totalBreachProCost, subTotal }
  }, [invoicePeriods, periodOptions, period, companiesMap])

  const [companies, setCompanies] = useState([])
  const applyCompanyFilters = useCallback((searchFilterText) => {
    let companies = allCompanies
    if (searchFilterText) {
      const parentMatches = []
      companies = companies.filter(company => {
        const { name } = company
        // MSP matches search
        const nameMatch = name && String(name).toLowerCase().includes(searchFilterText.toLowerCase())

        // One or more of this MSP's customers matches search
        const childMatch = company.children.some(({ name }) =>
          name && String(name).toLowerCase().includes(searchFilterText.toLowerCase())
        )
        if (childMatch) {
          parentMatches.push(company.id)
        }
        return nameMatch || childMatch > 0
      })
      setExpandedRows(parentMatches)
    }

    setCompanies(companies)
  }, [allCompanies, setExpandedRows])
  const debouncedApplyCompanyFilters = useCallback(_debounce(applyCompanyFilters, 500), [applyCompanyFilters])
  useEffect(() => {
    debouncedApplyCompanyFilters(searchFilterText)
  }, [debouncedApplyCompanyFilters, searchFilterText])

  useEffect(() => {
    if (currentPeriod) {
      if (period && periodOptions.find(({ value }) => value === period)) {
        return
      }
      updatePeriod(currentPeriod)
    }
  }, [currentPeriod, period, periodOptions, updatePeriod])

  const [expandAll, setExpandAll] = useState(false)
  const toggleExpandAll = useCallback(() => {
    if (expandAll) {
      setExpandedRows([])
      setExpandAll(false)
    } else {
      setExpandedRows(companies.map(c => c.id))
      setExpandAll(true)
    }
  }, [expandAll, setExpandedRows, companies])
  useEffect(() => {
    setExpandAll(companies.length > 0 && companies.every(c => expandedRows.includes(c.id)))
  }, [companies, expandedRows])

  const tabs = useMemo(() => {
    const tabs = []

    tabs.push({
      tab: I18n.t('platformUsage', trOpt),
      key: 'platformUsage',
      content: (
        <MspTable
          {...{
            loading,
            companies,
            sorter,
            updateSorter,
            filters,
            updateFilters,
            pagination,
            updatePagination,
            client,
            companyId,
            userId,
            accountType,
            listAccountType: editAccountType,
            cost,
            costIsEstimate,
            currency,
            totalUsers,
            totalPlatformCost,
            tax,
            totalCost,
            expandedRows,
            setExpandedRows,
            searchFilterText
          }}
        />
      )
    })

    if (_isInteger(totalBreachDomains)) {
      tabs.push({
        tab: I18n.t('uBreachProUsage', trOpt),
        key: 'uBreachProUsage',
        content: (
          <UBreachUsageTable
            {...{
              loading,
              companies,
              sorter,
              updateSorter,
              updateFilters,
              pagination,
              updatePagination,
              client,
              companyId,
              userId,
              accountType,
              listAccountType: editAccountType,
              cost,
              currency,
              totalBreachProCost,
              totalBreachDomains,
              tax,
              searchFilterText
            }}
          />
        )
      })
    }

    return tabs
  }, [loading, companies, sorter, updateSorter, filters, updateFilters, pagination, updatePagination, client,
    companyId, userId, accountType, editAccountType, cost, costIsEstimate, currency, totalUsers, totalPlatformCost, tax, totalCost, expandedRows, setExpandedRows, searchFilterText, totalBreachDomains, totalBreachProCost])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5em' }}>
        <IntercomHeader Size='h1' id='uService-invoice-header' style={{ marginBottom: 0 }}>{I18n.t('title', { ...trOpt, product: I18n.t('common.uService') })}</IntercomHeader>
        <div style={{ display: 'flex', gap: 10 }}>
          <PeriodField>
            <label>{I18n.t('period', trOpt)}:</label>
            <Select value={periodOptions.length === 0 ? null : period} onChange={onPeriodChange} disabled={periodOptions.length === 0}>
              {
                periodOptions.map(({ value, label }, optIndex) => (<Option key={optIndex} value={value}>{label}</Option>))
              }
            </Select>
          </PeriodField>
          <UServiceInvoiceDownload {...{ company, distributor: true, companies: invoiceCompanies, period, currency, cost, costIsEstimate, totalUsers, totalPlatformCost, tax, totalCost, lastUpdatedAt, totalBreachDomains, totalBreachProCost, subTotal }} />
        </div>
      </div>
      {_isInteger(totalCost) && currency && <Alert style={{ flexBasis: '100%' }} showIcon message={I18n.t('costDisclaimerDistributor', trOpt)} />}
      <div style={{ margin: '20px 0 40px' }}>
        <UServiceInvoiceSummary
          {...{
            userAccountType,
            loading,
            totalBreachDomains,
            totalBreachProCost,
            totalPlatformCost,
            platformCost: cost,
            platformUsage: totalUsers,
            currency,
            subTotal,
            tax,
            total: totalCost
          }}
        />
      </div>
      <ListHeader>
        <ListHeaderPanel align='left'>
          <h2>{I18n.t([tabKey], trOpt)}</h2>
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          <Button type='primary' icon={expandAll ? 'minus-square' : 'plus-square'} onClick={toggleExpandAll}>{expandAll ? I18n.t('collapseAll', trOpt) : I18n.t('expandAll', trOpt)}</Button>
          <SearchBar
            placeholder={I18n.t('uService.searchForACompany')}
            value={searchFilterText}
            allowClear
            onChange={onSearchChange}
          />
        </ListHeaderPanel>
      </ListHeader>
      {error
        ? <ErrorAlerts {...{ error }} defaultError={I18n.t('theInvoiceDataCouldNotBeLoaded', { ...trOpt, editAccountType })} />
        : (
          <Tabs activeKey={tabKey} onChange={onTabChange}>
            {
              tabs.map(({ tab, key, content }) => (
                <TabPane key={key} tab={tab}>
                  <TabContainer>
                    {content}
                  </TabContainer>
                </TabPane>
              ))
            }
          </Tabs>
        )}
      {lastUpdatedAt ? <div>{I18n.t('lastUpdated', { ...trOpt, datetime: formatDateTime(lastUpdatedAt) })}</div> : null}
    </>
  )
}

const DistributorInvoice = ({ match, userId, companyId, client, userAccountType, paymentProvided, billingType }) => {
  const { params: { accountType } = {} } = match || {}
  const [period, updatePeriod] = useState(null)
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)
  const [expandedRows, setExpandedRows] = useState([])
  const [companyError, setCompanyError] = useState(null)
  const [invoiceError, setInvoiceError] = useState(null)

  const storageId = useMemo(() => `uServiceInvoiceDistributor|${companyId}|${userId}${accountType ? `|${accountType}` : ''}`, [userId, companyId, accountType])
  useEffect(() => {
    try {
      let listFilter = {
        searchFilterText: '',
        filters: null,
        pagination: undefined,
        expandedRows: []

      }
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        listFilter = { ...listFilter, ...JSON.parse(listFilterString) }
      }
      Object.keys(listFilter).forEach(key => {
        const value = listFilter[key]
        switch (key) {
          case 'searchFilterText':
            updateSearchFilterText(value)
            break
          case 'period':
            updatePeriod(value)
            break
          case 'filters':
            updateFilters(value)
            break
          case 'sorter':
            updateSorter(value)
            break
          case 'pagination':
            updatePagination(value)
            break
          case 'expandedRows':
            setExpandedRows(value)
            break
          default:
            break
        }
      })
    } catch (e) { }
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    let listFilter = {}
    try {
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        listFilter = JSON.parse(listFilterString)
      }
      listFilter = { ...listFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(listFilter))
    } catch (e) { }
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      period,
      filters,
      sorter,
      pagination,
      expandedRows
    })
  }, [updateLocalStorage, searchFilterText, period, filters, sorter, pagination, expandedRows])

  // TODO Check if this will ever be shown on this page given that MSPs shouldn't be using it
  const renderBillingBanner = useCallback(companiesMap => {
    if (userAccountType === 'msp' && !paymentProvided && billingType === 'auto') {
      const companies = Object.keys(companiesMap).map(id => ({ id, ...companiesMap[id] }))
      const payingTenantCount = companies.filter(company => company.id !== companyId && !company.freeTrial).length
      return payingTenantCount > 0 ? <AddBillingMessage /> : null
    }
  }, [userAccountType, paymentProvided, billingType, companyId])

  const { loading: getCompanyLoading, data: { company } = {} } = useQuery(GET_COMPANY_FOR_INVOICE, {
    onError: setCompanyError
  })
  const { loading: invoicesLoading, data: { uServiceInvoices } = {} } = useQuery(USERVICE_INVOICES_DISTRI, {
    onError: setInvoiceError
  })

  const { periods = [], companiesMap = {}, invoicePeriodsDistributor: invoicePeriods = {} } = uServiceInvoices || {}

  return (
    <>
      {renderBillingBanner(companiesMap)}
      <ContentWrap>
        <Card>
          <CompaniesView
            {...{
              client,
              loading: getCompanyLoading || invoicesLoading,
              error: ((companyError || invoiceError) ? [companyError, invoiceError] : null),
              periods,
              companiesMap,
              invoicePeriods,
              companyId,
              userId,
              searchFilterText,
              updateSearchFilterText,
              sorter,
              updateSorter,
              filters,
              updateFilters,
              pagination,
              updatePagination,
              accountType,
              userAccountType,
              period,
              updatePeriod,
              company,
              expandedRows,
              setExpandedRows
            }}
          />
        </Card>
      </ContentWrap>
    </>
  )
}

const MspTable = withTheme(({
  companies = [], loading = false,
  sorter, updateSorter,
  filters, updateFilters,
  pagination, updatePagination,
  cost,
  costIsEstimate = false,
  currency,
  totalPlatformCost,
  tax,
  totalCost = 0,
  totalUsers = 0,
  expandedRows = [], setExpandedRows = () => {},
  searchFilterText,
  theme
}) => {
  const onTableChange = useCallback((pagination, filters, sorter) => {
    updatePagination(pagination)
    updateFilters(filters)
    updateSorter(sorter)
  }, [updateFilters, updateSorter, updatePagination])
  const hasCost = _isInteger(cost) && CURRENCIES.includes(currency)
  const hasTax = hasCost && _isInteger(tax)

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}
    const columns = [
      {
        key: 'expand',
        width: 20
      },
      {
        title: I18n.t('common.fields.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortColumnKey === 'name' && order,
        // Highlight children when expanded due to filter
        render: (text, record) =>
          <div className='name-container'>
            {searchFilterText && !record.children && text && String(text).toLowerCase().includes(searchFilterText.toLowerCase()) ? (
              <Highlighter
                highlightStyle={{ backgroundColor: theme.primary, padding: '0 2px', borderRadius: 2, color: theme.white }}
                searchWords={[searchFilterText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ) : (
              text
            )}
          </div>
      }, {
        title: I18n.t('maxUsers', trOpt),
        key: 'maxUsers',
        dataIndex: 'maxUsers',
        sorter: (a, b) => a.maxUsers - b.maxUsers,
        sortOrder: sortColumnKey === 'maxUsers' && order,
        render: (text, row) => {
          if (row.exemptFromDistributorBilling) return I18n.t('common.na')
          if (row.billableUsers) return `${text} ${row.billableUsers ? '*' : ''}`

          return text
        }
      }
    ]
    if (hasCost) {
      columns.push({
        title: hasTax ? I18n.t('costExcludingTax', trOpt) : I18n.t('cost', trOpt),
        key: 'cost',
        dataIndex: 'costStr',
        sorter: (a, b) => a.cost - b.cost,
        sortOrder: sortColumnKey === 'cost' && order
      })
    }
    if (hasTax) {
      columns.push({
        title: I18n.t('costIncludingTax', trOpt),
        key: 'costInclTax',
        dataIndex: 'costInclTaxStr',
        sorter: (a, b) => a.costInclTax - b.costInclTax,
        sortOrder: sortColumnKey === 'costInclTax' && order
      })
    }

    return columns
  }, [sorter, hasCost, hasTax, searchFilterText, theme])

  return (
    <>
      <ListHeader>
        <ListHeaderPanel>
          <h4>{I18n.t('uService.companyCount', { count: companies.length })}</h4>
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          {hasCost ? <h4>{I18n.t('costPerUser', trOpt)}{costIsEstimate && '*'}: {displayMoney(cost, currency)}</h4> : null}
          <h4>{I18n.t('totalUsers', trOpt)}: {totalUsers}</h4>
          {hasCost ? <h4>{I18n.t('totalCost', trOpt)}: {displayMoney(totalPlatformCost, currency)}</h4> : null}
        </ListHeaderPanel>

      </ListHeader>
      <TableContainer>
        <Table
          columns={columns} dataSource={companies} loading={loading}
          onChange={onTableChange}
          pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={setExpandedRows}
        />
      </TableContainer>
      {costIsEstimate && <div>{I18n.t('costPerUserIsApproximatelyEqual', { ...trOpt, total: hasTax ? 'totalPlatformCost' : 'total' })}</div>}
    </>
  )
})

const UBreachUsageTable = withTheme(({
  companies = [], loading = false,
  sorter, updateSorter,
  updateFilters,
  pagination, updatePagination,
  cost,
  currency,
  totalBreachProCost,
  totalBreachDomains,
  tax,
  searchFilterText,
  theme
}) => {
  const onTableChange = useCallback((pagination, filters, sorter) => {
    updatePagination(pagination)
    updateFilters(filters)
    updateSorter(sorter)
  }, [updateFilters, updateSorter, updatePagination])
  const hasCost = _isInteger(cost) && CURRENCIES.includes(currency)
  const hasTax = hasCost && _isInteger(tax)

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}
    const columns = [
      {
        title: I18n.t('common.fields.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortColumnKey === 'name' && order,
        render: (_text, row) => row.name
      }, {
        title: I18n.t('uBreachProEnabled', trOpt),
        key: 'uBreachProEnabled',
        dataIndex: 'uBreachProEnabled',
        sorter: (a, b) => a.uBreachProEnabled - b.uBreachProEnabled,
        sortOrder: sortColumnKey === 'uBreachProEnabled' && order,
        render: (_text, row) => {
          return row.uBreachProEnabled
            ? I18n.t('common.yes')
            : I18n.t('common.no')
        }
      }, {
        title: I18n.t('domainCount', trOpt),
        key: 'uBreachDomains',
        dataIndex: 'uBreachDomains',
        sorter: (a, b) => a.uBreachDomains - b.uBreachDomains,
        sortOrder: sortColumnKey === 'uBreachDomains' && order,
        render: (_text, row) => {
          if (!row.uBreachProEnabled) return I18n.t('common.na')
          return `${row.uBreachDomains}`
        }
      }
    ]
    if (hasCost) {
      columns.push({
        title: hasTax ? I18n.t('costExcludingTax', trOpt) : I18n.t('cost', trOpt),
        key: 'uBreachProCost',
        dataIndex: 'uBreachProCost',
        sorter: (a, b) => a.uBreachProCost - b.uBreachProCost,
        sortOrder: sortColumnKey === 'uBreachProCost' && order,
        render: (_text, row) => {
          return `${displayMoney(row.uBreachProCost, currency)}`
        }
      })
    }
    if (hasTax) {
      columns.push({
        title: I18n.t('costIncludingTax', trOpt),
        key: 'uBreachProCostInclTax',
        dataIndex: 'uBreachProCostInclTax',
        sorter: (a, b) => a.uBreachProCostInclTax - b.uBreachProCostInclTax,
        sortOrder: sortColumnKey === 'uBreachProCostInclTax' && order,
        render: (_text, row) => {
          return `${displayMoney(row.uBreachProCostInclTax, currency)}`
        }
      })
    }

    return columns
  }, [sorter, hasCost, hasTax, currency])

  return (
    <>
      <ListHeader>
        <ListHeaderPanel>
          <h4>{I18n.t('uService.companyCount', { count: companies.length })}</h4>
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          {hasCost ? <h4>{I18n.t('totalCost', trOpt)}: {displayMoney(totalBreachProCost, currency)}</h4> : null}
        </ListHeaderPanel>
      </ListHeader>
      <TableContainer>
        <Table
          columns={columns} dataSource={companies} loading={loading}
          onChange={onTableChange}
          pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
          // Expanded rows have to be explicitly set to false/nullish to prevent the expand icon, etc. from showing
          expandedRows={false}
          expandedRowKeys={[]}
          expandIcon={() => null}
        />
      </TableContainer>
    </>
  )
})

export default compose(
  withConsumer,
  connect(state => {
    const { userId, companyId, accountType: userAccountType, paymentProvided, billingStartDate } = selectors.session.get(state)
    return { userId, companyId, userAccountType, paymentProvided, billingStartDate }
  })
)(DistributorInvoice)
