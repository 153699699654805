import React, { useCallback, useEffect, useMemo } from 'react'
import { Icon, Layout, Menu } from 'antd'
import styled from 'styled-components'
import { lighten } from 'polished'
import I18n from 'i18n-js'
import { generatePath } from 'react-router-dom'
import _isArray from 'lodash/isArray'
import { PreviewTag } from '../common'

import routes from '../../constants/routes'
import { ENABLE_REPORT_CENTRE, REPORT_CENTRE_PREVIEW, ENABLE_MESSAGE_INJECTION, SSO_ALLOWED } from '../../constants/environment'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const { Sider: _Sider } = Layout
const { SubMenu } = Menu
const trOpt = { scope: 'settings.settingsSidebar' }

const getMenuItemTitle = ({ key, trKey }) => trKey ? I18n.t(trKey) : I18n.t(key, { ...trOpt, defaultValue: key })

const ULEARN_MENU = [
  { key: 'courseWaitTime', routeId: 'course-slide-delay', trKey: 'settings.courseWaitTime.title' },
  { key: 'courseReminder', routeId: 'course-reminders' },
  { key: 'gapAnalysisReminder', routeId: 'gap-analysis-reminders' },
  { key: 'minimumPassScore', routeId: 'minimum-pass-score', trKey: 'settings.minimumPassScore.title' },
  { key: 'autoEnrol', routeId: 'auto-enrol', trKey: 'common.autoEnrol' },
  { key: 'compulsoryCourses', routeId: 'compulsory-courses', trKey: 'settings.compulsoryCourses.title' },
  { key: 'videoPlayback', routeId: 'video-playback', trKey: 'settings.videoPlayback.title' }
]
const UPOLICY_MENU = [
  { key: 'policyReminder', routeId: 'policy-reminders' },
  { key: 'policyExternalLink', routeId: 'external-access', trKey: 'settings.policyExternalLink.title' },
  { key: 'policySignatureSettings', routeId: 'signature-settings' }
]
const UPHISH_MENU = [
  { key: 'autoPhish', routeId: 'auto-phish', trKey: 'uPhish.autoPhish.title' },
  { key: 'messageInjection', routeId: 'message-injection', trKey: 'settings.messageInjection.title', anyPermissions: [permissions.SETTINGS_GENERAL_UPDATE, permissions.SETTINGS_GENERAL_READ] },
  { key: 'phishAlert', routeId: 'phish-alert-button', trKey: 'settings.phishAlert.title', preview: window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_PREVIEW === 'true' },
  { key: 'compromiseMessage', routeId: 'compromise-message', trKey: 'settings.compromiseMessage.title' },
  { key: 'workingHours', routeId: 'working-hours-timezone', trKey: 'settings.workingHours.title' },
  { key: 'topLevelDomainAllowList', routeId: 'tld', trKey: 'settings.tldAllowList.title' }
]
const EMAIL_MENU = [
  { key: 'defaultEmail', routeId: 'default', anyPermissions: [permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_EMAIL_UPDATE] },
  { key: 'whitelisting', routeId: 'allowlisting', anyPermissions: [permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_EMAIL_UPDATE] },
  { key: 'gapAnalysisEmail', routeId: 'gap-analysis', anyPermissions: [permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_ULEARN_UPDATE] },
  { key: 'courseEmail', routeId: 'courses', anyPermissions: [permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_ULEARN_UPDATE] },
  { key: 'weeklySummaryEmail', routeId: 'weekly-summary', anyPermissions: [permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_EMAIL_UPDATE] },
  { key: 'googleSyncEmail', routeId: 'google-workspace', anyPermissions: [permissions.SETTINGS_SYNC_READ, permissions.SETTINGS_SYNC_UPDATE] },
  { key: 'office365Email', routeId: 'office-365', anyPermissions: [permissions.SETTINGS_SYNC_READ, permissions.SETTINGS_SYNC_UPDATE] },
  { key: 'dummySimulationEmail', routeId: 'simulation-tests', anyPermissions: [permissions.SETTINGS_UPHISH_READ, permissions.SETTINGS_UPHISH_UPDATE] },
  { key: 'policyEmail', routeId: 'policies', trKey: 'uPolicy.common.policies', anyPermissions: [permissions.SETTINGS_UPOLICY_READ, permissions.SETTINGS_UPOLICY_UPDATE] },
  { key: 'reportEmail', routeId: 'reports', preview: REPORT_CENTRE_PREVIEW, anyPermissions: [permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_EMAIL_UPDATE] },
  { key: 'riskReportEmail', routeId: 'risk-report', trKey: 'settings.riskReport.title', anyPermissions: [permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_EMAIL_UPDATE] },
  { key: 'riskReportParentEmail', routeId: 'risk-report', trKey: 'settings.riskReport.title', anyPermissions: [permissions.SETTINGS_RISK_REPORT_READ, permissions.SETTINGS_RISK_REPORT_UPDATE] },
  { key: 'breachAlertEmail', routeId: 'breach-alert', trKey: 'settings.breachAlertEmail.title', anyPermissions: [permissions.SETTINGS_UBREACH_READ, permissions.SETTINGS_UBREACH_UPDATE] }
]
const PLATFORM_MENU = [
  { key: 'preferredDomain', routeId: 'domain', icon: 'link', trKey: 'settings.preferredDomain.title' },
  {
    key: 'emails',
    icon: 'mail',
    menu: EMAIL_MENU,
    anyPermissions: [
      permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_EMAIL_UPDATE,
      permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_ULEARN_UPDATE,
      permissions.SETTINGS_UPHISH_READ, permissions.SETTINGS_UPHISH_UPDATE,
      permissions.SETTINGS_UPOLICY_READ, permissions.SETTINGS_UPOLICY_UPDATE,
      permissions.SETTINGS_SYNC_READ, permissions.SETTINGS_SYNC_UPDATE,
      permissions.SETTINGS_RISK_REPORT_READ, permissions.SETTINGS_RISK_REPORT_UPDATE,
      permissions.SETTINGS_UBREACH_READ, permissions.SETTINGS_UBREACH_UPDATE
    ]
  },
  { key: 'theme', icon: 'picture', trKey: 'common.theme' },
  { key: 'language', trKey: 'common.language', icon: 'global' },
  { key: 'timezone', trKey: 'settings.timezone.title', icon: 'clock-circle' },
  { key: 'sso', routeId: 'sso', icon: 'idcard', trKey: 'settings.sso.title', anyPermissions: [permissions.SETTINGS_SSO_READ, permissions.SETTINGS_SSO_UPDATE] },
  { key: 'uLearn', routeId: 'learn', icon: 'book', menu: ULEARN_MENU, trKey: 'common.uLearn', anyPermissions: [permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_ULEARN_UPDATE] },
  { key: 'uPhish', routeId: 'phish', icon: 'file-exclamation', trKey: 'common.uPhish', menu: UPHISH_MENU, anyPermissions: [permissions.SETTINGS_UPHISH_READ, permissions.SETTINGS_UPHISH_UPDATE] },
  { key: 'uPolicy', routeId: 'policy', icon: 'check-square', menu: UPOLICY_MENU, trKey: 'common.uPolicy', anyPermissions: [permissions.SETTINGS_UPOLICY_READ, permissions.SETTINGS_UPOLICY_READ] },
  { key: 'uBreachPro', routeId: 'breach', icon: 'exclamation-circle', trKey: 'common.uBreach', anyPermissions: [permissions.SETTINGS_UBREACH_READ, permissions.SETTINGS_UBREACH_UPDATE] },
  { key: 'weeklySummary', routeId: 'weekly-summary', icon: 'calendar', trKey: 'settings.weeklySummary.title' },
  { key: 'reportSettings', routeId: 'reports', icon: 'file-done' },
  { key: 'riskReport', routeId: 'risk-report', icon: 'profile', trKey: 'settings.riskReport.title', anyPermissions: [permissions.SETTINGS_RISK_REPORT_READ, permissions.SETTINGS_RISK_REPORT_UPDATE] },
  { key: 'api', icon: 'cloud' },
  { key: 'office365', routeId: 'office-365', icon: 'windows', trKey: 'office365.common.office365', anyPermissions: [permissions.SETTINGS_SYNC_READ, permissions.SETTINGS_SYNC_UPDATE] },
  { key: 'googleSync', routeId: 'google-sync', icon: 'google', trKey: 'googleSync.common.googleSync', anyPermissions: [permissions.SETTINGS_SYNC_READ, permissions.SETTINGS_SYNC_UPDATE] },
  { key: 'endUserPortal', routeId: 'end-user-portal', icon: 'radar-chart', trKey: 'settings.endUserPortal.controlAccess.title' },
  { key: 'outstandingActivitiesLogin', routeId: 'outstanding-activities', icon: 'book', trKey: 'settings.outstandingActivitiesLogin.title' },
  { key: 'productNames', routeId: 'product-names', icon: 'tag', trKey: 'settings.productNames.title' }
]
const DEFAULT_CUSTOMER_MENU = [
  { key: 'features', icon: 'appstore' },
  ...PLATFORM_MENU
]
const DEFAULT_CUSTOMER_EXCLUDE_KEYS = ['office365', 'googleSync', 'policyExternalLink', 'riskReportParentEmail', 'api', 'riskReport', 'productNames', 'messageInjection', 'sso']
const PLATFORM_MENU_MSP_WO_ACCESS_KEYS = ['theme', 'riskReport', 'emails', 'riskReportParentEmail', 'whitelisting', 'defaultEmail', 'preferredDomain', 'language', 'api']
const redirects = {
  learn: 'ulearn',
  phish: 'uphish',
  policy: 'upolicy'
}

const Sider = styled(_Sider)`
  background-color: #ffffff;
  border-right: 1px solid #e8e8e8;
  overflow-x: hidden;
  overflow-y: auto;
`

const SettingsMenu = styled(Menu)`
  border-right: none;

  .ant-menu-submenu-selected {
    color: ${({ theme }) => theme.primary};
  }
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: ${({ theme }) => theme.primary};
  }
  .ant-menu-item-selected {
    color: ${({ theme }) => theme.primary} !important;
    background-color: ${({ theme }) => lighten(0.6, theme.primary)} !important;
  }
  .ant-menu-item:after {
    border-right-color: ${({ theme }) => theme.primary};
  }
`

const TopSettingsMenu = styled(SettingsMenu)`
  padding: 15px 0 20px;
`

const MenuItemTitle = ({ icon, title, preview = false }) => (
  <>
    <span>{icon ? <Icon type={icon} /> : null}{title}</span>
    {preview && <PreviewTag />}
  </>
)

const renderMenuItem = ({ key, title, icon, preview }) => (
  <Menu.Item key={key}>
    <MenuItemTitle {...{ icon, title, preview }} />
  </Menu.Item>
)

const renderSubMenu = ({ key, title, icon, menu, preview }) => {
  if (menu) {
    return (
      <SubMenu
        key={key}
        title={<MenuItemTitle {...{ icon, title, preview }} />}
      >
        {
          menu.map(({ key, title, icon, menu, preview }) =>
            menu
              ? renderSubMenu({ key, title, icon, menu, preview })
              : renderMenuItem({ key, title, icon, preview })
          )
        }
      </SubMenu>
    )
  }

  return renderMenuItem({ key, title, icon, preview })
}

const filterMenu = ({ menu: sourceMenu, allowedKeys, excludeKeys, hasAllSessionPermissions = () => false, hasAnySessionPermission = () => false, skipPermissionsCheck = false, defaultAnyPermissions = [permissions.SETTINGS_GENERAL_READ, permissions.SETTINGS_GENERAL_UPDATE], defaultAllPermissions = [] }) => {
  let menu = sourceMenu
  if (_isArray(allowedKeys)) {
    menu = menu.filter(({ key }) => allowedKeys.includes(key))
  }
  if (_isArray(excludeKeys)) {
    menu = menu.filter(({ key }) => !excludeKeys.includes(key))
  }
  if (!skipPermissionsCheck) {
    // Remove any menu items that require permissions that the user does not have
    menu = menu.filter(({ anyPermissions = defaultAnyPermissions, allPermissions = defaultAllPermissions }) => {
      let allowed = true
      if (_isArray(allPermissions)) {
        allowed = hasAllSessionPermissions(allPermissions)
      }
      if (_isArray(anyPermissions) && allowed) {
        allowed = hasAnySessionPermission(anyPermissions)
      }
      return allowed
    })
  }

  return menu
}

const getFilteredSettingsMenu = ({ menu, allowedKeys, excludeKeys, hasAllSessionPermissions, hasAnySessionPermission, skipPermissionsCheck, defaultAnyPermissions = [permissions.SETTINGS_GENERAL_READ, permissions.SETTINGS_GENERAL_UPDATE], defaultAllPermissions = [] } = {}) => {
  return filterMenu({ menu, allowedKeys, excludeKeys, hasAllSessionPermissions, hasAnySessionPermission, skipPermissionsCheck, defaultAnyPermissions, defaultAllPermissions })
    .map(({ key, routeId, icon, menu, trKey, preview, anyPermissions = defaultAnyPermissions, allPermissions = defaultAllPermissions }) => {
      const item = {
        key,
        routeId,
        icon,
        title: getMenuItemTitle({ key, trKey }),
        preview
      }
      if (menu) {
        const opt = {
          menu,
          allowedKeys,
          excludeKeys,
          hasAllSessionPermissions,
          hasAnySessionPermission,
          skipPermissionsCheck,
          // Pass the permissions down to the next level, this ensures that child menu items inherit the permissions of their parent
          // if they don't have their own permissions set
          defaultAnyPermissions: anyPermissions,
          defaultAllPermissions: allPermissions
        }
        item.menu = getFilteredSettingsMenu(opt)
      }

      return item
    })
}

const getPlatformSettings = ({ menu: topMenu = PLATFORM_MENU, allowedKeys, excludeKeys, hasAllSessionPermissions, hasAnySessionPermission, skipPermissionsCheck } = {}) => {
  const settings = getFilteredSettingsMenu({ menu: topMenu, allowedKeys, excludeKeys, hasAllSessionPermissions, hasAnySessionPermission, skipPermissionsCheck })
  return { settings, settingsMenu: settings.map(renderSubMenu) }
}

const getSettingsOpenedKeys = (menu, path = []) =>
  menu.reduce((acc, item) => {
    if (item.menu) {
      acc = {
        ...acc,
        ...getSettingsOpenedKeys(item.menu, [...path, item.key])
      }
    } else {
      acc[item.key] = path
    }
    return acc
  }, {})
const getItemRouteId = item => item.routeId || item.key
const getSettingsPaths = (menu, path = []) =>
  menu.reduce((acc, item) => {
    if (item.menu) {
      acc = {
        ...acc,
        ...getSettingsPaths(item.menu, [...path, getItemRouteId(item)])
      }
      acc[item.key] = [...path, getItemRouteId(item), getItemRouteId(item.menu[0])].join('/')
    } else {
      acc[item.key] = [...path, getItemRouteId(item)].join('/')
    }
    return acc
  }, {})
const getSettingsPathKeys = (menu, path = []) =>
  menu.reduce((acc, item) => {
    const routeId = getItemRouteId(item)
    acc = addSettingsPathKeys(acc, path, item, routeId)
    // Add redirect paths to map object if set
    if (redirects[routeId]) acc = addSettingsPathKeys(acc, path, item, redirects[routeId])
    return acc
  }, {})

const addSettingsPathKeys = (acc, path, item, routeId) => {
  if (item.menu) {
    acc = {
      ...acc,
      ...getSettingsPathKeys(item.menu, [...path, routeId])
    }
    acc[[...path, routeId].join('/')] = item.menu[0].key
  } else {
    acc[[...path, routeId].join('/')] = item.key
  }
  return acc
}

const END_USER_PORTAL = window.__USECURE_CONFIG__.REACT_APP_END_USER_PORTAL === 'true'

const SettingsSidebar = ({
  partner, accountType, platformAccess,
  activeKey, updateActiveKey, openKeys, updateOpenKeys,
  uLearn = true, uPhish = true, uPolicy, office365Enabled = true, googleSyncEnabled = true, api = false, enablePublicApi = false,
  enableProspects = false, enableRiskReportTenants = false, prospectStatus,
  defaultTenant = false, phishAlertEnabled = false,
  history, screenPath, setSettingsRoutes, mspId, uBreachProEnabled = false

}) => {
  const { hasAllSessionPermissionsOriginally, hasAllSessionPermissions, hasAnySessionPermission } = useHasSessionPermission()
  const { settingsOpenedKeys, settingsMenu, settingsPaths, settingsPathKeys } = useMemo(() => {
    const isMSP = accountType === 'msp'
    const excludeKeys = []
    if (!uLearn) {
      excludeKeys.push('uLearn', 'gapAnalysisEmail', 'courseEmail')
    }
    if (!uPhish) {
      excludeKeys.push('uPhish', 'dummySimulationEmail')
    }
    if (!uLearn && !uPhish) {
      excludeKeys.push('weeklySummary', 'weeklySummaryEmail')
    }
    if (!uPolicy) {
      excludeKeys.push('uPolicy', 'policyEmail')
    }
    if (!office365Enabled) {
      excludeKeys.push('office365', 'office365Email')
    }
    if (!googleSyncEnabled) {
      excludeKeys.push('googleSync', 'googleSyncEmail')
    }
    if (!enablePublicApi && !api) {
      excludeKeys.push('api')
    }
    if (!((enableProspects || enableRiskReportTenants) && (hasAllSessionPermissions([permissions.USECURE_SUPER_ADMIN]) || isMSP))) {
      excludeKeys.push('riskReportParentEmail')
    }
    if (!phishAlertEnabled || accountType === 'prospect') {
      excludeKeys.push('phishAlert')
    }
    if (!uPolicy && !uLearn) {
      excludeKeys.push('outstandingActivitiesLogin')
    }
    if (!END_USER_PORTAL) {
      excludeKeys.push('endUserPortal')
    }
    if (!ENABLE_REPORT_CENTRE) {
      excludeKeys.push('reportEmail')
    }
    if (!ENABLE_MESSAGE_INJECTION) {
      excludeKeys.push('messageInjection')
    }
    if (!SSO_ALLOWED) {
      excludeKeys.push('sso')
    }
    // MSP Customers and Prospects are no allowed to set custom product names
    if (mspId && (accountType === 'tenant' || accountType === 'prospect')) {
      excludeKeys.push('productNames')
    }
    let platformSettings = null
    if (defaultTenant) {
    // Default Customer Settings Menu
      const excludeKeysPartner = [...excludeKeys, ...DEFAULT_CUSTOMER_EXCLUDE_KEYS]
      if (!enableProspects && !enableRiskReportTenants) {
        excludeKeysPartner.push('riskReportEmail')
      }
      if (!uBreachProEnabled) {
        excludeKeysPartner.push('breachAlertEmail', 'uBreachPro')
      }

      platformSettings = getPlatformSettings({ menu: DEFAULT_CUSTOMER_MENU, excludeKeys: excludeKeysPartner, skipPermissionsCheck: true })
    } else {
      const excludeKeysPlatform = [...excludeKeys]
      if (!(hasAllSessionPermissionsOriginally([permissions.COMPANY_READ]) && prospectStatus && prospectStatus !== 'finished')) {
        excludeKeysPlatform.push('riskReportEmail')
      }
      if (accountType === 'prospect') {
        excludeKeysPlatform.push('weeklySummaryEmail', 'weeklySummary', 'autoPhish', 'phishAlert', 'endUserPortal', 'sso')
      }
      if (!isMSP) {
        excludeKeysPlatform.push('riskReport')
      }
      if (!uBreachProEnabled) {
        excludeKeysPlatform.push('breachAlertEmail', 'uBreachPro')
      }
      if (partner && !platformAccess) {
        platformSettings = getPlatformSettings({ allowedKeys: PLATFORM_MENU_MSP_WO_ACCESS_KEYS, excludeKeys: excludeKeysPlatform, hasAllSessionPermissions, hasAnySessionPermission })
      } else {
        platformSettings = getPlatformSettings({ excludeKeys: excludeKeysPlatform, hasAllSessionPermissions, hasAnySessionPermission })
      }
    }
    const { settings, settingsMenu } = platformSettings
    const settingsPaths = getSettingsPaths(settings)

    const getPath = (path) => decodeURIComponent(generatePath(defaultTenant ? routes.DEFAULT_CUSTOMER_SETTINGS_SCREEN : routes.SETTINGS_SCREEN, { screenPath: settingsPaths[path] }))
    // At the moment this only covers 2 level menu; therefore, if there is a third level this function wouldn't pick it up
    const flattenSettings = settings.reduce((arr, setting) => {
      // If there's a menu array, get it and return it in our format
      const menu = setting.menu?.map(m => ({ text: m.title, value: m.key, path: getPath(m.key), parentText: setting.title }))
      arr.push({ text: setting.title, value: setting.key, path: getPath(setting.key), parentKey: null })

      if (menu) arr.push(...menu)

      return arr
    }, [])

    setSettingsRoutes(flattenSettings) // sends the settings array to parent
    return {
      settingsMenu,
      settingsOpenedKeys: getSettingsOpenedKeys(settings),
      settingsPaths,
      settingsPathKeys: getSettingsPathKeys(settings)
    }
  }, [
    accountType, api, defaultTenant, enableProspects, enableRiskReportTenants,
    office365Enabled, googleSyncEnabled, partner, platformAccess, prospectStatus, uLearn, uPhish, uPolicy,
    phishAlertEnabled, enablePublicApi, setSettingsRoutes, mspId, uBreachProEnabled, hasAllSessionPermissionsOriginally, hasAllSessionPermissions, hasAnySessionPermission
  ])

  const onSelect = useCallback(({ key }) => {
    history.push(decodeURIComponent(generatePath(defaultTenant ? routes.DEFAULT_CUSTOMER_SETTINGS_SCREEN : routes.SETTINGS_SCREEN, { screenPath: settingsPaths[key] })))
  }, [settingsPaths, history, defaultTenant])

  const onOpenChange = useCallback(openedKeys => updateOpenKeys(openedKeys), [updateOpenKeys])

  useEffect(() => {
    const key = settingsPathKeys[screenPath]
    if (key && key !== activeKey) {
      updateActiveKey(key)
      updateOpenKeys(settingsOpenedKeys[key] || [])
    } else if (!activeKey) {
      const firstKey = Object.values(settingsPathKeys)[0]
      updateActiveKey(firstKey)
      updateOpenKeys(settingsOpenedKeys[firstKey] || [])
    }
  }, [screenPath, settingsPathKeys, settingsOpenedKeys, activeKey, updateActiveKey, updateOpenKeys])

  return (
    <Sider width={340}>
      <TopSettingsMenu
        mode='inline'
        selectedKeys={[activeKey]}
        {...{ onSelect, openKeys, onOpenChange }}
      >
        {settingsMenu}
      </TopSettingsMenu>
    </Sider>
  )
}

export default SettingsSidebar
