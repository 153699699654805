import React, { useCallback } from 'react'
import { Button, message } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { RESEND_VERIFY_EMAIL } from '../Queries/Users'
import { showErrors } from '../../helpers'

const trOpt = { scope: 'accountSettings.verifyEmail' }

const VerifyEmailStatus = ({ email, disabled, isVerified }) => {
  const resend = useCallback(async () => {
    try {
      const { data: { resendVerificationEmail: success } } = await authenticatedClient.mutate({
        mutation: RESEND_VERIFY_EMAIL
      })
      if (!success) {
        throw new Error()
      }
      message.success(I18n.t('checkYourEmailForYourEmailValidationLink', trOpt))
    } catch (e) {
      showErrors(e, I18n.t('failedToSendEmailValidationLink', trOpt))
    }
  }, [])

  if (disabled || typeof isVerified !== 'boolean') {
    return null
  }

  return (
    isVerified
      ? <span><i className='fa fa-check' /> {I18n.t('verified', trOpt)}</span>
      : <span>{I18n.t('verificationEmailSentTo', { ...trOpt, email })}<Button type='link' onClick={resend}>{I18n.t('resend', trOpt)}</Button></span>
  )
}

export default VerifyEmailStatus
