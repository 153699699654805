import React from 'react'
import I18n from 'i18n-js'

import SettingsForm, { SettingsFormFieldExtra } from '../SettingsForm'
import { FORCE_VIMEO_PLAYER } from '../../../constants/environment'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.videoPlayback' }

class VideoPlayback extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'forceCourseVideoSubtitles',
      'forceCourseVimeoPlayer'
    ]
    this.defaultValue = {
      forceCourseVideoSubtitles: false,
      forceCourseVimeoPlayer: false
    }
    this.setInitialFormValuesOnMount = true

    this.requiredUpdatePermissions = [permissions.SETTINGS_ULEARN_UPDATE]
  }

  get headerId () {
    return 'settings-course-video-playback-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    return [{
      id: 'forceCourseVideoSubtitles',
      type: 'switch',
      label: I18n.t('forceCourseVideoSubtitles', trOpt),
      extra: <SettingsFormFieldExtra copy={I18n.t('forceCourseVideoSubtitlesExtra', trOpt)} />,
      defaultValue: this.defaultValue.forceCourseVideoSubtitles
    }, {
      id: 'forceCourseVimeoPlayer',
      type: 'switch',
      label: I18n.t('forceCourseVimeoPlayer', trOpt),
      extra: <SettingsFormFieldExtra copy={I18n.t('forceCourseVimeoPlayerExtra', trOpt)} />,
      defaultValue: this.defaultValue.forceCourseVimeoPlayer,
      visible: !FORCE_VIMEO_PLAYER
    }]
  }
}

export default VideoPlayback
