import moment from 'moment'

/**
 * Returns a localised moment format string based on the type and whether to include seconds/days/years
 * @param {string} type - The type of date to format. Can be 'date', 'time', or 'datetime'
 * @param {boolean} includeSeconds - Whether to include seconds in the format string (only applies to time and datetime)
 * @param {boolean} includeDay - Whether to include the day in the format string (only applies to date)
 * @param {boolean} includeYear - Whether to include the year in the format string (only applies to date)
 * @returns {string} - The moment format string
 */
export const getMomentFormatString = ({ type = 'date', includeSeconds = false, includeDay = true, includeYear = true }) => {
  switch (type) {
    case 'date':
      if (!includeDay) return 'MMM YYYY'
      if (!includeYear) return 'DD MMM'
      return 'L'
    case 'time':
      return includeSeconds ? 'LTS' : 'LT'
    case 'datetime':
      return includeSeconds ? 'L LTS' : 'L LT'
    default:
      return 'L'
  }
}

const formatDatetimeCore = (type, value, opt) => {
  const { inputFormat = null } = opt || {}
  const format = getMomentFormatString({ ...(opt || {}), type })
  return moment(value, inputFormat).format(format)
}

export const formatDate = (value, opt) => formatDatetimeCore('date', value, opt)

export const formatDateTime = (value, opt) => formatDatetimeCore('datetime', value, opt)

export const formatTime = (value, opt) => formatDatetimeCore('time', value, opt)
