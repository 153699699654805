import { types } from '../actions/session'
import { updateLocaleLibraries, getBrowserLocale } from '../../helpers/locale'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const {
  SESSION_FAIL,
  SESSION_INIT,
  SESSION_UPDATE,
  SESSION_LOCALE,
  SESSION_RESET
} = types

export const initialState = {
  initialised: false,
  ready: false,
  locale: DEFAULT_LANGUAGE,
  contentLocales: null,
  userId: null,
  name: '',
  firstName: '',
  lastName: '',
  permissions: [],
  companyId: null,
  companyName: '',
  accountType: null,
  partner: false,
  email: '',
  planName: null,
  planValid: true,
  expires: null,
  inDisguise: false,
  intercomHash: null,
  originalPermissions: null,
  multipleDisguises: false,
  billingType: null,
  paymentProvided: false,
  billingStartDate: null,
  managerTenant: false,
  distributorId: null,
  distributorName: null,
  distributorUBreachProEnabled: false,
  mspName: null,
  mspId: null,
  mspUBreachProEnabled: false,
  authRisk: false,
  commsType: null,
  prospectStatus: null,
  prospectSimulation: null,
  type: '',
  mfaToken: null,
  passwordAuthAllowed: false
}

const sessionUpdate = (prevState, payload) => {
  const newState = {
    ...prevState,
    ...payload,
    initialised: true,
    ready: true
  }

  if (newState.locale !== prevState.locale) {
    updateLocaleLibraries(newState.locale)
  }

  return newState
}

const actionsMap = {
  [SESSION_FAIL]: state => ({
    ...state,
    initialised: true,
    ready: false
  }),
  [SESSION_INIT]: (prevState, { payload: { session: payload } = {} }) => sessionUpdate(prevState, payload),
  [SESSION_UPDATE]: (prevState, { payload = {} }) => sessionUpdate(prevState, payload),
  [SESSION_LOCALE]: (prevState, { payload: locale = DEFAULT_LANGUAGE }) => {
    updateLocaleLibraries(locale)
    // Sets locale with making any other changes to the state
    return {
      ...prevState,
      locale
    }
  },
  [SESSION_RESET]: (state, { payload: initialised = true }) => {
    // Reset locale to browser locale
    const locale = getBrowserLocale()
    updateLocaleLibraries(locale)

    return {
      ...initialState,
      initialised,
      locale
    }
  }
}

const reducer = (state = initialState, action = {}) => {
  const fn = actionsMap[action.type]
  return fn ? fn(state, action) : state
}

export default reducer
