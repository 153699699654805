import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import MutationForm from '../MutationForm/MutationForm'
import { GET_COMPANY_FOR_UPDATE, UPDATE_COMPANY_PARENT_COMPANY } from '../Queries/Companies'
import { LoadingBlock } from '../common'
import { modalConfirmAsync, showErrors, UsecureError } from '../../helpers'

const trOpt = { scope: 'modals.updateCompanyParentCompanyModal' }

const EditModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const UpdateCompanyParentCompanyModal = React.forwardRef(({ refetchQueries = [], onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [parentCompanyFieldLabel, setParentCompanyFieldLabel] = useState(I18n.t('common.fields.owner'))
  const [parentCompanyAccountType, setParentCompanyAccountType] = useState([])
  const [parentCompanyOptions, setParentCompanyOptions] = useState([])
  const [companyName, setCompanyName] = useState(null)
  const [accountType, setAccountType] = useState(null)

  const client = useApolloClient()

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const setInitialFormValues = useCallback(async (values) => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async (companyId) => {
      try {
        setLoading(true)
        setVisible(true)
        const companyResult = await client.query({
          query: GET_COMPANY_FOR_UPDATE,
          variables: { id: companyId }
        })
        const company = _get(companyResult, 'data.company')
        const { id, name, parentCompanyId, parentCompany, accountType } = company

        let parentCompanyFieldLabel = I18n.t('common.fields.owner')
        let parentCompanyAccountType = null
        if (accountType === 'msp') {
          parentCompanyFieldLabel = I18n.t('common.accountTypes.distributor')
          parentCompanyAccountType = 'distributor'
        } else if (accountType === 'tenant' || accountType === 'prospect') {
          parentCompanyFieldLabel = I18n.t('common.accountTypes.msp')
          parentCompanyAccountType = 'msp'
        }

        setParentCompanyFieldLabel(parentCompanyFieldLabel)
        setParentCompanyAccountType(parentCompanyAccountType)
        setParentCompanyOptions(parentCompanyId ? [{ value: parentCompanyId, label: parentCompany?.name }] : [{ value: 'direct', label: I18n.t('common.managedByUsecure') }])
        setCompanyId(id)
        setCompanyName(name)
        setAccountType(accountType)
        await setInitialFormValues({ parentCompanyId: parentCompanyId || 'direct' })
        setLoading(false)
      } catch (e) {
        setVisible(false)
        showErrors(e, I18n.t('initialisationError', trOpt))
      }
    }
  }), [client, setInitialFormValues])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setCompanyId(null)
    setCompanyName(null)
    setAccountType(null)
    setParentCompanyFieldLabel(I18n.t('common.fields.owner'))
    setParentCompanyOptions([])
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(async () => {
    const confirm = await modalConfirmAsync({
      content: accountType === 'msp' ? I18n.t('confirmWarning', trOpt) : null
    })
    if (!confirm) {
      throw new UsecureError(I18n.t('cancelledMessage', trOpt), { level: 'info' })
    }
  }, [accountType])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  let title = I18n.t('title', trOpt)
  if (companyName) {
    title = I18n.t('titleName', { ...trOpt, name: companyName })
  } else {
    title = I18n.t('title', trOpt)
  }

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={title}
      afterClose={afterClose}
      width='50%'
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <MutationForm
        mutation={UPDATE_COMPANY_PARENT_COMPANY}
        variables={{ companyId }}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('submitLabel', trOpt)}
        fields={[{
          id: 'parentCompanyId',
          label: parentCompanyFieldLabel,
          type: 'companyAutoComplete',
          accountType: parentCompanyAccountType,
          includeDirect: true,
          required: true,
          initialOptions: parentCompanyOptions
        }]}
        ref={form}
        refetchQueries={refetchQueries}
        skipResetFieldsOnSubmit
      />
    </EditModal>
  )
})

export default UpdateCompanyParentCompanyModal
