import { Modal, message } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { ENROL_ON_GAP_ANALYSIS } from '../Queries/Courses'
import { showErrors } from '../../helpers'

const trOpt = { scope: 'modals.sendGapAnalysisReminderConfirm' }

const { confirm } = Modal

const sendGapAnalysisReminderConfirm = async (learnerId, client = authenticatedClient) => {
  confirm({
    title: I18n.t('prompt', trOpt),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        const { data: { enrollLearnersOnGapAnalysis: { success = false } } } = await client.mutate({
          mutation: ENROL_ON_GAP_ANALYSIS,
          variables: { learnerIds: [learnerId] }
        })

        if (!success) {
          throw new Error()
        }
        message.success(I18n.t('successMessage', trOpt))
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      }
    }
  })
}

export default sendGapAnalysisReminderConfirm
