import React, { useCallback } from 'react'
import { Button, Divider, Alert, message } from 'antd'
import I18n from 'i18n-js'

import { modalConfirmAsync, renderToString } from '../../helpers'

const trOpt = { scope: 'accountSettings.qrIntro' }

const AppLink = ({ id, href }) => (
  <a target='_blank' rel='noopener noreferrer' href={href}>{I18n.t(id, trOpt)}</a>
)

const QRIntro = ({ is2FAEnabled, disable2FA, setCurrentStep }) => {
  const handleSetupClick = useCallback(async () => {
    let goToRecovery = !is2FAEnabled
    if (is2FAEnabled) {
      // Reset 2FA and perform setup
      const confirm = await modalConfirmAsync({
        content: I18n.t('youreAboutToChangeYourTwoFADevice', trOpt)
      })
      if (confirm) {
        try {
          await disable2FA()
          goToRecovery = true
        } catch (e) {
          message.error(I18n.t('common.somethingWentWrong'))
        }
      }
    }
    if (goToRecovery) {
      setCurrentStep('recovery')
    }
  }, [is2FAEnabled, setCurrentStep, disable2FA])

  const goToTwoFactorSettings = useCallback(() => setCurrentStep('twoFactorSettings'), [setCurrentStep])

  return (
    <div style={{ paddingBottom: '32px' }}>
      {is2FAEnabled && (
        <>
          <Button
            style={{ padding: 0, margin: '0 0 25px 0' }}
            type='link' icon='arrow-left'
            onClick={goToTwoFactorSettings}
          >
            {I18n.t('accountSettings.common.backToSettings')}
          </Button>
          <Alert type='warning' style={{ marginBottom: '25px' }} showIcon message={I18n.t('youreAboutToChangeYourTwoFADevice', trOpt)} />
        </>
      )}
      <p>{I18n.t('twoFactorAuthAddsExtraLayerOfSecurity', trOpt)}</p>
      <img src='/images/usecure2fa.jpg' width='100%' height='135' alt='2fa overview' style={{ margin: '24px 0' }} />
      <ol style={{ display: 'flex', listStyle: 'none', padding: 0, fontSize: '12px' }}>
        <li style={{ width: '185px', marginRight: '36px' }}>{I18n.t('whenYouSignIn', trOpt)}</li>
        <li style={{ width: '230px', marginRight: '42px' }}>{I18n.t('whenLoggingIn', trOpt)}</li>
        <li style={{ width: '180px' }}>{I18n.t('onceYouEnterCode', trOpt)}</li>
      </ol>
      <Divider />
      <p>{I18n.t('useAnApplicationOnYourPhone', trOpt)}</p>
      <Button type='primary' style={{ marginBottom: '14px' }} onClick={handleSetupClick}>{I18n.t('setupUsingAnApp', trOpt)}</Button>
      <p
        style={{ fontSize: '12px', fontWeight: 400 }}
        dangerouslySetInnerHTML={{
          __html: I18n.t('recommendedApplications', {
            ...trOpt,
            googleLink: renderToString(<AppLink id='googleAuthenticator' href='https://support.google.com/accounts/answer/1066447' />),
            authyLink: renderToString(<AppLink id='authy' href='https://authy.com/guides/github/' />),
            onePasswordLink: renderToString(<AppLink id='onePassword' href='https://support.1password.com/one-time-passwords/' />),
            lastPassLink: renderToString(<AppLink id='lastPassAuthenticator' href='https://support.logmeininc.com/lastpass/help/lastpass-authenticator-lp030014' />)
          })
        }}
      />
    </div>
  )
}

export default QRIntro
