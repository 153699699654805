import { Card } from 'antd'
import styled from 'styled-components'

const ViewRecordCard = styled(Card)`
  min-height: 100%;

  &> .ant-card-body {
    height: 100%;
  }

  .view-record__header {
    display: flex;
    justify-content: space-between;

    .ant-tag {
      align-self: flex-start;
      font-size: 18px;
      margin-left: 5px;
      padding: 5px 7px 0px;
    }

    .view-record__buttons {
      .ant-btn {
        margin-left: 5px;
      }
      text-align: right;
    }
  }

  .view-record__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-tabs, .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: calc(100% - 60px);
    overflow: auto;
    padding: 12px 12px 0;
  }
`

export default ViewRecordCard
