// async/promise function for retrieving image dimensions for a URL
const getImageSize = async url => {
  const img = document.createElement('img')

  const promise = new Promise((resolve, reject) => {
    // Reject after 10 second timeout to avoid hanging promise
    const timeoutId = setTimeout(() => {
      reject(new Error('getImageSize timed out'))
    }, 10000)

    img.onload = () => {
      try {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth
        const height = img.naturalHeight

        // Resolve promise with the width and height
        clearTimeout(timeoutId)
        resolve({ width, height })
      } catch (e) {
        reject(e)
      } finally {
        clearTimeout(timeoutId)
      }
    }

    // Reject promise on error
    img.onerror = event => {
      clearTimeout(timeoutId)
      reject(event)
    }
  })

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url

  return promise
}

export default getImageSize
