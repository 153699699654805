import React from 'react'
import styled from 'styled-components'
import Checkbox from '../common/Checkbox'

const CheckboxWrap = styled.div`
  :not(:last-child) {
    margin-bottom: 1em;
  }
  
  display: table;
`

const CheckboxLabel = styled.span`
  color: rgb(124,112,107);
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
  vertical-align: middle;
`

const CheckboxLink = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 15px;
`

const CheckboxLinkCircle = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
`

const CheckboxLinkIcon = styled.i`
  color: white;
`

export default class ChecklistItem extends React.Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onLinkClick = this.onLinkClick.bind(this)
  }

  onChange () {
    const { onChange, index } = this.props
    if (typeof onChange === 'function' && typeof index === 'number') {
      onChange(index)
    }
  }

  onLinkClick () {
    const { onLinkClick, slideId } = this.props
    if (typeof onLinkClick === 'function') {
      onLinkClick(slideId)
    }
  }

  render () {
    const { checked, text, slideId } = this.props

    return (
      <CheckboxWrap>
        <Checkbox checked={checked} onClick={this.onChange} style={{ verticalAlign: 'middle' }} />
        <CheckboxLabel onClick={this.onChange}>{text}</CheckboxLabel>
        { slideId &&
        <CheckboxLink onClick={this.onLinkClick}>
          <CheckboxLinkCircle>
            <CheckboxLinkIcon className='fa fa-arrow-right' />
          </CheckboxLinkCircle>
        </CheckboxLink>
        }
      </CheckboxWrap>
    )
  }
}
