import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks'

import unauthenticatedClient from '../apollo-client/unauthenticatedClient'

// The ApolloProvider components from react-apollo and @apollo/react-hooks differs
// Which means that hooks e.g. useQuery cannot pick the client from the react-apollo provider and vice versa
// So this switch will use both until we've fully migrated to @apollo/react-hooks
const withProvider = (client = unauthenticatedClient) => Comp => {
  const WithProvider = ({ forwardRef, ...props }) => {
    return (
      <ApolloProvider client={client}>
        <ApolloProviderHooks client={client}>
          <Comp ref={forwardRef} {...props} />
        </ApolloProviderHooks>
      </ApolloProvider>
    )
  }

  return React.forwardRef((props, ref) => (
    <WithProvider {...props} forwardRef={ref} />
  ))
}

export default withProvider
