import I18n from 'i18n-js'

import { HIGH_WEIGHTED_BREACH_DATA_CLASSES, MEDIUM_WEIGHTED_BREACH_DATA_CLASSES, SEVERITY_OPTIONS } from '../constants/uBreach'

const trOpt = { scope: 'uBreach' }

// Table filters
export const breachFilter = (userBreaches) => {
  if (!userBreaches) {
    return []
  }

  return [
    ...userBreaches
      .reduce((acc, { breachName }) => {
        if (!acc.some(d => d.value === breachName)) {
          acc.push({ value: breachName, text: breachName })
        }
        return acc
      }, [])
      .sort((a, b) => a.text.localeCompare(b.text))
  ]
}

export const emailFilter = (userBreaches) => {
  if (!userBreaches) {
    return []
  }

  return [
    ...userBreaches
      .reduce((acc, { email }) => {
        if (!acc.some(d => d.value === email)) {
          acc.push({ value: email, text: email })
        }
        return acc
      }, [])
      .sort((a, b) => a.text.localeCompare(b.text))
  ]
}

export const resolvedFilter = (userBreaches) => {
  if (!userBreaches) {
    return []
  }

  return [
    { value: true, text: I18n.t('tables.resolved', trOpt) },
    { value: false, text: I18n.t('tables.unresolved', trOpt) }
  ]
}

export const dataClassFilter = (breaches) => {
  if (breaches.length === 0) {
    return []
  }

  return [
    ...breaches
      .reduce((acc, { dataClasses }) => {
        dataClasses.forEach(dataClass => {
          if (!acc.some(d => d.value === dataClass)) {
            acc.push({ value: dataClass, text: I18n.t(`dataClasses.${dataClass}`, { ...trOpt, defaults: [{ message: dataClass }] }) })
          }
        })
        return acc
      }, [])
      .sort((a, b) => a.text.localeCompare(b.text))
  ]
}

export const severityFilter = (breaches) => {
  if (breaches.length === 0) {
    return []
  }

  return [
    ...breaches
      .reduce((acc, { severity }) => {
        if (!acc.some(d => d.value === severity)) {
          const { label = '' } = getSeverityDetail(severity) || {}
          acc.push({ value: severity, text: label })
        }
        return acc
      }, [])
  ]
}

export const calculateAverageSeverity = (breaches) => {
  let averageSeverity = 0
  const accumulator = breaches.reduce((acc, { severity }) => {
    return acc + SEVERITY_OPTIONS.indexOf(severity)
  }, 0)
  if (averageSeverity !== accumulator) {
    averageSeverity = Math.round(accumulator / breaches.length)
  }
  return getSeverityDetail(SEVERITY_OPTIONS[averageSeverity])
}

export const sortDataClasses = (dataClasses) => {
  const highPriority = []
  const mediumPriority = []
  const lowPriority = []

  for (const dataClass of dataClasses) {
    if (HIGH_WEIGHTED_BREACH_DATA_CLASSES.includes(dataClass)) {
      highPriority.push(dataClass)
    } else if (MEDIUM_WEIGHTED_BREACH_DATA_CLASSES.includes(dataClass)) {
      mediumPriority.push(dataClass)
    } else {
      lowPriority.push(dataClass)
    }
  }

  return [
    ...highPriority.sort(),
    ...mediumPriority.sort(),
    ...lowPriority.sort()
  ]
}

export const getSeverityDetail = (severity) => {
  const options = {
    low: { color: 'green', label: I18n.t('category.low', trOpt) },
    medium: { color: 'orange', label: I18n.t('category.medium', trOpt) },
    high: { color: 'red', label: I18n.t('category.high', trOpt) }
  }

  return options[severity]
}
