import moment from 'moment'
import _forEach from 'lodash/forEach'
import _isArray from 'lodash/isArray'
import _isEqual from 'lodash/isEqual'
import _isNumber from 'lodash/isNumber'
import _isFunction from 'lodash/isFunction'
import _isEmpty from 'lodash/isEmpty'

// The default of reset=true is to adhere to approach to filter changes in USEC-191
// If we'd rather retain selections that are still relevant when filters change set this to false
export const updateSelectedIdsOnRecordSetUpdate = (updateSelectedIds = () => {}, records = [], { reset = true } = {}) =>
  updateSelectedIds((currentSelectedIds = []) => {
    // Unselect all
    if (reset === true || records.length === 0 || currentSelectedIds.length === 0) return []
    // Only leave records selected if they are still in the results set
    const recordIds = records.map(l => l.id)
    const validIds = currentSelectedIds.filter(id => recordIds.includes(id))
    if (validIds.length === currentSelectedIds.length) {
      // All currently selected records in the updated record set
      return currentSelectedIds
    }
    return validIds
  })

// Suspends query polling if 1 or more rows are selected
export const togglePollingBasedOnSelectedIds = (selectedIds, setPollingEnabled) => {
  if (!_isFunction(setPollingEnabled)) return
  setPollingEnabled(selectedIds.length === 0)
}

// This function expects each table column to have a key otherwise the onFilter function can't pulled in
export const onListTableSelectAll = ({
  selected, updateSelectedIds, filters, columns, records
} = {}) => {
  let selectedRowKeys = []
  if (selected) {
    selectedRowKeys = [...records]
    _forEach(filters, (filterValues, key) => {
      if (_isArray(filterValues) && filterValues.length > 0) {
        const { onFilter } = columns.find(c => c.key === key) || {}
        if (_isFunction(onFilter)) {
          selectedRowKeys = selectedRowKeys.filter(record =>
            filterValues.some(value => onFilter(value, record))
          )
        }
      }
    })
    selectedRowKeys = selectedRowKeys.map(el => el.key)
  }

  updateSelectedIds(selectedRowKeys)
}

export const onListTableChange = ({
  pagination, updatePagination,
  sorter, updateSorter,
  filters, prevFilters, updateFilters,
  onSelectAll
} = {}) => {
  if (_isFunction(updatePagination)) updatePagination(pagination)
  if (_isFunction(updateSorter)) updateSorter(sorter)

  // Deselect all rows when filters change
  if (_isFunction(onSelectAll) && !_isEqual(prevFilters, filters)) {
    onSelectAll(false)
  }
  if (_isFunction(updateFilters)) updateFilters(filters)
}

export const textColumnSorter = (columnId, a, b) => a[columnId].localeCompare(b[columnId])

export const dateTimeColumnSorter = (columnId, a, b) => {
  return (a[columnId] ? moment(a[columnId]).unix() : 0) - (b[columnId] ? moment(b[columnId]).unix() : 0)
}

export const numberColumnSorter = (columnId, a, b, defaultValue = -1) => {
  const aValue = _isNumber(a[columnId]) ? a[columnId] : defaultValue
  const bValue = _isNumber(b[columnId]) ? b[columnId] : defaultValue
  return aValue - bValue
}

export const idArrayColumnSorter = (columnId, idArray, a, b) => {
  const aIndex = idArray.indexOf(a[columnId])
  const bIndex = idArray.indexOf(b[columnId])
  return aIndex - bIndex
}

export const disableActions = (actions, disabledActions) => {
  if (_isArray(disabledActions) && !_isEmpty(disabledActions)) {
    return actions.map(action => ({ ...action, disabled: disabledActions.includes(action.key || action.id) }))
  }
  return actions
}

export const omitActions = (actions, keys) => actions.filter(action => !keys.includes(action.key || action.id))

export const processListActions = ({ actions, omittedActions, disabledActions }) =>
  disableActions(
    omitActions(actions, omittedActions),
    disabledActions
  )

export const searchRecords = ({ records, searchText, searchKeys }) => {
  if (!searchText) return records
  return records.filter((record) => {
    const searchValues = searchKeys.map((key) => record[key])
    const searchValue = searchValues.join(' ').toLowerCase()
    return searchValue.includes(searchText.toLowerCase())
  })
}
