import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { compose } from 'recompose'

import unauthenticatedClient from '../../apollo-client/unauthenticatedClient'
import { VERIFY_EMAIL } from '../../components/Queries/Users'
import { connect, withAppTitleAndIcons } from '../../hocs'
import { SignInContainer, SignInHeader } from '../../components/SignIn'
import { ErrorAlerts, ShortLoader } from '../../components/common'
import { creators as settingsActions } from '../../state/actions/settings'
import { captureSentryError } from '../../helpers/sentry'
import GoToLoginButton from '../../components/SignIn/GoToLoginButton'

const trOpt = { scope: 'verifyEmail' }

const LoadingContent = styled.div`
  text-align: center;
`

class VerifyEmail extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      status: 'loading',
      error: null
    }
  }

  processErrors () {
    // 'this' is the ErrorHandler class instance on this method
    // If this class component is reworked into a functional component do not use an arrow function or you'll break the processor prop
    // Filter out the no_user error from the back end as it is similar to the 'verifyEmail.thereWasAnErrorVerifyingYourEmail' translation key display by renderError
    this.errors = this.errors.filter(({ type }) => type !== 'no_user')
  }

  async componentDidMount () {
    const { updateSettings, match: { params: { token } = {} } = {} } = this.props

    updateSettings({ useSingleFavicon: true })

    try {
      await unauthenticatedClient.mutate({
        mutation: VERIFY_EMAIL,
        variables: { token }
      })
      this.setState({ status: 'success' })
    } catch (e) {
      captureSentryError(e, { msg: 'VerifyEmail.handleFailure - Error' })
      this.setState({ status: 'fail', error: e })
    }
  }

  render () {
    const { status } = this.state
    return (
      <SignInContainer>
        <SignInHeader>{I18n.t('verifyingYourAccount', trOpt)}</SignInHeader>
        <LoadingContent>
          {status === 'loading' && <ShortLoader />}
          {status === 'success' && (
            <>
              <p>{I18n.t('emailSuccessfullyVerified', trOpt)}</p>
              <p>{I18n.t('signIn.pleaseClickToGoToLogin')}</p>
              <GoToLoginButton />
            </>
          )}
          {status === 'fail' && (
            <>
              <p>{I18n.t('thereWasAnErrorVerifyingYourEmail', trOpt)}</p>
              <p>{I18n.t('contactSupport', trOpt)}</p>
              <ErrorAlerts error={this.state.error} processor={this.processErrors} showDefaultIfEmpty={false} />
            </>
          )}
        </LoadingContent>
      </SignInContainer>
    )
  }
}

export default compose(
  connect(
    undefined,
    dispatch => ({
      updateSettings: settings => dispatch(settingsActions.update(settings))
    })
  ),
  withAppTitleAndIcons({ isSignInPage: true })
)(VerifyEmail)
