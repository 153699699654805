import React, { useCallback, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import styled, { css } from 'styled-components'
import { withRouter, generatePath } from 'react-router-dom'
import I18n from 'i18n-js'
import { rgba, lighten, darken } from 'polished'
import _get from 'lodash/get'
import _has from 'lodash/has'

import { GET_RECOMMENDED_SIMULATIONS } from '../Queries/uPhish'
import { LoadingBlock, ErrorAlerts } from '../common'
import ViewRecommendSimulationModal from '../Modals/ViewRecommendSimulationModal'
import routes from '../../constants/routes'

const TILE_IMG_PROPS = ['tile', 'emailTemplate.tile', 'landingPage.tile']

const _TileCardImage = ({ className, src, onClick }) => (
  <div {...{ className, onClick }}>
    <div style={{ backgroundImage: src ? `url('${src}')` : null }} />
  </div>
)
const TileCardImage = styled(_TileCardImage)`
  height: 100%;

  &> div {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
  }
`
const _TileCard = ({ className, children }) => <div {...{ className }}>{children}</div>
const TileCard = styled(_TileCard)`
  .ant-card {
    border-radius: 5px;
    cursor: pointer;
    height: 215px;
    width: 215px;
    display: flex;
    flex-direction: column;

    &:hover {
      filter: drop-shadow(0px 0px 10px ${({ theme }) => rgba(theme.primary, 0.5)});
    }
  }
  .ant-card-head {
    border-bottom: none;
    min-height: unset;
    text-align: center;
  }
  .ant-card-head-title {
    padding: 22px 0 5px;
  }
  .ant-card-body {
    flex-grow: 1;
    padding: 3px;
  }

  ${({ promoted, theme }) => promoted ? css`
  position: relative;

  .ant-card {
    background-color: ${theme.cta};
    height: 260px;
    width: 260px;

    &:hover {
      filter: drop-shadow(0px 0px 15px ${({ theme }) => rgba(darken(0.2, theme.cta), 0.5)});
    }
  }
  .ant-card-bordered {
    border-color: ${theme.cta};
    border-radius: 8px;
    border-width: 5px 5px 30px;
  }
  .ant-card-head {
    background-color: ${theme.primary};
    border-radius: 8px 8px 0 0;
    border-top: solid 1px transparent;
  }
  .ant-card-head-title {
    color: ${theme.white};
    font-size: 20px;
  }
  .ant-card-body {
    background-color: ${theme.primary};
    border-bottom: solid 1px transparent;
    border-radius: 0 0 8px 8px;
    filter: drop-shadow(0px 6px 3px ${rgba(lighten(0.1, theme.primary), 0.9)});
    padding: 5px 5px 10px;
  }
  ` : ''}
`

const PromotedIcon = styled(({ className }) => <i className={`${className} fas fa-medal`} />)`
  color: ${({ theme }) => theme.cta};
  font-size: 28px;
  position: absolute;
  right: 30px;
  top: 5px;
`

const TileCardContent = styled(Card)`
  .ant-card-body {
    height: 100%;
  }
`

const PromotedLabel = styled.div`
  bottom: -33px;
  color: ${({ theme }) => theme.white};
  font-size: 18px;
  position: absolute;
  left: 0px;
  text-align: center;
  width: 100%;
`

const SimulationCard = ({ simulation, onClick: onClickProp = () => {} }) => {
  const onClick = useCallback(() => onClickProp(simulation.id, _get(simulation, 'config.skipConfirmDialog')), [simulation, onClickProp])

  let image = '/images/templates/custom-template.png'
  TILE_IMG_PROPS.some(propPath => {
    const tile = _get(simulation, propPath)
    if (tile) {
      image = tile
      return true
    }
    return false
  })

  const promoted = _get(simulation, 'config.promoted') === true

  return (
    <TileCard {...{ promoted }}>
      <TileCardContent title={simulation.name} onClick={onClick}>
        <TileCardImage src={image} />
        {promoted && <PromotedLabel>{I18n.t('promotedLabel', trOpt)}</PromotedLabel>}
      </TileCardContent>
      {promoted && <PromotedIcon />}
    </TileCard>
  )
}

const SimulationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  width: calc(100% + 30px);

  .ant-empty {
    margin: 50px auto 0;
  }

  ${TileCard} {
    padding: 0 10px 10px;
  }
`

const trOpt = { scope: 'riskReport.uPhish.recommendedSimulations' }

const RecommendedSimulations = ({ onLoad = () => {}, history }) => {
  const { loading, error, data } = useQuery(GET_RECOMMENDED_SIMULATIONS)
  const { recommendedSimulations = [] } = data || {}

  const modalRef = useRef(null)
  const onClick = useCallback((simulationId, skipConfirmDialog) => {
    if (skipConfirmDialog === true) {
      history.push(generatePath(routes.UPHISH_CREATE_SIM_FROM_REC, { simulation_id: simulationId }))
    } else if (modalRef.current) {
      modalRef.current.open(simulationId)
    }
  }, [modalRef, history])

  // Simulations must have associated content
  const simulations = recommendedSimulations.filter(sim =>
    _has(sim, 'landingPage.id') || _has(sim, 'emailTemplate.id') || _has(sim, 'config.content')
  )
  const simulationCount = simulations.length
  useEffect(() =>
    onLoad(
      simulations.length > 0,
      simulations.some(simulation => _get(simulation, 'config.promoted') === true)
    ),
  [onLoad, simulations])

  if (!loading && !error && simulationCount === 0) {
    return null
  }

  return (
    <>
      <LoadingBlock fullScreen loading={loading} />
      {error && <ErrorAlerts {...{ error }} defaultError={I18n.t('defaultError', trOpt)} />}
      {!error && simulationCount > 0 && (
        <>
          <h5>{I18n.t('title', trOpt)}</h5>
          <SimulationContainer>
            {
              simulations.map((simulation, index) => (
                <SimulationCard key={`${simulation.id}-${index}`} {...{ simulation, onClick }} />
              ))
            }
          </SimulationContainer>
          <ViewRecommendSimulationModal ref={modalRef} />
        </>
      )}
    </>
  )
}

export default withRouter(RecommendedSimulations)
