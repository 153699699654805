import I18n from 'i18n-js'

import SettingsForm from '../SettingsForm'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.courseWaitTime' }
const DEFAULT_WAIT_SECS = 5

class CourseWaitTime extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'courseMaterialWaitSeconds'
    this.defaultValue = DEFAULT_WAIT_SECS
    this.useValuesObjectName = false

    this.requiredUpdatePermissions = [permissions.SETTINGS_ULEARN_UPDATE]
  }

  get headerId () {
    return 'settings-course-wait-time-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get formValuesFromSettings () {
    const values = super.formValuesFromSettings
    values.courseMaterialWaitSeconds = values.courseMaterialWaitSeconds === DEFAULT_WAIT_SECS
    values.setting = values.setting === DEFAULT_WAIT_SECS
    return values
  }

  get _fields () {
    const { settings = {} } = this.props
    const {
      courseMaterialWaitSeconds = DEFAULT_WAIT_SECS
    } = settings

    return [
      {
        id: 'setting',
        type: 'switch',
        label: I18n.t('settingLabel', trOpt),
        defaultValue: courseMaterialWaitSeconds === DEFAULT_WAIT_SECS,
        mutateValue: value => value ? DEFAULT_WAIT_SECS : 0
      }
    ]
  }
}

export default CourseWaitTime
