import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Button, Form, Input, Modal, message, Select } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'

import selectors from '../../state/selectors'
import { sendTestEmail, sendTestEmailForSimulation, sendTestEmailForTemplate } from '../../helpers/uPhish'
import { DELIVERY_METHOD_OPTIONS } from '../../constants/uPhish'
import { useGlobalState } from '../../hooks'
import { isMessageInjectionAuthorised } from '../../helpers/messageInjection'

const { Option } = Select

const trOpt = { scope: 'modals.sendTestSimulationEmailConfirm' }
const commonTrOpt = { scope: 'uPhish.common' }

const ConfirmModalWrap = styled.div`
  overflow: hidden;
`

const ConfirmModalTitle = styled.span`
  color: rgba(0,0,0,0.85);
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  overflow: hidden;
`

const ConfirmModalContent = styled.div`
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  margin-top: 8px;

  ul {
    padding-left: 20px;
  }
`

const ConfirmModalFooter = styled.div`
  float: right;

  & > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const SendTestSimulationEmailForm = Form.create({ name: 'send_test_sim' })(
  React.forwardRef(({ form, onStart = () => {}, onKeyUp = () => {}, showDeliveryMethod = false, disabled = false }, ref) => {
    const { getFieldDecorator } = form
    const emailRef = useRef(null)
    useEffect(() => {
      if (emailRef.current) {
        emailRef.current.focus()
      }
      onStart()
    }, [onStart, emailRef])

    const [validateTrigger, setValidateTrigger] = useState('onBlur')
    const onBlur = () => setValidateTrigger('onChange')
    useImperativeHandle(ref, () => ({ form, setValidateTrigger }))

    const commonFieldProps = { onKeyUp, onBlur, disabled }

    return (
      <Form layout='horizontal'>
        <Form.Item
          label={I18n.t('recipient', trOpt)}
        >
          {getFieldDecorator('email', {
            validateTrigger,
            rules: [{
              type: 'email',
              message: I18n.t('invalidEmailError', trOpt)
            },
            {
              required: true,
              message: I18n.t('emptyEmailError', trOpt)
            }]
          })(<Input ref={emailRef} allowClear {...commonFieldProps} />)}
        </Form.Item>
        <Form.Item
          label={I18n.t('common.fields.firstName')}
        >
          {getFieldDecorator('firstName', {
            validateTrigger,
            rules: [{
              required: true,
              message: I18n.t('mutationForm.mutationForm.requiredFieldError')
            }]
          })(<Input allowClear {...commonFieldProps} />)}
        </Form.Item>
        <Form.Item
          label={I18n.t('common.fields.lastName')}
        >
          {getFieldDecorator('lastName', {
            validateTrigger,
            rules: [{
              required: true,
              message: I18n.t('mutationForm.mutationForm.requiredFieldError')
            }]
          })(<Input allowClear {...commonFieldProps} />)}
        </Form.Item>
        {showDeliveryMethod && (
          <Form.Item
            label={I18n.t('preferredDeliveryMethod', commonTrOpt)}
            extra={I18n.t('messageInjectionDisclaimer', commonTrOpt)}
          >
            {getFieldDecorator('deliveryMethod', {
              validateTrigger,
              rules: [{
                required: true,
                message: I18n.t('mutationForm.mutationForm.requiredFieldError')
              }]
            })(
              <Select {...commonFieldProps}>
                {DELIVERY_METHOD_OPTIONS.map(option => (
                  <Option key={option.value} value={option.value} disabled={option.disabled} label={option.label}>{option.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    )
  })
)

const SendTestSimulationEmailConfirm = React.forwardRef(({ type }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [variables, setVariables] = useState(null)
  const formRef = useRef(null)

  const { userEmail, userFirstName, userLastName, messageInjectionSettings, uPhishDeliveryMethod } = useGlobalState(
    state => {
      const { email: userEmail, firstName: userFirstName, lastName: userLastName } = selectors.session.get(state)
      const { messageInjection: messageInjectionSettings, uPhishDeliveryMethod } = selectors.settings.get(state)
      return { userEmail, userFirstName, userLastName, messageInjectionSettings, uPhishDeliveryMethod }
    }
  )
  const showDeliveryMethod = useMemo(() => isMessageInjectionAuthorised({ messageInjection: messageInjectionSettings }), [messageInjectionSettings])

  const setInitialFormValues = useCallback(() => {
    const form = _get(formRef, 'current.form')
    if (!form) {
      return
    }
    const values = { email: userEmail, firstName: userFirstName, lastName: userLastName }
    if (showDeliveryMethod) {
      values.deliveryMethod = variables?.config?.deliveryMethod || variables?.deliveryMethod || uPhishDeliveryMethod || 'smtp'
    }
    form.setFieldsValue(values)
  }, [userEmail, userFirstName, userLastName, showDeliveryMethod, variables, uPhishDeliveryMethod, formRef])

  useImperativeHandle(ref, () => ({
    open: ({ id, variables }) => {
      setVisible(true)
      setId(id)
      setVariables(variables)
    }
  }))

  const onCancel = useCallback(() => setVisible(false), [setVisible])
  const onOk = useCallback(async () => {
    const form = _get(formRef, 'current.form')
    if (!form) {
      return
    }

    setLoading(true)
    const { email, firstName, lastName, deliveryMethod } = await (
      new Promise(resolve => {
        form.validateFields((err, values) => {
          resolve(err ? {} : values)
        })
      })
    )

    if (email) {
      let sendFunct

      let useVars = false
      if (type === 'simulation' && id) {
        sendFunct = sendTestEmailForSimulation
      } else if (type === 'template' && id) {
        sendFunct = sendTestEmailForTemplate
      } else if (variables) {
        sendFunct = sendTestEmail
        useVars = true
      }
      if (!sendFunct) {
        message.error(I18n.t('errorMessage', trOpt))
        return
      }

      let success = false
      if (useVars) {
        success = await sendFunct({
          ...((_isFunction(variables) ? variables() : variables) || {}),
          email,
          firstName,
          lastName,
          deliveryMethod
        })
      } else {
        success = await sendFunct(id, email, firstName, lastName, deliveryMethod)
      }
      if (success) {
        setVisible(false)
      }
    } else {
      const setValidateTrigger = _get(formRef, 'current.setValidateTrigger')
      if (setValidateTrigger) {
        setValidateTrigger('onChange')
      }
    }

    setLoading(false)
  }, [type, id, variables, formRef])
  const onKeyUp = useCallback(event => {
    if (event.keyCode === 13) {
      onOk()
    }
  }, [onOk])

  const afterClose = useCallback(() => {
    setVisible(false)
    setId(null)
    setInitialFormValues()
  }, [setInitialFormValues])

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      maskClosable={false}
      destroyOnClose
      afterClose={afterClose}
    >
      <ConfirmModalWrap>
        <ConfirmModalTitle>{I18n.t('title', trOpt)}</ConfirmModalTitle>
        <ConfirmModalContent>
          <SendTestSimulationEmailForm
            wrappedComponentRef={formRef}
            onStart={setInitialFormValues}
            onKeyUp={onKeyUp}
            {...{ showDeliveryMethod }}
            disabled={loading}
          />
        </ConfirmModalContent>
        <ConfirmModalFooter>
          <Button disabled={loading} onClick={onCancel}>{I18n.t('common.cancel')}</Button>
          <Button type='primary' icon='mail' loading={loading} onClick={onOk}>{I18n.t('send', trOpt)}</Button>
        </ConfirmModalFooter>
      </ConfirmModalWrap>
    </Modal>
  )
})

export default SendTestSimulationEmailConfirm
