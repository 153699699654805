import React, { useCallback } from 'react'
import { Icon, Button } from 'antd'
import I18n from 'i18n-js'

const trOpt = { scope: 'accountSettings.enableQrAuth' }

const EnableQR = ({ setCurrentStep }) => {
  const goToIntro = useCallback(() => setCurrentStep('intro'), [setCurrentStep])
  return (
    <div style={{ textAlign: 'center', padding: '32px 0px' }}>
      <Icon type='lock' style={{ fontSize: '30px', marginBottom: '8px' }} />
      <h3 style={{ fontWeight: 500 }}>{I18n.t('twoFactorAuthIsNotEnabledYet', trOpt)}</h3>
      <p style={{ marginBottom: '24px' }}>{I18n.t('twoFactorAuthAddsAdditionalLayerOfSecurity', trOpt)}</p>
      <Button type='primary' onClick={goToIntro}>{I18n.t('enableTwoFactorAuthentication', trOpt)}</Button>
    </div>
  )
}

export default EnableQR
