import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import { Slider, Dropdown, Menu, Tooltip } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { darken } from 'polished'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'
import _isNumber from 'lodash/isNumber'

import { FontAwesomeIcon, Loader } from '../common'
import { createAction } from '../../helpers/state'
import { getVideoTimestampFromSeconds, getVideoTimestampFromSecondsMatchingDuration } from '../../helpers/video'
import { BOOTSTRAP_BREAKPOINTS, BREAKPOINT } from '../../constants/style'

const trOpt = { scope: 'learnerCourse.learnerCourseVideoPlayer' }

const BaseOverlay = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`

const PlayerOverlay = styled(BaseOverlay)`
  cursor: ${({ hideCursor }) => hideCursor ? 'none' : 'default'};
`

const PlayerContainer = styled.div`
  bottom: 15px;
  color: ${({ theme }) => theme.videoPlayerIconColor};
  display: ${({ started, controlStatus }) => (!started || controlStatus === 'hide') ? 'none' : 'flex'};
  height: 40px;
  margin: 0;
  opacity: ${({ started, controlStatus }) => (!started || controlStatus === 'hide' || controlStatus === 'hiding') ? 0 : 1};
  padding: 0 15px;
  position: absolute;
  transition: opacity 0.4s ease-in-out;
  user-select: none;
  width: 100%;
  z-index: 500;

  @media (max-width: ${BOOTSTRAP_BREAKPOINTS.sm}px) {
    bottom: 5px;
    height: 30px;
    padding: 0 5px;
  }
`

const PlayerBG = styled(BaseOverlay)`
  z-index: 100;
`

const AudioUnavailableInfo = styled.div`
  background-color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  border-radius: 4px;
  color: ${({ theme }) => theme.videoPlayerIconColor};
  padding: 8px 8px 4px;
  position: absolute;
  right: 10px;
  top: 10px;

  i {
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
`

const PlayerControlsContainer = styled.div`
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.videoPlayerBackgroundColor};
  border-radius: ${({ position }) => {
    switch (position) {
      case 'left':
        return '4px 0 0 4px'
      case 'right':
        return '0 4px 4px 0'
      default:
        return 'none'
    }
  }};
  display: flex;

  .ant-slider-track {
    background-color: ${({ theme }) => theme.videoPlayerControlHighlightColor};
  }
  .ant-slider:hover .ant-slider-track {
    background-color: ${({ theme }) => darken(0.1, theme.videoPlayerControlHighlightColor)};
  }
  .ant-slider-handle {
    border-color: ${({ theme }) => theme.videoPlayerControlHighlightColor};

    &:focus {
      border-color: ${({ theme }) => darken(0.1, theme.videoPlayerControlHighlightColor)};
    }
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${({ theme }) => darken(0.1, theme.videoPlayerControlHighlightColor)};
  }

  @media (max-width: ${BREAKPOINT}) {
    padding-left: ${({ position }) => position === 'left' ? '5px' : '0'};
    padding-right: ${({ position }) => position === 'right' ? '5px' : '0'};
  }
`

// show can't be passed to a div, a destructured declaration is the quickest to exclude it from controlProps passed to the div
const _PlayerControl = ({ tooltipTitle, tooltipPlacement = 'top', tooltipDisabled = false, getPopupContainer, show, ...controlProps }) => {
  const control = <div {...controlProps} />
  if (tooltipTitle && !tooltipDisabled) {
    return (
      <Tooltip
        title={tooltipTitle} placement={tooltipPlacement}
        {...{ getPopupContainer }}
      >
        {control}
      </Tooltip>
    )
  }
  return control
}

const PlayerControl = styled(_PlayerControl)`
  cursor: pointer;
  padding: 10px 5px;
  width: 40px;
  
  & > i {
    position: relative;
    top: 3px;
  }

  &:hover {
    color: ${({ theme }) => theme.videoPlayerControlHighlightColor};
  }

  @media (max-width: ${BREAKPOINT}) {
    padding: 10px 2px;
    width: 25px;
  }
  @media (max-width: ${BOOTSTRAP_BREAKPOINTS.sm}px) {
    & > i {
      top: -2px;
    }
  }
`

const PlayButton = styled(PlayerControl)`
  background-color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  border-radius: 4px;
  color: ${({ theme }) => theme.videoPlayerIconColor};
  margin-right: 10px;
  width: 80px;

  &:hover {
    background-color: ${({ theme }) => theme.videoPlayerControlHighlightColor};
    color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  }

  @media (max-width: ${BREAKPOINT}) {
    margin-right: 5px;
    padding: 10px 5px;
    width: 50px;
  }
`

const VideoStartButton = styled(PlayButton)`
  display: ${({ show }) => show ? 'block' : 'none'};
  height: 40px;
  left: calc(50% - 40px);
  margin: 0;
  padding: 10px;
  position: absolute;
  top: calc(50% - 20px);
  z-index: 500;

  @media (max-width: ${BREAKPOINT}) {
    padding: 10px 5px;
    width: 80px;
  }
  @media (max-width: ${BOOTSTRAP_BREAKPOINTS.sm}px) {
    & > i {
      top: 3px;
    }
  }
`

const StartButtonLoader = styled(Loader)`
  color: ${({ theme }) => theme.videoPlayerIconColor};
  margin: 0 auto;
  top: -10px;

  &:hover {
    color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  }
`

const PlayerStartButton = ({ className, show, loading = false, onClick, getPopupContainer, tooltipDisabled = false }) => {
  return (
    <VideoStartButton
      {...{ className, show, onClick, getPopupContainer, tooltipDisabled }}
      tooltipTitle={I18n.t('play', trOpt)}
    >
      {loading ? <StartButtonLoader /> : <FontAwesomeIcon icon='play' />}
    </VideoStartButton>
  )
}

const DebugDeviceIndicator = styled.div`
  color: ${({ theme }) => theme.videoPlayerIconColor};
  position: absolute;
  right: 20px;
  top: 20px;
`

/* Fix the width as we aren't using a mono spaced font */
const PlayerTime = styled.span`
  display: inline-block;
  width: ${({ hasHours }) => hasHours ? 60 : 40}px;
`
// Hide duration on mobile
const PlayerTimeDuration = styled(PlayerTime)`
  @media (max-width: ${BOOTSTRAP_BREAKPOINTS.sm}px) {
    display: none;
  }
`
const PlayerTimerDivider = styled.span`
  display: inline-block;
  margin: 0 2px;

  @media (max-width: ${BOOTSTRAP_BREAKPOINTS.sm}px) {
    display: none;
  }
`

const PlayerTimer = styled.div`
  padding: 10px;

  & > span {
    position: relative;
    top: 2px;
  }

  @media (max-width: ${BREAKPOINT}) {
    padding: 10px 0px;
  }
  @media (max-width: ${BOOTSTRAP_BREAKPOINTS.sm}px) {
    & > span {
      top: -2px;
    }
  }
`

const PlayBar = styled(PlayerControlsContainer)`
  background-color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  display: flex;
  flex: 1;
  padding: 0 5px;

  .ant-slider {
    flex: 1;
  }
  @media (max-width: ${BOOTSTRAP_BREAKPOINTS.sm}px) {
    .ant-slider {
      top: -5px;
    }
  }
`

// The inner control the height of the ant d slider while allowing the control to have it's own styling
const VolumeSliderContainer = styled.div`
  background-color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  border-radius: 4px;
  height: 175px;
  left: 2px;
  position: absolute;
  top: 40px;
  z-index: 1000;

  & > div {
    height: 150px;
  }
`

const VolumeSliderHoverContainer = styled.div`
  bottom: 40px;
  height: 220px;
  position: absolute;
  width: 100%;
  z-index: 1000;

  &:hover, &:active {
    display: block;
  }
`

const VolumeControlContainer = styled.div`
  position: relative;

  ${VolumeSliderHoverContainer} {
    display: none;
  }
  
  &:hover, &:active {
    ${VolumeSliderHoverContainer} {
      display: block;
    }
  }
`

const TextTrackIcon = ({ textTrackId }) => (
  <i className={`${textTrackId ? 'fas' : 'far'} fa-closed-captioning`} />
)

const TextTrackMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  color: ${({ theme }) => theme.videoPlayerIconColor};
`
const TextTrackMenuItem = styled(Menu.Item)`
  background-color: ${({ theme }) => theme.videoPlayerBackgroundColor};
  color: ${({ theme }) => theme.videoPlayerIconColor};

  &:hover, &.ant-dropdown-menu-item-selected {
    background-color: ${({ theme }) => theme.videoPlayerControlHighlightColor} !important;
  }
`

const types = {
  UPDATE: 'UPDATE',
  RESET: 'RESET'
}

const creators = {
  update: createAction(types.UPDATE),
  playerCurrentTime: createAction(types.UPDATE, playerCurrentTime => ({ playerCurrentTime })),
  durationTimestamp: createAction(types.UPDATE, durationTimestamp => ({ durationTimestamp })),
  controlStatus: createAction(types.UPDATE, controlStatus => ({ controlStatus })),
  usingTouch: createAction(types.UPDATE, usingTouch => ({ usingTouch: usingTouch === true })),
  lastOverlayInteraction: createAction(types.UPDATE, lastOverlayInteraction => ({ lastOverlayInteraction, controlStatus: 'show' })),
  showTextTracksDropdown: createAction(types.UPDATE, showTextTracksDropdown => {
    const payload = { showTextTracksDropdown: showTextTracksDropdown === true }
    if (!payload.showTextTracksDropdown) {
      payload.ignoreBgClicks = true
    }
    return payload
  }),
  onControls: createAction(
    types.UPDATE,
    onControls => {
      const payload = { onControls: onControls === true }
      if (payload.onControls) {
        payload.controlStatus = 'show'
      }
      return payload
    }
  ),
  ended: createAction(types.UPDATE, ended => ({ ended: ended === true })),
  reset: createAction(types.RESET)
}

const initialState = {
  playerCurrentTime: 0,
  lastOverlayInteraction: null,
  onControls: false,
  controlStatus: 'hide',
  showTextTracksDropdown: false,
  showVolumeSlider: false,
  durationTimestamp: null,
  ignoreBgClicks: false,
  usingTouch: false,
  ended: false
}

const actionsMap = {
  [types.UPDATE]: (prevState, payload) => {
    return {
      ...prevState,
      ...(payload || {})
    }
  },
  [types.RESET]: (prevState) => ({
    ...initialState,
    // Preserve usingTouch on reset
    usingTouch: prevState.usingTouch === true
  })
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  const fn = actionsMap[type]
  return fn ? fn(state, payload) : state
}

const LearnerCourseVideoPlayer = ({
  videoId, className,
  starting = false, started = false, onStartClick: onStartClickProp = () => {},
  playing = false, onPlayPauseClick: onPlayPauseClickProp = () => {},
  currentTime, setCurrentTime = () => {}, duration, maxTime, getMaxTime,
  volume, setVolume = () => {}, muted, onMuteUnmuteClick: onMuteUnmuteClickProp = () => {}, audioUnavailable = false,
  textTracks, textTrackId, setTextTrack = () => {},
  fullScreen, onFullScreenClick: onFullScreenClickProp = () => {},
  debugMode = false, debugForceShowControls = false, debugDeviceIndicator = false
}) => {
  const overlayRef = useRef(null)
  const controlsRef = useRef(null)
  const volumeControlRef = useRef(null)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { playerCurrentTime, durationTimestamp, lastOverlayInteraction, onControls, showTextTracksDropdown, ignoreBgClicks, usingTouch, ended } = state
  const controlStatus = debugMode || debugForceShowControls || ended ? 'show' : state.controlStatus

  // Attempt to create a TouchEvent on mount
  // If successful we assume the user is using a touch and set usingTouch accordingly
  // This will be overridden by any pointerenter events detected which should handle devices with mouse and touch
  useEffect(() => {
    try {
      document.createEvent('TouchEvent')
      dispatch(creators.usingTouch(true))
    } catch (e) {
      // Silent failure as this hook is for touch detection purposes only
    }
  }, [])

  // Delayed controls hiding relative to last interaction unless mouse is on the controls
  useEffect(() => {
    if (usingTouch) return

    const timeoutId = setTimeout(() => {
      if (!onControls) {
        dispatch(creators.controlStatus('hiding'))
      }
    }, 1000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [lastOverlayInteraction, onControls, usingTouch])

  // Delayed controlStatus change from hiding to hide to allow opacity transition to play before applying display:none
  useEffect(() => {
    let timeoutId
    if (controlStatus === 'hiding') {
      timeoutId = setTimeout(() => {
        dispatch(creators.update({
          controlStatus: 'hide',
          showTextTracksDropdown: false
        }))
      }, 500)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [controlStatus])

  useEffect(() => {
    const updateLastOverlayInteraction = e => {
      if (e.pointerType !== 'touch') {
        dispatch(creators.lastOverlayInteraction(Date.now()))
      }
    }
    const setPointerType = e => {
      dispatch(creators.usingTouch(e.pointerType === 'touch'))
    }
    const { current: overlayEl } = overlayRef
    if (overlayEl) {
      overlayEl.addEventListener('pointerenter', setPointerType)
      overlayEl.addEventListener('pointermove', updateLastOverlayInteraction)
      overlayEl.addEventListener('pointerdown', updateLastOverlayInteraction)
    }
    return () => {
      if (overlayEl) {
        overlayEl.removeEventListener('pointerenter', setPointerType)
        overlayEl.removeEventListener('pointermove', updateLastOverlayInteraction)
        overlayEl.removeEventListener('pointerdown', updateLastOverlayInteraction)
      }
    }
  }, [overlayRef])

  useEffect(() => {
    const enterControls = () => {
      dispatch(creators.onControls(true))
    }
    const leaveControls = () => {
      dispatch(creators.onControls(false))
    }
    const { current: controlsEl } = controlsRef
    if (controlsEl) {
      controlsEl.addEventListener('mouseenter', enterControls)
      controlsEl.addEventListener('mouseleave', leaveControls)
    }
    return () => {
      if (controlsEl) {
        controlsEl.removeEventListener('mouseenter', enterControls)
        controlsEl.removeEventListener('mouseleave', leaveControls)
      }
    }
  }, [controlsRef])

  // Revert ignoreBgClicks after 0.5 seconds of becoming true
  // This avoids accidental trigger of bg play/pause click when closing the subtitle menu
  useEffect(() => {
    let timeoutId
    if (ignoreBgClicks) {
      timeoutId = setTimeout(() => {
        dispatch(creators.update({
          ignoreBgClicks: false
        }))
      }, 500)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [ignoreBgClicks])

  // Reset state when video changes
  useEffect(() => {
    dispatch(creators.reset())
  }, [videoId])

  // Sync player's playbar with video via currentTime prop
  useEffect(() => {
    dispatch(creators.playerCurrentTime(currentTime))
  }, [currentTime])

  // Set duration timestamp in state based on prop
  useEffect(() => {
    dispatch(creators.durationTimestamp(getVideoTimestampFromSeconds(duration)))
  }, [duration])

  // video ended effect hook
  useEffect(() => {
    dispatch(creators.ended(Math.floor(currentTime) === Math.floor(duration)))
  }, [currentTime, duration])

  const onPlayPauseClick = useCallback(async () => {
    await onPlayPauseClickProp(playing)
    if (ended && !playing) {
      await setCurrentTime(0)
    }
    // Touch - Hide controls after click play button
    if (usingTouch && !playing) {
      dispatch(creators.controlStatus('hiding'))
    }
  }, [onPlayPauseClickProp, playing, usingTouch, ended, setCurrentTime])

  const onPlayBarChange = useCallback(async seconds => {
    let maxPlayerTime
    if (_isFunction(getMaxTime)) {
      maxPlayerTime = getMaxTime()
    } else if (_isNumber(maxTime)) {
      maxPlayerTime = maxTime
    }
    const playerCurrentTime = _isNumber(maxPlayerTime) && seconds > maxPlayerTime ? maxPlayerTime : seconds
    dispatch(creators.playerCurrentTime(playerCurrentTime))
    await setCurrentTime(playerCurrentTime)
  }, [setCurrentTime, maxTime, getMaxTime])

  const onVolumeChange = useCallback(async volume => {
    if (audioUnavailable) return
    setVolume(volume)
  }, [setVolume, audioUnavailable])
  const onMuteUnmuteClick = useCallback(async () => {
    if (audioUnavailable) return
    if (usingTouch) {
      // Touch - Mute or 100% volume as there's no slider
      // Android & iOS don't support volume control on web video players instead relying on device volume. We're following that approach for all touch devices
      await setVolume(muted ? 1 : 0) // volume range is between 0 and 1 so 100% is 1
    } else {
      // Mouse - full volume control
      await onMuteUnmuteClickProp(!muted)
    }
  }, [muted, onMuteUnmuteClickProp, usingTouch, setVolume, audioUnavailable])

  const onTextTrackToggleClick = useCallback(async () => {
    if (textTrackId) {
      // Turn off subtitles
      await setTextTrack(null)
    } else {
      // Turn on sole subtitle track
      const singleTextTrackId = _get(textTracks, '0.id')
      if (singleTextTrackId) {
        await setTextTrack(singleTextTrackId)
      }
    }
  }, [textTracks, textTrackId, setTextTrack])

  const onTextTrackClick = useCallback(async e => {
    await setTextTrack(e.key === 'disabled' ? null : e.key)
  }, [setTextTrack])

  const onFullScreenClick = useCallback(async () => {
    await onFullScreenClickProp()
  }, [onFullScreenClickProp])

  const getOverlayPopupContainer = useCallback(() => {
    return overlayRef?.current ?? document.body
  }, [overlayRef])

  const getControlsPopupContainer = useCallback(() => {
    return controlsRef?.current ?? document.body
  }, [controlsRef])

  const getVolumeSliderTooltipPopupContainer = useCallback(() => {
    return volumeControlRef?.current ?? document.body
  }, [volumeControlRef])

  const onTextTrackDropdownVisibleChange = useCallback(visible => {
    dispatch(creators.showTextTracksDropdown(visible))
  }, [])

  const formatPlayBarTooltip = useCallback(value => {
    return getVideoTimestampFromSecondsMatchingDuration(value, duration)
  }, [duration])

  const onStartClick = useCallback(async () => {
    if (starting) return

    await onStartClickProp()
    // Show controls when playback starts
    // Mouse - controls hide automatically after a second
    // Touch - user has to tap to hide the controls
    if (usingTouch) {
      dispatch(creators.controlStatus('show'))
    }
  }, [onStartClickProp, usingTouch, starting])

  const onPlayerBgClick = useCallback(async e => {
    if (ignoreBgClicks) return

    if (!started) {
      await onStartClick()
    } else if (usingTouch) {
      // Tap to show/hide video controls
      dispatch(creators.controlStatus(controlStatus === 'show' ? 'hiding' : 'show'))
    } else {
      // Clicking the background plays or pauses the video when using a mouse
      await onPlayPauseClick()
    }
  }, [started, onPlayPauseClick, onStartClick, ignoreBgClicks, usingTouch, controlStatus])

  const timerHasHours = duration >= 3600

  return (
    <PlayerOverlay
      ref={overlayRef}
      {...{ className }}
      hideCursor={started && fullScreen && controlStatus !== 'show'}
    >
      {(debugMode || debugDeviceIndicator) && (
        // DEBUG - Indicator to show whether a mouse or touchscreen is being used
        <DebugDeviceIndicator>
          <FontAwesomeIcon icon={usingTouch ? 'tablet-alt' : 'mouse'} />
        </DebugDeviceIndicator>
      )}
      <PlayerStartButton show={!started} loading={starting} onClick={onStartClick} getPopupContainer={getOverlayPopupContainer} />
      <PlayerBG onClick={onPlayerBgClick} />
      {started && audioUnavailable && (
        <AudioUnavailableInfo>
          <FontAwesomeIcon icon='volume-mute' />
          <span>{I18n.t('noSoundWarning', trOpt)}</span>
        </AudioUnavailableInfo>
      )}
      <PlayerContainer {...{ started, controlStatus }} ref={controlsRef}>
        <PlayerControlsContainer backgroundColor='transparent'>
          <PlayButton
            onClick={onPlayPauseClick}
            tooltipTitle={I18n.t(playing ? 'pause' : 'play', trOpt)}
            tooltipPlacement='topLeft'
            tooltipDisabled={usingTouch}
            getPopupContainer={getControlsPopupContainer}
          >
            <FontAwesomeIcon icon={playing ? 'pause' : (ended ? 'undo' : 'play')} />
          </PlayButton>
        </PlayerControlsContainer>
        <PlayBar position='left'>
          <PlayerTimer>
            <span>
              <PlayerTime hasHours={timerHasHours}>{getVideoTimestampFromSecondsMatchingDuration(currentTime, duration)}</PlayerTime>
              <PlayerTimerDivider>/</PlayerTimerDivider>
              <PlayerTimeDuration hasHours={timerHasHours}>{durationTimestamp}</PlayerTimeDuration>
            </span>
          </PlayerTimer>
          <Slider
            value={playerCurrentTime} max={duration}
            onChange={onPlayBarChange}
            tipFormatter={formatPlayBarTooltip}
          />
        </PlayBar>
        <PlayerControlsContainer position='right'>
          <VolumeControlContainer ref={volumeControlRef}>
            {!usingTouch && !showTextTracksDropdown && !audioUnavailable && (
              <VolumeSliderHoverContainer>
                <VolumeSliderContainer>
                  <div>
                    <Slider
                      vertical
                      value={muted ? 0 : volume} max={1}
                      step={0.01}
                      onChange={onVolumeChange}
                      tipFormatter={value => `${Math.round(value * 100)}%`}
                      getTooltipPopupContainer={getVolumeSliderTooltipPopupContainer}
                    />
                  </div>
                </VolumeSliderContainer>
              </VolumeSliderHoverContainer>
            )}
            <PlayerControl onClick={onMuteUnmuteClick}>
              <FontAwesomeIcon icon={muted ? 'volume-mute' : (volume > 0.5 ? 'volume-up' : 'volume-down')} />
            </PlayerControl>
          </VolumeControlContainer>
          {
            _isArray(textTracks) && textTracks.length === 1 && (
              <PlayerControl
                onClick={onTextTrackToggleClick}
                tooltipTitle={I18n.t(textTrackId ? 'subtitlesOn' : 'subtitlesOff', trOpt)}
                tooltipPlacement='topRight'
                tooltipDisabled={usingTouch}
                getPopupContainer={getControlsPopupContainer}
              >
                <TextTrackIcon {...{ textTrackId }} />
              </PlayerControl>
            )
          }
          {
            _isArray(textTracks) && textTracks.length > 1 && (
              <Dropdown
                placement='topRight'
                trigger={['click']}
                getPopupContainer={getControlsPopupContainer}
                visible={showTextTracksDropdown}
                onVisibleChange={onTextTrackDropdownVisibleChange}
                overlay={
                  <TextTrackMenu
                    onClick={onTextTrackClick}
                    selectedKeys={[textTrackId || 'disabled']}
                  >
                    <TextTrackMenuItem key='disabled'>{I18n.t('common.off')}</TextTrackMenuItem>
                    {
                      textTracks.map(textTrack => (
                        <TextTrackMenuItem key={textTrack.id}>{textTrack.label}</TextTrackMenuItem>
                      ))
                    }
                  </TextTrackMenu>
                }
              >
                <PlayerControl
                  tooltipTitle={I18n.t('subtitles', trOpt)}
                  tooltipPlacement='topRight'
                  tooltipDisabled={usingTouch || showTextTracksDropdown}
                  getPopupContainer={getControlsPopupContainer}
                >
                  <TextTrackIcon {...{ textTrackId }} />
                </PlayerControl>
              </Dropdown>
            )
          }
          <PlayerControl
            onClick={onFullScreenClick}
            tooltipTitle={I18n.t('fullscreen', trOpt)} tooltipPlacement='topRight'
            tooltipDisabled={usingTouch}
            getPopupContainer={getControlsPopupContainer}
          >
            <FontAwesomeIcon icon={fullScreen ? 'compress-arrows-alt' : 'expand-arrows-alt'} />
          </PlayerControl>
        </PlayerControlsContainer>
      </PlayerContainer>
    </PlayerOverlay>
  )
}

export default LearnerCourseVideoPlayer
