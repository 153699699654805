import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import IntercomHeader from '../../../components/IntercomHeader'
import { ExcludeInactiveToggle } from '../../../components/common'

const UBreachHeaderStyled = styled.div`
height: 100%;
background-color: white;
padding: 40px;
display: flex;
flex-direction: row;
justify-content: space-between;
`
const UBreachHeader = () => {
  return (
    <UBreachHeaderStyled style={{ backgroundColor: 'white', gridArea: 'topbar' }}>
      <IntercomHeader id='uBreach-report-header' style={{ margin: 0 }}>{I18n.t('common.uBreach')}</IntercomHeader>
      <div>
        <ExcludeInactiveToggle />
      </div>
    </UBreachHeaderStyled>
  )
}

export default UBreachHeader
