import React from 'react'
import UPolicy from './uPolicy'
import I18n from 'i18n-js'

const trOpt = { scope: 'uPolicy.common' }

export const Policies = props =>
  <UPolicy
    {...props}
    storageId='uPolicy'
    id='policies-header' type='policy' builder={false}
  />
export const PolicyTemplates = props =>
  <UPolicy
    {...props}
    storageId='uPolicyTemplates'
    id='uPolicy-template-library-header'
    title={I18n.t('templateLibrary', trOpt)}
    type='template' builder={false}
    useSupportedLocales
  />
export const PolicyTemplateBuilder = props =>
  <UPolicy
    {...props}
    storageId='uPolicyTemplateBuilder'
    id='uPolicy-template-builder-header'
    title={I18n.t('templateBuilder', trOpt)}
    type='template' builder
  />
