import React, { useImperativeHandle, useState, useCallback } from 'react'
import { message, Modal, Button } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import ExportServiceClient from '../../helpers/ExportServiceClient'
import StatusIndicator from '../Reports/DownloadReportStatusIndicator'

// CL - DownloadReportModal and ExportServiceDownloadModal are very similar and could be combined.

const DownloadModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const FailedOkBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

// CL - The keys and text under this are identical to modals.downloadReportModal
const trOpt = { scope: 'modals.exportServiceDownloadModal' }

const ExportServiceDownloadModal = React.forwardRef(({
  baseURL
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState(null)

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    setStatus(null)
  }, [])

  const onStatusChange = useCallback(({ status }) => {
    setStatus(status)
  }, [setStatus])

  const onFinish = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  useImperativeHandle(ref, () => ({
    open: async ({ fileName, fileType, jobData }) => {
      const exportServiceClient = new ExportServiceClient({
        onStart: onStatusChange,
        onStatusChange,
        onFinish,
        fileType,
        fileName,
        baseURL
      })
      setStatus('pending')
      setVisible(true)
      await exportServiceClient.execute(jobData)
    }
  }), [baseURL, onStatusChange, onFinish])

  return (
    <DownloadModal
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      afterClose={afterClose}
      width='70%'
      closable={false}
      maskClosable={false}
    >
      <StatusIndicator {...{ status, trOpt }} />
      {status === 'failed' && (
        <FailedOkBtnContainer>
          <Button type='primary' onClick={closeModal}>{I18n.t('common.ok')}</Button>
        </FailedOkBtnContainer>
      )}
    </DownloadModal>
  )
})

export default ExportServiceDownloadModal
