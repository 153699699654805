import React from 'react'
import PropTypes from 'prop-types'
import ChecklistItem from './LearnerCourseChecklistItem'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'

import { ContentBox, CardTitle } from './common'
import { ProgressButton } from '../common'
import LearnerCourseSlate from './LearnerCourseSlate'
import { LearnerCourseBase } from './LearnerCourseBase'

export class LearnerCourseChecklist extends LearnerCourseBase {
  constructor (props) {
    super(props)

    this.completedTimer = null
    this.canProgress = this.canProgress.bind(this)
    this.handleListItemChange = this.handleListItemChange.bind(this)

    this.state = {
      list: this.getList(props)
    }
  }

  getList (props = this.props) {
    return _get(props, 'slide.content.checklistItems', []).map((item, index) => ({ ...item, checked: false }))
  }

  canProgress () {
    return this.state.list.every(item => item.checked)
  }

  handleListItemChange (index) {
    const { list } = this.state
    if (list[index]) {
      list[index].checked = !list[index].checked
      this.setState({ list })
    }
  }

  componentDidMount () {
    this.startCompletionTimer()
  }

  componentDidUpdate (prevProps) {
    const { slide: { id } = {} } = this.props
    const { slide: { id: prevId } = {} } = prevProps
    const list = _get(this.props, 'slide.content.checklistItems')
    const prevList = _get(prevProps, 'slide.content.checklistItems')

    if (id !== prevId) {
      this.startCompletionTimer()
    }

    // handle transition between slides
    if ((id && prevId && id !== prevId) || (list && prevList && !_isEqual(list, prevList))) {
      this.setState({
        list: this.getList()
      })
    }
  }

  componentWillUnmount () {
    clearTimeout(this.completedTimer)
  }

  render () {
    const { slide, canGoPrevSlide, goToNextSlide, goToPrevSlide, goToSlide } = this.props
    const { id, title, content } = slide
    const { list } = this.state

    return (
      <ContentBox
        material innerKey={id}
        buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
        buttonsRight={<ProgressButton disabled={!this.canProgress()} onClick={goToNextSlide} />}
      >
        <CardTitle>{title}</CardTitle>
        <LearnerCourseSlate content={content.intro} />
        {list.map((item, index) => {
          return <ChecklistItem text={item.title} checked={item.checked} slideId={item.slideId} onChange={this.handleListItemChange} onLinkClick={goToSlide} key={index} index={index} />
        })}
      </ContentBox>
    )
  }
}

LearnerCourseChecklist.propTypes = {
  slide: PropTypes.object,
  goToNextSlide: PropTypes.func,
  update: PropTypes.func,
  waitMS: PropTypes.number
}

LearnerCourseChecklist.defaultProps = {
  slide: {},
  goToNextSlide: () => {},
  update: () => {},
  waitMS: -1
}

export default LearnerCourseChecklist
