import I18n from 'i18n-js'

import SettingsForm from '../SettingsForm'
import { PolicySignatureSettingsField, PolicySignatureSettingsValidator } from '../../Policies/PolicySignatureSettingsField'
import { generateSignatureSettingsObject } from '../../../helpers/Policies'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.policySignatureSettings' }

class PolicySignatureSettings extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = ['policySignatureSettings']
    this.defaultValue = {
      type: 'none'
    }
    this.useValuesObjectName = false
    this.requiredUpdatePermissions = [permissions.SETTINGS_UPOLICY_UPDATE]
  }

  get headerId () {
    return 'settings-policy-signature-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const { settings = {} } = this.props
    const { type: defaultType } = this.defaultValue
    const {
      policySignatureSettings: {
        type = defaultType,
        intervalLength,
        intervalUnit,
        startDate
      } = {}
    } = settings
    const { disabled } = this

    return [
      {
        id: 'policySignatureSettings',
        type: 'custom',
        component: PolicySignatureSettingsField,
        defaultValue: generateSignatureSettingsObject({
          type,
          intervalLength,
          intervalUnit,
          startDate
        }),
        validate: PolicySignatureSettingsValidator,
        updatingCompanySettings: true,
        disabled
      }
    ]
  }
}

export default PolicySignatureSettings
