import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Button } from 'antd'
import ReactDOMServer from 'react-dom/server'
import I18n from 'i18n-js'
import _isNumber from 'lodash/isNumber'
import _get from 'lodash/get'
import _findLast from 'lodash/findLast'

import { renderContent } from '../../components/LearnerCourse/common'
import { connect } from '../../hocs'
import { creators as viewActions } from '../../state/actions/view'
import { formatDateTime } from '../../helpers/datetime'

const trOpt = { scope: 'uPolicy.printPolicyDocumentButton' }

export const getPolicyDocumentAndVersion = (policy, documentId) => {
  let document = null
  let version = null

  if (policy) {
    const { documents = [] } = policy || {}
    let majorVersion
    let minorVersion

    if (documentId === 'live') {
      document = policy.document || _get(policy, 'livePolicyDocument.document')
      majorVersion = policy.majorVersion
      if (!_isNumber(majorVersion)) {
        majorVersion = _get(policy, 'livePolicyDocument.majorVersion')
      }
      minorVersion = policy.minorVersion
      if (!_isNumber(minorVersion)) {
        minorVersion = _get(policy, 'livePolicyDocument.minorVersion')
      }
      if (!document || !_isNumber(majorVersion) || !_isNumber(minorVersion)) {
        const live = policy.livePolicyDocument && policy.livePolicyDocument.document
          ? policy.livePolicyDocument
          : documents.find(doc => doc.status === 'live')
        if (live) {
          document = document || live.document
          majorVersion = _isNumber(majorVersion) ? majorVersion : live.majorVersion
          minorVersion = _isNumber(minorVersion) ? minorVersion : live.minorVersion
        }
      }
    } else if (documentId === 'draft') {
      document = policy.draftDocument || _get(policy, 'draftPolicyDocument.document') || (documents.find(doc => doc.status === 'draft') || {}).document
      version = 'draft'
    } else if (documentId === 'major') {
      document = _get(policy, 'lastMajorPolicyDocument.document')
      const lastMajor = _get(policy, 'lastMajorPolicyDocument') || _findLast(documents, doc => doc.major)
      if (lastMajor) {
        document = document || lastMajor.document
        majorVersion = lastMajor.majorVersion
        minorVersion = lastMajor.minorVersion
      }
    } else {
      const doc = documents.find(doc => doc.id === documentId)
      if (doc) {
        document = doc.document
        majorVersion = doc.majorVersion
        minorVersion = doc.minorVersion
      }
    }

    if (_isNumber(majorVersion) && _isNumber(minorVersion)) {
      version = `v${majorVersion}-${minorVersion}`
    }
  }

  return { document, version }
}

const getPageTitle = (policy, version) => {
  const { name = '' } = policy || {}
  if (version === 'draft') {
    return I18n.t('pageTitleDraft', { ...trOpt, name })
  } else if (version) {
    return I18n.t('pageTitleVersion', { ...trOpt, name, version: version.replace('-', '.') })
  }
  return name
}

const PrintPolicyDocumentButton = ({ policy, documentId, setPageTitle = () => {}, resetPageTitle = () => {} }) => {
  const [version, setVersion] = useState(null)
  const [iframeSrcDoc, setIframeSrcDoc] = useState(null)
  const printIframe = useRef(null)

  const delayedSetPageTitle = useCallback(async (delay = 500) => {
    return new Promise(resolve => {
      setPageTitle(getPageTitle(policy, version))
      setTimeout(resolve, delay)
    })
  }, [policy, version, setPageTitle])

  const onClick = useCallback(async () => {
    await delayedSetPageTitle()
    const { current: iframeElement } = printIframe
    if (iframeElement) {
      // This function is blocking as JS execution pauses while the print dialog is open as far I can tell
      iframeElement.contentWindow.print()
    }
    // Reset page title after print dialog has closed
    resetPageTitle()
  }, [printIframe, delayedSetPageTitle, resetPageTitle])

  useEffect(() => {
    const { version, document } = getPolicyDocumentAndVersion(policy, documentId)
    setVersion(version)
    const documentHTML =
    `<html>
      <head>
        <title>${getPageTitle(policy, version)}</title>
      </head>
      <body>
        <h1 style="font-size: 10px;">${getPageTitle(policy, version)} - ${I18n.t('printedAt', { ...trOpt, datetime: formatDateTime() })}</h1>
        ${
          document
            ? ReactDOMServer.renderToString(<div dangerouslySetInnerHTML={{ __html: renderContent(JSON.stringify(document), { print: true }) }} />)
            : ''
        }
      </body>
    </html>`
    setIframeSrcDoc(documentHTML)
  }, [policy, version, documentId])

  return (
    <>
      <Button type='primary' icon='printer' onClick={onClick}>{I18n.t('printDocument', trOpt)}</Button>
      <iframe style={{ display: 'none' }} ref={printIframe} title={`${policy.id}-${documentId}`} srcDoc={iframeSrcDoc} />
    </>
  )
}

export default connect(null, dispatch => ({
  setPageTitle: title => dispatch(viewActions.title(title)),
  resetPageTitle: () => dispatch(viewActions.resetTitle())
}))(PrintPolicyDocumentButton)
