import React, { Component } from 'react'
import { Card } from 'antd'
import { withRouter } from 'react-router-dom'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import I18n from 'i18n-js'

import LandingPageForm from '../../components/uPhish/LandingPageForm'
import routes from '../../constants/routes'
import IntercomHeader from '../../components/IntercomHeader'

const trOpt = { scope: 'uPhish.common' }

class EditLandingPage extends Component {
  constructor (props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleLoadError = this.handleLoadError.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  get id () {
    return _get(this.props, 'match.params.landing_page_id')
  }

  get isCreate () {
    return _isNil(this.id)
  }

  get isClone () {
    return _get(this.props, 'match.path') === routes.UPHISH_LANDING_PAGE_CLONE
  }

  get isUpdate () {
    return this.isClone !== true && !_isNil(this.id)
  }

  redirect () {
    this.props.history.push(routes.UPHISH_LANDING_PAGE_BUILDER)
  }

  handleCancel () {
    // Redirect to grid on cancel
    this.redirect()
  }

  handleSuccess () {
    // Redirect to grid on save
    this.redirect()
  }

  handleLoadError () {
    // Redirect to grid if a error occurred loading the template
    this.redirect()
  }

  render () {
    return (
      <Card>
        {this.isCreate && <IntercomHeader Size='h1' id='uPhish-landing-page-create-header'>{I18n.t('common.uPhish')} - {I18n.t('createLandingPage', trOpt)}</IntercomHeader>}
        {this.isClone && <IntercomHeader Size='h1' id='uPhish-landing-page-clone-header'>{I18n.t('common.uPhish')} - {I18n.t('cloneLandingPage', trOpt)}</IntercomHeader>}
        {this.isUpdate && <IntercomHeader Size='h1' id='uPhish-landing-page-update-header'>{I18n.t('common.uPhish')} - {I18n.t('updateLandingPage', trOpt)}</IntercomHeader>}
        <LandingPageForm
          id={this.id}
          clone={this.isClone}
          onSuccess={this.handleSuccess}
          onLoadError={this.handleLoadError}
          showCancel
          onCancel={this.handleCancel}
        />
      </Card>
    )
  }
}

export default withRouter(EditLandingPage)
