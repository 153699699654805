export const BrandColours = ['#1e7be4', '#17baf7', '#49b8cb', '#f75959', '#9458cb', '#414042']

export const generateYScaleMinMax = (data) => {
  // Calculate sensible min/max values for use in Nivo charts
  // Current implementation needs improvement. At the moment it's
  // just to stop the Nivo charts overshooting the y scale
  let min = 0
  let max = 0
  for (const set of data) {
    for (const point of set.data) {
      if (point.y < min) min = point.y
      if (point.y > max) max = point.y
    }
  }

  // If max is more than 200, round up to the nearest 100
  if (max > 200) max = Math.ceil(max / 100) * 100

  // If max is more than 10, round up to the nearest 10
  else if (max > 10) max = Math.ceil(max / 10) * 10

  // If max is less than 1, round up to 1
  else if (max < 1) max = Math.ceil(max)

  return { min, max }
}
