import React, { useImperativeHandle, useRef, useState, useCallback } from 'react'

import { MARK_BREACHES_RESOLVED } from '../../Queries/uBreach'
import ActionConfirm from '../ActionConfirm'
import I18n from 'i18n-js'

const trOpt = { scope: 'modals.markResolvedConfirm' }

const MarkResolvedConfirm = React.forwardRef(({
  refetchQueries, onClose: onCloseProp = () => {}, type
}, ref) => {
  const [records, setRecords] = useState([])
  const title = useCallback(() => {
    const breachCount = records.reduce((acc, { breachName }) => {
      if (!acc.includes(breachName)) acc.push(breachName)
      return acc
    }, []).length

    const userCount = records.reduce((acc, { email }) => {
      if (!acc.includes(email)) acc.push(email)
      return acc
    }, []).length

    if (type === 'breach') {
      return I18n.t('titleBreach', { ...trOpt, count: breachCount })
    } else if (type === 'user') {
      return I18n.t('titleUser', { ...trOpt, count: userCount })
    } else if (type === 'userBreaches') {
      return I18n.t('titleUserBreaches', { ...trOpt, count: breachCount })
    } else if (type === 'breachedUsers') {
      return I18n.t('titleBreachedUsers', { ...trOpt, count: userCount })
    }

    return I18n.t('title', trOpt)
  }, [type, records])

  const actionConfirmRef = useRef(null)
  useImperativeHandle(ref, () => ({
    open: records => {
      setRecords(records)
      if (actionConfirmRef.current) {
        actionConfirmRef.current.open(records.map(r => r.id))
      }
    }
  }), [actionConfirmRef])

  const onClose = useCallback(() => {
    setRecords([])
    onCloseProp()
  }, [onCloseProp])

  return (
    <ActionConfirm
      mutation={MARK_BREACHES_RESOLVED}
      ref={actionConfirmRef}
      variableName={null}
      variables={{ records }}
      {...{ refetchQueries, onClose, trOpt, title }}
    />
  )
})

export default MarkResolvedConfirm
