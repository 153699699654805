// default payloadFunct that either returns the single argument passed in or an array of all the arguments
const defaultPayloadCreator = (...args) => {
  if (args.length === 0) {
    return
  }

  return args.length === 1 ? args[0] : args
}

export const createAction = (type, payloadCreator = defaultPayloadCreator) =>
  // Using a traditional function declaration as arguments is undefined in arrow functions
  function () {
    return {
      type,
      payload: payloadCreator.apply(null, Array.prototype.slice.call(arguments))
    }
  }
