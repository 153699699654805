/* global localStorage */
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react'
import styled from 'styled-components'
import Player from '@vimeo/player'
import I18n from 'i18n-js'
import uuid from 'uuid/v4'
import moment from 'moment'
import Bowser from 'bowser'
import queryString from 'query-string'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _toInteger from 'lodash/toInteger'
import ms from 'ms'

import { ContentBox, CardTitle } from './common'
import LearnerCourseSlate from './LearnerCourseSlate'
import { LoadingBlock, ProgressButton } from '../common'
import DemoMode from '../../helpers/DemoMode'
import useTheme from '../../hooks/useTheme'
import { createAction } from '../../helpers/state'
import { MIN_BREAKPOINT } from '../../constants/style'
import { UsecureError, getErrorStrings, modalWarningAsync, renderParagraphsFragmentFromArray } from '../../helpers'
import { IntroDetail } from './LearnerCourseIntro'
import LearnerCourseVideoPlayer from './LearnerCourseVideoPlayer'
import { getVideoTimestampFromSecondsMatchingDuration } from '../../helpers/video'
import { ULEARN_VIMEO_STORAGE_PREFIX, ULEARN_VIMEO_USER_PLAYER_STORAGE_ID } from '../../constants/courses'
import { FORCE_VIMEO_PLAYER } from '../../constants/environment'
import { DialogModalContent } from '../Modals/common'
import { Button } from 'antd'
import { captureSentryError } from '../../helpers/sentry'

const trOpt = { scope: 'learnerCourse.learnerCourseVimeo' }

const browser = Bowser.getParser(window.navigator.userAgent)
// Force Vimeo player on iOS and iPad OS due to incomplete/missing fullscreen API support
// FORCE_VIMEO_PLAYER_ON_BROWSER won't be true on iOS or iPad OS if request desktop website is enabled in Safari.
// Possible iPad OS Fix
// - Safari 12+ has limited support for the fullscreen api unlike iPhone which as none.
// - It doesn't set the height of the fullscreen'd div match the screen. The video content is rendered small as a result.
// - Setting its height to 100vh and 100% doesn't work due to how the course slide UI is built.
// - Setting a pixel height on VimeoContainer & StyledVimeoWrapper to match the screen height solves the problem.
// - We'd have to track the screen height in state and apply it to these components when in full screen.
// - That could impact other browsers and even if limited to Safari it would impact Mac OS Safari users unnecessarily.
const FORCE_VIMEO_PLAYER_ON_BROWSER = browser.satisfies({
  mobile: {
    safari: '>=*'
  }
})

const CONTAINER_ID = 'course-slide-vimeo-player'
const VIMEO_TIMEOUT = ms(window.__USECURE_CONFIG__.REACT_APP_VIMEO_TIMEOUT ?? '30s');

const StyledVimeoWrapper = styled.div`
  text-align: center;
  width: 100%;
`

// The height in the style below sets the vimeo container div's dimensions to a 16:9 aspect ratio relative to the available width
// usecure's video content is produced in 16:9 and this is likely true of ours clients' content too.
// Any video content using a different aspect ratio will have black bars to fill the space
// This code originally used a JS approach but this pure CSS approach is much simpler. It will need to be updated if the ContextBox styles change.
const VimeoContainer = styled.div`
  background-color: ${({ status }) => status === 'ready' ? '#000000' : 'transparent'};
  display: ${({ status }) => status === 'failed' ? 'none' : 'block'};
  position: relative;
  text-align: center;
  width: 100%;

  height: calc((100vw - 3rem) / (16 / 9));
  @media (min-width: ${MIN_BREAKPOINT}) {
    height: calc((75vw - 8.2rem) / (16 / 9));
  }

  iframe, object, embed {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
`

const TimeRemaining = styled.div`
  color: rgb(124, 112, 107);
  font-weight: 500;
  font-size: 1.5em;
  margin-top: 5px;
  text-align: center;
`

const ErrorDetail = styled(IntroDetail)`
  margin-top: 3rem;
  max-width: none;
`

const ActionsContainer = styled.div`
  margin-bottom: 10px;

  .ant-btn {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const DebugErrorOutput = styled.div`
  p {
    font-family: unset;
    font-size: 10px;
    margin-bottom: 2px;
  }
`

const getSavedCurrentTime = storageId => {
  const lastSavedTime = localStorage.getItem(storageId)
  if (lastSavedTime === 'played') {
    return lastSavedTime
  } else if (_isString(lastSavedTime) && !isNaN(lastSavedTime)) {
    return _toInteger(lastSavedTime)
  }
  return null
}

const getTextTrackId = textTrack => `${textTrack.language}|${textTrack.kind}`

const applyTimeout = async (promise, { timeoutError = I18n.t('common.anErrorOccurred', trOpt), delay = 30000, errorPrefix } = {}) => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new UsecureError(timeoutError))
    }, delay)
    promise
      .then(value => {
        clearTimeout(timeoutId)
        resolve(value)
      })
      .catch(e => {
        clearTimeout(timeoutId)
        captureSentryError(e, { msg: `LearnerCourseVimeo.applyTimeout${errorPrefix ? ` - ${errorPrefix}` : ''} - ERROR` })
        reject(e)
      })
  })
}

// Based on this article - https://levelup.gitconnected.com/fullscreen-practical-tips-and-tricks-cebcba69fb95
const toggleFullScreen = () => {
  const doc = window.document
  const targetEl = document.getElementById(CONTAINER_ID) // Vimeo player container div

  const requestFullScreen = targetEl && (
    targetEl.requestFullscreen ||
    targetEl.mozRequestFullScreen ||
    targetEl.webkitRequestFullScreen ||
    targetEl.msRequestFullscreen
  )
  const cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen

  if (!documentIsInFullScreen()) {
    requestFullScreen.call(targetEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

const documentIsInFullScreen = () => {
  const doc = window.document
  if (!doc.fullscreenElement && !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    return false
  } else {
    return true
  }
}

const getErrorMessagesArray = e => {
  let errorMessages = getErrorStrings(e)
  if (errorMessages.length === 0) {
    errorMessages = [`${e}`]
  }
  return errorMessages
}

const types = {
  UPDATE: 'UPDATE',
  RESET: 'RESET',
  FAIL: 'FAIL'
}

const creators = {
  update: createAction(types.UPDATE),
  demoMode: createAction(types.UPDATE, demoModeEnabled => ({ demoModeEnabled })),
  timeRemaining: createAction(types.UPDATE, timeRemaining => ({ timeRemaining })),
  currentTime: createAction(types.UPDATE, currentTime => ({ currentTime })),
  duration: createAction(types.UPDATE, duration => ({ duration })),
  starting: createAction(types.UPDATE, started => ({ starting: started === true, started: false })),
  started: createAction(types.UPDATE, started => ({ started: started === true, starting: false })),
  playing: createAction(
    types.UPDATE,
    playing => {
      const payload = { playing: playing === true }
      if (payload.playing) {
        // Set started to true in case initial playback was triggered without using the start button
        payload.started = true
      }
      return payload
    }
  ),
  volume: createAction(types.UPDATE, volume => ({ volume })),
  muted: createAction(types.UPDATE, muted => ({ muted: muted === true })),
  audioUnavailable: createAction(types.UPDATE, audioUnavailable => ({ audioUnavailable: audioUnavailable === true })),
  fullScreen: createAction(types.UPDATE, fullScreen => ({ fullScreen: fullScreen === true })),
  reset: createAction(types.RESET, status => status),
  textTrackId: createAction(types.UPDATE, textTrackId => ({ textTrackId })),
  fail: createAction(types.FAIL, (e, { errorTitleKey, errorMessageKey, instance } = {}) => {
    captureSentryError(e)
    return {
      errorMessages: getErrorMessagesArray(e),
      errorTitleKey,
      errorMessageKey,
      instance
    }
  })
}

const initialState = {
  status: 'init',
  vimeoPlayer: null,
  failedVimeoPlayerInstance: null,
  disableErrorActions: false,
  useCustomPlayer: true,
  storageId: null,
  duration: null,
  currentTime: null,
  timeRemaining: null,
  starting: false,
  started: false,
  playing: false,
  played: false,
  volume: 1,
  muted: false,
  audioUnavailable: false,
  textTrackId: null,
  textTracks: [],
  initialTextTrackId: null,
  fullScreen: false,
  demoModeEnabled: false,
  errorMessages: null,
  errorTime: null,
  currentVimeoLink: null
}
const PERSIST_AFTER_RESET_VARS = ['demoModeEnabled', 'failedVimeoPlayerInstance', 'forceVideoPlayer', 'currentVimeoLink']
const resetInitialState = _omit(initialState, PERSIST_AFTER_RESET_VARS)

const controlledResetState = (prevState) => ({
  ...resetInitialState,
  ..._pick(prevState, PERSIST_AFTER_RESET_VARS)
})

const actionsMap = {
  [types.UPDATE]: (prevState, payload) => {
    const newState = {
      ...prevState,
      ...(payload || {})
    }
    if (newState.status !== 'failed') {
      newState.errorMessages = null
      newState.errorTime = null
    }
    return newState
  },
  [types.RESET]: (prevState, status = 'start') => ({ ...controlledResetState(prevState), status }),
  [types.FAIL]: (prevState, { errorMessages = null, errorTitleKey = null, errorMessageKey = null, instance } = {}) => ({
    ...controlledResetState(prevState),
    status: 'failed',
    errorTitleKey,
    errorMessageKey,
    errorMessages,
    errorTime: moment.utc(),
    failedVimeoPlayerInstance: instance ?? prevState.vimeoPlayer?.instance
  })
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  const fn = actionsMap[type]
  return fn ? fn(state, payload) : state
}

const LearnerCourseVimeo = React.forwardRef(({
  slide, canGoPrevSlide, goToNextSlide, goToPrevSlide, courseResultId, waitMS, forceSubtitles = false, forceVimeoPlayerSetting = false
}, ref) => {
  const { id: slideId, title, content } = slide
  const { intro, vimeoType = 'id', vimeoId, vimeoURL, forceVimeoPlayer } = content || {}
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    status, vimeoPlayer, storageId, duration, currentTime, timeRemaining, played, demoModeEnabled, started, starting,
    useCustomPlayer, errorMessages, errorTime, errorTitleKey, errorMessageKey, playing, volume, muted, audioUnavailable, textTracks, textTrackId, initialTextTrackId, fullScreen,
    failedVimeoPlayerInstance, disableErrorActions, forceVideoPlayer, currentVimeoLink
  } = state
  const theme = useTheme()
  const { primary: primaryColor } = theme

  const slideDelayEnabled = useMemo(() => waitMS > 0 && !demoModeEnabled, [waitMS, demoModeEnabled])

  const canProgress = useCallback(() => {
    return slideDelayEnabled ? played : true
  }, [slideDelayEnabled, played])
  const allowProgress = useMemo(() => canProgress(), [canProgress])

  // "componentDidMount" effect
  useEffect(() => {
    // Set forceVideoPlayer in state from URL query param
    const { forceVideoPlayer } = queryString.parse(window.location.search)

    dispatch(creators.update({
      status: 'start',
      // Only set forceVideoPlayer if the query param is an allowed value for this slide type
      forceVideoPlayer: ['system', 'provider', 'vimeo'].includes(forceVideoPlayer) ? forceVideoPlayer : null
    }))
  }, [])

  // Reset state when slide changes
  useEffect(() => {
    dispatch(creators.reset())
  }, [slideId])

  // Destroy failed vimeo instances
  const destroyFailedVimeoPlayerInstance = useCallback(async () => {
    if (failedVimeoPlayerInstance) {
      try {
        dispatch(creators.update({ disableErrorActions: true }))
        await failedVimeoPlayerInstance.destroy()
        dispatch(creators.update({ destroyFailedVimeoPlayerInstance: null, disableErrorActions: false }))
      } catch (e) {
        // Silent failure - A "try again" won't be possible if this has failed
        console.error('LearnerCourseVimeo.destroyFailedVimeoPlayerInstance - Error destroying failed player instance', e)
      }
    }
  }, [failedVimeoPlayerInstance])
  useEffect(() => {
    destroyFailedVimeoPlayerInstance()
  }, [destroyFailedVimeoPlayerInstance])

  const initialisePlayerInstance = useCallback(async ({ storageId, instance, useCustomPlayer }) => {
    const update = {
      status: 'ready',
      storageId,
      useCustomPlayer,
      vimeoPlayer: {
        instance,
        storageId: storageId
      }
    }

    // Set time remaining state vars
    update.duration = await instance.getDuration()
    // Set the player to the last played timestamp for the result+slide+video if applicable
    const lastSavedTime = getSavedCurrentTime(storageId)
    update.played = lastSavedTime === 'played'
    if (_isNumber(lastSavedTime) && lastSavedTime > 0) {
      await instance.setCurrentTime(lastSavedTime)
    }

    update.currentTime = await instance.getCurrentTime()
    update.volume = await instance.getVolume()
    update.muted = await instance.getMuted()

    const vimeoTextTracks = await instance.getTextTracks()
    if (_isArray(vimeoTextTracks) && !_isEmpty(vimeoTextTracks)) {
      update.textTracks = vimeoTextTracks.map(textTrack => ({
        id: getTextTrackId(textTrack),
        ...textTrack
      }))
      const currentTextTrack = vimeoTextTracks.find(textTrack => textTrack.mode === 'showing')
      if (currentTextTrack) {
        update.textTrackId = getTextTrackId(currentTextTrack)
      } else if (forceSubtitles) {
        // Force use of first subtitle track
        // Our video content typically only has one subtitle track which suits this simple approach to enable a default subtitle track.
        update.initialTextTrackId = getTextTrackId(vimeoTextTracks[0])
      }
    }

    dispatch(creators.update(update))
  }, [forceSubtitles])

  const startVimeoPlayer = useCallback(async () => {
    let vimeoLink
    if (vimeoType === 'url' && vimeoURL) {
      vimeoLink = vimeoURL
    } else if (vimeoType === 'id' && vimeoId) {
      vimeoLink = vimeoId
    }

    if (!vimeoLink) {
      console.error('LearnerCourseVimeo.startVimeoPlayer - No Vimeo ID or URL present in course slide data')
      if (vimeoPlayer?.instance) {
        try {
          await vimeoPlayer.instance.destroy()
        } catch (e) {
          // Silent failure as this is a clean up operation
          console.error('LearnerCourseVimeo.startVimeoPlayer - Error destroying old player instance', e)
        }
      }
      dispatch(creators.fail(new Error(`${I18n.t('misconfiguredSlideError', trOpt)} [NO_VIMEO_LINK]`)))
      return
    }

    if (status !== 'start' && vimeoLink === currentVimeoLink) return

    // Set current vimeo link as we're loading a new video
    dispatch(creators.update({ currentVimeoLink: vimeoLink }))

    // Select whether our player overlay is used based on browser compatibility, user preference, env var, company settings or the course slide's settings
    let useCustomPlayer = (
      localStorage.getItem(ULEARN_VIMEO_USER_PLAYER_STORAGE_ID) !== 'true' && // user preference from browser local storage
      !FORCE_VIMEO_PLAYER && // client env var
      !FORCE_VIMEO_PLAYER_ON_BROWSER && // browser compatibility
      forceVimeoPlayer !== true && // Course slide setting
      forceVimeoPlayerSetting !== true // Company setting (company.settings.forceCourseVimeoPlayer)
    )
    // Override player type based on forceVideoPlayer URL query parameter
    if (forceVideoPlayer === 'system') {
      useCustomPlayer = true
    } else if (forceVideoPlayer === 'provider' || forceVideoPlayer === 'vimeo') {
      useCustomPlayer = false
    }

    // CL - It isn't possible to tell if a "chromeless" embed (i.e. controls=false) is allowed by a video or not. Vimeo's player will show alongside our own if not.
    const options = {
      byline: false,
      color: primaryColor, // The video can override this
      portrait: false,
      title: false,
      controls: !useCustomPlayer,
      // Disabling Vimeo's keyboard shortcuts when using LearnerCourseVideoPlayer as the component bg overlay prevents them from working as the underlying vimeo player nevers gets focus.
      // It would be nice to implement keyboard shortcuts within LearnerCourseVideoPlayer depending on client feedback.
      keyboard: !useCustomPlayer
    }
    if (vimeoType === 'url') options.url = vimeoURL
    if (vimeoType === 'id') options.id = vimeoId

    const storageId = `${ULEARN_VIMEO_STORAGE_PREFIX}${courseResultId}|${slideId}|${vimeoLink}`

    if (!vimeoPlayer || vimeoPlayer?.storageId !== storageId) {
      dispatch(creators.reset('loading'))
      // New Vimeo URL/ID - Reset player and component
      // New Vimeo instance required due to initialisation or transition between 2 video slides
      try {
        if (vimeoPlayer?.instance) {
          await applyTimeout(vimeoPlayer.instance.destroy(), { delay: 5000, timeoutError: 'Destroy operation timed out', errorPrefix: 'Destroy previous video player' })
        }
      } catch (e) {
        // Silent failure as the new video takes priority
        // That's also why the error is a plain string
        console.error('LearnerCourseVimeo.startVimeoPlayer - Error destroying old player instance', e)
      }

      let instance
      try {
        // Create new player instance and update state vars
        instance = new Player(CONTAINER_ID, options)
        // Add error event listener to capture preinitialisation errors
        // The event listener effect hook should replace this listener when it runs after a successful initialisation
        instance.on('error', (error) => {
          if (error.message && error.method && error.name) {
            // Error was generated by Vimeo SDK method call
            console.error(`LearnerCourseVimeo.startVimeoPlayer - Vimeo Player Instance - Method Error - method: ${error.method}; name: ${error.name}; message: ${error.message};`, error)
          } else {
            // All other errors
            console.error('LearnerCourseVimeo.startVimeoPlayer - Vimeo Player Instance - ERROR', error)
          }
        })
        // Controlled @vimeo/player ready call - a plain call will hang indefinitely if *.vimeocdn.com is blocked
        // The embed process won't reach this point if vimeo.com is blocked
        // CL - I wasn't sure what to set the timeout to as ready calls are usually quick. We may need to increase this if there are false negatives due to network latency
        await applyTimeout(instance.ready(), { timeoutError: `${I18n.t('playerTimeoutError', trOpt)} [VIMEO_PLAYER_TIMEOUT]`, delay: 15000, errorPrefix: 'Waiting for player to be ready' })

        await applyTimeout(
          initialisePlayerInstance({ storageId, instance, useCustomPlayer }),
          {
            delay: VIMEO_TIMEOUT, // 30 second timeout to complete player initialisation - this operation should be very quick under normal circumstances
            timeoutError: `${I18n.t('playerInitialisationTimeoutError', trOpt)} [VIMEO_INIT_TIMEOUT]`,
            errorPrefix: 'Initialising video player'
          }
        )
      } catch (e) {
        console.error('LearnerCourseVimeo.startVimeoPlayer - Error creating new player instance', e)
        // This sets failedVimeoPlayerInstance in state to the failed instance so destroyFailedVimeoPlayerInstance can destroy so a retry or language change will trigger a player restart
        dispatch(creators.fail(e, { instance }))
      }
    }
  }, [
    status, vimeoType, vimeoId, vimeoURL, primaryColor, vimeoPlayer, courseResultId,
    slideId, forceVimeoPlayer, forceVimeoPlayerSetting, initialisePlayerInstance, forceVideoPlayer, currentVimeoLink
  ])
  useEffect(() => {
    startVimeoPlayer()
  }, [startVimeoPlayer])

  useEffect(() => {
    const { instance, storageId } = vimeoPlayer || {}
    if (instance) {
      // Remove existing handlers
      // The code below removed all handlers for that event on this player instance. This will break any handlers assigned outside this effect hook.
      instance.off('playing')
      instance.off('pause')
      instance.off('durationchange')
      instance.off('timeupdate')
      instance.off('seeking')
      instance.off('volumechange')
      instance.off('texttrackchange')
      instance.off('error')

      // Add player event listeners
      instance.on('playing', () => dispatch(creators.playing(true)))
      instance.on('pause', () => dispatch(creators.playing(false)))
      instance.on('durationchange', ({ duration }) => dispatch(creators.duration(Math.floor(duration))))
      instance.on('volumechange', ({ volume }) => dispatch(creators.volume(volume)))
      instance.on('timeupdate', async ({ seconds, duration }) => {
        const seeking = await instance.getSeeking()
        if (!seeking) {
          const update = { currentTime: Math.floor(seconds) }
          if (Math.floor(duration) === Math.floor(seconds)) {
            localStorage.setItem(storageId, 'played')
            update.played = true
            update.playing = false
          }
          dispatch(creators.update(update))
        }
      })
      instance.on('texttrackchange', ({ kind, language }) => {
        dispatch(creators.textTrackId(kind && language ? getTextTrackId({ language, kind }) : null))
      })
      // Log errors from player instance to the console
      instance.on('error', (error) => {
        if (error.message && error.method && error.name) {
          // Error was generated by Vimeo SDK method call
          console.error(`LearnerCourseVimeo.vimeoPlayer.instance - Method Error - method: ${error.method}; name: ${error.name}; message: ${error.message};`, error)
        } else {
          // All other errors
          console.error('LearnerCourseVimeo.vimeoPlayer.instance - ERROR', error)
        }
      })
      if (slideDelayEnabled) {
        instance.on('seeking', async ({ seconds }) => {
          const lastSavedTime = getSavedCurrentTime(storageId)
          if (_isNumber(lastSavedTime) && seconds > lastSavedTime) {
            // Video has been previously started and user is trying to advance past the further point they've played
            await instance.setCurrentTime(lastSavedTime)
          } else if (_isNil(lastSavedTime)) {
            // Video has never been started - reset to start on seek
            await instance.setCurrentTime(0)
          } else {
            // Video has watched this part of the video is allowed forward/rewind/navigate to it
            await instance.setCurrentTime(seconds)
          }
        })
      }
    }
  }, [vimeoPlayer, slideDelayEnabled])

  useEffect(() => {
    const setDemoModeEnabled = demoModeEnabled => dispatch(creators.demoMode(demoModeEnabled))
    setDemoModeEnabled(DemoMode.isEnabled())
    const listenerId = uuid()
    DemoMode.addListener(listenerId, demoModeEnabled => setDemoModeEnabled(demoModeEnabled))
    return () => {
      DemoMode.removeListener(listenerId, demoModeEnabled => setDemoModeEnabled(demoModeEnabled))
    }
  }, [])

  useEffect(() => {
    let timeRemaining = null
    if (_isNumber(duration) && _isNumber(currentTime)) {
      timeRemaining = getVideoTimestampFromSecondsMatchingDuration(duration - currentTime, duration)
    }
    dispatch(creators.timeRemaining(timeRemaining))
  }, [currentTime, duration])

  useEffect(() => {
    if (_isNumber(currentTime)) {
      const lastSavedTime = getSavedCurrentTime(storageId)
      if (lastSavedTime !== 'played' && (_isNil(lastSavedTime) || currentTime > lastSavedTime)) {
        localStorage.setItem(storageId, currentTime)
      }
    }
  }, [currentTime, storageId])

  // Listen for fullscreen change on container
  // This hooks has slideId as a dep because containerRef is nullified on slide transition
  const containerRef = useRef(null)
  useEffect(() => {
    function fullScreenChanged () {
      dispatch(creators.fullScreen(documentIsInFullScreen()))
    }
    const { current: container } = containerRef
    if (container) {
      container.addEventListener('fullscreenchange', fullScreenChanged)
      container.addEventListener('webkitfullscreenchange', fullScreenChanged)
    }
    return () => {
      if (container) {
        container.removeEventListener('fullscreenchange', fullScreenChanged)
        container.removeEventListener('webkitfullscreenchange', fullScreenChanged)
      }
    }
  }, [containerRef, slideId])

  useImperativeHandle(ref, () => ({
    canProgress
  }), [canProgress])

  const setPlayerCurrentTime = useCallback(async seconds => {
    if (vimeoPlayer?.instance) {
      await vimeoPlayer.instance.setCurrentTime(seconds)
    }
  }, [vimeoPlayer])
  const getPlayerMaxTime = useCallback(() => {
    if (slideDelayEnabled && storageId) {
      const lastSavedTime = getSavedCurrentTime(storageId)
      return _isNumber(lastSavedTime) ? lastSavedTime : null
    }
  }, [storageId, slideDelayEnabled])

  const onPlayerPlayPauseClick = useCallback(async playing => {
    if (vimeoPlayer?.instance) {
      if (playing) {
        await vimeoPlayer.instance.pause()
      } else {
        await vimeoPlayer.instance.play()
      }
    }
  }, [vimeoPlayer])
  const onPlayerMuteUnmuteClick = useCallback(async muted => {
    if (vimeoPlayer?.instance) {
      await vimeoPlayer.instance.setMuted(muted)
      // Vimeo doesn't have a muted event or trigger a volumechange=0 event when muted
      // This state update puts LearnerCourseVideoPlayer in a muted state.
      // The lack of a muted event means that we can not be 100% sure if the Vimeo player is muted or not.
      // Our player could go out of sync with the Vimeo player so this logic introduces a bug risk.
      // When unmuted, Vimeo will restore the volume to the premute volume if available. This component doesn't track that value for this reason.
      dispatch(creators.muted(muted))
    }
  }, [vimeoPlayer])
  const setPlayerVolume = useCallback(async volume => {
    if (vimeoPlayer?.instance) {
      await onPlayerMuteUnmuteClick(volume === 0)
      await vimeoPlayer.instance.setVolume(volume)
    }
  }, [vimeoPlayer, onPlayerMuteUnmuteClick])
  const setTextTrack = useCallback(async id => {
    if (vimeoPlayer?.instance) {
      if (id) {
        const textTrack = textTracks.find(t => t.id === id)
        if (textTrack) {
          await vimeoPlayer.instance.enableTextTrack(textTrack.language, textTrack.kind)
        }
      } else {
        await vimeoPlayer.instance.disableTextTrack()
      }
    }
  }, [vimeoPlayer, textTracks])

  const startVideo = useCallback(async () => {
    if (vimeoPlayer?.instance) {
      dispatch(creators.starting(true))
      await vimeoPlayer.instance.play()
      dispatch(creators.started(true))
    }
  }, [vimeoPlayer])

  const startMutedVideo = useCallback(async () => {
    let setSubtitle = false
    // Vimeo uses an iframe so calls to play method are treated as script initiated and maybe blocked due to autoplay restrictions
    // Autoplaying videos are allowed if they're on mute which is why we have this fallback.
    await applyTimeout(
      (async () => {
        await setPlayerVolume(0)
        await startVideo()
        await vimeoPlayer.instance.pause()
        if (_isArray(textTracks) && !_isEmpty(textTracks)) {
          setSubtitle = true
          await setTextTrack(textTracks[0].id)
        }
        dispatch(creators.audioUnavailable(true))
      })(),
      {
        delay: VIMEO_TIMEOUT, // 30 second timeout to start playback
        timeoutError: `${I18n.t('startTimeoutError', trOpt)} [VIMEO_MUTED_START_TIMEOUT]`,
        errorPrefix: 'Muted Video Start'
      }
    )

    // Show warning modal and then start playback
    await modalWarningAsync({
      title: I18n.t('learnerCourse.learnerCourseVideoPlayer.noSoundWarning'),
      content: (
        <DialogModalContent>
          <p>{I18n.t(`soundWarningMessage.${setSubtitle ? 'unableToPlayVideoSubtitles' : 'unableToPlayVideo'}`, trOpt)}</p>
          <p>{I18n.t('soundWarningMessage.allowAutoPlayInstruction', trOpt)}</p>
        </DialogModalContent>
      )
    })
    await vimeoPlayer.instance.play()
  }, [startVideo, setPlayerVolume, vimeoPlayer, textTracks, setTextTrack])

  const onPlayerStartClick = useCallback(async () => {
    try {
      await applyTimeout(startVideo(), {
        // 30 second timeout to start playback
        delay: VIMEO_TIMEOUT,
        timeoutError: `${I18n.t('startTimeoutError', trOpt)} [VIMEO_START_TIMEOUT]`,
        errorPrefix: 'Start Video'
      })
    } catch (e) {
      console.error('LearnerCourseVimeo.onPlayerStartClick - Attempt 1 - ERROR', e)
      console.log()
      if (e.name === 'NotAllowedError') {
        // Video could not be played - Try again but on mute
        try {
          await applyTimeout(startMutedVideo(), {
            delay: VIMEO_TIMEOUT, // 30 second timeout to start playback
            timeoutError: `${I18n.t('startTimeoutError', trOpt)} [VIMEO_MUTED_START_TIMEOUT]`,
            errorPrefix: 'Start muted video'
          })
        } catch (e) {
          console.error('LearnerCourseVimeo.onPlayerStartClick - Attempt 2 - ERROR', e)
          dispatch(creators.fail(e, { errorTitleKey: 'startErrorTitle', errorMessageKey: 'startErrorMessage' }))
        }
      } else {
        dispatch(creators.fail(e, { errorTitleKey: 'startErrorTitle', errorMessageKey: 'startErrorMessage' }))
      }
    }
  }, [startVideo, startMutedVideo])

  // Set initial subtitle text after start of playback when subtitles are forced by company settings
  // This is more gracefull than calling enableTextTrack in startVimeoPlayer as that causes the subtitles to appear while the video is loading.
  useEffect(() => {
    if (vimeoPlayer?.instance && started && initialTextTrackId) {
      setTimeout(() => setTextTrack(initialTextTrackId), 200) // Can't await this as useEffect hooks can't be async
      dispatch(creators.update({ initialTextTrackId: null })) // Clear state var so this effect can't trigger again for the current video
    }
  }, [initialTextTrackId, started, vimeoPlayer, forceSubtitles, setTextTrack])

  const onTryAgainClick = useCallback(async () => {
    if (vimeoPlayer?.instance) {
      try {
        await vimeoPlayer.instance.destroy()
      } catch (e) {
        // Silent failure as this is a clean up operation
        console.error('LearnerCourseVimeo.onTryAgainClick - Error destroying old player instance', e)
      }
    }
    dispatch(creators.reset())
  }, [vimeoPlayer])
  const switchPlayer = useCallback(async (useVimeo = false) => {
    if (useVimeo) {
      localStorage.setItem(ULEARN_VIMEO_USER_PLAYER_STORAGE_ID, 'true')
    } else {
      localStorage.removeItem(ULEARN_VIMEO_USER_PLAYER_STORAGE_ID)
    }
    await onTryAgainClick()
  }, [onTryAgainClick])
  const switchToSystemPlayerClick = useCallback(async () => switchPlayer(false), [switchPlayer])
  const switchToVimeoPlayerClick = useCallback(async () => switchPlayer(true), [switchPlayer])

  const userPrefersVimeoPlayer = localStorage.getItem(ULEARN_VIMEO_USER_PLAYER_STORAGE_ID) === 'true'
  // Vimeo playback is enforced by either the system, company settings or course slide preferences
  // Do not make the ability to switch player visible when vimeo player use is forced
  const vimeoPlayerForced = FORCE_VIMEO_PLAYER || forceVimeoPlayerSetting || forceVimeoPlayer

  return (
    <ContentBox
      material innerKey={slideId}
      buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
      buttonsRight={<ProgressButton disabled={!allowProgress} onClick={goToNextSlide} />}
    >
      <CardTitle>{title}</CardTitle>
      <LearnerCourseSlate content={intro} />
      <StyledVimeoWrapper>
        {status === 'failed' && (
          <ErrorDetail>
            <h1>{I18n.t(errorTitleKey || 'errorTitle', trOpt)}</h1>
            <h3>
              {I18n.t(errorMessageKey || 'errorMessage', trOpt)}
              <br />
              {I18n.t('common.pleaseContactSupport')}
            </h3>
            <ActionsContainer>
              <Button disabled={disableErrorActions} onClick={onTryAgainClick} icon='redo'>{I18n.t('tryAgainButton', trOpt)}</Button>
              {!vimeoPlayerForced && !forceVideoPlayer && (
                useCustomPlayer
                  ? <Button disabled={disableErrorActions} onClick={switchToVimeoPlayerClick} icon='swap'>{I18n.t('switchToVimeoPlayerButton', trOpt)}</Button>
                  : <Button disabled={disableErrorActions} onClick={switchToSystemPlayerClick} icon='swap'>{I18n.t('switchToSystemPlayerButton', trOpt)}</Button>
              )}
            </ActionsContainer>
            <DebugErrorOutput>
              {_isArray(errorMessages) && !_isEmpty(errorMessages) && errorMessages.every(e => _isString(e)) && renderParagraphsFragmentFromArray(errorMessages)}
              <p>{window.location.pathname}</p>
              <p>{I18n.t('debugTiming', { ...trOpt, currentTime: (errorTime || moment.utc()).format('YYYY-MM-DD HH:mm') })}</p>
              <p>{navigator.userAgent}</p>
            </DebugErrorOutput>
          </ErrorDetail>
        )}
        <VimeoContainer id={CONTAINER_ID} status={status} ref={containerRef}>
          <LoadingBlock loading={status === 'init' || status === 'start' || status === 'loading'} fullScreen={false} />
          {useCustomPlayer && vimeoPlayer?.instance && _isNumber(currentTime) && _isNumber(duration) && (
            <LearnerCourseVideoPlayer
              videoId={storageId}
              {...{ starting, started, currentTime, duration, playing, volume, muted, textTracks, textTrackId, setTextTrack, fullScreen, audioUnavailable }}
              onStartClick={onPlayerStartClick}
              onPlayPauseClick={onPlayerPlayPauseClick}
              setCurrentTime={setPlayerCurrentTime}
              getMaxTime={getPlayerMaxTime}
              setVolume={setPlayerVolume}
              onMuteUnmuteClick={onPlayerMuteUnmuteClick}
              onFullScreenClick={toggleFullScreen}
            />
          )}
        </VimeoContainer>
        {status === 'ready' && _isString(timeRemaining) && <TimeRemaining>{I18n.t('learnerCourse.timeRemaining', { time: timeRemaining })}</TimeRemaining>}
        {status === 'ready' && !vimeoPlayerForced && !forceVideoPlayer && (audioUnavailable || userPrefersVimeoPlayer) && (
          <ActionsContainer>
            {
              useCustomPlayer
                ? <Button onClick={switchToVimeoPlayerClick} icon='swap'>{I18n.t('switchToVimeoPlayerButton', trOpt)}</Button>
                : <Button onClick={switchToSystemPlayerClick} icon='swap'>{I18n.t('switchToSystemPlayerButton', trOpt)}</Button>
            }
          </ActionsContainer>
        )}
      </StyledVimeoWrapper>
    </ContentBox>
  )
})

export default LearnerCourseVimeo
