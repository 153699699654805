import React from 'react'
import { Table } from 'antd'
import styled from 'styled-components'
import _clone from 'lodash/clone'

import { trendColors } from '../common/PerformanceReport'

const HighlightChange = styled.span`
  color: ${props => props.trend ? '#fff' : 'inherit'};
  padding: ${props => props.trend ? '3px 3px 0px' : '0px'};
  background-color: ${props => {
    if (props.trend === 'positive') return trendColors.positive
    if (props.trend === 'negative') return trendColors.negative
    return 'inherit'
  }};
`
const TrendBarCell = styled.div`
  display: flex;
  align-items: center;
`

const TrendBarValue = styled.div`
  width: 25%;
`

const TrendBarContainer = styled.div`
  width: 75%;
  margin-top: -5px;
`

const TrendBar = styled.div`
  width: ${props => props.width}%;
  height: 10px;
  background-color: ${props => {
    if (props.trend === 'positive') return trendColors.positive
    if (props.trend === 'negative') return trendColors.negative
    return trendColors.neutral
  }};
`

const determineTrend = (value, calc, fallback = '') => {
  const { positive, negative } = calc
  if (positive.comp === '>') {
    if (value > positive.val) return 'positive'
    if (value < negative.val) return 'negative'
  } else {
    if (value < positive.val) return 'positive'
    if (value > negative.val) return 'negative'
  }
  return fallback
}

const ReportTable = ({
  data = [],
  columns = [] // A regular column definition array for antd tables, with a slight addition (see tableColumns below)
}) => {
  // To render the mini bar chart thing and the cell highlighting you can pass a `significantChange` property to the column definition. This will look somthing like:
  // significantChange: {
  //  positive: { comp: '>', val: 190 }, - A value over 190 is positive
  //  negative: { comp: '<', val: 100 } - A value below 100 is negative
  // }
  // and a `showBar` boolean
  // These will then be used to override the render function.
  // This will probably break with a complicated render function
  const tableColumns = columns.map(col => {
    const newCol = _clone(col)
    if (col.showBar && col.significantChange) {
      // Get the original render function (if it exists)
      const _render = col.render ? col.render : v => v

      // Calculate max value for bar
      // The largest value in the data will take up all the available space
      // Everything else will be proportional to that
      const max = Math.max(...data.map(row => row[col.dataIndex]))

      // Create the new render function
      newCol.render = (text, record, index) => (
        <TrendBarCell>
          <TrendBarValue>
            {/* Call the original render function */}
            {_render(text, record, index)}
          </TrendBarValue>
          <TrendBarContainer>
            <TrendBar
              trend={determineTrend(text, col.significantChange)}
              width={(text / max) * 100}
            />
          </TrendBarContainer>
        </TrendBarCell>
      )
    } else if (col.significantChange) {
      const _render = col.render ? col.render : v => v

      newCol.render = (text, record, index) => (
        <HighlightChange
          trend={determineTrend(text, col.significantChange)}
        >
          {_render(text, record, index)}
        </HighlightChange>
      )
    }
    return newCol
  })

  return (
    <Table
      dataSource={data}
      columns={tableColumns}
      size='middle'
      pagination={{
        position: 'none',
        pageSize: data.length // Show all records
      }}
    />
  )
}

export default ReportTable
