import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Select, Collapse, Switch } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import EditCourseSlate from './EditCourseSlate'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import { FORCE_VIMEO_PLAYER } from '../../constants/environment'

const { Option } = Select
const { Panel } = Collapse
const trOpt = { scope: 'editCourse.editCourseVimeo' }

const InlineLabel = styled.span`
  display: inline-block;
  line-height: 1.5;
  margin-left: 15px;
`

class EditCourseVimeo extends Component {
  constructor (props) {
    super(props)

    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleVimeoTypeChange = this.handleVimeoTypeChange.bind(this)
    this.handleVimeoIdChange = this.handleVimeoIdChange.bind(this)
    this.handleVimeoUrlChange = this.handleVimeoUrlChange.bind(this)
    this.handleForceVimeoPlayerChange = this.handleForceVimeoPlayerChange.bind(this)
    this.updateIntro = this.updateIntro.bind(this)
  }

  handleTitleChange (event) {
    this.props.updateTitle(event.target.value)
  }

  updateIntro ({ intro }) {
    this.props.updateContent({ ...this.props.content, intro })
  }

  handleVimeoTypeChange (vimeoType) {
    const content = { ...this.props.content, vimeoType }
    if (vimeoType === 'id') {
      delete content.vimeoURL
    } else if (vimeoType === 'url') {
      delete content.vimeoId
    }
    this.props.updateContent(content)
  }

  handleVimeoIdChange (event) {
    this.props.updateContent({ ...this.props.content, vimeoId: event.target.value })
  }

  handleVimeoUrlChange (event) {
    this.props.updateContent({ ...this.props.content, vimeoURL: event.target.value })
  }

  handleForceVimeoPlayerChange (forceVimeoPlayer) {
    this.props.updateContent({ ...this.props.content, forceVimeoPlayer })
  }

  render () {
    const { title, content, locale, slideId, updateId } = this.props
    const { vimeoType = 'id', vimeoId = '', vimeoURL = '', forceVimeoPlayer } = content || {}

    return (
      <div>
        <Form.Item label={I18n.t('editCourse.common.title')}>
          <Input
            onChange={this.handleTitleChange}
            value={title}
          />
        </Form.Item>

        <Form.Item label={I18n.t('editCourse.common.intro')}>
          <EditCourseSlate updateContent={this.updateIntro} {...{ locale, content, slideId, updateId }} />
        </Form.Item>
        <Form.Item label={I18n.t('vimeoType', trOpt)}>
          <Select
            style={{ width: '100%' }}
            value={vimeoType}
            onChange={this.handleVimeoTypeChange}
          >
            <Option value='id'>{I18n.t('idOption', trOpt)}</Option>
            <Option value='url'>{I18n.t('urlOption', trOpt)}</Option>
          </Select>
        </Form.Item>
        {vimeoType === 'id' && (
          <Form.Item label={I18n.t('vimeoId', trOpt)}>
            <Input
              onChange={this.handleVimeoIdChange}
              value={vimeoId}
            />
          </Form.Item>
        )}
        {vimeoType === 'url' && (
          <Form.Item label={I18n.t('vimeoURL', trOpt)}>
            <Input
              onChange={this.handleVimeoUrlChange}
              value={vimeoURL}
            />
          </Form.Item>
        )}
        {!FORCE_VIMEO_PLAYER && (
          <Collapse>
            <Panel header={I18n.t('common.advancedOptions')}>
              <Form.Item
                extra={I18n.t('forceVimeoPlayerExtra', trOpt)}
              >
                <Switch
                  checked={forceVimeoPlayer === true}
                  onChange={this.handleForceVimeoPlayerChange}
                />
                <InlineLabel>{I18n.t('forceVimeoPlayer', trOpt)}</InlineLabel>
              </Form.Item>
            </Panel>
          </Collapse>
        )}
      </div>
    )
  }
}

EditCourseVimeo.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseVimeo.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseVimeo
