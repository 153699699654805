import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { generatePath } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Button } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import routes from '../../constants/routes'
import { connect } from '../../hocs'
import { useWindowSize } from '../../hooks/useWindowSize'
import useTheme from '../../hooks/useTheme'
import { GET_POLICY_DASHBOARD_DATA } from '../../components/Queries/EndUser'
import { getSessionAndSettings } from '../../state/selectors'
import { LoadingBlock } from '../../components/common'
import ErrorAlerts from '../../components/common/ErrorAlerts'
import { ContentWrap } from '../../components/common/Layout'
import { Row, Column, RoundedCard, ConditionalLayout } from '../../components/EndUserPortal/GridFlexiLayout'
import { PolicyProgress, TableTag, setStatusTagColour } from '../../components/EndUserPortal/DashboardComponents'
import { SearchBar } from '../../components/common/List'
import EndUserTable from '../../components/EndUserPortal/Table'
import { CATEGORY_NAMES } from '../../constants/uPolicy.js'
import { getStatusHierarchyAndTableFilters } from '../../helpers/endUser'
import { captureSentryError } from '../../helpers/sentry'

const trOptDashboard = { scope: 'endUserPortal.dashboard' }
const trOpt = { scope: 'endUserPortal.policyDashboard' }
const trOptCommon = { scope: 'endUserPortal.common' }
const statuses = ['outstanding', 'opened', 'signed']
const { statusHierarchy, statusFilters } = getStatusHierarchyAndTableFilters(statuses, { scope: 'endUserPortal.policyDashboard.policyStatus' })
const cardTemplate = {
  title: {
    key: 'name'
  },
  body: [
    {
      type: 'tag',
      key: 'status',
      trOpt: { scope: 'endUserPortal.policyDashboard.policyStatus' }
    }, {
      type: 'text',
      title: I18n.t('tableColumn.category', trOpt),
      key: 'category'
    }
  ]
}
const DataIteratorActions = ({ action, record, learnerId }) => {
  const trOpt = { scope: 'endUserPortal.policyDashboard' }
  return <Button type='link' href={generatePath(routes.LEARNER_POLICY, { learnerId, policyId: record.policyId })} target='blank' icon='form'>{I18n.t(`actions.${action}`, trOpt)}</Button>
}
const PolicyDashboard = ({ session }) => {
  const { loading, error, data } = useQuery(GET_POLICY_DASHBOARD_DATA, {
    onError: error => {
      captureSentryError(error, { msg: 'End User Portal - Policy Dashboard Load - ERROR' })
    }
  })
  const learnerName = _get(session, 'name')
  const learnerId = _get(session, 'learnerId')
  const theme = useTheme()
  const [rawPolicyResults, setRawPolicyResults] = useState([])
  const [outstandingPolicies, setOutstandingPolicies] = useState([])
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState({})
  const [filters, updateFilters] = useState({})
  const { type: screenSizeType } = useWindowSize()
  const isMobile = ['xs', 'sm'].includes(screenSizeType)

  useEffect(() => {
    if (data) {
      setRawPolicyResults(_get(data, 'learnerMe.endUser.policyResults'))
      setOutstandingPolicies(_get(data, 'learnerMe.endUser.policyResults').filter((policy) => policy.signed === null))
    }
  }, [data])

  const getStatusAndAction = (policyResult) => {
    let status = 'outstanding'
    let action = 'viewAndSign'
    if (policyResult.signed) {
      status = 'signed'
      action = 'view'
    } else if (policyResult.visit) {
      status = 'opened'
    }
    return { status, action }
  }
  const formattedPolicyResults = useMemo(() => {
    return rawPolicyResults.map((result) => {
      const { status, action } = getStatusAndAction(result)
      const policy = _get(result, 'policy')
      return {
        id: result.id,
        policyId: policy.id,
        name: policy.name,
        category: policy.category,
        status: status,
        action: action
      }
    }).sort((a, b) => a.name.localeCompare(b.name))
  }, [rawPolicyResults])

  const columns = useMemo(() => {
    return [{
      key: 'name',
      title: I18n.t('tableColumn.name', trOpt),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sorter && sorter.columnKey === 'name' && sorter.order
    }, {
      key: 'category',
      title: I18n.t('tableColumn.category', trOpt),
      dataIndex: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
      sortOrder: sorter && sorter.columnKey === 'category' && sorter.order,
      render: category => CATEGORY_NAMES[category] || CATEGORY_NAMES.none
    }, {
      key: 'status',
      title: I18n.t('tableColumn.status', trOpt),
      dataIndex: 'status',
      sorter: (a, b) => statusHierarchy[a.status] - statusHierarchy[b.status],
      sortOrder: sorter && sorter.columnKey === 'status' && sorter.order,
      filters: statusFilters,
      filterMultiple: true,
      onFilter: (value, record) => value === record.status,
      filteredValue: (filters && filters.status) || null,
      render: status => (
        <span>
          <TableTag color={setStatusTagColour(status, theme)} status={status} trOpt={{ scope: 'endUserPortal.policyDashboard.policyStatus' }} />
        </span>
      )
    }, {
      key: 'action',
      title: I18n.t('tableColumn.action', trOpt),
      dataIndex: 'action',
      render: (action, record) => (
        <Button type='link' href={generatePath(routes.LEARNER_POLICY, { learnerId, policyId: record.policyId })} target='blank' icon='form'>{I18n.t(`actions.${action}`, trOpt)}</Button>
      )
    }]
  }, [sorter, filters, learnerId, theme])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const applyOutstandingPolicyFilter = () => {
    const status = { status: ['outstanding', 'opened'] }
    updateFilters({ ...status })
  }
  const clearFilters = () => {
    updateFilters({})
  }
  const outstandingStatusIsFiltered = !_isEmpty(filters.status) && filters.status.includes('outstanding') && filters.status.includes('opened')
  return (
    <>
      <LoadingBlock loading={loading} fullScreen={false} showMenu />
      {error && <ContentWrap><ErrorAlerts error={error} defaultError={I18n.t('defaultPageError', trOptCommon)} /></ContentWrap>}
      {data && (
        <ContentWrap>
          <ConditionalLayout isRow={!isMobile}>
            <Column flex={isMobile ? '1' : '0 1 25%'}>
              <PolicyProgress locale={session.locale} policyResults={formattedPolicyResults} outstandingPolicies={outstandingPolicies} theme={theme} trOpt={trOptDashboard} companyId={null} />
            </Column>
            <Column flex={isMobile ? '1' : '2'}>
              <RoundedCard>
                <Column>
                  <ConditionalLayout isRow={!isMobile} className='justify' row={{ justify: 'space-between' }}>
                    <h1>{I18n.t('policyTableHeader', trOpt)}</h1>
                    <Row className='justify' justify={isMobile ? 'stretch' : 'flex-end'}>
                      <div style={isMobile ? { width: '100%' } : {}}>
                        {!outstandingStatusIsFiltered ? <Button type='primary' block={isMobile} onClick={() => applyOutstandingPolicyFilter()}>{I18n.t('outstandingPoliciesFilter', trOpt)}</Button>
                          : <Button block={isMobile} icon='rollback' onClick={() => clearFilters()}>{I18n.t('clearFilters', trOptCommon)}</Button>}
                      </div>
                      <div style={isMobile ? { width: '100%' } : {}}>
                        <SearchBar
                          placeholder={I18n.t('searchPolicies', trOpt)}
                          value={searchFilterText}
                          allowClear
                          onChange={onSearchChange}
                        />
                      </div>
                    </Row>
                  </ConditionalLayout>
                  <EndUserTable
                    tableData={formattedPolicyResults}
                    searchFilterText={searchFilterText}
                    trOpt={trOpt}
                    columns={columns}
                    tableProps={{
                      pagination: pagination,
                      updatePagination: updatePagination,
                      updateSorter: updateSorter,
                      updateFilters: updateFilters,
                      sortBy: ['name']
                    }}
                    dataIteratorProps={{
                      learnerName: learnerName,
                      learnerId: learnerId,
                      defaultPageSize: 5,
                      cardTemplate: cardTemplate,
                      cardAction: DataIteratorActions
                    }}
                  />
                </Column>
              </RoundedCard>
            </Column>
          </ConditionalLayout>
        </ContentWrap>
      )}
    </>
  )
}

export default connect(
  getSessionAndSettings
)(PolicyDashboard)
