import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'
import queryString from 'query-string'

import { SyncSetup } from '../components/SyncSetup'
import { MS_SYNC_GROUPS_PAGE } from '../components/Queries/Learners'
import { UPDATE_OFFICE_365_SETTINGS } from '../components/Queries/Companies'
import { connect } from '../hocs'
import selectors from '../state/selectors'
import { syncOffice365, renderListFromArray, renderListFragmentFromArray } from '../helpers'
import routes from '../constants/routes'
import { OFFICE_365_GROUP_TYPES } from '../constants/office365'

const trOpt = { scope: 'office365Setup' }
const autoSyncTrOpt = { scope: 'office365Setup.autoSync' }
const groupsTrOpt = { scope: 'office365Setup.includeGroups' }
const groupUsersTrOpt = { scope: 'office365Setup.includeGroupUsers' }
const groupMapTrOpt = { scope: 'office365Setup.includeGroupMapping' }
const unlicensedUsersTrOpt = { scope: 'office365Setup.excludeUsersWithoutAssignedLicenses' }
const emailMethodTrOpt = { scope: 'office365Setup.emailMethod' }
const syncManagersTrOpt = { scope: 'syncSetup.syncManagers' }

const QuestionPoints = styled.ul`
  padding: 15px 0 0 20px;
`

const MicrosoftSyncSetup = ({ office365 }) => {
  const handleAuthCode = useCallback(async () => {
    const { code: authCode, admin_consent: adminConsent, tenant: tenantId } = queryString.parse(window.location.search)
    if (!(authCode || (adminConsent === 'True' && tenantId))) {
      return
    }
    const authResult = await syncOffice365.updateAuthConfig({ authCode, adminConsent, tenantId })
    if (!authResult) {
      throw new Error(I18n.t('authConfigFailed', trOpt))
    }
  }, [])

  const steps = useMemo(() => [
    { type: 'intro' },
    {
      type: 'question',
      id: 'autoSync',
      question: I18n.t('question', autoSyncTrOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', autoSyncTrOpt))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'emailMethod',
      question: I18n.t('question', emailMethodTrOpt),
      options: ['userPrincipalNameOnly', 'userPrincipalNameFirst', 'mailOnly', 'mailFirst', 'original']
        .map(value => ({
          value,
          label: I18n.t(`options.${value}`, emailMethodTrOpt),
          recommended: value === 'userPrincipalNameOnly'
        })),
      description: ({ emailMethod }) => (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t(`infoPoints.${emailMethod}`, emailMethodTrOpt))}
        </QuestionPoints>
      ),
      verticalOptions: true
    },
    {
      type: 'question',
      id: 'includeGroups',
      question: I18n.t('question', groupsTrOpt),
      description: ({ includeGroups = false }) => (
        includeGroups
          ? (
            <QuestionPoints>
              <li>{I18n.t('groupsIncluded', groupsTrOpt)}</li>
              <li>{I18n.t('groupsChanges', groupsTrOpt)}</li>
              <li>
                <span>{I18n.t('mergeExistingGroupsIntro', groupsTrOpt)}</span>
                {renderListFromArray(I18n.t('mergeExistingGroupsPoints', groupsTrOpt))}
              </li>
              <li>{I18n.t('groupConfigurationScreen', groupsTrOpt)}</li>
            </QuestionPoints>
          )
          : (
            <QuestionPoints>
              <li>{I18n.t('groupsNotIncluded', groupsTrOpt)}</li>
            </QuestionPoints>
          )
      )
    },
    {
      type: 'question',
      id: 'includeGroupMembWhitelist',
      question: I18n.t('question', groupUsersTrOpt),
      description: ({ includeGroups = false, includeGroupMembWhitelist = false }) => (
        <QuestionPoints>
          {
            includeGroupMembWhitelist
              ? (
                <>
                  <li>{I18n.t('ableToSelectGroups', groupUsersTrOpt)}</li>
                  {
                    includeGroups
                      ? (
                        <li>
                          <span>{I18n.t('usersWillBeIncluded', groupUsersTrOpt)}:</span>
                          <ul>
                            <li>{I18n.t('syncedGroup', groupUsersTrOpt)}</li>
                            <li>{I18n.t('nonSyncedGroup', groupUsersTrOpt)}</li>
                          </ul>
                        </li>
                      )
                      : (
                        <>
                          <li>{I18n.t('groupUsersIncluded', groupUsersTrOpt)}</li>
                          <li>{I18n.t('syncedUsersWillNotAppear', groupUsersTrOpt)}</li>
                        </>
                      )
                  }
                </>
              )
              : (
                <>
                  <li>{I18n.t('unableToSelectGroups', groupUsersTrOpt)}</li>
                  {
                    includeGroups
                      ? (
                        <>
                          <li>{I18n.t('allUsersWillBeIncluded', groupUsersTrOpt)}</li>
                          <li>{I18n.t('usersFromNonSyncedGroups', groupUsersTrOpt)}</li>
                        </>
                      )
                      : (
                        <>
                          <li>{I18n.t('allUsersWillBeIncluded', groupUsersTrOpt)}</li>
                          <li>{I18n.t('usersWillNotAppearInGroup', groupUsersTrOpt)}</li>
                        </>
                      )
                  }
                </>
              )
          }
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'includeGroupMembMapping',
      question: I18n.t('question', groupMapTrOpt),
      description: ({ includeGroups = false, includeGroupMembMapping = false }) => (
        <QuestionPoints>
          {
            includeGroupMembMapping
              ? (
                <>
                  <li>{I18n.t('mapExistingGroups', groupMapTrOpt)}</li>
                  {includeGroups && <li>{I18n.t('syncedGroupsCannotBeMapped', groupMapTrOpt)}</li>}
                  <li>{includeGroups ? I18n.t('usersFromSelectedNonSyncedMapped', groupMapTrOpt) : I18n.t('usersFromSelectedMapped', groupMapTrOpt)}</li>
                  <li>{includeGroups ? I18n.t('usersFromSelectedNonSyncedUnmapped', groupMapTrOpt) : I18n.t('usersFromSelectedUnmapped', groupMapTrOpt)}</li>
                </>
              )
              : (
                <>
                  <li>{I18n.t('existingGroupsNotMapped', groupMapTrOpt)}</li>
                  <li>{includeGroups ? I18n.t('usersFromSelectedNonSyncedGroups', groupMapTrOpt) : I18n.t('usersFromSelectedGroups', groupMapTrOpt)}</li>
                </>
              )
          }
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'groupType',
      question: I18n.t('groupTypeQuestion', trOpt),
      options: ['all', 'office365', 'security'].map(value => ({ value, label: OFFICE_365_GROUP_TYPES[value] }))
    },
    { type: 'groups' },
    {
      type: 'question',
      id: 'excludeUsersWithoutAssignedLicenses',
      question: I18n.t('question', unlicensedUsersTrOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', unlicensedUsersTrOpt))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'syncManagers',
      question: I18n.t('question', syncManagersTrOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('infoPoints', syncManagersTrOpt))}
          {renderListFragmentFromArray(I18n.t('syncManagersInfoPoints', trOpt))}
        </QuestionPoints>
      )
    },
    {
      type: 'question',
      id: 'includeUserEmailBlacklist',
      question: I18n.t('excludeUsers.question', trOpt),
      description: (
        <QuestionPoints>
          {renderListFragmentFromArray(I18n.t('excludeUsers.infoPoints', trOpt))}
          {renderListFragmentFromArray(I18n.t('office365.common.emailBlacklistPoints'))}
        </QuestionPoints>
      )
    },
    { type: 'users' },
    { type: 'complete' }
  ], [])

  return (
    <SyncSetup
      headerId='m365-sync-header'
      trOptScopeKey='office365Setup'
      settings={office365}
      syncType='microsoft'
      usePaginatedGroupLoad
      groupsQuery={MS_SYNC_GROUPS_PAGE}
      groupsQueryKey='microsoftSyncGroupsPage'
      groupTypes={OFFICE_365_GROUP_TYPES}
      updateSettingsMutation={UPDATE_OFFICE_365_SETTINGS}
      syncSetupRoute={routes.OFFICE_365_SETUP}
      {...{ steps, handleAuthCode }}
    />
  )
}

export default connect(
  state => _pick(selectors.settings.get(state), ['office365'])
)(MicrosoftSyncSetup)
