/* global Image */
import JsPDF from 'jspdf'
import JsZip from 'jszip'
import I18n from 'i18n-js'
import { message } from 'antd'
import _isEmpty from 'lodash/isEmpty'
import mime from 'mime-types'
import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import _isArray from 'lodash/isArray'

import getImageSize from './getImageSize'
import { saveAsFromBlob } from './downloads'
import { sanitiseString } from './sanitise'
import { formatDate, formatDateTime } from './datetime'
import { addLocaleFontToJsPdfDoc } from './jsPDF'
import { captureSentryError } from './sentry'

const trOpt = { scope: 'common.certificate' }
// all pdf documents are A4 therefore the height and width are static
const pageWidth = 210.0015555555555
const centreOfDoc = pageWidth / 2

const ALLOW_CERT_IMAGE_FAIL = (_get(window, '__USECURE_CONFIG__.REACT_APP_ALLOW_CERT_IMAGE_FAIL') || 'true') === 'true'
const getSvgImageURI = async (svgSrc) => {
  const imgLogo = new Image()
  imgLogo.setAttribute('crossOrigin', 'anonymous')
  const promise = new Promise((resolve, reject) => {
    // Reject after 10 second timeout to avoid hanging promise
    const timeoutId = setTimeout(() => {
      reject(new Error('getImageSize timed out'))
    }, 10000)

    imgLogo.onload = () => {
      try {
        const canvas = document.createElement('canvas')
        canvas.width = imgLogo.naturalWidth
        canvas.height = imgLogo.naturalHeight
        const width = imgLogo.naturalWidth
        const height = imgLogo.naturalHeight
        canvas.getContext('2d').drawImage(imgLogo, 0, 0)
        const url = canvas.toDataURL('image/png')
        resolve({ url, width, height })
      } catch (e) {
        reject(e)
      } finally {
        clearTimeout(timeoutId)
      }
    }
    imgLogo.onerror = event => {
      reject(event)
    }
  })
  imgLogo.src = svgSrc
  return promise
}

const calculateCustomImageSize = (imageHeight, imageWidth) => {
  // Defined as constants outside generateCertificate
  const LOGO_BOUND_Y = 40
  const LOGO_BOUND_WIDTH = 50
  const LOGO_BOUND_HEIGHT = 40

  // Scaling and positioning from imageWidth and imageHeight within generateCertificate
  let logoX
  let logoY
  let logoWidth
  let logoHeight
  if (imageWidth === imageHeight) {
  // Square Logo - set width and height to LOGO_BOUND_HEIGHT
    logoX = centreOfDoc - (LOGO_BOUND_HEIGHT / 2)
    logoY = LOGO_BOUND_Y
    logoWidth = LOGO_BOUND_HEIGHT
    logoHeight = LOGO_BOUND_HEIGHT
  } else if (imageWidth > imageHeight) {
  // Landscape Logo - Set width to boundary, scale height relative to width, align to bottom of boundary
    logoX = centreOfDoc - (LOGO_BOUND_WIDTH / 2)
    logoWidth = LOGO_BOUND_WIDTH
    logoHeight = (imageHeight / imageWidth) * LOGO_BOUND_WIDTH
    logoY = LOGO_BOUND_Y + LOGO_BOUND_HEIGHT - logoHeight
  } else if (imageHeight > imageWidth) {
  // Portrait Logo - Set height to boundary, scale width relative to height, centre horizontally within boundary
    logoY = LOGO_BOUND_Y
    logoHeight = LOGO_BOUND_HEIGHT
    logoWidth = (imageWidth / imageHeight) * LOGO_BOUND_HEIGHT
    logoX = centreOfDoc - (logoWidth / 2)
  }
  return { logoX, logoY, logoWidth, logoHeight }
}

export const getCertificateLogoInfoFromSrc = async (originalLogoSrc) => {
  let imageURL; let imageWidth; let imageHeight
  const imageLookup = mime.lookup(originalLogoSrc)
  const imageType = mime.extension(imageLookup)
  const logoSrc = `${originalLogoSrc}?timestamp=${Date.now()}` // Cachebust to avoid CORS issues in Chromium based browsers
  if (imageType === 'svg') {
    const { url, width, height } = await getSvgImageURI(logoSrc)
    imageURL = url; imageWidth = width; imageHeight = height
  } else {
    const imgLogo = new Image()
    imgLogo.src = logoSrc
    imgLogo.setAttribute('crossOrigin', 'anonymous')
    const { width, height } = await getImageSize(logoSrc)
    imageURL = imgLogo; imageWidth = width; imageHeight = height
  }
  const { logoX, logoY, logoWidth, logoHeight } = calculateCustomImageSize(imageHeight, imageWidth)

  return { imageURL, imageType, logoX, logoY, logoWidth, logoHeight }
}

export const generateCertificate = async ({
  margin = 14, logoSrc: originalLogoSrc = '/images/logos/usecure.svg', courseTitle, dateOfCompletion, scorePercentage, presentedToName, ribbonColour = '#2d9bdb', saveDoc = true, logoInfo = null,
  locale
}) => {
  try {
    const doc = new JsPDF('p', 'mm', 'a4', true)
    // Add and set font for locale - applied when locale's character set is not natively supported by jsPDF
    const localeFontName = await addLocaleFontToJsPdfDoc(doc, locale)
    if (localeFontName) {
      doc.setFont(localeFontName, 'normal')
    }
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height
    const certificateBodyWidth = pageWidth - (margin * 2)
    const certificateBodyHeight = pageHeight - (margin * 2)
    let y = margin; let x = margin
    //  certificate body
    doc.setFillColor(230, 230, 230)
    doc.rect(x, y, certificateBodyWidth, certificateBodyHeight, 'F')
    y = 50
    try {
      if (!logoInfo || logoInfo === null) {
        logoInfo = await getCertificateLogoInfoFromSrc(originalLogoSrc)
      }
      const { imageURL, imageType, logoX, logoY, logoWidth, logoHeight } = logoInfo
      doc.addImage(imageURL, imageType, logoX, logoY, logoWidth, logoHeight, undefined, 'FAST')
    } catch (error) {
      // The process to add an image to a jsPDF document is fairly brittle due to it's reliance on XHR
      // If an error occurs the logo will omitted rather than failure the PDF generation process altogether
      // This is typically due to CORS error with the S3 bucket the image is stored
      console.error('generateCertificate - LOGO IMAGE ERROR', error)
      if (!ALLOW_CERT_IMAGE_FAIL) {
        throw error
      }
    }
    // top right ribbon
    doc.setFillColor(ribbonColour)
    doc.rect(certificateBodyWidth - 28, 0, 30, 50, 'F')
    doc.setFillColor(230, 230, 230)
    doc.triangle(certificateBodyWidth - 28, 51, certificateBodyWidth + 2, 51, certificateBodyWidth - 12, 40, 'F')
    // Certificate of completion text
    doc.setFontSize(35)
    y += 50
    x = pageWidth / 2
    doc.text(x, y, I18n.t('certificate', trOpt), 'center')
    // course title text
    doc.setFontSize(12)
    y += 10
    doc.text(x, y, I18n.t('ofCompletion', trOpt), 'center')
    const courseTitleLength = doc.getTextWidth(courseTitle)
    y += 15
    doc.setFontSize(35)
    let multiLineCourseTitle = null
    if (courseTitleLength > 75) {
      doc.setFontSize(20)
      if (courseTitleLength > 100) multiLineCourseTitle = doc.splitTextToSize(courseTitle, 180) // word wrap
    } else if (courseTitleLength < 75 && courseTitleLength > 50) {
      doc.setFontSize(30)
    }
    if (_isArray(multiLineCourseTitle) && multiLineCourseTitle.length > 1) {
      doc.text(x, y, multiLineCourseTitle, 'center')
      y += (multiLineCourseTitle.length - 1) * 10 // multiplying y by 10 depending on the number of rows
    } else {
      doc.text(x, y, courseTitle, 'center')
    }
    // Date of completion
    y += 15
    doc.setFontSize(20)
    doc.text(x, y, formatDate(dateOfCompletion), 'center')
    // Score Percentage
    // Only include this if there is a score - it's rare but impossible that course won't have one due to having no questions
    y += 25
    if (_isNumber(scorePercentage)) {
      doc.setFontSize(45)
      doc.text(x, y, `${scorePercentage}%`, 'center')
    }
    // Present to + name
    y += 15
    doc.setFontSize(20)
    doc.text(x, y, I18n.t('presentedTo', trOpt), 'center')
    doc.setFontSize(30)
    y += 15
    doc.text(x, y, presentedToName, 'center')
    // Issue date bottom right of certificate
    const dateOfIssue = formatDateTime(undefined, { includeSeconds: true })
    doc.setFontSize(12)
    doc.text(pageWidth - 20, pageHeight - 20, dateOfIssue, 'right')
    // File name
    const dateOfCompletionFormatted = sanitiseString(formatDate(dateOfCompletion), '-')
    const formattedCourseTitle = sanitiseString(courseTitle, '-')
    const fileName = `${I18n.t('fileName', trOpt)}-${formattedCourseTitle}-${dateOfCompletionFormatted}.pdf`
    // export
    return saveDoc ? doc.save(fileName) : { fileName, certificate: doc.output('blob') }
  } catch (error) {
    console.error('generateCertificate - ERROR', error)
    captureSentryError(error)
    message.error(I18n.t('downloadError', { ...trOpt, courseTitle: courseTitle }))
  }
}

export const generateAndZipAllCertificates = async ({ courses, logoSrc, ribbonColour, logoInfo, locale }) => {
  let localLearnerName = null
  if (!logoInfo) logoInfo = await getCertificateLogoInfoFromSrc(logoSrc)
  if (courses && !_isEmpty(courses)) {
    const zip = new JsZip()
    for (let i = 0; i < courses.length; i++) {
      const { name, finishDate, score, learnerName } = courses[i]
      if (localLearnerName !== learnerName) localLearnerName = learnerName
      try {
        const { fileName, certificate } = await generateCertificate({ logoSrc, ribbonColour, courseTitle: name, dateOfCompletion: finishDate, scorePercentage: score, presentedToName: learnerName, saveDoc: false, logoInfo, locale })
        zip.file(fileName, certificate)
      } catch (e) {
        message.error(I18n.t('zipDownloadError', trOpt))
      }
    }
    const content = await zip.generateAsync({ type: 'blob' })
    if (content) {
      const sanitizedLearnerName = sanitiseString(localLearnerName, '-')
      saveAsFromBlob(content, `${I18n.t('certificates', trOpt)}-${sanitizedLearnerName}`)
      message.success(I18n.t('successfullyDownloadedZip', trOpt))
    } else {
      message.error(I18n.t('zipDownloadError', trOpt))
    }
    return typeof content !== 'undefined'
  } else {
    message.log('No completed courses were provided - Error')
  }
}
