import React, { useCallback, useEffect } from 'react'
import { message } from 'antd'
import { useMutation, useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import MutationForm from '../MutationForm/MutationForm'
import { LoadingBlock } from '../common'
import { EXTEND_SESSION, GET_ME, UPDATE_ACCOUNT } from '../Queries/Users'
import { connect } from '../../hocs'
import { sessionLocale } from '../../state/selectors/session'
import { creators as sessionActions } from '../../state/actions/session'
import { SettingsContainer } from '../Settings/common'
import { refreshSessionToken, postSessionChannelMessage } from '../../helpers/session'
import { LANGUAGE_SELECT_OPTIONS, DEFAULT_LANGUAGE } from '../../constants/languages'
import IntercomHeader from '../IntercomHeader'

const trOpt = { scope: 'accountSettings.language' }

const ChangeLanguage = ({ setLocale, locale: currentLocale }) => {
  const { data, loading, refetch } = useQuery(GET_ME)
  const form = React.createRef()
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  const [extendSession] = useMutation(EXTEND_SESSION)
  const onSuccess = useCallback(async () => {
    // Refresh auth token so that the server can pull updated language from it
    await refreshSessionToken(extendSession, false)
    await refetch()
    postSessionChannelMessage('sessionRefresh')
    message.success(I18n.t('successMessage', trOpt))
  }, [refetch, extendSession])

  const onFailure = () => message.error(I18n.t('failureMessage', trOpt))
  const locale = _get(data, 'me.user.locale') || DEFAULT_LANGUAGE

  useEffect(() => {
    resetForm()
    if (locale && locale !== currentLocale) {
      setLocale(locale)
    }
  }, [resetForm, locale, setLocale, currentLocale])

  if (loading) return <LoadingBlock fullScreen loading={loading} />

  const fields = [
    {
      id: 'locale',
      label: I18n.t('common.preferredLanguage'),
      extra: <span>{I18n.t('thisWillChangeTheAppLanguage', trOpt)}</span>,
      defaultValue: locale,
      type: 'select',
      options: LANGUAGE_SELECT_OPTIONS,
      sortOptions: true
    }
  ]

  return (
    <>
      <IntercomHeader Size='h1' id='account-settings-change-language'>{I18n.t(`${trOpt.scope}.changeLanguage`, { defaults: [{ scope: 'learners.learnersView.changeLanguage' }] })}</IntercomHeader>
      <SettingsContainer>
        <MutationForm
          mutation={UPDATE_ACCOUNT}
          onSuccess={onSuccess}
          onFailure={onFailure}
          submitLabel={I18n.t('common.save')}
          ref={form}
          fields={fields}
        />
      </SettingsContainer>
    </>
  )
}

export default connect(
  state => ({ locale: sessionLocale(state) }),
  dispatch => ({
    setLocale: locale => dispatch(sessionActions.updateLocale(locale))
  })
)(ChangeLanguage)
