import React, { useMemo } from 'react'
import I18n from 'i18n-js'
import { Table } from 'antd'
import _isInteger from 'lodash/isInteger'

import { displayMoney } from '../../helpers'
import { ListHeader, ListHeaderPanel } from '../common'

const trOpt = { scope: 'uServiceInvoice' }

const UServiceInvoiceSummary = ({
  userAccountType,
  loading,
  currency,
  totalBreachDomains,
  totalBreachProCost,
  platformUsage,
  platformCost,
  totalPlatformCost,
  subTotal,
  tax,
  total
}) => {
  const hasCost = useMemo(() => _isInteger(total) && currency, [total, currency])
  const hasTax = useMemo(() => hasCost && _isInteger(tax), [tax, hasCost])

  const dataSource = useMemo(() => {
    const dataSource = [
      {
        item: I18n.t('platformUsage', trOpt),
        key: 'platformUsage',
        units: platformUsage,
        unitCost: hasCost && _isInteger(platformCost) ? displayMoney(platformCost, currency) : I18n.t('common.na'),
        total: hasCost && _isInteger(totalPlatformCost) ? displayMoney(totalPlatformCost, currency) : I18n.t('common.na')
      }
    ]
    if (_isInteger(totalBreachDomains)) {
      dataSource.push({
        item: I18n.t('common.uBreachPro'),
        key: 'uBreachPro',
        ...(userAccountType === 'msp' ? { units: totalBreachDomains } : {}),
        unitCost: '',
        total: hasCost && _isInteger(totalBreachProCost) ? displayMoney(totalBreachProCost, currency) : I18n.t('common.na')
      })
    }
    if (hasTax) {
      dataSource.push(
        {
          item: I18n.t('subtotal', trOpt),
          key: 'subTotal',
          units: null,
          unitCost: null,
          total: displayMoney(subTotal, currency),
          isTotal: true
        },
        {
          item: I18n.t('tax', trOpt),
          key: 'tax',
          units: null,
          unitCost: null,
          total: displayMoney(tax, currency),
          isTotal: true
        }
      )
    }
    if (hasCost) {
      dataSource.push(
        {
          item: I18n.t('total', trOpt),
          key: 'total',
          units: null,
          unitCost: null,
          total: _isInteger(total) ? displayMoney(total, currency) : I18n.t('common.na'),
          isTotal: true
        }
      )
    }
    return dataSource
  }, [totalBreachDomains, totalBreachProCost, platformCost, platformUsage, totalPlatformCost, subTotal, tax, total, currency, hasTax, hasCost, userAccountType])

  const columns = useMemo(() => {
    const columns = [
      {
        title: I18n.t('item', trOpt),
        dataIndex: 'item',
        key: 'item',
        render: (text, row) => row.isTotal ? <span style={{ fontWeight: '500' }}>{text}</span> : text
      },
      {
        title: I18n.t('units', trOpt),
        dataIndex: 'units',
        key: 'units'
      }
    ]

    if (hasCost) {
      columns.push(
        {
          title: I18n.t('unitCost', trOpt),
          dataIndex: 'unitCost',
          key: 'unitCost'
        },
        {
          title: I18n.t('total', trOpt),
          dataIndex: 'total',
          key: 'total',
          render: (text, row) => row.isTotal ? <span style={{ fontWeight: '500' }}>{text}</span> : text
        }
      )
    }
    return columns
  }, [hasCost])

  return (
    <>
      <ListHeader>
        <ListHeaderPanel>
          <h2>{I18n.t('summary', trOpt)}</h2>
        </ListHeaderPanel>
      </ListHeader>
      <Table
        columns={columns} dataSource={dataSource} loading={loading} pagination={false}
      />
    </>
  )
}

export default UServiceInvoiceSummary
