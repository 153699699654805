import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { setReportAccessSessionToken, removeReportAccessSessionToken } from '../helpers/session'

const useReportAccessToken = ({ tokenParamName = 'token' } = {}) => {
  const [reportAccessToken, setReportAccessToken] = useState(null)
  const { [tokenParamName]: reportAccessTokenParam } = useParams()
  useEffect(() => {
    setReportAccessSessionToken(reportAccessTokenParam) // used by reportAccessClient apollo client
    setReportAccessToken(reportAccessTokenParam) // Tells the component using the token is available
  }, [reportAccessTokenParam])
  useEffect(() => {
    // Clear reportAccessSessionToken on unmount - separated to avoid unexpected triggering from deps
    return () => removeReportAccessSessionToken()
  }, [])

  return { reportAccessToken }
}

export default useReportAccessToken
