
import React from 'react'
import { Icon } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import I18n from 'i18n-js'

import routes from '../../../constants/routes'
import { COURSE_SUBJECTS } from '../../../constants/courses'
import { ExcludeInactiveToggle } from '../../../components/common'
import IntercomHeader from '../../../components/IntercomHeader'
const trOpt = { scope: 'reports.uLearnReport' }

const ULearnHeaderStyled = styled.div`
height: 100%;
background-color: white;
padding: 40px;
display: flex;
flex-direction: column;
`
const ULearnHeader = ({
  learnerName,
  learnerEmail,
  learnerId,
  courseName,
  courseSubject,
  isGapAnalysis
}) => {
  return (
    <ULearnHeaderStyled style={{ backgroundColor: 'white', gridArea: 'topbar' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {!isGapAnalysis && (
            (learnerName || courseName) ? (
              <Link to={generatePath(routes.ULEARN_REPORT)} style={{ display: 'inline-block', marginBottom: '15px' }}>
                <Icon type='arrow-left' style={{ marginRight: '15px' }} />{I18n.t('backToULearnReport', trOpt)}
              </Link>)
              : <IntercomHeader id='uLearn-report-header' style={{ margin: 0 }}>{I18n.t('common.uLearn')} - {I18n.t('uLearnReport', trOpt)}</IntercomHeader>
          )}
          {isGapAnalysis && (
            <IntercomHeader id='uLearn-report-header' style={{ margin: 0 }}>{I18n.t('common.uLearn')} - {I18n.t('gapAnalysisReport', trOpt)}</IntercomHeader>
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {learnerName && (<h3 style={{ margin: 0 }}>{learnerName}</h3>)}
              {learnerEmail && (<p style={{ margin: 0 }}>{learnerEmail}</p>)}
              {!isGapAnalysis && courseName && (<h3 style={{ margin: 0 }}>{courseName}</h3>)}
              {!isGapAnalysis && courseSubject && COURSE_SUBJECTS[courseSubject] && (<p style={{ margin: 0 }}>{COURSE_SUBJECTS[courseSubject]}</p>)}
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          {!learnerId ? <ExcludeInactiveToggle /> : null}
        </div>
      </div>
    </ULearnHeaderStyled>
  )
}

export default ULearnHeader
