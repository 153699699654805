import React from 'react'
import { ApolloConsumer as ApolloConsumerReact } from 'react-apollo'
import { ApolloConsumer as ApolloConsumerHooks } from '@apollo/react-hooks'

export const withConsumer = ({ useHooks = false } = {}) => Component => {
  const ApolloConsumer = useHooks ? ApolloConsumerHooks : ApolloConsumerReact
  const WithConsumer = ({ forwardRef, ...props }) => (
    <ApolloConsumer>
      {client => (
        <Component ref={forwardRef} {...props} {...{ client }} />
      )}
    </ApolloConsumer>
  )

  return React.forwardRef((props, ref) => (
    <WithConsumer {...props} forwardRef={ref} />
  ))
}

export default withConsumer() // Exported with hooks disabled
