import { Modal, message } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { UNENROLL_LEARNERS_FROM_COURSES } from '../Queries/Courses'
import { GET_LEARNER_DATA } from '../Queries/Learners'
import { showErrors } from '../../helpers'

const trOpt = { scope: 'modals.unenrolFromCourseModal' }
const { confirm } = Modal

const UnenrolFromCourseModal = async (courseId, learnerId, client = authenticatedClient) => {
  confirm({
    title: I18n.t('title', trOpt),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        const { data: { unenrollLearnersFromCourses: success } } = await client.mutate({
          mutation: UNENROLL_LEARNERS_FROM_COURSES,
          variables: { courseIds: [courseId], learnerIds: [learnerId] },
          refetchQueries: [{
            query: GET_LEARNER_DATA,
            variables: { id: learnerId }
          }]
        })

        if (!success) {
          throw new Error(I18n.t('notEnrolledMessage', trOpt))
        }
        message.success(I18n.t('successMessage', trOpt))
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      }
    }
  })
}

export default UnenrolFromCourseModal
