import React from 'react'
import I18n from 'i18n-js'
import routes from '../../constants/routes'
import { withLogout } from '../../hocs'

const LogOut = ({ className, logOut }) => (
  <div {...{ className }} onClick={logOut}>
    {I18n.t('nav.navBar.logOut')}
  </div>
)

export default withLogout({ redirectRoute: routes.HOME })(LogOut)
