import React, { Component } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

import routes from '../../constants/routes'
import MutationForm from '../../components/MutationForm/MutationForm'
import { SignInContainer, SignInHeader, SignInHeaderLink } from '../../components/SignIn'
import { withLogin } from '../../hocs'
import { SIGN_UP } from '../../components/Queries/Users'

const trOpt = { scope: 'register' }

const Header = styled.h1`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`

class Register extends Component {
  constructor (props) {
    super(props)

    this.state = {
      success: false
    }
    this.fields = [
      { id: 'email', placeholder: I18n.t('common.fields.email'), type: 'email', required: true, autofill: false },
      { id: 'firstName', placeholder: I18n.t('common.fields.firstName'), required: true },
      { id: 'lastName', placeholder: I18n.t('common.fields.lastName'), required: true },
      { id: 'password', placeholder: I18n.t('common.fields.password'), type: 'password', required: true, autofill: false },
      { id: 'companyName', placeholder: I18n.t('companyName', trOpt), required: true }
    ]

    this.handleSuccess = this.handleSuccess.bind(this)
  }

  async handleSuccess () {
    this.setState({ success: true })
  }

  renderSuccess () {
    return (
      <div>
        <Header>{I18n.t('verifyYourAccount', trOpt)}</Header>
        <p>{I18n.t('allYouNeedToGetStarted', trOpt)}</p>
      </div>
    )
  }

  render () {
    return (
      <SignInContainer>
        <SignInHeader>{I18n.t('registerForUsecure', trOpt)}</SignInHeader>
        <SignInHeaderLink to={routes.HOME}>{I18n.t('backToSignIn', trOpt)}</SignInHeaderLink>
        {
          this.state.success
            ? this.renderSuccess()
            : (
              <MutationForm
                mutation={SIGN_UP}
                onSuccess={this.handleSuccess}
                onFailure={I18n.t('anErrorOccurredAttemptingToRegister', trOpt)}
                submitLabel={I18n.t('register', trOpt)}
                fields={this.fields}
              />
            )
        }
      </SignInContainer>
    )
  }
}

export default withLogin(Register)
