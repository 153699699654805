import styled from 'styled-components'
import { Modal } from 'antd'

export const DialogModalContent = styled.div`
  p {
    font-family: unset;
  }
  ul {
    padding-left: 0;
  }
`

export const OverflowModal = styled(Modal)`
  max-height: calc(100% - 80px);
  max-width: 1000px;
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-modal-content {
    height: 100%;
  }

  .ant-modal-body {
    height: ${({ headerHeight = 0 }) => headerHeight === 0 ? '100%' : `calc(100% - ${headerHeight}px)`};
    overflow: auto;
    padding-bottom: 4px;
  }
`

export const ModalFormScrollContainer = styled.div`
  max-height: calc(100vh - ${({ isTab }) => isTab ? 290 : 230}px);
  overflow: auto;
  padding-bottom: 20px;
  padding-right: 10px;
`
