import React from 'react'
import styled from 'styled-components'

import UpdateAPIKey from './UpdateApiKey'
import { GENERATE_API_KEY, GENERATE_CLIENT_API_KEY } from '../Queries/Companies'

const ApiSettingsScroller = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 75px;
`

const Api = ({ settings }) => (
  <ApiSettingsScroller>
    <UpdateAPIKey keyId='key' mutation={GENERATE_API_KEY} trOpt={{ scope: 'settings.api' }} {...{ settings }} />
    <UpdateAPIKey keyId='clientKey' mutation={GENERATE_CLIENT_API_KEY} trOpt={{ scope: 'settings.clientApiKey' }} {...{ settings }} />
  </ApiSettingsScroller>
)

export default Api
