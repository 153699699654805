
import React from 'react'
import styled from 'styled-components'
import IntercomHeader from '../IntercomHeader'

import { ExcludeInactiveToggle } from '.'

const GridPageHeaderStyled = styled.div`
  height: 100%;
  background-color: white;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

/**
 * Displays a header for a page that uses a grid layout. Used in report pages such as /breach
 * @param {string} title - Title to display in header
 * @param {string} [gridArea] - CSS grid area to display header in
 * @param {string} id - ID to assign to header
 * @param {boolean} [showExcludeInactiveToggle] - Whether to show the Exclude Inactive toggle
 * @param {Array} [additionalToggles] - Additional toggles to display in header
 */
const GridPageHeader = ({
  title,
  gridArea = 'topbar',
  id,
  showExcludeInactiveToggle = true,
  additionalToggles = []
}) => {
  return (
    <GridPageHeaderStyled style={{ backgroundColor: 'white', gridArea }}>
      <IntercomHeader Size='h2' id={id} style={{ margin: 0 }}>{title}</IntercomHeader>
      {(showExcludeInactiveToggle || additionalToggles.length) ? (
        <div style={{ display: 'flex', gap: '20px' }}>
          {showExcludeInactiveToggle && <div><ExcludeInactiveToggle /></div>}
          {additionalToggles.map((toggle, index) => (
            <div key={index}>{toggle}</div>
          ))}
        </div>
      ) : null}
    </GridPageHeaderStyled>
  )
}

export default GridPageHeader
