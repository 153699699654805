import { defaultDataIdFromObject } from 'apollo-cache-inmemory'
import _isNil from 'lodash/isNil'

export const dataIdFromObject = object => {
  switch (object.__typename) {
    // JG: Appolo's default caching strategy was causing performance report data to be cached
    // too aggressively and be reused across different reports. This is a workaround to prevent that.
    case 'TimeSeriesData':
    case 'CategoricalData':
      return `${object.key}|${defaultDataIdFromObject(object)}`
    case 'Simulation':
      return `${defaultDataIdFromObject(object)}|excludesInactiveUsers:${object.excludesInactiveUsers}`
    case 'Policy':
      return `${defaultDataIdFromObject(object)}|excludesInactiveUsers:${object.excludesInactiveUsers}${!_isNil(object.majorVersion) ? `|version:${object.majorVersion}.${object.minorVersion}` : ''}`
    default:
      return defaultDataIdFromObject(object) // fall back to default handling
  }
}
