import { message, Modal } from 'antd'
import I18n from 'i18n-js'

import { DELETE_SIMULATIONS } from '../Queries/uPhish'
import { UPHISH_SIMULATION_PERFORMANCE } from '../Queries/Reports'
import { GET_PROSPECT_RISK_REPORT } from '../Queries/Companies'
import authenticatedClient from '../../apollo-client/authenticatedClient'
import { showErrors } from '../../helpers'
import { isRiskReportAtStatus } from '../../helpers/company'

const { confirm } = Modal
const trOpt = { scope: 'modals.deleteSimulationConfirm' }

const deleteSimulationConfirm = async ({
  simulationIds = [], client = authenticatedClient, refreshSessionState = async () => {}, companyId, prospectStatus, prospectSimulation,
  excludeInactiveUsers = false
}) => {
  const count = simulationIds.length
  const refetchQueries = [{
    query: UPHISH_SIMULATION_PERFORMANCE,
    variables: { excludeInactiveUsers }
  }]
  if (isRiskReportAtStatus(prospectStatus, 'uphish_requested')) {
    refetchQueries.push({
      query: GET_PROSPECT_RISK_REPORT, variables: { companyId }
    })
  }
  let content
  if (prospectSimulation && simulationIds.includes(prospectSimulation)) {
    if (isRiskReportAtStatus(prospectStatus, 'uphish_complete')) {
      content = I18n.t('riskReportAttachedCompleteWarning', { ...trOpt, count: simulationIds.length })
    } else if (isRiskReportAtStatus(prospectStatus, 'uphish_ready')) {
      content = I18n.t('riskReportAttachedIncompleteWarning', { ...trOpt, count: simulationIds.length })
    }
  }
  confirm({
    title: I18n.t('title', { ...trOpt, count }),
    content,
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        await client.mutate({
          mutation: DELETE_SIMULATIONS,
          variables: { simulationIds },
          refetchQueries
        })
        message.success(I18n.t('successMessage', { ...trOpt, count }))
        await refreshSessionState()
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', { ...trOpt, count }))
      }
    }
  })
}

export default deleteSimulationConfirm
