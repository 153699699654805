import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { message, Alert } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isBoolean from 'lodash/isBoolean'
import _pick from 'lodash/pick'

import { OverflowModal, ModalFormScrollContainer } from './common'
import MutationForm from '../MutationForm/MutationForm'
import {
  CREATE_DISTRIBUTOR,
  CREATE_MSP,
  CREATE_TENANT,
  EDIT_DISTRIBUTOR,
  EDIT_MSP,
  EDIT_TENANT,
  GET_COMPANIES_BY_ACCOUNT_TYPE,
  GET_COMPANY_FOR_UPDATE
} from '../Queries/Companies'
import { invalidateCompaniesQueryCache, validateDomain } from '../../helpers'
import { companyManagedByUsecure } from '../../helpers/company'
import { ErrorAlerts, LoadingBlock } from '../common'
import { ACCOUNT_TYPES } from '../../constants/company'
import { COMMS_TYPE_OPTIONS } from '../../constants/users'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { CURRENCY_OPTIONS } from '../../constants/billing'
import { LANGUAGE_SELECT_OPTIONS, DEFAULT_LANGUAGE } from '../../constants/languages'
import { REGION_SELECT_OPTIONS } from '../../constants/regions'
import { DEFAULT_TIMEZONE } from '../../constants/timezone'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'modals.editCompanyModal' }
const addUserTrOpt = { scope: 'modals.addUserModal' }
const LOCALES_REQUIRED = window.__USECURE_CONFIG__.REACT_APP_COMPANY_LOCALES_REQUIRED === 'true'
const USERVICE_SHOW_UBREACH_PRO = window.__USECURE_CONFIG__.REACT_APP_USERVICE_SHOW_UBREACH_PRO === 'true' || false
const UBREACH_PRO_TOGGLE_USECURE_ADMIN_ONLY = window.__USECURE_CONFIG__.REACT_APP_UBREACH_PRO_TOGGLE_USECURE_ADMIN_ONLY === 'true' || false
const CREATE_MUTATIONS = {
  tenant: CREATE_TENANT,
  msp: CREATE_MSP,
  distributor: CREATE_DISTRIBUTOR
}
const EDIT_MUTATIONS = {
  tenant: EDIT_TENANT,
  msp: EDIT_MSP,
  distributor: EDIT_DISTRIBUTOR
}

const getFeatureFlagValue = ({ company, setting, settingsPath, defaultTenantSettings, isTenant, isUserMSP }) => {
  let value = _get(company, `${settingsPath}${setting}`)
  if (!_isBoolean(value) && isTenant && isUserMSP) {
    value = defaultTenantSettings[setting]
  }
  value = _isBoolean(value) ? value : true

  return value
}

const CompanyMutationTitle = styled.h1`
  transition: opacity 0.3s;
`
const CompanyMutationForm = styled(MutationForm)`
  transition: opacity 0.3s;

  .ant-tabs {
    position: unset;
  }
`

export const AdminUserExplainer = React.forwardRef(({ visible }, ref) => (
  visible ? (
    <Alert
      type='info' showIcon banner
      message={I18n.t('adminUserExplainer', trOpt)}
    />
  ) : null
))

const isDistiBillingEnabled = values => values.billingType === 'manual'
const isDistiInvoiceAddressVisible = values => isDistiBillingEnabled(values) && values.showAddressOnInvoice === true

export const EditCompanyModalForm = React.forwardRef(({
  company,
  accountType: accountTypeProp,
  forceAccountType = false,
  userAccountType,
  defaultTenantSettings = {},
  loading = false,
  closeModal = () => {},
  refetchQueries = [],
  parentCompanyOptions = [],
  userCompanyId,
  userEditExternalId,
  showRiskScoreOption,
  companyLocale,
  companyContentLocales,
  companyTimezone,
  onSuccess: onSuccessProp,
  failureMessage,
  submitLabel,
  showTitle = true,
  upgrade = false,
  mutation,
  footer,
  submitIcon,
  footerProps,
  userUBreachProEnabled
}, ref) => {
  const { hasAllSessionPermissions, hasAllSessionPermissionsOriginally } = useHasSessionPermission()
  const form = useRef(null)
  const { accountType, accountTypeName } = useMemo(() => {
    const accountType = (company && !forceAccountType ? company.accountType : null) || accountTypeProp
    return {
      accountType,
      accountTypeName: ACCOUNT_TYPES[accountType] || accountType
    }
  }, [accountTypeProp, company, forceAccountType])
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  useImperativeHandle(ref, () => ({
    reset: resetForm
  }), [resetForm])
  useEffect(() => {
    if (company || !loading) {
      resetForm()
    }
  }, [company, loading, resetForm])

  // Check if the parent company has uBreach Pro enabled. If the company doesn't exist, use the company setting from the current user
  const parentUBreachProEnabled = useMemo(() => !!company?.parentCompany?.uBreachProEnabled || (!company && userUBreachProEnabled), [company, userUBreachProEnabled])

  const { tabs, fields } = useMemo(() => {
    const hasSuperPermission = hasAllSessionPermissions(company ? [permissions.COMPANY_SUPER_UPDATE] : [permissions.COMPANY_SUPER_CREATE])
    const hadSuperPermission = hasAllSessionPermissionsOriginally(company ? [permissions.COMPANY_SUPER_UPDATE] : [permissions.COMPANY_SUPER_CREATE])

    const isTenant = accountType === 'tenant'
    const isMSP = accountType === 'msp'
    const isUserMSP = userAccountType === 'msp' && !hasSuperPermission
    const isDist = accountType === 'distributor'
    const includeParentCompanyField = parentCompanyOptions.length > 0

    const uBreachProToggleAllowed = UBREACH_PRO_TOGGLE_USECURE_ADMIN_ONLY ? (hasSuperPermission || hadSuperPermission) : true

    const domainLockSpec = {
      id: 'domainLock',
      label: I18n.t('domainLock', trOpt),
      type: 'switch',
      defaultValue: _get(company, 'plan.domainLock', true)
    }
    const domainSpec = {
      id: 'domain',
      label: I18n.t('domain', trOpt),
      defaultValue: _get(company, 'domain', null),
      validate: (field, value, errors) => {
        if (!validateDomain(value)) {
          errors.push(I18n.t('common.invalidDomainError'))
        }
      }
    }

    // Details Tab
    const tabs = [{
      id: 'details',
      title: I18n.t('common.details')
    }]
    const fields = [{
      id: 'name',
      label: I18n.t('companyName', trOpt),
      placeholder: I18n.t('companyName', trOpt),
      required: true,
      defaultValue: _get(company, 'name', null),
      tab: 'details'
    }, {
      id: 'locale',
      label: I18n.t('common.preferredLanguage'),
      defaultValue: company ? (_get(company, 'locale') || DEFAULT_LANGUAGE) : (defaultTenantSettings.locale || companyLocale || DEFAULT_LANGUAGE),
      type: 'select',
      options: LANGUAGE_SELECT_OPTIONS,
      sortOptions: true
    }, {
      id: 'contentLocales',
      label: I18n.t('settings.language.contentLocales'),
      type: 'multiSelect',
      options: LANGUAGE_SELECT_OPTIONS,
      sortOptions: true,
      // Use MSP default customer setting by default on new MSP customers
      // Otherwise default to the current user's company setting if present
      defaultValue: (company ? company.contentLocales : (defaultTenantSettings.contentLocales || companyContentLocales)) || [],
      placeholder: I18n.t('common.fields.languagesPlaceHolder'),
      extra: I18n.t('contentLocalesExtra', trOpt),
      required: LOCALES_REQUIRED,
      requiredError: I18n.t('common.fields.languagesRequiredError')
    }, {
      id: 'timezone',
      label: I18n.t('common.timezone'),
      defaultValue: company ? (_get(company, 'timezone') || companyTimezone || DEFAULT_TIMEZONE) : (defaultTenantSettings.timezone || companyTimezone || DEFAULT_TIMEZONE),
      type: 'timezone',
      required: true
    }]
    if (isMSP || isDist) {
      fields.push({
        id: 'platformAccess',
        label: I18n.t('enableUsecureFeatures', trOpt),
        type: 'switch',
        defaultValue: company ? _get(company, 'settings.platformAccess') === true : true,
        tab: 'details'
      })
    }
    if (includeParentCompanyField) {
      fields.push({
        id: 'parentCompanyId',
        label: isMSP ? I18n.t('common.accountTypes.distributor') : I18n.t('common.accountTypes.msp'),
        type: 'select',
        options: parentCompanyOptions,
        defaultValue: (company ? company.parentCompanyId : null) || (hasSuperPermission ? 'usecure' : userCompanyId),
        mutateValue: value => value === 'usecure' ? null : value,
        extra: <span>{I18n.t('fieldOnlyVisibleToUsecureAdmins', trOpt)}</span>,
        tab: 'details'
      })
    }

    if ((isMSP || isTenant) && (_get(company, 'editExternalId') || userEditExternalId)) {
      fields.push({
        id: 'externalId',
        label: I18n.t('uService.externalId'),
        defaultValue: _get(company, 'externalId', null),
        tab: 'details'
      })
    }
    if (!isTenant) {
      fields.push({
        ...domainLockSpec,
        tab: 'details'
      }, {
        ...domainSpec,
        required: ({ domainLock }) => domainLock === true,
        visible: ({ domainLock }) => domainLock === true,
        tab: 'details'
      })
    }
    // Suspend account fields when updating tenant or MSP
    if (!upgrade && company && (isMSP || isTenant)) {
      fields.push({
        id: 'suspended',
        label: I18n.t('suspended', trOpt),
        type: 'switch',
        defaultValue: _get(company, 'suspended', false),
        extra: <span>{I18n.t('suspendedExtra', trOpt)}</span>,
        tab: 'details'
      })
    }

    // plan tab (tenant only)
    if (isTenant) {
      tabs.push({
        id: 'plan',
        title: I18n.t('planTab', trOpt)
      })
      if (!upgrade) {
        fields.push({
          id: 'freeTrial',
          label: I18n.t('freeTrial', trOpt),
          type: 'switch',
          // This is mostly redundant now as it's only shown to free trials
          // But I'm keeping it in just to cover our back - KJ
          defaultValue: _get(company, 'plan.planName') === 'freeTrial',
          // Stop the ability to convert paid accounts to free trials - KJ
          visible: _get(company, 'plan.planName') !== 'premium',
          tab: 'plan'
        })
      }
      fields.push({
        ...domainLockSpec,
        visible: ({ freeTrial }) => freeTrial !== true,
        tab: 'plan'
      }, {
        ...domainSpec,
        required: ({ freeTrial, domainLock }) => freeTrial === true || domainLock === true,
        visible: ({ freeTrial, domainLock }) => freeTrial === true || domainLock === true,
        tab: 'plan'
      })
      const learnerLimitSpec = {
        id: 'learnerLimit',
        label: I18n.t('learnerLimit', trOpt),
        type: 'number',
        visible: values => values.freeTrial === false,
        tab: 'plan'
      }
      if (upgrade && company) {
        // Upgrading a prospect to a paid plan
        learnerLimitSpec.visible = true
        // Upgrading a direct prospect to a paid plan
        if (!company.parentCompanyId) {
          learnerLimitSpec.required = true
        }
      } else if (!upgrade && company) {
        // Updating a customer
        learnerLimitSpec.defaultValue = _get(company, 'settings.learnerLimit')
        // Updating a direct customer
        if (!company.parentCompanyId) {
          learnerLimitSpec.required = values => values.freeTrial === false
        }
      } else if (!upgrade && !company && hasSuperPermission) {
        // Creating a direct customer
        if (includeParentCompanyField) {
          learnerLimitSpec.required = values => companyManagedByUsecure(values) && values.freeTrial === false
        } else {
          learnerLimitSpec.required = values => values.freeTrial === false
        }
      }
      fields.push(learnerLimitSpec)

      if (!isUserMSP) {
        fields.push({
          id: 'expiryDate',
          label: I18n.t('expiryDate', trOpt),
          type: 'date',
          defaultValue: _get(company, 'plan.expiryDate', null),
          visible: values => values.freeTrial === false,
          allowClear: true,
          min: moment().subtract(1, 'days'),
          mutateValue: value => value ? moment(value).set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD HH:mm:ss') : null,
          showNowButton: false,
          tab: 'plan'
        })
      }
    }

    // Billing Tab - Tenants under Distributor MSPs
    // Only available when updating a company as usecure admin or a usecure admin in disguise at the MSP level
    if (isTenant && ((hasSuperPermission || hadSuperPermission) && company && company.parentCompany && company.parentCompany.parentCompany && (company.parentCompany.parentCompany.name !== 'usecure'))) {
      tabs.push({
        id: 'billing',
        title: I18n.t('billingTab', trOpt),
        visible: true
      })

      fields.push({
        id: 'exemptFromDistributorBilling',
        label: I18n.t('exemptFromDistributorBilling', trOpt),
        type: 'switch',
        defaultValue: Boolean(_get(company, 'billingSettings.exemptFromDistributorBilling')),
        tab: 'billing'
      }, {
        id: 'billableUsersToggle',
        label: I18n.t('billableUsersToggle', trOpt),
        type: 'switch',
        defaultValue: Boolean(_get(company, 'billingSettings.billableUsers')),
        tab: 'billing',
        visible: values => values.exemptFromDistributorBilling === false
      }, {
        id: 'billableUsers',
        label: I18n.t('billableUsers', trOpt),
        type: 'number',
        min: 1,
        defaultValue: _get(company, 'billingSettings.billableUsers') || 0,
        visible: values => values.billableUsersToggle === true && values.exemptFromDistributorBilling === false,
        required: values => values.billableUsersToggle === true,
        tab: 'billing'
      })
    }

    // Billing Tab - Distributors and MSPs
    // Only visible to usecure admins as no one else should be able to update billing
    if ((isDist || isMSP) && hasSuperPermission) {
      tabs.push({
        id: 'billing',
        title: I18n.t('billingTab', trOpt),
        visible: true
      })

      // Distributor MSPs - Exempt from Billing toggle
      if (isMSP) {
        fields.push({
          id: 'exemptFromDistributorBilling',
          label: I18n.t('exemptFromDistributorBilling', trOpt),
          type: 'switch',
          defaultValue: _get(company, 'billingSettings.exemptFromDistributorBilling'),
          visible: includeParentCompanyField ? values => !companyManagedByUsecure(values) : company?.parentCompanyId !== null,
          tab: 'billing'
        })
      }

      // Distributors and Direct MSPs - Billing Type
      const defaultBillingType = isDist ? 'exempt' : 'auto'
      const billingTypeSpec = {
        id: 'billingType',
        type: 'select',
        label: isDist ? I18n.t('billingCycles', trOpt) : I18n.t('uService.billingType'),
        options: [],
        defaultValue: company ? company.billingType : defaultBillingType,
        tab: 'billing',
        visible: includeParentCompanyField ? companyManagedByUsecure : (isDist || company?.parentCompanyId === null),
        extra: isDist ? I18n.t('billingCyclesExtra', trOpt) : null
      }
      if (isDist) {
        billingTypeSpec.options = [
          { value: 'manual', label: I18n.t('common.enabled') },
          { value: 'exempt', label: I18n.t('common.disabled') }
        ]
      } else {
        billingTypeSpec.options = [
          { value: 'auto', label: I18n.t('automatic', trOpt) },
          { value: 'exempt', label: I18n.t('exemptFromBilling', trOpt) }
        ]
        if (includeParentCompanyField) {
          // Mutate value only needed when creating a company and the parent company field is available
          billingTypeSpec.mutateValue = (value, values) => companyManagedByUsecure(values) ? value : null
        }
      }
      fields.push(billingTypeSpec)

      if (isDist) {
        // Distributor
        fields.push({
          id: 'billingStartDate',
          label: I18n.t('uService.billingStartDate'),
          type: 'date',
          defaultValue: _get(company, 'billingSettings.startDate', null),
          visible: isDistiBillingEnabled,
          required: isDistiBillingEnabled,
          allowClear: true,
          showNowButton: false,
          tab: 'billing'
        }, {
          id: 'billingEmail',
          type: 'email',
          label: I18n.t('billingEmail', trOpt),
          defaultValue: _get(company, 'billingSettings.billingEmail', null),
          visible: isDistiBillingEnabled,
          required: isDistiBillingEnabled,
          tab: 'billing'
        }, {
          id: 'costPerLearner',
          label: I18n.t('costPerLearner', trOpt),
          type: 'number',
          defaultValue: _get(company, 'billingSettings.costPerLearner', 55),
          visible: isDistiBillingEnabled,
          required: isDistiBillingEnabled,
          tab: 'billing'
        }, {
          id: 'currency',
          type: 'select',
          label: I18n.t('uService.currency'),
          options: CURRENCY_OPTIONS,
          defaultValue: _get(company, 'billingSettings.currency', 'gbp'),
          visible: values => values.billingType !== 'exempt',
          tab: 'billing'
        })
      } else if (isMSP && (!company || (company && !company.parentCompany))) {
        // Direct MSP
        const hasPrevBilling = Boolean(company && _get(company, 'billingSettings.stripeCustomerId'))
        fields.push({
          id: 'billingEmail',
          type: 'email',
          label: I18n.t('billingEmail', trOpt),
          defaultValue: _get(company, 'billingSettings.billingEmail', null),
          required: values => (!includeParentCompanyField || companyManagedByUsecure(values)) && values.billingType === 'auto',
          visible: values => (!includeParentCompanyField || companyManagedByUsecure(values)) && values.billingType !== 'exempt',
          tab: 'billing'
        }, {
          id: 'currency',
          type: 'select',
          label: I18n.t('uService.currency'),
          options: CURRENCY_OPTIONS,
          defaultValue: _get(company, 'billingSettings.currency', 'gbp'),
          visible: values => (!includeParentCompanyField || companyManagedByUsecure(values)) && values.billingType !== 'exempt',
          disabled: hasPrevBilling,
          mutateValue:
            includeParentCompanyField
              ? (value, values) => companyManagedByUsecure(values) ? value : null
              : null,
          extra: I18n.t(`currencyExtra.${accountType}`, trOpt),
          tab: 'billing'
        })
      }

      fields.push({
        id: 'logDailyUsage',
        type: 'switch',
        label: I18n.t('logDailyUsage', trOpt),
        defaultValue: _get(company, 'settings.logDailyUsage', false),
        visible: values => (includeParentCompanyField ? companyManagedByUsecure(values) : (isDist || company?.parentCompanyId === null)) && values.billingType !== 'exempt',
        mutateValue: (value, values) => (isDist || companyManagedByUsecure(values)) ? value : false,
        tab: 'billing'
      })

      // Invoice Company Name and Address Fields for Distributor Invoice
      // This should probably be on a separate tab given its length
      if (isDist) {
        fields.push({
          id: 'showAddressOnInvoice',
          type: 'switch',
          label: I18n.t('showAddressOnInvoice', trOpt),
          defaultValue: _get(company, 'billingSettings.showAddressOnInvoice', false) === true,
          visible: isDistiBillingEnabled,
          tab: 'billing'
        }, {
          id: 'invoiceCompanyName',
          type: 'text',
          label: I18n.t('invoiceCompanyName', trOpt),
          defaultValue: _get(company, 'billingSettings.invoiceCompanyName', null),
          visible: isDistiInvoiceAddressVisible,
          tab: 'billing'
        }, {
          id: 'addressLine1',
          type: 'text',
          label: I18n.t('addressLine1', trOpt),
          defaultValue: _get(company, 'billingSettings.addressLine1', null),
          visible: isDistiInvoiceAddressVisible,
          tab: 'billing'
        }, {
          id: 'addressLine2',
          type: 'text',
          label: I18n.t('addressLine2', trOpt),
          defaultValue: _get(company, 'billingSettings.addressLine2', null),
          visible: isDistiInvoiceAddressVisible,
          tab: 'billing'
        }, {
          id: 'addressLine3',
          type: 'text',
          label: I18n.t('addressLine3', trOpt),
          defaultValue: _get(company, 'billingSettings.addressLine3', null),
          visible: isDistiInvoiceAddressVisible,
          tab: 'billing'
        }, {
          id: 'addressLine4',
          type: 'text',
          label: I18n.t('addressLine4', trOpt),
          defaultValue: _get(company, 'billingSettings.addressLine4', null),
          visible: isDistiInvoiceAddressVisible,
          tab: 'billing'
        }, {
          id: 'addressPostcode',
          type: 'text',
          label: I18n.t('addressPostcode', trOpt),
          defaultValue: _get(company, 'billingSettings.addressPostcode', null),
          visible: isDistiInvoiceAddressVisible,
          tab: 'billing'
        }, {
          id: 'addressCountry',
          type: 'text',
          label: I18n.t('addressCountry', trOpt),
          defaultValue: _get(company, 'billingSettings.addressCountry', null),
          visible: isDistiInvoiceAddressVisible,
          tab: 'billing'
        })
      }
    }

    // UBreach Pro
    const uBreachProSpec = {
      id: 'uBreachPro',
      type: 'switch',
      defaultValue: _get(company, 'settings.uBreachPro',
        (isTenant && isUserMSP ? defaultTenantSettings.uBreachPro : null) || false),
      visible: ({ freeTrial }) => !freeTrial && uBreachProToggleAllowed
    }
    // Ubreach Pro tab for Distributors and MSPs
    if (uBreachProToggleAllowed && USERVICE_SHOW_UBREACH_PRO && (isDist || isMSP)) {
      tabs.push({
        id: 'uBreachPro',
        title: I18n.t('common.uBreachPro'),
        // Only show tab for distis, direct MSPs and MSPs where disti has enabled uBreach Pro
        visible: includeParentCompanyField ? companyManagedByUsecure : (isDist || company?.parentCompanyId === null || parentUBreachProEnabled)
      })

      uBreachProSpec.label = I18n.t('common.enabled')
      uBreachProSpec.tab = 'uBreachPro'

      fields.push(uBreachProSpec)
    }

    // Admin User Tab - Option to create an admin user when alongside a new company
    if (!company && userAccountType !== 'msp') {
      tabs.push({
        id: 'admin',
        title: I18n.t('adminUserTab', trOpt)
      })
      const adminUserProps = {
        required: ({ firstName, lastName, email }) => Boolean(firstName || lastName || email),
        tab: 'admin'
      }
      // Admin user fields
      fields.push({
        id: 'adminUserExplainer',
        type: 'custom',
        component: AdminUserExplainer,
        tab: 'admin'
      }, {
        id: 'firstName',
        label: I18n.t('common.fields.firstName'),
        ...adminUserProps
      }, {
        id: 'lastName',
        label: I18n.t('common.fields.lastName'),
        ...adminUserProps
      }, {
        id: 'email',
        label: I18n.t('common.fields.email'),
        type: 'email',
        ...adminUserProps
      })
      if (isMSP) {
        fields.push({
          id: 'commsType',
          label: I18n.t('commsType', addUserTrOpt),
          type: 'select',
          options: COMMS_TYPE_OPTIONS,
          ...adminUserProps
        })
      }
    }
    // Features and Emails tabs
    const settingsPathArr = ['settings']
    if (isMSP) {
      settingsPathArr.push('defaultTenantSettings')
    }
    const settingsPath = `${settingsPathArr.join('.')}.`
    if (isMSP || isTenant) {
      tabs.push({
        id: 'features',
        title: isMSP ? I18n.t('featuresMSPTab', trOpt) : I18n.t('settings.settingsSidebar.features')
      })
      fields.push({
        id: 'uLearn',
        type: 'switch',
        label: I18n.t('common.uLearn'),
        defaultValue: getFeatureFlagValue({ company, setting: 'uLearn', settingsPath, defaultTenantSettings, isTenant, isUserMSP }),
        tab: 'features'
      }, {
        id: 'uPhish',
        type: 'switch',
        label: I18n.t('common.uPhish'),
        defaultValue: getFeatureFlagValue({ company, setting: 'uPhish', settingsPath, defaultTenantSettings, isTenant, isUserMSP }),
        tab: 'features'
      }, {
        id: 'uBreachEnabled',
        type: 'switch',
        label: I18n.t('common.uBreach'),
        defaultValue: getFeatureFlagValue({ company, setting: 'uBreachEnabled', settingsPath, defaultTenantSettings, isTenant, isUserMSP }),
        tab: 'features'
      }, {
        id: 'uPolicy',
        type: 'switch',
        label: I18n.t('common.uPolicy'),
        defaultValue: getFeatureFlagValue({ company, setting: 'uPolicy', settingsPath, defaultTenantSettings, isTenant, isUserMSP }),
        tab: 'features'
      })

      fields.push({
        id: 'enableRiskScore',
        type: 'switch',
        label: I18n.t('settings.riskScore.enableRiskScore'),
        defaultValue: _get(company, `${settingsPath}enableRiskScore`,
          (isTenant && isUserMSP ? defaultTenantSettings.enableRiskScore : null) || false),
        visible: showRiskScoreOption,
        tab: 'features'
      })
    }

    // uBreach V2
    if (USERVICE_SHOW_UBREACH_PRO && isTenant) {
      uBreachProSpec.label = I18n.t('common.uBreachPro')
      uBreachProSpec.tab = 'features'
      // Only show uBreachPro toggle for direct tenants or if parent has uBreachPro enabled (and hide for free trials)
      uBreachProSpec.visible = values => uBreachProToggleAllowed && !values.freeTrial && (
        includeParentCompanyField
          ? companyManagedByUsecure
          : (company?.parentCompanyId === null || parentUBreachProEnabled)
      )
      uBreachProSpec.disabled = values => values.uBreachEnabled === false

      // Ensure uBreachPro is disabled for free trials and when uBreach is disabled
      uBreachProSpec.mutateValue = (value, values) => values.uBreachEnabled === false || values.freeTrial ? false : value

      fields.push(
        uBreachProSpec,
        {
          id: 'breachDomainsLimit',
          label: I18n.t('breachDomainLimit', trOpt),
          type: 'number',
          min: 0,
          defaultValue: company ? _get(company, 'settings.uBreachProSettings.breachDomainsLimit', null) : null,
          required: false,
          // Only show breachDomainsLimit for non-free trial direct tenants
          visible: values => values.uBreachPro === true && !values.freeTrial && uBreachProSpec.visible && (
            includeParentCompanyField
              ? companyManagedByUsecure(values)
              : company?.parentCompanyId === null
          ),
          tab: 'features',
          disabled: values => values.uBreachEnabled === false
        }, {
          id: 'breachDomains',
          label: I18n.t('breachDomains', trOpt),
          type: 'textTags',
          defaultValue: _get(company, 'settings.uBreachProSettings.breachDomains', []),
          validateTag: value => {
            if (!validateDomain(value)) {
              return I18n.t('common.invalidDomainError')
            }
          },
          required: false,
          // Only show breachDomains for msp-managed, non-free trial, tenants
          visible: values => (
            values.uBreachPro === true && uBreachProSpec.visible && !values.freeTrial && (
              includeParentCompanyField
                ? !companyManagedByUsecure(values)
                : company?.parentCompanyId !== null
            )
          ),
          tab: 'features',
          disabled: values => values.uBreachEnabled === false
        }
      )
    }
    // Region field for region based MSP reporting only available to superAdmin role
    if (hasSuperPermission && (accountType === 'msp' || (!company && accountTypeName === 'MSP'))) {
      fields.push({
        id: 'region',
        label: I18n.t('region', trOpt),
        type: 'select',
        tab: 'details',
        options: REGION_SELECT_OPTIONS,
        defaultValue: _get(company, 'region')
      })
    }
    return { tabs, fields }
  }, [
    company, accountType, hasAllSessionPermissions, hasAllSessionPermissionsOriginally, userAccountType, defaultTenantSettings, parentCompanyOptions, userCompanyId, userEditExternalId,
    showRiskScoreOption, companyLocale, companyContentLocales, upgrade, companyTimezone, parentUBreachProEnabled, accountTypeName
  ])

  const onSuccess = useCallback(() => {
    if (onSuccessProp) {
      onSuccessProp()
      return
    }

    message.success(I18n.t(company ? 'updateSuccessMessage' : 'createSuccessMessage', { ...trOpt, accountType: accountTypeName }))
    closeModal()
  }, [closeModal, company, accountTypeName, onSuccessProp])

  const mutateValues = useCallback(values => {
    const isTenant = accountType === 'tenant'
    const isMSP = accountType === 'msp'
    const isDist = accountType === 'distributor'

    const hasSuperPermission = hasAllSessionPermissions(company ? [permissions.COMPANY_SUPER_UPDATE] : [permissions.COMPANY_SUPER_CREATE])
    const hadSuperPermission = hasAllSessionPermissionsOriginally(company ? [permissions.COMPANY_SUPER_UPDATE] : [permissions.COMPANY_SUPER_CREATE])

    const { region, name, parentCompanyId, domain, suspended, locale, contentLocales, timezone, ...rest } = values || {}
    const mutatedValues = { region, name, parentCompanyId, domain, suspended, locale, contentLocales, timezone }

    // Nullify contentLocales on create unless it's populated - this will trigger the server side code to populate it from MSP Default Customer Settings when creating a customer
    if (!company && !(contentLocales && contentLocales.length > 0)) {
      mutatedValues.contentLocales = null
    }

    // Admin user when creating a company
    if (!company && userAccountType !== 'msp') {
      const userAdminDetails = _pick(rest, ['firstName', 'lastName', 'email'])
      const isAdminUserEmpty = !Object.values(userAdminDetails).some(value => (value !== null && value !== ''))
      if (!isAdminUserEmpty) {
        mutatedValues.adminUser = { ...userAdminDetails, commsType: rest.commsType }
      }
    }

    // Company settings fields
    if (isMSP || isDist) {
      mutatedValues.settings = _pick(rest, ['platformAccess', 'domainLock', 'logDailyUsage', 'uBreachPro', 'breachDomainsLimit'])
    } else if (isTenant) {
      if (upgrade) {
        rest.freeTrial = false
      }
      if (rest.freeTrial === true) {
        delete rest.learnerLimit
      }
      mutatedValues.settings = _pick(rest, [
        'enableRiskScore',
        'freeTrial', 'domainLock', 'learnerLimit',
        'uBreachEnabled', 'uLearn', 'uPhish', 'uPolicy', 'uBreachPro', 'breachDomainsLimit', 'breachDomains',
        'expiryDate'
      ])
    }

    // Billing Fields - Tenant under Distributor MSP
    if ((hasSuperPermission || hadSuperPermission) && company && company.parentCompany && company.parentCompany.parentCompany && (company.parentCompany.parentCompany.name !== 'usecure')) {
      const { exemptFromDistributorBilling, billableUsersToggle, billableUsers } = rest
      mutatedValues.billingSettings = {
        exemptFromDistributorBilling,
        billableUsers: billableUsersToggle ? billableUsers : null
      }
    }

    // Billing fields - Distributor and MSP
    if ((isDist || isMSP) && hasSuperPermission) {
      const { billingType } = rest
      mutatedValues.billingType = billingType
      if (billingType !== 'exempt') {
        if (isMSP && (company ? !company.parentCompanyId : !parentCompanyId)) {
          // Set billing setting on direct MSP
          const { billingEmail, currency, taxRate } = rest
          mutatedValues.billingSettings = {
            billingEmail,
            currency: billingType === 'auto' ? currency : undefined,
            taxRate: billingType === 'auto' && currency === 'gbp' ? taxRate : null
          }
        } else if (isDist) {
          mutatedValues.billingSettings = {
            startDate: rest.billingStartDate,
            ..._pick(rest, [
              'currency', 'costPerLearner', 'billingEmail',
              'invoiceCompanyName', 'showAddressOnInvoice',
              'addressLine1', 'addressLine2', 'addressLine3', 'addressLine4',
              'addressPostcode', 'addressCountry'
            ])
          }
        }
      }

      if (isMSP && company ? company.parentCompanyId : parentCompanyId) {
        // Apply Exempt from Billing setting to Distributor MSP
        const { exemptFromDistributorBilling } = rest

        mutatedValues.billingSettings = {
          exemptFromDistributorBilling
        }
      }
    }

    // MSP Default Tenant Settings
    if (isMSP) {
      mutatedValues.defaultTenantSettings = _pick(rest, [
        'enableRiskScore',
        'uBreachEnabled', 'uLearn', 'uPhish', 'uPolicy'
      ])
    }

    // External Id field for distributor's using the public API
    if (isMSP || isTenant) {
      mutatedValues.externalId = rest.externalId
    }

    return mutatedValues
  }, [accountType, company, userAccountType, upgrade, hasAllSessionPermissions, hasAllSessionPermissionsOriginally])
  const mutationProps = useMemo(() => {
    const mutationProps = {}
    if (mutation) {
      mutationProps.mutation = mutation
    } else {
      mutationProps.mutation = company ? EDIT_MUTATIONS[accountType] : CREATE_MUTATIONS[accountType]
    }

    if (company) {
      mutationProps.variables = { companyId: company.id }
    }

    return mutationProps
  }, [company, accountType, mutation])

  let title
  if (company && company.name) {
    title = I18n.t('updateTitleName', { ...trOpt, accountType: accountTypeName, name: company.name })
  } else if (company) {
    title = I18n.t('updateTitle', { ...trOpt, accountType: accountTypeName })
  } else {
    title = I18n.t('createTitle', { ...trOpt, accountType: accountTypeName })
  }

  return (
    <>
      {showTitle && <CompanyMutationTitle id={`uService-${company ? 'update' : 'create'}-company-modal-header`} style={{ opacity: loading ? 0 : 1 }}>{title}</CompanyMutationTitle>}
      <LoadingBlock fullScreen={false} loading={loading} />
      <CompanyMutationForm
        {...mutationProps}
        style={{ opacity: loading ? 0 : 1 }}
        failureMessage={failureMessage || I18n.t(company ? 'updateErrorMessage' : 'createErrorMessage', { ...trOpt, accountType: accountTypeName })}
        submitLabel={submitLabel || I18n.t(company ? 'updateSubmitLabel' : 'createTitle', { ...trOpt, accountType: accountTypeName })}
        ref={form}
        update={invalidateCompaniesQueryCache}
        skipResetFieldsOnSubmit
        defaultTab={upgrade ? 'plan' : null}
        {...{ fields, tabs, refetchQueries, mutateValues, onSuccess, submitIcon, footer, footerProps }}
        scrollContainer={ModalFormScrollContainer}
      />
    </>
  )
})

const EditCompanyErrorAlerts = ({ companyId, ...errorAlertProps }) => (
  <>
    <h1>{I18n.t(companyId ? 'updateErrorTitle' : 'createErrorTitle', trOpt)}</h1>
    <ErrorAlerts {...errorAlertProps} />
  </>
)

const EditCompanyModal = ({
  companyId, visible = false, setVisible = () => {}, refetchQueries = [], setCreateCompanyType, accountType, userUBreachProEnabled, ...formProps
}) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasSuperPermission = hasAllSessionPermissions(companyId ? [permissions.COMPANY_SUPER_UPDATE] : [permissions.COMPANY_SUPER_CREATE])

  const form = useRef(null)
  const closeModal = useCallback(() => {
    setVisible(false)
    setCreateCompanyType('')
    if (form && form.current) {
      form.current.reset()
    }
  }, [setVisible, setCreateCompanyType, form])

  const { loading: companyLoading, error: companyError, data: companyData = {} } = useQuery(GET_COMPANY_FOR_UPDATE, {
    variables: { id: companyId },
    skip: !visible || !companyId,
    fetchPolicy: 'cache-and-network'
  })

  // Only set parentAccountType (and by extension parentOptions) if creating a new msp or tenant as a usecure-admin
  let parentAccountType
  if (hasSuperPermission && !companyId && accountType !== 'distributor') {
    parentAccountType = accountType === 'msp' ? 'distributor' : 'msp'
  }

  const { loading: parentLoading, error: companiesError, data: parentCompanyData } = useQuery(GET_COMPANIES_BY_ACCOUNT_TYPE, {
    variables: { all: true, accountType: parentAccountType },
    skip: !(visible && parentAccountType),
    fetchPolicy: 'cache-and-network'
  })

  const parentCompanyOptions = useMemo(() => {
    let parentCompanyOptions
    if (parentCompanyData) {
      parentCompanyOptions = [{ value: 'usecure', label: I18n.t('common.managedByUsecure') }]
      const companies = _get(parentCompanyData, 'companies', [])
      if (companies.length > 0) {
        parentCompanyOptions = [
          ...parentCompanyOptions,
          ...companies.map(({ id: value, name: label }) => ({ value, label })).sort((a, b) => a.label.localeCompare(b.label))
        ]
      }
    }
    return parentCompanyOptions
  }, [parentCompanyData])

  let body = null
  if (companyError) {
    body = <EditCompanyErrorAlerts {...{ companyId }} error={companyError} defaultError={I18n.t('companyError', trOpt)} />
  } else if (companiesError) {
    body = (
      <EditCompanyErrorAlerts
        {...{ companyId }}
        error={companiesError}
        defaultError={
          ACCOUNT_TYPES[parentAccountType]
            ? I18n.t('parentCompanyAccountTypeError', { ...trOpt, accountType: ACCOUNT_TYPES[parentAccountType] })
            : I18n.t('parentCompanyError', trOpt)
        }
      />
    )
  } else if (visible) {
    body = (
      <EditCompanyModalForm
        ref={form}
        loading={parentLoading || companyLoading}
        company={companyId ? _get(companyData || {}, 'company', {}) : undefined}
        {...{ closeModal, parentCompanyOptions, accountType }}
        {...formProps}
        refetchQueries={refetchQueries}
        userUBreachProEnabled={userUBreachProEnabled}
      />
    )
  }

  return (
    <OverflowModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      maskClosable={false}
      width='auto'
    >
      {body}
    </OverflowModal>
  )
}

export const EditCompanyConnector = connect(state => {
  const { accountType: userAccountType, companyId: userCompanyId, companyLocale, companyTimezone, contentLocales: companyContentLocales } = selectors.session.get(state)
  const { defaultTenantSettings, editExternalId: userEditExternalId, showRiskScoreOption, uBreachProEnabled: userUBreachProEnabled } = selectors.settings.get(state)
  return { userAccountType, defaultTenantSettings, userCompanyId, userEditExternalId, showRiskScoreOption, companyLocale, companyContentLocales, companyTimezone, userUBreachProEnabled }
})

export default EditCompanyConnector(EditCompanyModal)
