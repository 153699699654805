import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { Layout, message, Card, Button, Icon } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import compose from 'recompose/compose'
import I18n from 'i18n-js'

import useGlobalState from '../hooks/useGlobalState'
import CourseTable from '../components/Courses/CourseTable'
import routes from '../constants/routes'
import { GET_COURSES } from '../components/Queries/Courses'
import DeleteCourseConfirm from '../components/Modals/DeleteCourseConfirm'
import DuplicateCourseModal from '../components/Courses/DuplicateCourseModal'
import { connect, withConsumer } from '../hocs'
import { ContentWrap, ListHeader, ListHeaderPanel, ListActions, SearchBar, ErrorAlerts } from '../components/common'
import { getSessionAndSettings } from '../state/selectors'
import { COURSE_SUBJECTS } from '../constants/courses'
import { updateSelectedIdsOnRecordSetUpdate, processListActions } from '../helpers/listPages'
import { isRouteAllowed } from '../helpers'
import { getCourseActions } from '../helpers/courses'
import { WRITABLE_COURSE_ACTIONS } from '../constants/actions'
import IntercomHeader from '../components/IntercomHeader'
import CourseAccessControlModal from '../components/Modals/uLearn/CourseAccessControlModal'

import { useHasSessionPermission } from '../hooks'
import { permissions } from '../constants/permissions'
import useLocalStorage from '../hooks/useLocalStorage'
import IncludeClientContentToggle from '../components/common/IncludeClientContentToggle'
import { cleanUpContentCompanyNameListFilter } from '../helpers/company'

const trOpt = { scope: 'courses' }

const Content = Layout.Content

const CoursesView = ({
  loading, error, courses: rawCourses = [], history,
  panelLeft = [],
  panelRight = ['search', 'actions', 'add'],
  searchFilterText = '',
  updateSearchFilterText = () => {},
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  planValid,
  includeClientContent,
  setIncludeClientContent
}) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const { accountType } = useGlobalState(
    state => ({ accountType: state.session?.accountType }))
  const [selectedCourseIds, updateSelectedCourseIds] = useState([])
  const [showDuplicateCourseOpen, updateDuplicateCourseModal] = useState(false)
  const [showDeleteCourse, updateDeleteCourseModal] = useState(false)
  const [singleCourse, updateSingleCourse] = useState(null)

  const { disabledActions, omittedActions } = useMemo(() => {
    const omittedActions = []

    if (!hasAllSessionPermissions([permissions.COURSE_CREATE])) omittedActions.push('createCourse', 'duplicateCourse')
    if (!hasAllSessionPermissions([permissions.COURSE_SUPER_CREATE])) omittedActions.push('translateCourse')

    return {
      disabledActions: !planValid ? WRITABLE_COURSE_ACTIONS : null,
      omittedActions
    }
  }, [planValid, hasAllSessionPermissions])

  const allCourses = useMemo(() =>
    rawCourses.map((course) => ({
      key: course.id,
      id: course.id,
      name: course.name,
      difficulty: course.difficulty,
      subject: course.subject,
      subjectText: COURSE_SUBJECTS[course.subject] || course.subject,
      companyId: _get(course, 'company.id') || 'usecure',
      companyName: _get(course, 'company.name') || I18n.t('common.managedByUsecure'),
      locale: course.locale,
      locales: course.availableLocales || [],
      global: course.global === true,
      actions: getCourseActions({ course, omittedActions, disabledActions })
    })),
  [rawCourses, disabledActions, omittedActions])

  useEffect(() =>
    updateSelectedIdsOnRecordSetUpdate(updateSelectedCourseIds, allCourses),
  [allCourses])

  const [courses, setCourses] = useState([])
  const applyCoursesFilters = useCallback(searchFilterText => {
    let courses = [...allCourses]

    if (searchFilterText) {
      const filterFields = ['name']
      courses = courses.filter(course =>
        filterFields.some(field => {
          const value = course[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setCourses(courses)
  }, [allCourses])
  const debouncedApplyCoursesFilters = useCallback(_debounce(applyCoursesFilters, 500), [applyCoursesFilters])
  useEffect(() => {
    debouncedApplyCoursesFilters(searchFilterText)
  }, [debouncedApplyCoursesFilters, searchFilterText])

  const actionCourseIds = useMemo(() => singleCourse ? [singleCourse] : selectedCourseIds, [singleCourse, selectedCourseIds])
  const actionCourses = useMemo(() => actionCourseIds.map(courseId => allCourses.find(c => c.id === courseId)), [actionCourseIds, allCourses])

  const actions = useMemo(() => {
    if (selectedCourseIds.length === 1) {
      const course = allCourses.find(({ id }) => id === selectedCourseIds[0])

      if (course) {
        return course.actions
      }
    }

    if (selectedCourseIds.length > 1) {
      const selectedCourses = rawCourses.filter(({ id }) => selectedCourseIds.includes(id))
      const selectedCoursesOmittedActions = [...omittedActions]

      if (selectedCourses.some(course => !course.deleteAllowed)) selectedCoursesOmittedActions.push('deleteCourse')

      if (
        !(hasAllSessionPermissions([permissions.COURSE_ACCESS_CONTROL]) && ['msp', 'distributor'].includes(accountType)) &&
        !hasAllSessionPermissions([permissions.COURSE_SUPER_ACCESS_CONTROL])
      ) selectedCoursesOmittedActions.push('addCourseControlAccess', 'removeCourseControlAccess')

      return processListActions({
        actions: [
          { key: 'deleteCourse', label: I18n.t('deleteCourses', trOpt), icon: 'delete' },
          { key: 'addCourseControlAccess', label: accountType === 'msp' ? I18n.t('addCustomerAccess', trOpt) : I18n.t('addCompanyAccess', trOpt), icon: 'usergroup-add', theme: 'outlined' },
          { key: 'removeCourseControlAccess', label: accountType === 'msp' ? I18n.t('removeCustomerAccess', trOpt) : I18n.t('removeCustomerAccess', trOpt), icon: 'usergroup-delete', theme: 'outlined' }
        ],
        omittedActions: selectedCoursesOmittedActions,
        disabledActions
      })
    }

    return []
  }, [selectedCourseIds, hasAllSessionPermissions, disabledActions, accountType, allCourses, rawCourses, omittedActions])

  const updateCourseControlAccessRef = useRef(null)
  const openCourseControlAccessModal = useCallback((courseIds, action) => {
    if (updateCourseControlAccessRef.current) {
      updateCourseControlAccessRef.current.open(courseIds, action)
    }
  }, [updateCourseControlAccessRef])

  const performActions = useCallback((action, courseIds) => {
    updateSingleCourse(null)
    switch (action) {
      case 'editCourse':
        history.push(generatePath(routes.BUILDER_EDIT, { course_id: courseIds[0] }))
        break
      case 'editSlides':
        history.push(generatePath(routes.BUILDER_EDIT_SLIDES, { course_id: courseIds[0] }))
        break
      case 'previewCourse':
        window.open(generatePath(routes.BUILDER_PREVIEW_COURSE, { course_id: courseIds[0] }), '_blank')
        break
      case 'duplicateCourse':
        updateDuplicateCourseModal(true)
        break
      case 'deleteCourse':
        updateDeleteCourseModal(true)
        break
      case 'addCourseControlAccess':
        openCourseControlAccessModal(courseIds, 'add')
        break
      case 'removeCourseControlAccess':
        openCourseControlAccessModal(courseIds, 'remove')
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [updateDuplicateCourseModal, updateDeleteCourseModal, updateSingleCourse, openCourseControlAccessModal, history])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    return (
      <ListHeaderPanel align={align}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'search':
                return (
                  <SearchBar
                    key={index}
                    placeholder={I18n.t('searchForACourse', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                  />
                )
              case 'actions':
                return (
                  <ListActions
                    key={index} selectedIds={selectedCourseIds} actions={actions}
                    performAction={performActions} multiple
                    onEmptyActions='disable'
                  />
                )
              case 'add':
                return (
                  <Link key={index} to={routes.BUILDER_CREATE}>
                    <Button disabled={!planValid} type='primary'>
                      <Icon type='plus' /> {I18n.t('createCourse', trOpt)}
                    </Button>
                  </Link>
                )
              case 'include-client-content-toggle':
                return (
                  <IncludeClientContentToggle
                    key={index}
                    {...{ includeClientContent, setIncludeClientContent }}
                  />
                )
              default:
                return (
                  <div key={index}>{panelElem}</div>
                )
            }
          })
        }
      </ListHeaderPanel>
    )
  }, [onSearchChange, searchFilterText, selectedCourseIds, actions, performActions, planValid, includeClientContent, setIncludeClientContent])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      <DuplicateCourseModal
        visible={showDuplicateCourseOpen} setVisible={updateDuplicateCourseModal}
        courses={actionCourses}
      />
      <CourseAccessControlModal
        ref={updateCourseControlAccessRef}
      />
      <DeleteCourseConfirm
        visible={showDeleteCourse} setVisible={updateDeleteCourseModal}
        courseIds={actionCourseIds}
      />
      <>
        <ListHeader>
          {panelLeftComponent}
          {panelRightComponent}
        </ListHeader>
        <ErrorAlerts {...{ error }} />
        <CourseTable
          {...{
            courses,
            selectedCourseIds,
            updateSelectedCourseIds,
            updateSingleCourse,
            updateDuplicateCourseModal,
            updateDeleteCourseModal,
            loading,
            sorter,
            updateSorter,
            filters,
            updateFilters,
            pagination,
            updatePagination
          }}
        />
      </>
    </>
  )
}

const Courses = ({ client, history, session, settings }) => {
  const { hasAllSessionPermissions, hasAnySessionPermission } = useHasSessionPermission()
  const { userId, companyId, planValid } = session || {}
  const hasSuperPermission = hasAllSessionPermissions([permissions.COURSE_SUPER_LIST, permissions.COURSE_SUPER_READ])
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(hasSuperPermission ? { companyName: ['usecure'] } : null)
  const [includeClientContent, setIncludeClientContent] = useState(false)

  const storageId = useMemo(() => `courses|${companyId}|${userId}`, [userId, companyId])
  const { localStorageInitialised, updateFromLocalStorage, updateLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      searchFilterText: updateSearchFilterText,
      filters: updateFilters,
      sorter: updateSorter,
      pagination: updatePagination
    })
  }, [updateFromLocalStorage, storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const { loading, error, data } = useQuery(GET_COURSES, {
    variables: {
      restrictToOwn: !hasSuperPermission || !includeClientContent,
      withCompany: hasSuperPermission,
      forUpdate: true,
      includeGlobal: hasSuperPermission
    }
  })
  const { courses = [] } = data || {}

  // Remove values that aren't in the course dataset from companyName filter
  useEffect(() => {
    cleanUpContentCompanyNameListFilter({
      records: data?.courses, filters, updateFilters, includeClientContent, hasSuperPermission, localStorageInitialised
    })
  }, [data, filters, includeClientContent, hasSuperPermission, localStorageInitialised])

  const panelRight = ['search']
  if (hasAnySessionPermission([permissions.COURSE_DELETE, permissions.COURSE_ACCESS_CONTROL])) {
    panelRight.push('actions')
  }
  if (isRouteAllowed(session, settings, routes.BUILDER_CREATE)) {
    panelRight.push('add')
  }

  return (
    <ContentWrap>
      <Card>
        <IntercomHeader Size='h1' id='course-builder-header'>{I18n.t('common.uLearn')} - {I18n.t('courseBuilder', trOpt)}</IntercomHeader>
        <Layout>
          <Content>
            <CoursesView
              {...{
                loading,
                error,
                panelRight,
                courses,
                client,
                searchFilterText,
                updateSearchFilterText,
                history,
                sorter,
                updateSorter,
                filters,
                updateFilters,
                pagination,
                updatePagination,
                planValid,
                includeClientContent,
                setIncludeClientContent
              }}
              panelLeft={hasSuperPermission ? ['include-client-content-toggle'] : []}
            />
          </Content>
        </Layout>
      </Card>
    </ContentWrap>
  )
}

export default compose(
  withConsumer,
  connect(state => getSessionAndSettings(state))
)(Courses)
