import React, { useReducer, useEffect, useCallback, useRef } from 'react'
import { useParams, useLocation, generatePath, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { Alert as _Alert, Button } from 'antd'

import { GET_SAML_AUTH_START_TOKEN } from '../../components/Queries/Users'
import { createAction } from '../../helpers/state'
import { apiUrl } from '../../apollo-client/common'
import { LoadingBlock, ShortLoader, ErrorAlerts } from '../../components/common'
import MutationForm from '../../components/MutationForm/MutationForm'
import { UsecureError, addDelay, isUUID, validateEmail } from '../../helpers'
import { SignInContainer, SignInHeader } from '../../components/SignIn'
import { setEndUserSessionToken, setSessionToken } from '../../helpers/session'
import routes from '../../constants/routes'

const Alert = styled(_Alert)`
  margin-bottom: 25px;

  .ant-alert-message {
    position: relative;
    top: 2px;
  }
`

const SuccessHeader = styled.h1`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`

const TryAgainButton = styled(Button)`
  margin: 0 auto;
  display: block;
`

const AUTH_TYPE_MAP = {
  'admin-user': 'adminUser',
  'end-user': 'endUser',
  'no-auth-type': 'noAuthType'
}

const AUTH_TYPE_SUBTITLE = {
  adminUser: 'Admin User',
  endUser: 'End User',
  noAuthType: 'User Type Unknown'
}

const types = {
  UPDATE: 'UPDATE',
  RESET: 'RESET'
}

const creators = {
  update: createAction(types.UPDATE),
  reset: createAction(types.RESET)
}

const initialState = {
  status: 'init',
  loading: true,
  authType: null,
  companyIdParam: null,
  token: null,
  error: null
}

const actionsMap = {
  [types.UPDATE]: (prevState, payload) => {
    return {
      ...prevState,
      ...(payload || {})
    }
  },
  [types.RESET]: () => ({ ...initialState })
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  const fn = actionsMap[type]
  return fn ? fn(state, payload) : state
}

// CL - This page is currently intended for internal testing hence the mix of hard coded and translation based copy.
const SAMLAuthTest = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const routeParams = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const [callGetAuthStartToken] = useMutation(GET_SAML_AUTH_START_TOKEN)
  const form = useRef(null)
  const { status, authType, loading = false, companyIdParam, error } = state

  // "On Mount" hook
  useEffect(() => {
    if (status !== 'init') return

    dispatch(creators.reset())

    const { authType: authTypeParam, status: statusParam } = routeParams
    const authType = AUTH_TYPE_MAP[authTypeParam] || 'noAuthType'
    const queryParams = new URLSearchParams(search)
    const token = queryParams.get('token')
    const errorCode = queryParams.get('errorCode')
    const companyId = queryParams.get('companyId')

    let update = { status: 'fail', authType, loading: false }
    if (statusParam === 'start') {
      update.status = 'start'
      if (companyId) {
        update.status = 'populateForm'
        update.companyIdParam = companyId
        update.loading = true
      }
    } else if (statusParam === 'mfa' && authType === 'adminUser' && token) {
      // Admin user who requires MFA before auth is complete
      update.status = 'mfaSuccess'
      dispatch(creators.update(update))
      update = null

      // Artificial delay for user feedback
      addDelay({
        delay: 1500,
        complete: () => {
          // Uses simple navigation to take advantage of existing initialise process for logged in users
          // The final process will route without a hard reload like this
          window.location.href = `${routes.MFA_LOGIN}?token=${token}`
        }
      })
    } else if (statusParam === 'success') {
      let redirectPath
      let setSessionTokenFn

      if (authType === 'adminUser' && token) {
        // Route successful admin user auth to home page
        redirectPath = routes.HOME
        setSessionTokenFn = setSessionToken
      } else if (authType === 'endUser' && token) {
        // Route successful end user auth to portal home page using companyId query param
        const companyId = queryParams.get('companyId')
        if (companyId) {
          setSessionTokenFn = setEndUserSessionToken
          redirectPath = generatePath(routes.PORTAL_HOME, { companyId })
        }
      }

      if (redirectPath && setSessionTokenFn) {
        update.status = 'success'
        dispatch(creators.update(update))
        update = null

        // Artificial delay for user feedback
        addDelay({
          delay: 1500,
          action: () => {
            setSessionTokenFn(token)
          },
          complete: () => {
            // Uses simple navigation to take advantage of existing initialise process for logged in users
            // The final process will route without a hard reload like this
            window.location.href = redirectPath
          }
        })
      }
    } else if (statusParam === 'fail' && errorCode) {
      update.error = new UsecureError('An error occurred attempting to authenticate using SSO via SAML', { errorCode })
      if (companyId) {
        update.companyIdParam = companyId
      }
    }
    if (update) {
      dispatch(creators.update(update))
    }
  }, [status, routeParams, search])

  const initialiseFormWithCompanyId = useCallback(async (form, companyId) => {
    if (form && form.current) {
      await form.current.setInitialValues({
        method: 'companyId',
        companyId,
        email: null
      })
      dispatch(creators.update({ status: 'start', loading: false, companyIdParam: null }))
    }
  }, [])

  useEffect(() => {
    if (status === 'populateForm' && companyIdParam && form && form.current) {
      initialiseFormWithCompanyId(form, companyIdParam)
    }
  }, [initialiseFormWithCompanyId, status, form, companyIdParam])

  // start - Start SAML Auth Flow
  // - Get auth start token for company id or email. (Company id mirrors typical end user portal flow, email for admin flow)
  // - If successful, navigate to {API_URL}/auth/saml/:startToken
  // - If failed, navigate to /sso-saml-login-test/:authType(admin-user|end-user)/fail?errorCode={error code from mutation}
  const onStartSubmit = useCallback(async ({ method, email, companyId }) => {
    if (status !== 'start') return
    try {
      dispatch(creators.update({ status: 'starting', loading: true }))
      const variables = { authType, targetPath: '/sso-saml-login-test' }
      if (method === 'email') {
        variables.email = email
      } else {
        variables.companyId = companyId
      }
      const { data } = await callGetAuthStartToken({
        variables
      })
      const startToken = data?.getSAMLAuthStartToken
      if (startToken) {
        window.location.href = `${apiUrl}/auth/saml/${startToken}`
      } else {
        throw new UsecureError('No token returned', { errorCode: 'NTR' })
      }
    } catch (e) {
      console.error('SAMLAuthTest.onStartSubmit - ERROR', e?.errorCode || 'NO_CODE', e)
      dispatch(creators.update({ status: 'start', loading: false, error: e }))
    }
  }, [callGetAuthStartToken, authType, status])

  const onTryAgainClick = useCallback(() => {
    dispatch(creators.reset())
    let startPath = generatePath(routes.SAML_AUTH_TEST, { status: 'start', authType: authType === 'endUser' ? 'end-user' : 'admin-user' })
    if (companyIdParam) {
      startPath += `?companyId=${companyIdParam}`
    }
    history.push(startPath)
  }, [history, authType, companyIdParam])

  return (
    <>
      <LoadingBlock {...{ loading }} />
      <SignInContainer>
        <SignInHeader>SAML Auth Test - {authType ? (AUTH_TYPE_SUBTITLE[authType] || AUTH_TYPE_SUBTITLE.noAuthType) : 'Loading...'}</SignInHeader>
        {status === 'init' && !loading && <Alert type='error' showIcon message='Page did not initialise correctly' />}
        {status === 'success' && (
          <>
            <SuccessHeader>Logging you in...</SuccessHeader>
            <ShortLoader />
          </>
        )}
        {status === 'mfaSuccess' && (
          <>
            <SuccessHeader>Redirecting to Two-Factor Authentication...</SuccessHeader>
            <ShortLoader />
          </>
        )}
        {(status === 'fail' || error) && <ErrorAlerts error={error || new Error(I18n.t('common.anErrorOccurred'))} defaultError={I18n.t('common.anErrorOccurred')} includeErrorCode />}
        {status === 'fail' && (['adminUser', 'endUser'].includes(authType)) && <TryAgainButton onClick={onTryAgainClick} icon='redo'>Try Again</TryAgainButton>}
        {['populateForm', 'start', 'starting'].includes(status) && (
          <MutationForm
            ref={form}
            disabled={loading}
            fields={[
              {
                id: 'method',
                type: 'radio',
                label: 'Authentication Flow',
                options: [{
                  value: 'email',
                  label: 'Email'
                }, {
                  value: 'companyId',
                  label: 'Company Id'
                }],
                defaultValue: 'email'
              }, {
                id: 'email',
                type: 'email',
                label: I18n.t('common.fields.email'),
                required: values => values.method === 'email',
                visible: values => values.method === 'email',
                validate: (field, value, errors) => {
                  if (!validateEmail(value)) {
                    errors.push(I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError'))
                  }
                }
              }, {
                id: 'companyId',
                type: 'text',
                label: 'Company Id',
                required: values => values.method === 'companyId',
                visible: values => values.method === 'companyId',
                validate: (field, value, errors) => {
                  if (!isUUID(value)) {
                    errors.push('Please a valid company id')
                  }
                }
              }
            ]}
            onSubmit={onStartSubmit}
            submitIcon='play-circle'
            submitLabel={I18n.t('common.start')}
            skipResetFieldsOnSubmit
            disableSubmitIfInvalid={false}
          />
        )}
      </SignInContainer>
    </>
  )
}

export default SAMLAuthTest
