import React, { useEffect, useMemo, useState } from 'react'
import I18n from 'i18n-js'
import queryString from 'query-string'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'

import { connect } from '../../../hocs'
import useIsPrinting from '../../../hooks/useIsPrinting'
import { creators as viewActions } from '../../../state/actions/view'
import { BREACH_REPORT } from '../../../components/Queries/Reports'
import BaseReport from '../../../components/Reports/BaseReport'
import { LoadingBlock, ErrorAlerts, PreviewTag } from '../../../components/common'
import { REPORT_CENTRE_PREVIEW } from '../../../constants/environment'
import { getBreachReportSections } from '../../../helpers/breachReport'

const trOpt = { scope: 'reports.breachReport' }

const BreachReport = ({ setLoadingVisible = () => {}, setPageTitle = () => {}, resetPageTitle = () => {} }) => {
  const [variables, setVariables] = useState(null)
  const { companyId, fromDate, toDate } = variables || {}

  const { data, loading, error } = useQuery(BREACH_REPORT, {
    variables,
    skip: !variables
  })

  const isPrinting = useIsPrinting()

  // Temporarily change page title to a filename string when printing
  useEffect(() => {
    if (isPrinting) {
      const { fromDate, toDate } = variables || {}
      setPageTitle(I18n.t('printTitle', { ...trOpt, fromDate, toDate }))
    } else {
      resetPageTitle()
    }
  }, [isPrinting, setPageTitle, resetPageTitle, variables])

  useEffect(() => {
    // Parse query string on mount
    const {
      companyId,
      fromDate,
      toDate
    } = queryString.parse(window.location.search, { arrayFormat: 'comma' })
    setVariables({
      companyId,
      fromDate,
      toDate
    })
  }, [])

  useEffect(() => {
    if (!loading) setLoadingVisible(false)
  }, [setLoadingVisible, loading])

  const sections = useMemo(() => {
    if (!_isEmpty(_get(data, 'breachReport'))) {
      if (loading || !data || !data.breachReport) return []
      return getBreachReportSections({
        breachReport: data.breachReport,
        trOpt
      })
    }
    return []
  }, [data, loading])

  return (
    <>
      <LoadingBlock fullScreen loading={loading} />
      <ErrorAlerts error={error} defaultError={I18n.t('reports.loadError')} />
      <BaseReport
        {...{ companyId, fromDate, toDate, sections, trOpt }}
        title={<>{I18n.t('reportTitle', trOpt)}{REPORT_CENTRE_PREVIEW && <PreviewTag header className='hideOnPrint' />}</>}
        downloadFileName={I18n.t('reports.downloadReport.fileNames.breachReport', { fromDate, toDate })}
        reportType='breachReport'
      />
    </>
  )
}

export default connect(
  undefined,
  dispatch => ({
    setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
    setPageTitle: title => dispatch(viewActions.title(title)),
    resetPageTitle: () => dispatch(viewActions.resetTitle())
  })
)(BreachReport)
