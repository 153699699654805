import React, { useState, useImperativeHandle, useCallback, useRef } from 'react'
import I18n from 'i18n-js'
import { Modal, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import _isArray from 'lodash/isArray'

import MutationForm from '../MutationForm/MutationForm'
import { LoadingBlock } from '../common'
import { waitForRefRender, showErrors, modalConfirmAsync, UsecureError } from '../../helpers'

const trOpt = { scope: 'modals.editReportModal' }

const EditReportModal = React.forwardRef(({
  companyId,
  refetchQueries,
  reportStartDate,
  reportType,
  createMutation,
  updateMutation,
  useDBCache
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [record, setRecord] = useState(null)

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const setInitialFormValues = useCallback(async (values) => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async (record) => {
      try {
        setVisible(true)
        if (record) {
          setLoading(true)
          setRecord(record)
          await waitForRefRender(form)
          const { name, fromDate, toDate } = record
          await setInitialFormValues({
            name,
            period: [fromDate, toDate]
          })
          setLoading(false)
        }
      } catch (e) {
        setVisible(false)
        showErrors(e, I18n.t('initialisationError', trOpt))
      }
    }
  }), [form, setInitialFormValues])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    setLoading(false)
    setRecord(null)
    resetForm()
  }, [resetForm])

  const mutateValues = useCallback(({ name, period }) => {
    return { name, fromDate: period?.[0], toDate: period?.[1] }
  }, [])

  const [createRecord] = useMutation(createMutation, { refetchQueries })
  const [updateRecord] = useMutation(updateMutation, { refetchQueries })
  const onSubmit = useCallback(async (values, errors, variables) => {
    try {
      setLoading(true)
      if (record) {
        const { hasCachedData, fromDate, toDate, recordIds, id: recordId } = record
        const id = _isArray(recordIds) ? recordIds[0] : recordId
        const { fromDate: fromDateUpdt, toDate: toDateUpdt } = variables
        // Warning user that the cached version of this report will be deleted if date range has changed
        if (useDBCache && hasCachedData && (fromDate !== fromDateUpdt || toDate !== toDateUpdt)) {
          const confirm = await modalConfirmAsync({
            content: I18n.t('cachedDataPrompt', trOpt)
          })
          if (!confirm) {
            throw new UsecureError(I18n.t('cancelledMessage', trOpt), { level: 'info' })
          }
        }

        await updateRecord({ variables: { companyId, id, ...variables } })
      } else {
        await createRecord({ variables: { companyId, ...variables } })
      }
    } catch (e) {
      console.error('EditReportModal.onSubmit - Mutation Error', e)
      throw e // will be caught and displayed by MutationForm
    } finally {
      // This try-catch-finally is only here to disable loading overlay
      setLoading(false)
    }
  }, [createRecord, updateRecord, companyId, record, useDBCache])

  const onSuccess = useCallback(() => {
    message.success(I18n.t(record ? 'updateSuccessMessage' : 'createSuccessMessage', trOpt))
    closeModal()
  }, [closeModal, record])

  let title
  if (record?.name) {
    title = I18n.t(`${reportType}.updateTitleName`, { ...trOpt, name: record.name })
  } else if (record) {
    title = I18n.t(`${reportType}.updateTitle`, trOpt)
  } else {
    title = I18n.t(`${reportType}.createTitle`, trOpt)
  }

  const endOfMonth = moment().endOf('month')
  const oneMonthAgo = moment().subtract(1, 'month')

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <MutationForm
        ref={form}
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        mutateValues={mutateValues}
        failureMessage={I18n.t(record ? 'updateErrorMessage' : 'createErrorMessage', trOpt)}
        submitLabel={I18n.t(record ? 'common.update' : 'common.create')}
        submitIcon='save'
        skipResetFieldsOnSubmit
        fields={[{
          id: 'name',
          label: I18n.t('reports.common.reportName'),
          required: true
        }, {
          id: 'period',
          label: I18n.t('reports.common.reportPeriod'),
          type: 'dateRange',
          required: true,
          defaultValue: [
            moment(oneMonthAgo).startOf('month').format('YYYY-MM-DD'),
            moment(oneMonthAgo).endOf('month').format('YYYY-MM-DD')
          ],
          min: reportStartDate,
          max: endOfMonth
        }]}
      />
    </Modal>
  )
})

export default EditReportModal
