export const trendColors = {
  positive: '#009E06',
  negative: '#D10101',
  neutral: '#757575'
}

export const trendIcons = {
  positive: 'caret-up',
  negative: 'caret-down',
  neutral: 'caret-right'
}
