import { Modal, message } from 'antd'
import I18n from 'i18n-js'

import { ATTACH_SIMULATION_TO_RISK_REPORT, DETACH_SIMULATION_FROM_RISK_REPORT } from '../Queries/uPhish'
import { UPHISH_SIMULATION_PERFORMANCE } from '../Queries/Reports'
import authenticatedClient from '../../apollo-client/authenticatedClient'
import { showErrors } from '../../helpers'
import { UBREACH_V2_ENABLED } from '../../constants/environment'

const { confirm } = Modal

const riskReportSimulationConfirm = async ({
  simulationId, detach = false, refreshSessionState = () => {}, client = authenticatedClient,
  excludeInactiveUsers = false
}) => {
  const trOpt = { scope: `modals.riskReportSimulationConfirm.${detach ? 'detach' : 'attach'}` }
  confirm({
    title: I18n.t('prompt', trOpt),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        await client.mutate({
          mutation: detach ? DETACH_SIMULATION_FROM_RISK_REPORT : ATTACH_SIMULATION_TO_RISK_REPORT,
          variables: { simulationId, returnBreachDataClassIds: UBREACH_V2_ENABLED },
          refetchQueries: [{
            query: UPHISH_SIMULATION_PERFORMANCE,
            variables: { excludeInactiveUsers }
          }]
        })
        message.success(I18n.t('successMessage', trOpt))
        await refreshSessionState()
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      }
    }
  })
}

export default riskReportSimulationConfirm
