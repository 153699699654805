import React from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import moment from 'moment'
import _isArray from 'lodash/isArray'
import _isInteger from 'lodash/isInteger'
import _isString from 'lodash/isString'

import { IntroDetail, IntroWrap } from './LearnerCourseIntro'
import { renderParagraphsFragmentFromArray } from '../../helpers'
import { LEARNER_COURSE_ERROR_TR_KEYS } from '../../constants/courses'

const trOpt = { scope: 'learnerCourse.learnerCourseError' }

const DebugErrorOutput = styled.div`
  p {
    font-family: unset;
    font-size: 10px;
    margin-bottom: 2px;
  }
`

const LearnerCourseError = ({ errorType, errors, loadTime }) => {
  const { titleKey, subtitleKey } = LEARNER_COURSE_ERROR_TR_KEYS[errorType] || LEARNER_COURSE_ERROR_TR_KEYS.unknown
  return (
    <IntroWrap>
      <IntroDetail>
        <h1>{I18n.t(titleKey, trOpt)}</h1>
        <h3>
          {subtitleKey && (
            <>
              {I18n.t(subtitleKey, trOpt)}
              <br />
            </>
          )}
          {I18n.t('common.pleaseContactSupport')}
        </h3>
        <DebugErrorOutput>
          {_isArray(errors) && errors.length > 0 && errors.every(e => _isString(e)) && renderParagraphsFragmentFromArray(errors)}
          <p>{window.location.pathname}</p>
          <p>{I18n.t('debugTiming', { ...trOpt, loadTime: _isInteger(loadTime) ? loadTime : I18n.t('common.na'), currentTime: moment.utc().format('YYYY-MM-DD HH:mm') })}</p>
          <p>{navigator.userAgent}</p>
        </DebugErrorOutput>
      </IntroDetail>
    </IntroWrap>
  )
}

export default LearnerCourseError
