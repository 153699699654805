import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import EditCourseSlate from './EditCourseSlate'
import I18n from 'i18n-js'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const EditCourseMaterial = ({ title, locale, content, slideId, updateId, updateTitle, updateContent }) => {
  const onTitleChange = useCallback(event => updateTitle(event.target.value), [updateTitle])

  return (
    <div>
      <Form.Item label={I18n.t('editCourse.common.title')}>
        <Input
          onChange={onTitleChange}
          value={title}
        />
      </Form.Item>

      <Form.Item label={I18n.t('common.fields.content')}>
        <EditCourseSlate {...{ locale, content, slideId, updateId, updateContent }} />
      </Form.Item>
    </div>
  )
}

EditCourseMaterial.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseMaterial.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseMaterial
