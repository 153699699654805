import _cloneDeep from 'lodash/cloneDeep'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'

// This function only exists because it isn't clear to retrieve filtered and/or sorted data from an antd table
const processTableData = (columns, originalData) => {
  let data = originalData

  const { activeFilters, sorter, sortOrder, defaultSorter, defaultSortOrder } = columns.reduce((obj, column) => {
    const { sorter, sortOrder, defaultSortOrder, filteredValue: values, onFilter } = column
    if (_isArray(values) && values.length > 0 && _isFunction(onFilter)) {
      obj.activeFilters.push({ values, onFilter })
    }
    if (_isFunction(sorter)) {
      if (sortOrder) {
        obj.sorter = sorter
        obj.sortOrder = sortOrder
      }
      if (!obj.defaultSortOrder && defaultSortOrder) {
        obj.defaultSorter = sorter
        obj.defaultSortOrder = defaultSortOrder
      }
    }

    return obj
  }, {
    activeFilters: [],
    sorter: null,
    sortOrder: null,
    defaultSorter: null,
    defaultSortOrder: null
  })
  if (activeFilters.length > 0) {
    data = data.filter(record =>
      activeFilters.every(({ values, onFilter }) =>
        values.some(value => onFilter(value, record))
      )
    )
  }

  const tSorter = sorter || defaultSorter
  const tSortOrder = sortOrder || defaultSortOrder
  if (tSorter && tSortOrder) {
    data = _cloneDeep(data)
    data.sort((a, b) => {
      // Based on Table.getSorterFn from antd table component
      const result = tSorter(a, b, sortOrder)
      if (result !== 0) {
        return tSortOrder === 'descend' ? -result : result
      }
      return 0
    })
  }

  return data
}

export default processTableData
