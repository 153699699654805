/* global localStorage */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, Modal, message, Form } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'
import uuid from 'uuid/v4'
import { useQuery } from '@apollo/react-hooks'
import fontParser from 'css-font-parser'
import axios from 'axios'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'

import MutationForm from '../components/MutationForm/MutationForm'
import { LoadingBlock, ErrorAlerts, BannerMessage as _BannerMessage } from '../components/common'
import { DescriptionContainer, ActionContainer, CopyToClipboard as _CopyToClipboard } from '../components/Settings/common'
import { connect } from '../hocs'
import { getSession } from '../state/selectors/session'
import { getAppOrigin } from '../state/selectors/settings'
import { GET_RISK_REPORT_SIGN_UP_WIDGET, UPDATE_RISK_REPORT_SIGN_UP_WIDGET } from '../components/Queries/Companies'
import { renderListFragmentFromHtmlArray, renderListFromArray } from '../helpers'
import IntercomHeader from '../components/IntercomHeader'
import { permissions } from '../constants/permissions'
import { useHasSessionPermission } from '../hooks'

const trOpt = { scope: 'riskReportSignUpSetup' }

const BannerMessage = styled(_BannerMessage)`
  margin-bottom: 15px;
`

const WidgetMutationForm = styled(MutationForm)`
  margin-bottom: 50px;

  .ant-tabs {
    position: unset;
  }
`

const ChangeAlert = styled(Alert)`
  margin-bottom: 10px;
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: -4px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }

  .ant-btn {
    border-color: #faad14;
    color: #faad14;
    margin-left: 10px;
  }
`

const CopyToClipboard = styled(_CopyToClipboard)`
  margin-left: 10px;
`

const SampleHTMLBlock = styled.div`
  background-color: ${({ theme }) => theme.lightGrey};
  border-radius: 4px;
  padding: 10px;

  &> span {
    display: block;
    font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
    margin: 10px 45px 10px 10px;
    white-space: pre;
  }

  ${CopyToClipboard} {
    float: right;
    margin-left: 0;

    i {
      top: -1px;
    }
  }
`

const SampleHTMLContainer = styled.div`
  margin: 15px 0;

  ${SampleHTMLBlock} {
    display: inline-block;
  }
`

const AccessKeyContainer = styled.div`
  margin-bottom: 15px;

  p {
    font-family: ${({ theme }) => theme.baseFont};
  }
`

const LinkActionContainer = styled(ActionContainer)`
  margin-bottom: 15px;
`

const WidgetEditorReadOnlyPreview = React.forwardRef(({ id, value, accessKey }, ref) => {
  return (
    <>
      {!accessKey &&
        <Alert
          type='error'
          message={I18n.t('widgetConfigError', trOpt)}
        />}
      <Form.Item label={I18n.t('widgetPreview', trOpt)}>
        <iframe
          title={I18n.t('widgetPreview', trOpt)}
          src={`/risk-report-sign-up/${accessKey}?mode=test`}
          style={{ width: '100%', height: '500px', border: 'none' }}
        />
      </Form.Item>
    </>
  )
})

const AccessKeyField = React.forwardRef(({
  id, value, defaultValue, onChange = () => {}, changed = false, appOrigin, visible, disabled
}, ref) => {
  let link
  let sampleHtml
  if (value) {
    link = `${appOrigin}/risk-report-sign-up/${value}`
    sampleHtml = `<iframe\n    src="${link}"\n    style="width:100%;height:500px;border:none;"\n></iframe>`
  }

  const updateKey = useCallback(key => {
    onChange(id, key)
  }, [onChange, id])
  const generateKey = useCallback(() => updateKey(uuid()), [updateKey])
  const resetKey = useCallback(() => updateKey(defaultValue), [updateKey, defaultValue])

  const hasChanged = value !== defaultValue && changed

  if (!visible) return null

  return (
    <AccessKeyContainer>
      {
        hasChanged &&
          <ChangeAlert
            showIcon type='warning'
            message={
              <>
                <span>{I18n.t('changedMessage', trOpt)}</span>
                <Button icon='undo' ghost onClick={resetKey}>{I18n.t('resetChanges', trOpt)}</Button>
              </>
            }
          />
      }
      {
        link
          ? (
            <div>
              <DescriptionContainer>
                <p>{I18n.t('hasLinkDescription1', trOpt)}</p>
                <a href={link} target='_blank' rel='noopener noreferrer'>{link}</a>
                <CopyToClipboard value={link} copiedMessage={I18n.t('urlCopied', trOpt)} />
                <SampleHTMLContainer>
                  <SampleHTMLBlock>
                    <CopyToClipboard value={sampleHtml} copiedMessage={I18n.t('htmlCopied', trOpt)} iconOnly />
                    <span>{sampleHtml}</span>
                  </SampleHTMLBlock>
                </SampleHTMLContainer>
                <p>{I18n.t('hasLinkDescription2', trOpt)}</p>
              </DescriptionContainer>
              <LinkActionContainer>
                <Button icon='reload' ghost type='primary' onClick={generateKey} {...{ disabled }}>{I18n.t('regenerateLink', trOpt)}</Button>
              </LinkActionContainer>
              <p>{I18n.t('linkTestMode', trOpt)}</p>
            </div>
          ) : (
            <div>
              <DescriptionContainer>
                <p>{I18n.t('noLinkDescription', trOpt)}</p>
              </DescriptionContainer>
              <ActionContainer>
                <Button icon='link' type='primary' onClick={generateKey} {...{ disabled }}>{I18n.t('generateLink', trOpt)}</Button>
              </ActionContainer>
            </div>
          )
      }
    </AccessKeyContainer>
  )
})

const helpTrOpt = { scope: `${trOpt.scope}.contentEditorHelp` }
const ContentEditorHelpSection = ({ scope, list, footer = false }) => {
  if (!scope) {
    return null
  }

  const sectionTrOpt = { scope: `${helpTrOpt.scope}.${scope}` }

  return (
    <>
      <h4>{I18n.t('title', sectionTrOpt)}</h4>
      <ul>
        {list}
        {renderListFragmentFromHtmlArray(I18n.t('list', sectionTrOpt))}
      </ul>
      {footer}
    </>
  )
}

const _ContentEditorHelp = ({ className }) => {
  return (
    <div {...{ className }}>
      <ContentEditorHelpSection
        scope='addingForm'
      />
    </div>
  )
}
const ContentEditorHelp = styled(_ContentEditorHelp)`
  max-width: 525px;

  ul {
    padding-inline-start: 18px;
  }
`

const triggerTrOpt = { scope: `${trOpt.scope}.invalidContentAlert` }
const ErrorAlert = styled(Alert)`
  margin-bottom: 15px;
`
export const InvalidContentAlert = React.forwardRef(({ visible }, ref) => (
  visible ? (
    <ErrorAlert
      type='error'
      message={I18n.t('title', triggerTrOpt)}
      description={
        <>
          <p className='base-font'>{I18n.t('message', triggerTrOpt)}</p>
          <p className='base-font'>{I18n.t('listIntro', triggerTrOpt)}</p>
          {renderListFromArray(I18n.t('list', triggerTrOpt))}
        </>
      }
    />
  ) : null
))

const SuccessMessagePreviewField = styled.div`
  margin-bottom: 15px;
  max-width: 800px;
  width: 100%;
`
const SuccessMessagePreviewContainer = styled.div`
  border: 1px solid #e8e8e8;
  padding: 50px;
  text-align: center;

  p {
    white-space: pre-wrap;
  }
`

export const SuccessMessagePreview = React.forwardRef(({
  visible,
  successMessageTitle,
  successMessageTitleFontColor,
  successMessageTitleFontSize,
  successMessageBody,
  successMessageBodyFontColor,
  successMessageBodyFontSize
}, ref) => (
  visible ? (
    <SuccessMessagePreviewField>
      <p className='base-font'>{I18n.t('successMessageIntro', trOpt)}</p>
      <SuccessMessagePreviewContainer>
        <h1 style={{ fontSize: successMessageTitleFontSize, color: successMessageTitleFontColor }}>{successMessageTitle}</h1>
        <p style={{ fontSize: successMessageBodyFontSize, color: successMessageBodyFontColor }}>{successMessageBody}</p>
      </SuccessMessagePreviewContainer>
    </SuccessMessagePreviewField>
  ) : null
))

const isEnabled = ({ enabled }) => enabled === true

const fontSizeValueFormatingProps = {
  formatter: value => `${value}px`,
  parser: value => value.replace(/px$/, '')
}

const getValueFromWidget = (widget, fieldId, defaultValue) => {
  const value = _get(widget, fieldId)
  return _isNil(value) ? defaultValue : value
}

const SUCCESS_MSG_FIELDS = [
  'successMessageTitle', 'successMessageTitleFontColor', 'successMessageTitleFontSize',
  'successMessageBody', 'successMessageBodyFontColor', 'successMessageBodyFontSize'
]

const getSuccessMessageValuesFromWidget = widget => ({
  successMessageTitle: getValueFromWidget(widget, 'successMessageTitle', I18n.t('defaultSuccessMessageTitle', trOpt)),
  successMessageTitleFontColor: getValueFromWidget(widget, 'successMessageTitleFontColor', '#000000'),
  successMessageTitleFontSize: getValueFromWidget(widget, 'successMessageTitleFontSize', 22),
  successMessageBody: getValueFromWidget(widget, 'successMessageBody', I18n.t('defaultSuccessMessageBody', trOpt)),
  successMessageBodyFontColor: getValueFromWidget(widget, 'successMessageBodyFontColor', '#000000'),
  successMessageBodyFontSize: getValueFromWidget(widget, 'successMessageBodyFontSize', 16)
})

const getCustomFontArrayFromWidget = widget => {
  const { customFontValue: value, customFontURL: url } = widget || {}
  let customFont
  // Get font name for Unlayer UI from font value e.g. 'Poppins', sans-serif becomes Poppins
  // The font parser library needs a font size to work hence the 10 px prefix
  const label = value ? _get(fontParser(`10px ${value}`), 'font-family[0]').replace(/"|'/g, '') : null
  if (label && value && url) {
    customFont = { label, value, url }
  }
  return customFont ? [customFont] : null
}

const mutateValues = values => {
  const { enabled } = values || {}
  return enabled ? values : { enabled: false }
}

const defaultState = {
  enabled: false,
  accessKey: false,
  content: null,
  customFontValue: null,
  customFontURL: null,
  ...getSuccessMessageValuesFromWidget()
}

const RiskReportSignUpSetupForm = ({ storageId, widget, appOrigin }) => {
  const form = useRef(null)

  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasRiskReportUpdatePermission = hasAllSessionPermissions([permissions.SETTINGS_RISK_REPORT_UPDATE])

  const [contentValid, setContentValid] = useState(false)
  const validateContent = useCallback((field, value, errors, opt, values) => {
    if (values.enabled && !contentValid) {
      errors.push(I18n.t('formInvalidError', trOpt))
    }
  }, [contentValid])
  const updateContentValid = useCallback(rawContent => {
    const content = _get(rawContent, 'html', '')
    setContentValid(
      content
        // Lazy check for usecure-prospect-sign-up class and email field name
        ? [/usecure-prospect-sign-up/, /name="email"/].every(patt => patt.test(content))
        : false
    )
  }, [])

  const [customFonts, setCustomFonts] = useState(getCustomFontArrayFromWidget(widget))
  const updateCustomFonts = useCallback((widget) => {
    setCustomFonts(getCustomFontArrayFromWidget(widget))
  }, [])
  const updateCustomFontsFromWidget = useCallback(() => {
    updateCustomFonts(widget)
  }, [updateCustomFonts, widget])
  const updateCustomFontsFromForm = useCallback(() => {
    if (form.current) {
      updateCustomFonts(_pick(form.current.getValues(), ['customFontValue', 'customFontURL']))
    }
  }, [updateCustomFonts, form])

  const [successMessagePreview, setSuccessMessagePreview] = useState(getSuccessMessageValuesFromWidget(widget))
  const updateSuccessMessagePreview = useCallback((widget = {}) => {
    setSuccessMessagePreview(getSuccessMessageValuesFromWidget(widget))
  }, [])
  const updateSuccessMessagePreviewFromWidget = useCallback(() => {
    updateSuccessMessagePreview(widget)
  }, [updateSuccessMessagePreview, widget])
  const updateSuccessMessagePreviewFromForm = useCallback(() => {
    if (form.current) {
      updateSuccessMessagePreview(_pick(form.current.getValues(), SUCCESS_MSG_FIELDS))
    }
  }, [updateSuccessMessagePreview, form])

  const [workingState, setWorkingState] = useState(widget || defaultState)
  const [changed, setChanged] = useState(false)
  const [resettingContent, setResettingContent] = useState(false)

  const getStoredState = useCallback(() => {
    let storedState
    try {
      const storedStateString = localStorage.getItem(storageId)
      if (storedStateString) {
        storedState = JSON.parse(storedStateString)
      }
    } catch (e) {}
    return storedState
  }, [storageId])

  const load = useCallback(async (storedState = getStoredState()) => {
    if (storedState) {
      await setWorkingState(storedState)
      return form.current.replaceValues(storedState, true)
    }
  }, [form, getStoredState])

  const resetStorage = useCallback(() => {
    localStorage.removeItem(storageId)
  }, [storageId])

  const reset = useCallback(async () => {
    const workingWidget = widget || defaultState
    setResettingContent(true)
    await setWorkingState(workingWidget)
    resetStorage()
    await form.current.resetFields()
    await form.current.refreshFieldElements()
    updateCustomFonts(workingWidget)
    updateSuccessMessagePreview(workingWidget)
    setChanged(false)
  }, [resetStorage, form, widget, updateCustomFonts, updateSuccessMessagePreview])

  const loadPrompt = useCallback(async () => {
    const storedState = getStoredState()
    if (storedState) {
      // Set state using previous creation attempt
      const workingState = {
        ...getSuccessMessageValuesFromWidget(), // Default success message values
        ...(widget || {}),
        ...storedState
      }
      await load(workingState)
      updateCustomFonts(workingState)
      updateSuccessMessagePreview(workingState)
      Modal.confirm({
        title: I18n.t('common.resumeConfirmDialog.title'),
        okText: I18n.t('common.resumeConfirmDialog.ok'),
        cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
        onCancel: reset
      })
    } else if (widget) {
      updateCustomFontsFromWidget()
      updateSuccessMessagePreviewFromWidget()
    }
  }, [getStoredState, widget, load, reset, updateCustomFontsFromWidget, updateSuccessMessagePreviewFromWidget, updateCustomFonts, updateSuccessMessagePreview])

  useEffect(() => {
    loadPrompt()
  }, [loadPrompt])

  useEffect(() => {
    if (changed) {
      const values = _pick(workingState, [
        'enabled', 'accessKey', 'content',
        'customFontValue', 'customFontURL',
        'successMessageTitle', 'successMessageTitleFontColor', 'successMessageTitleFontSize',
        'successMessageBody', 'successMessageBodyFontColor', 'successMessageBodyFontSize'
      ])
      localStorage.setItem(storageId, JSON.stringify(values))
    }
  }, [workingState, storageId, changed])

  const onChange = useCallback((name, value) => {
    const changed = name === 'content' ? !resettingContent && value && value.initialised === true : true
    if (changed) {
      setWorkingState({
        ...workingState,
        [name]: value
      })
    }
    setChanged(changed)
    if (name === 'content') {
      updateContentValid(value)
      if (resettingContent) {
        setResettingContent(false)
      }
    }
    if (SUCCESS_MSG_FIELDS.includes(name)) {
      updateSuccessMessagePreviewFromForm()
    }
  }, [updateContentValid, updateSuccessMessagePreviewFromForm, workingState, resettingContent])

  const onCustomFontBlur = useCallback((name) => {
    if (['customFontValue', 'customFontURL'].includes(name)) {
      updateCustomFontsFromForm()
    }
  }, [updateCustomFontsFromForm])

  const showInvalidContentAlert = useCallback(values => isEnabled(values) && contentValid !== true, [contentValid])

  const onSuccess = useCallback(async (result) => {
    const updatedWidget = _get(result, 'data.updateCompanySignUpWidget')
    if (form.current && updatedWidget) {
      await form.current.replaceValues(updatedWidget, true)
    }
    message.success(I18n.t('successMessage', trOpt))
    resetStorage()
    setChanged(false)

    // Clear widget cache on client server
    const { accessKey: prevAccessKey } = widget || {}
    const { accessKey } = updatedWidget || {}
    try {
      if (accessKey) {
        await axios.get(`${appOrigin}/risk-report-sign-up/cache/clear/${accessKey}`)
      }
      if (prevAccessKey && accessKey !== prevAccessKey) {
        await axios.get(`${appOrigin}/risk-report-sign-up/cache/clear/${prevAccessKey}`)
      }
    } catch {}
  }, [form, resetStorage, appOrigin, widget])

  const editorEmailField = {
    name: 'email',
    type: 'email',
    label: I18n.t('common.fields.email'),
    placeholder_text: I18n.t('common.fields.email'),
    show_label: true,
    required: true
  }
  const editorFields = [
    editorEmailField,
    {
      type: 'text',
      name: 'company_name',
      label: I18n.t('companyName', trOpt),
      placeholder_text: I18n.t('companyName', trOpt),
      value: '',
      options: '',
      show_label: true,
      required: true
    }, {
      type: 'text',
      name: 'company_domain',
      label: I18n.t('companyDomain', trOpt),
      placeholder_text: I18n.t('companyDomain', trOpt),
      value: '',
      options: '',
      show_label: true,
      required: true
    },
    {
      type: 'text',
      name: 'first_name',
      label: I18n.t('common.fields.firstName'),
      placeholder_text: I18n.t('common.fields.firstName', trOpt),
      value: '',
      options: '',
      show_label: true,
      required: true
    },
    {
      type: 'text',
      name: 'last_name',
      label: I18n.t('common.fields.lastName'),
      placeholder_text: I18n.t('common.fields.lastName', trOpt),
      value: '',
      options: '',
      show_label: true,
      required: true
    },
    {
      type: 'text',
      name: 'full_name',
      label: I18n.t('common.fields.fullName'),
      placeholder_text: I18n.t('common.fields.fullName', trOpt),
      value: '',
      options: '',
      show_label: true,
      required: true
    }
  ]

  const tabs = [{
    id: 'setup',
    title: I18n.t('setupTab', trOpt)
  }, {
    id: 'editor',
    title: I18n.t('editorTab', trOpt)
  }, {
    id: 'successMessage',
    title: I18n.t('successMessageTab', trOpt)
  }]

  const successMessageValues = getSuccessMessageValuesFromWidget(widget)

  const fields = [{
    id: 'enabled',
    label: I18n.t('enabled', trOpt),
    type: 'switch',
    defaultValue: _get(widget, 'enabled') === true,
    tab: 'setup'
  }, {
    id: 'accessKey',
    type: 'custom',
    component: AccessKeyField,
    required: true,
    visible: isEnabled,
    defaultValue: _get(widget, 'accessKey'),
    tab: 'setup',
    appOrigin
  }, {
    type: 'custom',
    component: SuccessMessagePreview,
    visible: isEnabled,
    tab: 'successMessage',
    ...successMessagePreview
  }, {
    id: 'successMessageTitle',
    type: 'text',
    label: I18n.t('successMessageTitle', trOpt),
    required: true,
    visible: isEnabled,
    defaultValue: successMessageValues.successMessageTitle,
    tab: 'successMessage'
  }, {
    id: 'successMessageTitleFontColor',
    type: 'colour',
    label: I18n.t('successMessageTitleFontColor', trOpt),
    required: true,
    visible: isEnabled,
    defaultValue: successMessageValues.successMessageTitleFontColor,
    useOverlay: false,
    tab: 'successMessage'
  }, {
    id: 'successMessageTitleFontSize',
    type: 'number',
    label: I18n.t('successMessageTitleFontSize', trOpt),
    required: true,
    visible: isEnabled,
    ...fontSizeValueFormatingProps,
    defaultValue: successMessageValues.successMessageTitleFontSize,
    tab: 'successMessage'
  }, {
    id: 'successMessageBody',
    type: 'textarea',
    label: I18n.t('successMessageBody', trOpt),
    required: true,
    visible: isEnabled,
    defaultValue: successMessageValues.successMessageBody,
    tab: 'successMessage'
  }, {
    id: 'successMessageBodyFontColor',
    type: 'colour',
    label: I18n.t('successMessageBodyFontColor', trOpt),
    required: true,
    visible: isEnabled,
    defaultValue: successMessageValues.successMessageBodyFontColor,
    useOverlay: false,
    tab: 'successMessage'
  }, {
    id: 'successMessageBodyFontSize',
    type: 'number',
    label: I18n.t('successMessageBodyFontSize', trOpt),
    required: true,
    visible: isEnabled,
    ...fontSizeValueFormatingProps,
    defaultValue: successMessageValues.successMessageBodyFontSize,
    tab: 'successMessage'
  }, (
    hasRiskReportUpdatePermission
      ? {
        id: 'content',
        label: I18n.t('common.fields.content'),
        type: 'unlayer',
        defaultValue: getValueFromWidget(widget, 'content'),
        visible: isEnabled,
        validate: validateContent,
        required: true,
        tab: 'editor',
        helpTitle: I18n.t('title', helpTrOpt),
        helpContent: <ContentEditorHelp />,
        customFonts,
        displayMode: 'web',
        designMode: 'edit',
        tools: {
          form: {
            properties: {
              fields: {
                editor: {
                  data: {
                    defaultFields: editorFields,
                    allowAddNewField: false
                  }
                }
              }
            },
            values: {
              _meta: {
                htmlClassNames: 'u_content_form usecure-prospect-sign-up'
              },
              fields: [editorEmailField]
            }
          }
        },
        customJS: [`${window.location.origin}/unlayer/web.js`]
      }
      : {
        id: 'content',
        type: 'custom',
        component: WidgetEditorReadOnlyPreview,
        accessKey: _get(widget, 'accessKey'),
        tab: 'editor',
        label: I18n.t('common.fields.content'),
        visible: isEnabled
      }
  ),
  {
    type: 'custom',
    component: InvalidContentAlert,
    visible: hasRiskReportUpdatePermission ? showInvalidContentAlert : false,
    tab: 'editor'
  },
  {
    id: 'customFontValue',
    type: 'text',
    label: I18n.t('customFontValue', trOpt),
    extra: I18n.t('customFontValueExtra', trOpt),
    visible: isEnabled,
    onBlur: onCustomFontBlur,
    defaultValue: getValueFromWidget(widget, 'customFontValue'),
    tab: 'editor',
    disabled: !hasRiskReportUpdatePermission
  }, {
    id: 'customFontURL',
    type: 'text',
    label: I18n.t('customFontURL', trOpt),
    extra: I18n.t('customFontURLExtra', trOpt),
    visible: isEnabled,
    onBlur: onCustomFontBlur,
    defaultValue: getValueFromWidget(widget, 'customFontURL'),
    tab: 'editor',
    disabled: !hasRiskReportUpdatePermission
  }]

  return (
    <>
      {!hasRiskReportUpdatePermission && <BannerMessage type='info' message={I18n.t('settings.settingsForm.readOnlyBanner')} />}
      <WidgetMutationForm
        ref={form}
        {...{ fields, tabs, onChange, onSuccess, mutateValues }}
        mutation={UPDATE_RISK_REPORT_SIGN_UP_WIDGET}
        refetchQueries={[{ query: GET_RISK_REPORT_SIGN_UP_WIDGET }]}
        submitLabel={I18n.t('common.save')}
        failureMessage={I18n.t('errorMessage', trOpt)}
        skipResetFieldsOnSubmit
        forceTabRender
        disableSubmit={!hasRiskReportUpdatePermission}
        readOnly={!hasRiskReportUpdatePermission}
      />
    </>
  )
}

const RiskReportSignUpSetup = ({ companyId, userId, appOrigin }) => {
  const { loading, error, data: { companySignUpWidget: widget } = {} } = useQuery(GET_RISK_REPORT_SIGN_UP_WIDGET, {
    fetchPolicy: 'cache-and-network'
  })

  let body = null
  if (loading) {
    body = <LoadingBlock fullScreen loading />
  } else if (error) {
    body = <ErrorAlerts error={error} defaultError={I18n.t('loadError', trOpt)} />
  } else {
    body = (
      <RiskReportSignUpSetupForm
        storageId={`riskReportSignUpSetup|${companyId}|${userId}`}
        {...{ widget, appOrigin }}
      />
    )
  }

  return (
    <Card>
      <IntercomHeader Size='h1' id='uService-risk-report-signup-header'>{I18n.t('title', trOpt)}</IntercomHeader>
      {body}
    </Card>
  )
}

export default connect(
  state => ({
    appOrigin: getAppOrigin(state),
    ..._pick(getSession(state), ['companyId', 'userId'])
  })
)(RiskReportSignUpSetup)
