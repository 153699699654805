import React, { useCallback } from 'react'
import { message, Modal } from 'antd'
import { Query } from 'react-apollo'
import I18n from 'i18n-js'

import { buildTree } from '../Learners/helpers'
import MutationForm from '../MutationForm/MutationForm'
import { GET_GROUP_TREE } from '../Queries/Groups'
import { ADD_LEARNERS_TO_GROUPS } from '../Queries/LearnerGroups'
import { invalidateLearnersQueryCache } from '../../helpers'
import { ErrorAlerts, LoadingBlock } from '../common'

const trOpt = { scope: 'modals.addLearnersToGroupModal' }

const AddLearnersToGroupModal = ({ learners = [], visible = false, setVisible = () => {}, refetchQueries, onClose = () => {} }) => {
  const form = React.createRef()
  const closeModal = useCallback(() => {
    setVisible(false)
    if (form && form.current) {
      form.current.resetFields()
    }
    onClose()
  }, [setVisible, form, onClose])

  return (
    <Modal visible={visible} onCancel={closeModal} footer={null} destroyOnClose>
      <h1>{I18n.t('addUsersToGroups', trOpt)}</h1>
      <Query query={GET_GROUP_TREE} skip={!visible}>
        {({ loading: getGroupsLoading, error: getGroupsError, data: getGroupsData = {} }) => {
          if (getGroupsLoading) return <LoadingBlock fullScreen={false} loading />
          const { groupTree = [] } = getGroupsData

          const groupTreeOptions = buildTree(groupTree)

          const fields = [
            {
              id: 'groupIds',
              label: I18n.t('modals.editLearnerModal.groups'),
              type: 'treeSelect',
              treeData: groupTreeOptions,
              multiple: true,
              required: true,
              requiredError: I18n.t('groupRequiredError', trOpt)
            }
          ]

          const onSuccess = () => {
            message.success(I18n.t('successMessage', trOpt))
            closeModal()
          }

          const learnerIds = learners.map(learner => learner.id)

          return (
            <>
              <ErrorAlerts
                error={getGroupsError}
                defaultError={I18n.t('common.groupsLoadError')}
              />
              <MutationForm
                mutation={ADD_LEARNERS_TO_GROUPS}
                onSuccess={onSuccess}
                failureMessage={I18n.t('errorMessage', trOpt)}
                submitLabel={I18n.t('common.save')}
                fields={fields}
                ref={form}
                variables={{ learnerIds: learnerIds }}
                refetchQueries={[...refetchQueries, { query: GET_GROUP_TREE, variables: { includeLearnerCount: true } }]}
                update={invalidateLearnersQueryCache}
              />
            </>
          )
        }}
      </Query>
    </Modal>
  )
}

export default AddLearnersToGroupModal
