import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled, { css } from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { useWindowSize } from '../../hooks/useWindowSize'
import { StyledLayout, StyledHeader, StyledContent } from '../Main'
import { getSessionAndSettings } from '../../state/selectors'
import { GET_OUTSTANDING_ACTIVITIES } from '../../components/Queries/Learners'
import { connect } from '../../hocs'
import { ContentWrap } from '../../components/common/Layout'
import NavBar from '../../components/EndUserPortal/NavBar'
import ErrorAlerts from '../../components/common/ErrorAlerts'
import { LoadingBlock } from '../../components/common'
import { CourseCards, PolicyCards } from '../../components/EndUserPortal/DashboardComponents.js'
import { Column, ConditionalLayout } from '../../components/EndUserPortal/GridFlexiLayout'
import { captureSentryError } from '../../helpers/sentry'
import { extractOutstandingCourses } from '../../helpers/endUser'

const trOpt = { scope: 'endUserPortal.dashboard' }
const trOptCommon = { scope: 'endUserPortal.common' }

const ActivityHeader = styled.span`
  font-weight: bold;
`
const ActivityColumn = styled(Column)`
  width: ${({ isMobile, has2Cols }) => has2Cols && !isMobile ? 'calc(50% - 7.5px)' : '100%'};
`
const ScrollingColumn = styled(Column)`
  ${({ isMobile }) => {
    if (!isMobile) {
      return css`
  height: calc( 100vh - 165px);
  overflow-y: auto;

  & > *:last-child {
    margin-bottom: 15px;
  }
      `
    }
  }}
`

const OutstandingActivitiesHome = ({ session, settings }) => {
  const { type: screenType } = useWindowSize()
  const isMobile = ['xs', 'sm'].includes(screenType)
  const [outstandingCourses, setOutstandingCourses] = useState([])
  const [outstandingPolicies, setOutstandingPolicies] = useState([])
  const learnerId = _get(session, 'learnerId')

  const { loading, error, data } = useQuery(GET_OUTSTANDING_ACTIVITIES, {
    variables: {
      companyId: session.companyId,
      learnerId: session.learnerId,
      onlyPending: true
    },
    onError: error => {
      captureSentryError(error, { msg: 'User ID Access - Home Load - ERROR' })
    }
  })

  useEffect(() => {
    if (data) {
      setOutstandingCourses(
        extractOutstandingCourses(_get(data, 'outstandingActivities.courseResults'))
          .sort((a, b) => a.course.name.localeCompare(b.course.name))
      )
      setOutstandingPolicies(
        _get(data, 'outstandingActivities.policyResults').sort((a, b) => a.policy.name.localeCompare(b.policy.name))
      )
    }
  }, [data])

  const has2Cols = settings.uLearn && settings.uPolicy

  return (
    <>
      <LoadingBlock loading={loading} fullScreen={false} showMenu />
      {error && <ContentWrap><ErrorAlerts error={error} defaultError={I18n.t('defaultPageError', trOptCommon)} /></ContentWrap>}
      {data && (
        <StyledLayout>
          <StyledHeader>
            <NavBar />
          </StyledHeader>
          <StyledContent>
            <ContentWrap disableScrolling={!isMobile}>
              <h2>{I18n.t('welcomeBack', { ...trOptCommon, firstName: session.firstName })}</h2>
              <ConditionalLayout isRow={!isMobile}>
                {settings.uPolicy && (
                  <ActivityColumn flex={1} {...{ has2Cols, isMobile }}>
                    <ActivityHeader>{I18n.t('outstandingPolicy', trOpt)}</ActivityHeader>
                    <ScrollingColumn isMobile={isMobile}>
                      <PolicyCards {...{ learnerId, outstandingPolicies, flex: 'null' }} />
                    </ScrollingColumn>
                  </ActivityColumn>
                )}
                {settings.uLearn && (
                  <ActivityColumn flex={1} className='align' alignItems='stretch' {...{ has2Cols, isMobile }}>
                    <ActivityHeader>{I18n.t('outstandingCourse', trOpt)}</ActivityHeader>
                    <ScrollingColumn isMobile={isMobile} className='align' alignItems='stretch'>
                      <CourseCards flex={0} {...{ outstandingCourses, learnerId }} />
                    </ScrollingColumn>
                  </ActivityColumn>
                )}
              </ConditionalLayout>
            </ContentWrap>
          </StyledContent>
        </StyledLayout>
      )}
    </>
  )
}
export default connect(
  getSessionAndSettings
)(OutstandingActivitiesHome)
