import React, { useState, useEffect, useCallback } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { message, Button, Card, Modal, Input, Icon } from 'antd'
import I18n from 'i18n-js'

import { Loader } from '../common'
import { GET_QR, VERIFY_QR, CREATE_AUTHENTICATOR_TOKEN } from '../Queries/Users'
import { modalConfirmAsync, renderToString, showErrors } from '../../helpers'

const { Meta } = Card
const trOpt = { scope: 'accountSettings.QrCode' }

const QRCode = ({ setCurrentStep, enable2FA, disable2FA }) => {
  const [authCode, setAuthCode] = useState('')
  const { loading: getQRLoading, data: getQRData, refetch: getQR } = useQuery(GET_QR)
  const [createAuthenticatorToken] = useMutation(CREATE_AUTHENTICATOR_TOKEN, {
    onCompleted: () => getQR()
  })

  const [verifyQR] = useMutation(VERIFY_QR, {
    onCompleted: useCallback(async (result) => {
      if (result?.verifyQR?.qrVerified === true) {
        await enable2FA()
        message.success(I18n.t('twoFAEnabled', trOpt))
      } else {
        message.error(I18n.t('failedTwoFAVerification', trOpt))
      }
    }, [enable2FA]),
    onError: e => showErrors(e, I18n.t('failedTwoFAVerification', trOpt))
  })

  const handleChange = useCallback(e => {
    setAuthCode(e.target.value)
  }, [])

  const handleSubmit = useCallback(async () => {
    await verifyQR({ variables: { authCode } })
  }, [verifyQR, authCode])

  const handleKeyUp = useCallback(event => {
    if (event.keyCode === 13) {
      handleSubmit()
    }
  }, [handleSubmit])

  const showInfoModal = useCallback(() => {
    Modal.info({
      title: I18n.t('qrKey', trOpt),
      icon: <Icon type='qrcode' />,
      content: (
        <h5>{getQRData.qr.token}</h5>
      )
    })
  }, [getQRData])

  const handleCancelClick = useCallback(async () => {
    const confirm = await modalConfirmAsync({
      title: I18n.t('common.cancelConfirmDialog')
    })
    if (confirm) {
    // Reset any changes made by a partial setup
      try {
        await disable2FA()
      } catch (e) {
      // Silent failure
        console.error('QRCode.handleCancelClick - 2FA Setup Cancel - ERROR', e)
      }
      setCurrentStep('disabled')
    }
  }, [setCurrentStep, disable2FA])

  useEffect(() => {
    createAuthenticatorToken()
  }, [createAuthenticatorToken])

  return (
    <div>
      <Card>
        <Meta
          title={I18n.t('accountSettings.common.stepTitle', { number: 2, title: I18n.t('scanThisBarcodeWithYourApp', trOpt) })}
          description={
            // The paragraph is clickable as it was the simplest way I could think of to restrict this to 2 i18n keys - one for the overall copy and another for the 'clickable' bit
            // This code originally used 3 keys which led to syntax issues when translated.
            <p
              onClick={showInfoModal}
              dangerouslySetInnerHTML={{
                __html: I18n.t('scanTheImageBelow', {
                  ...trOpt,
                  link: renderToString(<span style={{ color: 'blue', cursor: 'pointer' }}>{I18n.t('enterThisTextCode', trOpt)}</span>)
                })
              }}
            />
          }
        />
        <div style={{ width: '100%', marginTop: '25px', marginBottom: '45px' }}>
          {getQRLoading ? <Loader /> : getQRData && getQRData.qr && (
            <Card style={{ width: '220px', height: '220px', margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img alt='qrcode' src={getQRData.qr.imagePath} style={{ height: '200px', width: '200px' }} />
            </Card>
          )}
        </div>
        <h5>{I18n.t('enterTheSixDigitCodeFromTheApplication', trOpt)}</h5>
        <p>{I18n.t('afterScanningTheBarcodeImage', trOpt)}</p>
        <Input placeholder='123456' value={authCode} onChange={handleChange} onKeyUp={handleKeyUp} minLength={6} maxLength={6} />
      </Card>
      <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
        <Button type='primary' onClick={handleSubmit}>{I18n.t('common.enable')}</Button>
        <Button onClick={handleCancelClick}>{I18n.t('common.cancel')}</Button>
      </div>
    </div>
  )
}

export default QRCode
