/* global localStorage */
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { dataIdFromObject } from './caching'
import wsLink from './wsLink'
import timezoneLink from './timezoneLink'
import { endUserSignOut } from '../helpers/SignOut'

const terminatingLink = wsLink

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('endUserToken')

    if (token) {
      headers = { ...headers, 'x-token': token }
    }

    return { headers }
  })

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path = [], extensions = {} }) => {
      const { code } = extensions

      console.error(message, {
        code,
        path
      })

      if (code === 'UNAUTHENTICATED' && (path.length === 0 || path.includes('learnerMe'))) {
        endUserSignOut(client)
      }
    })
  }

  if (networkError) {
    console.log('Network error', networkError)

    if (networkError.statusCode === 401) {
      endUserSignOut(client)
    }
  }
})

const link = ApolloLink.from([authLink, errorLink, timezoneLink, terminatingLink])

const cache = new InMemoryCache({
  dataIdFromObject
})

const client = new ApolloClient({
  link,
  cache
})

export default client
