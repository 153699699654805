import React, { useMemo } from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import _lowerCase from 'lodash/lowerCase'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'
import { compose } from 'recompose'

import { BannerMessage, ContentWrap, LoadingBlock } from '../../../components/common'
import ULearnHeader from './uLearnHeader'
import ULearnBarChart from './uLearnBarChart'
import ULearnPieChart from './uLearnPieChart'
import ULearnTable from './uLearnTable'
import DashboardStatistic from '../../../components/Dashboard/DashboardStatistic'
import { ULEARN_REPORT } from '../../../components/Queries/Reports'
import routes from '../../../constants/routes'
import { connect } from '../../../hocs'
import selectors from '../../../state/selectors'

const trOpt = { scope: 'reports.uLearnReport' }

const ULearnReportGrid = styled.div`
    display: grid;
    grid-template-areas: 
            "topbar topbar topbar topbar topbar topbar topbar topbar " 
            "average-time average-time  started started completed completed  average-score average-score "
            "bar-chart  bar-chart bar-chart bar-chart bar-chart pie-chart pie-chart pie-chart"
            "table table table table table table table table ";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-template-rows: auto 260px auto auto;
    grid-gap: 25px;`

const ULearnItem = styled.div`
background-color: white;
padding: 30px 40px;
`

const ULearnReport = (props) => {
  const { learnerId, courseId } = props.match.params
  const isGapAnalysis = useMemo(() => _get(props, 'match.path') === routes.GAP_ANALYSIS_REPORT, [props])

  const { excludeInactiveUsersInReports } = props
  const {
    loading,
    error,
    data: { uLearnReport } = {}
  } = useQuery(ULEARN_REPORT, { variables: { learnerId, courseId, excludeInactiveUsers: excludeInactiveUsersInReports, isGapAnalysis } })

  const { learnerName, learnerEmail, courseName, courseSubject } = useMemo(() => uLearnReport || {}, [uLearnReport])
  if (error) {
    return (
      <div>{error.graphQLErrors.map(({ message }, index) => (
        <BannerMessage message={message} key={index} />
      ))}
      </div>
    )
  }

  return (
    <>
      {loading && <LoadingBlock fullScreen loading={loading} showMenu />}
      <ContentWrap>
        <ULearnReportGrid>
          <ULearnHeader {...{ learnerName, learnerEmail, learnerId, courseName, courseSubject, isGapAnalysis }} />
          {uLearnReport && (
            <>
              <ULearnItem style={{ gridArea: 'average-time' }}>
                <DashboardStatistic
                  hoverText={I18n.t('learnersAveragedATimeOf', { averageTimeFromStart: _lowerCase(uLearnReport.courseAverages.averageTimeFromStart), averageTimeFromEnrollment: uLearnReport.courseAverages.averageTimeFromEnrollment, ...trOpt })}
                  isValue
                  title={I18n.t('averageTimeTaken', trOpt)}
                  extraTitle={I18n.t('fromEnrollment', trOpt)}
                  extraData={uLearnReport.courseAverages.averageTimeFromEnrollment}
                  value={uLearnReport.courseAverages.averageTimeFromStart}
                />
              </ULearnItem>
              <ULearnItem style={{ gridArea: 'started' }}>
                <DashboardStatistic
                  hoverText={I18n.t('totalCoursesStarted', { count: uLearnReport.courseTotals.totalCoursesStarted, totalCourseResults: uLearnReport.courseTotals.totalCourseResults, ...trOpt })}
                  title={I18n.t('started', trOpt)}
                  percentage={Math.floor(uLearnReport.courseTotals.totalCoursesStarted * 100 / uLearnReport.courseTotals.totalCourseResults)}
                  percentageDescription={I18n.t('ofCoursesHaveBeenStarted', trOpt)}
                  extraTitle={I18n.t('total', trOpt)}
                  extraData={uLearnReport.courseTotals.totalCoursesStarted}
                />
              </ULearnItem>
              <ULearnItem style={{ gridArea: 'completed' }}>
                <DashboardStatistic
                  hoverText={I18n.t('totalCoursesCompleted', { count: uLearnReport.courseTotals.totalCoursesFinished, totalCourseResults: uLearnReport.courseTotals.totalCourseResults, ...trOpt })}
                  title={I18n.t('completed', trOpt)}
                  percentage={Math.floor(uLearnReport.courseTotals.totalCoursesFinished * 100 / uLearnReport.courseTotals.totalCourseResults)}
                  percentageDescription={I18n.t('ofCoursesHaveBeenCompleted', trOpt)}
                  extraTitle={I18n.t('total', trOpt)}
                  extraData={uLearnReport.courseTotals.totalCoursesFinished}
                />
              </ULearnItem>
              <ULearnItem style={{ gridArea: 'average-score' }}>
                <DashboardStatistic
                  hoverText={I18n.t('learnersAveragesAScoreOf', { averageScore: Math.floor(uLearnReport.courseTotals.totalScore / uLearnReport.courseTotals.totalCoursesFinished), count: uLearnReport.courseTotals.totalCoursesFinished, ...trOpt })}
                  title={I18n.t('averageScore', trOpt)}
                  percentage={Math.floor(uLearnReport.courseTotals.totalScore / uLearnReport.courseTotals.totalCoursesFinished)}
                  percentageDescription={I18n.t('isTheAverageScoreForAllCourses', trOpt)}
                  extraTitle={I18n.t('totalCoursesEnrolled', trOpt)}
                  extraData={uLearnReport.courseTotals.totalCourseResults}
                />
              </ULearnItem>
              <ULearnItem style={{ gridArea: 'bar-chart' }}>
                <ULearnBarChart scoreBarChartData={uLearnReport.scoreBarChartData} />
              </ULearnItem>
              <ULearnItem style={{ gridArea: 'pie-chart' }}>
                <ULearnPieChart participationPieChartData={uLearnReport.participationPieChartData} />
              </ULearnItem>
              <ULearnItem style={{ gridArea: 'table' }}>
                <ULearnTable
                  learnerTableData={uLearnReport.learnerTableData}
                  courseTableData={uLearnReport.courseTableData}
                  learnerName={uLearnReport.learnerName}
                  courseName={uLearnReport.courseName}
                  {...{ isGapAnalysis, learnerId, courseId, excludeInactiveUsersInReports }}
                />
              </ULearnItem>
            </>
          )}
        </ULearnReportGrid>
      </ContentWrap>
    </>
  )
}

export default compose(
  withRouter,
  connect(
    state => ({
      ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
    })
  )
)(ULearnReport)
