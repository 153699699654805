/* global FileReader */
import axios from 'axios'

const FONT_BASE_URL = {
  s3: 'https://usecure-static.s3.eu-west-1.amazonaws.com/webfonts',
  usecureClient: `${window.location.origin}/webfonts`
}

export class JsPdfFontLoader {
  constructor () {
    this.fonts = {}
  }

  async getBase64FromBlob (blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = () => resolve(reader.result.split(',')[1]) // The split pulls the base64 string from the data url
      reader.onerror = error => reject(error)
    })
  }

  async getFontAsBase64 (url) {
    const response = await axios.get(url, {
      responseType: 'blob'
    })
    return this.getBase64FromBlob(response.data)
  }

  getFontId (id, fontStyle) {
    return `%${id}%__%${fontStyle}%`
  }

  async loadFont ({ id, fontStyle, filename, url, forceLoad = false, fileSource = 's3' } = {}) {
    const fontId = this.getFontId(id, fontStyle)
    if (this.fonts[fontId] && !forceLoad) return

    if (filename && !url && FONT_BASE_URL[fileSource]) {
      url = `${FONT_BASE_URL[fileSource]}/${filename}`
    }
    const base64 = await this.getFontAsBase64(url)

    this.fonts[fontId] = { filename, id, fontStyle, base64 }
  }

  async addFontToDoc (doc, { id, fontStyle, filename, url, forceLoad = false, baseUrl } = {}) {
    await this.loadFont({ id, fontStyle, filename, url, forceLoad, baseUrl })
    const { base64 } = this.fonts[this.getFontId(id, fontStyle)]
    doc.addFileToVFS(filename, base64)
    doc.addFont(filename, id, fontStyle)
  }
}

export const jsPdfFontLoader = new JsPdfFontLoader()

export const addFontToJsPdfDoc = async (doc, { id, fontStyle, filename, url, forceLoad = false, baseUrl } = {}) =>
  jsPdfFontLoader.addFontToDoc(doc, { id, fontStyle, filename, url, forceLoad, baseUrl })

const LOCALE_JS_PDF_FONTS = {
  NotoSansSC: {
    id: 'NotoSansSC',
    // CL - These files are 18MB which is a bit big for web use
    fontStyles: {
      normal: { filename: 'NotoSansCJKsc-Regular.ttf' },
      bold: { filename: 'NotoSansCJKsc-Bold.ttf' }
    },
    locales: ['zh']
  }
}

export const addLocaleFontToJsPdfDoc = async (doc, locale, { loadBold = false, forceLoad = false } = {}) => {
  let id
  if (locale) {
    const localeFont = Object.values(LOCALE_JS_PDF_FONTS).find(({ locales }) => locales.includes(locale))
    if (localeFont) {
      ({ id } = localeFont)
      const { fontStyles: { normal, bold } } = localeFont
      await addFontToJsPdfDoc(doc, { id, fontStyle: 'normal', forceLoad, ...normal })
      if (loadBold) {
        await addFontToJsPdfDoc(doc, { id, fontStyle: 'bold', forceLoad, ...bold })
      }
    }
  }
  return id
}
