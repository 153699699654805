import React, { useCallback, useMemo, useState, useImperativeHandle, useEffect } from 'react'
import { Table as _Table, Tag, Modal, Button, Popover } from 'antd'
import { generatePath } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import SimulationActions from './SimulationActions'
import routes from '../../constants/routes'
import { processTableData } from '../../helpers'
import ViewSimulation from '../../views/uPhish/ViewSimulation'
import { ModalPager } from '../common'
import { onListTableSelectAll, onListTableChange } from '../../helpers/listPages'
import { LIST_PAGINATION_PROPS } from '../../constants/list'
import TooltipIcon from '../common/TooltipIcon'
import { UPHISH_ATTACK_TYPES, UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP } from '../../constants/uPhish'

const trOpt = { scope: 'uPhish.simulationsTable' }
const simsTrOpt = { scope: 'uPhish.simulations' }
const SIMULATION_STATUS = ['pending', 'started', 'finished']
const SIMULATION_STATUS_KEYS = {
  pending: 'common.pending',
  started: 'common.started',
  finished: `${trOpt.scope}.finished`
}

const SimulationModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-modal-content {
    height: 100%;
  }
`

const SimulationModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const SimulationNameLinkButton = styled(Button)`
  padding: 0;

  .ant-tag {
    margin-left: 5px;
  }
`
const SimulationNameLink = ({ simulation, onClick: onClickProp = () => {} }) => {
  const { id, name, autoPhish, onRiskReport } = simulation

  const onClick = useCallback(() => onClickProp(id), [onClickProp, id])

  return (
    <SimulationNameLinkButton type='link' onClick={onClick}>
      {name}
      {autoPhish && <Tag color='blue' key={id}>{I18n.t('auto', trOpt)}</Tag>}
      {onRiskReport && <Tag>{I18n.t('riskReportTag', trOpt)}</Tag>}
    </SimulationNameLinkButton>
  )
}

const StatusTitleCell = styled.div`
  width: 60px;

  .anticon {
    color: #bfbfbf;
    font-size: 12px;
    margin-left: 5px;
  }

  h4 {
    font-size: 3px;
  }
`

const _StatusText = ({ className }) => (
  <div {...{ className }}>
    <h4>{I18n.t(SIMULATION_STATUS_KEYS.pending)}</h4>
    <p>{I18n.t('pendingInfo', trOpt)}</p>
    <h4>{I18n.t(SIMULATION_STATUS_KEYS.started)}</h4>
    <p>{I18n.t('startedInfo', trOpt)}</p>
    <h4>{I18n.t(SIMULATION_STATUS_KEYS.finished)}</h4>
    <p>{I18n.t('finishedInfo', trOpt)}</p>
  </div>
)
const StatusText = styled(_StatusText)`
  display: inline-block;
  width: 250px;

  h4 {
    font-size: 14px;
    margin-bottom: 3px;
  }
  p {
    font-family: ${props => props.theme.baseFont};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StatusTitle = () => (
  <StatusTitleCell>
    <span>{I18n.t('common.status')}</span>
    <Popover
      title={I18n.t('simulationStatus', trOpt)}
      content={<StatusText />}
    >
      <span><TooltipIcon /></span>
    </Popover>
  </StatusTitleCell>
)

const Table = styled(_Table)`
  .ant-table {
    overflow-x: auto;
  }
`

const SimulationsTable = ({
  history, simulations = [], selectedSimulationIds = [], updateSelectedSimulationIds, loading = false,
  sorter, updateSorter,
  filters, updateFilters,
  pagination, updatePagination, routeSimulationId,
  showPhishAlert = false,
  performAction = () => {},
  showMultiSelectActions = true
}, ref) => {
  const [showModal, updateShowModal] = useState(false)
  const [viewSimulation, updateViewSimulation] = useState(null)

  const openSimulationModal = useCallback(simulationId => {
    updateViewSimulation(simulationId)
    updateShowModal(true)
  }, [updateViewSimulation, updateShowModal])
  const openSimulation = useCallback(simulationId => {
    history.push(generatePath(routes.UPHISH_SIM, { simulation_id: simulationId }))
  }, [history])

  const renderActionsCell = useCallback((actions, simulation) => (
    <SimulationActions actions={actions} simulationId={simulation.id} performAction={performAction} />
  ), [performAction])

  useImperativeHandle(ref, () => ({
    openSimulation
  }), [openSimulation])

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}

    const cols = [
      {
        title: I18n.t('simulation', trOpt),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortColumnKey === 'name' && order,
        render: (text, simulation) => (<SimulationNameLink simulation={simulation} onClick={openSimulation} />)
      }, {
        title: I18n.t('uPhish.common.attackType'),
        dataIndex: 'attackType',
        key: 'attackType',
        filters: UPHISH_ATTACK_TYPES.map(value => ({ value, text: I18n.t(`uPhish.common.attackTypes.${UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP[value]}`) })),
        onFilter: (value, record) => value === record.attackType,
        render: (text) => I18n.t(`uPhish.common.attackTypes.${UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP[text]}`)
      }, {
        title: I18n.t('common.recipients'),
        dataIndex: 'recipients',
        key: 'recipients',
        sorter: (a, b) => a.recipients - b.recipients,
        sortOrder: sortColumnKey === 'recipients' && order
      }, {
        title: StatusTitle,
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => SIMULATION_STATUS.indexOf(a.status) - SIMULATION_STATUS.indexOf(b.status),
        sortOrder: sortColumnKey === 'status' && order,
        filters: SIMULATION_STATUS.map(status => ({ value: status, text: I18n.t(SIMULATION_STATUS_KEYS[status]) })),
        onFilter: (value, record) => record.status === value,
        filteredValue: _get(filters, 'status', null),
        render: (text, simulation) => (
          <div>{I18n.t(SIMULATION_STATUS_KEYS[simulation.status])}</div>
        )
      }, {
        title: I18n.t('uPhish.common.startDate'),
        dataIndex: 'startDateStr',
        key: 'startDateStr',
        sorter: (a, b) => {
          const aMoment = moment(a.startDate)
          const bMoment = moment(b.startDate)
          if (aMoment.isAfter(bMoment)) {
            return 1
          } else if (aMoment.isBefore(bMoment)) {
            return -1
          }
          return 0
        },
        sortOrder: sortColumnKey === 'startDateStr' && order
      }, {
        title: I18n.t('uPhish.common.endDate'),
        dataIndex: 'endDateStr',
        key: 'endDateStr',
        sorter: (a, b) => {
          if (a.endDate && b.endDate) {
            const aMoment = moment(a.endDate)
            const bMoment = moment(b.endDate)
            if (aMoment.isAfter(bMoment)) {
              return 1
            } else if (aMoment.isBefore(bMoment)) {
              return -1
            }
          } else if (a.endDate && !b.endDate) {
            return 1
          } else if (!a.endDate && b.endDate) {
            return -1
          }
          return 0
        },
        sortOrder: sortColumnKey === 'endDateStr' && order
      }, {
        title: I18n.t('common.sent'),
        dataIndex: 'sent',
        key: 'sent',
        align: 'center',
        sorter: (a, b) => a.sent - b.sent,
        sortOrder: sortColumnKey === 'sent' && order
      }, {
        title: I18n.t('common.opened'),
        dataIndex: 'opened',
        key: 'opened',
        align: 'center',
        sorter: (a, b) => a.opened - b.opened,
        sortOrder: sortColumnKey === 'opened' && order
      }, {
        title: I18n.t('common.visited'),
        dataIndex: 'visited',
        key: 'visited',
        align: 'center',
        sorter: (a, b) => a.visited - b.visited,
        sortOrder: sortColumnKey === 'visited' && order
      }, {
        title: I18n.t('common.compromised'),
        dataIndex: 'compromised',
        key: 'compromised',
        align: 'center',
        sorter: (a, b) => a.compromised - b.compromised,
        sortOrder: sortColumnKey === 'compromised' && order
      }, {
        dataIndex: 'actions',
        key: 'actions',
        render: renderActionsCell
      }
    ]

    if (showPhishAlert) {
      // simsTrOpt
      cols.splice(9, 0, {
        title: I18n.t('reported', simsTrOpt),
        dataIndex: 'reported',
        key: 'reported',
        align: 'center',
        sorter: (a, b) => a.reported - b.reported,
        sortOrder: sortColumnKey === 'reported' && order
      })
    }

    return cols
  }, [renderActionsCell, sorter, filters, openSimulation, showPhishAlert])

  const onSelectChange = useCallback(selectedRowKeys => updateSelectedSimulationIds(selectedRowKeys), [updateSelectedSimulationIds])
  const onSelectAll = useCallback(selected => {
    onListTableSelectAll({ selected, updateSelectedIds: updateSelectedSimulationIds, filters, columns, records: simulations })
  }, [simulations, updateSelectedSimulationIds, filters, columns])

  const onTableChange = useCallback((pagination, updatedFilters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: updatedFilters,
      updateFilters,
      onSelectAll
    })
  }, [updatePagination, updateSorter, updateFilters, filters, onSelectAll])

  const closeModal = useCallback(() => updateShowModal(false), [updateShowModal])
  const afterClose = useCallback(() => {
    updateViewSimulation(null)
    history.push(routes.UPHISH_SIMS)
  }, [history, updateViewSimulation])

  const { simulationCount, currentSimulationNum, prevSimulation, nextSimulation } = useMemo(() => {
    let currentSimulationNum
    let prevSimulation = null
    let nextSimulation = null

    const tSimulations = processTableData(columns, simulations)
    if (viewSimulation && tSimulations.length > 1) {
      const index = tSimulations.findIndex(({ id }) => id === viewSimulation)
      if (index !== -1) {
        currentSimulationNum = index + 1
        prevSimulation = index - 1
        prevSimulation = prevSimulation < 0 ? tSimulations.length - 1 : prevSimulation
        prevSimulation = (tSimulations[prevSimulation] || { id: null }).id
        nextSimulation = index + 1
        nextSimulation = nextSimulation > tSimulations.length - 1 ? 0 : nextSimulation
        nextSimulation = (tSimulations[nextSimulation] || { id: null }).id
      }
    }

    return {
      simulationCount: tSimulations.length,
      currentSimulationNum,
      prevSimulation,
      nextSimulation
    }
  }, [viewSimulation, simulations, columns])

  useEffect(() => {
    if (routeSimulationId) {
      openSimulationModal(routeSimulationId)
    } else {
      closeModal()
    }
  }, [routeSimulationId, openSimulationModal, closeModal])

  return (
    <>
      <Table
        columns={columns} dataSource={simulations} loading={loading}
        onChange={onTableChange}
        rowSelection={showMultiSelectActions ? {
          selectedRowKeys: selectedSimulationIds,
          onChange: onSelectChange,
          onSelectAll
        } : undefined}
        pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
      />
      <SimulationModal
        width='90%'
        bodyStyle={{ paddingTop: 5, height: 'calc(100% - 60px)' }}
        visible={showModal}
        onOk={closeModal}
        onCancel={closeModal}
        afterClose={afterClose}
        footer={null}
        destroyOnClose
        title={<ModalPager count={simulationCount || 1} current={currentSimulationNum} prev={prevSimulation} next={nextSimulation} onClick={openSimulation} />}
      >
        <SimulationModalBody>
          <ViewSimulation id={viewSimulation} modal persist />
        </SimulationModalBody>
      </SimulationModal>
    </>
  )
}

const SimulationsTableWithForwardRef = React.forwardRef(SimulationsTable)

export default SimulationsTableWithForwardRef
