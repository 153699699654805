import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Switch } from 'antd'
import styled from 'styled-components'

import MutationFormErrors from './MutationFormErrors'

const SwitchFormItem = styled(Form.Item)`
  .ant-form-item-children {
    align-items: baseline;
    display: flex;
  }
`

const InlineLabel = styled.span`
  display: inline-block;
  line-height: 1.5;
  margin-left: 15px;
`

class MutationFormSwitch extends Component {
  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    this.props.onChange(this.props.id, event)
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, label, checked, inlineLabel, checkedChildren, unCheckedChildren, formItemStyle, disabled, required, extra, errors = [] } = this.props
    const showErrors = errors.length > 0

    return (
      <SwitchFormItem
        label={inlineLabel ? undefined : label} style={formItemStyle} required={required} extra={extra}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <Switch
          onChange={this.handleChange}
          name={id}
          {...{ checked, checkedChildren, unCheckedChildren, disabled }}
        />
        {
          label && inlineLabel && <InlineLabel>{label}</InlineLabel>
        }
      </SwitchFormItem>
    )
  }
}

MutationFormSwitch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  inlineLabel: PropTypes.bool,
  checkedChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  uncheckedChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  formItemStyle: PropTypes.object,
  extra: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.func])
}

MutationFormSwitch.defaultProps = {
  id: '',
  label: null,
  onChange: () => {},
  checked: false,
  inlineLabel: false,
  checkedChildren: null,
  uncheckedChildren: null,
  extra: null
}

export default MutationFormSwitch
