import React, { useCallback } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import I18n from 'i18n-js'
import { get as _get } from 'lodash/object'

import withConsumer from './withConsumer'
import withRefreshSessionState from './withRefreshSessionState'
import routes from '../constants/routes'
import { setSessionToken, postSessionChannelMessage } from '../helpers/session'

const setLocalToken = (queryResult, tokenPath) => {
  const token = _get(queryResult, tokenPath)
  if (!token) {
    throw Error(I18n.t('signIn.noAuthTokenError'))
  }

  setSessionToken(token)

  return true
}

const withLogin = Component => props => {
  const { refreshSessionState, history } = props

  const handleRedirect = useCallback(async (redirectPath) => {
    await refreshSessionState(false)
    redirectPath = typeof redirectPath === 'undefined' ? routes.HOME : redirectPath
    if (redirectPath) {
      history.push(redirectPath)
    }
  }, [refreshSessionState, history])
  const onSuccess = useCallback(async (queryResult, tokenPath = '', redirectPath) => {
    const tokenSet = setLocalToken(queryResult, tokenPath)
    if (tokenSet) {
      await handleRedirect(redirectPath)
      postSessionChannelMessage('sessionRefresh')
    }
  }, [handleRedirect])

  return (
    <Component {...{ onSuccess, setLocalToken, handleRedirect, refreshSessionState, ...props }} />
  )
}

export default compose(
  withRouter,
  withConsumer,
  withRefreshSessionState,
  withLogin
)
