import React, { useCallback } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { compose } from 'recompose'

import connect from './connect'
import { getInitialSessionStateFromMe } from '../helpers'
import { sessionActionsDispatcher } from '../components/SessionDispatcher'

// Provides a function to refresh the session and settings global state from a me query
// This will do things like update the global theme or pull in a language change
const withRefreshSessionState = Comp => {
  const WithRefreshSessionState = ({ forwardRef, ...props }) => {
    const client = useApolloClient()
    const { initialiseSession } = props
    const refreshSessionState = useCallback(async (throwErrorOnFailedGetMe = false) => {
      try {
        const sessionAndSettings = await getInitialSessionStateFromMe(client, throwErrorOnFailedGetMe)
        initialiseSession(sessionAndSettings)
      } catch (e) {
        if (throwErrorOnFailedGetMe) {
          throw e
        }
        console.error(e)
      }
    }, [initialiseSession, client])

    return (
      <Comp ref={forwardRef} {...props} refreshSessionState={refreshSessionState} />
    )
  }

  return React.forwardRef((props, ref) => (
    <WithRefreshSessionState {...props} forwardRef={ref} />
  ))
}

export default compose(
  connect(
    undefined,
    sessionActionsDispatcher
  ),
  withRefreshSessionState
)
