import React from 'react'
import { Tooltip } from 'antd'
import _isEmpty from 'lodash/isEmpty'
import FontAwesomeIcon from '../common/FontAwesomeIcon'
import I18n from 'i18n-js'

const syncTypeOptions = {
  microsoft: { icon: 'microsoft', tooltipTrKey: 'learner.M365IndicatorTooltip' },
  google: { icon: 'google', tooltipTrKey: 'learner.GoogleIndicatorTooltip' }
}
const SyncIndicator = ({ syncRecordId, syncType, tooltipPlacement = 'right' }) => {
  const syncTypeAttributes = syncTypeOptions[syncType]
  return (syncRecordId && !_isEmpty(syncTypeAttributes))
    ? (
      <Tooltip
        title={
          <>
            <div>{I18n.t(syncTypeAttributes.tooltipTrKey)}</div>
            <div>{syncRecordId}</div>
          </>
        }
        placement={tooltipPlacement}
        overlayStyle={{ maxWidth: 300 }}
      >
        <span>
          <FontAwesomeIcon icon={syncTypeAttributes.icon} />
        </span>
      </Tooltip>
    )
    : null
}

export default SyncIndicator
