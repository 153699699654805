import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import wsLink from './wsLink'
import timezoneLink from './timezoneLink'
import { dataIdFromObject } from './caching'
import { signOut } from '../helpers/SignOut'
import { getSessionToken } from '../helpers/session'

const terminatingLink = wsLink

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = getSessionToken()

    if (token) {
      headers = { ...headers, 'x-token': token }
    }

    return { headers }
  })

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  let signOutRequired = false
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path = [], extensions = {} }) => {
      const { code } = extensions

      console.error(message, {
        code,
        path
      })

      signOutRequired = signOutRequired || (code === 'UNAUTHENTICATED' && (path.length === 0 || path.includes('me')))
    })
  }

  if (networkError) {
    console.error('Network error', networkError)
    signOutRequired = signOutRequired || networkError.statusCode === 401
  }

  if (signOutRequired) {
    signOut(client)
  }
})

const link = ApolloLink.from([authLink, errorLink, timezoneLink, terminatingLink])

const cache = new InMemoryCache({
  dataIdFromObject
})

const client = new ApolloClient({
  link,
  cache
})

export default client
