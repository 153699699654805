import React from 'react'
import styled, { css } from 'styled-components'
import _omit from 'lodash/omit'
import transition from 'styled-transition-group'
import { TransitionGroup } from 'react-transition-group'

import { BREAKPOINT, MIN_BREAKPOINT, PAGE_X_PAD_MOBILE_REM } from '../../constants/style'
import Html from 'slate-html-serializer'

export const FadeAnimation = transition.div`
  transition: opacity ${props => props.timeout.enter}ms ease-in;
  &:enter {
    opacity: 0;
  }
  &:enter-active {
    opacity: 1;
  }
  &:exit {
      opacity: 1;
  }
  &:exit-active {
      opacity: 0;
  }
`

export const TransitionWithAnimation = props => {
  const { children, innerKey, animation, duration: timeout } = props
  if (!innerKey) {
    console.warn('No inner key provided!')
  }

  const AnimationComponent = animation
  return (
    <div>
      <TransitionGroup>
        <AnimationComponent
          key={innerKey}
          timeout={timeout}
        >
          {children}
        </AnimationComponent>
      </TransitionGroup>
    </div>
  )
}

/**
 * Wrap buttons relating to a piece of content inside this
 *
 * QuestionButtonWrap must be inside a ContentBox for it to
 * display responsively correctly
 */
export const DesktopButtonWrapLeft = styled.div`
  @media (max-width: ${BREAKPOINT}) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 12vw;
  background: transparent;
  text-align: center;
  transition: background-color .3s;

  &:hover {
      background: rgba(0, 0, 0, 0.01);
  }
`

export const DesktopButtonWrapRight = styled.div`
  @media (max-width: ${BREAKPOINT}) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 12vw;
  background: transparent;
  text-align: center;
  transition: background-color .3s;

  &:hover {
      background: rgba(0, 0, 0, 0.01);
  }
`
export const MobileQuestionButtonWrapLeft = styled.div`
  text-align: ${props => props.align || 'right'};
  margin-top: 1em;
  font-size: 0.92em;

  >:not(:last-child) {
      margin-bottom: .4em;
  }

  @media (min-width: ${MIN_BREAKPOINT}) {
    display: none;
  }
  @media (max-width: ${BREAKPOINT}) {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 6.1rem;
    padding: 1.3rem 1.8rem;
    background: white;
  }
`

export const MobileQuestionButtonWrapRight = styled.div`
  text-align: ${props => props.align || 'right'};
  margin-top: 1em;
  font-size: 0.92em;

  >:not(:last-child) {
      margin-bottom: .4em;
  }

  @media (min-width: ${MIN_BREAKPOINT}) {
    display: none;
  }
  @media (max-width: ${BREAKPOINT}) {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 6.1rem;
    padding: 1.3rem 1.8rem;
    background: white;
  }
`

const ContentBoxStyle = styled.div`
    > * {
      padding: 3.3rem 4.1rem;
      padding-bottom: 2.3rem;
    }
    
    margin: 0 auto;
    ${props => props.question ? css`
      /* Question-only styles */
      display: flex;
      width: 100%;
      > * {
        display: flex;
        > * {
          flex-shrink: 0;
        }
        flex-direction: column;
        width: 100%;
      }
      @media (min-width: ${MIN_BREAKPOINT}) {
        /* If enabled on mobile, prevents scroll from working */
        min-height: 36rem;
      }
      @media (min-width: ${BREAKPOINT}) {
          width: 75vw;
      }
    ` : css``}
    ${props => props.material ? css`
      /* Material-only styles */
      @media (min-width: ${MIN_BREAKPOINT}) {
        ${DesktopButtonWrapLeft} {
          display: none;
        }
        ${DesktopButtonWrapRight} {
          display: none;
        }
      }

      ${props => props.justAnImage ? css`
        background: white;
      ` : css`
        @media (min-width: ${MIN_BREAKPOINT}) {
          width: 75vw;
          position: absolute;
          bottom: 0;
          left: 50%;
          top: 7vh;
          transform: translateX(-50%);
        }
      `}
      ` : css``}
    
    ${props => props.justAnImage ? css`
      /* Just an image */
      img {
        max-height: 59vh;
        width: auto !important;
        /* border: 1px solid #e3e3e3; */
      }
      text-align: center;
    ` : css`
      /* More than an image */
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      background: white;
      @media (min-width: ${MIN_BREAKPOINT}) {
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.07);
      }
    `}

    @media (max-width: ${BREAKPOINT}) {
      position: absolute;
      top: 0;
      bottom: 6rem;
      left: 0;
      right: 0;
      border: none;
      > * {
        padding: 2.3rem ${PAGE_X_PAD_MOBILE_REM}rem;
      }
    }
`

export const CardTitle = styled.div`
  font-weight: 300;
  font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
  color: #4c4e4d;
  @media (max-width: ${BREAKPOINT}) {
    font-size: 32px !important;
  }
  @media (min-width: ${MIN_BREAKPOINT}) {
    font-size: 35px !important;
  }
  margin-bottom: ${props => props.question ? '3rem' : '2rem'};
  line-height: 1.1;
  white-space: pre-line;
`

/**
 * Holds the main piece of content for a question/material and
 * displays differently accordingly
 */
export class ContentBox extends React.Component {
  render () {
    const propsWithoutChildren = _omit(this.props, ['children'])
    const { buttonsLeft, buttonsRight, innerKey } = this.props

    return (
      <div>
        <TransitionWithAnimation animation={FadeAnimation} duration={{ enter: 600, exit: 600 }} innerKey={innerKey}>
          <ContentBoxStyle {...propsWithoutChildren}>
            <div>
              {this.props.children}
            </div>
          </ContentBoxStyle>
        </TransitionWithAnimation>

        <DesktopButtonWrapLeft>{buttonsLeft}</DesktopButtonWrapLeft>
        <DesktopButtonWrapRight>{buttonsRight}</DesktopButtonWrapRight>
        <MobileQuestionButtonWrapLeft>{buttonsLeft}</MobileQuestionButtonWrapLeft>
        <MobileQuestionButtonWrapRight>{buttonsRight}</MobileQuestionButtonWrapRight>
      </div>
    )
  }
}

const renderContentColor = (obj, children) => {
  const { color = 'inherit' } = obj.data
  const colorProp = obj.type === 'background' ? 'backgroundColor' : 'color'
  return <span style={{ [colorProp]: color }}>{children}</span>
}

const renderParagraph = (children, { print = false } = {}) => {
  const empty = children.length === 1 && Array.isArray(children[0]) && children[0].length === 1 &&
    children[0][0] === ''

  if (empty && print) {
    return null
  }

  return (
    <p>
      {
        empty ? <br /> : children
      }
    </p>
  )
}

export function renderContent (content, opt) {
  const { download = false } = opt || {}
  const rules = [
    {
      // Add a serializing function property to our rule...
      serialize (obj, children) {
        if (obj.object === 'block') {
          switch (obj.type) {
            case 'paragraph':
              return renderParagraph(children, opt)
            case 'block-quote':
            case 'quote':
              return <blockquote>{children}</blockquote>
            case 'bulleted-list':
            case 'unordered-list':
              return <ul>{children}</ul>
            case 'heading-one':
              return <h1>{children}</h1>
            case 'heading-two':
              return <h2>{children}</h2>
            case 'heading-three':
              return <h3>{children}</h3>
            case 'heading-four':
              return <h4>{children}</h4>
            case 'heading-five':
              return <h5>{children}</h5>
            case 'heading-six':
              return <h6>{children}</h6>
            case 'list-item':
              return <li>{children}</li>
            case 'list-item-child':
              return <div>{children}</div>
            case 'numbered-list':
            case 'ordered-list':
              return <ol>{children}</ol>
            case 'image':
              return <img src={obj.data.src} alt='' />
            case 'align-left':
            case 'align-center':
            case 'align-right':
            case 'align-justify':
              if (download) {
                // Return fragment containing the children as jspdf doesn't handle text-align style very well
                return <>{children}</>
              }
              return <div style={{ textAlign: obj.type.replace('align-', '') }}>{children}</div>
            default:
              return null
          }
        }
      }
    },
    {
      serialize (obj, children) {
        if (obj.object === 'mark') {
          switch (obj.type) {
            case 'bold':
              return <strong>{children}</strong>
            case 'italic':
              return <em>{children}</em>
            case 'underlined':
            case 'underline':
              return <u>{children}</u>
            case 'strikethrough':
              return <span style={{ textDecoration: 'line-through' }}>{children}</span>
            case 'background':
            case 'color':
              return renderContentColor(obj, children)
            default:
              break
          }
        }
      }
    },
    {
      serialize (obj, children) {
        if (obj.object === 'inline') {
          switch (obj.type) {
            case 'link':
              return <a href={obj.data.href} target='_blank' rel='noopener noreferrer'>{children}</a>
            default:
              break
          }
        }
      }
    }
  ]

  const html = new Html({ rules })

  return html.serialize(JSON.parse(content))
}
