
import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { ExcludeInactiveToggle } from '../../../components/common'
import IntercomHeader from '../../../components/IntercomHeader'

const trOpt = { scope: 'uPolicy.common' }

const UPolicyHeaderStyled = styled.div`
height: 100%;
background-color: white;
padding: 40px;
display: flex;
flex-direction: row;
justify-content: space-between;
`

const UPolicyHeader = () => {
  return (
    <UPolicyHeaderStyled style={{ backgroundColor: 'white', gridArea: 'topbar' }}>
      <IntercomHeader id='uPolicy-report-header' style={{ margin: 0 }}>{I18n.t('common.uPolicy')} - {I18n.t('policies', trOpt)}</IntercomHeader>
      <div>
        <ExcludeInactiveToggle />
      </div>
    </UPolicyHeaderStyled>
  )
}

export default UPolicyHeader
