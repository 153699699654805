import React from 'react'
import I18n from 'i18n-js'
import { Button, Divider as _Divider } from 'antd'
import styled from 'styled-components'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'

import SettingsForm from '../SettingsForm'
import { themes } from '../../../theme'
import ReportDocumentPreview from '../ReportDocumentPreview'
import { ENABLE_REPORT_CENTRE, REPORT_CENTRE_PREVIEW } from '../../../constants/environment'
import { PreviewTag } from '../../common'

const { main: defaultTheme } = themes
const trOpt = { scope: 'settings.reportSettings' }

const Divider = styled(_Divider)`
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.primary};
  }
`

const ResetTheme = React.forwardRef(({ onClick = () => {}, disabled }, ref) => (
  <Button type='primary' onClick={onClick} icon='undo' disabled={disabled}>{I18n.t('reportDocumentSettings.resetTheme', trOpt)}</Button>
))

const DocumentSettingsDivider = React.forwardRef(({ title, description }, ref) => {
  return (
    <div>
      <Divider>{title}{REPORT_CENTRE_PREVIEW && <PreviewTag />}</Divider>
      <p>{description}</p>
    </div>
  )
})

const ReportDocumentPreviewContainer = React.forwardRef(({ formValues, appThemeLogo }, ref) => {
  const {
    reportDocumentUseAppThemeLogo: useAppThemeLogo,
    reportDocumentLogo: logo,
    reportDocumentHeaderBackgroundColor: backgroundColor,
    reportDocumentHeaderFontColor: fontColor,
    reportDocumentShowCompanyName: showCompanyName
  } = formValues || {}

  return (
    <ReportDocumentPreview
      logo={useAppThemeLogo ? appThemeLogo : logo}
      {...{ backgroundColor, fontColor, showCompanyName }}
    />
  )
})

class ReportSettings extends SettingsForm {
  constructor (props) {
    super(props)

    this.setInitialFormValuesOnMount = true

    this.settingIds = [
      'displayUserEmailInReports',
      'disableInactiveUsersInReport',
      'enableRiskScore',
      'reportDocumentUseAppThemeLogo',
      'reportDocumentLogo',
      'reportDocumentHeaderBackgroundColor',
      'reportDocumentHeaderFontColor',
      'reportDocumentShowCompanyName'
    ]

    Object.defineProperty(this, 'defaultValue', {
      get: () => this.getDefaultValue()
    })

    this.resetThemeToDefault = this.resetThemeToDefault.bind(this)
  }

  getDefaultValue () {
    const { defaultTenant = false, settings: { parentDefaultSettings } = {} } = this.props

    let defaultValue = {
      displayUserEmailInReports: false,
      disableInactiveUsersInReport: false,
      enableRiskScore: false,
      reportDocumentUseAppThemeLogo: true,
      reportDocumentHeaderBackgroundColor: defaultTheme.reportDocumentHeaderBackgroundColor,
      reportDocumentHeaderFontColor: defaultTheme.reportDocumentHeaderFontColor,
      reportDocumentShowCompanyName: true
    }

    if (!defaultTenant && parentDefaultSettings) {
      defaultValue = {
        ...defaultValue,
        ..._pick(parentDefaultSettings, this.settingIds)
      }
    }

    return defaultValue
  }

  onSuccess (result) {
    super.onSuccess(result)
    super.resetFields()
  }

  get headerId () {
    return 'settings-report-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  async resetThemeToDefault () {
    const form = this.form.current
    if (form) {
      await form.setField('reportDocumentUseAppThemeLogo', this.defaultValue.reportDocumentUseAppThemeLogo)
      await form.setField('reportDocumentLogo', this.defaultValue.reportDocumentLogo)
      await form.setField('reportDocumentHeaderBackgroundColor', this.defaultValue.reportDocumentHeaderBackgroundColor)
      await form.setField('reportDocumentHeaderFontColor', this.defaultValue.reportDocumentHeaderFontColor)
      await form.setField('reportDocumentShowCompanyName', this.defaultValue.reportDocumentShowCompanyName)
    }
  }

  mutateValues (values) {
    return {
      ..._omit(values, ['documentSettingsDivider', 'resetTheme', 'documentPreviewDivider', 'reportDocumentPreview'])
    }
  }

  get _fields () {
    const { showRiskScoreOption = false } = this.props
    const { uLearn = true, uPhish = true, uBreachEnabled = true, appThemeLogo } = this.props.settings || {}

    const fields = [
      {
        id: 'disableInactiveUsersInReport',
        type: 'switch',
        label: I18n.t('disableInactiveUsersInReport', trOpt),
        defaultValue: this.defaultValue.disableInactiveUsersInReport
      }
    ]

    if (uLearn) {
      fields.unshift({
        id: 'displayUserEmailInReports',
        type: 'switch',
        label: I18n.t('displayUserEmailInReports', trOpt),
        defaultValue: this.defaultValue.displayUserEmailInReports
      })
    }

    if (showRiskScoreOption && (this.defaultTenant || uLearn || uPhish || uBreachEnabled)) {
      fields.unshift({
        id: 'enableRiskScore',
        type: 'switch',
        label: I18n.t('settings.riskScore.enableRiskScore'),
        defaultValue: this.defaultValue.enableRiskScore
      })
    }

    if (ENABLE_REPORT_CENTRE) {
      // Report Documents and Document Review Section
      fields.push(
        {
          id: 'documentSettingsDivider',
          type: 'custom',
          component: DocumentSettingsDivider,
          title: I18n.t('reportDocumentSettings.title', trOpt),
          description: I18n.t('reportDocumentSettings.description', trOpt)
        }, {
          id: 'reportDocumentShowCompanyName',
          type: 'switch',
          label: I18n.t('reportDocumentSettings.showCompanyName', trOpt),
          defaultValue: this.defaultValue.reportDocumentShowCompanyName
        }, {
          id: 'reportDocumentUseAppThemeLogo',
          type: 'switch',
          label: I18n.t('reportDocumentSettings.useAppThemeLogo', trOpt),
          defaultValue: this.defaultValue.reportDocumentUseAppThemeLogo
        }, {
          id: 'reportDocumentLogo',
          type: 'image',
          label: I18n.t('reportDocumentSettings.logo', trOpt),
          defaultValue: this.defaultValue.reportDocumentLogo,
          visible: ({ reportDocumentUseAppThemeLogo }) => !reportDocumentUseAppThemeLogo,
          required: ({ reportDocumentUseAppThemeLogo }) => !reportDocumentUseAppThemeLogo
        }, {
          id: 'reportDocumentHeaderBackgroundColor',
          type: 'colour',
          label: I18n.t('reportDocumentSettings.backgroundColor', trOpt),
          defaultValue: this.defaultValue.reportDocumentHeaderBackgroundColor,
          required: true,
          useOverlay: false
        }, {
          id: 'reportDocumentHeaderFontColor',
          type: 'colour',
          label: I18n.t('reportDocumentSettings.headerFontColor', trOpt),
          defaultValue: this.defaultValue.reportDocumentHeaderFontColor,
          required: true,
          useOverlay: false
        }, {
          id: 'resetTheme',
          type: 'custom',
          component: ResetTheme,
          onClick: this.resetThemeToDefault,
          disabled: this.isUpdateAllowed
        }, {
          id: 'documentPreviewDivider',
          type: 'custom',
          component: DocumentSettingsDivider,
          title: I18n.t('reportDocumentSettings.previewTitle', trOpt),
          description: I18n.t('reportDocumentSettings.previewDescription', trOpt)
        }, {
          id: 'reportDocumentPreview',
          type: 'custom',
          component: ReportDocumentPreviewContainer,
          useFormValues: values => _pick(values, ['reportDocumentUseAppThemeLogo', 'reportDocumentLogo', 'reportDocumentHeaderBackgroundColor', 'reportDocumentHeaderFontColor', 'reportDocumentShowCompanyName']),
          appThemeLogo
        }
      )
    }

    return fields
  }
}

export default ReportSettings
