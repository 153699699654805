import React, { useMemo, useRef } from 'react'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'
import { Tooltip } from 'antd'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { dateTimeColumnSorter, numberColumnSorter, textColumnSorter } from '../../helpers/listPages'
import { formatDate } from '../../helpers/datetime'
import { ActionTable } from '../../components/common'
import TooltipIcon from '../common/TooltipIcon'
import { emailFilter, resolvedFilter } from '../../helpers/uBreachV2'
import UserBreachesModal from '../Modals/uBreach/UserBreachesModal'
import UserBreachDetailsModal from '../Modals/uBreach/UserBreachDetailsModal'

const trOpt = { scope: 'uBreach' }

const NonSystemUserField = ({ getPopupContainer, value }) => (
  <span>
    {value}
    &nbsp;
    <Tooltip title={I18n.t('nonSystemUserTooltip', trOpt)} {...{ getPopupContainer }}>
      <span><TooltipIcon /></span>
    </Tooltip>
  </span>
)

const UserBreachesTable = ({
  userBreaches,
  isAggregate = false, // if false we're showing data for a single user
  showActions = true,
  performAction = () => {},
  tableProps = {},
  storageId = null,
  updateSelectedIds,
  selectedIds,
  uBreachProEnabled,
  loading = false,
  error,
  breachedServices = [],
  breachedUsers = [],
  refetchQueries = [],
  handleRefreshClick,
  getPopupContainer,
  showMultiSelectActions
}) => {
  const userBreachDetailsModalRef = useRef(null)
  const userBreachesModalRef = useRef(null)
  const columns = useMemo(() => {
    const columns = [
      {
        title: I18n.t('tables.learnerName', trOpt),
        dataIndex: 'learnerName',
        key: 'learnerName',
        render: (text) => text || I18n.t('tables.nonSystemUser', trOpt),
        postRender: (value, originalValue) => !originalValue ? <NonSystemUserField {...{ getPopupContainer, value }} /> : value,
        sorter: (a, b) => textColumnSorter(
          'learnerName',
          { ...a, learnerName: a.learnerName || I18n.t('tables.nonSystemUser', trOpt) },
          { ...b, learnerName: b.learnerName || I18n.t('tables.nonSystemUser', trOpt) }
        ),
        // For aggregate view, we show a modal with all breaches for the user.
        // For single user view, we show a modal with details for a single breach.
        // The single breach modal is only available if uBreach Pro is enabled.
        triggerModal: (record) => {
          if (isAggregate) return userBreachesModalRef
          return uBreachProEnabled && record.hasExposedInfoData ? userBreachDetailsModalRef : null
        },
        triggerModalAction: !isAggregate ? { key: 'view', label: I18n.t('common.view') } : null,
        modalRecordKey: 'email',
        useModalPager: true
      },
      {
        title: I18n.t('tables.email', trOpt),
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => textColumnSorter('email', a, b),
        filters: emailFilter(userBreaches),
        filterMultiple: true,
        onFilter: (value, record) => record.email === value,
        defaultSortOrder: 'ascend'
      }
    ]
    if (isAggregate) {
      columns.push(
        {
          title: I18n.t('breachCount', trOpt),
          dataIndex: 'breachCount',
          key: 'breachCount',
          sorter: (a, b) => numberColumnSorter('breachCount', a, b)
        }
      )
      if (uBreachProEnabled) {
        // Only show the resolved count column if uBreach Pro is enabled.
        columns.push(
          {
            title: I18n.t('tables.resolvedCount', trOpt),
            dataIndex: 'resolvedCount',
            key: 'resolvedCount',
            sorter: (a, b) => numberColumnSorter('resolvedCount', a, b)
          }
        )
      }
      columns.push(
        {
          title: I18n.t('tables.lastBreachDate', trOpt),
          dataIndex: 'lastBreachDate',
          key: 'lastBreachDate',
          sorter: (a, b) => dateTimeColumnSorter('lastBreachDate', a, b),
          render: (lastBreachDate) => lastBreachDate ? formatDate(lastBreachDate) : ''
        },
        {
          title: I18n.t('tables.lastBreachAdded', trOpt),
          dataIndex: 'lastBreachAdded',
          key: 'lastBreachAdded',
          sorter: (a, b) => dateTimeColumnSorter('lastBreachAdded', a, b),
          render: (lastBreachAdded) => lastBreachAdded ? formatDate(lastBreachAdded) : ''
        }
      )
    } else if (uBreachProEnabled) {
      columns.push(
        {
          title: I18n.t('tables.resolvedDate', trOpt),
          dataIndex: 'resolved',
          key: 'resolved',
          sorter: (a, b) => dateTimeColumnSorter('resolved', a, b),
          filters: resolvedFilter(userBreaches),
          filterMultiple: true,
          onFilter: (value, record) => value ? record.resolved !== null : record.resolved === null,
          render: (resolved) => resolved ? formatDate(resolved) : ''
        }
      )
    }
    return columns
  }, [isAggregate, uBreachProEnabled, userBreaches, getPopupContainer])

  const sortedUserBreaches = useMemo(() => userBreaches.sort((a, b) => {
    if (a.email < b.email) return -1
    if (a.email > b.email) return 1
    return 0
  }), [userBreaches])

  return (
    <>
      <ActionTable
        {...{
          columns,
          loading,
          performAction,
          showActions,
          tableProps,
          storageId,
          updateSelectedIds,
          showMultiSelectActions
        }}
        dataSource={sortedUserBreaches}
        buttonActionKeys={['view', 'markResolved']}
        // Only render the selections column if uBreachProEnabled, no need to render it otherwise
        selectedIds={uBreachProEnabled ? selectedIds : null}
      />
      <UserBreachDetailsModal ref={userBreachDetailsModalRef} />
      <UserBreachesModal ref={userBreachesModalRef} {...{ breachedUsers, breachedServices, error, loading, refetchQueries, handleRefreshClick }} />
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled'])
  })
)(UserBreachesTable)
