import React from 'react'
import { Card, Icon } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { useGlobalState } from '../hooks'
import { getExpiredPlanHoldType } from '../helpers/getExpiredPlanHoldType'

const trMainOpt = { scope: 'main' }
const trOpt = { scope: 'holding' }

const HoldingContainer = styled.div`
  text-align: center;
`

export const Content = ({ title, body, cta }) => {
  return (
    <>
      {title ? <h1>{I18n.t(title, trMainOpt)} <Icon type='frown' /></h1> : null}
      {body ? <p>{I18n.t(body, trOpt)}</p> : null}
      {cta ? <p>{I18n.t(cta, trOpt)}</p> : null}
    </>
  )
}

export const HoldingContent = ({ className, holdType, userType }) => {
  const RenderContent = () => {
    switch (holdType) {
      case 'expiredFreeTrial':
        return (
          <Content
            title='freeTrialMessage.expiredMessage'
            body='unfortunatelyYourFreeTrialHasExpired'
            cta={userType === 'adminUser' ? 'ifYouWouldLikeToDiscussPricing' : null}
          />
        )
      case 'expiredPaidPlan':
        return (
          <Content
            title='expiredPaidPlanMessage'
            body='unfortunatelyYourCurrentPlanHasExpired'
            cta={userType === 'adminUser' ? 'ifYouWouldLikeToReactivate' : null}
          />
        )
      case 'accessDenied':
        return (
          <Content
            title='accessDenied'
            body='itIsNotCurrentlyPossibleToAccess'
            cta={userType === 'adminUser' ? 'ifYouWouldLikeToResolveThisIssueOpenLiveChat' : null}
          />
        )
      default:
        // return empty as default, prevents render popping where default content shows and then is replaced once query stablises
        return (
          <Content />
        )
    }
  }

  return (
    <HoldingContainer className={className}>
      <RenderContent />
    </HoldingContainer>
  )
}

const Holding = () => {
  const { userType, holdType } = useGlobalState(state => {
    const planName = state?.session?.planName // 'premium', 'freeTrial', 'custom'
    const planValid = state?.session?.planValid // 'true', 'false'

    return {
      userType: state?.session?.type === 'admin' ? 'adminUser' : state?.session?.type, // 'adminUser', 'endUser'
      holdType: planValid ? null : getExpiredPlanHoldType({ planName }) // 'expiredFreeTrial', 'expiredPaidPlan', 'accessDenied'
    }
  })

  return (
    <Card>
      <HoldingContent {...{ holdType, userType }} />
    </Card>
  )
}

export default Holding
