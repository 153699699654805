import React, { useCallback, useRef } from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'

import ExportServiceDownloadModal from '../Modals/ExportServiceDownloadModal'

const ExportServiceDownloadButton = ({
  jobData,
  fileName,
  fileType,
  baseURL,
  btnType = 'primary',
  children = I18n.t('reports.downloadSpreadsheet'),
  icon = 'download',
  modalRef
}) => {
  const exportServiceDownloadModalRef = useRef(null)
  const onClick = useCallback(async () => {
    const downloadModalRef = modalRef ?? exportServiceDownloadModalRef
    if (downloadModalRef.current) {
      downloadModalRef.current.open({ fileName, fileType, jobData })
    }
  }, [exportServiceDownloadModalRef, modalRef, fileName, fileType, jobData])

  return (
    <>
      {!modalRef && (
        <ExportServiceDownloadModal
          ref={exportServiceDownloadModalRef}
          {...{ baseURL }}
        />
      )}
      <Button icon={icon} {...{ onClick }} type={btnType}>{children}</Button>
    </>
  )
}

export default ExportServiceDownloadButton
