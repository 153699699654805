import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'

import ActionConfirm from './ActionConfirm'

const trOpt = { scope: 'modals.invalidateReportCacheConfirm' }

const InvalidateReportCacheConfirm = React.forwardRef(({
  companyId,
  refetchQueries,
  onClose: onCloseProp = () => {},
  invalidateMutation
}, ref) => {
  const [recordIds, setRecordIds] = useState([])

  const actionConfirmRef = useRef(null)
  useImperativeHandle(ref, () => ({
    open: reports => {
      setRecordIds(reports.map(r => r.recordIds).flat())
      if (actionConfirmRef.current) {
        actionConfirmRef.current.open(reports.map(r => r.id))
      }
    }
  }), [actionConfirmRef])

  const onClose = useCallback(() => {
    setRecordIds([])
    onCloseProp()
  }, [onCloseProp])

  return (
    <ActionConfirm
      mutation={invalidateMutation}
      ref={actionConfirmRef}
      variableName={null}
      variables={{ companyId, recordIds }}
      {...{ refetchQueries, onClose, trOpt }}
    />
  )
})

export default InvalidateReportCacheConfirm
