import React from 'react'
import { Modal, message } from 'antd'
import I18n from 'i18n-js'

import { NEXT_RISK_REPORT_STATUS } from '../constants/company'
import { showErrors } from './ErrorHandler'
import { DialogModalContent } from '../components/Modals/common'
import { renderParagraphsFragmentFromArray } from './renderFromArray'

const advTrOpt = { scope: 'riskReport.advanceRiskReport' }
export const advanceRiskReport = async (executeAdvanceRiskReport, companyId, companyName, riskReportStatus, admin = false) => {
  const nextStatus = NEXT_RISK_REPORT_STATUS[riskReportStatus] || 'domain_started'

  let content = []
  if (admin && ['domain_complete', 'ubreach_complete', 'uphish_complete'].includes(riskReportStatus)) {
    content.push(I18n.t('disclaimer', advTrOpt))
  }
  if (admin && nextStatus === 'uphish_complete') {
    content.push(I18n.t('irreversibleActionWarning', advTrOpt))
  }
  if (content.length > 0) {
    content = (
      <DialogModalContent>
        {renderParagraphsFragmentFromArray(content)}
      </DialogModalContent>
    )
  } else {
    content = null
  }

  return new Promise(resolve => {
    Modal.confirm({
      title: I18n.t(`title.${nextStatus}`, { ...advTrOpt, companyName }),
      content,
      okText: I18n.t('okText', advTrOpt),
      cancelText: I18n.t('cancelText', advTrOpt),
      onOk: async () => {
        let updated = false
        try {
          await executeAdvanceRiskReport({ variables: { companyId } })
          updated = true
          message.success(I18n.t(`success.${nextStatus}`, { ...advTrOpt, companyName }))
        } catch (e) {
          showErrors(e, I18n.t('error', { ...advTrOpt, companyName }))
        }
        resolve(updated)
      },
      onCancel: () => resolve(false)
    })
  })
}

const conTrOpt = { scope: 'riskReport.convertProspectToFreeTrial' }
export const convertProspectToFreeTrial = async (executeConvertToFreeTrial, companyId, companyName, riskReportStatus) => {
  const title = I18n.t(`title.${riskReportStatus === 'trial_requested' ? 'finished' : 'skip_to_trial'}`, { ...conTrOpt, companyName })

  return new Promise(resolve => {
    Modal.confirm({
      title,
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk: async () => {
        let updated = false
        try {
          await executeConvertToFreeTrial({ variables: { companyId } })
          updated = true
        } catch (e) {
          showErrors(e, I18n.t('error', { ...conTrOpt, companyName }))
        }
        resolve(updated)
      },
      onCancel: () => resolve()
    })
  })
}

const nextTrOpt = { scope: 'riskReport.requestNextRiskReportStep' }
export const requestNextRiskReportStep = async (moveToNextStep, riskReport) => {
  const { companyId, status } = riskReport || {}
  const nextStatus = NEXT_RISK_REPORT_STATUS[status]

  return new Promise(resolve => {
    Modal.confirm({
      title: I18n.t(`title.${nextStatus}`, nextTrOpt),
      okText: I18n.t('common.resumeConfirmDialog.ok'),
      cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
      onOk: async () => {
        let updated = false
        try {
          await moveToNextStep({ variables: { companyId } })
          updated = true
        } catch (e) {
          showErrors(e, I18n.t('riskReport.requestError'))
        }
        resolve(updated)
      },
      onCancel: () => resolve(false)
    })
  })
}

export const getSimEventStartingPoint = (eventType) => {
  if (eventType === 'compromise') {
    return 'open'
  }
  return 'createdAt'
}
