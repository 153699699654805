import React, { useImperativeHandle, useRef, useState, useCallback } from 'react'
import I18n from 'i18n-js'

import ActionConfirm from './ActionConfirm'

import { DETACH_LEARNERS_FROM_SYNC } from '../Queries/Learners'
import { invalidateLearnersQueryCache } from '../../helpers'

const trOpt = { scope: 'modals.detachLearnerConfirm' }

const DetachLearnerConfirm = React.forwardRef(({
  refetchQueries, update = invalidateLearnersQueryCache,
  onClose: onCloseProp = () => {},
  ...actionConfirmProps
}, ref) => {
  const [count, setCount] = useState(0)

  const actionConfirmRef = useRef(null)
  useImperativeHandle(ref, () => ({
    open: learnerIds => {
      setCount(learnerIds.length ?? 0)
      if (actionConfirmRef.current) {
        actionConfirmRef.current.open(learnerIds)
      }
    }
  }), [actionConfirmRef])

  const onClose = useCallback(() => {
    setCount(0)
    onCloseProp()
  }, [onCloseProp])

  return (
    <ActionConfirm
      mutation={DETACH_LEARNERS_FROM_SYNC}
      variableName='learnerIds'
      ref={actionConfirmRef}
      {...{ update, refetchQueries, trOpt, onClose }}
      {...actionConfirmProps}
      body={(
        <div>
          <span>{I18n.t('description', { ...trOpt, count })}</span>
        </div>
      )}
    />
  )
})

export default DetachLearnerConfirm
