import { createLocaleGetterObject, createLocaleGetterOptions } from '../helpers/locale'

export const CATEGORY_IDS = [
  'none',
  'compliance',
  'essential',
  'encryption',
  'mobile',
  'server',
  'other'
]

const categoryTrScope = 'uPolicy.common.categories'
export const CATEGORY_NAMES = createLocaleGetterObject({
  none: 'common.noCategory',
  compliance: `${categoryTrScope}.compliance`,
  essential: `${categoryTrScope}.essential`,
  encryption: `${categoryTrScope}.encryption`,
  mobile: `${categoryTrScope}.mobile`,
  server: `${categoryTrScope}.server`,
  other: `${categoryTrScope}.other`
})

export const CATEGORY_OPTIONS = createLocaleGetterOptions(CATEGORY_NAMES)

export const TEMPLATE_TYPE_IDS = ['core', 'custom']

export const TEMPLATE_TYPE_NAMES = createLocaleGetterObject({
  core: 'common.core',
  custom: 'common.custom'
})

export const TEMPLATE_TYPE_OPTIONS = createLocaleGetterOptions(TEMPLATE_TYPE_NAMES)

export const SIGNATURE_TYPE_IDS = [
  'fixed',
  'lastSignature',
  'newUsers',
  'none'
]

export const SIGNATURE_TYPE_NAMES = createLocaleGetterObject({
  fixed: 'fixed',
  lastSignature: 'lastSignature',
  newUsers: 'newUsers',
  none: 'none'
}, { scope: 'uPolicy.common.signatureTypes' })

export const SIGNATURE_TYPE_OPTIONS = createLocaleGetterOptions(SIGNATURE_TYPE_NAMES)
