import React, { useCallback, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { Button, message, Divider, Alert as _Alert } from 'antd'
import compose from 'recompose/compose'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'
import { useLocation } from 'react-router-dom'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'

import { SignInHeader } from '../../components/SignIn'
import { connect, withAppTitleAndIcons } from '../../hocs'
import { creators as settingsActions } from '../../state/actions/settings'
import { creators as viewActions } from '../../state/actions/view'
import { Row, Column } from '../../components/EndUserPortal/GridFlexiLayout'
import MagicLinkForm from '../../components/EndUserPortal/MagicLinkForm'
import useRouteCompany from '../../hooks/useRouteCompany'
import { GET_SAML_AUTH_START_TOKEN } from '../../components/Queries/Users'
import { captureSentryError } from '../../helpers/sentry'
import { UsecureError, getErrorCode } from '../../helpers'
import { apiUrl } from '../../apollo-client/common'
import { showSAMLAuthErrors } from '../../helpers/sso'
import { SSO_GOOGLE_ENABLED, SSO_MS_ENABLED, SSO_SAML_ENABLED } from '../../constants/environment'
import routes from '../../constants/routes'
import SSOEmailForm from '../../components/EndUserPortal/SSOEmailForm'

const trOpt = { scope: 'endUserPortal.signIn' }
const trCommonOpt = { scope: 'common' }

const ROUTE_TO_TARGET_MAP = {
  [routes.PORTAL_LEARN]: '/learn',
  [routes.PORTAL_POLICY]: '/policy',
  [routes.PORTAL_BREACH]: '/breach',
  [routes.PORTAL_SETTINGS]: '/settings'
}

const AuthButton = styled(Button)`
  margin-bottom: 1em;
`
const ColourDivider = styled(Divider)`
  color: ${({ theme }) => theme.primary}!important;
  &:after {
    border-top: 1px solid ${({ theme }) => theme.primary}!important;
  }
  &:before {
    border-top: 1px solid ${({ theme }) => theme.primary}!important;
  }
`

const Alert = styled(_Alert)`
  margin-bottom: 25px;

  .ant-alert-message {
    position: relative;
    top: 2px;
  }
`

const SignIn = ({ setLoadingVisible, updateSettings, setTheme, history, match }) => {
  const { loading: queryLoader, error, company, isPlanValid, settings, accountType, endUserAuthOptions } = useRouteCompany({
    variables: { withEndUserAuthOptions: true }
  })
  const [isEndUserPortalEnabled, setIsEndUserPortalEnabled] = useState(false)
  const [showSSOEmailForm, setShowSSOEmailForm] = useState(false)
  const [callGetAuthStartToken] = useMutation(GET_SAML_AUTH_START_TOKEN)
  const { search } = useLocation()
  const [showScreen, setShowScreen] = useState(false)

  const cantGrantAccess = !isPlanValid || accountType === 'prospect'

  const startSSOAuth = useCallback(async ({ companyId, email } = {}) => {
    try {
      setLoadingVisible(true)
      const variables = { authType: 'endUser', origin: window.location.origin }
      if (email) {
        variables.email = email
      } else {
        variables.companyId = companyId
      }

      // Set target path to set route loaded after session has initialised in the client by EndUserSAMLResponse
      variables.targetPath = ROUTE_TO_TARGET_MAP[match?.path]

      const { data } = await callGetAuthStartToken({ variables })
      const startToken = data?.getSAMLAuthStartToken
      if (startToken) {
        window.location.href = `${apiUrl}/auth/saml/${startToken}`
      } else {
        // EUP-NTR - End User Portal, No Token Returned
        throw new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode: 'SAML-EUP-NTR' })
      }
    } catch (e) {
      captureSentryError(e, { msg: `End User Portal - Start SAML SSO - ERROR - ${getErrorCode(e, 'NO_CODE')}` })
      // Fallback error - EUP-UNK - End User Portal, UNKnown error
      showSAMLAuthErrors(e, { trOpt, errorCodePrefix: 'EUP' })
      setShowScreen(true)
      setLoadingVisible(false)
    }
  }, [callGetAuthStartToken, setLoadingVisible, match])

  useEffect(() => {
    if (settings && (company !== null)) {
      setIsEndUserPortalEnabled(settings.endUserPortalEnabled)
      setTheme({
        ...(settings || {}),
        parentDefaultSettings: _get(company, 'parentCompany.settings.defaultTenantSettings')
      })
      updateSettings({ ...settings, ready: true })

      // Start the SAML SSO flow on load if the autoLogin=saml query parameter is present
      if ((new URLSearchParams(search ?? {})).get('autoLogin') === 'saml' && company !== null && SSO_SAML_ENABLED && endUserAuthOptions?.saml) {
        startSSOAuth({ companyId: company.id })
      } else {
        setShowScreen(true)
        setLoadingVisible(false)
      }
    }
  }, [setLoadingVisible, updateSettings, settings, setTheme, company, search, endUserAuthOptions, startSSOAuth])

  useEffect(() => {
    setLoadingVisible(queryLoader)
  }, [queryLoader, setLoadingVisible])

  // Login options visibility
  // The Google and Microsoft options are placeholders that will be replaced when the authentication approaches are in place.
  const { showMagicLinkForm, ssoAny, ssoSaml, ssoSamlEmailRequired, ssoGoogle, ssoMicrosoft } = useMemo(() => {
    const ssoSaml = SSO_SAML_ENABLED && (endUserAuthOptions?.saml || endUserAuthOptions?.samlSharedMSP)
    const ssoSamlEmailRequired = SSO_SAML_ENABLED && endUserAuthOptions?.samlSharedMSP
    const ssoMicrosoft = SSO_MS_ENABLED && endUserAuthOptions?.microsoft
    const ssoGoogle = SSO_GOOGLE_ENABLED && endUserAuthOptions?.google
    const ssoAny = ssoSamlEmailRequired || ssoSaml || ssoGoogle || ssoMicrosoft

    return {
      showMagicLinkForm: !ssoAny || endUserAuthOptions?.magicLink,
      ssoAny,
      ssoSaml,
      ssoSamlEmailRequired,
      ssoGoogle,
      ssoMicrosoft
    }
  }, [endUserAuthOptions])

  const onStartSSOClick = useCallback(async () => {
    if (ssoSamlEmailRequired) {
      setShowSSOEmailForm(true)
      return
    }

    return startSSOAuth({ companyId: company.id })
  }, [company, ssoSamlEmailRequired, startSSOAuth])

  const onSSOBackClick = useCallback(() => setShowSSOEmailForm(false), [])

  if (queryLoader || (!showScreen && !error)) return null

  return (
    <Row className='full-height justify align' justify='space-around' alignItems='center' alignContent='center'>
      <Column className='align' align-items='center' align-content='center' flex='0 0 30em' style={{ padding: '2em' }}>
        <SignInHeader bottomMargin='2em' allowThemeLogo={!_isNil(settings)}>
          {I18n.t('common.portalLogin')}
        </SignInHeader>
        {((company === null) || error !== null || (!isEndUserPortalEnabled)) && (
          <Alert type='info' showIcon message={I18n.t('noCompanyWithTheSuppliedIdExists', trCommonOpt)} />
        )}
        {(company && cantGrantAccess) && <Alert type='info' showIcon message={I18n.t('main.portalUnavailable')} />}
        {(company && isEndUserPortalEnabled && !cantGrantAccess && !showSSOEmailForm) && (
          <>
            {ssoMicrosoft && <AuthButton onClick={() => message.info('M365 OAuth coming soon')} icon='windows' block type='primary'>Sign in with M365</AuthButton>}
            {ssoGoogle && <AuthButton onClick={() => message.info('Google OAuth coming soon')} icon='google' block type='primary'>Sign in with Google</AuthButton>}
            {(ssoSaml || ssoSamlEmailRequired) && <AuthButton onClick={onStartSSOClick} block type='primary'>{I18n.t('loginWithSSO', trOpt)}</AuthButton>}
            {ssoAny && showMagicLinkForm && <ColourDivider>{I18n.t('or', trCommonOpt)}</ColourDivider>}
            {showMagicLinkForm && <MagicLinkForm setLoadingVisible={setLoadingVisible} history={history} />}
          </>
        )}
        {showSSOEmailForm && (
          <>
            <SSOEmailForm {...{ startSSOAuth }} />
            <Button type='link' onClick={onSSOBackClick}>{I18n.t('common.goBack')}</Button>
          </>
        )}
      </Column>
    </Row>
  )
}

export default compose(
  withAppTitleAndIcons({ isSignInPage: true }),
  connect(
    undefined,
    dispatch => ({
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      updateSettings: settings => dispatch(settingsActions.update(settings)),
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    })
  )
)(SignIn)
