export const base64EncodeUnicode = str => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  const utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1))

  return window.btoa(utf8Bytes)
}

export const base64EncodeJSON = obj => base64EncodeUnicode(JSON.stringify(obj))

export default base64EncodeUnicode
