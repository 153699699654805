import React, { useCallback, useImperativeHandle, useState, useRef, useMemo } from 'react'
import { Modal as _Modal, message } from 'antd'
import I18n from 'i18n-js'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

import { UNENROLL_LEARNERS_FROM_COURSES, GET_COURSES } from '../Queries/Courses'
import MutationForm from '../MutationForm/MutationForm'
import { LoadingBlock, ErrorAlerts } from '../common'
import { invalidateLearnersAndLearnerDataQueryCache } from '../../helpers'
import { COURSE_SUBJECT_OPTIONS } from '../../constants/courses'

const trOpt = { scope: 'modals.unenrolLearnersFromCourseModal' }

const Modal = styled(_Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-title {
    margin-right: 15px;

    h1 {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
`

const UnenrolLearnersFromCourseModal = React.forwardRef(({ refetchQueries, onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [learnerIds, setLearnerIds] = useState([])
  const [title, setTitle] = useState(I18n.t('title', trOpt))
  const [learnerName, setLearnerName] = useState(null)
  const [courseIds, setCourseIds] = useState([])

  useImperativeHandle(ref, () => ({
    open: learners => {
      if (learners) {
        setLearnerIds(learners.map(learner => learner.id))
        let title
        const singleLearner = learners.length === 1
        const learnerName = singleLearner ? learners[0].name : null
        setLearnerName(learnerName)
        if (singleLearner) {
          title = learnerName ? I18n.t('titleUserName', { ...trOpt, name: learnerName }) : I18n.t('titleSingleUser', trOpt)
        } else {
          title = I18n.t('title', trOpt)
        }
        setTitle(title)
        setVisible(true)
      }
    }
  }))

  const form = useRef(null)
  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    setLearnerIds([])
    setLearnerName(null)
    setCourseIds([])
    setTitle(I18n.t('title', trOpt))
    if (form && form.current) {
      form.current.resetFields()
    }
    onClose()
  }, [onClose])

  const { loading: coursesLoading, error: coursesError, data: coursesData } = useQuery(GET_COURSES, { variables: { excludeGapAnalysis: true, restrictToPlan: true, withCompany: true }, skip: !visible })
  const fields = useMemo(() => {
    const { courses = [] } = coursesData || {}
    const subjectOptions = [
      { value: '{all}', label: I18n.t('common.all') },
      ...COURSE_SUBJECT_OPTIONS.filter(({ value }) => courses.some(course => course.subject === value))
    ]
    const courseOptions = courses
    // Sort by difficulty then sort by displayName
      .sort((a, b) => a.difficulty - b.difficulty || a.displayName.toLowerCase() > b.displayName.toLowerCase())
      .map(course => ({ value: course.id, label: course.displayName, linkFieldValue: course.subject }))

    return [
      {
        id: 'subject',
        label: I18n.t('common.fields.subject'),
        type: 'select',
        required: true,
        defaultValue: 'InfoSec',
        options: subjectOptions
      },
      {
        id: 'courseIds',
        label: I18n.t('learners.enrolOnCourseModal.courses'),
        type: 'multiSelect',
        required: true,
        linkField: 'subject',
        placeholder: I18n.t('learners.enrolOnCourseModal.selectCourses'),
        options: courseOptions,
        mutateValue: values => values || []
      }
    ]
  }, [coursesData])

  const onChange = (name, values) => {
    if (name === 'courseIds') {
      setCourseIds(values)
    }
  }

  const onSuccess = useCallback(({ data: { unenrollLearnersFromCourses: success } }) => {
    const learnerCount = learnerIds.length
    const courseCount = courseIds.length
    if (success) {
      let successMessage
      if (learnerCount === 1 && learnerName) {
        successMessage = I18n.t('successMessageUserName', { ...trOpt, count: courseCount, name: learnerName })
      } else if (learnerCount === 1) {
        successMessage = I18n.t('successMessageSingleUser', { ...trOpt, count: courseCount })
      } else {
        successMessage = I18n.t('successMessage', { ...trOpt, count: courseCount })
      }
      message.success(successMessage)
      closeModal()
    } else {
      let notEnrolledMessage
      if (learnerCount === 1 && learnerName) {
        notEnrolledMessage = I18n.t('userNotEnrolledMessageUserName', { ...trOpt, count: courseCount, name: learnerName })
      } else if (learnerCount === 1) {
        notEnrolledMessage = I18n.t('userNotEnrolledMessage', { ...trOpt, count: courseCount })
      } else {
        notEnrolledMessage = I18n.t('usersNotEnrolledMessage', { ...trOpt, count: courseCount })
      }
      throw new Error(notEnrolledMessage)
    }
  }, [closeModal, learnerName, learnerIds, courseIds])

  if (coursesError) return <ErrorAlerts error={coursesError} defaultError={I18n.t('coursesError', trOpt)} />
  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      onCancel={closeModal}
      afterClose={afterClose}
      title={<h1>{title}</h1>}
      width='600px'
    >
      <LoadingBlock loading={coursesLoading} fullScreen={false} />
      <MutationForm
        mutation={UNENROLL_LEARNERS_FROM_COURSES}
        failureMessage={I18n.t('errorMessage', { ...trOpt, count: courseIds.length })}
        submitLabel={I18n.t('submitLabel', trOpt)}
        variables={{ learnerIds }}
        disableSubmitIfInvalid={false}
        fields={fields}
        ref={form}
        onChange={onChange}
        onSuccess={onSuccess}
        refetchQueries={refetchQueries}
        update={invalidateLearnersAndLearnerDataQueryCache}
      />
    </Modal>
  )
})

export default UnenrolLearnersFromCourseModal
