import React, { useRef, useCallback, useMemo } from 'react'
import { Table, Input, Button, Icon } from 'antd'
import _startCase from 'lodash/startCase'
import _isNumber from 'lodash/isNumber'
import { Link, generatePath } from 'react-router-dom'
import moment from 'moment'
import I18n from 'i18n-js'
import styled, { withTheme } from 'styled-components'
import _isInteger from 'lodash/isInteger'

import routes from '../../../constants/routes'
import { creators as viewActions } from '../../../state/actions/view'
import { generateCertificate } from '../../../helpers/certificates'
import { LIST_PAGINATION_PROPS } from '../../../constants/list'
import { dateTimeColumnSorter, numberColumnSorter, textColumnSorter } from '../../../helpers/listPages'
import { courseResultDurationColumnSorter, coursePercentageColumnSorter } from '../../../helpers/uLearnReport'
import { COURSE_SUBJECTS, COURSE_SUBJECT_IDS, COURSE_SUBJECT_OPTIONS } from '../../../constants/courses'
import { formatDate } from '../../../helpers/datetime'
import useGlobalState from '../../../hooks/useGlobalState'
import { sessionLocale } from '../../../state/selectors/session'
import { permissions } from '../../../constants/permissions'
import { useHasSessionPermission } from '../../../hooks'

const trOpt = { scope: 'reports.uLearnReport' }

const ActionsCell = styled.div`
  &> * {
    margin-right: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
`

const _DownloadCertificateLink = ({ className, course, onClick: onClickProp = () => {} }) => {
  const onClick = useCallback(() => onClickProp(course), [onClickProp, course])
  return (
    <Button type='link' {...{ className, onClick }}>{I18n.t('common.downloadCertificate')}</Button>
  )
}
const DownloadCertificateLink = styled(_DownloadCertificateLink)`
  font-weight: 300;
  padding: 0;
`

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm()
}

const handleReset = clearFilters => {
  clearFilters()
}

const CourseTable = ({ courseTableData: rawCourseTableData, hasLearnerId, learnerName, theme }) => {
  const { locale, setLoadingVisible } = useGlobalState(
    state => ({ locale: sessionLocale(state) }),
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    })
  )

  const searchInputRef = useRef(null)

  const { hasAllSessionPermissions } = useHasSessionPermission()

  const getColumnSearchProps = useCallback(dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={I18n.t('searchCourseName', trOpt)}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => { handleSearch(selectedKeys, confirm, dataIndex) }}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {I18n.t('search', trOpt)}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          {I18n.t('reset', trOpt)}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select())
      }
    }
  }), [searchInputRef])

  const handleDownloadCertificate = useCallback(async course => {
    try {
      setLoadingVisible(true)
      const { courseName: courseTitle, finishDate: dateOfCompletion, score: scorePercentage } = course
      await generateCertificate({
        logoSrc: theme.appThemeLogo,
        courseTitle,
        dateOfCompletion,
        scorePercentage,
        presentedToName: learnerName,
        ribbonColour: theme.primary,
        locale
      })
    } catch (e) {
      console.error('Learner.downloadCertificate - ERROR', e)
    } finally {
      setLoadingVisible(false)
    }
  }, [setLoadingVisible, theme, learnerName, locale])

  const renderActions = useCallback((course) => {
    // The view link logic will need to change if we add a learner/course report to view an individual course result
    const actions = [
      <Link key='viewCourse' to={generatePath(routes.COURSE_REPORT, { courseId: course.courseId })}>{I18n.t('view', trOpt)}</Link>
    ]
    if (hasAllSessionPermissions([permissions.COURSE_DOWNLOAD_CERTIFICATE]) && course.finishDate && hasLearnerId && !course.isGapAnalysis) {
      actions.push(<DownloadCertificateLink key='certificate' course={course} onClick={handleDownloadCertificate} />)
    }
    return <ActionsCell>{actions}</ActionsCell>
  }, [hasLearnerId, handleDownloadCertificate, hasAllSessionPermissions])

  const courseTableData = useMemo(() =>
    rawCourseTableData?.map(({ courseSubject, ...course }) => ({
      ...course,
      courseSubject: COURSE_SUBJECT_IDS.includes(courseSubject) ? courseSubject : 'Custom',
      courseSubjectStr: COURSE_SUBJECTS[courseSubject] || COURSE_SUBJECTS.Custom
    }))
  , [rawCourseTableData])

  const courseColumns = useMemo(() => {
    const columns = [
      {
        title: I18n.t('courseName', trOpt),
        dataIndex: 'courseName',
        key: 'courseName',
        sorter: (a, b) => textColumnSorter('courseName', a, b),
        ...getColumnSearchProps('courseName')
      },
      {
        title: I18n.t('courseSubject', trOpt),
        dataIndex: 'courseSubjectStr',
        key: 'courseSubject',
        onFilter: (value, record) => record.courseSubject.indexOf(value) === 0,
        filters: COURSE_SUBJECT_OPTIONS.map(({ value, label: text }) => ({ value, text })),
        sorter: (a, b) => textColumnSorter('courseSubjectStr', a, b)
      }
    ]
    if (hasLearnerId) {
      // Courses Table on Single Learner Report
      columns.push(
        {
          title: I18n.t('score', trOpt),
          key: 'score',
          dataIndex: 'score',
          sorter: (a, b) => numberColumnSorter('score', a, b),
          render: (totalScore) => {
            if (!totalScore) return <span>{I18n.t('common.na')}</span>
            return <span>{totalScore}%</span>
          }
        },
        {
          title: I18n.t('retries', trOpt),
          key: 'retries',
          dataIndex: 'retries',
          sorter: (a, b) => numberColumnSorter('retries', a, b),
          render: (retries) => {
            return _isNumber(retries) ? retries : I18n.t('common.na')
          }
        },
        {
          title: I18n.t('enrollDate', trOpt),
          key: 'enrollDate',
          dataIndex: 'enrollDate',
          sorter: (a, b) => dateTimeColumnSorter('enrollDate', a, b),
          render: (enrollDate) => {
            return <div>{enrollDate ? formatDate(enrollDate) : I18n.t('common.na')}</div>
          }
        },
        {
          title: I18n.t('startDate', trOpt),
          key: 'startDate',
          dataIndex: 'startDate',
          sorter: (a, b) => dateTimeColumnSorter('startDate', a, b),
          render: (startDate) => {
            return <div>{startDate ? formatDate(startDate) : I18n.t('common.na')}</div>
          }
        },
        {
          title: I18n.t('finishDate', trOpt),
          key: 'finishDate',
          dataIndex: 'finishDate',
          sorter: (a, b) => dateTimeColumnSorter('finishDate', a, b),
          render: (finishDate) => {
            return <div>{finishDate ? formatDate(finishDate) : I18n.t('common.na')}</div>
          }
        },
        {
          title: I18n.t('completionTimeFromStart', trOpt),
          key: 'timeToCompleteFromStart',
          dataIndex: 'timeToCompleteFromStart',
          sorter: (a, b) => courseResultDurationColumnSorter('timeToCompleteFromStart', a, b),
          render: (totalMinutesFromStart, { startDate }) => {
            if (!startDate) return <span>{I18n.t('notStarted', trOpt)}</span>
            if (!_isInteger(totalMinutesFromStart)) return <span>{I18n.t('notCompleted', trOpt)}</span>
            return <span>{_startCase(moment.duration(totalMinutesFromStart, 'minutes').humanize())}</span>
          }
        },
        {
          title: I18n.t('completionTimeFromEnrollment', trOpt),
          key: 'timeToCompleteFromEnrollment',
          dataIndex: 'timeToCompleteFromEnrollment',
          sorter: (a, b) => courseResultDurationColumnSorter('timeToCompleteFromEnrollment', a, b),
          render: (totalMinutesFromEnrollment, { startDate }) => {
            if (!startDate) return <span>{I18n.t('notStarted', trOpt)}</span>
            if (!_isInteger(totalMinutesFromEnrollment)) return <span>{I18n.t('notCompleted', trOpt)}</span>
            return <span>{_startCase(moment.duration(totalMinutesFromEnrollment, 'minutes').humanize())}</span>
          }
        }
      )
    } else {
      // Courses Table on Company Report
      columns.push(
        {
          title: I18n.t('learnersEnrolled', trOpt),
          dataIndex: 'totalCourseResults',
          key: 'totalCourseResults',
          sorter: (a, b) => numberColumnSorter('totalCourseResults', a, b)
        },
        {
          title: `${I18n.t('coursesStarted', trOpt)} %`,
          key: 'totalCoursesStarted',
          dataIndex: 'totalCoursesStarted',
          sorter: (a, b) => coursePercentageColumnSorter('totalCoursesStarted', 'totalCourseResults', a, b),
          render: (totalCoursesStarted, { totalCourseResults }) => {
            return <span>{Math.floor(totalCoursesStarted * 100 / totalCourseResults)}%</span>
          }
        },
        {
          title: `${I18n.t('coursesFinished', trOpt)} %`,
          key: 'totalCoursesFinished',
          dataIndex: 'totalCoursesFinished',
          sorter: (a, b) => coursePercentageColumnSorter('totalCoursesFinished', 'totalCourseResults', a, b),
          render: (totalCoursesFinished, { totalCourseResults }) => {
            return <span>{Math.floor(totalCoursesFinished * 100 / totalCourseResults)}%</span>
          }
        },
        {
          title: I18n.t('averageCompletionTimeFromStart', trOpt),
          key: 'averageTimeFromStart',
          dataIndex: 'averageTimeFromStart',
          sorter: (a, b) => numberColumnSorter('averageTimeFromStart', a, b),
          render: (averageTimeFromStart) => {
            if (_isNumber(averageTimeFromStart)) {
              return _startCase(moment.duration(averageTimeFromStart, 'minutes').humanize())
            } else {
              return I18n.t('common.na')
            }
          }
        },
        {
          title: I18n.t('averageCompletionTimeFromEnrollment', trOpt),
          key: 'averageTimeFromEnrollment',
          dataIndex: 'averageTimeFromEnrollment',
          sorter: (a, b) => numberColumnSorter('averageTimeFromEnrollment', a, b),
          render: (averageTimeFromEnrollment) => {
            if (_isNumber(averageTimeFromEnrollment)) {
              return _startCase(moment.duration(averageTimeFromEnrollment, 'minutes').humanize())
            } else {
              return I18n.t('common.na')
            }
          }
        },
        {
          title: I18n.t('totalRetries', trOpt),
          key: 'totalRetries',
          dataIndex: 'totalRetries',
          sorter: (a, b) => numberColumnSorter('totalRetries', a, b),
          render: (totalRetries) => {
            return _isNumber(totalRetries) ? totalRetries : I18n.t('common.na')
          }
        }, {
          title: I18n.t('averageRetries', trOpt),
          key: 'averageRetries',
          dataIndex: 'averageRetries',
          sorter: (a, b) => numberColumnSorter('averageRetries', a, b),
          render: (averageRetries) => {
            return _isNumber(averageRetries) ? averageRetries : I18n.t('common.na')
          }
        },
        {
          title: I18n.t('averageScore', trOpt),
          key: 'averageScore',
          dataIndex: 'averageScore',
          sorter: (a, b) => numberColumnSorter('averageScore', a, b),
          render: (averageScore) => {
            return _isNumber(averageScore) ? `${averageScore}%` : I18n.t('common.na')
          }
        }
      )
    }
    columns.push({
      title: I18n.t('action', trOpt),
      key: 'action',
      render: renderActions
    })
    return columns
  }, [hasLearnerId, renderActions, getColumnSearchProps])

  return (
    <Table
      columns={courseColumns}
      dataSource={courseTableData}
      pagination={LIST_PAGINATION_PROPS}
    />
  )
}

export default withTheme(CourseTable)
