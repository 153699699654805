import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { HttpLink } from 'apollo-link-http'

import { API_HOST, USE_SSL } from '../constants/environment'

export const apiUrl = `${(USE_SSL ? 'https' : 'http')}://${API_HOST}`

export const defaultHttpLink = new HttpLink({
  uri: `${apiUrl}/graphql`
})

export const getTerminatingLink = ({ wsLink, httpLink = defaultHttpLink }) => split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return (
      kind === 'OperationDefinition' && operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)
