import React, { useMemo, useRef } from 'react'
import I18n from 'i18n-js'

import { dateTimeColumnSorter, numberColumnSorter, textColumnSorter } from '../../helpers/listPages'
import { formatDate } from '../../helpers/datetime'
import { ActionTable } from '../../components/common'
import DomainDetailsModal from '../Modals/uBreach/DomainDetailsModal'

const trOpt = { scope: 'uBreach' }

const DomainsTable = ({
  domains,
  showActions = true,
  performAction = () => {},
  tableProps = {},
  storageId = null,
  breachedServices = [],
  breachedUsers = [],
  error,
  loading = false,
  refetchQueries,
  handleRefreshClick
}) => {
  const domainDetailsModalRef = useRef(null)

  const columns = useMemo(() => {
    const columns = [
      {
        title: I18n.t('tables.domain', trOpt),
        dataIndex: 'domain',
        key: 'domain',
        sorter: (a, b) => textColumnSorter('domain', a, b),
        triggerModal: domainDetailsModalRef,
        modalRecordKey: 'domain',
        useModalPager: true
      },
      {
        title: I18n.t('tables.lastBreachDate', trOpt),
        dataIndex: 'lastBreachDate',
        key: 'lastBreachDate',
        sorter: (a, b) => dateTimeColumnSorter('lastBreachDate', a, b),
        render: (lastBreachDate) => lastBreachDate ? formatDate(lastBreachDate) : ''
      },
      {
        title: I18n.t('breachCount', trOpt),
        dataIndex: 'breachCount',
        key: 'breachCount',
        sorter: (a, b) => numberColumnSorter('breachCount', a, b)
      },
      {
        title: I18n.t('tables.userBreachCount', trOpt),
        dataIndex: 'userBreachCount',
        key: 'userBreachCount',
        sorter: (a, b) => numberColumnSorter('userBreachCount', a, b)
      },
      {
        title: I18n.t('tables.resolvedCount', trOpt),
        dataIndex: 'resolvedCount',
        key: 'resolvedCount',
        sorter: (a, b) => numberColumnSorter('resolvedCount', a, b)
      }
    ]
    return columns
  }, [])
  return (
    <>
      <ActionTable
        {...{
          columns,
          loading,
          performAction,
          tableProps,
          storageId,
          showActions
        }}
        dataSource={domains}
      />
      <DomainDetailsModal ref={domainDetailsModalRef} {...{ breachedUsers, breachedServices, error, refetchQueries, handleRefreshClick }} />
    </>
  )
}

export default DomainsTable
