import React from 'react'
import I18n from 'i18n-js'

import UpdateSimulation from './UpdateSimulation'

const CloneSimulation = props => (
  <UpdateSimulation
    clone update={false}
    pageId='cloneSimulation'
    title={I18n.t('uPhish.common.cloneSimulation')}
    {...props}
  />
)

export default CloneSimulation
