/* global localStorage */
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { Button, Dropdown, Menu, message } from 'antd'
import moment from 'moment'
import { useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import queryString from 'query-string'
import { generatePath } from 'react-router-dom'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'

import { ACCOUNT_TYPES, NEXT_RISK_REPORT_STATUS } from '../../constants/company'
import routes from '../../constants/routes'
import { validateDomain } from '../../helpers'
import { assumeCompanyUser, reprocessOutstandingInvoices } from '../../helpers/company'
import { advanceRiskReport } from '../../helpers/riskReport'
import { ADVANCE_RISK_REPORT, REPROCESS_OUTSTANDING_INVOICES } from '../Queries/Companies'
import { ASSUME_COMPANY_USER } from '../Queries/Users'
import { GET_RISK_REPORT_DOWNLOAD_TOKEN } from '../Queries/Reports'
import { ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts } from '../common'
import CompaniesTable from './CompaniesTable'
import EditCompanyModal from '../Modals/EditCompanyModal'
import EditProspectModal from '../Modals/EditProspectModal'
import DeleteCompanyConfirm from '../Modals/DeleteCompanyConfirm'
import BillingExportModal from '../Modals/BillingExportModal'
import StartRiskReportModal from '../Modals/StartRiskReportModal'
import UpgradeProspectModal from '../Modals/UpgradeProspectModal'
import UpgradeTrialModal from '../Modals/UpgradeTrialModal'
import extendCompanyFreeTrialConfirm from '../Modals/ExtendCompanyFreeTrialConfirm'
import SetCompanyFeatureFlagModal, { HAS_FEATURE_FLAGS } from '../Modals/SetCompanyFeatureFlagModal'
import UpdateCompanyAccountTypeModal from '../Modals/UpdateCompanyAccountTypeModal'
import UpdateCompanyParentCompanyModal from '../Modals/UpdateCompanyParentCompanyModal'
import UServiceAdminToolsModal from '../Modals/UServiceAdminToolsModal'
import DownloadReportModal from '../Modals/DownloadReportModal'

import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uService' }

const isFeatureEnabled = (company, settingId) => _get(company, ['uServiceSettings', settingId]) !== false

const getRiskReportStatus = (company) =>
  I18n.t(`riskReport.status.${_get(company, 'riskReport.status') || 'unknown'}`, { defaults: [{ scope: 'common.unknown' }] })

const getCompanyStatus = (rawCompany, now = moment()) => {
  const { partner, plan, suspended, accountType, riskReport } = rawCompany
  if (accountType === 'prospect') {
    return getRiskReportStatus({ riskReport })
  }
  if (suspended) return I18n.t('suspended', trOpt)
  if (!partner && ['freeTrial', 'custom'].includes(plan.planName) && plan.expires) {
    const expires = moment(plan.expires)
    if (now.isAfter(expires)) {
      return I18n.t('trialExpired', trOpt)
    } else {
      return I18n.t('daysRemaining', { ...trOpt, count: Math.ceil(now.diff(expires, 'day', true) * -1) })
    }
  } else {
    return I18n.t('common.active')
  }
}

const getActions = ({ company, disableTenantDeletion = false, disableProspectDeletion = false, enableRiskReportTenants = false, userCompanyId, hasAllSessionPermissions = () => false }) => {
  const { parentCompanyId, accountType, riskReportStatus, uBreachEnabled, uPhishEnabled, billingType } = company || {}
  const actions = []

  const hasSuperUpdatePermission = hasAllSessionPermissions([permissions.COMPANY_SUPER_UPDATE])
  const hasUpdatePermission = hasAllSessionPermissions([permissions.COMPANY_UPDATE])
  const hasDeletePermission = hasAllSessionPermissions([permissions.COMPANY_DELETE])
  const hasImpersonatePermission = hasAllSessionPermissions([permissions.COMPANY_IMPERSONATE])
  const hasRiskReportCreatePermission = hasAllSessionPermissions([permissions.RISK_REPORT_CREATE])
  const hasRiskReportUpdatePermission = hasAllSessionPermissions([permissions.RISK_REPORT_UPDATE])

  const isDirect = hasSuperUpdatePermission && parentCompanyId === null
  const isOwner = parentCompanyId === userCompanyId || isDirect

  if (hasUpdatePermission) actions.push({ key: 'edit', label: I18n.t('common.edit'), icon: 'edit' })

  if (hasImpersonatePermission) actions.push({ key: 'adminLogin', label: I18n.t('adminLogin', trOpt), icon: 'login' })

  if (isOwner) {
    if (riskReportStatus && riskReportStatus !== 'finished' && (accountType === 'prospect' || (enableRiskReportTenants && accountType === 'tenant'))) {
      if (['new', 'domain_complete', 'ubreach_requested', 'ubreach_complete', 'uphish_finished', 'uphish_complete'].includes(riskReportStatus)) {
        const nextStatus = NEXT_RISK_REPORT_STATUS[riskReportStatus]
        actions.push({ key: 'advanceProspect', label: I18n.t(`riskReport.action.${nextStatus}`), icon: 'arrow-right', theme: 'outlined' })
      }
      if (accountType === 'prospect' && hasUpdatePermission) {
        actions.push({
          key: 'convertProspect',
          label: I18n.t(riskReportStatus === 'trial_requested' ? 'trial_requested' : 'skipToTrial', { scope: 'riskReport.mspTaskAction' }),
          icon: 'crown'
        })
      }
    }

    if (hasRiskReportUpdatePermission && accountType === 'prospect' && !riskReportStatus) {
      actions.push({ key: 'advanceProspect', label: I18n.t('startRiskReport', trOpt), icon: 'arrow-right', theme: 'outlined' })
    }

    if (
      hasRiskReportCreatePermission &&
      accountType === 'tenant' && enableRiskReportTenants && !riskReportStatus &&
      uBreachEnabled && uPhishEnabled
    ) {
      actions.push({ key: 'startRiskReport', label: I18n.t('startRiskReport', trOpt), icon: 'carry-out' })
    }
  }

  if (company.onFreeTrial && (hasSuperUpdatePermission || (isOwner && hasUpdatePermission))) {
    actions.push({ key: 'upgradeTrial', label: I18n.t('modals.editCompanyModal.upgradeTrial'), icon: 'credit-card' })
  }

  if (riskReportStatus && (accountType === 'prospect' || accountType === 'tenant')) {
    actions.push(
      { key: 'openRiskReport', label: I18n.t('riskReport.openRiskReport'), icon: 'file' },
      { key: 'downloadRiskReport', label: I18n.t('riskReport.downloadRiskReport'), icon: 'download', theme: 'outlined' }
    )
  }

  if (hasSuperUpdatePermission) {
    if (company.onFreeTrial) {
      actions.push({ key: 'extendTrial', label: I18n.t('modals.editCompanyModal.extendTrial'), icon: 'schedule' })
    }
    if (accountType === 'msp' && isDirect && billingType === 'auto') {
      actions.push({ key: 'reprocessInvoices', label: I18n.t('reprocessInvoices', trOpt), icon: 'credit-card' })
    }
    if (accountType !== 'prospect' && HAS_FEATURE_FLAGS) {
      actions.push({ key: 'setFeatureFlag', label: I18n.t('setFeatureFlag', trOpt), icon: 'experiment' })
    }
    if (accountType !== 'prospect') {
      actions.push({ key: 'updateAccountType', label: I18n.t('updateAccountType', trOpt), icon: 'star' })
    }
    if (accountType !== 'distributor') {
      actions.push({ key: 'updateParentCompany', label: I18n.t('updateParentCompany', trOpt), icon: 'reconciliation' })
    }
    actions.push({ key: 'openAdminTools', label: I18n.t('openAdminTools', trOpt), icon: 'tool' })
  }

  if (
    hasDeletePermission && (
      accountType === 'distributor' ||
      accountType === 'msp' ||
      (accountType === 'tenant' && !disableTenantDeletion) ||
      (accountType === 'prospect' && !disableProspectDeletion)
    )
  ) {
    actions.push({ key: 'delete', label: I18n.t('deleteAccountType', { ...trOpt, accountType: ACCOUNT_TYPES[accountType] || accountType }), icon: 'delete' })
  }

  return actions
}

const CompaniesView = ({
  loading = false, error, companies: rawCompanies,
  searchFilterText = '',
  updateSearchFilterText = () => {},
  skipAllCompaniesFilter = false,
  panelLeft = [],
  panelRight = ['search', 'add'],
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  disablePagination = false,
  companyId,
  userId,
  accountType,
  userAccountType,
  showExternalId,
  path,
  theme,
  refetchQueries,
  upgradeProspectRefetchQueries,
  enableProspects,
  disableTenantDeletion,
  disableProspectDeletion,
  enableRiskReportTenants,
  openAddModal,
  setPollingEnabled,
  skipDefaultCompanySort = false,
  hideEmptyTable = false,
  showCompanyCount = true
}) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const [showEditCompanyModal, updateShowEditCompanyModal] = useState(false)
  const [showEditProspectModal, updateShowEditProspectModal] = useState(false)
  const [showBillingExportModal, updateShowBillingExportModal] = useState(false)
  const [editCompany, updateEditCompany] = useState(null)
  const [showDeleteCompanyModal, updateShowDeleteCompanyModal] = useState(false)
  const [deleteCompanies, updateDeleteCompanies] = useState([])
  const [createCompanyType, setCreateCompanyType] = useState('')

  // Set query polling based on whether these modals are open: EditCompanyModal, EditProspectModal, DeleteCompanyModal, BillingExportModal
  // These modals are opened using a `visible` prop instead of with a call to `ref.current.open()`
  // We can track their open state here instead of calling setPollingEnabled each time they open and passing the onModalClose function for them to call on close
  useEffect(() => {
    setPollingEnabled(!(showEditCompanyModal || showEditProspectModal || showDeleteCompanyModal || showBillingExportModal))
  }, [
    showEditCompanyModal, showEditProspectModal, showDeleteCompanyModal, showBillingExportModal, setPollingEnabled
  ])

  const onModalClose = useCallback(() => setPollingEnabled(true), [setPollingEnabled])

  const editAccountType = useMemo(() => {
    if (accountType) {
      return accountType
    } else if (userAccountType === 'distributor') {
      return 'msp'
    } else if (userAccountType === 'msp') {
      return 'tenant'
    }
    return null
  }, [userAccountType, accountType])
  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const allCompanies = useMemo(() => {
    if (hideEmptyTable && !(_isArray(rawCompanies) && rawCompanies.length > 0)) return null

    const now = moment()
    const companies = rawCompanies.map(rawCompany => {
      const { id, name, uServiceSettings, accountType, activeLearnerCount: activePeople, childCompanyCount, billingType, externalId, parentCompanyId, parentCompany, domain, createdAt } = rawCompany
      const company = {
        key: id,
        id,
        autoEnrol: I18n.t(`common.${_get(uServiceSettings, 'autoSubscription') ? 'on' : 'off'}`),
        plan: _get(rawCompany, 'plan.planName'),
        activePeople,
        childCompanyCount,
        status: getCompanyStatus(rawCompany, now),
        riskReportStatus: _get(rawCompany, 'riskReport.status'),
        riskReportStatusLabel: getRiskReportStatus(rawCompany),
        riskReportContactName: `${(_get(rawCompany, 'riskReport.firstName') || '').trim()} ${(_get(rawCompany, 'riskReport.lastName') || '').trim()}`.trim(),
        riskReportContactEmail: _get(rawCompany, 'riskReport.email'),
        name,
        accountType,
        platformAccess: I18n.t(`common.${_get(uServiceSettings, 'platformAccess') ? 'yes' : 'no'}`),
        billingType,
        externalId,
        parentCompanyId,
        parentCompany: parentCompany ? parentCompany.name : 'usecure',
        domain,
        uBreachEnabled: isFeatureEnabled(rawCompany, 'uBreachEnabled'),
        uPhishEnabled: isFeatureEnabled(rawCompany, 'uPhish'),
        intercomEnabled: isFeatureEnabled(rawCompany, 'intercomEnabled'),
        enableCreateDomainScan: isFeatureEnabled(rawCompany, 'enableCreateDomainScan'),
        onFreeTrial: accountType === 'tenant' && _get(rawCompany, 'plan.planName') === 'freeTrial',
        signUp: _get(rawCompany, 'riskReport.signUp') === true,
        createdAt,
        riskReportLastUpdatedAt: _get(rawCompany, 'riskReport.lastActivity')
      }
      company.actions = getActions({ company, disableTenantDeletion, disableProspectDeletion, enableRiskReportTenants, userCompanyId: companyId, hasAllSessionPermissions })
      return company
    })

    if (!skipDefaultCompanySort) {
      companies.sort((a, b) => a.name.localeCompare(b.name))
    }

    return companies
  }, [rawCompanies, disableTenantDeletion, disableProspectDeletion, enableRiskReportTenants, companyId, skipDefaultCompanySort, hideEmptyTable, hasAllSessionPermissions])
  const [companies, setCompanies] = useState([])
  const applyCompanyFilters = useCallback((searchFilterText, showExternalId) => {
    let companies = allCompanies
    if (searchFilterText && _isArray(companies)) {
      const filterFields = ['name']
      if (showExternalId) filterFields.push('externalId')
      // Only filter by domain if input is a valid domain (at least in regex terms)
      if (validateDomain(searchFilterText, { skipWWWCheck: true })) {
        filterFields.push('domain')
      }

      const filterValue = searchFilterText.toLowerCase()
      companies = companies.filter(company => {
        return filterFields.some(field => {
          if (!company[field]) return false
          const value = String(company[field]).toLowerCase()
          return field === 'domain'
            ? value === filterValue // Case insensitive equality check for domain
            : value.includes(filterValue)
        })
      })
    }

    setCompanies(companies)
  }, [allCompanies])
  const debouncedApplyCompanyFilters = useCallback(_debounce(applyCompanyFilters, 500), [applyCompanyFilters])
  useEffect(() => {
    if (!skipAllCompaniesFilter) debouncedApplyCompanyFilters(searchFilterText, showExternalId)
  }, [debouncedApplyCompanyFilters, skipAllCompaniesFilter, searchFilterText, showExternalId])
  useEffect(() => {
    if (skipAllCompaniesFilter) setCompanies(allCompanies)
  }, [allCompanies, skipAllCompaniesFilter])

  const onAddProspectClick = useCallback(() => {
    updateShowEditProspectModal(true)
    updateEditCompany(null)
  }, [updateShowEditProspectModal, updateEditCompany])
  const onAddClick = useCallback(() => {
    if (editAccountType === 'prospect') {
      onAddProspectClick()
    } else {
      updateShowEditCompanyModal(true)
      updateEditCompany(null)
    }
  }, [updateShowEditCompanyModal, updateEditCompany, onAddProspectClick, editAccountType])
  const onAddTypeClick = useCallback((type) => {
    if (type === 'prospect') {
      onAddProspectClick()
    } else {
      setCreateCompanyType(type)
      onAddClick()
    }
  }, [setCreateCompanyType, onAddClick, onAddProspectClick])

  const findCompany = useCallback(companyId => companies.find(c => c.id === companyId), [companies])
  const onEditClick = useCallback(companyId => {
    const company = findCompany(companyId)
    if (company && company.accountType === 'prospect') {
      updateShowEditProspectModal(true)
    } else {
      updateShowEditCompanyModal(true)
    }
    updateEditCompany(companyId)
  }, [updateShowEditCompanyModal, updateShowEditProspectModal, updateEditCompany, findCompany])
  const onDeleteClick = useCallback(companyId => {
    updateShowDeleteCompanyModal(true)
    updateDeleteCompanies([companyId])
  }, [updateShowDeleteCompanyModal, updateDeleteCompanies])

  const assumeStorageId = useMemo(() => `uServiceAssume|${companyId}|${userId}`, [userId, companyId])
  useEffect(() => {
    localStorage.removeItem(assumeStorageId)
  }, [assumeStorageId])
  const [executeAssumeCompanyUser] = useMutation(ASSUME_COMPANY_USER)
  const onAssumeClick = useCallback(async (companyId, admin = false) => {
    const company = findCompany(companyId)
    return assumeCompanyUser({ executeAssumeCompanyUser, company, accountType, assumeStorageId, admin })
  }, [assumeStorageId, accountType, executeAssumeCompanyUser, findCompany])

  const [executeAdvanceRiskReport] = useMutation(ADVANCE_RISK_REPORT, { refetchQueries })
  const onAdvanceClick = useCallback(async (id) => {
    const { name, riskReportStatus } = findCompany(id)
    return advanceRiskReport(executeAdvanceRiskReport, id, name, riskReportStatus, true)
  }, [executeAdvanceRiskReport, findCompany])

  const startRiskReportModalRef = useRef(null)
  const onStartRiskReportClick = useCallback(companyId => {
    if (startRiskReportModalRef.current) {
      setPollingEnabled(false)
      startRiskReportModalRef.current.open(findCompany(companyId))
    }
  }, [startRiskReportModalRef, findCompany, setPollingEnabled])

  const upgradeTrialModalRef = useRef(null)
  const onUpgradeTrialClick = useCallback(companyId => {
    if (upgradeTrialModalRef.current) {
      setPollingEnabled(false)
      upgradeTrialModalRef.current.open(companyId)
    }
  }, [upgradeTrialModalRef, setPollingEnabled])

  const updateAccountTypeModalRef = useRef(null)
  const onUpdateAccountTypeClick = useCallback(companyId => {
    if (updateAccountTypeModalRef.current) {
      setPollingEnabled(false)
      updateAccountTypeModalRef.current.open(companyId)
    }
  }, [updateAccountTypeModalRef, setPollingEnabled])

  const updateParentCompanyModalRef = useRef(null)
  const onUpdateParentCompanyClick = useCallback(companyId => {
    if (updateParentCompanyModalRef.current) {
      setPollingEnabled(false)
      updateParentCompanyModalRef.current.open(companyId)
    }
  }, [updateParentCompanyModalRef, setPollingEnabled])

  const onOpenRiskReportClick = useCallback(companyId => {
    window.open(`${generatePath(routes.RISK_REPORT, { companyId })}?${queryString.stringify({ readOnly: true })}`, '_blank')
  }, [])

  const upgradeProspectModalRef = useRef(null)
  const onConvertClick = useCallback(companyId => {
    if (upgradeProspectModalRef.current) {
      setPollingEnabled(false)
      upgradeProspectModalRef.current.open(companyId)
    }
  }, [upgradeProspectModalRef, setPollingEnabled])

  const setCompanyFeatureFlagModalRef = useRef(null)
  const onSetFeatureFlagClick = useCallback(companyId => {
    if (setCompanyFeatureFlagModalRef.current) {
      setPollingEnabled(false)
      setCompanyFeatureFlagModalRef.current.open(companyId)
    }
  }, [setCompanyFeatureFlagModalRef, setPollingEnabled])

  const onExtendTrialClick = useCallback(companyId => {
    extendCompanyFreeTrialConfirm({ companyId, refetchQueries })
  }, [refetchQueries])

  const [executeReprocessInvoices] = useMutation(REPROCESS_OUTSTANDING_INVOICES, { refetchQueries })
  const onReprocessInvoicesClick = useCallback(
    companyId => reprocessOutstandingInvoices({ companyId, executeReprocessInvoices }),
    [executeReprocessInvoices]
  )

  const adminToolsModalRef = useRef(null)
  const onOpenAdminToolsClick = useCallback(companyId => {
    if (adminToolsModalRef.current) {
      setPollingEnabled(false)
      adminToolsModalRef.current.open(companyId)
    }
  }, [adminToolsModalRef, setPollingEnabled])

  const downloadReportModalRef = useRef(null)
  const openDownloadRiskReportModal = useCallback(companyId => {
    const company = findCompany(companyId)
    if (downloadReportModalRef.current && company) {
      downloadReportModalRef.current.open({
        reportType: 'riskReport',
        companyId,
        name: I18n.t('reports.downloadReport.fileNames.riskReport', { companyName: company.name })
      })
    } else {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
    }
  }, [downloadReportModalRef, findCompany])

  const performAction = useCallback(async (action, ids) => {
    switch (action) {
      case 'edit':
        onEditClick(ids[0])
        break
      case 'adminLogin':
        await onAssumeClick(ids[0], true)
        break
      case 'userLogin':
        await onAssumeClick(ids[0], false)
        break
      case 'delete':
        onDeleteClick(ids[0])
        break
      case 'advanceProspect':
        await onAdvanceClick(ids[0])
        break
      case 'convertProspect':
        onConvertClick(ids[0])
        break
      case 'setFeatureFlag':
        onSetFeatureFlagClick(ids[0])
        break
      case 'startRiskReport':
        onStartRiskReportClick(ids[0])
        break
      case 'openRiskReport':
        onOpenRiskReportClick(ids[0])
        break
      case 'extendTrial':
        onExtendTrialClick(ids[0])
        break
      case 'reprocessInvoices':
        await onReprocessInvoicesClick(ids[0])
        break
      case 'upgradeTrial':
        onUpgradeTrialClick(ids[0])
        break
      case 'updateAccountType':
        onUpdateAccountTypeClick(ids[0])
        break
      case 'updateParentCompany':
        onUpdateParentCompanyClick(ids[0])
        break
      case 'openAdminTools':
        onOpenAdminToolsClick(ids[0])
        break
      case 'downloadRiskReport':
        openDownloadRiskReportModal(ids[0])
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [
    onEditClick, onDeleteClick, onAdvanceClick, onConvertClick, onAssumeClick,
    onStartRiskReportClick, onOpenRiskReportClick, onExtendTrialClick, onReprocessInvoicesClick,
    onSetFeatureFlagClick, onUpgradeTrialClick, onUpdateAccountTypeClick, onUpdateParentCompanyClick,
    onOpenAdminToolsClick, openDownloadRiskReportModal
  ])

  const onBillingExportClick = useCallback(() => updateShowBillingExportModal(true), [updateShowBillingExportModal])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    if ((panelElems ?? []).length === 0) {
      return null
    }
    return (
      <ListHeaderPanel align={align}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'search':
                return (
                  <SearchBar
                    key={index}
                    placeholder={I18n.t('searchForACompany', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                  />
                )
              case 'add':
                return (
                  <Button key={index} type='primary' icon='plus' onClick={onAddClick}>{I18n.t('common.createItemName', { name: ACCOUNT_TYPES[editAccountType] || editAccountType })}</Button>
                )
              case 'add-prospect': {
                const menu = (
                  <Menu>
                    <Menu.Item onClick={() => onAddTypeClick('tenant')}>{ACCOUNT_TYPES.tenant}</Menu.Item>
                    <Menu.Item onClick={onAddProspectClick}>{ACCOUNT_TYPES.prospect}</Menu.Item>
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} key={index}>
                    <Button key={index} type='primary' icon='plus'>
                      {I18n.t('common.create')}
                    </Button>
                  </Dropdown>
                )
              }
              case 'addMenu': {
                const types = Object.keys(ACCOUNT_TYPES)
                const menuItems = (enableProspects ? types : types.filter(type => type !== 'prospect'))
                  .map((type, index) => (
                    <Menu.Item key={index} onClick={() => onAddTypeClick(type)}>{ACCOUNT_TYPES[type]}</Menu.Item>
                  ))
                const menu = <Menu>{menuItems}</Menu>

                return (
                  <Dropdown overlay={menu} key={index}>
                    <Button key={index} type='primary' icon='plus'>
                      {I18n.t('common.create')}
                    </Button>
                  </Dropdown>
                )
              }
              case 'billing-export':
                return (
                  <Button key={index} type='primary' icon='mail' onClick={onBillingExportClick}>{I18n.t('sendBillingExport', trOpt)}</Button>
                )
              default:
                return (
                  panelElem ? <div key={index}>{panelElem}</div> : null
                )
            }
          })
        }
      </ListHeaderPanel>
    )
  }, [searchFilterText, onSearchChange, editAccountType, onAddClick, onBillingExportClick, onAddTypeClick, onAddProspectClick, enableProspects])

  useEffect(() => {
    if (openAddModal) {
      onAddClick()
    }
  }, [openAddModal, onAddClick])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      {(panelLeftComponent || panelRightComponent) && (
        <ListHeader>
          {panelLeftComponent}
          {panelRightComponent}
        </ListHeader>
      )}
      <EditCompanyModal
        setVisible={updateShowEditCompanyModal}
        setCreateCompanyType={setCreateCompanyType}
        visible={showEditCompanyModal}
        companyId={showEditCompanyModal ? editCompany : undefined}
        accountType={editAccountType || createCompanyType}
        userAccountType={userAccountType}
        refetchQueries={refetchQueries}
      />
      <EditProspectModal
        setVisible={updateShowEditProspectModal}
        visible={showEditProspectModal}
        companyId={showEditProspectModal ? editCompany : undefined}
        refetchQueries={refetchQueries}
        goBackToProspectsPage={openAddModal}
      />
      <StartRiskReportModal
        ref={startRiskReportModalRef}
        refetchQueries={refetchQueries}
        onClose={onModalClose}
      />
      <UpgradeTrialModal
        ref={upgradeTrialModalRef}
        refetchQueries={refetchQueries}
        onClose={onModalClose}
      />
      <UpgradeProspectModal
        ref={upgradeProspectModalRef}
        refetchQueries={upgradeProspectRefetchQueries}
        onClose={onModalClose}
      />
      <SetCompanyFeatureFlagModal
        ref={setCompanyFeatureFlagModalRef}
        refetchQueries={refetchQueries}
        onClose={onModalClose}
      />
      <DeleteCompanyConfirm
        setVisible={updateShowDeleteCompanyModal}
        visible={showDeleteCompanyModal}
        companyIds={showDeleteCompanyModal ? deleteCompanies : undefined}
        accountType={editAccountType}
        refetchQueries={refetchQueries}
      />
      <BillingExportModal
        visible={showBillingExportModal}
        setVisible={updateShowBillingExportModal}
        afterClose={onModalClose}
      />
      <UpdateCompanyAccountTypeModal
        ref={updateAccountTypeModalRef}
        refetchQueries={refetchQueries}
        onClose={onModalClose}
      />
      <UpdateCompanyParentCompanyModal
        ref={updateParentCompanyModalRef}
        refetchQueries={refetchQueries}
        onClose={onModalClose}
      />
      <UServiceAdminToolsModal
        ref={adminToolsModalRef}
        refetchQueries={refetchQueries}
        onClose={onModalClose}
      />
      <DownloadReportModal
        ref={downloadReportModalRef}
        getReportAccessTokenMutation={GET_RISK_REPORT_DOWNLOAD_TOKEN}
        getReportAccessTokenMutationName='riskReportDownloadToken'
      />
      {error && (
        <ErrorAlerts
          {...{ error }}
          defaultError={I18n.t('yourAccountTypesCouldNotBeLoaded', { ...trOpt, accountType: I18n.t(editAccountType || 'tenant', { scope: 'common.accountTypesPlural' }) })}
        />
      )}
      {!error && (!hideEmptyTable || companies?.length > 0) && (
        <CompaniesTable
          companies={companies ?? []}
          {...{
            loading,
            companies,
            sorter,
            updateSorter,
            filters,
            updateFilters,
            pagination,
            updatePagination,
            companyId,
            userId,
            accountType,
            listAccountType: editAccountType,
            showExternalId,
            path,
            userAccountType,
            performAction,
            enableProspects,
            enableRiskReportTenants,
            theme,
            showCompanyCount,
            disablePagination
          }}
        />
      )}
    </>
  )
}

export default CompaniesView
