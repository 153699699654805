import React from 'react'
import styled from 'styled-components'

const size = 2.2
const CheckStyle = styled.div`
    display: inline-block;
    width: ${size}em;
    height: ${size}em;
    position: relative;
    border: ${props => props.borderWidth || `1px`} solid ${props => props.theme[props.color] || props.color || `#607D8B`};
    cursor: pointer;
    background: white;
  
    > i {
      font-size: 1.7em;
        color: ${props => {
    if (props.checked) {
      return props.theme[props.color] || props.color || props.theme.primary
    }
    return 'transparent'
  }};
        top: 50%;
        transition: transform .25s, color .25s; 
        left: 50%;
        transform: translate(-50%, -50%) scale(${props => props.checked ? 1 : 0});
        position: absolute;
    }
    
`

export default class Checkbox extends React.Component {
  render () {
    return <CheckStyle {...this.props}>
      <i className="fa fa-check" />
    </CheckStyle>
  }
}
