import React, { useCallback } from 'react'
import styled from 'styled-components'
import { BCP_47_REGEX, DEFAULT_LANGUAGE } from '../../constants/languages'

const BTN_LOCALES = ['en', 'cs', 'de', 'es', 'fr', 'it', 'nl', 'zh'] // Locales we have a ms-sign-btn svg for

// Localised 'Sign in with Microsoft' SVG buttons
// New languages can be obtained by changing language and download svg images from this URL - https://docs.microsoft.com/en-gb/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps
const getMicrosoftSignInButtonImageForLocale = (locale = DEFAULT_LANGUAGE) => {
  let lang = 'en'
  if (BTN_LOCALES.includes(locale)) {
    lang = locale
  } else if (BCP_47_REGEX.test(locale)) {
    const localeLang = locale.substring(0, 2)
    if (BTN_LOCALES.includes(localeLang)) {
      lang = localeLang
    }
  }

  return `/images/ms-signin-btn/${lang}.svg`
}

const _BrandedMicrosoftAuthButton = ({ className, onClick: onClickProp, locale, disabled }) => {
  const onClick = useCallback(() => {
    if (!disabled) {
      onClickProp()
    }
  }, [onClickProp, disabled])
  return (
    <div {...{ className, onClick, disabled }} style={{ backgroundImage: `url('${getMicrosoftSignInButtonImageForLocale(locale)}')` }} />
  )
}

const BrandedMicrosoftAuthButton = styled(_BrandedMicrosoftAuthButton)`
  background-image: url('/images/ms-signin-btn/en.svg');
  background-size: contain;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  display: inline-block;
  height: 41px;
  width: 215px;
  ${props => props.disabled && 'filter: grayscale(1);'}
`

export default BrandedMicrosoftAuthButton
