import React from 'react'
import { Link } from 'react-router-dom'
import { Alert } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import routes from '../../constants/routes'

const trOpt = { scope: 'uService.addBillingDetailsMessage' }

const BillingAlert = styled(Alert)`
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`

const AddBillingMessage = () => (
  <BillingAlert
    type='warning' showIcon banner
    message={<span>{I18n.t('addDetails', trOpt)} - <Link to={routes.PAYMENT}>{I18n.t('goToPaymentSettings', trOpt)}</Link></span>}
  />
)

export default AddBillingMessage
