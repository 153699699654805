import React, { useState, useImperativeHandle, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Badge, Tree } from 'antd'
import styled, { withTheme } from 'styled-components'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isInteger from 'lodash/isInteger'
import I18n from 'i18n-js'

import { buildTree } from './helpers'
import { GET_GROUP_TREE } from '../Queries/Groups'
import { ErrorAlerts as _ErrorAlerts } from '../common'
import { NO_GROUP_ID } from '../../constants/group'

const { TreeNode } = Tree

const StyledGroupTree = styled(Tree)`
  .group-node-leaf > .ant-tree-switcher {
    display: none;
  }
`

const GroupUserCount = styled(Badge)`
  margin-left: 5px;
`

const ErrorAlerts = styled(_ErrorAlerts)`
  margin-top: 10px;
`

const renderTreeNodes = ({ groupTreeOptions = [], hideSwitcher, theme = {} } = {}) => {
  return groupTreeOptions.map(item => {
    const { key, title, learnerCount, children } = item
    if (_isArray(children)) {
      return (
        <TreeNode
          className={hideSwitcher ? 'group-node-leaf' : undefined}
          title={
            <span>
              <span>{title}</span>
              {
                _isInteger(learnerCount) &&
                  <GroupUserCount count={learnerCount} overflowCount={10000} style={{ backgroundColor: theme.primary }} />
              }
            </span>
          }
          key={key}
          dataRef={item}
        >
          {renderTreeNodes({ groupTreeOptions: children, theme })}
        </TreeNode>
      )
    }
    return <TreeNode key={key} {...item} />
  })
}

const GroupTree = React.forwardRef(({ theme, checkedGroups, updateCheckedGroups = () => {}, updateSelectedGroup = () => {}, isManager, inactive }, ref) => {
  const { error, data, refetch } = useQuery(GET_GROUP_TREE, { variables: { includeLearnerCount: true, isManager, inactive, includeNoGroup: true } })

  const [expandedKeys, setExpandedKeys] = useState([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [checkedKeys, setCheckedKeys] = useState(checkedGroups)
  const [selectedKey, setSelectedKey] = useState(null)

  const { groupTree = [] } = data || {}
  const checkedKeysValue = (_isArray(groupTree) && _isEmpty(groupTree) ? null : checkedGroups) || checkedKeys
  const groupTreeOptions = buildTree(groupTree || [])
  const hideSwitcher = groupTreeOptions.every(group => group.children.length === 0)

  useImperativeHandle(ref, () => ({
    refetch
  }), [refetch])

  const onExpand = useCallback(expandedKeys => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys)
    setAutoExpandParent(false)
  }, [])

  const onCheck = useCallback(checkedKeys => {
    setCheckedKeys(checkedKeys)
    updateCheckedGroups(checkedKeys)
  }, [updateCheckedGroups])

  const onSelect = useCallback(selectedKey => {
    // 'No Group' cannot be selected as it isn't a real group and can't be edited
    if (selectedKey[0] === NO_GROUP_ID) return
    updateSelectedGroup(selectedKey[0])
    setSelectedKey(selectedKey)
  }, [updateSelectedGroup])

  if (groupTreeOptions.length === 0 || (groupTreeOptions.length === 1 && groupTreeOptions[0].key === NO_GROUP_ID)) {
    return null
  }

  return (
    <>
      <ErrorAlerts {...{ error }} defaultError={I18n.t('common.groupsLoadError')} />
      <StyledGroupTree
        checkable
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedKeysValue}
        onSelect={onSelect}
        selectedKeys={selectedKey}
      >
        {renderTreeNodes({ groupTreeOptions, hideSwitcher, theme })}
      </StyledGroupTree>
    </>
  )
})

export default withTheme(GroupTree)
