import React, { useMemo, useRef } from 'react'
import I18n from 'i18n-js'
import styled, { css } from 'styled-components'
import _isArray from 'lodash/isArray'
import { renderParagraphsFragmentFromArray } from '../../helpers'

import AreaChart from '../Graphs/PerformanceReportAreaChart'
import PieChart from '../Graphs/PerformanceReportPieChart'
import LineChart from '../Graphs/PerformanceReportLineChart'
import BarChart from '../Graphs/PerformanceReportBarChart'
import RankedTable from './RankedTable'
import ReportTable from './ReportTable'
import { RoundedCard } from '../EndUserPortal/GridFlexiLayout'

const SMALL_WIDTH = 700

const ChartPlaceholder = styled.div`
  width: 100%;
  color: rgb(117, 117, 117);
  font-size: 20px;
  margin-top: 20px;
`

const PrintableRoundedCard = styled(RoundedCard)`
  ${({ size }) => size === 'small' && 'float: left;'}
  ${({ size }) => size === 'small' && 'width: 485px;'}
  ${({ size }) => size === 'small' && 'display: block;'}
  ${({ size }) => size === 'small' && 'margin-right: 20px;'}

  .ant-card-body {
    padding: 24px;
  }

  @media print {
    &:last-child {
      margin-right: 0
    }
    margin-bottom: 0;
    border: 0;
    padding-bottom: 0;

    padding: 0px 24px !important;

    .ant-card-body {
      padding: 24px 24px 0 !important;
    }
  }
`

const CardContent = styled.div`
  @media (min-width: ${SMALL_WIDTH}px) {
    ${({ size, useFixedColumnLayout }) => {
      let flexDirection
      if (useFixedColumnLayout) flexDirection = 'column'
      else if (size === 'large') flexDirection = 'row'
      if (flexDirection) {
        return css`
      display: flex;
      flex-direction: ${flexDirection};
        `
      }
    }}
  }
`

const CardDescription = styled.div`
  margin-bottom: 20px;

  ${({ size, useFixedColumnLayout, useFullWidth, cardDescriptionWidth }) => {
    if (size === 'large' && !useFixedColumnLayout && !useFullWidth) {
      return css`
  @media (min-width: ${SMALL_WIDTH}px) {
    width: ${cardDescriptionWidth};
    margin-bottom: 0;
  }
      `
    } else if (size === 'large' && useFixedColumnLayout && !useFullWidth) {
      return css`
  @media (min-width: ${SMALL_WIDTH}px) {
    max-width: 60%;
  }
      `
    }
  }}
`
const ChartContent = styled.div`
  margin-right: 0;
  width: 100%;
  ${({ size, useFixedColumnLayout, useFullWidth, chartContentWidth }) => {
    if (size === 'large' && !useFixedColumnLayout && !useFullWidth) {
      return css`
  @media (min-width: ${SMALL_WIDTH}px) {
    width: ${chartContentWidth};
    margin-left: 40px;
  }
      `
    }
  }}

  @media print {
    ${({ hideOnPrint }) => hideOnPrint && 'display: none;'}
  }
`

const CardText = styled.div`
  ${({ size }) => size === 'small' && 'margin-right: 40px;'}
  ${({ size }) => size === 'small' && 'width: 60%;'}
  @media (min-width: ${SMALL_WIDTH}px) {
    ${({ size }) => size === 'large' && 'margin-right: 0;'}
    ${({ size }) => size === 'large' && 'width: 100%;'}
  }
`

const CardIndicator = styled.div`
  width: 40%;
`

const CardChart = styled.div`
  height: ${({ fixedHeight }) => fixedHeight ? '200px' : 'auto'};
  width: 100%;
  @media (min-width: ${SMALL_WIDTH}px) {
    ${({ fixedHeight, size }) => fixedHeight ? size === 'large' ? 'height: 250px;' : 'height: 200px;' : 'height: 100%;'}
  }
  @media print {
    height: 100% !important;
    min-width: 0 !important;
  }
`
const CardHorizontalBarChart = styled.div`
  height: calc(${({ numItems }) => 140 + (numItems * 40)}px);
  width: 100%;
  @media (min-width: ${SMALL_WIDTH}px) {
    height: calc(${({ numItems }) => 140 + (numItems * 40)}px);
  }
  @media print {
    height: calc(${({ numItems }) => 140 + (numItems * 40)}px / 0.9) !important;
    min-width: 0 !important;
  }
`
const ChartTitleSpacer = styled.div`
  display: block;
  height: 33px;

  @media print {
    height: 16px;
  }
`

const SummaryCard = ({
  text = '', // The descriptive text that appears above/beside the chart
  title = '', // Card title
  size = 'large', // or 'small'. Determines which layout to use. Would be better to select layout based on available space
  chartType, // see switch statement for options
  chartProps, // passed to the chart component
  chartTitle, // title for the chart (if needed)
  chartDescription, // description for the chart (if needed)
  chartComponent, // Any component. Will be rendered in the chart area when chartType is 'custom'
  indicator, // Any component - eg `ThumbCard`. On 'small' layout appears alongside text
  children, // Anything that should appear below text on 'large' components. Prob better to scrap 'indicator' and just use this.
  flex,
  useTitleSpacer = false,
  useFixedColumnLayout = false,
  useFullWidth = false,
  chartContentWidth = '60%',
  cardDescriptionWidth = '40%'
}) => {
  const chartRef = useRef(null)

  const { chart, fixedHeight } = useMemo(() => {
    let chart
    // Nivo charts need to be rendered in a container with a height/width
    // The tables will be variable height. `fixedHeight` will be passed to the
    // styled components to control this.
    let fixedHeight = true
    switch (chartType) {
      case 'rankedTable':
        fixedHeight = false
        chart = <RankedTable {...chartProps} />
        break
      case 'table':
        fixedHeight = false
        chart = <ReportTable {...chartProps} />
        break
      case 'pie':
        fixedHeight = false
        chart = <PieChart {...chartProps} containerRef={chartRef} />
        break
      case 'area':
        chart = <AreaChart {...chartProps} />
        break
      case 'line':
        chart = <LineChart {...chartProps} />
        break
      case 'bar':
        chart = <BarChart {...chartProps} />
        break
      case 'custom':
        fixedHeight = false
        chart = chartComponent
        break
      default:
        fixedHeight = false
        chart = <ChartPlaceholder>{I18n.t('reports.noChartData')}</ChartPlaceholder>
    }
    return { chart, fixedHeight }
  }, [chartType, chartProps, chartComponent, chartRef])

  return (
    <PrintableRoundedCard {...{ size, flex }}>
      <CardContent {...{ size, useFixedColumnLayout }}>
        <CardDescription {...{ size, useFixedColumnLayout, useFullWidth, cardDescriptionWidth }}>
          <h1>{title}</h1>
          <CardText size={size === 'small' && indicator ? 'small' : 'large'}>
            <div style={{ marginBottom: 20 }}>
              {_isArray(text) ? renderParagraphsFragmentFromArray(text) : text}
            </div>
            {children}
          </CardText>
          {indicator && <CardIndicator>{indicator}</CardIndicator>}
        </CardDescription>
        <ChartContent {...{ size, useFixedColumnLayout, useFullWidth, chartContentWidth }} hideOnPrint={chartType === undefined}>
          {chartTitle && <h3>{chartTitle}</h3>}
          {chartDescription && <p>{chartDescription}</p>}
          {useTitleSpacer && !chartTitle && <ChartTitleSpacer />}
          {chartType === 'bar' ? (
            <CardHorizontalBarChart {...{ size, numItems: chartProps.data.length }}>{chart}</CardHorizontalBarChart>
          ) : (
            <CardChart ref={chartRef} {...{ fixedHeight, size }}>{chart}</CardChart>
          )}
        </ChartContent>
      </CardContent>
    </PrintableRoundedCard>
  )
}

export default SummaryCard
