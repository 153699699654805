import React, { useRef, useImperativeHandle, useState, useCallback, useEffect, useMemo } from 'react'
import I18n from 'i18n-js'
import { Alert, Button, Form, Input, Skeleton, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import isUrl from 'is-url'
import styled from 'styled-components'
import _omit from 'lodash/omit'
import _isNil from 'lodash/isNil'
import _isPlainObject from 'lodash/isPlainObject'

import { SettingsFormElement, SettingsFormFieldExtra } from './SettingsForm'
import { GET_COMPANY_SSO_SETTINGS, UPDATE_COMPANY_SSO_SETTINGS } from '../Queries/Companies'
import { DragAndDropTextFileReader, ErrorAlerts, LoadingBlock } from '../common'
import { SectionDivider, SectionDividerField } from './common'
import MutationFormErrors from '../MutationForm/MutationFormErrors'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import { CLIENT_ENV, API_SUBDOMAIN } from '../../constants/environment'
import { apiUrl } from '../../apollo-client/common'
import { withRefreshSessionState } from '../../hocs'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'
import { getRequiredUpdatePermissions } from '../../helpers/settings'

const { TextArea } = Input

const trOpt = { scope: 'settings.sso' }
const CERT_POPULATED_VALUE = '%CERT_POPULATED%'
const CERT_FINGERPRINT_INVALID_VALUE = '%INVALID%'

const getUserLoginMethods = methods =>
  methods.map(method => {
    let value
    let trKey
    if (_isPlainObject(method)) {
      ({ value, trKey } = method)
    } else {
      value = method
    }
    return { value, label: I18n.t(trKey || value, { scope: `${trOpt.scope}.userLoginMethods` }) }
  })

const isEnabled = values => values.enabled === true

const isEnabledAndNotPasswordOnly = values => values.enabled === true && values.adminLoginMethod !== 'password'

// Show ACS URL (https://app.{preferredDomain}/auth/saml in prod) and Entity ID (https://app.{preferredDomain} in prod)
const SamlDividerField = React.forwardRef(({ visible, appDomain }, ref) => {
  if (!visible) {
    return null
  }

  let authOrigin
  if (CLIENT_ENV === 'production') {
    // In production - Use server api URL preferred domain under https
    authOrigin = `https://${API_SUBDOMAIN}.${appDomain}`
  } else {
    // Otherwise use current server api URL
    authOrigin = apiUrl
  }

  return (
    <SectionDivider
      title={I18n.t('samlDivider.title', trOpt)}
      visible
    >
      <p>{I18n.t('samlDivider.description.paramIntro', trOpt)}</p>
      <ul className='copy-font'>
        <li>{I18n.t('samlDivider.description.acsUrl', { ...trOpt, acsUrl: `${authOrigin}/auth/saml` })}</li>
        <li>{I18n.t('samlDivider.description.entityId', { ...trOpt, entityId: authOrigin })}</li>
      </ul>
      <p>{I18n.t('samlDivider.description.paramDisclaimer', trOpt)}</p>
    </SectionDivider>
  )
})

const CertAlert = styled(Alert)`
  margin-bottom: 25px;

  .ant-alert-message {
    position: relative;
    top: 2px;
  }
`

const CertTextArea = styled(TextArea)`
  &.ant-input {
    max-height: 600px;
    min-height: 200px;
    height: 400px;
  }
`

const SigningCertificateField = React.forwardRef(({ id, required, visible, value, loading, fingerprint, errors, onChange, disabled }, ref) => {
  const [showTextArea, setShowTextArea] = useState(false)

  const onReplaceCertClick = useCallback(() => {
    setShowTextArea(true)
    onChange(id, null)
  }, [onChange, id])

  const onTextAreaChange = useCallback((event) => {
    const { name, value } = event.target
    onChange(name, value)
  }, [onChange])

  const onFileRead = useCallback(certText => {
    onChange(id, certText)
  }, [onChange, id])

  useEffect(() => {
    setShowTextArea(!loading && !fingerprint)
  }, [loading, fingerprint])
  if (!visible) {
    return null
  }

  if (loading || !showTextArea) {
    return (
      <>
        <Form.Item
          label={I18n.t('samlSigningCertificateFingerprint', trOpt)} required={required}
          extra={
            fingerprint && fingerprint !== CERT_FINGERPRINT_INVALID_VALUE
              ? <SettingsFormFieldExtra copy={I18n.t('samlSigningCertificateFingerprintExtra', trOpt)} />
              : null
          }
        >
          <Skeleton loading={loading || !fingerprint} paragraph={{ rows: 1 }} />
          {!loading && fingerprint && (
            <>
              {
                fingerprint === CERT_FINGERPRINT_INVALID_VALUE
                  ? <CertAlert type='error' showIcon message={I18n.t('samlSigningCertificateFingerprintInvalidError', trOpt)} />
                  : <p className='base-font'>{fingerprint}</p>
              }
            </>
          )}
        </Form.Item>
        <Button type='danger' ghost onClick={onReplaceCertClick} {...{ disabled }}>{I18n.t('replaceCertificate', trOpt)}</Button>
      </>
    )
  }
  const showErrors = errors?.length > 0
  // The placeholder on the textarea is hard coded as it applies in all languages
  return (
    <Form.Item
      label={I18n.t('samlSigningCertificate', trOpt)} required={required}
      validateStatus={showErrors ? 'error' : undefined}
      help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
    >
      <DragAndDropTextFileReader onFileRead={onFileRead}>
        <CertTextArea
          {...{ value, required }}
          onChange={onTextAreaChange}
          name={id}
          type='textArea'
          autoComplete={false}
          placeholder='-----BEGIN CERTIFICATE-----'
        />
      </DragAndDropTextFileReader>
    </Form.Item>
  )
})

const SSO = React.forwardRef(({ accountType, disableSubmit, settings, refreshSessionState, defaultTenant }, ref) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const [, setForceRender] = useState(null)
  const { loading, data, error, refetch } = useQuery(GET_COMPANY_SSO_SETTINGS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })
  const form = useRef(null)

  const onSuccess = useCallback(async () => {
    message.success(I18n.t('successMessage', trOpt))
    await refetch()
    await refreshSessionState()
  }, [refetch, refreshSessionState])

  useEffect(() => {
    if (form && form.current && data?.getSSOSettings) {
      const { samlSigningCertificateFingerprint, ...rest } = data.getSSOSettings
      form.current.setInitialValues({
        ...Object.entries(rest).reduce((acc, [id, value]) => {
          if (!_isNil(value)) acc[id] = value
          return acc
        }, {}),
        samlSigningCertificate: samlSigningCertificateFingerprint ? CERT_POPULATED_VALUE : null
      })
    }
  }, [data, form])

  const requiredUpdatePermissions = getRequiredUpdatePermissions({
    defaultTenant,
    requiredPermissions: [permissions.SETTINGS_SSO_UPDATE]
  })
  const isUpdateAllowed = hasAllSessionPermissions(requiredUpdatePermissions)
  const hasChanged = useCallback(() => isUpdateAllowed && form.current?.hasChanged() === true, [form, isUpdateAllowed])

  useImperativeHandle(ref, () => ({
    hasChanged: hasChanged
  }), [hasChanged])

  const onChange = () => setForceRender(Date.now())

  const mutateValues = useCallback(values => {
    const ssoSettings = _omit(values, ['authMethodsDivider', 'samlDivider', 'additionalSettingsDivider'])
    if (ssoSettings.samlSigningCertificate === CERT_POPULATED_VALUE) {
      delete ssoSettings.samlSigningCertificate
    }
    return ssoSettings
  }, [])

  const fields = useMemo(() => {
    const isMSP = accountType === 'msp'
    const fields = [{
      id: 'enabled',
      type: 'switch',
      label: I18n.t('enabled', trOpt),
      defaultValue: false
    },
    {
      id: 'authMethodsDivider',
      type: 'custom',
      component: SectionDividerField,
      title: I18n.t('authMethodsDivider.title', trOpt),
      description: I18n.t('authMethodsDivider.description', trOpt),
      visible: isEnabled
    }, {
      id: 'adminLoginMethod',
      type: 'radio',
      label: I18n.t('adminLoginMethod', trOpt),
      options: getUserLoginMethods(['password', 'sso', { value: 'both', trKey: 'bothAdmin' }]),
      defaultValue: 'both',
      required: isEnabled,
      visible: isEnabled,
      extra: <SettingsFormFieldExtra copy={I18n.t('adminLoginMethodExtra', trOpt)} />
    },
    {
      id: 'disableUsecureMfa',
      type: 'switch',
      label: I18n.t('usecureMfa.title', trOpt),
      defaultValue: false,
      extra: I18n.t('usecureMfa.description', trOpt),
      visible: isEnabledAndNotPasswordOnly
    },
    {
      id: 'endUserLoginMethod',
      type: 'radio',
      label: I18n.t('endUserLoginMethod', trOpt),
      options: getUserLoginMethods(['magicLink', 'sso', { value: 'both', trKey: 'bothEndUser' }]),
      defaultValue: 'both',
      required: isEnabled,
      visible: isEnabled,
      extra: <SettingsFormFieldExtra copy={I18n.t('endUserLoginMethodExtra', trOpt)} />
    }, {
      id: 'samlDivider',
      type: 'custom',
      component: SamlDividerField,
      appDomain: settings?.appDomain?.[DEFAULT_LANGUAGE] ?? 'usecure.io',
      visible: isEnabled
    }, {
      id: 'samlEntryPoint',
      type: 'text',
      label: I18n.t('samlEntryPoint', trOpt),
      required: isEnabled,
      visible: isEnabled,
      validate: (field, value, errors) => {
        if (!isUrl(value)) {
          errors.push(I18n.t('common.invalidUrlError'))
        }
      },
      extra: <SettingsFormFieldExtra copy={I18n.t('samlEntryPointExtra', trOpt)} />
    }, {
      id: 'samlSigningCertificate',
      type: 'custom',
      component: SigningCertificateField,
      fingerprint: data?.getSSOSettings?.samlSigningCertificateFingerprint,
      loading,
      required: isEnabled,
      visible: isEnabled,
      disabled: !isUpdateAllowed
    }, {
      id: 'advancedOptions',
      type: 'collapse',
      visible: isEnabled,
      fields: [
        {
          id: 'disableRequestedAuthnContext',
          type: 'switch',
          label: I18n.t('disableRequestedAuthnContext', trOpt),
          defaultValue: false,
          extra: <SettingsFormFieldExtra copy={I18n.t('disableRequestedAuthnContextExtra', trOpt)} />
        }
      ]
    }]
    if (isMSP) {
      fields.push(
        {
          id: 'additionalSettingsDivider',
          type: 'custom',
          component: SectionDividerField,
          title: I18n.t('common.additionalSettings')
        }, {
          id: 'samlEndUserOptionForced',
          type: 'switch',
          label: I18n.t('samlEndUserOptionForced', trOpt),
          defaultValue: false,
          extra: <SettingsFormFieldExtra copy={I18n.t('samlEndUserOptionForcedExtra', trOpt)} />
        }
      )
    }

    return fields
  }, [accountType, data, loading, settings, isUpdateAllowed])

  return (
    <>
      <LoadingBlock fullScreen loading={loading} />
      <SettingsFormElement
        headerId='settings-sso-header'
        headerExtra={<ErrorAlerts error={error} defaultError={I18n.t('common.anErrorOccurred')} />}
        showForm={!loading && !error}
        title={I18n.t('title', trOpt)}
        desc={<p>{I18n.t('description', trOpt)}</p>}
        formProps={{
          mutation: UPDATE_COMPANY_SSO_SETTINGS,
          onSuccess,
          onChange,
          failureMessage: I18n.t('errorMessage', trOpt),
          submitLabel: I18n.t('common.save'),
          ref: form,
          fields: fields,
          skipResetFieldsOnSubmit: true,
          mutateValues
        }}
        disableSubmit={disableSubmit || !isUpdateAllowed}
        isUpdateAllowed={isUpdateAllowed}
        hasChanged={hasChanged()}
      />
      {error && <ErrorAlerts error={error} defaultError={I18n.t('common.anErrorOccurred')} />}
    </>
  )
})

export default withRefreshSessionState(SSO)
