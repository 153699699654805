import React, { useCallback } from 'react'
import I18n from 'i18n-js'

import DeleteConfirm from './DeleteConfirm'
import { DELETE_LEARNERS } from '../Queries/Learners'
import { invalidateLearnersQueryCache, mapStringArrayToLi } from '../../helpers'

const trOpt = { scope: 'modals.deleteUserConfirm' }

const DeleteLearnerConfirm = React.forwardRef(({
  refetchQueries, update = invalidateLearnersQueryCache,
  uLearnEnabled = true, uBreachEnabled = true, uPhishEnabled = true, uPolicyEnabled = true, enableRiskScore = true,
  onClose = () => {}
}, ref) => {
  const deletedItems = useCallback(count => {
    const deletedItems = Object.entries({
      uBreachEnabled, uLearnEnabled, uPhishEnabled, uPolicyEnabled, enableRiskScore
    }).reduce((acc, [id, value]) => {
      if (value === true) {
        acc.push(id)
      }
      return acc
    }, [])
    deletedItems.push('reporting')

    return mapStringArrayToLi(deletedItems.map(id => I18n.t(id, { scope: `${trOpt.scope}.deletedItems`, count })))
  }, [uLearnEnabled, uBreachEnabled, uPhishEnabled, uPolicyEnabled, enableRiskScore])

  return (
    <DeleteConfirm
      mutation={DELETE_LEARNERS}
      variableName='learnerIds'
      {...{ update, refetchQueries, trOpt, ref, deletedItems, onClose }}
    />
  )
})

export default DeleteLearnerConfirm
