import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { ShortLoader } from '../common/Loading'

const _StatusIndicator = ({ status, loading, errorMessage, trOpt }) => {
  if (status === 'failed' || errorMessage) {
    // See if the error message exists in the translations file, otherwise show as is
    const formattedErrorMessage = I18n.t(`errors.${errorMessage}`, { ...trOpt, defaultValue: errorMessage })

    return (
      <>
        <p className='base-font'>{I18n.t('failed', trOpt)}</p>
        {errorMessage && <small>{I18n.t('error', trOpt)} {formattedErrorMessage}</small>}
      </>
    )
  }
  if ((status === 'pending' || status === 'waiting') && !loading) {
    return (
      <>
        <p className='base-font'>{I18n.t('pending', trOpt)}</p>
        <ShortLoader />
      </>
    )
  }
  if (status === 'completed') return <p className='base-font'>{I18n.t('completed', trOpt)}</p>
  if (status === 'downloaded') return <p className='base-font'>{I18n.t('downloaded', trOpt)}</p>
  return <></>
}

const StatusIndicator = styled(_StatusIndicator)`
  display: flex;
  text-align: center;
`

export default StatusIndicator
