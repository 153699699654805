import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, InputNumber } from 'antd'

import MutationFormErrors from './MutationFormErrors'

class MutationFormInputNumber extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hasReceivedFocus: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  handleChange (value) {
    value = value === '' ? null : value
    this.props.onChange(this.props.id, value)
  };

  handleFocus () {
    this.setState({ hasReceivedFocus: true })
  }

  handleKeyDown (event) {
    if (event.keyCode === 13 && this.props.disableSubmitOnEnter) {
      event.preventDefault()
    }
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, label, required, placeholder, autofill, value, max, min, step, disabled, formItemStyle, errors = [], formatter, parser } = this.props
    const readOnly = !autofill ? !this.state.hasReceivedFocus : undefined
    const showErrors = errors.length > 0

    return (
      <Form.Item
        label={label} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <InputNumber
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          name={id}
          {...{
            value,
            required,
            placeholder,
            readOnly,
            min,
            max,
            step,
            disabled,
            formatter,
            parser
          }}
        />
      </Form.Item>
    )
  }
}

MutationFormInputNumber.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  autofill: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disableSubmitOnEnter: PropTypes.bool
}

MutationFormInputNumber.defaultProps = {
  id: '',
  label: null,
  required: false,
  placeholder: '',
  autofill: true,
  value: '',
  max: Infinity,
  min: -Infinity,
  onChange: () => {},
  disableSubmitOnEnter: false
}

export default MutationFormInputNumber
