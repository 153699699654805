import React from 'react'
import I18n from 'i18n-js'

import { DELETE_SIMULATION_EMAIL_TEMPLATES } from '../Queries/uPhish'
import DeleteConfirm from './DeleteConfirm'
import { mapStringArrayToLi } from '../../helpers/renderFromArray'

const trOpt = { scope: 'modals.deleteEmailTemplateConfirm' }

const DeleteEmailTemplateConfirm = ({ visible = false, setVisible = () => {}, ids = [], refetchQueries }) => {
  return (
    <DeleteConfirm
      {...{ ids, visible, setVisible, refetchQueries, trOpt }}
      mutation={DELETE_SIMULATION_EMAIL_TEMPLATES}
      variableName='ids'
      deletedItems={mapStringArrayToLi([
        I18n.t('allSimulationsDeletedItem', { ...trOpt, count: ids.length }),
        I18n.t('uPhish.common.simulationRecipientsDeletedItem'),
        I18n.t('uPhish.common.simulationResultsDeletedItem'),
        I18n.t('anyActiveSimulationDeleteItem', { ...trOpt, count: ids.length })
      ])}
    />
  )
}

export default DeleteEmailTemplateConfirm
