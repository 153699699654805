import React, { useCallback, useMemo } from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'
import I18n from 'i18n-js'

const AddDropdown = ({ actions = [], performAction = () => { }, disabled = false }) => {
  const handleMenuClick = useCallback(e => performAction(e.key), [performAction])
  const menu = useMemo(() => (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item key={action.key}>{action.label}</Menu.Item>
        ))
      }
    </Menu>
  ), [actions, handleMenuClick])

  return (
    <Dropdown disabled={disabled} overlay={menu} trigger={['hover']}>
      <Button type='primary'>
        <Icon type='plus' /> {I18n.t('common.add')}
      </Button>
    </Dropdown>
  )
}

export default AddDropdown
