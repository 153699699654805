import React, { useCallback, useRef, useEffect, useState, useImperativeHandle } from 'react'
import { Button, Card, Descriptions } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _isEmpty from 'lodash/isEmpty'

import { TileCardImage as _TileCardImage } from './TemplateTile'
import { TemplateTiles } from './TemplateTiles'
import { ListHeader, ListHeaderPanel, LocalesCell } from '../common'
import { clearLandingPageContentCache, generateLandingPageLink } from '../../helpers/uPhish'
import { CATEGORY_NAMES } from '../../constants/uPhish'
import { LIST_LOCALES_CELL_LIMIT } from '../../constants/list'

const trOpt = { scope: 'uPhish.viewLandingPageDetails' }

const DetailContainer = styled.div`
  padding-bottom: 10px;

  .ant-descriptions-item {
    vertical-align: top;

    &> span {
      display: block;
    }
  }

  .hide-label-item {
    &> .ant-descriptions-item-colon::after {
      content: none;
    }
  }
`

const DetailDescriptions = styled(Descriptions)`
  .ant-descriptions-row:nth-child(odd) > td {
    padding-bottom: 0;
  }
`

const PageIframe = styled.iframe`
  border: none;
  height: 800px;
  width: 100%;
`

const TileCardImage = styled(_TileCardImage)`
  width: 300px;
`

const ViewLandingPageDetails = React.forwardRef(({ template, companyId, builder }, ref) => {
  const { id: templateId, pageTitle, tile, category, emailTemplates, locales, supportedLocales } = template
  const [link, setLink] = useState(null)
  const iframe = useRef(null)

  useEffect(() => {
    setLink(templateId && companyId ? generateLandingPageLink({ landingPageId: templateId, companyId }) : null)
  }, [templateId, companyId])

  const onLinkClick = useCallback(() => {
    if (link) {
      window.open(link, '_blank')
    }
  }, [link])
  const onReloadClick = useCallback(() => {
    if (iframe.current) {
      iframe.current.src += ''
    }
  }, [iframe])
  const onCacheClick = useCallback(async () => {
    await clearLandingPageContentCache(templateId)
    onReloadClick()
  }, [templateId, onReloadClick])
  useImperativeHandle(ref, () => ({
    reloadContent: onReloadClick
  }), [onReloadClick])

  // This reducer render the detail items
  // It is in place to compensate Ant Design's Descriptions component's poor span handling, it doesn't wrap as expected when a row is full
  // The alternative would be to create our own
  let rowSpan = 0
  const items = [{
    id: 'pageTitle',
    span: 1,
    allowed: pageTitle
  }, {
    id: 'category',
    span: 1,
    allowed: true
  }, {
    id: 'locales',
    span: 1,
    allowed: true
  }, {
    id: 'tile',
    span: 2,
    allowed: builder && tile
  }, {
    id: 'content',
    span: 3,
    allowed: link
  }, {
    id: 'emailTemplates',
    span: 3,
    allowed: !_isEmpty(emailTemplates)
  }].filter(item => item.allowed)
    .reduce((acc, item, index, items) => {
      const { id, span } = item
      let itemSpan = span
      const nextItem = items[index + 1]
      if (index === items.length - 1 || (nextItem && (nextItem.span + rowSpan + span) > 3)) {
        itemSpan = 3 - rowSpan
      }
      rowSpan += itemSpan
      rowSpan = rowSpan >= 3 ? 0 : rowSpan

      const itemProps = { key: `${id}-${index}`, span: itemSpan }
      switch (id) {
        case 'pageTitle':
          acc.push(<Descriptions.Item {...itemProps} label={I18n.t('uPhish.common.pageTitle')}>{pageTitle}</Descriptions.Item>)
          break
        case 'category':
          acc.push(<Descriptions.Item {...itemProps} label={I18n.t('common.category')}>{CATEGORY_NAMES[category] || CATEGORY_NAMES.none}</Descriptions.Item>)
          break
        case 'locales':
          acc.push(
            <Descriptions.Item {...itemProps} label={I18n.t('common.languagesParenPlural')}>
              <LocalesCell itemName={template.name} limit={LIST_LOCALES_CELL_LIMIT} locales={builder ? locales : supportedLocales} />
            </Descriptions.Item>
          )
          break
        case 'tile':
          acc.push(
            <Descriptions.Item {...itemProps} label={I18n.t('common.templateTile')}>
              <TileCardImage src={tile} />
            </Descriptions.Item>
          )
          break
        case 'content':
          acc.push(
            <Descriptions.Item {...itemProps} className='hide-label-item'>
              <ListHeader>
                <ListHeaderPanel>
                  <h3>{I18n.t('content', trOpt)}</h3>
                </ListHeaderPanel>
                <ListHeaderPanel align='right'>
                  <Button icon='reload' onClick={onReloadClick}>{I18n.t('common.reload')}</Button>
                  <Button icon='database' onClick={onCacheClick}>{I18n.t('clearCache', trOpt)}</Button>
                  <Button icon='global' onClick={onLinkClick}>{I18n.t('openInBrowser', trOpt)}</Button>
                </ListHeaderPanel>
              </ListHeader>
              <Card>
                <PageIframe ref={iframe} src={`${link}&skipCache=true`} />
              </Card>
            </Descriptions.Item>
          )
          break
        case 'emailTemplates':
          acc.push(
            <Descriptions.Item {...itemProps} className='hide-label-item'>
              <Card title={I18n.t('uPhish.common.recommendedEmailTemplates')}>
                <TemplateTiles templates={emailTemplates} showLocalesIcon />
              </Card>
            </Descriptions.Item>
          )
          break
        default:
          break
      }

      return acc
    }, [])

  return (
    <DetailContainer>
      <DetailDescriptions column={3} layout='vertical'>
        {items}
      </DetailDescriptions>
    </DetailContainer>
  )
})

export default ViewLandingPageDetails
