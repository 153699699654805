import React from 'react'

import UpdateLanguagesModal from './UpdateLanguagesModal'
import { UPDATE_POLICY_TEMPLATE_LANGUAGES } from '../Queries/uPolicy'

const trScope = 'modals.updatePolicyTemplateLanguagesModal'

const UpdatePolicyTemplateLanguagesModal = React.forwardRef(({ refetchQueries }, ref) =>
  <UpdateLanguagesModal
    mutation={UPDATE_POLICY_TEMPLATE_LANGUAGES}
    addTitleKey={`${trScope}.addTitle`}
    removeTitleKey={`${trScope}.removeTitle`}
    {...{ ref, refetchQueries }}
  />
)

export default UpdatePolicyTemplateLanguagesModal
