import I18n from 'i18n-js'

const trOpt = { scope: 'common.accountTypes' }
export const ACCOUNT_TYPES = {
  get distributor () { return I18n.t('distributor', trOpt) },
  get msp () { return I18n.t('msp', trOpt) },
  get tenant () { return I18n.t('tenant', trOpt) },
  get prospect () { return I18n.t('prospect', trOpt) }
}
export const ACCOUNT_TYPE_IDS = Object.keys(ACCOUNT_TYPES)

export const RISK_REPORT_STATUS_IDS = [
  'new',
  'domain_started',
  'domain_complete',
  'ubreach_requested',
  'ubreach_started',
  'ubreach_complete',
  'uphish_requested',
  'uphish_ready',
  'uphish_started',
  'uphish_finished',
  'uphish_complete',
  'trial_requested',
  'finished'
]

export const NEXT_RISK_REPORT_STATUS = RISK_REPORT_STATUS_IDS.reduce((acc, id, index, array) => ({
  ...acc,
  [id]: array[index + 1]
}), {})

export const RISK_REPORT_STATUS_UPHISH = [
  'uphish_requested',
  'uphish_ready',
  'uphish_started',
  'uphish_finished'
]

export const RISK_REPORT_PROGRESS = {
  domain_started: 10,
  domain_complete: 25,
  ubreach_requested: 30,
  ubreach_started: 40,
  ubreach_complete: 50,
  uphish_requested: 55,
  uphish_ready: 60,
  uphish_started: 65,
  uphish_finished: 75,
  uphish_complete: 85,
  trial_requested: 90,
  finished: 100
}

export const RISK_REPORT_STEPS = 4
export const RISK_REPORT_STEP = {
  domain_started: 1,
  domain_complete: 1,
  ubreach_requested: 2,
  ubreach_started: 2,
  ubreach_complete: 2,
  uphish_requested: 3,
  uphish_ready: 3,
  uphish_started: 3,
  uphish_finished: 3,
  uphish_complete: 3,
  trial_requested: 4,
  finished: 4
}
export const RISK_REPORT_STEP_END = {
  1: 'domain_complete',
  2: 'ubreach_complete',
  3: 'uphish_complete',
  4: 'finished'
}

export const MSP_BILLING_TYPE_KEYS = {
  auto: 'uService.billingType.auto',
  exempt: 'uService.billingType.exempt',
  none: 'common.none'
}
