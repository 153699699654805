import { darken, getContrast, lighten } from 'polished'
import _upperFirst from 'lodash/upperFirst'

export const MIN_CONTRAST_RATIO_ON_WHITE = 3 // This is the W3 recommended contrast ratio of 3:1

// Sets a highlight colour based a minimum contrast ratio to avoid invisible element
const getHighlightColor = ({ amount, baseColor, bgColor = '#ffffff', minContrastRatio = MIN_CONTRAST_RATIO_ON_WHITE, darkenOnContrastFail = false } = {}) => {
  const lighterBaseColor = lighten(amount, baseColor)
  const lighterOnBgContrast = getContrast(lighterBaseColor, bgColor)
  if (lighterOnBgContrast < minContrastRatio) {
    return darkenOnContrastFail ? darken(amount, baseColor) : baseColor
  }
  return lighterBaseColor
}

const THEME_SETTINGS = {
  appThemeNavColor: 'nav',
  appThemePrimaryColor: 'primary',
  appThemeSecondaryColor: 'secondary'
}

/** Work out the report document settings based on the current settings and the default theme */
const getReportDocumentSettings = (settings = {}, sourceTheme) => {
  const {
    reportDocumentHeaderBackgroundColor: backgroundColor,
    reportDocumentHeaderFontColor: fontColor,
    reportDocumentUseAppThemeLogo,
    reportDocumentLogo: logo,
    appThemeLogo,
    parentDefaultSettings: defaultSettings = {}
  } = settings

  const {
    reportDocumentHeaderBackgroundColor: defaultBackgroundColor = sourceTheme.reportDocumentHeaderBackgroundColor,
    reportDocumentHeaderFontColor: defaultFontColor = sourceTheme.reportDocumentHeaderFontColor,
    reportDocumentUseAppThemeLogo: defaultUseAppThemeLogo = true,
    reportDocumentLogo: defaultLogo = sourceTheme.reportDocumentLogo,
    appThemeLogo: defaultAppThemeLogo = sourceTheme.appThemeLogo
  } = defaultSettings || {}

  const useAppThemeLogo = reportDocumentUseAppThemeLogo === undefined ? defaultUseAppThemeLogo : reportDocumentUseAppThemeLogo
  const documentSettings = {
    reportDocumentHeaderBackgroundColor: backgroundColor || defaultBackgroundColor,
    defaultReportDocumentHeaderBackgroundColor: sourceTheme.reportDocumentHeaderBackgroundColor,
    reportDocumentHeaderFontColor: fontColor || defaultFontColor,
    defaultReportDocumentHeaderFontColor: sourceTheme.reportDocumentHeaderFontColor,
    reportDocumentLogo: useAppThemeLogo === false ? (logo || defaultLogo) : (appThemeLogo || defaultAppThemeLogo),
    defaultReportDocumentLogo: sourceTheme.reportDocumentLogo
  }

  return documentSettings
}

const buildTheme = (sourceTheme, settings = {}) => {
  const themeSettings = [
    'appThemeLogo',
    'appThemeNavColor',
    'appThemePrimaryColor',
    'appThemeSecondaryColor'
  ].reduce((obj, id) => {
    const value = settings[id]
    const themeValueId = THEME_SETTINGS[id] || id
    if (value) {
      obj[themeValueId] = value
    }
    obj[`default${_upperFirst(themeValueId)}`] = sourceTheme[themeValueId]
    return obj
  }, {})

  const theme = {
    ...sourceTheme,
    ...{
      ...themeSettings,
      ...getReportDocumentSettings(settings, sourceTheme)
    }
  }

  theme.primaryHighlight = getHighlightColor({ amount: 0.2, baseColor: theme.primary })
  theme.primaryButtonHighlight = getHighlightColor({ amount: 0.25, baseColor: theme.primary, minContrastRatio: 1.8, bgColor: theme.primary })
  theme.primaryGhostHighlight = getHighlightColor({ amount: 0.2, baseColor: theme.primary, darkenOnContrastFail: true })

  // Video Player Theming
  // - At the moment there are no video player theme settings
  // - The logic below sets the video player highlight color to the main theme's primary color
  // - This code will need to change if we introduce video player theme settings
  theme.videoPlayerControlHighlightColor = theme.primary

  return theme
}

export default buildTheme
