import I18n from 'i18n-js'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'
import _isString from 'lodash/isString'
import _set from 'lodash/set'

import { DEFAULT_LANGUAGE, LANGUAGE_CODES, BCP_47_REGEX, LANGUAGE_SELECT_OPTIONS, BASE_LANGUAGE_CODES, LANGUAGE_CODES_BASE_TO_REGION_MAP } from '../constants/languages'
import { PRODUCT_NAMES } from '../constants/productNames'
import _ from 'lodash'

// Returns the corresponding moment locale code to our language codes
export const getMomentLocale = (locale = DEFAULT_LANGUAGE) => {
  switch (locale) {
    case 'en':
    case 'debugKeys':
      return 'en-gb'
    case 'zh':
      return 'zh-cn'
    case 'fr-CA':
      return 'fr-ca'
    case 'en-US':
      return 'en'
    default:
      return locale.toLowerCase()
  }
}

export const updateLocaleLibraries = locale => {
  // Update i18n-js locale
  I18n.locale = locale

  // Update moment locale
  moment.locale([getMomentLocale(locale), 'en-gb'])
}

export const setProductNames = (names = {}) => {
  const { uLearn, uPhish, uBreach, uBreachPro, uPolicy, uService } = { ...PRODUCT_NAMES, ...(names || {}) }
  // Product names without translation support
  I18n.translations.en.common.uLearn = uLearn
  I18n.translations.en.common.uPhish = uPhish
  I18n.translations.en.common.uBreach = uBreach
  I18n.translations.en.common.uPolicy = uPolicy
  I18n.translations.en.common.uService = uService
  // Product names with translation support
  // Product name customisation is language agnostic so the settings value is used for all languages
  // These names should not be added to the PRODUCT_NAMES constant as their translations as part of I18n
  LANGUAGE_CODES.forEach(locale => {
    _set(I18n.translations, `${locale}.common.uBreachPro`, uBreachPro || I18n.t('common.uBreachProDefaultName', { locale }))
  })
}

export const createLocaleGetterObjectFromArray = (keyArray, trOpt) =>
  createLocaleGetterObject(
    keyArray.reduce((acc, id) => {
      acc[id] = id
      return acc
    }, {}),
    trOpt
  )

export const createLocaleGetterObject = (keyObject, trOpt) => {
  const { trFn = 't', ...opt } = trOpt ?? {}
  return Object.entries(keyObject)
    .reduce((acc, [id, key]) => {
      Object.defineProperty(acc, id, {
        get: () => I18n[trFn](key, opt)
      })
      return acc
    }, {})
}

export const createLocaleGetterOptions = textObject =>
  Object.getOwnPropertyNames(textObject)
    .map(id => ({
      value: id,
      get label () {
        return textObject[id]
      }
    }))

export const getSortedLocaleOptions = (locale, keyObject, trOpt) =>
  Object.keys(keyObject)
    .map((id) => ({
      value: id,
      label: I18n.t(keyObject[id], { ...(trOpt || {}), locale })
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

// Maps the browser locale to one of our supported languages
export const getBrowserLocale = (defaultValue = DEFAULT_LANGUAGE) => {
  let browserLocale = window.navigator.userLanguage || window.navigator.language
  // Check if language is directly supported
  if (LANGUAGE_CODES.includes(browserLocale)) {
    return browserLocale
  }
  // Convert BCP 47 code to ISO 639 2 character code
  if (BCP_47_REGEX.test(browserLocale)) {
    browserLocale = browserLocale.substring(0, 2)
    if (LANGUAGE_CODES.includes(browserLocale)) {
      return browserLocale
    }
  }
  // Use default value
  return defaultValue
}

export const isLocaleFull = (actualLocale, baseLocale, acceptedLocales) => {
  return (_.isArray(acceptedLocales) ? acceptedLocales.includes(actualLocale) : baseLocale === actualLocale) ||
    (BCP_47_REGEX.test(actualLocale) && actualLocale.substring(0, 2) === baseLocale)
}
export const isLocale = (actualLocale, baseLocale) => isLocaleFull(actualLocale, baseLocale)
export const isLocaleEnglish = actualLocale => isLocaleFull(actualLocale, 'en')
export const createLocaleCopyObjectFromKeys = key => LANGUAGE_CODES.reduce((acc, locale) => ({ ...acc, [locale]: I18n.t(key, { locale }) }), {})
export const createLocaleCopyObjectFromText = text => LANGUAGE_CODES.reduce((acc, locale) => ({ ...acc, [locale]: text }), {})

export const getTranslationWithFallback = (key, scope, fallbackScope, options = {}) =>
  I18n.t(key, { ...options, scope, defaults: { message: I18n.t(key, { ...options, scope: fallbackScope }) } })

const addCopyDeltaValue = (acc, values, defaultValues, locale, { baseLocaleValue } = {}) => {
  const value = values[locale]
  if (_isString(value) && !_isEmpty(value)) {
    const defaultValue = defaultValues[locale]
    if (!(value === defaultValue || (baseLocaleValue && value === baseLocaleValue))) {
      acc[locale] = value
    }
  }
}

export const getLocaleCopyDeltaObject = (values, defaultValues) => {
  return BASE_LANGUAGE_CODES.reduce((acc, baseLocale) => {
    addCopyDeltaValue(acc, values, defaultValues, baseLocale);
    (LANGUAGE_CODES_BASE_TO_REGION_MAP[baseLocale] ?? []).forEach(regionalLocale =>
      addCopyDeltaValue(acc, values, defaultValues, regionalLocale, { baseLocaleValue: values[baseLocale] })
    )
    return acc
  }, {})
}

export const injectRegionalValuesIntoLocaleObject = values => {
  return BASE_LANGUAGE_CODES.reduce((acc, baseLocale) => {
    const baseValue = values[baseLocale]
    if (baseValue) {
      acc[baseLocale] = baseValue
    }
    (LANGUAGE_CODES_BASE_TO_REGION_MAP[baseLocale] ?? []).forEach(regionalLocale => {
      const regionalValue = values[regionalLocale]
      if (regionalValue) {
        acc[regionalLocale] = regionalValue
      } else if (baseValue) {
        acc[regionalLocale] = baseValue
      }
    })
    return acc
  }, {})
}

export const getSortedLanguageSelectOptions = () =>
  [...LANGUAGE_SELECT_OPTIONS].sort((a, b) => a.label.localeCompare(b.label))

export const getSortedLanguageColumnFilterOptions = () =>
  getSortedLanguageSelectOptions().map(({ value, label: text }) => ({ value, text }))
