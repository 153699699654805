import React, { useCallback, useEffect } from 'react'
import { Button, Dropdown, Icon, Menu } from 'antd'
import styled from 'styled-components'
import _isArray from 'lodash/isArray'

import connect from '../../hocs/connect'
import { creators as sessionActions } from '../../state/actions/session'
import { sessionLocale, sessionInitialised } from '../../state/selectors/session'
import DemoMode from '../../helpers/DemoMode'
import { LANGUAGE_CODES, LANGUAGE_NAMES_BY_CODE } from '../../constants/languages'

const LanguageMenu = styled(Menu)`
  height: 100vh - 100px;
  max-height: 400px;
  overflow-y: auto;
`

const LanguageMenuItem = ({ code, name, onClick = () => {} }) => {
  const onItemClick = useCallback(() => onClick(code), [code, onClick])

  return (
    <div onClick={onItemClick}>{name}</div>
  )
}

const _LanguageDropdown = ({ className, value, languageCodes = LANGUAGE_CODES, onChange, placement = 'bottomCenter', trigger = 'click', disabled = false }) => {
  const onLanguageChange = useCallback(code => onChange(code), [onChange])

  return (
    <Dropdown
      {...{ className, placement, disabled }}
      trigger={_isArray(trigger) ? trigger : [trigger]}
      overlay={
        <LanguageMenu selectedKeys={[value]}>
          {
            [...languageCodes]
              .sort((a, b) => (LANGUAGE_NAMES_BY_CODE[a] ?? a).localeCompare(LANGUAGE_NAMES_BY_CODE[b] ?? b))
              .map(code => (
                <Menu.Item key={code}>
                  <LanguageMenuItem code={code} name={LANGUAGE_NAMES_BY_CODE[code] ?? code} onClick={onLanguageChange} />
                </Menu.Item>
              ))
          }
        </LanguageMenu>
      }
    >
      <Button>
        <div className='usecure-language-dropdown-value'>
          <LanguageMenuItem code={value} name={LANGUAGE_NAMES_BY_CODE[value] ?? value} />
        </div>
        <Icon type='down' />
      </Button>
    </Dropdown>
  )
}
const LanguageDropdown = styled(_LanguageDropdown)`
  .ant-button {
    padding-right: 10px;
  }
  .usecure-language-dropdown-value {
    display: inline-block;
    margin-right: 5px;
  }
`

export const DemoLocaleListener = connect(
  state => ({ locale: sessionLocale(state), initialised: sessionInitialised(state) }),
  dispatch => ({
    setLocale: locale => dispatch(sessionActions.updateLocale(locale))
  })
)(({ locale, setLocale, initialised }) => {
  useEffect(() => {
    DemoMode.setLocale(locale)
  }, [locale])
  useEffect(() => {
    if (!initialised) return
    DemoMode.removeLocaleListener('demoLocaleListener')
    DemoMode.addLocaleListener('demoLocaleListener', function (locale) {
      setLocale(locale)
    })

    return function () {
      DemoMode.removeLocaleListener('demoLocaleListener')
    }
  }, [setLocale, initialised])

  return null
})

export default LanguageDropdown
