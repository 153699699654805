import React, { useCallback, useMemo, useState } from 'react'
import { Row, Col, Button, Empty, Tag, Table } from 'antd'
import _uniqBy from 'lodash/uniqBy'
import _pick from 'lodash/pick'
import routes from '../constants/routes'
import { compose } from 'recompose'
import styled, { css, withTheme } from 'styled-components'
import { Query } from 'react-apollo'
import I18n from 'i18n-js'
import moment from 'moment'

import { ContentWrap, LoadingBlock, ListHeader, ErrorAlerts } from '../components/common'
import { GET_UBREACH_DATA } from '../components/Queries/uBreach'
import { GET_LEARNERS } from '../components/Queries/Learners'
import downloadBreachesReport from '../helpers/downloadBreachesReport'
import UBreachHeader from './Reports/uBreach/UBreachHeader'
import DashboardStatistic from '../components/Dashboard/DashboardStatistic'
import { LIST_PAGINATION_PROPS } from '../constants/list'
import { formatDate } from '../helpers/datetime'
import { connect } from '../hocs'
import selectors from '../state/selectors'

const trOpt = { scope: 'uBreach' }

const UBreachReportGrid = styled.div`
    display: grid;
    grid-template-areas: 
            "topbar topbar topbar topbar" 
            "total-breaches  services-appeared-in total-users-breached breached"
            "table table table table";
        
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 260px auto;
    grid-gap: 25px;`

const UBreachItem = styled.div`
background-color: white;
padding: 30px 40px;
`

const _PieContainer = ({ empty, ...props }) => <Col {...props} />
export const PieContainer = styled(_PieContainer)`
  ${({ empty }) => empty ? '' : css`height: 400px;`}
  margin-bottom: 30px;
  margin-top: 30px;
  
  h1 {
    text-align: center;
  }
`

export const BreachEmpty = styled(Empty)`
  .ant-empty-image {
    height: 75px;
    margin-bottom: 0;
  }
`

const DataClassTag = styled(Tag)`
  margin: 4px 4px 4px 0;
`

const BreachTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
`

const UBreach = ({ theme, excludeInactiveUsersInReports }) => {
  const [reportLoading, setReportLoading] = useState(false)
  const [dataError, setDataError] = useState(null)
  const [learnersError, setLearnersError] = useState(null)

  const onDownloadClick = useCallback(async () => {
    setReportLoading(true)
    await downloadBreachesReport()
    setReportLoading(false)
  }, [])

  const learnerVariables = useMemo(() => {
    const variables = { withBreaches: true }
    if (excludeInactiveUsersInReports) variables.inactive = false
    return variables
  }, [excludeInactiveUsersInReports])

  return (
    <ContentWrap>
      <Query query={GET_UBREACH_DATA} variables={{ excludeInactiveUsers: excludeInactiveUsersInReports }} onError={setDataError}>
        {({ loading, error, data }) => {
          if (loading) return <LoadingBlock fullScreen loading={loading} />
          const { uBreachData: { learnerCount = 0 } = {} } = data || {}

          if (!(dataError || learnersError) && learnerCount <= 0) {
            return (
              <Empty
                style={{ margin: '40px 0' }}
                image='images/icons/Ubreach.png'
                description={<span>{I18n.t('common.noUsersMessage')}</span>}
              >
                <Button type='primary' href={routes.LEARNERS}>{I18n.t('common.addUsers')}</Button>
              </Empty>
            )
          }

          return (
            <Query query={GET_LEARNERS} variables={learnerVariables} onError={setLearnersError}>
              {({ loading, error, data }) => {
                const { learners = [] } = data || {}

                if (loading) return <LoadingBlock loading={loading} />

                let totalBreaches = 0
                let totalUsers = 0
                const allBreaches = []
                let latestBreachDate
                let latestBreachedService
                const tableData = learners.map(({ email, name, breaches = [] }) => {
                  totalBreaches += breaches.length
                  totalUsers++
                  allBreaches.push(...breaches)
                  breaches.forEach(breach => {
                    if (!latestBreachDate) {
                      latestBreachDate = breach.breachDate
                      latestBreachedService = breach.name
                    } else if (moment(breach.breachDate).isAfter(latestBreachDate)) {
                      latestBreachDate = breach.breachDate
                      latestBreachedService = breach.name
                    }
                  })
                  return {
                    key: email, name, email, breaches, breachCount: breaches.length
                  }
                }).filter(l => l.breachCount > 0)

                const uniqueBreaches = _uniqBy(allBreaches, 'name')

                const columns = [
                  { title: I18n.t('common.fields.name'), dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
                  { title: I18n.t('common.fields.email'), dataIndex: 'email', key: 'email' },
                  {
                    title: I18n.t('breachCount', trOpt),
                    dataIndex: 'breachCount',
                    key: 'breachCount',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.breachCount - b.breachCount
                  }
                ]

                const nestedColumns = [
                  { title: I18n.t('service', trOpt), dataIndex: 'name', key: 'name', width: '15%' },
                  { title: I18n.t('breachDate', trOpt), dataIndex: 'breachDate', key: 'breachDate', width: '15%', render: (value) => formatDate(value) },
                  { title: I18n.t('addedOn', trOpt), dataIndex: 'addedDate', key: 'addedDate', width: '15%', render: (value) => formatDate(value) },
                  {
                    title: I18n.t('exposedInformation', trOpt),
                    dataIndex: 'dataClasses',
                    width: '55%',
                    key: 'dataClasses',
                    render: (record) => {
                      return record.map((data, index) => {
                        return (<DataClassTag key={index} color={theme.primary}>{data}</DataClassTag>)
                      })
                    }
                  }
                ]

                const expandedRowRender = (record) => {
                  const breaches = record.breaches.map(breach => {
                    return {
                      key: record.email + breach.name,
                      name: breach.name,
                      breachDate: breach.breachDate,
                      addedDate: breach.addedDate,
                      dataClasses: breach.dataClasses
                    }
                  })

                  return <BreachTable columns={nestedColumns} dataSource={breaches} pagination={false} />
                }

                const breachCount = tableData.length

                const sectionData = [
                  {
                    title: I18n.t('totalBreaches', trOpt),
                    gridArea: 'total-breaches',
                    link: '/uPhish/simulations',
                    value: totalBreaches,
                    extraTitle: I18n.t('servicesAppearedIn', trOpt),
                    extraData: uniqueBreaches.length,
                    hoverText: I18n.t('aTotalOfBreaches', { count: totalBreaches, services: I18n.t('services', { count: uniqueBreaches.length, ...trOpt }), ...trOpt }),
                    isValue: true
                  },
                  {
                    title: I18n.t('totalUsers', trOpt),
                    gridArea: 'total-users-breached',
                    link: '/uPhish/simulations',
                    value: totalUsers,
                    extraTitle: I18n.t('usersNotBreached', trOpt),
                    extraData: totalUsers - breachCount,
                    hoverText: I18n.t('aTotalOfUsers', { count: breachCount, usersBreached: I18n.t('usersBreached', { count: totalUsers, ...trOpt }), ...trOpt }),
                    isValue: true
                  },
                  {
                    title: I18n.t('latestBreach', trOpt),
                    gridArea: 'services-appeared-in',
                    link: '/uPhish/simulations',
                    value: (
                      <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                        {latestBreachDate ? formatDate(latestBreachDate) : I18n.t('noBreachesFound', trOpt)}
                      </p>
                    ),
                    extraTitle: I18n.t('latestBreachedService', trOpt),
                    extraData: latestBreachedService || I18n.t('common.na'),
                    hoverText: latestBreachDate ? I18n.t('yourMostRecentBreach', { lastBreachDate: moment(latestBreachDate).fromNow(), ...trOpt }) : null,
                    isValue: true
                  },
                  {
                    title: I18n.t('breached', trOpt),
                    gridArea: 'breached',
                    link: '/uPhish/simulations',
                    percentage: Math.round(breachCount * 100 / totalUsers),
                    isGauge: true,
                    extraTitle: I18n.t('totalUsersBreached', trOpt),
                    extraData: breachCount,
                    hoverText: I18n.t('aTotalOfUsers', { count: breachCount, usersBreached: I18n.t('usersBreached', { count: totalUsers, ...trOpt }), ...trOpt })
                  }

                ]

                return (
                  <UBreachReportGrid>
                    <UBreachHeader />
                    {sectionData.map((item, index) => {
                      return (
                        <UBreachItem key={index} style={{ gridArea: `${item.gridArea}` }}>
                          <DashboardStatistic isValue={item.isValue} hoverText={item.hoverText} title={item.title} link={item.link} value={item.value} percentage={item.percentage} percentageChange={item.percentageChange} percentageDescription={item.percentageDescription} extraTitle={item.extraTitle} extraData={item.extraData} isGauge={item.isGauge} />
                        </UBreachItem>
                      )
                    })}
                    <UBreachItem style={{ gridArea: 'table' }}>
                      <Row>
                        <Col span={24}>
                          <ErrorAlerts error={learnersError} defaultError={I18n.t('yourBreachedUsersCouldNotBeLoaded', trOpt)} />
                          <ListHeader align='right'>
                            <Button type='primary' onClick={onDownloadClick} loading={reportLoading} icon='download'>{I18n.t('downloadUserBreachesReport', trOpt)}</Button>
                          </ListHeader>
                          <Table
                            columns={columns} expandedRowRender={expandedRowRender} dataSource={tableData} pagination={LIST_PAGINATION_PROPS}
                            locale={{
                              emptyText: <BreachEmpty image='images/icons/Ubreach.png' description={I18n.t('noBreachesFound', trOpt)} />
                            }}
                          />
                        </Col>
                      </Row>
                    </UBreachItem>
                  </UBreachReportGrid>
                )
              }}
            </Query>
          )
        }}
      </Query>
    </ContentWrap>
  )
}

export default compose(
  connect(
    state => _pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
  ),
  withTheme
)(UBreach)
