import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _get from 'lodash/get'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { ContentBox, CardTitle } from './common'
import LearnerCourseSlate from './LearnerCourseSlate'
import { ProgressButton } from '../common'
import { withTimer } from '../../hocs'
import { BREAKPOINT } from '../../constants/style'
import { LearnerCourseBase } from './LearnerCourseBase'

const CarouselContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column;
    width: 100%;
    >div {
      width: 100%;
    }
  }
  @media (min-width: ${BREAKPOINT}) {
    flex-direction: column;
    width: 75%;
    >div {
      width: 75%;
      margin: 0 auto;
    }
  }
  
  .carousel .control-next.control-arrow:before { 
    border-left: 8px solid ${({ theme: { primary } }) => primary};
  }
  
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid ${({ theme: { primary } }) => primary};
  }
  
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 2px solid ${({ theme: { primary } }) => primary};
  }
  .carousel .slide {
    background-color: transparent;
  }
  .carousel .control-dots .dot {
    background-color: ${({ theme: { primary } }) => primary};
  }

`

const CarouselSlide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export class LearnerCourseCarousel extends LearnerCourseBase {
  constructor (props) {
    super(props)

    this.completedTimer = null
    this.canProgress = this.canProgress.bind(this)
  }

  canProgress () {
    return !this.props.timerActive
  }

  get slides () {
    return _get(this.props, 'slide.content.slides', []).filter(slide => slide.image)
  }

  componentDidMount () {
    this.startCompletionTimer()
  }

  componentDidUpdate (prevProps) {
    const { slide: { id } = {}, resetTimer } = this.props
    const { slide: { id: prevId } = {} } = prevProps

    if (id !== prevId) {
      this.startCompletionTimer()
    }

    // Reset timer
    if (id && prevId && id !== prevId) {
      resetTimer()
    }
  }

  componentWillUnmount () {
    this.props.resetTimer()
    clearTimeout(this.completedTimer)
  }

  render () {
    const { slide, canGoPrevSlide, goToNextSlide, goToPrevSlide, waitMS, timerActive } = this.props
    const { id, title, content } = slide
    const { intro } = content || {}

    return (
      <ContentBox
        material innerKey={id}
        buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
        buttonsRight={<ProgressButton {...{ timerActive, waitMS }} onClick={goToNextSlide} />}
      >
        <CardTitle>{title}</CardTitle>
        <LearnerCourseSlate content={intro} />
        <CarouselContainer>
          <Carousel showStatus={false} swipeable emulateTouch>
            {this.slides.map(({ image }, index) => (
              <CarouselSlide key={index}>
                <img src={image} alt={`${title} - Slide #${index + 1}}`} />
              </CarouselSlide>
            ))}
          </Carousel>
        </CarouselContainer>
      </ContentBox>
    )
  }
}

LearnerCourseCarousel.propTypes = {
  slide: PropTypes.object,
  goToNextSlide: PropTypes.func,
  resetTimer: PropTypes.func,
  timerActive: PropTypes.bool,
  update: PropTypes.func,
  waitMS: PropTypes.number
}

LearnerCourseCarousel.defaultProps = {
  slide: {},
  goToNextSlide: () => {},
  resetTimer: () => {},
  timerActive: false,
  update: () => {},
  waitMS: -1
}

export default withTimer(LearnerCourseCarousel)
