import { permissions } from './permissions'

// JG: these permissions should not be assigned to any user role
const EXCLUDED_PERMISSIONS = [
  permissions.END_USER_ACCESS_FULL,
  permissions.END_USER_ACCESS_LIMITED,
  permissions.REPORT_ACCESS
]

// JG: Almost all read-only/admin roles have this set of permissions
// They are required to view learners & groups, and create/view performance/scheduled reports
const COMMON_READ_ONLY_PERMISSIONS = [
  permissions.LEARNER_READ,
  permissions.LEARNER_LIST,
  permissions.GROUP_READ,
  permissions.GROUP_LIST,
  permissions.PERFORMANCE_REPORT_READ,
  permissions.PERFORMANCE_REPORT_LIST,
  permissions.SCHEDULED_REPORT_READ,
  permissions.SCHEDULED_REPORT_LIST
]

const COMMON_ADMIN_PERMISSIONS = [
  permissions.PERFORMANCE_REPORT_CREATE,
  permissions.PERFORMANCE_REPORT_UPDATE,
  permissions.PERFORMANCE_REPORT_DELETE,
  permissions.SCHEDULED_REPORT_CREATE,
  permissions.SCHEDULED_REPORT_UPDATE,
  permissions.SCHEDULED_REPORT_DELETE
]

// Global roles
export const GLOBAL_ADMIN = {
  id: 'globalAdmin',
  permissions: [
    // All non-super/default PERMISSIONS
    ...Object.values(permissions).filter(p => !p.includes('.super.') && !p.includes('.default.') && !EXCLUDED_PERMISSIONS.includes(p))
  ]
}
export const GLOBAL_READ_ONLY = {
  id: 'globalReadOnly',
  permissions: [
    ...COMMON_READ_ONLY_PERMISSIONS,
    permissions.SCHEDULED_REPORT_SEND,
    permissions.PERFORMANCE_REPORT_SEND,
    permissions.BREACH_REPORT_SEND,
    // All non-super/default read/list PERMISSIONS
    ...Object.values(permissions).filter(p => !p.includes('.super.') && !p.includes('.default.') && (p.endsWith('.read') || p.endsWith('.list') || p.endsWith('.report')) && !EXCLUDED_PERMISSIONS.includes(p)),
    // Permissions for sending email tests from Settings and uPhish
    permissions.SETTINGS_EMAIL_TEST,
    permissions.SIMULATION_EMAIL_TEST,
    permissions.EMAIL_TEMPLATE_EMAIL_TEST
  ]
}

// Users
export const USER_READ_ONLY = {
  id: 'userReadOnly',
  permissions: [
    ...COMMON_READ_ONLY_PERMISSIONS,
    permissions.LEARNER_REPORT,
    permissions.GROUP_REPORT
  ]
}

export const USER_EDITOR = {
  id: 'userEditor',
  extends: [
    USER_READ_ONLY
  ],
  permissions: [
    permissions.LEARNER_CREATE,
    permissions.LEARNER_UPDATE,
    permissions.LEARNER_DELETE,
    permissions.GROUP_CREATE,
    permissions.GROUP_UPDATE,
    permissions.GROUP_DELETE
  ]
}

export const USER_ADMIN = {
  id: 'userAdmin',
  extends: [
    USER_EDITOR
  ],
  permissions: [
    ...COMMON_ADMIN_PERMISSIONS,
    permissions.LEARNER_RESTORE,
    permissions.LEARNER_BULK_CREATE
  ]
}

// uLearn
export const ULEARN_READ_ONLY = {
  id: 'ulearnReadOnly',
  permissions: [
    ...COMMON_READ_ONLY_PERMISSIONS,
    permissions.COURSE_READ,
    permissions.COURSE_LIST,
    permissions.COURSE_ENROLMENT_READ,
    permissions.COURSE_ENROLMENT_LIST,
    permissions.COURSE_REPORT,
    permissions.SETTINGS_ULEARN_READ,
    permissions.SETTINGS_EMAIL_TEST,
    permissions.COURSE_DOWNLOAD_CERTIFICATE
  ]
}

export const ULEARN_EDITOR = {
  id: 'ulearnEditor',
  permissions: [
    permissions.COURSE_READ,
    permissions.COURSE_UPDATE,
    permissions.COURSE_LIST
  ]
}

export const ULEARN_ADMIN = {
  id: 'ulearnAdmin',
  extends: [
    ULEARN_READ_ONLY,
    ULEARN_EDITOR
  ],
  permissions: [
    ...COMMON_ADMIN_PERMISSIONS,
    permissions.COURSE_ENROLMENT_CREATE,
    permissions.COURSE_ENROLMENT_UPDATE,
    permissions.COURSE_ENROLMENT_DELETE,
    permissions.COURSE_ENROLMENT_BULK_CREATE,
    permissions.COURSE_REMINDER_SEND,
    permissions.SETTINGS_ULEARN_UPDATE,
    permissions.COURSE_CREATE,
    permissions.COURSE_DELETE,
    permissions.COURSE_ACCESS_CONTROL
  ]
}

// uBreach
export const UBREACH_READ_ONLY = {
  id: 'ubreachReadOnly',
  permissions: [
    ...COMMON_READ_ONLY_PERMISSIONS,
    permissions.BREACH_RESOLUTION_READ,
    permissions.BREACH_RESOLUTION_LIST,
    permissions.BREACH_READ,
    permissions.BREACH_LIST,
    permissions.BREACH_REPORT,
    permissions.BREACH_REPORT_CREATE,
    permissions.BREACH_REPORT_READ,
    permissions.BREACH_REPORT_UPDATE,
    permissions.BREACH_REPORT_DELETE,
    permissions.BREACH_REPORT_LIST,
    permissions.BREACH_REPORT_SEND,
    permissions.SETTINGS_UBREACH_READ,
    permissions.SETTINGS_EMAIL_TEST
  ]
}

export const UBREACH_ADMIN = {
  id: 'ubreachAdmin',
  extends: [
    UBREACH_READ_ONLY
  ],
  permissions: [
    ...COMMON_ADMIN_PERMISSIONS,
    permissions.BREACH_REPORT_CREATE,
    permissions.BREACH_REPORT_UPDATE,
    permissions.BREACH_REPORT_DELETE,
    permissions.BREACH_RESOLUTION_CREATE,
    permissions.SETTINGS_UBREACH_UPDATE
  ]
}

// uPolicy
export const UPOLICY_READ_ONLY = {
  id: 'upolicyReadOnly',
  permissions: [
    ...COMMON_READ_ONLY_PERMISSIONS,
    permissions.POLICY_READ,
    permissions.POLICY_LIST,
    permissions.POLICY_REPORT,
    permissions.POLICY_TEMPLATE_READ,
    permissions.POLICY_TEMPLATE_LIST,
    permissions.POLICY_RESULT_READ,
    permissions.POLICY_RESULT_LIST,
    permissions.SETTINGS_UPOLICY_READ,
    permissions.SETTINGS_EMAIL_TEST
  ]
}

// uPolicy Editor
export const UPOLICY_EDITOR = {
  id: 'upolicyEditor',
  permissions: [
    permissions.POLICY_CREATE,
    permissions.POLICY_READ,
    permissions.POLICY_UPDATE,
    permissions.POLICY_DELETE,
    permissions.POLICY_LIST,
    permissions.POLICY_TEMPLATE_CREATE,
    permissions.POLICY_TEMPLATE_READ,
    permissions.POLICY_TEMPLATE_UPDATE,
    permissions.POLICY_TEMPLATE_DELETE,
    permissions.POLICY_TEMPLATE_LIST
  ]
}

export const UPOLICY_ADMIN = {
  id: 'upolicyAdmin',
  extends: [
    UPOLICY_READ_ONLY,
    UPOLICY_EDITOR
  ],
  permissions: [
    ...COMMON_ADMIN_PERMISSIONS,
    permissions.POLICY_RESULT_CREATE,
    permissions.POLICY_RESULT_UPDATE,
    permissions.POLICY_RESULT_DELETE,
    permissions.POLICY_REMINDER_SEND,
    permissions.SETTINGS_UPOLICY_UPDATE,
    permissions.POLICY_TEMPLATE_ACCESS_CONTROL
  ]
}

// uPhish:
export const UPHISH_READ_ONLY = {
  id: 'uphishReadOnly',
  permissions: [
    ...COMMON_READ_ONLY_PERMISSIONS,
    permissions.SIMULATION_READ,
    permissions.SIMULATION_LIST,
    permissions.SIMULATION_REPORT,
    permissions.SIMULATION_RESULT_READ,
    permissions.SIMULATION_RESULT_LIST,
    permissions.SIMULATION_EMAIL_TEST,
    permissions.LANDING_PAGE_READ,
    permissions.LANDING_PAGE_LIST,
    permissions.EMAIL_TEMPLATE_READ,
    permissions.EMAIL_TEMPLATE_LIST,
    permissions.EMAIL_TEMPLATE_EMAIL_TEST,
    permissions.SETTINGS_UPHISH_READ
  ]
}

export const UPHISH_EDITOR = {
  id: 'uphishEditor',
  permissions: [
    permissions.LANDING_PAGE_CREATE,
    permissions.LANDING_PAGE_READ,
    permissions.LANDING_PAGE_UPDATE,
    permissions.LANDING_PAGE_DELETE,
    permissions.LANDING_PAGE_LIST,
    permissions.EMAIL_TEMPLATE_CREATE,
    permissions.EMAIL_TEMPLATE_READ,
    permissions.EMAIL_TEMPLATE_UPDATE,
    permissions.EMAIL_TEMPLATE_DELETE,
    permissions.EMAIL_TEMPLATE_LIST,
    permissions.EMAIL_TEMPLATE_EMAIL_TEST
  ]
}

export const UPHISH_ADMIN = {
  id: 'uphishAdmin',
  extends: [
    UPHISH_READ_ONLY,
    UPHISH_EDITOR
  ],
  permissions: [
    ...COMMON_ADMIN_PERMISSIONS,
    permissions.SIMULATION_CREATE,
    permissions.SIMULATION_UPDATE,
    permissions.SIMULATION_DELETE,
    permissions.SIMULATION_RESULT_CREATE,
    permissions.SETTINGS_UPHISH_UPDATE,
    permissions.LANDING_PAGE_ACCESS_CONTROL,
    permissions.EMAIL_TEMPLATE_ACCESS_CONTROL
  ]
}

// uService
export const USERVICE_READ_ONLY = {
  id: 'userviceReadOnly',
  permissions: [
    permissions.BILLING_READ,
    permissions.COMPANY_READ,
    permissions.COMPANY_LIST,
    permissions.SETTINGS_RISK_REPORT_READ,
    permissions.DEFAULT_TENANT_SETTINGS_READ
  ]
}

export const USERVICE_ADMIN = {
  id: 'userviceAdmin',
  extends: [
    USERVICE_READ_ONLY
  ],
  permissions: [
    permissions.COMPANY_CREATE,
    permissions.COMPANY_UPDATE,
    permissions.COMPANY_DELETE,
    permissions.COMPANY_IMPERSONATE,
    permissions.SETTINGS_RISK_REPORT_UPDATE,
    permissions.RISK_REPORT_CREATE,
    permissions.RISK_REPORT_UPDATE,
    permissions.DEFAULT_TENANT_SETTINGS_UPDATE
  ]
}

// 'Super' roles
export const SUPER_ADMIN = {
  id: 'superAdmin',
  // All permissions
  permissions: Object.values(permissions).filter(p => !EXCLUDED_PERMISSIONS.includes(p))
}

export const SUPER_READ_ONLY = {
  id: 'superReadOnly',
  permissions: [
    ...COMMON_READ_ONLY_PERMISSIONS,
    permissions.SCHEDULED_REPORT_SEND,
    permissions.PERFORMANCE_REPORT_SEND,
    permissions.BREACH_REPORT_SEND,
    // All other read/list PERMISSIONS
    ...(Object.values(permissions).filter(p => (p.endsWith('.read') || p.endsWith('.list') || p.endsWith('.report')) && !EXCLUDED_PERMISSIONS.includes(p)))
  ]
}

export const roles = {
  GLOBAL_ADMIN,
  GLOBAL_READ_ONLY,
  USER_READ_ONLY,
  USER_EDITOR,
  USER_ADMIN,
  ULEARN_READ_ONLY,
  ULEARN_EDITOR,
  ULEARN_ADMIN,
  UBREACH_READ_ONLY,
  UBREACH_ADMIN,
  UPOLICY_READ_ONLY,
  UPOLICY_EDITOR,
  UPOLICY_ADMIN,
  UPHISH_READ_ONLY,
  UPHISH_EDITOR,
  UPHISH_ADMIN,
  USERVICE_READ_ONLY,
  USERVICE_ADMIN,
  SUPER_ADMIN,
  SUPER_READ_ONLY
}

export const rolesById = Object.values(roles).reduce((acc, role) => {
  acc[role.id] = role
  return acc
}, {})

export const roleIds = Object.keys(rolesById)

export const superRoleIds = roleIds.filter(roleId => roleId.startsWith('super'))

export const superRoleIdDowngrades = {
  [SUPER_ADMIN.id]: GLOBAL_ADMIN.id,
  [SUPER_READ_ONLY.id]: GLOBAL_READ_ONLY.id
}
