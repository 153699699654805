import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'

import { disableActions } from './listPages'
import { permissions } from '../constants/permissions'

const trOpt = { scope: 'learners.learnersView' }

export const getLearnerActions = ({ learner, uLearnEnabled = true, uPhishEnabled = true, uPolicyEnabled = true, policy, allowedActions, disabledActions, hasAllSessionPermissions }) => {
  const { attachedToSync = false } = learner || {}
  const actions = []
  if (hasAllSessionPermissions([permissions.LEARNER_UPDATE])) {
    actions.push({ key: 'editLearner', label: I18n.t('common.editUser') })
  }
  if (hasAllSessionPermissions([permissions.LEARNER_DELETE])) {
    actions.push(({ key: 'deleteLearners', label: I18n.t('deleteUser', trOpt) }))
  }
  if (hasAllSessionPermissions([permissions.LEARNER_UPDATE])) {
    actions.push({ key: 'markLearnersActive', label: I18n.t('markAsActive', trOpt) },
      { key: 'markLearnersInactive', label: I18n.t('markAsInactive', trOpt) })
  }
  if (hasAllSessionPermissions([permissions.LEARNER_RESTORE])) {
    actions.push({ key: 'restoreUsers', label: I18n.t('restoreUser', trOpt) })
  }
  if (hasAllSessionPermissions([permissions.LEARNER_UPDATE, permissions.GROUP_UPDATE])) {
    actions.push({
      key: 'addLearnersToGroup', label: I18n.t('addUsersToGroup', trOpt)
    })
  }

  if (uLearnEnabled) {
    if (hasAllSessionPermissions([permissions.COURSE_ENROLMENT_CREATE])) {
      actions.push({
        key: 'enrollOnCourse', label: I18n.t('enrollOnCourse', trOpt)
      },
      {
        key: 'enrollOnGapAnalysis', label: I18n.t('enrollOnGapAnalysis', trOpt)
      })
    }
    if (hasAllSessionPermissions([permissions.COURSE_ENROLMENT_DELETE])) {
      actions.push({
        key: 'unenrolFromCourse', label: I18n.t('unenrolFromCourse', trOpt)
      },
      {
        key: 'unenrolGapAnalysis', label: I18n.t('unenrolGapAnalysis', trOpt)
      })
    }
    if (hasAllSessionPermissions([permissions.COURSE_REPORT])) {
      actions.push({
        key: 'exportCourseData', label: I18n.t('exportCourseData', trOpt)
      })
    }
  }
  if (uPolicyEnabled) {
    if (hasAllSessionPermissions([permissions.POLICY_RESULT_CREATE])) {
      actions.push({ key: 'sendPolicy', label: I18n.t('uPolicy.common.sendPolicy') })
    }
    if (policy) {
      if (hasAllSessionPermissions([permissions.POLICY_RESULT_UPDATE])) {
        actions.push({ key: 'signPolicy', label: I18n.t('markPolicyAsSigned', trOpt) })
      }
      if (['visited', 'pending'].includes(learner.policyStatus) && hasAllSessionPermissions([permissions.POLICY_RESULT_DELETE])) { // This corresponds to a row for policy result with learner data
        actions.push({ key: 'removeLearnersFromPolicy', label: I18n.t('removeUserFromPolicy', trOpt) })
      }
    }
    if (hasAllSessionPermissions([permissions.POLICY_REPORT])) {
      actions.push({ key: 'exportPolicyData', label: I18n.t('common.exportPolicyData') })
    }
  }
  if (uPhishEnabled) {
    if (hasAllSessionPermissions([permissions.SIMULATION_REPORT])) {
      actions.push({ key: 'exportSimulationData', label: I18n.t('exportSimulationData', trOpt) })
    }
  }

  if (attachedToSync) {
    if (hasAllSessionPermissions([permissions.LEARNER_UPDATE, permissions.SETTINGS_SYNC_UPDATE])) {
      actions.push({ key: 'detachLearnersFromSync', label: I18n.t('detachLearnersFromSync', trOpt) })
    }
  }

  const filteredActions = filterLearnerActions(actions, allowedActions)
  return disableActions(filteredActions, disabledActions)
}

export const filterLearnerActions = (actions, allowedActions) => {
  if (_isArray(allowedActions) && !_isEmpty(allowedActions)) {
    return actions.filter(action => allowedActions.includes(action.key))
  }
  // restoreUsers is only allowed if explicitly asked for
  return actions.filter(action => action.key !== 'restoreUsers')
}
