import React from 'react'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import SettingsForm from '../SettingsForm'
import { withConsumer } from '../../../hocs'
import { GET_SIMULATION_TLDS } from '../../Queries/uPhish'
import { showErrors } from '../../../helpers'
import { LoadingBlock } from '../../common'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.tldAllowList' }

class TopLevelDomainAllowList extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = ['tldAllowList']
    this.defaultValue = {
      tldAllowList: []
    }
    this.state = {
      tlds: []
    }

    this.requiredUpdatePermissions = [permissions.SETTINGS_UPHISH_UPDATE]
  }

  get headerId () {
    return 'settings-preferred-TLDs-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const tldAllowList = _get(this.props.settings, 'tldAllowList') || this.defaultValue

    return [
      {
        id: 'tldAllowList',
        label: I18n.t('tldAllowList', trOpt),
        type: 'multiSelect',
        options: this.state.tlds.map(tld => ({ value: tld, label: tld })),
        defaultValue: tldAllowList,
        placeholder: I18n.t('tldAllowListPlaceholder', trOpt),
        extra: I18n.t('tldAllowListExtra', trOpt),
        allowClear: true,
        showSearch: true
      }
    ]
  }

  async componentDidMount () {
    try {
      const { data: { simulationTLDs: tlds = [] } = {}, error } = await this.props.client.query({ query: GET_SIMULATION_TLDS })
      if (error) throw error
      await this.updateState({ tlds })
    } catch (e) {
      showErrors(e, I18n.t('loadTLDsErrorMessage', trOpt))
    }
    await super.componentDidMount()
  }

  render () {
    return (
      <>
        <LoadingBlock fullScreen={false} loading={this.state.loading} />
        {super.render()}
      </>
    )
  }
}

export default withConsumer(TopLevelDomainAllowList)
