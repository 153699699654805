import React, { useState, useCallback } from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'
import { downloadReportsReducer } from '../../helpers'

const ReportDownloadButton = ({ reports = [], children = I18n.t('reports.downloadSpreadsheet') }) => {
  const [loading, updateLoading] = useState(false)
  const onClick = useCallback(async () => {
    updateLoading(true)
    await downloadReportsReducer(reports)
    updateLoading(false)
  }, [reports])

  return (
    <Button icon='download' {...{ loading, onClick }} type='primary'>{children}</Button>
  )
}

export default ReportDownloadButton
