import React, { useCallback } from 'react'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'

import ActionConfirm from './ActionConfirm'

const deleteTrOpt = { scope: 'modals.deleteConfirm' }

const DeleteConfirm = React.forwardRef(({
  deletedItems = [],
  trOpt = deleteTrOpt,
  ...actionConfirmProps
}, ref) => {
  // This should be a component really but it's rendered by ActionConfirm and it was easier to handle that with a function
  const body = useCallback(count => {
    let items
    if (_isArray(deletedItems) && deletedItems.length > 0) {
      items = deletedItems
    } else if (_isFunction(deletedItems)) {
      items = deletedItems(count)
    }
    return (
      _isArray(items) && items.length > 0
        ? (
          <div>
            <span>{I18n.t('thisWillDelete', { ...trOpt, defaultValue: I18n.t('thisWillDelete', deleteTrOpt) })}:</span>
            <ul>
              {items}
            </ul>
          </div>
        ) : null
    )
  }, [deletedItems, trOpt])

  return (
    <ActionConfirm
      {...{ body, trOpt, ref }}
      {...actionConfirmProps}
    />
  )
})

export default DeleteConfirm
