import React, { useImperativeHandle, useState, useCallback, useEffect, useMemo } from 'react'
import { message, Modal, Button } from 'antd'
import { useMutation } from 'react-apollo'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { withConsumer } from '../../hocs/withConsumer'
import ExportServiceClient from '../../helpers/ExportServiceClient'
import { GET_REPORT_ACCESS_TOKEN } from '../Queries/Reports'
import StatusIndicator from '../Reports/DownloadReportStatusIndicator'

// CL - DownloadReportModal and ExportServiceDownloadModal are very similar and could be combined. I've added comments where they differ.

const PUPPETEER_MICROSERVICE_URL = window.__USECURE_CONFIG__.REACT_APP_PUPPETEER_MICROSERVICE_URL

const DownloadModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const FailedOkBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

// CL - The keys and text under this are identical to modals.downloadReportModal
const trOpt = { scope: 'modals.exportServiceDownloadModal' }

const DownloadReportModal = React.forwardRef(({
  companyId, getReportAccessTokenMutation = GET_REPORT_ACCESS_TOKEN, getReportAccessTokenMutationName = 'reportAccessToken'
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [report, setReport] = useState(null)
  const [status, setStatus] = useState(null)
  const [reportAccessToken, setReportAccessToken] = useState(null) // CL - Specific to this modal

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setReport(null)
    setStatus(null)
    setReportAccessToken(null) // CL - Specific to this modal
  }

  const onStatusChange = useCallback(({ status }) => {
    setStatus(status)
  }, [setStatus])

  const onFinish = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  // CL - Specific to this modal
  const [getReportAccessToken] = useMutation(getReportAccessTokenMutation, {
    onCompleted: ({ [getReportAccessTokenMutationName]: token }) => setReportAccessToken(token),
    onError: () => setStatus('failed')
  })

  // CL - Specific to this modal
  const puppeteerServiceClient = useMemo(() => {
    if (!reportAccessToken || !report) return null

    const { name } = report
    const fileName = `${name}.pdf`

    return new ExportServiceClient({
      reportAccessToken,
      onStart: onStatusChange,
      onStatusChange,
      onFinish,
      fileType: 'pdf',
      fileName,
      baseURL: PUPPETEER_MICROSERVICE_URL
    })
  }, [reportAccessToken, report, onStatusChange, onFinish])

  // CL - Specific to this modal
  useEffect(() => {
    if (!puppeteerServiceClient) return
    puppeteerServiceClient.execute()
  }, [puppeteerServiceClient])

  useImperativeHandle(ref, () => ({
    open: async (report, reportAccessToken) => {
      setReport(report) // CL - Specific to this modal
      setStatus('pending')
      setVisible(true)

      // CL - Specific to this modal
      if (reportAccessToken) {
        setReportAccessToken(reportAccessToken)
      } else {
        getReportAccessToken({
          variables: {
            companyId,
            ...report
          }
        })
      }
    }
  }), [setReport, setVisible, getReportAccessToken, companyId])

  return (
    <DownloadModal
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      afterClose={afterClose}
      width='70%'
      closable={false}
      maskClosable={false}
    >
      <StatusIndicator {...{ status, trOpt }} />
      {status === 'failed' && (
        <FailedOkBtnContainer>
          <Button type='primary' onClick={closeModal}>{I18n.t('common.ok')}</Button>
        </FailedOkBtnContainer>
      )}
    </DownloadModal>
  )
})

// CL - Specific to this modal as ExportServiceDownloadModal doesn't make any GraphQL requests
export default withConsumer({ useHooks: true })(DownloadReportModal)
