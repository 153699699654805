/* global localStorage */
import React, { useCallback } from 'react'
import { Button, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'
import _pick from 'lodash/pick'

import { addDelay, showErrors } from '../../helpers'
import { clearLandingPageSimulationCache, getSimulationMutationVariables } from '../../helpers/uPhish'
import { GET_SIMULATION, UPDATE_SIMULATION } from '../Queries/uPhish'
import { UPHISH_SIMULATION_PERFORMANCE } from '../Queries/Reports'
import { creators as viewActions } from '../../state/actions/view'
import { connect } from '../../hocs'
import routes from '../../constants/routes'
import selectors from '../../state/selectors'

const trOpt = { scope: 'uPhish.updateSimulation' }

const UpdateSimulationMutateButton = ({ history, disabled, simulation, setLoadingVisible = () => {}, storageId, excludeInactiveUsersInReports: excludeInactiveUsers }) => {
  const [saveSimulation] = useMutation(UPDATE_SIMULATION)
  const mutate = useCallback(async () => {
    setLoadingVisible(true)
    await saveSimulation({
      variables: getSimulationMutationVariables(simulation),
      refetchQueries: [
        {
          query: GET_SIMULATION,
          variables: { id: simulation.id, excludeInactiveUsers }
        },
        {
          query: UPHISH_SIMULATION_PERFORMANCE,
          variables: { excludeInactiveUsers }
        }
      ]
    })
    await clearLandingPageSimulationCache(simulation.id, { skipMessage: true })
  }, [setLoadingVisible, saveSimulation, simulation, excludeInactiveUsers])

  const onComplete = useCallback(() => {
    // Remove simulation from local storage
    localStorage.removeItem(storageId)
    setLoadingVisible(false)
    message.success(I18n.t('uPhish.common.saveSuccessMessage'))
    // Redirect to simulations page on success
    history.push(routes.UPHISH_SIMS)
  }, [setLoadingVisible, storageId, history])

  const onFailure = useCallback(e => {
    setLoadingVisible(false)
    showErrors(e, I18n.t('errorMessage', trOpt))
  }, [setLoadingVisible])

  const onClick = useCallback(async () => {
    // Artificial delay on hiding the loading screen unless mutation took longer than articifial delay
    addDelay({
      delay: 1000,
      action: mutate,
      complete: onComplete,
      failure: onFailure
    })
  }, [mutate, onComplete, onFailure])

  return (
    <Button type='primary' disabled={disabled} onClick={onClick}>{I18n.t('common.save')}</Button>
  )
}

export default compose(
  withRouter,
  connect(
    state => _pick(selectors.view.get(state), ['excludeInactiveUsersInReports']),
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    })
  )
)(UpdateSimulationMutateButton)
