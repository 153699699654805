import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { message, Modal as _Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import MutationForm from '../MutationForm/MutationForm'
import { modalConfirmAsync, showErrors, UsecureError, waitForRefRender } from '../../helpers'
import { SEND_WEEKLY_SUMMARY_NOW } from '../Queries/Reports'
import { connect } from '../../hocs'
import { getSettings } from '../../state/selectors/settings'
import { MutationFormFooter } from '../MutationForm'
import { LoadingBlock } from '../common'

const trOpt = { scope: 'modals.sendWeeklySummaryNowModal' }
const settingsTrOpt = { scope: 'settings.weeklySummary' }

const Modal = styled(_Modal)`
  max-width: 500px;
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const UserTypeMutationForm = styled(MutationForm)`
  .ant-form-item {
    margin-bottom: 0;
  }

  ${MutationFormFooter} {
    margin-top: 15px;
  }
`

const SendWeeklySummaryNowModal = React.forwardRef(({ weeklySummary }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const setInitialFormValues = useCallback(async (values) => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async () => {
      try {
        setVisible(true)
        setLoading(true)
        await waitForRefRender(form)
        const values = {
          admin: true,
          groupManager: true,
          learnerManager: true
        }
        if (weeklySummary?.enabled === true) {
          values.admin = weeklySummary?.admin === true
          values.groupManager = weeklySummary?.groupManager === true
          values.learnerManager = weeklySummary?.learnerManager === true
        }
        await setInitialFormValues(values)
        setLoading(false)
      } catch (e) {
        setVisible(false)
        showErrors(e, I18n.t('initialisationError', trOpt))
      }
    }
  }), [setInitialFormValues, weeklySummary, form])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setLoading(false)
    resetForm()
  }

  const [sendWeeklySummaryNow] = useMutation(SEND_WEEKLY_SUMMARY_NOW)
  const onSubmit = useCallback(async (values, errors, variables) => {
    if (variables.reportTypes.length === 0) {
      throw new UsecureError(I18n.t('noReportTypesError', trOpt))
    }

    try {
      setLoading(true)
      const confirm = await modalConfirmAsync({
        content: I18n.t('sendWeeklySummaryPrompt', settingsTrOpt)
      })
      if (!confirm) {
        throw new UsecureError(I18n.t('cancelledMessage', trOpt), { level: 'info' })
      }
      const success = _get((await sendWeeklySummaryNow({ variables })), 'data.sendWeeklySummaryNow', false)
      if (!success) {
        throw new Error() // will be caught by MutationForm.onSubmit
      }
    } catch (e) {
      console.error('SendWeeklySummaryNowModal.onSubmit - Mutation Error', e)
      throw e // will be caught and displayed by MutationForm
    } finally {
      // This try-catch-finally is only here to disable loading overlay
      setLoading(false)
    }
  }, [sendWeeklySummaryNow])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', settingsTrOpt))
    closeModal()
  }, [closeModal])

  const mutateValues = useCallback(({ admin, groupManager, learnerManager }) => {
    const reportTypes = []
    if (admin) {
      reportTypes.push('admin')
    }
    if (groupManager) {
      reportTypes.push('groupManager')
    }
    if (learnerManager) {
      reportTypes.push('learnerManager')
    }

    return { reportTypes }
  }, [])

  return (
    <Modal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={I18n.t('sendWeeklySummaryTitle', settingsTrOpt)}
      afterClose={afterClose}
      width='50%'
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <p>{I18n.t('intro', trOpt)}</p>
      <UserTypeMutationForm
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        mutateValues={mutateValues}
        failureMessage={I18n.t('errorMessage', settingsTrOpt)}
        submitLabel={I18n.t('sendWeeklySummary', settingsTrOpt)}
        submitIcon='mail'
        fields={[{
          id: 'admin',
          type: 'checkbox',
          label: I18n.t('userTypes.admin', trOpt),
          defaultValue: true,
          renderLabelInline: true
        }, {
          id: 'groupManager',
          type: 'checkbox',
          label: I18n.t('userTypes.groupManager', trOpt),
          defaultValue: true,
          renderLabelInline: true
        }, {
          id: 'learnerManager',
          type: 'checkbox',
          label: I18n.t('userTypes.learnerManager', trOpt),
          defaultValue: true,
          renderLabelInline: true
        }]}
        ref={form}
        skipResetFieldsOnSubmit
      />
    </Modal>
  )
})

export default connect(
  state => ({
    ..._pick(getSettings(state), ['weeklySummary'])
  })
)(SendWeeklySummaryNowModal)
