import React, { useCallback, useMemo } from 'react'

import ActionConfirm from './ActionConfirm'
import { SEND_POLICIES } from '../Queries/uPolicy'
import { invalidatePoliciesQueryCache } from '../../helpers'
import { showSendPoliciesMessages } from './SendPolicyLearnerModal'

const SendPolicyLearnerConfirm = ({ policy, learners = [], ...actionConfirmProps }) => {
  policy = policy || { id: null, name: 'POLICY' }
  const learnerIds = useMemo(() => learners.map(({ id }) => id), [learners])
  const getSuccessMessage = useCallback((count, result) => {
    const { data: { sendPoliciesToLearners: { completed = [], policyIds = [], learnerIds = [] } = {} } = {} } = result || {}
    showSendPoliciesMessages({ completed, learnerIds, policyIds, policies: [policy] })
  }, [policy])

  return (
    <ActionConfirm
      mutation={SEND_POLICIES}
      ids={learnerIds}
      variableName='learnerIds'
      variables={{ policyIds: [policy.id] }}
      update={invalidatePoliciesQueryCache}
      trOpt={{ scope: 'modals.sendPolicyLearnerConfirm', count: learnerIds.length, policy: policy.name }}
      successMessage={getSuccessMessage}
      {...actionConfirmProps}
    />
  )
}

export default SendPolicyLearnerConfirm
