import React, { useCallback } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

const ModalPagerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  min-height: 32px;

  span {
    margin-right: 5px;
  }

  .ant-btn {
    margin-left: 5px;
  }
`

const ModalPager = ({ className, current = 1, count = 0, prev, next, onClick: onClickProp = () => {} }) => {
  const onPrevClick = useCallback(() => onClickProp(prev), [prev, onClickProp])
  const onNextClick = useCallback(() => onClickProp(next), [next, onClickProp])

  if (count === 0) return null
  return (
    <ModalPagerContainer className={className}>
      <span>{I18n.t('common.modalPagerLabel', { current, count })}</span>
      {prev && <Button type='primary' icon='left' onClick={onPrevClick} />}
      {next && <Button type='primary' icon='right' onClick={onNextClick} />}
    </ModalPagerContainer>
  )
}

export default ModalPager
