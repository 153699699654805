import _isNil from 'lodash/isNil'

import { PRODUCT_NAME_IDS } from '../../constants/productNames'
import { setProductNames } from '../../helpers/locale'
import { types as sessionTypes } from '../actions/session'
import { types } from '../actions/settings'

const {
  SETTINGS_UPDATE,
  SETTINGS_REPLACE,
  SETTINGS_RESET
} = types
const {
  SESSION_INIT
} = sessionTypes

export const initialState = {
  ready: false
}

const setProductNamesFromSettingsState = state => {
  const appProductNames = state.appProductNames || {}
  setProductNames(
    PRODUCT_NAME_IDS.reduce((acc, id) => {
      const { [id]: appProductName } = appProductNames
      if (!_isNil(appProductName)) {
        acc[id] = appProductName
      }
      return acc
    }, {})
  )
}

const settingsUpdate = (prevState, payload) => {
  const newState = {
    ...prevState,
    ...payload,
    ready: true
  }
  setProductNamesFromSettingsState(newState)

  return newState
}

const actionsMap = {
  [SESSION_INIT]: (state, { payload: { settings: payload } = {} }) => settingsUpdate(state, payload),
  [SETTINGS_UPDATE]: (state, { payload = {} }) => settingsUpdate(state, payload),
  [SETTINGS_REPLACE]: (state, { payload = {} }) => {
    const replacedState = {
      ...payload,
      ready: true
    }
    setProductNamesFromSettingsState(replacedState)
    return replacedState
  },
  [SETTINGS_RESET]: () => {
    const resetState = { ...initialState }
    setProductNamesFromSettingsState(resetState)
    return resetState
  }
}

const reducer = (state = initialState, action = {}) => {
  const fn = actionsMap[action.type]
  return fn ? fn(state, action) : state
}

export default reducer
