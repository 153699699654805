import React from 'react'
import styled, { css, withTheme } from 'styled-components'
import { Badge } from 'antd'

import { RISK_SCORE_LABELS, RISK_SCORE_COLORS } from '../../constants/riskScore'

const riskScoreBadge = ({ size, ...rest }) => <Badge {...rest} />
const RiskScoreBadge = styled(riskScoreBadge)`
  ${({ size }) => {
    if (size === 'large') {
      return css`
  .ant-badge-status-dot {
    height: 18px;
    top: -4px;
    width: 18px;
  }
  .ant-badge-status-text {
    font-size: 1.6rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
`
    }
  }}
  ${({ color }) => {
    if (color === 'transparent') {
      return css`
        .ant-badge-status-dot {
          box-shadow: 0 0 0 1px #7b7b7b inset;
          color: #999;
        }
      `
    }
  }}
`

const RiskScoreIndicator = ({ value, text, theme, size }) => {
  text = text || RISK_SCORE_LABELS[value]
  if (!text) {
    return null
  }
  let color = RISK_SCORE_COLORS[value] || 'primary'
  if (value === 'transparent') {
    color = value
  } else if (color && theme[color]) {
    color = theme[color]
  }

  return (
    text
      ? <RiskScoreBadge {...{ text, color, size }} />
      : null
  )
}

export default withTheme(RiskScoreIndicator)
