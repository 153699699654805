/* global localStorage */
import React, { useCallback } from 'react'
import { Col, message, Modal, Row, Skeleton } from 'antd'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { addDelay, showErrors } from '../../helpers'
import uPhish from '../../helpers/uPhish'
import { CREATE_SIMULATION, GET_SIMULATION_EMAIL_TEMPLATE, GET_SIMULATION_LANDING_PAGE_TEMPLATE } from '../Queries/uPhish'
import { UPHISH_SIMULATION_PERFORMANCE } from '../Queries/Reports'
import { GET_COURSE_BASIC } from '../Queries/Courses'
import { GET_PROSPECT_RISK_REPORT } from '../Queries/Companies'
import { creators as viewActions } from '../../state/actions/view'
import { connect, withRefreshSessionState } from '../../hocs'
import routes from '../../constants/routes'
import selectors from '../../state/selectors'
import { NO_GROUP_ID } from '../../constants/group'
import { formatDate, formatTime } from '../../helpers/datetime'
import { DELIVERY_METHODS, UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP } from '../../constants/uPhish'
import { ENABLE_MESSAGE_INJECTION } from '../../constants/environment'

const trOpt = { scope: 'modals.createSimulationConfirm' }
const { getSimulationMutationVariables } = uPhish

const WHITELIST = window.__USECURE_CONFIG__.REACT_APP_UPHISH_WHITELIST

const SimulationSummaryRowTitle = styled.span`
  font-weight: 500;
`

const SimulationSummaryFooter = styled.div`
  margin-top: 40px;

  p {
    font-weight: 500;
  }
`
const SummaryRow = styled(Row)`
  margin-bottom: 10px;
`

const SimulationSummaryRow = ({ label, value, loading = false }) => {
  const valueComponent = loading ? <Skeleton active /> : <span>{value}</span>

  return (
    <SummaryRow>
      <Col span={8}>
        <SimulationSummaryRowTitle>{label}</SimulationSummaryRowTitle>
      </Col>
      <Col span={16}>
        {valueComponent}
      </Col>
    </SummaryRow>
  )
}

const getSenderText = simulation => {
  const {
    customSender = false,
    senderPrefix = '', senderDomain = '',
    senderName = '', senderEmail = ''
  } = simulation
  const email = customSender ? senderEmail : `${senderPrefix.trim()}@${senderDomain.trim()}`
  return senderName ? `${senderName} <${email}>` : email
}

const SimulationSummary = ({ simulation }) => {
  const {
    attackType,
    landingPageId, emailTemplateId,
    name,
    recipients, sendToAll, startDate,
    courseId,
    deliveryMethod = 'smtp'
  } = simulation
  let { link } = simulation
  const isFreeform = emailTemplateId === 'freeform'

  const learners = recipients && recipients.learners ? recipients.learners.length : 0
  let recipientsValue
  if (sendToAll) {
    recipientsValue = I18n.t('allUsers', trOpt)
  } else {
    recipientsValue = []
    if (learners > 0) {
      recipientsValue.push(I18n.t('recipientUsers', { ...trOpt, count: learners }))
    }
    if (recipients && recipients.groups && recipients.groups.length > 0) {
      const noGroupSelected = recipients.groups.some(({ id }) => id === NO_GROUP_ID)
      const groups = recipients.groups.length - (noGroupSelected ? 1 : 0)
      if (groups > 0) {
        recipientsValue.push(I18n.t('recipientGroups', { ...trOpt, count: groups }))
      }
      if (noGroupSelected) {
        recipientsValue.push(I18n.t('recipientNoGroup', trOpt))
      }
    }
    recipientsValue = recipientsValue.join(', ')
  }

  const sender = getSenderText(simulation)

  const date = formatDate(startDate)
  const time = formatTime(startDate)
  link = `https://${link}`

  const { data: { simulationEmailTemplate } = {} } = useQuery(GET_SIMULATION_EMAIL_TEMPLATE, {
    variables: { id: emailTemplateId },
    skip: isFreeform
  })
  const { data: { simulationLandingPage } = {} } = useQuery(GET_SIMULATION_LANDING_PAGE_TEMPLATE, {
    variables: { id: landingPageId },
    skip: !landingPageId
  })
  const { data: { course } = {} } = useQuery(GET_COURSE_BASIC, {
    variables: { courseId },
    skip: !courseId
  })

  const landingPage = _get(simulationLandingPage, 'name') || `<${landingPageId}>`
  const emailTemplate = isFreeform ? I18n.t('common.custom') : (_get(simulationEmailTemplate, 'name') || `<${emailTemplateId}>`)
  const courseName = _get(course, 'displayName')

  return (
    <div>
      <SimulationSummaryRow label={I18n.t('uPhish.common.attackType')} value={I18n.t(`uPhish.common.attackTypes.${UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP[attackType]}`)} />
      <SimulationSummaryRow label={I18n.t('uPhish.common.simulationName')} value={name} />
      <SimulationSummaryRow label={I18n.t('simulationRecipients', trOpt)} value={recipientsValue} />
      {attackType !== 'attachment' && (
        <>
          <SimulationSummaryRow label={I18n.t('uPhish.common.landingPage')} value={landingPage} />
          <SimulationSummaryRow label={I18n.t('uPhish.viewSimulationDetails.landingPageURL')} value={link} />
        </>
      )}
      <SimulationSummaryRow label={I18n.t('emailSender', trOpt)} value={sender} />
      <SimulationSummaryRow label={I18n.t('uPhish.common.emailTemplate')} value={emailTemplate} />
      {courseName && <SimulationSummaryRow label={I18n.t('uPhish.common.inlineTrainingCourse')} value={courseName} />}
      <SimulationSummaryRow label={I18n.t('common.fields.date')} value={date} />
      <SimulationSummaryRow label={I18n.t('time', trOpt)} value={time} />
      {ENABLE_MESSAGE_INJECTION && <SimulationSummaryRow label={I18n.t('uPhish.common.preferredDeliveryMethod')} value={DELIVERY_METHODS[deliveryMethod]} />}
      <SimulationSummaryFooter>
        {WHITELIST && (
          <>
            {I18n.t('whitelistMessage', { ...trOpt, count: (WHITELIST || '').split(',').length })}
            <br />{WHITELIST}
          </>
        )}
      </SimulationSummaryFooter>
    </div>
  )
}

const CreateSimulationConfirm = ({
  visible, history, updateVisible = () => {}, simulation, setLoadingVisible = () => {}, storageId, companyId, prospectStatus, refreshSessionState, excludeInactiveUsersInReports: excludeInactiveUsers
}) => {
  const [saveSimulation] = useMutation(CREATE_SIMULATION)
  const mutate = useCallback(async () => {
    setLoadingVisible(true)
    const refetchQueries = [{ query: UPHISH_SIMULATION_PERFORMANCE, variables: { excludeInactiveUsers } }]
    if (prospectStatus && ['uphish_requested', 'uphish_ready'].includes(prospectStatus)) {
      refetchQueries.push({ query: GET_PROSPECT_RISK_REPORT, variables: { companyId } })
    }
    await saveSimulation({
      variables: getSimulationMutationVariables(simulation),
      refetchQueries
    })
    await refreshSessionState()
  }, [setLoadingVisible, saveSimulation, simulation, companyId, prospectStatus, refreshSessionState, excludeInactiveUsers])

  const onComplete = useCallback(() => {
    // Remove simulation from local storage
    localStorage.removeItem(storageId)
    setLoadingVisible(false)
    message.success(I18n.t('uPhish.common.saveSuccessMessage'))
    // Redirect to simulations page on success
    history.push(routes.UPHISH_SIMS)
  }, [setLoadingVisible, storageId, history])

  const onFailure = useCallback(e => {
    setLoadingVisible(false)
    showErrors(e, I18n.t('errorMessage', trOpt))
  }, [setLoadingVisible])

  const onOk = useCallback(async () => {
    // Artificial delay on hiding the loading screen unless mutation took longer than articifial delay
    addDelay({
      delay: 1000,
      action: mutate,
      complete: onComplete,
      failure: onFailure
    })
  }, [mutate, onComplete, onFailure])

  const onCancel = useCallback(() => updateVisible(false), [updateVisible])

  return (
    <Modal
      visible={visible}
      title={I18n.t('title', trOpt)}
      okText={I18n.t('okText', trOpt)}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
    >
      <SimulationSummary simulation={simulation} />
    </Modal>
  )
}

export default compose(
  withRouter,
  withRefreshSessionState,
  connect(
    state => ({
      ..._pick(selectors.session.get(state), ['companyId', 'prospectStatus']),
      ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
    }),
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    })
  )
)(CreateSimulationConfirm)
