import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import { Button, Icon } from 'antd'

import { connect, withAppTitleAndIcons } from '../../hocs'
import { creators as viewActions } from '../../state/actions/view'
import { creators as settingsActions } from '../../state/actions/settings'
import { SignInHeader } from '../../components/SignIn'
import { Row, Column } from '../../components/EndUserPortal/GridFlexiLayout'
import { MutationFormFooter } from '../../components/MutationForm'
import MutationForm from '../../components/MutationForm/MutationForm'
import { FORGOTTEN_PASSWORD } from '../../components/Queries/Users'
import { showErrors } from '../../helpers'
import routes from '../../constants/routes'
import { captureSentryError } from '../../helpers/sentry'

const trOpt = { scope: 'signIn.forgottenPasswordLink' }

const BackToLoginContainer = styled.div`
  height: 0;
  text-align: center;
`
const BackToLoginLink = styled(Link)`
  position: relative;
  top: -20px;
`
const MessageWrapper = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  h2 {
    color: ${props => props.theme.primary} !important;
  }
`
const BigIcon = styled(Icon)`
  font-size: 40pt;
  margin-bottom: 0.5em;
  color: ${props => props.theme.primary} !important;
`
const ForgottenPassword = ({ setLoadingVisible, updateSettings }) => {
  const [showForm, setShowForm] = useState(true)

  useEffect(() => {
    updateSettings({ useSingleFavicon: true }) // Force default icon, withAppTitleAndIcons will set the default icon
  }, [updateSettings])

  const fields = [
    { id: 'email', placeholder: I18n.t('common.fields.email'), type: 'email', required: true }
  ]

  const handleSubmit = () => {
    setLoadingVisible(true)
  }
  const handleSuccess = () => {
    setLoadingVisible(false)
    setShowForm(false)
  }
  const handleFailure = (e) => {
    setLoadingVisible(false)
    captureSentryError(e, { msg: 'ForgottenPassword.handleFailure - Error' })
    showErrors(e)
  }

  const renderFooter = ({ submitLabel, loading, footerAlign }) => {
    return (
      <>
        <MutationFormFooter footerAlign={footerAlign}>
          <Button type='primary' ghost disabled={loading} htmlType='submit'>{submitLabel}</Button>
        </MutationFormFooter>
      </>
    )
  }

  return (
    <Row className='full-height justify align' justify='space-around' alignItems='center' alignContent='center'>
      <Column className='align' align-items='center' align-content='center' flex='0 0 30em' style={{ padding: '2em' }}>
        <SignInHeader bottomMargin='2em'>
          {I18n.t('title', trOpt)}
        </SignInHeader>
        <BackToLoginContainer>
          <BackToLoginLink to={routes.HOME}>{I18n.t('backToLogin', trOpt)}</BackToLoginLink>
        </BackToLoginContainer>
        {showForm
          ? (
            <MutationForm
              mutation={FORGOTTEN_PASSWORD}
              onSubmit={handleSubmit}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              submitLabel={I18n.t('requestPasswordReset', trOpt)}
              fields={fields}
              footer={renderFooter}
              focusOnMount
            />
          ) : (
            <MessageWrapper>
              <BigIcon type='mail' />
              <h2>{I18n.t('successMessage', trOpt)}</h2>
            </MessageWrapper>
          )}
      </Column>
    </Row>
  )
}

export default compose(
  connect(
    undefined,
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
      updateSettings: settings => dispatch(settingsActions.update(settings))
    })
  ),
  withAppTitleAndIcons({ isSignInPage: true })
)(ForgottenPassword)
