import React from 'react'
import { Alert } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { renderListFromArray } from '../../helpers/renderFromArray'

const ErrorAlert = styled(Alert)`
  margin-bottom: 15px;
`

const ValidationAlert = ({ visible, type, message, description }) => {
  if (!visible) {
    return null
  }
  return (
    <ErrorAlert {...{ type, message, description }} />
  )
}

const linkTrOpt = { scope: 'uPhish.contentLinkAlert' }
export const ContentLinkAlert = React.forwardRef(({ visible }, ref) => (
  <ValidationAlert
    visible={visible}
    type='error'
    message={I18n.t('title', linkTrOpt)}
    description={I18n.t('message', linkTrOpt)}
  />
))

const senderTrOpt = { scope: 'uPhish.senderWarningAlert' }
export const SenderWarningAlert = React.forwardRef(({ visible }, ref) => (
  <ValidationAlert
    visible={visible}
    type='warning'
    message={I18n.t('title', senderTrOpt)}
    description={renderListFromArray(I18n.t('list', senderTrOpt))}
  />
))

const triggerTrOpt = { scope: 'uPhish.contentTriggerAlert' }
export const ContentTriggerAlert = React.forwardRef(({ visible }, ref) => (
  <ValidationAlert
    visible={visible}
    type='error'
    message={I18n.t('title', triggerTrOpt)}
    description={
      <>
        <p className='base-font'>{I18n.t('message', triggerTrOpt)}</p>
        <p className='base-font'>{I18n.t('listIntro', triggerTrOpt)}</p>
        {renderListFromArray(I18n.t('list', triggerTrOpt))}
      </>
    }
  />
))

export default ValidationAlert
