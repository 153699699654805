import { REGION_ID } from '../constants/environment'

const setupRegionCookie = () => {
  const regionCookie = document.cookie.match('(^|;)\\s*region\\s*=\\s*([^;]+)')?.pop() || ''
  // REACT_APP_REGION_COOKIE_DOMAIN env var must be set for multi region environment to work properly
  const regionCookieDomain = process.env.REACT_APP_REGION_COOKIE_DOMAIN ?? ''
  if (regionCookie && !regionCookie.includes(`${REGION_ID}`)) {
    document.cookie = `region=${regionCookie},${REGION_ID}; domain=.${regionCookieDomain}`
  } else {
    document.cookie = `region=${REGION_ID}; domain=.${regionCookieDomain}`
  }
}

export default setupRegionCookie
