import React, { useCallback, useState, useRef, useMemo } from 'react'
import { Alert as _Alert, Button, Progress } from 'antd'
import styled, { css, withTheme } from 'styled-components'
import { rgba } from 'polished'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Link, generatePath } from 'react-router-dom'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'

import { connect, withRefreshSessionState } from '../../hocs'
import selectors from '../../state/selectors'
import { RiskReport } from './RiskReport'
import { ADVANCE_RISK_REPORT, GET_PROSPECT_RISK_REPORT } from '../../components/Queries/Companies'
import { RISK_REPORT_PROGRESS, RISK_REPORT_STEPS, RISK_REPORT_STEP } from '../../constants/company'
import routes from '../../constants/routes'
import { advanceRiskReport } from '../../helpers/riskReport'
import RecommendedSimulations from '../../components/uPhish/RecommendedSimulations'
import { RiskReportContainer, RiskReportBody as _RiskReportBody, RiskReportLayout, RiskReportCard, RiskReportCardBody } from '../../components/Reports/RiskReport'
import { isRiskReportAtStatus, processRiskReport } from '../../helpers/company'
import { renderToString } from '../../helpers'
import { getDurationString } from '../../helpers/duration'
import UpgradeProspectModal from '../../components/Modals/UpgradeProspectModal'
import { formatDateTime } from '../../helpers/datetime'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const Alert = styled(_Alert)`
  margin-bottom: 15px;
  padding: 8px 15px;
  text-align: center;

  ${({ banner }) => {
    if (banner) {
      return css`
  &.ant-alert-info {
    background-color: #d9ecf5;
  }
      `
    } else {
      return css`
  margin-left: 20px;
  width: calc(100% - 40px);
      `
    }
  }}

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`

const RiskReportBody = styled(_RiskReportBody)`
  @media (max-width: 768px) {
    padding-top: 20px;
  }
`

const RiskReportInfoLayout = styled(RiskReportLayout)`
  padding-top: 30px;
`

const ProspectCard = styled.div`
  display: flex;
`
const RiskReportNextCard = styled(RiskReportCard)`
  font-size: 14px;

  padding-bottom: ${({ hasRecommendSimulations, hasPromotedRecommendSimulations }) =>
    hasRecommendSimulations
      ? (hasPromotedRecommendSimulations ? 115 : 60)
      : 40
  }px;

  ${RiskReportCardBody} {
    padding: ${({ hasRecommendSimulations }) =>
      hasRecommendSimulations
        ? '60px 70px 0'
        : '60px 70px'
    };
    @media (max-width: 768px) {
      padding: ${({ hasRecommendSimulations }) =>
        hasRecommendSimulations
          ? '40px 40px 0'
          : '40px'
      };
    }
  }
`

const ProspectCardBody = styled.div`
  flex: 1;
  padding-right: 120px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }

  h1 {
    color: ${({ theme }) => theme.primary};
    font-size: 36px;
  }
`
const ProspectCardImage = styled.div`
  flex: 0 0 275px;
  position: relative;
  width: 275px;
  .risk-report-progress-tablet {
    display: none;
  }

  @media (max-width: 992px) {
    flex: 0 0 180px;
    width: 180px;

    .risk-report-progress-desktop {
      display: none;
    }
    .risk-report-progress-tablet {
      display: block;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const ProspectCardProgress = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 275px;
  position: absolute;
  top: -90px;
  width: 275px;

  @media (max-width: 992px) {
    height: 180px;
    width: 180px;
  }
`

const ProgressInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  margin-top: 7px;
  padding: 5px;
  position: absolute;
  width: 150px;

  h1, p {
    text-align: center;
  }
  h1 {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 0;
  }

  @media (max-width: 992px) {
    height: 80px;
    width: 120px;

    h1 {
      font-size: 20px;
    }
    p {
      font-size: 12px;
    }
  }
`

const _NextSteps = ({ className, children }) => (
  <div className={className}>
    <ul>
      {children}
    </ul>
  </div>
)
const NextSteps = styled(_NextSteps)`
  margin: 15px 0 30px;

  p, ul {
    color: #000000;
  }

  ul {
    list-style-position: inside;
    list-style-type: none;
    padding-left: 12px;

    li {
      text-indent: -5px;

      &:before {
        content: "-";
        left: -5px;
        position: relative;
        text-indent: -5px;
      }
    }
  }
`

const _RecommendedSimulationsContainer = ({ className, onLoad }) => (
  <div {...{ className }}>
    <div>
      <RecommendedSimulations onLoad={onLoad} />
    </div>
  </div>
)
const RecommendedSimulationsContainer = styled(_RecommendedSimulationsContainer)`
  ${({ hasRecommendSimulations, hasPromotedRecommendSimulations }) => {
    if (hasRecommendSimulations) {
      return css`
  margin-top: ${hasPromotedRecommendSimulations ? -99 : -44}px;
  position: relative;
  top: ${hasPromotedRecommendSimulations ? 99 : 44}px;
      `
    }
  }}
`

const SimulationInfo = styled.div`
  margin-top: 30px;
`

const WHITELIST = window.__USECURE_CONFIG__.REACT_APP_UPHISH_WHITELIST
const WHITELIST_COUNT = (WHITELIST || '').split(',').length

const RiskReportInfoCard = ({
  prospectStatus, companyId, companyName, riskReport, refetchQueries, refreshSessionState, theme, activeLearnerCount
}) => {
  const advanceAllowed = ['new', 'domain_complete', 'ubreach_requested', 'ubreach_complete', 'uphish_finished', 'uphish_complete', 'trial_requested'].includes(prospectStatus)

  const upgradeProspectModalRef = useRef(null)
  const openUpgradeProspect = useCallback(companyId => {
    if (upgradeProspectModalRef.current) {
      upgradeProspectModalRef.current.open(companyId)
    }
  }, [upgradeProspectModalRef])

  const [executeAdvanceRiskReport] = useMutation(ADVANCE_RISK_REPORT, { refetchQueries })
  const onAdvanceClick = useCallback(async () => {
    if (prospectStatus === 'trial_requested') {
      return openUpgradeProspect(companyId)
    }
    const updated = await advanceRiskReport(executeAdvanceRiskReport, companyId, companyName, prospectStatus, true)
    if (updated) {
      await refreshSessionState()
    }
  }, [executeAdvanceRiskReport, companyId, companyName, prospectStatus, refreshSessionState, openUpgradeProspect])

  const [hasRecommendSimulations, setHasRecommendedSimulations] = useState(false)
  const [hasPromotedRecommendSimulations, setHasPromotedRecommendedSimulations] = useState(false)
  const updateRecommendedSimState = useCallback((hasRecommendSimulations, hasPromotedRecommendSimulations) => {
    setHasRecommendedSimulations(hasRecommendSimulations)
    setHasPromotedRecommendedSimulations(hasPromotedRecommendSimulations)
  }, [])

  const advanceAction = I18n.t('riskReport.startNextStep')
  const advanceButton = advanceAllowed ? <Button type='primary' onClick={onAdvanceClick}>{advanceAction}</Button> : null

  const stepVars = { advanceAction }
  let simulationContent = null
  if (['new', 'domain_started', 'domain_complete'].includes(prospectStatus)) {
    stepVars.domain = renderToString(<strong>{_get(riskReport, 'company.domain')}</strong>)
  } else if (prospectStatus === 'ubreach_requested') {
    stepVars.usersLink = renderToString(<a className='usecure-manual-rr-link' href={routes.LEARNERS}>{I18n.t('common.users')}</a>)
  } else if (prospectStatus === 'ubreach_started') {
    // Breach scan estimate is 5 seconds per user padding by 10% in minutes
    let estimate = Math.round((activeLearnerCount * 5 * 1.1) / 60)
    // Minimum value is 10 minutes i.e. the frequency of the schedule task to check whether the breach scan has finished
    if (estimate < 10) {
      estimate = 10
    }
    estimate = Math.ceil(estimate / 5) * 5 // Round up to the nearest 5 minutes
    stepVars.estimate = getDurationString(estimate * 60 * 1000, { long: true })
  } else if (['uphish_requested', 'uphish_ready', 'uphish_started', 'uphish_finished'].includes(prospectStatus)) {
    const simulationId = _get(riskReport, 'simulation.id')
    const simulationName = _get(riskReport, 'simulation.name')
    const simulationStart = formatDateTime(_get(riskReport, 'simulation.startDate'))
    const simulationEnd = formatDateTime(_get(riskReport, 'simulation.endDate'))
    const simulationLink = simulationId ? generatePath(routes.UPHISH_SIM, { simulation_id: simulationId }) : null
    simulationContent = simulationId && isRiskReportAtStatus(prospectStatus, 'uphish_ready') ? (
      <SimulationInfo>
        <h5>{I18n.t('riskReport.uPhish.simInfo.title')}</h5>
        <p>{I18n.t('riskReport.uPhish.simInfo.name', { simulationName })}</p>
        <p>{I18n.t('riskReport.uPhish.simInfo.start', { simulationStart })}</p>
        <p>{I18n.t('riskReport.uPhish.simInfo.end', { simulationEnd })}</p>
        {
          simulationLink &&
            <Link to={simulationLink}>
              <Button>{I18n.t('riskReport.uPhish.simInfo.viewButton')}</Button>
            </Link>
        }
      </SimulationInfo>
    ) : null

    stepVars.simulationStart = simulationStart
    stepVars.simulationEnd = simulationEnd
    stepVars.createSimulationLink = renderToString(<a className='usecure-manual-rr-link' href={routes.UPHISH_CREATE_SIM}>{I18n.t('uPhish.common.createSimulation')}</a>)
    stepVars.simulationLink = renderToString(<a className='usecure-manual-rr-link' href={simulationLink}>{I18n.t('common.clickHere')}</a>)
    stepVars.whitelistIPs = WHITELIST || I18n.t('riskReport.whitelist')
    stepVars.whitelistIPNoun = I18n.t('riskReport.whitelistIPNoun', { count: WHITELIST_COUNT })
    stepVars.emailTemplateLibraryLink = renderToString(<a className='usecure-manual-rr-link' href={routes.UPHISH_EMAIL_TEMPLATES}>{I18n.t('uPhish.emailTemplateLibrary.emailTemplateLibrary')}</a>)
  } else if (prospectStatus === 'finished') {
    stepVars.teamLink = renderToString(<a className='usecure-manual-rr-link' href={routes.TEAM}>{I18n.t('nav.navBar.team')}</a>)
  }

  const steps = I18n.tWithProductNames(`riskReport.nextSteps.${prospectStatus}`, stepVars)
  const progress = !_isNil(RISK_REPORT_PROGRESS[prospectStatus]) ? RISK_REPORT_PROGRESS[prospectStatus] : 0
  const progressStep = I18n.t('riskReport.stepXofY', { step: !_isNil(RISK_REPORT_STEP[prospectStatus]) ? RISK_REPORT_STEP[prospectStatus] : 1, total: RISK_REPORT_STEPS })
  const progressProps = {
    type: 'circle',
    percent: progress,
    showInfo: progress === 100,
    strokeColor: progress < 100 ? theme.primary : null
  }

  // The last line of riskReport.nextSteps.uphish_requested refers to recommended simulations which may not be there depending on the config
  // This code removes the line
  if (prospectStatus === 'uphish_requested' && !hasRecommendSimulations) {
    steps.splice(-1, 1)
  }

  return (
    <RiskReportNextCard
      {...{ hasRecommendSimulations, hasPromotedRecommendSimulations }}
      backgroundColor={rgba(theme.primary, 0.34)}
    >
      <ProspectCard>
        <ProspectCardBody>
          <h1>{I18n.t('riskReport.nextStepsTitle')}</h1>
          {
            _isArray(steps)
              ? (
                <NextSteps>
                  {steps.map((step, index) => <li key={index} dangerouslySetInnerHTML={{ __html: step }} />)}
                </NextSteps>
              )
              : <p>{I18n.t('riskReport.nextSteps.error')}</p>
          }
          {advanceButton}
          {simulationContent}
        </ProspectCardBody>
        <ProspectCardImage>
          <ProspectCardProgress>
            <Progress className='risk-report-progress-desktop' width={200} {...progressProps} />
            <Progress className='risk-report-progress-tablet' width={150} {...progressProps} />
            {
              progress < 100 && (
                <ProgressInfo>
                  <h1>{progressStep}</h1>
                  <p>{I18n.t(`riskReport.status.${prospectStatus}`, { defaults: [{ message: prospectStatus }] })}</p>
                </ProgressInfo>
              )
            }
          </ProspectCardProgress>
        </ProspectCardImage>
      </ProspectCard>
      {prospectStatus === 'uphish_requested' && (
        <RecommendedSimulationsContainer
          onLoad={updateRecommendedSimState}
          {...{ hasRecommendSimulations, hasPromotedRecommendSimulations }}
        />
      )}
      <UpgradeProspectModal
        ref={upgradeProspectModalRef}
        preview
        {...{ refetchQueries, refreshSessionState }}
      />
    </RiskReportNextCard>
  )
}

const RiskReportPreview = ({ companyId, companyName, prospectStatus, activeLearnerCount, showDisclaimer = true, refreshSessionState, theme }) => {
  const { hasAllSessionPermissionsOriginally } = useHasSessionPermission()
  const isDisguisedAdmin = hasAllSessionPermissionsOriginally([permissions.COMPANY_LIST])

  const query = GET_PROSPECT_RISK_REPORT
  const variables = { companyId }
  const refetchQueries = [{ query, variables }]
  const { loading, error, data } = useQuery(GET_PROSPECT_RISK_REPORT, { variables: { companyId, preview: isDisguisedAdmin } })
  // CL - rawRiskReport.status may have a different value that the underlying. This allows the preview to show what the risk report will look after the next step is completed.
  // For example, the uphish_finished preview reflects what the report looks like at uphish_complete. This helps the MSP to decide if they wish to continue or run another campaign.
  // prospectStatus is the actual status of the report and should be used with any logic related to managing the report.
  const { riskReport: rawRiskReport } = data || {}
  const riskReport = useMemo(() => processRiskReport(rawRiskReport, { preview: true }), [rawRiskReport])

  showDisclaimer = showDisclaimer && isRiskReportAtStatus(prospectStatus, 'domain_complete')

  return (
    <RiskReportContainer>
      {
        showDisclaimer && !isDisguisedAdmin &&
          <Alert
            type='info' showIcon banner
            message={I18n.t('riskReport.previewBanner.prospect', { openRiskReport: I18n.t('riskReport.openRiskReport') })}
          />
      }
      <RiskReportBody>
        {
          isDisguisedAdmin && (
            <RiskReportInfoLayout>
              <RiskReportInfoCard {...{ prospectStatus, companyId, companyName, activeLearnerCount, riskReport, refetchQueries, refreshSessionState, theme }} />
              {
                showDisclaimer &&
                  <Alert
                    type='info' showIcon
                    message={I18n.t('riskReport.previewBanner.msp', { openRiskReport: I18n.t('riskReport.openRiskReport') })}
                  />
              }
            </RiskReportInfoLayout>
          )
        }
        <RiskReport {...{ loading, error, companyId, riskReport, refetchQueries }} readOnly embedded />
      </RiskReportBody>
    </RiskReportContainer>
  )
}

export default compose(
  connect(
    state => _pick(selectors.session.get(state), ['companyId', 'companyName', 'prospectStatus', 'activeLearnerCount'])
  ),
  withRefreshSessionState,
  withTheme
)(RiskReportPreview)
