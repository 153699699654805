import React from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { settingsConnector } from '../Settings/Settings'
import ChangeEndUserLanguage from '../../components/EndUserPortal/ChangeEndUserLanguage'
import { ContentWrap } from '../../components/common/Layout'

const SettingsContainer = styled.div`
  max-width: 500px;
`

const Settings = ({ session, settings = {}, updateSettings = () => {} }) => {
  return (
    <ContentWrap>
      <h1>{I18n.t('nav.navBar.settings')}</h1>
      <SettingsContainer>
        <ChangeEndUserLanguage />
      </SettingsContainer>
    </ContentWrap>
  )
}

export default settingsConnector(Settings)
