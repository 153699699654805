import React, { useCallback } from 'react'
import { message } from 'antd'
import I18n from 'i18n-js'

import { QUEUE_SYNC } from '../Queries/Learners'
import { MutationButton } from '../common'
import { renderListFragmentFromArray } from '../../helpers/renderFromArray'

const trOpt = { scope: 'sync.syncButton' }

const SyncButton = ({
  dummy, force, syncType, disabled,
  children = I18n.t('runManualSync', trOpt),
  errorMessage = I18n.t('errorMessage', trOpt),
  confirmTitle = I18n.t('confirmTitle', trOpt),
  confirmContent = I18n.t('confirmContent', trOpt),
  successMessage = I18n.t('successMessage', trOpt),
  ...buttonProps
}) => {
  const onResponse = useCallback(() => {
    message.success(successMessage)
  }, [successMessage])

  return (
    <MutationButton
      icon='sync'
      mutation={QUEUE_SYNC}
      variables={{ dummy, force, syncType }}
      {...{ onResponse, ...buttonProps, disabled }}
      errorMessage={errorMessage}
      confirm={!dummy}
      confirmTitle={confirmTitle}
      confirmContent={
        <ul style={{ marginTop: 5, paddingLeft: 20 }}>
          {renderListFragmentFromArray(confirmContent)}
        </ul>
      }
    >{children}
    </MutationButton>
  )
}

export default SyncButton
