import _pick from 'lodash/pick'

import { createAction } from '../../helpers/state'

export const types = {
  SETTINGS_UPDATE: 'SETTINGS_UPDATE',
  SETTINGS_REPLACE: 'SETTINGS_REPLACE',
  SETTINGS_RESET: 'SETTINGS_RESET'
}

const update = createAction(types.SETTINGS_UPDATE)
const replace = createAction(types.SETTINGS_REPLACE)

const TITLE_FAVIICON_IDS = ['appPageTitle', 'appFavicon16', 'appFavicon32', 'appIcon', 'useSingleFavicon', 'appFavicon180', 'safariTabIcon']
const THEME_IDS = ['appThemeLogo', 'appThemeNavColor', 'appThemePrimaryColor', 'appThemeSecondaryColor', 'reportDocumentUseAppThemeLogo', 'reportDocumentLogo', 'reportDocumentHeaderFontColor', 'reportDocumentHeaderBackgroundColor', ...TITLE_FAVIICON_IDS]

export const creators = {
  update,
  updateTheme: settings => {
    const { parentDefaultSettings } = settings || {}
    return update({
      ..._pick(settings, THEME_IDS),
      parentDefaultSettings: _pick(parentDefaultSettings, TITLE_FAVIICON_IDS)
    })
  },
  replace,
  reset: createAction(types.SETTINGS_RESET)
}

export default {
  types,
  creators
}
