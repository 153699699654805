import React from 'react'
import Viewer, { Worker, defaultLayout } from '@phuocng/react-pdf-viewer'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Empty } from 'antd'
import Bowser from 'bowser'
import I18n from 'i18n-js'
import mime from 'mime-types'
import _memoize from 'lodash/memoize'
import _pick from 'lodash/pick'

import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'

import { BREAKPOINT, MIN_BREAKPOINT } from '../../constants/style'
import { connect } from '../../hocs'
import { session } from '../../state/selectors'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const EMBED_POLICY_PDF = window.__USECURE_CONFIG__.REACT_APP_EMBED_POLICY_PDF === 'true'
const trOpt = { scope: 'uPolicy.policyPDFViewer' }

// react-pdf-viewer doesn't work in Firefox or IE11 at the time of writing
// It will only be used for browsers that pass the test below
const browser = Bowser.getParser(window.navigator.userAgent)
const POLICY_PDF_VIEWER_ALLOWED = browser.satisfies({
  desktop: { chrome: '>=45', safari: '>=11', edge: '>=79' },
  mobile: { chrome: '>=85', safari: '>=11.3', samsung_internet: '>=14' }
})
const workerUrl = `${window.location.origin}/libs/pdf.worker.min.js` // Load a local copy of PDF worker

const _ViewerContainer = ({ height, children, ...props }) => <div {...props}>{children}</div>
const ViewerContainer = styled(_ViewerContainer)`
  height: ${({ height = '750px' }) => height};

  .viewer-layout-container {
    grid-template-rows: 55px calc(100% - 55px);
  }

  .viewer-layout-toolbar {
    color: ${({ theme }) => theme.secondary};
    padding: 10px;

    button, input, optgroup, select, textarea {
      line-height: 1;
    }
  }
  .viewer-layout-toolbar {
    background-color: ${({ theme }) => theme.nav};
  }
  .viewer-sidebar-tabs {
    background-color: ${({ theme }) => theme.nav};
    display: none;
  }
  .viewer-popover-body {
    background-color: ${({ theme }) => theme.nav};
  }
  .viewer-icon {
    stroke: ${({ theme }) => theme.secondary};
  }
  .viewer-menu-item:hover {
    background-color: ${({ theme }) => theme.primary};
  }
  .viewer-button {
    line-height: 1;

    &:hover {
      background-color: ${({ theme }) => theme.primary};
    }
  }
  .viewer-toolbar-current-page-input, .viewer-search-popover-input {
    color: ${({ theme }) => theme.copy};
  }
  .viewer-zoom-popover-target-arrow {
    border-color: ${({ theme }) => rgba(theme.secondary, 0.6)} transparent transparent;
  }
  .viewer-popover-body-arrow {
    background-color: ${({ theme }) => theme.nav};
  }
  .viewer-tooltip-body {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};

    &-arrow {
      background-color: ${({ theme }) => theme.primary};
    }
  }

  .viewer-exit-fullscreen {
    bottom: 0px;
    right: 10px;
    z-index: 1000;
  }
  .viewer-exit-fullscreen-inner {
    background-color: transparent;

    .viewer-button {
      background-color: ${({ theme }) => theme.primary};
    }
  }

  @media (max-width: ${BREAKPOINT}) {
    .viewer-toolbar-desktop {
      display: none;
    }
  }
  @media (min-width: ${MIN_BREAKPOINT}) {
    .viewer-toolbar-mobile {
      display: none;
    }
  }

  &.viewer-toolbar-hide-print .view-toolbar-print {
    display: none;
  }
`

const renderToolbar = (toolbarSlot) => {
  return (
    <div className='viewer-toolbar'>
      <div className='viewer-toolbar-left'>
        <div className='viewer-toolbar-item viewer-toolbar-desktop'>
          {toolbarSlot.toggleSidebarButton}
        </div>
        <div className='viewer-toolbar-item'>
          {toolbarSlot.previousPageButton}
        </div>
        <div className='viewer-toolbar-item'>
          {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
        </div>
        <div className='viewer-toolbar-item'>
          {toolbarSlot.nextPageButton}
        </div>
      </div>
      <div className='viewer-toolbar-center'>
        <div className='viewer-toolbar-item'>
          {toolbarSlot.zoomOutButton}
        </div>
        <div className='viewer-toolbar-item'>
          {toolbarSlot.zoomPopover}
        </div>
        <div className='viewer-toolbar-item'>
          {toolbarSlot.zoomInButton}
        </div>
      </div>
      <div className='viewer-toolbar-right'>
        <div className='viewer-toolbar-item viewer-toolbar-desktop'>
          {toolbarSlot.rotateCounterclockwiseButton}
        </div>
        <div className='viewer-toolbar-item viewer-toolbar-desktop'>
          {toolbarSlot.rotateClockwiseButton}
        </div>
        <div className='viewer-toolbar-item viewer-toolbar-mobile'>
          {toolbarSlot.downloadButton}
        </div>
        <div className='viewer-toolbar-item'>
          {toolbarSlot.fullScreenButton}
        </div>
        <div className='viewer-toolbar-item viewer-toolbar-desktop view-toolbar-print'>
          {toolbarSlot.printButton}
        </div>
      </div>
    </div>
  )
}

const layout = (
  isSidebarOpened,
  container,
  main,
  toolbar,
  sidebar
) => {
  return defaultLayout(
    isSidebarOpened,
    container,
    main,
    toolbar(renderToolbar),
    sidebar
  )
}

// React PDF Viewer expects a full localization object in the format below.
// getReactPDFViewerLocalization provide a memoized copy of that object for a given locale
// It does have any fallback values and will not display any copy if the corresponding string is missing
// The base en translation was copied from https://github.com/react-pdf-viewer/react-pdf-viewer/blob/1f10ad457f1c8521a50d07f7b861e766e2ca63fc/src/localization/en_US.json
const viewerTrOpt = { scope: `${trOpt.scope}.reactPDFViewer` }
const getReactPDFViewerLocalization = _memoize(locale => ({
  askingPassword: {
    requirePasswordToOpen: I18n.t('askingPassword.requirePasswordToOpen', { ...viewerTrOpt, locale }),
    submit: I18n.t('common.submit', { locale })
  },
  attachment: {
    clickToDownload: I18n.t('attachment.attachment', { ...viewerTrOpt, locale }),
    noAttachment: I18n.t('attachment.noAttachment', { ...viewerTrOpt, locale })
  },
  bookmark: {
    noBookmark: I18n.t('bookmark.noBookmark', { ...viewerTrOpt, locale })
  },
  main: {
    dragDropFile: I18n.t('main.dragDropFile', { ...viewerTrOpt, locale })
  },
  printProgress: {
    cancel: I18n.t('common.cancel', { locale }),
    preparingDocument: I18n.t('printProgress.preparingDocument', { ...viewerTrOpt, locale })
  },
  property: {
    author: I18n.t('property.author', { ...viewerTrOpt, locale }),
    close: I18n.t('common.close', { locale }),
    creationDate: I18n.t('property.creationDate', { ...viewerTrOpt, locale }),
    creator: I18n.t('property.creator', { ...viewerTrOpt, locale }),
    fileName: I18n.t('property.fileName', { ...viewerTrOpt, locale }),
    fileSize: I18n.t('property.fileSize', { ...viewerTrOpt, locale }),
    keywords: I18n.t('property.keywords', { ...viewerTrOpt, locale }),
    modificationDate: I18n.t('property.modificationDate', { ...viewerTrOpt, locale }),
    pageCount: I18n.t('property.pageCount', { ...viewerTrOpt, locale }),
    pdfProducer: I18n.t('property.pdfProducer', { ...viewerTrOpt, locale }),
    pdfVersion: I18n.t('property.pdfVersion', { ...viewerTrOpt, locale }),
    subject: I18n.t('common.fields.subject', { locale }),
    title: I18n.t('property.title', { ...viewerTrOpt, locale })
  },
  search: {
    close: I18n.t('common.close', { locale }),
    enterToSearch: I18n.t('search.enterToSearch', { ...viewerTrOpt, locale }),
    matchCase: I18n.t('search.matchCase', { ...viewerTrOpt, locale }),
    nextMatch: I18n.t('search.nextMatch', { ...viewerTrOpt, locale }),
    previousMatch: I18n.t('search.previousMatch', { ...viewerTrOpt, locale }),
    wholeWords: I18n.t('search.wholeWords', { ...viewerTrOpt, locale })
  },
  sidebar: {
    attachment: I18n.t('sidebar.attachment', { ...viewerTrOpt, locale }),
    bookmark: I18n.t('sidebar.bookmark', { ...viewerTrOpt, locale }),
    thumbnail: I18n.t('sidebar.thumbnail', { ...viewerTrOpt, locale })
  },
  toolbar: {
    documentProperties: I18n.t('toolbar.documentProperties', { ...viewerTrOpt, locale }),
    download: I18n.t('toolbar.download', { ...viewerTrOpt, locale }),
    fullScreen: I18n.t('toolbar.fullScreen', { ...viewerTrOpt, locale }),
    goToFirstPage: I18n.t('toolbar.goToFirstPage', { ...viewerTrOpt, locale }),
    goToLastPage: I18n.t('toolbar.goToLastPage', { ...viewerTrOpt, locale }),
    handTool: I18n.t('toolbar.handTool', { ...viewerTrOpt, locale }),
    horizontalScrolling: I18n.t('toolbar.horizontalScrolling', { ...viewerTrOpt, locale }),
    moreActions: I18n.t('toolbar.moreActions', { ...viewerTrOpt, locale }),
    nextPage: I18n.t('toolbar.nextPage', { ...viewerTrOpt, locale }),
    openFile: I18n.t('toolbar.openFile', { ...viewerTrOpt, locale }),
    previousPage: I18n.t('toolbar.previousPage', { ...viewerTrOpt, locale }),
    print: I18n.t('toolbar.print', { ...viewerTrOpt, locale }),
    rotateBackward: I18n.t('toolbar.rotateBackward', { ...viewerTrOpt, locale }),
    rotateForward: I18n.t('toolbar.rotateForward', { ...viewerTrOpt, locale }),
    search: I18n.t('common.search', { locale }),
    textSelectionTool: I18n.t('toolbar.textSelectionTool', { ...viewerTrOpt, locale }),
    toggleSidebar: I18n.t('toolbar.toggleSidebar', { ...viewerTrOpt, locale }),
    verticalScrolling: I18n.t('toolbar.verticalScrolling', { ...viewerTrOpt, locale }),
    zoomIn: I18n.t('toolbar.zoomIn', { ...viewerTrOpt, locale }),
    zoomOut: I18n.t('toolbar.zoomOut', { ...viewerTrOpt, locale }),
    wrappedScrolling: I18n.t('toolbar.wrappedScrolling', { ...viewerTrOpt, locale })
  },
  wrongPassword: {
    submit: I18n.t('common.submit', { locale }),
    tryAgain: I18n.t('wrongPassword.tryAgain', { ...viewerTrOpt, locale })
  },
  zoom: {
    actualSize: I18n.t('zoom.actualSize', { ...viewerTrOpt, locale }),
    pageFit: I18n.t('zoom.pageFit', { ...viewerTrOpt, locale }),
    pageWidth: I18n.t('zoom.pageWidth', { ...viewerTrOpt, locale })
  }
}))

const PolicyPDFViewer = ({ className, file, height, locale = DEFAULT_LANGUAGE, useEmbed = false, noData = I18n.t('noPolicyAvailable', trOpt), hidePrint = false }) => {
  if (!file) {
    return <Empty description={noData} />
  }
  if (useEmbed || EMBED_POLICY_PDF || !POLICY_PDF_VIEWER_ALLOWED) {
    return (
      <ViewerContainer className={className} height={height}>
        <embed src={file} type={mime.types.pdf} width='100%' height='100%' />
      </ViewerContainer>
    )
  }

  // The container click handler stops react-pdf-viewer's buttons triggering a form submission
  const onContainerClick = e => {
    e.preventDefault()

    // Make sure external links will still work, and open in a new tab
    if (e.target.href && e.target.host !== window.location.host) {
      window.open(e.target.href, '_blank', 'noopener')
    }
  }

  return (
    <Worker workerUrl={workerUrl}>
      <ViewerContainer
        className={`${className || ''} ${hidePrint ? 'viewer-toolbar-hide-print' : ''}`.trim()}
        height={height} onClick={onContainerClick}
      >
        <Viewer fileUrl={file} layout={layout} localization={getReactPDFViewerLocalization(locale)} defaultScale={window.innerWidth <= 768 ? 'PageWidth' : null} />
      </ViewerContainer>
    </Worker>
  )
}

export default connect(
  state => _pick(session.get(state), ['locale'])
)(PolicyPDFViewer)
