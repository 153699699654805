import { useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import _isNull from 'lodash/isNull'
const useIsPrinting = ({ debugForceTrue = false } = {}) => {
  const [isPrinting, setIsPrinting] = useState(false)
  const { search } = useLocation()

  const getQueryParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    const beforePrint = () => { setIsPrinting(true) }
    const afterPrint = () => { setIsPrinting(false) }

    window.addEventListener('beforeprint', beforePrint)
    window.addEventListener('afterprint', afterPrint)

    return () => {
      window.removeEventListener('beforeprint', beforePrint)
      window.removeEventListener('afterprint', afterPrint)
    }
  }, [])

  useEffect(() => {
    if (!_isNull(getQueryParams.get('download'))) {
      // CC: because it takes the value from queryString, checking for true is false as its 'true' (string)
      setIsPrinting(getQueryParams.get('download') === 'true')
    }
  }, [getQueryParams])

  return debugForceTrue || isPrinting
}

export default useIsPrinting
