import I18n from 'i18n-js'

import { GET_COURSES } from '../components/Queries/Courses'
import { processListActions } from './listPages'

const trOpt = { scope: 'courses' }

export const getCoursesRefetchQueries = (hasSuperPermission = false) =>
  [{
    // Course Builder Refetch
    query: GET_COURSES,
    variables: {
      restrictToOwn: true,
      withCompany: hasSuperPermission,
      forUpdate: true
    }
  }, {
    // Course Library Refetch
    query: GET_COURSES,
    variables: {
      restrictToPlan: true,
      withCompany: true,
      inCourseLibrary: true
    }
  }]

export const getCourseActions = ({ course, omittedActions, disabledActions }) => {
  const actions = [
    { key: 'editCourse', label: I18n.t('courses.common.editCourse'), icon: 'edit' },
    { key: 'editSlides', label: I18n.t('editSlides', trOpt), icon: 'play-square' },
    { key: 'translateCourse', label: I18n.t('translateCourse', trOpt), icon: 'rocket' },
    { key: 'previewCourse', label: I18n.t('previewCourse', trOpt), icon: 'eye' },
    { key: 'duplicateCourse', label: I18n.t('duplicateCourse', trOpt), icon: 'copy' },
    { key: 'deleteCourse', label: I18n.t('deleteCourse', trOpt), icon: 'delete' }
  ]

  const courseOmittedActions = [...omittedActions]

  if (!course.updateAllowed) courseOmittedActions.push('editCourse', 'editSlides', 'translateCourse')

  if (!course.deleteAllowed) courseOmittedActions.push('deleteCourse')

  return processListActions({ actions, omittedActions: courseOmittedActions, disabledActions })
}
