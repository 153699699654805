import ms from 'ms'
import UsecureError from './UsecureError'

class QueueJobStatusHandler {
  constructor ({ client, mutation, mutationName, query, queryName, timeout = ms('2m'), delay = 1000 }) {
    this.client = client
    this.mutation = mutation
    this.mutationName = mutationName
    this.query = query
    this.queryName = queryName
    this.jobId = null
    this.timeout = timeout
    this.delay = delay

    this.executePromise = null
    this.startTime = null
  }

  hasTimedOut () {
    return this.startTime ? (Date.now() - this.startTime) > this.timeout : false
  }

  async createExecutePromise () {
    const self = this
    self.executePromise = {}
    return new Promise((resolve, reject) => {
      self.executePromise.resolve = resolve
      self.executePromise.reject = reject
    })
  }

  async startQueueJob (variables, cb) {
    const startTestResult = await this.client.mutate({
      mutation: this.mutation,
      variables
    })
    const { data: { [this.mutationName]: jobId } = {} } = startTestResult || {}

    this.jobId = jobId
    if (cb) cb(jobId)
  }

  async execute (variables, { onJobStart } = {}) {
    if (this.jobId) {
      // Encountering this error is bug in the code using this class as it does not support trying to perform multiple execute calls in parallel
      // This error will be ignored by showErrors and won't appear on screen so it doesn't need a localised message
      throw new UsecureError('QueueJobStatusHandler.execute - Execution already in progress', { level: 'silent' })
    }

    const self = this

    await this.startQueueJob(variables, onJobStart)

    // Wait for this class' promise to be instantiate
    // This promise can be resolved or rejected outside this method
    // This allows the cancel method to work
    this.startTime = Date.now()
    const starter = setInterval(function () {
      if (self.executePromise && self.executePromise.resolve && self.executePromise.reject) {
        clearInterval(starter)
        self.checkJob()
      }
    }, 50)

    return this.createExecutePromise()
  }

  async checkJob () {
    if (!this.executePromise) {
      return
    }
    if (this.hasTimedOut()) {
      this.complete({ status: 'jobTimeout' })
      return
    }
    try {
      const self = this
      const rawResult = await this.client.query({
        query: this.query,
        variables: { jobId: this.jobId },
        fetchPolicy: 'no-cache'
      })
      const { data: { [this.queryName]: result } = {} } = rawResult || {}
      if (result?.complete === true) {
        this.complete(result)
      } else {
        setTimeout(function () {
          self.checkJob()
        }, this.delay)
      }
    } catch (e) {
      this.fail(e)
    }
  }

  complete (result) {
    if (this.executePromise?.resolve) this.executePromise.resolve(result)
    this.reset()
  }

  cancel () {
    this.complete({ cancelled: true })
  }

  reset () {
    this.executePromise = null
    this.startTime = null
    this.jobId = null
  }

  fail (e) {
    if (this.executePromise?.reject) this.executePromise.reject(e)
    this.reset()
  }
}

export default QueueJobStatusHandler
