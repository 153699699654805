import React, { useMemo } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { BasicTooltip } from '@nivo/tooltip'
import _isNumber from 'lodash/isNumber'

import useIsPrinting from '../../hooks/useIsPrinting'
import { trendColors } from '../common/PerformanceReport'
import { generateYScaleMinMax } from '../../helpers/Graphs'

const AreaChart = ({
  data = [], // Data should be formated in a way that Nivo expects
  trend = 'neutral', // should match one of the keys in `trendColors`. Will be used to colour the chart
  isStatic = true, // should be true if this chart is being printed
  axisFormat = {}, // used to set the formating of axis labels (eg turn 0.32 into 32%). Only just for axisLeft here
  maxValue,
  showAllAxisLeftTickValues = false
}) => {
  const isPrinting = useIsPrinting()

  const chartProps = useMemo(() => {
    let max
    if (_isNumber(maxValue)) {
      max = maxValue
    } else {
      // Nivo charts by default overshoot the y axis in most instances.
      // generateYScaleMinMax will provide a max value we can use in yScale
      // to prevent this
      ({ max } = generateYScaleMinMax(data))
    }

    // Get the axisFormat.left, with a fallback if not present
    const { left: axisFormatLeft = v => v } = axisFormat

    return {
      theme: {
        background: '#ffffff',
        color: trendColors[trend]
      },
      animate: isPrinting ? false : isStatic,
      isInteractive: isPrinting ? false : isStatic,
      colors: [trendColors[trend]],
      lineWidth: 2,
      enableArea: true,
      margin: {
        top: 5,
        right: 50,
        bottom: 30,
        left: 50
      },
      yScale: { type: 'linear', stacked: false, max },
      pointSize: 10,
      dotBorderColor: '#ffffff',
      pointBorderWidth: 2,
      pointBorderColor: { from: 'seriesColor' },
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      pointColor: { theme: 'color' },
      pointLabel: 'y',
      pointLabelYOffset: -12,
      useMesh: true,
      axisLeft: {
        tickValues: showAllAxisLeftTickValues ? undefined : 5, // There is no guarentee you'll actually get 5 value here. It does mean that the yAxis labels will be more spaced out than the default.
        format: axisFormatLeft
      },
      tooltip: ({ point }) => (
        <BasicTooltip
          id={
            <span>{point.data.xFormatted}: {axisFormatLeft(point.data.yFormatted)}</span>
          }
          enableChip
          color={point.serieColor}
        />
      ),
      ...(isPrinting ? {
        width: 724 / 0.9,
        height: 190 / 0.9
      } : {})
    }
  }, [isPrinting, isStatic, trend, axisFormat, data, maxValue, showAllAxisLeftTickValues])

  return (
    <ResponsiveLine
      data={data}
      {...chartProps}
    />
  )
}

export default AreaChart
