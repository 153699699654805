import React, { useCallback, useState } from 'react'
import { Button, Alert, message, Form } from 'antd'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _set from 'lodash/set'
import _unset from 'lodash/unset'

import SettingsForm from '../SettingsForm'
import { InputWithReset, SectionDividerField, SenderAddressField } from '../common'
import { COPY_DEFAULT_TENANT_EMAIL_SETTING, UPDATE_COMPANY_EMAIL_SETTING, UPDATE_DEFAULT_TENANT_EMAIL_SETTING, SEND_EMAIL_THEME_TEST } from '../../Queries/Companies'
import { BCP_47_REGEX, DEFAULT_LANGUAGE, LANGUAGE_CODES } from '../../../constants/languages'
import { DEFAULT_EMAIL_SETTINGS, SETTING_DELETE_VALUE } from '../../../constants/settings'
import { connect } from '../../../hocs'
import { session, settings } from '../../../state/selectors'
import { MultiLocaleLabel } from '../LanguageSelector'
import { getLocaleCopyDeltaObject, injectRegionalValuesIntoLocaleObject } from '../../../helpers/locale'
import { doColorsMeetContrastRequirements } from '../../../helpers/doColorsMeetContrastRequirements'
import { MutationButton } from '../../common'
import EmailTestPreview from './EmailTestPreview'
import { permissions } from '../../../constants/permissions'
import { useHasSessionPermission } from '../../../hooks'

export const EmailSettingsConnect = connect(
  state => ({
    ..._pick(session.get(state), ['locale', 'companyLocale', 'accountType']),
    ..._pick(settings.get(state), ['enableRiskReportTenants', 'enableProspects'])
  })
)

const trScope = 'settings.emailSettingsForm'
const trOpt = { scope: trScope }

const CopyTheme = React.forwardRef(({ visible, disabled, emailId }, ref) => {
  if (!visible) return null

  return (
    <Form.Item>
      <Alert
        type='info'
        showIcon
        message={I18n.t('copyThemeSettings.mspAlertMessage', trOpt)}
        description={(
          <div>
            <p>{I18n.t('copyThemeSettings.mspDescriptionIntro', trOpt)}</p>
            <p>{I18n.t('copyThemeSettings.mspDescriptionSaveFirst', trOpt)}</p>
            <CopyDefaultTenantSettingsButton
              disabled={disabled}
              mutation={COPY_DEFAULT_TENANT_EMAIL_SETTING}
              confirm
              confirmOkText={I18n.t('copyThemeSettings.confirmOkText', trOpt)}
              confirmCancelText={I18n.t('copyThemeSettings.confirmCancelText', trOpt)}
              confirmTitle={I18n.t('copyThemeSettings.copyTheme', trOpt)}
              confirmContent={I18n.t('copyThemeSettings.copyThemeConfirm', trOpt)}
              variables={{
                emailId,
                settingIds: ['themeLogo', 'themePrimaryColor', 'themeSecondaryColor']
              }}
            >{I18n.t('copyThemeSettings.copyTheme', trOpt)}
            </CopyDefaultTenantSettingsButton>
          </div>
        )}
      />
    </Form.Item>
  )
})

const SendTestEmail = React.forwardRef(({ emailConfig, locale, visible, useDefaultTenantSettings }, ref) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  // The copy is 'Send Test Email' is taken from uPhish as an analogous process has already been translated
  // Support for keys specific to this screen is included should it be needed
  // trOpt is not used here as the scope and defaults options can't be used together hence the longhand key
  const [sendTest] = useMutation(SEND_EMAIL_THEME_TEST, { variables: { locale, emailConfig, useDefaultTenantSettings } })
  const [loading, setLoading] = useState(false)
  const onClick = useCallback(async () => {
    try {
      setLoading(true)
      await sendTest()
      message.success(I18n.t(`${trScope}.testEmailSent`, { defaults: [{ scope: 'uPhish.helpers.testEmailSent' }] }))
    } catch (e) {
      message.error(I18n.t(`${trScope}.testEmailError`, { defaults: [{ scope: 'uPhish.sendTestEmail.errorMessage' }] }))
    } finally {
      setLoading(false)
    }
  }, [sendTest])
  return (
    visible && hasAllSessionPermissions([permissions.SETTINGS_EMAIL_TEST])
      ? <Button type='primary' icon='mail' onClick={onClick} loading={loading}>{I18n.t(`${trScope}.sendTestEmail`, { defaults: [{ scope: 'uPhish.common.sendTestEmail' }] })}</Button>
      : null
  )
})

const ResetTheme = React.forwardRef(({ visible, onClick = () => { }, disabled }, ref) => (
  visible
    ? <Button type='primary' onClick={onClick} icon='undo' style={{ marginLeft: 5 }} disabled={disabled}>{I18n.t(`${trScope}.resetTheme`, { defaults: [{ scope: 'settings.theme.resetTheme' }] })}</Button>
    : null
))

const CopyDefaultTenantSettingsButton = styled(MutationButton)`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`

class EmailSettingsForm extends SettingsForm {
  constructor (props) {
    super(props)

    this.defaultTenantSettingMutation = UPDATE_DEFAULT_TENANT_EMAIL_SETTING
    this.defaultTenantSettingsMutation = null
    this.companySettingMutation = UPDATE_COMPANY_EMAIL_SETTING
    this.companySettingsMutation = null
    this.showSentTestButton = false
    this.showTheme = true
    this.showUseDefaultThemeSwitch = true
    this.subjectFieldIds = []
    this.subjectFields = []
    this.usingWhiteTheme = false
    this.includeFormLocale = true

    this.requiredUpdatePermissions = [permissions.SETTINGS_EMAIL_UPDATE]
    this.matchAllUpdatePermissions = false

    this.state = {
      senderEmailAddress: null,
      themeLogo: null,
      themePrimaryColor: null,
      themeSecondaryColor: null,
      prevThemeLogo: null,
      prevThemePrimaryColor: null,
      prevThemeSecondaryColor: null,
      omitEmailHeader: false,
      useDefaultTheme: false,
      updatedMultiLocaleValues: {},
      designChanged: false
    }

    this.getFieldResetValueMultiLocale = this.getFieldResetValueMultiLocale.bind(this)
    this.getThemePrimaryColorLabel = this.getThemePrimaryColorLabel.bind(this)
    this.isThemeLogoVisible = this.isThemeLogoVisible.bind(this)
    this.isThemeVisible = this.isThemeVisible.bind(this)
    this.resetToDefaultTheme = this.resetToDefaultTheme.bind(this)
    this.isThemeColorContrastValid = this.isThemeColorContrastValid.bind(this)
    this.isThemeColorBackgroundContrastValid = this.isThemeColorBackgroundContrastValid.bind(this)
  }

  get isDefault () {
    return this.settingId === 'default'
  }

  resetToDefaultTheme () {
    const form = this.form.current
    if (form) {
      form.replaceValues({
        themeLogo: this.getThemeResetValue('themeLogo'),
        themePrimaryColor: this.getThemeResetValue('themePrimaryColor'),
        themeSecondaryColor: this.getThemeResetValue('themeSecondaryColor'),
        omitEmailHeader: this.getFieldValue('omitEmailHeader', { loadFromEmailDefault: true, loadFromSettings: false, loadFromMSP: true }) === true
      })
    }
  }

  getRefreshedLocaleFieldValue (id) {
    return _get(this.state.updatedMultiLocaleValues, [id, this.state.formLocale]) || this.getFieldDefaultValueMultiLocale(id)
  }

  refreshMultiLocaleFields () {
    const form = this.form.current
    if (form) {
      form.replaceValues(
        this.multiLocaleFieldIds.reduce((acc, id) => ({
          ...acc,
          [id]: this.getRefreshedLocaleFieldValue(id)
        }), {})
      )
    }
  }

  getThemePrimaryColorLabel ({ omitEmailHeader }) {
    return I18n.t(omitEmailHeader || this.usingWhiteTheme ? 'themePrimaryColorNoHeader' : 'themePrimaryColor', trOpt)
  }

  isThemeLogoVisible (values = {}) {
    return this.isThemeVisible(values) && !values.omitEmailHeader
  }

  isThemeVisible ({ useDefaultTheme } = {}) {
    return this.showTheme && this.showUseDefaultThemeSwitch ? !useDefaultTheme : this.showTheme
  }

  getFieldDefaultValue (fieldId, opt) {
    return this.getFieldValue(fieldId, { ...(opt || {}), loadFromSettings: true })
  }

  getThemeResetValue (fieldId) {
    return this.getThemeFieldDefaultValue(fieldId, { loadFromSettings: false, loadFromMSP: true })
  }

  getThemeFieldDefaultValue (fieldId, { loadFromSettings = true, loadFromMSP = false } = {}) {
    return this.getFieldValue(fieldId, { isLocaleObject: true, singleLanguage: true, loadFromEmailDefault: true, loadFromSettings, loadFromMSP })
  }

  getFieldDefaultValueSingleLocale (fieldId) {
    return this.getFieldDefaultValue(fieldId, { isLocaleObject: true, singleLanguage: true })
  }

  getFieldDefaultValueMultiLocale (fieldId, opt) {
    return this.getFieldDefaultValue(fieldId, { ...(opt || {}), isLocaleObject: true, singleLanguage: false })
  }

  getFieldResetValueMultiLocale (fieldId) {
    return this.getFieldValue(fieldId, { loadFromSettings: false, loadFromMSP: true, isLocaleObject: true, singleLanguage: false })
  }

  getLocaleFieldValue (fieldId, locale) {
    return this.getFieldDefaultValueMultiLocale(fieldId, { locale })
  }

  getFieldValue (fieldId, opt) {
    if (fieldId === 'senderEmailAddress') {
      return {
        prefix: this.getFieldDefaultValue('senderEmailAddressPrefix', opt),
        subdomain: this.getFieldDefaultValue('senderEmailAddressSubdomain', opt)
      }
    }

    const { loadFromSettings = false, loadFromEmailDefault = false, isLocaleObject = false, singleLanguage = false, locale: optLocale, loadFromMSP = false } = opt || {}
    const { formLocale } = this.state
    const { defaultTenant = false, settings = {} } = this.props
    const { parentDefaultSettings } = settings || {}
    const searchPath = []
    let baseLocaleSearchPath
    const useDefaultEmailValue = loadFromEmailDefault && !this.isDefault
    const locale = optLocale || formLocale

    switch (fieldId) {
      case 'senderName':
        searchPath.push('sender', 'name')
        break
      case 'senderEmailAddressPrefix':
        searchPath.push('sender', 'prefix')
        break
      case 'senderEmailAddressSubdomain':
        searchPath.push('sender', 'subdomain')
        break
      default:
        searchPath.push(fieldId)
        break
    }

    if (isLocaleObject) {
      if (!singleLanguage) {
        // Use base locale value if form locale is regional variant with no dedicated value
        const baseLocale = BCP_47_REGEX.test(locale) ? locale.substring(0, 2) : null
        if (baseLocale) {
          baseLocaleSearchPath = [...searchPath, baseLocale]
        }
      }
      searchPath.push(singleLanguage ? DEFAULT_LANGUAGE : locale)
    }

    // CL - I have rewritten this to incorporate base locale translations into the fallback chain but I'll be honest this logic is nuts
    // When this is rebuilt we need to decide what the expected values should be and avoid Swiss army knife methods like this that mask the logic for multiple use cases.
    return [
      // Current Value
      (loadFromSettings ? _get(settings, ['emailSettings', this.settingId, ...searchPath]) : null),
      // Current Value for base locale
      (loadFromSettings && baseLocaleSearchPath ? _get(settings, ['emailSettings', this.settingId, ...baseLocaleSearchPath]) : null),
      // Current default email setting for this company for fallback use
      (useDefaultEmailValue ? _get(settings, ['emailSettings', 'default', ...searchPath]) : null),
      // Current base locale default email setting for this company for fallback use
      (useDefaultEmailValue && baseLocaleSearchPath ? _get(settings, ['emailSettings', 'default', ...baseLocaleSearchPath]) : null),
      // Current value from MSP's Default Customer Setting
      (loadFromMSP && !defaultTenant && parentDefaultSettings ? _get(parentDefaultSettings, ['emailSettings', this.settingId, ...searchPath]) : null),
      // Current value from MSP's Default Customer Setting for base locale
      (loadFromMSP && !defaultTenant && parentDefaultSettings && baseLocaleSearchPath ? _get(parentDefaultSettings, ['emailSettings', this.settingId, ...baseLocaleSearchPath]) : null),
      // Default email settings value from MSP's Default customer settings
      (loadFromMSP && loadFromEmailDefault && this.isDefault && !defaultTenant && parentDefaultSettings ? _get(parentDefaultSettings, ['emailSettings', 'default', ...searchPath]) : null),
      // Default email settings value from MSP's Default customer settings for base locale
      (loadFromMSP && loadFromEmailDefault && this.isDefault && !defaultTenant && parentDefaultSettings && baseLocaleSearchPath ? _get(parentDefaultSettings, ['emailSettings', 'default', ...baseLocaleSearchPath]) : null),
      // Default email settting for this setting
      _get(DEFAULT_EMAIL_SETTINGS, [this.settingId, ...searchPath]),
      // Default base locale email settting for this setting
      (baseLocaleSearchPath ? _get(DEFAULT_EMAIL_SETTINGS, [this.settingId, ...baseLocaleSearchPath]) : null),
      // Default email setting for fallback use
      (useDefaultEmailValue ? _get(DEFAULT_EMAIL_SETTINGS, ['default', ...searchPath]) : null),
      // Default base locale email setting for fallback use
      (useDefaultEmailValue && baseLocaleSearchPath ? _get(DEFAULT_EMAIL_SETTINGS, ['default', ...baseLocaleSearchPath]) : null)
    ].find(value => _isBoolean(value) || (_isString(value) && !_isEmpty(value)))
  }

  isThemeColorContrastValid (themePrimaryColor, themeSecondaryColor) {
    return doColorsMeetContrastRequirements(themePrimaryColor, themeSecondaryColor)
  }

  isThemeColorBackgroundContrastValid (themeColour) {
    return doColorsMeetContrastRequirements(themeColour, '#ffffff')
  }

  generateSubjectFields (fields, labelTrOpt) {
    fields.forEach(field => {
      let id
      let labelKey
      let extra
      if (_isObject(field)) {
        id = field.id
        labelKey = field.label
        extra = field.extra
      } else if (_isString(field)) {
        id = field
      }

      if (id) {
        this.subjectFieldIds.push(id)
        this.subjectFields.push(this.createSubjectFieldConfig({ id, labelKey, labelTrOpt, extra }))
      }
    })
  }

  createSubjectFieldConfig ({ id, labelKey, labelTrOpt, extra }) {
    return {
      id: id,
      type: 'text',
      label: I18n.t(labelKey || id, labelTrOpt),
      required: true,
      component: InputWithReset,
      extra
    }
  }

  get multiLocaleFieldIds () {
    return ['senderName', ...this.subjectFieldIds]
  }

  async componentDidMount () {
    const { settings = {} } = this.props
    let useDefaultTheme = _get(settings, ['emailSettings', this.settingId, 'useDefaultTheme'])
    if (!_isBoolean(useDefaultTheme)) {
      useDefaultTheme = this.showUseDefaultThemeSwitch
    }
    const omitEmailHeader = _get(settings, ['emailSettings', this.settingId, 'omitEmailHeader'])
    await this.updateState({
      useDefaultTheme,
      omitEmailHeader
    })
    await super.componentDidMount()
  }

  get formValues () {
    const { settings = {} } = this.props
    const themeLogo = this.getThemeFieldDefaultValue('themeLogo')
    const themePrimaryColor = this.getThemeFieldDefaultValue('themePrimaryColor')
    const themeSecondaryColor = this.getThemeFieldDefaultValue('themeSecondaryColor')
    let useDefaultTheme = _get(settings, ['emailSettings', this.settingId, 'useDefaultTheme'])
    if (!_isBoolean(useDefaultTheme)) {
      useDefaultTheme = this.showUseDefaultThemeSwitch
    }
    const omitEmailHeader = _get(settings, ['emailSettings', this.settingId, 'omitEmailHeader'])

    return {
      senderName: this.getFieldDefaultValueMultiLocale('senderName'),
      senderEmailAddress: this.getFieldDefaultValueSingleLocale('senderEmailAddress'),
      ...this.subjectFieldIds.reduce((acc, id) => ({
        ...acc,
        [id]: this.getFieldDefaultValueMultiLocale(id)
      }), {}),
      themeLogo,
      themePrimaryColor,
      themeSecondaryColor,
      omitEmailHeader,
      useDefaultTheme
    }
  }

  get fields () {
    const { formLocale, designChanged } = this.state
    const { formValues } = this
    const { senderName, senderEmailAddress, themeLogo, themePrimaryColor, themeSecondaryColor, omitEmailHeader, useDefaultTheme } = formValues
    const appDomain = _get(this.props.settings, ['appDomain', DEFAULT_LANGUAGE]) || 'usecure.io'

    const hasCompanyUpdatePermissions = this.hasAllSessionPermissions([permissions.COMPANY_UPDATE])

    return [{
      id: 'senderName',
      type: 'text',
      component: InputWithReset,
      label: <MultiLocaleLabel label={I18n.t('senderName', trOpt)} />,
      required: true,
      defaultValue: senderName,
      defaultSetting: this.getFieldResetValueMultiLocale('senderName')
    }, {
      id: 'senderEmailAddress',
      type: 'custom',
      component: SenderAddressField,
      defaultValue: senderEmailAddress,
      appDomain,
      required: true
    },
    ...this.subjectFields.map(field => ({
      ...field,
      label: <MultiLocaleLabel label={field.label} />,
      defaultValue: formValues[field.id],
      defaultSetting: this.getFieldResetValueMultiLocale(field.id)
    })),
    {
      id: 'useDefaultTheme',
      type: 'switch',
      label: I18n.t('useDefaultTheme', trOpt),
      defaultValue: useDefaultTheme,
      visible: this.showTheme && this.showUseDefaultThemeSwitch
    },
    {
      id: 'themeLogo',
      type: 'image',
      label: I18n.t('themeLogo', trOpt),
      defaultValue: themeLogo,
      required: true,
      visible: this.isThemeLogoVisible
    },
    {
      id: 'themePrimaryColor',
      type: 'colour',
      label: this.getThemePrimaryColorLabel,
      defaultValue: themePrimaryColor,
      required: true,
      visible: this.isThemeVisible,
      validate: (field, value, errors, opt, values) => {
        if (!(
          this.isThemeColorBackgroundContrastValid(value) &&
          this.isThemeColorContrastValid(value, values.themeSecondaryColor)
        )) {
          errors.push(I18n.t('contrastError', trOpt))
        }
      }
    }, {
      id: 'themeSecondaryColor',
      type: 'colour',
      label: I18n.t('themeSecondaryColor', trOpt),
      defaultValue: themeSecondaryColor,
      required: true,
      visible: this.isThemeVisible,
      validate: (field, value, errors, opt, values) => {
        if (!this.isThemeColorContrastValid(values.themePrimaryColor, value)) {
          errors.push(I18n.t('contrastError', trOpt))
        }
      }
    }, {
      id: 'copyTheme',
      type: 'custom',
      component: CopyTheme,
      visible: Boolean(this.props.defaultTenant && hasCompanyUpdatePermissions),
      disabled: designChanged,
      emailId: this.settingId
    }, {
      id: 'omitEmailHeader',
      type: 'switch',
      label: I18n.t('omitEmailHeader', trOpt),
      defaultValue: omitEmailHeader,
      visible: this.isThemeVisible
    }, {
      id: 'previewDivider',
      component: SectionDividerField,
      visible: this.isThemeVisible,
      title: I18n.t('common.preview'),
      description: I18n.t('preview.description', trOpt)
    }, {
      id: 'preview',
      type: 'custom',
      component: EmailTestPreview,
      visible: this.isThemeVisible,
      ...this.previewProps
    }, {
      id: 'sendEmailTest',
      type: 'custom',
      component: SendTestEmail,
      visible: this.showSentTestButton,
      locale: formLocale,
      emailConfig: this.emailConfig,
      useDefaultTenantSettings: this.props.defaultTenant
    }, {
      id: 'reset',
      type: 'custom',
      component: ResetTheme,
      onClick: this.resetToDefaultTheme,
      visible: this.isThemeVisible,
      disabled: this.isUpdateAllowed
    }]
  }

  get initialValues () {
    // Initial values represents what company.settings(.defaultTenantSettings)[this.settingId] would look like without the delta saving approach applied in onSubmit
    const settingsValues = _cloneDeep(_get(this.props.settings, ['emailSettings', this.settingId]) ?? {})
    if (settingsValues.sender?.name) {
      settingsValues.sender.name = injectRegionalValuesIntoLocaleObject(settingsValues.sender.name)
    }
    this.subjectFieldIds.forEach(fieldId => {
      if (settingsValues[fieldId]) {
        settingsValues[fieldId] = injectRegionalValuesIntoLocaleObject(settingsValues[fieldId])
      }
    })

    const initialValues = _merge(
      _cloneDeep(_get(DEFAULT_EMAIL_SETTINGS, [this.settingId]) || {}), // cloning this as lodash merge mutates the destination object
      settingsValues
    )
    _set(initialValues, 'sender.name', _pick(_get(initialValues, 'sender.name') || {}, LANGUAGE_CODES))
    this.subjectFieldIds.forEach(fieldId => {
      initialValues[fieldId] = _pick(initialValues[fieldId], LANGUAGE_CODES)
    })

    // Remove useDefaultTheme if the field is not visible
    if (!(this.showTheme && this.showUseDefaultThemeSwitch)) {
      delete initialValues.useDefaultTheme
    }

    return initialValues
  }

  get emailConfig () {
    const {
      senderEmailAddress: senderEmailAddressUpdt,
      themeLogo: themeLogoUpdt,
      themePrimaryColor: themePrimaryColorUpdt,
      themeSecondaryColor: themeSecondaryColorUpdt,
      omitEmailHeader: omitEmailHeaderUpdt,
      useDefaultTheme: useDefaultThemeUpdt
    } = this.state
    const { senderEmailAddress, themeLogo, themePrimaryColor, themeSecondaryColor, omitEmailHeader, useDefaultTheme } = this.formValues
    const { prefix, subdomain } = senderEmailAddressUpdt || senderEmailAddress || {}
    return this.getMultiLocaleFieldDelta({
      emailId: this.settingId,
      sender: {
        name: this.createLocaleCopyObject('senderName'),
        prefix: {
          [DEFAULT_LANGUAGE]: prefix
        },
        subdomain: {
          [DEFAULT_LANGUAGE]: subdomain
        }
      },
      themeLogo: {
        [DEFAULT_LANGUAGE]: themeLogoUpdt || themeLogo
      },
      themePrimaryColor: {
        [DEFAULT_LANGUAGE]: themePrimaryColorUpdt || themePrimaryColor
      },
      themeSecondaryColor: {
        [DEFAULT_LANGUAGE]: themeSecondaryColorUpdt || themeSecondaryColor
      },
      omitEmailHeader: _isBoolean(omitEmailHeaderUpdt) ? omitEmailHeaderUpdt : (_isBoolean(omitEmailHeader) ? omitEmailHeader : false),
      useDefaultTheme: _isBoolean(useDefaultThemeUpdt) ? useDefaultThemeUpdt : (_isBoolean(useDefaultTheme) ? useDefaultTheme : false),
      subjectFieldIds: this.subjectFieldIds,
      ...this.subjectFieldIds.reduce((acc, id) => ({
        ...acc,
        [id]: this.createLocaleCopyObject(id)
      }), {})
    }, {
      useInitialValues: true
    })
  }

  get previewProps () {
    const {
      themeLogo: themeLogoUpdt,
      themePrimaryColor: themePrimaryColorUpdt,
      themeSecondaryColor: themeSecondaryColorUpdt,
      omitEmailHeader: omitEmailHeaderUpdt
    } = this.state
    const { themeLogo, themePrimaryColor, themeSecondaryColor, omitEmailHeader } = this.formValues
    return {
      themeLogo: themeLogoUpdt || themeLogo,
      themePrimaryColor: themePrimaryColorUpdt || themePrimaryColor,
      themeSecondaryColor: themeSecondaryColorUpdt || themeSecondaryColor,
      omitEmailHeader: _isBoolean(omitEmailHeaderUpdt) ? omitEmailHeaderUpdt : (_isBoolean(omitEmailHeader) ? omitEmailHeader : false)
    }
  }

  get mutationName () {
    return this.props.defaultTenant === true ? 'updateDefaultTenantEmailSetting' : 'updateCompanyEmailSetting'
  }

  get mutationFormProps () {
    return {
      ...super.mutationFormProps,
      disableSubmitIfInvalid: false
    }
  }

  createLocaleCopyObject (fieldId) {
    const { updatedMultiLocaleValues } = this.state
    return LANGUAGE_CODES.reduce((acc, locale) => ({
      ...acc,
      [locale]: _get(updatedMultiLocaleValues, [fieldId, locale]) || this.getLocaleFieldValue(fieldId, locale)
    }), {})
  }

  mutateValues (values) {
    const { senderEmailAddress: { prefix, subdomain } = {}, themeLogo, themePrimaryColor, themeSecondaryColor, useDefaultTheme, omitEmailHeader } = values
    let emailSetting = {
      sender: {
        name: this.createLocaleCopyObject('senderName'),
        prefix: {
          [DEFAULT_LANGUAGE]: prefix
        }
      }
    }

    if (subdomain) {
      emailSetting.sender.subdomain = {
        [DEFAULT_LANGUAGE]: subdomain
      }
    }

    this.subjectFieldIds.forEach(id => {
      emailSetting[id] = this.createLocaleCopyObject(id)
    })

    if (this.showTheme) {
      let addTheme = true
      if (this.showUseDefaultThemeSwitch) {
        emailSetting.useDefaultTheme = useDefaultTheme
        addTheme = !useDefaultTheme
      }

      if (addTheme) {
        emailSetting.omitEmailHeader = omitEmailHeader
        if (!omitEmailHeader) {
          emailSetting.themeLogo = {
            [DEFAULT_LANGUAGE]: themeLogo
          }
        }
        emailSetting = {
          ...emailSetting,
          themePrimaryColor: {
            [DEFAULT_LANGUAGE]: themePrimaryColor
          },
          themeSecondaryColor: {
            [DEFAULT_LANGUAGE]: themeSecondaryColor
          }
        }
      } else {
        emailSetting = {
          ...emailSetting,
          omitEmailHeader: SETTING_DELETE_VALUE,
          themeLogo: SETTING_DELETE_VALUE,
          themePrimaryColor: SETTING_DELETE_VALUE,
          themeSecondaryColor: SETTING_DELETE_VALUE
        }
      }
    }

    return emailSetting
  }

  onSubmit (values, errors, variables) {
    // Replace multi locale setting objects with either:
    // - an object representing the delta from the system defaults
    // A deletion value as there is no delta and there's no need to store the value in the form
    // variables is the output of MutationForm variables getter i.e. the data passed to the mutation therein.
    // CL - Doing this in mutateValues proved a massive headache, changing the mutation variables here was simpler.
    variables.setting = this.getMultiLocaleFieldDelta(variables.setting, { applyDeleteValue: true })
  }

  getMultiLocaleFieldDelta (values, { applyDeleteValue = false, useInitialValues = false } = {}) {
    const defaultSetting = useInitialValues ? this.initialValues : DEFAULT_EMAIL_SETTINGS[this.settingId]
    this.multiLocaleFieldIds.forEach(id => {
      const isSenderName = id === 'senderName'
      let defaultValue
      let value
      if (isSenderName) {
        defaultValue = defaultSetting?.sender?.name
        value = values.sender.name
      } else {
        defaultValue = defaultSetting?.[id]
        value = values[id]
      }

      if (defaultValue) {
        value = getLocaleCopyDeltaObject(value, defaultValue)
      }
      if (_isEmpty(value) && applyDeleteValue) {
        // No changes for default, apply delete value which tells the settings update mutation to remove the setting
        if (isSenderName) {
          values.sender.name = SETTING_DELETE_VALUE
        } else {
          values[id] = SETTING_DELETE_VALUE
        }
      } else if (_isEmpty(value)) {
        // No changes from default, delete item from object
        if (isSenderName) {
          delete values.sender.name
        } else {
          delete values[id]
        }
      } else {
        // Changes from default, replace item with delta object
        if (isSenderName) {
          values.sender.name = value
        } else {
          values[id] = value
        }
      }
    })
    return values
  }

  onSuccess (result) {
    super.onSuccess(result)
    this.setState({
      senderEmailAddress: null,
      themeLogo: null,
      themePrimaryColor: null,
      themeSecondaryColor: null,
      prevThemeLogo: null,
      prevThemePrimaryColor: null,
      prevThemeSecondaryColor: null,
      updatedMultiLocaleValues: {},
      designChanged: false
    })
  }

  onFormLocaleChange () {
    this.refreshMultiLocaleFields()
  }

  hasChanged () {
    const { initialValues, isUpdateAllowed } = this

    // If the user can't update the settings then we don't need to check for changes
    if (!isUpdateAllowed) return false

    // Remove deleted values to avoid false positive
    const currentValues = Object.entries(this.form.current?.variables.setting ?? {}).reduce((acc, [id, value]) => {
      if (value !== SETTING_DELETE_VALUE) {
        acc[id] = value
      }
      return acc
    }, {})
    // The default subdomain is an empty string but the mutateValues only includes the subdomain if it's populated
    // This is replicated in initialValues using the code below
    // This code will need to be amended or removed if we add support for multilingual subdomains in future
    if (!_get(currentValues, ['sender', 'subdomain', DEFAULT_LANGUAGE])) {
      _unset(initialValues, 'sender.subdomain')
    }
    const hasChanged = !_isEqual(initialValues, currentValues)
    return hasChanged
  }

  onChange (id, value) {
    super.onChange(id, value)
    if (['themeLogo', 'themePrimaryColor', 'themeSecondaryColor', 'senderEmailAddress', 'omitEmailHeader'].includes(id)) {
      this.setState({ [id]: value })
    } else if (id === 'useDefaultTheme' && value === true) {
      const { themeLogo, themePrimaryColor, themeSecondaryColor } = this.state
      this.setState({
        themeLogo: this.getThemeResetValue('themeLogo'),
        themePrimaryColor: this.getThemeResetValue('themePrimaryColor'),
        themeSecondaryColor: this.getThemeResetValue('themeSecondaryColor'),
        prevThemeLogo: themeLogo,
        prevThemePrimaryColor: themePrimaryColor,
        prevThemeSecondaryColor: themeSecondaryColor,
        useDefaultTheme: true
      })
    } else if (id === 'useDefaultTheme' && value !== true) {
      const { prevThemeLogo, prevThemePrimaryColor, prevThemeSecondaryColor } = this.state
      this.setState({
        themeLogo: prevThemeLogo || this.getThemeFieldDefaultValue('themeLogo'),
        themePrimaryColor: prevThemePrimaryColor || this.getThemeFieldDefaultValue('themePrimaryColor'),
        themeSecondaryColor: prevThemeSecondaryColor || this.getThemeFieldDefaultValue('themeSecondaryColor'),
        prevThemeLogo: null,
        prevThemePrimaryColor: null,
        prevThemeSecondaryColor: null,
        useDefaultTheme: false
      })
    }

    if (this.multiLocaleFieldIds.includes(id)) {
      const { updatedMultiLocaleValues, formLocale } = this.state
      this.setState({
        updatedMultiLocaleValues: {
          ...updatedMultiLocaleValues,
          [id]: {
            ...(updatedMultiLocaleValues[id] || {}),
            [formLocale]: value
          }
        }
      })
    }

    if (['themeLogo', 'themePrimaryColor', 'themeSecondaryColor'].includes(id)) {
      this.setState({
        designChanged: true
      })
    }
  }

  async componentDidUpdate (prevProps) {
    const form = this.form.current
    if (!form) {
      return
    }

    const { defaultTenant } = this.props
    const { defaultTenant: prevDefaultTenant } = prevProps
    const setting = _get(this.props, `settings.emailSettings.${this.settingId}`)
    const prevSetting = _get(prevProps, `settings.emailSettings.${this.settingId}`)
    // Replace form values if either updates have come in via a change to the settings global state e.g. a subscription
    // Or the user has gone from the partner settings variant of this page to platform one or vice versa
    if (defaultTenant !== prevDefaultTenant || !_isEqual(setting, prevSetting)) {
      await form.setInitialValues(this.formValues)
    }
  }
}

export default EmailSettingsForm
