/**
 * Since we are still using styled-components: 4.2.0 the useTheme hook isn't
 * available until v5. For now, this custom hook implementation will be fine until
 * we find time to migrate to v5.
 */

import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const useTheme = () => {
  const themeContext = useContext(ThemeContext)
  return themeContext
}

export default useTheme
