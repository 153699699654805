import React from 'react'

const MutationFormErrors = ({ visible = false, errors = [] }) => {
  if (!(visible && errors.length > 0)) {
    return null
  }

  return (
    <>
      {
        errors.map((error, index) => <div key={index} className='ant-form-explain'>{error}</div>)
      }
    </>
  )
}

export default MutationFormErrors
