/* global localStorage */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Tabs, Tag } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { Link, generatePath } from 'react-router-dom'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'
import { NetworkStatus } from 'apollo-client'

import { GET_SIMULATION } from '../../components/Queries/uPhish'
import ViewSimulationResults from './ViewSimulationResults'
import ViewSimulationUsers from './ViewSimulationUsers'
import ViewSimulationDetails from './ViewSimulationDetails'
import { LoadingBlock, ErrorAlerts, ExcludeInactiveToggle } from '../../components/common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import routes from '../../constants/routes'
import { QUERY_POLL_INTERVAL } from '../../constants/apolloClient'
import { formatDateTime } from '../../helpers/datetime'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uPhish.viewSimulation' }

const { TabPane } = Tabs

const ViewSimulationCard = styled(Card)`
  height: 100%;

  &> .ant-card-body {
    height: 100%;
  }

  .view-sim__header {
    display: flex;
    justify-content: space-between;

    .ant-tag {
      align-self: flex-start;
      font-size: 18px;
      margin-left: 5px;
      padding: 5px 7px 0px;
    }

    .view-sim__buttons {
      .ant-btn {
        margin-left: 5px;
      }
      text-align: right;
    }
  }

  .view-sim__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-tabs, .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: calc(100% - 60px);
    overflow: auto;
    padding: 12px 12px 0;
  }
`

const ViewSimulation = ({ id, simulation, persist, userId, companyId, loading, refetch = () => {}, showPhishAlert = false, setPollingEnabled }) => {
  const { name, startDate, autoPhish, status } = simulation

  const [tabKey, setTabKey] = useState('details')

  const { hasAllSessionPermissions } = useHasSessionPermission()

  const onTabChange = useCallback(tabKey => setTabKey(tabKey), [setTabKey])

  const storageId = useMemo(() =>
    (!loading && persist && userId && companyId) ? `viewSimulation|${companyId}|${userId}` : null,
  [persist, userId, companyId, loading])
  useEffect(() => {
    if (!storageId) {
      return
    }

    try {
      const storeString = localStorage.getItem(storageId)
      if (storeString) {
        const store = JSON.parse(storeString)
        Object.keys(store).forEach(key => {
          const value = store[key]
          switch (key) {
            case 'tabKey':
              setTabKey(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    if (storageId) {
      let store = {}
      try {
        const storeString = localStorage.getItem(storageId)
        if (storeString) {
          store = JSON.parse(storeString)
        }
        store = { ...store, tabKey }
        localStorage.setItem(storageId, JSON.stringify(store))
      } catch (e) {}
    }
  }, [storageId, tabKey])

  const handleRefreshClick = useCallback(() => refetch(), [refetch])

  return (
    <ViewSimulationCard>
      <div className='view-sim__body'>
        <div className='view-sim__header'>
          <div>
            {
              name
                ? <h1>{name} ({formatDateTime(startDate)}){autoPhish && <Tag color='blue' key={id}>{I18n.t('auto', trOpt)}</Tag>}</h1>
                : <h1>{I18n.t('common.loading')}</h1>
            }
          </div>
          <div>
            <div className='view-sim__buttons'>
              {
                status === 'pending' &&
                  <Link to={generatePath(routes.UPHISH_EDIT_SIM, { simulation_id: id || 'simulation' })}>
                    <Button icon='edit' ghost type='primary' disabled={loading || !id}>{I18n.t('common.edit')}</Button>
                  </Link>
              }
              <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
            </div>
            <div style={{ textAlign: 'right', marginTop: 10 }}><ExcludeInactiveToggle /></div>
          </div>
        </div>
        <Tabs activeKey={tabKey} onChange={onTabChange}>
          <TabPane tab={I18n.t('common.details')} key='details'>
            <ViewSimulationDetails {...{ id, simulation }} />
          </TabPane>
          {hasAllSessionPermissions([permissions.SIMULATION_RESULT_LIST]) && (
            <TabPane tab={I18n.t('common.recipients')} key='recipients'>
              <ViewSimulationUsers {...{ id, simulation, showPhishAlert, setPollingEnabled }} />
            </TabPane>
          )}
          {hasAllSessionPermissions([permissions.SIMULATION_REPORT]) && (
            <TabPane tab={I18n.t('results', trOpt)} key='results'>
              <ViewSimulationResults {...{ id, simulation, showPhishAlert }} />
            </TabPane>
          )}
        </Tabs>
      </div>
    </ViewSimulationCard>
  )
}

const ViewSimulationQuery = ({ id, modal = false, phishAlertEnabled, phishAlert, excludeInactiveUsersInReports, ...props }) => {
  const [error, updateError] = useState(null)
  const resetError = useCallback(() => updateError(null), [])
  // Delay rendering of content until modal is open - avoids squashed graphs
  const [ready, updateReady] = useState(!modal)
  useEffect(() => {
    if (modal) {
      setTimeout(() => updateReady(true), 500)
    }
  }, [modal, updateReady])

  const [pollingEnabled, setPollingEnabled] = useState(true)
  const {
    loading: queryLoading, refetch, networkStatus,
    data: { simulation = {} } = {}
  } = useQuery(GET_SIMULATION, {
    variables: { id, excludeInactiveUsers: excludeInactiveUsersInReports },
    onError: updateError,
    onCompleted: resetError,
    notifyOnNetworkStatusChange: true,
    pollInterval: pollingEnabled ? QUERY_POLL_INTERVAL : 0
  })
  const loading = queryLoading && networkStatus !== NetworkStatus.poll

  return (
    <>
      <LoadingBlock loading={loading || !ready} fullScreen={!modal} />
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('uPhish.common.errors.thisSimulationCouldNotBeLoaded')} />
          : (
            <ViewSimulation
              loading={loading || !ready}
              {...props}
              {...{ id, simulation, refetch, setPollingEnabled }}
              showPhishAlert={phishAlertEnabled === true && phishAlert?.enabled === true}
            />
          )
      }
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['userId', 'companyId']),
    ..._pick(selectors.settings.get(state), ['phishAlertEnabled', 'phishAlert']),
    ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
  })
)(ViewSimulationQuery)
