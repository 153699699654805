import { WebSocketLink } from 'apollo-link-ws'
import { getTerminatingLink } from './common'
import { API_HOST, USE_SSL } from '../constants/environment'
import { getSessionToken } from '../helpers/session'

const wsLink = new WebSocketLink({
  uri: `${(USE_SSL ? 'wss' : 'ws')}://${API_HOST}/graphql`,
  options: {
    reconnect: true,
    lazy: true, // Allows the token to be loaded after login
    timeout: 26000, // 26s to differ from express timeout of 25s
    reconnectionAttempts: 5,
    connectionParams: () => {
      const params = {}
      const token = getSessionToken()
      if (token) {
        params['x-token'] = token
      }
      params['accept-language'] = window.navigator.userLanguage || window.navigator.language

      return params
    }
  }
})

export default getTerminatingLink({ wsLink })
