import React, { useMemo } from 'react'
import { Tag } from 'antd'
import { COURSE_DIFFICULTY } from '../../constants/courses'

export const DifficultyTag = ({ difficulty, gapAnalysisLabel, customLabel }) => {
  const { colour, label = COURSE_DIFFICULTY[difficulty] } = useMemo(() => {
    switch (difficulty) {
      case 1 :
        return { label: gapAnalysisLabel || COURSE_DIFFICULTY[difficulty] }
      case 2:
        return { colour: 'green' }
      case 3:
        return { colour: 'volcano' }
      case 4:
        return { colour: 'red' }
      default:
        return { label: customLabel || COURSE_DIFFICULTY[difficulty] }
    }
  }, [difficulty, customLabel, gapAnalysisLabel])
  return (
    <Tag color={colour}>{label}</Tag>
  )
}
