import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Alert as _Alert, Button } from 'antd'
import { compose } from 'recompose'
import styled, { css, withTheme } from 'styled-components'
import I18n from 'i18n-js'
import queryString from 'query-string'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isString from 'lodash/isString'
import _size from 'lodash/size'

import { LoadingBlock, ErrorAlerts, PrintableWrap } from '../../components/common'
import { GET_PROSPECT_RISK_REPORT, REQUEST_NEXT_RISK_REPORT_STEP } from '../../components/Queries/Companies'
import { withProvider, withLoadingBuffer, connect, withAppTitleAndIcons } from '../../hocs'
import { isRiskReportAtStatus, processRiskReport } from '../../helpers/company'
import {
  RiskReportContainer, RiskReportBody, RiskReportLayout, RiskReportCard, RiskReportCardBody,
  RiskReportDomainScanCard, RiskReportNoBreachesCard, RiskReportPhishInteractions, RiskScore, RiskScoreMini,
  LockedOverlay, LockedCardOverlay, getSectionStatus, RiskReportBreachCategories, RiskReportTimeToBreach,
  RiskReportSectionHeader, RiskReportPrintSectionHeader, RiskReportHeader,
  RiskReportPrintCover, RiskReportTextCard, RiskReportHeaderBody, RiskReportHeaderLogo, RiskReportHeaderInfo, RiskReportHeaderTitle
} from '../../components/Reports/RiskReport'
import RiskReportInfoModal from '../../components/Modals/RiskReportInfoModal'
import { getBrowserLocale } from '../../helpers/locale'
import { creators as sessionActions } from '../../state/actions/session'
import { creators as settingsActions } from '../../state/actions/settings'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import useIsPrinting from '../../hooks/useIsPrinting'
import DownloadReportModal from '../../components/Modals/DownloadReportModal'
import { GET_RISK_REPORT_DOWNLOAD_TOKEN } from '../../components/Queries/Reports'
import { UBREACH_V2_ENABLED } from '../../constants/environment'
import { captureSentryError } from '../../helpers/sentry'

const Alert = styled(_Alert)`
  margin-bottom: 15px;
  padding: 8px 15px;
  text-align: center;

  ${({ banner }) => {
    if (banner) {
      return css`
  &.ant-alert-info {
    background-color: #d9ecf5;
  }
      `
    } else {
      return css`
  margin-left: 20px;
  width: calc(100% - 40px);
      `
    }
  }}

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`

const _RiskReportTextCardSubSection = ({ className, trScope, type, level, useHTML, tokens }) => {
  const { title, body } = getSectionContentByTypeAndLevel(trScope, type, level, { useHTML, tokens })
  return (
    <div {...{ className }}>
      {useHTML ? <h2 dangerouslySetInnerHTML={{ __html: title }} /> : <h2>{title}</h2>}
      {body}
    </div>
  )
}
const RiskReportTextCardSubSection = styled(_RiskReportTextCardSubSection)`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 700;
  }

  @media print {
    h2 {
      line-height: 1;
    }
  }
`

const RiskReportScoreCard = styled(RiskReportCard)`
  ${RiskReportCardBody} {
    display: flex;
    position: relative; /* Fixes issue in Safari where overlay takes it's width from the card's container not the body despite being its child */

    @media (max-width: 969px) {
      justify-content: center;
    }
  }

  .risk-report-score-label {
    font-size: 12px;
    margin-bottom: 15px;
  }

  @media (max-width: 969px) {
    width: 100%;
  }
`

const LockedRiskScoreCardOverlay = styled(LockedCardOverlay)`
  padding: 50px;
`

const RiskReportInfo = ({ riskReport, theme, showScore, score, scoreLockMessage }) => {
  const isPrinting = useIsPrinting()
  const { status = 'new' } = riskReport || {}

  let type = 'new'
  if (isRiskReportAtStatus(status, 'uphish_complete')) {
    type = 'phishingCampaignComplete'
  } else if (isRiskReportAtStatus(status, 'ubreach_complete')) {
    type = 'breachScanComplete'
  } else if (isRiskReportAtStatus(status, 'domain_complete')) {
    type = 'domainScanComplete'
  }

  let { title: introTitle, body: introBody } = getSectionContentByType('riskReport.intro', type)

  // If printing remove 2nd line of intro body text as it refers to click
  if (isPrinting && type !== 'new') {
    introBody = introBody.slice(0, introBody.length - 1)
  }

  return (
    <RiskReportLayout>
      <RiskReportPrintSectionHeader pageBreakBefore='auto'>{I18n.t('riskReport.intro.printTitle')}</RiskReportPrintSectionHeader>
      <RiskReportTextCard span={8} {...{ theme }}>
        {introTitle && <h1>{introTitle}</h1>}
        {introBody}
      </RiskReportTextCard>
      <RiskReportScoreCard
        className='risk-report-score' span={4} padding='12px 12px 21px'
        locked={!showScore}
        lockedMessage={
          <LockedRiskScoreCardOverlay message={scoreLockMessage} />
        }
      >
        <RiskScore
          data={score}
          title={I18n.t('riskReport.riskScore.title')}
        />
      </RiskReportScoreCard>
    </RiskReportLayout>
  )
}

const NONE_COPY = '[[NONE]]'
const getSectionContent = (trScope, opt) => getSectionContentByTypeAndLevel(trScope, undefined, undefined, opt)
const getSectionContentByType = (trScope, type, opt) => getSectionContentByTypeAndLevel(trScope, type, undefined, opt)
const getSectionContentByLevel = (trScope, level, opt) => getSectionContentByTypeAndLevel(trScope, undefined, level, opt)
const getSectionContentByTypeAndLevel = (trScope, type, level, { useHTML, tokens = {} } = {}) => {
  const trOpt = { scope: `${trScope}${type ? `.${type}` : ''}`, ...tokens }

  let title = I18n.t('title', { ...trOpt, defaultValue: NONE_COPY })
  if (title === NONE_COPY) {
    title = null
  }

  const bodyKeys = ['body']
  if (level) {
    bodyKeys.push(level)
  }
  const bodyText = I18n.t(bodyKeys, { ...trOpt, defaultValue: I18n.t('common.somethingWentWrong') })
  let body = null
  if (_isArray(bodyText)) {
    body = bodyText.map((step, index) => useHTML ? <p key={index} dangerouslySetInnerHTML={{ __html: step }} /> : <p key={index}>{step}</p>)
  } else if (_isString(bodyText)) {
    body = useHTML ? <p dangerouslySetInnerHTML={{ __html: bodyText }} /> : <p>{bodyText}</p>
  }

  return {
    title,
    body
  }
}

const RiskImplications = ({ theme, type, level, span = 12 }) => {
  const { title, body } = getSectionContentByTypeAndLevel('riskReport.riskImplications', type, level)

  return (
    <RiskReportTextCard {...{ span, theme }}>
      {title && <h1>{title}</h1>}
      {body}
    </RiskReportTextCard>
  )
}

const RiskImplicationsSubSection = ({ type }) => <RiskReportTextCardSubSection trScope='riskReport.riskImplications.domainScan' {...{ type }} />

const DomainScanRiskImplications = ({ theme, span }) => {
  const { title } = getSectionContentByTypeAndLevel('riskReport.riskImplications', 'domainScan')
  return (
    <RiskReportTextCard {...{ span, theme }}>
      {title && <h1>{title}</h1>}
      <RiskImplicationsSubSection type='domainsAndServices' />
      <RiskImplicationsSubSection type='lookalikeDomains' />
      <RiskImplicationsSubSection type='applications' />
    </RiskReportTextCard>
  )
}

const RiskReportDomainScanCardContainer = styled(RiskReportLayout)`
  padding: 0 10px;

  ${RiskReportDomainScanCard} {
    padding: 0 10px 30px;
  }

  @media print {
    padding: 0;
    ${RiskReportDomainScanCard} {
      left: 20px;
      padding: 0 10px 20px;
      position: relative;
      width: calc(50% - 20px);

      ${RiskReportCardBody} {
        border: 1px #C4C4C4 solid;
      }
    }
  }
`

const RiskReportDomainScan = props => {
  const { riskReport, readOnly, theme, onMoreClick } = props
  const { visible } = getSectionStatus(riskReport, readOnly, null, null, 'domain_complete')

  if (!visible) {
    return null
  }

  const scope = 'riskReport.domainScan.title'
  const domainCount = _size(_get(riskReport, 'domainScan.domains'))
  const serviceCount = _size(_get(riskReport, 'domainScan.services'))
  const lookalikeCount = _size(_get(riskReport, 'domainScan.lookalikeDomains'))
  const appCount = _size(_get(riskReport, 'domainScan.applications'))

  return (
    <RiskReportLayout>
      <RiskReportSectionHeader>{I18n.t('riskReport.domainScan.sectionTitle')}</RiskReportSectionHeader>
      <RiskReportDomainScanCardContainer>
        <RiskReportDomainScanCard
          color={theme.defaultPrimary}
          count={domainCount}
          type='domainScanDomains'
          label={I18n.t('domains', { scope, count: domainCount })}
          {...{ onMoreClick }}
        />
        <RiskReportDomainScanCard
          color={theme.cta}
          count={serviceCount}
          type='domainScanServices'
          label={I18n.t('services', { scope, count: serviceCount })}
          {...{ onMoreClick }}
        />
        <RiskReportDomainScanCard
          color='#8606a6'
          count={lookalikeCount}
          type='domainScanLookalikes'
          label={I18n.t('lookalikeDomains', { scope, count: lookalikeCount })}
          {...{ onMoreClick }}
        />
        <RiskReportDomainScanCard
          color='#e41e2a'
          count={appCount}
          type='domainScanApps'
          label={I18n.t('applications', { scope, count: appCount })}
          {...{ onMoreClick }}
        />
      </RiskReportDomainScanCardContainer>
      <DomainScanRiskImplications {...{ theme }} />
    </RiskReportLayout>
  )
}

const RiskReportBreaches = props => {
  const { riskReport, readOnly, onMoreClick, theme } = props
  const { complete, visible } = getSectionStatus(riskReport, readOnly, 'domain_complete', 'ubreach_requested', 'ubreach_complete')

  if (!visible) {
    return null
  }

  const breached = _get(riskReport, 'uBreachStats.breached') || 0
  const empty = complete && breached === 0

  return (
    <RiskReportLayout>
      <RiskReportSectionHeader>{I18n.t('riskReport.uBreach.sectionTitle')}</RiskReportSectionHeader>
      {
        !complete &&
          <LockedOverlay
            {...props}
            message={I18n.t('riskReport.uBreach.lockedRequested')}
          />
      }
      {
        empty
          ? <RiskReportNoBreachesCard />
          : (
            <RiskReportBreachCategories
              uBreachStats={_get(riskReport, 'uBreachStats')}
              onMoreClick={onMoreClick}
              sample={!complete}
            />
          )
      }
      {complete && <RiskImplications type='breachScan' {...{ theme }} level={_get(riskReport, 'riskScore.breachRisklevel')} />}
    </RiskReportLayout>
  )
}

const RiskReportSimulation = props => {
  const { riskReport, readOnly, onMoreClick, theme } = props
  const { complete, visible } = getSectionStatus(riskReport, readOnly, 'ubreach_complete', 'uphish_requested', 'uphish_complete')

  if (!visible) {
    return null
  }

  const results = _get(riskReport, 'simulation.results') || []

  return (
    <RiskReportLayout>
      {
        !complete &&
          <LockedOverlay
            {...props}
            message={I18n.t('riskReport.uPhish.lockedRequested')}
          />
      }
      <RiskReportSectionHeader>{I18n.t('riskReport.uPhish.sectionTitle')}</RiskReportSectionHeader>
      <RiskReportPhishInteractions sample={!complete} {...{ results, onMoreClick }} />
      {complete && <RiskImplications type='phishingCampaign' {...{ theme }} level={_get(riskReport, 'riskScore.phishRisklevel')} />}
      <RiskReportSectionHeader>{I18n.t('riskReport.timeToBreach.sectionTitle')}</RiskReportSectionHeader>
      <RiskReportTimeToBreach sample={!complete} {...{ riskReport }} />
    </RiskReportLayout>
  )
}

const RiskReportView = ({ loading, error, sectionProps, companyName, children, showCompanyName = false }) => {
  const scroller = useRef(null)
  const isPrinting = useIsPrinting()
  const { riskReport, readOnly, embedded } = sectionProps || {}
  const { companyId, status = 'new', riskScore } = riskReport || {}

  // Scroll to top after printing
  useEffect(() => {
    if (!embedded && scroller.current && isPrinting) {
      scroller.current.scrollTop = 0
    }
  }, [isPrinting, scroller, embedded])

  const showScore = isRiskReportAtStatus(status, 'uphish_complete')
  let score = riskScore
  let scoreLockMessage
  if (!showScore) {
    score = { riskScore: 700 }

    let stepsUntilRiskScore = 3
    if (isRiskReportAtStatus(status, 'ubreach_complete')) {
      // 1 - ubreach_complete to uphish_finished
      stepsUntilRiskScore = 1
    } else if (isRiskReportAtStatus(status, 'domain_complete')) {
      // 2 - domain_complete to ubreach_started
      stepsUntilRiskScore = 2
    } else {
      // 3 - Before domain_complete - No one should see the report before this
      stepsUntilRiskScore = 3
    }
    scoreLockMessage = I18n.t('riskReport.riskScore.locked', { count: stepsUntilRiskScore })
  }

  const infoProps = {
    ...sectionProps,
    showScore,
    score,
    scoreLockMessage
  }

  const downloadReportModalRef = useRef(null)
  const openDownloadReportModal = useCallback(() => {
    if (downloadReportModalRef.current) {
      downloadReportModalRef.current.open({
        reportType: 'riskReport',
        companyId,
        name: I18n.t('reports.downloadReport.fileNames.riskReport', { companyName })
      })
    }
  }, [downloadReportModalRef, companyId, companyName])

  if (embedded) {
    return (
      <>
        <LoadingBlock loading={loading} />
        <ErrorAlerts {...{ error }} />
        {!loading && <RiskReportInfo {...infoProps} />}
        {children}
      </>
    )
  }

  return (
    <PrintableWrap>
      <LoadingBlock useDefaultTheme loading={loading} />
      <DownloadReportModal
        ref={downloadReportModalRef}
        getReportAccessTokenMutation={GET_RISK_REPORT_DOWNLOAD_TOKEN}
        getReportAccessTokenMutationName='riskReportDownloadToken'
      />
      <RiskReportPrintCover companyName={showCompanyName ? companyName : null} />
      <RiskReportContainer>
        <RiskReportHeader className='hideOnPrint'>
          <RiskReportHeaderBody>
            <RiskReportHeaderLogo loading={loading} />
            <RiskReportHeaderInfo>
              <RiskReportHeaderTitle>
                <span>{I18n.t('riskReport.title', { companyName: companyName ? ` - ${companyName}` : '' })}</span>
                <Button icon='download' size='small' onClick={openDownloadReportModal}>{I18n.t('common.download')}</Button>
              </RiskReportHeaderTitle>
              {showScore && !loading && <RiskScoreMini data={score} />}
            </RiskReportHeaderInfo>
          </RiskReportHeaderBody>
        </RiskReportHeader>
        <ErrorAlerts {...{ error }} />
        {!loading && !error && (
          <RiskReportBody ref={scroller}>
            {
              readOnly && !embedded && (
                <Alert
                  type='info' showIcon className='hideOnPrint'
                  message={I18n.t('riskReport.readOnlyBanner')}
                />
              )
            }
            {!loading && <RiskReportInfo {...infoProps} />}
            {children}
            {isPrinting && <div id='usecure-risk-report-safe' />}
          </RiskReportBody>
        )}
      </RiskReportContainer>
    </PrintableWrap>
  )
}

const ReportSummaryCard = styled(RiskReportTextCard)`
  ${RiskReportCardBody} {
    h1, h2 {
      color: ${({ theme }) => theme.evaluate};
    }
  }

  @media print {
    padding-bottom: 20px;
  }
`

const ReportSummary = ({ theme, level, span = 12 }) => {
  const { title, body } = getSectionContentByLevel('riskReport.reportSummary', level)

  return (
    <ReportSummaryCard {...{ span, theme }}>
      <h1>{title}</h1>
      {body}
    </ReportSummaryCard>
  )
}

const RemediationActionsCard = styled(RiskReportTextCard)`
  ${RiskReportCardBody} {
    h1, h2 {
      color: ${({ theme }) => theme.cta};
    }
  }
`
const RemediationActionsPrintCard = styled.div`
  padding: 15px 35px;

  h1, h2 {
    color: ${({ theme }) => theme.cta};
  }
  p {
    color: ${({ theme }) => theme.copy};
  }
`

const _RemediationActionsSection = props => <RiskReportTextCardSubSection trScope='riskReport.remediationActions' {...props} />
const RemediationActionsSection = styled(_RemediationActionsSection)`
  page-break-inside: avoid;

  @media print {
    margin-bottom: 0px;
    padding-top: ${({ type }) => type === 'intro' ? 0 : 5}px;
  }
`

const RemediationActions = ({ theme, span = 12 }) => {
  const isPrinting = useIsPrinting()
  const { title } = getSectionContent('riskReport.remediationActions')
  const body = (
    <>
      <h1>{title}</h1>
      <RemediationActionsSection
        type='intro'
        useHTML tokens={{
          coreElements: `<strong>${I18n.t('riskReport.remediationActions.intro.coreElements')}</strong>`
        }}
      />
      <RemediationActionsSection type='training' />
      <RemediationActionsSection type='policies' />
      <RemediationActionsSection type='phishing' />
      <RemediationActionsSection type='breachScan' />
    </>
  )

  if (isPrinting) {
    return (
      <RemediationActionsPrintCard>{body}</RemediationActionsPrintCard>
    )
  }

  return (
    <RemediationActionsCard {...{ span, theme }}>
      {body}
    </RemediationActionsCard>
  )
}

const RiskReportComplete = props => {
  const { riskReport, readOnly, theme } = props
  const { complete } = getSectionStatus(riskReport, readOnly, 'ubreach_complete', 'uphish_requested', 'uphish_complete')
  if (!complete) return null

  const sectionProps = { theme }
  return (
    <RiskReportLayout>
      <RiskReportSectionHeader pageBreakBefore='auto'>{I18n.t('riskReport.reportSummary.sectionTitle')}</RiskReportSectionHeader>
      <ReportSummary {...sectionProps} level={_get(riskReport, 'riskScore.level')} />
      <RiskReportSectionHeader>{I18n.t('riskReport.remediationActions.sectionTitle')}</RiskReportSectionHeader>
      <RemediationActions {...sectionProps} />
    </RiskReportLayout>
  )
}

export const RiskReport = withTheme(({ companyId, loading: reportLoading, error, riskReport, theme, refetchQueries, readOnly = false, embedded = false }) => {
  const [moveToNextStep] = useMutation(REQUEST_NEXT_RISK_REPORT_STEP, { variables: { companyId, returnBreachDataClassIds: UBREACH_V2_ENABLED }, refetchQueries })

  const [autoLoading, setAutoLoading] = useState(false)
  const autoRequestNextStep = useCallback(async () => {
    setAutoLoading(true)
    try {
      await moveToNextStep()
    } catch (e) {
      // This is a silent error for now as the prospect shouldn't know about this
      captureSentryError(e, { msg: 'Risk Report - Automatic Next Step Request - ERROR' })
    }
    setAutoLoading(false)
  }, [moveToNextStep])

  useEffect(() => {
    if (!(embedded || readOnly) && riskReport && ['domain_complete', 'ubreach_complete', 'uphish_complete'].includes(riskReport.status)) {
      autoRequestNextStep()
    }
  }, [riskReport, embedded, readOnly, autoRequestNextStep])

  const companyName = _get(riskReport, 'company.name')
  const infoRef = useRef(null)
  const onMoreClick = useCallback(type => {
    if (infoRef.current) {
      infoRef.current.open(type)
    }
  }, [infoRef])

  // Close info modal when printing
  const isPrinting = useIsPrinting()
  useEffect(() => {
    if (!embedded && isPrinting && infoRef.current) {
      infoRef.current.close()
    }
  }, [isPrinting, infoRef, embedded])

  const sectionProps = {
    readOnly,
    embedded,
    riskReport,
    onMoreClick,
    theme
  }

  return (
    <RiskReportView
      loading={reportLoading || autoLoading}
      showCompanyName={_get(riskReport, 'company.settings.reportDocumentShowCompanyName', true) === true}
      {...{ error, sectionProps, companyName }}
    >
      <RiskReportDomainScan {...sectionProps} />
      <RiskReportBreaches {...sectionProps} />
      <RiskReportSimulation {...sectionProps} />
      <RiskReportComplete {...sectionProps} />
      <RiskReportInfoModal ref={infoRef} {...{ riskReport }} />
    </RiskReportView>
  )
})

const RiskReportQuery = ({ match, setTheme, updateSession, ...props }) => {
  const companyId = _get(match, 'params.companyId')
  const query = GET_PROSPECT_RISK_REPORT
  const variables = { companyId, returnBreachDataClassIds: UBREACH_V2_ENABLED }
  const refetchQueries = [{ query, variables }]
  const { loading, error, data } = useQuery(GET_PROSPECT_RISK_REPORT, {
    variables,
    onError: error => {
      captureSentryError(error, { msg: 'Risk Report Load - ERROR' })
    }
  })
  const { riskReport: rawRiskReport } = data || {}
  const riskReport = useMemo(() => processRiskReport(rawRiskReport), [rawRiskReport])

  useEffect(() => {
    if (!riskReport) return
    setTheme({
      ...(_get(riskReport, 'company.settings') || {}),
      parentDefaultSettings: _get(riskReport, 'company.parentCompany.settings.defaultTenantSettings')
    })
    updateSession({
      mspName: _get(riskReport, 'company.parentCompany.name'),
      locale: getBrowserLocale(null) || _get(riskReport, 'company.locale') || DEFAULT_LANGUAGE
    })
  }, [riskReport, setTheme, updateSession])

  return (
    <RiskReport
      {...{ loading, error, companyId, riskReport, refetchQueries }}
      readOnly={_get(queryString.parse(window.location.search), 'readOnly') === 'true'}
      {...props}
    />
  )
}

export default compose(
  withLoadingBuffer,
  withProvider(),
  connect(
    undefined,
    dispatch => ({
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      updateSession: session => dispatch(sessionActions.noAuthUpdate(session))
    })
  ),
  withAppTitleAndIcons({ noAuth: true })
)(RiskReportQuery)
