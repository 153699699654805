import { createLocaleGetterObject } from '../helpers/locale'

const groupTypeScope = 'office365.common.groupTypes'

export const OFFICE_365_GROUP_TYPES = createLocaleGetterObject({
  all: 'common.all',
  office365: 'office365.common.office365',
  security: 'common.security',
  dynamic: `${groupTypeScope}.dynamic`,
  unknown: `${groupTypeScope}.unknown`
})
