import React, { Component, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Select, Tag, TreeSelect, Row, Col } from 'antd'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'

import { buildTree } from '../Learners/helpers'
import { GET_GROUP_TREE } from '../Queries/Groups'
import { GET_LEARNERS_AUTOCOMPLETE } from '../Queries/Learners'
import { ErrorAlerts } from '../common'
import { NO_GROUP_ID } from '../../constants/group'
import MutationFormErrors from './MutationFormErrors'

const { Option, OptGroup } = Select
const trOpt = { scope: 'mutationForm.mutationFormLearnerAndGroupSelect' }

const RemoveAllContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`

const SelectedTag = ({ type, id, label, remove = () => {} }) => {
  const onClose = useCallback(e => {
    e.preventDefault()
    remove(id, type)
  }, [id, type, remove])

  return (
    <Tag closable onClose={onClose}>{label}</Tag>
  )
}

const TagContainer = styled.div`
  margin-top: 20px;
  max-height: 300px;
  overflow: auto;
`

// This component is intended to recreate the 'Add or choose email address' from 'Create a new simulation' in v1
// Sadly Ant Design and by extension react-component's select component can't deliver this as:
// - its filter behaviour doesn't work with Opt group, this made an attempt to use its multiple mode difficult
// - It doesn't retain the search term used for filtering after selection
// As a result there is rather a lot of code in the component to get around those shortcomings.
// This is also why the values are displayed as Tag components rather than the typical multiple/multiSelect behaviour
// In future it would good to either create a custom component using a library to provide a menu like antd or split this into multiSelect fields
// This one is being left in place for now pending feedback on the Create Simulation UI due to excessive development time spent on it so far
class MutationFormLearnerAndGroupSelect extends Component {
  constructor (props) {
    super(props)

    this.state = {
      groupTreeData: this.getGroupTreeData(false),
      learnerOptions: this.getLearnerOptions(false)
    }

    this.handleLearnerSelect = this.handleLearnerSelect.bind(this)
    this.handleGroupSelect = this.handleGroupSelect.bind(this)
    this.handleRemoveAllClick = this.handleRemoveAllClick.bind(this)
    this.removeSelectedItem = this.removeSelectedItem.bind(this)
    this.reset = this.reset.bind(this)
  }

  getGroupTreeData (updateState = true) {
    const { groupTree = [], value: { groups: selectedGroups } } = this.props
    let groupTreeData = []
    if (selectedGroups.length > 0) {
      const filterChildren = group => {
        group.children = group.children
          .filter(({ key }) => this.findSelectedIndex('groups', key) === -1)
          .map(filterChildren)
        return group
      }
      groupTreeData = groupTree
        .filter(({ key }) => this.findSelectedIndex('groups', key) === -1)
        .reduce((tree, group) => {
          tree.push(filterChildren(group))
          return tree
        }, [])
    } else {
      groupTreeData = groupTree
    }

    if (updateState) {
      this.setState({ groupTreeData })
    }

    return groupTreeData
  }

  getLearnerOptions (updateState = true) {
    const { learners = [], value: { learners: selectedLearners }, learnersWithEmailOnly } = this.props
    let learnerOptions = []

    if (selectedLearners.length > 0) {
      learnerOptions = learners.filter(({ value }) => this.findSelectedIndex('learners', value) === -1)
    } else {
      learnerOptions = learners
    }
    if (learnersWithEmailOnly) {
      learnerOptions = learnerOptions.filter(l => l.email)
    }

    learnerOptions.sort((a, b) => a.label.localeCompare(b.label))

    if (updateState) {
      this.setState({ learnerOptions })
    }

    return learnerOptions
  }

  handleLearnerSelect (id, option) {
    if (!option) {
      return
    }

    const { value: selected } = this.props
    const label = option.props.children
    const index = selected.learners.findIndex(item => item.id === id)

    if (index === -1) {
      selected.learners.push({ id, label })
      this.props.onChange(this.props.id, selected)

      // Remove selected item from options
      this.getLearnerOptions()
    }
  }

  findSelectedIndex (type, id) {
    let index = -1
    const { value: selected } = this.props
    if (selected && selected[type]) {
      index = selected[type].findIndex(item => item.id === id)
    }

    return index
  }

  handleGroupSelect (id, node) {
    const { value: selected } = this.props
    const label = id === NO_GROUP_ID ? I18n.t('usersWithNoGroup', trOpt) : I18n.t('groupLabel', { ...trOpt, name: node.props.title })
    const index = selected.groups.findIndex(item => item.id === id)

    if (index === -1) {
      selected.groups.push({ id, label })
      this.props.onChange(this.props.id, selected)
      this.getGroupTreeData()
    }
  }

  renderOptionGroup ({ label, options, valuePrefix }) {
    if (options.length === 0) {
      return null
    }

    return (
      <OptGroup label={label}>
        {
          options.map(({ value, disabled, label }, index) => (
            <Option key={index} value={valuePrefix ? `${valuePrefix}__${value}` : value} disabled={disabled}>{label}</Option>
          ))
        }
      </OptGroup>
    )
  }

  removeSelectedItem (id, type) {
    const { value: selected = {}, disabled } = this.props
    if (disabled) {
      return
    }

    const index = selected[type] ? selected[type].findIndex(item => item.id === id) : -1
    if (index !== -1) {
      selected[type].splice(index, 1)
      this.props.onChange(this.props.id, selected)
      if (type === 'groups') {
        this.getGroupTreeData()
      } else if (type === 'learners') {
        this.getLearnerOptions()
      }
    }
  }

  reset () {
    this.setState({
      groupTreeData: this.getGroupTreeData(false),
      learnerOptions: this.getLearnerOptions(false)
    })
  }

  handleRemoveAllClick () {
    this.props.onChange(this.props.id, {
      groups: [],
      learners: []
    })
    this.setState({
      learnerOptions: this.props.learners,
      groupTreeData: this.props.groupTree
    })
  }

  renderTags (type, tags) {
    return (
      tags.map(({ id, label }) => (
        <SelectedTag key={id} remove={this.removeSelectedItem} {...{ type, id, label }} />
      ))
    )
  }

  componentDidUpdate (prevProps) {
    const { loading } = this.props
    const { loading: prevLoading } = prevProps

    if (prevLoading && !loading) {
      this.getGroupTreeData()
      this.getLearnerOptions()
    }
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, label, value: { groups: selectedGroups = [], learners: selectedLearners = [] }, removeLabel, loading, required, queryErrors, disabled, extra, errors = [] } = this.props
    const { learnerOptions, groupTreeData } = this.state
    const showErrors = errors.length > 0

    return (
      <Form.Item
        label={label} required={required} extra={extra}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        {queryErrors}
        <Row>
          <Col span={12}>
            <Select
              name={id}
              showSearch
              onSelect={this.handleLearnerSelect}
              defaultActiveFirstOption={false}
              placeholder={I18n.t('usersPlaceholder', trOpt)}
              disabled={loading || disabled}
              optionFilterProp='label'
              value={undefined}
              notFoundContent={<span>{I18n.t('noResults', trOpt)}</span>}
            >
              {
                learnerOptions.map(({ value, disabled, label }, index) => (
                  <Option key={index} value={value} disabled={disabled} label={label}>{label}</Option>
                ))
              }
            </Select>
          </Col>
          <Col span={12}>
            <TreeSelect
              name={id}
              treeData={groupTreeData}
              showSearch
              treeNodeFilterProp='title'
              treeDefaultExpandAll
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              onSelect={this.handleGroupSelect}
              placeholder={I18n.t('groupsPlaceholder', trOpt)}
              value={null}
              disabled={loading || disabled}
            />
          </Col>
        </Row>
        <div>
          <TagContainer>
            {this.renderTags('learners', selectedLearners)}
            {this.renderTags('groups', selectedGroups)}
          </TagContainer>
          {
            (selectedLearners.length > 0 || selectedGroups.length > 0)
              ? (
                <RemoveAllContainer>
                  <Button type='danger' disabled={loading || disabled} onClick={this.handleRemoveAllClick}>{removeLabel}</Button>
                </RemoveAllContainer>
              )
              : null
          }
        </div>
      </Form.Item>
    )
  }
}

const idArrayType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.string
  })
)

MutationFormLearnerAndGroupSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  value: PropTypes.shape({
    groups: idArrayType,
    learners: idArrayType
  }),
  onChange: PropTypes.func,
  learners: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  groupTree: PropTypes.arrayOf(PropTypes.object),
  removeLabel: PropTypes.string,
  loading: PropTypes.bool,
  learnersWithEmailOnly: PropTypes.bool
}

MutationFormLearnerAndGroupSelect.defaultProps = {
  id: '',
  label: null,
  required: false,
  linkField: null,
  linkFieldValue: null,
  value: {
    groups: [],
    learners: []
  },
  onChange: () => {},
  learners: [],
  groups: [],
  groupTree: [],
  get removeLabel () { return I18n.t('removeLabel', trOpt) },
  loading: false,
  learnersWithEmailOnly: false
}

const withLearnerAndGroupSelectQuery = Component => {
  const MutationFormLearnerAndGroupSelectQuery = ({ forwardedRef, ...props }) => {
    const [groupTreeError, setGroupTreeError] = useState(null)
    const [learnersError, setLearnersError] = useState(null)
    const resetGroupTreeError = useCallback(() => setGroupTreeError(null), [])
    const resetLearnersError = useCallback(() => setLearnersError(null), [])

    const { includeNoGroup = false } = props

    const { loading: groupsLoading, data: { groupTree: rawGroupTree = [] } = {} } = useQuery(
      GET_GROUP_TREE,
      {
        variables: { includeNoGroup },
        onError: setGroupTreeError,
        onCompleted: resetGroupTreeError
      }
    )
    const { loading: learnersLoading, data: { learners: rawLearners = [] } = {} } = useQuery(
      GET_LEARNERS_AUTOCOMPLETE,
      {
        onError: setLearnersError,
        onCompleted: resetLearnersError
      }
    )

    const groupTree = buildTree(rawGroupTree)
    const learners = rawLearners.map(({ id: value, name, email, learnerId }) => ({ value, label: `${name} <${email || learnerId}>`, email }))

    return (
      <>
        <Component
          ref={forwardedRef}
          loading={groupsLoading || learnersLoading}
          queryErrors={[
            <ErrorAlerts key='group-tree-error-alert' error={groupTreeError} defaultError={I18n.t('groupsError', trOpt)} />,
            <ErrorAlerts key='learner-error-alert' error={learnersError} defaultError={I18n.t('common.usersLoadError')} />
          ]}
          {...{ ...props, learners, groupTree }}
        />
      </>
    )
  }

  return React.forwardRef((props, ref) => (
    <MutationFormLearnerAndGroupSelectQuery {...props} forwardedRef={ref} />
  ))
}

export default withLearnerAndGroupSelectQuery(MutationFormLearnerAndGroupSelect)
