export const PRODUCT_NAMES = {
  uLearn: 'uLearn',
  uPhish: 'uPhish',
  uBreach: 'uBreach',
  uPolicy: 'uPolicy',
  uService: 'uService'
}

export const PRODUCT_NAME_IDS = [
  ...Object.keys(PRODUCT_NAMES),
  'uBreachPro'
]
