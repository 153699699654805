import React, { useCallback, useRef } from 'react'
import { message, Modal as _Modal } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import MutationForm from '../MutationForm/MutationForm'
import { invalidateLearnersAndLearnerDataQueryCache } from '../../helpers'
import { DEFAULT_LANGUAGE, LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { UPDATE_LEARNERS_LANGUAGE } from '../Queries/Learners'

const trOpt = { scope: 'modals.changeLanguageModal' }

const Modal = styled(_Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-title {
    margin-right: 15px;

    h1 {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
`

const ChangeLanguageModal = ({ learners = [], visible = false, setVisible = () => {}, refetchQueries, onClose = () => {} }) => {
  const learnerIds = learners.map(learner => learner.id)
  const form = useRef(null)
  const closeModal = useCallback(() => setVisible(false), [setVisible])

  const afterClose = useCallback(() => {
    setVisible(false)
    if (form && form.current) {
      form.current.resetFields()
    }
    onClose()
  }, [setVisible, onClose])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  const fields = [{
    id: 'locale',
    label: I18n.t('common.preferredLanguage'),
    defaultValue: DEFAULT_LANGUAGE,
    type: 'select',
    options: LANGUAGE_SELECT_OPTIONS,
    sortOptions: true
  }]

  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      onCancel={closeModal}
      afterClose={afterClose}
      title={<h1>{I18n.t('title', trOpt)}</h1>}
      width='600px'
    >
      <MutationForm
        mutation={UPDATE_LEARNERS_LANGUAGE}
        failureMessage={I18n.t('failedMessage', trOpt)}
        submitLabel={I18n.t('common.update')}
        variables={{ learnerIds }}
        disableSubmitIfInvalid={false}
        fields={fields}
        ref={form}
        onSuccess={onSuccess}
        refetchQueries={refetchQueries}
        update={invalidateLearnersAndLearnerDataQueryCache}
      />
    </Modal>
  )
}

export default ChangeLanguageModal
