import React, { Component, useMemo } from 'react'
import Html from 'slate-html-serializer'
import styled, { css } from 'styled-components'

export const emailContentStyle = css`
  line-height: 1.42;

  h1, h2, h3, h4, h5, h6, ul, li, p {
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }

  b, strong {
    font-weight: bold;
  }
`

const StyledMutationFormEmailBodyPreview = styled.div(emailContentStyle)

class EmailContent {
  constructor (content, data, targetBlank = false) {
    this.setContent(content)
    this.setData(data)
    this.setTargetBlank(targetBlank)

    this.html = new Html({
      rules: [
        {
          // Add a serializing function property to our rule...
          serialize (obj, children) {
            if (obj.object === 'block') {
              switch (obj.type) {
                case 'paragraph':
                  return <p>{children.length === 1 && Array.isArray(children[0]) && children[0].length === 1 && children[0][0] === '' ? <br /> : children}</p>
                case 'block-quote':
                case 'quote':
                  return <blockquote>{children}</blockquote>
                case 'bulleted-list':
                  return <ul>{children}</ul>
                case 'heading-one':
                  return <h1>{children}</h1>
                case 'heading-two':
                  return <h2>{children}</h2>
                case 'heading-three':
                  return <h3>{children}</h3>
                case 'heading-four':
                  return <h4>{children}</h4>
                case 'heading-five':
                  return <h5>{children}</h5>
                case 'heading-six':
                  return <h6>{children}</h6>
                case 'list-item':
                  return <li>{children}</li>
                case 'numbered-list':
                  return <ol>{children}</ol>
                case 'image':
                  return <img src={obj.data.src} alt='' />
                case 'align-left':
                case 'align-center':
                case 'align-right':
                case 'align-justify':
                  const textAlign = obj.type.replace('align-', '')
                  return <div style={{ textAlign }}>{children}</div>
                default:
                  break
              }
            }
          }
        },
        {
          serialize (obj, children) {
            if (obj.object === 'mark') {
              switch (obj.type) {
                case 'bold':
                  return <strong>{children}</strong>
                case 'italic':
                  return <em>{children}</em>
                case 'underlined':
                  return <u>{children}</u>
                case 'strikethrough':
                  return <span style={{ textDecoration: 'line-through' }}>{children}</span>
                case 'background':
                case 'color':
                  const { color = 'inherit' } = obj.data
                  const colorProp = obj.type === 'background' ? 'backgroundColor' : 'color'
                  return <span style={{ [colorProp]: color }}>{children}</span>
                default:
                  break
              }
            }
          }
        },
        {
          serialize (obj, children) {
            if (obj.object === 'inline') {
              switch (obj.type) {
                case 'link':
                  return <a href={obj.data.href} target={this.targetBlank ? '_blank' : null}>{children}</a>
                default:
                  break
              }
            }
          }
        }
      ]
    })
  }

  setContent (content) {
    this.content = content
  }

  setData (data = {}) {
    this.data = data = {
      firstName: 'Dev',
      lastName: 'User',
      ...data
    }
    this.data.fullName = this.data.fullName || `${this.data.firstName} ${this.data.lastName}`
  }

  setTargetBlank (targetBlank) {
    this.targetBlank = targetBlank
  }

  detokenise (renderedContent) {
    return [
      { id: 'firstName', regex: /%first_name%/g },
      { id: 'lastName', regex: /%last_name%/g },
      { id: 'fullName', regex: /%full_name%/g },
      { id: 'link' }
    ].reduce((content, { id, regex }) => {
      const value = this.data[id]
      if (id === 'link') {
        const matches = content.match(/(\[\[)(.*?)(\]\])/g)
        if (matches) {
          content = matches.reduce((content, match) => {
            const text = match.replace(new RegExp('[\\[,\\]]', 'g'), '')
            return content.replace(match, `<a href${value ? `="${value}"${this.targetBlank ? ' target="_blank"' : ''}` : ''}>${text}</a>`)
          }, content)
        }
      } else if (value) {
        content = content.replace(regex, value)
      }

      return content
    }, renderedContent)
  }

  render () {
    return this.detokenise(this.html.serialize(this.content))
  }
}

export const MutationFormEmailBodyPreview = ({ content, data = {}, openLinksInNewTab }) => {
  const __html = useMemo(() => {
    const renderer = new EmailContent(content, { ...data, link: openLinksInNewTab ? data.link : null }, openLinksInNewTab)
    return renderer.render()
  }, [content, data, openLinksInNewTab])
  return (
    <StyledMutationFormEmailBodyPreview dangerouslySetInnerHTML={{ __html }} />
  )
}

class PreviewEventHandler extends Component {
  constructor (props) {
    super(props)

    this.div = React.createRef()

    this.preventLinkClick = this.preventLinkClick.bind(this)
  }

  get element () {
    return this.div.current
  }

  componentDidMount () {
    if (this.element) {
      this.element.addEventListener('click', this.preventLinkClick)
    }
  }

  componentWillUnmount () {
    if (this.element) {
      this.element.removeEventListener('click', this.preventLinkClick)
    }
  }

  preventLinkClick (event) {
    if (event.target.tagName === 'A' && !this.props.openLinksInNewTab) {
      event.preventDefault()
    }
  }

  render () {
    return (
      <div ref={this.div}>
        <MutationFormEmailBodyPreview {...this.props} />
      </div>
    )
  }
}

export default PreviewEventHandler
