import React, { useMemo, useEffect, useState } from 'react'
import { Pie } from '@nivo/pie'

import useIsPrinting from '../../hooks/useIsPrinting'
import { BrandColours } from '../../helpers/Graphs'

const MIN_HEIGHT = 200
const MIN_CHART_SIZE = 150

const PieChart = ({
  data = [], // Data should be formated in a way that Nivo expects
  isStatic = true, // should be true if this chart is being printed
  colors = BrandColours,
  valueFormat,
  containerRef = null, // nesessary for calculating the size of the chart
  printScale = 1
}) => {
  const isPrinting = useIsPrinting()
  const [width, setWidth] = useState(700)
  const [height, setHeight] = useState(200)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver((event) => {
      const width = event[0].contentBoxSize[0].inlineSize || 700
      const height = event[0].contentBoxSize[0].blockSize * 0.8

      const adjustedMinHeight = MIN_HEIGHT * (isPrinting ? printScale : 1)

      setWidth(width)
      setHeight(height < adjustedMinHeight ? adjustedMinHeight : height)
    })

    if (containerRef) {
      resizeObserver.observe(containerRef.current)
    }
  }, [containerRef, isPrinting, printScale])

  const sizing = useMemo(() => {
    const legendWidth = 250
    const marginRight = (width - height) < legendWidth ? legendWidth : width - height
    const chartSize = width - marginRight

    return {
      margin: {
        top: 0,
        right: chartSize >= MIN_CHART_SIZE ? marginRight : 0,
        bottom: chartSize < MIN_CHART_SIZE ? 80 : 0,
        left: 0
      },
      legend: {
        translateX: chartSize >= MIN_CHART_SIZE ? 20 : 0,
        translateY: chartSize < MIN_CHART_SIZE ? 80 : 0,
        itemsSpacing: chartSize >= MIN_CHART_SIZE ? 0 : 0,
        itemWidth: 0,
        itemHeight: 25,
        symbolSize: 18,
        anchor: chartSize >= MIN_CHART_SIZE ? 'right' : 'bottom-left',
        direction: 'column'
      },
      theme: {
        fontSize: 12
      },
      width,
      height
    }
  }, [width, height])

  const chartProps = useMemo(() => ({
    data,
    margin: sizing.margin,
    innerRadius: 0.5,
    padAngle: 0.7,
    cornerRadius: 3,
    colors,
    colorBy: 'id',
    sliceLabelsSkipAngle: 14,
    sliceLabelsTextColor: '#ffffff',
    borderWidth: 1,
    borderColor: 'inherit:darker(0.2)',
    animate: isPrinting ? false : isStatic,
    isInteractive: isPrinting ? false : isStatic,
    enableRadialLabels: false,
    valueFormat: valueFormat || (v => v),
    legends: [
      {
        justify: false,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolShape: 'circle',
        ...sizing.legend
      }
    ],
    theme: sizing.theme,
    width: sizing.width,
    height: sizing.height
  }), [data, isStatic, colors, isPrinting, sizing, valueFormat])

  return (
    <Pie
      data={data}
      {...chartProps}
    />
  )
}

export default PieChart
