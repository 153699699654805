import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { message, Modal } from 'antd'
import { useMutation, useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import { generatePath, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import _get from 'lodash/get'

import MutationForm from '../MutationForm/MutationForm'
import { CREATE_PROSPECT, EDIT_PROSPECT, GET_COMPANY_FOR_UPDATE } from '../Queries/Companies'
import { ErrorAlerts, LoadingBlock } from '../common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { LANGUAGE_SELECT_OPTIONS, DEFAULT_LANGUAGE } from '../../constants/languages'
import { ACCOUNT_TYPES } from '../../constants/company'
import { DEFAULT_TIMEZONE } from '../../constants/timezone'
import routes from '../../constants/routes'
import { validateDomain } from '../../helpers'
import IntercomHeader from '../IntercomHeader'
import { OverflowModal, ModalFormScrollContainer } from './common'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'modals.editProspectModal' }
const editCompTrOpt = { scope: 'modals.editCompanyModal' }
const LOCALES_REQUIRED = window.__USECURE_CONFIG__.REACT_APP_COMPANY_LOCALES_REQUIRED === 'true'

const EditProspectForm = React.forwardRef(({
  company,
  loading = false,
  closeModal = () => {},
  refetchQueries = [],
  userEditExternalId,
  companyLocale,
  companyTimezone,
  companyContentLocales,
  defaultTenantSettings = {}
}, ref) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasSuperPermission = hasAllSessionPermissions(company ? [permissions.COMPANY_SUPER_UPDATE] : [permissions.COMPANY_SUPER_CREATE])
  const { prospect: accountTypeName } = ACCOUNT_TYPES // Needs to be in render use getter in constant
  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  useImperativeHandle(ref, () => ({
    reset: resetForm
  }), [resetForm])
  useEffect(() => {
    if (company || !loading) {
      resetForm()
    }
  }, [company, loading, resetForm])

  const onSuccess = useCallback(() => {
    message.success(I18n.t(company ? 'updateSuccessMessage' : 'createSuccessMessage', { ...editCompTrOpt, accountType: accountTypeName }))
    closeModal()
  }, [closeModal, company, accountTypeName])

  const mutateValues = useCallback(values => {
    const { contentLocales } = values
    const mutatedValues = { ...values }
    // Nullify contentLocales on create unless it's populated - this will trigger the server side code to populate it from MSP Default Customer Settings when creating a prospect
    if (!company && !(contentLocales && contentLocales.length > 0)) {
      mutatedValues.contentLocales = null
    }

    return mutatedValues
  }, [company])

  const [submitProspect] = useMutation(company ? EDIT_PROSPECT : CREATE_PROSPECT, { refetchQueries })
  const onSubmit = useCallback(async (values, errors, variables) => {
    if (!company) {
      // startReport confirm dialog
      variables.startReport = await new Promise(resolve =>
        Modal.confirm({
          title: I18n.t('startRiskReportPrompt', trOpt),
          okText: I18n.t('common.resumeConfirmDialog.ok'),
          cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
          onOk: () => resolve(true),
          onCancel: () => resolve(false)
        })
      )
    } else {
      variables.companyId = company.id
    }

    // usecure admins can only create direct prospects
    if (hasSuperPermission) {
      variables.parentCompanyId = null
    }

    return await submitProspect({ variables })
  }, [company, submitProspect, hasSuperPermission])

  const fields = [{
    id: 'name',
    label: I18n.t('companyName', editCompTrOpt),
    placeholder: I18n.t('companyName', editCompTrOpt),
    required: true,
    defaultValue: _get(company, 'name', null)
  }, {
    id: 'domain',
    label: I18n.t('modals.editCompanyModal.domain'),
    required: true,
    defaultValue: _get(company, 'domain', null),
    validate: (field, value, errors) => {
      if (!validateDomain(value)) {
        errors.push(I18n.t('common.invalidDomainError'))
      }
    }
  }, {
    id: 'firstName',
    label: I18n.t('common.fields.firstName'),
    autofill: false,
    defaultValue: _get(company, 'riskReport.firstName')
  }, {
    id: 'lastName',
    label: I18n.t('common.fields.lastName'),
    autofill: false,
    defaultValue: _get(company, 'riskReport.lastName')
  }, {
    id: 'email',
    label: I18n.t('common.fields.email'),
    type: 'email',
    required: true,
    autofill: false,
    defaultValue: _get(company, 'riskReport.email')
  }]

  if (_get(company, 'editExternalId') || userEditExternalId) {
    fields.push({
      id: 'externalId',
      label: I18n.t('uService.externalId'),
      defaultValue: _get(company, 'externalId', null)
    })
  }

  fields.push({
    id: 'locale',
    label: I18n.t('common.preferredLanguage'),
    defaultValue: company ? (_get(company, 'locale') || DEFAULT_LANGUAGE) : (defaultTenantSettings.locale || companyLocale || DEFAULT_LANGUAGE),
    type: 'select',
    options: LANGUAGE_SELECT_OPTIONS,
    sortOptions: true
  }, {
    id: 'contentLocales',
    label: I18n.t('settings.language.contentLocales'),
    type: 'multiSelect',
    options: LANGUAGE_SELECT_OPTIONS,
    sortOptions: true,
    // Use MSP default customer setting by default on new MSP prospects
    // Otherwise default to the current user's company setting if present
    defaultValue: (company ? company.contentLocales : (defaultTenantSettings.contentLocales || companyContentLocales)) || [],
    placeholder: I18n.t('common.fields.languagesPlaceHolder'),
    extra: I18n.t('contentLocalesExtra', editCompTrOpt),
    required: LOCALES_REQUIRED,
    requiredError: I18n.t('common.fields.languagesRequiredError')
  }, {
    id: 'timezone',
    label: I18n.t('common.timezone'),
    defaultValue: company ? (_get(company, 'timezone') || companyTimezone || DEFAULT_TIMEZONE) : (defaultTenantSettings.timezone || companyTimezone || DEFAULT_TIMEZONE),
    type: 'timezone',
    required: true
  })

  let title
  if (company && company.name) {
    title = I18n.t('updateTitleName', { ...editCompTrOpt, accountType: accountTypeName, name: company.name })
  } else if (company) {
    title = I18n.t('updateTitle', { ...editCompTrOpt, accountType: accountTypeName })
  } else {
    title = I18n.t('createTitle', { ...editCompTrOpt, accountType: accountTypeName })
  }
  return (
    <>
      <IntercomHeader Size='h1' id='uService-update-prospect-modal-header'>{title}</IntercomHeader>
      <LoadingBlock fullScreen={false} loading={loading} />
      <MutationForm
        {...{ mutateValues, onSubmit, onSuccess }}
        failureMessage={I18n.t(company ? 'updateErrorMessage' : 'createErrorMessage', { ...editCompTrOpt, accountType: accountTypeName })}
        submitLabel={I18n.t(company ? 'updateSubmitLabel' : 'createTitle', { ...editCompTrOpt, accountType: accountTypeName })}
        fields={fields}
        ref={form}
        skipResetFieldsOnSubmits
        scrollContainer={ModalFormScrollContainer}
      />
    </>
  )
})

const EditProspectModal = ({
  companyId, visible = false, setVisible = () => {}, refetchQueries = [], history, goBackToProspectsPage, ...rest
}) => {
  const form = useRef(null)
  const closeModal = useCallback(() => {
    setVisible(false)
    if (form && form.current) {
      form.current.reset()
    }
    if (goBackToProspectsPage) {
      history.push(generatePath(routes.USERVICE_MSP_TYPE, { accountType: 'prospects' }))
    }
  }, [setVisible, form, goBackToProspectsPage, history])

  const { loading, error, data } = useQuery(GET_COMPANY_FOR_UPDATE, { variables: { id: companyId }, skip: !companyId })

  const company = _get(data || {}, 'company')

  return (
    <OverflowModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      width='70%'
    >
      <LoadingBlock {...{ loading }} fullScreen={false} />
      {error && <ErrorAlerts error={error} defaultError={I18n.t('prospectLoadError', trOpt)} />}
      {
        visible && !loading && !error &&
          <EditProspectForm
            ref={form}
            {...{ company, loading, closeModal }}
            {...rest}
            refetchQueries={refetchQueries}
          />
      }
    </OverflowModal>
  )
}

export default compose(
  withRouter,
  connect(state => {
    const { accountType: userAccountType, companyId: userCompanyId, companyLocale, companyTimezone, contentLocales: companyContentLocales } = selectors.session.get(state)
    const { defaultTenantSettings, editExternalId: userEditExternalId } = selectors.settings.get(state)
    return { userAccountType, defaultTenantSettings, userCompanyId, userEditExternalId, companyLocale, companyTimezone, companyContentLocales }
  })
)(EditProspectModal)
