import React from 'react'
import I18n from 'i18n-js'
import routes from '../../constants/routes'
import { generatePath } from 'react-router-dom'
import { compose } from 'recompose'
import { SettingsContainer, DescriptionContainer, ActionContainer, CopyToClipboard } from './common'
import { connect } from '../../hocs'
import getSessionAndSettings from '../../state/selectors'
import { getAppOrigin } from '../../state/selectors/settings'
import IntercomHeader from '../IntercomHeader'
const OutstandingActivitiesLogin = React.forwardRef(({ companyId, settings, appOrigin }, ref) => {
  const { uLearn, uPolicy } = settings
  let trOpt = { scope: 'settings.outstandingActivitiesLogin' }
  if (!uLearn) trOpt = { scope: 'settings.outstandingActivitiesLogin.uLearnOff' }
  else if (!uPolicy) trOpt = { scope: 'settings.outstandingActivitiesLogin.uPolicyOff' }

  const link = `${appOrigin}${generatePath(routes.OUTSTANDING_ACTIVITIES_LOGIN, { companyId })}`
  return (
    <SettingsContainer>
      <IntercomHeader Size='h1' id='settings-user-id-login-header'>{I18n.t('title', trOpt)}</IntercomHeader>
      <DescriptionContainer>
        <p>{I18n.t('description', trOpt)}</p>
        <a href={link} target='_blank' rel='noopener noreferrer'>{link}</a>
      </DescriptionContainer>
      <ActionContainer>
        <CopyToClipboard value={link} />
      </ActionContainer>
    </SettingsContainer>
  )
})
export default compose(
  connect(
    state => ({
      appOrigin: getAppOrigin(state),
      getSessionAndSettings
    })
  )
)(OutstandingActivitiesLogin)
