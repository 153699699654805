import React, { useCallback, useMemo, useState, useRef, useImperativeHandle } from 'react'
import { message } from 'antd'
import I18n from 'i18n-js'
import { useApolloClient } from '@apollo/react-hooks'
import _uniq from 'lodash/uniq'

import MutationForm from '../MutationForm/MutationForm'
import { CREATE_USER, GET_USER, GET_USERS, UPDATE_USER } from '../Queries/Users'
import { showErrors } from '../../helpers'
import { COMMS_TYPE_OPTIONS } from '../../constants/users'
import { LoadingBlock } from '../common'
import { LANGUAGE_SELECT_OPTIONS, DEFAULT_LANGUAGE } from '../../constants/languages'
import { DEFAULT_TIMEZONE } from '../../constants/timezone'
import ModalHeaderId from './ModalHeaderId'
import { OverflowModal, ModalFormScrollContainer as _ModalFormScrollContainer } from './common'
import { useHasSessionPermission, useGlobalState } from '../../hooks'
import { permissions } from '../../constants/permissions'
import { roles, superRoleIdDowngrades, superRoleIds } from '../../constants/roles'
import RoleAssignmentField from '../Users/RoleAssignmentField'
import styled from 'styled-components'

const trOpt = { scope: 'modals.addUserModal' }

const ModalFormScrollContainer = styled(_ModalFormScrollContainer)`
  padding-left: ${({ isTab }) => isTab ? 10 : 0}px;
`

const AddUserModal = React.forwardRef(({ onClose = () => {} }, ref) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const { sessionUserId, partner, companyLocale, companyTimezone, sessionUserIsUsecure } = useGlobalState(
    state => ({
      sessionUserId: state?.session?.userId,
      planValid: state?.session?.planValid,
      partner: state?.session?.partner,
      companyLocale: state?.session?.companyLocale,
      companyTimezone: state?.session?.companyTimezone,
      sessionUserIsUsecure: state?.session?.isUsecure
    })
  )
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState(null)
  const [userName, setUserName] = useState(null)
  const [allowedToSetRole, setAllowedToSetRole] = useState(false)
  const client = useApolloClient()

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const setInitialFormValues = useCallback(async values => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async userId => {
      setVisible(true)

      if (userId) {
        setUserId(userId)
        setLoading(true)

        try {
          const result = await client.query({
            query: GET_USER,
            variables: { id: userId }
          })

          const { user } = result?.data ?? {}
          if (user) {
            let roles = user.roles ?? []
            if (!sessionUserIsUsecure) {
              // Downgrade super roles if the current admin does not belong to the usecure company
              roles = _uniq(
                roles.map(role => superRoleIdDowngrades[role] ?? role)
              )
            }

            setAllowedToSetRole(
              // The session user cannot update their own roles
              userId !== sessionUserId &&
              // Only a user with USER_SUPER_UPDATE can change roles on a user with at least one super role
              (!roles.some(roleId => superRoleIds.includes(roleId)) || hasAllSessionPermissions([permissions.USER_SUPER_UPDATE]))
            )

            await setInitialFormValues({
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              locale: user.locale,
              timezone: user.timezone,
              roles,
              commsType: user.commsType
            })
            setUserName(user.name)
            setLoading(false)
          }
        } catch (e) {
          setVisible(false)
          showErrors(e, I18n.t('userError', trOpt))
        }
      } else {
        // Anyone can set a role when creating an admin as the role assignment UI will restrict the roles they can assign
        setAllowedToSetRole(true)
      }
    }
  }), [client, setInitialFormValues, sessionUserId, sessionUserIsUsecure, hasAllSessionPermissions])

  const closeModal = useCallback(() => {
    setVisible(false)
    setLoading(false)
    setUserId(null)
    setUserName(null)
    resetForm()
    onClose()
  }, [setVisible, resetForm, onClose])

  const onSuccess = useCallback(() => {
    message.success(userId ? I18n.t('common.updateUserSuccess') : I18n.t('common.createUserSuccess'))
    closeModal()
  }, [userId, closeModal])

  const onFailure = useCallback(error => {
    showErrors(error,
      userId ? I18n.t('updateErrorMessage', trOpt) : I18n.t('common.createUserError'),
      function () {
        this.errors = this.errors.map(error => {
          if (error.type === 'field_value_exists' && error.original.path === 'email') {
            error.message = I18n.t('common.userEmailExistsError')
          }
          return error
        })
      }
    )
  }, [userId])

  const { tabs, fields } = useMemo(() => {
    const hasSuperPermission = hasAllSessionPermissions(userId ? [permissions.USER_SUPER_UPDATE] : [permissions.USER_SUPER_CREATE])

    const tabs = [{
      id: 'details',
      title: I18n.t('detailsTab', trOpt)
    }]

    const fields = [{
      id: 'firstName',
      placeholder: I18n.t('common.fields.firstName'),
      required: true,
      label: I18n.t('common.fields.firstName'),
      tab: 'details'
    }, {
      id: 'lastName',
      placeholder: I18n.t('common.fields.lastName'),
      required: true,
      label: I18n.t('common.fields.lastName'),
      tab: 'details'
    }, {
      id: 'email',
      placeholder: I18n.t('common.fields.email'),
      type: 'email',
      required: true,
      autofill: false,
      label: I18n.t('common.fields.email'),
      tab: 'details'
    }, {
      id: 'locale',
      label: I18n.t('common.preferredLanguage'),
      defaultValue: companyLocale ?? DEFAULT_LANGUAGE,
      type: 'select',
      required: true,
      options: LANGUAGE_SELECT_OPTIONS,
      sortOptions: true,
      tab: 'details'
    }, {
      id: 'timezone',
      label: I18n.t('common.timezone'),
      defaultValue: companyTimezone ?? DEFAULT_TIMEZONE,
      type: 'timezone',
      required: true,
      tab: 'details'
    }]

    if (allowedToSetRole) {
      tabs.push({
        id: 'roles',
        title: I18n.t('rolesTab', trOpt),
        visible: allowedToSetRole
      })

      fields.push({
        id: 'roles',
        type: 'custom',
        component: RoleAssignmentField,
        required: true,
        defaultValue: [roles.GLOBAL_ADMIN.id],
        validate: (field, value, errors) => {
          if ((value ?? []).length === 0) {
            errors.push(I18n.t('users.roleAssignmentField.noRolesError'))
          }
        },
        hasSuperPermission,
        tab: 'roles'
      })
    }
    if (partner || hasSuperPermission) {
      fields.push({
        id: 'commsType',
        label: I18n.t('commsType', trOpt),
        type: 'select',
        options: COMMS_TYPE_OPTIONS,
        required: true
      })
    }
    return { tabs, fields }
  }, [userId, partner, companyLocale, companyTimezone, hasAllSessionPermissions, allowedToSetRole])

  return (
    <OverflowModal
      visible={visible}
      title={
        <ModalHeaderId
          idText={`${userId ? 'update' : 'create'}-admin-user-header`}
          text={userName
            ? I18n.t('editTitleName', { ...trOpt, name: userName })
            : I18n.t(userId ? 'editTitle' : 'addTitle', trOpt)}
        />
      }
      onCancel={closeModal}
      footer={null}
      destroyOnClose
    >
      <LoadingBlock {...{ loading }} fullScreen={false} />
      <MutationForm
        mutation={userId ? UPDATE_USER : CREATE_USER}
        onSuccess={onSuccess}
        onFailure={onFailure}
        submitLabel={userId ? I18n.t('updateUser', trOpt) : I18n.t('common.createUser')}
        ref={form}
        tabs={tabs}
        fields={fields}
        variables={userId ? { id: userId } : undefined}
        refetchQueries={[{
          query: GET_USERS
        }]}
        scrollContainer={ModalFormScrollContainer}
        disableSubmitIfInvalid={false}
      />
    </OverflowModal>
  )
})

export default AddUserModal
