import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Tag, Button, Modal } from 'antd'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isInteger from 'lodash/isInteger'

import { LANGUAGE_NAMES_BY_CODE } from '../../constants/languages'

const SourceTag = styled(Tag)`
  margin-bottom: 3px;
  margin-left: 3px;
`

const LocalesContainer = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`

const LocalesUL = styled.ul`
  padding-left: 20px;
`

const MoreLocalesButton = styled(Button)`
  & > .anticon {
    position: relative;
    top: 2px;
  }
  & > span {
    position: relative;
    top: 1px;
  }
`

const sortLanguagesArrayByNames = (a, b) => {
  if (a.source && !b.source) return -1
  if (!a.source && b.source) return 1
  return a.name.localeCompare(b.name)
}

const LocalesCell = ({ locales, sourceLocale, limit, itemName }) => {
  const localeValues = useMemo(() => {
    if (!(_isArray(locales) && locales.length > 0)) return []

    const showSourceLocale = sourceLocale && locales.length > 1
    return locales
      .map(locale => ({ locale, name: LANGUAGE_NAMES_BY_CODE[locale] ?? locale, source: showSourceLocale && sourceLocale === locale }))
      .sort(sortLanguagesArrayByNames)
  }, [locales, sourceLocale])

  const openInfoModal = useCallback(() => {
    Modal.info({
      title: `${itemName ? `${itemName} - ` : ''}${I18n.t('common.languages')}`,
      content: (
        <LocalesContainer>
          <LocalesUL>
            {localeValues.map(({ name, source }, index) => (
              <li key={index}>{name}{source && <SourceTag>{I18n.t('courses.common.sourceLocaleTag')}</SourceTag>}</li>
            ))}
          </LocalesUL>
        </LocalesContainer>
      ),
      maskClosable: true
    })
  }, [itemName, localeValues])

  if (!(localeValues && localeValues.length > 0)) return <div>{I18n.t('common.none')}</div>

  const applyLimit = _isInteger(limit) && localeValues.length > limit

  return (
    <>
      {
        (applyLimit ? localeValues.slice(0, limit + 1) : localeValues)
          .map(({ name, source }, index) => <div key={index}>{name}{source && <SourceTag>{I18n.t('courses.common.sourceLocaleTag')}</SourceTag>}</div>)
      }
      {applyLimit && (
        <MoreLocalesButton
          type='primary' icon='plus' size='small'
          onClick={openInfoModal}
        >
          {I18n.t('common.moreLanguages', { count: localeValues.length - limit })}
        </MoreLocalesButton>
      )}
    </>
  )
}

export default LocalesCell
