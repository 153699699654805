import React from 'react'
import { Button, message, Modal } from 'antd'
import I18n from 'i18n-js'
import { useMutation } from 'react-apollo'
import { DETATCH_ALL_FROM_SYNC } from '../Queries/Companies'

const DetachAllFromSyncButton = ({ syncType, action, disabled, trOpt }) => {
  const [setDetachAll] = useMutation(DETATCH_ALL_FROM_SYNC, {
    variables: { syncType, action }
  })

  const clickDetach = async () => {
    Modal.confirm({
      title: I18n.t('modalTitle', trOpt),
      content: I18n.t('modalDescription', trOpt),
      onOk: async () => {
        try {
          await setDetachAll()

          message.success(I18n.t('successMsg', trOpt))
        } catch (e) {
          message.error(I18n.t('errorMsg', trOpt))
        }
      }
    })
  }

  return (
    <Button {...{ disabled }} onClick={clickDetach} type='danger' icon='close-circle'>
      {I18n.t('button', trOpt)}
    </Button>
  )
}
export default DetachAllFromSyncButton
