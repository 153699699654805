import React, { useCallback, useEffect, useState } from 'react'
import styled, { css, withTheme } from 'styled-components'
import { Button, Divider, Card, Tag } from 'antd'
import { rgba, transparentize } from 'polished'
import moment from 'moment'
import I18n from 'i18n-js'
import chroma from 'chroma-js'
import { useQuery } from '@apollo/react-hooks'
import ms from 'ms'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'
import _max from 'lodash/max'
import _minBy from 'lodash/minBy'
import _size from 'lodash/size'

import { PAGE_X_PAD_DESKTOP_REM, PAGE_X_PAD_MOBILE_REM, BREAKPOINT } from '../../constants/style'
import { FontAwesomeIcon, LoadingBlock } from '../common'
import { isRiskReportAtStatus } from '../../helpers/company'
import { renderParagraphsFragmentFromArray } from '../../helpers'
import { RISK_SCORE_CONFIG } from '../Queries/Reports'
import { RISK_SCORE_LEVELS, RISK_SCORE_COLORS } from '../../constants/riskScore'
import { getDurationString } from '../../helpers/duration'
import { getSimEventStartingPoint } from '../../helpers/riskReport'
import useIsPrinting from '../../hooks/useIsPrinting'
import { UBREACH_V2_ENABLED } from '../../constants/environment'
import SeverityField from '../uBreach/SeverityField'
import { Logo } from './ReportHeader'
import { renderDataClasses } from '../uBreach/BreachesTable'

export const getSectionStatus = (riskReport, readOnly, nextStatus, requestedStatus, completeStatus) => {
  const { status = 'new' } = riskReport || {}
  const complete = completeStatus && isRiskReportAtStatus(status, completeStatus)
  const requested = !complete && requestedStatus && isRiskReportAtStatus(status, requestedStatus)
  const next = !requested && !readOnly && !complete && nextStatus && isRiskReportAtStatus(status, nextStatus)
  const visible = next || requested || complete

  return {
    next,
    requested,
    complete,
    visible
  }
}

export const RiskReportContainer = styled.div`
  background-color: ${({ theme }) => theme.reportBG};
  display: flex;
  flex-direction: column;
  /* font-family: 'Roboto', sans-serif; */
  height: 100%;

  p {
    font-family: unset;
  }

  @media print {
    background-color: transparent;
  }
`

export const RiskReportBody = styled.div`
  background-color: ${({ theme }) => theme.reportBG};
  flex: 1;
  font-size: 18px;
  overflow: auto;
  padding: 50px ${PAGE_X_PAD_DESKTOP_REM}rem;
  width: 100%;

  @media (max-width: ${BREAKPOINT}) {
    padding-left: ${PAGE_X_PAD_MOBILE_REM}rem;
    padding-right: ${PAGE_X_PAD_MOBILE_REM}rem;
  }

  @media print {
    background-color: transparent;
    font-size: 16px;
    padding: 0;
    overflow: visible;
  }
`

export const RiskReportLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1240px;
  page-break-inside: ${({ pageBreakInside = 'avoid' }) => pageBreakInside};
  page-break-before: ${({ pageBreakBefore = 'auto' }) => pageBreakBefore};
  position: relative;
  width: 100%;
`

export const RiskReportLayoutDivider = styled(Divider)`
  padding: 0 20px 20px;
  page-break-before: ${({ pageBreakBefore = 'auto' }) => pageBreakBefore};

  &.ant-divider-horizontal.ant-divider-with-text-center {
    &::before, &::after {
      border-top-color: ${({ theme }) => theme.reportDark};
    }
  }

  .ant-divider-inner-text {
    color: ${({ theme }) => theme.defaultPrimary};
    font-size: 30px;
    position: relative;
    top: 5px;
  }
`

const _RiskReportPrintSectionHeader = ({ className, children }) => (
  <div {...{ className }}>
    <h2>{children}</h2>
  </div>
)
export const RiskReportPrintSectionHeader = styled(_RiskReportPrintSectionHeader)`
  display: none;

  @media print {
    display: block;
    /* The !importants here are to fix printing in Firefox */
    margin-top: 0;
    margin-bottom: 15px;
    break-before: page;
    border: 0 !important;
    border-radius: 0 !important;
    padding: 20px 35px 15px !important;
    background-color: ${({ theme }) => theme.reportDocumentHeaderBackgroundColor} !important;
    page-break-before: ${({ pageBreakBefore = 'always' }) => pageBreakBefore};
    width: 100%;
    h2 {
      font-weight: bold;
      font-size: 24px;
      color: ${({ theme }) => theme.reportDocumentHeaderFontColor};
      margin-bottom: 0;
    }
  }
`

export const RiskReportSectionHeader = ({ pageBreakBefore, children }) => {
  const isPrinting = useIsPrinting()

  if (isPrinting) {
    return (
      <RiskReportPrintSectionHeader {...{ pageBreakBefore }}>{children}</RiskReportPrintSectionHeader>
    )
  }

  return (
    <RiskReportLayoutDivider>{children}</RiskReportLayoutDivider>
  )
}

export const RiskReportCardBody = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  cursor: ${({ onClick }) => _isFunction(onClick) ? 'pointer' : 'default'};
  filter: drop-shadow(0px 4px 4px ${rgba('#D8D8D8', 0.25)});
  height: 100%;
  position: relative;
  width: 100%;

  ${({ onClick }) => _isFunction(onClick) ? css`
  &:hover {
    box-shadow: 0px 0px 15px ${({ theme }) => rgba(theme.defaultPrimary, 0.4)};
  }
  ` : ''}

  @media print {
    filter: none;
    &:hover {
      box-shadow: none;
    }
  }
`

const _RiskReportCard = ({ className, children, locked, lockedMessage, onClick }) => (
  <div {...{ className }}>
    <RiskReportCardBody {...{ onClick }}>
      {locked && <RiskReportCardLockedOverlay>{lockedMessage}</RiskReportCardLockedOverlay>}
      {_isFunction(onClick) && <RiskReportMore />}
      {children}
    </RiskReportCardBody>
  </div>
)

const CardSpanStyles = {
  3: css`
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) {
  width: 25%;
  }
  `,
  4: css`
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) {
  width: ${100 / 3}%;
  }
  `,
  6: css`
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) {
  width: 50%;
  }
  `,
  8: css`
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) {
  width: ${(100 / 3) * 2}%;
  }
  `,
  9: css`
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) {
  width: 75%;
  }
  `,
  12: css`
  width: 100%;
  `
}

const _RiskReportMore = ({ className, icon = 'plus-square' }) => (
  <div {...{ className }}>
    <FontAwesomeIcon icon={icon} />
  </div>
)
const RiskReportMore = styled(_RiskReportMore)`
  color: #C4C4C4;
  cursor: pointer;
  font-size: 25px;
  padding: 10px;

  @media print {
    display: none;
  }
`

export const RiskReportCard = styled(_RiskReportCard)`
  padding: 0 20px 40px;
  position: relative;

  ${({ span = 12 }) => CardSpanStyles[span]}

  ${RiskReportCardBody} {
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.white};
    padding: ${({ padding = '20px' }) => padding};
    ${({ fontColor }) => {
      if (fontColor) {
        return css`
          color: ${fontColor};
          h1, h2, h3, h4, h5, h6 {
            color: ${fontColor};
          }
        `
      }
    }}
  }

  ${RiskReportMore} {
    position: absolute;
    right: 10px;
    top: 0px;
  }
`
export const RiskReportCardLockedOverlay = styled.div`
  background-color: ${rgba('#C4C4C4', 0.9)};
  border-radius: 8px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 200;
`

export const RiskReportLockedOverlay = styled(RiskReportCardLockedOverlay)`
  height: calc(100% - 40px);
  left: 20px;
  width: calc(100% - 40px);
`

const _LockedCardOverlay = ({ className, icon = 'lock', message = 'MESSAGE', buttonLabel = 'Advance', onClick = () => {}, next, readOnly }) => (
  <div className={className}>
    <FontAwesomeIcon icon={icon} />
    {_isString(message) ? <p>{message}</p> : message}
    {next && !readOnly && <Button type='primary' size='large' onClick={onClick}>{buttonLabel}</Button>}
  </div>
)
export const LockedCardOverlay = styled(_LockedCardOverlay)`
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;

  p, i {
    color: ${({ theme }) => theme.reportDark};
  }

  p {
    text-shadow: 0px 1px 3px ${({ theme }) => transparentize(0.4, theme.white)};
  }

  i {
    font-size: 64px;
    margin-bottom: 15px;
  }

`

const _LockedOverlay = ({ className, ...cardProps }) => (
  <RiskReportLockedOverlay className={className}>
    <LockedCardOverlay {...cardProps} />
  </RiskReportLockedOverlay>
)
export const LockedOverlay = styled(_LockedOverlay)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  text-align: center;

  ${LockedCardOverlay} {
    height: auto;
    margin-bottom: 40px;
  }
`

const RiskReportDomainScanContainer = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 15px 0;

  .risk-report-domain-count {
    font-size: 24px;
    font-weight: 500;
  }
  .risk-report-domain-label {
    color: rgba(0,0,0, 0.47);
    font-size: 16px;
  }
`
const _RiskReportDomainScanIcon = ({ className, children }) => <div {...{ className }}>{children}</div>
const RiskReportDomainScanIcon = styled(_RiskReportDomainScanIcon)`
  align-items: center;
  background-color: ${({ color }) => rgba(color, 0.2)};
  border-radius: 60px;
  color: ${({ color }) => color};
  display: flex;
  flex: 0 0 60px;
  height: 60px;
  justify-content: center;
  margin-right: 15px;
  width: 60px;

  i {
    font-size: 25px;
  }
`

// TODO Update icon to comment-alt-dots when Font Awesome Pro is available
const _RiskReportDomainScanCard = ({ className, color = 'red', count = 0, label = '<DOMAIN SCAN RESULT>', type, onMoreClick: onMoreClickProp = () => {} }) => {
  const onMoreClick = useCallback(() => {
    onMoreClickProp(type)
  }, [type, onMoreClickProp])

  return (
    <RiskReportCard className={className} onClick={count > 0 ? onMoreClick : null}>
      <RiskReportDomainScanContainer>
        <RiskReportDomainScanIcon {...{ color }}>
          <FontAwesomeIcon icon='comment-alt' />
        </RiskReportDomainScanIcon>
        <div>
          <div className='risk-report-domain-count'>{count}</div>
          <div className='risk-report-domain-label'>{label}</div>
        </div>
      </RiskReportDomainScanContainer>
    </RiskReportCard>
  )
}
export const RiskReportDomainScanCard = styled(_RiskReportDomainScanCard)`
  @media (max-width: 575px) {
    width: 100%;
  }
  @media (min-width: 576px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 25%;
  }

  ${RiskReportMore} {
    font-size: 20px;
    right: 0px;
  }
`

export const RiskReportCategoryCard = styled(RiskReportCard)`
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: ${100 / 3}%;
  }

  ${RiskReportMore} {
    top: 10px;
    ${({ inverted, theme }) => {
      if (inverted) {
        return css`
    color: ${theme.white};
        `
      }
    }}
  }

  ${RiskReportCardBody} {
    padding: 40px;
    ${({ inverted, theme, onClick }) => {
      if (inverted) {
        return css`
          background-color: ${theme.riskReportRed};
          color: ${theme.white};
          h1, h2, h3, h4, h5, h6 {
            color: ${theme.white};
          }

          ${_isFunction(onClick) ? css`
          &:hover {
            box-shadow: 0px 0px 20px ${({ theme }) => rgba(theme.riskReportRed, 0.4)};
          }
          @media print {
            &:hover {
              box-shadow: none;
            }
          }
          ` : ''}
        `
      }
    }}
  }

  @media print {
    ${RiskReportCardBody} {
      border: 1px ${({ inverted, theme }) => inverted ? theme.riskReportRed : '#C4C4C4'} solid;
      ${({ inverted, theme }) => {
        if (inverted) {
          return css`
            background-color: ${theme.white};
            color: ${theme.riskReportRed};
            h1, h2, h3, h4, h5, h6 {
              color: ${theme.riskReportRed};
            }
          `
        }
      }}
    }
  }
`

const RiskReportBreachCategoryContainer = styled(RiskReportCategoryCard)`
  .risk-report-breach-category {
    display: flex;
    flex-direction: column;
    height: 260px;
    line-height: 1;
    text-align: center;

    &-value {
      color: ${({ inverted, theme }) => inverted ? theme.white : '#000000'};
      font-size: 84px;
      margin-top: 70px;
    }
    &-label {
      color: ${({ inverted, theme }) => inverted ? theme.white : 'rgba(0,0,0,0.4)'};
      font-size: 24px;
    }
  }

  @media print {
    left: 20px;
    padding: 0 10px 40px;
    position: relative;
    width: calc(${100 / 3}% - ${40 / 3}px);

    .risk-report-breach-category {
      height: 200px;

      &-value {
        color: ${({ inverted, theme }) => inverted ? theme.riskReportRed : '#000000'};
        margin-top: 15px;
      }
      &-label {
        color: ${({ inverted, theme }) => inverted ? theme.riskReportRed : 'rgba(0,0,0,0.4)'};
      }
    }
  }


`

export const RiskReportBreachCategoryCard = ({
  inverted, value = 0, label = '<LABEL>', type, onMoreClick: onMoreClickProp = () => {}, sample
}) => {
  const onMoreClick = useCallback(() => {
    onMoreClickProp(type)
  }, [type, onMoreClickProp])

  return (
    <RiskReportBreachCategoryContainer
      inverted={inverted} span={4} onClick={!sample && value > 0 ? onMoreClick : null}
    >
      <div className='risk-report-breach-category'>
        <div className='risk-report-breach-category-value'>{value}%</div>
        <div className='risk-report-breach-category-label'>{label}</div>
      </div>
    </RiskReportBreachCategoryContainer>
  )
}

const breachTrOpt = { scope: 'riskReport.uBreach.categoryLabel' }

const BREACH_CATEGORIES = ['high', 'medium', 'low']
const DEFAULT_BREACH_CATEGORY_DATA = { high: 50, medium: 40, low: 75 }
export const RiskReportBreachCategories = ({ uBreachStats, sample, onMoreClick }) => {
  const [data, setData] = useState(DEFAULT_BREACH_CATEGORY_DATA)
  useEffect(() => {
    if (sample) {
      setData(DEFAULT_BREACH_CATEGORY_DATA)
    } else {
      const { breached = 0, notBreached = 0, breachedByCategory } = uBreachStats || {}
      const totalLearners = breached + notBreached
      if (breached > 0 && totalLearners > 0) {
        setData(BREACH_CATEGORIES.reduce((acc, category) => {
          const count = _get(breachedByCategory, category) || 0
          acc[category] = Math.round((count / totalLearners) * 100)
          return acc
        }, {}))
      } else {
        setData({ high: 0, medium: 0, low: 0 })
      }
    }
  }, [sample, uBreachStats])

  const categoryProps = { onMoreClick, sample }

  return (
    <>
      <RiskReportBreachCategoryCard type='breachCategoryHigh' label={I18n.t('high', breachTrOpt)} value={data.high} inverted {...categoryProps} />
      <RiskReportBreachCategoryCard type='breachCategoryMedium' label={I18n.t('medium', breachTrOpt)} value={data.medium} {...categoryProps} />
      <RiskReportBreachCategoryCard type='breachCategoryLow' label={I18n.t('low', breachTrOpt)} value={data.low} {...categoryProps} />
    </>
  )
}

const _RiskReportNoBreachesCard = props => (
  <RiskReportCard {...props} span={12}>
    <div className='risk-report-no-breach-icon'>
      <FontAwesomeIcon icon='lock' />
    </div>
    <div className='risk-report-no-breach-label'>{I18n.t('riskReport.uBreach.empty')}</div>
  </RiskReportCard>
)
export const RiskReportNoBreachesCard = styled(_RiskReportNoBreachesCard)`
  ${RiskReportCardBody} {
    align-items: center;
    display: flex;
    height: 260px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .risk-report-no-breach {
    &-label {
      font-size: 24px;
      margin-top: 15px;
    }
    &-icon {
      align-items: center;
      background-color: ${({ theme }) => rgba(theme.defaultPrimary, 0.2)};
      border-radius: 100px;
      display: flex;
      height: 100px;
      justify-content: center;
      width: 100px;

      i {
        color: ${({ theme }) => theme.defaultPrimary};
        font-size: 64px;
        margin-bottom: 15px;
      }
    }
  }

  @media print {
    ${RiskReportCardBody} {
      border: 1px #C4C4C4 solid;
    }
  }
`

const _RiskReportPhishBar = ({ className, label, value, max }) => {
  const height = max > 0 ? ((value / max) * 100) : 0

  return (
    <div {...{ className }}>
      <div className='risk-report-phish-bar-container'>
        <div className='risk-report-phish-bar' style={{ height: `${height}%` }}>
          <div className='risk-report-phish-bar-label'>{label}</div>
        </div>
      </div>
    </div>
  )
}
const RiskReportPhishBar = styled(_RiskReportPhishBar)`
  margin-top: 30px;

  .risk-report-phish-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
  .risk-report-phish-bar-label {
    color: ${({ inverted, theme }) => inverted ? theme.white : '#000000'};
    font-weight: bold;
    font-size: 14px;
    left: 0;
    position: absolute;
    text-align: center;
    top: -25px;
    width: 100%;
  }
  .risk-report-phish-bar {
    background-color: ${({ inverted, theme }) => inverted ? theme.white : theme.defaultPrimary};
    position: relative;
  }

  @media print {
    .risk-report-phish-bar-label {
      color: ${({ inverted, theme }) => inverted ? theme.riskReportRed : '#000000'};
    }
    .risk-report-phish-bar {
      background-color: ${({ inverted, theme }) => inverted ? theme.riskReportRed : theme.defaultPrimary};
    }
  }
`

const phIntTrOpt = { scope: 'riskReport.uPhish.interactions' }
const PHISH_BAR_LABEL_KEYS = ['oneMinute', 'fiveMinutes', 'tenMinutes', 'fifteenMinutes', 'fortyFiveMinutes', 'sixtyMinutes', 'oneHourPlus']
const _RiskReportPhishBarChart = ({ className, values, inverted }) => {
  const max = _max(values)

  return (
    <>
      <div {...{ className }}>
        {values.map((value, index) => (
          <RiskReportPhishBar
            key={index}
            label={I18n.t(`barLabel.${PHISH_BAR_LABEL_KEYS[index]}`, phIntTrOpt)}
            {...{ value, max, inverted }}
          />
        ))}
      </div>
    </>
  )
}
const RiskReportPhishBarChart = styled(_RiskReportPhishBarChart)`
  display: flex;

  ${RiskReportPhishBar} {
    padding: 0 2.5px;
    width: ${(1 / 7) * 100}%;
  }
`

const SIM_EVENTS = ['open', 'visit', 'compromise']
const DEFAULT_EVENT_VALUE = 0
const DEFAULT_EVENT_VALUES = [0, 0, 0, 0, 0, 0, 0]
const SAMPLE_DATA = {
  open: { value: 85, overallValue: 85, eventValues: [10, 8, 5, 10, 6, 10, 8] },
  visit: { value: 70, overallValue: 60, eventValues: [10, 8, 5, 10, 6, 10, 8] },
  compromise: { value: 80, overallValue: 50, eventValues: [10, 8, 5, 10, 8, 10, 8] }
}
const DEFAULT_DATA = {
  open: { value: DEFAULT_EVENT_VALUE, overallValue: DEFAULT_EVENT_VALUE, eventValues: DEFAULT_EVENT_VALUES },
  visit: { value: DEFAULT_EVENT_VALUE, overallValue: DEFAULT_EVENT_VALUE, eventValues: DEFAULT_EVENT_VALUES },
  compromise: { value: DEFAULT_EVENT_VALUE, overallValue: DEFAULT_EVENT_VALUE, eventValues: DEFAULT_EVENT_VALUES }
}

export const RiskReportPhishInteractions = ({ sample, results, onMoreClick }) => {
  const [data, setData] = useState(DEFAULT_DATA)

  useEffect(() => {
    if (sample) {
      setData(SAMPLE_DATA)
    } else if (results.length > 0) {
      const { totalEvents, eventValues } = (results || []).reduce((acc, result) => {
        SIM_EVENTS.forEach(event => {
          const { [getSimEventStartingPoint(event)]: startedAt, [event]: eventAt } = result
          if (eventAt) {
            acc.totalEvents[event] += 1

            const diff = moment(eventAt).diff(startedAt, 'minutes', true)
            if (diff <= 1) {
              acc.eventValues[event][0] += 1 // 1M
            } else if (diff <= 5) {
              acc.eventValues[event][1] += 1 // 5M
            } else if (diff <= 10) {
              acc.eventValues[event][2] += 1 // 10M
            } else if (diff <= 15) {
              acc.eventValues[event][3] += 1 // 15M
            } else if (diff <= 45) {
              acc.eventValues[event][4] += 1 // 45M
            } else if (diff <= 60) {
              acc.eventValues[event][5] += 1 // 60M
            } else {
              acc.eventValues[event][6] += 1 // 1H+
            }
          }
        })

        return acc
      }, {
        totalEvents: { open: 0, visit: 0, compromise: 0 },
        eventValues: { open: [0, 0, 0, 0, 0, 0, 0], visit: [0, 0, 0, 0, 0, 0, 0], compromise: [0, 0, 0, 0, 0, 0, 0] }
      })

      setData(SIM_EVENTS.reduce((acc, event) => {
        const overallValue = Math.round((totalEvents[event] / results.length) * 100)
        let value = overallValue
        if (event === 'visit') {
          value = totalEvents.open > 0 ? Math.round((totalEvents[event] / totalEvents.open) * 100) : 0
        } else if (event === 'compromise') {
          value = totalEvents.visit > 0 ? Math.round((totalEvents[event] / totalEvents.visit) * 100) : 0
        }

        acc[event] = {
          value,
          overallValue,
          eventValues: eventValues[event]
        }

        return acc
      }, {}))
    } else {
      setData(DEFAULT_DATA)
    }
  }, [sample, results])

  return (
    <>
      <RiskReportPhishInteractionCard
        value={data.open.value} eventValues={data.open.eventValues}
        label={I18n.t('label.open', phIntTrOpt)} chartLabel={I18n.t('chartLabel.open', phIntTrOpt)}
        type='simEventOpen' {...{ onMoreClick, sample }}
      />
      <RiskReportPhishInteractionCard
        value={data.visit.value} eventValues={data.visit.eventValues} overallValue={data.visit.overallValue}
        label={I18n.t('label.visit', phIntTrOpt)} chartLabel={I18n.t('chartLabel.visit', phIntTrOpt)}
        type='simEventVisit' {...{ onMoreClick, sample }}
      />
      <RiskReportPhishInteractionCard
        value={data.compromise.value} eventValues={data.compromise.eventValues} overallValue={data.compromise.overallValue}
        inverted label={I18n.t('label.compromise', phIntTrOpt)} chartLabel={I18n.t('chartLabel.compromise', phIntTrOpt)}
        type='simEventCompromise' {...{ onMoreClick, sample }}
      />
    </>
  )
}

export const RiskReportPhishInteractionCard = ({
  inverted, value = DEFAULT_EVENT_VALUE, eventValues = DEFAULT_EVENT_VALUES, overallValue, label = '<EVENT>', chartLabel,
  type, onMoreClick: onMoreClickProp = () => {}, sample
}) => {
  const onMoreClick = useCallback(() => {
    onMoreClickProp(type)
  }, [type, onMoreClickProp])

  return (
    <RiskReportPhishInteractionContainer
      inverted={inverted} span={4}
      onClick={!sample && value > 0 ? onMoreClick : null}
    >
      <div className='risk-report-phish'>
        <div className='risk-report-phish-info'>
          <div className='risk-report-phish-value'>{value}%</div>
          <div className='risk-report-phish-label'>{label}</div>
          {_isNumber(overallValue) && <span className='risk-report-phish-value-sub'>({overallValue}% of all users)</span>}
        </div>
        {value > 0 && (
          <>
            {chartLabel && <h4 className='risk-report-phish-chart-title'>{chartLabel}</h4>}
            <RiskReportPhishBarChart values={eventValues} {...{ inverted }} />
          </>
        )}
      </div>
    </RiskReportPhishInteractionContainer>
  )
}
const RiskReportPhishInteractionContainer = styled(RiskReportCategoryCard)`
  @media print {
    left: 20px;
    padding: 0 10px 20px;
    position: relative;
    width: calc(50% - 20px);

    &:nth-child(4) {
      left: 0;
      padding: 0 30px 40px;
      width: 100%;
    }
  }

  ${RiskReportCardBody} {
    padding: 40px 40px 0px;
  }

  .risk-report-phish {
    display: flex;
    flex-direction: column;
    height: 300px;
    &-info {
      margin-bottom: ${({ chartLabel }) => chartLabel ? 30 : 45}px;
      padding: 0 2.5px;
      position: relative;
    }
    &-value {
      font-size: 48px;
      line-height: 1;
    }
    &-value-sub {
      display: none;
      font-size: 14px;
      margin-right: 0;
      position: absolute;
      bottom: -20px;
      left: 2px;
    }
    &-label {
      font-size: 24px;
      line-height: 1;
    }
    &-chart-title {
      margin-bottom: 0;
      text-align: center;
    }
  }
  ${RiskReportPhishBarChart} {
    flex: 1;
  }
`

const RiskScoreDot = ({ className, score, color: backgroundColor }) => (
  <div
    {...{ className }}
    style={{ backgroundColor }}
  >
    <span>{score}</span>
  </div>
)

const RiskScoreCore = withTheme(({ className, theme, data, title, dot = false, useFontColorScale = false }) => {
  const [thresholds, setThresholds] = useState(null)
  const [gradient, setGradient] = useState(null)
  const [colorScale, setColorScale] = useState(null)
  const [colorDomain, setColorDomain] = useState(null)
  const [color, setColor] = useState(null)
  const [status, setStatus] = useState(null)
  const [score, setScore] = useState(null)
  const [scoreTop, setScoreTop] = useState(null)
  const { data: { riskScoreConfig: config } = {}, loading } = useQuery(RISK_SCORE_CONFIG)

  useEffect(() => {
    if (!config) return
    const { maxRiskScore } = config

    const { scoreColors, thresholds } = RISK_SCORE_LEVELS.reduce((acc, level) => {
      const color = theme[RISK_SCORE_COLORS[level]]
      const max = level === 'veryHigh' ? maxRiskScore : config[`${level}Max`]
      acc.scoreColors.unshift({ max, color })
      acc.thresholds[level] = {
        max,
        status: I18n.t(level, { scope: 'riskReport.riskScore.status' }),
        color
      }
      return acc
    }, {
      scoreColors: [],
      thresholds: {}
    })

    setThresholds(thresholds)
    const { gradient, scale, domain } = scoreColors.reduce((acc, { color, max }, index) => {
      acc.gradient += `, ${transparentize(0.25, color)} ${((max / maxRiskScore) * 100)}%`
      if (index === scoreColors.length - 1) {
        acc.gradient += ')'
      }
      acc.scale.push(color)
      let domain = 0
      const prev = scoreColors[index - 1]
      if (prev) {
        domain = prev.max / maxRiskScore
      }
      acc.domain.push(domain)

      return acc
    }, {
      gradient: 'linear-gradient(0deg',
      scale: [],
      domain: []
    })
    setGradient(gradient)
    setColorScale(scale)
    setColorDomain(domain)
  }, [theme, config])

  useEffect(() => {
    if (!config || !thresholds || !data) return

    const { maxRiskScore } = config
    const { riskScore: score, level } = data
    let tLevel = level
    if (!tLevel) {
      tLevel = [...RISK_SCORE_LEVELS].reverse()
        .find(possLevel => {
          const { max } = thresholds[possLevel] || {}
          return !max || score <= max
        })
    }

    const { status, color: tColor } = thresholds[tLevel]
    setScore(score)
    setScoreTop(`calc(${((maxRiskScore - score) / maxRiskScore) * 100}% - 8px)`)
    if (useFontColorScale && colorScale && colorDomain) {
      setColor(
        (
          chroma
            .scale(colorScale)
            .domain(colorDomain)
        )(score / maxRiskScore)
      )
    } else {
      setColor(tColor)
    }
    setStatus(status)
  }, [config, data, thresholds, useFontColorScale, colorScale, colorDomain])

  if (loading) {
    return (
      <div {...{ className }} style={{ width: dot ? undefined : '100%' }}>
        <LoadingBlock loading fullScreen={false} useContainer />
      </div>
    )
  }

  if (dot) {
    return <RiskScoreDot {...{ className, score, color }} />
  }
  return (
    <div {...{ className }}>
      <div className='risk-score-scale' style={{ backgroundImage: gradient }}>
        <div className='risk-score-indicator' style={{ top: scoreTop }}>{I18n.t('riskReport.riskScore.you')}</div>
      </div>
      <div className='risk-score-info'>
        <div>
          {title && <h1>{title}</h1>}
        </div>
        <div>
          <div className='risk-score-status' style={{ color }}>{status}</div>
          <div className='risk-score-value' style={{ color }}>{score}</div>
        </div>
      </div>
    </div>
  )
})

export const RiskScore = styled(RiskScoreCore)`
  display: flex;
  
  .risk-score-scale {
    background-image: linear-gradient(0deg, rgba(55, 123, 89, 0.75) 10%, rgba(94, 183, 126, 0.75) 20%, rgba(248, 220, 74, 0.75) 50%, rgba(240, 149, 62, 0.75) 80%, rgba(209, 45, 52, 0.75) 100%);
    background-color: white;
    border-radius: 8px;
    flex: 0 0 54px;
    height: 100%;
    margin-right: 70px;
    position: relative;
    width: 54px;
  }
  .risk-score-indicator {
    color: #000000;
    position: absolute;
    left: 76px;

    &:before {
      background-color: #000000;
      content: '';
      height: 1px;
      left: -50px;
      top: 8px;
      position: absolute;
      width: 45px;
    }
  }
  .risk-score-info {
    display: flex;
    flex-direction: column;
    justify-content: ${({ footer }) => footer ? 'space-between' : 'flex-start'};
    text-align: center;

    h1 {
      color: #000000;
      font-size: 30px;
      line-height: 1.17;
      margin-bottom: 5px;
    }
  }
  .risk-score-status {
    font-size: 24px;
    font-weight: 700;
    line-height: 0.92;
    margin: 20px 0;
  }
  .risk-score-value {
    display: inline-block;
    font-size: 56px;
    font-weight: 400;
    line-height: 1;

    &:after {
      content: '/900';
      color: #C4C4C4;
      display: block;
      font-size: 24px;
      line-height: 1;
      text-align: right;
    }
  }
`
const _RiskScoreMini = props => <RiskScoreCore {...props} dot />

export const RiskScoreMini = styled(_RiskScoreMini)`
  align-items: center;
  border-radius: 25px;
  color: ${({ theme }) => theme.white};
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(216,216,216,0.5));
  font-size: 24px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  text-shadow: 0 0 1.5px rgba(125,125,125,0.4);
  width: 50px;

  span {
    margin-top: 7px;
    line-height: 1;
  }
`

const _BreachMarker = ({ className, label, left, above }) => (
  <div {...{ className }} style={{ left }}>
    <div className='breach-marker'>
      {above
        ? (
          <>
            <div className='breach-marker-label'>
              <span>{label}</span>
            </div>
            <div className='breach-marker-line' />
            <div className='breach-marker-circle' />
          </>
        ) : (
          <>
            <div className='breach-marker-circle' />
            <div className='breach-marker-line' />
            <div className='breach-marker-label'>
              <span>{label}</span>
            </div>
          </>
        )}
    </div>
  </div>
)
const BreachMarker = styled(_BreachMarker)`
  ${({ above }) => above ? 'bottom' : 'top'}: -8px;
  position: absolute;

  .breach-marker {
    position: relative;
    height: 140px;
    width: 25px;

    &-circle {
      background-color: ${({ theme }) => theme.white};
      border: 5px solid ${({ color, theme }) => color || theme.defaultPrimary};
      border-radius: 25px;
      height: 25px;
      width: 25px;
    }
    &-line {
      background-color: #000000;
      height: 80px;
      margin: 0 auto;
      width: 1px;
    }
    &-label {
      align-items: ${({ above }) => above ? 'flex-start' : 'flex-end'};
      display: flex;
      height: 35px;
      justify-content: center;
      width: 25px;

      span {
        background-color: ${({ theme }) => theme.white};
        display: table;
        font-size: 24px;
        padding-top: 5px;
        text-align: center;
        white-space: pre;
      }
    }
  }
`

const _BreachArrow = ({ className, start, end }) => {
  const left = `calc(${start} + 20px)`
  const width = `calc(${parseFloat(end) - parseFloat(start)}% - 18px)`

  return (
    <div {...{ className }} style={{ left, width }}>
      <div className='breach-arrow'>
        <div className='breach-arrow-line' />
        <div className='breach-arrow-head' />
      </div>
    </div>
  )
}
const BreachArrow = styled(_BreachArrow)`
  position: absolute;

  .breach-arrow {
    position: relative;

    &-line {
      background-color: ${({ color, theme }) => color || theme.defaultPrimary};
      height: 10px;
      width: calc(100% - 14px);
    }
    &-head {
      position: absolute;
      right: 0;
      top: -9px;
      width: 0; 
      height: 0; 
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      
      border-left: 14px solid ${({ color, theme }) => color || theme.defaultPrimary};
    }
  }
`

const ttbTrOpt = { scope: 'riskReport.timeToBreach' }
const SAMPLE_TIME_TO_BREACH = ms('2.5 hours')
const getSampleTimeToBreachText = () => getDurationString(SAMPLE_TIME_TO_BREACH, { maxUnits: 2 }) // This will return a string for the current locale
const SAMPLE_TIMELINE_POSITIONS = ['0%', '20%', '65%', '80%', '90%', '100%']
const TIMELINE_POSITION_PADDING = 8.5
const SIM_BREACH_EVENTS = [...SIM_EVENTS].reverse()
const DOMAIN_FACTOR = 7 / 12 // Up to 35 minutes
const LOOKALIKE_FACTOR = 5 / 12 // Up to 25 minutes
const _RiskReportTimeToBreach = withTheme(({ className, theme, riskReport, sample = false }) => {
  const [breached, setBreached] = useState(true)
  const [timelinePositions, setTimelinePositions] = useState(SAMPLE_TIMELINE_POSITIONS)
  const [timeToBreach, setTimeToBreach] = useState(SAMPLE_TIME_TO_BREACH)

  useEffect(() => {
    if (sample) {
      setBreached(true)
      setTimelinePositions(SAMPLE_TIMELINE_POSITIONS)
      setTimeToBreach(getSampleTimeToBreachText())
      return
    }

    // Establish breach time - shortest time between sending phish and compromise
    // Use visit if no compromises and opens if no visits
    const results = _get(riskReport, 'simulation.results') || []
    let fastestBreach
    SIM_BREACH_EVENTS.some(eventType => {
      const events = results.filter(r => r[eventType])
      if (events.length > 0) {
        fastestBreach = _minBy(
          events.map(result => {
            const { [getSimEventStartingPoint(eventType)]: startedAt, [eventType]: breach } = result
            return { breach, diff: moment(breach).diff(startedAt) }
          }),
          'diff'
        )
      }
      return events.length > 0
    })
    if (fastestBreach) {
      fastestBreach.breach = moment(fastestBreach.breach)
    } else {
      // No engagement will default to 5 minutes
      fastestBreach = { breach: moment(), diff: 300000 }
    }

    // Build timeline from last event backward
    const breached = !_isNil(fastestBreach)
    const timeline = ['breach', 'launchPhish', 'createPhish', 'acquire', 'research'].reduce((acc, pos, index, positions) => {
      if (pos === 'breach' && fastestBreach) {
        acc[pos] = fastestBreach.breach
      } else if (pos === 'launchPhish' && fastestBreach) {
        acc[pos] = moment(fastestBreach.breach).subtract(fastestBreach.diff, 'milliseconds') // Time between phish being sent and breach e.g. compromise
      } else if (pos === 'launchPhish') {
        acc[pos] = moment()
      } else {
        const prevEvent = acc[positions[index - 1]]
        let posDuration
        if (pos === 'createPhish') {
          posDuration = 30 // Time to create & launch a phish
        } else if (pos === 'acquire') {
          // Time to acquire assets/emails
          // Select duration by % breached users
          const { breached = 0, notBreached = 0 } = _get(riskReport, 'uBreachStats') || {}
          const totalLearners = breached + notBreached
          const breachedUsers = totalLearners > 0 ? ((breached / totalLearners) * 100) : 0
          if (breachedUsers > 66) {
            // 10 minutes for 66%+
            posDuration = 10
          } else if (breachedUsers >= 34) {
            // 20 minutes for 34-66%
            posDuration = 20
          } else {
            // 30 minutes for 0-33%
            posDuration = 30
          }
        } else if (pos === 'research') {
          // Time to complete research
          // Determine duration from the number of connected and lookalike domains
          // The domain and lookalikes parts of the calculation are weighted to yield a total reflecting the difference in volume between the 2
          // Both Slow - 60 minutes max
          // Both Typical - 50 minutes max
          // Both Quick - 30 minutes max
          // DOMAIN_FACTOR & LOOKALIKE_FACTOR determine the split between the 2 domains
          const domainCount = _size(_get(riskReport, 'domainScan.domains'))
          if (domainCount > 20) {
            // Slow 20+
            posDuration = 60 * DOMAIN_FACTOR
          } else if (domainCount >= 11) {
            // Typical 11-20
            posDuration = 50 * DOMAIN_FACTOR
          } else {
            // Quick for 1-10
            posDuration = 30 * DOMAIN_FACTOR
          }

          const lookalikeCount = _size(_get(riskReport, 'domainScan.lookalikeDomains'))
          if (lookalikeCount > 800) {
            // Slow 800+
            posDuration += 60 * LOOKALIKE_FACTOR
          } else if (lookalikeCount >= 651) {
            // Typical 651-800
            posDuration += 50 * LOOKALIKE_FACTOR
          } else {
            // Quick for 0-650
            posDuration += 30 * LOOKALIKE_FACTOR
          }
        }
        acc[pos] = moment(prevEvent).subtract(posDuration, 'minutes')
      }
      return acc
    }, {})

    setBreached(breached)
    let tlPositions = ['acquire', 'createPhish']
    let lastEvent
    if (timeline.breach) {
      tlPositions.push('launchPhish')
      lastEvent = timeline.breach
    } else {
      lastEvent = timeline.launchPhish
    }
    const diff = lastEvent.diff(timeline.research)
    tlPositions = tlPositions.map(event => 100 - ((lastEvent.diff(timeline[event]) / diff) * 100))
    tlPositions = [0, ...tlPositions, 100]
    // Adjust positions to remove overlap
    tlPositions.forEach((pos, index, array) => {
      if (index > 0) {
        const prev = array[index - 1]
        if (pos - prev < TIMELINE_POSITION_PADDING) {
          array[index] = prev + TIMELINE_POSITION_PADDING
        }
      }
    })
    const lastPosition = tlPositions[tlPositions.length - 1]
    if (lastPosition > 100) {
      const scaleFactor = tlPositions[tlPositions.length - 1] / 100
      tlPositions = tlPositions.map((p, index) => {
        if (index === 0) {
          return 0
        } else if (index === tlPositions.length - 1) {
          return 100
        }
        return p / scaleFactor
      })
    }
    tlPositions = tlPositions.map(v => `${v}%`)
    setTimelinePositions(tlPositions)
    setTimeToBreach(getDurationString(lastEvent.diff(timeline.research), { maxUnits: 2 }))
  }, [riskReport, sample])

  const desc = I18n.t('description', ttbTrOpt)

  return (
    <RiskReportCard
      {...{ className }}
      span={12}
    >
      <div className='risk-report-ttb-header'>
        <div className='risk-report-ttb-title'>{I18n.t('title', ttbTrOpt)}</div>
        <div className='risk-report-ttb-value'>{timeToBreach}</div>
      </div>
      <div className='risk-report-ttb-tl'>
        <div className='risk-report-ttb-line'>
          <BreachMarker label={I18n.t('research', ttbTrOpt)} left={timelinePositions[0]} color={theme.riskScoreVeryLow} />
          <BreachArrow start={timelinePositions[0]} end={timelinePositions[1]} color={theme.riskScoreVeryLow} />
          <BreachMarker label={I18n.t('acquire', ttbTrOpt)} left={timelinePositions[1]} color={theme.riskScoreLow} above />
          <BreachArrow start={timelinePositions[1]} end={timelinePositions[2]} color={theme.riskScoreLow} />
          <BreachMarker label={I18n.t('createPhish', ttbTrOpt)} left={timelinePositions[2]} color={theme.riskScoreMedium} />
          <BreachArrow start={timelinePositions[2]} end={timelinePositions[3]} color={theme.riskScoreMedium} />
          <BreachMarker label={I18n.t('launchPhish', ttbTrOpt)} left={timelinePositions[3]} color={theme.riskScoreHigh} above />
          {breached && (
            <>
              <BreachArrow start={timelinePositions[3]} end={timelinePositions[4]} color={theme.riskScoreHigh} />
              <BreachMarker label={I18n.t('breach', ttbTrOpt)} left={timelinePositions[4]} color={theme.riskScoreVeryHigh} />
            </>
          )}
        </div>
      </div>
      <div className='risk-report-ttb-desc'>{renderParagraphsFragmentFromArray(desc) || <p>{desc}</p>}</div>
    </RiskReportCard>
  )
})

export const RiskReportTimeToBreach = styled(_RiskReportTimeToBreach)`
  .risk-report-ttb {
    &-header {
      margin-bottom: 40px;
      text-align: center;
    }
    &-title {
      font-size: 24px;
    }
    &-value {
      color: #FF0000;
      font-size: 36px;
    }
    &-tl {
      margin: 0 auto;
      max-width: 800px;
      padding: 120px 50px 150px;
      width: 100%;
    }
    &-line {
      background-color: ${rgba('#D8D8D8', 0.25)};
      height: 10px;
      position: relative;
      width: 100%;
    }
    &-desc {
      margin: 20px auto 0;
      max-width: 800px;
      padding: 0 50px;
      width: 100%;
    }
  }
  @media print {
    .risk-report-ttb-desc {
      max-width: none;
      padding: 0px;

      p {
        line-height: 1.3;
      }
    }
  }
`

const _BreachCardLogo = ({ logo, className }) => (
  <div {...{ className }}>
    <div className='breach-info-logo' style={{ backgroundImage: `url(${logo})` }} />
  </div>
)

const BreachCardLogo = styled(_BreachCardLogo)`
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 5px;
  height: 90px;
  margin-right: 25px;
  width: 90px;

  &> div {
    align-self: flex-start;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
  }
`

const StyledBreachCard = styled.div`
  .breach-info-header {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
  }
  .breach-info-detail {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    h4 {
      font-size: 22px;
      line-height: 1;
      margin-bottom: 0;
      margin-right: 10px;
      width: calc(100% - 60px);
    }
  }
  .breach-info-count {
    align-items: center;
    background-color: ${({ theme }) => theme.defaultPrimary};
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
    width: 50px;

    span {
      color: ${({ theme }) => theme.white};
      font-weight: bold;
      margin-top: 6px;
    }
  }
  .breach-info-date {
    align-self: flex-start;
    margin: 10px 20px 0 0;
  }
  .ant-tag {
    margin: 0 5px 5px 0;
    padding-top: 3px;
  }
  p {
    font-family: unset;
  }
`

export const BreachCardScroller = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  flex: 1;
  min-height: 0;
  overflow: auto;
  padding: 10px 5px;
`

export const BreachCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${StyledBreachCard} {
    padding: 0 5px 10px;
    width: 50%;
    break-inside: avoid;

    .ant-card {
      height: 100%;
    }

    @media (max-width: 890px) {
      width: 100%;

      .breach-info-date div {
        display: inline-block;

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    @media print {
      width: 50%;
      display: inline-block;
    }

  }
`

export const BreachCard = ({
  service: {
    logo,
    title,
    count,
    breachDate,
    addedDate,
    modifiedDate,
    dataClasses,
    description,
    severity
  },
  inEng,
  useDataClassColours = false,
  theme,
  trOpt = { scope: 'riskReport.uBreach.categoryInfo' }
}) => (
  <StyledBreachCard>
    <Card>
      <div className='breach-info-header'>
        {logo && <BreachCardLogo {...{ logo }} />}
        <div className='breach-info-detail'>
          <h4>{title}</h4>
          <div className='breach-info-count'>
            <span>{count}</span>
          </div>
          {breachDate && (
            <div className='breach-info-date'>
              <div>{I18n.t('breachDate', trOpt)}:</div>
              <div>{breachDate}</div>
            </div>
          )}
          {addedDate && (
            <div className='breach-info-date'>
              <div>{I18n.t('addedDate', trOpt)}:</div>
              <div>{addedDate}</div>
            </div>
          )}
          {modifiedDate && (
            <div className='breach-info-date'>
              <div>{I18n.t('modifiedDate', trOpt)}:</div>
              <div>{modifiedDate}</div>
            </div>
          )}
          {severity && (
            <div className='breach-info-date'>
              <div>{I18n.t('severity', trOpt)}:</div>
              <SeverityField severity={severity} />
            </div>
          )}
        </div>
      </div>
      {inEng && <p dangerouslySetInnerHTML={{ __html: description }} />}
      <div>
        {
          useDataClassColours && UBREACH_V2_ENABLED
            ? (
              renderDataClasses(dataClasses)
            )
            : (
              dataClasses.map((dataClass, index) => (
                <Tag key={index} color={theme.defaultPrimary}>{UBREACH_V2_ENABLED ? I18n.t(`uBreach.dataClasses.${dataClass}`, { defaults: [{ message: dataClass }] }) : dataClass}</Tag>
              ))
            )
        }
      </div>
    </Card>
  </StyledBreachCard>
)

const _RiskReportPrintCover = ({ className, companyName, logo, trOpt = { scope: 'riskReport' } }) => (
  <div {...{ className }}>
    <Logo {...{ logo }} />
    <h1 style={{ margin: 0 }}>{I18n.t('title', { ...trOpt, companyName: '' })}</h1>
    {companyName && <div>{companyName}</div>}
  </div>
)

export const RiskReportPrintCover = styled(_RiskReportPrintCover)`
  display: none;
  @media print {
    display: block;
    /* The !importants here are to fix printing in Firefox */
    padding-top: 300px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    height: calc(100vh / 0.9);
    width: 100%;
    position: relative;
    border: 0 !important;
    border-radius: 0 !important;
    background-color: ${({ theme }) => theme.reportDocumentHeaderBackgroundColor} !important;
    color: ${({ theme }) => theme.reportDocumentHeaderFontColor} !important;
    font-size: 30px;
    h1 {
      font-size: 60px;
      color: ${({ theme }) => theme.reportDocumentHeaderFontColor};
      margin-bottom: 0;
    }
  }
`

export const RiskReportHeader = styled.div`
  background-color: ${({ theme }) => theme.white};
  height: 103px;
  padding-left: calc(${PAGE_X_PAD_DESKTOP_REM}rem + 20px);
  padding-right: calc(${PAGE_X_PAD_DESKTOP_REM}rem + 20px);
  width: 100%;

  @media (max-width: ${BREAKPOINT}) {
    padding-left: calc(${PAGE_X_PAD_MOBILE_REM}rem + 20px);
    padding-right: calc(${PAGE_X_PAD_MOBILE_REM}rem + 20px);
  }
`

export const RiskReportHeaderBody = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
  width: 100%;
`

const _RiskReportHeaderLogo = ({ loading = false, className }) => (
  <div {...{ className }}>
    {
      loading
        ? <div className='title-logo-placeholder' />
        : <div className='title-logo-image' />
    }
  </div>
)
export const RiskReportHeaderLogo = styled(_RiskReportHeaderLogo)`
  .title-logo-image {
    background-image: url('${({ theme, logo }) => logo || theme.appThemeLogo}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 6.6em;
    width: 12em;
  }

  .title-logo-placeholder {
    height: 1.5em;
  }
`

export const RiskReportHeaderInfo = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${RiskScoreMini} {
    margin-left: 20px;
  }
`

export const RiskReportHeaderTitle = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 7px;
  }
`

const _RiskReportTextCard = props => {
  const { theme } = props
  return (
    <RiskReportCard
      backgroundColor={rgba(theme.reportDark, 0.9)}
      fontColor={theme.white}
      {...props}
    />
  )
}

export const RiskReportTextCard = styled(_RiskReportTextCard)`
  h1 {
    font-size: 30px;
    line-height: 1;
  }
  ${RiskReportCardBody} {
    h1, h2 {
      color: ${({ theme }) => theme.defaultPrimary};
    }
    padding: 60px 70px;
  }
  p {
    margin-bottom: 10px;
  }

  .ant-btn {
    margin-top: 20px;
  }

  @media (max-width: 969px) {
    width: 100%;
  }
  @media print {
    ${RiskReportCardBody} {
      background-color: transparent;
      padding: 15px;

      p {
        color: ${({ theme }) => theme.copy};
        line-height: 1.3;
      }
    }
  }
`

export const ErrorAlertWrapper = styled.div`
  height: calc(100vh - 110px);
  padding: calc(${PAGE_X_PAD_DESKTOP_REM}rem);
`
