import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { rgba } from 'polished'
import I18n from 'i18n-js'
import moment from 'moment'
import styled from 'styled-components'
import { Progress } from 'antd'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { useWindowSize } from '../../hooks/useWindowSize'
import useTheme from '../../hooks/useTheme'
import ErrorAlerts from '../../components/common/ErrorAlerts'
import { connect } from '../../hocs'
import { getSessionAndSettings } from '../../state/selectors'
import { ContentWrap } from '../../components/common/Layout'
import { RoundedCard, Column, ConditionalLayout, GridWrapper, GridLayoutWrap } from '../../components/EndUserPortal/GridFlexiLayout'
import { TotalRiskScoreCard, PhishRiskScoreCard, AverageScoreProgress, SectionHeader, showInfoModal, PolicyProgress, getPercentAndStrokeColour, RiskOverTimeChart, OutstandingCourses, OutstandingPolicies } from '../../components/EndUserPortal/DashboardComponents'
import { GET_END_PAGE_DATA } from '../../components/Queries/EndUser'
import { LoadingBlock } from '../../components/common'
import { RISK_SCORE_CONFIG } from '../../components/Queries/Reports'
import { extractOutstandingCourses, riskOverTimeDurations, buildInfoPopUpMessage, processCourseResults } from '../../helpers/endUser'
import routes from '../../constants/routes'
import { LockedCardOverlay } from '../../components/Reports/RiskReport'
import { captureSentryError } from '../../helpers/sentry'

const StatsTitleText = styled.h4`
  margin-bottom: 0;
  line-height: 1;
`
const NoRiskScoreOverlay = styled(LockedCardOverlay)`
    z-index: 2;
    position: absolute;
    background-color: ${({ theme }) => rgba(theme.secondary, 0.9)};
    margin-left: -24px;
    height: 100%;
    opacity: 90%;
    margin-top: -24px;
    border-radius: 15px;
    padding: 24px;
    p {
      font-weight: bold;
    }
`

const RiskAndStatLayout = styled(ConditionalLayout)`
  .ant-card {
    flex-basis: ${({ isRow }) => isRow ? 'calc(50% - 7.5px)' : '0%'}; /* Subtracted 7.5px to account for gap */
    flex-grow: ${({ isRow }) => isRow ? '0' : '1'};

    &.eup-total-risk-score {
      flex-basis: ${({ isRow, totalRiskScoreFullWidth }) => isRow ? (totalRiskScoreFullWidth ? '100%' : 'calc(50% - 7.5px)') : '0%'};
    }
  }
`

const trOpt = { scope: 'endUserPortal.dashboard' }
const trOptCommon = { scope: 'endUserPortal.common' }
const trOptReports = { scope: 'reports.dashboard' }
const statItems = [
  {
    key: 'opened',
    title: 'open'
  }, {
    key: 'visited',
    title: 'visit'
  }, {
    key: 'compromised',
    title: 'compromise'
  }, {
    key: 'reported',
    title: 'reported'
  }
]

const getFromDate = (durationKey) => {
  const duration = riskOverTimeDurations.find(duration => duration.id === durationKey)
  return moment().subtract(duration.fromVal, duration.fromUnit)
}

const Home = ({ session, settings }) => {
  const learnerId = _get(session, 'learnerId')
  const companyId = _get(session, 'companyId')
  const { type: screenType } = useWindowSize()
  const theme = useTheme()
  const isMobile = ['xs', 'sm'].includes(screenType)
  const isTablet = screenType === 'md'

  const [courseResults, setCourseResults] = useState([])
  const [policyResults, setPolicyResults] = useState([])
  const [phishStats, setPhishStats] = useState([])
  const [outstandingCourses, setOutstandingCourses] = useState([])
  const [outstandingPolicies, setOutstandingPolicies] = useState([])
  const [riskScores, setRiskScores] = useState(null)
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null)
  const [averageScore, setAverageScore] = useState(null)
  const [fromDate, setFromDate] = useState(getFromDate('1month'))
  const [durationKey, setDurationKey] = useState('1month')

  const { loading, error, data: homeData } = useQuery(GET_END_PAGE_DATA, {
    variables: {
      uPhishEnabled: settings.uPhish || false,
      fromDate
    },
    skip: !fromDate,
    onError: error => {
      captureSentryError(error, { msg: 'End User Portal - Home Load - ERROR' })
    }
  })
  const { data: { riskScoreConfig: config } = {} } = useQuery(RISK_SCORE_CONFIG)

  useEffect(() => {
    setFromDate(getFromDate(durationKey))
  }, [durationKey])

  useEffect(() => {
    if (homeData && !_isEmpty(homeData)) {
      setCourseResults(processCourseResults(_get(homeData, 'learnerMe.endUser.courseResults')))
      setPolicyResults(_get(homeData, 'learnerMe.endUser.policyResults'))
      setOutstandingCourses(
        extractOutstandingCourses(_get(homeData, 'learnerMe.endUser.courseResults'))
          .sort((a, b) => a.course.name.localeCompare(b.course.name))
      )
      setOutstandingPolicies(
        _get(homeData, 'learnerMe.endUser.policyResults')
          .filter((policy) => policy.signed === null)
          .sort((a, b) => a.policy.name.localeCompare(b.policy.name))
      )
      setRiskScores(_get(homeData, 'learnerMe.endUser.riskScores'))
      setRiskScoreOverTime(_get(homeData, 'learnerMe.endUser.riskScores.riskScoreOverTime'))
      setPhishStats(_get(homeData, 'learnerMe.endUser.phish'))
      setAverageScore(_get(homeData, 'learnerMe.endUser.averageCourseScore'))
    }
  }, [homeData])

  const policyProgress = settings.uPolicy
    ? <PolicyProgress locale={session.locale} policyResults={policyResults} outstandingPolicies={outstandingPolicies} theme={theme} trOpt={trOpt} link={routes.PORTAL_POLICY} companyId={companyId} />
    : null
  const averageScoreProgress = settings.uLearn
    ? <AverageScoreProgress locale={session.locale} trOpt={trOpt} courseResults={courseResults} averageScore={averageScore} theme={theme} companyId={companyId} />
    : null
  const noRiskScore = riskScores && riskScores.level == null
  const noRiskScoreOverlayComp = noRiskScore && (
    <NoRiskScoreOverlay
      readOnly
      message={I18n.t('noRiskScore', trOptReports)}
    />
  )
  return (
    <>
      <LoadingBlock loading={loading} fullScreen={false} showMenu />
      {error && <ContentWrap><ErrorAlerts error={error} defaultError={I18n.t('defaultPageError', trOptCommon)} /></ContentWrap>}
      {homeData && !_isEmpty(Object.keys(homeData)) && (
        <ContentWrap>
          <h2>{I18n.t('welcomeBack', { ...trOptCommon, firstName: session.firstName })}</h2>
          <GridWrapper screenType={screenType}>

            {settings.enableRiskScore && (
              <GridLayoutWrap screenType={screenType} xs={1} sm={1} md='span 4' lg={1}>
                <RiskAndStatLayout isRow={isTablet} totalRiskScoreFullWidth={!settings.uPhish}>
                  <TotalRiskScoreCard
                    className='eup-total-risk-score' phishStats={phishStats} riskScores={riskScores} config={config}
                    overlay={noRiskScoreOverlayComp}
                  />
                  {((settings.enableRiskScore && settings.uPhish) && !noRiskScore) && <PhishRiskScoreCard phishStats={phishStats} riskScores={riskScores} config={config} />}
                  {policyProgress}
                  {averageScoreProgress}
                </RiskAndStatLayout>
              </GridLayoutWrap>
            )}
            <GridLayoutWrap
              screenType={screenType} xs={1} sm={1} md='span 4'
              lg={(settings.enableRiskScore && (settings.uPolicy || settings.uLearn)) ? 'span 3' : 'span 4'}
            >
              <Column>
                {settings.enableRiskScore && <RiskOverTimeChart settings={settings} riskScoreOverTime={riskScoreOverTime} durationKey={durationKey} setDurationKey={setDurationKey} />}
                {settings.uPhish && (
                  <>
                    <SectionHeader id='phishing-simulations-header' title='phishingSimulations' trOpt={trOpt} icon='info-circle' action={() => showInfoModal(I18n.t('phishingSimulations', trOpt), buildInfoPopUpMessage())} />
                    <ConditionalLayout isRow={!isMobile}>
                      {statItems.map(({ key, title }, index) => {
                        if (key === 'reported' && !settings.phishAlertEnabled) return null
                        const data = phishStats[key] || 0
                        const { percent, strokeColour } = getPercentAndStrokeColour(phishStats.total, data, false, theme)
                        return (
                          <RoundedCard key={key} flex={settings.phishAlertEnabled ? '40%' : 'calc((100% / 3) - 15px)'}>
                            <Column className='justify align' alignContent='center' alignItems='center' justify='center'>
                              <div>
                                <Progress type='dashboard' successPercent={0} width={80} strokeWidth={9} percent={percent} strokeColor={strokeColour} />
                              </div>
                              <StatsTitleText>{I18n.t(title, trOpt)}</StatsTitleText>
                            </Column>
                          </RoundedCard>
                        )
                      })}
                    </ConditionalLayout>
                  </>
                )}
                {!settings.enableRiskScore && (
                  <ConditionalLayout isRow={!isMobile}>
                    {policyProgress}
                    {averageScoreProgress}
                  </ConditionalLayout>
                )}
              </Column>
            </GridLayoutWrap>

            <GridLayoutWrap screenType={screenType} xs={1} sm={1} md='span 4' lg='span 2' mobilePriorityOrder={-1}>
              <Column>
                {settings.uPolicy && <OutstandingPolicies {...{ learnerId, outstandingPolicies, isRowBool: false }} />}
                {settings.uLearn && <OutstandingCourses {...{ outstandingCourses, learnerId, isRowBool: false }} />}
              </Column>
            </GridLayoutWrap>
          </GridWrapper>
        </ContentWrap>
      )}
    </>
  )
}

export default connect(
  getSessionAndSettings
)(Home)
