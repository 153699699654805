import React, { useCallback, useMemo } from 'react'
import { getThemeColorForPercentage } from '../../helpers'
import { ResponsiveRadar } from '@nivo/radar'
import I18n from 'i18n-js'

export const UlearnPerformanceRadar = ({ theme, data = [], disabled = false }) => {
  const dotColor = useCallback(({ value = 0 }) => value > 0 ? getThemeColorForPercentage(value, theme, true) : 'rgba(0,0,0,0)', [theme])
  const dotLabel = useCallback(({ value }) => value > 0 ? value : '', [])
  const overallColor = useMemo(() => {
    const averageValue = data.length > 0 ? data.reduce((total, { value }) => total + value, 0) / data.length : 0
    return getThemeColorForPercentage(averageValue, theme, true)
  }, [theme, data])
  // Format tooltip - Custom label Implemented but tooltips are hidden
  // Nivo doesn't support custom tooltips so they can't be formatted to match v1 (Can't hide the default coloured square)
  const tooltipFormat = useCallback(value => value ? I18n.t('learner.courseRadarTooltip', { score: value }) : I18n.t('common.na'), [])
  // TODO Match grid level lines to v1 - Nivo doesn't allow selection of which levels show so it's either 5 or none
  // TODO Grid level labels - Can't see support for this in Nivo
  return (
    <ResponsiveRadar
      data={data}
      keys={['value']}
      indexBy='category'
      maxValue={100}
      margin={{ top: 70, right: 80, bottom: 50, left: 80 }}
      curve='linearClosed'
      borderWidth={3}
      borderColor={overallColor}
      gridLevels={5}
      gridShape='linear'
      gridLabelOffset={25}
      enableDots
      dotSize={22}
      dotColor={dotColor}
      enableDotLabel
      dotLabel={dotLabel}
      dotLabelYOffset={4}
      colors={[overallColor]}
      fillOpacity={0.25}
      blendMode='multiply'
      animate
      motionStiffness={90}
      motionDamping={15}
      isInteractive={false}
      tooltipFormat={tooltipFormat}
      theme={{
        axis: {
          ticks: {
            text: {
              fontFamily: 'inherit',
              fontSize: 12
            }
          }
        },
        dots: {
          text: {
            fill: 'white',
            fontFamily: 'inherit',
            fontSize: 10
          }
        },
        grid: {
          stroke: '#dddddd'
        }
      }}
    />
  )
}
