import React from 'react'
import _isArray from 'lodash/isArray'

export const mapStringArrayToLi = list =>
  _isArray(list) ? (
    list.map((item, index) =>
      <li key={index}>{item}</li>
    )
  ) : null

export const mapHtmlArrayToLi = list =>
  _isArray(list) ? (
    list.map((item, index) =>
      <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
    )
  ) : null
export const mapStringArrayToP = list =>
  _isArray(list) ? (
    list.map((item, index) =>
      <p key={index}>{item}</p>
    )
  ) : null

export const mapHtmlArrayToP = list =>
  _isArray(list) ? (
    list.map((item, index) =>
      <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
    )
  ) : null

export const renderListFromArray = list =>
  _isArray(list) ? (
    <ul>{mapStringArrayToLi(list)}</ul>
  ) : null

export const renderListFromHtmlArray = list =>
  _isArray(list) ? (
    <ul>{mapHtmlArrayToLi(list)}</ul>
  ) : null

export const renderListFragmentFromArray = list =>
  _isArray(list) ? (
    <>{mapStringArrayToLi(list)}</>
  ) : null

export const renderListFragmentFromHtmlArray = list =>
  _isArray(list) ? (
    <>{mapHtmlArrayToLi(list)}</>
  ) : null

export const renderParagraphsFragmentFromArray = list =>
  _isArray(list) ? (
    <>{mapStringArrayToP(list)}</>
  ) : null

export const renderParagraphsFragmentFromHtmlArray = list =>
  _isArray(list) ? (
    <>{mapHtmlArrayToP(list)}</>
  ) : null
