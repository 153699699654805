import I18n from 'i18n-js'

const trOpt = { scope: 'common.contentType' }

export const CONTENT_TYPE_NAMES = {
  get material () { return I18n.t('material', trOpt) },
  get multipleChoice () { return I18n.t('multipleChoice', trOpt) },
  get vimeo () { return I18n.t('vimeo', trOpt) },
  get cards () { return I18n.t('cards', trOpt) },
  get annotatedImage () { return I18n.t('annotatedImage', trOpt) },
  get checklist () { return I18n.t('checklist', trOpt) },
  get carousel () { return I18n.t('carousel', trOpt) }
}

export { default as ChangedIndicator } from './ChangedIndicator'
