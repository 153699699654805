/* global Blob */
/**
 * Generates a absolute URL for a provided path. Expected by manifest files.
 * This will return the path passed into provided with an absolute URL
 * @param {String} relativePath - The relative path you wish to convert
 * @param {String} origin - The origin or base URL you wish to obtain an absolute URL for the path provided. Defaults to window.location.origin
 * @returns {String} An absolute URL for the relative path and origin
 */
export const getAbsoluteURL = (relativePath, origin = window.location.origin) => {
  return new URL(relativePath, origin).href
}

export const getDataURL = manifest => {
  // Based on this - https://stackoverflow.com/a/57856162
  const blob = new Blob([JSON.stringify(manifest)], { type: 'application/json' })
  return URL.createObjectURL(blob)
}
