import React, { useCallback, useRef } from 'react'
import _pick from 'lodash/pick'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import routes from '../../constants/routes'
import BrandedMicrosoftAuthButton from './BrandedMicrosoftAuthButton'
import SyncActions from '../Sync/SyncActions'
import Microsoft365SyncSelectAuthFlowModal from '../Modals/Microsoft365SyncSelectAuthFlowModal'
import { OFFICE_365_GROUP_TYPES } from '../../constants/office365'
import { MS_SYNC_GROUPS_PAGE } from '../Queries/Learners'

const MicrosoftSyncActions = ({ office365, locale, isUpdateAllowed }) => {
  const selectAuthRef = useRef(null)
  const onAuthClick = useCallback(() => {
    if (isUpdateAllowed && selectAuthRef.current) {
      selectAuthRef.current.open()
    }
  }, [isUpdateAllowed])

  return (
    <SyncActions
      settings={office365}
      groupTypes={OFFICE_365_GROUP_TYPES}
      usePaginatedGroupLoad
      groupsQuery={MS_SYNC_GROUPS_PAGE}
      groupsQueryKey='microsoftSyncGroupsPage'
      setupRoute={routes.OFFICE_365_SETUP}
      signInButton={<BrandedMicrosoftAuthButton disabled={!isUpdateAllowed} onClick={onAuthClick} locale={locale} />}
      authFlowModal={<Microsoft365SyncSelectAuthFlowModal ref={selectAuthRef} />}
      syncType='microsoft'
      trOptScopeKey='office365'
      completeTrOpt={{ scope: 'office365Setup.setupComplete' }}
      revokeButtonOptions={[
        {
          action: 'clearAll',
          trOption: { scope: 'office365.actions.office365RevokeAllConfiguration' }
        },
        {
          action: 'clearAuth',
          trOption: { scope: 'office365.actions.office365RevokeAuthentication' }
        }
      ]}
      {...{ isUpdateAllowed }}
    />
  )
}

export default connect(
  state => ({
    ..._pick(selectors.settings.get(state), ['office365']),
    locale: selectors.session.sessionLocale(state)
  })
)(MicrosoftSyncActions)
