export const get = state => state.session
export const getSession = get
export const sessionInitialised = state => ({ initialised: get(state).initialised })
export const sessionLocale = state => get(state).locale
export const sessionContentLocales = state => get(state).contentLocales
export const mfaToken = state => get(state).mfaToken

export default {
  get,
  getSession,
  sessionInitialised,
  sessionLocale
}
