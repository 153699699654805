import React from 'react'
import { Icon } from 'antd'
import { withTheme } from 'styled-components'

/*
 * This component is a Ant D Icon wrapper where the icon defaults to utilise the theme primary colour
 * @param {String} iconType defaults to 'info-circle' this is assigned to Ant D Icon type
 * @param {String} iconStyle defaults to 'filled' this is assigned to Icon Ant D Icon theme
 * @returns TooltipsIcon
 */
const TooltipIcon = ({ theme, iconType = 'info-circle', iconStyle = 'filled' }) => (
  <Icon type={iconType} theme={iconStyle} style={{ color: theme.primary }} />
)
export default withTheme(TooltipIcon)
