import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { BasicTooltip } from '@nivo/tooltip'
import I18n from 'i18n-js'

const trOpt = { scope: 'reports.uLearnReport' }

const uLearnPieChart = ({ participationPieChartData }) => {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4 style={{ margin: 0 }}>{I18n.t('courseParticipation', trOpt)}</h4>
      </div>
      <div style={{ height: '490px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ResponsivePie
          data={participationPieChartData}
          margin={{ top: 75, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.8}
          padAngle={1}
          cornerRadius={0}
          colors={['#F8DC4A', '#5EB77E', '#f75959', '#17baf7']}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          radialLabelsSkipAngle={10}
          radialLabelsTextColor='#333333'
          radialLabelsLinkColor={{ from: 'color' }}
          radialLabel={d => `${I18n.t(d.id, trOpt)}: ${d.value}`}
          sliceLabelsSkipAngle={10}
          sliceLabelsTextColor='#333333'
          tooltip={({ datum }) => (
            <BasicTooltip
              id={
                <span>{I18n.t(datum.id, trOpt)}: {datum.formattedValue}</span>
              }
              color={datum.color}
              enableChip
            />
            )
          }
        />
      </div>
    </div>
  )
}

export default uLearnPieChart
