import I18n from 'i18n-js'

const trOpt = { scope: 'modals.editCompanyModal.regions' }

const REGIONS = [
  'uk', 'ie', 'emea', 'apac', 'na', 'ca'
]

const REGIONS_WITH_TRANSLATIONS = REGIONS.map(rg => ({ value: rg, label: I18n.t(rg, trOpt) }))

export const REGION_SELECT_OPTIONS = REGIONS_WITH_TRANSLATIONS.map((region) => { return { value: region.value, get label () { return region.label } } })
