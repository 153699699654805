import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Statistic, Switch, Tooltip } from 'antd'
import styled, { withTheme } from 'styled-components'
import moment from 'moment'
import I18n from 'i18n-js'

import { GET_SIMULATION } from '../../components/Queries/uPhish'
import LearnersView from '../../components/Learners/LearnersView'
import { FontAwesomeIcon, ListHeaderPanel } from '../../components/common'
import { formatDateTime } from '../../helpers/datetime'
import { SIM_RESULT_DELIVERY_METHODS, SIM_RESULT_DELIVERY_METHOD_IDS, SIM_RESULT_DELIVERY_METHOD_OPTIONS } from '../../constants/uPhish'
import { idArrayColumnSorter } from '../../helpers/listPages'
import { ENABLE_MESSAGE_INJECTION } from '../../constants/environment'

const trOpt = { scope: 'uPhish.viewSimulationUsers' }

const STATUS = {
  notSent: {
    sort: 0,
    text: 'common.notSent',
    themeColor: 'grey'
  },
  sent: {
    sort: 1,
    text: 'common.sent',
    themeColor: 'simulationSend'
  },
  open: {
    sort: 2,
    text: 'common.opened',
    themeColor: 'simulationOpen'
  },
  visit: {
    sort: 3,
    text: 'common.visited',
    themeColor: 'simulationVisit'
  },
  compromise: {
    sort: 4,
    text: 'common.compromised',
    themeColor: 'simulationCompromise'
  },
  report: {
    sort: 5,
    text: 'uPhish.simulations.reported',
    themeColor: 'simulationReport'
  }
}

const STATUS_FILTERS = Object.keys(STATUS).map(status => ({
  get text () { return I18n.t(STATUS[status].text) },
  value: status
}))

const REPORTED_STATUS = {
  yes: {
    sort: 0,
    text: 'common.yes',
    themeColor: 'riskScoreVeryLow'
  },
  no: {
    sort: 1,
    text: 'common.no',
    themeColor: 'grey'
  },
  na: {
    sort: 2,
    text: 'common.na',
    themeColor: 'grey'
  }
}
const REPORTED_STATUS_FILTERS = Object.keys(REPORTED_STATUS).map(status => ({
  get text () { return I18n.t(REPORTED_STATUS[status].text) },
  value: status
}))

const statusColumnSorter = (columnId, statusObj, a, b) => {
  const { sort: aSort = 0 } = statusObj[a[columnId]]
  const { sort: bSort = 0 } = statusObj[b[columnId]]

  return aSort - bSort
}

const SCHEDULED_COL_TITLE = {
  get pending () { return I18n.t('scheduled', trOpt) },
  get started () { return I18n.t('scheduledSentAt', trOpt) },
  get finished () { return I18n.t('sentAt', trOpt) }
}

const LEARNER_VIEW_ACTIONS = ['exportSimulationData', 'enrollOnCourse']

const DELIVERY_METHOD_FA_ICONS = {
  smtp: 'envelope',
  googleMessageInjection: 'google',
  microsoftMessageInjection: 'microsoft'
}

const RecipientLine = styled.span`
  display: block;
  font-size: ${({ sub }) => sub ? '12px' : 'inherit'};
`

const LearnersContainer = styled.div`
  ${ListHeaderPanel} {
    align-items: center;
  }
`

const EngagedSwitch = styled.div`
  .ant-switch {
    margin-right: 10px;
  }
`

const EventCountStatistic = styled(Statistic)`
  .ant-statistic-title, .ant-statistic-content-value {
    color: ${({ theme, themeColor }) => theme[themeColor]};
  }
  .ant-statistic-content-suffix {
    color: ${({ theme }) => theme.darkGrey};
  }
`

const StatCol = styled.div`
  padding: 16px;
`

const StatRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  left: -16px;
  margin-bottom: 15px;
  position: relative;
  width: calc(100% + 32px);

  ${StatCol} {
    width: ${({ showPhishAlert }) => showPhishAlert ? 20 : 25}%;
  }
`

const ViewSimulationUsers = ({ id, simulation, loading, error, theme, showPhishAlert = false, setPollingEnabled }) => {
  const { status: simStatus, queue = [], results = [], recipientCount, sentCount, openCount, visitCount, compromiseCount, reportCount } = simulation

  const [status, updateStatus] = useState('all')
  const [searchFilterText, updateSearchFilterText] = useState('')
  const columns = useMemo(() => {
    const columns = [{
      title: I18n.t('common.recipient'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      render (name, learner, index, nameComponent) {
        return (
          <div>
            <RecipientLine>{nameComponent || name}</RecipientLine>
            <RecipientLine sub>{learner.email}</RecipientLine>
          </div>
        )
      }
    }, {
      title: SCHEDULED_COL_TITLE[simStatus || 'pending'],
      dataIndex: 'scheduledStr',
      key: 'scheduledStr',
      width: 300,
      sorter: (a, b) => {
        const aMoment = a.scheduled
        const bMoment = b.scheduled
        if (aMoment.isAfter(bMoment)) {
          return 1
        } else if (aMoment.isBefore(bMoment)) {
          return -1
        }
        return 0
      }
    }, {
      title: I18n.t('common.status'),
      dataIndex: 'status',
      key: 'status',
      width: 170,
      filters: STATUS_FILTERS.filter(f => f.value !== 'report'),
      filterMultiple: true,
      onFilter: (status, learner) => learner.status === status,
      defaultSortOrder: 'descend',
      sorter: (a, b) => statusColumnSorter('status', STATUS, a, b),
      render: (statusType) => {
        const { text, themeColor } = STATUS[statusType]
        return (
          <Badge text={I18n.t(text)} color={theme[themeColor]} />
        )
      }
    }]

    if (simStatus !== 'pending') {
      if (showPhishAlert && simStatus !== 'pending') {
        columns.push({
          title: I18n.t('uPhish.simulations.reported'),
          dataIndex: 'reported',
          key: 'reported',
          width: 170,
          filters: REPORTED_STATUS_FILTERS,
          filterMultiple: true,
          onFilter: (reported, learner) => learner.reported === reported,
          sorter: (a, b) => statusColumnSorter('reported', REPORTED_STATUS, a, b),
          render: (reported, learner) => {
            const { text, themeColor } = REPORTED_STATUS[reported]
            return (
              <Badge text={I18n.t(text)} color={theme[themeColor]} />
            )
          }
        })
      }

      if (ENABLE_MESSAGE_INJECTION) {
        columns.push({
          title: I18n.t('uPhish.common.deliveryMethod'),
          dataIndex: 'deliveryMethod',
          key: 'deliveryMethod',
          width: 190,
          filters: SIM_RESULT_DELIVERY_METHOD_OPTIONS.map(({ label: text, value }) => ({ text, value })),
          filterMultiple: true,
          onFilter: (deliveryMethod, learner) => learner.deliveryMethod === deliveryMethod,
          sorter: (a, b) => idArrayColumnSorter('deliveryMethod', SIM_RESULT_DELIVERY_METHOD_IDS, a, b),
          render: (deliveryMethod) => {
            if (!deliveryMethod) return null
            return (
              <Tooltip
                placement='bottom'
                title={SIM_RESULT_DELIVERY_METHODS[deliveryMethod]}
              >
                <span>
                  <FontAwesomeIcon icon={DELIVERY_METHOD_FA_ICONS[deliveryMethod]} />
                </span>
              </Tooltip>
            )
          }
        })
      }
    }

    return columns
  }, [theme, simStatus, showPhishAlert])

  const [allLearners, setAllLearners] = useState([])
  const [learners, setLearners] = useState([])
  useEffect(() => {
    setAllLearners(
      [
        ...queue.map(recipient => ({ ...recipient, isQueue: true })),
        ...results
      ].reduce((learners, recipient) => {
        const { learner: rawLearner, isQueue = false, open, compromise, visit, report, scheduled: queued, createdAt: sentAt, deliveryMethod } = recipient
        if (rawLearner) {
          const scheduled = isQueue ? queued : sentAt
          let engaged = false
          let status = 'notSent'
          if (!isQueue) {
            status = 'sent'

            engaged = Object.entries({ compromise, visit, open }).some(([eventType, event]) => {
              if (event) {
                status = eventType
                return true
              }
              return false
            })
          }
          let reported = 'na'
          if (!isQueue) {
            reported = showPhishAlert && report ? 'yes' : 'no'
          }

          learners.push({
            ...rawLearner,
            status,
            engaged,
            reported,
            scheduled: moment(scheduled),
            scheduledStr: formatDateTime(scheduled),
            deliveryMethod: isQueue ? null : deliveryMethod || 'smtp'
          })
        }
        return learners
      }, [])
    )
  }, [queue, results, setAllLearners, showPhishAlert])

  const panelLeft = ['status']

  const [restrictToEngaged, setRestrictToEngaged] = useState(false)
  const onEngagedChange = useCallback(checked => setRestrictToEngaged(checked), [setRestrictToEngaged])

  if (simStatus !== 'pending') {
    panelLeft.push(
      <EngagedSwitch>
        <Switch checked={restrictToEngaged} onChange={onEngagedChange} />
        <span>{I18n.t('showEngagedUsers', trOpt)}</span>
      </EngagedSwitch>
    )
  }

  useEffect(() => {
    let learners = [...allLearners]
    if (simStatus !== 'pending' && restrictToEngaged) {
      learners = learners.filter(learner => learner.engaged)
    }
    if (status === 'inactive') {
      learners = learners.filter(learner => learner.inactive === true)
    } else if (status === 'active') {
      learners = learners.filter(learner => learner.inactive !== true)
    } else if (status === 'managers') {
      learners = learners.filter(learner => learner.isManager === true && learner.inactive === false)
    }
    setLearners(learners)
  }, [allLearners, status, simStatus, restrictToEngaged, setLearners])

  return (
    <>
      <StatRow {...{ showPhishAlert }}>
        <StatCol>
          <EventCountStatistic title={I18n.t(STATUS.sent.text)} themeColor={STATUS.sent.themeColor} value={sentCount} suffix={`/ ${recipientCount}`} />
        </StatCol>
        <StatCol>
          <EventCountStatistic title={I18n.t(STATUS.open.text)} themeColor={STATUS.open.themeColor} value={openCount} suffix={`/ ${recipientCount}`} />
        </StatCol>
        <StatCol>
          <EventCountStatistic title={I18n.t(STATUS.visit.text)} themeColor={STATUS.visit.themeColor} value={visitCount} suffix={`/ ${recipientCount}`} />
        </StatCol>
        <StatCol>
          <EventCountStatistic title={I18n.t(STATUS.compromise.text)} themeColor={STATUS.compromise.themeColor} value={compromiseCount} suffix={`/ ${recipientCount}`} />
        </StatCol>
        {showPhishAlert && (
          <StatCol>
            <EventCountStatistic title={I18n.t(STATUS.report.text)} themeColor={STATUS.report.themeColor} value={reportCount} suffix={`/ ${recipientCount}`} />
          </StatCol>
        )}
      </StatRow>
      <LearnersContainer>
        <LearnersView
          {...{ loading, error, learners, panelLeft, columns, status, updateStatus, searchFilterText, updateSearchFilterText, setPollingEnabled }}
          showGroups={false}
          panelRight={['search', 'actions']}
          defaultSortByName={false}
          refetchQueries={[
            {
              query: GET_SIMULATION,
              variables: { id }
            }
          ]}
          allowedActions={LEARNER_VIEW_ACTIONS}
        />
      </LearnersContainer>
    </>
  )
}

export default withTheme(ViewSimulationUsers)
