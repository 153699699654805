import React from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'

import { ContentBox, CardTitle } from './common'
import LearnerCourseSlate from './LearnerCourseSlate'
import { ProgressButton } from '../common'
import { withTimer } from '../../hocs'
import { LearnerCourseBase } from './LearnerCourseBase'

export class LearnerCourseMaterial extends LearnerCourseBase {
  constructor (props) {
    super(props)

    this.completedTimer = null
    this.canProgress = this.canProgress.bind(this)
  }

  canProgress () {
    return !this.props.timerActive
  }

  componentDidMount () {
    this.startCompletionTimer()
  }

  componentDidUpdate (prevProps) {
    const { slide: { id } = {}, resetTimer } = this.props
    const { slide: { id: prevId } = {} } = prevProps

    if (id !== prevId) {
      this.startCompletionTimer()
    }

    // Reset timer
    if (id && prevId && id !== prevId) {
      resetTimer()
    }
  }

  componentWillUnmount () {
    this.props.resetTimer()
    clearTimeout(this.completedTimer)
  }

  render () {
    const { slide, canGoPrevSlide, goToNextSlide, goToPrevSlide, waitMS, timerActive } = this.props
    const { id, title } = slide

    return (
      <ContentBox
        material innerKey={id}
        buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
        buttonsRight={<ProgressButton {...{ timerActive, waitMS }} onClick={goToNextSlide} />}
      >
        <CardTitle>{title}</CardTitle>
        <LearnerCourseSlate content={_get(slide, 'content.intro')} />
      </ContentBox>
    )
  }
}

LearnerCourseMaterial.propTypes = {
  slide: PropTypes.object,
  goToNextSlide: PropTypes.func,
  resetTimer: PropTypes.func,
  timerActive: PropTypes.bool,
  update: PropTypes.func,
  waitMS: PropTypes.number
}

LearnerCourseMaterial.defaultProps = {
  slide: {},
  goToNextSlide: () => {},
  resetTimer: () => {},
  timerActive: false,
  update: () => {},
  waitMS: -1
}

export default withTimer(LearnerCourseMaterial)
