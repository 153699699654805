import React from 'react'
import I18n from 'i18n-js'
import { Tag } from 'antd'
import { HIGH_WEIGHTED_BREACH_DATA_CLASSES, MEDIUM_WEIGHTED_BREACH_DATA_CLASSES } from '../../constants/uBreach'

const trOpt = { scope: 'uBreach.dataClasses' }

const getDataClassTagColor = (dataClass) => {
  if (HIGH_WEIGHTED_BREACH_DATA_CLASSES.includes(dataClass)) {
    return 'red'
  } else if (MEDIUM_WEIGHTED_BREACH_DATA_CLASSES.includes(dataClass)) {
    return 'orange'
  } else {
    return 'geekblue'
  }
}

const DataClassTag = ({ dataClass }) => (
  <Tag
    key={dataClass}
    color={getDataClassTagColor(dataClass)}
    style={{ marginBottom: '5px' }}
  >
    {I18n.t(dataClass, { ...trOpt, defaults: [{ message: dataClass }] })}
  </Tag>
)

export default DataClassTag
