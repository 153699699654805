import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

import useGlobalState from '../../../hooks/useGlobalState'
import { DEFAULT_EMAIL_SETTINGS } from '../../../constants/settings'
import { getImageSize } from '../../../helpers'

const trOpt = { scope: 'settings.emailSettingsForm.preview' }

export const DEFAULT_EMAIL_THEME_LOGO = 'https://s3-eu-west-1.amazonaws.com/usecure/images/usecure-logo-white-email.png'
export const DEFAULT_EMAIL_THEME_LOGO_DIMENSIONS = { width: 200, height: 75 }
export const DEFAULT_EMAIL_THEME_BLUE_LOGO = 'https://usecure.s3-eu-west-1.amazonaws.com/images/usecure-logo-blue-email.png'
export const DEFAULT_EMAIL_THEME_BLUE_LOGO_DIMENSIONS = { width: 200, height: 29 }

const EmailPreviewContainer = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: auto;
  padding: 20px 0;
`

const EmailPreviewBody = styled.div`
  background-color: #ffffff;
  font-family: arial, helvetica, sans-serif;
  margin: 0 auto;
  max-width: 600px;
  min-width: 320px;
  padding-bottom: 40px;

  h4 {
    margin: 0 0 10px;
    color:${({ themePrimaryColor }) => themePrimaryColor};
    font-family: Arial,sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    padding: 0;
    text-align: left;
    word-wrap: normal;
  }

  p {
    color: #000000;
    font-family: arial, helvetica, sans-serif;
    font-size: 14px;
    line-height: 190%;
    margin: 0;
  }
`

const EmailPreviewLogo = styled.div`
  background-color: transparent;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
`

const EmailPreviewHeader = styled.div`
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  padding: 40px 10px;
  text-align: center;
`

const EmailPreviewMessage = styled.div`
  padding: 26px;
`

const EmailPreviewButtonContainer = styled.div`
  text-align: center;
`

const EmailPreviewButton = styled.div`
  background-color: ${({ themePrimaryColor }) => themePrimaryColor};
  border: 1px solid ${({ themePrimaryColor }) => themePrimaryColor};
  border-radius: 4px;
  color: ${({ themeSecondaryColor }) => themeSecondaryColor};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.2;
  padding: 10px 20px;
  text-decoration: none;
`

const getScaledWidth = (targetHeight, width, height) => ({ width: (width * (targetHeight / height)), height: targetHeight })
const getScaledHeight = (targetWidth, width, height) => ({ width: targetWidth, height: (height * (targetWidth / width)) })

export const getEmailLogoDimensions = async (themeLogo) => {
  let logoDimensions
  if (themeLogo === DEFAULT_EMAIL_THEME_LOGO) {
    logoDimensions = DEFAULT_EMAIL_THEME_LOGO_DIMENSIONS
  } else if (themeLogo === DEFAULT_EMAIL_THEME_BLUE_LOGO) {
    logoDimensions = DEFAULT_EMAIL_THEME_BLUE_LOGO_DIMENSIONS
  } else {
    const { width, height } = await getImageSize(themeLogo)
    const aspectRatio = width / height

    if (aspectRatio < 1) {
      // Portrait - Target height 125, scale width to fit
      logoDimensions = getScaledWidth(125, width, height)
    } else if (aspectRatio > 1 && aspectRatio <= 2) {
      // Landscape - Narrow - target height 100
      logoDimensions = getScaledWidth(100, width, height)
    } else if (aspectRatio > 2 && aspectRatio <= 3.5) {
      // Landscape - Medium - target width 250
      logoDimensions = getScaledHeight(250, width, height)
    } else if (aspectRatio > 3.5) {
      // Landscape - Wide - target height 75
      logoDimensions = getScaledWidth(75, width, height)
      if (logoDimensions.width > 300) {
        // Landscape - ultrawide - target width 350
        logoDimensions = getScaledHeight(350, width, height)
      }
    } else if (aspectRatio === 1) {
      // Square - 100x100
      logoDimensions = { width: 100, height: 100 }
    }
  }

  return logoDimensions
}

const EmailTestPreview = React.forwardRef(({
  visible,
  themeLogo = DEFAULT_EMAIL_SETTINGS.themeLogo.en,
  themePrimaryColor = DEFAULT_EMAIL_SETTINGS.themePrimaryColor.en,
  themeSecondaryColor = DEFAULT_EMAIL_SETTINGS.themeSecondaryColor.en,
  omitEmailHeader = false
}, ref) => {
  const { firstName } = useGlobalState(
    state => ({ firstName: state.session?.firstName })
  )
  const [imageWidth, setImageWidth] = useState(200)
  const [imageHeight, setImageHeight] = useState(29)

  const updateImageSize = useCallback(async themeLogo => {
    try {
      const { width, height } = await getEmailLogoDimensions(themeLogo)
      setImageWidth(width)
      setImageHeight(height)
    } catch (e) {
      console.error('EmailTestPreview.updateImageSize ERROR', e)
      throw e
    }
  }, [])

  useEffect(() => {
    updateImageSize(themeLogo)
  }, [themeLogo, updateImageSize])

  if (!visible) {
    return null
  }

  return (
    <EmailPreviewContainer>
      <EmailPreviewBody {...{ themePrimaryColor }}>
        {!omitEmailHeader && (
          <EmailPreviewHeader>
            <EmailPreviewLogo>
              <img src={themeLogo} alt={I18n.t('settings.emailSettingsForm.themeLogo')} {...{ width: imageWidth, height: imageHeight }} />
            </EmailPreviewLogo>
          </EmailPreviewHeader>
        )}
        <EmailPreviewMessage>
          <p>{firstName ? I18n.t('greetingName', { ...trOpt, firstName }) : I18n.t('greeting', trOpt)}</p>
          <p>{I18n.t('message', trOpt)}</p>
          <h4>{I18n.t('dummyHeading', trOpt)}</h4>
        </EmailPreviewMessage>
        <EmailPreviewButtonContainer>
          <EmailPreviewButton {...{ themePrimaryColor, themeSecondaryColor }}>{I18n.t('dummyButton', trOpt)}</EmailPreviewButton>
        </EmailPreviewButtonContainer>
      </EmailPreviewBody>
    </EmailPreviewContainer>
  )
})

export default EmailTestPreview
