import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _isEqual from 'lodash/isEqual'

import { ContentBox, CardTitle } from './common'
import LearnerCourseSlate from './LearnerCourseSlate'
import { ProgressButton } from '../common'
import { withTimer } from '../../hocs'
import LearnerCourseAnnotatedImagePoint from './LearnerCourseAnnotatedImagePoint'
import { LearnerCourseBase } from './LearnerCourseBase'

const AnnotatedImageWrap = styled.div`
  margin-bottom: 42px;
  position: relative;
  transition: opacity 0.7s;
  opacity: ${props => props.ready && props.showImage ? 1 : 0};
  visibility: ${props => props.showImage ? 'visible' : 'hidden'};
`

const AnnotatedImageImg = styled.img`
  width: 100%;
`
const AnnotatedPointsContainer = styled.div`
  border: gray solid 1px;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export class LearnerCourseAnnotatedImage extends LearnerCourseBase {
  constructor (props) {
    super(props)

    this.completedTimer = null
    this.canProgress = this.canProgress.bind(this)

    const { slide: { content: { points = {} } = {} } = {} } = props || {}

    this.state = {
      prevImage: null,
      imageWidth: 0,
      imageHeight: 0,
      points,
      ready: false,
      lastActiveChange: null
    }

    this.img = React.createRef()

    this.handleResize = this.handleResize.bind(this)
    this.handleContainerClick = this.handleContainerClick.bind(this)
    this.handleImageLoad = this.handleImageLoad.bind(this)
    this.handlePointVisibleChange = this.handlePointVisibleChange.bind(this)
  }

  canProgress () {
    return !this.props.timerActive
  }

  handleResize () {
    this.updateImageDimensions(this.updatePointPositions)
  }

  // Timeout in place to solve issue where img element dimensions onLoad are larger than the rendered element in browser (no issue on resize)
  // Points are positioned incorrectly if imageWidth and/or imageHeight are incorrect
  handleImageLoad () {
    setTimeout(() => {
      const { content: { image } = {} } = this.props

      // This interval routine repositions the points until the image width/height have settled
      // after the image's initial render after src is set or changed
      if (this.img.current) {
        let lastImageDimensionChange = Date.now()
        let { width: lastImageWidth, height: lastImageHeight } = this.img.current
        const imageInterval = setInterval(() => {
          const img = this.img.current
          if (img) {
            if (Date.now() - lastImageDimensionChange > 500) {
              clearInterval(imageInterval)
            } else if (lastImageWidth !== img.width || lastImageHeight !== img.height) {
              lastImageWidth = img.width
              lastImageHeight = img.height
              this.updateImageDimensions(this.updatePointPositions)
              lastImageDimensionChange = Date.now()
            }
          }
        }, 50)
      }

      this.handleResize()
      this.setState({ ready: true, prevImage: image })
    }, 500)
  }

  updateImageDimensions (cb) {
    if (this.img.current) {
      cb = typeof cb === 'function' ? cb : () => {}
      const { width: imageWidth, height: imageHeight } = this.img.current
      this.setState({ imageWidth, imageHeight }, cb)
    }
  }

  updatePointPositions () {
    const { points, imageWidth, imageHeight } = this.state
    Object.keys(points).forEach(id => {
      const { x, y } = points[id]
      points[id] = {
        ...points[id],
        posX: x * imageWidth,
        posY: y * imageHeight
      }
    })

    this.setState({ points })
  }

  handlePointVisibleChange (id, active) {
    const { points } = this.state

    if (points[id]) {
      if (active) {
        this.hideAllPoints()
      }
      points[id].active = active
      this.setState({ points, lastActiveChange: Date.now() })
    }
  }

  handleContainerClick () {
    this.hideAllPoints()
  }

  hideAllPoints () {
    const { points } = this.state

    Object.keys(points).forEach(id => {
      points[id].active = false
    })

    this.setState({ points })
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.startCompletionTimer()
  }

  componentDidUpdate (prevProps) {
    const { slide: { id, content: { points, image } } = {}, resetTimer } = this.props
    const { slide: { id: prevId, content: { points: prevPoints, image: prevImage } = {} } } = prevProps

    if (id !== prevId) {
      this.startCompletionTimer()
    }

    // Reset timer
    if (id && prevId && id !== prevId) {
      resetTimer()
    }

    if (!_isEqual(points, prevPoints)) {
      this.setState({ points: points || {} }, this.updatePointPositions)
    }
    if (image !== prevImage) {
      this.img = React.createRef()// Reinstantiate reference on slide/image transition
      this.setState({ ready: false })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
    this.props.resetTimer()
    clearTimeout(this.completedTimer)
  }

  render () {
    const { slide, canGoPrevSlide, goToNextSlide, goToPrevSlide, waitMS, timerActive } = this.props
    const { id, title, content } = slide
    const { intro, image } = content || {}
    const { points, imageHeight, ready, lastActiveChange, prevImage } = this.state

    const showImage = prevImage ? prevImage === image : true

    return (
      <ContentBox
        material innerKey={id}
        buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
        buttonsRight={<ProgressButton {...{ timerActive, waitMS }} onClick={goToNextSlide} />}
      >
        <CardTitle>{title}</CardTitle>
        <LearnerCourseSlate content={intro} />
        <AnnotatedImageWrap ready={ready} showImage={showImage}>
          {image && (
            <AnnotatedImageImg
              onLoad={this.handleImageLoad} src={image}
              ref={this.img}
            />
          )}
          <AnnotatedPointsContainer
            onClick={this.handleContainerClick}
            style={{
              height: imageHeight ? `${imageHeight}px` : undefined
            }}
          >
            {
              Object.keys(points).map(id => {
                const { posX, posY, text, placement, active = false } = points[id]

                return (
                  <LearnerCourseAnnotatedImagePoint
                    key={id}
                    {...{ id, posX, posY, active, placement, text, hide: !ready, lastActiveChange }}
                    onVisibleChange={this.handlePointVisibleChange}
                  />
                )
              })
            }
          </AnnotatedPointsContainer>
        </AnnotatedImageWrap>
      </ContentBox>
    )
  }
}

LearnerCourseAnnotatedImage.propTypes = {
  slide: PropTypes.object,
  goToNextSlide: PropTypes.func,
  resetTimer: PropTypes.func,
  timerActive: PropTypes.bool,
  update: PropTypes.func,
  waitMS: PropTypes.number
}

LearnerCourseAnnotatedImage.defaultProps = {
  slide: {},
  goToNextSlide: () => {},
  resetTimer: () => {},
  timerActive: false,
  update: () => {},
  waitMS: -1
}

export default withTimer(LearnerCourseAnnotatedImage)
