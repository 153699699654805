import React from 'react'
import styled, { css } from 'styled-components'

import { PrintableContentWrap, PrintableWrap, PrintableColumn } from '../common'
import SummaryCard from './SummaryCard'
import TrendCard from './TrendCard'
import { ReportHeader, SectionHeader } from './ReportHeader'
import { getValueText } from '../../helpers/reports'
import useIsPrinting from '../../hooks/useIsPrinting'
import NoDataCard from './NoDataCard'

const SMALL_WIDTH = 700

const CardGridContainer = styled.div`
  @media (min-width: ${SMALL_WIDTH}px) {
    ${({ numColumns }) => (css`
      grid-template-columns: repeat(${numColumns}, 1fr);
    `)
    }
  }
  display: grid;
  gap: 20px;
`

const CardContainer = ({ children, numColumns }) => {
  if (numColumns > 1) {
    return <CardGridContainer {...{ numColumns }}>{children}</CardGridContainer>
  } else {
    return children
  }
}

const BaseReport = ({
  sections = [], companyId, fromDate, toDate, title, trOpt, hideHeader = false, reportType = 'performanceReport', downloadFileName,
  showCompanyName = false, companyName
}) => {
  const isPrinting = useIsPrinting()

  const calculateCardWidth = ({ cardLength, index, size = 'large' }) => {
    let width = 60; let margin = '0 16px 0 0 '
    if (cardLength !== 1) {
      cardLength = 2
      width = (100 / cardLength) - 4
      margin = (index % 2 === 0) ? `${index > 1 ? '16px' : '0'} 18px 0 0` : `${index > 1 ? '16px' : '0'} 0 0 0`
    }
    return { width, margin }
  }

  return (
    sections.length > 0
      ? (
        <PrintableWrap>
          <PrintableContentWrap>
            <PrintableColumn>
              <ReportHeader title={title} {...{ companyId, fromDate, toDate, hideHeader, reportType, downloadFileName, showCompanyName, companyName }} />
              {Object.entries(sections).map(([sectionId, section]) => {
                if (!section) return null
                const { header, headerText, components, numColumns = 1 } = section
                return (
                  <React.Fragment key={sectionId}>
                    <SectionHeader title={header} text={headerText} />
                    <CardContainer {...{ numColumns }}>
                      {Object.values(components).map((component) => {
                        const { key, title, text, graph, cards = [], skip = false, noData = false, useFixedColumnLayout = false, useFullWidth = false, chartContentWidth = '60%', cardDescriptionWidth = '40%' } = component
                        if (skip) return null
                        const cardKey = key || title
                        if (noData) return <NoDataCard key={cardKey} />
                        const cardContainerStyle = cards.length === 1 || isPrinting ? {} : { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 20 }
                        return (
                          <SummaryCard
                            key={cardKey}
                            {...{
                              title,
                              text,
                              useFixedColumnLayout,
                              useFullWidth,
                              chartContentWidth,
                              cardDescriptionWidth
                            }}
                            chartProps={{
                              data: graph?.data,
                              trend: graph?.trend,
                              ...graph?.options || {}
                            }}
                            chartTitle={graph?.title}
                            chartDescription={graph?.description}
                            chartType={graph?.type}
                            chartComponent={graph?.component}
                            useTitleSpacer={graph?.useTitleSpacer === true}
                          >
                            <div style={cardContainerStyle}>
                              {cards.map((card, index) => {
                                const { width, margin } = calculateCardWidth({ cardLength: cards.length, index })
                                if (card.skip) return null
                                const valueTextOpts = { isPercentage: card.isPercentage, noValueText: card.noValueText }
                                const itemStyle = cards.length === 1 || isPrinting ? { width: `${width}%`, display: 'inline-block', margin: margin } : {}
                                return (
                                  <div style={itemStyle} key={index}>
                                    <TrendCard
                                      key={index}
                                      title={card.title}
                                      percentageChange={card.percentageChange}
                                      data={getValueText({ value: card.value, ...valueTextOpts })}
                                      caption={getValueText({ value: card.previousValue, ...valueTextOpts })}
                                      trend={{ ...card.trend }}
                                      withIcon={card.withIcon}
                                      withPrevious={card.withPrevious}
                                      denominator={card.denominator}
                                      printScale={card.printScale || 1}
                                      tooltip={card.tooltip}
                                      trOpt={trOpt}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          </SummaryCard>
                        )
                      })}
                    </CardContainer>
                  </React.Fragment>
                )
              })}
            </PrintableColumn>
          </PrintableContentWrap>
        </PrintableWrap>
      )
      : <></>
  )
}

export default BaseReport
