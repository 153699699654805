class DemoMode {
  constructor () {
    this.demoMode = false;
    this.demoModeListeners = {};
    this.locale = null;
    this.localeListeners = {};
  }

  _addListener = (id, listener, listenersType, data) => {
    if (typeof listener === 'function') {
      this[listenersType][id] = listener;
      if (data !== undefined) this[listenersType][id](data);
    }
  }

  _removeListener = (id, listenersType) => delete this[listenersType][id];

  _callListeners = (listenersType, data) => Object.keys(this[listenersType]).forEach(id => this[listenersType][id](data));
  
  isEnabled = () => this.demoMode;

  _setMode = value => {
    this.demoMode = value;
    this.callListeners();
  }

  enable = () => this._setMode(true);
  disable = () => this._setMode(false);

  addListener = (id, listener) => this._addListener(id, listener, 'demoModeListeners', this.demoMode)

  removeListener = (id) => this._removeListener(id, 'demoModeListeners')

  callListeners = () => this._callListeners('demoModeListeners', this.demoMode)

  setLocale = locale => {
    if (locale !== this.locale) {
      this.locale = locale
      this.callLocaleListeners(locale);
    }
  }

  addLocaleListener = (id, listener) => this._addListener(id, listener, 'localeListeners')

  removeLocaleListener = (id) => this._removeListener(id, 'localeListeners')

  callLocaleListeners = () => this._callListeners('localeListeners', this.locale)
}

const demoMode = new DemoMode();
window.demoMode = demoMode;

export default demoMode;