import { message, Modal } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { DETACH_GROUPS_FROM_SYNC, GET_GROUP } from '../Queries/Groups'
import { invalidateGroupsQueryCache, showErrors } from '../../helpers'

const confirm = Modal.confirm
const trOpt = { scope: 'modals.detachGroupConfirm' }

const DetachGroupConfirm = async (groupId, refetchQueries, client = authenticatedClient) => {
  confirm({
    title: I18n.t('title', trOpt),
    content: I18n.t('content', trOpt),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        await client.mutate({
          mutation: DETACH_GROUPS_FROM_SYNC,
          variables: { groupIds: [groupId] },
          refetchQueries: [
            ...refetchQueries,
            { query: GET_GROUP, variables: { groupId } }
          ],
          update: invalidateGroupsQueryCache
        })

        message.success(I18n.t('successMessage', trOpt))
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      }
    }
  })
}

export default DetachGroupConfirm
