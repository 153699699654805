/* global BroadcastChannel */
import _get from 'lodash/get'

import authenticatedClient from '../apollo-client/authenticatedClient'
import { EXTEND_SESSION } from '../components/Queries/Users'
import { ADMIN_SESSION_MGR_CHANNEL_ID, ADMIN_TOKEN, END_USER_TOKEN, REPORT_ACCESS_TOKEN } from '../constants/session'
import { removeLocalStorageItem, setLocalStorageItem, getLocalStorageItem } from './localStorage'
import { removeSessionStorageItem, setSessionStorageItem, getSessionStorageItem } from './sessionStorage'

export const getSessionToken = () => getLocalStorageItem(ADMIN_TOKEN)
export const setSessionToken = token => setLocalStorageItem(ADMIN_TOKEN, token)
export const removeSessionToken = () => removeLocalStorageItem(ADMIN_TOKEN)

// CL - Future proofing for when this code is merged into the end user portal to provide a set of helpers
export const getEndUserSessionToken = () => getLocalStorageItem(END_USER_TOKEN)
export const setEndUserSessionToken = token => setLocalStorageItem(END_USER_TOKEN, token)
export const removeEndUserSessionToken = () => removeLocalStorageItem(END_USER_TOKEN)

export const getReportAccessSessionToken = () => getSessionStorageItem(REPORT_ACCESS_TOKEN)
export const setReportAccessSessionToken = token => setSessionStorageItem(REPORT_ACCESS_TOKEN, token)
export const removeReportAccessSessionToken = () => removeSessionStorageItem(REPORT_ACCESS_TOKEN)

const setSessionTokenFromRefresh = result => {
  const token = _get(result, 'data.extendSession.token')
  if (!token) {
    throw new Error('No token returned')
  }
  // Set token
  setSessionToken(token)
}
const setEndUserSessionTokenFromRefresh = result => {
  const token = _get(result, 'data.extendEndUserSession.token')
  if (!token) throw new Error('No token returned')
  setEndUserSessionToken(token)
}

// extendSession comes from a mutation in the component using this helper
export const refreshSessionToken = async (extendSession, throwError = true) => {
  try {
    const result = await extendSession()
    setSessionTokenFromRefresh(result)
  } catch (e) {
    if (throwError) {
      throw e
    }
    console.error('refreshSessionToken - Error', e)
  }
}

export const refreshSessionTokenUsingClient = async (throwError = true) => {
  try {
    const result = await authenticatedClient.mutate({
      mutation: EXTEND_SESSION
    })
    setSessionTokenFromRefresh(result)
  } catch (e) {
    if (throwError) {
      throw e
    }
    console.error('refreshSessionTokenUsingClient - Error', e)
  }
}

// Returns null if BroadcastChannel is not supported by the user's browser
const createChannel = channelName => {
  let channel = null
  try {
    channel = new BroadcastChannel(channelName)
  } catch (e) {
    console.error(`Error instantiating BroadcastChannel for "${channelName}"`, e)
  }
  return channel
}
export const adminChannel = createChannel(ADMIN_SESSION_MGR_CHANNEL_ID) // Single instance as a channel won't respond to its own message
export const channels = {
  admin: adminChannel
}

export const postChannelMessage = (channelOrChannelId, id, data) => {
  const channel = channelOrChannelId instanceof BroadcastChannel ? channelOrChannelId : channels[channelOrChannelId]
  if (channel) {
    const msg = { id }
    if (data) msg.data = data
    channel.postMessage(msg)
  }
}
export const postSessionChannelMessage = (id, data) => postChannelMessage('admin', id, data)

export const refreshEndUserSessionToken = async (extendSession, throwError = true) => {
  try {
    const result = await extendSession()
    setEndUserSessionTokenFromRefresh(result)
  } catch (e) {
    if (throwError) {
      throw e
    }
    console.error('refreshSessionToken - Error', e)
  }
}
