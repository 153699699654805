import React from 'react'
import _isInteger from 'lodash/isInteger'

const withRenderDelay = Component => {
  class WithRenderDelay extends React.Component {
    constructor (props) {
      super(props)

      this.state = {
        hidden: true
      }
    }

    show () {
      if (this._isMounted) {
        this.setState({ hidden: false })
      }
    }

    processDelay () {
      const { wait } = this.props
      if (_isInteger(wait) && wait > 0) {
        setTimeout(() => this.show(), wait)
      } else {
        this.show()
      }
    }

    componentDidMount () {
      this._isMounted = true
      this.processDelay()
    }

    componentWillUnmount () {
      this._isMounted = false
    }

    render () {
      const { forwardedRef, ...rest } = this.props
      const { hidden } = this.state

      return (
        <Component ref={forwardedRef} {...rest}
          {...{
            hidden,
            hiddenClassName: hidden ? 'render-hidden' : ''
          }} />
      )
    }
  }

  return React.forwardRef((props, ref) => (
    <WithRenderDelay {...props} forwardedRef={ref} />
  ))
}

export default withRenderDelay
