/* global localStorage */
import React, { Component } from 'react'
import { Card, Modal, Button, message } from 'antd'
import styled from 'styled-components'
import { compose } from 'recompose'
import { Link, withRouter } from 'react-router-dom'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'

import { connect, withConsumer } from '../../hocs'
import selectors from '../../state/selectors'
import DefaultTemplates from './DefaultTemplates'
import { GET_POLICY_TEMPLATE, CREATE_POLICY } from '../../components/Queries/uPolicy'
import { ListHeader, ListHeaderPanel } from '../../components/common'
import routes from '../../constants/routes'
import PolicyForm from '../../components/Policies/PolicyForm'
import ModalHeaderId from '../../components/Modals/ModalHeaderId'

const trOpt = { scope: 'uPolicy.createPolicy' }
const commonTrOpt = { scope: 'uPolicy.common' }

const CreatePolicyContent = styled.div`
  padding-bottom: 80px;
`

const TemplateModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-modal-content {
    height: 100%;
  }
`

const TemplateModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  position: relative;
`

const TEMPLATE_DATA_FIELDS = [
  'name',
  'category',
  'type',
  'document',
  'file'
]

const defaultState = {
  name: null,
  type: 'editor',
  document: null,
  file: null,
  category: null,
  companyId: null,
  companies: [],
  global: false,
  signatureSettings: { type: 'none', useDefault: false },
  showTemplateModal: false
}

class CreatePolicy extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...defaultState
    }

    this.form = React.createRef()

    this.closeTemplateModal = this.closeTemplateModal.bind(this)
    this.getPolicyData = this.getPolicyData.bind(this)
    this.handleChangeTemplateClick = this.handleChangeTemplateClick.bind(this)
    this.handleFormFieldChange = this.handleFormFieldChange.bind(this)
    this.handleTemplateModalCancel = this.handleTemplateModalCancel.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.reset = this.reset.bind(this)
    this.resetState = this.resetState.bind(this)
    this.updateTemplate = this.updateTemplate.bind(this)
  }

  updateState (stateUpdate) {
    return new Promise(resolve => {
      this.setState(stateUpdate, async () => {
        this.updateLocalStorage()
        resolve()
      })
    })
  }

  // Reset to default
  async resetState () {
    await this.updateState(defaultState)
    localStorage.removeItem(this.storageId)
    if (this.fromTemplate) {
      this.setState({ showTemplateModal: true })
    }
  }

  get storageId () {
    const { userId, companyId } = this.props
    return `createPolicy|${companyId}|${userId}`
  }

  get fromTemplate () {
    return _get(this, 'props.match.path') === routes.UPOLICY_CREATE_FROM_TEMPLATE
  }

  updateLocalStorage () {
    if (!this.updating) {
      localStorage.setItem(this.storageId, JSON.stringify(_omit(this.state, ['showTemplateModal'])))
    }
  }

  async updateTemplate (templateId) {
    let template
    if (templateId && templateId !== 'create') {
      const result = await this.props.client.query({
        query: GET_POLICY_TEMPLATE,
        variables: {
          id: templateId
        }
      })
      template = _get(result, 'data.policyTemplate')
    }
    let stateUpdt = { templateId: null, name: null, category: null, type: 'editor', document: null, file: null }
    if (template) {
      stateUpdt = {
        ...stateUpdt,
        templateId,
        ..._pick(template, TEMPLATE_DATA_FIELDS)
      }
    }
    await this.updateState(stateUpdt)
    this.setFormValuesFromTemplateState()
    this.closeTemplateModal()
  }

  get isTemplateComplete () {
    return Boolean(this.state.templateId)
  }

  handleFormFieldChange (name, value) {
    this.updateState({ [name]: value })
  }

  getPolicyData () {
    return _pick(this.state, [
      'name',
      'type',
      'document',
      'file',
      'category',
      'global',
      'companyId',
      'companies',
      'signatureSettings'
    ])
  }

  getTemplateData () {
    return _pick(this.state, TEMPLATE_DATA_FIELDS)
  }

  handleChangeTemplateClick () {
    this.openTemplateModal()
  }

  handleTemplateModalCancel () {
    this.closeTemplateModal()
  }

  openTemplateModal () {
    this.setState({ showTemplateModal: true })
  }

  closeTemplateModal () {
    this.setState({ showTemplateModal: false })
  }

  resetForm () {
    if (this.form.current) {
      this.form.current.reset()
    }
  }

  setFormValuesFromState () {
    if (this.form.current) {
      this.form.current.replaceValues(this.getPolicyData())
    }
  }

  setFormValuesFromTemplateState () {
    if (this.form.current) {
      this.form.current.replaceValues(this.getTemplateData())
    }
  }

  async reset () {
    await this.resetState()
    this.resetForm()
  }

  get backPath () {
    return routes.UPOLICY
  }

  async handleSuccess () {
    message.success(I18n.t('successfullyCreatedPolicy', trOpt))
    await this.resetState()

    return this.props.history.push(this.backPath)
  }

  async componentDidMount () {
    let previousCreatePolicy
    try {
      const previousCreatePolicyString = localStorage.getItem(this.storageId)
      if (previousCreatePolicyString) {
        previousCreatePolicy = JSON.parse(previousCreatePolicyString)
      }
    } catch (e) {}

    if (previousCreatePolicy) {
      // Set state using previous creation attempt
      await this.updateState(previousCreatePolicy)
      this.setFormValuesFromState()
      Modal.confirm({
        title: I18n.t('common.resumeConfirmDialog.title'),
        okText: I18n.t('common.resumeConfirmDialog.ok'),
        cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
        onCancel: this.reset
      })
    } else if (this.fromTemplate) {
      this.openTemplateModal()
    }
  }

  render () {
    const { templateId } = this.state

    return (
      <>
        <Card>
          <CreatePolicyContent>
            <h1 id='uPolicy-policy-create-header'>{I18n.t('common.uPolicy')} - {I18n.t('createPolicy', commonTrOpt)}</h1>
            <ListHeader>
              <ListHeaderPanel>
                <Link to={this.backPath}>
                  <Button type='primary'>{I18n.t('backToPolicies', commonTrOpt)}</Button>
                </Link>
              </ListHeaderPanel>
              <ListHeaderPanel align='right'>
                <Button type='primary' onClick={this.handleChangeTemplateClick}>{I18n.t('selectATemplate', trOpt)}</Button>
              </ListHeaderPanel>
            </ListHeader>
            <PolicyForm
              ref={this.form}
              mutation={CREATE_POLICY}
              onFailure={I18n.t('failedToCreatePolicy', trOpt)}
              templatePolicyId={templateId !== 'create' ? templateId : null}
              onSuccess={this.handleSuccess}
              submitLabel={I18n.t('createPolicy', commonTrOpt)}
              onChange={this.handleFormFieldChange}
              saveActions={['saveDraft', 'publish']}
              create
            />
          </CreatePolicyContent>
        </Card>
        <TemplateModal
          width='90%'
          bodyStyle={{ paddingTop: 5, height: 'calc(100% - 60px)' }}
          title={<ModalHeaderId idText='uPolicy-choose-template-create-header' text={I18n.t('chooseAPolicyTemplate', trOpt)} />}
          visible={this.state.showTemplateModal}
          onCancel={this.handleTemplateModalCancel}
          afterClose={this.closeTemplateModal}
          footer={null}
        >
          <TemplateModalBody>
            <DefaultTemplates update={this.updateTemplate} />
          </TemplateModalBody>
        </TemplateModal>
      </>
    )
  }
}

export default compose(
  connect(
    state => _pick(selectors.session.get(state), ['userId', 'companyId'])
  ),
  withConsumer,
  withRouter
)(CreatePolicy)
