import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import { Button } from 'antd'
import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import _isEmpty from 'lodash/isEmpty'

import { connect } from '../../hocs'
import useTheme from '../../hooks/useTheme'
import ErrorAlerts from '../../components/common/ErrorAlerts'
import { Row, Column, RoundedCard } from '../../components/EndUserPortal/GridFlexiLayout'
import { ContentWrap } from '../../components/common/Layout'
import { GET_LEARNER_DASHBOARD_DATA } from '../../components/Queries/EndUser'
import DownloadCertificateBtn from '../../components/common/DownloadCertificateBtn.js'
import { LoadingBlock } from '../../components/common'
import { getSessionAndSettings } from '../../state/selectors'
import { SearchBar } from '../../components/common/List'
import { useWindowSize } from '../../hooks/useWindowSize'
import { AverageScoreProgress, StartedCourse, CompletedCourses, TableTag, setStatusTagColour, showInfoModal } from '../../components/EndUserPortal/DashboardComponents'
import { COURSE_DIFFICULTY, COURSE_SUBJECTS } from '../../constants/courses'
import { processCourseResults, getStatusHierarchyAndTableFilters } from '../../helpers/endUser'
import { captureSentryError } from '../../helpers/sentry'
import EndUserTable from '../../components/EndUserPortal/Table'

const trOpt = { scope: 'endUserPortal.learnerDashboard' }
const trOptCommon = { scope: 'endUserPortal.common' }
const trOptDashboard = { scope: 'endUserPortal.dashboard' }
const gaCertificatePopupTrOpt = { scope: 'endUserPortal.learnerDashboard.gapAnalysisCertificatePopup' }
const getStatusAndAction = (courseResult) => {
  let status = 'started'
  let action = 'continue'
  if (courseResult.finished) {
    status = 'completed'
    if (courseResult.course.difficulty !== 1) {
      action = 'downloadCertificate'
    } else {
      action = 'noCertificate'
    }
  } else if (courseResult.startDate === null) {
    status = 'outstanding'
    action = 'takeCourse'
  }
  return { status, action }
}

export const generateCourseLink = (courseId, learnerId) => {
  if (courseId && learnerId) {
    return `/learner/${learnerId}/course/${courseId}`
  }
}
const cardTemplate = {
  title: {
    key: 'name'
  },
  body: [
    {
      type: 'tag',
      key: 'status',
      trOpt: { scope: 'endUserPortal.learnerDashboard.courseStatus' }
    }, {
      type: 'text',
      title: I18n.t('tableColumn.subject', trOpt),
      key: 'subject'
    }, {
      type: 'text',
      title: I18n.t('tableColumn.score', trOpt),
      key: 'score'
    }
  ]
}

const DataIteratorActions = ({ action, record, learnerName, theme, learnerId }) => {
  const trOpt = { scope: 'endUserPortal.learnerDashboard' }
  if (action === 'downloadCertificate') {
    return <DownloadCertificateBtn key={record.id} type='link' logoSrc={theme.appThemeLogo} ribbonColour={theme.primary} courseTitle={record.name} dateOfCompletion={record.finishDate} scorePercentage={record.score} presentedToName={learnerName} />
  }
  if (action === 'noCertificate') {
    return <Button type='link' icon='info-circle' onClick={() => showInfoModal(I18n.t('title', gaCertificatePopupTrOpt), I18n.t('content', gaCertificatePopupTrOpt))} key={record.id}>{I18n.t('button', gaCertificatePopupTrOpt)}</Button>
  }
  if (action === 'continue' || action === 'takeCourse') {
    return <Button type='link' key={record.id} href={generateCourseLink(record.courseId, learnerId)} target='blank' icon='form'>{I18n.t(`courseActions.${record.action}`, trOpt)}</Button>
  }
}

const statuses = ['outstanding', 'started', 'completed']
const { statusHierarchy, statusFilters } = getStatusHierarchyAndTableFilters(statuses, { scope: 'endUserPortal.learnerDashboard.courseStatus' })

const LearnerDashboard = ({ session, settings }) => {
  const theme = useTheme()
  const { type: screenSizeType } = useWindowSize()
  const isMobile = ['xs', 'sm'].includes(screenSizeType)

  const { loading, error, data } = useQuery(GET_LEARNER_DASHBOARD_DATA, {
    onError: error => {
      captureSentryError(error, { msg: 'End User Portal - Learn Dashboard Load - ERROR' })
    }
  })
  const [learnerName, setLearnerName] = useState(null)
  const [averageScore, setAverageScore] = useState(null)
  const [rawCourseResults, setRawCourseResults] = useState([])
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState({})
  const [filters, updateFilters] = useState({})
  const learnerId = _get(session, 'learnerId')

  useEffect(() => {
    if (data && !_isEmpty(data)) {
      setLearnerName(_get(data, 'learnerMe.endUser.name'))
      setAverageScore(_get(data, 'learnerMe.endUser.averageCourseScore'))
      setRawCourseResults(processCourseResults(_get(data, 'learnerMe.endUser.courseResults')))
    }
  }, [data])

  const formattedCourseResults = useMemo(() => {
    return rawCourseResults.map((courseResult) => {
      const { status, action } = getStatusAndAction(courseResult)
      return {
        id: courseResult.id,
        courseId: courseResult.course.id,
        name: courseResult.course.name,
        subject: COURSE_SUBJECTS[courseResult.course.subject],
        difficulty: courseResult.course.difficulty,
        score: courseResult.score,
        finishDate: courseResult.finishDate,
        finished: courseResult.finished,
        status: status,
        action: action,
        learnerName: learnerName
      }
    }).sort((a, b) => a.name.localeCompare(b.name))
  }, [rawCourseResults, learnerName])

  const completedCourses = useMemo(() => {
    return formattedCourseResults.filter(course => (course.finished === true && course.action === 'downloadCertificate'))
  }, [formattedCourseResults])

  const uniqueColumnFilterItems = useMemo(() => {
    const courses = rawCourseResults.map(result => result.course)
    const subjects = _uniq(courses.map(course => course.subject))
      .map(subject => ({ text: subject, value: subject }))

    const difficulties = _uniq(courses.map(course => course.difficulty))
      .map(difficulty => ({ text: COURSE_DIFFICULTY[difficulty], value: difficulty }))
    return { subjects, difficulties }
  }, [rawCourseResults])

  const columns = useMemo(() => {
    return [
      {
        title: I18n.t('tableColumn.name', trOpt),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sorter.columnKey === 'name' && sorter.order
      }, {
        title: I18n.t('tableColumn.subject', trOpt),
        dataIndex: 'subject',
        key: 'subject',
        sorter: (a, b) => a.subject.localeCompare(b.subject),
        sortOrder: sorter.columnKey === 'subject' && sorter.order,
        filters: uniqueColumnFilterItems.subjects,
        filterMultiple: true,
        onFilter: (value, record) => record.subject.includes(value),
        filteredValue: filters.subject || null
      }, {
        title: I18n.t('tableColumn.difficulty', trOpt),
        dataIndex: 'difficulty',
        key: 'difficulty',
        sorter: (a, b) => a.difficulty - b.difficulty,
        sortOrder: sorter.columnKey === 'difficulty' && sorter.order,
        filters: uniqueColumnFilterItems.difficulties,
        filterMultiple: true,
        onFilter: (value, record) => record.difficulty === value,
        filteredValue: filters.difficulty || null,
        render: difficulty => COURSE_DIFFICULTY[difficulty] || COURSE_DIFFICULTY[99]
      }, {
        title: I18n.t('tableColumn.score', trOpt),
        dataIndex: 'score',
        key: 'score',
        sorter: (a, b) => a.score - b.score,
        sortOrder: sorter.columnKey === 'score' && sorter.order
      }, {
        title: I18n.t('tableColumn.status', trOpt),
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => statusHierarchy[a.status] - statusHierarchy[b.status],
        sortOrder: sorter.columnKey === 'status' && sorter.order,
        filters: statusFilters,
        filterMultiple: true,
        onFilter: (value, record) => value === record.status,
        filteredValue: filters.status || null,
        render: status => (
          <span>
            <TableTag color={setStatusTagColour(status, theme)} status={status} trOpt={{ scope: 'endUserPortal.learnerDashboard.courseStatus' }} />
          </span>
        )
      }, {
        title: I18n.t('tableColumn.action', trOpt),
        dataIndex: 'action',
        key: 'action',
        render: (action, record) => (
          <span>
            {action === 'downloadCertificate' && <DownloadCertificateBtn logoSrc={theme.appThemeLogo} ribbonColour={theme.primary} course={{ courseTitle: record.name, dateOfCompletion: record.finishDate, scorePercentage: record.score, presentedToName: learnerName }} type='link' />}
            {(action === 'continue' || action === 'takeCourse') && <Button type='link' href={generateCourseLink(record.courseId, learnerId)} target='blank' icon='form'>{I18n.t(`courseActions.${action}`, trOpt)}</Button>}
            {action === 'noCertificate' && <Button type='link' icon='info-circle' onClick={() => showInfoModal(I18n.t('title', gaCertificatePopupTrOpt), I18n.t('content', gaCertificatePopupTrOpt))} key={record.id}>{I18n.t('button', gaCertificatePopupTrOpt)}</Button>}
          </span>
        )
      }
    ]
  }, [filters, sorter, theme, uniqueColumnFilterItems, learnerName, learnerId])

  const applyOutstandingCourseFilter = () => {
    const status = { status: ['outstanding', 'started'] }
    updateFilters({ ...status })
  }
  const clearFilters = () => {
    updateFilters({})
  }
  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])
  const outstandingStatusIsFiltered = !_isEmpty(filters.status) && filters.status.includes('outstanding')
  return (
    <>
      <LoadingBlock loading={loading} fullScreen={false} showMenu />
      {error && <ContentWrap><ErrorAlerts error={error} defaultError={I18n.t('defaultPageError', trOptCommon)} /></ContentWrap>}
      {data && !_isEmpty(data) &&
        <ContentWrap>
          <Column>
            <Row>
              <div style={{ flex: '1 1 auto' }}><StartedCourse locale={settings.locale} courseResults={formattedCourseResults} theme={theme} trOpt={trOptDashboard} /></div>
              <div style={{ flex: '1 1 auto' }}><CompletedCourses locale={settings.locale} courseResults={formattedCourseResults} theme={theme} trOpt={trOptDashboard} /></div>
              <div style={{ flex: '1 1 auto' }}><AverageScoreProgress locale={settings.locale} courseResults={formattedCourseResults} averageScore={averageScore} theme={theme} trOpt={trOptDashboard} linkRequired={false} /></div>
            </Row>
            <RoundedCard>
              <Row className='justify padded-bottom' justify='space-between'>
                <h1>{I18n.t('courseTableHeader', trOpt)}</h1>
                <Row className='justify' justify={!isMobile ? 'flex-end' : 'stretch'} style={isMobile ? { width: '100%' } : {}}>
                  {!outstandingStatusIsFiltered && <Button block={isMobile} type='primary' onClick={() => applyOutstandingCourseFilter()}>{I18n.t('outstandingCoursesFilter', trOpt)}</Button>}
                  {outstandingStatusIsFiltered && <Button block={isMobile} icon='rollback' onClick={() => clearFilters()}>{I18n.t('clearFilters', trOptCommon)}</Button>}
                  {!_isEmpty(completedCourses) && <DownloadCertificateBtn btnTextKey='downloadAllCertificates' logoSrc={theme.appThemeLogo} ribbonColour={theme.primary} courses={completedCourses} type='primary' />}
                  <div style={isMobile ? { width: '100%' } : {}}>
                    <SearchBar
                      className='learner-course-search'
                      placeholder={I18n.t('searchCourses', trOpt)}
                      value={searchFilterText}
                      allowClear
                      onChange={onSearchChange}
                    />
                  </div>
                </Row>
              </Row>
              <EndUserTable
                tableData={formattedCourseResults}
                searchFilterText={searchFilterText}
                trOpt={trOpt}
                columns={columns}
                tableProps={{
                  pagination: pagination,
                  updatePagination: updatePagination,
                  updateSorter: updateSorter,
                  updateFilters: updateFilters,
                  sortBy: ['name']
                }}
                dataIteratorProps={{
                  learnerName: learnerName,
                  learnerId: learnerId,
                  defaultPageSize: 5,
                  cardTemplate: cardTemplate,
                  cardAction: DataIteratorActions
                }}
              />
            </RoundedCard>
          </Column>
        </ContentWrap>}
    </>
  )
}

export default connect(
  getSessionAndSettings
)(LearnerDashboard)
