/* global localStorage */
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import moment from 'moment'
import { Table, Button, message } from 'antd'
import styled from 'styled-components'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'

import { connect } from '../../hocs'
import { Column } from '../../components/EndUserPortal/GridFlexiLayout'
import EditReportModal from '../../components/Modals/EditReportModal'
import { ListHeader, ListHeaderPanel, ListTableActions, SearchBar, ErrorAlerts } from '../../components/common'
import { getSession } from '../../state/selectors/session'
import { onListTableChange, textColumnSorter, dateTimeColumnSorter, processListActions } from '../../helpers/listPages'
import { LIST_PAGINATION_PROPS } from '../../constants/list'
import { openReport } from '../../helpers/reports'
import OpenReportModal from '../../components/Modals/OpenReportModal'
import DeleteCustomReportConfirm from '../../components/Modals/DeleteCustomReportConfirm'
import InvalidateReportCacheConfirm from '../../components/Modals/InvalidateReportCacheConfirm'
import SendReportModal from '../../components/Modals/SendReportModal'
import DownloadReportModal from '../../components/Modals/DownloadReportModal'
import { WRITABLE_REPORT_MANAGER_ACTIONS } from '../../constants/actions'
import { useWindowSize } from '../../hooks/useWindowSize'
import { REPORT_PERIOD_TYPES as PERIOD_TYPES } from '../../constants/reports'
import { formatDate } from '../../helpers/datetime'
import IntercomHeader from '../../components/IntercomHeader'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'reports.reportCentre.reportsList' }
const tableColumnTrOpt = { scope: `${trOpt.scope}.columns` }
const actionTrOpt = { scope: `${trOpt.scope}.actions` }
const periodTypeTrOpt = { scope: `${trOpt.scope}.periodTypes` }

const TABLE_BUTTON_ACTIONS = ['view', 'download', 'send']

// This is for the mobile layout
const MobileListHeaderPanel = styled(ListHeaderPanel)`
  flex-wrap: wrap;

  & > * {
    margin-top: 10px;
  }
`

// Cache invalidation is currently limited to usecure admins for support reasons
// However the back end does allow admins to invalidate the report DB cache so we can remove this restriction easily if needed.
const getReportActions = (report, disabledActions, omittedActions) => {
  const recordOmittedActions = [...omittedActions]
  if (!report.updateAllowed) recordOmittedActions.push('update')
  if (!report.deleteAllowed) recordOmittedActions.push('delete')

  const actions = [{
    key: 'view',
    get label () { return I18n.t('reports.common.viewReport') },
    buttonIcon: 'eye',
    get buttonLabel () { return I18n.t('common.view') }
  }, {
    key: 'download',
    get label () { return I18n.t('reports.common.downloadReport') },
    buttonIcon: 'download',
    get buttonLabel () { return I18n.t('common.download') }
  }, {
    key: 'send',
    get label () { return I18n.t(this.key, actionTrOpt) },
    buttonIcon: 'mail',
    get buttonLabel () { return I18n.t('common.send') }
  }]
  if (report.custom) {
    actions.push(
      { key: 'update', get label () { return I18n.t(this.key, actionTrOpt) } },
      { key: 'delete', get label () { return I18n.t(this.key, actionTrOpt) } }
    )
  }
  if (report.hasCachedData) {
    actions.push({ key: 'invalidate', get label () { return I18n.t(this.key, actionTrOpt) } })
  }
  return processListActions({
    actions,
    omittedActions: recordOmittedActions,
    disabledActions
  })
}

const getGeneratedReportName = (periodType, periodValue, periodYear) => {
  switch (periodType) {
    case 'month': {
      const periodStart = moment({ day: 1, month: periodValue, year: periodYear })
      return I18n.t(periodType, { scope: `${trOpt.scope}.periodReportNames`, month: periodStart.format('MMMM'), year: periodStart.year() })
    }
    case 'quarter':
      return I18n.t(periodType, { scope: `${trOpt.scope}.periodReportNames`, quarter: periodValue, year: periodYear })
    case 'year':
      return I18n.t(periodType, { scope: `${trOpt.scope}.periodReportNames`, year: periodValue })
    default:
      return I18n.t('title', trOpt) // The value is "Performance Report" in English, if shown there's a bug
  }
}

const getPeriodTypeText = periodType => periodType === 'custom' ? I18n.t('common.custom') : I18n.t(periodType, periodTypeTrOpt)

const periodTypeSorter = (a, b) => PERIOD_TYPES.indexOf(a.periodType) - PERIOD_TYPES.indexOf(b.periodType)

const reportDateSorter = (columnId, a, b) => {
  const dateTimeSortValue = dateTimeColumnSorter(columnId, a, b)
  return dateTimeSortValue === 0 ? periodTypeSorter(a, b) : dateTimeSortValue
}

const BaseReportsListView = ({
  companyId,
  accountType,
  sessionCompanyId,
  records: rawRecords = [],
  searchFilterText = '',
  updateSearchFilterText = () => { },
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  refetch,
  loading: recordsLoading,
  error,
  refetchQueries,
  planValid = false,
  reportStartDate,
  startDateError,
  startDateLoading,
  refetchStartDate,
  reportType,
  useDBCache,
  queries,
  requiredPermissions
}) => {
  const { hasAllSessionPermissions, hasAllSessionPermissionsOriginally } = useHasSessionPermission()
  const [allRecords, setAllRecords] = useState([])
  const [records, setRecords] = useState([])
  const { type: screenTypeClass } = useWindowSize()
  // md is included as the mobile is a better fit for that screen size
  const isMobile = ['xs', 'sm', 'md'].includes(screenTypeClass)

  const editReportModalRef = useRef(null)
  const openEditReportModal = useCallback(record => {
    if (editReportModalRef.current) {
      editReportModalRef.current.open(record)
    }
  }, [editReportModalRef])
  const openCreateReportModal = useCallback(() => openEditReportModal(), [openEditReportModal])

  const deleteConfirmRef = useRef(null)
  const openDeleteConfirm = useCallback(reports => {
    if (deleteConfirmRef.current) {
      deleteConfirmRef.current.open(reports)
    }
  }, [deleteConfirmRef])
  const invalidateConfirmRef = useRef(null)
  const openInvalidateCacheConfirm = useCallback(reports => {
    if (invalidateConfirmRef.current) {
      invalidateConfirmRef.current.open(reports)
    }
  }, [invalidateConfirmRef])

  const viewReportModalRef = useRef(null)
  const openViewReportModal = useCallback(() => {
    if (viewReportModalRef.current) {
      viewReportModalRef.current.open()
    }
  }, [viewReportModalRef])

  const sendReportModalRef = useRef(null)
  const openSendReportModal = useCallback((report) => {
    if (sendReportModalRef.current) {
      sendReportModalRef.current.open({ ...report, reportType })
    }
  }, [sendReportModalRef, reportType])

  const downloadReportModalRef = useRef(null)
  const openDownloadReportModal = useCallback((report) => {
    if (downloadReportModalRef.current) {
      downloadReportModalRef.current.open({ ...report, reportType })
    }
  }, [downloadReportModalRef, reportType])

  const performAction = useCallback(async (action, ids) => {
    let actionRecord
    let actionRecords
    if (ids.length === 1) {
      actionRecord = allRecords.find(record => record.key === ids[0])
      if (actionRecord) {
        actionRecords = [actionRecord]
      }
    } else if (ids.length > 1) {
      actionRecords = allRecords.filter(record => ids.includes(record.key))
    }
    if (!actionRecord && !actionRecords) {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
      return
    }
    switch (action) {
      case 'view': {
        if (accountType === 'msp' && companyId !== sessionCompanyId) {
          // MSP Viewing a Customer's Report from the MSP Level
          openReport({ companyId, reportType, ...actionRecord })
        } else {
          openReport({ reportType, ...actionRecord })
        }
        break
      }
      case 'download':
        openDownloadReportModal(actionRecord)
        break
      case 'send':
        openSendReportModal(actionRecord)
        break
      case 'update':
        openEditReportModal(actionRecord)
        break
      case 'delete':
        openDeleteConfirm(actionRecords.filter(r => r.custom))
        break
      case 'invalidate': {
        openInvalidateCacheConfirm(actionRecords.filter(r => r.hasCachedData))
        break
      }
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [allRecords, openEditReportModal, openDeleteConfirm, openInvalidateCacheConfirm, accountType, companyId, sessionCompanyId, openSendReportModal, openDownloadReportModal, reportType])

  const performRowAction = useCallback(async (action, id) => {
    performAction(action, [id])
  }, [performAction])

  const { disabledActions, omittedActions } = useMemo(() => {
    const omittedActions = []
    if (!hasAllSessionPermissions(requiredPermissions.send)) {
      omittedActions.push('send')
    }
    if (!hasAllSessionPermissionsOriginally([permissions.USECURE_SUPER_ADMIN]) || !useDBCache) {
      omittedActions.push('invalidate')
    }

    return {
      disabledActions: !planValid ? WRITABLE_REPORT_MANAGER_ACTIONS : null,
      omittedActions
    }
  }, [planValid, hasAllSessionPermissions, hasAllSessionPermissionsOriginally, requiredPermissions, useDBCache])

  // Hide row arrow if no rows have an arrow action otherwise disable arrow button on rows with no applicable actions
  const onEmptyActions = useMemo(() =>
    records.some(
      record => record.actions.some(action => !TABLE_BUTTON_ACTIONS.includes(action.key))
    ) ? 'disable' : 'skip'
  , [records])
  const renderActionsCell = useCallback((actions, record) => (
    <ListTableActions
      actions={actions}
      id={record.id}
      performAction={performRowAction}
      buttonActionKeys={TABLE_BUTTON_ACTIONS}
      onEmptyActions={onEmptyActions}
    />
  ), [performRowAction, onEmptyActions])

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}
    return [
      {
        title: I18n.t('common.fields.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => textColumnSorter('name', a, b),
        sortOrder: sortColumnKey === 'name' && order
      }, {
        title: I18n.t('periodType', tableColumnTrOpt),
        dataIndex: 'periodType',
        key: 'periodType',
        filters: PERIOD_TYPES.map(periodType => ({ value: periodType, text: getPeriodTypeText(periodType) })),
        filterMultiple: true,
        onFilter: (value, record) => record.periodType === value,
        filteredValue: _get(filters, 'periodType', null),
        sorter: periodTypeSorter,
        sortOrder: sortColumnKey === 'periodType' && order,
        render: periodType => getPeriodTypeText(periodType)
      }, {
        title: I18n.t('fromDate', tableColumnTrOpt),
        dataIndex: 'fromDate',
        key: 'fromDate',
        sorter: (a, b) => reportDateSorter('fromDate', a, b),
        sortOrder: sortColumnKey === 'fromDate' && order,
        render: date => formatDate(date)
      }, {
        title: I18n.t('toDate', tableColumnTrOpt),
        dataIndex: 'toDate',
        key: 'toDate',
        sorter: (a, b) => reportDateSorter('toDate', a, b),
        sortOrder: sortColumnKey === 'toDate' && order,
        render: date => formatDate(date)
      }, {
        title: '',
        dataIndex: 'actions',
        align: 'right',
        render: renderActionsCell
      }
    ]
  }, [filters, sorter, renderActionsCell])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const handleRefreshClick = useCallback(() => {
    refetch()
    refetchStartDate()
  }, [refetch, refetchStartDate])

  useEffect(() => {
    setAllRecords(
      rawRecords.map(rawRecord => {
        const { name, custom, id, periodType, periodValue, periodYear } = rawRecord
        const record = {
          ...rawRecord,
          key: id,
          name: custom && name ? name : getGeneratedReportName(periodType, periodValue, periodYear),
          periodType: custom ? 'custom' : periodType
        }
        record.actions = getReportActions(record, disabledActions, omittedActions)
        return record
      })
        // Apply default sort order - toDate desc
        // defaultSortOrder wouldn't work on the column
        .sort((a, b) => -1 * reportDateSorter('toDate', a, b))
    )
  }, [rawRecords, disabledActions, omittedActions])

  const applyRecordFilters = useCallback(searchFilterText => {
    let records = [...allRecords]
    if (searchFilterText) {
      const filterFields = ['name']
      records = records.filter(record =>
        filterFields.some(field => {
          const value = record[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setRecords(records)
  }, [allRecords])
  const debouncedApplyRecordFilters = useCallback(_debounce(applyRecordFilters, 500), [applyRecordFilters])
  useEffect(() => {
    debouncedApplyRecordFilters(searchFilterText)
  }, [debouncedApplyRecordFilters, searchFilterText])

  const onTableChange = useCallback((pagination, updatedFilters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination: updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: updatedFilters,
      updateFilters
    })
  }, [updatePagination, updateSorter, updateFilters, filters])

  const loading = recordsLoading || startDateLoading

  const ListHeaderPanelComponent = isMobile ? MobileListHeaderPanel : ListHeaderPanel
  const listHeaderAlign = isMobile ? 'left' : 'right'

  const errors = []
  if (error) {
    errors.push(error)
  }
  if (startDateError) {
    errors.push(startDateError)
  }
  const hasErrors = errors.length > 0

  const hasCreatePermission = hasAllSessionPermissions(requiredPermissions.create)

  return (
    <>
      <>
        <EditReportModal ref={editReportModalRef} {...{ companyId, refetchQueries, reportStartDate, reportType, useDBCache }} createMutation={queries.createMutation} updateMutation={queries.updateMutation} />
        <OpenReportModal
          ref={viewReportModalRef} {...{ companyId, reportStartDate, reportType }} useCompanyId={accountType === 'msp' && companyId !== sessionCompanyId}
        />
        <DeleteCustomReportConfirm ref={deleteConfirmRef} {...{ companyId, refetchQueries }} deleteMutation={queries.deleteMutation} />
        <InvalidateReportCacheConfirm ref={invalidateConfirmRef} {...{ companyId, refetchQueries }} invalidateMutation={queries.invalidateMutation} />
        <SendReportModal ref={sendReportModalRef} {...{ companyId }} />
        <DownloadReportModal ref={downloadReportModalRef} {...{ companyId }} />
      </>
      <ListHeader align={listHeaderAlign}>
        <ListHeaderPanelComponent align={listHeaderAlign}>
          <SearchBar
            placeholder={I18n.t('searchPlaceholder', trOpt)}
            value={searchFilterText}
            allowClear
            onChange={onSearchChange}
          />
          <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading || hasErrors} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
          {hasCreatePermission && <Button onClick={openCreateReportModal} type='primary' icon='plus' disabled={!planValid || !reportStartDate || hasErrors}>{I18n.t('createNewReport', trOpt)}</Button>}
          <Button onClick={openViewReportModal} type='primary' icon='eye' disabled={!reportStartDate || hasErrors}>{I18n.t('reports.common.viewReport')}</Button>
        </ListHeaderPanelComponent>
      </ListHeader>
      {
        hasErrors
          ? <ErrorAlerts error={errors} defaultError={I18n.t('reportLoadError', trOpt)} />
          : (
            <Table
              loading={loading}
              columns={columns}
              dataSource={records}
              onChange={onTableChange}
              pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
            />
          )
      }
    </>
  )
}

const BaseReportsList = ({
  companyId,
  accountType,
  sessionCompanyId,
  userId,
  planValid,
  reportType,
  queries,
  useDBCache,
  requiredPermissions
}) => {
  const { viewQuery, viewQueryName, startDateQuery, startDateQueryName } = queries
  const { data: { [viewQueryName]: records = [] } = {}, loading, error, refetch } = useQuery(viewQuery, { variables: { companyId }, notifyOnNetworkStatusChange: true })
  const { data: { [startDateQueryName]: reportStartDate } = {}, loading: startDateLoading, error: startDateError, refetch: refetchStartDate } = useQuery(startDateQuery, { variables: { companyId }, notifyOnNetworkStatusChange: true })

  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  const storageId = useMemo(() => `reportCentre|${reportType}|${sessionCompanyId}|${userId}`, [userId, sessionCompanyId, reportType])
  useEffect(() => {
    try {
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        const listFilter = JSON.parse(listFilterString)
        Object.keys(listFilter).forEach(key => {
          const value = listFilter[key]
          switch (key) {
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'filters':
              updateFilters(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) { }
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    if (!storageId) {
      return
    }

    let viewFilter = {}
    try {
      const viewFilterString = localStorage.getItem(storageId)
      if (viewFilterString) {
        viewFilter = JSON.parse(viewFilterString)
      }
      viewFilter = { ...viewFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(viewFilter))
    } catch (e) { }
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  return (
    <Column>
      <IntercomHeader Size='h1' id='report-centre-report-list-header' style={{ marginBottom: '0px' }}>{I18n.t(`${reportType}.title`, trOpt)}</IntercomHeader>
      <p style={{ marginBottom: '0px' }}>{I18n.t(`${reportType}.description`, trOpt)}</p>
      <BaseReportsListView
        {...{
          records,
          searchFilterText,
          updateSearchFilterText,
          sorter,
          updateSorter,
          filters,
          updateFilters,
          pagination,
          updatePagination,
          refetch,
          loading,
          error,
          companyId,
          sessionCompanyId,
          accountType,
          planValid,
          reportStartDate,
          startDateError,
          startDateLoading,
          refetchStartDate,
          reportType,
          useDBCache,
          queries,
          requiredPermissions
        }}
        refetchQueries={[{ query: viewQuery, variables: { companyId } }]}
      />
    </Column>
  )
}

export default connect(
  state => {
    const { companyId: sessionCompanyId, userId } = getSession(state) || {}
    return { sessionCompanyId, userId }
  }
)(BaseReportsList)
