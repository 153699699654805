import React, { useCallback } from 'react'
import { withTheme } from 'styled-components'
import { ResponsiveBar } from '@nivo/bar'
import I18n from 'i18n-js'

const TrendsChart = ({ eventPercentages: { open, visit, compromise, report }, theme, showPhishAlert }) => {
  const data = [
    {
      type: 'opens',
      value: open
    },
    {
      type: 'visits',
      value: visit
    },
    {
      type: 'compromises',
      value: compromise
    }
  ]
  if (showPhishAlert) {
    data.push({
      type: 'reports',
      value: report
    })
  }

  const colors = useCallback(({ indexValue }) => {
    switch (indexValue) {
      case 'opens':
        return theme.simulationOpen
      case 'visits':
        return theme.simulationVisit
      case 'compromises':
        return theme.simulationCompromise
      case 'reports':
        return theme.simulationReport
      default:
        return theme.simulationSend
    }
  }, [theme])
  const formatPercentage = value => `${value}%`

  return (
    <ResponsiveBar
      data={data}
      maxValue={100}
      enableLabel={false}
      tooltipLabel={e => e.indexValue}
      tooltipFormat={formatPercentage}
      gridYValues={[0, 20, 40, 60, 80, 100]}
      axisLeft={{ format: formatPercentage, tickValues: [0, 20, 40, 60, 80, 100] }}
      axisBottom={{ tickRotation: -45, format: value => I18n.t(value, { scope: 'uPhish.common.charts' }) }}
      animate={false}
      margin={{ top: 10, right: 0, bottom: 75, left: 40 }}
      indexBy='type'
      keys={['value']}
      padding={0.65}
      colors={colors}
      theme={{
        axis: {
          ticks: {
            text: {
              // fill: theme.primary // In case we're asked to make the axis label blue
              fontFamily: 'inherit'
            }
          }
        }
      }}
    />
  )
}

export default withTheme(TrendsChart)
