import _isEmpty from 'lodash/isEmpty'
import _findLastIndex from 'lodash/findLastIndex'
import _last from 'lodash/last'
import _sortBy from 'lodash/sortBy'
import _isNil from 'lodash/isNil'
import moment from 'moment'

import { formatDateTime } from './datetime'

export const getAllVersionsPolicyData = ({ documents = [], learnerResults = [], lastMajorIndex = -1, sentVersions = [], visitedVersions = [], signedVersions = [] }) => {
  let docIndex = -1
  const all = {
    status: 'notSent',
    rag: 'transparent',
    offline: false
  }
  if (!_isEmpty(signedVersions)) {
    all.status = 'signed'
    docIndex = _findLastIndex(documents, doc => signedVersions.includes(doc.id))
    const lastSigned = _last(_sortBy(learnerResults.filter(r => !_isNil(r.signed)), ['signed']))
    if (lastSigned) {
      all.lastSigned = moment(lastSigned.signed)
      all.lastSignedStr = formatDateTime(all.lastSigned)
      all.offline = lastSigned.signedOffline
    }
  } else if (!_isEmpty(visitedVersions)) {
    all.status = 'visited'
    docIndex = _findLastIndex(documents, doc => visitedVersions.includes(doc.id))
  } else if (!_isEmpty(sentVersions)) {
    all.status = 'pending'
    docIndex = _findLastIndex(documents, doc => sentVersions.includes(doc.id))
  }

  const doc = documents[docIndex]
  if (doc) {
    const { status: versionStatus, major, majorVersion, minorVersion } = doc
    all.versionNo = `v${majorVersion}.${minorVersion}`
    all.major = major
    all.archived = versionStatus === 'archived'
    if (all.status === 'signed') {
      // Signature RAG Indicator
      if (versionStatus === 'live') {
        // Green - Signed the current version
        all.rag = 'green'
      } else if (lastMajorIndex !== -1 && docIndex >= lastMajorIndex) {
        // Amber - Signed the last major version or later
        all.rag = 'amber'
      } else {
        // Red - They've signed an outdated version of this policy
        all.rag = 'red'
      }
    } else if (all.status === 'visited' || all.status === 'pending') {
      // Red - The user has been sent or visited the last major version or later
      all.rag = 'red'
    }
  } else if (all.status !== 'notSent') {
    // Red - Policy Document has been removed
    all.rag = 'red'
  }

  return all
}

export default getAllVersionsPolicyData
