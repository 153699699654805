import React from 'react'
import routes from '../../../constants/routes'
import { LearnerLoginInfo } from '../uLearn/LearnerLogin'

const LearnerLoginPolicies = ({ companyId }) => (
  <LearnerLoginInfo
    route={routes.OUTSTANDING_ACTIVITIES_LOGIN}
    {...{ companyId }}
    trOpt={{ scope: 'settings.learnerLoginPolicies' }}
  />
)

export default LearnerLoginPolicies
