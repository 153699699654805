import React from 'react'

import UpdateLanguagesModal from './UpdateLanguagesModal'
import { UPDATE_SIMULATION_LANDING_PAGE_LANGUAGES } from '../Queries/uPhish'

const trScope = 'modals.updateLandingPageLanguagesModal'

const UpdateLandingPageLanguagesModal = React.forwardRef(({ refetchQueries }, ref) =>
  <UpdateLanguagesModal
    mutation={UPDATE_SIMULATION_LANDING_PAGE_LANGUAGES}
    addTitleKey={`${trScope}.addTitle`}
    removeTitleKey={`${trScope}.removeTitle`}
    {...{ ref, refetchQueries }}
  />
)

export default UpdateLandingPageLanguagesModal
