import React, { useMemo } from 'react'
import { useQuery } from 'react-apollo'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'

import { ContentWrap, LoadingBlock } from '../../components/common'
import { DASHBOARD_REPORT } from '../../components/Queries/Reports'
import DashboardHeader from './DashboardHeader'
import { DashboardGrid } from './Dashboard'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import useGlobalState from '../../hooks/useGlobalState'
import useHasSessionPermission from '../../hooks/useHasSessionPermission'
import { permissions } from '../../constants/permissions'

const SimplifiedDashboard = ({ excludeInactiveUsersInReports }) => {
  const { companyName, companyDomain } = useGlobalState(
    state => ({
      companyName: state.session?.companyName,
      companyDomain: state.session?.companyDomain
    })
  )
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const canShowStats = hasAllSessionPermissions([
    permissions.LEARNER_LIST,
    permissions.LEARNER_READ,
    permissions.GROUP_LIST,
    permissions.GROUP_READ
  ])
  const {
    loading, data: {
      dashboardReport: {
        companyDashboardDetails
      } = {}
    } = {}
  } = useQuery(DASHBOARD_REPORT, {
    variables: {
      excludeInactiveUsers: excludeInactiveUsersInReports
    },
    skip: !canShowStats
  })

  const { companyDetails, totalGroups, totalUsers } = useMemo(() => companyDashboardDetails || {}, [companyDashboardDetails])

  return (
    <>
      {loading && <LoadingBlock fullScreen loading={loading} />}
      <ContentWrap>
        <DashboardGrid>
          <DashboardHeader
            companyName={companyDetails?.name ?? companyName}
            companyDomain={companyDetails?.domain ?? companyDomain}
            totalGroups={totalGroups || 0}
            totalUsers={totalUsers || 0}
            showStats={canShowStats && !_isNil(companyDetails)}
            showToggle={false}
          />
        </DashboardGrid>
      </ContentWrap>
    </>
  )
}
export default

connect(
  state => ({
    ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
  })

)(SimplifiedDashboard)
