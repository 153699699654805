import React, { useMemo, useRef, useCallback, useState } from 'react'
import { Card, Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _uniq from 'lodash/uniq'

import { GET_USERS } from '../../components/Queries/Users'
import UsersTable from '../../components/Users/UsersTable'
import AddUserModal from '../../components/Modals/AddUserModal'
import { LoadingBlock, ErrorAlerts, ListHeader, ContentWrap, SearchBar, ListHeaderPanel } from '../../components/common'
import { COMMS_TYPE_SHORT_LABELS } from '../../constants/users'
import IntercomHeader from '../../components/IntercomHeader'
import { useGlobalState, useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'
import { superRoleIdDowngrades } from '../../constants/roles'
import { searchRecords } from '../../helpers/listPages'

const Team = () => {
  const { hasAllSessionPermissions, hasAnySessionPermission } = useHasSessionPermission()
  const { sessionUserId, planValid, partner, sessionUserIsUsecure } = useGlobalState(
    state => ({
      sessionUserId: state?.session?.userId,
      planValid: state?.session?.planValid,
      partner: state?.session?.partner,
      sessionUserIsUsecure: state?.session?.isUsecure
    })
  )
  const [searchText, setSearchText] = useState('')

  const editUserRef = useRef(null)
  const onAddClick = useCallback(() => {
    if (editUserRef.current) {
      editUserRef.current.open()
    }
  }, [editUserRef])
  const onEditClick = useCallback((userId) => {
    if (editUserRef.current) {
      editUserRef.current.open(userId)
    }
  }, [editUserRef])

  const { loading, error, refetch, data } = useQuery(GET_USERS)

  const allUsers = useMemo(() =>
    (_get(data, 'users') || []).map((user) => {
      let { roles } = user
      // Downgrade super/default roles visually to match back end behaviour when this user logs in
      if (!sessionUserIsUsecure) {
        roles = roles.map(role => superRoleIdDowngrades[role] ?? role)
      }
      roles = _uniq(roles)

      return {
        key: user.id,
        name: user.name,
        email: user.email,
        commsType: COMMS_TYPE_SHORT_LABELS[user.commsType] || user.commsType,
        roles,
        currentUser: sessionUserId === user.id,
        is2FAEnabled: user.is2FAEnabled
      }
    }),
  [data, sessionUserId, sessionUserIsUsecure])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const users = useMemo(
    () => searchRecords({ records: allUsers, searchText, searchKeys: ['name', 'email'] }),
    [allUsers, searchText]
  )

  return (
    <ContentWrap>
      <AddUserModal ref={editUserRef} />
      <Card>
        <IntercomHeader Size='h1' id='manage-admin-users-header'>{I18n.t('users.title')}</IntercomHeader>
        <LoadingBlock fullScreen loading={loading} />
        {error && <ErrorAlerts {...{ error }} defaultError={I18n.t('users.error')} />}
        {!error && !loading && (
          <>
            <ListHeader>
              <ListHeaderPanel align='left' />
              <ListHeaderPanel align='right'>
                <SearchBar
                  placeholder={I18n.t('common.search')}
                  value={searchText}
                  allowClear
                  onChange={onSearchChange}
                />
                {hasAllSessionPermissions([permissions.USER_CREATE]) && (
                  <Button disabled={!planValid} type='primary' icon='user-add' onClick={onAddClick}>{I18n.t('users.addUser')}</Button>
                )}
              </ListHeaderPanel>
            </ListHeader>
            <UsersTable users={users} partner={partner} onEdit={onEditClick} showActions={(hasAnySessionPermission([permissions.USER_UPDATE, permissions.USER_DELETE])) && planValid} refetchUsers={refetch} />
          </>
        )}
      </Card>
    </ContentWrap>
  )
}

export default Team
