import I18n from 'i18n-js'
import { types } from '../actions/view'
import { types as sessionTypes } from '../actions/session'

const {
  VIEW_LOADING,
  VIEW_TITLE_SET,
  VIEW_DEFAULT_TITLE_SET,
  VIEW_TITLE_RESET,
  VIEW_TITLES_RESET,
  VIEW_TITLE_COMPANY_SET,
  VIEW_APPICON_SET,
  VIEW_FAVICON16_SET,
  VIEW_FAVICON32_SET,
  VIEW_FAVICON180_SET,
  VIEW_SAFARI_ICON_SET,
  VIEW_FAVICON_RESET,
  VIEW_EXCLUDE_INACTIVE_USERS_SET
} = types
const {
  SESSION_INIT
} = sessionTypes

export const initialState = {
  loading: true,
  title: I18n.t('common.loading'),
  defaultTitle: I18n.t('common.loading'),
  appIcon: null,
  favicon16: null,
  favicon32: null,
  favicon180: null,
  safariTabIcon: null,
  excludeInactiveUsersInReports: true
}

const actionsMap = {
  [SESSION_INIT]: (state, { payload: { view: payload } = {} }) => ({
    ...state,
    ...payload
  }),
  [VIEW_LOADING]: (state, { payload: loading = false }) => ({
    ...state,
    loading
  }),
  [VIEW_TITLE_SET]: (state, { payload: title }) => ({
    ...state,
    title
  }),
  [VIEW_TITLE_COMPANY_SET]: (state, { payload: companyName }) => {
    const pageTitle = companyName ? I18n.t('common.fullPageTitle', { companyName }) : I18n.t('common.pageTitle')
    return {
      ...state,
      title: pageTitle,
      defaultTitle: pageTitle
    }
  },
  [VIEW_DEFAULT_TITLE_SET]: (state, { payload: defaultTitle }) => ({
    ...state,
    defaultTitle
  }),
  [VIEW_TITLE_RESET]: state => ({
    ...state,
    title: state.defaultTitle
  }),
  [VIEW_TITLES_RESET]: state => ({
    ...state,
    title: I18n.t('common.pageTitle'),
    defaultTitle: I18n.t('common.pageTitle')
  }),
  [VIEW_FAVICON16_SET]: (state, { payload: favicon16 }) => ({
    ...state,
    favicon16
  }),
  [VIEW_FAVICON32_SET]: (state, { payload: favicon32 }) => ({
    ...state,
    favicon32
  }),
  [VIEW_FAVICON180_SET]: (state, { payload: favicon180 }) => ({
    ...state,
    favicon180
  }),
  [VIEW_SAFARI_ICON_SET]: (state, { payload: safariTabIcon }) => ({
    ...state,
    safariTabIcon
  }),
  [VIEW_APPICON_SET]: (state, { payload: appIcon }) => ({
    ...state,
    appIcon
  }),
  [VIEW_EXCLUDE_INACTIVE_USERS_SET]: (state, { payload: excludeInactiveUsersInReports }) => ({
    ...state,
    excludeInactiveUsersInReports
  }),
  [VIEW_FAVICON_RESET]: state => ({
    ...state,
    appIcon: null,
    favicon16: null,
    favicon32: null,
    favicon180: null,
    safariTabIcon: null
  })
}

const reducer = (state = initialState, action = {}) => {
  const fn = actionsMap[action.type]
  return fn ? fn(state, action) : state
}

export default reducer
