import React, { useState, useMemo, useCallback } from 'react'
import { Layout, AutoComplete, Divider, message } from 'antd'
import { Prompt } from 'react-router-dom'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _pick from 'lodash/pick'
import {
  Emails, SettingsSidebar, uPhish, uLearn, Theme, uPolicy, Features, Office365, GoogleSync, Api, Language,
  PreferredDomain, RiskReport, EndUserPortal, OutstandingActivitiesLogin, WeeklySummary, Timezone, ProductNames,
  MessageInjection, UBreachPro, SSO
} from '../../components/Settings'
import { connect } from '../../hocs'
import { getSessionAndSettings } from '../../state/selectors'
import { creators as settingsActions } from '../../state/actions/settings'
import { CompulsoryCourses } from '../../components/Settings/uLearn'
import { AutoPhish } from '../uPhish'
import { ListHeader, ListHeaderPanel } from '../../components/common/List'
import SaveConfirmRefModal from '../../components/Modals/SaveConfirmRefModal.js'
import useHasSessionPermission from '../../hooks/useHasSessionPermission.js'
const { Option } = AutoComplete
const { AutoEnrol, CourseReminder, CourseWaitTime, GapAnalysisReminder, MinimumPassScore, ReportSettings, VideoPlayback } = uLearn
const { PolicyExternalLink, PolicySignatureSettings, PolicyReminder } = uPolicy
const { Content } = Layout
const { DefaultEmail, CourseEmail, DummySimulationEmail, GapAnalysisEmail, GoogleSyncEmail, Office365Email, PolicyEmail, RiskReportEmail, RiskReportParentEmail, ReportEmail, WeeklySummaryEmail, Whitelisting, BreachAlertEmail } = Emails
const { CompromiseMessage, PhishAlert, TopLevelDomainAllowList, WorkingHours } = uPhish

const SETTINGS_COMPONENTS = {
  autoEnrol: AutoEnrol,
  courseReminder: CourseReminder,
  gapAnalysisReminder: GapAnalysisReminder,
  courseWaitTime: CourseWaitTime,
  features: Features,
  minimumPassScore: MinimumPassScore,
  office365: Office365,
  googleSync: GoogleSync,
  preferredDomain: PreferredDomain,
  reportSettings: ReportSettings,
  theme: Theme,
  api: Api,
  weeklySummary: WeeklySummary,
  compulsoryCourses: CompulsoryCourses,
  policyExternalLink: PolicyExternalLink,
  policySignatureSettings: PolicySignatureSettings,
  language: Language,
  defaultEmail: DefaultEmail,
  courseEmail: CourseEmail,
  dummySimulationEmail: DummySimulationEmail,
  gapAnalysisEmail: GapAnalysisEmail,
  googleSyncEmail: GoogleSyncEmail,
  office365Email: Office365Email,
  policyEmail: PolicyEmail,
  riskReportEmail: RiskReportEmail,
  riskReportParentEmail: RiskReportParentEmail,
  reportEmail: ReportEmail,
  weeklySummaryEmail: WeeklySummaryEmail,
  whitelisting: Whitelisting,
  riskReport: RiskReport,
  compromiseMessage: CompromiseMessage,
  workingHours: WorkingHours,
  phishAlert: PhishAlert,
  autoPhish: AutoPhish,
  policyReminder: PolicyReminder,
  topLevelDomainAllowList: TopLevelDomainAllowList,
  endUserPortal: EndUserPortal,
  outstandingActivitiesLogin: OutstandingActivitiesLogin,
  timezone: Timezone,
  breachAlertEmail: BreachAlertEmail,
  uBreachPro: UBreachPro,
  productNames: ProductNames,
  messageInjection: MessageInjection,
  videoPlayback: VideoPlayback,
  sso: SSO
}
const SettingsWrap = styled(Layout)`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0;

  .usecure-settings-layout {
    background-color: #ffffff;
  }
  .usecure-settings-body {
    min-height: 280px;
    overflow: hidden;
    padding: 25px 0 0 25px;
  }
`

const SettingsHeader = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding: 25px 25px 0px;
  h1 {
    font-size: 36px;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 5px;
  }
  .certain-category-icon {
    color: #6e6e6e;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
  }
`

const ResultOption = styled.div`
  p {
    font-family: unset;
    margin: 0;
  }
  .ant-divider {
    margin: 3px 0 0;
  }
  .ant-divider-horizontal {
    margin: 3px 0 0;
  }
`

export const Settings = ({ session, settings = {}, updateSettings = () => {}, defaultTenant, match, history }) => {
  const { userId, companyId, accountType, locale, companyLocale, planValid } = session
  const { platformAccess } = settings
  const [activeKey, updateActiveKey] = useState(null)
  const [openKeys, updateOpenKeys] = useState([])
  const [settingsRoutes, setSettingsRoutes] = useState([])
  const [filteredSettingsRoutes, setFilteredSettingsRoutes] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [redirectPath, setRedirectPath] = useState(null)
  const [allowPrompt, setAllowPrompt] = useState(true)

  const { hasAllSessionPermissions, hasAnySessionPermission } = useHasSessionPermission()

  const saveConfirmRefModalRef = React.useRef(null)
  const contentRef = React.useRef(null)

  const content = useMemo(() => {
    let Content = SETTINGS_COMPONENTS[activeKey] || null
    if (Content) {
      const { showRiskScoreOption, uPhish: uPhishEnabled, uBreachProEnabled } = settings
      const { planValid } = session
      Content = (
        <Content
          ref={contentRef}
          {...{
            defaultTenant,
            userId,
            companyId,
            settings: defaultTenant ? (settings.defaultTenantSettings) : settings,
            updateSettings,
            locale,
            companyLocale,
            showRiskScoreOption,
            uPhishEnabled,
            uBreachProEnabled,
            disableSubmit: !planValid,
            accountType,
            hasAllSessionPermissions,
            hasAnySessionPermission
          }}
        />
      )
    }

    return Content
  }, [activeKey, settings, userId, companyId, locale, companyLocale, updateSettings, defaultTenant, session, accountType, hasAllSessionPermissions, hasAnySessionPermission])

  const onSelect = (value) => {
    const objSelectedRouteObj = settingsRoutes.find(current => current.value === value)
    if (objSelectedRouteObj) {
      setSearchTerm('')
      history.push(objSelectedRouteObj.path)
    } else {
      message.error(I18n.t('common.somethingWentWrong'))
    }
  }
  const onSearch = (searchVal) => {
    setSearchTerm(searchVal)
    const filteredSettings = settingsRoutes.filter(value =>
      String(value.text).toLowerCase().includes(searchTerm.toLowerCase()) ||
      (value.parentText && String(value.parentText).toLowerCase().includes(searchTerm.toLowerCase()))
    )
    setFilteredSettingsRoutes(filteredSettings)
  }

  const resetRedirectPrompt = useCallback(() => {
    setRedirectPath(null)
    setAllowPrompt(true)
  }, [])

  const resetRedirectPromptAndPush = useCallback(() => {
    if (redirectPath) {
      history.push(redirectPath)
      resetRedirectPrompt()
    }
  }, [history, redirectPath, resetRedirectPrompt])

  const handlePromptOnCancel = useCallback(() => {
    saveConfirmRefModalRef.current.close()
    resetRedirectPrompt()
  }, [resetRedirectPrompt])

  const handlePromptOnOpen = useCallback(() => {
    if (planValid) saveConfirmRefModalRef.current.open()
  }, [planValid])

  const handleRedirectNoSave = useCallback(() => {
    resetRedirectPromptAndPush()
  }, [resetRedirectPromptAndPush])

  const handleRedirectSaveForm = useCallback(async () => {
    if (_isFunction(contentRef.current?.triggerSubmit)) {
      await contentRef.current.triggerSubmit()
    }
    resetRedirectPromptAndPush()
  }, [resetRedirectPromptAndPush])

  const shouldShowPrompt = (location) => {
    if (allowPrompt && _isFunction(contentRef.current?.hasChanged) && contentRef.current.hasChanged()) {
      setRedirectPath(location.pathname)
      setAllowPrompt(false)
      handlePromptOnOpen()
      return false
    }
    return true
  }

  let title = 'nav.navBar.settings'
  let titleId = 'settings-main-header'
  if (defaultTenant) {
    title = 'settings.settingsForm.defaultCustomerSettingsTitle'
    titleId = 'default-customer-settings-main-header'
  } else if (accountType === 'msp' && platformAccess) {
    title = 'nav.navBar.nfrSettings'
    titleId = 'nfr-settings-main-header'
  }
  title = I18n.t(title)

  return (
    <SettingsWrap className='usecure-settings-layout'>
      <SaveConfirmRefModal ref={saveConfirmRefModalRef} onCancel={handlePromptOnCancel} onYes={handleRedirectSaveForm} onNo={handleRedirectNoSave} />
      <Prompt
        message={(location) => shouldShowPrompt(location)}
      />
      <SettingsHeader className='usecure-settings-layout'>
        <ListHeader>
          <ListHeaderPanel align='left'>
            <div>
              <h1 id={titleId}>{title}</h1>
              {defaultTenant && <p>{I18n.t('settings.settingsForm.defaultCustomerSettingsAlert')}</p>}
            </div>
          </ListHeaderPanel>
          <ListHeaderPanel align='right'>
            <AutoComplete
              optionLabelProp='value'
              value={searchTerm}
              style={{ width: 300 }}
              onSelect={onSelect}
              onSearch={onSearch}
              placeholder={I18n.t('settings.settingsForm.searchInput')}
            >
              {filteredSettingsRoutes.map(({ value, text, path, parentText }) =>
                <Option key={value} value={value}>
                  <ResultOption>
                    <p>{text}</p>
                    {parentText && <small>{parentText}</small>}
                    <Divider />
                  </ResultOption>
                </Option>
              )}
            </AutoComplete>
          </ListHeaderPanel>
        </ListHeader>
      </SettingsHeader>
      <Layout className='usecure-settings-layout'>
        <SettingsSidebar
          {...{ activeKey, updateActiveKey, openKeys, updateOpenKeys, defaultTenant, history }}
          {..._pick(session, ['partner', 'accountType', 'prospectStatus', 'mspId'])}
          {..._pick(settings, ['platformAccess', 'uLearn', 'uPhish', 'uPolicy', 'office365Enabled', 'api', 'enablePublicApi', 'enableProspects', 'enableRiskReportTenants', 'phishAlertEnabled', 'uBreachProEnabled'])}
          office365Enabled={_get(settings, 'office365.enabled')}
          googleSyncEnabled={_get(settings, 'googleSync.enabled')}
          screenPath={_get(match, 'params.screenPath')}
          setSettingsRoutes={setSettingsRoutes}
        />
        <Content className='usecure-settings-body'>
          {content}
        </Content>
      </Layout>
    </SettingsWrap>
  )
}

export const settingsConnector = connect(
  getSessionAndSettings,
  dispatch => ({
    updateSettings: settings => dispatch(settingsActions.replace(settings))
  })
)

export default settingsConnector(Settings)
