import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { connect } from '../hocs'
import routes from '../constants/routes'
import { getSession } from '../state/selectors/session'
import Holding from '../views/Holding'

const HoldingRoute = ({ session, redirect = routes.HOME, ...rest }) => {
  return (
    !session.planValid
      ? <Route path={routes.HOLDING} {...rest} component={Holding} />
      : <Redirect to={redirect} />
  )
}

HoldingRoute.propTypes = {
  session: PropTypes.object.isRequired,
  redirect: PropTypes.string
}

export default connect(
  state => ({ session: getSession(state) })
)(HoldingRoute)
