import React from 'react'
import { generatePath } from 'react-router-dom'
import I18n from 'i18n-js'

import { SettingsContainer, DescriptionContainer, ActionContainer, CopyToClipboard } from '../common'
import routes from '../../../constants/routes'
import { connect } from '../../../hocs'
import { getAppOrigin } from '../../../state/selectors/settings'

export const LearnerLoginInfo = connect(state => ({
  appOrigin: getAppOrigin(state)
}))(
  ({ route, appOrigin, companyId = '', trOpt }) => {
    const link = `${appOrigin}${generatePath(route, { companyId })}`
    return (
      <div>
        <SettingsContainer>
          <h1>{I18n.t('title', trOpt)}</h1>
          <DescriptionContainer>
            <p>{I18n.t('description', trOpt)}</p>
            <a href={link} target='_blank' rel='noopener noreferrer'>{link}</a>
          </DescriptionContainer>
          <ActionContainer>
            <CopyToClipboard value={link} />
          </ActionContainer>
        </SettingsContainer>
      </div>
    )
  }
)

const LearnerLogin = ({ companyId }) => (
  <LearnerLoginInfo
    route={routes.OUTSTANDING_ACTIVITIES_LOGIN}
    {...{ companyId }}
    trOpt={{ scope: 'settings.learnerLogin' }}
  />
)

export default LearnerLogin
