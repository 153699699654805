import React, { useState, useCallback, useImperativeHandle } from 'react'
import { Modal, Table } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { ListHeader, ListHeaderPanel, ListHeaderTitle, ErrorAlerts, ModalPager } from '../../common'
import ViewRecordCard from '../../common/ViewRecordCard'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'

const trOpt = { scope: 'modals.userBreachDetailsModal' }
const uBreachTrOpt = { scope: 'uBreach' }

const UserBreachModal = styled(Modal)`
  height: calc(100% - 60px);
  max-width: 800px;
  top: 100px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const renderBreachData = (breachedData) => {
  if (_isArray(breachedData)) return breachedData.map(data => <div key={data}>{data}</div>)
  if (_isObject(breachedData)) return Object.entries(breachedData).map(([key, value]) => <div key={key}>{`${key}: ${value}`}</div>)
  return breachedData
}

const UserBreachDetailsModal = React.forwardRef((props, ref) => {
  const error = false
  const [visible, setVisible] = useState(false)
  const [pager, setPager] = useState(null)
  const [email, setEmail] = useState(null)
  const [breachTitle, setBreachTitle] = useState(null)
  const [breachedData, setBreachedData] = useState(null)

  useImperativeHandle(ref, () => ({
    open: async ({ record: { email, breachTitle, breachedData }, pager }) => {
      setEmail(email)
      setBreachTitle(breachTitle)
      setBreachedData(breachedData.map(data => {
        return {
          ...data,
          key: data.type
        }
      }))
      setPager(pager)
      setVisible(true)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setEmail(null)
    setBreachTitle(null)
    setBreachedData(null)
    setPager(null)
  }

  const columns = [
    {
      title: I18n.t('dataClass', trOpt),
      dataIndex: 'type',
      key: 'type',
      render: text => I18n.t(`dataClasses.${text}`, { ...uBreachTrOpt, defaults: [{ message: text }] })
    },
    {
      title: I18n.t('dataBreached', trOpt),
      dataIndex: 'value',
      key: 'value',
      render: renderBreachData,
      width: '66%'
    }
  ]

  return (
    <UserBreachModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={<ModalPager {...pager} />}
      afterClose={afterClose}
      width='90%'
      bodyStyle={{ paddingTop: 5 }}
    >
      <ViewRecordCard>
        <div className='view-record__body'>
          <ListHeader>
            <ListHeaderPanel align='left'>
              <ListHeaderTitle>{email ? I18n.t('title', { ...trOpt, email, breachTitle }) : ''}</ListHeaderTitle>
            </ListHeaderPanel>
          </ListHeader>
          {
            error
              ? <ErrorAlerts {...{ error }} defaultError={I18n.t('errors.breachedDataCouldNotBeLoaded', uBreachTrOpt)} />
              : breachedData && <Table columns={columns} dataSource={breachedData} />
          }
        </div>
      </ViewRecordCard>
    </UserBreachModal>
  )
})

export default UserBreachDetailsModal
