import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Button, Form, message, Upload } from 'antd'
import I18n from 'i18n-js'

import { apiUrl } from '../../apollo-client/common'
import EditCourseListItem from './EditCourseListItem'
import { getSessionToken } from '../../helpers/session'

const trOpt = { scope: 'editCourse.editCourseCarouselSlide' }

const ImageWrap = styled.div`
  width: ${({ width = '400px' }) => width};

  img {
    width: 100%;
  }
`

const ImagePlaceholder = styled.div`
  align-items: center;
  background-color: #f4f4f4;
  display: flex;
  height: ${({ height = '224px' }) => height};
  justify-content: center;
`

const SlideImage = ({ image, title, width, height }) => (
  <ImageWrap {...{ width }}>
    {
      image
        ? (
          <img src={image} alt={title} />
        )
        : (
          <ImagePlaceholder height={height}>
            <span>{I18n.t('editCourse.common.noImage')}</span>
          </ImagePlaceholder>
        )
    }
  </ImageWrap>
)

const SlideTitle = index => I18n.t('slideTitle', { ...trOpt, slideNo: index + 1 })

const SlideItem = ({ item, index }) => {
  const title = SlideTitle(index)
  const { image } = item

  return (
    <div style={{ display: 'flex' }}>
      <SlideImage {...{ title, image }} width='150px' height='85px' />
      <span style={{ fontStyle: (title ? 'normal' : 'italic'), marginLeft: 5 }}>{title || I18n.t('editCourse.common.noTitleAvailable')}</span>
    </div>
  )
}

const SlideForm = ({ item, index, onChange: onChangeProp }) => {
  const title = SlideTitle(index)
  const { image } = item

  const onImageUpload = useCallback((info) => {
    if (info.file.status === 'done') {
      message.success(I18n.t('editCourse.common.fileUploadedSuccessfully', { name: info.file.name }))
      onChangeProp('image', info.file.response.url)
    } else if (info.file.status === 'error') {
      message.error(I18n.t('editCourse.common.fileUploadedFailed', { name: info.file.name }))
    }
  }, [onChangeProp])

  return (
    <>
      <Form.Item label={I18n.t('editCourse.common.image')}>
        <SlideImage {...{ title, image }} />
        <Upload name='courseWysiwyg' onChange={onImageUpload} showUploadList={false} action={`${apiUrl}/upload-course-wysiwyg`} headers={{ 'x-token': getSessionToken() }}>
          <Button id='courseWysiwyg'>{I18n.t('editCourse.common.chooseImage')}</Button>
        </Upload>
      </Form.Item>
    </>
  )
}

const EditCourseCarouselSlide = props => {
  return (
    <EditCourseListItem
      {...props}
      modalProps={{
        width: '90%',
        style: { maxWidth: 1100 }
      }}
      itemComponent={SlideItem}
      formComponent={SlideForm}
    />
  )
}

export default EditCourseCarouselSlide
