import React from 'react'
import { Popover, Icon } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import LocalesCell from './LocalesCell'

const LocaleCellContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 3px;
  padding-right: 2px;
`

const LocalesIconPopover = ({ locales, getPopupContainer }) => (
  <Popover
    placement='bottomRight'
    title={I18n.t('common.availableLanguages')}
    arrowPointAtCenter
    {...{ getPopupContainer }}
    content={
      <LocaleCellContainer>
        <LocalesCell {...{ locales }} />
      </LocaleCellContainer>
    }
  >
    <Icon type='global' />
  </Popover>
)

export default LocalesIconPopover
