import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, DatePicker } from 'antd'
import moment from 'moment'
import _isArray from 'lodash/isArray'

import MutationFormErrors from './MutationFormErrors'

const { RangePicker } = DatePicker

class MutationFormDateRangePicker extends Component {
  constructor (props) {
    super(props)

    this.disabledDate = this.disabledDate.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  disabledDate (current) {
    const { min, max } = this.props
    if (current) {
      if (min && max) {
        return !current.isBetween(min, max, 'day', '[]')
      } else if (min) {
        return current.isBefore(min)
      } else {
        return current.isAfter(max)
      }
    }
    return false
  }

  handleChange (dates, dateStrings) {
    this.props.onChange(this.props.id, _isArray(dates) ? dates.map(date => moment.isMoment(date) ? date.format('YYYY-MM-DD') : null) : null)
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, extra, formItemStyle, errors = [], required, className, value, disabled } = this.props
    const showErrors = errors.length > 0

    return (
      <Form.Item
        className={className} label={label} extra={extra} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <RangePicker
          value={_isArray(value) ? value.map(v => moment(v)) : null}
          separator='-'
          onChange={this.handleChange}
          disabled={disabled}
          disabledDate={this.disabledDate}
          format={['l', 'YYYY-MM-DD']}
        />
      </Form.Item>
    )
  }
}

MutationFormDateRangePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  extra: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.func]),
  allowClear: PropTypes.bool,
  formItemStyle: PropTypes.object,
  disabled: PropTypes.bool
}

MutationFormDateRangePicker.defaultProps = {
  id: '',
  label: null,
  required: false,
  placeholder: '',
  value: null,
  extra: null,
  onChange: () => {},
  disabled: false
}

export default MutationFormDateRangePicker
