import I18n from 'i18n-js'
import { connect } from '../../../hocs'
import { getAppOrigin } from '../../../state/selectors/settings'
import SettingsForm from '../SettingsForm'
import PortalLogin from './PortalLogin'
import GenerateMagicLink from './GenerateMagicLinkURL'
import _omit from 'lodash/omit'
const trOpt = { scope: 'settings.endUserPortal.controlAccess' }

class EndUserPortal extends SettingsForm {
  constructor (props) {
    super(props)
    this.defaultValue = {
      endUserPortalEnabled: true
    }
  }

  get headerId () {
    return 'settings-end-user-portal-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  mutateValues (values) {
    return _omit(values, 'loginPath', 'getMagicLink')
  }

  get _fields () {
    const { companyId, settings = {}, appOrigin } = this.props

    const { endUserPortalEnabled } = {
      ...this.defaultValue,
      ...settings
    }
    return [
      {
        id: 'endUserPortalEnabled',
        type: 'switch',
        label: I18n.t('title', trOpt),
        defaultValue: endUserPortalEnabled
      },
      {
        id: 'loginPath',
        type: 'custom',
        visible: ({ endUserPortalEnabled }) => endUserPortalEnabled === true,
        component: PortalLogin,
        appOrigin: appOrigin,
        companyId: companyId
      },
      {
        id: 'getMagicLink',
        type: 'custom',
        visible: ({ endUserPortalEnabled }) => endUserPortalEnabled === true,
        component: GenerateMagicLink,
        appOrigin: appOrigin,
        companyId: companyId
      }
    ]
  }
}

export default connect(
  state => ({
    appOrigin: getAppOrigin(state)
  })
)(EndUserPortal)
