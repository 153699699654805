import React from 'react'

/*
- Component must be an Ant Design Table
- Parent element must use display: flex with a flex-direction of column
*/

const freezeTableHeader = ({ forwardRef = false } = {}) => Component => {
  class FreezeTableHeader extends React.Component {
    constructor (props) {
      super(props)

      this.state = {
        scrollY: 0
      }

      this.tableContainer = React.createRef()

      this.handleResize = this.handleResize.bind(this)
      this.updateHeaderCellWidths = this.updateHeaderCellWidths.bind(this)
      this.updateHeaderCellWidthsWithDelay = this.updateHeaderCellWidthsWithDelay.bind(this)
    }

    handleResize () {
      this.updateScrollY()
      this.updateHeaderCellWidths()
    }

    initializeResizeElements () {
      const start = Date.now()
      let headerInit = false
      let scrollInit = false
      const initialIntv = setInterval(() => {
        const tableContainer = this.tableContainer.current
        const headerCells = tableContainer ? tableContainer.querySelectorAll('.ant-table-header thead > tr:first-child > th') : []
        if (headerCells.length > 0) {
          this.updateHeaderCellWidths()
          headerInit = true
        }
        const container = tableContainer ? tableContainer.parentNode : null
        if (container) {
          this.updateScrollY()
          scrollInit = true
        }
        if ((headerInit && scrollInit) || (Date.now() - start) > 5000) {
          clearInterval(initialIntv)
        }
      }, 100)
    }

    updateHeaderCellWidths () {
      const tableContainer = this.tableContainer.current
      if (tableContainer) {
        const firstRowCellsWidths = Array.from(tableContainer.querySelectorAll('.ant-table-body tbody > tr:first-child > td')).map(td => td.clientWidth)
        const headerCells = tableContainer.querySelectorAll('.ant-table-header thead > tr:first-child > th')
        headerCells.forEach((th, index) => {
          th.style.width = `${firstRowCellsWidths[index]}px`
        })
      }
    }

    updateHeaderCellWidthsWithDelay (delay = 100) {
      setTimeout(() => this.updateHeaderCellWidths(), delay)
    }

    updateScrollY () {
      const tableContainer = this.tableContainer.current
      const container = tableContainer ? tableContainer.parentNode : null
      if (container && tableContainer) {
        const siblingHeight = Array.from(container.childNodes).reduce((height, child) => {
          if (tableContainer !== child) {
            height += child.offsetHeight
          }

          return height
        }, 0)
        const tableHeader = tableContainer.querySelector('.ant-table-header table')
        const tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0
        this.setState({ scrollY: container.clientHeight - siblingHeight - tableHeaderHeight })
      }
    }

    componentDidMount () {
      window.addEventListener('resize', this.handleResize)
      this.initializeResizeElements()
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.handleResize)
    }

    render () {
      if (forwardRef) {
        return this.renderWithForwardedRef()
      }

      return (
        <div ref={this.tableContainer} style={{ flex: 1, overflow: 'hidden' }}>
          <Component
            {...this.props}
            scroll={{ y: this.state.scrollY }}
          />
        </div>
      )
    }

    renderWithForwardedRef () {
      const { forwardedRef, ...rest } = this.props

      return (
        <div ref={this.tableContainer} style={{ flex: 1, overflow: 'hidden' }}>
          <Component
            ref={forwardedRef} {...rest}
            scroll={{ y: this.state.scrollY }}
          />
        </div>
      )
    }
  }

  if (forwardRef) {
    return React.forwardRef((props, ref) => (
      <FreezeTableHeader {...props} forwardedRef={ref} />
    ))
  }

  return FreezeTableHeader
}

export default freezeTableHeader
