import React from 'react'
/**
 * Intercom Header was created because tooltips were shifting when the screen size changed. This was because of the space-between
 * flex. The tooltip would be added as an extra element in the flex and the position absolute would then have nothing to attach on to.
 * @param {String} Size should be of text element e,g. h1, h2, p
 * @param {String} id intercom tooltip id
 * @param {String} className space separated
 * @param {Object<String>} style title styles
 */
const IntercomHeader = ({ Size = 'h2', id, className, style = {}, children }) => {
  return (
    <span>
      <Size id={id} className={className} style={{ ...style }}>{children}</Size>
    </span>
  )
}

export default IntercomHeader
