import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.policyEmail' }

class PolicyEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'policy'
    this.showSentTestButton = true
    this.generateSubjectFields(['newSubject', 'newSubjectMultiple', 'reminderSubject', 'reminderSubjectMultiple'], trOpt)
    this.requiredUpdatePermissions = [permissions.SETTINGS_UPOLICY_UPDATE, permissions.SETTINGS_EMAIL_UPDATE]
  }

  get headerId () {
    return 'settings-policy-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(PolicyEmail)
