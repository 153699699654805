import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const SignHeaderLinkContainer = styled.div`
  height: 0;
  text-align: center;
`

const SignHeaderLinkElement = styled(Link)`
  position: relative;
  top: -40px;
`

const SignHeaderLink = ({ to, children }) => (
  <SignHeaderLinkContainer>
    <SignHeaderLinkElement to={to}>{children}</SignHeaderLinkElement>
  </SignHeaderLinkContainer>
)

export default SignHeaderLink
