import I18n from 'i18n-js'
import React from 'react'

import { REMOVE_LEARNERS_FROM_POLICY } from '../Queries/uPolicy'
import ActionConfirm from './ActionConfirm'

const RemoveLearnersFromPolicyConfirm = ({ visible = false, setVisible = () => { }, policy, learners = [], refetchQueries, onClose = () => {} }) => {
  const trOpt = { scope: 'modals.removeLearnersFromPolicyConfirm', count: learners.length, policy: policy.name }
  return (
    <ActionConfirm
      {...{ visible, setVisible, refetchQueries, trOpt, onClose }}
      ids={learners.map(l => l.id)}
      mutation={REMOVE_LEARNERS_FROM_POLICY}
      variableName='learnerIds'
      variables={{ policyIds: [policy.id] }}
      body={<span>{I18n.t('body', trOpt)}</span>}
    />
  )
}
export default RemoveLearnersFromPolicyConfirm
