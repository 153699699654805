import React, { useCallback } from 'react'
import { Icon, Layout, Menu } from 'antd'
import styled from 'styled-components'
import { lighten } from 'polished'
import I18n from 'i18n-js'
import useGlobalState from '../../hooks/useGlobalState'

const { Sider } = Layout
const { SubMenu } = Menu

const SettingsMenu = styled(Menu)`
  .ant-menu-submenu-selected {
    color: ${({ theme }) => theme.primary};
  }
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: ${({ theme }) => theme.primary};
  }
  .ant-menu-item-selected {
    color: ${({ theme }) => theme.primary} !important;
    background-color: ${({ theme }) => lighten(0.6, theme.primary)} !important;
  }
  .ant-menu-item:after {
    border-right-color: ${({ theme }) => theme.primary};
  }
`

const renderMenuItem = ({ key, title, icon }) => {
  title = title || key
  return (
    <Menu.Item key={key}>
      <span>{icon ? <Icon type={icon} /> : null}{title}</span>
    </Menu.Item>
  )
}

const AccountSettingsSidebar = ({
  activeContent,
  updateActiveContent
}) => {
  const onSelect = useCallback(({ key }) => updateActiveContent(key), [updateActiveContent])
  const { passwordAuthAllowed } = useGlobalState(
    state => ({
      passwordAuthAllowed: state?.session?.passwordAuthAllowed
    })
  )

  return (
    <Sider
      width={250}
      style={{ background: '#fff' }}
    >
      <SettingsMenu
        mode='inline'
        defaultSelectedKeys={[activeContent]}
        style={{ height: '100%' }}
        onSelect={onSelect}
      >
        {
          [
            { key: 'Details', title: I18n.t('common.details'), icon: 'book' },
            { key: 'Password', title: I18n.t('common.fields.password'), icon: 'file-exclamation', visible: passwordAuthAllowed },
            { key: 'Security', title: I18n.t('common.security'), icon: 'check-square' },
            { key: 'Language', title: I18n.t('common.language'), icon: 'global' }
          ].reduce((acc, { key, title, icon, menu, visible = true }) => {
            if (visible) {
              if (menu) {
                acc.push(
                  <SubMenu key={key} title={<span><Icon type={icon} />{title || key}</span>}>
                    {menu.map(({ key, title, icon }) => renderMenuItem({ key, title: title || key, icon }))}
                  </SubMenu>
                )
              } else {
                acc.push(renderMenuItem({ key, title: title || key, icon }))
              }
            }

            return acc
          }, [])
        }
      </SettingsMenu>
    </Sider>
  )
}

export default AccountSettingsSidebar
