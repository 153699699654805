import React, { useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle } from 'react'
import I18n from 'i18n-js'
import { message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'

import {
  UPDATE_COMPANY_LOCALE_SETTINGS, GET_COMPANY_LOCALE_SETTINGS,
  UPDATE_DEFAULT_TENANT_LOCALE_SETTINGS, GET_DEFAULT_TENANT_LOCALE_SETTINGS
} from '../Queries/Companies'
import { LoadingBlock } from '../common'
import { DEFAULT_LANGUAGE, LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { withRefreshSessionState } from '../../hocs'
import { SettingsFormElement } from './SettingsForm'
import { useHasSessionPermission } from '../../hooks'
import { getRequiredUpdatePermissions } from '../../helpers/settings'

const trOpt = { scope: 'settings.language' }
const LOCALES_REQUIRED = window.__USECURE_CONFIG__.REACT_APP_COMPANY_LOCALES_REQUIRED === 'true'

const Language = React.forwardRef(({ refreshSessionState, defaultTenant, disableSubmit }, ref) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const [, setForceRender] = useState(null)
  const { loading, data, refetch } = useQuery(defaultTenant ? GET_DEFAULT_TENANT_LOCALE_SETTINGS : GET_COMPANY_LOCALE_SETTINGS)
  const form = useRef(null)

  const onSuccess = useCallback(async () => {
    message.success(I18n.t('successMessage', trOpt))
    await refetch()
    await refreshSessionState()
    resetFields()
  }, [refetch, refreshSessionState])

  const resetFields = () => {
    if (form && form.current) form.current.resetFields()
    setForceRender(Date.now())
  }
  const { locale, contentLocales } = useMemo(() => {
    const dataId = defaultTenant ? 'getDefaultTenantLanguageSettings' : 'company'
    return {
      locale: _get(data, [dataId, 'locale']) || DEFAULT_LANGUAGE,
      contentLocales: _get(data, [dataId, 'contentLocales']) || []
    }
  }, [data, defaultTenant])

  useEffect(() => {
    if (form && form.current) {
      form.current.replaceValues({ locale, contentLocales }, true)
    }
  }, [locale, contentLocales, form])

  const requiredUpdatePermissions = getRequiredUpdatePermissions({
    defaultTenant
  })
  const isUpdateAllowed = hasAllSessionPermissions(requiredUpdatePermissions)
  const hasChanged = useCallback(() => isUpdateAllowed && form.current?.hasChanged() === true, [form, isUpdateAllowed])

  useImperativeHandle(ref, () => ({
    hasChanged: hasChanged
  }), [hasChanged])

  const onChange = () => setForceRender(Date.now())

  if (loading) return <LoadingBlock fullScreen loading={loading} />

  const fields = [{
    id: 'locale',
    label: I18n.t('common.preferredLanguage'),
    defaultValue: locale,
    type: 'select',
    options: LANGUAGE_SELECT_OPTIONS,
    sortOptions: true,
    formItemStyle: { maxWidth: 400 }
  }, {
    id: 'contentLocales',
    label: I18n.t('contentLocales', trOpt),
    type: 'multiSelect',
    options: LANGUAGE_SELECT_OPTIONS,
    sortOptions: true,
    defaultValue: contentLocales,
    placeholder: I18n.t('common.fields.languagesPlaceHolder'),
    extra: I18n.t('contentLocalesExtra', trOpt),
    required: LOCALES_REQUIRED,
    requiredError: I18n.t('common.fields.languagesRequiredError')
  }]

  return (
    <SettingsFormElement
      headerId='settings-language-header'
      title={I18n.t('common.language')}
      desc={<p>{I18n.t('thisWillSetTheDefaultLanguage', trOpt)}</p>}
      formProps={{
        mutation: defaultTenant ? UPDATE_DEFAULT_TENANT_LOCALE_SETTINGS : UPDATE_COMPANY_LOCALE_SETTINGS,
        onSuccess,
        onChange,
        failureMessage: I18n.t('failureMessage', trOpt),
        submitLabel: I18n.t('common.save'),
        ref: form,
        fields: fields,
        skipResetFieldsOnSubmit: true
      }}
      disableSubmit={disableSubmit || !isUpdateAllowed}
      isUpdateAllowed={isUpdateAllowed}
      hasChanged={hasChanged()}
    />
  )
})

export default withRefreshSessionState(Language)
