import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'

const ChangedIndicatorSpan = styled.span`
  color: ${({ theme }) => theme.primary};
  margin-right: 5px;
`

const ChangedIndicator = ({ changed, tooltip, tooltipPlacement = 'topLeft' }) => {
  if (!changed) {
    return null
  }
  const span = <ChangedIndicatorSpan>*</ChangedIndicatorSpan>
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        {span}
      </Tooltip>
    )
  }
  return span
}

export default ChangedIndicator
