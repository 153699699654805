import React, { useCallback, useRef, useEffect } from 'react'
import { Card, Input, Divider, Icon, Button } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

const trOpt = { scope: 'signIn.authenticationSteps' }

const StepsContainer = styled.div`
  h5 {
    margin: 0;
  }

  .ant-card .ant-btn {
    margin-top: 15px;
  }

  .ant-divider {
    margin-top: 0;
  }

   li {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    padding-inline-start: 10px;
   }
`

const StepsDetail = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  .anticon {
    font-size: 20px;
    margin-right: 10px;
  }

  p {
    font-family: unset;
    margin: 0;
  }
`

const AuthenticationSteps = ({ type, icon, onInputChange, handleSubmit, input, optionClick, title, details }) => {
  const handleKeyUp = useCallback(event => {
    if (event.keyCode === 13) {
      handleSubmit()
    }
  }, [handleSubmit])
  const inputRef = useRef(null)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [type, inputRef])

  return (
    <StepsContainer>
      <Card>
        <h5>{title}</h5>
        <Input
          ref={inputRef}
          placeholder={type === 'authentication' ? '6-digit code' : null}
          maxLength={type === 'recovery' ? 18 : 6}
          onChange={onInputChange} onKeyUp={handleKeyUp} value={input}
        />
        <Button type='primary' onClick={handleSubmit}>{I18n.t('verify', trOpt)}</Button>
      </Card>
      <StepsDetail>
        <Icon type={icon} />
        <p>{details}</p>
      </StepsDetail>
      {optionClick && (
        <>
          <Divider />
          <h4>{I18n.t('havingProblems', trOpt)}</h4>
          {type === 'authentication' && (
            <li onClick={optionClick}>
              {I18n.t('enterRecoveryCode', trOpt)}
            </li>
          )}
        </>
      )}
    </StepsContainer>
  )
}

export default AuthenticationSteps
