import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { Modal, Button } from 'antd'
import { SaveConfirmModalIcon, SaveConfirmModalTitle, SaveConfirmModalContent } from '../EditCourse/SaveConfirmModal.js'
const trOpt = { scope: 'editCourse.saveConfirmModal' }

const StyledFooterModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0px 10px 10px 16px !important;
    border-top: none;
  }
`
const SaveConfirmRefModal = forwardRef(({ onCancel, onYes, onNo }, ref) => {
  const [visible, setVisible] = useState(false)
  const onYesClick = useCallback(() => {
    onYes()
    setVisible(false)
  }, [onYes])
  const onNoClick = useCallback(() => {
    onNo()
    setVisible(false)
  }, [onNo])
  const onCancelClick = useCallback(() => {
    onCancel()
    setVisible(false)
  }, [onCancel])
  const closeModal = useCallback(() => setVisible(false), [])

  useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false)
  }))

  return (
    <StyledFooterModal
      {...{
        title: null,
        closable: false,
        maskClosable: false,
        onCancel: closeModal,
        visible: visible
      }}
      footer={[
        <Button key='cancel' onClick={onCancelClick}>{I18n.t('common.cancel')}</Button>,
        <Button key='no' onClick={onNoClick}>{I18n.t('common.no')}</Button>,
        <Button key='yes' onClick={onYesClick}>{I18n.t('common.yes')}</Button>
      ]}
    >
      <SaveConfirmModalIcon type='question-circle' />
      <SaveConfirmModalTitle>{I18n.t('wouldYouLikeToSaveBeforeLeaving', trOpt)}</SaveConfirmModalTitle>
      <SaveConfirmModalContent>{I18n.t('ifYouDontSaveYourChangesWillBeLost', trOpt)}</SaveConfirmModalContent>
    </StyledFooterModal>
  )
})
export default SaveConfirmRefModal
