import isEmail from 'isemail'
import _isEmpty from 'lodash/isEmpty'
import _isString from 'lodash/isString'

import validateDomain from './validateDomain'

const LOCAL_PART_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+$/

const validateEmail = email => {
  if (
    _isString(email) && !_isEmpty(email) &&
    isEmail.validate(email, { minDomainAtoms: 2 }) // isemail considers a@b valid, minDomainAtoms=2 requires a@b.c
  ) {
    const [localPart, domain] = email.split('@')
    return LOCAL_PART_REGEX.test(localPart) && validateDomain(domain)
  }
  return false
}

export default validateEmail
