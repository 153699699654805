/**
 * Checks if a given set of permissions has the required permissions
 * @param {Object} options
 * @param {string[]} options.availablePermissions
 * @param {string[]} options.requiredPermissions
 * @param {boolean} options.matchAll - if true, all required permissions must be availble, otherwise the presence of any required permission is sufficient
 * @returns {boolean}
  */
export const hasPermission = ({ availablePermisisons = [], requiredPermissions = [], matchAll = false }) => {
  if (!requiredPermissions.length) return true

  if (matchAll) {
    return requiredPermissions.every(permission => availablePermisisons.includes(permission))
  }

  return requiredPermissions.some(permission => availablePermisisons.includes(permission))
}

export const hasAnyPermission = ({ availablePermisisons = [], requiredPermissions = [] }) => hasPermission({ availablePermisisons, requiredPermissions, matchAll: false })

export const hasAllPermissions = ({ availablePermisisons = [], requiredPermissions = [] }) => hasPermission({ availablePermisisons, requiredPermissions, matchAll: true })
