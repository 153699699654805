import React, { Component } from 'react'
import { message } from 'antd'
import I18n from 'i18n-js'
import { compose } from 'recompose'
import styled from 'styled-components'

import MutationForm from '../../components/MutationForm/MutationForm'
import { SignInContainer, SignInHeader } from '../../components/SignIn'
import { connect, withAppTitleAndIcons } from '../../hocs'
import { showErrors } from '../../helpers'
import { creators as settingsActions } from '../../state/actions/settings'
import { captureSentryError } from '../../helpers/sentry'
import { RESET_PASSWORD } from '../../components/Queries/Users'
import GoToLoginButton from '../../components/SignIn/GoToLoginButton'

const trOpt = { scope: 'resetPassword' }

const SuccessContainer = styled.div`
  text-align: center;
`

export class ResetPassword extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: 'password'
    }

    this.fields = [
      { id: 'password', placeholder: I18n.t('newPassword', trOpt), type: 'password', required: true, autofill: false },
      { id: 'confirmPassword', placeholder: I18n.t('confirmPassword', trOpt), type: 'password', required: true, autofill: false }
    ]

    this.handleFailureConsoleMsg = 'ResetPassword.onFailure'

    this.doPasswordsMatch = this.doPasswordsMatch.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleFailure = this.handleFailure.bind(this)
  }

  get title () {
    return I18n.t('resetYourPassword', trOpt)
  }

  get successMessage () {
    return I18n.t('resetSuccessMessage', trOpt)
  }

  get submitLabel () {
    return I18n.t('common.reset')
  }

  componentDidMount () {
    this.props.updateSettings({ useSingleFavicon: true })
  }

  doPasswordsMatch (form) {
    const { state: { values: { password, confirmPassword } = {} } = {} } = form || {}
    if (password !== confirmPassword) {
      message.error(I18n.t('passwordsDoNotMatch', trOpt))
      return false
    }

    return true
  }

  handleSuccess () {
    this.setState({ status: 'success' })
  }

  handleFailure (e) {
    captureSentryError(e, { msg: this.handleFailureConsoleMsg })
    showErrors(e)
  }

  render () {
    const { match: { params: { token } = {} } = {} } = this.props
    const variables = { ...this.values, token }
    const { status } = this.state

    return (
      <SignInContainer>
        <SignInHeader>{this.title}</SignInHeader>
        {status === 'password' && (
          <MutationForm
            mutation={RESET_PASSWORD}
            onSuccess={this.handleSuccess}
            onFailure={this.handleFailure}
            submitLabel={this.submitLabel}
            variables={variables}
            disableSubmitIfInvalid={false}
            isValidBeforeSubmit={this.doPasswordsMatch}
            invalidMessage={null}
            fields={this.fields}
            focusOnMount
          />
        )}
        {status === 'success' && (
          <SuccessContainer>
            <p>{this.successMessage}</p>
            <p>{I18n.t('signIn.pleaseClickToGoToLogin')}</p>
            <GoToLoginButton />
          </SuccessContainer>
        )}
      </SignInContainer>
    )
  }
}

export const resetPasswordCompose = compose(
  connect(
    undefined,
    dispatch => ({
      updateSettings: settings => dispatch(settingsActions.update(settings))
    })
  ),
  withAppTitleAndIcons({ isSignInPage: true })
)

export default resetPasswordCompose(ResetPassword)
