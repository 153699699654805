import { Modal, message } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { SET_LEARNER_ACTIVE, SET_LEARNER_INACTIVE } from '../Queries/Learners'
import { invalidateLearnersQueryCache, showErrors } from '../../helpers'

const confirm = Modal.confirm

const setLearnerStatusConfirm = async (learnerIds = [], { refetchQueries, setInactive = false, client = authenticatedClient, onClose = () => {} } = {}) => {
  const trOpt = { scope: 'modals.setLearnerStatusConfirm', count: learnerIds.length }
  confirm({
    title: I18n.t(setInactive ? 'setInactiveTitle' : 'setActiveTitle', trOpt),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        await client.mutate({
          mutation: setInactive ? SET_LEARNER_INACTIVE : SET_LEARNER_ACTIVE,
          variables: { learnerIds },
          refetchQueries,
          update: invalidateLearnersQueryCache
        })

        message.success(I18n.t('successMessage', trOpt))
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      } finally {
        onClose()
      }
    },
    onCancel: onClose
  })
}

export default setLearnerStatusConfirm
