import React from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'
import mime from 'mime-types'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isString from 'lodash/isString'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _isEqual from 'lodash/isEqual'
import _merge from 'lodash/merge'
import SettingsForm from './SettingsForm'
import { themes } from '../../theme'
import { BCP_47_REGEX, LANGUAGE_CODES } from '../../constants/languages'
import { MultiLocaleLabel } from './LanguageSelector'
import { InputWithReset } from './common'
import { createLocaleCopyObjectFromKeys, getLocaleCopyDeltaObject, injectRegionalValuesIntoLocaleObject } from '../../helpers/locale'
import { SETTING_DELETE_VALUE } from '../../constants/settings'

const { main: defaultTheme } = themes
const trOpt = { scope: 'settings.theme' }
const DEFAULT_MULTI_LOCALE_VALUES = {
  appPageTitle: createLocaleCopyObjectFromKeys('common.pageTitle')
}

const ResetTheme = React.forwardRef(({ onClick = () => {}, disabled }, ref) => (
  <Button type='primary' onClick={onClick} disabled={disabled} icon='undo'>{I18n.t('resetTheme', trOpt)}</Button>
))

class Theme extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'appThemeLogo',
      'appThemePrimaryColor',
      'appThemeSecondaryColor',
      'appThemeNavColor',
      'appPageTitle',
      'useSingleFavicon',
      'appIcon',
      'appFavicon16',
      'appFavicon32',
      'appFavicon180',
      'safariTabIcon'
    ]
    this.multiLocaleFieldIds = [
      'appPageTitle'
    ]
    this.includeFormLocale = true

    this.state = {
      updatedMultiLocaleValues: {}
    }

    Object.defineProperty(this, 'defaultValue', {
      get: () => this.getDefaultValue()
    })

    this.resetToDefault = this.resetToDefault.bind(this)
  }

  get headerId () {
    return 'settings-theme-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  getDefaultValue () {
    const { defaultTenant = false, settings: { parentDefaultSettings } = {} } = this.props
    let defaultValue = {
      appThemeLogo: defaultTheme.appThemeLogo,
      appThemePrimaryColor: defaultTheme.primary,
      appThemeSecondaryColor: defaultTheme.secondary,
      appThemeNavColor: defaultTheme.nav,
      appPageTitle: DEFAULT_MULTI_LOCALE_VALUES.appPageTitle,
      appIcon: '/favicon.svg',
      appFavicon16: '/favicon-16x16.png',
      appFavicon32: '/favicon-32x32.png',
      appFavicon180: '/apple-touch-icon.png',
      safariTabIcon: '/safari-pinned-tab.svg',
      useSingleFavicon: true
    }

    if (!defaultTenant && parentDefaultSettings) {
      defaultValue = {
        ...defaultValue,
        ..._pick(parentDefaultSettings, this.settingIds),
        // appPageTitle - merge parentDefaultSettings.appPageTitle with defaultValues.appPageTitle
        // Parent settings (i.e. the Default Customer Settings of a customer's MSP) may be missing values for languages without overridden values.
        appPageTitle: {
          ...defaultValue.appPageTitle,
          ...(parentDefaultSettings.appPageTitle ?? {})
        }
      }
    }

    return defaultValue
  }

  resetToDefault () {
    const form = this.form.current
    if (form) {
      form.replaceValues(this.formValuesFromDefault)
      this.setState({
        appPageTitle: DEFAULT_MULTI_LOCALE_VALUES.appPageTitle
      })
    }
  }

  getRefreshedLocaleFieldValue (id) {
    return _get(this.state.updatedMultiLocaleValues, [id, this.state.formLocale]) || this.getFieldDefaultValueMultiLocale(id)
  }

  refreshMultiLocaleFields () {
    const form = this.form.current
    if (form) {
      form.replaceValues(
        this.multiLocaleFieldIds.reduce((acc, id) => ({
          ...acc,
          [id]: this.getRefreshedLocaleFieldValue(id)
        }), {})
      )
    }
  }

  getBaseLocaleSearchPath (fieldId, locale) {
    const baseLocale = BCP_47_REGEX.test(locale) ? locale.substring(0, 2) : null
    let baseLocaleSearchPath
    if (baseLocale) {
      baseLocaleSearchPath = [fieldId, baseLocale]
    }
    return baseLocaleSearchPath
  }

  getFieldDefaultValueMultiLocale (fieldId, locale = this.state.formLocale) {
    const searchPath = [fieldId, locale]
    // Use base locale value if form locale is regional variant with no dedicated value
    const baseLocaleSearchPath = this.getBaseLocaleSearchPath(fieldId, locale)

    return [
      // Current value
      _get(this.props.settings, searchPath),
      // Current value for base locale
      baseLocaleSearchPath ? _get(this.props.settings, baseLocaleSearchPath) : null,
      // Default setting
      _get(this.defaultValue, searchPath),
      // Default setting for base locale
      baseLocaleSearchPath ? _get(this.defaultValue, baseLocaleSearchPath) : null
    ].find(value => _isBoolean(value) || (_isString(value) && !_isEmpty(value)))
  }

  getFieldResetValueMultiLocale (fieldId, locale = this.state.formLocale) {
    const { defaultTenant = false, settings = {} } = this.props
    const { parentDefaultSettings } = settings || {}
    const searchPath = [fieldId, locale]
    // Use base locale value if form locale is regional variant with no dedicated value
    const baseLocaleSearchPath = this.getBaseLocaleSearchPath(fieldId, locale)
    return [
      // Current value from MSP's Default Customer Setting
      (!defaultTenant && parentDefaultSettings ? _get(parentDefaultSettings, searchPath) : null),
      // Current value from MSP's Default Customer Setting for base locale
      (baseLocaleSearchPath && !defaultTenant && parentDefaultSettings ? _get(parentDefaultSettings, baseLocaleSearchPath) : null),
      // Default setting
      _get(this.defaultValue, searchPath),
      // Default setting for base locale
      (baseLocaleSearchPath ? _get(this.defaultValue, baseLocaleSearchPath) : null)
    ].find(value => _isBoolean(value) || (_isString(value) && !_isEmpty(value)))
  }

  get _fields () {
    const { settings = {} } = this.props
    const {
      appThemeLogo,
      appThemePrimaryColor,
      appThemeSecondaryColor,
      appThemeNavColor,
      appIcon,
      appFavicon16,
      appFavicon32,
      appFavicon180,
      safariTabIcon,
      useSingleFavicon
    } = {
      ...this.defaultValue,
      ...settings
    }
    const appPageTitle = this.getFieldDefaultValueMultiLocale('appPageTitle')

    return [
      {
        id: 'appThemeLogo',
        type: 'image',
        label: I18n.t('appThemeLogo', trOpt),
        defaultValue: appThemeLogo
      }, {
        id: 'appThemePrimaryColor',
        type: 'colour',
        label: I18n.t('appThemePrimaryColor', trOpt),
        defaultValue: appThemePrimaryColor,
        required: true,
        useOverlay: false
      }, {
        id: 'appThemeSecondaryColor',
        type: 'colour',
        label: I18n.t('appThemeSecondaryColor', trOpt),
        defaultValue: appThemeSecondaryColor,
        required: true,
        useOverlay: false
      }, {
        id: 'appThemeNavColor',
        type: 'colour',
        label: I18n.t('appThemeNavColor', trOpt),
        defaultValue: appThemeNavColor,
        required: true,
        useOverlay: false
      }, {
        id: 'appPageTitle',
        type: 'text',
        component: InputWithReset,
        label: <MultiLocaleLabel label={I18n.t('appPageTitle', trOpt)} />,
        required: true,
        defaultValue: appPageTitle,
        defaultSetting: this.getFieldResetValueMultiLocale('appPageTitle')
      }, {
        id: 'useSingleFavicon',
        type: 'switch',
        label: I18n.t('useSingleFavicon.label', trOpt),
        extra: I18n.t('useSingleFavicon.extra', trOpt),
        defaultValue: useSingleFavicon
      }, {
        id: 'appIcon',
        type: 'image',
        label: I18n.t('appIcon.label', trOpt),
        extra: I18n.t('appIcon.extra', trOpt),
        required: true,
        defaultValue: appIcon,
        resetValue: this.defaultValue.appIcon,
        width: 180,
        height: 180,
        fileTypes: [mime.types.svg],
        fileTypeError: I18n.t('appFaviconFileTypeError', trOpt),
        visible: values => values.useSingleFavicon === true
      }, {
        id: 'appFavicon32',
        type: 'image',
        label: I18n.t('appFavicon32', trOpt),
        required: true,
        defaultValue: appFavicon32,
        resetValue: this.defaultValue.appFavicon32,
        width: 64,
        height: 64,
        requiredWidth: 32,
        requiredHeight: 32,
        visible: values => values.useSingleFavicon !== true
      }, {
        id: 'appFavicon16',
        type: 'image',
        label: I18n.t('appFavicon16', trOpt),
        required: true,
        defaultValue: appFavicon16,
        resetValue: this.defaultValue.appFavicon16,
        width: 32,
        height: 32,
        requiredWidth: 16,
        requiredHeight: 16,
        visible: values => values.useSingleFavicon !== true
      }, {
        id: 'appFavicon180',
        type: 'image',
        label: I18n.t('appFavicon180', trOpt),
        required: true,
        defaultValue: appFavicon180,
        resetValue: this.defaultValue.appFavicon180,
        width: 180,
        height: 180,
        requiredWidth: 180,
        requiredHeight: 180,
        visible: values => values.useSingleFavicon !== true
      }, {
        id: 'safariTabIcon',
        type: 'image',
        label: I18n.t('safariTabIcon', trOpt),
        required: true,
        defaultValue: safariTabIcon,
        resetValue: this.defaultValue.safariTabIcon,
        width: 180,
        height: 180,
        fileTypes: [mime.types.svg],
        fileTypeError: I18n.t('appFaviconFileTypeError', trOpt),
        visible: values => values.useSingleFavicon !== true
      }, {
        id: 'reset',
        type: 'custom',
        component: ResetTheme,
        onClick: this.resetToDefault
      }
    ]
  }

  createLocaleCopyObject (fieldId) {
    const { updatedMultiLocaleValues } = this.state
    return LANGUAGE_CODES.reduce((acc, locale) => ({
      ...acc,
      [locale]: _get(updatedMultiLocaleValues, [fieldId, locale]) || this.getFieldDefaultValueMultiLocale(fieldId, locale)
    }), {})
  }

  mutateValues (values) {
    return {
      ..._omit(values, ['formLocale', 'reset', ...this.multiLocaleFieldIds]),
      ...this.multiLocaleFieldIds.reduce((acc, id) => {
        acc[id] = this.createLocaleCopyObject(id)
        return acc
      }, {})
    }
  }

  hasChanged () {
    const settingsValues = _cloneDeep(_pick(this.props.settings ?? {}, this.settingIds))
    this.multiLocaleFieldIds.forEach(fieldId => {
      if (settingsValues[fieldId]) {
        settingsValues[fieldId] = injectRegionalValuesIntoLocaleObject(settingsValues[fieldId])
      }
    })
    const initialValues = _merge(
      _cloneDeep(this.defaultValue), // cloning this as lodash merge mutates the destination object
      settingsValues
    )
    const currentValues = _omit(this.form.current?.variables.settings, ['reset'])
    return !_isEqual(initialValues, currentValues)
  }

  onChange (id, value) {
    super.onChange(id, value)
    if (this.multiLocaleFieldIds.includes(id)) {
      const { updatedMultiLocaleValues, formLocale } = this.state
      this.setState({
        updatedMultiLocaleValues: {
          ...updatedMultiLocaleValues,
          [id]: {
            ...(updatedMultiLocaleValues[id] || {}),
            [formLocale]: value
          }
        }
      })
    }
  }

  onSubmit (values, errors, variables) {
    // Replace multi locale setting objects with either:
    // - an object representing the delta from the system defaults
    // A deletion value as there is no delta and there's no need to store the value in the form
    // variables is the output of MutationForm variables getter i.e. the data passed to the mutation therein.
    // CL - Doing this in mutateValues proved a massive headache, changing the mutation variables here was simpler.
    this.multiLocaleFieldIds.forEach(id => {
      const defaultValue = DEFAULT_MULTI_LOCALE_VALUES[id]
      let value = variables.settings[id]
      if (defaultValue) {
        value = getLocaleCopyDeltaObject(value, defaultValue)
      }
      if (_isEmpty(value)) {
        // No changes for default, apply delete value which tells the settings update mutation to remove the setting
        value = SETTING_DELETE_VALUE
      }
      variables.settings[id] = value
    })
  }

  get formValuesFromSettings () {
    const { settings = {} } = this.props
    const { formLocale } = this.state
    return {
      ..._pick(settings, this.settingIds),
      ...this.multiLocaleFieldIds.reduce((acc, id) => {
        acc[id] = settings?.[id]?.[formLocale] ?? this.defaultValue?.[id]?.[formLocale]
        return acc
      }, {})
    }
  }

  onFormLocaleChange () {
    this.refreshMultiLocaleFields()
  }

  get formValuesFromDefault () {
    const { formLocale } = this.state
    return {
      ...this.defaultValue,
      ...this.multiLocaleFieldIds.reduce((acc, id) => {
        acc[id] = _get(this.defaultValue, [id, formLocale])
        return acc
      }, {})
    }
  }
}

export default Theme
