import React, { useCallback, useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ms from 'ms'
import { Modal, Icon } from 'antd'
import I18n from 'i18n-js'
import compose from 'recompose/compose'
import _get from 'lodash/get'
import _isString from 'lodash/isString'
import _throttle from 'lodash/throttle'

import { withLogout, withRefreshSessionState } from '../hocs'
import { SESSION_EXPIRES_AT, EXTEND_SESSION } from './Queries/Users'
import { refreshSessionToken, postChannelMessage } from '../helpers/session'
import useInterval from '../hooks/useInterval'

const trOpt = { scope: 'sessionManager' }

// ms before expiry that we should refresh the session - 1 minute by default
const REFRESH_SESSION_LEADTIME = ms(_isString(window.__USECURE_CONFIG__.REACT_APP_REFRESH_SESSION_LEADTIME) ? window.__USECURE_CONFIG__.REACT_APP_REFRESH_SESSION_LEADTIME : '1m')
// last activity must have happened within this interval for token refresh to occur - 5 minutes by default
const SESSION_INACTIVITY_TIMEOUT = ms(_isString(window.__USECURE_CONFIG__.REACT_APP_SESSION_INACTIVITY_TIMEOUT) ? window.__USECURE_CONFIG__.REACT_APP_SESSION_INACTIVITY_TIMEOUT : '5m')
// Session expire at query polling interval - 5 seconds by default
const SESSION_HEARTBEAT_INTV = ms(_isString(window.__USECURE_CONFIG__.REACT_APP_SESSION_HEARTBEAT_INTV) ? window.__USECURE_CONFIG__.REACT_APP_SESSION_HEARTBEAT_INTV : '5s')

let expiryModal = null
const destroyExpiryModal = () => {
  if (expiryModal) {
    expiryModal.destroy()
    expiryModal = null
  }
}

const SessionManager = ({ logOut, refreshSessionState, channel }) => {
  const [status, setStatus] = useState(null)
  const [expiresAt, setExpiresAt] = useState(null)
  const [lastActiveAt, setLastActiveAt] = useState(Date.now())

  const onCompleted = useCallback(data => {
    const { sessionExpiresAt: expiresAt } = data || {}
    setExpiresAt(expiresAt)
    if (!status) setStatus('active')
  }, [status])
  // Poll for session expiry date/time every 5 seconds - Without this the log out on expiry won't be triggered as expiresAt won't be set to null
  const { loading, refetch: refetchExpiry } = useQuery(SESSION_EXPIRES_AT, { onCompleted, notifyOnNetworkStatusChange: true, pollInterval: SESSION_HEARTBEAT_INTV })

  const [extendSession] = useMutation(EXTEND_SESSION)
  const refreshToken = useCallback(async () => {
    setStatus('refresh')
    try {
      await refreshSessionToken(extendSession)
      destroyExpiryModal()
      const result = await refetchExpiry()
      const expiresAt = _get(result, 'data.sessionExpiresAt')
      setExpiresAt(expiresAt)
      setStatus('active')
      postChannelMessage(channel, 'sessionExtended', expiresAt)
    } catch (e) {
      console.error('SessionManager.refreshTokenCheck - Error', e)
    }
  }, [extendSession, refetchExpiry, channel])
  const refreshTokenCheck = useCallback(async () => {
    // Check how long it's been since the user interacted with the app before refreshing their token
    if (!lastActiveAt || (Date.now() - lastActiveAt > SESSION_INACTIVITY_TIMEOUT)) {
      setStatus('prompt')
      expiryModal = Modal.confirm({
        title: I18n.t('extendConfirmDialog.title', trOpt),
        content: I18n.t('extendConfirmDialog.content', trOpt),
        onOk: async () => refreshToken(),
        onCancel: () => logOut(),
        okText: I18n.t('common.yes'),
        cancelText: I18n.t('common.no'),
        icon: <Icon type='exclamation-circle' />
      })
      return
    }

    return refreshToken()
  }, [refreshToken, logOut, lastActiveAt])

  // Mount and unmount processes
  useEffect(() => {
    // Mount
    const updateLastActiveAt = _throttle(() => {
      const now = Date.now()
      // Keep last active in sync between tabs to avoid inactive tab logging out
      postChannelMessage(channel, 'sessionLastActiveAt', now)
      setLastActiveAt(now)
    }, 1000)
    // Event listeners to track user activity
    window.addEventListener('mousemove', updateLastActiveAt, false)
    window.addEventListener('mousedown', updateLastActiveAt, false)
    window.addEventListener('keypress', updateLastActiveAt, false)
    window.addEventListener('touchmove', updateLastActiveAt, false)

    // Listen for events in other tabs/windows via a Broadcast Channel
    // This will also pick events from another BroadcastChannel instance using the same name
    let handleChannelMessage
    if (channel) {
      handleChannelMessage = e => {
        if (e.data) {
          const { id, data } = e.data || {}
          if (id === 'sessionRefresh') {
          // Refresh session state when another tab/window updates
            refreshSessionState()
            refetchExpiry()
          } else if (id === 'sessionReloadPage') {
          // Reload window - used when admin'ing into an account in uService
            window.location.reload()
          } else if (id === 'sessionLastActiveAt' && data) {
            // User activity event from another tab/window
            setLastActiveAt(data)
          } else if (id === 'sessionExtended' && data) {
          // Receive session extension message - use expire date/time from there ahead of next poll
            destroyExpiryModal()
            setExpiresAt(data)
            setStatus('active')
            refreshSessionState()
          } else if (id === 'sessionSignOut') {
          // User signed out in another tab/window
            setExpiresAt(null)
          }
        }
      }
      channel.addEventListener('message', handleChannelMessage, false)
    }

    return () => {
      // Unmount
      // Remove listeners
      window.removeEventListener('mousemove', updateLastActiveAt, false)
      window.removeEventListener('mousedown', updateLastActiveAt, false)
      window.removeEventListener('keypress', updateLastActiveAt, false)
      window.removeEventListener('touchmove', updateLastActiveAt, false)

      if (channel && handleChannelMessage) {
        channel.removeEventListener('message', handleChannelMessage, false)
      }

      // Clean up
      destroyExpiryModal()
    }
  }, [refreshSessionState, refetchExpiry, channel])

  const expiryChecker = useCallback(() => {
    if (loading) return

    const exp = expiresAt ? Date.parse(expiresAt) : null
    if (!exp && (status === 'active' || status === 'prompt')) {
      logOut()
    } else if (status === 'active') {
      if (exp) {
        const timeRemaining = exp - Date.now()
        if (timeRemaining > 0) {
          // Within session
          const timeRemainingUntilRefresh = timeRemaining - REFRESH_SESSION_LEADTIME
          if (timeRemainingUntilRefresh <= 0) {
            // Within extension window - prompt user unless we've detected recent activity
            refreshTokenCheck()
          }
        } else {
          logOut()
        }
      }
    }
  }, [loading, status, expiresAt, refreshTokenCheck, logOut])
  useInterval(expiryChecker, 1000)

  return null
}

export default compose(
  withLogout(),
  withRefreshSessionState
)(SessionManager)
