import React from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'
import { withTheme } from 'styled-components'

const CourseActions = ({ courseId, actions = [], performAction = () => {}, theme }) => {
  const handleMenuClick = e => performAction(e.key, courseId)
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item key={action.key} disabled={Boolean(action.disabled)}>{action.icon && <Icon type={action.icon} theme='twoTone' twoToneColor={theme.primary} />}  {action.label}</Menu.Item>
        ))
      }
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['hover']}>
      <Button>
        <Icon type='right' />
      </Button>
    </Dropdown>
  )
}

export default withTheme(CourseActions)
