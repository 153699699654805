import I18n from 'i18n-js'
import { RISK_SCORE_LEVELS } from '../constants/riskScore'

export const RiskLevelStatus = ({ riskScores, theme }) => {
  const trOpt = { scope: 'reports.dashboard' }
  let status = I18n.t('noRiskScore', trOpt)
  let colour = theme.primary
  if (riskScores.level === 'veryHigh') {
    status = I18n.t('veryPoor', trOpt)
    colour = theme.riskScoreVeryHigh
  } else if (riskScores.level === 'high') {
    status = I18n.t('poor', trOpt)
    colour = theme.riskScoreHigh
  } else if (riskScores.level === 'medium') {
    status = I18n.t('average', trOpt)
    colour = theme.riskScoreMedium
  } else if (riskScores.level === 'low') {
    status = I18n.t('good', trOpt)
    colour = theme.riskScoreLow
  } else if (riskScores.level === 'veryLow') {
    status = I18n.t('veryGood', trOpt)
    colour = theme.riskScoreVeryLow
  }
  return { level: riskScores.level, status, colour }
}
export const calculateRiskScorePercentage = (riskScore, level, config) => {
  if (!config || riskScore === 0) return 0
  const { veryLowMax, lowMax, mediumMax, highMax, maxRiskScore: veryHighMax } = config
  const minValues = { veryLow: 0, low: veryLowMax + 1, medium: lowMax + 1, high: mediumMax + 1, veryHigh: highMax + 1 }
  const maxValues = { veryLow: veryLowMax, low: lowMax, medium: mediumMax, high: highMax, veryHigh: veryHighMax }
  const levels = [...RISK_SCORE_LEVELS].reverse()
  let percent = 0
  levels.some(scoreLevel => {
    if (scoreLevel === level) {
      const max = maxValues[level]
      const min = minValues[level]
      const gaugeLevelScore = riskScore - min
      const gaugeLevelTotal = max - min
      percent += (gaugeLevelScore / gaugeLevelTotal) * 20
      return true
    }
    percent += 20
    return false
  })

  return percent
}
