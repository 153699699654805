import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-apollo'

import { IntroDetail, IntroWrap } from './LearnerCourseIntro'
import { HoldingContent } from '../../views/Holding'
import { GET_SIMPLE_COMPANY } from '../Queries/Companies'
import { getExpiredPlanHoldType } from '../../helpers/getExpiredPlanHoldType'

const LearnerCourseholding = ({ companyId }) => {
  const [holdType, setHoldType] = useState()
  const { loading, data, error } = useQuery(GET_SIMPLE_COMPANY, { variables: { id: companyId } })

  useEffect(() => {
    if (!loading) {
      const planName = data?.simpleCompany?.planName
      const planValid = data?.simpleCompany?.planValid

      if (error) {
        setHoldType('accessDenied')
        console.error(error)
      } else {
        setHoldType(planValid ? null : getExpiredPlanHoldType({ planName }))
      }
    }
  }, [loading, data, error])

  return (
    <IntroWrap>
      <IntroDetail>
        <HoldingContent {...{ holdType }} userType='endUser' />
      </IntroDetail>
    </IntroWrap>
  )
}

export default LearnerCourseholding
