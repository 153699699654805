import { message } from 'antd'
import I18n from 'i18n-js'
import _capitalize from 'lodash/capitalize'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'
import _isPlainObject from 'lodash/isPlainObject'
import _isString from 'lodash/isString'
import _uniqWith from 'lodash/uniqWith'

class ErrorHandler {
  constructor (e, opt) {
    const { processor, defaultError, messageDuration, includeErrorCode = false } = opt || {}
    this.defaultError = _isString(defaultError) ? defaultError : I18n.t('common.anErrorOccurred')
    this.errors = []
    this.empty = false
    this.messageDuration = messageDuration
    this.includeErrorCode = includeErrorCode === true
    // 'this' will not be ErrorHandler if processor is an arrow function (lexical binding) - use function()
    this.processor = _isFunction(processor) ? processor.bind(this) : null
    this.processError(e)
  }

  mergeErrorArray (e) {
    if (_isArray(e)) {
      if (e.every(_isNil)) return null

      return e.reduce((obj, error) => {
        return {
          graphQLErrors: [
            ...obj.graphQLErrors,
            ...(_isArray(_get(error, 'graphQLErrors')) ? error.graphQLErrors : [])
          ]
        }
      }, { graphQLErrors: [] })
    }

    return e
  }

  convertErrorObject (e) {
    // The e.graphQLErrors check is a haphazard way of telling the difference between an Error and an Apollo Error
    return e instanceof Error && _isNil(e.graphQLErrors) ? createErrorObject(e) : e
  }

  processError (e) {
    this.errors = []
    e = this.convertErrorObject(e)
    e = this.mergeErrorArray(e)
    this.empty = _isNil(e)
    if (this.empty) {
      return
    }

    const { graphQLErrors } = e || {}
    if (_isArray(graphQLErrors) && graphQLErrors.length > 0) {
      graphQLErrors.forEach(error => {
        const isUsecure = _get(error, 'extensions.exception.isUsecure') === true || error.isUSecure === true
        if (isUsecure) {
          this.errors.push({
            message: error.message,
            type: _get(error, 'extensions.exception.errorCode', error.errorCode),
            level: _get(error, 'extensions.exception.level', error.level),
            isUsecureClient: error.isUsecureClient
          })
        } else {
          const errorCode = _get(error, 'extensions.code')
          const errorName = _get(error, 'extensions.exception.name')
          if (errorCode === 'FORBIDDEN' || errorCode === 'BAD_USER_INPUT' || errorCode === 'UNAUTHENTICATED') {
            this.errors.push({ message: error.message, type: errorCode.toLowerCase(), original: error })
          } else if (errorName === 'SequelizeUniqueConstraintError') {
            const errors = _get(error, 'extensions.exception.errors')
            errors.forEach(e => this.errors.push({ message: _capitalize(e.message), type: 'field_value_exists', original: e }))
          } else if (errorName === 'SequelizeValidationError') {
            const errors = _get(error, 'extensions.exception.errors')
            errors.forEach(e => this.errors.push({ message: _capitalize(e.message), type: 'field_value_invalid', original: e }))
          }
        }
      })
    }

    if (_isFunction(this.processor)) {
      const result = this.processor(e, this.errors)
      if (_isArray(result)) {
        this.errors = result
      }
    }

    this.errors = this.errors.filter(e => e.level !== 'silent')

    this.deduplicateErrors()
  }

  deduplicateErrors () {
    if (_isEmpty(this.errors)) {
      return
    }
    this.errors = _uniqWith(this.errors, (a, b) => {
      const { level: aLevel = 'error', message: aMessage } = a
      const { level: bLevel = 'error', message: bMessage } = b

      return aLevel === bLevel && aMessage === bMessage
    })
  }

  getErrors (getDefaultIfEmpty = true) {
    return this.empty
      ? []
      : (
        _isEmpty(this.errors)
          ? (getDefaultIfEmpty && this.defaultError ? [this.defaultError] : [])
          : this.errors
      )
  }

  getErrorMsgString (e) {
    return this.includeErrorCode && e.type ? `${e.message} [${e.type}]` : e.message
  }

  getErrorStrings (getDefaultIfEmpty = true) {
    const errors = this.getErrors(getDefaultIfEmpty)
    return errors.reduce((acc, e) => {
      const msg = _isString(e) ? e : this.getErrorMsgString(e)
      if (_isString(msg)) {
        acc.push(msg)
      }
      return acc
    }, [])
  }

  showErrors (showDefaultIfEmpty = true) {
    const errors = this.getErrors(showDefaultIfEmpty)
    errors.forEach(e => {
      let msg
      let level = 'error'
      if (_isString(e)) {
        msg = e
      } else {
        msg = this.getErrorMsgString(e)
        level = e.level || level
      }
      if (_isString(msg) && level !== 'silent') {
        message[level](msg, this.messageDuration)
      }
    })
  }
}

export const showErrors = (error, defaultError, { processor, showDefaultIfEmpty, messageDuration, includeErrorCode } = {}) => new ErrorHandler(error, { processor, defaultError, messageDuration, includeErrorCode }).showErrors(showDefaultIfEmpty)
export const getErrorStrings = (error, { processor, includeErrorCode } = {}) => new ErrorHandler(error, { processor, includeErrorCode }).getErrorStrings(false)

export const convertErrorToPlainObject = err => {
  if (!(err instanceof Error)) {
    return err
  }

  const alt = {}

  Object.getOwnPropertyNames(err).forEach(function (key) {
    alt[key] = err[key]
  }, err)

  return alt
}

export const createErrorObject = errors => {
  if (_isString(errors) || errors instanceof Error || _isPlainObject(errors)) {
    errors = [errors]
  }
  return {
    graphQLErrors: _isArray(errors)
      ? errors.filter(error => !_isNil(error)).map(error => {
        error = error instanceof Error ? convertErrorToPlainObject(error) : error
        if (_isString(error)) {
          return { isUSecure: true, message: error }
        } else if (_isPlainObject(error) && error.message) {
          return { isUSecure: true, ...error }
        }
        return error
      })
      : []
  }
}

export const getErrorCode = (e, defaultErrorCode) => e?.errorCode || e?.graphQLErrors?.[0]?.extensions?.exception?.errorCode || defaultErrorCode

export default ErrorHandler
