import { useState, useEffect } from 'react'

import { BOOTSTRAP_BREAKPOINTS } from '../constants/style'

const { sm, md, lg, xl, xxl } = BOOTSTRAP_BREAKPOINTS

function getDeviceType () {
  const width = window.innerWidth
  // CCHAU: these breakpoints follow bootstrap rules
  if (width < sm) {
    return 'xs'
  } else if (width >= sm && width < md) {
    return 'sm'
  } else if (width >= md && width < lg) {
    return 'md'
  } else if (width >= lg && width < xl) {
    return 'lg'
  } else if (width >= xl && width < xxl) {
    return 'xl'
  } else {
    return 'xxl'
  }
}
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    type: undefined
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize () {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        type: getDeviceType()
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}
