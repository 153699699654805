import React, { useState, useCallback, useEffect } from 'react'
import { Card } from 'antd'
import queryString from 'query-string'
import { useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'
import I18n from 'i18n-js'

import routes from '../constants/routes'
import { UPDATE_M365_AUTH } from '../components/Queries/Companies'
import { LoadingBlock } from '../components/common'
import { renderParagraphsFragmentFromArray } from '../helpers'
import { postSessionChannelMessage } from '../helpers/session'
import IntercomHeader from '../components/IntercomHeader'

const ContentWrap = styled.div`
  height: 100%;
  padding: 1rem 50px 5.809rem;
`

const ContentCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BodyContainer = styled.div`
  flex-grow: 1;
  overflow: auto;

  p {
    font-family: unset;
    font-size: 16px;
  }
`

const TR_OPTS = {
  messageInjection: { scope: 'settings.messageInjection.authM365Complete' },
  phishAlertSSO: { scope: 'uPhish.phishAlertAuthM365' },
  phishAlertMailbox: { scope: 'uPhish.phishAlertAuthM365' }
}

const AUTH_ROUTES = {
  messageInjection: routes.MESSAGE_INJECTION_M365_AUTH,
  phishAlertSSO: routes.PHISH_ALERT_M365_AUTH,
  phishAlertMailbox: routes.PHISH_ALERT_M365_AUTH
}

const HEADER_IDS = {
  messageInjection: 'messageInjection-auth-m365-header',
  phishAlertSSO: 'phish-alert-auth-m365-header',
  phishAlertMailbox: 'phish-alert-auth-m365-header'
}

const SUCCESS_TR_KEY = {
  phishAlertSSO: 'ssoAuthSuccess',
  phishAlertMailbox: 'mailAuthSuccess'
}

const M365AuthComplete = ({ initialApp, match, history }) => {
  const [status, setStatus] = useState('loading')
  const [app, setApp] = useState(initialApp)
  const [executeAuthUpdate] = useMutation(UPDATE_M365_AUTH)
  const updateAuth = useCallback(async (app, tenantId) => {
    try {
      await executeAuthUpdate({
        variables: {
          app,
          authType: 'admin',
          tenantId
        }
      })
      setStatus('complete')
      postSessionChannelMessage('sessionRefresh')
    } catch (e) {
      console.error('M365AuthComplete ERROR', e)
      setStatus('error')
    }
  }, [executeAuthUpdate])
  useEffect(() => {
    if (status === 'loading') {
      setStatus('updating')
      const parsedQueryString = queryString.parse(window.location.search)
      const { admin_consent: adminConsent, tenant: tenantId, state } = parsedQueryString
      history.push(AUTH_ROUTES[state])
      if (state && tenantId && adminConsent === 'True') {
        setApp(state)
        updateAuth(state, tenantId)
      } else {
        setStatus('error')
      }
    }
  }, [match, history, status, updateAuth])

  const trOpt = TR_OPTS[app]

  return (
    <ContentWrap>
      <LoadingBlock loading={status === 'loading' || status === 'updating'} />
      <ContentCard>
        <ContentContainer>
          <IntercomHeader Size='h1' id={HEADER_IDS[app]}>{I18n.t('title', trOpt)}</IntercomHeader>
          <BodyContainer>
            {status === 'error' && renderParagraphsFragmentFromArray(I18n.t('authFailed', trOpt))}
            {status === 'complete' && <p>{I18n.t(SUCCESS_TR_KEY[app] || 'authSuccess', trOpt)}</p>}
            {status === 'complete' && <p>{I18n.t('closeTab', trOpt)}</p>}
          </BodyContainer>
        </ContentContainer>
      </ContentCard>
    </ContentWrap>
  )
}

export const PhishAlertAuthM365 = props => <M365AuthComplete {...props} initialApp='phishAlertSSO' />
export const MessageInjectionAuthM365 = props => <M365AuthComplete {...props} initialApp='messageInjection' />

export default M365AuthComplete
