import React, { useCallback, useState, useMemo } from 'react'
import { Button, Card, Divider } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import EmailTemplateTiles from './EmailTemplateTiles'
import { TemplateTiles } from './TemplateTiles'
import { GET_SIMULATION_LANDING_PAGE_EMAIL_TEMPLATES } from '../Queries/uPhish'
import { ErrorAlerts, LoadingBlock } from '../common'
import TemplateHeader, { TemplateAlert, TemplateSelectedMessage, ALL_FILTER_VALUE } from './TemplateHeader'
import ViewEmailTemplateModal from '../Modals/ViewEmailTemplateModal'
import { CATEGORY_OPTIONS } from '../../constants/uPhish'
import { permissions } from '../../constants/permissions'
import { useHasSessionPermission } from '../../hooks'

const trOpt = { scope: 'uPhish.createSimulationEmailTemplates' }

const RecommendedEmailTemplates = ({ linkedTemplates = [], emailTemplateId, update, updateSource }) => {
  const [viewLinkedModalVisible, setViewLinkedModalVisible] = useState(false)
  const [viewLinkedModalId, setViewLinkedModalId] = useState(false)

  const openLinkedModal = useCallback(id => {
    setViewLinkedModalId(id)
    setViewLinkedModalVisible(true)
  }, [])
  const afterLinkedModalClose = useCallback(() => {
    setViewLinkedModalId(null)
  }, [])

  const linkedModalProps = {
    component: ViewEmailTemplateModal,
    visible: viewLinkedModalVisible,
    setVisible: setViewLinkedModalVisible,
    afterClose: afterLinkedModalClose,
    id: viewLinkedModalId,
    onClick: setViewLinkedModalId
  }

  return (
    <>
      <Card title={I18n.t('uPhish.common.recommendedEmailTemplates')}>
        <TemplateTiles
          templates={linkedTemplates}
          selectedId={emailTemplateId}
          onClick={update}
          actions={[{
            id: 'view',
            title: I18n.t('common.view'),
            icon: 'eye',
            onClick: openLinkedModal
          }, {
            id: 'select',
            title: I18n.t('selectTemplate', trOpt),
            icon: 'check-circle',
            onClick: update
          }, {
            id: 'copy',
            title: I18n.t('copyTemplate', trOpt),
            icon: 'copy',
            onClick: updateSource
          }]}
          modal={linkedModalProps}
          showCategory
        />
      </Card>
      <Divider />
    </>
  )
}

const CreateSimulationEmailTemplates = ({ landingPageId, emailTemplateId, attackType, update, updateSource, goToNextStep = () => {}, contentLocales = [], setContentLocales = () => {}, defaultContentLocales }) => {
  const { loading, error: linkedTemplatesError, data: linkedTemplatesData } = useQuery(GET_SIMULATION_LANDING_PAGE_EMAIL_TEMPLATES, {
    skip: !landingPageId,
    variables: {
      id: landingPageId
    }
  })
  let linkedTemplates = _get(linkedTemplatesData, 'simulationLandingPage.emailTemplates') || []

  if (attackType !== 'page') {
    linkedTemplates = linkedTemplates.filter(({ attachmentFile }) => attachmentFile !== null)
  }

  const [type, setType] = useState(ALL_FILTER_VALUE)
  const [category, setCategory] = useState(ALL_FILTER_VALUE)
  const [attackTypes, setAttackTypes] = useState(attackType)
  const [searchText, setSearchText] = useState('')
  const [contentLocaleCounts, setContentLocaleCounts] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templatesLoading, setTemplatesLoading] = useState(true)

  const { hasAllSessionPermissions } = useHasSessionPermission()

  const [viewModalVisible, setViewModalVisible] = useState(false)
  const [viewModalId, setViewModalId] = useState(false)

  const openModal = useCallback(id => {
    setViewModalId(id)
    setViewModalVisible(true)
  }, [])
  const afterModalClose = useCallback(() => {
    setViewModalId(null)
  }, [])

  const modalProps = {
    component: ViewEmailTemplateModal,
    visible: viewModalVisible,
    setVisible: setViewModalVisible,
    afterClose: afterModalClose,
    id: viewModalId,
    onClick: setViewModalId
  }

  const categories = useMemo(() => CATEGORY_OPTIONS.sort((a, b) => {
    if (a.value === 'none') return -1
    if (b.value === 'none') return 1
    return a.label.localeCompare(b.label)
  }), [])

  if (loading) return <LoadingBlock fullScreen loading={loading} />
  return (
    <>
      {
        !emailTemplateId &&
          <TemplateAlert message={I18n.t('initialAlert', trOpt)} description='hidden' type='info' showIcon />
      }
      {
        selectedTemplate &&
          <TemplateAlert
            message={
              <TemplateSelectedMessage>
                <span>{I18n.t('selectedAlert', { ...trOpt, name: selectedTemplate.name })}</span>
                <Button type='primary' onClick={goToNextStep} icon='right-circle'>{I18n.t('common.continue')}</Button>
              </TemplateSelectedMessage>
            }
            description='hidden' type='success' showIcon
          />
      }
      {
        !loading && !_isEmpty(linkedTemplates) && (
          <RecommendedEmailTemplates {...{ linkedTemplates, emailTemplateId, update, updateSource }} />
        )
      }
      {linkedTemplatesError && <ErrorAlerts error={linkedTemplatesError} defaultError={I18n.t('recommendedEmailTemplatesError', trOpt)} />}
      <h2>{I18n.t('uPhish.createSimulation.chooseYourEmail')}</h2>
      <TemplateHeader
        {...{
          type,
          setType,
          searchText,
          setSearchText,
          category,
          categories,
          attackTypes,
          setCategory,
          contentLocales,
          setContentLocales,
          contentLocaleCounts,
          defaultContentLocales
        }}
        showAttackTypes
        setAttackTypes={setAttackTypes}
        loading={templatesLoading}
        searchPlaceholder={I18n.t('uPhish.emailTemplateLibrary.searchEmailTemplates')}
        showContentLocaleCounts
      />
      <EmailTemplateTiles
        errorMessage={I18n.t('emailTemplateError', trOpt)}
        showCreate={hasAllSessionPermissions([permissions.EMAIL_TEMPLATE_CREATE])}
        showFreeform
        {...{ emailTemplateId }}
        filters={{
          searchText,
          type,
          category,
          attackTypes,
          contentLocales,
          hasAttachment: attackType === 'attachment' || attackType === 'attachmentPage' ? 'has-attachment' : 'no-attachment'
        }}
        onClick={update}
        actions={[{
          id: 'view',
          title: I18n.t('common.view'),
          icon: 'eye',
          onClick: openModal
        }, {
          id: 'select',
          title: I18n.t('selectTemplate', trOpt),
          icon: 'check-circle',
          onClick: update
        }, {
          id: 'copy',
          title: I18n.t('copyTemplate', trOpt),
          icon: 'copy',
          onClick: updateSource
        }]}
        categories={categories}
        queryVariables={{ restrictToPlan: true }}
        onSelectedTemplateUpdate={setSelectedTemplate}
        modal={modalProps}
        {...{
          setContentLocaleCounts,
          currentPage,
          setCurrentPage,
          pageSize,
          setPageSize,
          setTemplatesLoading
        }}
        showPagination
        paginationTotalTrKey='uPhish.common.emailTemplatePaginationTotal'
      />
    </>
  )
}

export default CreateSimulationEmailTemplates
