import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Row, Column } from '../../components/EndUserPortal/GridFlexiLayout'
import { ContentScroller, ContentWrap } from './Layout'

const _PrintableWrap = ({ children }) => {
  return (
    <>
      <Helmet>
        <style type='text/css'>
          {`
            .showOnPrint { display: none; }
            @media print {
              * { 
                color-adjust: exact;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
              body { zoom: 0.9; }
              .ant-layout-header { display:none; }
              .ant-layout {
                height: initial;
                background: none !important;
              }
              .ant-layout-content { margin-top: 0; }
              .hideOnPrint { display: none; }
              .showOnPrint { display: block; }
            }
          `}
        </style>
      </Helmet>
      {children}
    </>
  )
}

export const PrintableWrap = styled(_PrintableWrap)`
  @page {
    margin: 0;
    size: A4;
  }
`

export const PrintableContentScroller = styled(ContentScroller)`
  @media print {
    overflow: hidden;
  }
`

export const PrintableContentWrap = props => <ContentWrap {...props} scrollerComponent={PrintableContentScroller} />

export const PrintableRow = styled(Row)`
  @media print {
    display: block; // This is needed for the break-inside: avoid rule on 
                    // RoundedCard to work. This rule is required to stop cards 
                    // being split across two pages when printed
    clear: both;
  }
`

export const PrintableColumn = styled(Column)`
  @media print {
    display: block; // This is needed for the break-inside: avoid rule on 
                    // RoundedCard to work. This rule is required to stop cards 
                    // being split across two pages when printed
    gap: 0px;
  }
`
