import React, { useRef, useCallback } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import I18n from 'i18n-js'

import { Row, RoundedCard } from '../../components/EndUserPortal/GridFlexiLayout'
import routes from '../../constants/routes'

import DownloadReportModal from '../Modals/DownloadReportModal'
import { formatDate } from '../../helpers/datetime'

const SectionHeaderContainer = styled(RoundedCard)`
margin-top: 40px;

p {
  font-family: unset;
}

@media print {
  /* The !importants here are to fix printing in Firefox */
  margin-top: 0;
  margin-bottom: 0;
  break-before: page;
  border: 0 !important;
  border-radius: 0 !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  background-color: ${({ theme }) => theme.reportDocumentHeaderBackgroundColor} !important;
  h2 {
    font-weight: bold;
    font-size: 24px;
    color: ${({ theme }) => theme.reportDocumentHeaderFontColor};
  }

  p {
    color: ${({ theme }) => theme.reportDocumentHeaderFontColor};
  }
}
`

export const Logo = styled.div`
  display: none;
  @media print {
    display: block;
    background-image: url('${({ theme, logo }) => logo || theme.reportDocumentLogo}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 200px;
    width: 300px;
    padding-left: 24px;
    padding-right: 24px;
  }
`

const ReportHeaderContainer = styled(RoundedCard)`
/* The report header doubles as the front page of the report when printing */

@media print {
  /* The !importants here are to fix printing in Firefox */
  padding-top: 300px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  height: calc(100vh / 0.9);
  width: 100%;
  position: relative;
  border: 0 !important;
  border-radius: 0 !important;
  background-color: ${({ theme }) => theme.reportDocumentHeaderBackgroundColor} !important;
  color: ${({ theme }) => theme.reportDocumentHeaderFontColor} !important;
  font-size: 30px;
  h1 {
    font-size: 60px;
    color: ${({ theme }) => theme.reportDocumentHeaderFontColor};
    margin-bottom: 0;
  }
}
`

export const SectionHeader = ({ title, text }) => (
  <SectionHeaderContainer>
    <h2 style={{ margin: 0 }}>{title}</h2>
    {text && <p>{text}</p>}
  </SectionHeaderContainer>
)

export const ReportHeader = ({
  title,
  companyId,
  fromDate,
  toDate,
  reportType,
  downloadFileName,
  hideHeader = false,
  showCompanyName = false,
  companyName
}) => {
  const downloadReportModalRef = useRef(null)
  const openDownloadReportModal = useCallback(() => {
    if (downloadReportModalRef.current) {
      downloadReportModalRef.current.open({
        name: downloadFileName || `${title} - ${moment(fromDate).format('YYYY-MM-DD')} - ${moment(toDate).format('YYYY-MM-DD')}`,
        fromDate,
        toDate,
        reportType
      })
    }
  }, [downloadReportModalRef, downloadFileName, title, fromDate, toDate, reportType])

  return (
    <ReportHeaderContainer className={hideHeader ? 'showOnPrint' : undefined}>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Logo />
          <h1 style={{ margin: 0 }}>{title}</h1>
          {showCompanyName && companyName && <div>{companyName}</div>}
          <div>{formatDate(fromDate)} - {formatDate(toDate)}</div>
        </div>
        {!hideHeader && (
          <div className='hideOnPrint'>
            <Link to={routes.REPORT_CENTRE} style={{ marginRight: 10 }}>
              <Button>{I18n.t('reports.common.returnToReportCentre')}</Button>
            </Link>
            <Button type='primary' icon='download' onClick={openDownloadReportModal}>{I18n.t('reports.common.downloadReport')}</Button>
          </div>
        )}
      </Row>
      <DownloadReportModal ref={downloadReportModalRef} {...{ companyId }} />
    </ReportHeaderContainer>
  )
}
