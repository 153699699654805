import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

import { RISK_REPORT_STEP, RISK_REPORT_STEP_END } from '../../constants/company'

const RiskReportStatusDot = styled.div`
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-weight: 700;
  height: 30px;
  padding: 5px;
  position: relative;
  text-align: center;
  width: 30px;

  span {
    display: inline-block;
    font-size: 14px;
    height: 14px;
    line-height: 14px;
    position: relative;
    top: 1px;
    width: 100%;
  }

  &.risk-report-status__dot {
    &--incomplete {
      border-color: ${({ theme }) => transparentize(0.7, theme.darkGrey)};
      color: ${({ theme }) => transparentize(0.7, theme.darkGrey)};
    }
    &--active {
      border-color: ${({ theme }) => theme.primary};
      border-width: 2px;
      color: ${({ theme }) => theme.primary};

      span {
        top: 0px;
      }
    }
    &--complete {
      background-color: ${({ theme }) => theme.primary};
    }
  }

  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
`

const RiskReportStatusStep = ({ step, status }) => {
  const currentStep = RISK_REPORT_STEP[status]
  const isAtStepEnd = step === currentStep && RISK_REPORT_STEP_END[currentStep] === status
  let type = 'incomplete'
  if (step < currentStep || isAtStepEnd) {
    type = 'complete'
  } else if (step === currentStep) {
    type = 'active'
  }

  return (
    <RiskReportStatusDot className={`risk-report-status__dot--${type}`}>
      <span>{step}</span>
    </RiskReportStatusDot>
  )
}

const _RiskReportStatus = ({ className, ...props }) => {
  return (
    <div {...{ className }}>
      <RiskReportStatusStep step={1} {...props} />
      <RiskReportStatusStep step={2} {...props} />
      <RiskReportStatusStep step={3} {...props} />
      <RiskReportStatusStep step={4} {...props} />
    </div>
  )
}

const RiskReportStatus = styled(_RiskReportStatus)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  /* ${RiskReportStatusDot} {
    display: inline-block;
  } */
`

export default RiskReportStatus
