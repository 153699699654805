import React from 'react'

import {
  GET_PERFORMANCE_REPORT_VIEWS,
  GET_PERFORMANCE_REPORT_START_DATE,
  CREATE_CUSTOM_PERFORMANCE_REPORT,
  UPDATE_CUSTOM_PERFORMANCE_REPORT,
  DELETE_CUSTOM_PERFORMANCE_REPORTS,
  INVALIDATE_PERFORMANCE_REPORT_CACHE
} from '../../components/Queries/Reports'
import BaseReportCentreList from './BaseReportCentreList'
import { permissions } from '../../constants/permissions'

const USE_PERFORMANCE_REPORT_DB_CACHE = window.__USECURE_CONFIG__.REACT_APP_USE_PERFORMANCE_REPORT_DB_CACHE === 'true'

const PerformanceReports = ({
  companyId,
  accountType,
  planValid
}) => (
  <BaseReportCentreList
    viewQuery={GET_PERFORMANCE_REPORT_VIEWS}
    viewQueryName='performanceReportViews'
    startDateQuery={GET_PERFORMANCE_REPORT_START_DATE}
    startDateQueryName='performanceReportStartDate'
    reportType='performanceReport'
    queries={{
      viewQuery: GET_PERFORMANCE_REPORT_VIEWS,
      viewQueryName: 'performanceReportViews',
      startDateQuery: GET_PERFORMANCE_REPORT_START_DATE,
      startDateQueryName: 'performanceReportStartDate',
      createMutation: CREATE_CUSTOM_PERFORMANCE_REPORT,
      updateMutation: UPDATE_CUSTOM_PERFORMANCE_REPORT,
      deleteMutation: DELETE_CUSTOM_PERFORMANCE_REPORTS,
      invalidateMutation: INVALIDATE_PERFORMANCE_REPORT_CACHE
    }}
    requiredPermissions={{
      create: [permissions.PERFORMANCE_REPORT_CREATE],
      update: [permissions.PERFORMANCE_REPORT_UPDATE],
      delete: [permissions.PERFORMANCE_REPORT_DELETE],
      send: [permissions.PERFORMANCE_REPORT_SEND]
    }}
    useDBCache={USE_PERFORMANCE_REPORT_DB_CACHE}
    {...{
      companyId,
      accountType,
      planValid
    }}
  />
)

export default PerformanceReports
