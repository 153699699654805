import React from 'react'
import styled from 'styled-components'

import { apiUrl } from '../../apollo-client/common'
import { getSessionToken } from '../../helpers/session'

const Iframe = styled.iframe`
  border: none;
  height: 600px;
  width: 100%;
`

const UnlayerIframe = ({ simulationId }) => {
  const authToken = getSessionToken()

  return (
    <div>
      <Iframe src={`${apiUrl}/api/simulations/${simulationId}/email-preview?authToken=${authToken}`} />
    </div>
  )
}

export default UnlayerIframe
