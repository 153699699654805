import { Component } from 'react'

import { creators as sessionActions } from '../state/actions/session'
import { creators as viewActions } from '../state/actions/view'
import connect from '../hocs/connect'
import { getInitialSessionStateFromMe } from '../helpers'
import authenticatedClient from '../apollo-client/authenticatedClient'
import { getBrowserLocale } from '../helpers/locale'

class SessionDispatcher extends Component {
  async componentDidMount () {
    this.props.updateLocaleOnly(getBrowserLocale())
    try {
      const sessionAndSettings = await getInitialSessionStateFromMe(authenticatedClient, true)
      this.props.initialiseSession(sessionAndSettings)
    } catch (e) {
      this.props.fail()
    }
  }

  render () {
    return null
  }
}

export const sessionActionsDispatcher = dispatch => ({
  fail: session => {
    dispatch(sessionActions.fail(session))
    dispatch(viewActions.loading(false))
    dispatch(viewActions.resetTitles())
    dispatch(viewActions.resetFavicon())
  },
  initialiseSession: (sessionAndSettings) => {
    dispatch(sessionActions.initialise(sessionAndSettings))
    dispatch(viewActions.loading(false))
  },
  updateLocaleOnly: locale => dispatch(sessionActions.updateLocaleOnly(locale))
})

export default connect(
  undefined,
  sessionActionsDispatcher
)(SessionDispatcher)
