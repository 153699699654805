import React, { useCallback } from 'react'
import { generatePath, withRouter } from 'react-router-dom'
import { Layout } from 'antd'
import I18n from 'i18n-js'
import _isFunction from 'lodash/isFunction'

import TemplateTiles from './TemplateTiles'
import { GET_SIMULATION_EMAIL_TEMPLATES } from '../Queries/uPhish'

const trOpt = { scope: 'uPhish.emailTemplateTiles' }

const { Content } = Layout

const EmailTemplateTiles = ({ emailTemplateId, history, errorMessage = I18n.t('errorMessage', trOpt), onClick: onClickProp, viewRoute, ...tilesProps }) => {
  const onClick = useCallback(id => {
    if (_isFunction(onClickProp)) {
      onClickProp(id)
    }
    if (viewRoute) {
      history.push(generatePath(viewRoute, { email_template_id: id }))
    }
  }, [history, viewRoute, onClickProp])

  return (
    <Layout>
      <Content>
        <TemplateTiles
          query={GET_SIMULATION_EMAIL_TEMPLATES}
          queryName='simulationEmailTemplates'
          showCategory
          showLocalesIcon
          selectedId={emailTemplateId}
          emptyMessage={I18n.t('emptyMessage', trOpt)}
          localesFieldId='supportedLocales'
          {...{ onClick, errorMessage, ...tilesProps }}
        />
      </Content>
    </Layout>
  )
}

export default withRouter(EmailTemplateTiles)
