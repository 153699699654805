import gql from 'graphql-tag'
// ($uPhishEnabled: Boolean)
export const GET_END_PAGE_DATA = gql`
  query ($fromDate: DateTime, $toDate: DateTime) {
    learnerMe {
      endUser {
        id
        company {
          id
          settings
          uBreachProEnabled
        }
        riskScores (fromDate: $fromDate, toDate: $toDate)
        courseResults {
          id
          finished
          enrollDate
          startDate
          finishDate
          retries
          score
          course {
            id
            name
            difficulty
            icon
            description
            locale    
          }
        }
        policyResults {
          id
          open
          visit
          signed
          policy {
            id
            name  
          }
        }
        phish {
          total
          opened
          visited
          compromised
          reported
        }
        averageCourseScore
      }
    }
  }
`
export const GET_LEARNER_DASHBOARD_DATA = gql`
  query {
    learnerMe {
      endUser {
        id
        name
        company {
          id
          settings
        }
        courseResults {
          id
          finished
          enrollDate
          startDate
          finishDate
          retries
          score
          course {
            id
            name
            difficulty
            icon
            description
            locale
            subject
          }
        }
        averageCourseScore
      } 
    }
  }
`

export const GET_POLICY_DASHBOARD_DATA = gql`
  query {
    learnerMe {
      endUser {
        id
        policyResults {
          id
          open
          visit
          signed
          policy {
            id
            name
            category
          }
        }
      }
    }
  }
`

export const GET_BREACH_DASHBOARD = gql`
  query {
    learnerMe{
      endUser{
        id
        breaches {
          name
          breachDate
          addedDate
          modifiedDate
          dataClasses (returnIds: true)
          category
          breachedData {
            type
            value
          }
        }
      }
    }
  }
`
