import React from 'react'
import { Subscription } from 'react-apollo'
import gql from 'graphql-tag'
import { get as _get } from 'lodash/object'

const LEARNER_COURSE_SETTINGS_SUBSCRIPTION = gql`
  subscription onSettingsUpdated($companyId: ID) {
    learnerCourseSettingsUpdated(companyId: $companyId) {
      settings
    }
  }
`

const LearnerCourseSettingsSubscription = ({ companyId, update }) => {
  return (
    <Subscription
      subscription={LEARNER_COURSE_SETTINGS_SUBSCRIPTION}
      variables={{ companyId }}
      onSubscriptionData={({ subscriptionData }) => {
        const settings = _get(subscriptionData, 'data.learnerCourseSettingsUpdated.settings', {})
        update(settings)
      }}
    />
  )
}

export default LearnerCourseSettingsSubscription
