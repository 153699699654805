import React from 'react'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import styled from 'styled-components'
import { Upload, message, Modal, Button as AntButton } from 'antd'
import I18n from 'i18n-js'
import _isEqual from 'lodash/isEqual'

import { apiUrl } from '../../apollo-client/common'
import { Button, SlateComponents } from '../common/SlateComponents'
import { getSessionToken } from '../../helpers/session'

const trOpt = { scope: 'editCourse.editCourseSlate' }
const editorTrOpt = { scope: 'common.slateEditor' }

const EditorUpload = styled(Upload)`
  display: none;
`

const EditorContainer = styled(Editor)`
  img {
    max-width: 100%;
    height: auto !important;
    margin: 2.8em auto;
    display: table;
  }
  
  p {
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    color: rgb(124, 112, 107);
  }
  
  ul li {
    list-style: none;
    margin-bottom: 1.8em;
    line-height: 30px;
    margin-left: -25px;
    
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px !important;
    font-weight: 400;
  }
  
  blockquote {
    border: none;
    box-sizing: border-box;
    color: #4c4e4d;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 60px 30px;
    position: relative;
    quotes: "\\201C" "\\201D" "\\2018" "\\2019";
    text-align: center;
    width: 95%;
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 2.6em;
    line-height: 1.4;
    font-weight: 400;
    
    
    :before, :after {
      color: #4c4e4d;
      font-family: 'Impact', sans-serif !important;
      font-size: 7rem;
      font-style: normal;
      height: 60px;
      line-height: 1;
      position: absolute;
      width: 60px;
    }
    
    :before {
      content: open-quote;
      top: 0;
      left: 5px;
    }
    
    :after {
      content: close-quote;
      bottom: 0;
      right: 5px;
    }
  }
  
  ul li:before {
    content: "•";
    color: ${({ theme }) => theme.primary};
    vertical-align: top;
    font-size: 46px;
    margin-right: 15px !important;
  }
  
  ol {
    counter-reset: ol-counter;
    margin-left: 25px;
  
    li {
      counter-increment: ol-counter;
      list-style-type: none;
      margin-bottom: 1.75rem !important;
      padding-left: 15px;
      position: relative;
      
      span {
        font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
        font-size: 20px;
        line-height: 1.4;
        font-weight: 400;
        color: rgb(124,112,107);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        background-color: ${({ theme }) => theme.primary};
        color: white;
        content: counter(ol-counter);
        height: 2rem;
        left: -40px;
        padding: 0.15rem;
        position: absolute;
        text-align: center;
        top: -0.1rem;
        width: 2rem;
      }
    }
  }
  
  strong {
    font-weight: bold;
  }
`

const DEFAULT_NODE = 'paragraph'

function insertImage (editor, src, target) {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'image',
    data: { src }
  })
}

const schema = {
  blocks: {
    image: {
      isVoid: true
    }
  }
}

const initialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: 'Insert your course content here!'
          }
        ]
      }
    ]
  }
})

/**
 * A change helper to standardize wrapping links.
 *
 * @param {Editor} editor
 * @param {String} href
 */

function wrapLink (editor, href) {
  editor.wrapInline({
    type: 'link',
    data: { href }
  })

  editor.moveToEnd()
}

/**
 * A change helper to standardize unwrapping links.
 *
 * @param {Editor} editor
 */

function unwrapLink (editor) {
  editor.unwrapInline('link')
}

class EditCourseSlate extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.getContentValueFromProps(props)
    }

    this.handleChange = this.handleChange.bind(this)
    this.onClickBlock = this.onClickBlock.bind(this)
    this.onClickImage = this.onClickImage.bind(this)
    this.onClickMark = this.onClickMark.bind(this)
    this.onClickLink = this.onClickLink.bind(this)
    this.handleOpenShortcutsClick = this.handleOpenShortcutsClick.bind(this)
    this.handleImageUpload = this.handleImageUpload.bind(this)
    this.handleShortcutFormatting = this.handleShortcutFormatting.bind(this)
    this.ref = this.ref.bind(this)
    this.renderBlock = this.renderBlock.bind(this)
    this.renderMark = this.renderMark.bind(this)
    this.renderInline = this.renderInline.bind(this)
  }

  getContentValueFromProps (props = this.props) {
    if (props.content && props.content.intro) {
      return Value.fromJSON(JSON.parse(this.props.content.intro))
    } else {
      return initialValue
    }
  }

  componentDidUpdate (prevProps) {
    const { locale, slideId, updateId } = this.props
    const { locale: prevLocale, slideId: prevSlideId, updateId: prevUpdateId } = prevProps

    if (locale !== prevLocale || slideId !== prevSlideId || updateId !== prevUpdateId) {
      this.setState({
        value: this.getContentValueFromProps()
      })
    }
  }

  renderMarkButton (type, icon) {
    const isActive = this.hasMark(type)

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickMark(event, type)}
      >
        <i className={`fa fa-${icon}`} />
      </Button>
    )
  }

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasMark (type) {
    const { value } = this.state
    return value.activeMarks.some(mark => mark.type === type)
  }

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasBlock (type) {
    const { value } = this.state
    return value.blocks.some(node => node.type === type)
  }

  /**
   * Store a reference to the `editor`.
   *
   * @param {Editor} editor
   */

  ref (editor) {
    this.editor = editor
  }

  handleChange ({ value }) {
    this.setState({ value })
    const { intro: currentIntro } = this.props.content || {}
    const newIntro = JSON.stringify(value.toJSON())
    if (!_isEqual(currentIntro, newIntro)) {
      this.props.updateContent({ ...this.props.content, intro: newIntro })
    }
  }

  renderBlock (props, editor, next) {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'paragraph':
        return <p {...attributes}>{children}</p>
      case 'quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
      case 'list-item':
      case 'list-item-child':
        return <li {...attributes}>{children}</li>
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      case 'image': {
        const src = node.data.get('src')
        return <img {...attributes} src={src} alt='' />
      }
      default:
        return next()
    }
  }

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderMark (props, editor, next) {
    const { children, mark, attributes } = props

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>
      case 'italic':
        return <em {...attributes}>{children}</em>
      case 'underlined':
        return <u {...attributes}>{children}</u>
      default:
        return next()
    }
  }

  /**
   * Render a Slate inline.
   *
   * @param {Object} props
   * @param {Editor} editor
   * @param {Function} next
   * @return {Element}
   */

  renderInline (props, editor, next) {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'link': {
        const { data } = node
        const href = data.get('href')
        return (
          <a {...attributes} href={href} target='_blank' rel='noopener noreferrer'>
            {children}
          </a>
        )
      }

      default: {
        return next()
      }
    }
  }

  /**
   * Render a block-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderBlockButton (type, icon) {
    let isActive = this.hasBlock(type)

    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const { value: { document, blocks } } = this.state

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = this.hasBlock('list-item') && parent && parent.type === type
      }
    }

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickBlock(event, type)}
      >
        <i className={`fa fa-${icon}`} />
      </Button>
    )
  }

  /**
   * When a mark button is clicked, toggle the current mark.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickMark (event, type) {
    event.preventDefault()
    this.editor.toggleMark(type)
  }

  /**
   * When a block button is clicked, toggle the block type.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickBlock (event, type) {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const { document } = value

    if (type === 'image') {
      this.onClickImage()
    }

    // Handle everything but list buttons.
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type)
      const isList = this.hasBlock('list-item')

      if (isList) {
        editor.setBlocks(isActive ? DEFAULT_NODE : type).unwrapBlock('bulleted-list').unwrapBlock('numbered-list')
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item')
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor.setBlocks(DEFAULT_NODE).unwrapBlock('bulleted-list').unwrapBlock('numbered-list')
      } else if (isList) {
        editor.unwrapBlock(
          type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
        ).wrapBlock(type)
      } else {
        editor.setBlocks('list-item').wrapBlock(type)
      }
    }
  }

  onClickImage () {
    document.getElementById('courseWysiwyg').click()
  }

  handleImageUpload (info) {
    if (info.file.status === 'done') {
      message.success(I18n.t('imageUploadSuccessMessage', { ...editorTrOpt, file: info.file.name }))
      this.editor.command(insertImage, info.file.response.url)
    } else if (info.file.status === 'error') {
      message.error(I18n.t('imageUploadErrorMessage', { ...editorTrOpt, file: info.file.name }))
    }
  }

  handleShortcutFormatting (event, editor, next) {
    if (!event.ctrlKey) return next()

    switch (event.key) {
      case 'b':
        // Stops browsers handling the shortcut
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('bold')
        break
      case 'i':
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('italic')
        break
      case 'u':
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('underlined')
        break
      case 's':
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('strikethrough')
        break
      case 'q':
        event.preventDefault()
        event.stopPropagation()
        editor.setBlocks('block-quote')
        break
      case 'h': {
        event.preventDefault()
        event.stopPropagation()
        const text = editor.value.blocks.some(block => ['heading-one', 'heading-two', 'paragraph'].includes(block.type))
        if (text) {
          const headerOne = editor.value.blocks.some(block => block.type === 'heading-one')
          const headerTwo = editor.value.blocks.some(block => block.type === 'heading-two')
          const paragraph = editor.value.blocks.some(block => block.type === 'paragraph')

          if (paragraph) editor.setBlocks('heading-one')
          if (headerOne) editor.setBlocks('heading-two')
          if (headerTwo) editor.setBlocks('paragraph')
        }
        break
      }
      default:
        next()
    }
  }

  handleOpenShortcutsClick () {
    Modal.info({
      title: I18n.t('help.keyboardShortcuts', editorTrOpt),
      content: (
        <div>
          <p>{I18n.t('keyboardShortcutsIntro', trOpt)}</p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>B</AntButton>: <strong>{I18n.t('help.bold', editorTrOpt)}</strong></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>I</AntButton>: <i>{I18n.t('help.italic', editorTrOpt)}</i></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>S</AntButton>: <strike>{I18n.t('help.strikethrough', editorTrOpt)}</strike></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>U</AntButton>: <u>{I18n.t('help.underlined', editorTrOpt)}</u></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>H</AntButton>: {I18n.t('help.toggleHeaders', editorTrOpt)}</p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>Q</AntButton>: {I18n.t('help.quote', editorTrOpt)}</p>
        </div>
      )
    })
  }

  renderHelpButton () {
    return (
      <Button onMouseDown={null} onClick={this.handleOpenShortcutsClick}>
        <i className='fa fa-question-circle' />
      </Button>
    )
  }

  /**
   * When clicking a link, if the selection has a link in it, remove the link.
   * Otherwise, add a new link with an href and text.
   *
   * @param {Event} event
   */

  onClickLink (event) {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const hasLinks = this.hasLinks()

    if (hasLinks) {
      editor.command(unwrapLink)
    } else if (value.selection.isExpanded) {
      const href = window.prompt(I18n.t('enterLinkUrl', editorTrOpt))

      if (href == null) {
        return
      }

      editor.command(wrapLink, href)
    } else {
      const href = window.prompt(I18n.t('enterLinkUrl', editorTrOpt))

      if (href == null) {
        return
      }

      const text = window.prompt(I18n.t('enterLinkText', editorTrOpt))

      if (text == null) {
        return
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapLink, href)
    }
  }

  /**
   * Check whether the current selection has a link in it.
   *
   * @return {Boolean} hasLinks
   */

  hasLinks () {
    const { value } = this.state
    return value.inlines.some(inline => inline.type === 'link')
  }

  renderLinkButton (icon) {
    const isActive = this.hasLinks()

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickLink(event)}
      >
        <i className={`fa fa-${icon}`} />
      </Button>
    )
  }

  // JG: Slate was ignoring text is entered with an input method editor. Here we are listening for the compositionend event and forcing slate to insert the text at the current cursor location.
  handleCompositionEnd (event, editor, next) {
    editor.insertText(event.data)
    next()
  }

  render () {
    return (
      <div>
        <EditorUpload
          name='courseWysiwyg' onChange={this.handleImageUpload} showUploadList={false}
          action={`${apiUrl}/upload-course-wysiwyg`}
          headers={{ 'x-token': getSessionToken() }}
        >
          <Button id='courseWysiwyg' />
        </EditorUpload>
        <SlateComponents style={{ margin: '0 2px 20px', padding: 0 }}>
          {this.renderMarkButton('bold', 'bold')}
          {this.renderMarkButton('italic', 'italic')}
          {this.renderMarkButton('underlined', 'underline')}
          {this.renderBlockButton('heading-one', 'heading')}
          {this.renderBlockButton('block-quote', 'quote-left')}
          {this.renderBlockButton('numbered-list', 'list-ol')}
          {this.renderBlockButton('bulleted-list', 'list-ul')}
          {this.renderBlockButton('image', 'image')}
          {this.renderLinkButton('link')}
          {this.renderHelpButton()}
        </SlateComponents>
        <EditorContainer
          value={this.state.value} onChange={this.handleChange} ref={this.ref} renderBlock={this.renderBlock}
          renderMark={this.renderMark} schema={schema} onKeyDown={this.handleShortcutFormatting} renderInline={this.renderInline} onCompositionEnd={this.handleCompositionEnd}
        />
      </div>
    )
  }
}

export default EditCourseSlate
