import I18n from 'i18n-js'

import SettingsForm from '../SettingsForm'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.minimumPassScore' }

class MinimumPassScore extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'minimumPassScoreEnabled',
      'minimumPassScore'
    ]
    this.defaultValue = {
      minimumPassScore: 80,
      minimumPassScoreEnabled: false
    }

    this.requiredUpdatePermissions = [permissions.SETTINGS_ULEARN_UPDATE]
  }

  onSuccess (result) {
    super.onSuccess(result)
    super.resetFields()
  }

  get headerId () {
    return 'settings-minimum-pass-score-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const { settings = {} } = this.props
    const {
      minimumPassScore,
      minimumPassScoreEnabled
    } = {
      ...this.defaultValue,
      ...settings
    }

    return [
      {
        id: 'minimumPassScoreEnabled',
        type: 'switch',
        label: I18n.t('minimumPassScoreEnabled', trOpt),
        defaultValue: minimumPassScoreEnabled
      }, {
        id: 'minimumPassScore',
        type: 'number',
        label: I18n.t('minimumPassScore', trOpt),
        defaultValue: minimumPassScore,
        visible: values => values.minimumPassScoreEnabled === true,
        mutateValue: value => parseInt(value, 10),
        renderAsInput: true,
        min: 0,
        max: 100,
        step: 1,
        addonAfter: '%',
        formItemStyle: { width: '100px' },
        required: true
      }
    ]
  }
}

export default MinimumPassScore
