import React, { useCallback } from 'react'
import _get from 'lodash/get'

import { EditCourseForm } from '../components/EditCourse'
import routes from '../constants/routes'

const EditCourse = props => {
  const { history } = props
  const courseId = _get(props, 'match.params.course_id')
  const onSuccess = useCallback(() => history.push(routes.BUILDER), [history])
  return (
    <EditCourseForm {...{ courseId, onSuccess }} />
  )
}

export default EditCourse
