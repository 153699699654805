import { createAction } from '../../helpers/state'

export const types = {
  VIEW_LOADING: 'VIEW_LOADING',
  VIEW_TITLE_SET: 'VIEW_TITLE_SET',
  VIEW_TITLE_COMPANY_SET: 'VIEW_TITLE_COMPANY_SET',
  VIEW_DEFAULT_TITLE_SET: 'VIEW_DEFAULT_TITLE_SET',
  VIEW_TITLE_RESET: 'VIEW_TITLE_RESET',
  VIEW_TITLES_RESET: 'VIEW_TITLES_RESET',
  VIEW_APPICON_SET: 'VIEW_APPICON_SET',
  VIEW_FAVICON16_SET: 'VIEW_FAVICON16_SET',
  VIEW_FAVICON32_SET: 'VIEW_FAVICON32_SET',
  VIEW_FAVICON180_SET: 'VIEW_FAVICON180_SET',
  VIEW_SAFARI_ICON_SET: 'VIEW_SAFARI_ICON_SET',
  VIEW_FAVICON_RESET: 'VIEW_FAVICON_RESET',
  VIEW_EXCLUDE_INACTIVE_USERS_SET: 'VIEW_EXCLUDE_INACTIVE_USERS_SET'
}

export const creators = {
  loading: createAction(types.VIEW_LOADING),
  title: createAction(types.VIEW_TITLE_SET),
  defaultTitle: createAction(types.VIEW_DEFAULT_TITLE_SET),
  resetTitle: createAction(types.VIEW_TITLE_RESET),
  resetTitles: createAction(types.VIEW_TITLES_RESET),
  titleFromCompanyName: createAction(types.VIEW_TITLE_COMPANY_SET),
  appIcon: createAction(types.VIEW_APPICON_SET),
  favicon16: createAction(types.VIEW_FAVICON16_SET),
  favicon32: createAction(types.VIEW_FAVICON32_SET),
  favicon180: createAction(types.VIEW_FAVICON180_SET),
  safariTabIcon: createAction(types.VIEW_SAFARI_ICON_SET),
  resetFavicon: createAction(types.VIEW_FAVICON_RESET),
  excludeInactiveUsersInReports: createAction(types.VIEW_EXCLUDE_INACTIVE_USERS_SET)
}

export default {
  types,
  creators
}
