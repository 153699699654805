import I18n from 'i18n-js'

import { withConsumer, connect } from '../../hocs'
import _pick from 'lodash/pick'
import compose from 'recompose/compose'
import { session } from '../../state/selectors'

import SettingsForm from './SettingsForm'
import { UPDATE_UBREACH_PRO_SETTING } from '../Queries/Companies'
import { validateEmail, validateDomain } from '../../helpers'
import { SectionDividerField } from './common'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'settings.uBreach' }
const isNFR = ({ accountType, defaultTenant }) => accountType === 'msp' && !defaultTenant

class UBreachPro extends SettingsForm {
  constructor (props) {
    super(props)
    this.settingId = 'uBreachProSettings'
    this.defaultValue = {
      adminEmails: [],
      alertLearners: false,
      globalEmails: [],
      breachDomains: [],
      showUserDetailsInBreachAlert: false,
      includeNonLearnersInBreachAlert: false,
      alertNewLearners: false
    }
    this.companySettingMutation = UPDATE_UBREACH_PRO_SETTING
    this.requiredUpdatePermissions = [permissions.SETTINGS_UBREACH_UPDATE]
  }

  get headerId () {
    return 'settings-ubreach-v2-header'
  }

  get title () {
    return I18n.t('common.uBreach')
  }

  get description () {
    if (this.breachDomainVisibility()) {
      return I18n.t('descriptionExtra', trOpt)
    }
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get mutationName () {
    return this.props.defaultTenant === true ? 'updateDefaultTenantSetting' : 'updateUBreachProSetting'
  }

  mutateValues (formValues) {
    const { accountType, defaultTenant } = this.props
    const values = _pick(formValues, ['adminEmails', 'alertLearners', 'showUserDetailsInBreachAlert', 'includeNonLearnersInBreachAlert', 'alertNewLearners'])

    if (this.breachDomainVisibility()) {
      values.breachDomains = formValues.breachDomains
    }

    if (isNFR({ accountType, defaultTenant })) {
      values.globalEmails = formValues.globalEmails
    }

    return values
  }

  breachDomainVisibility () {
    const { accountType, parentCompanyId } = this.props
    if (accountType === 'msp') {
      // All MSP - Show on NFR Settings, Hide on MSP default Customer settings
      return this.defaultTenant !== true
    } else if (accountType === 'distributor' || (accountType === 'tenant' && !parentCompanyId)) {
      return true
    }
    return false
  }

  get _fields () {
    const {
      adminEmails = [],
      globalEmails = [],
      alertLearners = false,
      breachDomains = [],
      showUserDetailsInBreachAlert = false,
      includeNonLearnersInBreachAlert = false,
      alertNewLearners = false
    } = {
      ...this.defaultValue,
      ...this.formValuesFromSettings
    }

    const { accountType, defaultTenant } = this.props
    return [
      {
        id: 'mspDivider',
        type: 'custom',
        component: SectionDividerField,
        visible: isNFR({ accountType, defaultTenant }),
        title: I18n.t('mspDividerTitle', trOpt),
        description: I18n.t('mspDividerDescription', trOpt),
        size: 'large'
      },
      {
        id: 'globalEmails',
        type: 'textTags',
        label: I18n.t('globalEmails', trOpt),
        requiredError: I18n.t('emailsRequiredError', trOpt),
        emptyError: I18n.t('modals.sendTestSimulationEmailConfirm.emptyEmailError'),
        duplicateError: I18n.t('emailsDuplicateError', trOpt),
        defaultValue: globalEmails,
        validateTag: value => {
          if (!validateEmail(value)) {
            return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
          }
        },
        visible: isNFR({ accountType, defaultTenant }),
        extra: I18n.t('globalEmailsExtra', trOpt)
      },
      {
        id: 'nfrDivider',
        type: 'custom',
        component: SectionDividerField,
        visible: isNFR({ accountType, defaultTenant }),
        title: I18n.t('nfrDividerTitle', trOpt),
        description: I18n.t('nfrDividerDescription', trOpt),
        size: 'large'
      },
      {
        id: 'monitoringDivider',
        type: 'custom',
        component: SectionDividerField,
        visible: this.breachDomainVisibility(),
        title: I18n.t('monitoringDividerTitle', trOpt)
      },
      {
        id: 'breachDomains',
        type: 'textTags',
        label: I18n.t('breachDomains', trOpt),
        emptyError: I18n.t('common.invalidDomainError'),
        duplicateError: I18n.t('senderDomainsDuplicateError', trOpt),
        defaultValue: breachDomains,
        validateTag: value => {
          if (!validateDomain(value)) {
            return I18n.t('common.invalidDomainError')
          }
        },
        visible: this.breachDomainVisibility(),
        extra: I18n.t('breachDomainsExtra', trOpt)
      },
      {
        id: 'userAlertsDivider',
        type: 'custom',
        component: SectionDividerField,
        formItemStyle: { fontSize: '30px' },
        title: I18n.t('userAlertsDividerTitle', trOpt)
      },
      {
        id: 'alertLearners',
        type: 'switch',
        label: I18n.t('alertLearners', trOpt),
        formItemStyle: { maxWidth: 600 },
        defaultValue: alertLearners
      },
      {
        id: 'alertNewLearners',
        type: 'switch',
        label: I18n.t('alertNewLearners', trOpt),
        formItemStyle: { maxWidth: 600 },
        defaultValue: alertNewLearners
      },
      {
        id: 'breachAlertDivider',
        type: 'custom',
        component: SectionDividerField,
        title: I18n.t('breachAlertDividerTitle', trOpt)
      },
      {
        id: 'adminEmails',
        type: 'textTags',
        label: I18n.t('adminEmails', trOpt),
        requiredError: I18n.t('emailsRequiredError', trOpt),
        emptyError: I18n.t('modals.sendTestSimulationEmailConfirm.emptyEmailError'),
        duplicateError: I18n.t('emailsDuplicateError', trOpt),
        defaultValue: adminEmails,
        validateTag: value => {
          if (!validateEmail(value)) {
            return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
          }
        },
        extra: I18n.t('adminEmailsExtra', trOpt)
      },
      {
        id: 'showUserDetailsInBreachAlert',
        type: 'switch',
        label: I18n.t('showUserDetailsInBreachAlert', trOpt),
        formItemStyle: { maxWidth: 600 },
        defaultValue: showUserDetailsInBreachAlert
      },
      {
        id: 'includeNonLearnersInBreachAlert',
        type: 'switch',
        label: I18n.t('includeNonLearnersInBreachAlert', trOpt),
        formItemStyle: { maxWidth: 600 },
        defaultValue: includeNonLearnersInBreachAlert
      }
    ]
  }
}

export default compose(
  withConsumer,
  connect(
    state => ({
      ..._pick(session.get(state), ['accountType', 'parentCompanyId'])
    })
  )
)(UBreachPro)
