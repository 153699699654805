import React, { useEffect, useState } from 'react'
import { Alert } from 'antd'
import styled from 'styled-components'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'

import { ErrorHandler, UsecureError, createErrorObject } from '../../helpers'
import I18n from 'i18n-js'

const ErrorContainer = styled.div`
  .ant-alert {
    margin-bottom: 8px;

    &-message {
      display: block;
      margin-top: 3px;
    }
  }
`

const DEMO_ERROR = createErrorObject('This is a sample error')

const ErrorAlerts = ({
  error: errorData, processor, defaultError, showIcon = true, className, demo = false, showDefaultIfEmpty = true, includeErrorCode = false,
  forceDefaultError = false
}) => {
  const [errors, setErrors] = useState([])

  useEffect(() => {
    let err = errorData
    if (demo) {
      err = DEMO_ERROR
    } else if (!err && forceDefaultError && showDefaultIfEmpty) {
      // Passing a silent error to ErrorHandler will force it's getErrors method to return the defaultError without any duplication risk
      err = new UsecureError(I18n.t('common.anErrorOccurred'), { level: 'silent' })
    }
    if (_isNil(err) || (_isArray(err) && err.every(_isNil))) {
      return setErrors([])
    }
    const errorHandler = new ErrorHandler(err, { processor, defaultError })
    setErrors(errorHandler.getErrors(showDefaultIfEmpty))
  }, [errorData, processor, defaultError, demo, showDefaultIfEmpty, forceDefaultError])

  return _isEmpty(errors) ? null : (
    <ErrorContainer {...{ className }}>
      {
        errors.reduce((acc, error, key) => {
          const { message, type: errorCode, level: type = 'error' } = (_isString(error) ? { message: error } : error) || {}
          if (type !== 'silent') {
            acc.push(
              <Alert key={key} {...{ type, showIcon }} message={includeErrorCode && errorCode ? `${message} [${errorCode}]` : message} />
            )
          }
          return acc
        }, [])
      }
    </ErrorContainer>
  )
}

export default ErrorAlerts
