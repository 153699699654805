import React, { useState } from 'react'
import { generatePath } from 'react-router-dom'
import { Button, Form, Modal, message, Alert } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import routes from '../../../constants/routes'
import { ADMIN_MAGIC_LINK_REQUEST } from '../../../components/Queries/Learners'
import MutationForm from '../../MutationForm/MutationForm'
import { CopyToClipboard } from '../common'
import ModalHeaderId from '../../Modals/ModalHeaderId'

const trOpt = { scope: 'settings.endUserPortal.generateMagicLink' }

const GenerateMagicLink = React.forwardRef(({ appOrigin, visible, companyId }, ref) => {
  const [showModal, setModalVisibility] = useState(false)
  const [magicLinkUrl, setMagicLinkUrl] = useState(null)
  const onSuccess = (queryResult) => {
    const token = _get(queryResult, 'data.generateAdminMagicLinkToken.token')
    if (token !== null) {
      const link = `${appOrigin}${generatePath(routes.PORTAL_MAGIC_REDIRECT, { token, companyId })}`
      setMagicLinkUrl(link)
    }
    message.success(I18n.t('successMessage', trOpt))
  }
  const closeModal = () => {
    setModalVisibility(false)
    setMagicLinkUrl(null)
  }
  return visible ? (
    <div>
      <br />
      <p>{I18n.t('description', trOpt)}</p>
      <Button type='primary' onClick={() => setModalVisibility(true)}>{I18n.t('modalButton', trOpt)}</Button>
      <Modal
        title={<ModalHeaderId idText='settings-EUP-magic-link-modal-header' text={I18n.t('title', trOpt)} />}
        visible={showModal}
        onCancel={() => closeModal()}
        footer={[
          <Button key='esc' onClick={closeModal}>
            {I18n.t('common.close')}
          </Button>
        ]}
      >
        <p>{I18n.t('modalInstruction', trOpt)}</p>
        <MutationForm
          mutation={ADMIN_MAGIC_LINK_REQUEST}
          onSuccess={onSuccess}
          btnBlock
          submitLabel={I18n.t('generateButton', trOpt)}
          fields={[
            { id: 'email', placeholder: I18n.t('common.fields.email'), type: 'email', required: true }
          ]}
        />
        <br />
        {magicLinkUrl !== null && (
          <>
            <Alert
              message={I18n.t('alertTitle', trOpt)}
              description={I18n.t('alertMessage', trOpt)}
              type='info'
              showIcon
            />
            <br />
            <CopyToClipboard value={magicLinkUrl} label={I18n.t('copyLinkButton', trOpt)} />
          </>
        )}
      </Modal>
    </div>

  ) : null
})

export default Form.create()(GenerateMagicLink)
