import React, { useState, useEffect } from 'react'
import { Divider } from 'antd'
import { generatePath } from 'react-router-dom'
import GaugeChart from 'react-gauge-chart'
import I18n from 'i18n-js'
import moment from 'moment'
import _pick from 'lodash/pick'
import { useQuery } from 'react-apollo'
import { compose } from 'recompose'
import styled, { withTheme } from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import _isInteger from 'lodash/isInteger'

import { RISK_SCORE_CONFIG } from '../../components/Queries/Reports'
import routes from '../../constants/routes'
import { Loader } from '../../components/common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { calculateRiskScorePercentage, RiskLevelStatus } from '../../components/RiskScore'
import { Column } from '../../components/EndUserPortal/GridFlexiLayout'
import { SectionHeader } from '../../components/EndUserPortal/DashboardComponents'
import { formatDate } from '../../helpers/datetime'

const trOpt = { scope: 'reports.dashboard' }
const CentredText = styled.div`
  text-align: center;
`
const RiskScoreTitle = styled.h1`
  font-size: 40px;
  margin-bottom: 2px;
`
const RiskScoreSubTitle = styled.h2`
  margin: 0;
`
const RiskScoreCardDivider = styled(Divider)`
  margin-top: 25px;
  margin-bottom: 10px;
`

const DashboardRiskScore = ({ companyId, prospectStatus, currentRiskScore, previousRiskScore, theme }) => {
  const { data: { riskScoreConfig = {} } = {}, loading } = useQuery(RISK_SCORE_CONFIG)
  const [riskScorePercentage, updateRiskScorePercentage] = useState(null)
  const [riskLevelStatus, updateRisksLevelStatus] = useState(null)
  const hasRiskScore = _isInteger(currentRiskScore?.riskScore)
  const hasPrevRiskScore = _isInteger(previousRiskScore?.riskScore) && previousRiskScore?.date

  useEffect(() => {
    if (currentRiskScore && currentRiskScore.riskScore && !_isEmpty(riskScoreConfig)) {
      updateRiskScorePercentage(calculateRiskScorePercentage(currentRiskScore.riskScore || 0, currentRiskScore.level, riskScoreConfig))
      updateRisksLevelStatus(RiskLevelStatus({ riskScores: currentRiskScore, theme }))
    }
  }, [currentRiskScore, riskScoreConfig, theme])

  if (loading) {
    return <Loader />
  }

  return (
    <Column className='full-height align justify' alignContent='flex-start' justify='space-between'>
      <Column>
        <SectionHeader id='risk-score-header' size='h4' flex='none' title='common.riskScore' link={prospectStatus ? generatePath(routes.RISK_REPORT, { companyId }) : null} />
        <GaugeChart
          style={{ minWidth: '100', marginLeft: 'auto', marginRight: 'auto', width: '75%' }}
          nrOfLevels={5}
          colors={hasRiskScore ? [theme.riskScoreVeryLow, theme.riskScoreLow, theme.riskScoreMedium, theme.riskScoreHigh, theme.riskScoreVeryHigh] : [theme.grey]}
          needleColor={hasRiskScore ? '#464A4F' : theme.darkGrey}
          needleBaseColor={hasRiskScore ? '#464A4F' : theme.darkGrey}
          arcWidth={0.3}
          animate={false}
          percent={riskScorePercentage / 100}
          hideText
        />
        <CentredText>
          {hasRiskScore ? (
            <>
              {riskLevelStatus && (riskLevelStatus.colour && riskLevelStatus.status) && <RiskScoreTitle colour={riskLevelStatus.colour}>{riskLevelStatus.status}</RiskScoreTitle>}
              <RiskScoreSubTitle>{currentRiskScore.riskScore}/{riskScoreConfig.maxRiskScore}</RiskScoreSubTitle>
              {hasPrevRiskScore ? (
                <RiskScoreSubTitle>{currentRiskScore.riskScore - previousRiskScore.riskScore >= 0
                  ? I18n.t('upBy', trOpt)
                  : I18n.t('downBy', trOpt)} <span style={{ color: '#f75959', fontWeight: 'bold' }}>{Math.abs(currentRiskScore.riskScore - previousRiskScore.riskScore)}</span>, {I18n.t('fromDaysAgo', { count: moment().diff(moment(previousRiskScore.date), 'days'), ...trOpt })}
                </RiskScoreSubTitle>
              ) : (
                <h4>{I18n.t('noPreviousRiskScore', trOpt)}</h4>
              )}
            </>
          ) : <h4>{I18n.t('pleaseRunTheRiskReport', trOpt)}</h4>}
        </CentredText>
      </Column>
      <Column>
        <RiskScoreCardDivider />
        {I18n.t('latestRiskScore', trOpt)} - {currentRiskScore?.date ? formatDate(currentRiskScore.date) : I18n.t('common.na')}
      </Column>
    </Column>
  )
}

export default compose(
  connect(
    state => _pick(selectors.session.get(state), ['prospectStatus', 'companyId'])
  ),
  withTheme
)(DashboardRiskScore)
