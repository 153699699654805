import _get from 'lodash/get'

import endUserClient from '../apollo-client/endUserClient'
import { GET_END_USER_SESSION_TYPE } from '../components/Queries/Learners'

// CL - I tried to put this helper in helpers/session but it caused dependency issues.
// Used by courses and policies to check for an End User Portal or User ID Access session before making portal links visible
// End user session types (technically the role property on the JWT)
// full - End User Portal
// limited - User ID Access
// none - No end user session found
export const getEndUserSessionType = async () => {
  let endUserSessionType = 'none'

  try {
    const result = await endUserClient.query({
      query: GET_END_USER_SESSION_TYPE,
      fetchPolicy: 'no-cache'
    })
    const resultEndUserSessionType = _get(result, 'data.endUserSessionType')
    if (['full', 'limited'].includes(resultEndUserSessionType)) {
      endUserSessionType = resultEndUserSessionType
    }
  } catch (e) {
    // silent failure means they don't have a session
    console.error('LearnerCourse.getEndUserSessionTypeFromLearnerMe - ERROR', e)
  }

  return endUserSessionType
}
