import I18n from 'i18n-js'

import { ResetPassword, resetPasswordCompose } from './ResetPassword'

const trOpt = { scope: 'resetPassword' }

class SetPassword extends ResetPassword {
  constructor (props) {
    super(props)

    this.handleFailureConsoleMsg = 'SetPassword.handleFailure'
  }

  get title () {
    return I18n.t('setYourPassword', trOpt)
  }

  get successMessage () {
    return I18n.t('setSuccessMessage', trOpt)
  }

  get submitLabel () {
    return I18n.t('setPassword', trOpt)
  }
}

export default resetPasswordCompose(SetPassword)
