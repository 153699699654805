import { createLocaleGetterObject, createLocaleGetterObjectFromArray, createLocaleGetterOptions } from '../helpers/locale'
import { roleIds } from './roles'

const commsTypeTrScope = 'users.common.commsType'
const roleTrScope = 'users.common.roles'

export const COMMS_TYPES = ['All', 'Sales', 'Marketing', 'Technical']
export const COMMS_TYPE_SHORT_LABELS = createLocaleGetterObject({
  All: 'common.all',
  Sales: `${commsTypeTrScope}.sales`,
  Marketing: `${commsTypeTrScope}.marketing`,
  Technical: `${commsTypeTrScope}.technical`
})
export const COMMS_TYPE_LABELS = createLocaleGetterObject({
  All: 'aBitOfEverything',
  Sales: 'sales',
  Marketing: 'marketing',
  Technical: 'technical'
}, { scope: commsTypeTrScope })
export const COMMS_TYPE_OPTIONS = createLocaleGetterOptions(COMMS_TYPE_LABELS)

// tWithProductNames is used as the role names now include product names (e.g. uLearn) which can be customised by clients
export const ROLE_LABELS = createLocaleGetterObjectFromArray(roleIds, { scope: roleTrScope, trFn: 'tWithProductNames' })
