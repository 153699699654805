import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import I18n from 'i18n-js'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '../common'
import routes from '../../constants/routes'

const trOpt = { scope: 'uBreach.uBreachProUpgradeAlert' }

const Overlay = styled.div`
  background-color: ${rgba('#C4C4C4', 0.9)};
  height: 100%;
  left: 0;
  padding: 40px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2, p {
    text-align: center;
  }

  p {
    font-size: 16px;;
  }

  i {
    color: ${({ theme }) => theme.primary};
    font-size: 64px;
    margin-bottom: 15px;
  }
`

const UBreachProUpgradeCTAOverlay = ({ selfService = false, showDescription = false, className }) => {
  return (
    <Overlay {...{ className }}>
      <Container>
        <FontAwesomeIcon icon='lock' />
        <h2>{I18n.tWithProductNames('title', trOpt)}</h2>
        {showDescription && <p className='base-font'>{I18n.t('description', trOpt)}</p>}
        <Link to={routes.UBREACH_UPGRADE}>
          <Button type='primary'>{I18n.t(selfService ? 'upgradeButton' : 'learnMoreButton', trOpt)}</Button>
        </Link>
      </Container>
    </Overlay>
  )
}

export default UBreachProUpgradeCTAOverlay
