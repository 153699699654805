import React from 'react'
import I18n from 'i18n-js'
import { Switch } from 'antd'
import _pick from 'lodash/pick'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { creators as viewActions } from '../../state/actions/view'

const ExcludeInactiveToggle = ({ excludeInactiveUsersInReports, setExcludeInactiveUsersInReports }) => {
  return (
    <>
      <Switch checked={excludeInactiveUsersInReports} onChange={setExcludeInactiveUsersInReports} />
      <span style={{ marginLeft: 10 }}>{I18n.t('common.excludeInactiveUsersInReports')}</span>
    </>
  )
}

export default connect(
  state => _pick(selectors.view.get(state), ['excludeInactiveUsersInReports']),
  dispatch => ({
    setExcludeInactiveUsersInReports: excludeInactiveUsersInReports => dispatch(viewActions.excludeInactiveUsersInReports(excludeInactiveUsersInReports))
  })
)(ExcludeInactiveToggle)
