import React from 'react'
import { Alert } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _isArray from 'lodash/isArray'

import { renderParagraphsFragmentFromArray, renderListFromArray, renderListFragmentFromArray } from '../../helpers/renderFromArray'
import { getTranslationWithFallback } from '../../helpers/locale'

const defaultIntroTrOpt = { scope: 'syncSetup.setupIntro' }

const SyncSetupIntroContainer = styled.div`
  & > p {
    font-family: unset;
  }
`

const PerformingAlert = styled(Alert)`
  margin: 15px 0;
  width: 100%;
  max-width: 600;

  p {
    font-family: unset;
  }
`

// Going forward any new translations for this view should be added to syncSetup.setupIntro
// However you can create a service scope for overrides which will picked by the function
const getSyncSetupIntroTranslation = (key, trOpt) => getTranslationWithFallback(key, trOpt.scope, defaultIntroTrOpt.scope)

const SyncSetupIntro = ({ syncType, trOpt = defaultIntroTrOpt }) => {
  let optionsList = getSyncSetupIntroTranslation('optionsList', trOpt)
  if (!_isArray) {
    optionsList = []
  }
  if (syncType === 'microsoft') {
    optionsList.splice(1, 0, getSyncSetupIntroTranslation('emailMethod', trOpt))
  }

  return (
    <SyncSetupIntroContainer>
      <h3>{getSyncSetupIntroTranslation('title', trOpt)}</h3>
      <p>{getSyncSetupIntroTranslation('setupProcess', trOpt)}</p>
      <p>{getSyncSetupIntroTranslation('optionsIntro', trOpt)}</p>
      <ul>
        {renderListFragmentFromArray(optionsList)}
        <li>
          <span>{getSyncSetupIntroTranslation('groupMapping', trOpt)}</span>
          {renderListFromArray(getSyncSetupIntroTranslation('groupMappingList', trOpt))}
        </li>
        {syncType === 'microsoft' && <li>{getSyncSetupIntroTranslation('excludeUsersWithoutAssignedLicenses', trOpt)}</li>}
        <li>
          <span>{getSyncSetupIntroTranslation('emailBlacklist', trOpt)}</span>
          {renderListFromArray(I18n.t('googleSync.common.emailBlacklistPoints'))}
        </li>
        <li>{getSyncSetupIntroTranslation('syncManagers', trOpt)}</li>
      </ul>
      <p>{getSyncSetupIntroTranslation('existingUsers', trOpt)}</p>
      <PerformingAlert
        type='warning' showIcon
        message={getSyncSetupIntroTranslation('performingSyncAlertTitle', trOpt)}
        description={renderParagraphsFragmentFromArray(getSyncSetupIntroTranslation('performingSyncAlertBody', trOpt))}
      />
      <h5>{I18n.t('sync.common.testSync')}</h5>
      <ul>
        {renderListFragmentFromArray(getSyncSetupIntroTranslation('testSyncList', trOpt))}
        <li>
          <span>{getSyncSetupIntroTranslation('testSyncSuggestedProcess', trOpt)}</span>
          {renderListFromArray(getSyncSetupIntroTranslation('testSyncSuggestedProcessList', trOpt))}
        </li>
      </ul>
    </SyncSetupIntroContainer>
  )
}

export default SyncSetupIntro
