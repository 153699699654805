import moment from 'moment'

import CourseReminder from './CourseReminder'
import { SEND_GAP_ANALYSIS_REMINDERS_NOW } from '../../Queries/Courses'

const DEFAULT_THRESHOLD = moment.duration(2, 'days').asMilliseconds()

class GapAnalysisReminder extends CourseReminder {
  constructor (props) {
    super(props)

    this.enabledSettingId = 'gapAnalysisReminder'
    this.thresholdSettingId = 'gapAnalysisReminderThresholdMs'
    this.sendReminderNowMutation = SEND_GAP_ANALYSIS_REMINDERS_NOW
    this.sendReminderNowMutationResultId = 'sendGapAnalysisReminders'

    this.trOpt = { scope: 'settings.gapAnalysisReminder' }
  }

  get headerId () {
    return 'settings-gap-analysis-reminder-header'
  }

  getDefaultValue () {
    return {
      [this.enabledSettingId]: true,
      [this.thresholdSettingId]: DEFAULT_THRESHOLD
    }
  }
}

export default GapAnalysisReminder
