/* global Event */
import React, { useCallback, useMemo, useRef, useEffect, useState, useImperativeHandle } from 'react'
import { Alert, Button, Form, Icon, Select, message } from 'antd'
import { useQuery, useApolloClient, useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import styled from 'styled-components'
import moment from 'moment'
import { generatePath } from 'react-router-dom'
import _difference from 'lodash/difference'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'

import { UPDATE_COMPANY_SETTING, UPDATE_DEFAULT_TENANT_SETTING } from '../../components/Queries/Companies'
import { GET_GROUP_TREE } from '../../components/Queries/Groups'
import { GET_AUTO_PHISH_LANDING_PAGES, GET_SIMULATION_DOMAINS, ARE_AUTO_PHISH_TEMPLATES_AVAILABLE } from '../../components/Queries/uPhish'
import { GET_COURSES } from '../../components/Queries/Courses'
import { buildTree } from '../../components/Learners/helpers'
import { LoadingBlock, ErrorAlerts } from '../../components/common'
import { SettingsFormElement, SettingsHeader, SettingsFormContainer, SettingsFormFieldExtra } from '../../components/Settings/SettingsForm'
import { getSettingsFromCompany } from '../../helpers/getMe'
import { LANGUAGE_SELECT_OPTIONS, LANGUAGE_NAMES_BY_CODE } from '../../constants/languages'
import { COURSE_SUBJECT_OPTIONS } from '../../constants/courses'
import selectors from '../../state/selectors'
import { renderToString } from 'react-dom/server'
import routes from '../../constants/routes'
import { renderParagraphsFragmentFromArray, renderListFragmentFromArray, showErrors, modalConfirmAsync, modalErrorAsync } from '../../helpers'
import { DialogModalContent } from '../../components/Modals/common'
import { DescriptionContainer } from '../../components/Settings/common'
import { DELIVERY_METHODS, DELIVERY_METHOD_OPTIONS } from '../../constants/uPhish'
import { SETTING_DELETE_VALUE } from '../../constants/settings'
import { useGlobalState, useHasSessionPermission } from '../../hooks'
import { isMessageInjectionAuthorised } from '../../helpers/messageInjection'
import IntercomHeader from '../../components/IntercomHeader'
import { permissions } from '../../constants/permissions'
import { getRequiredUpdatePermissions } from '../../helpers/settings'

const { Option } = Select
const trOpt = { scope: 'uPhish.autoPhish' }
const checkTrOpt = { scope: `${trOpt.scope}.templateCheck` }
const commonTrOpt = { scope: 'uPhish.common' }
const LOCALES_REQUIRED = window.__USECURE_CONFIG__.REACT_APP_AUTO_PHISH_LOCALES_REQUIRED === 'true'

const AutoPhishMutationForm = styled(SettingsFormElement)`
  .auto-phish-template-filter-select {
    .ant-form-item-control-wrapper {
      display: inline-block;
    }
    /* The width of this field was tested with the English and could result in truncated options in other languages */
    .ant-select {
      min-width: 240px;
    }
  }
`

// Returns true if value is string representing HH:mm time
const isTimeValue = value => _isString(value) && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)

const getTemplateLinkField = values => values.useCompanyLocales ? 'companyContentLocales' : 'locales'
const getTemplateOptionLabelProp = values =>
  ((values.useCompanyLocales ? values.companyContentLocales : values.locales) ?? []).length === 1
    // Use template name as option label as only a single language selected so ideally each template will have a unique name
    ? 'name'
    : 'nameWithLanguages'

const getTemplateFieldValueForFormLocales = ({ value, useCompanyLocales, companyContentLocales, locales, landingPageOptions } = {}) => {
  if (!(_isArray(value) && !_isEmpty(value))) return []
  const formLocales = (useCompanyLocales ? companyContentLocales : locales) || []
  const landingPageOptionValues = (
    formLocales.length > 0
      ? landingPageOptions.filter(({ linkFieldValue }) =>
        (linkFieldValue ?? []).some(locale => formLocales.includes(locale))
      )
      : landingPageOptions
  ).map(l => l.value)
  return value.filter(id => landingPageOptionValues.includes(id))
}

const getDomainAllowListValue = ({ domainAllowList, domainOptions }) => {
  if (!(_isArray(domainAllowList) && !_isEmpty(domainAllowList))) return []
  const domainOptionValues = domainOptions.map(d => d.value)
  return domainAllowList.filter(domain => domainOptionValues.includes(domain))
}

const DisabledLocalesSelect = React.forwardRef(({ id, label, placeholder, options, contentLocales = [], visible }, ref) => {
  if (!visible) return null
  return (
    <Form.Item
      label={label}
      required={LOCALES_REQUIRED}
    >
      <Select
        name={id}
        optionFilterProp='label'
        value={contentLocales}
        required={LOCALES_REQUIRED}
        mode='multiple'
        disabled
        {...{ placeholder }}
      >
        {options.map((option, index) => (
          <Option key={index} value={option.value} disabled={option.disabled} label={option.label}>{option.label}</Option>
        ))}
      </Select>
    </Form.Item>
  )
})

const TemplateCheckDesc = styled.div`
  p {
    font-family: unset;
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    padding-left: 18px;
  }
`
const TemplateCheckAlert = React.forwardRef(({ visible, disabled, checkAvailableTemplates = () => {}, formValues: autoPhishSettings }, ref) => {
  const [loading, setLoading] = useState()
  const onClick = useCallback(async () => {
    setLoading(true)
    try {
      if (!autoPhishSettings) throw new Error(I18n.t('errorMessage', checkTrOpt))
      const available = await checkAvailableTemplates(autoPhishSettings)
      if (available) {
        message.success(I18n.t('successMessage', checkTrOpt))
      } else {
        message.warning(I18n.t('warningMessage', checkTrOpt))
      }
    } catch (e) {
      console.error('AutoPhish.TemplateCheckAlert.onClick', e)
      showErrors(e, I18n.t('errorMessage', checkTrOpt))
    }
    setLoading(false)
  }, [autoPhishSettings, checkAvailableTemplates])
  if (!visible) return null
  return (
    <Alert
      style={{ marginBottom: 14 }}
      message={I18n.t('title', checkTrOpt)}
      description={
        <TemplateCheckDesc>
          <ul>{renderListFragmentFromArray(I18n.t('body', checkTrOpt))}</ul>
          <Button
            type='primary' icon='safety'
            {...{ loading, onClick, disabled }}
          >
            {I18n.t('button', checkTrOpt)}
          </Button>
        </TemplateCheckDesc>
      }
      type='info'
      showIcon
    />
  )
})

const CourseHeader = React.forwardRef(({ visible }, ref) => (
  visible
    ? (
      <div style={{ marginTop: 24 }}>
        <h4>{I18n.t('uPhish.createSimulationSchedule.inlineTrainingTitle')}</h4>
        <p className='base-font'>{I18n.t('uPhish.autoPhish.inlineTrainingInfo')}</p>
      </div>
    ) : null
))

const preSaveWarningModalSpec = {
  icon: <Icon type='exclamation-circle' />,
  get okText () { return I18n.t('common.continue') },
  get cancelText () { return I18n.t('common.cancel') }
}

const AutoPhishForm = ({ autoPhish, landingPageOptions, groupTreeOptions, workingHours, updateSettings, refetchQueries, domainOptions, defaultTenant, uLearn: uLearnEnabled, forwardedRef, disableSubmit }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const [loading, setLoading] = useState(true)
  const [subjectOptions, setSubjectOptions] = useState([])
  const [courseOptions, setCourseOptions] = useState([])
  const [, setForceRender] = useState(null) // Forces re-render to bump has changed state

  const { contentLocales = [], uPhishDeliveryMethod = 'smtp', messageInjection } = useGlobalState(
    state => ({
      ..._pick(selectors.session.get(state), ['contentLocales']),
      ..._pick(selectors.settings.get(state), ['uPhishDeliveryMethod', 'messageInjection'])
    })
  )

  const form = useRef(null)
  const initialiseForm = useCallback(async () => {
    if (form && form.current) {
      let includeTemplates = null
      let excludeTemplates = null
      if (autoPhish.templateFiltering === 'include') {
        includeTemplates = getTemplateFieldValueForFormLocales({ ...autoPhish, value: autoPhish.includeTemplates, companyContentLocales: contentLocales, landingPageOptions })
      } else if (autoPhish.templateFiltering === 'exclude') {
        excludeTemplates = getTemplateFieldValueForFormLocales({ ...autoPhish, value: autoPhish.excludeTemplates, companyContentLocales: contentLocales, landingPageOptions })
      }

      await form.current.setInitialValues({
        ...autoPhish,
        domainAllowList: getDomainAllowListValue({ ...autoPhish, domainOptions }),
        includeTemplates,
        excludeTemplates,
        companyContentLocales: contentLocales ?? []
      })
      setForceRender(Date.now())
      setLoading(false)
    }
  }, [autoPhish, landingPageOptions, domainOptions, form, contentLocales])
  useEffect(() => {
    initialiseForm()
  }, [initialiseForm, defaultTenant])
  const onChange = useCallback(id => {
    if (id === 'enabled') {
      window.dispatchEvent(new Event('resize'))
    }
    setForceRender(Date.now())
  }, [])

  const requiredUpdatePermissions = getRequiredUpdatePermissions({
    defaultTenant,
    requiredPermissions: [permissions.SETTINGS_UPHISH_UPDATE]
  })
  const isUpdateAllowed = hasAllSessionPermissions(requiredUpdatePermissions)
  const hasChanged = useCallback(() => isUpdateAllowed && form.current?.hasChanged() === true, [form, isUpdateAllowed])

  useImperativeHandle(forwardedRef, () => ({
    hasChanged
  }), [hasChanged])

  const { data: coursesData } = useQuery(GET_COURSES, {
    variables: { excludeGapAnalysis: true, restrictToPlan: true, withCompany: true },
    skip: !uLearnEnabled
  })

  useEffect(() => {
    const { courses = [] } = coursesData ?? {}
    setSubjectOptions([
      { value: '{all}', label: I18n.t('common.all') },
      ...COURSE_SUBJECT_OPTIONS.filter(({ value }) => courses.some(course => course.subject === value))
    ])
    setCourseOptions(courses
    // Sort by difficulty then sort by displayName
      .sort((a, b) => a.difficulty - b.difficulty || a.displayName.toLowerCase() > b.displayName.toLowerCase())
      .map(course => ({ value: course.id, label: course.displayName, linkFieldValue: course.subject }))
    )
  }, [coursesData])

  const areWorkingHoursValid = useMemo(() => {
    const { start: startStr, end: endStr } = workingHours ?? {}
    let areWorkingHoursValid = isTimeValue(startStr) && isTimeValue(endStr)
    if (areWorkingHoursValid) {
      const workingStartToday = moment(startStr, 'HH:mm')
      const workingEndToday = moment(endStr, 'HH:mm')
      areWorkingHoursValid = workingStartToday.isBefore(workingEndToday, 'minutes')
    }
    return areWorkingHoursValid
  }, [workingHours])

  const client = useApolloClient()
  const checkAvailableTemplates = useCallback(async (autoPhishSettings) => {
    const result = await client.query({
      query: ARE_AUTO_PHISH_TEMPLATES_AVAILABLE,
      variables: { autoPhishSettings, defaultTenant },
      fetchPolicy: 'no-cache'
    })
    return result?.data?.areAutoPhishTemplatesAvailable
  }, [defaultTenant, client])

  const showDeliveryMethod = useMemo(() => !defaultTenant && isMessageInjectionAuthorised({ messageInjection }), [messageInjection, defaultTenant])
  const deliveryMethodOptions = useMemo(() => {
    return [
      {
        value: 'default',
        get label () {
          return I18n.t('defaultDeliveryMethod', { ...trOpt, deliveryMethod: DELIVERY_METHODS[uPhishDeliveryMethod] })
        }
      },
      ...DELIVERY_METHOD_OPTIONS
    ]
  }, [uPhishDeliveryMethod])

  const fields = useMemo(() => {
    const localesProps = {
      label: I18n.t('locales', trOpt),
      options: LANGUAGE_SELECT_OPTIONS,
      sortOptions: true,
      placeholder: I18n.t('common.fields.languagesPlaceHolder')
    }
    const templateProps = {
      type: 'multiSelect',
      required: true,
      options: landingPageOptions,
      optionLabelProp: getTemplateOptionLabelProp,
      linkField: getTemplateLinkField,
      disableOnEmptyLinkField: false,
      allowClear: true,
      maxTagTextLength: 60,
      alwaysShowValidateErrors: true,
      validate: (field, value, errors, opt, values) => {
        const { id } = field
        const { templateFiltering, useCompanyLocales, companyContentLocales, locales } = values
        if (value?.length > 0 && ((templateFiltering === 'include' && id === 'includeTemplates') || (templateFiltering === 'exclude' && id === 'excludeTemplates'))) {
          // Show error if there any selected templates that are no longer in the dropdown options
          // CL - I wanted to highlight the invalid tags within MutationFormSelect but Ant Design 3 doesn't let you customise tag rendering
          const processedValue = getTemplateFieldValueForFormLocales({ value, useCompanyLocales, companyContentLocales, locales, landingPageOptions })
          const invalidValues = _difference(value, processedValue)
          if (invalidValues.length > 0) {
            errors.push(I18n.t('invalidTemplateError', { ...trOpt, count: invalidValues.length }))
          }
        }
      }
    }
    // Default Customer Settings version of Auto Phish allows MSPs to configure the feature with it needing to be activated immediately.
    // An MSP can then turn on auto phish on a new customer when ready and the configuration will be prepopulated.
    const isEnabled = ({ enabled }) => defaultTenant || enabled === true
    return [
      {
        id: 'enabled',
        label: I18n.t('enableAutoPhish', trOpt),
        type: 'switch',
        defaultValue: false
      },
      {
        id: 'howOften',
        label: I18n.t('howManyWeeksBetweenSimulations', trOpt),
        type: 'number',
        required: true,
        max: 52,
        min: 1,
        visible: isEnabled
      },
      {
        id: 'workingHours',
        label: I18n.t('uPhish.common.workingHours'),
        type: 'checkbox',
        mutateValue: value => areWorkingHoursValid && value === true,
        visible: isEnabled,
        disabled: !areWorkingHoursValid
      },
      {
        id: 'deliveryMethod',
        label: I18n.t('preferredDeliveryMethod', commonTrOpt),
        type: 'select',
        options: deliveryMethodOptions,
        defaultValue: 'default',
        visible: showDeliveryMethod ? isEnabled : false,
        extra: (
          <SettingsFormFieldExtra
            copy={[
              I18n.t('preferredDeliveryMethodExtra', trOpt),
              I18n.t('messageInjectionDisclaimer', commonTrOpt)
            ]}
          />
        )
      },
      {
        id: 'excludeGroups',
        label: I18n.t('excludeGroupsFromReceivingSimulations', trOpt),
        type: 'treeSelect',
        treeData: groupTreeOptions,
        multiple: true,
        visible: defaultTenant ? false : isEnabled
      },
      {
        id: 'domainAllowList',
        label: I18n.t('domainAllowList', trOpt),
        type: 'multiSelect',
        extra: I18n.t('domainAllowListExtra', { ...trOpt, domainsAllowed: 50 }),
        options: domainOptions,
        visible: isEnabled
      },
      {
        id: 'useCompanyLocales',
        label: I18n.t('useCompanyLocales', trOpt),
        type: 'switch',
        defaultValue: contentLocales?.length > 0,
        visible: isEnabled,
        extra: (
          <span
            dangerouslySetInnerHTML={{
              __html: I18n.t('useCompanyLocalesExtra', {
                ...trOpt,
                languagesLink: renderToString(
                  <a
                    className='usecure-manual-rr-link'
                    href={decodeURIComponent(generatePath(defaultTenant ? routes.DEFAULT_CUSTOMER_SETTINGS_SCREEN : routes.SETTINGS_SCREEN, { screenPath: 'language' }))}
                  >
                    {I18n.t('common.language')}
                  </a>
                )
              })
            }}
          />
        )
      },
      {
        id: 'locales',
        type: 'multiSelect',
        ...localesProps,
        defaultValue: [],
        required: LOCALES_REQUIRED ? ({ useCompanyLocales }) => useCompanyLocales !== true : false,
        visible: ({ enabled, useCompanyLocales }) => isEnabled({ enabled }) && useCompanyLocales !== true
      },
      // This custom component is a dummy locales field listing company.contentLocales in a disabled state when useCompanyLocales is true
      // Saves writing some weird logic to replicate this with the actual field
      {
        id: 'companyContentLocales',
        type: 'custom',
        ...localesProps,
        contentLocales,
        component: DisabledLocalesSelect,
        visible: ({ enabled, useCompanyLocales }) => isEnabled({ enabled }) && useCompanyLocales === true
      },
      {
        id: 'templateFiltering',
        label: I18n.t('templateFiltering', trOpt),
        type: 'select',
        className: 'auto-phish-template-filter-select',
        options: [{
          value: 'none', label: I18n.t('common.none')
        }, {
          value: 'include', label: I18n.t('include', trOpt)
        }, {
          value: 'exclude', label: I18n.t('exclude', trOpt)
        }],
        defaultValue: 'none',
        visible: isEnabled
      },
      {
        id: 'includeTemplates',
        label: I18n.t('includeTemplates', trOpt),
        visible: ({ enabled, templateFiltering }) => isEnabled({ enabled }) && templateFiltering === 'include',
        ...templateProps
      },
      {
        id: 'excludeTemplates',
        label: I18n.t('excludeTemplates', trOpt),
        visible: ({ enabled, templateFiltering }) => isEnabled({ enabled }) && templateFiltering === 'exclude',
        ...templateProps
      },
      {
        id: 'templateCheckAlert',
        type: 'custom',
        component: TemplateCheckAlert,
        checkAvailableTemplates,
        useFormValues: true,
        visible: isEnabled
      }, {
        type: 'custom',
        component: CourseHeader,
        visible: uLearnEnabled ? isEnabled : false
      }, {
        id: 'courseSubject',
        label: I18n.t('common.fields.subject'),
        type: 'select',
        defaultValue: '{all}',
        options: subjectOptions,
        visible: uLearnEnabled ? isEnabled : false
      }, {
        id: 'courseId',
        label: I18n.t('common.courseLabel'),
        type: 'select',
        linkField: 'courseSubject',
        placeholder: I18n.t('uPhish.createSimulationSchedule.coursePlaceholder'),
        options: courseOptions,
        mutateValue: value => value ?? null,
        visible: uLearnEnabled ? isEnabled : false
      }
    ]
  }, [
    landingPageOptions, groupTreeOptions, areWorkingHoursValid, domainOptions, defaultTenant, contentLocales,
    checkAvailableTemplates, uLearnEnabled, subjectOptions, courseOptions, deliveryMethodOptions, showDeliveryMethod
  ])

  const mutateValues = useCallback(values => {
    const { useCompanyLocales, companyContentLocales, locales, templateFiltering, includeTemplates, excludeTemplates, domainAllowList } = values
    const mutatedValues = _omit(values, ['companyContentLocales', 'templateCheckAlert', 'courseSubject'])
    if (useCompanyLocales) mutatedValues.locales = SETTING_DELETE_VALUE
    if (!showDeliveryMethod) mutatedValues.deliveryMethod = 'default'

    if (templateFiltering === 'include') {
      mutatedValues.includeTemplates = getTemplateFieldValueForFormLocales({ value: includeTemplates, useCompanyLocales, companyContentLocales, locales, landingPageOptions })
      mutatedValues.excludeTemplates = SETTING_DELETE_VALUE
    } else if (templateFiltering === 'exclude') {
      mutatedValues.includeTemplates = SETTING_DELETE_VALUE
      mutatedValues.excludeTemplates = getTemplateFieldValueForFormLocales({ value: excludeTemplates, useCompanyLocales, companyContentLocales, locales, landingPageOptions })
    } else {
      mutatedValues.includeTemplates = SETTING_DELETE_VALUE
      mutatedValues.excludeTemplates = SETTING_DELETE_VALUE
    }

    mutatedValues.domainAllowList = getDomainAllowListValue({ domainAllowList, domainOptions })

    return mutatedValues
  }, [showDeliveryMethod, domainOptions, landingPageOptions])

  const [saveSettings] = useMutation(defaultTenant ? UPDATE_DEFAULT_TENANT_SETTING : UPDATE_COMPANY_SETTING, { refetchQueries })
  const onSuccess = useCallback(result => {
    message.success(I18n.t('successfullyUpdatedAutoPhishSettings', trOpt))
    if (_isFunction(updateSettings)) {
      const company = result?.data?.[defaultTenant ? 'updateCompanySetting' : 'updateDefaultTenantSetting']
      if (company) {
        const settings = getSettingsFromCompany(company)
        if (settings) {
          updateSettings(settings)
        }
      }
    }
  }, [updateSettings, defaultTenant])
  const onSubmit = useCallback(async (values, errors, variables) => {
    try {
      setLoading(true)

      if (defaultTenant || values.enabled) {
        let completeSave = true
        if (values.useCompanyLocales === true && !(_isArray(contentLocales) && !_isEmpty(contentLocales))) {
          let content = I18n.t('noLanguagesPrompt.content', { ...trOpt, useCompanyLocales: I18n.t('useCompanyLocales', trOpt) })
          content = _isArray(content) ? [...content] : [content] // Paranoid code to avoid modifying the translation or an error if this is a string
          completeSave = await modalConfirmAsync({
            ...preSaveWarningModalSpec,
            title: I18n.t('noLanguagesPrompt.title', trOpt),
            content: <DialogModalContent>{renderParagraphsFragmentFromArray(content)}</DialogModalContent>
          })
        }

        if (completeSave) {
          const available = await checkAvailableTemplates(values)
          if (!available) {
            let content = I18n.t('noTemplatesPrompt.content', trOpt)
            content = _isArray(content) ? [...content] : [content] // Paranoid code to avoid modifying the translation or an error if this is a string
            if (defaultTenant) {
              // MSP Default Customer Settings - Show warning confirm dialog as the settings may work for a customer
              content.push(I18n.t('noTemplatesPrompt.defaultTenantWarning', trOpt))
              completeSave = await modalConfirmAsync({
                ...preSaveWarningModalSpec,
                title: I18n.t('noTemplatesPrompt.title', trOpt),
                content: <DialogModalContent>{renderParagraphsFragmentFromArray(content)}</DialogModalContent>
              })
            } else {
              // Customer and NFR Settings - Show error dialog as these settings will result in failed auto phish runs
              completeSave = false
              await modalErrorAsync({
                title: I18n.t('noTemplatesPrompt.errorTitle', trOpt),
                content: <DialogModalContent>{renderParagraphsFragmentFromArray(content)}</DialogModalContent>
              })
            }
          }
        }
        if (!completeSave) {
          throw new Error(I18n.t('cancelledMessage', trOpt))
        }
      }
      const results = await saveSettings({ variables })
      onSuccess(results)
    } finally {
      setLoading(false)
    }
  }, [checkAvailableTemplates, saveSettings, onSuccess, defaultTenant, contentLocales])

  return (
    <>
      <LoadingBlock loading={loading} fullScreen={false} />
      <AutoPhishMutationForm
        headerId='settings-auto-phish-header'
        title={I18n.t('title', trOpt)}
        desc={renderParagraphsFragmentFromArray(I18n.t('description', trOpt))}
        formProps={{
          ref: form,
          fields,
          variables: { settingId: 'autoPhish' },
          valuesObjectName: 'setting',
          mutateValues,
          onChange,
          onSubmit,
          refetchQueries,
          failureMessage: I18n.t('anErrorOccurredAttemptingToUpdate', trOpt),
          skipResetFieldsOnSubmit: true,
          disableSubmitIfInvalid: false
        }}
        disableSubmit={disableSubmit || !isUpdateAllowed}
        isUpdateAllowed={isUpdateAllowed}
        hasChanged={hasChanged()}
      />
    </>
  )
}

// This view is now part of settings so it no longer loads company settings itself instead relying on the global state values provided by the Settings view
const AutoPhish = React.forwardRef(({ defaultTenant, settings: companySettings, updateSettings, contentLocales: companyContentLocales, disableSubmit }, ref) => {
  const { loading: groupTreeLoading, error: groupsLoadError, data: groupsData = {} } = useQuery(GET_GROUP_TREE)
  const { loading: domainsLoading, error: domainsError, data: domainsData } = useQuery(GET_SIMULATION_DOMAINS)
  const { loading: landingPagesLoading, error: landingPagesError, data: landingPagesData } = useQuery(GET_AUTO_PHISH_LANDING_PAGES)

  const autoPhish = companySettings?.autoPhish ?? {}
  const uLearn = companySettings?.uLearn === true
  const workingHours = companySettings?.workingHours ?? {}

  const groupTreeOptions = useMemo(
    () => buildTree(groupsData?.groupTree ?? []),
    [groupsData]
  )

  const landingPageOptions = useMemo(
    () => (landingPagesData?.autoPhishLandingPages ?? []).map(({ id, name, locales, supportedLocales }) => {
      // The language summary only includes the landing page assigned languages as any base language content will have a bloated.
      const languageNames = _isArray(locales) && !_isEmpty(locales) ? locales.map(l => LANGUAGE_NAMES_BY_CODE[l]).sort((a, b) => a.localeCompare(b)) : []
      const languages = ` - ${_isEmpty(languageNames) ? I18n.t('common.none') : languageNames.join(', ')}`
      return {
        value: id,
        name,
        nameWithLanguages: `${name}${languages}`,
        // This uses supportedLocales so the regional to base language fallback is applied to match the Landing Page Library
        linkFieldValue: supportedLocales ?? []
      }
    }),
    [landingPagesData]
  )
  const domainOptions = useMemo(
    () => (domainsData?.simulationDomains ?? []).map(domain => ({
      value: domain,
      label: domain
    })),
    [domainsData]
  )

  let body = null
  if (groupsLoadError) {
    body = <ErrorAlerts error={groupsLoadError} defaultError={I18n.t('common.groupsLoadError')} />
  } else if (landingPagesError) {
    body = <ErrorAlerts error={landingPagesError} defaultError={I18n.t('theLandingPageListCouldNotBeLoaded', trOpt)} />
  } else if (domainsError) {
    body = <ErrorAlerts error={domainsError} defaultError={I18n.t('domainsLoadError', trOpt)} />
  } else if (groupTreeLoading || landingPagesLoading || domainsLoading) {
    body = <LoadingBlock fullScreen={false} loading />
  }
  if (body) {
    return (
      <SettingsFormContainer>
        <SettingsHeader>
          <IntercomHeader Size='h1' id='auto-phish-header'>{I18n.t('title', trOpt)}</IntercomHeader>
          <DescriptionContainer>{renderParagraphsFragmentFromArray(I18n.t('description', trOpt))}</DescriptionContainer>
        </SettingsHeader>
        {body}
      </SettingsFormContainer>
    )
  }

  return (
    <AutoPhishForm
      {...{ autoPhish, uLearn, groupTreeOptions, landingPageOptions, workingHours, updateSettings, domainOptions, defaultTenant, disableSubmit, forwardedRef: ref }}
      contentLocales={(defaultTenant ? companySettings?.contentLocales : companyContentLocales) ?? []}
    />
  )
})

export default AutoPhish
