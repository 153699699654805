import React from 'react'
import FlipCardComponentOriginal from '@kennethormandy/react-flipcard'

import '@kennethormandy/react-flipcard/dist/Flipcard.css'

class FlipCardComponent extends FlipCardComponentOriginal {
  render () {
    const props = this.props
    const state = this.state
    let {
      type,
      flipped,
      disabled,
      classNameFlipper,
      classNameFront,
      classNameBack,
      ...remainingProps
    } = props

    // TODO Always seems to update styles on Safari?
    let styles = {}
    // usecure - Skip applying rotate transform on card element
    // if (type === 'revolving-door') {
    //   let rotateY = `rotateY(${state.flipperRotate}deg)`
    //   styles = {
    //     WebkitTransform: rotateY,
    //     transform: rotateY
    //   }
    // }

    let classes = [
      'Flipcard-flipper',
      state.isFlipped === true ? 'Flipcard--flipped' : '',
      `Flipcard--${type}`,
      classNameFlipper
    ]

    return (
      <div {...remainingProps} className='Flipcard' tabIndex={0}>
        <div className={classes.join(' ')} style={styles}>
          {[0, 1].map(index => {
            const child = props.children[index]
            let zero = 0
            let one = 1
            if (index === 1) {
              zero = 1
              one = 0
            }

            const style = {
              opacity: state.isFlipped ? zero : one,
              userSelect:
                state.isFlipped && !disabled === true ? 'none' : null,
              cursor:
                !disabled &&
                (typeof props.onClick === 'function' ||
                  typeof child.props.onClick === 'function')
                  ? 'pointer'
                  : 'auto'
            }
            // usecure - Override to rotateY transform on front and back of flip card rather than the element containing them
            // Allows the flip effect to work in IE11
            if (type === 'revolving-door') {
              let rotateY = `rotateY(${state.flipperRotate + (index === 0 ? 0 : 180)}deg)`
              style.WebkitTransform = rotateY
              style.transform = rotateY
            }

            return (
              <div
                key={`Flipcard_card_${index}`}
                className={`Flipcard-${
                  index === 0
                    ? `front ${classNameFront}`
                    : `back ${classNameBack}`
                }`}
                style={style}
                tabIndex={-1}
                aria-hidden={state.isFlipped && !disabled}>
                {child}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default FlipCardComponent
