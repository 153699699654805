import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Checkbox } from 'antd'

class MutationFormCheckbox extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hasReceivedFocus: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
  }

  handleChange (event) {
    const { name, checked } = event.target
    this.props.onChange(name, checked)
  }

  handleFocus () {
    this.setState({ hasReceivedFocus: true })
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, label, required, placeholder, checked, disabled, extra, renderLabelInline } = this.props

    return (
      <Form.Item label={renderLabelInline ? null : label} required={required} extra={extra}>
        <Checkbox
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          checked={checked}
          name={id}
          {...{ required, placeholder, disabled }}
        >
          {renderLabelInline ? label : null}
        </Checkbox>
      </Form.Item>
    )
  }
}

MutationFormCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

MutationFormCheckbox.defaultProps = {
  id: '',
  label: null,
  required: false,
  disabled: false,
  onChange: () => { },
  value: false,
  checked: false
}

export default MutationFormCheckbox
