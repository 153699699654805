import React, { useCallback, useMemo, useState } from 'react'
import { Button, Card, Radio, Select, TreeSelect, Modal, Icon } from 'antd'
import { Query } from 'react-apollo'
import styled, { css, withTheme } from 'styled-components'
import _capitalize from 'lodash/capitalize'
import _groupBy from 'lodash/groupBy'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import { lighten } from 'polished'
import moment from 'moment'
import I18n from 'i18n-js'

import { SIMULATION_PERFORMANCE } from '../../components/Queries/Reports'
import { GET_SIMULATION_LANDING_PAGE_TEMPLATES, GET_SIMULATION_EMAIL_TEMPLATES } from '../../components/Queries/uPhish'
import { buildTree } from '../../components/Learners/helpers'
import { ViewSimulation, ViewSimulationUsers } from '../uPhish'
import { LineChart } from '../../components/Graphs'
import { ErrorAlerts, LoadingBlock } from '../../components/common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { getMomentFormatString, formatDate } from '../../helpers/datetime'
import IntercomHeader from '../../components/IntercomHeader'

const trOpt = { scope: 'reports.simulationPerformance' }

/**
 * Group filter
 * v1
 * - simulations only appear if they have a byGroup metric for a selected group
 * - A byGroup metric is only created when a tracking event occurs
 * - The rate appears to events in group / total number of recipients in simulation
 *
 * v2
 * - simulations appear if a recipient (queue or result) is part of the selected group or one of its descendants
 * - A simulation will appear even if no events have been recorded on it
 * - The rate is events in group / total number of recipients in simulation that belong to the selected groups or one of its descendants
 */

const DATE_FORMATS = {
  days: getMomentFormatString({ type: 'date', includeYear: false }),
  weeks: getMomentFormatString({ type: 'date', includeYear: false }),
  months: getMomentFormatString({ type: 'date', includeDay: false }),
  years: 'YYYY'
}
const FILTER_PERIODS = ['days', 'weeks', 'months', 'years']

const { Option } = Select

const SimulationRowCard = styled(Card)`
  cursor: pointer;
  margin-bottom: 10px;
`
const SimulationRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const SimulationRowName = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  span:nth-of-type(1) {
    color: ${({ theme }) => theme.primary};
    font-size: 15.5px;
    font-weight: 500;
  }
  span:nth-of-type(2) {
    color: #cccccc;
    font-size: 12.5px;
    font-weight: 500;
    margin-top: 3px;
  }
`
const SimulationRowPercentages = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`
const SimulationRowRateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  span:nth-of-type(1) {
    color: ${({ eventType, theme }) => theme[`simulation${_capitalize(eventType)}`] || 'inherit'};
    font-size: 24px;
    font-weight: 100;
  }
  span:nth-of-type(2) {
    font-size: 13.3px;
    font-weight: 500;
  }
`
const SimulationRowArrow = styled.div`
  text-align: right;
  width: 15%;
`

const SimulationRow = ({ simulation, onViewClick = () => {} }) => {
  const { id, name, startDate, overall: { open, visit, compromise, total } } = simulation
  const onClick = useCallback(() => onViewClick(id), [onViewClick, id])
  const openRate = total > 0 ? Math.floor((open / total) * 100) : 0
  const visitRate = total > 0 ? Math.floor((visit / total) * 100) : 0
  const compromiseRate = total > 0 ? Math.floor((compromise / total) * 100) : 0

  return (
    <SimulationRowCard onClick={onClick}>
      <SimulationRowContainer>
        <SimulationRowName>
          <span>{name}</span>
          <span>{I18n.t('startedOn', { ...trOpt, date: formatDate(startDate) })}</span>
        </SimulationRowName>
        <SimulationRowPercentages>
          <SimulationRowRateContainer>
            <span>{total}</span>
            <span>{I18n.t('recipients', { ...trOpt, count: total })}</span>
          </SimulationRowRateContainer>
          <SimulationRowRateContainer eventType='open'>
            <span>{I18n.t('common.percentage', { value: openRate })}</span>
            <span>{I18n.t('uPhish.simulations.opened')}</span>
          </SimulationRowRateContainer>
          <SimulationRowRateContainer eventType='visit'>
            <span>{I18n.t('common.percentage', { value: visitRate })}</span>
            <span>{I18n.t('uPhish.simulations.visited')}</span>
          </SimulationRowRateContainer>
          <SimulationRowRateContainer eventType='compromise'>
            <span>{I18n.t('common.percentage', { value: compromiseRate })}</span>
            <span>{I18n.t('uPhish.simulations.compromised')}</span>
          </SimulationRowRateContainer>
        </SimulationRowPercentages>
        <SimulationRowArrow>
          <Icon type='right' />
        </SimulationRowArrow>
      </SimulationRowContainer>
    </SimulationRowCard>
  )
}

const SimulationPerformanceModal = styled(Modal)`
  height: calc(100% - 100px);
  top: 50px;

  .ant-modal-content {
    height: 100%;
  }
`

const SimulationPerformanceModalBody = styled.div`
  height: 100%;
  position: relative;
`
const SimulationPerformanceModalScroller = styled.div`
  height: 100%;
  overflow: auto;
`

const SimulationPerformanceChartTooltip = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  color: inherit;
  font-size: inherit;
  padding: 5px 9px;
`

const SimulationPerformanceChart = ({ data = [], averagePeriod = 'off', simulations = [] }) => {
  const [showModal, updateShowModal] = useState(false)
  const [showView, updateShowView] = useState(false)
  const [showEngagedUsers, updateShowEngagedUsers] = useState(false)
  const [showList, updateShowList] = useState(false)
  const [viewSimulation, updateViewSimulation] = useState(null)
  const [listSimulations, updateListSimulations] = useState([])
  const [listSimulationsTitle, updateListSimulationsTitle] = useState('')
  const useDate = averagePeriod !== 'off'
  const colors = data.map(({ color }) => color)
  const pointColor = e => e.color
  const formatPercentage = value => I18n.t('common.percentage', { value })
  const axisBottomLegend = useDate ? I18n.t('common.fields.date') : ''
  const simulationIdMap = useMemo(() =>
    useDate
      ? {}
      : (
        data.reduce((map, line) => {
          line.data.forEach(point => {
            map[point.x] = point.name
          })
          return map
        }, {})
      ),
  [useDate, data])
  const formatXAxis = useCallback(value => {
    if (useDate) {
      return moment(value).format(DATE_FORMATS[averagePeriod])
    }
    return simulationIdMap[value] || value
  }, [simulationIdMap, useDate, averagePeriod])

  const simulationMap = useMemo(() => simulations.reduce((map, simulation) => ({ ...map, [simulation.id]: simulation }), {}), [simulations])
  const simulationsList = useMemo(() => listSimulations.map(id => simulationMap[id]), [simulationMap, listSimulations])

  const onClick = useCallback(node => {
    const { data: { x: titleDate, simulations = [] } = {} } = node
    if (simulations.length === 1) {
      updateShowView(true)
      updateViewSimulation(simulations[0])
      updateShowModal(true)
    } else if (simulations.length > 0) {
      updateShowList(true)
      updateListSimulations(simulations)
      const prefix = averagePeriod === 'weeks' ? `${moment(titleDate).subtract(6, 'days').format(DATE_FORMATS[averagePeriod])} - ` : ''
      updateListSimulationsTitle(`${prefix}${moment(titleDate).format(DATE_FORMATS[averagePeriod])}`)
      updateShowModal(true)
    }
  }, [updateShowView, updateShowList, averagePeriod])
  const onViewClick = useCallback(id => {
    updateViewSimulation(id)
    updateShowView(true)
  }, [updateViewSimulation, updateShowView])
  const onEngagedUsersClick = useCallback(() => updateShowEngagedUsers(true), [updateShowEngagedUsers])
  const onBackToViewClick = useCallback(() => updateShowEngagedUsers(false), [updateShowEngagedUsers])

  const onBackToListClick = useCallback(() => updateShowView(false), [updateShowView])

  const closeModal = useCallback(() => updateShowModal(false), [updateShowModal])
  const afterClose = useCallback(() => {
    updateShowView(false)
    updateShowList(false)
    updateShowEngagedUsers(false)
    updateListSimulations([])
    updateViewSimulation(null)
    updateListSimulationsTitle('')
  }, [updateShowList, updateShowView, updateShowEngagedUsers, updateListSimulations, updateViewSimulation])

  const tooltip = useCallback(({ point }) => {
    const { data: { name, x: titleDate, y: value, simulations: { length: simCount } }, serieId: eventType } = point
    const label = useDate ? moment(titleDate).format(DATE_FORMATS[averagePeriod]) : name
    return (
      <SimulationPerformanceChartTooltip>
        <span>{I18n.t(`tooltip.${eventType}`, { ...trOpt, label, value: Math.floor(value) })}</span>
        {useDate && <div>{I18n.t('tooltip.simulationCount', { ...trOpt, count: simCount })}</div>}
      </SimulationPerformanceChartTooltip>
    )
  }, [averagePeriod, useDate])

  return (
    <>
      <SimulationPerformanceModal
        width='90%'
        bodyStyle={{ paddingTop: 45, height: '100%' }}
        visible={showModal}
        onOk={closeModal}
        onCancel={closeModal}
        afterClose={afterClose}
        footer={null}
        destroyOnClose
      >
        <SimulationPerformanceModalBody>
          <SimulationPerformanceModalScroller>
            {
              showView
                ? (
                  showEngagedUsers
                    ? <ViewSimulationUsers id={viewSimulation} modal onSimulationClick={onBackToViewClick} />
                    : <ViewSimulation id={viewSimulation} modal onEngagedUsersClick={onEngagedUsersClick} showSimulationsLink={showList} onSimulationsClick={onBackToListClick} />
                )
                : (
                  <Card>
                    <h1>{listSimulationsTitle ? I18n.t('simulationsHeadingWithTitle', { ...trOpt, listSimulationsTitle }) : I18n.t('simulations', { ...trOpt })}</h1>
                    {
                      simulationsList.map((simulation, index) => <SimulationRow key={index} {...{ simulation }} onViewClick={onViewClick} />)
                    }
                  </Card>
                )
            }
          </SimulationPerformanceModalScroller>
        </SimulationPerformanceModalBody>
      </SimulationPerformanceModal>
      <LineChart
        data={data}
        margin={{ top: 50, right: useDate ? 10 : 110, bottom: useDate ? 55 : 150, left: 60 }}
        xScale={{ type: 'point' }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: useDate ? 10 : 5,
          tickRotation: useDate ? 0 : -45,
          legend: axisBottomLegend,
          legendOffset: 41,
          legendPosition: 'middle',
          format: formatXAxis
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: I18n.t('interactionRate', trOpt),
          legendOffset: -45,
          legendPosition: 'middle',
          format: formatPercentage
        }}
        colors={colors}
        pointColor={pointColor}
        onClick={onClick}
        tooltip={tooltip}
      />
    </>
  )
}

const SimulationPerformanceFilters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  &> * {
    margin-left: 10px;
  }
`
const SimulationPerformanceGroupsFilter = styled(TreeSelect)`
  width: 300px;
`
const SimulationPerformanceTemplateFilter = styled(Select)`
  width: 175px;
`

const SimulationPerformanceChartContainer = styled.div`
  height: 500px;
  position: relative;
`
const SimulationPerformanceChartNoData = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 100;
`

const SimulationPerformanceToggleRow = styled.div`
  display: flex;
  justify-content: space-between;
`
const MetricToggles = styled.div`
  &> * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`
const MetricToggleComponent = ({ active, eventType = '', update, children, className }) => {
  const onClick = useCallback(() => update(eventType), [eventType, update])
  return (
    <Button className={className} onClick={onClick}>{children || I18n.t(eventType, trOpt)}</Button>
  )
}
const MetricToggle = styled(MetricToggleComponent)`
${({ active = false, eventType = '', theme }) => active
    ? css`
      color: white;
      background-color: ${theme[`simulation${_capitalize(eventType)}`]};
      :hover, :focus, :active, .ant-btn.active {
        color: white;
        background-color: ${lighten(0.1, theme[`simulation${_capitalize(eventType)}`])};
      }
    `
    : css``
} 
`

const getGroupDescendants = (groupId, groups, found = false) => {
  return groups.reduce((ids, group) => {
    if (found || group.id === groupId) {
      if (group.id !== groupId) {
        ids.push(group.id)
      }
      ids.push(...getGroupDescendants(groupId, group.children, true))
    }
    return ids
  }, [])
}

const SimulationPerformance = ({ groupTree = [], simulations = [], emailTemplates = [], landingPages = [], theme, phishAlertEnabled, phishAlert }) => {
  const [landingPage, updateLandingPage] = useState('any')
  const [emailTemplate, updateEmailTemplate] = useState('any')
  const [group, updateGroup] = useState('overall')
  const [metricsShown, updateMetricsShown] = useState({
    open: true,
    visit: true,
    compromise: true,
    report: true
  })
  const [averagePeriod, updateAveragePeriod] = useState('off')

  const showPhishAlert = phishAlertEnabled === true && phishAlert?.enabled === true

  const landingPageOptions = useMemo(() => {
    const usedLandingPageIds = simulations.map(({ landingPageId }) => landingPageId)
    const usedLandingPages = landingPages.filter(({ id }) => usedLandingPageIds.includes(id))
    return [
      { id: 'any', name: I18n.t('any', trOpt) },
      ...usedLandingPages
    ].map(({ id, name }) => (<Option key={id} value={id}>{name}</Option>))
  }, [landingPages, simulations])
  const onLandingPageChange = useCallback((value = 'any') => updateLandingPage(value), [])

  const emailTemplateOptions = useMemo(() => {
    const usedEmailTemplateIds = simulations.map(({ emailTemplateId }) => emailTemplateId)
    const usedEmailTemplates = emailTemplates.filter(({ id }) => usedEmailTemplateIds.includes(id))
    if (simulations.some(({ emailTemplateId }) => emailTemplateId === null)) {
      usedEmailTemplates.push({ id: 'freeform', name: I18n.t('freeform', trOpt) })
    }
    return [
      { id: 'any', name: I18n.t('any', trOpt) },
      ...usedEmailTemplates
    ].map(({ id, name }) => (<Option key={id} value={id}>{name}</Option>))
  }, [emailTemplates, simulations])
  const onEmailTemplateChange = useCallback((value = 'any') => updateEmailTemplate(value), [])

  const groupTreeData = useMemo(() => [
    {
      title: I18n.t('common.all'), key: 'overall', value: 'overall'
    },
    ...buildTree(groupTree)
  ], [groupTree])

  const onGroupChange = useCallback((value = 'overall') => updateGroup(value), [])

  const toggleMetric = useCallback(eventType => updateMetricsShown({ ...metricsShown, [eventType]: !metricsShown[eventType] }), [metricsShown])

  const onAveragePeriodChange = useCallback(e => updateAveragePeriod(e.target.value), [])

  // Filter group and its descendants
  const selectedGroups = useMemo(() => {
    return group && group !== 'overall'
      ? [
        group,
        ...getGroupDescendants(group, groupTree)
      ]
      : []
  }, [group, groupTree])

  const filteredSimulations = useMemo(() =>
    simulations.filter(simulation => {
      const { landingPageId, emailTemplateId, byGroup } = simulation
      return (landingPage === 'any' || landingPageId === landingPage) &&
      (emailTemplate === 'any' || emailTemplateId === (emailTemplate === 'freeform' ? null : emailTemplate)) &&
      (selectedGroups.length > 0 ? selectedGroups.some(group => byGroup[group]) : true)
    }),
  [simulations, landingPage, emailTemplate, selectedGroups])

  const { eventTypes, baseData } = useMemo(() =>
    Object.keys(metricsShown).reduce((obj, eventType) => {
      if (metricsShown[eventType] && !(eventType === 'report' && !showPhishAlert)) {
        obj.eventTypes.push(eventType)
        obj.baseData.push({
          id: eventType,
          color: theme[`simulation${_capitalize(eventType)}`],
          data: []
        })
      }
      return obj
    }, {
      eventTypes: [],
      baseData: []
    }),
  [metricsShown, theme, showPhishAlert])

  const dataPresent = baseData.length > 0 && filteredSimulations.length > 0

  // Only show average periods relevant to the overall simulation period i.e. start of first sim to start of last sim
  const periodButtons = useMemo(() => {
    let allowedPeriods = []
    if (filteredSimulations.length > 2) {
      const startDate = filteredSimulations[0].startDate
      const lastDate = filteredSimulations[filteredSimulations.length - 1].startDate

      allowedPeriods = FILTER_PERIODS.filter(period => {
        const diff = moment(lastDate).diff(startDate, period)
        return diff > 1
      })
    }
    if (!dataPresent || allowedPeriods.length === 0) {
      updateAveragePeriod('off')
    }

    return [
      <Radio.Button key='off' value='off' disabled={!dataPresent}>{I18n.t('common.off')}</Radio.Button>,
      FILTER_PERIODS.map(period => (
        <Radio.Button
          key={period} value={period}
          disabled={!(dataPresent && allowedPeriods.includes(period))}
        >
          {_capitalize(I18n.t(period, { scope: 'common.duration.plural' }))}
        </Radio.Button>
      ))
    ]
  }, [filteredSimulations, dataPresent])

  const data = useMemo(() => {
    if (filteredSimulations.length === 0) {
      return []
    }

    if (averagePeriod !== 'off') {
      let { startDate } = filteredSimulations[0]
      startDate = moment(startDate)
      // Shift startDate to the start of the selected period
      const setSpec = { hour: 0, minute: 0 }
      const periodIndex = FILTER_PERIODS.indexOf(averagePeriod)
      if (periodIndex >= 1) {
        setSpec.day = startDate.day() === 0 ? -6 : 1
      }
      if (periodIndex >= 2) {
        setSpec.date = 1
      }
      if (averagePeriod === 'years') {
        setSpec.month = 0
      }
      startDate.set(setSpec)

      const groupByInterval = _groupBy(filteredSimulations, ({ startDate: simStartDate }) => moment(simStartDate).diff(startDate, averagePeriod))
      // Only include points where there are simulations
      const groupedSimulations = Object.keys(groupByInterval).map(tick => {
        const labelDate = moment(startDate).add(tick, averagePeriod)
        // Use week ending to match chart formatting
        if (averagePeriod === 'weeks') {
          labelDate.day(7)
        }
        return {
          label: labelDate.toISOString(),
          simulations: groupByInterval[tick] || []
        }
      })

      return groupedSimulations.reduce((lines, simGroup) => {
        const { label, simulations } = simGroup
        const eventCounts = simulations.reduce((counts, simulation) => {
          const { id, overall } = simulation
          eventTypes.forEach(eventType => {
            counts[eventType] += overall[eventType]
          })
          counts.total += overall.total
          counts.simulationIds.push(id)

          return counts
        }, {
          open: 0,
          visit: 0,
          compromise: 0,
          report: 0,
          total: 0,
          simulationIds: []
        })
        if (!showPhishAlert) {
          delete eventCounts.report
        }

        const { simulationIds, total } = eventCounts
        eventTypes.forEach((eventType, index) =>
          lines[index].data.push({
            simulations: simulationIds,
            x: label,
            y: total > 0 ? (eventCounts[eventType] / total) * 100 : 0
          })
        )

        return lines
      }, _cloneDeep(baseData))
    }

    return filteredSimulations
      .reduce((lines, simulation) => {
        const { id, name, byGroup, overall } = simulation

        let eventCounts = overall
        if (selectedGroups.length > 0) {
          eventCounts = selectedGroups.reduce((counts, group) => {
            if (byGroup[group]) {
              eventTypes.forEach(eventType => {
                counts[eventType] += byGroup[group][eventType]
              })
              counts.total += byGroup[group].total
            }
            return counts
          }, {
            open: 0,
            visit: 0,
            compromise: 0,
            report: 0,
            total: 0
          })
        }

        const { total } = eventCounts
        eventTypes.forEach((eventType, index) =>
          lines[index].data.push({
            simulations: [id],
            name,
            x: id,
            y: total > 0 ? (eventCounts[eventType] / total) * 100 : 0
          })
        )

        return lines
      }, _cloneDeep(baseData))
  }, [filteredSimulations, selectedGroups, eventTypes, baseData, averagePeriod, showPhishAlert])

  return (
    <>
      <SimulationPerformanceFilters>
        <div>
          <div>{I18n.t('uPhish.common.landingPage')}</div>
          <SimulationPerformanceTemplateFilter value={landingPage} onChange={onLandingPageChange} allowClear>
            {landingPageOptions}
          </SimulationPerformanceTemplateFilter>
        </div>
        <div>
          <div>{I18n.t('uPhish.common.emailTemplate')}</div>
          <SimulationPerformanceTemplateFilter value={emailTemplate} onChange={onEmailTemplateChange} allowClear>
            {emailTemplateOptions}
          </SimulationPerformanceTemplateFilter>
        </div>
        <div>
          <div>{I18n.t('common.groups')}</div>
          <SimulationPerformanceGroupsFilter
            treeData={groupTreeData}
            showSearch
            value={group}
            defaultValue='overall'
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            allowClear
            treeNodeFilterProp='title'
            onChange={onGroupChange}
          />
        </div>
      </SimulationPerformanceFilters>
      <SimulationPerformanceChartContainer>
        {!dataPresent &&
          <SimulationPerformanceChartNoData>
            <h4>{I18n.t('youDoNotHaveEnoughData', trOpt)}</h4>
          </SimulationPerformanceChartNoData>}
        <SimulationPerformanceChart
          {...{ data, averagePeriod }}
          simulations={filteredSimulations}
        />
      </SimulationPerformanceChartContainer>
      <SimulationPerformanceToggleRow>
        <div>
          <div>{I18n.t('metricsShown', trOpt)}</div>
          <MetricToggles>
            <MetricToggle active={metricsShown.open} update={toggleMetric} eventType='open'>{I18n.t('common.opened')}</MetricToggle>
            <MetricToggle active={metricsShown.visit} update={toggleMetric} eventType='visit'>{I18n.t('common.visited')}</MetricToggle>
            <MetricToggle active={metricsShown.compromise} update={toggleMetric} eventType='compromise'>{I18n.t('common.compromised')}</MetricToggle>
            {showPhishAlert && <MetricToggle active={metricsShown.report} update={toggleMetric} eventType='report'>{I18n.t('uPhish.simulations.reported')}</MetricToggle>}
          </MetricToggles>
        </div>
        <div>
          <div>{I18n.t('averageOverTime', trOpt)}</div>
          <Radio.Group value={averagePeriod} onChange={onAveragePeriodChange} buttonStyle='solid'>
            {periodButtons}
          </Radio.Group>
        </div>
      </SimulationPerformanceToggleRow>
    </>
  )
}

const SimulationPerformanceWithTheme = withTheme(SimulationPerformance)

const SimulationPerformanceQuery = ({ phishAlertEnabled, phishAlert }) => {
  const [landingError, setLandingError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [reportError, setReportError] = useState(null)

  return (
    <Query query={GET_SIMULATION_LANDING_PAGE_TEMPLATES} onError={setLandingError}>
      {
        ({ loading: landingLoading, data: { simulationLandingPages: landingPages = [] } = {} }) => (
          <Query query={GET_SIMULATION_EMAIL_TEMPLATES} onError={setEmailError}>
            {
              ({ loading: emailLoading, data: { simulationEmailTemplates: emailTemplates = [] } = {} }) => (
                <Query query={SIMULATION_PERFORMANCE} onError={setReportError}>
                  {
                    ({ loading: reportLoading, data: { simulationPerformance: data = {} } = {} }) => {
                      const loading = (reportLoading || landingLoading || emailLoading)
                      const error = (reportError || landingError || emailError)
                      return (
                        <Card>
                          <IntercomHeader Size='h1' id='reports-simulation-performance-header'>{I18n.t('common.uPhish')} - {I18n.t('title', trOpt)}</IntercomHeader>
                          {loading && <LoadingBlock fullScreen loading={loading} />}
                          {error && <ErrorAlerts error={[reportError, landingError, emailError]} defaultError={I18n.t('reports.loadError')} />}
                          {!loading && !error && <SimulationPerformanceWithTheme {...data} {...{ landingPages, emailTemplates, phishAlert, phishAlertEnabled }} />}
                        </Card>
                      )
                    }
                  }
                </Query>
              )
            }
          </Query>
        )
      }
    </Query>
  )
}

export default connect(
  state => _pick(selectors.settings.get(state), ['phishAlertEnabled', 'phishAlert'])
)(SimulationPerformanceQuery)
