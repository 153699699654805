import React, { useCallback, useImperativeHandle, useState, useMemo, useEffect } from 'react'
import { Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import I18n from 'i18n-js'

import LearnersView from '../Learners/LearnersView'
import { GET_DELETED_LEARNERS } from '../Queries/Learners'
import { withConsumer } from '../../hocs/withConsumer'
import { LoadingBlock } from '../common'
import { dateTimeColumnSorter } from '../../helpers/listPages'
import { formatDateTime } from '../../helpers/datetime'

const trOpt = { scope: 'modals.restoreUsersModal' }

const RestoreModal = styled(Modal)`
  height: calc(100% - 60px);
  max-width: 1100px;
  top: 50px;
`

const RESTORE_USERS_ACTIONS = ['restoreUsers']

const RestoreUsersModal = React.forwardRef(({ client }, ref) => {
  const [visible, setVisible] = useState(false)
  const [learners, setLearners] = useState([])
  const [searchFilterText, updateSearchFilterText] = useState('')

  const { loading, data } = useQuery(GET_DELETED_LEARNERS, { notifyOnNetworkStatusChange: true })
  useEffect(() => {
    if (data) {
      setLearners(data.deletedLearners)
    }
  }, [data])

  useImperativeHandle(ref, () => ({
    open: async () => {
      setVisible(true)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
  }

  const columns = useMemo(() => {
    return [{
      get title () { return I18n.t('common.fields.name') },
      dataIndex: 'name',
      key: 'learnerName',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      }
    }, {
      get title () { return I18n.t('learners.common.emailUserId') },
      dataIndex: 'email',
      key: 'email'
    }, {
      title: I18n.t('deletedAt', trOpt),
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (deletedAt) => formatDateTime(deletedAt, { includeSeconds: true }),
      sorter: (a, b) => dateTimeColumnSorter('deletedAt', a, b),
      defaultSortOrder: 'descend'
    }]
  }, [])

  const title = I18n.t('title', trOpt)

  return (
    <RestoreModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={title}
      afterClose={afterClose}
      width='90%'
      bodyStyle={{ height: 'calc(100% - 50px)' }}
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <LearnersView
        showGroups={false}
        panelRight={['search', 'actions']}
        panelLeft={[]}
        size='small'
        renderSingleActionAsButton
        {...{
          learners,
          columns,
          searchFilterText,
          updateSearchFilterText
        }}
        allowedActions={RESTORE_USERS_ACTIONS}
      />
    </RestoreModal>
  )
})

export default withConsumer({ useHooks: true })(RestoreUsersModal)
