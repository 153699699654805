/* global localStorage */
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Card } from 'antd'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _cloneDeep from 'lodash/cloneDeep'
import I18n from 'i18n-js'
import { NetworkStatus } from 'apollo-client'

import { RiskScoreIndicator } from '../components/Learners'
import LearnersView from '../components/Learners/LearnersView'
import RestoreUsersModal from '../components/Modals/RestoreUsersModal'

import { RISK_SCORE_LEVELS } from '../constants/riskScore'
import { permissions } from '../constants/permissions'
import { LONG_QUERY_POLL_INTERVAL, QUERY_POLL_INTERVAL } from '../constants/apolloClient'
import { GET_LEARNERS } from '../components/Queries/Learners'
import { ContentWrap, ListHeader, ListHeaderPanel } from '../components/common'
import { connect } from '../hocs'
import selectors from '../state/selectors'
import { defaultLearnerTableColumns } from '../components/Learners/LearnersTable'
import ReportDownloadDropdown from '../components/Reports/ReportDownloadDropdown'
import useColumnSearch from '../hooks/useColumnSearch'
import { useHasSessionPermission } from '../hooks'
import IntercomHeader from '../components/IntercomHeader'

const trOpt = { scope: 'learners' }

const RISK_SCORE_NONE = '<NONE>'
const noRiskScoreFilter = {
  value: RISK_SCORE_NONE,
  get text () { return I18n.t('noRiskScore', trOpt) }
}
const riskScoreLevelFilters = [
  ...RISK_SCORE_LEVELS.map(value => ({
    value,
    text: (<RiskScoreIndicator value={value} />)
  })).reverse(),
  noRiskScoreFilter
]

const Learners = ({ userId, companyId, enableRiskScore = false, history, match }) => {
  const [checkedGroups, updateCheckedGroups] = useState([])
  const [status, updateStatus] = useState('all')
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const managerSearchOptions = useColumnSearch('manager.name', I18n.t('common.managers', trOpt), filters, ['manager.email', 'manager.learnerId'])

  const storageId = useMemo(() => `learners|${companyId}|${userId}`, [userId, companyId])
  useEffect(() => {
    try {
      const learnersFilterString = localStorage.getItem(storageId)
      if (learnersFilterString) {
        const learnersFilter = JSON.parse(learnersFilterString)
        Object.keys(learnersFilter).forEach(key => {
          const value = learnersFilter[key]
          switch (key) {
            case 'checkedGroups':
              updateCheckedGroups(value)
              break
            case 'status':
              updateStatus(value)
              break
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'filters':
              updateFilters(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId, updateCheckedGroups, updateStatus, updatePagination, updateSorter, updateFilters])

  const updateLocalStorage = useCallback((data = {}) => {
    let learnersFilter = {}
    try {
      const learnersFilterString = localStorage.getItem(storageId)
      if (learnersFilterString) {
        learnersFilter = JSON.parse(learnersFilterString)
      }
      learnersFilter = { ...learnersFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(learnersFilter))
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      status,
      checkedGroups,
      searchFilterText,
      pagination,
      sorter,
      filters
    })
  }, [updateLocalStorage, status, checkedGroups, searchFilterText, pagination, sorter, filters])

  const variables = useMemo(() => ({
    groupIds: checkedGroups.length > 0 ? checkedGroups : null,
    inactive: status === 'all' ? null : status === 'inactive',
    isManager: status === 'managers' ? true : null,
    isLineManager: status === 'lineManagers' ? true : null,
    withRiskScore: enableRiskScore
  }), [checkedGroups, status, enableRiskScore])

  const [pollingEnabled, setPollingEnabled] = useState(true)
  const [queryPollInterval, setQueryPollInterval] = useState(QUERY_POLL_INTERVAL)
  const { loading: queryLoading, error, refetch, networkStatus, data } = useQuery(GET_LEARNERS, {
    variables,
    pollInterval: pollingEnabled ? queryPollInterval : 0,
    notifyOnNetworkStatusChange: true
  })
  const loading = queryLoading && networkStatus !== NetworkStatus.poll
  useEffect(() => {
    setQueryPollInterval(data?.learners?.length >= 10000 ? LONG_QUERY_POLL_INTERVAL : QUERY_POLL_INTERVAL)
  }, [data])

  const { learners = [] } = data || {}
  const groupTreeRef = useRef(null)
  const handleRefreshClick = useCallback(() => {
    refetch()
    if (groupTreeRef.current) {
      groupTreeRef.current.refetch()
    }
  }, [refetch, groupTreeRef])

  const restoreUsersModalRef = useRef(null)
  const onRestoreUsersClick = useCallback(() => {
    restoreUsersModalRef.current.open()
  }, [restoreUsersModalRef])

  const columns = useMemo(() => {
    const columns = _cloneDeep(defaultLearnerTableColumns)
    columns.push({
      title: I18n.t('common.manager', trOpt),
      key: 'manager.name',
      dataIndex: 'manager.name',
      sorter: (a, b) => (a.manager.name || '').localeCompare(b.manager.name || ''),
      ...managerSearchOptions
    })
    if (enableRiskScore) {
      columns.push({
        title: I18n.t('common.riskScore'),
        dataIndex: 'riskScoreLevel',
        key: 'riskScoreLevel',
        // Ascending order is very low, low, medium, high, very high, null
        sorter: (a, b) => {
          const aIndex = a.riskScoreLevel ? RISK_SCORE_LEVELS.indexOf(a.riskScoreLevel) : -1
          const bIndex = b.riskScoreLevel ? RISK_SCORE_LEVELS.indexOf(b.riskScoreLevel) : -1
          return (aIndex - bIndex) * -1
        },
        filters: riskScoreLevelFilters,
        onFilter: (value, record) => value === RISK_SCORE_NONE ? record.riskScoreLevel === null : record.riskScoreLevel === value,
        render (riskScoreLevel) {
          return (
            <RiskScoreIndicator value={riskScoreLevel} />
          )
        }
      })
    }

    return columns
  }, [enableRiskScore, managerSearchOptions])

  const reportDownloads = []
  if (hasAllSessionPermissions([permissions.GROUP_REPORT])) {
    reportDownloads.push({
      label: I18n.t('exportGroupManagers', trOpt),
      fileName: 'group-managers-report.xlsx',
      jobData: { reportType: 'groupManagersReport' },
      fileType: 'xlsx'
    })
  }
  if (hasAllSessionPermissions([permissions.LEARNER_REPORT])) {
    reportDownloads.push({
      label: I18n.t('exportLearners', trOpt),
      fileName: 'users-report.xlsx',
      jobData: { reportType: 'learnersReport' },
      fileType: 'xlsx'
    })
  }

  return (
    <ContentWrap>
      <Card>
        <ListHeader>
          <ListHeaderPanel align='left'>
            <IntercomHeader Size='h1' style={{ margin: 0 }} id='learners-header'>{I18n.t('common.users')}</IntercomHeader>
          </ListHeaderPanel>
          <ListHeaderPanel align='right'>
            <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
            {hasAllSessionPermissions([permissions.LEARNER_RESTORE]) && <Button onClick={onRestoreUsersClick}>{I18n.t('modals.restoreUsersModal.title')}</Button>}
            <ReportDownloadDropdown reports={reportDownloads} />
          </ListHeaderPanel>
        </ListHeader>
        <LearnersView
          useModalRouting
          {...{
            loading,
            error,
            learners,
            checkedGroups,
            updateCheckedGroups,
            status,
            updateStatus,
            searchFilterText,
            updateSearchFilterText,
            pagination,
            updatePagination,
            sorter,
            updateSorter,
            filters,
            updateFilters,
            columns,
            groupTreeRef,
            history,
            setPollingEnabled
          }}
          routeId={_get(match, 'params.learnerId')}
        />
      </Card>
      <RestoreUsersModal ref={restoreUsersModalRef} />
    </ContentWrap>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['userId', 'companyId']),
    ..._pick(selectors.settings.get(state), ['enableRiskScore'])
  })
)(Learners)
