import _omit from 'lodash/omit'

const generateCacheKeyQueryPattern = queryNames => {
  const queryNamesOr = queryNames.join('|')
  // Matches each query name to cache keys with or without arguments i.e. queryName(...) or queryName
  return new RegExp(`(^(${queryNamesOr})\\(.*\\)$)|(^(${queryNamesOr})$)`)
}

export const invalidateLearnersQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['learners']))
export const invalidateCompaniesQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['companies']))
export const invalidateLearnersAndLearnerDataQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['learners', 'learnerData']))
export const invalidatePoliciesQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['policies', 'policyTemplates', 'policy', 'policyTemplatesByCategory']))
export const invalidateGroupsQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['groups', 'groupTree']))
export const invalidateLearnersAndGroupsQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['learners', 'groups', 'groupTree']))
export const invalidateLandingPageQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['simulationLandingPages', 'landingPageCategories']))
export const invalidateEmailTemplateQueryCache = cache => invalidateQueryCache(cache, generateCacheKeyQueryPattern(['simulationEmailTemplates']))

export const getCacheKeys = (cache, rootQuery) => {
  rootQuery = rootQuery || cache.data.get('ROOT_QUERY')
  // Filter keys by regex for target query/queries
  return Object.keys(rootQuery)
}

// Utility for obtaining cache keys for debug purposes e.g. logging out cache keys to write a new invalidate regex
export const logCacheKeys = cache => console.log('CACHE KEYS', getCacheKeys(cache))

const invalidateQueryCache = (cache, queryPattern) => {
  // Remove target query cache entries
  // This solution is a bit of a hack as it isn't a documented part of the apollo-cache-inmemory API
  // cache.data is an instance of their DepTrackingCache and could be broken by an update in the future
  const rootQuery = cache.data.get('ROOT_QUERY')
  // Filter keys by regex for target query/queries
  const targetKeys = getCacheKeys(cache, rootQuery).filter(key => queryPattern.test(key))
  if (targetKeys.length > 0) {
    // lodash omit filtered keys to create new ROOT_QUERY
    const updatedRootQuery = _omit(rootQuery, targetKeys)
    // Call cache.data.set with update
    cache.data.set('ROOT_QUERY', updatedRootQuery)
  }
}

export default invalidateQueryCache
