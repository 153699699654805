import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import compose from 'recompose/compose'
import { connect, withProvider } from '../hocs'
import { sessionInitialised } from '../state/selectors/session'

import OutstandingActivitiesHome from './OutstandingActivities/Home'
import SignIn from './EndUserPortal/Signin'
import Main from './EndUserPortal/Main'
import MagicLinkRedirect from './EndUserPortal/MagicLinkRedirect'
import OutstandingActivitiesLogin from './OutstandingActivities/Login'
import endUserClient from '../apollo-client/endUserClient'
import routes from '../constants/routes'
import EndUserProtectedRoute from '../components/EndUserProtectedRoute'
import OutstandingActivitiesProtectedRoute from '../components/OutstandingActivitiesProtectedRoute'
import EndUserSessionDispatcher from '../components/EndUserSessionDispatcher'
import EndUserSAMLResponse from './EndUserPortal/EndUserSAMLResponse'

const END_USER_PORTAL = window.__USECURE_CONFIG__.REACT_APP_END_USER_PORTAL === 'true'

const EndUserSessionSwitcher = ({ initialised }) => {
  return (
    <>
      <EndUserSessionDispatcher />
      {initialised && (
        <Switch>
          {END_USER_PORTAL && (
            <EndUserProtectedRoute
              exact
              path={routes.PORTAL_MAIN}
              component={Main}
              fallbackComponent={SignIn}
            />
          )}
          <Route
            exact
            path={routes.OUTSTANDING_ACTIVITIES_LOGIN}
            component={OutstandingActivitiesLogin}
          />
          <OutstandingActivitiesProtectedRoute
            exact
            path={routes.OUTSTANDING_ACTIVITIES}
            component={OutstandingActivitiesHome}
          />
          {END_USER_PORTAL && (
            <Route
              exact
              path={routes.PORTAL_MAGIC_REDIRECT}
              component={MagicLinkRedirect}
            />
          )}
          {END_USER_PORTAL && (
            <Route
              exact
              path={routes.SAML_END_USER_AUTH_RESPONSE}
              component={EndUserSAMLResponse}
            />
          )}
          {END_USER_PORTAL ? <Route component={SignIn} /> : <Redirect push to={routes.HOME} />}
        </Switch>
      )}
    </>
  )
}

export default compose(
  connect(
    sessionInitialised
  ),
  withProvider(endUserClient)
)(EndUserSessionSwitcher)
