import React from 'react'
import styled from 'styled-components'

export const ContentScroller = styled.div`
  height: 100%;
  overflow: ${({ disableScrolling }) => disableScrolling ? 'hidden' : 'auto'};
  position: ${({ position = 'static' }) => position};
`

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 1rem 50px 5.809rem;
  @media (max-width: 800px) {
    padding: 0.5rem 15px 2.809rem;
  }
  &.full-height {
    height: 100%;
  }
  @media print {
    padding: 0;
  }
`

export const ContentWrap = ({ position, disableScrolling, children, className, scrollerComponent: Scroller = ContentScroller }) => (
  <Scroller {...{ position, disableScrolling }}>
    <ContentWrapper className={className}>{children}</ContentWrapper>
  </Scroller>
)
