import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { Statistic, Button } from 'antd'

import { connect } from '../../hocs'
import { getSessionAndSettings } from '../../state/selectors'
import { dateTimeColumnSorter, idArrayColumnSorter } from '../../helpers/listPages'
import { GET_BREACH_DASHBOARD } from '../../components/Queries/EndUser'
import ErrorAlerts from '../../components/common/ErrorAlerts'
import { Row, Column, RoundedCard } from '../../components/EndUserPortal/GridFlexiLayout'
import { ContentWrap } from '../../components/common/Layout'
import { LoadingBlock } from '../../components/common'
import EndUserTable from '../../components/EndUserPortal/Table'
import { useWindowSize } from '../../hooks/useWindowSize'
import { SearchBar } from '../../components/common/List'
import { formatDate } from '../../helpers/datetime'
import { severityFilter, calculateAverageSeverity, dataClassFilter } from '../../helpers/uBreachV2'
import SeverityField from '../../components/uBreach/SeverityField'
import { SEVERITY_OPTIONS } from '../../constants/uBreach'
import { renderDataClasses } from '../../components/uBreach/BreachesTable'
const trOpt = { scope: 'endUserPortal.breachDashboard' }
const trOptCommon = { scope: 'endUserPortal.common' }
const columnTrOpt = { scope: 'endUserPortal.breachDashboard.tableColumn' }

const cardTemplate = {
  title: {
    key: 'name'
  },
  body: [
    {
      type: 'text',
      key: 'category',
      title: I18n.t('severity', columnTrOpt)
    }, {
      type: 'text',
      key: 'breachedOn',
      title: I18n.t('breachDate', trOpt)
    }, {
      type: 'tag',
      key: 'dataClass',
      trOpt: { scope: 'uBreach.dataClasses' }
    }
  ]
}
const DataIteratorActions = ({ action, record }) => {
  return <Button key={record.id} onClick={action} icon='eye'>{I18n.t('viewBreach', trOpt)}</Button>
}

const BreachDashboard = ({ session, settings }) => {
  const { data, error, loading } = useQuery(GET_BREACH_DASHBOARD)
  const { type: screenSizeType } = useWindowSize()
  const isMobile = ['xs', 'sm'].includes(screenSizeType)
  const [learnerName, setLearnerName] = useState(null)
  const [rawBreaches, setRawBreaches] = useState([])
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState({})
  const [filters, updateFilters] = useState({})

  const formattedBreaches = useMemo(() => {
    return rawBreaches.map((breach) => {
      const { breachDate, addedDate, modifiedDate, resolved, name } = breach
      return {
        ...breach,
        breachDate,
        breachDateStr: breachDate ? formatDate(breachDate) : '',
        addedDate,
        addedDateStr: addedDate ? formatDate(addedDate) : '',
        modifiedDate,
        modifiedDateStr: modifiedDate ? formatDate(modifiedDate) : '',
        resolved,
        resolvedStr: resolved ? formatDate(resolved) : '',
        severity: breach.category, // assigning category with the key severity
        id: name
      }
    })
  }, [rawBreaches])

  const learnerId = _get(session, 'learnerId')

  useEffect(() => {
    if (data && !_isEmpty(data)) {
      setLearnerName(_get(data, 'learnerMe.endUser.name'))
      setRawBreaches(_get(data, 'learnerMe.endUser.breaches'))
    }
  }, [data])

  const columns = useMemo(() => {
    const columns = [
      {
        // CC: Name ambiguous EU should see it as breach
        title: I18n.t('name', columnTrOpt),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sorter.columnKey === 'name' && sorter.order,
        filteredValue: _get(filters, 'name') || null
      },
      {
        title: I18n.t('breachedOn', columnTrOpt),
        dataIndex: 'breachDateStr',
        key: 'breachDateStr',
        sorter: (a, b) => dateTimeColumnSorter('breachDate', a, b),
        sortOrder: sorter.columnKey === 'breachDateStr' && sorter.order,
        filteredValue: _get(filters, 'breachDateStr') || null
      },
      {
        title: I18n.t('severity', columnTrOpt),
        dataIndex: 'severity',
        key: 'severity',
        sorter: (a, b) => idArrayColumnSorter('severity', SEVERITY_OPTIONS, a, b),
        render: value => <SeverityField severity={value} />,
        filters: severityFilter(formattedBreaches),
        filteredValue: _get(filters, 'severity') || null,
        filterMultiple: true,
        onFilter: (value, record) => record.severity === value
      },
      {
        title: I18n.t('exposedInformation', columnTrOpt),
        dataIndex: 'dataClasses',
        key: 'dataClasses',
        render: value => renderDataClasses(value),
        filters: dataClassFilter(formattedBreaches),
        filterMultiple: true,
        onFilter: (value, record) => record.dataClasses.includes(value),
        filteredValue: _get(filters, 'dataClasses') || null
      }
    ]
    return columns
  }, [sorter, filters, formattedBreaches])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const breachStats = useMemo(() => {
    const { color, label: averageSeverity } = calculateAverageSeverity(formattedBreaches)
    return [
      {
        title: I18n.t('numberOfBreaches', trOpt),
        value: formattedBreaches.length
      },
      {
        title: I18n.t('averageSeverity', trOpt),
        value: !_isEmpty(formattedBreaches) ? averageSeverity : '- - ',
        colour: color
      }
    ]
  }, [formattedBreaches])

  return (
    <>
      <LoadingBlock loading={loading} fullScreen={false} showMenu />
      {error && <ContentWrap><ErrorAlerts error={error} defaultError={I18n.t('defaultPageError', trOptCommon)} /></ContentWrap>}
      {data && !_isEmpty(data) &&
        <ContentWrap>
          <Column>
            <Row>
              {breachStats.map(({ title, value, colour = null }, index) => (
                <div key={index} style={{ flex: '1 1 auto' }}>
                  <RoundedCard>
                    <Statistic title={title} value={value} valueStyle={{ ...colour ? { color: colour } : {} }} />
                  </RoundedCard>
                </div>
              ))}
            </Row>
            <RoundedCard>
              <Row className='justify padded-bottom' justify='space-between'>
                <h1>{I18n.t('breaches', trOpt)}</h1>
                <Row className='justify' justify={isMobile ? 'flex-end' : 'stretch'} style={isMobile ? { width: '100%' } : {}}>
                  {/* Todo: add onClick to download breaches */}
                  {/* <Button block={isMobile} color={theme.primary}>{I18n.t('downloadBreaches', trOpt)}</Button> */}
                  <div style={isMobile ? { width: '100%' } : {}}>
                    <SearchBar
                      placeholder={I18n.t('searchBreaches', trOpt)}
                      value={searchFilterText}
                      allowClear
                      onChange={onSearchChange}
                    />
                  </div>
                </Row>
              </Row>
              <EndUserTable
                tableData={formattedBreaches}
                searchFilterText={searchFilterText}
                trOpt={trOpt}
                columns={columns}
                tableProps={{
                  pagination: pagination,
                  updatePagination: updatePagination,
                  updateSorter: updateSorter,
                  updateFilters: updateFilters,
                  sortBy: ['name']
                }}
                dataIteratorProps={{
                  learnerName: learnerName,
                  learnerId: learnerId,
                  defaultPageSize: 5,
                  cardTemplate: cardTemplate,
                  cardAction: DataIteratorActions
                }}
              />
            </RoundedCard>
          </Column>
        </ContentWrap>}
    </>
  )
}

export default connect(
  getSessionAndSettings
)(BreachDashboard)
