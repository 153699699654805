import React, { useMemo, useRef } from 'react'
import { Icon } from 'antd'
import Highlighter from 'react-highlight-words'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import useTheme from './useTheme'
import SearchFilterDropdown from '../components/common/SearchFilterDropdown'
import styled from 'styled-components'

const RecipientLine = styled.span`
  display: block;
  font-size: ${({ sub }) => sub ? '12px' : 'inherit'};
`

const useColumnSearch = (dataIndex, title, filters, subLineIndex = '') => {
  const searchRef = useRef(null)
  const theme = useTheme()

  const getColumnSearchProps = useMemo(() => {
    return {
      filteredValue: filters ? _get(filters, dataIndex) : null,
      filterDropdown: props => <SearchFilterDropdown {...{ dataIndex, title, searchRef }} {...props} />,
      filterIcon: filtered => <Icon type='search' style={{ color: filtered ? theme.primary : undefined }} />,
      onFilter: (value, record) => {
        return _get(record, dataIndex) ? _get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()) : false
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible && searchRef && searchRef.current) {
          setTimeout(() => searchRef.current.select())
        }
      },
      render: (text, record) => {
        const filter = filters ? _get(filters, dataIndex) : null
        const lineMain = !_isEmpty(filter) ? (
          <Highlighter
            highlightStyle={{ backgroundColor: theme.primary, color: theme.secondary, padding: '0 5px', borderRadius: 2 }}
            searchWords={filter}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : text
        let lineSub = null

        if (subLineIndex) {
          if (_isArray(subLineIndex)) {
            for (const i of subLineIndex) {
              lineSub = _get(record, i) || null
              if (lineSub) break
            }
          } else { lineSub = _get(record, subLineIndex) || null }
        }

        return lineSub ? (
          <div>
            <RecipientLine>{lineMain}</RecipientLine>
            <RecipientLine sub>{lineSub}</RecipientLine>
          </div>
        ) : lineMain
      }
    }
  }, [filters, dataIndex, title, theme, subLineIndex])
  return getColumnSearchProps
}

export default useColumnSearch
