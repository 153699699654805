import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { useQuery, useLazyQuery } from 'react-apollo'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { Button } from 'antd'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { BREACH_REPORT_ACCESS, REPORT_ME } from '../../../components/Queries/Reports'
import { connect, withProvider, withAppTitleAndIcons } from '../../../hocs'
import reportAccessClient from '../../../apollo-client/reportAccessClient'
import { getBrowserLocale } from '../../../helpers/locale'
import { DEFAULT_LANGUAGE } from '../../../constants/languages'
import useReportAccessToken from '../../../hooks/useReportAccessToken'
import { creators as sessionActions } from '../../../state/actions/session'
import { creators as settingsActions } from '../../../state/actions/settings'
import { creators as viewActions } from '../../../state/actions/view'
import { ErrorAlerts, PreviewTag } from '../../../components/common'
import BaseReport from '../../../components/Reports/BaseReport'
import { PAGE_X_PAD_DESKTOP_REM, PAGE_X_PAD_MOBILE_REM, BREAKPOINT } from '../../../constants/style'
import useIsPrinting from '../../../hooks/useIsPrinting'
import { REPORT_CENTRE_PREVIEW } from '../../../constants/environment'
import DownloadReportModal from '../../../components/Modals/DownloadReportModal'
import { formatDate } from '../../../helpers/datetime'
import { captureSentryError } from '../../../helpers/sentry'
import { getBreachReportSections } from '../../../helpers/breachReport'

const trOpt = { scope: 'reports.breachReport' }
const contentViewTrOpt = { scope: 'reports.breachReport.contentView' }
const ErrorAlertWrapper = styled.div`
  height: calc(100vh - 110px);
  padding: calc(${PAGE_X_PAD_DESKTOP_REM}rem);
`

const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
  height: 103px;
  padding-left: calc(${PAGE_X_PAD_DESKTOP_REM}rem + 20px);
  padding-right: calc(${PAGE_X_PAD_DESKTOP_REM}rem + 20px);
  width: 100%;

  @media (max-width: ${BREAKPOINT}) {
    padding-left: calc(${PAGE_X_PAD_MOBILE_REM}rem + 20px);
    padding-right: calc(${PAGE_X_PAD_MOBILE_REM}rem + 20px);
  }
`
const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`
const _HeaderLogo = ({ loading = false, className }) => (
  <div {...{ className }}>
    {
      loading
        ? <div className='title-logo-placeholder' />
        : <div className='title-logo-image' />
    }
  </div>
)
const HeaderLogo = styled(_HeaderLogo)`
  .title-logo-image {
    background-image: url('${({ theme }) => theme.appThemeLogo}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 6.6em;
    width: 12em;
  }

  .title-logo-placeholder {
    height: 1.5em;
  }
`
const ReportWrapper = styled.div`
  background-color: ${({ theme }) => theme.reportBG};
  padding-top: 0;

  @media print {
    background-color: transparent;
  }
`

const HeaderTitle = styled.div`
  color: rgba(0, 0, 0, 0.85);
  text-align: end;
  & h1 {
    margin-bottom: 0px;
  }
  & p {
    margin-bottom: 0px;
  }
  `

const HeaderSubtitle = styled.div`
  p {
    display: inline-block;
  }
  ${PreviewTag} {
    margin-right: 0;
  }
  .ant-btn {
    margin-left: 10px;
  }
`

const BreachReportContentView = ({ setLoadingVisible, setTheme, updateSession, setPageTitle, resetPageTitle, setLocale }) => {
  const { reportAccessToken } = useReportAccessToken()
  const isPrinting = useIsPrinting()

  const [getPerformanceReportAccess, {
    loading: breachReportAccessLoading,
    error: breachReportAccessError,
    data: { breachReportAccess } = {}
  }] = useLazyQuery(BREACH_REPORT_ACCESS, {
    onError: (error) => {
      captureSentryError(error, { msg: 'Breach Report - Access Report Data Load - ERROR' })
    }
  })
  const { data: { reportMe } = {}, loading: reportMeLoading, error: reportMeError } = useQuery(REPORT_ME, {
    skip: !reportAccessToken,
    onError: (error) => {
      captureSentryError(error, { msg: 'Breach Report - Access Token Verification - ERROR' })
    },
    onCompleted: (data) => {
      if (!_isEmpty(data)) {
        const { reportMe } = data
        setTheme({
          ...(_get(reportMe, 'company.settings') || {}),
          parentDefaultSettings: _get(reportMe, 'company.parentCompany.settings.defaultTenantSettings')
        })
        updateSession({
          mspName: _get(reportMe, 'company.parentCompany.name'),
          locale: getBrowserLocale(null) || _get(reportMe, 'company.locale') || DEFAULT_LANGUAGE
        })
        getPerformanceReportAccess()
      }
    }
  }, [reportAccessToken])

  // Set initial language from browser
  useEffect(() => setLocale(getBrowserLocale()), [setLocale])

  useEffect(() => {
    setLoadingVisible(breachReportAccessLoading || reportMeLoading)
  }, [breachReportAccessLoading, reportMeLoading, setLoadingVisible])

  const sections = useMemo(() => {
    if (breachReportAccess?.breachReport && !(breachReportAccessLoading || reportMeLoading)) {
      return getBreachReportSections({
        breachReport: breachReportAccess.breachReport,
        trOpt
      })
    }
    return []
  }, [breachReportAccess, breachReportAccessLoading, reportMeLoading])

  // Temporarily change page title to a filename string when printing
  useEffect(() => {
    if (isPrinting) {
      const { fromDate, toDate } = breachReportAccess || {}
      setPageTitle(I18n.t('printTitle', { ...trOpt, fromDate, toDate }))
    } else {
      resetPageTitle()
    }
  }, [isPrinting, setPageTitle, resetPageTitle, breachReportAccess])

  const downloadReportModalRef = useRef(null)
  const openDownloadReportModal = useCallback(() => {
    if (downloadReportModalRef.current) {
      const { reportType, fromDate, toDate } = breachReportAccess || {}
      downloadReportModalRef.current.open({
        name: I18n.t('reports.downloadReport.fileNames.breachReport', { fromDate, toDate }),
        fromDate,
        toDate,
        reportType
      }, reportAccessToken)
    }
  }, [downloadReportModalRef, breachReportAccess, reportAccessToken])

  return (
    <>
      {(!reportMeError && !breachReportAccessError && !reportMeLoading && !breachReportAccessLoading) && <div id='usecure-report-safe' />}
      <HeaderWrapper className='hideOnPrint'>
        <HeaderContainer>
          <HeaderLogo />
          <HeaderTitle>
            {reportMe?.company ? (
              <>
                <h1>{I18n.t('reportTitleWithCompany', { ...trOpt, companyName: reportMe.company.name })}</h1>
                <HeaderSubtitle>
                  <p>{breachReportAccess ? `${formatDate(_get(breachReportAccess, 'fromDate'))} - ${formatDate(_get(breachReportAccess, 'toDate'))}` : ''}</p>
                  <Button icon='download' onClick={openDownloadReportModal}>{I18n.t('common.download')}</Button>
                  {REPORT_CENTRE_PREVIEW && <PreviewTag />}
                </HeaderSubtitle>
              </>
            ) : (
              <>
                <h1>{I18n.t('reportTitle', trOpt)}</h1>
                {reportMe && (
                  <HeaderSubtitle>
                    {REPORT_CENTRE_PREVIEW && <PreviewTag />}
                  </HeaderSubtitle>
                )}
              </>
            )}
          </HeaderTitle>
        </HeaderContainer>
      </HeaderWrapper>
      <ReportWrapper>
        {(reportMeError || breachReportAccessError) && (
          <ErrorAlertWrapper>
            {reportMeError && <ErrorAlerts {...{ error: reportMeError }} defaultError={I18n.t('reportMeDefaultErrorMsg', contentViewTrOpt)} />}
            {breachReportAccessError && <ErrorAlerts {...{ error: breachReportAccessError }} defaultError={I18n.t('breachReportAccessDefaultErrorMsg', contentViewTrOpt)} />}
          </ErrorAlertWrapper>
        )}
        {!reportMeLoading && sections.length > 0 && (
          <BaseReport
            {...{ sections, trOpt }}
            title={I18n.t('reportTitle', trOpt)}
            companyId={reportMe?.company?.id}
            companyName={reportMe?.company?.name}
            showCompanyName={_get(reportMe, 'company.settings.reportDocumentShowCompanyName', true) === true}
            fromDate={_get(breachReportAccess, 'fromDate')}
            toDate={_get(breachReportAccess, 'toDate')}
            hideHeader
            reportType='breachReport'
          />
        )}
      </ReportWrapper>
      <DownloadReportModal ref={downloadReportModalRef} />
    </>
  )
}

export default compose(
  connect(
    undefined,
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      updateSession: session => dispatch(sessionActions.noAuthUpdate(session)),
      setLocale: locale => dispatch(sessionActions.updateLocaleOnly(locale)),
      setPageTitle: title => dispatch(viewActions.title(title)),
      resetPageTitle: () => dispatch(viewActions.resetTitle())
    })
  ),
  withProvider(reportAccessClient),
  withAppTitleAndIcons({ noAuth: true })
)(BreachReportContentView)
