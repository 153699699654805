import React, { useMemo } from 'react'
import I18n from 'i18n-js'

import ActionConfirm from './ActionConfirm'
import { SIGN_POLICY_OFFLINE } from '../Queries/uPolicy'
import { invalidatePoliciesQueryCache, renderListFromArray } from '../../helpers'

const trOpt = { scope: 'modals.signPolicyOfflineConfirm' }

const SignPolicyOfflineConfirmList = ({ count = 0 }) => {
  const listTrOpt = { scope: `${trOpt.scope}.body`, count }
  return (
    <div>
      <span>{I18n.t('body.intro', trOpt)}:</span>
      {renderListFromArray([
        I18n.t('list1', listTrOpt),
        I18n.t('list2', listTrOpt),
        I18n.t('list3', listTrOpt),
        I18n.t('list4', listTrOpt),
        I18n.t('list5', listTrOpt)
      ])}
    </div>
  )
}

const SignPolicyOfflineConfirm = ({ policy, learners = [], ...actionConfirmProps }) => {
  policy = policy || { id: null, name: 'POLICY' }
  const learnerIds = useMemo(() => learners.map(({ id }) => id), [learners])

  return (
    <ActionConfirm
      mutation={SIGN_POLICY_OFFLINE}
      ids={learnerIds}
      variableName='learnerIds'
      variables={{ policyId: policy.id }}
      update={invalidatePoliciesQueryCache}
      body={
        <SignPolicyOfflineConfirmList count={learnerIds.length} />
      }
      trOpt={{ ...trOpt, count: learnerIds.length, policy: policy.name }}
      {...actionConfirmProps}
    />
  )
}

export default SignPolicyOfflineConfirm
