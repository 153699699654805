import { numberColumnSorter } from './listPages'

export const courseResultDurationColumnSorter = (columnId, a, b) => {
  let aValue = a[columnId]
  if (!a.startDate) {
    aValue = -1
  }
  let bValue = b[columnId]
  if (!b.startDate) {
    bValue = -1
  }
  return numberColumnSorter(columnId, { [columnId]: aValue }, { [columnId]: bValue }, 0)
}

export const coursePercentageColumnSorter = (columnId, totalColumnId, a, b) =>
  Math.floor(a[columnId] * 100 / a[totalColumnId]) - Math.floor(b[columnId] * 100 / b[totalColumnId])
