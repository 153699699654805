import React, { useReducer, useEffect, useImperativeHandle, useCallback, useMemo } from 'react'
import { Card, Radio, Select } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _upperFirst from 'lodash/upperFirst'

import { createAction } from '../../helpers/state'
import { roles, superRoleIdDowngrades, superRoleIds } from '../../constants/roles'
import MutationFormErrors from '../MutationForm/MutationFormErrors'
import { createLocaleGetterObjectFromArray } from '../../helpers/locale'
import useGlobalState from '../../hooks/useGlobalState'

const { Option } = Select

const trOpt = { scope: 'users.roleAssignmentField' }
const rolesTrOpt = { scope: 'users.common.roles' }

const ROOT_ROLES = [
  roles.SUPER_ADMIN.id,
  roles.GLOBAL_ADMIN.id,
  roles.GLOBAL_READ_ONLY.id
]
const CUSTOM_ROLE_TYPE = 'custom'
const ROLE_TYPES = [
  ...ROOT_ROLES,
  CUSTOM_ROLE_TYPE
]

const ROLE_TYPE_PRODUCT_ROLE = {
  [roles.GLOBAL_ADMIN.id]: 'admin',
  [roles.GLOBAL_READ_ONLY.id]: 'readOnly'
}

const PRODUCT_ROLES = {
  users: {
    admin: roles.USER_ADMIN.id,
    editor: roles.USER_EDITOR.id,
    readOnly: roles.USER_READ_ONLY.id
  },
  uLearn: {
    admin: roles.ULEARN_ADMIN.id,
    editor: roles.ULEARN_EDITOR.id,
    readOnly: roles.ULEARN_READ_ONLY.id
  },
  uBreach: {
    admin: roles.UBREACH_ADMIN.id,
    readOnly: roles.UBREACH_READ_ONLY.id
  },
  uPolicy: {
    admin: roles.UPOLICY_ADMIN.id,
    editor: roles.UPOLICY_EDITOR.id,
    readOnly: roles.UPOLICY_READ_ONLY.id
  },
  uPhish: {
    admin: roles.UPHISH_ADMIN.id,
    editor: roles.UPHISH_EDITOR.id,
    readOnly: roles.UPHISH_READ_ONLY.id
  },
  uService: {
    admin: roles.USERVICE_ADMIN.id,
    readOnly: roles.USERVICE_READ_ONLY.id
  }
}
const PRODUCTS = Object.keys(PRODUCT_ROLES)
const PRODUCT_NAMES = createLocaleGetterObjectFromArray(PRODUCTS, { scope: 'common' })

const RoleTypeRadioGroup = styled(Radio.Group)`
  padding-top: 10px;
  width: 100%;
`
const RoleTypeCard = styled(Card)`
  cursor: pointer;
  margin-bottom: 15px;

  border-color: ${({ selected, theme }) => selected ? theme.primary : '#e8e8e8'};

  .ant-card-body {
    padding: 12px;
  }
  
  &:hover {
    border-color: ${({ theme }) => theme.primary};
  }
`
const RoleTypeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`
const RoleTypeDetail = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;

  h4 {
    margin-bottom: 0.1em;
  }

  p {
    font-family: unset;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const RoleTypeRadioCard = ({ roleType, disabled = false, selected = false, onChange }) => {
  const onClick = useCallback(() => {
    onChange(roleType)
  }, [onChange, roleType])

  return (
    <RoleTypeCard {...{ onClick, selected }}>
      <RoleTypeContainer>
        <Radio value={roleType} disabled={disabled} />
        <RoleTypeDetail>
          <h4>{roleType === CUSTOM_ROLE_TYPE ? I18n.t('common.custom') : I18n.t(roleType, rolesTrOpt)}</h4>
          <p>{I18n.t(`roleTypeDescriptions.${roleType}`, trOpt)}</p>
        </RoleTypeDetail>
      </RoleTypeContainer>
    </RoleTypeCard>
  )
}

const Divider = styled.div`
  border-top: 1px solid #e8e8e8;
  margin: 10px 0 20px;
  width: 100%;
`

const ProductRoleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5px 15px;

  .ant-select {
    width: 30%;
  }
`
const ProductRoleDetail = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  width: 70%;

  h4 {
    margin-bottom: 0.1em;
  }

  p {
    font-family: unset;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const ProductRoleSelect = ({ product, value, onChange: onChangeProp, disabled = false, partner = false, accountType }) => {
  const onChange = useCallback(role => {
    onChangeProp(product, role)
  }, [product, onChangeProp])

  const { desc, hasSuperValue } = useMemo(() => {
    let descTrKey
    const hasSuperValue = value === roles.SUPER_ADMIN.id
    if (value === 'disabled') {
      // Same copy used on any disabled product role
      descTrKey = 'productRoleDisabled'
    } else {
      descTrKey = value
      if (hasSuperValue) {
        // Use downgraded product role description on super roles
        // Super roles are only available internally so having dedicated description copy isn't necessary
        const downgradedRoleId = superRoleIdDowngrades[value]
        if (downgradedRoleId) {
          descTrKey = ROLE_TYPE_PRODUCT_ROLE[downgradedRoleId] ?? downgradedRoleId
        }
      }
      if (product === 'uPolicy') {
        descTrKey = `${partner ? 'partner' : 'customer'}${_upperFirst(descTrKey)}`
      } else if (product === 'uService') {
        descTrKey = `${accountType}${_upperFirst(descTrKey)}`
      }
      descTrKey = `productRoleDescriptions.${product}.${descTrKey}`
    }

    return {
      // tWithProductNames is used as the product role description as they include references to product names (e.g. uBreach Pro) which can be customised by clients
      desc: descTrKey ? I18n.tWithProductNames(descTrKey, trOpt) : null,
      hasSuperValue: superRoleIds.includes(value)
    }
  }, [product, value, partner, accountType])

  return (
    <ProductRoleContainer>
      <ProductRoleDetail>
        <h4>{PRODUCT_NAMES[product] ?? product}</h4>
        {desc && <p>{desc}</p>}
      </ProductRoleDetail>
      <Select {...{ value, onChange }} disabled={hasSuperValue || disabled}>
        {hasSuperValue && (
          <Option value={value}>{I18n.t(value, rolesTrOpt)}</Option>
        )}
        {Object.keys(PRODUCT_ROLES[product]).map(role => (
          <Option key={`${product}-${role}`} value={role}>{I18n.t(`productRoles.${role}`, trOpt)}</Option>
        ))}
        <Option value='disabled'>{I18n.t('common.disabled')}</Option>
      </Select>
    </ProductRoleContainer>
  )
}

const setAllProductRoles = ({ products = PRODUCTS, role, newState = {} } = {}) =>
  products.reduce((acc, product) => {
    acc[product] = role
    return acc
  }, newState)

const getProductRole = (roleValues, product) =>
  Object.entries(PRODUCT_ROLES[product] ?? {})
    .find(([, roleId]) => roleValues.includes(roleId))?.[0] ??
    'disabled'

const getRoleTypeFromProductRoles = ({ roleTypes = ROLE_TYPES, products = PRODUCTS, newState } = {}) =>
  roleTypes.find(roleType => {
    // custom doesn't have a specified role so it doesn't apply here
    if (roleType === CUSTOM_ROLE_TYPE) return false

    const productRole = ROLE_TYPE_PRODUCT_ROLE[roleType] ?? roleType
    return products.every(product => newState[product] === productRole)
  }) ?? CUSTOM_ROLE_TYPE

const types = {
  UPDATE: 'UPDATE',
  UPDATE_ROLE_TYPE: 'UPDATE_ROLE_TYPE',
  UPDATE_PRODUCT_ROLE: 'UPDATE_PRODUCT_ROLE',
  RESET: 'RESET'
}

const creators = {
  update: createAction(types.UPDATE),
  updateRoleType: createAction(types.UPDATE_ROLE_TYPE),
  updateProductRole: createAction(types.UPDATE_PRODUCT_ROLE, (product, role) => ({ product, role })),
  reset: createAction(types.RESET)
}

const initialState = {
  status: 'init',
  allowedRoleTypes: [],
  allowedProducts: [],
  allowedRoles: [],
  roleType: null,
  users: null,
  uLearn: null,
  uBreach: null,
  uPolicy: null,
  uPhish: null,
  uService: null
}

const actionsMap = {
  [types.UPDATE]: (prevState, payload) => {
    return {
      ...prevState,
      ...(payload || {})
    }
  },
  [types.UPDATE_ROLE_TYPE]: (prevState, roleType) => {
    let newState = {
      ...prevState,
      roleType
    }
    const { allowedProducts } = newState
    // Set product role selects to same value when role type is changed
    if (ROOT_ROLES.includes(roleType)) {
      newState = setAllProductRoles({
        products: allowedProducts, role: ROLE_TYPE_PRODUCT_ROLE[roleType] ?? roleType, newState
      })
    } else if (
      newState.roleType === CUSTOM_ROLE_TYPE &&
      allowedProducts.some(product => superRoleIds.includes(newState[product]))
    ) {
      // Set any super product roles values to disabled when changing role type to custom
      // Avoids scenarios where roles can't be submitted
      allowedProducts.forEach(product => {
        if (superRoleIds.includes(newState[product])) {
          newState[product] = 'disabled'
        }
      })
    }

    return newState
  },
  [types.UPDATE_PRODUCT_ROLE]: (prevState, { product, role }) => {
    const newState = {
      ...prevState,
      [product]: role
    }

    // Set each product role to the same value if role type has changed to Super Admin, Global Admin or Global Read Only
    newState.roleType = getRoleTypeFromProductRoles({
      products: newState.allowedProducts, roleTypes: newState.allowedRoleTypes, newState
    })

    return newState
  },
  [types.RESET]: () => ({ ...initialState })
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  const fn = actionsMap[type]
  return fn ? fn(state, payload) : state
}

const RoleAssignmentField = React.forwardRef(({ id, visible, value, loading, disabled: fieldDisabled, errors, onChange, hasSuperPermission = false } = {}, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { accountType, partner, uLearnEnabled, uPhishEnabled, uBreachEnabled, uPolicyEnabled } = useGlobalState(useCallback((state) => ({
    accountType: state?.session?.accountType,
    partner: state?.session?.partner,
    uLearnEnabled: state?.settings?.uLearn,
    uPhishEnabled: state?.settings?.uPhish,
    uBreachEnabled: state?.settings?.uBreachEnabled,
    uPolicyEnabled: state?.settings?.uPolicy
  }), []))
  const {
    status, roleType, users, uLearn, uBreach, uPolicy, uPhish, uService,
    allowedRoleTypes, allowedProducts, allowedRoles
  } = state
  const disabled = loading || fieldDisabled

  // "On Mount" hook
  useEffect(() => {
    if (status !== 'init') return

    // Set allowed role types, product roles and roles
    let allowedRoleTypes = [...ROLE_TYPES]
    if (!hasSuperPermission) {
      allowedRoleTypes = allowedRoleTypes.filter(roleType => !superRoleIds.includes(roleType))
    }

    const allowedProducts = [
      ['users', true],
      ['uLearn', uLearnEnabled],
      ['uBreach', uBreachEnabled],
      ['uPolicy', uPolicyEnabled],
      ['uPhish', uPhishEnabled],
      ['uService', partner]
    ].reduce((acc, [product, productEnabled]) => {
      if (productEnabled) {
        acc.push(product)
      }
      return acc
    }, [])

    const allowedRoles = [
      ...allowedRoleTypes.filter(roleType => roleType !== CUSTOM_ROLE_TYPE),
      ...allowedProducts.reduce((acc, product) => {
        const productRoles = PRODUCT_ROLES[product]
        if (productRoles) {
          acc.push(...Object.values(productRoles))
        }
        return acc
      }, [])
    ]

    dispatch(creators.update({ allowedRoleTypes, allowedProducts, allowedRoles }))

    // Convert to (user.roles) value to field state
    const roleValues = value ?? [roles.GLOBAL_ADMIN.id] // Default to global admin
    const roleType = ROOT_ROLES.find(roleId => allowedRoleTypes.includes(roleId) && roleValues.includes(roleId))
    let update = { status: 'ready' }

    if (roleType) {
      // One of the roles present matches a role type e.g. Global Admin
      dispatch(creators.updateRoleType(roleType))
    } else {
      // None of the roles present matches a role type
      update = allowedProducts.reduce((acc, type) => {
        acc[type] = getProductRole(roleValues, type)
        return acc
      }, update)
      // Set role type based on product role present
      // This will be custom unless all of the product roles have the same value e.g. all admin will convert to Product Admin
      update.roleType = getRoleTypeFromProductRoles({
        products: allowedProducts, roleTypes: allowedRoleTypes, newState: update
      })
    }

    dispatch(creators.update(update))
  }, [status, value, partner, uLearnEnabled, uPhishEnabled, uBreachEnabled, uPolicyEnabled, hasSuperPermission])

  // useEffect hook to generate roles array from current field state and call onChange with the value
  useEffect(() => {
    if (status !== 'ready') return

    let roleValues = []

    if (ROOT_ROLES.includes(roleType) && allowedRoles.includes(roleType)) {
      // Use selected role type as sole role
      roleValues.push(roleType)
    } else {
      const productRoleValues = { users, uLearn, uBreach, uPolicy, uPhish, uService }
      // Check if product roles have the same value
      const roleTypeFromRoleValues = getRoleTypeFromProductRoles({
        products: allowedProducts, roleTypes: allowedRoleTypes, newState: productRoleValues
      })

      if (roleTypeFromRoleValues && roleTypeFromRoleValues !== CUSTOM_ROLE_TYPE) {
        // Set role from inferred "same value" role type
        roleValues.push(roleTypeFromRoleValues)
      } else {
        // Use composite roles for customised access
        roleValues = allowedProducts.reduce((acc, type) => {
          const roleValue = productRoleValues[type]
          if (roleValue) {
            const roleId = PRODUCT_ROLES[type]?.[roleValue]
            if (roleId && allowedRoles.includes(roleId)) {
              acc.push(roleId)
            }
          }
          return acc
        }, [])
      }
    }

    onChange(id, roleValues)
  }, [status, onChange, id, roleType, users, uLearn, uBreach, uPolicy, uPhish, uService, allowedProducts, allowedRoles, allowedRoleTypes])

  useImperativeHandle(ref, () => ({
    reset: () => dispatch(creators.reset())
  }), [])

  const onRoleTypeChange = useCallback(roleType => {
    dispatch(creators.updateRoleType(roleType))
  }, [])
  const onRoleTypeRadioChange = useCallback(e => {
    onRoleTypeChange(e.target.value)
  }, [onRoleTypeChange])
  const onProductRoleChange = useCallback((product, role) => {
    dispatch(creators.updateProductRole(product, role))
  }, [])

  if (!visible) return null

  const productRoleValues = { users, uLearn, uBreach, uPolicy, uPhish, uService }

  return (
    <div>
      <RoleTypeRadioGroup
        value={roleType} disabled={disabled}
        onChange={onRoleTypeRadioChange}
      >
        {allowedRoleTypes.map(roleTypeId => (
          <RoleTypeRadioCard
            key={roleTypeId} roleType={roleTypeId}
            selected={roleType === roleTypeId}
            onChange={onRoleTypeChange}
            {...{ disabled }}
          />
        ))}
      </RoleTypeRadioGroup>
      <div className='has-error'>
        <MutationFormErrors visible={errors?.length} errors={errors} />
      </div>
      <Divider />
      <h4>{I18n.t('productRolesTitle', trOpt)}</h4>
      {allowedProducts.map(product => (
        <ProductRoleSelect
          key={product}
          value={productRoleValues[product]}
          {...{ product, disabled, partner, accountType }}
          onChange={onProductRoleChange}
        />
      ))}
    </div>
  )
})

export default RoleAssignmentField
