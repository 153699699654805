import React from 'react'

import RevokeIntegrationButton from '../Integrations/RevokeIntegrationButton'

const RevokeSyncButton = ({ trOption, syncType, action, disabled }) => (
  <RevokeIntegrationButton
    app='sync'
    action={action}
    service={syncType}
    trOpt={trOption}
    disabled={disabled}
  />
)

export default RevokeSyncButton
