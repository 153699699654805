import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Alert, Button, Form, Input, message, Upload } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import mime from 'mime-types'
import { compose } from 'recompose'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { Google, validateEmail, base64EncodeJSON, showErrors, modalConfirmAsync, renderToString } from '../../helpers'
import { connect, withRefreshSessionState } from '../../hocs'
import { getSettings } from '../../state/selectors/settings'
import routes from '../../constants/routes'
import { LoadingBlock } from '../common'
import {
  AuthModal, AuthModalWrap, AuthModalTitle, AuthModalContent, AuthModalFooter,
  AuthRadioOption
} from '../Sync/SelectAuthFlow'

const { REACT_APP_GOOGLE_SYNC_OAUTH_ORIGIN: OAUTH_ORIGIN } = window.__USECURE_CONFIG__ || {}
const DELEGATED_AUTH_ALLOWED = !OAUTH_ORIGIN || window.location.origin === OAUTH_ORIGIN

const UploadAlert = styled(Alert)`
  margin-bottom: 5px;

  .ant-alert-message {
    display: inline-block;
    margin-top: 2px;
  }
`

const ServiceAccountFormItem = styled(Form.Item)`
  margin-bottom: 5px;
`

const APP_SETTINGS = {
  sync: 'googleSync',
  messageInjection: 'messageInjection.google'
}

const openGoogleAuthorizeURL = async (app, redirectUri, sameTab = false) => {
  const syncGoogle = new Google({ app, redirectUri })
  const url = await syncGoogle.getAuthorizeUrl()
  if (sameTab) {
    window.location.href = url
  } else {
    window.open(url, '_blank', ['noopener'])
  }
}

const GoogleSelectAuthFlowModal = React.forwardRef(({ settings, refreshSessionState, sameTab = false, trOpt = { scope: 'modals.googleSelectAuthFlowModal' } }, ref) => {
  // This would have been a good place to try useReducer - maybe next time
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('select')
  const [authType, setAuthType] = useState('delegated')
  const [existingAuthType, setExistingAuthType] = useState(null)
  const [delegatedUserEmail, setDelegatedUserEmail] = useState(null)
  const [hasCredentials, setHasCredentials] = useState(false)
  const [configured, setConfigured] = useState(false)
  const [app, setApp] = useState(null)
  const [redirectUri, setRedirectUri] = useState(null)
  const [credentials, setCredentials] = useState(null)
  const [delegatedUserEmailRequired, setDelegatedUserEmailRequired] = useState(true)
  const [allowGoBack, setAllowGoBack] = useState(true)

  useImperativeHandle(ref, () => ({
    open: async ({ app, redirectUri, fixedAuthType, delegatedUserEmailRequired = true }) => {
      if (fixedAuthType === 'delegated') {
        // Open google oAuth consent URL and skip modal completely
        await openGoogleAuthorizeURL(app, redirectUri, sameTab)
      } else {
        // Open Modal
        const settingId = APP_SETTINGS[app]
        const currentAuthType = _get(settings, `${settingId}.authType`)
        if (fixedAuthType === 'serviceAccount') {
          // Go straight to service config without option to navigate back to selection screen
          setAuthType('serviceAccount')
          setExistingAuthType(null)
          setStatus('serviceAccount')
          setAllowGoBack(false)
        } else {
          // Show delegeated and service account options
          // Initialise modal based on existing auth config
          let authType = _get(settings, `${settingId}.authType`)
          let existingAuthType
          if (authType) {
            existingAuthType = authType
          } else {
            authType = 'delegated'
          }

          setAuthType(authType)
          setExistingAuthType(existingAuthType)
          setAllowGoBack(true)
        }

        setApp(app)
        setRedirectUri(redirectUri)
        setDelegatedUserEmail(_get(settings, `${settingId}.serviceAccountDelegatedUserEmail`))
        setDelegatedUserEmailRequired(delegatedUserEmailRequired)
        setHasCredentials(
          currentAuthType === 'serviceAccount' &&
          _get(settings, `${settingId}.serviceAccountClientEmail`) === true &&
          _get(settings, `${settingId}.serviceAccountPrivateKey`) === true
        )
        setCredentials(null)
        setConfigured(_get(settings, `${settingId}.configured`) === true)
        setVisible(true)
      }
    }
  }), [settings, sameTab])

  const onDelegatedEmailChange = useCallback(e => {
    setDelegatedUserEmail(e.currentTarget.value)
  }, [])

  const closeModal = useCallback(() => setVisible(false), [])
  const onAuthOptionSelected = useCallback(async () => {
    if (authType === 'delegated') {
      await openGoogleAuthorizeURL(app, redirectUri, sameTab)
      closeModal()
    } else if (authType === 'serviceAccount') {
      setStatus('serviceAccount')
    }
  }, [authType, closeModal, app, redirectUri, sameTab])

  const handleCredentialsUpload = useCallback(async file => {
    try {
      const credentialsStr = await file.text()
      const credentialsObj = JSON.parse(credentialsStr)
      const credentials = _pick(credentialsObj, ['client_email', 'private_key'])
      if (Object.keys(credentials).length === 0) throw new Error('Credentials file invalid')
      setCredentials(credentials)
    } catch (e) {
      console.error('Error parsing service credentials json', e)
      message.error(I18n.t('credentialsUploadError', trOpt))
    }
    return Promise.reject(new Error('Forced error to skip upload'))
  }, [trOpt])

  const onContinueClick = useCallback(async () => {
    if (status === 'select') {
      onAuthOptionSelected()
    } else if (status === 'serviceAccount') {
      const variables = { app, authType: 'serviceAccount' }
      if (delegatedUserEmailRequired && delegatedUserEmail && validateEmail(delegatedUserEmail)) {
        variables.serviceAccountDelegatedUserEmail = delegatedUserEmail
      } else if (delegatedUserEmailRequired) {
        message.error(I18n.t('invalidEmailError', trOpt))
        return
      }

      if (credentials) {
        variables.serviceAccountCredentials = base64EncodeJSON(credentials)
      } else if (!hasCredentials) {
        message.error(I18n.t('missingCredentialsError', trOpt))
        return
      }

      let success = false
      try {
        setLoading(true)
        const google = new Google()
        await google.updateAuthConfig(variables)
        await refreshSessionState()
        success = true
      } catch (e) {
        showErrors(e, message.error(I18n.t('updateAuthError', trOpt)))
      }
      setLoading(false)
      if (success) {
        closeModal()
        if (app === 'sync') {
          if (configured) {
            const confirmed = await modalConfirmAsync({
              title: I18n.t('updateAuthSuccessPrompt.title', trOpt),
              content: I18n.t('updateAuthSuccessPrompt.message', trOpt)
            })
            if (confirmed) window.open(routes.GOOGLE_SYNC_SETUP, '_blank', ['noopener'])
          } else {
            window.open(routes.GOOGLE_SYNC_SETUP, '_blank', ['noopener'])
          }
        } else {
          message.success(I18n.t('serviceAccount.successMessage', trOpt))
        }
      }
    }
  }, [status, onAuthOptionSelected, delegatedUserEmail, hasCredentials, credentials, refreshSessionState, configured, closeModal, app, trOpt, delegatedUserEmailRequired])

  const onGoBackClick = useCallback(() => {
    setStatus('select')
  }, [])

  const afterClose = () => {
    setVisible(false)
    setLoading(false)
    setStatus('select')
    setApp(null)
    setRedirectUri(null)
    setAuthType('delegated')
    setCredentials(null)
    setDelegatedUserEmail(null)
    setHasCredentials(false)
    setCredentials(null)
    setConfigured(false)
    setDelegatedUserEmailRequired(true)
    setAllowGoBack(true)
  }

  return (
    <AuthModal
      width='70%'
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      maskClosable={false}
      destroyOnClose
      afterClose={afterClose}
    >
      <LoadingBlock loading={loading} fullScreen={false} />
      <AuthModalWrap>
        <AuthModalTitle>{I18n.t(status === 'serviceAccount' ? 'serviceAccountTitle' : 'title', trOpt)}</AuthModalTitle>
        <AuthModalContent
          flexDirection={status === 'serviceAccount' ? 'column' : 'row'}
        >
          {status === 'select' && (
            <>
              <AuthRadioOption
                type='delegated'
                checked={authType === 'delegated'}
                isExistingValue={existingAuthType === 'delegated'}
                onChange={setAuthType}
                trOpt={trOpt}
                disabled={!DELEGATED_AUTH_ALLOWED}
                extra={!DELEGATED_AUTH_ALLOWED && (
                  <UploadAlert
                    showIcon type='warning'
                    message={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: I18n.t('delegated.originNotAllowed', {
                            ...trOpt,
                            origin: window.location.origin,
                            allowedOrigin: renderToString(<a href={OAUTH_ORIGIN} target='_blank' rel='noopener noreferrer'>{OAUTH_ORIGIN}</a>)
                          })
                        }}
                      />
                    }
                  />
                )}
              />
              <AuthRadioOption
                type='serviceAccount'
                checked={authType === 'serviceAccount'}
                isExistingValue={existingAuthType === 'serviceAccount'}
                onChange={setAuthType}
                trOpt={trOpt}
              />
            </>
          )}
          {status === 'serviceAccount' && (
            <>
              {delegatedUserEmailRequired && (
                <ServiceAccountFormItem
                  label={I18n.t('delegatedUserEmail', trOpt)}
                  extra={I18n.t('delegatedUserEmailExtra', trOpt)}
                  required
                >
                  <Input
                    name='email'
                    value={delegatedUserEmail}
                    onChange={onDelegatedEmailChange}
                    allowClear
                  />
                </ServiceAccountFormItem>
              )}
              <ServiceAccountFormItem
                label={I18n.t('uploadCredentials', trOpt)}
                required
              >
                {!hasCredentials && !credentials && <UploadAlert showIcon type='info' message={I18n.t('uploadCredentialsInfo.new', trOpt)} />}
                {credentials && <UploadAlert showIcon type='warning' message={I18n.t('uploadCredentialsInfo.pending', trOpt)} />}
                {hasCredentials && !credentials && <UploadAlert showIcon type='success' message={I18n.t('uploadCredentialsInfo.replace', trOpt)} />}
                <Upload
                  accept={mime.types.json}
                  beforeUpload={handleCredentialsUpload}
                >
                  <Button icon='upload'>{I18n.t('uploadCredentialsButton', trOpt)}</Button>
                </Upload>
              </ServiceAccountFormItem>
            </>
          )}
        </AuthModalContent>
        <AuthModalFooter>
          <Button onClick={closeModal}>{I18n.t('common.cancel')}</Button>
          {allowGoBack && status === 'serviceAccount' && <Button onClick={onGoBackClick}>{I18n.t('common.goBack')}</Button>}
          <Button type='primary' onClick={onContinueClick} disabled={authType === 'delegated' && !DELEGATED_AUTH_ALLOWED}>{I18n.t('common.continue')}</Button>
        </AuthModalFooter>
      </AuthModalWrap>
    </AuthModal>
  )
})

export default compose(
  connect(state => ({ settings: getSettings(state) })),
  withRefreshSessionState
)(GoogleSelectAuthFlowModal)
