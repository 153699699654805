import isValidDomain from 'is-valid-domain'
import _isEmpty from 'lodash/isEmpty'
import _isString from 'lodash/isString'

const validateDomain = (domain, { skipWWWCheck = false } = {}) =>
  _isString(domain) && !_isEmpty(domain) &&
  isValidDomain(domain) &&
  (skipWWWCheck || !/^www\./i.test(domain.trim()))

export default validateDomain
