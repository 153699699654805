import React, { useImperativeHandle, useState, useCallback, useRef } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { validateEmail } from '../../helpers'
import { SEND_REPORT } from '../Queries/Reports'
import { withConsumer } from '../../hocs/withConsumer'
import MutationForm from '../MutationForm/MutationForm'

const SendModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const trOpt = { scope: 'modals.sendReportModal' }

const SendReportModal = React.forwardRef(({
  companyId
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [report, setReport] = useState(null)

  const form = useRef(null)

  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setReport(null)
    resetForm()
  }

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  useImperativeHandle(ref, () => ({
    open: async (report) => {
      setReport(report)
      setVisible(true)
    }
  }), [setReport, setVisible])

  const fields = [
    {
      id: 'recipients',
      label: I18n.t('reports.reportCentre.common.recipients'),
      type: 'textTags',
      required: true,
      validateTag: value => {
        if (!validateEmail(value)) {
          return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
        }
      },
      defaultValue: []
    }
  ]

  const mutateValues = useCallback(values => {
    const { fromDate, toDate, period, reportType, recordIds } = report

    return {
      ...values,
      fromDate,
      toDate,
      period,
      reportType,
      reportId: reportType === 'performanceReport' && recordIds?.length > 0 ? recordIds[0] : null
    }
  }, [report])

  return (
    <SendModal
      title={report?.name ? I18n.t('title', { ...trOpt, name: report.name }) : I18n.t('titleDefault', trOpt)}
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      afterClose={afterClose}
      width='70%'
    >
      <MutationForm
        ref={form}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('submitLabel', trOpt)}
        mutation={SEND_REPORT}
        variables={{ companyId }}
        {...{
          fields,
          onSuccess,
          mutateValues
        }}
      />
    </SendModal>
  )
})

export default withConsumer({ useHooks: true })(SendReportModal)
