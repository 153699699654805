import React from 'react'
import { ConfigProvider } from 'antd'
import connect from '../../hocs/connect'
import { sessionLocale } from '../../state/selectors/session'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

// Load antd language data
import en from 'antd/es/locale/en_GB'
import us from 'antd/es/locale/en_US'
import fr from 'antd/es/locale/fr_FR'
import de from 'antd/es/locale/de_DE'
import nl from 'antd/es/locale/nl_NL'
import it from 'antd/es/locale/it_IT'
import es from 'antd/es/locale/es_ES'
import cs from 'antd/es/locale/cs_CZ'
import zh from 'antd/es/locale/zh_CN'
import fi from 'antd/es/locale/fi_FI'
import pt from 'antd/es/locale/pt_PT'
import sv from 'antd/es/locale/sv_SE'
import ro from 'antd/es/locale/ro_RO'
import pl from 'antd/es/locale/pl_PL'

// Map our locales to antd's - https://3x.ant.design/docs/react/i18n
const ANTD_LOCALES = {
  en,
  us,
  fr,
  'fr-CA': fr,
  'fr-CH': fr,
  de,
  'de-CH': de,
  nl,
  it,
  es,
  cs,
  zh,
  fi,
  pt,
  sv,
  ro,
  pl
}
const AntdConfigProvider = ({ locale = DEFAULT_LANGUAGE, children }) => (
  <ConfigProvider locale={ANTD_LOCALES[locale] || ANTD_LOCALES[DEFAULT_LANGUAGE]}>{children}</ConfigProvider>
)

export default connect(state => ({
  locale: sessionLocale(state)
}))(AntdConfigProvider)
