const getThemeColorForPercentage = (percentage = 0, theme = {}, reverse) => {
  percentage = reverse ? 100 - percentage : percentage
  let themeColor
  // JG: This threshold was changed from 33 to 34 to preserve the colouring on the uLearn radar graph in the Learner Modal when change to displaying score instead of grades was made
  if (percentage < 34) {
    themeColor = 'green'
  // JG: This threshold was changed from 66 to 68 for the same reason as above
  } else if (percentage < 68) {
    themeColor = 'orange'
  } else {
    themeColor = 'red'
  }

  return themeColor ? theme[themeColor] : null
}

export default getThemeColorForPercentage
