import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Select } from 'antd'
import styled from 'styled-components'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import _uniq from 'lodash/uniq'

import MutationFormErrors from './MutationFormErrors'

const { Option } = Select

const Divider = styled.span`
  margin: 0 5px;
`

const FieldContainer = styled.div`
  display: flex;
`

class MutationFormInputWithSelect extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hasReceivedFocus: false
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputFocus = this.handleInputFocus.bind(this)
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  handleInputChange (event) {
    let { name, value } = event.target

    if (this.props.input.removeSpaces) {
      value = value.replace(/\s/g, '')
    }

    this.props.onChange(name, value)
  }

  handleInputFocus () {
    this.setState({ hasReceivedFocus: true })
  }

  handleInputKeyDown (event) {
    if (event.keyCode === 13 && this.props.disableSubmitOnEnter) {
      event.preventDefault()
    }
  }

  renderInput () {
    const { id, type = 'text', required, placeholder, autofill, value, className, style } = this.props.input || {}

    // Prevent browser autofil of password
    const autoComplete = type === 'password' && !autofill ? 'new-password' : undefined
    const readOnly = !autofill ? !this.state.hasReceivedFocus : undefined

    return (
      <Input
        onChange={this.handleInputChange}
        onFocus={this.handleInputFocus}
        onKeyDown={this.handleInputKeyDown}
        name={id}
        {...{ className, style, value, required, type, placeholder, autoComplete, readOnly }}
      />
    )
  }

  handleSelectChange (value) {
    this.props.onChange(this.props.select.id, value)
  }

  renderSelect () {
    const { id, type = 'select', required, placeholder, options, showSearch = true, allowClear, className, style } = this.props.select
    const isMultiSelect = type === 'multiSelect'
    const mode = isMultiSelect ? 'multiple' : 'default'
    let { value } = this.props.select

    if (isMultiSelect && !(value && _isArray(value))) {
      // Set value to empty array on multiselect unless value is an array
      value = []
    } else if (_isNil(value)) {
      // antd Select placeholder only shows when value is undefined
      value = undefined
    }

    return (
      <Select
        name={id}
        onChange={this.handleSelectChange}
        optionFilterProp='label'
        {...{ className, style, value, required, mode, placeholder, showSearch, allowClear }}
      >
        {options.map((option, index) => (
          <Option key={index} value={option.value} disabled={option.disabled} label={option.label}>{option.label}</Option>
        ))}
      </Select>
    )
  }

  get errors () {
    return _uniq([
      ...(_get(this.props, 'errors') || []),
      ...(_get(this.props, 'input.errors') || []),
      ...(_get(this.props, 'select.errors') || [])
    ])
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { errors = [] } = this
    const { label, divider, extra, required, formItemStyle, className } = this.props
    const showErrors = errors.length > 0

    return (
      <Form.Item
        label={label} extra={extra} required={required}
        className={className} style={formItemStyle}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <FieldContainer>
          {this.renderInput()}
          {divider ? <Divider>{divider}</Divider> : null}
          {this.renderSelect()}
        </FieldContainer>
      </Form.Item>
    )
  }
}

MutationFormInputWithSelect.propTypes = {
  divider: PropTypes.string,
  input: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    placeholder: PropTypes.string,
    autofill: PropTypes.bool,
    value: PropTypes.string,
    removeSpaces: PropTypes.bool
  }),
  onChange: PropTypes.func,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  select: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      label: PropTypes.string
    })),
    showSearch: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.func]),
    disableSubmitOnEnter: PropTypes.bool
  })
}

MutationFormInputWithSelect.defaultProps = {
  divider: null,
  input: {
    id: '',
    type: 'text',
    required: false,
    placeholder: '',
    autofill: true,
    value: '',
    removeSpaces: PropTypes.bool
  },
  onChange: () => {},
  required: false,
  select: {
    id: '',
    type: 'select',
    required: false,
    linkField: null,
    linkFieldValue: null,
    value: '',
    options: [],
    showSearch: true,
    extra: null
  },
  disableSubmitOnEnter: false
}

export default MutationFormInputWithSelect
