import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Tooltip, message } from 'antd'
import { Prompt } from 'react-router'
import { generatePath, withRouter } from 'react-router-dom'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import I18n from 'i18n-js'
import uuid from 'uuid/v4'

import { ChangedIndicator, CONTENT_TYPE_NAMES } from './common'
import EditCourseMaterial from './EditCourseMaterial'
import EditCourseMultipleChoice from './EditCourseMultipleChoice'
import EditCourseVimeo from './EditCourseVimeo'
import EditCourseAnnotatedImage from './EditCourseAnnotatedImage'
import EditCourseChecklist from './EditCourseChecklist'
import EditCourseCards from './EditCourseCards'
import EditCourseCarousel from './EditCourseCarousel'
import SaveConfirmModal from './SaveConfirmModal'
import {
  CourseContentControls,
  CourseContentEditorContent,
  CourseContentEditorHeader,
  CourseContentEditorHeaderWrap,
  CourseContentEditorLayout,
  CourseContentEditorPanel,
  CourseHeaderLink,
  CourseTitle
} from './CourseContentEditor'
import routes from '../../constants/routes'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import { LanguageDropdown } from '../common'
import { base64EncodeJSON, showErrors } from '../../helpers'

const trOpt = { scope: 'editCourse.courseSlideEditor' }

const CONTENT_EDITORS = {
  material: EditCourseMaterial,
  multipleChoice: EditCourseMultipleChoice,
  vimeo: EditCourseVimeo,
  annotatedImage: EditCourseAnnotatedImage,
  checklist: EditCourseChecklist,
  cards: EditCourseCards,
  carousel: EditCourseCarousel
}

class CourseSlideEditor extends Component {
  constructor (props) {
    super(props)

    this.state = {
      saveConfirmOpen: false,
      postSavePath: null,
      ...(props.slide ? this.initialiseSlide(props.slide, false) : {})
    }

    this.handleNextSlideClick = this.handleNextSlideClick.bind(this)
    this.handlePrevSlideClick = this.handlePrevSlideClick.bind(this)
    this.handlePreviewClick = this.handlePreviewClick.bind(this)
    this.onPrompt = this.onPrompt.bind(this)
    this.handleSaveConfirmModalCancel = this.handleSaveConfirmModalCancel.bind(this)
    this.handleSaveConfirmModalNo = this.handleSaveConfirmModalNo.bind(this)
    this.handleSaveConfirmModalYes = this.handleSaveConfirmModalYes.bind(this)
    this.onUnload = this.onUnload.bind(this)
    this.handleUpdateClick = this.handleUpdateClick.bind(this)
    this.updateTitle = this.updateTitle.bind(this)
    this.updateContent = this.updateContent.bind(this)
    this.updateInProgress = this.updateInProgress.bind(this)
    this.update = this.update.bind(this)
  }

  _updateState (stateUpdate) {
    return new Promise(resolve => {
      this.setState(stateUpdate, async () => {
        resolve()
      })
    })
  }

  extractSlideProps (rawSlide) {
    return _pick(rawSlide, ['id', 'type', 'title', 'content'])
  }

  get slidesPath () {
    const { courseId, locale } = this.props
    if (courseId && locale) {
      return generatePath(routes.BUILDER_EDIT_SLIDES_LOCALE, { course_id: courseId, locale })
    } else if (courseId) {
      return generatePath(routes.BUILDER_EDIT_SLIDES, { course_id: courseId })
    }
    return null
  }

  get changed () {
    const { changed, changeInProgress } = this.state
    return changed || changeInProgress
  }

  async handleUpdateClick () {
    await this.save()
  }

  revert () {
    // Revert within component
    return this._updateState({ changed: false, ...this.extractSlideProps(_cloneDeep(this.initialSlide)) })
  }

  async save () {
    const start = Date.now()
    const delay = 800
    let success = false
    this.props.setLoadingVisible(true)
    try {
      const { courseId, slideId, locale } = this.props
      const { title, content } = this.state
      const result = await this.props.saveSlide({
        variables: {
          courseId,
          slideId,
          locale,
          title,
          content: base64EncodeJSON(content || {})
        }
      })

      const courseSlide = _get(result, 'data.updateCourseSlide')
      if (courseSlide) {
        const slide = _pick(courseSlide, ['id', 'slideId', 'type', 'title', 'content'])
        await this._updateState({ changed: false, updateId: uuid(), ...slide })
        this.initialSlide = _cloneDeep(slide)
        success = true
      }
    } catch (e) {
      showErrors(e, I18n.t('editCourse.common.anErrorOccurredUpdatingThisCourse'))
    }
    if (success) {
      await this.props.refetchSlide()
      await this.props.refetchSlides()
      // Artificial delay on hiding the loading screen unless mutation took longer than artificial delay
      const mutationTime = Date.now() - start
      if (mutationTime >= delay) {
        this.props.setLoadingVisible(false)
      } else {
        setTimeout(() => {
          this.props.setLoadingVisible(false)
          message.success(I18n.t('editCourse.common.successMessage'))
        }, delay - mutationTime)
      }
    } else {
      this.props.setLoadingVisible(false)
    }
    return success
  }

  async showUnloadSaveConfirm (postSavePath) {
    this.setState({
      postSavePath,
      saveConfirmOpen: true
    })
  }

  async handleSaveConfirmModalYes () {
    const { postSavePath } = this.state
    const success = await this.save()
    this.handleSaveConfirmModalCancel()
    if (success) {
      this.navigate(postSavePath)
    }
  }

  async handleSaveConfirmModalNo () {
    const { postSavePath } = this.state
    await this.revert()
    this.handleSaveConfirmModalCancel()
    this.navigate(postSavePath)
  }

  handleSaveConfirmModalCancel () {
    this.setState({
      postSavePath: null,
      saveConfirmOpen: false
    })
  }

  updateTitle (title) {
    this.update('title', title)
  }

  updateContent (content) {
    this.update('content', content)
  }

  updateInProgress (changeInProgress) {
    this.setState({ changeInProgress })
  }

  update (prop, data) {
    this.setState({ changed: true, [prop]: data })
  }

  onUnload (event) {
    if (this.changed) {
      // Chrome won't display this message, setting event.returnValue trigger the unload behaviour
      event.returnValue = I18n.t('youHaveUnsavedChangesOnThisSlide', trOpt)
    }
  }

  onPrompt (nextLocation) {
    if (this.changed) {
      this.showUnloadSaveConfirm(nextLocation.pathname)
      return false
    }
    return true
  }

  componentDidMount () {
    window.addEventListener('beforeunload', this.onUnload)
  }

  initialiseSlide (slide = this.props.slide, updateState = true) {
    this.initialSlide = _cloneDeep(slide)
    // Deep clone prevents changes in this component reaching EditCourseContent until it's saved
    const { id, slideId, type, title, content } = _cloneDeep(slide)

    const stateUpdate = {
      changed: false,
      changeInProgress: false,
      // This is an identifier for the page "session" - it's used to tell the slide type components that the page has reloaded
      updateId: uuid(),
      // Slide Id value duplicated in case older expects either prop
      id,
      slideId,
      type,
      title,
      content
    }
    if (updateState) {
      this.setState(stateUpdate)
    }
    return stateUpdate
  }

  componentDidUpdate (prevProps) {
    const { slide } = this.props
    const { slide: prevSlide } = prevProps

    if (!_isEqual(slide, prevSlide)) {
      this.initialiseSlide(slide)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  get prevSlidePath () {
    return this.getEditSlidePath(this.props.prevSlide)
  }

  get nextSlidePath () {
    return this.getEditSlidePath(this.props.nextSlide)
  }

  isValidSlideId (slideId) {
    return ['number', 'string'].includes(typeof slideId)
  }

  getEditSlidePath (slideId) {
    return this.isValidSlideId(slideId) ? generatePath(routes.BUILDER_EDIT_SLIDE_LOCALE, { course_id: this.props.courseId, slide_id: slideId, locale: this.props.locale }) : undefined
  }

  handlePrevSlideClick () {
    this.onSlideNav(this.prevSlidePath)
  }

  handleNextSlideClick () {
    this.onSlideNav(this.nextSlidePath)
  }

  onSlideNav (path) {
    if (this.changed) {
      this.showUnloadSaveConfirm(path)
      return
    }

    this.navigate(path)
  }

  navigate (path) {
    if (path) {
      this.props.history.push(path)
    }
  }

  handlePreviewClick () {
    window.open(generatePath(routes.BUILDER_PREVIEW_SLIDE_LOCALE, { course_id: this.props.courseId, slide_id: this.state.slideId, locale: this.props.locale }), '_blank')
  }

  render () {
    const { locale, onLocaleChange, prevSlide, nextSlide, slideList, dropdownLanguages } = this.props
    const { slideId, type, title, content, saveConfirmOpen, updateId } = this.state
    const Editor = CONTENT_EDITORS[type] || CONTENT_EDITORS.material
    const slideType = CONTENT_TYPE_NAMES[type]
    let pageTitle = ''
    if (slideType && title) {
      pageTitle = I18n.t('fullTitle', { ...trOpt, slideType, slideTitle: title })
    } else if (slideType) {
      pageTitle = I18n.t('title', { ...trOpt, slideType })
    }
    const editorProps = {
      slideId,
      updateId,
      locale,
      title,
      content,
      courseRandomizeAnswerOrder: this.props.courseRandomizeAnswerOrder,
      updateTitle: this.updateTitle,
      updateContent: this.updateContent,
      updateInProgress: this.updateInProgress
    }

    if (type === 'checklist') {
      editorProps.slideList = slideList
    }

    const previewButton = <Button type='primary' icon='eye' onClick={this.handlePreviewClick}>{I18n.t('previewSlide', trOpt)}</Button>

    return (
      <>
        <Prompt
          when={this.changed}
          message={this.onPrompt}
        />
        <SaveConfirmModal
          visible={saveConfirmOpen} item='slide'
          onYes={this.handleSaveConfirmModalYes}
          onNo={this.handleSaveConfirmModalNo}
          onCancel={this.handleSaveConfirmModalCancel}
        />
        <CourseContentEditorLayout>
          <CourseContentEditorHeader>
            <CourseContentEditorHeaderWrap>
              <CourseTitle>
                <ChangedIndicator changed={this.changed} tooltip={I18n.t('changedIndicatorTooltip', trOpt)} />
                {I18n.t('common.uLearn')} - {pageTitle}
              </CourseTitle>
              <CourseHeaderLink to={this.slidesPath}>
                <Button type='primary'>{I18n.t('backToCourseContent', trOpt)}</Button>
              </CourseHeaderLink>
            </CourseContentEditorHeaderWrap>
            <CourseContentControls>
              <CourseContentEditorPanel align='left'>
                <Button.Group>
                  <Button type='primary' ghost disabled={!this.isValidSlideId(prevSlide)} onClick={this.handlePrevSlideClick}><Icon type='left-circle' /> {I18n.t('previousSlide', trOpt)}</Button>
                  <Button type='primary' ghost disabled={!this.isValidSlideId(nextSlide)} onClick={this.handleNextSlideClick}>{I18n.t('nextSlide', trOpt)} <Icon type='right-circle' /></Button>
                </Button.Group>
                <LanguageDropdown
                  languageCodes={dropdownLanguages} value={locale} onChange={onLocaleChange}
                  disabled={!(dropdownLanguages && dropdownLanguages.length > 1)}
                />
              </CourseContentEditorPanel>
              <CourseContentEditorPanel align='right'>
                {
                  this.changed
                    ? (
                      <Tooltip title={I18n.t('yourChangesWillNotAppear', trOpt)}>
                        {previewButton}
                      </Tooltip>
                    ) : previewButton
                }
                <Button type='primary' icon='save' onClick={this.handleUpdateClick}>{I18n.t('courses.common.updateCourse')}</Button>
              </CourseContentEditorPanel>
            </CourseContentControls>
          </CourseContentEditorHeader>
          <CourseContentEditorContent padHorizontal='10px'>
            <Editor {...editorProps} />
          </CourseContentEditorContent>
        </CourseContentEditorLayout>
      </>
    )
  }
}

CourseSlideEditor.propTypes = {
  courseId: PropTypes.string,
  slideId: PropTypes.string,
  locale: PropTypes.string,
  dropdownLanguages: PropTypes.arrayOf(PropTypes.string),
  saveSlide: PropTypes.func,
  slide: PropTypes.shape({
    id: PropTypes.string,
    slideId: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.object
  }),
  refetchSlide: PropTypes.func,
  refetchSlides: PropTypes.func,
  courseRandomizeAnswerOrder: PropTypes.bool
}

CourseSlideEditor.defaultProps = {
  courseId: null,
  slideId: null,
  locale: DEFAULT_LANGUAGE,
  dropdownLanguages: [],
  saveSlide: () => {},
  slide: {
    id: null,
    slideId: null,
    type: 'material',
    title: null,
    content: {}
  },
  refetchSlide: () => {},
  refetchSlides: () => {},
  courseRandomizeAnswerOrder: false
}

export default withRouter(CourseSlideEditor)
