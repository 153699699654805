import React, { useCallback } from 'react'

import SendTestEmailButton from './SendTestEmailButton'
import { getSimulationMutationVariables } from '../../helpers/uPhish'

const SendTestEmailMutateButton = ({ disabled, getSimulationData = () => ({}) }) => {
  const getVariables = useCallback(() => getSimulationMutationVariables(getSimulationData()), [getSimulationData])

  return (
    <SendTestEmailButton disabled={disabled} variables={getVariables} />
  )
}

export default SendTestEmailMutateButton
