import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import connect from './connect'
import { creators as viewActions } from '../state/actions/view'
import { viewLoading } from '../state/selectors/view'

// withLoadingBuffer acts a buffer between global state loading and loading state within the wrapping component
// The global state is loading by default and needs to be set to false
// When the global state changes connect re-executes and the props it provides appear as new copies even if the data is the same
// This false positive effect causes useEffect hooks that depend on setLoadingVisible to loop infinitely
// as the setLoadingVisible function becomes a changed prop whenever it is called
const withLoadingBuffer = Comp => {
  const WithLoadingBuffer = ({ loading, setLoadingVisible = () => {}, ...props }) => {
    const [ready, updateReady] = useState(false)
    useEffect(() => {
      if (ready) {
        return
      }

      if (loading) {
        setLoadingVisible(!loading)
      } else {
        updateReady(true)
      }
    }, [loading, setLoadingVisible, ready, updateReady])

    return (
      ready
        ? <Comp {...props} />
        : null
    )
  }

  return React.forwardRef((props, ref) => (
    <WithLoadingBuffer {...props} forwardRef={ref} />
  ))
}

export default compose(
  connect(
    viewLoading,
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    })
  ),
  withLoadingBuffer
)
