/* global localStorage */
import { Modal } from 'antd'

import history from '../constants/history'
import { postSessionChannelMessage, removeSessionToken } from './session'

const LEARNERS_KEY_REGEX = /^learners\|.*\|.*$/
const SIMULATIONS_KEY_REGEX = /^simulations\|.*\|.*$/
const USERVICE_KEY_REGEX = /^uService(.*)\|.*\|.*$/
const COURSES_KEY_REGEX = /^courses\|.*\|.*$/
const COURSE_LIB_KEY_REGEX = /^course-library\|.*\|.*$/
const UPOLICY_KEY_REGEX = /^uPolicy(.*)\|.*\|.*$/
const SETTINGS_KEY_REGEX = /^settings\|.*\|.*$/
const REPORTS_KEY_REGEX = /^reportCentre\|.*\|.*$/
const SIGN_OUT_KEY_PATTERNS = [
  LEARNERS_KEY_REGEX, SIMULATIONS_KEY_REGEX, USERVICE_KEY_REGEX,
  COURSES_KEY_REGEX, COURSE_LIB_KEY_REGEX,
  UPOLICY_KEY_REGEX, SETTINGS_KEY_REGEX, REPORTS_KEY_REGEX
]

export const signOut = (client, redirectRoute = null) => {
  removeSessionToken()
  localStorage.removeItem('lastActivityAt')
  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i)
    const clearKey = SIGN_OUT_KEY_PATTERNS.some(pattern => pattern.test(key))
    if (clearKey) {
      localStorage.removeItem(key)
    }
  }

  Modal.destroyAll()
  client.resetStore()
  // TODO For end user sign out either skip or use end user equivalent helper
  postSessionChannelMessage('sessionSignOut')
  goToRoute(redirectRoute)
}

export const endUserSignOut = (client, redirectRoute = null) => {
  localStorage.removeItem('endUserToken')
  localStorage.removeItem('lastActivityAt')

  Modal.destroyAll()
  client.resetStore()
  goToRoute(redirectRoute)
}
const goToRoute = (redirectRoute) => {
  if (redirectRoute != null) {
    history.push(redirectRoute)
  }
}
