import { useEffect, useCallback } from 'react'
import { connect } from '../hocs'
import { creators as sessionActions } from '../state/actions/session'
import { creators as settingsActions } from '../state/actions/settings'
import { creators as viewActions } from '../state/actions/view'
import { getSessionAndSettingsFromLearnerMe } from '../helpers/getLearnerMe'
import endUserClient from '../apollo-client/endUserClient'
import { getBrowserLocale } from '../helpers/locale'

const EndUserSessionDispatcher = ({ fail, updateSettings, endUserSessionUpdate, updateLocaleOnly }) => {
  const setSessionAndSettings = useCallback(async () => {
    try {
      updateLocaleOnly(getBrowserLocale())
      const { session, settings } = await getSessionAndSettingsFromLearnerMe(endUserClient)
      // Set session
      updateSettings(settings)
      endUserSessionUpdate(session)
    } catch (e) {
      fail(e)
    }
  }, [fail, updateSettings, endUserSessionUpdate, updateLocaleOnly])

  useEffect(() => {
    setSessionAndSettings()
  }, [setSessionAndSettings])

  return null
}

export default connect(
  undefined,
  dispatch => ({
    fail: session => {
      dispatch(sessionActions.fail(session))
      dispatch(viewActions.loading(false))
    },
    endUserSessionUpdate: session => {
      dispatch(sessionActions.endUserUpdate(session))
      dispatch(viewActions.loading(false))
    },
    updateSettings: settings => dispatch(settingsActions.update(settings)),
    updateLocaleOnly: locale => dispatch(sessionActions.updateLocaleOnly(locale))
  })
)(EndUserSessionDispatcher)
