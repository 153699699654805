import React from 'react'
import styled, { withTheme } from 'styled-components'

import { USE_USECURE_BRANDING } from '../../constants/environment'

const SignInHeaderContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  padding-bottom: 1.4rem;
`

const SignInHeaderImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 185px;
`

const SignInHeading = styled.h6`
  color: ${props => props.theme.primary};
  margin-top: 10px;
  font-size: ${({ allowThemeLogo }) => (USE_USECURE_BRANDING || allowThemeLogo) ? 0.9 : 1.6}rem;
`

const SignInHeader = ({ className, children, theme, bottomMargin = '4em', allowThemeLogo = false }) => (
  <SignInHeaderContainer className={className} style={{ marginBottom: bottomMargin }}>
    {(USE_USECURE_BRANDING || allowThemeLogo) && <SignInHeaderImage src={theme.appThemeLogo} />}
    <SignInHeading>{children}</SignInHeading>
  </SignInHeaderContainer>
)

export default withTheme(SignInHeader)
