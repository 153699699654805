import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, DatePicker as _DatePicker, TimePicker as _TimePicker } from 'antd'
import moment from 'moment'
import I18n from 'i18n-js'
import styled from 'styled-components'

import MutationFormErrors from './MutationFormErrors'
import { getMomentFormatString } from '../../helpers/datetime'

const trOpt = { scope: 'mutationForm.mutationFormDateTimePicker' }

const DatePicker = styled(_DatePicker)`
  margin-right: 10px;
`
const TimePicker = styled(_TimePicker)`
  margin-right: 10px;
`

class MutationFormDateTimePicker extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.now
    }

    this.disabledDate = this.disabledDate.bind(this)
    this.disabledHours = this.disabledHours.bind(this)
    this.disabledMinutes = this.disabledMinutes.bind(this)
    this.handleNowClick = this.handleNowClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  static getDerivedStateFromProps (props, state) {
    const { type, value: currentValue } = props

    // Convert string value to moment instance
    if (currentValue) {
      let value
      if (type === 'date') {
        value = moment(currentValue, 'YYYY-MM-DD')
      } else if (type === 'time') {
        value = moment(currentValue, 'HH:mm:ss.SSS')
      } else {
        value = moment(currentValue)
      }
      if (value) {
        return {
          value
        }
      }
    }

    return {
      value: null
    }
  }

  get type () {
    return this.props.type
  }

  get now () {
    const { minuteStep, type } = this.props
    // Get now or next relevant time based upon minuteStep
    let now = moment()
    if (type !== 'date' && minuteStep) {
      now.minutes(Math.ceil(now.minutes() / minuteStep) * minuteStep)
    }
    if (type === 'time') {
      now = moment(now.format('HH:mm'), 'HH:mm')
    } else if (type === 'date') {
      now = moment(now.format('YYYY-MM-DD'), 'YYYY-MM-DD')
    }

    return now
  }

  handleNowClick () {
    this.updateValue(this.now)
  }

  getBoundaryValue (id) {
    let boundary = this.props[id]
    if (boundary === 'now' || boundary === 'today') {
      boundary = this.now
    }
    return boundary
  }

  get min () {
    return this.getBoundaryValue('min')
  }

  get max () {
    return this.getBoundaryValue('max')
  }

  isValid (value, precision = null) {
    const min = this.min
    const max = this.max
    let valid = true

    if (min || max) {
      if (min && max) {
        valid = value.isBetween(min, max, precision, '[]')
      } else if (min) {
        valid = value.isSameOrAfter(min, precision)
      } else if (max) {
        valid = value.isSameOrBefore(max, precision)
      }
    }

    return valid
  }

  disabledDate (currentDate) {
    return !this.isValid(currentDate, 'day')
  }

  disabledHours () {
    const disabledHours = []
    const { value: currentValue } = this.state

    // TODO handle time type
    // moment(now.format('HH:mm'), 'HH:mm')
    if (this.type === 'time' || (currentValue && this.type === 'datetime')) {
      const testValue = moment(this.type === 'time' ? this.now : currentValue)
      testValue.minutes(0)
      for (let hour = 0; hour < 24; hour += 1) {
        testValue.hours(hour)
        if (!(this.isValid(testValue, 'hour') && this.doesHourHaveActiveMinutes(hour))) {
          disabledHours.push(hour)
        }
      }
    }

    return disabledHours
  }

  doesHourHaveActiveMinutes (hour) {
    let active = true
    const { minuteStep } = this.props
    if (minuteStep) {
      const disabledMinutes = this.disabledMinutes(hour)
      let minute = 0
      let activeSteps = 0
      while (minute < 60) {
        if (!disabledMinutes.includes(minute)) {
          activeSteps += 1
        }
        minute += minuteStep
      }

      active = activeSteps > 0
    }
    return active
  }

  disabledMinutes (selectedHour) {
    const disabledMinutes = []
    const { value: currentValue } = this.state

    // if (currentValue && this.type === 'datetime') {
    if (this.type === 'time' || (currentValue && this.type === 'datetime')) {
      const testValue = moment(this.type === 'time' ? this.now : currentValue)
      testValue.hours(selectedHour)
      for (let minute = 0; minute < 60; minute += 1) {
        testValue.minutes(minute)
        if (!this.isValid(testValue, 'minute')) {
          disabledMinutes.push(minute)
        }
      }
    }

    return disabledMinutes
  }

  handleDateChange (value) {
    const { value: currentValue } = this.state
    if (this.type === 'datetime') {
      currentValue.set({
        date: value.date(),
        month: value.month(),
        year: value.year()
      })
      value = currentValue
    }

    this.updateValue(value)
  }

  handleTimeChange (value) {
    const { value: currentValue } = this.state
    if (this.type === 'datetime') {
      currentValue.set({
        hours: value.hours(),
        minutes: value.minutes()
      })
      value = currentValue
    }

    this.updateValue(value)
  }

  updateValue (value) {
    this.updateForm(value)
  }

  updateForm (value = this.state.value) {
    let valueString = null
    if (value) {
      valueString = value.toISOString()
      if (this.type === 'date') {
        valueString = value.format('YYYY-MM-DD')
      } else if (this.type === 'time') {
        valueString = value.format('HH:mm:ss.SSS')
      }
    }
    this.props.onChange(this.props.id, valueString)
  }

  validate (value = this.props.value, errors = [], { submitting = false } = {}) {
    const { type, min, max } = this
    if (!(value && (min || max))) {
      return
    }

    let momentValue
    let precision
    if (type === 'date') {
      momentValue = moment(value, 'YYYY-MM-DD')
      precision = 'day'
    } else if (type === 'time') {
      momentValue = moment(value, 'HH:mm:ss.SSS')
      precision = 'minute'
    } else if (type === 'datetime') {
      momentValue = moment(value)
      precision = 'minute'
    }

    if (min && max && !momentValue.isBetween(min, max, precision, '[]')) {
      errors.push(I18n.t(`${type}OutsideRangeError`, trOpt))
    } else if (min && !momentValue.isSameOrAfter(min, precision)) {
      errors.push(I18n.t(`${type}BeforeMinError`, trOpt))
    } else if (max && !momentValue.isSameOrBefore(max, precision)) {
      errors.push(I18n.t(`${type}AfterMaxError`, trOpt))
    }
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, dateFormat, timeFormat, minuteStep, allowClear, errors = [], showNowButton, disabled, required } = this.props
    const { value } = this.state
    const type = this.type
    const showErrors = errors.length > 0

    return (
      <Form.Item
        label={label} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        {
          (type === 'datetime' || type === 'date') &&
            <DatePicker
              value={value}
              onChange={this.handleDateChange}
              format={dateFormat}
              disabledDate={this.disabledDate}
              disabled={disabled}
              allowClear={allowClear}
            />
        }
        {
          (type === 'datetime' || type === 'time') &&
            <TimePicker
              value={value}
              onChange={this.handleTimeChange}
              format={timeFormat}
              minuteStep={minuteStep}
              disabledHours={this.disabledHours}
              disabledMinutes={this.disabledMinutes}
              allowClear={allowClear}
              disabled={disabled}
            />
        }
        {showNowButton ? <Button type='primary' onClick={this.handleNowClick}>{I18n.t(type === 'date' ? 'today' : 'now', trOpt)}</Button> : null}
      </Form.Item>
    )
  }
}

MutationFormDateTimePicker.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['date', 'time', 'datetime']),
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  minuteStep: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.oneOf(['today', 'now']), PropTypes.instanceOf(moment)]),
  max: PropTypes.oneOfType([PropTypes.oneOf(['today', 'now']), PropTypes.instanceOf(moment)]),
  allowClear: PropTypes.bool,
  showNowButton: PropTypes.bool
}

MutationFormDateTimePicker.defaultProps = {
  id: '',
  type: 'datetime',
  label: null,
  onChange: () => { },
  value: null,
  dateFormat: getMomentFormatString({ type: 'date' }),
  timeFormat: 'HH:mm',
  min: null,
  max: null,
  allowClear: false,
  showNowButton: true
}

export default MutationFormDateTimePicker
