import React, {useCallback, useEffect, useState} from 'react'
import {Modal} from 'antd'
import _cloneDeep from 'lodash/cloneDeep'
import ConfirmTitle from './ConfirmTitle'
import ChangedIndicator from './ChangedIndicator'
import I18n from 'i18n-js'

const { confirm } = Modal
const trOpt = { scope: 'editCourse.editCourseListItem' }

const DefaultItemComponent = ({ item }) => (
  <h3>{I18n.t('item', trOpt)} #{item.id}</h3>
)
const DefaultFormComponent = ({ item }) => (
  <h3>{I18n.t('form', trOpt)} #{item.id}</h3>
)

const EditCourseListItem = ({
  itemName = I18n.t('item', trOpt), modalProps = {},
  item: originalItem, id, index, editing: editModalOpen, setEditing: setEditModalOpen, update, remove, updateInProgress = () => {},
  slideOptions = [],
  itemComponent: ItemComponent = DefaultItemComponent,
  formComponent: FormComponent = DefaultFormComponent
}) => {
  const [item, updateItem] = useState({})
  const [changed, updateChanged] = useState(false)
  const resetItem = useCallback(() => {
    updateItem(_cloneDeep(originalItem))
    updateChanged(false)
  }, [originalItem, updateItem, updateChanged])
  useEffect(() => {
    resetItem()
  }, [originalItem, resetItem])
  useEffect(() => {
    updateInProgress(changed)
  }, [changed, updateInProgress])

  const onOk = useCallback(() => {
    setEditModalOpen(false)
    update(item)
  }, [update, item, setEditModalOpen])
  const revert = useCallback(() => {
    setEditModalOpen(false)
    resetItem()
  }, [setEditModalOpen, resetItem])
  const onCancel = useCallback(() => {
    if (changed) {
      confirm({
        title: (
          <ConfirmTitle>{I18n.t('youHavePendingChangesOnThisItem', { ...trOpt, itemName: itemName.toLowerCase() })}</ConfirmTitle>
        ),
        okText: I18n.t('common.yes'),
        cancelText: I18n.t('common.no'),
        onOk: revert
      })
    } else {
      revert()
    }
  }, [revert, changed, itemName])

  const onChange = useCallback((name, value) => {
    updateItem({
      ...item,
      [name]: value
    })
    updateChanged(true)
  }, [item, updateItem, updateChanged])

  return (
    <>
      <ItemComponent item={originalItem} {...{ id, index, slideOptions }} />
      <Modal
        {...modalProps}
        visible={editModalOpen}
        closable={false}
        maskClosable={false}
        okText={I18n.t('common.save')}
        onOk={onOk}
        onCancel={onCancel}
        title={<span><ChangedIndicator changed={changed} />{I18n.t('common.editItemName', { name: itemName })}</span>}
      >
        <FormComponent {...{ item, id, index, slideOptions, onChange }} />
      </Modal>
    </>
  )
}

export default EditCourseListItem
