import React, { useCallback, useState } from 'react'
import { Button, Form, message } from 'antd'
import I18n from 'i18n-js'
import moment from 'moment'
import _isBoolean from 'lodash/isBoolean'
import _isInteger from 'lodash/isInteger'
import _times from 'lodash/times'

import SettingsForm from '../SettingsForm'
import { SEND_COURSE_REMINDERS_NOW } from '../../Queries/Courses'
import authenticatedClient from '../../../apollo-client/authenticatedClient'
import { modalConfirmAsync, showErrors } from '../../../helpers'
import { useHasSessionPermission } from '../../../hooks'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.courseReminder' }
const DEFAULT_THRESHOLD = moment.duration(7, 'days').asMilliseconds()

const SendReminderNow = React.forwardRef(({ visible, mutation, resultId, threshold, trOpt, baseTrOpt, disableSubmit, requiredPermissions = [], matchAllRequiredPermissions = true }, ref) => {
  const [loading, setLoading] = useState(false)
  const { hasAllSessionPermissions, hasAnySessionPermission } = useHasSessionPermission(requiredPermissions)

  const isAllowed = matchAllRequiredPermissions ? hasAllSessionPermissions(requiredPermissions) : hasAnySessionPermission(requiredPermissions)

  const onClick = useCallback(async () => {
    const confirmed = await modalConfirmAsync({
      content: _isInteger(threshold) ? I18n.t('sendNowDisclaimer', { ...trOpt, count: moment.duration(threshold).asDays() }) : null
    })
    if (!confirmed) return

    setLoading(true)
    try {
      const { data: { [resultId]: success } } = await authenticatedClient.mutate({
        mutation
      })
      if (!success) {
        throw new Error()
      }
      message.success(I18n.t('sendNowSuccessMessage', trOpt))
    } catch (e) {
      showErrors(e, I18n.t('sendNowFailureMessage', trOpt))
    }
    setLoading(false)
  }, [setLoading, mutation, resultId, trOpt, threshold])

  return visible ? (
    <Form.Item
      label={I18n.t('sendNowIntro', baseTrOpt)}
      extra={_isInteger(threshold) ? I18n.t('sendNowDisclaimer', { ...trOpt, count: moment.duration(threshold).asDays() }) : null}
    >
      <Button
        disabled={disableSubmit || !isAllowed}
        type='primary' icon='mail' onClick={onClick}
        loading={loading}
      >{I18n.t('sendNow', baseTrOpt)}
      </Button>
    </Form.Item>
  ) : null
})

class CourseReminder extends SettingsForm {
  constructor (props) {
    super(props)

    this.enabledSettingId = 'courseReminders'
    this.thresholdSettingId = 'courseReminderThresholdMs'
    this.sendReminderNowMutation = SEND_COURSE_REMINDERS_NOW
    this.sendReminderNowMutationResultId = 'sendCourseReminders'
    this.requiredUpdatePermissions = [permissions.SETTINGS_ULEARN_UPDATE]
    this.requiredReminderPermissions = [permissions.COURSE_REMINDER_SEND]

    this.baseTrOpt = trOpt
    this.trOpt = trOpt

    Object.defineProperty(this, 'defaultValue', {
      get: () => this.getDefaultValue()
    })
    Object.defineProperty(this, 'settingIds', {
      get: () => this.getSettingIds()
    })
  }

  getSettingIds () {
    return [
      this.enabledSettingId,
      this.thresholdSettingId
    ]
  }

  getDefaultValue () {
    return {
      [this.enabledSettingId]: false,
      [this.thresholdSettingId]: DEFAULT_THRESHOLD
    }
  }

  get title () {
    return I18n.t('title', this.trOpt)
  }

  get headerId () {
    return 'settings-course-reminder-header'
  }

  get successMsg () {
    return I18n.t('successMessage', this.trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', this.trOpt)
  }

  get _fields () {
    const {
      [this.enabledSettingId]: enabled,
      [this.thresholdSettingId]: thresholdValue
    } = {
      ...this.defaultValue,
      ...this.formValuesFromSettings
    }
    const threshold = enabled ? thresholdValue : 'never'
    const { disableSubmit } = this.props

    return [
      {
        id: this.thresholdSettingId,
        type: 'select',
        label: I18n.t('thresholdLabel', this.trOpt),
        defaultValue: threshold,
        options: [
          { label: I18n.t('dontSendReminder', this.baseTrOpt), value: 'never' },
          ..._times(14, n => ({
            label: I18n.t('everyCountDays', { ...this.baseTrOpt, count: n + 1 }),
            value: moment.duration(n + 1, 'days').asMilliseconds()
          }))
        ],
        required: true
      }, {
        id: 'sendReminderNowSection',
        type: 'custom',
        component: SendReminderNow,
        trOpt: this.trOpt,
        baseTrOpt: this.baseTrOpt,
        mutation: this.sendReminderNowMutation,
        resultId: this.sendReminderNowMutationResultId,
        threshold: threshold === 'never' ? this.defaultValue[this.thresholdSettingId] : threshold,
        visible: !this.defaultTenant,
        requiredPermissions: this.requiredReminderPermissions,
        matchAllRequiredPermissions: this.matchAllRequiredPermissions,
        ...{ disableSubmit }
      }
    ]
  }

  mutateValues (values) {
    const { [this.thresholdSettingId]: threshold } = values
    const enabled = threshold !== 'never'
    return {
      [this.enabledSettingId]: enabled,
      [this.thresholdSettingId]: enabled ? threshold : '%DELETE%'
    }
  }

  onSuccess (result) {
    super.onSuccess(result)
    super.resetFields()
  }

  get formValuesFromSettings () {
    const { [this.thresholdSettingId]: threshold, [this.enabledSettingId]: enabled } = super.formValuesFromSettings || {}
    if (_isBoolean(enabled)) {
      const values = {
        [this.enabledSettingId]: enabled
      }
      if (!enabled) {
        values[this.thresholdSettingId] = 'never'
      } else if (_isInteger(threshold)) {
        values[this.thresholdSettingId] = threshold
      }
      return values
    }
    return {}
  }
}

export default CourseReminder
