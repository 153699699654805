import React, { useState, useEffect, useCallback } from 'react'
import mime from 'mime-types'

const useFavicon = ({ id, rel: initialRel = 'icon', sizes: initialSizes = null, color: initialColor = null, skipType = false }) => {
  const [href, setHref] = useState(null)
  const [rel, setRel] = useState(initialRel)
  const [sizes, setSizes] = useState(initialSizes)
  const [color, setColor] = useState(initialColor)
  const [type, setType] = useState(null)
  const [linkElement, setLinkElement] = useState(null)

  const setFavicon = useCallback((href, { rel, sizes, color } = {}) => {
    if (href) {
      setHref(href)
      if (skipType) {
        setType(null)
      } else {
        setType(mime.lookup(href) || null) // Look mime type from href based on file extension
      }
    } else {
      setHref(null)
      setType(null)
    }
    if (rel) {
      setRel(rel)
    }
    if (sizes) {
      setSizes(sizes)
    }
    if (color) {
      setColor(color)
    }
  }, [skipType])

  useEffect(() => {
    let linkElement = null
    if (href) {
      const linkProps = Object.entries({ id, rel, sizes, type, href, color }).reduce((acc, [id, value]) => {
        if (value) {
          acc[id] = value
        }
        return acc
      }, {})
      linkElement = <link {...linkProps} />
    }
    setLinkElement(linkElement)
  }, [id, href, rel, sizes, type, color])

  return [linkElement, setFavicon]
}

export default useFavicon
