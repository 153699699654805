import React, { useCallback, useState } from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'
import _isEmpty from 'lodash/isEmpty'

import { generateAndZipAllCertificates, generateCertificate, getCertificateLogoInfoFromSrc } from '../../helpers/certificates'
import useGlobalState from '../../hooks/useGlobalState'
import { sessionLocale } from '../../state/selectors/session'

const DownloadCertificateBtn = ({ btnTextKey = 'downloadCertificate', trOpt = { scope: 'common' }, course = null, courses = null, ribbonColour, logoSrc, type = null }) => {
  const [loading, setLoading] = useState(false)
  const { locale } = useGlobalState(
    state => ({ locale: sessionLocale(state) })
  )
  const callGenerateCertificate = useCallback(async () => {
    setLoading(true)
    const logoInfo = await getCertificateLogoInfoFromSrc(logoSrc)
    if (courses && !_isEmpty(courses)) {
      await generateAndZipAllCertificates({ courses, logoSrc, ribbonColour, logoInfo, locale })
    } else if (course && !_isEmpty(course)) {
      const { courseTitle, dateOfCompletion, scorePercentage, presentedToName } = course
      await generateCertificate({ logoSrc, ribbonColour, courseTitle, dateOfCompletion, scorePercentage, presentedToName, logoInfo, locale })
    } else {
      console.error('DownloadCertificateBtn.callGenerateCertificate - ERROR')
      console.error('No course(s) were provided - ERROR')
    }
    setLoading(false)
  }, [logoSrc, course, courses, ribbonColour, locale])

  return (
    <>
      <Button type={type} onClick={callGenerateCertificate} icon='download' loading={loading}>{I18n.t(btnTextKey, trOpt)}</Button>
    </>
  )
}

export default DownloadCertificateBtn
