import React, { useCallback, useMemo, useState } from 'react'
import { Button, Input, Modal, Radio, Tag } from 'antd'
import styled from 'styled-components'
import CSVReader from 'react-csv-reader'
import I18n from 'i18n-js'
import _difference from 'lodash/difference'
import _isEmpty from 'lodash/isEmpty'
import _isString from 'lodash/isString'
import _uniq from 'lodash/uniq'

import { withRenderDelay } from '../../hocs'
import { getWaitForIndex, validateEmail } from '../../helpers'
import { renderListFragmentFromArray } from '../../helpers/renderFromArray'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const HeaderPanel = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-btn {
    margin-left: 8px;
  }
`

const EmailContainer = styled.div`
  display: flex;
`

const EmailInputContainer = styled.div`
  max-width: 350px;

  .ant-form-explain {
    margin-top: 5px;
  }
`

const _EmailTag = withRenderDelay(({ className, hiddenClassName, closable = true, email, onClose: onCloseProp = () => {} }) => {
  const onClose = useCallback(() => onCloseProp(email), [email, onCloseProp])

  return (
    <Tag
      className={`${className || ''} ${hiddenClassName || ''}`.trim()}
      visible {...{ closable, onClose }}
    >{email}
    </Tag>
  )
})

const EmailTag = styled(_EmailTag)`
  font-size: 14px;
  margin-top: 8px;
  padding: 3px 7px;
`

const EmailTagContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
`

const UploadTagContainer = styled.div`
  max-height: 300px;
  overflow: auto;
`

export const EmailTags = ({ emails, Container = EmailTagContainer, closable, onClose }) => (
  <Container>
    {emails.map((email, index) => (
      <EmailTag
        key={index} {...{ email, closable, onClose }}
        wait={getWaitForTag(index)}
      />
    ))}
  </Container>
)

const getWaitForTag = index => getWaitForIndex(index, 0, 100, 10)

const EmailUploaderModal = ({ uploadVisible = false, closeModal, updateList = () => {}, filterEmails = emails => emails, trOpt }) => {
  const [mode, updateMode] = useState('add')
  const [hasEmails, updateHasEmails] = useState(false)
  const [uploaded, updateUploaded] = useState(false)
  const [emails, updateEmails] = useState([])

  const afterClose = useCallback(() => {
    updateEmails([])
    updateMode('add')
    updateUploaded(false)
  }, [updateEmails, updateMode, updateUploaded])

  const handleCsvData = useCallback(data => {
    // This loading state change doesn't render in browser
    const emails = data.reduce((emails, row) => {
      return [
        ...emails,
        ...row.filter(value => _isString(value) && validateEmail(value))
          .map(email => email.trim().toLowerCase())
      ]
    }, [])
    updateHasEmails(!_isEmpty(emails))
    updateUploaded(true)
    updateEmails(emails)
  }, [updateEmails, updateUploaded, updateHasEmails])

  const emailList = useMemo(() => {
    if (!uploaded) {
      return []
    }

    let emailList = []
    if (mode === 'replace') {
      emailList = emails
    } else if (mode === 'add') {
      emailList = _isEmpty(emails) ? [] : filterEmails(emails)
    }

    updateHasEmails(!_isEmpty(emailList))

    return emailList
  }, [mode, emails, uploaded, filterEmails, updateHasEmails])

  const removeFromEmails = useCallback(value => {
    updateEmails(emailList.filter(email => email !== value))
  }, [emailList, updateEmails])
  const onModeChange = useCallback(e => updateMode(e.target.value), [updateMode])

  const onOk = useCallback(() => {
    closeModal()
    setTimeout(() => updateList(mode, emailList), 500)
  }, [updateList, mode, emailList, closeModal])

  return (
    <Modal
      visible={uploadVisible}
      title={I18n.t('addEmailAddressesViaCSV', trOpt)}
      onCancel={closeModal}
      {...{ onOk, afterClose }}
      destroyOnClose
    >
      <ul style={{ paddingLeft: 20 }}>
        {renderListFragmentFromArray(I18n.t('addEmailAddressesList', trOpt))}
      </ul>
      <CSVReader
        onFileLoaded={handleCsvData}
        inputId='csvUpload'
      />
      <div style={{ marginTop: 15 }}>
        <h4>{I18n.t('uploadType', trOpt)}</h4>
        <Radio.Group
          value={mode}
          onChange={onModeChange}
          options={[
            { value: 'add', label: I18n.t('addEmails', trOpt) },
            { value: 'replace', label: I18n.t('replaceEmails', trOpt) }
          ]}
        />
      </div>
      {
        uploaded && (
          hasEmails
            ? (
              <div style={{ marginTop: 15 }}>
                <h4>{I18n.t('uploadedEmails', trOpt)}</h4>
                <EmailTags emails={emailList} onClose={removeFromEmails} Container={UploadTagContainer} />
              </div>
            )
            : <h4 style={{ textAlign: 'center', marginTop: 30 }}>{I18n.t('noNewEmailsFound', trOpt)}</h4>
        )
      }
    </Modal>
  )
}

const EmailInputPanel = ({ emailInList = () => {}, addToList = () => {}, trOpt }) => {
  const [value, updateValue] = useState(null)
  const [error, updateError] = useState(null)

  const onInputChange = useCallback(e => {
    updateValue(e.currentTarget.value || null)
    updateError(null)
  }, [updateValue, updateError])
  const onAddClick = useCallback(() => {
    const newValue = value ? value.trim().toLowerCase() : null
    if (!(newValue && validateEmail(newValue))) {
      return updateError(I18n.t('invalidEmailError', trOpt))
    }
    if (emailInList(newValue)) {
      return updateError(I18n.t('emailInListError', trOpt))
    }

    updateValue(null)
    updateError(null)
    addToList(newValue)
  }, [value, updateValue, updateError, addToList, emailInList, trOpt])

  return (
    <EmailContainer>
      <EmailInputContainer className={error ? 'has-error' : undefined}>
        <Input
          placeholder={I18n.t('emailAddress', trOpt)} allowClear
          value={value}
          onChange={onInputChange}
          onPressEnter={onAddClick}
        />
        <div className='ant-form-explain'>{error}</div>
      </EmailInputContainer>
      <Button icon='plus' type='primary' onClick={onAddClick}>{I18n.t('common.add')}</Button>
    </EmailContainer>
  )
}

const SyncSetupUserEmailBlacklist = ({ list, onChange, footer, trOpt = { scope: 'syncSetup.syncSetupUserEmailBlacklist' } }) => {
  const [uploadVisible, setUploadVisible] = useState(false)

  const emailInList = useCallback(value => list.includes(value), [list])
  const addToList = useCallback(value => onChange([value, ...list]), [list, onChange])
  const removeFromList = useCallback(value => {
    onChange(list.filter(email => email !== value))
  }, [list, onChange])

  const clearList = useCallback(() => {
    Modal.confirm({
      title: I18n.t('clearListConfirmDialog', trOpt),
      onOk: () => onChange([])
    })
  }, [onChange, trOpt])

  const openModal = useCallback(() => {
    setUploadVisible(true)
  }, [setUploadVisible])
  const closeModal = useCallback(() => {
    setUploadVisible(false)
  }, [setUploadVisible])

  const filterEmails = useCallback(emails => _difference(emails, list), [list])

  const updateList = useCallback((mode, emails) => {
    const hasEmails = !_isEmpty(emails)
    if (hasEmails && mode === 'add') {
      onChange(
        _uniq([
          ...emails,
          ...list
        ])
      )
    } else if (hasEmails && mode === 'replace') {
      onChange(emails)
    }
  }, [list, onChange])

  return (
    <>
      <ContentContainer>
        <h3>{I18n.t('sync.common.userEmailBlacklist')}</h3>
        <HeaderPanel>
          <EmailInputPanel {...{ emailInList, addToList, trOpt }} />
          <div>
            <Button icon='upload' onClick={openModal}>{I18n.t('uploadEmailAddresses', trOpt)}</Button>
            <Button icon='delete' type='danger' ghost onClick={clearList}>{I18n.t('clearList', trOpt)}</Button>
          </div>
        </HeaderPanel>
        <EmailTags emails={list} onClose={removeFromList} {...{ trOpt }} />
      </ContentContainer>
      <EmailUploaderModal
        {...{ uploadVisible, updateList, filterEmails, closeModal, trOpt }}
      />
    </>
  )
}

export default SyncSetupUserEmailBlacklist
