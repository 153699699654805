/* global localStorage */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Modal, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'

import { ContentWrap, ListHeader, ListHeaderPanel } from '../../components/common'
import PolicyForm from '../../components/Policies/PolicyForm'
import { CREATE_POLICY_TEMPLATE } from '../../components/Queries/uPolicy'
import routes from '../../constants/routes'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import IntercomHeader from '../../components/IntercomHeader'

const trOpt = { scope: 'uPolicy.createPolicyTemplate' }
const commonTrOpt = { scope: 'uPolicy.common' }

const CreatePolicyTemplate = ({ history, companyId, userId }) => {
  const [policy, setPolicy] = useState(null)
  const storageId = useMemo(() => `createPolicyTemplate|${companyId}|${userId}`, [companyId, userId])

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.reset()
    }
  }, [form])
  const replaceValues = useCallback(values => {
    if (form && form.current) {
      form.current.replaceValues(values)
    }
  }, [form])

  const handleFormFieldChange = useCallback((name, value) => {
    setPolicy({
      ...(policy || {}),
      [name]: value
    })
  }, [policy, setPolicy])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successfullyCreatedPolicyTemplate', trOpt))
    if (storageId) {
      localStorage.removeItem(storageId)
    }
    return history.push(routes.UPOLICY_TEMPLATE_BUILDER)
  }, [history, storageId])

  // load working copy from local storage prompting user if they want to start again
  useEffect(() => {
    if (!storageId) {
      return
    }
    try {
      const storedString = localStorage.getItem(storageId)
      if (storedString) {
        const storedObj = JSON.parse(storedString)
        setPolicy(storedObj)
        replaceValues(storedObj)
        Modal.confirm({
          title: I18n.t('common.resumeConfirmDialog.title'),
          okText: I18n.t('common.resumeConfirmDialog.ok'),
          cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
          onCancel: async () => {
            localStorage.removeItem(storageId)
            setPolicy(null)
            return resetForm()
          }
        })
      }
    } catch (e) {}
  }, [storageId, resetForm, replaceValues])

  // Save working copy to local storage
  useEffect(() => {
    if (storageId && !_isNil(policy)) {
      localStorage.setItem(storageId, JSON.stringify(policy))
    }
  }, [storageId, policy])

  return (
    <ContentWrap>
      <Card>
        <IntercomHeader Size='h1' id='uPolicy-template-create-header'>{I18n.t('createTemplate', commonTrOpt)}</IntercomHeader>
        <ListHeader>
          <ListHeaderPanel>
            <Link to={routes.UPOLICY_TEMPLATE_BUILDER}>
              <Button type='primary'>{I18n.t('backToTemplateBuilder', trOpt)}</Button>
            </Link>
          </ListHeaderPanel>
        </ListHeader>
        <PolicyForm
          ref={form}
          mutation={CREATE_POLICY_TEMPLATE}
          onFailure={I18n.t('failedToUpdatePolicyTemplate', trOpt)}
          submitLabel={I18n.t('createTemplate', commonTrOpt)}
          isTemplate
          create
          onChange={handleFormFieldChange}
          saveActions={['saveDraft', 'publish']}
          {...{ onSuccess }}
        />
      </Card>
    </ContentWrap>
  )
}

export default compose(
  withRouter,
  connect(
    state => _pick(selectors.session.get(state), ['userId', 'companyId'])
  )
)(CreatePolicyTemplate)
