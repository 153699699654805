import React, { useEffect, useState, useCallback } from 'react'
import { Form, Select, Spin } from 'antd'
import { useLazyQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _debounce from 'lodash/debounce'
import _isString from 'lodash/isString'
import _uniqBy from 'lodash/uniqBy'

import MutationFormErrors from './MutationFormErrors'
import { COMPANY_AUTOCOMPLETE_LOOKUP } from '../Queries/Companies'
import { showErrors } from '../../helpers'

const { Option } = Select

const MutationFormCompanyAutoComplete = React.forwardRef(({
  id,
  value,
  onChange,
  label,
  extra,
  formItemStyle,
  required,
  showErrors: showFormErrors,
  errors,
  disabled,
  accountType,
  includeDirect = false,
  resultsLimit = 50,
  initialOptions
}, ref) => {
  const [prependOptionsWithDirect, setPrependOptionsWithDirect] = useState(false)
  const [open, setOpen] = useState(false)
  const [prevValueOptions, setPrevValueOptions] = useState([]) // This should prevent the field's current value showing a company id instead of a company value
  const [options, setOptions] = useState([]) // Options from company search results

  const handleChange = useCallback((companyId, option) => {
    // Adds this value to the previously selected set to prevent the field showing a company id isntead of a name when the dropdown is closed
    setPrevValueOptions(prevValueOptions =>
      _uniqBy([...prevValueOptions, { value: companyId, label: option?.props?.label }], 'value')
    )
    onChange(id, companyId)
  }, [id, onChange])

  const [runQuickSearch, { loading, data }] = useLazyQuery(COMPANY_AUTOCOMPLETE_LOOKUP, {
    fetchPolicy: 'no-cache',
    onError: e => showErrors(e, I18n.t('common.companiesLoadError'))
  })

  useEffect(() => {
    setPrevValueOptions(prevValueOptions =>
      _uniqBy([...prevValueOptions, ...(initialOptions ?? [])], 'value')
    )
  }, [initialOptions])

  useEffect(() => {
    const options = (data?.uServiceCompanyQuickSearch ?? []).map(({ id, name }) => ({ value: id, label: name }))
    if (prependOptionsWithDirect) {
      options.unshift({ value: 'direct', label: I18n.t('common.managedByUsecure') })
    }
    setOptions(options)
  }, [data, prependOptionsWithDirect])

  const onDropdownVisibleChange = open => {
    setOpen(open)
    if (!open) {
      setOptions([])
    }
  }

  const handleSearch = useCallback(
    _debounce(
      searchTerm => {
        if (_isString(searchTerm) && searchTerm.length > 0) {
          setPrependOptionsWithDirect(includeDirect && I18n.t('common.managedByUsecure').toLowerCase().includes(searchTerm.toLowerCase()))

          const queryVariables = { searchTerm, accountType, limit: resultsLimit }
          // Run new search
          runQuickSearch({ variables: queryVariables })
        }
      }, 500),
    [runQuickSearch, resultsLimit, includeDirect, accountType]
  )

  return (
    <Form.Item
      label={label} extra={extra} style={formItemStyle} required={required}
      validateStatus={showFormErrors ? 'error' : undefined}
      help={showFormErrors ? <MutationFormErrors visible={showFormErrors} errors={errors} /> : null}
    >
      <Select
        name={id}
        onSelect={handleChange}
        onSearch={handleSearch}
        optionFilterProp='label'
        mode='default'
        showSearch
        {...{ value, required, disabled, loading }}
        onDropdownVisibleChange={onDropdownVisibleChange}
        notFoundContent={loading ? <Spin size='small' /> : I18n.t('mutationForm.mutationFormCompanyAutoComplete.emptyListLabel')}
        allowClear={open}
      >
        {
          (open ? options : prevValueOptions).map((option, index) => (
            <Option key={index} value={option.value} label={option.label}>{option.label}</Option>
          ))
        }
      </Select>
    </Form.Item>
  )
})

export default MutationFormCompanyAutoComplete
