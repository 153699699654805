import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import I18n from 'i18n-js'
import { BrandColours } from '../../../helpers/Graphs'

const trOpt = { scope: 'reports.uLearnReport' }

const uLearnBarChart = ({ scoreBarChartData }) => {
  // score token supplied to completedCoursesScoredBetween copy as translations don't include top and bottom yet
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4 style={{ margin: 0 }}>{I18n.t('numberOfCompletedCoursesVsScore', trOpt)}</h4>
      </div>
      <div style={{ height: '500px' }}>
        <ResponsiveBar
          data={scoreBarChartData}
          keys={['courseResults']}
          indexBy='score'
          margin={{ top: 60, right: 90, bottom: 90, left: 90 }}
          padding={0.5}
          groupMode='grouped'
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={BrandColours}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `${I18n.t('score', trOpt)} %`,
            legendPosition: 'middle',
            legendOffset: 52
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: I18n.t('numberOfCompletedCourses', trOpt),
            legendPosition: 'middle',
            legendOffset: -60
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          animate
          motionStiffness={90}
          motionDamping={15}
          tooltip={(props) => {
            const bottom = props.data.score.split('-')[0]
            const top = props.data.score.split('-')[1]
            return (
              <div
                style={{
                  padding: 12,
                  color: props.color
                }}
              >
                <span>
                  {I18n.t('completedCoursesScoredBetween', { count: props.data.courseResults, score: props.data.score, top, bottom, ...trOpt })}
                </span>
              </div>
            )
          }}
        />
      </div>

    </div>
  )
}

export default uLearnBarChart
