import React, { useMemo } from 'react'
import I18n from 'i18n-js'
import { Empty, Table } from 'antd'
import moment from 'moment'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'

import { renderDifficultyTagCell } from './common'
import { LIST_PAGINATION_PROPS } from '../../constants/list'
import { formatDateTime } from '../../helpers/datetime'

const trOpt = { scope: 'learner.upcomingCourses' }
const tableTrOpt = { scope: 'learner.courseTable' }

/*
This section is shown if:
  - Learner's company has auto enrol enabled
  - Learner's company has course re-enrolment enabled
 - If not learner must be in their first auto enrol cycle
This applies even if the learner schedule is empty
And similarly if they've completed their first auto cycle when course re-enrolment is disabled.
There's a argument to say we shouldn't display this section for that last use case.
As this section was implemented as a bonus feature we'll wait to see if anyone feeds this known issue back as a bug.
*/

const UpcomingCourses = ({ courseScheduleData }) => {
  const columns = [
    {
      title: I18n.t('common.courseTitle'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: I18n.t('level', tableTrOpt),
      dataIndex: 'level',
      key: 'level',
      sorter: (a, b) => a.level - b.level,
      render: renderDifficultyTagCell
    },
    {
      title: I18n.t('sendOutDate', trOpt),
      dataIndex: 'sendOutDateStr',
      key: 'sendOutDate',
      sorter: (a, b) => {
        return (a.sendOutDate ? moment(a.sendOutDate).unix() : 0) - (b.sendOutDate ? moment(b.sendOutDate).unix() : 0)
      },
      defaultSortOrder: 'ascend'
    }
  ]
  const { data, enabled = false } = useMemo(() => {
    const { enabled, scheduledCourses } = courseScheduleData
    let data = []
    if (_isArray(scheduledCourses)) {
      data = scheduledCourses.map(scheduledCourse => {
        const { courseId, course, sendOutDate } = scheduledCourse
        return {
          key: courseId,
          name: _get(course, 'name') || I18n.t('common.unknown'),
          level: _get(course, 'difficulty'),
          sendOutDate,
          sendOutDateStr: formatDateTime(sendOutDate)
        }
      })
    }
    return {
      enabled: enabled === true,
      data
    }
  }, [courseScheduleData])

  if (!enabled) return null

  return (
    <>
      <h1>{I18n.t('title', trOpt)}</h1>
      <Table
        dataSource={data} columns={columns} pagination={LIST_PAGINATION_PROPS}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={I18n.t('empty', trOpt)} />
        }}
      />
    </>
  )
}

export default UpcomingCourses
