import React, { useState, useCallback } from 'react'
import { Button, message, Icon, Alert } from 'antd'
import { useMutation, useQuery } from 'react-apollo'
import I18n from 'i18n-js'

import { GET_RECOVERY_KEYS, GENERATE_RECOVERY_KEYS, CREATE_RECOVERY_KEYS, REGENERATE_RECOVERY_KEYS } from '../Queries/Users'
import { UsecureError, modalConfirmAsync, showErrors } from '../../helpers'
import RecoveryKeysCard from './RecoveryKeysCard'

const trOpt = { scope: 'accountSettings.recoveryKeys' }

const RecoveryKeys = ({ is2FAEnabled, setCurrentStep }) => {
  const [recoveryKeys, setRecoveryKeys] = useState([])
  const [allowNextStep, setAllowNextStep] = useState(false)
  const [callGenerateRecoveryKeys] = useMutation(GENERATE_RECOVERY_KEYS)
  const [regenerateRecoveryKeys] = useMutation(REGENERATE_RECOVERY_KEYS)
  const [createRecoveryKeys] = useMutation(CREATE_RECOVERY_KEYS)
  // This function calls the server for a new set of recovery codes. The value returned is not tied to the user
  // The keys returned are used during MFA setup and recovery code regeneration
  const generateRecoveryKeys = useCallback(async () => {
    const result = await callGenerateRecoveryKeys()
    const { generateRecoveryKeys: keys } = result?.data ?? {}
    if (!keys) throw new UsecureError(I18n.t('common.somethingWentWrong'))

    return keys
  }, [callGenerateRecoveryKeys])
  const { refetch: getRecoveryKeys, loading: getRecoveryKeysLoading } = useQuery(GET_RECOVERY_KEYS, {
    fetchPolicy: 'no-cache',
    onCompleted: useCallback(async data => {
      if (data?.getRecoveryKeys && is2FAEnabled) {
        const keys = data.getRecoveryKeys.map(item => item.recoveryKey)
        setRecoveryKeys(keys)
      } else if (!is2FAEnabled) {
        const keys = await generateRecoveryKeys()
        setRecoveryKeys(keys)
      }
    }, [is2FAEnabled, generateRecoveryKeys]),
    onError: e => showErrors(e, I18n.t('common.somethingWentWrong'))
  })

  // Used to regenerate keys from the Account Settings Security (2FA) view
  const confirmNewKeys = useCallback(async () => {
    const confirm = await modalConfirmAsync({
      title: I18n.t('title', trOpt),
      icon: <Icon type='warning' />,
      content: I18n.t('thisWillInvalidateYourExisting', trOpt)
    })
    if (confirm) {
      try {
        await regenerateRecoveryKeys()

        // Retrieve keys for current admin user and update state to display them
        const result = await getRecoveryKeys()
        if (result?.data?.getRecoveryKeys) {
          const keys = result.data.getRecoveryKeys.map(item => item.recoveryKey)
          setRecoveryKeys(keys)
        }

        message.success(I18n.t('newRecoveryCodesGenerated', trOpt))
      } catch (e) {
        message.error(I18n.t('common.somethingWentWrong'))
      }
    }
  }, [getRecoveryKeys, regenerateRecoveryKeys])

  // Next button during MFA setup, saves recovery keys to the user's account
  const onNextClick = useCallback(async () => {
    if (!allowNextStep) return // User must download, copy or print their recovery codes first
    try {
      await createRecoveryKeys({ variables: { recoveryKeys } })
      setCurrentStep('QRCode')
    } catch (e) {
      showErrors(e, I18n.t('common.somethingWentWrong'))
    }
  }, [createRecoveryKeys, recoveryKeys, setCurrentStep, allowNextStep])

  const goToTwoFactorSettings = useCallback(() => setCurrentStep('twoFactorSettings'), [setCurrentStep])
  const handleCancelClick = useCallback(async () => {
    if (is2FAEnabled) {
      setCurrentStep('twoFactorSettings')
    } else {
      const confirm = await modalConfirmAsync({
        title: I18n.t('common.cancelConfirmDialog')
      })
      if (confirm) {
        setCurrentStep('disabled')
      }
    }
  }, [setCurrentStep, is2FAEnabled])

  return (
    <div>
      {is2FAEnabled && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            style={{ padding: 0, margin: '0 0 25px 0' }}
            type='link' icon='arrow-left'
            onClick={goToTwoFactorSettings}
          >
            {I18n.t('accountSettings.common.backToSettings')}
          </Button>
          <Button type='primary' onClick={confirmNewKeys}>{I18n.t('generateNewRecoveryKeys', trOpt)}</Button>
        </div>
      )}
      <RecoveryKeysCard
        title={is2FAEnabled ? I18n.t('recoveryKeys', trOpt) : I18n.t('accountSettings.common.stepTitle', { number: 1, title: I18n.t('generateNewRecoveryKeys', trOpt) })}
        alerts={
          !is2FAEnabled ? (
            <Alert
              description={I18n.t('downloadPrintOrCopyYourRecoveryKeys', trOpt)}
              type='warning'
              style={{ margin: '20px 0' }}
            />
          ) : null
        }
        recoveryKeys={recoveryKeys}
        loading={getRecoveryKeysLoading}
        setAllowNextStep={setAllowNextStep}
      />
      {!is2FAEnabled && (
        <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type='primary'
            onClick={onNextClick}
            disabled={!allowNextStep}
          >
            {I18n.t('common.next')}
          </Button>
          <Button
            onClick={handleCancelClick}
          >
            {I18n.t('common.cancel')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default React.memo(RecoveryKeys)
