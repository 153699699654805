import React, { Component } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import styled, { keyframes, css } from 'styled-components'
import { rgba, lighten } from 'polished'

import { PLACEMENT_PREVIEW_MAP, ANT_PLACEMENT_CLASS_SUFFIX_MAP } from '../EditCourse/EditCourseAnnotatedImagePoint'

const primaryLighten = (amount, { theme }) => lighten(amount, theme.primary)
const primaryLightenAlpha = (amount, alpha, props) => rgba(primaryLighten(amount, props), alpha)

const PointPulse = props => keyframes`
  0% {
    -moz-box-shadow: 0 0 0 0 ${primaryLightenAlpha(0.1, 0.4, props)};
    box-shadow: 0 0 0 0 ${primaryLightenAlpha(0.1, 0.4, props)};
  }
  70% {
      -moz-box-shadow: 0 0 0 10px ${primaryLightenAlpha(0.1, 0, props)};
      box-shadow: 0 0 0 10px ${primaryLightenAlpha(0.1, 0, props)};
  }
  100% {
      -moz-box-shadow: 0 0 0 0 ${primaryLightenAlpha(0.1, 0, props)};
      box-shadow: 0 0 0 0 ${primaryLightenAlpha(0.1, 0, props)};
  }
`

const Point = styled.div`
  background-color: ${({ active, theme }) => rgba(theme.primary, active ? 1 : 0.6)};
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  border-radius: 50%;
  touch-action: none;
  width: 3vw;
  max-width: 30px;
  height: 3vw;
  max-height: 30px;
  position: absolute;
  cursor: pointer;
  transition: background-color 0.2s;
  box-sizing: border-box;
  border: solid 2px ${props => primaryLighten(0.2, props)};
  ${props => props.noPulse ? css`` : css`animation: ${PointPulse} 3s infinite;`}
`

const PopoverContent = styled.div`
  max-width: 500px;
  p {
    white-space: pre-wrap;
  }
`

const AntPopover = styled.div`
  transform-origin: ${props => props.origin};
  transition: opacity 0.2s, transform 0.2s;
  opacity: ${props => props.open ? 1 : 0};
  transform: ${props => `scale(${props.open ? 1 : 0})`};
  
  .ant-popover-inner-content {
    border-top: 2px solid ${({ theme }) => theme.primary};
    font-weight: 500;
    font-size: 1rem;
    box-shadow: 0 0 1rem rgba(0,0,0,.5);
  }
`

class LearnerCourseAnnotatedImagePoint extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      active: false,
      noPulse: false
    }

    this.handleClick = this.handleClick.bind(this)
    this.handlePopoverClick = this.handlePopoverClick.bind(this)
    this.renderPopover = this.renderPopover.bind(this)
  }

  handleClick (e) {
    e.stopPropagation()
    this.props.onVisibleChange(this.props.id, !this.props.active)
  }

  handlePopoverClick (e) {
    e.stopPropagation()
  }

  get placement () {
    const { placement = 'T' } = this.props
    return PLACEMENT_PREVIEW_MAP[placement]
  }

  componentDidUpdate (prevProps) {
    const { active, lastActiveChange } = this.props
    const { active: prevActive, lastActiveChange: prevLastActiveChange } = prevProps

    if (active !== prevActive) {
      if (active) {
        this.setState({ active: true }, () => {
          this.setState({ open: true })
        })
      } else {
        this.setState({ open: false }, () => {
          setTimeout(() => this.setState({ active: false }), 200)
        })
      }
    }

    // Synchronise pulse Animation
    if (lastActiveChange !== prevLastActiveChange) {
      this.setState({ noPulse: true }, () => {
        setTimeout(() => this.setState({ noPulse: false }), 200)
      })
    }
  }

  renderPopover ({ ref, style, placement, arrowProps }) {
    const { open } = this.state
    const { text } = this.props
    const halfArrowSize = 6

    let transformOriginX = 'center'
    let transformOriginY = 'bottom'

    if (/^bottom/.test(placement)) {
      transformOriginY = 'top'
    }
    if (/^(top|bottom)/.test(placement)) {
      transformOriginX = `${arrowProps.style.left + halfArrowSize}px`
    }

    if (/^left/.test(placement)) {
      transformOriginX = 'right'
    }
    if (/^right/.test(placement)) {
      transformOriginX = 'left'
    }
    if (/^(left|right)/.test(placement)) {
      transformOriginY = `${arrowProps.style.top + halfArrowSize}px`
    }

    const antPlacementClassSuffix = ANT_PLACEMENT_CLASS_SUFFIX_MAP[placement] || placement

    // Overriding transform on arrow .ant-popover-placement-* adds a translateX transform which isn't required
    return (
      <div ref={ref} style={{ ...style, zIndex: 100 }} data-placement={placement} onClick={this.handlePopoverClick}>
        <AntPopover open={open} origin={`${transformOriginX} ${transformOriginY}`} className={`ant-popover-placement-${antPlacementClassSuffix}`}>
          <div className='ant-popover-content'>
            <div className='ant-popover-arrow' ref={arrowProps.ref} style={{ ...arrowProps.style, transform: 'rotate(45deg)' }} />
            <div className='ant-popover-inner' role='tooltip'>
              <div>
                <div className='ant-popover-inner-content'>
                  <PopoverContent>
                    <p>{text}</p>
                  </PopoverContent>
                </div>
              </div>
            </div>
          </div>
        </AntPopover>
      </div>
    )
  }

  render () {
    const { posX, posY, hide } = this.props
    const { active, open, noPulse } = this.state

    if (hide) {
      return null
    }

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <Point
              ref={ref} active={open} noPulse={active || noPulse}
              onClick={this.handleClick}
              style={{
                top: `${posY}px`,
                left: `${posX}px`
              }}
            />
          )}
        </Reference>
        {active
          ? (
            <Popper placement={this.placement} modifiers={{ preventOverflow: true }}>
              {this.renderPopover}
            </Popper>
          )
          : null}
      </Manager>
    )
  }
}

export default LearnerCourseAnnotatedImagePoint
