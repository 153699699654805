import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import LearnerCourse from '../components/LearnerCourse/LearnerCourse'
import { ANSWER_LEARNER_COURSE_QUESTION, FINISH_LEARNER_COURSE, RETRY_LEARNER_COURSE, START_LEARNER_COURSE } from '../components/Queries/LearnerCourses'
import { creators as viewActions } from '../state/actions/view'
import { creators as sessionActions } from '../state/actions/session'
import { creators as settingsActions } from '../state/actions/settings'
import { connect, withConsumer, withProvider } from '../hocs'
import DemoMode from '../helpers/DemoMode'
import routes from '../constants/routes'
import unauthenticatedClient from '../apollo-client/unauthenticatedClient'
import { addDelay } from '../helpers'

const LEARNER_COURSE_MUTATION_NAMES = {
  start: 'startCourse',
  answer: 'answerCourseQuestion',
  retry: 'retryCourse',
  finish: 'finishCourse'
}
const LEARNER_COURSE_MUTATIONS = {
  start: START_LEARNER_COURSE,
  answer: ANSWER_LEARNER_COURSE_QUESTION,
  retry: RETRY_LEARNER_COURSE,
  finish: FINISH_LEARNER_COURSE
}

// Minimum time each mutation should appear to take so that loading indicator don't blip in and out
const LEARNER_COURSE_MUTATION_DELAY = {
  start: 800,
  answer: 1000,
  retry: 800,
  finish: 1000
}
const GAP_ANALYSIS_MUTATION_DELAY = {
  ...LEARNER_COURSE_MUTATION_DELAY,
  answer: 800,
  finish: -1 // Disabled on Gap Analysis as finish is never user triggered and each of the 12 GA courses makes a finish call
}

// CL - LearnerCourseMutation is a remnant from when use of React Apollo's Mutation component required that we pass a mutate function around to execute a mutation
// This has been replaced with an ad hoc mutate call via an Apollo Client instance.
// Ideally this refactor would go further and combine LearnerCourseMutation with components/LearnerCourse/LearnerCourse.
// Use of the Mutation component is why they were split in the first place.
// I have decided against this for the time being given the testing required and the upcoming client app rebuild.
// I also refrained from converting this to a functional component for the same reason

export class LearnerCourseMutation extends Component {
  constructor (props) {
    super(props)

    const { match: { params: { learnerId = null, courseId = null, course_id: previewCourseId = null, slide_id: previewSlideId, locale: previewLocale } = {} } = {} } = props
    this.state = {
      id: null,
      loading: false, // The loading state of the in progress mutation when this.update is called.
      // The data from the mutation upon completion. This will be be null until a mutation is called
      data: {
        learnerId,
        courseId: courseId || previewCourseId
      },
      learnerId,
      courseId: courseId || previewCourseId,
      previewSlideId,
      previewLocale,
      isGapAnalysis: false
    }

    if (props.preview) {
      DemoMode.enable()
    }

    this.update = this.update.bind(this)
    this.updateId = this.updateId.bind(this)
    this.updateCourseId = this.updateCourseId.bind(this)
    this.setIsGapAnalysis = this.setIsGapAnalysis.bind(this)
  }

  get fromBuilder () {
    const { preview, match: { path } = {} } = this.props
    return preview && path !== routes.COURSE_LIBRARY_PREVIEW
  }

  getMutationVariables ({ action, questionId = null, answerIds = [], courseId = this.state.courseId } = {}) {
    const { preview = false } = this.props
    const { id, learnerId } = this.state

    const variables = { id, preview, learnerId, courseId, fromBuilder: this.fromBuilder }
    if (action === 'answer') {
      variables.questionId = questionId
      variables.answerIds = answerIds
    }
    return variables
  }

  updateCourseId (courseId) {
    this.setState({ courseId })
  }

  updateId (id) {
    this.setState({ id })
  }

  setIsGapAnalysis (isGapAnalysis) {
    this.setState({ isGapAnalysis })
  }

  async update ({ action, questionId = null, answerIds = [], courseId }) {
    const delay = (this.state.isGapAnalysis ? GAP_ANALYSIS_MUTATION_DELAY[action] : LEARNER_COURSE_MUTATION_DELAY[action]) || -1
    const mutationSpec = {
      mutation: LEARNER_COURSE_MUTATIONS[action],
      variables: this.getMutationVariables({ action, questionId, answerIds, courseId })
    }
    let data = null
    try {
      this.props.setLoadingVisible(true)
      this.setState({ loading: true })
      let result
      if (delay > 0) {
        result = await addDelay({
          delay,
          action: async () => this.props.client.mutate(mutationSpec)
        })
      } else {
        result = await this.props.client.mutate(mutationSpec)
      }
      data = this.parseData(result?.data?.[LEARNER_COURSE_MUTATION_NAMES[action]])
      return data
    } finally {
      this.setState({ data, loading: false })
      this.props.setLoadingVisible(false)
    }
  }

  parseData (rawData) {
    const data = rawData || {}

    data.learnerId = data.learnerId || this.state.learnerId
    data.courseId = data.courseId || this.state.courseId

    return data
  }

  componentDidUpdate (prevProps) {
    const { preview: prevPreview } = prevProps
    const { preview } = this.props
    if (prevPreview !== preview) {
      if (preview) {
        DemoMode.enable()
      } else {
        DemoMode.disable()
      }
    }
  }

  render () {
    return (
      <LearnerCourse
        loading={this.state.loading}
        data={this.state.data}
        update={this.update}
        preview={this.props.preview}
        previewSlideId={this.state.previewSlideId}
        previewLocale={this.state.previewLocale}
        updateCourseId={this.updateCourseId}
        updateId={this.updateId}
        setLoadingVisible={this.props.setLoadingVisible}
        setTheme={this.props.setTheme}
        setLocale={this.props.setLocale}
        setLearnerId={this.props.setLearnerId}
        fromBuilder={this.fromBuilder}
        setIsGapAnalysis={this.setIsGapAnalysis}
      />
    )
  }
}

LearnerCourseMutation.propTypes = {
  setLoadingVisible: PropTypes.func,
  preview: PropTypes.bool
}

LearnerCourseMutation.defaultProps = {
  setLoadingVisible: () => {},
  preview: false
}

export const LearnerCourseConnect = connect(
  undefined,
  dispatch => ({
    setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
    setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
    setLocale: locale => dispatch(sessionActions.updateLocaleOnly(locale)),
    setLearnerId: locale => dispatch(sessionActions.updateLearnerId(locale))
  })
)

export default compose(
  LearnerCourseConnect,
  withProvider(unauthenticatedClient),
  withConsumer
)(LearnerCourseMutation)
