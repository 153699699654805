import I18n from 'i18n-js'
import UsecureError from './UsecureError'

const REF_RENDER_TIMEOUT = 2000
const waitForRefRender = async (ref, timeout = REF_RENDER_TIMEOUT) => {
  await new Promise((resolve, reject) => {
    const start = Date.now()
    const intv = setInterval(() => {
      if (ref.current) {
        resolve()
        clearInterval(intv)
      } else if (Date.now() - start >= timeout) {
        reject(new UsecureError(I18n.t('common.anErrorOccurred')))
        clearInterval(intv)
      }
    }, 100)
  })
}

export default waitForRefRender
