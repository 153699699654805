import React, { useState, useCallback, useMemo } from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'

import LandingPageTiles from './LandingPageTiles'
import TemplateHeader, { TemplateAlert, TemplateSelectedMessage, ALL_FILTER_VALUE } from './TemplateHeader'
import ViewLandingPageModal from '../Modals/ViewLandingPageModal'
import { CATEGORY_OPTIONS } from '../../constants/uPhish'
import { permissions } from '../../constants/permissions'
import { useHasSessionPermission } from '../../hooks'

const trOpt = { scope: 'uPhish.createSimulationLandingPages' }

const CreateSimulationLandingPages = ({ landingPageId, update, goToNextStep = () => {}, contentLocales = [], setContentLocales = () => {}, defaultContentLocales }) => {
  const [type, setType] = useState(ALL_FILTER_VALUE)
  const [category, setCategory] = useState(ALL_FILTER_VALUE)
  const [searchText, setSearchText] = useState('')
  const [contentLocaleCounts, setContentLocaleCounts] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [viewModalVisible, setViewModalVisible] = useState(false)
  const [viewModalId, setViewModalId] = useState(false)
  const [templatesLoading, setTemplatesLoading] = useState(true)

  const { hasAllSessionPermissions } = useHasSessionPermission()

  const openModal = useCallback(id => {
    setViewModalId(id)
    setViewModalVisible(true)
  }, [])
  const afterModalClose = useCallback(() => {
    setViewModalId(null)
  }, [])

  const modalProps = {
    component: ViewLandingPageModal,
    visible: viewModalVisible,
    setVisible: setViewModalVisible,
    afterClose: afterModalClose,
    id: viewModalId,
    onClick: setViewModalId
  }

  const categories = useMemo(() => CATEGORY_OPTIONS.sort((a, b) => {
    if (a.value === 'none') return -1
    if (b.value === 'none') return 1
    return a.label.localeCompare(b.label)
  }), [])

  return (
    <>
      {
        !landingPageId &&
          <TemplateAlert message={I18n.t('initialAlert', trOpt)} description='hidden' type='info' showIcon />
      }
      {
        selectedTemplate &&
          <TemplateAlert
            message={
              <TemplateSelectedMessage>
                <span>{I18n.t('selectedAlert', { ...trOpt, name: selectedTemplate.name })}</span>
                <Button type='primary' icon='right-circle' onClick={goToNextStep}>{I18n.t('common.continue')}</Button>
              </TemplateSelectedMessage>
            }
            description='hidden' type='success' showIcon
          />
      }
      <h2>{I18n.t('uPhish.createSimulation.chooseYourTheme')}</h2>
      <TemplateHeader
        {...{
          type,
          setType,
          searchText,
          setSearchText,
          category,
          categories,
          setCategory,
          contentLocales,
          setContentLocales,
          contentLocaleCounts,
          defaultContentLocales
        }}
        loading={templatesLoading}
        searchPlaceholder={I18n.t('uPhish.landingPageLibrary.searchLandingPages')}
        showContentLocaleCounts
      />
      <LandingPageTiles
        errorMessage={I18n.t('landingPageError', trOpt)}
        landingPageId={landingPageId}
        showCreate={hasAllSessionPermissions([permissions.LANDING_PAGE_CREATE])}
        onClick={update}
        actions={[{
          id: 'view',
          title: I18n.t('common.view'),
          icon: 'eye',
          onClick: openModal
        }, {
          id: 'select',
          title: I18n.t('selectTemplate', trOpt),
          icon: 'check-circle',
          onClick: update
        }]}
        filters={{
          searchText,
          type,
          category,
          contentLocales
        }}
        categories={categories}
        queryVariables={{ restrictToPlan: true }}
        onSelectedTemplateUpdate={setSelectedTemplate}
        modal={modalProps}
        {...{
          setContentLocaleCounts,
          currentPage,
          setCurrentPage,
          pageSize,
          setPageSize,
          setTemplatesLoading
        }}
        showPagination
        paginationTotalTrKey='uPhish.common.landingPagePaginationTotal'
      />
    </>
  )
}

export default CreateSimulationLandingPages
