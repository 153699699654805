import React from 'react'
import { Alert } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { connect } from '../../hocs'

const trOpt = { scope: 'main' }

const ExpiredAccountMessageAlert = styled(Alert)`
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`

const ExpiredAccountMessage = ({ inDisguise = null, planValid = true }) => {
  if (!(!planValid && inDisguise)) {
    return null
  }

  return (
    <ExpiredAccountMessageAlert
      message={I18n.t('expiredAndReadOnlyMessage', trOpt)}
      type='error'
      showIcon
      banner
    />
  )
}

const expiredSelector = state => {
  const { session: { inDisguise = null, planValid = true } = {} } = state || {}
  return { inDisguise, planValid }
}

export default connect(
  expiredSelector
)(ExpiredAccountMessage)
