import I18n from 'i18n-js'

import UsecureError from './UsecureError'
import { showErrors } from './ErrorHandler'

export const showSAMLAuthErrors = (e, { trOpt, messageDuration = 10, errorCodePrefix = 'UNK' } = {}) => {
  showErrors(
    e,
    new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode: `SAML-${errorCodePrefix}-UNK` }),
    {
      includeErrorCode: true,
      messageDuration,
      processor: function () {
        return this.errors.map(error => {
          if (!error.isUsecureClient && error.type?.startsWith('SAML-')) {
            return {
              ...error,
              message: I18n.t('samlSSOError', trOpt)
            }
          }
          return error
        })
      }
    }
  )
}
