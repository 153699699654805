import React from 'react';
import PropTypes from 'prop-types';

import DemoMode from '../helpers/DemoMode';

const withTimer = Component => {
  class WithTimer extends React.Component {
    constructor (props) {
      super(props);

      // Demo mode will not work as expected if the listenerId isn't unique
      this.listenerId = props.listenerId || `WithTimer-${Date.now()}`;
    }

    state = {
      timerActive: false,
    }
    
    static propTypes = {
      listenerId: PropTypes.string,
      waitMS: PropTypes.number,
    };
    
    static defaultProps = {
      listenerId: null,
      waitMS: -1,
    };

    resetTimer = () => {
      const { waitMS } = this.props;
      if (waitMS <= 0 || DemoMode.isEnabled()) {
        this.setState({ timerActive: false });
        return;
      }
  
      if (this.timer) {
        clearTimeout(this.timer);
      }
  
      this.setState({ timerActive: true }, () => {
        this.timer = setTimeout(() => this.setState({ timerActive: false }), waitMS);
      });
    }
  
    componentDidMount () {
      this.resetTimer();
      DemoMode.addListener(this.listenerId, this.toggleTimerFromDemoMode);
    }
  
    componentWillUnmount () {
      DemoMode.removeListener(this.listenerId, this.toggleTimerFromDemoMode);
    }
  
    toggleTimerFromDemoMode = demoMode => {
      if (demoMode) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.setState({ timerActive: false });
        }
      }
    }

    render () {
      const { forwardedRef, ...rest } = this.props;

      return (
        <Component ref={forwardedRef} {...rest}
          resetTimer={this.resetTimer}
          timerActive={this.state.timerActive} />
      );
    }
  }

  return React.forwardRef((props, ref) => (
    <WithTimer {...props} forwardedRef={ref} />
  ));
};

export default withTimer;
