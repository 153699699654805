import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Row } from 'antd'

import { FontAwesomeIcon } from '../common'
import { LearnerCourseFinishedContainer, LearnerCourseFinishedLayout, LearnerCourseFinishedContent, LearnerCourseFinishedProgress } from './LearnerCourseFinished'
import I18n from 'i18n-js'

const trOpt = { scope: 'learnerCourse.retryCourse' }
class LearnerCourseRetry extends Component {
  constructor (props) {
    super(props)

    this.handleRetryClick = this.handleRetryClick.bind(this)
  }

  handleRetryClick () {
    this.props.update({
      action: 'retry'
    })
  }

  render () {
    const { courseTitle, courseIcon, minimumPassScore, score } = this.props

    return (
      <LearnerCourseFinishedContainer>
        <LearnerCourseFinishedLayout>
          <LearnerCourseFinishedContent>
            <Row>
              <div>
                {
                  courseIcon
                    ? <h1><FontAwesomeIcon icon={courseIcon} /> {courseTitle}</h1>
                    : <h1>{courseTitle}</h1>
                }
                <p>{I18n.t('failed', trOpt)}</p>

                <p>{I18n.t('learnerCourse.score')}</p>
                <LearnerCourseFinishedProgress type='circle' percent={score} format={(percent) => `${percent}%`} />
                <p>{I18n.t('minimumPass', { ...trOpt, minimumPassScore })}</p>
                <Button type='primary' onClick={this.handleRetryClick}>{I18n.t('retryCourse', trOpt)}</Button>
              </div>
            </Row>
          </LearnerCourseFinishedContent>
        </LearnerCourseFinishedLayout>
      </LearnerCourseFinishedContainer>
    )
  }
}

LearnerCourseRetry.propTypes = {
  courseTitle: PropTypes.string,
  courseIcon: PropTypes.string,
  minimumPassScore: PropTypes.number,
  score: PropTypes.number,
  update: PropTypes.func
}

LearnerCourseRetry.defaultProps = {
  courseTitle: null,
  courseIcon: null,
  minimumPassScore: null,
  score: null,
  update: () => {}
}

export default LearnerCourseRetry
