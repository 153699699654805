import React from 'react'
import { Card, Descriptions } from 'antd'
import styled from 'styled-components'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'

import { PolicyContent } from './LearnerPolicy'
import { renderContent } from '../../components/LearnerCourse/common'
import { CATEGORY_NAMES, TEMPLATE_TYPE_NAMES, SIGNATURE_TYPE_NAMES } from '../../constants/uPolicy'
import { TileCardImage } from './DefaultTemplates'
import PolicyVersionTag from '../../components/Policies/PolicyVersionTag'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { PolicySignatureInfo } from '../../components/Policies/PolicySignatureSettingsField'
import PolicyPDFViewer from '../../components/Policies/PolicyPDFViewer'
import { formatDate, formatDateTime } from '../../helpers/datetime'
import { LocalesCell } from '../../components/common'
import { LIST_LOCALES_CELL_LIMIT } from '../../constants/list'

import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uPolicy.viewPolicyDetails' }

const DetailContainer = styled.div`
  padding-bottom: 10px;

  .ant-descriptions-item > span {
    display: block;
  }

  .hide-label-item {
    &> .ant-descriptions-item-colon::after {
      content: none;
    }
  }
`

const SignatureContainer = styled.div`
  display: flex;

  .ant-descriptions {
    width: 50%;
  }
`

const RecipientLine = styled.span`
  display: block;
  font-size: ${({ sub }) => sub ? '12px' : 'inherit'};
`

const DetailDescriptions = styled(Descriptions)`
  .ant-descriptions-row:nth-child(odd) > td {
    padding-bottom: 0;
  }
`

const ViewPolicyDetails = ({ policy, type, builder, companyId: userCompanyId, showDocument = false, policySignatureSettings: companyPolicySignatureSettings, externalAccess: externalAccessView }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const { companyId, category, type: contentType, document, file, tile, majorVersion, minorVersion, ownerName, ownerEmail, public: externalAccess, locales, supportedLocales } = policy

  const isOwner = (type === 'template' && hasAllSessionPermissions([permissions.POLICY_TEMPLATE_SUPER_READ])) || companyId === userCompanyId

  const isLive = !_isNil(_get(policy, 'livePolicyDocument.id'))
  const isMajor = !policy.template && _get(policy, 'livePolicyDocument.major') === true
  const hasDraft = !_isNil(_get(policy, 'draftPolicyDocument.id'))
  let versionNo = null
  if (isLive && policy.template) {
    versionNo = I18n.t('uPolicy.common.live')
  } else if (isLive) {
    versionNo = `v${majorVersion}.${minorVersion}`
  } else if (hasDraft) {
    versionNo = I18n.t('uPolicy.common.inDraft')
  }

  let lastPublished
  if (isLive && !policy.template && (isOwner || externalAccessView)) {
    lastPublished = _get(policy, 'livePolicyDocument.publishedAt')
    if (lastPublished) {
      lastPublished = formatDateTime(lastPublished)
    }
  }

  let signatureType = 'none'
  let useDefaultSignatureType = false
  let startDate, intervalLength, intervalUnit
  // Signature Types only apply for policy's company and have no effect when shared
  // External Access does include shared policies
  if (isOwner || externalAccessView) {
    useDefaultSignatureType = _get(policy, 'signatureSettings.useDefault')
    const signatureSettings = (useDefaultSignatureType ? companyPolicySignatureSettings : _get(policy, 'signatureSettings')) || {}
    signatureType = signatureSettings.type
    startDate = signatureSettings.startDate
    intervalLength = signatureSettings.intervalLength
    intervalUnit = signatureSettings.intervalUnit
  }

  let owner
  if ((ownerName || ownerEmail) && type === 'policy' && isOwner) {
    if (ownerName && ownerEmail) {
      owner = (
        <div>
          <RecipientLine>{ownerName}</RecipientLine>
          <RecipientLine sub>{ownerEmail}</RecipientLine>
        </div>
      )
    } else {
      owner = ownerName || ownerEmail
    }
  }

  // This reducer render the policy detail items
  // It is in place to compensate Ant Design's Descriptions component's poor span handling, it doesn't wrap as expected when a row is full
  // The alternative would be to create our own
  let rowSpan = 0
  const items = [{
    id: 'category',
    span: 1,
    allowed: true
  }, {
    id: 'templateType',
    span: 1,
    allowed: type === 'template' && !builder
  }, {
    id: 'versionNo',
    span: 1,
    allowed: type === 'policy' || builder
  }, {
    id: 'lastPublished',
    span: 1,
    allowed: lastPublished
  }, {
    id: 'locales',
    span: 2,
    allowed: true
  }, {
    id: 'tile',
    span: 2,
    allowed: type === 'template' && builder && isOwner
  }, {
    id: 'owner',
    span: 1,
    allowed: owner
  }, {
    id: 'externalAccess',
    span: 1,
    allowed: type === 'policy' && !externalAccessView
  }, {
    id: 'signature',
    span: 3,
    allowed: type === 'policy'
  }, {
    id: 'document',
    span: 3,
    allowed: showDocument && contentType === 'editor' && document
  }, {
    id: 'file',
    span: 3,
    allowed: showDocument && contentType === 'pdf' && file
  }].filter(item => item.allowed)
    .reduce((acc, item, index, items) => {
      const { id, span } = item
      let itemSpan = span
      const nextItem = items[index + 1]
      if (index === items.length - 1 || (nextItem && (nextItem.span + rowSpan + span) > 3)) {
        itemSpan = 3 - rowSpan
      }
      rowSpan += itemSpan
      rowSpan = rowSpan >= 3 ? 0 : rowSpan

      const itemProps = { key: `${id}-${index}`, span: itemSpan }
      switch (id) {
        case 'category':
          acc.push(<Descriptions.Item {...itemProps} label={I18n.t('common.category')}>{_get(CATEGORY_NAMES, category, CATEGORY_NAMES.none)}</Descriptions.Item>)
          break
        case 'templateType':
          acc.push(<Descriptions.Item {...itemProps} label={I18n.t('common.type')}>{TEMPLATE_TYPE_NAMES[_isNil(companyId) ? 'core' : 'custom']}</Descriptions.Item>)
          break
        case 'owner':
          acc.push(<Descriptions.Item {...itemProps} label={I18n.t('policyOwner', trOpt)}>{owner}</Descriptions.Item>)
          break
        case 'externalAccess':
          acc.push(<Descriptions.Item {...itemProps} label={I18n.t('uPolicy.common.allowExternalAccess')}>{externalAccess ? I18n.t('common.yes') : I18n.t('common.no')}</Descriptions.Item>)
          break
        case 'lastPublished':
          acc.push(<Descriptions.Item {...itemProps} label={I18n.t('lastPublishedAt', trOpt)}>{lastPublished}</Descriptions.Item>)
          break
        case 'versionNo':
          acc.push(
            <Descriptions.Item {...itemProps} label={type === 'template' ? I18n.t('common.status') : I18n.t('versionNo', trOpt)}>
              <PolicyVersionTag lastMajorVersionNo={isMajor ? versionNo : null} {...{ isOwner, isLive, hasDraft, versionNo }} />
            </Descriptions.Item>
          )
          break
        case 'tile':
          acc.push(
            <Descriptions.Item {...itemProps} label={I18n.t('common.templateTile')}>
              <TileCardImage src={tile} width={300} />
            </Descriptions.Item>
          )
          break
        case 'signature':
          acc.push(
            <Descriptions.Item {...itemProps} className='hide-label-item'>
              <h3>{I18n.t('uPolicy.common.signatureSettings')}</h3>
              <SignatureContainer>
                <DetailDescriptions column={2} layout='vertical'>
                  <Descriptions.Item label={I18n.t('uPolicy.common.signatureType')}>{SIGNATURE_TYPE_NAMES[signatureType]}</Descriptions.Item>
                  <Descriptions.Item label={I18n.t('usingDefaultSettings', trOpt)}>{useDefaultSignatureType ? I18n.t('common.yes') : I18n.t('common.no')}</Descriptions.Item>
                  {
                    signatureType === 'fixed' && startDate &&
                      <Descriptions.Item label={I18n.t('uPolicy.common.signatureCycleStartDate')}>{formatDate(startDate)}</Descriptions.Item>
                  }
                  {
                    signatureType === 'fixed' && intervalLength && intervalUnit &&
                      <Descriptions.Item label={I18n.t('uPolicy.common.signatureFrequency')}>
                        {I18n.t('signatureFrequencyValue', { ...trOpt, interval: I18n.t(`common.duration.value.${intervalUnit}`, { count: intervalLength }) })}
                      </Descriptions.Item>
                  }
                  {
                    signatureType === 'lastSignature' && intervalLength && intervalUnit &&
                      <Descriptions.Item label={I18n.t('uPolicy.common.resignAfter')}>
                        {I18n.t(`common.duration.value.${intervalUnit}`, { count: intervalLength })}
                      </Descriptions.Item>
                  }
                </DetailDescriptions>
                <PolicySignatureInfo type={signatureType} {...{ startDate, intervalLength, intervalUnit }} />
              </SignatureContainer>
            </Descriptions.Item>
          )
          break
        case 'document':
          acc.push(
            <Descriptions.Item {...itemProps} className='hide-label-item'>
              <h3>{I18n.t('document', trOpt)}</h3>
              <Card>
                <PolicyContent dangerouslySetInnerHTML={{ __html: renderContent(JSON.stringify(document)) }} />
              </Card>
            </Descriptions.Item>
          )
          break
        case 'file':
          acc.push(
            <Descriptions.Item {...itemProps} className='hide-label-item'>
              <h3>{I18n.t('document', trOpt)}</h3>
              <Card>
                <PolicyPDFViewer file={policy.file} />
              </Card>
            </Descriptions.Item>
          )
          break
        case 'locales':
          acc.push(
            <Descriptions.Item {...itemProps} label={I18n.t('common.languagesParenPlural')}>
              <LocalesCell itemName={policy.name} limit={LIST_LOCALES_CELL_LIMIT} locales={builder ? locales : supportedLocales} />
            </Descriptions.Item>
          )
          break
        default:
          break
      }

      return acc
    }, [])

  return (
    <DetailContainer>
      <DetailDescriptions column={3} layout='vertical'>
        {items}
      </DetailDescriptions>
    </DetailContainer>
  )
}

export default connect(
  (state, props) => props.externalAccess ? {} : _pick(selectors.settings.get(state), ['policySignatureSettings'])
)(ViewPolicyDetails)
