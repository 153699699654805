import React from 'react'
import styled from 'styled-components'

const size = 2.2
const RadioStyle = styled.div`
    display: inline-block;
    width: ${size}em;
    height: ${size}em;
    position: relative;
    border: ${props => props.borderWidth || `1px`} solid ${props => props.theme[props.color] || props.color || `#607D8B`};
    color: ${props => props.theme[props.color] || props.color || props.theme.primary};
    cursor: pointer;
    &:after {
      content: "${({ correct }) => correct === false ? '\f00d' : '\f00c'}";
      color: ${props => props.theme[props.color] || props.color || props.theme.primary};
      font-weight: 900;
      font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      width: 100%;
      height: 50%;
      top: 30%;
      left: 50%;
      font-size: 1.7em;
      text-align: center;
      transition: transform .25s, background-color .25s; 
      transform: translate(-50%, -50%) scale(${props => props.checked ? 1 : 0});
      position: absolute;
      opacity: ${({ hideCheck }) => hideCheck ? 0 : 1}
    }
`

export default class Radio extends React.Component {
  render () {
    return <RadioStyle {...this.props} />
  }
}
