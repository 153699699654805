import React, { useCallback } from 'react'
import { Alert, Button, Collapse } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { renderListFromArray, renderListFragmentFromArray, renderParagraphsFragmentFromArray } from '../../helpers'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import _SyncButton from '../Sync/SyncButton'
import _SyncConfigSummary from '../Sync/SyncConfigSummary'
import _RevokeSyncButton from '../Sync/RevokeSyncButton'
import _DetachAllFromSyncButton from '../Sync/DetachAllFromSyncButton'

const { Panel } = Collapse

const SyncConfigSummary = styled(_SyncConfigSummary)`
  margin-bottom: 14px;
`

const SyncButton = styled(_SyncButton)`
  margin-top: 14px;
`

const RevokeSyncButton = styled(_RevokeSyncButton)`
  margin-top: 14px;
`

const DetachAllFromSyncButton = styled(_DetachAllFromSyncButton)`
  margin-top: 14px;
`

const ActionContainer = styled.div`
  padding-bottom: 30px;

  ul {
    font-family: ${({ theme }) => theme.copyFont};
  }
`

const SyncActions = ({
  settings, isUpdateAllowed, groupTypes, groupsQuery, groupsQueryKey, usePaginatedGroupLoad, setupRoute, signInButton, authFlowModal, syncType, completeTrOpt, revokeButtonOptions, trOptScopeKey
}) => {
  const onSetupClick = useCallback(() => window.open(setupRoute, '_blank', ['noopener']), [setupRoute])
  const trOpt = { scope: `${trOptScopeKey}.actions` }
  const configSummaryTrOpt = { scope: `${trOptScopeKey}.configSummary` }
  const configSummaryGroupsTrOpt = { scope: `${trOptScopeKey}.configSummaryGroups` }
  const syncButtonTrOpt = { scope: `${trOptScopeKey}.syncButton` }
  const detachFromSyncTrOpt = { scope: `${trOptScopeKey}.actions.detachAllFromSync` }

  const configured = _get(settings, 'configured') === true
  const authType = _get(settings, 'authType')
  let hasAuth = false
  if (authType === 'delegated') {
    hasAuth = _get(settings, 'refreshToken') === true
  } else if (syncType === 'microsoft' && authType === 'admin') {
    hasAuth = _get(settings, 'tenantId') === true
  } else if (syncType === 'google' && authType === 'serviceAccount') {
    hasAuth = _get(settings, 'serviceAccountDelegatedUserEmail') && _get(settings, 'serviceAccountClientEmail') === true && _get(settings, 'serviceAccountPrivateKey') === true
  }
  const includeGroups = _get(settings, 'includeGroups') === true

  const wizardPoints = renderListFromArray(I18n.t('configurationList', trOpt))

  return (
    <div style={{ textAlign: 'left' }}>
      {authFlowModal}
      <ActionContainer>
        {
          hasAuth
            ? (
              <>
                <h2>{I18n.t('common.configuration')}</h2>
                {configured &&
                  <SyncConfigSummary
                    {...{ groupsQuery, groupTypes, syncType, groupsQueryKey, usePaginatedGroupLoad }}
                    trOpt={configSummaryTrOpt}
                    groupsTrOpt={configSummaryGroupsTrOpt}
                  />}
                <p>{I18n.t('configurationIntro', trOpt)}</p>
                {wizardPoints}
                <Button disabled={!isUpdateAllowed} icon='setting' onClick={onSetupClick} style={{ marginTop: 14 }}>{I18n.t('runTheConfigurationWizard', trOpt)}</Button>
                {
                  !configured && (
                    <Alert
                      showIcon type='warning' style={{ marginTop: 14 }}
                      message={I18n.t('syncConfigRequired', trOpt)}
                      description={<p>{I18n.t('syncConfigRequiredBody', trOpt)}</p>}
                    />
                  )
                }
                <Alert
                  style={{ marginTop: 14 }}
                  message={I18n.t('authIssues', trOpt)}
                  description={
                    <div>
                      <p>{I18n.t('authIssuesBody', trOpt)}</p>
                      <p>{I18n.t('clickButtonToRerun', trOpt)}</p>
                      {signInButton}
                    </div>
                  }
                  type='info'
                  showIcon
                />
              </>
            )
            : (
              <>
                <h2>{I18n.t('setup', trOpt)}</h2>
                <ul>
                  {renderListFragmentFromArray(I18n.t('setupList', trOpt))}
                  <li>{I18n.t('setupConfig', trOpt)}
                    {wizardPoints}
                  </li>
                  <li>{I18n.t('setupTestSync', trOpt)}</li>
                </ul>
                {signInButton}
              </>
            )
        }
      </ActionContainer>
      {
        hasAuth && configured
          ? (
            <>
              <ActionContainer>
                <h2>{I18n.t('sync.common.testSync')}</h2>
                <ul>
                  {renderListFragmentFromArray(I18n.t('testSyncList', trOpt))}
                  <li>{I18n.t('emailIntro', completeTrOpt)}
                    <ul>
                      {includeGroups && renderListFragmentFromArray(I18n.t('groupsList', completeTrOpt))}
                      {renderListFragmentFromArray(I18n.t('usersList', completeTrOpt))}
                      <li>{I18n.t('errorsIncluded', completeTrOpt)}</li>
                    </ul>
                  </li>
                  <li>{I18n.t('syncTimeToFinish', trOpt)}</li>
                </ul>
                <SyncButton
                  {...{ syncType }}
                  force dummy disabled={!isUpdateAllowed}
                  successMessage={I18n.t('successMessage', syncButtonTrOpt)}
                  errorMessage={I18n.t('errorMessage', syncButtonTrOpt)}
                >
                  {I18n.t('runTestSync', trOpt)}
                </SyncButton>
              </ActionContainer>
              <ActionContainer>
                <h2>{I18n.t('manualSync', trOpt)}</h2>
                <ul>
                  <li>{I18n.t(includeGroups ? 'runningManualSyncWithGroups' : 'runningManualSync', trOpt)}</li>
                  <li>{I18n.t('manualSyncEmail', trOpt)}</li>
                  <li>{I18n.t('syncTimeToFinish', trOpt)}</li>
                </ul>
                <SyncButton
                  {...{ syncType }}
                  force
                  disabled={!isUpdateAllowed}
                  successMessage={I18n.t('successMessage', syncButtonTrOpt)}
                  errorMessage={I18n.t('errorMessage', syncButtonTrOpt)}
                />
              </ActionContainer>
              <Collapse>
                <Panel header={I18n.t('common.advancedOptions')}>
                  <ActionContainer>
                    <h2>{I18n.t('detachAllFromSync.detachAllFromSync', trOpt)}</h2>
                    <p>{I18n.t('detachAllFromSync.detachAllFromSyncDescription', trOpt)}</p>
                    <DetachAllFromSyncButton {...{ syncType }} disabled={!isUpdateAllowed} trOpt={detachFromSyncTrOpt} />
                  </ActionContainer>
                  {revokeButtonOptions.map(({ action, trOption }) => (
                    <ActionContainer key={action}>
                      <h2>{I18n.t('title', trOption)}</h2>
                      {renderParagraphsFragmentFromArray(I18n.t('descriptionList', trOption))}
                      <RevokeSyncButton disabled={!isUpdateAllowed} trOption={trOption} syncType={syncType} action={action} />
                    </ActionContainer>
                  ))}
                </Panel>
              </Collapse>
            </>
          ) : null
      }
    </div>
  )
}

export default connect(
  state => ({
    locale: selectors.session.sessionLocale(state)
  })
)(SyncActions)
