/* global localStorage */
import { useCallback, useState } from 'react'

const useLocalStorage = ({ storageId }) => {
  const [localStorageInitialised, setLocalStorageInitialised] = useState(false)
  const updateFromLocalStorage = useCallback((updateFunctions = {}) => {
    if (!storageId) return
    try {
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        const listFilter = JSON.parse(listFilterString)
        Object.keys(listFilter).forEach(key => {
          const value = listFilter[key]
          if (updateFunctions[key]) {
            updateFunctions[key](value)
          }
        })
      }
    } catch (e) {}
    setLocalStorageInitialised(true)
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    if (!storageId) {
      return
    }

    let viewFilter = {}
    try {
      const viewFilterString = localStorage.getItem(storageId)
      if (viewFilterString) {
        viewFilter = JSON.parse(viewFilterString)
      }
      viewFilter = { ...viewFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(viewFilter))
    } catch (e) {}
  }, [storageId])

  return {
    localStorageInitialised,
    updateLocalStorage,
    updateFromLocalStorage
  }
}
export default useLocalStorage
