/* global unlayer */
// This is a 'fork' of unlayer's react-email-editor package https://github.com/unlayer/react-email-editor
// The displayMode couldn't be set via the original code and extended the EmailEditor component didn't work
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _isArray from 'lodash/isArray'

import { IN_DEV } from '../../constants/environment'

// Forces the Unlayer editor to be loaded under https otherwise it will match the client URL's protocol
// This is useful for local testing of custom tools as you don't have to run a local client instance under HTTPS or add allowed domains to our Unlayer accounts
// CL - I haven't made this standard behaviour as I don't know the knock on effects of doing so.
const FORCE_HTTPS_SCRIPT = window.__USECURE_CONFIG__.REACT_APP_FORCE_HTTPS_ON_UNLAYER === 'true'
const scriptUrl = `${FORCE_HTTPS_SCRIPT && IN_DEV ? 'https:' : ''}//editor.unlayer.com/embed.js?2`
let loaded = false

const isScriptInjected = () => {
  const scripts = document.querySelectorAll('script')
  let injected = false

  scripts.forEach((script) => {
    if (script.src.includes(scriptUrl)) {
      injected = true
    }
  })

  return injected
}

const loadScript = async () => {
  return new Promise(resolve => {
    if (!isScriptInjected()) {
      const embedScript = document.createElement('script')
      embedScript.setAttribute('src', scriptUrl)
      embedScript.onload = () => {
        loaded = true
        resolve(loaded)
      }
      document.head.appendChild(embedScript)
    } else {
      resolve(loaded)
    }
  })
}

let lastEditorId = 0

class UnLayerEditor extends Component {
  constructor (props) {
    super(props)

    this.loadEditor = this.loadEditor.bind(this)
    this.registerCallback = this.registerCallback.bind(this)
    this.addEventListener = this.addEventListener.bind(this)
    this.loadDesign = this.loadDesign.bind(this)
    this.saveDesign = this.saveDesign.bind(this)
    this.exportHtml = this.exportHtml.bind(this)
    this.setMergeTags = this.setMergeTags.bind(this)
  }

  async componentDidMount () {
    const loaded = await loadScript()
    if (loaded) {
      this.loadEditor()
    }
  }

  render () {
    const {
      props: { minHeight = 500, style = {} }
    } = this

    this.editorId = `editor-${++lastEditorId}`

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          minHeight: minHeight
        }}
      >
        <div id={this.editorId} style={{ ...style, flex: 1 }} />
      </div>
    )
  }

  loadEditor () {
    const options = this.props.options || {}

    if (this.props.projectId) {
      options.projectId = this.props.projectId
    }

    if (this.props.tools) {
      options.tools = this.props.tools
    }

    if (this.props.appearance) {
      options.appearance = this.props.appearance
    }

    if (this.props.locale) {
      options.locale = this.props.locale
    }
    // Override - makes HTML class and id available
    if (this.props.designMode) {
      options.designMode = this.props.designMode
    }

    this.editor = unlayer.createEditor({
      ...options,
      id: this.editorId,
      displayMode: this.props.displayMode || 'email', // Override
      source: {
        name: 'react-email-editor', // Override
        version: '1.1.1'// Override
      }
    })

    // Override - Register additional row column layouts
    if (_isArray(this.props.columnLayouts)) {
      this.props.columnLayouts.forEach(columns => this.editor.registerColumns(columns))
    }

    // All properties starting with on[Name] are registered as event listeners.
    for (const [key, value] of Object.entries(this.props)) {
      if (/^on/.test(key) && key !== 'onLoad') {
        this.addEventListener(key, value)
      }
    }

    const { onLoad } = this.props
    onLoad && onLoad()
  };

  registerCallback (type, callback) {
    this.editor.registerCallback(type, callback)
  };

  addEventListener (type, callback) {
    this.editor.addEventListener(type, callback)
  };

  loadDesign (design) {
    this.editor.loadDesign(design)
  };

  saveDesign (callback) {
    this.editor.saveDesign(callback)
  };

  exportHtml (callback) {
    this.editor.exportHtml(callback)
  };

  setMergeTags (mergeTags) {
    this.editor.setMergeTags(mergeTags)
  };
}

UnLayerEditor.propTypes = {
  displayMode: PropTypes.string
}

UnLayerEditor.defaultProps = {
  displayMode: 'email'
}

export default UnLayerEditor
