import React, { useEffect, useMemo, useState } from 'react'
import I18n from 'i18n-js'
import queryString from 'query-string'
import { useQuery } from '@apollo/react-hooks'
import _isArray from 'lodash/isArray'
import _isString from 'lodash/isString'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import { connect } from '../../../hocs'
import useIsPrinting from '../../../hooks/useIsPrinting'
import { creators as viewActions } from '../../../state/actions/view'
import { PERFORMANCE_REPORT, RISK_SCORE_CONFIG } from '../../../components/Queries/Reports'
import BaseReport from '../../../components/Reports/BaseReport'
import { getPerformanceReportSections } from '../../../helpers/performanceReport'
import { LoadingBlock, ErrorAlerts, PreviewTag } from '../../../components/common'
import { REPORT_CENTRE_PREVIEW } from '../../../constants/environment'

const trOpt = { scope: 'reports.performanceReport' }

const PerformanceReport = ({ setLoadingVisible = () => {}, setPageTitle = () => {}, resetPageTitle = () => {} }) => {
  const [variables, setVariables] = useState(null)
  const [reportSections, setReportSections] = useState(['execSummary', 'users', 'uBreach', 'uPhish', 'uLearn', 'uPolicy'])
  const isPrinting = useIsPrinting()
  const { data, loading, error } = useQuery(PERFORMANCE_REPORT, {
    variables,
    skip: !variables
  })
  const { data: { riskScoreConfig } = {}, loading: riskScoreConfigLoading } = useQuery(RISK_SCORE_CONFIG)
  const { companyId, fromDate, toDate } = variables || {}

  useEffect(() => {
    if (!loading && !riskScoreConfigLoading) setLoadingVisible(false)
  }, [setLoadingVisible, loading, riskScoreConfigLoading])

  // Temporarily change page title to a filename string when printing
  useEffect(() => {
    if (isPrinting) {
      const { fromDate, toDate } = variables || {}
      setPageTitle(I18n.t('printTitle', { ...trOpt, fromDate, toDate }))
    } else {
      resetPageTitle()
    }
  }, [isPrinting, setPageTitle, resetPageTitle, variables])

  useEffect(() => {
    // Parse query string on mount
    const {
      companyId,
      fromDate,
      toDate,
      granularity,
      sections: reportSections
    } = queryString.parse(window.location.search, { arrayFormat: 'comma' })
    setVariables({
      companyId,
      fromDate,
      toDate,
      granularity
    })
    if (_isArray(reportSections)) {
      setReportSections(reportSections)
    } else if (_isString(reportSections)) {
      // queryString.parse will return a string for reportSections if there is only one value in the array
      // so make sure we have an array
      setReportSections([reportSections])
    }
  }, [])

  const sections = useMemo(() => {
    if (!_isEmpty(_get(data, 'performanceReport')) && riskScoreConfig) {
      if (loading || riskScoreConfigLoading || !riskScoreConfig || !data || !data.performanceReport) return []
      return getPerformanceReportSections({
        performanceReport: data.performanceReport,
        riskScoreConfig,
        trOpt,
        reportSections
      })
    }
    return []
  }, [data, reportSections, loading, riskScoreConfigLoading, riskScoreConfig])

  return (
    <>
      <LoadingBlock fullScreen loading={loading || riskScoreConfigLoading} />
      <ErrorAlerts error={error} defaultError={I18n.t('reports.loadError')} />
      {!loading && !riskScoreConfigLoading && sections.length > 0 && (
        <BaseReport
          {...{ companyId, fromDate, toDate, sections, trOpt }}
          title={<>{I18n.t('reportTitle', trOpt)}{REPORT_CENTRE_PREVIEW && <PreviewTag header className='hideOnPrint' />}</>}
          downloadFileName={I18n.t('reports.downloadReport.fileNames.performanceReport', { fromDate, toDate })}
          reportType='performanceReport'
        />
      )}
    </>
  )
}

export default connect(
  undefined,
  dispatch => ({
    setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
    setPageTitle: title => dispatch(viewActions.title(title)),
    resetPageTitle: () => dispatch(viewActions.resetTitle())
  })
)(PerformanceReport)
