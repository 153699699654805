import React, { useState, useEffect, useCallback, useImperativeHandle } from 'react'
import { Alert, Button, Form, InputNumber } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isPlainObject from 'lodash/isPlainObject'
import _isString from 'lodash/isString'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _toInteger from 'lodash/toInteger'
import { compose } from 'recompose'

import SettingsForm, { SettingsFormFieldExtra } from '../SettingsForm'
import { UPDATE_PHISH_ALERT_SETTINGS, UPDATE_DEFAULT_TENANT_PHISH_ALERT_SETTINGS } from '../../Queries/Companies'
import { DEFAULT_LANGUAGE } from '../../../constants/languages'
import { SenderAddressField, InputWithReset } from '../common'
import { validateEmail, downloadFileFromPost, Office365, renderListFragmentFromArray, renderParagraphsFragmentFromArray } from '../../../helpers'
import MutationFormErrors from '../../MutationForm/MutationFormErrors'
import { BrandedMicrosoftAuthButton } from '../../MicrosoftSync'
import { permissions } from '../../../constants/permissions'
import { connect } from '../../../hocs'
import selectors from '../../../state/selectors'
import { hasAnyPermission } from '../../../helpers/hasPermission'

const USE_OUTLOOK_API = window.__USECURE_CONFIG__.REACT_APP_USE_OUTLOOK_API === 'true'
const SEND_M365_THREAT = window.__USECURE_CONFIG__.REACT_APP_SEND_M365_THREAT === 'true'
const USE_MAILBOX = window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_USE_MAILBOX === 'true'
const SHARED_FOLDERS = window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_SHARED_FOLDERS === 'true'
const PHISH_ALERT_URL = window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_URL
const PHISH_ALERT_ASSETS_URL = `${PHISH_ALERT_URL}/assets`

const trOpt = { scope: 'settings.phishAlert' }
const tabsTrOpt = { scope: `${trOpt.scope}.tabs` }
const fieldsTrOpt = { scope: `${trOpt.scope}.fields` }
const defaultsTrOpt = { scope: `${trOpt.scope}.defaultValues` }

const isEnabled = values => values.enabled === true
const isDisabled = values => values.enabled !== true
const isForwarding = values => isEnabled(values) && values.forwarding === true
const emlAttachmentEnabled = values => isForwarding(values) && values.includeEMLAttachment === true
const allowOutlookAPI = values => emlAttachmentEnabled(values) && USE_OUTLOOK_API
const allowMailbox = values => emlAttachmentEnabled(values) && USE_MAILBOX
const allowSharedMailboxes = values => isEnabled(values) && SHARED_FOLDERS

const downloadManifest = async data => downloadFileFromPost({ url: PHISH_ALERT_URL, endpoint: '/manifest', type: 'text/xml', file: 'phish-alert-manifest.xml', data })

const manifestTrOpt = { scope: `${trOpt.scope}.manifestDownload` }
const ManifestDownloadDesc = styled.div`
  p {
    font-family: unset;
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    padding-left: 18px;
  }
`
const ManifestDownload = React.forwardRef(({ visible, manifestChanged = false, addInManifest, addInManifestFields = [] }, ref) => {
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValid(
      _isPlainObject(addInManifest) &&
      addInManifestFields.every(id => {
        const value = addInManifest[id]
        let valid = (_isString(value) && value.length > 0) || _isBoolean(value)
        if (valid && id === 'alertVersion') {
          valid = ALERT_VERSION_REGEX.test(value)
        }
        return valid
      })
    )
  }, [addInManifest, addInManifestFields])

  const onClick = useCallback(async () => {
    setLoading(true)
    await downloadManifest(addInManifest)
    setLoading(false)
  }, [addInManifest])

  if (!visible) {
    return null
  }

  const disabled = !valid || manifestChanged

  let warning
  let alertType = 'success'
  if (!valid) {
    if (_isPlainObject(addInManifest)) {
      warning = I18n.t('invalidWarning', manifestTrOpt)
      alertType = 'error'
    } else {
      warning = I18n.t('saveRequiredWarning', manifestTrOpt)
      alertType = 'warning'
    }
  } else if (manifestChanged) {
    warning = I18n.t('saveRequiredWarning', manifestTrOpt)
    alertType = 'warning'
  }

  return (
    <Alert
      style={{ marginBottom: 14 }}
      message={I18n.t('title', manifestTrOpt)}
      description={
        <ManifestDownloadDesc>
          {warning && <p>{warning}</p>}
          <ul>{renderListFragmentFromArray(I18n.t('body', manifestTrOpt))}</ul>
          <Button
            type='primary' icon='download'
            {...{ loading, onClick, disabled }}
          >
            {I18n.t('downloadButton', manifestTrOpt)}
          </Button>
        </ManifestDownloadDesc>
      }
      type={alertType}
      showIcon
    />
  )
})

const ALERT_VERSION_REGEX = /^(\d{1,}[.])(\d{1,}[.])(\d{1,}[.])(\d{1,})$/

const AlertVersionInput = ({ id, value, setValue, updateFieldValue, disabled }) => {
  const onChange = useCallback(value => {
    setValue(value)
    updateFieldValue(id, value)
  }, [id, setValue, updateFieldValue])
  return (
    <InputNumber
      name={id}
      {...{ value, onChange, disabled }}
      min={0}
      step={1}
    />
  )
}

const AlertVersionContainer = styled.div`
  display: flex;

  &> span {
    padding: 0 5px;
  }

  .ant-btn {
    margin-left: 10px;
  }
`

const AlertVersion = React.forwardRef(({ visible, label, extra, required, onChange, value, id, errors = [], defaultSetting, disabled }, ref) => {
  const [major, setMajor] = useState(1)
  const [minor, setMinor] = useState(0)
  const [build, setBuild] = useState(0)
  const [revision, setRevision] = useState(0)

  const updateFieldValue = useCallback((part, value) => {
    const version = { major, minor, build, revision }
    version[part] = value
    onChange(id, [version.major, version.minor, version.build, version.revision].join('.'))
  }, [id, major, minor, build, revision, onChange])

  const onResetClick = useCallback(() => onChange(id, defaultSetting), [id, defaultSetting, onChange])

  useEffect(() => {
    if (_isString(value) && ALERT_VERSION_REGEX.test(value)) {
      const [major, minor, build, revision] = value.split('.')
      setMajor(_toInteger(major))
      setMinor(_toInteger(minor))
      setBuild(_toInteger(build))
      setRevision(_toInteger(revision))
    }
  }, [value])

  useImperativeHandle(ref, () => ({
    validate: (value, errors) => {
      if (_isString(value) && value.length > 0 && !ALERT_VERSION_REGEX.test(value)) {
        errors.push(I18n.t('alertVersionError', fieldsTrOpt))
      }
    }
  }))

  if (!visible) {
    return null
  }

  const showErrors = errors.length > 0

  return (
    <Form.Item
      label={label} extra={extra} required={required}
      validateStatus={showErrors ? 'error' : undefined}
      help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
    >
      <AlertVersionContainer>
        <AlertVersionInput id='major' value={major} setValue={setMajor} {...{ updateFieldValue, disabled }} />
        <span>.</span>
        <AlertVersionInput id='minor' value={minor} setValue={setMinor} {...{ updateFieldValue, disabled }} />
        <span>.</span>
        <AlertVersionInput id='build' value={build} setValue={setBuild} {...{ updateFieldValue, disabled }} />
        <span>.</span>
        <AlertVersionInput id='revision' value={revision} setValue={setRevision} {...{ updateFieldValue, disabled }} />
        <Button type='primary' icon='undo' onClick={onResetClick} disabled={disabled}>{I18n.t('common.reset')}</Button>
      </AlertVersionContainer>
    </Form.Item>
  )
})

const REDIRECT_URI = `${window.location.origin}/phish-alert-m365-auth`

const authTrOpt = { scope: `${trOpt.scope}.m365Auth` }
const M365AuthAlertDisclaimer = styled.div`
  font-weight: bold;
  font-size: 16px;
`
const M365AuthAlertDesc = styled.div`
  p {
    font-family: unset;
    margin-bottom: 5px;
  }
  ${BrandedMicrosoftAuthButton} {
    margin-top: 5px;
  }
  ${M365AuthAlertDisclaimer} {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`
const M365AuthAlert = ({
  visible, locale, authConfigChanged = false, authConfig,
  clientId, authOrigin, title, disclaimer,
  defaultType = 'warning',
  hasAuthType = 'success',
  saveRequiredType = 'error',
  authRequiredType = 'warning',
  disabled = false
}) => {
  const [showSignIn, setShowSignIn] = useState(true)
  const [infoKey, setInfoKey] = useState('authRequiredInfo')
  const [infoType, setInfoType] = useState(defaultType)

  useEffect(() => {
    if (authConfig?.hasAuth === true) {
      setShowSignIn(true)
      setInfoKey('hasAuthInfo')
      setInfoType(hasAuthType)
    } else if (authConfigChanged) {
      setShowSignIn(false)
      setInfoKey('saveRequiredInfo')
      setInfoType(saveRequiredType)
    } else {
      setShowSignIn(true)
      setInfoKey('authRequiredInfo')
      setInfoType(authRequiredType)
    }
  }, [
    authConfig, authConfigChanged,
    authRequiredType, hasAuthType, saveRequiredType
  ])

  const onAuthClick = useCallback(() => {
    // Navigate to admin consent URL
    const office365 = new Office365({
      clientId,
      redirectUri: REDIRECT_URI
    })
    window.open(office365.getAdminConsentUrl(authOrigin), '_blank', ['noopener'])
  }, [clientId, authOrigin])

  if (!visible) {
    return null
  }

  return (
    <>
      <Alert
        style={{ marginBottom: 14 }}
        message={title}
        description={
          <M365AuthAlertDesc>
            {
              infoKey === 'hasAuthInfo'
                ? renderParagraphsFragmentFromArray(I18n.t(infoKey, authTrOpt))
                : <p>{I18n.t(infoKey, authTrOpt)}</p>
            }
            {showSignIn && <BrandedMicrosoftAuthButton onClick={onAuthClick} locale={locale} disabled={disabled} />}
            {disclaimer && <M365AuthAlertDisclaimer>{disclaimer}</M365AuthAlertDisclaimer>}
          </M365AuthAlertDesc>
        }
        type={infoType}
        showIcon
      />
    </>
  )
}

const SSOM365AuthAlert = React.forwardRef((props, ref) => {
  return (
    <M365AuthAlert
      title={I18n.t('ssoM365AuthTitle', trOpt)}
      disclaimer={renderParagraphsFragmentFromArray(I18n.t('ssoM365AuthDisclaimer', trOpt))}
      clientId={window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_SSO_CLIENT_ID}
      authOrigin='phishAlertSSO'
      defaultType='info'
      saveRequiredType='warning'
      authRequiredType='info'
      {...props}
    />
  )
})
const MailboxM365AuthAlert = React.forwardRef((props, ref) => {
  return (
    <M365AuthAlert
      title={I18n.t('mailboxM365AuthTitle', trOpt)}
      clientId={window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_CLIENT_ID}
      authOrigin='phishAlertMailbox'
      {...props}
    />
  )
})

class PhishAlert extends SettingsForm {
  constructor (props) {
    super(props)
    Object.defineProperty(this, 'defaultValue', {
      get: () => this.getDefaultValue()
    })

    this.preview = window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_PREVIEW === 'true'
    this.useTabs = true
    this.resizeTriggerFields = ['enabled', 'forwarding', 'includeEMLAttachment']
    this.addInManifestFields = [
      'alertVersion', 'displayName', 'actionLabel', 'actionTooltip', 'groupLabel', 'providerName',
      'icon', 'icon16', 'icon25', 'icon32', 'icon48', 'icon80', 'icon128'
    ]
    this.addInManifestUpdatedFields = [
      ...this.addInManifestFields,
      // These fields form the basis of useSSO prop in the the manifest POST body which tells it whether to include the web application info in its XML
      'sendThreatAssessment', 'forwarding', 'includeEMLAttachment', 'useSSO', 'supportsSharedFolders'
    ]
    this.ssoAuthFields = ['sendThreatAssessment', 'includeEMLAttachment', 'useSSO']
    this.mailboxAuthFields = ['includeEMLAttachment', 'useMailbox']

    this.companySettingsMutation = UPDATE_PHISH_ALERT_SETTINGS
    this.defaultTenantSettingsMutation = UPDATE_DEFAULT_TENANT_PHISH_ALERT_SETTINGS

    this.state = {
      manifestChanged: false,
      mailboxAuthChanged: false,
      ssoAuthChanged: false
    }

    this.requiredUpdatePermissions = [permissions.SETTINGS_UPHISH_UPDATE]
  }

  processDefaultValues (formDefaults, parentDefaults) {
    return Object.entries(formDefaults).reduce((acc, [id, formValue]) => {
      const parentValue = parentDefaults[id]
      if (_isString(formValue)) {
        acc[id] = _isString(parentValue) && !_isEmpty(parentValue) ? parentValue : formValue
      } else {
        acc[id] = _isNil(parentValue) ? formValue : parentValue
      }
      return acc
    }, {})
  }

  getDefaultValue () {
    const formDefaults = {
      enabled: false,
      displayName: I18n.t('displayName', defaultsTrOpt),
      alertVersion: '1.0.0.0',
      actionLabel: I18n.t('actionLabel', defaultsTrOpt),
      actionTooltip: I18n.t('actionTooltip', defaultsTrOpt),
      groupLabel: I18n.t('groupLabel', defaultsTrOpt),
      providerName: I18n.t('providerName', defaultsTrOpt),
      icon: `${PHISH_ALERT_ASSETS_URL}/icon-64.png`,
      icon16: `${PHISH_ALERT_ASSETS_URL}/icon-16.png`,
      icon25: `${PHISH_ALERT_ASSETS_URL}/icon-25.png`,
      icon32: `${PHISH_ALERT_ASSETS_URL}/icon-32.png`,
      icon48: `${PHISH_ALERT_ASSETS_URL}/icon-48.png`,
      icon80: `${PHISH_ALERT_ASSETS_URL}/icon-80.png`,
      icon128: `${PHISH_ALERT_ASSETS_URL}/icon-128.png`,
      sendThreatAssessment: false,
      forwarding: false,
      forwardingEmails: [],
      forwardingSubject: I18n.t('forwardingSubject', defaultsTrOpt),
      forwardingSenderName: I18n.t('forwardingSenderName', defaultsTrOpt),
      includeEMLAttachment: false,
      includeAttachmentsInEML: false,
      useOutlookApi: false,
      useSSO: false,
      useMailbox: false,
      supportsSharedFolders: false
    }
    const defaultForwardingSenderAddress = {
      prefix: 'phishalert',
      subdomain: ''
    }

    let parentDefaults = {}
    let parentDefaultForwardingSenderAddress = {}
    if (!this.defaultTenant && this.props.settings?.parentDefaultSettings) {
      parentDefaults = this.getFormValuesFromProps({
        settings: {
          phishAlert: _get(this.props, 'settings.parentDefaultSettings.phishAlert') || {}
        }
      });
      ({ forwardingSenderAddress: parentDefaultForwardingSenderAddress = {}, ...parentDefaults } = parentDefaults)

      return {
        ...this.processDefaultValues(formDefaults, parentDefaults),
        forwardingSenderAddress: this.processDefaultValues(defaultForwardingSenderAddress, parentDefaultForwardingSenderAddress)
      }
    }

    return {
      ...formDefaults,
      forwardingSenderAddress: defaultForwardingSenderAddress
    }
  }

  get mutationName () {
    return this.defaultTenant ? 'updateDefaultTenantPhishAlertSettings' : 'updateCompanyPhishAlertSettings'
  }

  get headerId () {
    return 'settings-phish-alert-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get tabs () {
    const { defaultTenant } = this
    return [{
      id: 'addIn',
      title: I18n.t('addIn', tabsTrOpt)
    }, {
      id: 'forwarding',
      title: I18n.t('forwarding', tabsTrOpt),
      disabled: isDisabled
    }, {
      id: 'm365',
      title: I18n.t('m365', tabsTrOpt),
      visible: values =>
        !defaultTenant && isEnabled(values) && (
          values.sendThreatAssessment === true ||
          (
            values.forwarding === true && values.includeEMLAttachment && (
              values.useSSO === true ||
              values.useMailbox === true
            )
          )
        ) && hasAnyPermission({ availablePermisisons: this.props.permissions, requiredPermissions: [permissions.SETTINGS_GENERAL_UPDATE] })
    }]
  }

  get addInManifestValues () {
    const { addInManifest, useSSO, includeEMLAttachment, forwarding, sendThreatAssessment, supportsSharedFolders } = _get(this.props, 'settings.phishAlert') || {}
    return {
      ...(addInManifest || {}),
      useSSO,
      includeEMLAttachment,
      forwarding,
      sendThreatAssessment,
      supportsSharedFolders
    }
  }

  get addInManifest () {
    const { companyId } = this.props
    const { useSSO, includeEMLAttachment, forwarding, sendThreatAssessment, supportsSharedFolders, ...addInManifest } = this.addInManifestValues
    if (Object.keys(addInManifest).length === 0) return undefined // Only return values if manifest settings exist on the company already
    return {
      ...addInManifest,
      companyId,
      // useSSO manifest POST body props which tells it whether to include the web application info in its XML
      useSSO: (
        (useSSO === true && includeEMLAttachment === true && forwarding === true) ||
        sendThreatAssessment === true
      ),
      supportsSharedFolders: SHARED_FOLDERS && supportsSharedFolders === true
    }
  }

  getM365AuthConfig (id, fields) {
    const { authType, tenantId } = _get(this.props, ['settings', 'phishAlert', 'microsoft365', id]) || {}
    return {
      ..._pick(_get(this.props, 'settings.phishAlert') || {}, fields),
      hasAuth: authType === 'admin' && tenantId === true
    }
  }

  get ssoAuthConfig () {
    return this.getM365AuthConfig('sso', this.ssoAuthFields)
  }

  get mailboxAuthConfig () {
    return this.getM365AuthConfig('mailbox', this.mailboxAuthFields)
  }

  get _fields () {
    const { defaultTenant } = this
    const {
      enabled = false,
      displayName,
      alertVersion,
      actionLabel,
      actionTooltip,
      groupLabel,
      providerName,
      icon,
      icon16,
      icon25,
      icon32,
      icon48,
      icon80,
      icon128,
      sendThreatAssessment = false,
      forwarding = false,
      forwardingEmails = [],
      forwardingSubject,
      forwardingSenderName,
      forwardingSenderAddress,
      includeEMLAttachment = false,
      includeAttachmentsInEML = false,
      useOutlookApi = false,
      useSSO = false,
      useMailbox = false,
      supportsSharedFolders = false
    } = {
      ...this.defaultValue,
      ...this.formValuesFromSettings
    }
    const appDomain = _get(this.props, ['settings', 'appDomain', DEFAULT_LANGUAGE]) || 'usecure.io'
    const { locale } = this.props
    const ssoAuthExtra = I18n.t('ssoAuthExtra', fieldsTrOpt)

    return [
      {
        id: 'enabled',
        type: 'switch',
        label: I18n.t('enabled', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('enabledExtra', fieldsTrOpt)} />,
        formItemStyle: { maxWidth: 600 },
        defaultValue: enabled,
        tab: 'addIn'
      },
      {
        id: 'manifestDownload',
        type: 'custom',
        component: ManifestDownload,
        manifestChanged: this.state.manifestChanged,
        addInManifest: this.addInManifest,
        addInManifestFields: this.addInManifestFields,
        visible: values => !defaultTenant && isEnabled(values),
        tab: 'addIn'
      },
      {
        id: 'displayName',
        type: 'text',
        component: InputWithReset,
        label: I18n.t('displayName', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('displayNameExtra', fieldsTrOpt)} />,
        required: true,
        defaultValue: displayName,
        defaultSetting: this.defaultValue.displayName,
        visible: isEnabled,
        tab: 'addIn'
      },
      {
        id: 'alertVersion',
        type: 'custom',
        component: AlertVersion,
        label: I18n.t('alertVersion', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('alertVersionExtra', fieldsTrOpt)} />,
        required: true,
        defaultValue: alertVersion,
        defaultSetting: this.defaultValue.alertVersion,
        visible: isEnabled,
        tab: 'addIn'
      },
      {
        id: 'actionLabel',
        type: 'text',
        component: InputWithReset,
        label: I18n.t('actionLabel', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('actionLabelExtra', fieldsTrOpt)} />,
        required: true,
        defaultValue: actionLabel,
        defaultSetting: this.defaultValue.actionLabel,
        visible: isEnabled,
        tab: 'addIn'
      },
      {
        id: 'actionTooltip',
        type: 'text',
        component: InputWithReset,
        label: I18n.t('actionTooltip', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('actionTooltipExtra', fieldsTrOpt)} />,
        required: true,
        defaultValue: actionTooltip,
        defaultSetting: this.defaultValue.actionTooltip,
        visible: isEnabled,
        tab: 'addIn'
      },
      {
        id: 'groupLabel',
        type: 'text',
        component: InputWithReset,
        label: I18n.t('groupLabel', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('groupLabelExtra', fieldsTrOpt)} />,
        required: true,
        defaultValue: groupLabel,
        defaultSetting: this.defaultValue.groupLabel,
        visible: isEnabled,
        tab: 'addIn'
      },
      {
        id: 'providerName',
        type: 'text',
        component: InputWithReset,
        label: I18n.t('providerName', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('providerNameExtra', fieldsTrOpt)} />,
        required: true,
        defaultValue: providerName,
        defaultSetting: this.defaultValue.providerName,
        visible: isEnabled,
        tab: 'addIn'
      },
      {
        id: 'supportsSharedFolders',
        type: 'switch',
        label: I18n.t('supportsSharedFolders', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('supportsSharedFoldersExtra', fieldsTrOpt)} />,
        defaultValue: supportsSharedFolders,
        defaultSetting: this.defaultValue.supportsSharedFolders,
        visible: allowSharedMailboxes,
        tab: 'addIn'
      },
      {
        id: 'icon',
        type: 'image',
        label: I18n.t('icon', fieldsTrOpt),
        required: true,
        defaultValue: icon,
        resetValue: this.defaultValue.icon,
        visible: isEnabled,
        width: 128,
        height: 128,
        requiredWidth: 64,
        requiredHeight: 64,
        tab: 'addIn'
      },
      {
        id: 'icon16',
        type: 'image',
        label: I18n.t('icon16', fieldsTrOpt),
        required: true,
        defaultValue: icon16,
        resetValue: this.defaultValue.icon16,
        visible: isEnabled,
        width: 128,
        height: 128,
        requiredWidth: 16,
        requiredHeight: 16,
        tab: 'addIn'
      },
      {
        id: 'icon25',
        type: 'image',
        label: I18n.t('icon25', fieldsTrOpt),
        required: true,
        defaultValue: icon25,
        resetValue: this.defaultValue.icon25,
        visible: isEnabled,
        width: 128,
        height: 128,
        requiredWidth: 25,
        requiredHeight: 25,
        tab: 'addIn'
      },
      {
        id: 'icon32',
        type: 'image',
        label: I18n.t('icon32', fieldsTrOpt),
        required: true,
        defaultValue: icon32,
        resetValue: this.defaultValue.icon32,
        visible: isEnabled,
        width: 128,
        height: 128,
        requiredWidth: 32,
        requiredHeight: 32,
        tab: 'addIn'
      },
      {
        id: 'icon48',
        type: 'image',
        label: I18n.t('icon48', fieldsTrOpt),
        required: true,
        defaultValue: icon48,
        resetValue: this.defaultValue.icon48,
        visible: isEnabled,
        width: 128,
        height: 128,
        requiredWidth: 48,
        requiredHeight: 48,
        tab: 'addIn'
      },
      {
        id: 'icon80',
        type: 'image',
        label: I18n.t('icon80', fieldsTrOpt),
        required: true,
        defaultValue: icon80,
        resetValue: this.defaultValue.icon80,
        visible: isEnabled,
        width: 128,
        height: 128,
        requiredWidth: 80,
        requiredHeight: 80,
        tab: 'addIn'
      },
      {
        id: 'icon128',
        type: 'image',
        label: I18n.t('icon128', fieldsTrOpt),
        required: true,
        defaultValue: icon128,
        resetValue: this.defaultValue.icon128,
        visible: isEnabled,
        width: 128,
        height: 128,
        requiredWidth: 128,
        requiredHeight: 128,
        tab: 'addIn'
      },
      {
        id: 'sendThreatAssessment',
        type: 'switch',
        label: I18n.t('sendThreatAssessment', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={[...I18n.t('sendThreatAssessmentExtra', fieldsTrOpt), ...ssoAuthExtra]} />,
        defaultValue: sendThreatAssessment,
        disabled: isDisabled,
        visible: SEND_M365_THREAT,
        tab: 'forwarding'
      },
      {
        id: 'forwarding',
        type: 'switch',
        label: I18n.t('forwarding', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('forwardingExtra', fieldsTrOpt)} />,
        defaultValue: forwarding,
        disabled: isDisabled,
        tab: 'forwarding'
      },
      {
        id: 'forwardingEmails',
        type: 'textTags',
        label: I18n.t('forwardingEmails', fieldsTrOpt),
        requiredError: I18n.t('forwardingEmailsRequiredError', fieldsTrOpt),
        emptyError: I18n.t('forwardingEmailsEmptyError', fieldsTrOpt),
        duplicateError: I18n.t('forwardingEmailsDuplicateError', fieldsTrOpt),
        required: true,
        defaultValue: forwardingEmails,
        visible: isForwarding,
        validateTag: value => {
          if (!validateEmail(value)) {
            return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
          }
        },
        tab: 'forwarding'
      },
      {
        id: 'forwardingSubject',
        type: 'text',
        component: InputWithReset,
        label: I18n.t('forwardingSubject', fieldsTrOpt),
        extra: (
          <div>
            <span>{I18n.t('forwardingSubjectExtra', fieldsTrOpt)}</span>
            <SettingsFormFieldExtra copy={I18n.t('forwardingSubjectExtraTokens', fieldsTrOpt)} />
          </div>
        ),
        required: true,
        defaultValue: forwardingSubject,
        defaultSetting: this.defaultValue.forwardingSubject,
        visible: isForwarding,
        tab: 'forwarding'
      },
      {
        id: 'forwardingSenderName',
        type: 'text',
        component: InputWithReset,
        label: I18n.t('forwardingSenderName', fieldsTrOpt),
        required: true,
        defaultValue: forwardingSenderName,
        defaultSetting: this.defaultValue.forwardingSenderName,
        visible: isForwarding,
        tab: 'forwarding'
      },
      {
        id: 'forwardingSenderAddress',
        type: 'custom',
        component: SenderAddressField,
        label: I18n.t('forwardingSenderAddress', fieldsTrOpt),
        defaultValue: forwardingSenderAddress,
        resetValue: this.defaultValue.forwardingSenderAddress,
        appDomain,
        required: true,
        visible: isForwarding,
        tab: 'forwarding'
      },
      {
        id: 'includeEMLAttachment',
        type: 'switch',
        label: I18n.t('includeEMLAttachment', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('includeEMLAttachmentExtra', fieldsTrOpt)} />,
        defaultValue: includeEMLAttachment,
        visible: isForwarding,
        tab: 'forwarding'
      },
      {
        id: 'includeAttachmentsInEML',
        type: 'switch',
        label: I18n.t('includeAttachmentsInEML', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('includeAttachmentsInEMLExtra', fieldsTrOpt)} />,
        defaultValue: includeAttachmentsInEML,
        visible: emlAttachmentEnabled,
        validate: (field, value, errors, opt, values) => {
          if (
            emlAttachmentEnabled(values) && value === true &&
            !(values.useSSO || values.useMailbox || (allowOutlookAPI(values) && values.useOutlookApi))
          ) {
            errors.push(I18n.t('includeAttachmentsAuthRequiredError', fieldsTrOpt))
          }
        },
        tab: 'forwarding'
      },
      {
        id: 'useSSO',
        type: 'switch',
        label: I18n.t('useSSO', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={[...I18n.t('useSSOExtra', fieldsTrOpt), ...ssoAuthExtra]} />,
        defaultValue: useSSO,
        visible: emlAttachmentEnabled,
        tab: 'forwarding'
      },
      {
        id: 'useMailbox',
        type: 'switch',
        label: I18n.t('useMailbox', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('useMailboxExtra', fieldsTrOpt)} />,
        defaultValue: useMailbox,
        visible: allowMailbox,
        tab: 'forwarding'
      },
      {
        id: 'useOutlookApi',
        type: 'switch',
        label: I18n.t('useOutlookApi', fieldsTrOpt),
        extra: <SettingsFormFieldExtra copy={I18n.t('useOutlookApiExtra', fieldsTrOpt)} />,
        defaultValue: useOutlookApi,
        visible: allowOutlookAPI,
        tab: 'forwarding'
      },
      {
        id: 'ssoM365Auth',
        type: 'custom',
        component: SSOM365AuthAlert,
        locale,
        authConfigChanged: this.state.ssoAuthChanged,
        authConfig: this.ssoAuthConfig,
        visible: values =>
          !defaultTenant && isEnabled(values) && (
            values.sendThreatAssessment === true ||
            (emlAttachmentEnabled(values) && values.useSSO === true)
          ),
        tab: 'm365'
      },
      {
        id: 'mailboxM365Auth',
        type: 'custom',
        component: MailboxM365AuthAlert,
        locale,
        authConfigChanged: this.state.mailboxAuthChanged,
        authConfig: this.mailboxAuthConfig,
        visible: values =>
          !defaultTenant && allowMailbox(values) && values.useMailbox === true,
        tab: 'm365'
      }
    ]
  }

  mutateValues (values) {
    const { forwardingSenderName, forwardingSenderAddress } = values
    return {
      ..._omit(values, [
        ...this.addInManifestFields,
        'forwardingSenderName', 'forwardingSenderAddress',
        'mailbox365Auth', 'sso365Auth', 'manifestDownload'
      ]),
      forwardingSender: {
        name: forwardingSenderName,
        ...(forwardingSenderAddress || {})
      },
      addInManifest: _pick(values, this.addInManifestFields)
    }
  }

  getFormValuesFromProps (props = this.props, alwaysReturn = true) {
    const setting = _get(props.settings, 'phishAlert') || {}
    if (!alwaysReturn && _isEmpty(setting)) {
      return
    }

    const { addInManifest, forwardingSender, ...rest } = _get(props.settings, 'phishAlert') || {}
    const values = {
      ...rest,
      ...(addInManifest || {})
    }

    const { name: forwardingSenderName, ...forwardingSenderAddress } = forwardingSender || {}
    if (forwardingSenderName) {
      values.forwardingSenderName = forwardingSenderName
    }
    if (forwardingSenderAddress.prefix || forwardingSenderAddress.subdomain) {
      values.forwardingSenderAddress = forwardingSenderAddress
    }

    return values
  }

  get formValuesFromSettings () {
    return this.getFormValuesFromProps()
  }

  getCurrentAndPreviousValuesFromProps (prevProps) {
    return {
      setting: this.getFormValuesFromProps(this.props, false),
      prevSetting: this.getFormValuesFromProps(prevProps, false)
    }
  }

  get mutationFormProps () {
    return {
      ...super.mutationFormProps,
      disableSubmitIfInvalid: false
    }
  }

  updateChanged (id, changedId, changedFields, currentValuesArg) {
    if (changedFields.includes(id)) {
      const currentValues = currentValuesArg || {}
      const { current: form } = this.form
      const formValues = form ? form.getValues() : {}
      this.setState({ [changedId]: changedFields.some(id => formValues[id] !== currentValues[id]) })
    }
  }

  onChange (id, value) {
    super.onChange(id, value)

    this.updateChanged(id, 'manifestChanged', this.addInManifestUpdatedFields, this.addInManifestValues)
    this.updateChanged(id, 'ssoAuthChanged', this.ssoAuthFields, this.ssoAuthConfig)
    this.updateChanged(id, 'mailboxAuthChanged', this.mailboxAuthFields, this.mailboxAuthConfig)
  }

  onSuccess (result) {
    super.onSuccess(result)
    this.setState({
      manifestChanged: false,
      mailboxAuthChanged: false,
      ssoAuthChanged: false
    })
    super.resetFields()
  }
}

export default compose(
  connect(
    state => ({
      ..._pick(selectors.session.get(state), ['permissions'])
    })
  )
)(PhishAlert)
