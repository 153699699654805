import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.gapAnalysisEmail' }
const courseTrOpt = { scope: 'settings.courseEmail' }

class GapAnalysisEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'gapAnalysis'
    this.showSentTestButton = true
    this.generateSubjectFields(['inviteSubject', 'reminderSubject'], courseTrOpt)
    this.requiredUpdatePermissions = [permissions.SETTINGS_ULEARN_UPDATE, permissions.SETTINGS_EMAIL_UPDATE]
  }

  get headerId () {
    return 'settings-gap-analysis-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(GapAnalysisEmail)
