import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form, Input, Select } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import DragAndDropList from './DragAndDropList'
import EditCourseMultipleChoiceAnswer from './EditCourseMultipleChoiceAnswer'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const { TextArea } = Input
const { Option } = Select

const trOpt = { scope: 'editCourse.editCourseMultipleChoice' }

const MultipleAnswersFormItem = styled(Form.Item)`
  margin-bottom: 0;
`

class EditCourseMultipleChoice extends Component {
  constructor (props) {
    super(props)

    this.handleMultipleChange = this.handleMultipleChange.bind(this)
    this.handleQuestionChange = this.handleQuestionChange.bind(this)
    this.handleRandomAnswerOrderChange = this.handleRandomAnswerOrderChange.bind(this)
    this.updateAnswers = this.updateAnswers.bind(this)

    this.answerTemplate = {
      id: null,
      text: '',
      correct: false,
      feedback: ''
    }
  }

  handleMultipleChange (event) {
    this.updateContent({ allowMultipleAnswers: event.target.checked })
  }

  handleRandomAnswerOrderChange (value) {
    this.updateContent({ randomizeAnswerOrder: value })
  }

  handleQuestionChange (event) {
    const question = event.target.value
    const title = question && question.length > 252 ? `${question.substr(0, 252)}...` : question
    this.props.updateTitle(title)
    this.updateContent({ question })
  }

  updateContent (update) {
    this.props.updateContent({ ...this.props.content, ...update })
  }

  updateAnswers (answers) {
    this.updateContent({ answers })
  }

  render () {
    const { content, updateInProgress, courseRandomizeAnswerOrder } = this.props
    const { question, answers, allowMultipleAnswers, randomizeAnswerOrder } = content || {}

    return (
      <div>
        <Form.Item label={I18n.t('question', trOpt)}>
          <TextArea
            onChange={this.handleQuestionChange}
            value={question}
          />
        </Form.Item>
        <MultipleAnswersFormItem>
          <Checkbox
            onChange={this.handleMultipleChange}
            checked={allowMultipleAnswers}
          >{I18n.t('allowMultipleAnswers', trOpt)}
          </Checkbox>
        </MultipleAnswersFormItem>
        <Form.Item
          label={I18n.t('randomizeAnswerOrderLabel', trOpt)}
          extra={I18n.t('randomizeAnswerOrderExtra', trOpt)}
        >
          <Select
            style={{ width: '100%' }}
            value={randomizeAnswerOrder ?? 'default'}
            onChange={this.handleRandomAnswerOrderChange}
          >
            <Option value='default'>
              {I18n.t('randomizeAnswerOrderDefaultOption', {
                ...trOpt, value: I18n.t(`common.${courseRandomizeAnswerOrder ? 'enabled' : 'disabled'}`)
              })}
            </Option>
            <Option value='enabled'>{I18n.t('common.enabled')}</Option>
            <Option value='disabled'>{I18n.t('common.disabled')}</Option>
          </Select>
        </Form.Item>
        <DragAndDropList
          list={answers || []}
          title={I18n.t('answers', trOpt)}
          itemName={I18n.t('answer', trOpt)}
          itemComponent={EditCourseMultipleChoiceAnswer}
          itemTemplate={this.answerTemplate}
          updateList={this.updateAnswers}
          updateInProgress={updateInProgress}
        />
      </div>
    )
  }
}

EditCourseMultipleChoice.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  courseRandomizeAnswerOrder: PropTypes.bool,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseMultipleChoice.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  courseRandomizeAnswerOrder: false,
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseMultipleChoice
