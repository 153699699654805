import React, { useEffect, useMemo, useState, useCallback, useImperativeHandle, useRef } from 'react'
import { Switch, Tag, Button } from 'antd'
import styled, { withTheme } from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import _keyBy from 'lodash/keyBy'
import _pick from 'lodash/pick'
import _sortBy from 'lodash/sortBy'

import LearnersView from '../../components/Learners/LearnersView'
import ViewPolicyVersionField, { PolicyVersionTag, getPolicyVersionOptions } from '../../components/Policies/ViewPolicyVersionField'
import RiskScoreIndicator from '../../components/Learners/RiskScoreIndicator'
import { GET_LEARNERS } from '../../components/Queries/Learners'
import { StatusTitleAll, StatusTitleVersion } from '../../components/Policies/ViewPolicyUsersStatusInfo'
import UploadPolicySignaturesModal from '../../components/Modals/UploadPolicySignaturesModal'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { getPolicyRecipientStatusText } from '../../helpers/Policies'
import { QUERY_POLL_INTERVAL } from '../../constants/apolloClient'
import { dateTimeColumnSorter } from '../../helpers/listPages'
import { formatDateTime } from '../../helpers/datetime'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uPolicy.viewPolicyUsers' }

const RecipientLine = styled.span`
  display: block;
  font-size: ${({ sub }) => sub ? '12px' : 'inherit'};
`

const StatusContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
  min-width: 70px;
`

const SwitchField = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  width: 145px;

  label {
    margin-right: 10px;
  }

  .ant-switch {
    margin-bottom: 5px;
  }
`

const ViewPolicyShowAllSwitch = ({ showAll = false, setShowAll = () => {} }) => {
  const onChange = useCallback(showAll => setShowAll(showAll), [setShowAll])

  return (
    <SwitchField>
      <label>{I18n.t('includeAllUsers', trOpt)}</label>
      <Switch size='small' checked={showAll} onChange={onChange} />
    </SwitchField>
  )
}

const LEARNER_VIEW_ACTIONS = ['sendPolicy', 'signPolicy', 'removeLearnersFromPolicy', 'exportPolicyData']
const POLICY_STATUS = ['notSent', 'pending', 'visited', 'signed']

const getStatusFilters = ({ showAll, version } = {}) => {
  const statusFilters = [
    { text: I18n.t('uPolicy.common.signed'), value: 'signed' }
  ]
  if (version === 'all') {
    statusFilters.unshift(
      { text: I18n.t('common.pending'), value: 'pending' },
      { text: I18n.t('common.visited'), value: 'visited' }
    )
    statusFilters.push({ text: I18n.t('notSigned', trOpt), value: 'notSigned' })
  }
  if (showAll) {
    statusFilters.unshift({ text: I18n.t('common.notSent'), value: 'notSent' })
  }
  return statusFilters
}

const ViewPolicyUsers = React.forwardRef(
  ({
    loading, error, policy, version = 'all', setVersion = () => {}, showAll = false, setShowAll = () => {}, query, theme, planValid,
    pollingEnabled = false, filters, updateFilters,
    ...learnerViewProps
  }, ref) => {
    const columns = useMemo(() => {
      const statusFilters = getStatusFilters({ showAll, version })

      let StatusTitle = StatusTitleVersion
      if (version === 'all') {
        StatusTitle = StatusTitleAll
      }

      return [
        {
          title: I18n.t('common.recipient'),
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name)
          },
          render (name, learner, index, nameComponent) {
            return (
              <div>
                <RecipientLine>{nameComponent || name}</RecipientLine>
                <RecipientLine sub>{learner.email || learner.learnerId}</RecipientLine>
              </div>
            )
          }
        }, {
          title: StatusTitle,
          dataIndex: 'policyStatus',
          key: 'policyStatus',
          render (statusId, learner) {
            const statusText = getPolicyRecipientStatusText(statusId)
            const { rag } = learner
            const tags = []
            let status = <span>{statusText}</span>
            if (statusId === 'signed') {
              const { versionNo, archived, major, offline } = learner
              if (versionNo) {
                tags.push(<PolicyVersionTag key='version' {...{ versionNo, archived, major }} />)
              }
              if (statusId === 'signed' && offline) {
                tags.push(<Tag key='offline' color={theme.orange}>{I18n.t('manual', trOpt)}</Tag>)
              }
            }
            if (rag) {
              status = (
                <StatusContainer>
                  <RiskScoreIndicator text={statusText} value={rag} />
                </StatusContainer>
              )
            }

            return (
              <>
                {status}
                {tags}
              </>
            )
          },
          filterMultiple: true,
          onFilter: (status, learner) => {
            if (status === 'notSigned') {
              return learner.policyStatus === 'pending' || learner.policyStatus === 'visited'
            } else {
              return learner.policyStatus === status
            }
          },
          filters: statusFilters.length > 1 ? statusFilters : null,
          sorter: (a, b) => {
            return POLICY_STATUS.indexOf(a.policyStatus) - POLICY_STATUS.indexOf(b.policyStatus)
          }
        }, {
          title: I18n.t('sentAt', trOpt),
          dataIndex: 'sentAtStr',
          key: 'sentAtStr',
          sorter: (a, b) => dateTimeColumnSorter('sentAt', a, b)
        }, {
          title: I18n.t('signedAt', trOpt),
          dataIndex: 'signedAtStr',
          key: 'signedAtStr',
          sorter: (a, b) => dateTimeColumnSorter('signedAt', a, b)
        }
      ]
    }, [showAll, version, theme])

    const { checkedGroups = [], status } = learnerViewProps
    const learnerVariables = {
      withRiskScore: false,
      groupIds: checkedGroups.length > 0 ? checkedGroups : null,
      inactive: status === 'all' ? null : status === 'inactive',
      isManager: status === 'managers' ? true : null
    }
    const { loading: allLearnersLoading, error: allLearnersError, data: allLearnersData, refetch } = useQuery(GET_LEARNERS, {
      variables: learnerVariables,
      skip: !showAll,
      pollInterval: pollingEnabled ? QUERY_POLL_INTERVAL : 0
    })

    const groupTreeRef = useRef(null)
    const refetchLearners = useCallback(() => {
      if (showAll) {
        refetch()
        if (groupTreeRef.current) {
          groupTreeRef.current.refetch()
        }
      }
    }, [showAll, refetch, groupTreeRef])
    useImperativeHandle(ref, () => ({
      refetchLearners
    }), [refetchLearners])

    const [rawLearners, setRawLearners] = useState([])
    const [learners, setLearners] = useState([])
    useEffect(() => {
      const results = policy.results || []
      const optionValueById = getPolicyVersionOptions(policy, true, true, false).reduce((acc, option) => {
        if (option.id) {
          acc[option.id] = option.value
        }
        return acc
      }, {})
      const documentMap = _keyBy(
        (policy.documents || []).reduce((acc, doc) => {
          const { id, status, major, majorVersion, minorVersion } = doc
          if (status === 'archived' || status === 'live') {
            acc.push({
              id,
              major,
              status,
              archived: status === 'archived',
              versionNo: `v${majorVersion}.${minorVersion}`
            })
          }
          return acc
        }, [])
        , 'id')
      setRawLearners(results.map(result => {
        const { learner, createdAt: sentAt, visit, signed, versionSigned: versionSignedId, signedOffline } = result

        const rawLearner = {
          key: result.id,
          ...learner,
          policyStatus: 'pending',
          rag: 'red',
          sentAt,
          sentAtStr: formatDateTime(sentAt),
          signedAt: null,
          signedAtStr: null,
          versionSignedId: null,
          versionSigned: null,
          offline: false
        }

        if (signed) {
          rawLearner.policyStatus = 'signed'
          rawLearner.rag = 'green'
          rawLearner.signedAt = signed
          rawLearner.signedAtStr = formatDateTime(signed)
          rawLearner.offline = signedOffline === true
          if (versionSignedId) {
            rawLearner.versionSignedId = versionSignedId
            rawLearner.versionSigned = optionValueById[versionSignedId] || versionSignedId
            const docSigned = documentMap[versionSignedId]
            if (docSigned) {
              rawLearner.archived = docSigned.archived
              rawLearner.major = docSigned.major
              rawLearner.versionNo = docSigned.versionNo
            }
          }
        } else if (visit) {
          rawLearner.policyStatus = 'visited'
          rawLearner.rag = 'amber'
        }
        return rawLearner
      }))
    }, [policy])
    // Show all learners and version filters
    useEffect(() => {
      let learners = []
      let policyLearners = rawLearners
      if (version === 'live') {
        // Include pending and visited records when the live version is selected as this is the version those users could sign at present
        policyLearners = rawLearners.filter(({ policyStatus, versionSigned }) => policyStatus === 'pending' || policyStatus === 'visited' || (policyStatus === 'signed' && versionSigned === version))
      } else if (version !== 'all') {
        policyLearners = rawLearners.filter(({ policyStatus, versionSigned }) => policyStatus === 'signed' && versionSigned === version)
      }
      if (showAll) {
        const { learners: allLearners = [] } = allLearnersData || {}
        const allLearnerIds = allLearners.map(l => l.id)
        policyLearners = policyLearners.filter(l => allLearnerIds.includes(l.id))
        const policyLearnerIds = policyLearners.map(l => l.id)
        learners = [
          ...policyLearners,
          ...allLearners.reduce((acc, learner) => {
            if (!policyLearnerIds.includes(learner.id)) {
              acc.push({
                ...learner,
                policyStatus: 'notSent',
                rag: 'transparent'
              })
            }
            return acc
          }, [])
        ]
      } else {
        learners = policyLearners.filter(({ status }) => status !== 'notSent')
      }
      learners = _sortBy(learners, ['name', 'sentAt'])
      setLearners(learners)
    }, [version, showAll, rawLearners, allLearnersData])

    // Reset status column filter when the version filter or Show all toggle is changed
    const clearStatusFilter = useCallback(({ version: updatedVersion, showAll: updatedShowAll } = {}) => {
      const statusFilterValue = filters?.policyStatus
      if (!_isArray(statusFilterValue)) return

      const statusFilters = getStatusFilters({
        showAll: !_isNil(updatedShowAll) ? updatedShowAll : showAll,
        version: !_isNil(updatedVersion) ? updatedVersion : version
      })
      const updatedFilters = { ...(filters || {}) }
      if (statusFilters.length <= 1) {
        delete updatedFilters.policyStatus
      } else {
        const statusFilterValues = statusFilters.map(f => f.value)
        updatedFilters.policyStatus = statusFilterValue.filter(value => statusFilterValues.includes(value))
      }
      updateFilters(updatedFilters)
    }, [filters, updateFilters, version, showAll])
    const onVersionChange = useCallback(version => {
      clearStatusFilter({ version })
      setVersion(version)
    }, [setVersion, clearStatusFilter])
    const onShowAllChange = useCallback(showAll => {
      clearStatusFilter({ showAll })
      setShowAll(showAll)
    }, [setShowAll, clearStatusFilter])

    const panelLeft = [
      <ViewPolicyVersionField key='policyVersion' width={170} draft={false} includeAggregatorOptions {...{ policy, version }} setVersion={onVersionChange} />
    ]
    const refetchQueries = [query]
    if (showAll) {
      panelLeft.push('status')
      refetchQueries.push({
        query: GET_LEARNERS,
        variables: {
          withRiskScore: false,
          groupIds: checkedGroups.length > 0 ? checkedGroups : null,
          inactive: status === 'all' ? null : status === 'inactive',
          isManager: status === 'managers' ? true : null
        }
      })
    }

    const [showUploadPolicySignaturesModal, updateShowUploadPolicySignaturesModal] = useState(false)
    const onUploadClick = useCallback(() => updateShowUploadPolicySignaturesModal(true), [updateShowUploadPolicySignaturesModal])
    const { hasAllSessionPermissions } = useHasSessionPermission()
    panelLeft.push(hasAllSessionPermissions([permissions.POLICY_RESULT_CREATE]) && <Button disabled={!planValid} key='uploadSignatures' type='primary' icon='file-done' onClick={onUploadClick}>{I18n.t('uPolicy.common.uploadSignatures')}</Button>)
    return (
      <>
        <UploadPolicySignaturesModal
          {...{ policy, refetchQueries }}
          visible={showUploadPolicySignaturesModal} setVisible={updateShowUploadPolicySignaturesModal}
        />
        <LearnersView
          loading={loading || allLearnersLoading}
          error={error || allLearnersError}
          {...{
            learners,
            columns,
            policy,
            panelLeft,
            refetchQueries,
            groupTreeRef,
            filters,
            updateFilters
          }}
          {...learnerViewProps}
          showGroups={showAll}
          panelRight={[
            <ViewPolicyShowAllSwitch key='showAll' {...{ showAll }} setShowAll={onShowAllChange} />,
            'search', 'actions'
          ]}
          groupTreeVariables={_pick(learnerVariables, ['inactive', 'isManager'])}
          allowedActions={LEARNER_VIEW_ACTIONS}
          defaultSortByName={false}
        />
      </>
    )
  }
)

export default compose(
  connect(
    state => _pick(selectors.session.get(state), ['planValid'])
  ),
  withTheme
)(ViewPolicyUsers)
