import React, { useCallback, useState } from 'react'
import { message } from 'antd'

import { UPDATE_PASSWORD } from '../Queries/Users'
import MutationForm from '../MutationForm/MutationForm'
import { LoadingBlock } from '../common'
import { showErrors } from '../../helpers'
import { SettingsContainer } from '../Settings/common'
import I18n from 'i18n-js'
import IntercomHeader from '../IntercomHeader'

const i18nBase = 'accountSettings.changePassword'

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)

  const changePasswordFields = [
    { id: 'oldPassword', label: I18n.t('common.fields.password'), placeholder: I18n.t('common.fields.password'), type: 'password', required: true, autofill: false },
    { id: 'password', label: I18n.t(`${i18nBase}.newPassword`), placeholder: I18n.t(`${i18nBase}.newPassword`), type: 'password', required: true },
    {
      id: 'confirmPassword',
      label: I18n.t(`${i18nBase}.confirmPassword`),
      placeholder: I18n.t(`${i18nBase}.confirmPassword`),
      type: 'password',
      required: true,
      autofill: false,
      visible: ({ password }) => typeof password === 'string' && password.length > 0
    }
  ]

  const onFailure = useCallback(e => {
    showErrors(e, I18n.t(`${i18nBase}.anErrorOccurredUpdatingYourPassword`))
    setLoading(false)
  }, [])

  const onSuccess = useCallback(result => {
    const { data: { updatePassword: success } = {} } = result || {}
    if (success) {
      message.success(I18n.t(`${i18nBase}.passwordUpdated`))
    } else {
      onFailure()
    }
    setLoading(false)
  }, [onFailure])

  const onSubmit = useCallback(() => setLoading(true), [])

  const doPasswordsMatch = useCallback(form => {
    const { state: { values: { password, confirmPassword } = {} } = {} } = form || {}
    if (password !== confirmPassword) {
      message.error(I18n.t(`${i18nBase}.passwordsDoNotMatch`))
      return false
    }

    return true
  }, [])

  return (
    <>
      {loading && <LoadingBlock fullScreen loading={loading} />}
      <IntercomHeader Size='h1' id='account-settings-change-password'>{I18n.t(`${i18nBase}.changePassword`)}</IntercomHeader>
      <SettingsContainer>
        <MutationForm
          mutation={UPDATE_PASSWORD}
          onSuccess={onSuccess}
          onFailure={onFailure}
          onSubmit={onSubmit}
          submitLabel={I18n.t(`${i18nBase}.updatePassword`)}
          disableSubmitIfInvalid={false}
          isValidBeforeSubmit={doPasswordsMatch}
          fields={changePasswordFields}
        />
      </SettingsContainer>
    </>
  )
}

export default ChangePassword
