import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { message, Modal } from 'antd'
import { Query } from 'react-apollo'
import I18n from 'i18n-js'

import { GET_COMPANIES } from '../Queries/Companies'
import { DUPLICATE_COURSE } from '../Queries/Courses'
import MutationForm from '../MutationForm/MutationForm'
import { ErrorAlerts } from '../common'
import { getCoursesRefetchQueries } from '../../helpers/courses'
import ModalHeaderId from '../Modals/ModalHeaderId'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'
const trOpt = { scope: 'courses.duplicateCourseModal' }

const DuplicateCourseModalForm = React.forwardRef(({
  course, companies, showCompanies = false,
  closeModal = () => {}
}, ref) => {
  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  useImperativeHandle(ref, () => ({
    reset: resetForm
  }), [resetForm])
  useEffect(() => {
    resetForm()
  }, [course, resetForm])

  const { id: courseId, name: courseName, companyId } = course || {}

  const { fields, refetchQueries } = useMemo(() => {
    const fields = [
      { id: 'courseName', label: I18n.t('common.courseTitle'), required: true, defaultValue: I18n.t('common.duplicateDefaultName', { name: courseName }) }
    ]
    if (showCompanies) {
      fields.push({
        label: I18n.t('common.accessControl.ownedBy'),
        id: 'companyId',
        type: 'select',
        required: true,
        options: [
          { value: 'usecure', label: I18n.t('common.managedByUsecure') },
          ...(
            (companies || [])
              .map(({ id: value, name: label }) => ({ value, label }))
              .sort((a, b) => a.label.localeCompare(b.label))
          )
        ],
        defaultValue: companyId || 'usecure',
        mutateValue: value => value === 'usecure' ? null : value
      })
    }

    return {
      fields,
      refetchQueries: getCoursesRefetchQueries(showCompanies)
    }
  }, [courseName, companyId, companies, showCompanies])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('courseDuplicatedSuccessfully', trOpt))
    closeModal()
  }, [closeModal])

  return (
    <MutationForm
      mutation={DUPLICATE_COURSE}
      variables={{ courseId }}
      submitLabel={I18n.t('common.saveDuplicate')}
      {...{ fields, onSuccess }}
      failureMessage={I18n.t('anErrorOccurredDuplicatingThisCourse', trOpt)}
      ref={form}
      refetchQueries={refetchQueries}
      skipResetFieldsOnSubmit
    />
  )
})

const DuplicateCourseModal = ({
  courses = [], visible, setVisible = () => {}, showCompanies = true
}) => {
  const closeModal = useCallback(() => setVisible(false), [setVisible])
  // This modal only works for a single course
  if (courses && courses.length > 1) {
    visible = false
  }
  const [course] = courses
  const { name = '' } = course || {}
  const { hasAnySessionPermission } = useHasSessionPermission()
  const canSetCompanyAccess = hasAnySessionPermission([permissions.COURSE_SUPER_ACCESS_CONTROL, permissions.COURSE_SUPER_CREATE])

  return (
    <Modal visible={visible} onCancel={closeModal} title={<ModalHeaderId idText='duplicate-course-modal-header' text={I18n.t('common.duplicateTitle', { name })} />} footer={null}>
      <Query query={GET_COMPANIES} skip={!canSetCompanyAccess}>
        {({ loading, error, data }) => {
          if (error) return <ErrorAlerts {...{ error }} />
          if (loading) return I18n.t('common.loading')

          const { companies } = data || {}

          return (
            <DuplicateCourseModalForm
              {...{ course, companies, closeModal }}
              showCompanies={showCompanies && canSetCompanyAccess}
            />
          )
        }}
      </Query>
    </Modal>
  )
}

export default DuplicateCourseModal
