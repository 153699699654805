import React, { Component } from 'react'
import styled from 'styled-components'
import uuid from 'uuid/v4'
import { Modal } from 'antd'
import _cloneDeep from 'lodash/cloneDeep'
import I18n from 'i18n-js'

import ConfirmTitle from './ConfirmTitle'
import ChangedIndicator from './ChangedIndicator'
import EditCourseAnnotatedImagePoint from './EditCourseAnnotatedImagePoint'
import { LoadingBlock } from '../common'

const { confirm } = Modal

const AnnotatedImageEditorContainer = styled.div`
  margin-top: 20px;
`

const AnnotatedImageWrap = styled.div`
  margin-bottom: 10px;
  position: relative;
`

const AnnotatedImageImg = styled.img`
  width: 100%;
`

const InteractContainer = styled.div`
  border: gray solid 1px;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const trOpt = { scope: 'editCourse.editCourseAnnotatedImagesEditor' }

class EditCourseAnnotatedImageEditor extends Component {
  constructor (props) {
    super(props)

    this.state = this.initialiseState(props, false)

    this.img = React.createRef()

    this.afterClose = this.afterClose.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleImageLoad = this.handleImageLoad.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleOkClick = this.handleOkClick.bind(this)
    this.handlePlacementChange = this.handlePlacementChange.bind(this)
    this.handlePointClick = this.handlePointClick.bind(this)
    this.handlePointEnd = this.handlePointEnd.bind(this)
    this.handlePointStart = this.handlePointStart.bind(this)
    this.handlePointUpdate = this.handlePointUpdate.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.revert = this.revert.bind(this)
  }

  initialiseState (props = this.props, updateState = true) {
    const { points = {} } = this.props
    const stateUpdate = {
      changed: false,
      ready: false,
      imageWidth: 0,
      imageHeight: 0,
      points: _cloneDeep(points),
      activePoint: null,
      ignorePointClick: false
    }

    if (updateState) {
      this.setState(stateUpdate)
    }

    return stateUpdate
  }

  handleResize () {
    this.updateImageDimensions(this.updatePointPositions)
  }

  // Timeout in place to solve issue where img element dimensions onLoad are larger than the rendered element in browser (no issue on resize)
  // Points are positioned incorrectly if imageWidth and/or imageHeight are incorrect
  handleImageLoad (e) {
    setTimeout(() => {
      this.handleResize()
      this.setState({ ready: true })
    }, 500)
  }

  updateImageDimensions (cb) {
    if (this.img && this.img.current) {
      cb = typeof cb === 'function' ? cb : () => {}
      const { width: imageWidth, height: imageHeight } = this.img.current
      this.setState({ imageWidth, imageHeight }, cb)
    }
  }

  updatePointPositions () {
    const { points, imageWidth, imageHeight } = this.state

    Object.keys(points).forEach(id => {
      const { x, y } = points[id]
      points[id] = {
        ...points[id],
        posX: x * imageWidth,
        posY: y * imageHeight
      }
    })

    this.setState({ points })
  }

  handlePointClick (id) {
    if (!this.state.ignorePointClick) {
      this.setState({ activePoint: id === this.state.activePoint ? null : id })
    }
  }

  // This compensates for inital incorrect image size by rechecking it when the starts to drag a point
  // This way the points should be correctly position when the image is resized
  handlePointStart () {
    this.updateImageDimensions()
  }

  handlePointUpdate (id, dx, dy) {
    const { points, imageWidth, imageHeight } = this.state

    if (!points[id]) {
      return
    }

    const { x, y } = points[id]

    let posX = x * imageWidth
    let posY = y * imageHeight

    posX += dx
    posY += dy

    points[id] = {
      ...points[id],
      x: posX / imageWidth,
      y: posY / imageHeight,
      posX,
      posY
    }

    this.setState({ points, changed: true })
  }

  handlePointEnd () {
    this.setState({ ignorePointClick: true })
    setTimeout(() => this.setState({ ignorePointClick: false }), 500)
  }

  updatePoints () {
    if (typeof this.props.updatePoints === 'function') {
      const { points } = this.state

      this.props.updatePoints(Object.keys(points).reduce((pointsUpdate, id) => {
        const { x, y, text, placement } = points[id]
        pointsUpdate[id] = {
          id,
          x,
          y,
          text,
          placement
        }
        return pointsUpdate
      }, {}))
    }
  }

  handleOkClick () {
    this.props.onCancel()
    this.updatePoints()
  }

  revert () {
    this.props.onCancel()
    this.setState({
      changed: false,
      points: _cloneDeep(this.props.points)
    }, () => this.updatePointPositions())
  }

  handleCancelClick () {
    if (this.state.changed) {
      confirm({
        title: (
          <ConfirmTitle>{I18n.t('youHavePendingChangesOnThisImage', trOpt)}</ConfirmTitle>
        ),
        okText: I18n.t('common.yes'),
        cancelText: I18n.t('common.no'),
        onOk: this.revert
      })
    } else {
      this.revert()
    }
  }

  addPoint (x = 0, y = 0) {
    const { points } = this.state
    const id = uuid()
    points[id] = {
      id,
      x,
      y,
      posX: 0,
      posY: 0,
      text: ''
    }

    this.setState({ points, changed: true }, () => {
      this.updatePointPositions()
      this.setState({ activePoint: id })
    })
  }

  handleAddClick (e) {
    if (this.state.activePoint) {
      return this.setState({ activePoint: null })
    }

    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    let pointWidth = 0.022 * viewportWidth // Calculate pixel value of 2.2vw on current viewport
    pointWidth = pointWidth > 30 ? 30 : pointWidth // match points max width
    const rect = e.target.getBoundingClientRect()
    const posX = e.clientX - rect.left - (pointWidth / 2)
    const posY = e.clientY - rect.top - (pointWidth / 2)
    const x = (posX / rect.width)
    const y = (posY / rect.height)
    this.addPoint(x, y)
  }

  removePoint (id) {
    const { points, activePoint } = this.state

    if (points[id]) {
      delete points[id]
      const stateUpdate = { points, changed: true }

      if (activePoint === id) {
        stateUpdate.activePoint = null
      }

      // this.setState(stateUpdate, this.updatePoints)
      this.setState(stateUpdate)
    }
  }

  handleRemoveClick (id) {
    this.removePoint(id)
  }

  handleTextChange (id, value) {
    this.updatePoint(id, 'text', value)
  }

  handlePlacementChange (id, value) {
    this.updatePoint(id, 'placement', value)
  }

  updatePoint (id, prop, value) {
    const { points } = this.state

    if (points[id]) {
      points[id][prop] = value
      this.setState({ points, changed: true })
    }
  }

  afterClose () {
    this.setState({ activePoint: null, changed: false })
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate (prevProps, prevState) {
    const { slideId, locale, updateId, updateInProgress } = this.props
    const { slideId: prevSlideId, updateId: prevUpdateId, locale: prevLocale } = prevProps
    const { changed } = this.state
    const { changed: prevChanged } = prevState

    // Reload points from props into state when the slide or locale has changed
    if (
      (slideId && prevSlideId && slideId !== prevSlideId) ||
      (locale && prevLocale && locale !== prevLocale) ||
      (updateId && prevUpdateId && updateId !== prevUpdateId)
    ) {
      this.initialiseState()
    }
    if (changed !== prevChanged) {
      updateInProgress(changed)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  render () {
    const { visible, image } = this.props
    const { changed, points, imageHeight, ready, activePoint } = this.state

    return (
      <Modal
        width='80%'
        style={{ top: 70, maxWidth: '1000px' }}
        bodyStyle={{ paddingTop: '0' }}
        visible={visible}
        closable={false}
        maskClosable={false}
        okText='Save'
        onOk={this.handleOkClick}
        onCancel={this.handleCancelClick}
        afterClose={this.afterClose}
        title={<span><ChangedIndicator changed={changed} />{I18n.t('annotatedImagePoints', trOpt)}</span>}
      >
        <AnnotatedImageEditorContainer>
          <AnnotatedImageWrap onClick={this.handleAddClick}>
            <LoadingBlock loading={!ready} fullScreen={false} />
            {
              image && visible && (
                <AnnotatedImageImg
                  onLoad={this.handleImageLoad} src={image}
                  ref={this.img}
                />
              )
            }
            <InteractContainer
              className='interact-container'
              style={{
                height: imageHeight ? `${imageHeight}px` : undefined
              }}
            >
              {
                Object.keys(points).map(id => {
                  const { x, y, posX, posY, text, placement } = points[id]
                  const active = id === activePoint

                  return (
                    <EditCourseAnnotatedImagePoint
                      key={id}
                      {...{ id, x, y, posX, posY, active, text, placement, hide: !ready }}
                      onClick={this.handlePointClick}
                      onStart={this.handlePointStart}
                      onUpdate={this.handlePointUpdate}
                      onEnd={this.handlePointEnd}
                      onTextChange={this.handleTextChange}
                      onPlacementChange={this.handlePlacementChange}
                      onRemoveClick={this.handleRemoveClick}
                    />
                  )
                })
              }
            </InteractContainer>
          </AnnotatedImageWrap>
          <div>
            <p>{I18n.t('clickAnywhereOnTheImage', trOpt)}</p>
            <p>{I18n.t('youCanEditExistingPoints', trOpt)}</p>
            <p>{I18n.t('hoverYourMouseOverAPoint', trOpt)}</p>
          </div>
        </AnnotatedImageEditorContainer>
      </Modal>
    )
  }
}

export default EditCourseAnnotatedImageEditor
