import React from 'react'
import I18n from 'i18n-js'

import DeleteConfirm from './DeleteConfirm'
import { DELETE_COMPANIES } from '../Queries/Companies'

const DeleteCompanyConfirm = ({ visible = false, setVisible = () => {}, companyIds = [], accountType, refetchQueries, client }) => {
  const trOpt = { scope: `modals.deleteCompanyConfirm.${accountType || 'company'}` }

  let deletedItems = ['deletedItem1', 'deletedItem2']
  if (accountType === 'prospect' || accountType === 'tenant') {
    deletedItems = ['deletedItem']
  } else if (accountType === 'distributor') {
    deletedItems.push('deletedItems3')
  }
  deletedItems = deletedItems.map(id => <li key={id}>{I18n.t(id, { ...trOpt, count: companyIds.length })}</li>)

  return (
    <DeleteConfirm
      {...{ visible, setVisible, client, refetchQueries, deletedItems, trOpt }}
      mutation={DELETE_COMPANIES}
      variableName='companyIds'
      ids={companyIds}
    />
  )
}

export default DeleteCompanyConfirm
