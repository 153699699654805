import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import I18n from 'i18n-js'

import ProgressCircle from './ProgressCircle'

const ProgressButtonWrap = styled.div`
  display: inline-block;
  padding: 0 10px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const ProgressButtonComponent = styled(Button)`
  box-shadow: 1px 1px 1px #5a5a5a;
  height: 60px;
  width: 60px;
`

const ProgressIcon = styled.i`
  font-size: 20px;
  position: relative;
  top: 5px;
`

const ProgressLabel = styled.span`
  color: ${({ theme }) => theme.white};
  display: block;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  text-shadow: 1px 1px #5a5a5a;
`

const ProgressButton = ({ label = 'common.next', disabled, icon = 'arrow-right', onClick, waitMS, timerActive }) => {
  return (
    <ProgressButtonWrap disabled={disabled || timerActive}>
      <ProgressCircle {...{ waitMS, timerActive }}>
        <ProgressButtonComponent shape='circle' type='primary' disabled={disabled || timerActive} onClick={onClick}>
          <ProgressIcon className={`fa fa-${icon}`} />
        </ProgressButtonComponent>
      </ProgressCircle>
      {label ? <ProgressLabel>{I18n.t(label)}</ProgressLabel> : null}
    </ProgressButtonWrap>
  )
}

export default ProgressButton
