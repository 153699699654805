import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'antd'
import I18n from 'i18n-js'

import { renderListFromArray, renderParagraphsFragmentFromArray } from '../../helpers'

export const IntroHint = styled.div`
  position: fixed;
  text-align: center;
  left: 0;
  right: 0;
  font-weight: 500;
  color: #999;
  bottom: 5vh;
`

export const IntroWrap = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding-bottom: 10vh;
`

export const IntroDetail = styled.div`
  margin-bottom: 1.4rem;
  max-width: 1000px;
  padding: 0 20px;
  text-align: center;
`

export const IntroDetailNoCenter = styled.div`
  margin-bottom: 1.4rem;
  max-width: 1000px;
  padding: 0 20px;
`

export const GapAnalysisIntro = styled.div`
  line-height: 1.5;
  padding: 0 20px;
  >* {
      text-align: center;
      max-width: 560px;
      margin: 0 auto;
  }

  h4 {
    margin-bottom: 37.5px;
    max-width: 308px;
  }
  h6 {
    max-width: 252px;
  }
  ul {
      text-align: left;
      max-width: 420px;
      margin: 42px auto;
  }
  .ant-btn {
    height: 45px;
    width: 190px;
  }
  p:not(:last-child) {
      margin-bottom: 16px;
  }
`

const gaIntroTrOpt = { scope: 'learnerCourse.gapAnalysisIntro' }

class LearnerCourseIntro extends Component {
  constructor (props) {
    super(props)

    this.handleStartClick = this.handleStartClick.bind(this)
    this.renderIntro = this.renderIntro.bind(this)
  }

  handleStartClick () {
    this.props.start()
  }

  renderIntro () {
    return (
      <GapAnalysisIntro>
        <h4>{I18n.t('title', gaIntroTrOpt)}</h4>
        {renderParagraphsFragmentFromArray(I18n.t('body', gaIntroTrOpt))}
        {renderListFromArray(I18n.t('guidePoints', gaIntroTrOpt))}
        <p><Button type='primary' onClick={this.handleStartClick}>{I18n.t('common.start')}</Button></p>
      </GapAnalysisIntro>
    )
  }

  // This markup has been pulled from v1
  render () {
    const { courseTitle, courseDescription, isGapAnalysis } = this.props

    return (
      <IntroWrap>
        {
          isGapAnalysis
            ? (
              this.renderIntro()
            )
            : (
              <IntroDetail>
                <h1>{courseTitle}</h1>
                <h3>{courseDescription}</h3>
                <Button type='primary' onClick={this.handleStartClick}>{I18n.t('learnerCourse.startCourse')}</Button>
              </IntroDetail>
            )
        }
        <IntroHint>{I18n.t('learnerCourse.hint')}</IntroHint>
      </IntroWrap>
    )
  }
}

LearnerCourseIntro.propTypes = {
  courseTitle: PropTypes.string,
  courseDescription: PropTypes.string,
  isGapAnalysis: PropTypes.bool,
  status: PropTypes.string,
  start: PropTypes.func
}

LearnerCourseIntro.defaultProps = {
  courseTitle: '',
  description: '',
  isGapAnalysis: false,
  status: 'new',
  start: () => {}
}

export default LearnerCourseIntro
