import React, { useCallback, useEffect, useState } from 'react'
import { Button, Descriptions, Modal as _Modal, Tabs } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { EmailTags } from '../SyncSetup/SyncSetupUserEmailBlacklist'
import SummaryGroups from './SyncConfigSummaryGroups'
import { getGroupAllowListCounts } from '../../helpers'

const syncTrOpt = { scope: 'sync.configSummary' }

const { TabPane } = Tabs

const Modal = styled(_Modal)`
  height: calc(100% - 60px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-modal-content {
    height: 90%;
  }
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-content {
    height: calc(100% - 60px);
  }
`
const ModalScroller = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
`

const SyncConfigSummary = ({ className, googleSync, office365, groupTypes, groupsQuery, groupsQueryKey, usePaginatedGroupLoad, syncType, trOpt, groupsTrOpt }) => {
  let settings
  if (syncType === 'microsoft') settings = { ...office365 } || {}
  if (syncType === 'google') settings = { ...googleSync } || {}

  const {
    authType,
    autoSync, includeGroups,
    groupType = 'all',
    selectAllGroups,
    includeGroupMembWhitelist,
    selectAllGroupMembWhitelist,
    includeGroupMembMapping,
    includeUserEmailBlacklist,
    userEmailBlacklist = [],
    excludeUsersWithoutMailboxSetup,
    excludeSuspendedUsers,
    excludeArchivedUsers,
    groupWhitelist,
    groupMembWhitelist,
    emailMethod,
    excludeUsersWithoutAssignedLicenses,
    syncManagers
  } = settings || {}

  const [visible, setVisible] = useState(false)
  const [tabKey, setTabKey] = useState('options')
  const [groupCount, setGroupCount] = useState(0) // Synced Groups count
  const [groupMembCount, setGroupMembCount] = useState(0) // Groups Users are synced from count

  const openModal = useCallback(() => setVisible(true), [setVisible])
  const closeModal = useCallback(() => setVisible(false), [setVisible])
  const afterClose = useCallback(() => {}, [])
  const onTabChange = useCallback(tabKey => setTabKey(tabKey), [setTabKey])

  const hasGroupConfig = includeGroups || includeGroupMembWhitelist || includeGroupMembMapping
  const hasUserBlacklist = includeUserEmailBlacklist && !_isEmpty(userEmailBlacklist)

  useEffect(() => {
    if ((!hasGroupConfig && tabKey === 'groups') ||
      (!hasUserBlacklist && tabKey === 'users')) {
      setTabKey('options')
    }
  }, [setTabKey, tabKey, hasGroupConfig, hasUserBlacklist])

  useEffect(() => {
    const { groupCount, groupMembCount } = getGroupAllowListCounts({ groupWhitelist, groupMembWhitelist, includeGroups, includeGroupMembWhitelist, selectAllGroups, selectAllGroupMembWhitelist })
    setGroupCount(groupCount)
    setGroupMembCount(groupMembCount)
  }, [groupWhitelist, groupMembWhitelist, includeGroups, includeGroupMembWhitelist, selectAllGroups, selectAllGroupMembWhitelist])

  const yes = I18n.t('common.yes')
  const no = I18n.t('common.no')

  return (
    <>
      <Button {...{ className }} icon='setting' onClick={openModal}>{I18n.t('viewCurrentSettings', trOpt)}</Button>
      <Modal
        width='90%'
        bodyStyle={{ paddingTop: 5, height: 'calc(100% - 40px)' }}
        visible={visible}
        onOk={closeModal}
        onCancel={closeModal}
        afterClose={afterClose}
        footer={null}
        destroyOnClose
        title={I18n.t('title', trOpt)}
      >
        <ModalBody>
          <Tabs activeKey={tabKey} onChange={onTabChange}>
            <TabPane tab={I18n.t('optionsTab', trOpt)} key='options'>
              <ModalScroller>
                <Descriptions>
                  <Descriptions.Item label={I18n.t('authType', syncTrOpt)}>{I18n.t(`authTypes.${authType}`, trOpt)}</Descriptions.Item>
                  <Descriptions.Item label={I18n.t('automaticSync', trOpt)}>{autoSync ? yes : no}</Descriptions.Item>
                  <Descriptions.Item label={I18n.t('groupType', trOpt)}>{groupTypes[groupType] || groupTypes.unknown}</Descriptions.Item>
                  <Descriptions.Item label={I18n.t('includeGroupsInSync', trOpt)}>{includeGroups ? yes : no}</Descriptions.Item>
                  {
                    includeGroups && <Descriptions.Item label={I18n.t('groupsIncludedInSync', trOpt)}>{groupCount}</Descriptions.Item>
                  }
                  <Descriptions.Item label={I18n.t('restrictUserSynctoGroups', trOpt)}>{includeGroupMembWhitelist ? yes : no}</Descriptions.Item>
                  {
                    includeGroupMembWhitelist &&
                      <Descriptions.Item label={I18n.t('groupsUsersIncludedInSync', trOpt)}>{groupMembCount}</Descriptions.Item>
                  }
                  <Descriptions.Item label={I18n.t('mapGroups', trOpt)}>{includeGroupMembMapping ? yes : no}</Descriptions.Item>
                  {syncType === 'google' && <Descriptions.Item label={I18n.t('excludeUsersWithoutMailboxSetup', trOpt)}>{excludeUsersWithoutMailboxSetup ? yes : no}</Descriptions.Item>}
                  {syncType === 'google' && <Descriptions.Item label={I18n.t('excludeSuspendedUsers', trOpt)}>{excludeSuspendedUsers ? yes : no}</Descriptions.Item>}
                  {syncType === 'google' && <Descriptions.Item label={I18n.t('excludeArchivedUsers', trOpt)}>{excludeArchivedUsers ? yes : no}</Descriptions.Item>}
                  {syncType === 'microsoft' && <Descriptions.Item label={I18n.t('emailMethod', trOpt)}>{I18n.t(emailMethod || 'original', { scope: 'office365Setup.emailMethod.options' })}</Descriptions.Item>}
                  {syncType === 'microsoft' && <Descriptions.Item label={I18n.t('excludeUsersWithoutAssignedLicenses', trOpt)}>{excludeUsersWithoutAssignedLicenses ? yes : no}</Descriptions.Item>}
                  <Descriptions.Item label={I18n.t('syncManagers', syncTrOpt)}>{syncManagers ? yes : no}</Descriptions.Item>
                  <Descriptions.Item label={I18n.t('excludeUsersByBlacklist', trOpt)}>{includeUserEmailBlacklist ? yes : no}</Descriptions.Item>
                </Descriptions>
              </ModalScroller>
            </TabPane>
            <TabPane tab={I18n.t('sync.common.groupConfiguration')} key='groups' disabled={!hasGroupConfig}>
              <SummaryGroups
                {...{ groupTypes, groupsQuery, syncType, groupsQueryKey, usePaginatedGroupLoad }}
                trOpt={groupsTrOpt}
              />
            </TabPane>
            <TabPane tab={I18n.t('sync.common.userEmailBlacklist')} key='users' disabled={!hasUserBlacklist}>
              <ModalScroller>
                <EmailTags emails={userEmailBlacklist} closable={false} />
              </ModalScroller>
            </TabPane>
          </Tabs>
        </ModalBody>
      </Modal>
    </>
  )
}

export default connect(
  state => _pick(selectors.settings.get(state), ['googleSync', 'office365'])
)(SyncConfigSummary)
