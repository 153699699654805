import { Button } from 'antd'
import I18n from 'i18n-js'
import React from 'react'
import styled from 'styled-components'
import { TemplateAlert, TemplateSelectedMessage } from './TemplateHeader'
import { UPHISH_ATTACK_TYPES, UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP } from '../../constants/uPhish'

const commonTrOpt = { scope: 'uPhish.common' }
const attackTypesTrOpt = { scope: 'uPhish.createSimulationAttackTypes' }

const AttackTypeGroup = styled.div`
  display: flex;
  justify-content: stretch;
  gap: 20px;
`

const AttackType = styled.div`
  padding: 15px;
  display: grid;
  justify-content: flex-start;
  background: #eeeeee;
  border: solid 1px #e1e1e1;
  border-radius: 5px;
`

const SelectButton = styled(Button)`
  margin-bottom: auto;
`

const ChooseAttackType = ({ attackTypeId, setAttackType }) => {
  return (
    <>
      {typeof attackTypeId !== 'string' && <TemplateAlert message={I18n.t('initialAlert', attackTypesTrOpt)} description='hidden' type='info' showIcon />}

      {typeof attackTypeId === 'string' && (
        <TemplateAlert
          message={
            <TemplateSelectedMessage>
              <span>{I18n.t('selectedAlert', { ...attackTypesTrOpt, attackType: I18n.t(`attackTypes.${UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP[attackTypeId]}`, commonTrOpt) })}</span>
              <Button type='primary' icon='right-circle' onClick={() => {}}>{I18n.t('common.continue')}</Button>
            </TemplateSelectedMessage>
          }
          description='hidden' type='success' showIcon
        />
      )}

      <AttackTypeGroup>
        {UPHISH_ATTACK_TYPES.map(id => (
          <AttackType key={id} onClick={() => setAttackType(id)}>
            <h2>{I18n.t(`attackTypes.${UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP[id]}`, commonTrOpt)}</h2>
            {I18n.t(`attackTypeDescriptions.${UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP[id]}`, attackTypesTrOpt).map((text, index) => <p key={index}>{text}</p>)}
            <SelectButton>{I18n.t('common.select')}</SelectButton>
          </AttackType>
        ))}
      </AttackTypeGroup>
    </>
  )
}

export default ChooseAttackType
