import { Modal } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import enrolLearnersOnGapAnalysis from '../../helpers/enrolLearnersOnGapAnalysis'

const trOpt = { scope: 'modals.enrolLearnersOnGapAnalysis' }
const confirm = Modal.confirm

const enrolLearnersOnGapAnalysisConfirm = async (learnerIds = [], { refetchQueries, client = authenticatedClient, onClose = () => {} } = {}) => {
  confirm({
    title: I18n.t('confirmPrompt', { ...trOpt, count: learnerIds.length }),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      await enrolLearnersOnGapAnalysis(learnerIds, refetchQueries, client)
      onClose()
    }
  })
}

export default enrolLearnersOnGapAnalysisConfirm
