import I18n from 'i18n-js'

export const RISK_SCORE_RAG = ['red', 'amber', 'green']
export const RISK_SCORE_LEVELS = ['veryHigh', 'high', 'medium', 'low', 'veryLow']

export const RISK_SCORE_LABELS = {
  get red () { return I18n.t('common.high') },
  get amber () { return I18n.t('common.medium') },
  get green () { return I18n.t('common.low') },
  get veryHigh () { return I18n.t('common.veryHigh') },
  get high () { return I18n.t('common.high') },
  get medium () { return I18n.t('common.medium') },
  get low () { return I18n.t('common.low') },
  get veryLow () { return I18n.t('common.veryLow') }
}
export const RISK_SCORE_COLORS = {
  red: 'evaluate',
  amber: 'amber',
  green: 'cta',
  veryHigh: 'riskScoreVeryHigh',
  high: 'riskScoreHigh',
  medium: 'riskScoreMedium',
  low: 'riskScoreLow',
  veryLow: 'riskScoreVeryLow'
}
