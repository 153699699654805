import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import MutationForm from '../MutationForm/MutationForm'
import { START_RISK_REPORT } from '../Queries/Companies'
import { validateDomain } from '../../helpers'

const trOpt = { scope: 'modals.startRiskReportModal' }

const EditModal = styled(Modal)`
  max-width: 800px;
  top: 50px;
`

const StartRiskReportModal = React.forwardRef(({ refetchQueries = [], onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [companyDomain, setCompanyDomain] = useState(null)

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: (company) => {
      const { id, name, domain } = company
      setVisible(true)
      setCompanyId(id)
      setCompanyName(name)
      setCompanyDomain(domain)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setCompanyId(null)
    setCompanyName(null)
    setCompanyDomain(null)
    resetForm()
    onClose()
  }

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  let title = I18n.t('title', trOpt)
  if (companyName) {
    title = I18n.t('titleName', { ...trOpt, name: companyName })
  } else {
    title = I18n.t('title', trOpt)
  }

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={title}
      afterClose={afterClose}
      width='70%'
    >
      <MutationForm
        mutation={START_RISK_REPORT}
        variables={{ companyId }}
        onSuccess={onSuccess}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('submitLabel', trOpt)}
        fields={[{
          id: 'domain',
          label: I18n.t('modals.editCompanyModal.domain'),
          validate: (field, value, errors) => {
            if (!validateDomain(value)) {
              errors.push(I18n.t('common.invalidDomainError'))
            }
          },
          required: true,
          visible: !companyDomain
        }, {
          id: 'email',
          label: I18n.t('common.fields.email'),
          type: 'email',
          required: true,
          autofill: false
        }]}
        ref={form}
        refetchQueries={refetchQueries}
        skipResetFieldsOnSubmit
      />
    </EditModal>
  )
})

export default StartRiskReportModal
