import React from 'react'
import I18n from 'i18n-js'
import { nanoid } from 'nanoid'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import SettingsForm from '../SettingsForm'
import { InputWithAction, InputWithReset } from '../common'

const trOpt = { scope: 'settings.whitelisting' }
const WHITELIST = window.__USECURE_CONFIG__.REACT_APP_UPHISH_WHITELIST
const WHITELIST_COUNT = (WHITELIST || '').split(',').length

class Whitelisting extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'emailHeader'
    this.defaultValue = { enabled: false, name: 'X-USECURE-EMAIL', value: null }
    this.useValuesObjectName = true
  }

  get headerId () {
    return 'settings-white-listing-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return (
      <>
        {WHITELIST && (
          <p>
            {I18n.t('whitelistDescription', { ...trOpt, count: WHITELIST_COUNT })}
            <br />{WHITELIST}
          </p>
        )}
        <p>{I18n.t('emailHeaderDescription', trOpt)},</p>
      </>
    )
  }

  mutateValues (values) {
    return values.enabled ? values : _pick(values, ['enabled'])
  }

  // This method is called in the field's context
  handleGenerateCodeClick () {
    this.props.onChange(this.props.id, nanoid())
  }

  // The regex requires the header name to start with "X-"
  // CL - I couldn't find a standard for this so it's limited to letters, numbers and hyphens
  validateHeaderName (field, value, errors) {
    if (!(value && /^(X|x)-(\d|\w|-)+$/.test(value))) {
      errors.push(I18n.t('nameFormatError', trOpt))
    }
  }

  // No white space
  // CL - Couldn't find a standard for this either
  validateHeaderValue (field, value, errors) {
    if (value && /\s/.test(value)) {
      errors.push(I18n.t('valueFormatError', trOpt))
    }
  }

  get _fields () {
    const { enabled: defaultEnabled, name: defaultName, value: defaultValue } = this.defaultValue
    const {
      enabled = defaultEnabled,
      name = defaultName,
      value = defaultValue
    } = _get(this.props, 'settings.emailHeader') || {}
    const parentName = this.defaultTenant ? null : _get(this.props, 'settings.parentDefaultSettings.emailHeader.name')

    return [{
      id: 'enabled',
      type: 'switch',
      label: I18n.t('enabled', trOpt),
      defaultValue: enabled
    }, {
      id: 'name',
      type: 'text',
      label: I18n.t('name', trOpt),
      required: true,
      defaultValue: name,
      visible: ({ enabled }) => enabled === true,
      validate: this.validateHeaderName,
      component: InputWithReset,
      defaultSetting: parentName || defaultName,
      showCopyButton: true
    }, {
      id: 'value',
      type: 'text',
      label: I18n.t('value', trOpt),
      required: true,
      defaultValue: value,
      visible: ({ enabled }) => enabled === true,
      validate: this.validateHeaderValue,
      component: InputWithAction,
      actionIcon: 'reload',
      actionLabel: `${trOpt.scope}.generateRandomCode`,
      onAction: this.handleGenerateCodeClick,
      showCopyButton: true
    }]
  }
}

export default Whitelisting
