import React from 'react'
import styled, { keyframes } from 'styled-components'
import { lighten } from 'polished'

// Based on v1 component

const CircleKeyframes = keyframes`
  0% {
    clip-path: inset(0% 0% 0 50%);
  }
  50% {
    clip-path: inset(0% 0% 0 50%);
  }
  51% {
    clip-path: inset(0 0% 0 0);
  }
  100% {
    clip-path: inset(0 0% 0 0);
  }
`

const RightTurn = keyframes`
  0% {
    transform: rotate(-180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const LeftTurn = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-180deg);
  }
  50% {
    opacity: 0;
    transform: rotate(-180deg);
  }
  51% {
    opacity: 1;
    transform: rotate(-180deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
`

const ProgressWrap = styled.div`
  display: table;
  margin: 0 auto;
  position: relative;
`

const Circles = styled.div`
  animation-duration: ${props => props.waitMS}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: ${CircleKeyframes};
  animation-timing-function: linear;
  left: 0;
  bottom: 0;
  position: absolute;
  right: 0;
  transform: ${props => props.timerActive ? 'scale(1.16)' : 'scale(1)'};
  transition: transform .3s;
  top: 0;

  > * {
    animation-duration: ${props => props.waitMS}ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    border: .5rem solid ${({ theme }) => lighten(0.2, theme.primary)};
    border-radius: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  >:first-child {
    /* Left */
    animation-name: ${LeftTurn};
    clip-path: inset(0px 50% 0px 0px);
  }

  >:last-child {
    /* Right */
    animation-name: ${RightTurn};
    clip-path: inset(0px 0px 0px 50%);
  }
`

const ProgressCircle = ({ disabled, waitMS, timerActive, children }) => (
  <ProgressWrap disabled={timerActive || disabled}>
    {timerActive
      ? (
        <Circles {...{ waitMS, timerActive }}>
          <div />
          <div />
        </Circles>
      )
      : null}
    {children}
  </ProgressWrap>
)

export default ProgressCircle
