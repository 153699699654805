import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Card, Tabs } from 'antd'
import { compose } from 'recompose'
import { useApolloClient } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import { withTheme } from 'styled-components'
import _pick from 'lodash/pick'

import { GET_USERVICE_DATA, GET_USERVICE_COUNT, GET_USERVICE_PRIORITY_TASKS } from '../components/Queries/Companies'
import { ContentWrap, ListHeader, ListHeaderPanel, ListHeaderTitle } from '../components/common'
import { connect } from '../hocs'
import { getSessionAndSettings } from '../state/selectors'
import routes from '../constants/routes'
import AddBillingMessage from '../components/uService/AddBillingDetailsMessage'
import MSPTeamMessage from '../components/uService/MSPTeamMessage'
import PriorityTasks from '../components/uService/PriorityTasks'
import { QUERY_POLL_INTERVAL, LONG_QUERY_POLL_INTERVAL } from '../constants/apolloClient'
import UServiceAllCompanies from '../components/uService/uServiceAllCompanies'
import UServiceQuickSearch from '../components/uService/uServiceQuickSearch'
import useLocalStorage from '../hooks/useLocalStorage'
import { useHasSessionPermission } from '../hooks'
import { permissions } from '../constants/permissions'

const trOpt = { scope: 'uService' }
const QUERY_PAGE_SIZE = 500
const USE_PAGED_QUERY_THRESHOLD = 1500
const PATH_TO_ACCOUNT_TYPE_MAP = {
  customers: 'tenant',
  prospects: 'prospect'
}

const { TabPane } = Tabs

const UService = ({
  match, userId, companyId, userAccountType, paymentProvided, billingType, theme, locale,
  disableTenantCreation, disableTenantDeletion, disableProspectCreation, disableProspectDeletion,
  showExternalId, enableBillingExport, enableProspects: enableProspectsSetting, enableRiskReportTenants
}) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasUpdatePermission = hasAllSessionPermissions([permissions.COMPANY_UPDATE])

  const { params: { accountType: pathAccountType } = {}, path } = match || {}
  const accountType = PATH_TO_ACCOUNT_TYPE_MAP[pathAccountType] ?? pathAccountType
  const enableProspects = userAccountType === 'msp'
    ? enableProspectsSetting && !accountType // Enables combined customer and prospect view for MSPs
    : enableProspectsSetting
  const [pollingEnabled, setPollingEnabled] = useState(true)

  const showTabs = hasAllSessionPermissions([permissions.COMPANY_SUPER_LIST])
  const [activeTab, setActiveTab] = useState('quickSearch')
  const [hasViewedAllTab, setHasViewedAllTab] = useState(false)
  const storageId = useMemo(() => `uService|${companyId}|${userId}${accountType ? `|${accountType}` : ''}`, [userId, companyId, accountType])
  const { updateFromLocalStorage, updateLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      activeTab: setActiveTab
    })
  }, [updateFromLocalStorage, storageId])
  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      activeTab
    })
  }, [updateLocalStorage, activeTab])
  useEffect(() => {
    if (activeTab === 'allCompanies') {
      setHasViewedAllTab(true)
    }
  }, [activeTab])

  const client = useApolloClient()
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [queryLoading, setQueryLoading] = useState(true)
  const [error, setError] = useState(null)
  const viewVariables = useMemo(() => ({ accountType, all: (hasAllSessionPermissions([permissions.COMPANY_SUPER_LIST])) }), [accountType, hasAllSessionPermissions])

  const showPriorityTasks = useMemo(() =>
    ((enableProspectsSetting && accountType === 'prospect') || enableProspects || enableRiskReportTenants) &&
    (hasAllSessionPermissions([permissions.COMPANY_SUPER_LIST]) || userAccountType === 'msp') && hasUpdatePermission,
  [enableProspectsSetting, accountType, enableProspects, enableRiskReportTenants, hasAllSessionPermissions, userAccountType, hasUpdatePermission])

  const refetchQueriesArray = useMemo(() => {
    const refetchQueriesArray = []
    if (showPriorityTasks) {
      refetchQueriesArray.push({
        query: GET_USERVICE_PRIORITY_TASKS,
        variables: viewVariables
      })
    }
    return refetchQueriesArray
  }, [showPriorityTasks, viewVariables])

  const getCompanyCount = useCallback(async queryOptions => {
    const { data: { companiesCount } = {} } = await client.query({ query: GET_USERVICE_COUNT, ...queryOptions })
    return companiesCount
  }, [client])

  const loadAllCompanyData = useCallback(async queryOptions => {
    const { data: { companies = [] } = {} } = await client.query({ query: GET_USERVICE_DATA, ...queryOptions })
    return companies
  }, [client])

  const loadPagedCompanyData = useCallback(async (companiesCount, queryOptions) => {
    let companies = []
    const pages = Math.ceil(companiesCount / QUERY_PAGE_SIZE)
    for (let page = 0; page < pages; page += 1) {
      const offset = page * QUERY_PAGE_SIZE
      const { data: { companies: companiesPage } } = await client.query({
        query: GET_USERVICE_DATA,
        ...queryOptions,
        variables: { ...(queryOptions.variables || {}), offset, limit: QUERY_PAGE_SIZE }
      })
      companies = [...companies, ...companiesPage]
    }
    return companies
  }, [client])

  const loadCompanyData = useCallback(async (options) => {
    if (showTabs && !hasViewedAllTab) return // Do not load all companies data as that tab has never been visible

    setQueryLoading(true)
    const { backgroundLoad = false, silentLoad = false, ...opt } = options || {}
    const queryOptions = { variables: viewVariables, ...opt }
    const makeStateUpdates = !(backgroundLoad || silentLoad)
    // A silent load repopulates the view without a loading indicator
    // This mimics an apollo mutation running refetchQueries on completion
    // A background load uses this function to load companies without showing them on this page. Typically uses for related refetches
    try {
      if (makeStateUpdates) {
        setError(null)
        setLoading(true)
      }
      const companiesCount = await getCompanyCount(queryOptions)
      let companies
      if (companiesCount >= USE_PAGED_QUERY_THRESHOLD) {
        // Use paged query when the total number of results is too high to load in one query
        // At time of writing the usecure-admin view will use this but no partners have the volume to need this
        companies = await loadPagedCompanyData(companiesCount, queryOptions)
      } else {
        companies = await loadAllCompanyData(queryOptions)
      }
      if (!backgroundLoad) setCompanies(companies)
    } catch (e) {
      if (makeStateUpdates) setError(e)
    } finally {
      setQueryLoading(false)
      if (makeStateUpdates) setLoading(false)
    }
  }, [getCompanyCount, loadAllCompanyData, loadPagedCompanyData, viewVariables, setQueryLoading, showTabs, hasViewedAllTab])

  const refetchAllCompanies = useCallback(async () => loadCompanyData({ fetchPolicy: 'network-only' }), [loadCompanyData])
  // Load company data on mount
  useEffect(() => {
    loadCompanyData()
  }, [loadCompanyData])

  // Using manual queries means that refetchQueries will not have the desired effect
  // refetchQueries takes a function which returns an array so we manually run an uncached query (all or paged) and return an empty array
  // None of our mutations use awaitRefetchQueries so we don't need to wait on the query load
  const refetchQueries = useCallback(() => {
    loadCompanyData({
      fetchPolicy: 'network-only',
      silentLoad: true
    })
    return refetchQueriesArray
  }, [loadCompanyData, refetchQueriesArray])

  // Using manual queries means we can't use the built in polling
  useEffect(() => {
    // We use a longer poll interval with a slow query to reduce the load on the server
    const pollInterval = companies.length >= USE_PAGED_QUERY_THRESHOLD ? LONG_QUERY_POLL_INTERVAL : QUERY_POLL_INTERVAL
    const poll = setInterval(() => {
      if (pollingEnabled && !queryLoading) refetchQueries()
    }, pollInterval)
    return () => clearInterval(poll)
  }, [refetchQueries, pollingEnabled, queryLoading, companies])

  // Background load of upgraded prospect into uService customer view
  const upgradeProspectRefetchQueries = useCallback(async () => {
    refetchQueries()
    if (accountType === 'prospect') {
      loadCompanyData({
        variables: { ...viewVariables, accountType: 'tenant' },
        fetchPolicy: 'network-only',
        backgroundLoad: true
      })
    }
    return refetchQueriesArray
  }, [loadCompanyData, accountType, viewVariables, refetchQueries, refetchQueriesArray])

  const title = (
    <ListHeaderPanel align='left'>
      <ListHeaderTitle id={accountType === 'tenant' || accountType === 'prospect' ? `uService-${accountType}-header` : ''}>{I18n.t('common.uService')}{accountType ? ` - ${I18n.t(accountType, { scope: 'common.accountTypesPlural' })}` : ''}</ListHeaderTitle>
    </ListHeaderPanel>
  )

  const allCompanies = (
    <UServiceAllCompanies
      title={showTabs ? null : title}
      {...{
        loading,
        error,
        companies,
        companyId,
        userId,
        accountType,
        userAccountType,
        showExternalId,
        path,
        theme,
        enableProspects,
        enableRiskReportTenants,
        disableTenantCreation,
        disableTenantDeletion,
        disableProspectCreation,
        disableProspectDeletion,
        enableBillingExport,
        refetchQueries,
        upgradeProspectRefetchQueries,
        setPollingEnabled
      }}
      refetch={refetchAllCompanies}
      openAddModal={path === routes.USERVICE_MSP_TYPE_CREATE}
    />
  )

  return (
    <>
      {userAccountType === 'msp' && !paymentProvided && billingType === 'auto' && <AddBillingMessage />}
      <MSPTeamMessage />
      <ContentWrap>
        {
          showPriorityTasks && (
            <PriorityTasks
              {...{ userId, companyId, accountType, locale, viewVariables, pollingEnabled }}
              refetchQueries={upgradeProspectRefetchQueries}
            />
          )
        }
        <Card>
          {showTabs ? (
            <>
              <ListHeader>
                {title}
              </ListHeader>
              <Tabs activeKey={activeTab} onChange={setActiveTab} animated={false}>
                <TabPane tab={I18n.t('quickSearch', trOpt)} key='quickSearch'>
                  <UServiceQuickSearch
                    {...{
                      companyId,
                      userId,
                      accountType,
                      userAccountType,
                      showExternalId,
                      path,
                      theme,
                      enableProspects,
                      enableRiskReportTenants,
                      disableTenantCreation,
                      disableTenantDeletion,
                      disableProspectCreation,
                      disableProspectDeletion,
                      enableBillingExport,
                      refetchQueries,
                      upgradeProspectRefetchQueries,
                      pollingEnabled,
                      setPollingEnabled
                    }}
                  />
                </TabPane>
                <TabPane tab={I18n.t('allCompanies', trOpt)} key='allCompanies'>
                  {allCompanies}
                </TabPane>
              </Tabs>
            </>
          ) : allCompanies}
        </Card>
      </ContentWrap>
    </>
  )
}

export default compose(
  connect(state => {
    const { session, settings } = getSessionAndSettings(state)
    const { accountType: userAccountType, billingType, billingStartDate } = session
    const { editExternalId: showExternalId } = settings
    const enableBillingExport = userAccountType === 'distributor' && billingType === 'manual' && billingStartDate
    return {
      userAccountType,
      billingType,
      showExternalId,
      enableBillingExport,
      ..._pick(session, ['userId', 'companyId', 'paymentProvided', 'locale']),
      ..._pick(settings, [
        'enableProspects', 'enableRiskReportTenants',
        'disableTenantCreation', 'disableTenantDeletion',
        'disableProspectCreation', 'disableProspectDeletion'
      ])
    }
  }),
  withTheme
)(UService)
