import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import MutationForm from '../MutationForm/MutationForm'
import { modalConfirmAsync, UsecureError } from '../../helpers'

const EditModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const UpdateUPhishTemplateCompanyModal = React.forwardRef(({ mutation, trOpt, refetchQueries = [], onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [ids, setIds] = useState([])

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async (ids) => {
      setIds(ids)
      setVisible(true)
    }
  }), [])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setIds([])
    setVisible(false)
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(async () => {
    const confirm = await modalConfirmAsync({
      content: I18n.t('confirmWarning', trOpt)
    })
    if (!confirm) {
      throw new UsecureError(I18n.t('cancelledMessage', trOpt), { level: 'info' })
    }
  }, [trOpt])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', { ...trOpt, count: ids?.length ?? 0 }))
    closeModal()
  }, [closeModal, trOpt, ids])

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={I18n.t('title', { ...trOpt, count: ids?.length ?? 0 })}
      afterClose={afterClose}
      width='50%'
    >
      <MutationForm
        mutation={mutation}
        variables={{ ids }}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        failureMessage={I18n.t('errorMessage', { ...trOpt, count: ids?.length ?? 0 })}
        submitLabel={I18n.t('submitLabel', trOpt)}
        fields={[{
          id: 'companyId',
          label: I18n.t('common.fields.owner'),
          type: 'companyAutoComplete',
          includeDirect: true,
          required: true
        }]}
        ref={form}
        refetchQueries={refetchQueries}
        skipResetFieldsOnSubmit
      />
    </EditModal>
  )
})

export default UpdateUPhishTemplateCompanyModal
