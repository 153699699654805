import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { dataIdFromObject } from './caching'
import wsLink from './wsLink'
import timezoneLink from './timezoneLink'
import { getReportAccessSessionToken } from '../helpers/session'

const terminatingLink = wsLink

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = getReportAccessSessionToken()
    if (token) {
      headers = { ...headers, 'x-token': token }
    }
    return { headers }
  })

  return forward(operation)
})

const link = ApolloLink.from([authLink, timezoneLink, terminatingLink])

const cache = new InMemoryCache({
  dataIdFromObject
})

const client = new ApolloClient({
  link,
  cache
})

export default client
