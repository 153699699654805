/* global Blob */
import { message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import I18n from 'i18n-js'

import { apiUrl } from '../apollo-client/common'
import { getSessionToken } from './session'
import { getTimezoneHeader } from './timezone'
import ExportServiceClient from './ExportServiceClient'

export const downloadReportWithExportServiceClient = async report => {
  const { fileType, fileName, jobData } = report
  const exportServiceClient = new ExportServiceClient({
    fileType,
    fileName,
    onFail: () => message.error(I18n.t('common.fileDownloadError', { file: fileName }))
  })
  await exportServiceClient.execute(jobData)
}

export const downloadReportsWithExportServiceClient = async reports =>
  reports?.length > 0 ? Promise.all(reports.map(report => downloadReportWithExportServiceClient(report))) : Promise.resolve()

const downloadFiles = async files => {
  for (const file of files) {
    await downloadFile(file)
  }
}

export const downloadFilesFromGet = async files => downloadFiles(files)
export const downloadFilesFromPost = async files => downloadFiles(files.map(file => ({ ...file, method: 'POST' })))
export const downloadReports = async reports => downloadFiles(reports.map(({ file, endpoint, method = 'GET', data }) => ({ file, endpoint: `/api/reports${endpoint}`, method, data })))

const downloadFile = async ({ endpoint, url = apiUrl, method = 'GET', file, data, type, params, axiosInstance }) => {
  file = file.replace(/{{ts}}/g, `-${moment().format('YYYY-MM-DD-HH-mm-ss')}`)

  const axiosClient = axiosInstance || axios.create({
    baseURL: url,
    headers: {
      'x-token': getSessionToken(),
      ...getTimezoneHeader()
    }
  })

  try {
    // Axios download based on https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
    const response = await axiosClient({
      url: endpoint,
      method,
      responseType: 'blob', // important
      data,
      params
    })
    handleDownloadedFile(response.data, file, type)
  } catch (e) {
    message.error(I18n.t('common.fileDownloadError', { file }))
    return false
  }
  return true
}

const handleDownloadedFile = (data, fileName, type = 'application/octet-stream') => {
  const blob = new Blob([data], { type })
  saveAsFromBlob(blob, fileName)
}

export const saveAsFromBlob = (blob, fileName) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE Fallback based on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    const blobUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const downloadReportsReducer = async (reports) => {
  const { directs, exports } = reports.reduce((acc, report) => {
    if (report.jobData) {
      acc.exports.push(report)
    } else {
      acc.directs.push(report)
    }
    return acc
  }, {
    directs: [], exports: []
  })

  await Promise.all([
    downloadReports(directs),
    downloadReportsWithExportServiceClient(exports)
  ])
}

export const downloadFileFromPost = async ({ url, endpoint, file, type, data, axiosInstance }) => downloadFile({ url, endpoint, file, type, data, method: 'POST', axiosInstance })
export const downloadFileFromGet = async ({ url, endpoint, file, type, axiosInstance, params }) => downloadFile({ url, endpoint, file, type, axiosInstance, params })
