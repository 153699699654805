import React, { useCallback } from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'

const _BrandedGoogleAuthButton = ({ className, onClick: onClickProp, disabled }) => {
  const onClick = useCallback(() => {
    if (!disabled) {
      onClickProp()
    }
  }, [onClickProp, disabled])
  return (
    <div {...{ className, onClick, disabled }}>
      <div className='google-signin-button'>
        <div className='google-signin-button-logo' />
        <div className='google-signin-button-text'>
          {I18n.t('googleSync.common.signInWithGoogle')}
        </div>
      </div>
    </div>
  )
}

const BrandedGoogleAuthButton = styled(_BrandedGoogleAuthButton)`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  display: inline-block;
  ${props => props.disabled && 'filter: grayscale(1);'}

  .google-signin-button {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .google-signin-button-logo {
    background-image: url('/images/google-signin-btn/logo.svg');
    background-size: contain;
    height: 40px;
    width: 40px;
    margin: 1px;
  }

  .google-signin-button-text {
    margin-left: 8px;
    margin-right: 8px;
    font-family: Roboto-Medium, Roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.21875;
    color: #FFFFFF;
  }
`

export default BrandedGoogleAuthButton
