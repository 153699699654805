import currencies from 'iso-currencies'
import I18n from 'i18n-js'

export const STRIPE_APIKEY = window.__USECURE_CONFIG__.REACT_APP_STRIPE_APIKEY
export const INVOICE_COMPANY_NAME = window.__USECURE_CONFIG__.REACT_APP_INVOICE_COMPANY_NAME
export const INVOICE_COMPANY_ADDRESS_JSON_STR = window.__USECURE_CONFIG__.REACT_APP_INVOICE_COMPANY_ADDRESS
export const INVOICE_SORT_CODE = window.__USECURE_CONFIG__.REACT_APP_INVOICE_SORT_CODE
export const INVOICE_ACCOUNT_NUMBER = window.__USECURE_CONFIG__.REACT_APP_INVOICE_ACCOUNT_NUMBER
export const INVOICE_DISTI_FINANCE_EMAIL = window.__USECURE_CONFIG__.REACT_APP_INVOICE_DISTI_FINANCE_EMAIL
export const INVOICE_MSP_FINANCE_EMAIL = window.__USECURE_CONFIG__.REACT_APP_INVOICE_MSP_FINANCE_EMAIL
export const INVOICE_VAT_NO = window.__USECURE_CONFIG__.REACT_APP_INVOICE_VAT_NO

export const CURRENCIES = ['gbp', 'zar', 'usd', 'cad', 'eur', 'aud', 'nzd']
export const CURRENCIES_INFO = CURRENCIES.reduce((info, code) => {
  const codeInfo = currencies.information(code)
  const { symbol, name } = codeInfo
  return ({
    ...info,
    [code]: {
      ...codeInfo,
      get label () {
        return `${code.toUpperCase()} - ${I18n.t(code, { scope: 'common.currencyNames', defaultValue: name })} (${symbol})`
      }
    }
  })
}, {})
export const CURRENCY_OPTIONS = CURRENCIES.map(code => ({
  value: code,
  get label () {
    return (CURRENCIES_INFO[code] || {}).label
  }
}))
