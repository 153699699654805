import { Modal } from 'antd'
import I18n from 'i18n-js'

// Helper to present a quick confirm modal where you just need to know the user's response
// Saves putting logic into the onOk and onCancel click handlers of a Modal.confirm call
// Return true when ok is clicked and false when cancel is clicked
export const modalConfirmAsync = async ({
  title = I18n.t('common.mutationButton.areYouSureYouWishToContinue'),
  content,
  okText = I18n.t('common.yes'),
  cancelText = I18n.t('common.no'),
  ...modalMethodProps
} = {}) => {
  return new Promise(resolve =>
    Modal.confirm({
      ...modalMethodProps,
      title,
      content,
      okText,
      cancelText,
      onOk: () => resolve(true),
      onCancel: () => resolve(false)
    })
  )
}

// Helpers for async calls to other antd Modal methods
// Saves putting logic into the onOk click handlers of a Modal.method() call
// Returns once the user has clicked ok
const okModalAsync = async (method, modalMethodProps = {}) => {
  return new Promise(resolve =>
    Modal[method]({
      ...modalMethodProps,
      onOk: () => resolve()
    })
  )
}
export const modalInfoAsync = async modalMethodProps => okModalAsync('info', modalMethodProps)
export const modalSuccessAsync = async modalMethodProps => okModalAsync('success', modalMethodProps)
export const modalErrorAsync = async modalMethodProps => okModalAsync('error', modalMethodProps)
export const modalWarningAsync = async modalMethodProps => okModalAsync('warning', modalMethodProps)

export const getModalPagerProps = (rows, activeId, recordKey) => {
  let currentRowNum
  let prevRow = null
  let nextRow = null

  if (activeId && rows.length > 1) {
    const index = rows.findIndex((record) => record[recordKey] === activeId)
    if (index !== -1) {
      currentRowNum = index + 1
      prevRow = index - 1
      prevRow = prevRow < 0 ? rows.length - 1 : prevRow
      prevRow = rows[prevRow] || null
      nextRow = index + 1
      nextRow = nextRow > rows.length - 1 ? 0 : nextRow
      nextRow = rows[nextRow] || null
    }
  }

  return {
    count: rows.length,
    current: currentRowNum,
    prev: prevRow,
    next: nextRow
  }
}
