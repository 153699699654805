import { getAllTimezones } from 'countries-and-timezones'

export const DEFAULT_TIMEZONE = 'Europe/London'

const sortedTimezones = Object.entries(getAllTimezones({ deprecated: true })).map(([key, value]) => value).sort((a, b) => {
  if (a.utcOffset < b.utcOffset) return -1
  if (a.utcOffset > b.utcOffset) return 1
  return 0
})

export const SUPPORTED_TIMEZONES = sortedTimezones.map(({ name }) => name)
