import React from 'react'
import styled from 'styled-components'

const SignInContainerOuter = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const SignInContainerInner = styled.div`
  width: ${props => props.newRecoveryKeys ? '800px' : '400px'};
`

const SignInContainer = ({ children, newRecoveryKeys }) => (
  <SignInContainerOuter>
    <SignInContainerInner newRecoveryKeys={newRecoveryKeys}>{children}</SignInContainerInner>
  </SignInContainerOuter>
)

export default SignInContainer
