import React, { useCallback } from 'react'
import I18n from 'i18n-js'

import MutationForm from '../MutationForm/MutationForm'
import { validateEmail } from '../../helpers'

const trOpt = { scope: 'endUserPortal.signIn' }

const SSOEmailForm = ({ startSSOAuth }) => {
  const onSubmit = useCallback(async ({ email }) => startSSOAuth({ email }), [startSSOAuth])

  return (
    <MutationForm
      {...{ onSubmit }}
      btnBlock
      submitLabel={I18n.t('loginWithSSO', trOpt)}
      fields={[{
        id: 'email',
        type: 'email',
        placeholder: I18n.t('common.fields.email'),
        required: true,
        validate: (field, value, errors) => {
          if (!validateEmail(value)) {
            errors.push(I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError'))
          }
        }
      }]}
    />
  )
}

export default SSOEmailForm
