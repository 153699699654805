import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, TimePicker } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import MutationFormErrors from './MutationFormErrors'

const trOpt = { scope: 'mutationForm.mutationFormTimeRange' }
const formTrOpt = { scope: 'mutationForm.mutationForm' }

const PickerDivider = styled.span`
  margin: 0 10px;
`

class MutationFormTimeRange extends Component {
  constructor (props) {
    super(props)
    const { value: { start, end } = {} } = props || {}
    this.state = {
      touched: Boolean(start || end)
    }

    this.format = 'HH:mm'

    this.handleStartChange = this.handleStartChange.bind(this)
    this.handleEndChange = this.handleEndChange.bind(this)
    this.reset = this.reset.bind(this)
    this.validate = this.validate.bind(this)
  }

  get start () {
    return this.getValue('start')
  }

  get end () {
    return this.getValue('end')
  }

  getValue (key) {
    const value = _get(this.props, `value.${key}`)
    return value ? moment(value, this.format) : null
  }

  // Called by MutationFormElement
  validate (value = this.props.value, errors = [], { submitting = false } = {}) {
    const {
      start,
      end
    } = value
    const {
      required,
      requiredError = I18n.t('requiredFieldError', formTrOpt)
    } = this.props

    if (required && !(start || end)) {
      errors.push(requiredError)
    } else if (start && end && moment(start, this.format).isAfter(moment(end, this.format), 'minute')) {
      errors.push(I18n.t('startAfterEndError', trOpt))
    } else if (start && !end) {
      errors.push(I18n.t('endEmptyError', trOpt))
    } else if (!start && end) {
      errors.push(I18n.t('startEmptyError', trOpt))
    }

    if (submitting) {
      this.updateTouched()
    }

    return errors
  }

  updateTouched () {
    if (!this.state.touched) {
      this.setState({ touched: true })
    }
  }

  reset () {
    this.setState({ touched: false })
  }

  handleStartChange (value) {
    this.onChange('start', value)
  }

  handleEndChange (value) {
    this.onChange('end', value)
  }

  onChange (key, value) {
    value = value ? value.format(this.format) : null
    this.props.onChange(this.props.id, {
      ...this.props.value,
      [key]: value
    })
    this.updateTouched()
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, extra, formItemStyle, minuteStep, disabled, errors = [], required } = this.props
    const showErrors = this.state.touched && errors.length > 0

    return (
      <Form.Item
        label={label} extra={extra} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <TimePicker
          format={this.format} minuteStep={minuteStep} allowClear
          onChange={this.handleStartChange}
          placeholder='Start Time' disabled={disabled}
          value={this.start}
        />
        <PickerDivider>-</PickerDivider>
        <TimePicker
          format={this.format} minuteStep={minuteStep} allowClear
          onChange={this.handleEndChange}
          placeholder='End Time' disabled={disabled}
          value={this.end}
        />
      </Form.Item>
    )
  }
}

MutationFormTimeRange.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object
}

MutationFormTimeRange.defaultProps = {
  id: '',
  label: null,
  onChange: () => { },
  value: {},
  minuteStep: 15
}

export default MutationFormTimeRange
