import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Upload, message } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import EditCourseAnnotatedImageEditor from './EditCourseAnnotatedImageEditor'
import EditCourseSlate from './EditCourseSlate'
import { apiUrl } from '../../apollo-client/common'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import { getSessionToken } from '../../helpers/session'

const ImageWrap = styled.div`
  width: 400px;

  img {
    width: 100%;
  }
`

const ImagePlaceholder = styled.div`
  align-items: center;
  background-color: #f4f4f4;
  display: flex;
  height: 224px;
  justify-content: center;
`

const ButtonsWrap = styled.div`
  margin-top: 8px;

  &> * {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const trOpt = { scope: 'editCourse.editCourseAnnotatedImages' }

class EditCourseAnnotatedImage extends Component {
  constructor (props) {
    super(props)

    this.handleEditorOpenClick = this.handleEditorOpenClick.bind(this)
    this.handleEditorCloseClick = this.handleEditorCloseClick.bind(this)
    this.handleImageUpload = this.handleImageUpload.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.updateIntro = this.updateIntro.bind(this)
    this.updatePoints = this.updatePoints.bind(this)

    this.state = {
      editorOpen: false
    }
  }

  handleTitleChange (event) {
    this.props.updateTitle(event.target.value)
  }

  handleImageUpload (info) {
    if (info.file.status === 'done') {
      message.success(I18n.t('editCourse.common.fileUploadedSuccessfully', { name: info.file.name }))
      this.updateContent('image', info.file.response.url)
    } else if (info.file.status === 'error') {
      message.error(I18n.t('editCourse.common.fileUploadedFailed', { name: info.file.name }))
    }
  }

  handleEditorOpenClick () {
    this.setState({ editorOpen: true })
  }

  handleEditorCloseClick () {
    this.setState({ editorOpen: false })
  }

  updatePoints (points) {
    this.updateContent('points', points)
  }

  updateIntro ({ intro }) {
    this.props.updateContent({ ...this.props.content, intro })
  }

  updateContent (prop, data) {
    this.props.updateContent({
      ...this.props.content,
      [prop]: data
    })
  }

  render () {
    const { slideId, title, locale, content, updateId, updateInProgress } = this.props
    const { image, points = {} } = content || {}

    return (
      <div>
        <Form.Item label={I18n.t('editCourse.common.title')}>
          <Input
            onChange={this.handleTitleChange}
            value={title}
          />
        </Form.Item>

        <Form.Item label={I18n.t('editCourse.common.intro')}>
          <EditCourseSlate updateContent={this.updateIntro} {...{ locale, content, updateId, slideId }} />
        </Form.Item>

        <Form.Item label={I18n.t('editCourse.common.image')}>
          <ImageWrap>
            {
              image
                ? (
                  <img src={image} alt={title} />
                )
                : (
                  <ImagePlaceholder>
                    <span>{I18n.t('editCourse.common.noImage')}</span>
                  </ImagePlaceholder>
                )
            }
          </ImageWrap>
          <ButtonsWrap>
            <Upload name='courseWysiwyg' onChange={this.handleImageUpload} showUploadList={false} action={`${apiUrl}/upload-course-wysiwyg`} headers={{ 'x-token': getSessionToken() }}>
              <Button id='courseWysiwyg'>{I18n.t('editCourse.common.chooseImage')}</Button>
            </Upload>
            <Button type='primary' disabled={!image} onClick={this.handleEditorOpenClick}>{I18n.t('changePoints', trOpt)}</Button>
          </ButtonsWrap>
          <EditCourseAnnotatedImageEditor
            visible={this.state.editorOpen}
            onCancel={this.handleEditorCloseClick}
            updatePoints={this.updatePoints}
            {...{ slideId, updateId, locale, image, points, updateInProgress }}
          />
        </Form.Item>
      </div>
    )
  }
}

EditCourseAnnotatedImage.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  slideId: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseAnnotatedImage.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  slideId: null,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseAnnotatedImage
