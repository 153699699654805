import React, { useCallback, useEffect } from 'react'
import { connect } from '../../hocs'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { creators as sessionActions } from '../../state/actions/session'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import { message } from 'antd'
import { GET_LEARNER_LOCALE, UPDATE_LEARNER_ME, EXTEND_END_USER_SESSION } from '../../components/Queries/Learners'
import { sessionLocale } from '../../state/selectors/session'
import MutationForm from '../../components/MutationForm/MutationForm'
import { LoadingBlock } from '../../components/common'
import { LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { refreshEndUserSessionToken } from '../../helpers/session'
const trOpt = { scope: 'accountSettings.language' }

const ChangeEndUserLanguage = ({ setLocale, locale: currentLocale }) => {
  const { data, loading, refetch } = useQuery(GET_LEARNER_LOCALE)
  const locale = _get(data, 'learnerMe.endUser.locale')
  const form = React.createRef()
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  const [extendSession] = useMutation(EXTEND_END_USER_SESSION)

  const onSuccess = useCallback(async () => {
    await refreshEndUserSessionToken(extendSession, false)
    await refetch()
    message.success(I18n.t('successMessage', trOpt))
  }, [refetch, extendSession])

  const onFailure = (e) => {
    message.error(I18n.t('failureMessage', trOpt))
  }
  useEffect(() => {
    resetForm()
    if (locale && locale !== currentLocale) {
      setLocale(locale)
    }
  }, [resetForm, locale, setLocale, currentLocale])

  return (
    <>
      {!loading ? (
        <div>
          <h3>{I18n.t(`${trOpt.scope}.changeLanguage`, { defaults: [{ scope: 'learners.learnersView.changeLanguage' }] })}</h3>
          <MutationForm
            ref={form}
            mutation={UPDATE_LEARNER_ME}
            onSuccess={onSuccess}
            onFailure={onFailure}
            fields={[{
              id: 'locale',
              label: I18n.t('common.preferredLanguage'),
              extra: <span>{I18n.t('thisWillChangeTheAppLanguage', trOpt)}</span>,
              defaultValue: locale,
              type: 'select',
              options: LANGUAGE_SELECT_OPTIONS,
              sortOptions: true
            }]}
          />
        </div>
      ) : <LoadingBlock fullScreen loading={loading} />}
    </>
  )
}

export default connect(
  state => ({ locale: sessionLocale(state) }),
  dispatch => ({
    setLocale: locale => dispatch(sessionActions.updateLocale(locale))
  })
)(ChangeEndUserLanguage)
