import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { message, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'

import I18n from 'i18n-js'
import _get from 'lodash/get'

import MutationForm from '../MutationForm/MutationForm'
import { SEND_BILLING_EXPORT, USERVICE_INVOICE_DATES } from '../Queries/Companies'
import { ErrorAlerts, LoadingBlock } from '../common'
import { formatDate } from '../../helpers/datetime'

const trOpt = { scope: 'modals.billingExportModal' }

const BillingExportModal = ({ visible, setVisible = () => {}, afterClose = () => {} }) => {
  const { loading, error, data } = useQuery(USERVICE_INVOICE_DATES, { skip: !visible })
  const periods = _get(data, 'uServiceInvoices.periods') || []
  const noPeriodsFound = !loading && periods.length === 0

  // resetForm and the effect below set the default field value after loading the periods
  // This pattern is used elsewhere and it would be nice to either redesign MutationForm so code like this isn't needed; or moved it to a HOC for reuse
  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  useEffect(() => {
    if (!loading) {
      resetForm()
    }
  }, [loading, resetForm])

  const closeModal = useCallback(() => {
    setVisible(false)
    resetForm()
  }, [setVisible, resetForm])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  const periodOptions = useMemo(() => {
    return periods.map(({ startDate, endDate }) => ({
      value: startDate,
      label: `${formatDate(startDate)} - ${formatDate(endDate)}`
    }))
  }, [periods])
  const { value: mostRecentStartDate = null } = periodOptions[periodOptions.length - 1] || {}
  const fields = [{
    id: 'startDate',
    label: I18n.t('billingPeriod', trOpt),
    type: 'select',
    options: periodOptions,
    required: true,
    defaultValue: mostRecentStartDate
  }]

  return (
    <Modal
      visible={visible}
      title={I18n.t('uService.sendBillingExport')}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
      destroyOnClose
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      {error && <ErrorAlerts error={error} defaultError={I18n.t('billingPeriodError', trOpt)} />}
      {noPeriodsFound && <ErrorAlerts error={new Error(I18n.t('usageError', trOpt))} />}
      {
        !error && !noPeriodsFound && (
          <MutationForm
            mutation={SEND_BILLING_EXPORT}
            onSuccess={onSuccess}
            failureMessage={I18n.t('errorMessage', trOpt)}
            submitLabel={I18n.t('uService.sendBillingExport')}
            ref={form}
            fields={fields}
          />
        )
      }
    </Modal>
  )
}

export default BillingExportModal
