import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { useApolloClient } from '@apollo/react-hooks'
import _get from 'lodash/get'

import MutationForm from '../MutationForm/MutationForm'
import { GET_COMPANY_FOR_UPDATE, UPDATE_COMPANY_ACCOUNT_TYPE } from '../Queries/Companies'
import { withConsumer } from '../../hocs/withConsumer'
import { LoadingBlock } from '../common'
import { modalConfirmAsync, showErrors, UsecureError } from '../../helpers'

const trOpt = { scope: 'modals.updateCompanyAccountTypeModal' }

const EditModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const UpdateCompanyAccountTypeModal = React.forwardRef(({ refetchQueries = [], onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [accountType, setAccountType] = useState(null)

  const client = useApolloClient()

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const setInitialFormValues = useCallback(async (values) => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async (companyId) => {
      try {
        setLoading(true)
        setVisible(true)
        const companyResult = await client.query({
          query: GET_COMPANY_FOR_UPDATE,
          variables: { id: companyId }
        })
        const company = _get(companyResult, 'data.company')
        const { id, name, accountType } = company
        setCompanyId(id)
        setCompanyName(name)
        setAccountType(accountType)
        await setInitialFormValues({ accountType })
        setLoading(false)
      } catch (e) {
        setVisible(false)
        showErrors(e, I18n.t('initialisationError', trOpt))
      }
    }
  }), [client, setInitialFormValues])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setCompanyId(null)
    setCompanyName(null)
    setAccountType(null)
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(async () => {
    const confirm = await modalConfirmAsync({
      content: ['msp', 'distributor'].includes(accountType) ? I18n.t('confirmWarning', trOpt) : null
    })
    if (!confirm) {
      throw new UsecureError(I18n.t('cancelledMessage', trOpt), { level: 'info' })
    }
  }, [accountType])

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  let title = I18n.t('title', trOpt)
  if (companyName) {
    title = I18n.t('titleName', { ...trOpt, name: companyName })
  } else {
    title = I18n.t('title', trOpt)
  }

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={title}
      afterClose={afterClose}
      width='50%'
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <MutationForm
        mutation={UPDATE_COMPANY_ACCOUNT_TYPE}
        variables={{ companyId }}
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('submitLabel', trOpt)}
        fields={[{
          id: 'accountType',
          label: I18n.t('uService.accountType'),
          type: 'select',
          options: [
            { value: 'distributor', label: I18n.t('common.accountTypes.distributor') },
            { value: 'msp', label: I18n.t('common.accountTypes.msp') },
            { value: 'tenant', label: I18n.t('common.accountTypes.tenant') }
          ],
          required: true
        }]}
        ref={form}
        refetchQueries={refetchQueries}
        skipResetFieldsOnSubmit
      />
    </EditModal>
  )
})

export default withConsumer({ useHooks: true })(UpdateCompanyAccountTypeModal)
