import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'
import { Button, message } from 'antd'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { ListHeader, ListHeaderPanel, ListViewActions, ErrorAlerts, SearchBar } from '../common'
import BreachesTable from './BreachesTable'
import { searchRecords, processListActions } from '../../helpers/listPages'
import useLocalStorage from '../../hooks/useLocalStorage'
import { WRITABLE_UBREACH_ACTIONS } from '../../constants/actions'
import MarkResolvedConfirm from '../Modals/uBreach/MarkResolvedConfirm'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uBreach' }

const getRowActions = ({ record, disabledActions, omittedActions }) => {
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', trOpt) }
  ]

  const recordOmittedActions = [...omittedActions]
  if (record.breachCount === record.resolvedCount) recordOmittedActions.push('markResolved')

  return processListActions({ actions, disabledActions, omittedActions: recordOmittedActions })
}

const getSelectionActions = ({ selection, disabledActions, omittedActions }) => {
  if (selection.length === 1) return getRowActions({ record: selection[0], disabledActions, omittedActions })
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', trOpt) }
  ]

  return processListActions({ actions, disabledActions, omittedActions })
}

const BreachesView = ({
  companyId,
  userId, // Current user, from global state
  domain = null, // If set, only show breaches where email domain matches
  breachedUsers = [],
  breachedServices = [],
  error,
  loading,
  refetchQueries,
  handleRefreshClick,
  planValid,
  uBreachProEnabled
}) => {
  const [searchText, setSearchText] = useState('')
  const [allRecords, setAllRecords] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [showMultiSelectActions, setShowMultiSelectActions] = useState(true)
  const markResolvedConfirmRef = useRef(null)

  const { hasAllSessionPermissions } = useHasSessionPermission()

  const openMarkResolvedConfirm = useCallback((records) => {
    if (markResolvedConfirmRef.current) {
      markResolvedConfirmRef.current.open(records)
    }
  }, [markResolvedConfirmRef])

  const { disabledActions, omittedActions } = useMemo(() => {
    const omittedActions = []

    if (!hasAllSessionPermissions([permissions.BREACH_RESOLUTION_CREATE]) || !uBreachProEnabled) omittedActions.push('markResolved')

    return {
      disabledActions: !planValid ? WRITABLE_UBREACH_ACTIONS : null,
      omittedActions
    }
  }, [planValid, hasAllSessionPermissions, uBreachProEnabled])

  const storageId = useMemo(() => `uBreach|breachesView|${companyId}|${userId}`, [userId, companyId])
  const tableStorageId = useMemo(() => `uBreach|breachesView|table|${companyId}|${userId}`, [userId, companyId])
  const { updateLocalStorage, updateFromLocalStorage } = useLocalStorage({ storageId })

  const performAction = useCallback((action, ids) => {
    let actionRecord
    let actionRecords
    if (ids.length === 1) {
      actionRecord = allRecords.find(record => record.id === ids[0])
      if (actionRecord) {
        actionRecords = [actionRecord]
      }
    } else if (ids.length > 1) {
      actionRecords = allRecords.filter(record => ids.includes(record.id))
    }
    if (!actionRecord && !actionRecords) {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
      return
    }
    switch (action) {
      case 'markResolved':
        openMarkResolvedConfirm(
          actionRecords.reduce((acc, record) => {
            const { id } = record
            const breachedUserRecords = breachedUsers.reduce((acc, user) => {
              const { breaches, email, learnerId } = user
              const breach = breaches.find(breach => breach.name === id)
              if (breach) {
                acc.push({ email, learnerId, breachName: id })
              }
              return acc
            }, [])

            return [
              ...acc,
              ...breachedUserRecords
            ]
          }, [])
        )
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [allRecords, openMarkResolvedConfirm, breachedUsers])

  const listActions = useMemo(() =>
    getSelectionActions({
      selection: allRecords.filter(record => selectedIds.includes(record.key)),
      disabledActions,
      omittedActions
    }),
  [selectedIds, allRecords, disabledActions, omittedActions])

  useEffect(() => {
    if (!updateFromLocalStorage || domain) return
    updateFromLocalStorage({
      searchText: setSearchText
    })
  }, [updateFromLocalStorage, storageId, domain])

  useEffect(() => {
    if (!updateLocalStorage || domain) return
    updateLocalStorage({
      searchText
    })
  }, [updateLocalStorage, searchText, domain])

  useEffect(() => {
    if (!breachedUsers.length || !breachedServices.length) return

    let breaches = breachedUsers.reduce((acc, user) => {
      const { breaches, domain: userDomain } = user
      if (domain && domain !== userDomain) return acc

      for (const breach of breaches) {
        const { name, resolved, title } = breach
        const breachRecord = acc.find(breach => breach.breachName === name)
        if (!breachRecord) {
          acc.push({
            id: name,
            breachName: name,
            breachTitle: title,
            breachCount: 1,
            resolvedCount: resolved ? 1 : 0
          })
        } else {
          breachRecord.breachCount += 1
          if (resolved) breachRecord.resolvedCount += 1
        }
      }
      return acc
    }, [])

    breaches = breaches.map(breach => {
      const breachedService = breachedServices.find(service => service.name === breach.breachName)
      if (breachedService) {
        breach.dataClasses = breachedService.dataClasses
        breach.breachDate = breachedService.breachDate
        breach.addedDate = breachedService.addedDate
        breach.severity = breachedService.category
      }
      return breach
    })

    setAllRecords(breaches)
  }, [domain, breachedUsers, breachedServices])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const records = useMemo(() => {
    const records = allRecords.map(r => {
      const { id } = r
      const record = {
        ...r,
        key: id
      }
      record.actions = getRowActions({
        record,
        disabledActions,
        omittedActions
      })
      return record
    })
    return searchRecords({ records, searchText, searchKeys: ['breachTitle'] })
  }, [allRecords, searchText, disabledActions, omittedActions])

  useEffect(() => {
    // Checks if any breaches have actions available, if so show the multi select actions allowing actions dropdown to show as disabled unless a breach is selected
    if (records.some(learner => learner.actions.length > 0)) {
      return setShowMultiSelectActions(true)
    }

    // If no breaches have actions available, check if any mutli select actions are available and update accordingly
    listActions > 0 ? setShowMultiSelectActions(true) : setShowMultiSelectActions(false)
  }, [records, listActions, setShowMultiSelectActions])

  return (
    <>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('errors.yourBreachesCouldNotBeLoaded', trOpt)} />
          : (
            <>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
                </ListHeaderPanel>
              </ListHeader>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <SearchBar
                    placeholder={I18n.t('common.searchBreaches', trOpt)}
                    value={searchText}
                    allowClear
                    onChange={onSearchChange}
                  />
                  {showMultiSelectActions ? <ListViewActions actions={listActions} performAction={performAction} selectedIds={selectedIds} /> : null}
                </ListHeaderPanel>
              </ListHeader>
              <BreachesTable
                {...{
                  breachedServices,
                  breachedUsers,
                  domain,
                  refetchQueries,
                  loading,
                  handleRefreshClick,
                  performAction,
                  selectedIds,
                  showMultiSelectActions
                }}
                updateSelectedIds={setSelectedIds}
                breaches={records}
                // Setting storageId to null when domain is set to prevent saving table state in the modals for domain view
                storageId={!domain && tableStorageId}
              />
            </>
          )
      }
      <MarkResolvedConfirm ref={markResolvedConfirmRef} type='breach' {...{ refetchQueries }} />
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['companyId', 'userId', 'planValid']),
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled'])
  })
)(BreachesView)
