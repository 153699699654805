const main = {
  primary: '#1e7be4',
  evaluate: '#f75959',
  educate: '#9458cb',
  simulate: '#17baf7',
  report: '#49b8cb',
  cta: '#00CC99',
  heading: '#414042',
  copy: '#76787b',
  white: '#ffffff',
  nav: '#414042',
  success: '#4FD9B6',
  missed: '#faad14',
  failure: '#ed2024',
  grey: '#DEDEDE',
  darkGrey: '#6C6A6A',
  darkerGrey: '#4C4E4D',
  lightGrey: '#f4f4f4',
  colorRiskMedium: '#FDAA0D',
  colorRiskHigh: '#F64234',
  green: 'rgb(90, 186, 80)',
  orange: 'rgb(254, 169, 13)',
  red: 'rgb(237, 32, 36)',
  amber: '#FFD300',

  pageBG: '#f0f2f5',

  reportBG: '#F5F5FB',
  reportDark: '#0D2547', // "Oxford Blue"

  widthLargeDesktop: '1280px',
  widthMediumDesktop: '992px',
  widthTablet: '768px',
  widthPhone: '480px',

  scrollbarSize: 5,
  scrollbarPadding: 10,

  appThemeLogo: '/images/logos/usecure.svg',

  baseFont: '\'Hind Guntur\', sans-serif, Georgia,Cambria, "Times New Roman", Times',
  copyFont: '\'Quicksand\', sans-serif',

  riskReportRed: 'rgba(232,33,45,0.8)',

  riskScoreVeryHigh: '#D12D34',
  riskScoreHigh: '#F0953E',
  riskScoreMedium: '#F8DC4A',
  riskScoreLow: '#5EB77E',
  riskScoreVeryLow: '#377B59',

  reportDocumentLogo: '/images/logos/usecure.svg',
  reportDocumentHeaderBackgroundColor: '#414042',
  reportDocumentHeaderFontColor: '#ffffff',

  simulationSend: '#5aba50',
  simulationOpen: '#1e7be4',
  simulationVisit: '#fdaa0d',
  simulationCompromise: '#f64234',
  simulationReport: '#377b59',

  videoPlayerBackgroundColor: '#000000',
  videoPlayerControlHighlightColor: '#1e7be4',
  videoPlayerIconColor: '#ffffff'
}

main.colorRiskLow = main.primary
main.secondary = main.white
main.navFontColor = main.secondary
main.navHighlightColor = main.primary

export default {
  main
}
