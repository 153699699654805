import moment from 'moment'
import I18n from 'i18n-js'
import _isInteger from 'lodash/isInteger'

export const DURATION_UNIT_IDS = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']
export const getDurationObject = diff => {
  const duration = moment.duration(diff)

  return DURATION_UNIT_IDS.reduce((acc, unit) => {
    acc[unit] = duration.get(unit)
    // weeks is a derived value in moment durations and doesn't affect days
    // So if days is a multiple of 7 it needs to zero'd so that we don't get strings like '2 weeks 14 days' for a diff of 14 days
    if (unit === 'days' && acc[unit] % 7 === 0) {
      acc[unit] = 0
    }
    return acc
  }, {})
}
const generateDurationString = (diff, { scope = 'common.duration.value', maxUnits, includeAll = false, roundSeconds = true, long = false } = {}) => {
  const durationObject = getDurationObject(diff)
  maxUnits = _isInteger(maxUnits) ? maxUnits : 0
  let baseUnitFound = false
  let unitsUsed = 0
  let maxUnitReached = false
  const phrases = DURATION_UNIT_IDS.reduce((acc, unit, index) => {
    let unitDuration = durationObject[unit]
    if ((includeAll || baseUnitFound || unitDuration > 0) && !maxUnitReached) {
      baseUnitFound = true
      unitsUsed += 1
      maxUnitReached = (maxUnits > 0 && maxUnits === unitsUsed) || (unit === 'seconds' && roundSeconds)
      if (maxUnitReached) {
        const remainingUnits = DURATION_UNIT_IDS.slice(index)
        if (remainingUnits.length > 1) {
          const remainingDuration = moment.duration(remainingUnits.reduce((acc, unit) => ({ ...acc, [unit]: durationObject[unit] }), {}))
          unitDuration = Math.round(remainingDuration.as(unit))
        }
      }
      if (unitDuration > 0) {
        acc.push(I18n.t(unit, { scope, count: unitDuration }))
      }
    }

    return acc
  }, [])
  return phrases.reduce((acc, phrase, index) => {
    // The 'long' feature only works for definite in English
    // It may need to be disabled or expanded for languages with different syntax to a duration like 'n hours, n minutes and n seconds'
    if (long && index > 0) {
      acc += index > 0 && index === phrases.length - 1 ? ` ${I18n.t('common.and')}` : ','
    }
    acc += ` ${phrase}`
    return acc
  }, '').trim()
}
export const getDurationShortString = (diff, opt = {}) => generateDurationString(diff, { scope: 'common.duration.shortValue', long: false, ...opt })
export const getDurationString = (diff, opt = {}) => generateDurationString(diff, opt)
