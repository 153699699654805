import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import moment from 'moment'

import { formatDate } from '../../helpers/datetime'

const trOpt = { scope: 'settings.reportSettings.reportDocumentPreview' }

const SectionHeaderContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 12px;
  padding-bottom: 6px;
  h2 {
    font-weight: bold;
    font-size: 12px;
    color: ${({ fontColor }) => fontColor};
  }
`

const Logo = styled.div`
  background-image: url('${({ logo }) => logo}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 50px;
  width: 75px;
  padding-left: 12px;
  padding-right: 12px;
`

const ReportHeaderContainer = styled.div`
  padding-top: 100px;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  font-size: 10px;
  h1 {
    font-size: 22px;
    margin-bottom: 0;
    color: ${({ fontColor }) => fontColor};
  }
`

const ReportPage = styled.div`
  background-color: #fff;
  width: 300px;
  height: 400px;
`

const ReportContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  background-color: #ccc;
  padding: 20px;
`

const ReportDocumentPreview = React.forwardRef(({
  logo,
  backgroundColor,
  fontColor,
  showCompanyName = false
}, ref) => {
  return (
    <ReportContainer>
      <ReportPage>
        <ReportHeaderContainer {...{ backgroundColor, fontColor }}>
          <Logo {...{ logo }} />
          <h1>{I18n.t('reportTitle', trOpt)}</h1>
          {showCompanyName && <div>{I18n.t('exampleCompanyName', trOpt)}</div>}
          <div>{formatDate(moment().startOf('year'))} - {formatDate(moment().endOf('year'))}</div>
        </ReportHeaderContainer>
      </ReportPage>
      <ReportPage>
        <SectionHeaderContainer {...{ backgroundColor, fontColor }}>
          <h2>{I18n.t('sectionHeading', trOpt)}</h2>
        </SectionHeaderContainer>
      </ReportPage>
    </ReportContainer>
  )
})

export default ReportDocumentPreview
