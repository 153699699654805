import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Button, Icon, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { buildTree } from '../Learners/helpers'
import MutationForm from '../MutationForm/MutationForm'
import { CREATE_GROUP, EDIT_GROUP, GET_GROUP, GET_GROUP_TREE } from '../Queries/Groups'
import DeleteGroupConfirm from '../Modals/DeleteGroupConfirm'
import DetachGroupConfirm from '../Modals/DetachGroupConfirm'
import { GET_LEARNERS } from '../Queries/Learners'
import { invalidateLearnersAndGroupsQueryCache } from '../../helpers'
import { ErrorAlerts, LoadingBlock } from '../common'
import { OverflowModal, ModalFormScrollContainer } from './common'

const trOpt = { scope: 'modals.editGroupModal' }

const EditGroupModal = ({
  groupId, visible = false, setVisible = () => {}, refetchQueries = [],
  checkedGroups = [], updateCheckedGroups = () => {}, updateSelectedGroup = () => {}
}) => {
  const { loading: getGroupLoading, error: getGroupError, data: groupData = {} } = useQuery(GET_GROUP, { variables: { groupId: groupId }, skip: !visible || !groupId })
  const { loading: getGroupsLoading, error: getGroupsError, data: getGroupsData = {} } = useQuery(GET_GROUP_TREE, { skip: !visible })
  const { loading: getLearnersLoading, error: getLearnersError, data: getLearnersData = {} } = useQuery(GET_LEARNERS, { skip: !visible })

  const group = groupId ? _get(groupData || {}, 'group', {}) : undefined
  const { groupTree = [] } = getGroupsData || {}
  const { learners = [] } = getLearnersData || {}

  const parentGroupId = group ? _get(group, 'parent.id') : null
  const groupTreeOptions = useMemo(() => buildTree(groupTree, group ? group.id : undefined), [groupTree, group])
  const learnerOptions = useMemo(() => {
    const options = learners.map(({ id: value, name: label }) => ({ value, label }))
    options.sort((a, b) => a.label.localeCompare(b.label))
    return options
  }, [learners])

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  useEffect(() => {
    if (group) {
      resetForm()
    }
  }, [group, resetForm])
  const closeModal = useCallback(() => {
    setVisible(false)
    resetForm()
  }, [setVisible, resetForm])

  const afterDelete = useCallback(() => {
    if (groupId) {
      if (checkedGroups.includes(groupId)) {
        updateCheckedGroups(checkedGroups.filter(g => g !== groupId))
      }
      // Assumes selected group matches groupId
      updateSelectedGroup(null)
      closeModal()
    }
  }, [groupId, checkedGroups, updateCheckedGroups, updateSelectedGroup, closeModal])
  const openDeleteConfirm = useCallback(() => DeleteGroupConfirm(groupId, afterDelete, refetchQueries), [groupId, afterDelete, refetchQueries])
  const openDetachConfirm = useCallback(() => DetachGroupConfirm(groupId, refetchQueries), [groupId, refetchQueries])

  const footer = groupId ? [<Button key='delete' onClick={openDeleteConfirm} type='danger'><Icon type='delete' />{I18n.t('deleteGroup', trOpt)}</Button>] : null

  if (footer && (group.syncRecordId || group.syncType)) {
    footer.unshift(<Button key='detach' onClick={openDetachConfirm}>{I18n.t('detachGroupFromSync', trOpt)}</Button>)
  }

  const fields = useMemo(() => [
    {
      id: 'name',
      label: I18n.t('name', trOpt),
      placeholder: 'Group Name',
      required: true,
      defaultValue: group ? group.name : null
    },
    {
      id: 'parentGroupId',
      label: I18n.t('parentGroup', trOpt),
      type: 'treeSelect',
      treeData: groupTreeOptions,
      defaultValue: parentGroupId,
      mutateValue: value => value || null,
      allowClear: true
    },
    {
      id: 'managerIds',
      label: I18n.t('manager', trOpt),
      type: 'multiSelect',
      options: learnerOptions,
      multiple: true,
      defaultValue: group && group.managers ? group.managers.map(managers => managers.id) : null
    }
  ], [group, groupTreeOptions, learnerOptions, parentGroupId])

  const onSuccess = useCallback(() => {
    message.success(I18n.t(group ? 'updateSuccessMessage' : 'createSuccessMessage', trOpt))
    closeModal()
  }, [closeModal, group])

  return (
    <OverflowModal
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={footer}
    >
      <h2>{I18n.t(groupId ? 'updateTitle' : 'createTitle', trOpt)}</h2>
      <LoadingBlock fullScreen={false} loading={getGroupLoading || getLearnersLoading || getGroupsLoading} />
      {getGroupError && <ErrorAlerts error={getGroupError} defaultError={I18n.t('groupError', trOpt)} />}
      {getGroupsError && <ErrorAlerts error={getGroupsError} defaultError={I18n.t('common.groupsLoadError')} />}
      {getLearnersError && <ErrorAlerts error={getLearnersError} defaultError={I18n.t('common.usersLoadError')} />}
      {!(getGroupError || getGroupsError || getLearnersError) && (
        <MutationForm
          mutation={group ? EDIT_GROUP : CREATE_GROUP}
          variables={group ? { groupId: group.id } : null}
          onSuccess={onSuccess}
          onFailure={I18n.t(group ? 'updateErrorMessage' : 'createErrorMessage', trOpt)}
          submitLabel={I18n.t(group ? 'updateSubmitLabel' : 'createSubmitLabel', trOpt)}
          fields={fields}
          ref={form}
          refetchQueries={refetchQueries}
          update={invalidateLearnersAndGroupsQueryCache}
          skipResetFieldsOnSubmit
          scrollContainer={ModalFormScrollContainer}
        />
      )}
    </OverflowModal>
  )
}

export default EditGroupModal
