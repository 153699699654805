import React, { useCallback } from 'react'
import { Icon, Button } from 'antd'
import I18n from 'i18n-js'

import { modalConfirmAsync } from '../../helpers'

const trOpt = { scope: 'accountSettings.twoFactorAuthSettings' }

const TwoFactorAuthSettings = ({ setCurrentStep, disable2FA }) => {
  const onDisableClick = useCallback(async () => {
    const confirm = await modalConfirmAsync({
      title: I18n.t('doYouWantToDisableTwoFA', trOpt),
      icon: <Icon type='warning' />,
      content: I18n.t('thisWillRemoveAnyExisting', trOpt)
    })
    if (confirm) {
      await disable2FA()
    }
  }, [disable2FA])

  const goToIntro = useCallback(() => setCurrentStep('intro'), [setCurrentStep])
  const goToRecovery = useCallback(() => setCurrentStep('recovery'), [setCurrentStep])

  return (
    <div>
      <p>{I18n.t('accountSettings.enableQrAuth.twoFactorAuthAddsAdditionalLayerOfSecurity')}</p>
      <Button type='danger' onClick={onDisableClick}>{I18n.t('common.disable')}</Button>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '25px' }}>
        <p style={{ margin: 0, fontWeight: 600 }}>{I18n.t('authenticatorApp', trOpt)}</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontStyle: 'italic' }}>{I18n.t('configured', trOpt)}</span>
          <Button style={{ width: '80px', marginLeft: '20px' }} onClick={goToIntro}>
            {I18n.t('common.edit')}
          </Button>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
        <p style={{ margin: 0, fontWeight: 600 }}>{I18n.t('recoveryCodes', trOpt)}</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontStyle: 'italic' }}>{I18n.t('configured', trOpt)}</span>
          <Button style={{ width: '80px', marginLeft: '20px' }} onClick={goToRecovery}>
            {I18n.t('common.view')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TwoFactorAuthSettings
