/* global DOMParser */
import _isString from 'lodash/isString'

const whiteSpaceRe = /\s+/gi // Replace white space with dash
const illegalRe = /[^a-zA-Z0-9-]/gi // Strip any special characters
export const sanitiseString = (input, replacement = '', toLowerCase = true) => {
  if (!_isString(input)) {
    throw new Error('Input must be string')
  }
  const sanitised = input.replace(whiteSpaceRe, replacement).replace(illegalRe, replacement)
  return toLowerCase ? sanitised.toLowerCase() : sanitised
}

export const stripHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}
