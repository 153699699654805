import { createLocaleGetterObject, createLocaleGetterOptions, getSortedLocaleOptions } from '../helpers/locale'

const difficultyScope = 'courses.common.difficulties'
export const COURSE_DIFFICULTY = createLocaleGetterObject({
  1: `${difficultyScope}.gapAnalysis`,
  2: `${difficultyScope}.beginner`,
  3: `${difficultyScope}.intermediate`,
  4: `${difficultyScope}.advanced`,
  99: 'common.custom'
})

// course.subject is a free text field where the store value is the same as the English copy
// The keys in this constant reflect that and should not be changed without a database migration
// const subjectTrOpt = { scope: 'courses.common.subjects' }
const subjectScope = 'courses.common.subjects'
const COURSE_SUBJECT_KEYS = {
  InfoSec: `${subjectScope}.infosec`,
  Video: `${subjectScope}.video`,
  Compliance: `${subjectScope}.compliance`,
  Generic: `${subjectScope}.generic`,
  Custom: 'common.custom'
}
export const COURSE_SUBJECT_IDS = ['InfoSec', 'Video', 'Compliance', 'Generic', 'Custom']
export const COURSE_SUBJECTS = createLocaleGetterObject(COURSE_SUBJECT_KEYS)
export const COURSE_SUBJECT_OPTIONS = createLocaleGetterOptions(COURSE_SUBJECTS)
export const getCourseSubjectOptionsByLocale = locale => getSortedLocaleOptions(locale, COURSE_SUBJECT_KEYS)

// course.category is a free text field where the store value is the same as the English copy
// The keys in this constant reflect that and should not be changed without a database migration
const categoryTrOpt = { scope: 'courses.common.categories' }
const COURSE_CATEGORY_KEYS = {
  Phishing: 'phishing',
  'Secure Passwords': 'securePasswords',
  'Physical Security': 'physicalSecurity',
  'Security at Home': 'securityAtHome',
  'Working Remotely': 'workingRemotely',
  'Public Wifi': 'publicWifi',
  'Social Engineering': 'socialEngineering',
  'Social Media': 'socialMedia',
  'Mobile Device Security': 'mobileDeviceSecurity',
  'Cloud Security': 'cloudSecurity',
  'Removable Media': 'removableMedia',
  'Internet and Email Usage': 'internetAndEmailUsage'
}
export const COURSE_CATEGORIES = createLocaleGetterObject(COURSE_CATEGORY_KEYS, categoryTrOpt)
export const COURSE_CATEGORY_OPTIONS = createLocaleGetterOptions(COURSE_CATEGORIES)
export const getCourseCategoryOptionsByLocale = locale => getSortedLocaleOptions(locale, COURSE_CATEGORY_KEYS, categoryTrOpt)

export const ULEARN_COMPLETED_SLIDES_STORAGE_PREFIX = 'ulearn|completedSlides|'
export const ULEARN_VIMEO_STORAGE_PREFIX = 'ulearn|vimeo|'
export const ULEARN_VIMEO_USER_PLAYER_STORAGE_ID = 'ulearn|useVimeoPlayer'

export const LEARNER_COURSE_ERROR_TR_KEYS = {
  load: {
    titleKey: 'anErrorOccurredLoadingYourCourse',
    subtitleKey: 'thisIsEitherDueToAnIncorrectLink'
  },
  start: {
    titleKey: 'anErrorOccurredStartingYourCourse',
    subtitleKey: 'networkOrSystemIssue'
  },
  answer: {
    titleKey: 'anErrorOccurredSubmittingYourAnswer',
    subtitleKey: 'networkOrSystemIssue'
  },
  retry: {
    titleKey: 'anErrorOccurreRestartingYourCourse',
    subtitleKey: 'networkOrSystemIssue'
  },
  finish: {
    titleKey: 'anErrorOccurredRecordingResult',
    subtitleKey: 'networkOrSystemIssue'
  },
  unknown: {
    titleKey: 'defaultErrorTitle'
  }
}
