import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { Collapse } from 'antd'

const { Panel } = Collapse

const MutationFormCollapse = styled(Collapse)`
  margin: 15px 0 20px;
`
const MutationFormPanel = styled(Panel)`
  .ant-btn {
    margin-bottom: 15px;
  }
`

const _MutationFormCollapse = ({ header = I18n.t('common.advancedOptions'), fieldComponents = [], visible }) => {
  if (!visible) {
    return null
  }

  return (
    <MutationFormCollapse>
      <MutationFormPanel {...{ header }}>
        {fieldComponents}
      </MutationFormPanel>
    </MutationFormCollapse>
  )
}

export default _MutationFormCollapse
