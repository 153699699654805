import { useState, useEffect, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import unauthenticatedClient from '../apollo-client/unauthenticatedClient'
import { GET_SIMPLE_COMPANY } from '../components/Queries/Companies'
import { captureSentryError } from '../helpers/sentry'
import { UsecureError } from '../helpers'

const useRouteCompany = ({ useQueryParam = false, variables } = {}) => {
  const { companyId: routeCompanyId } = useParams()
  const [dataRequired, setDataRequired] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const { search } = useLocation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [companyChecks, setCompanyChecks] = useState({
    isPlanValid: false,
    company: null,
    settings: null,
    accountType: null,
    endUserAuthOptions: null
  })
  const fetchCompany = useCallback(async companyId => {
    try {
      setDataRequired(false)
      setLoading(true)
      const results = await unauthenticatedClient.query({
        query: GET_SIMPLE_COMPANY,
        variables: { id: companyId, withParent: true, ...(variables || {}) }
      })
      const companyData = results?.data?.simpleCompany
      if (companyData) {
        setCompanyChecks({
          isPlanValid: companyData?.planValid,
          settings: companyData?.settings,
          company: companyData,
          accountType: companyData?.accountType,
          endUserAuthOptions: companyData?.endUserAuthOptions
        })
      } else {
        // This error should not be disabled on screen, it is only thrown so that this hook returns an error when no company is found
        throw new UsecureError('No company found for this URL', { level: 'silent' })
      }
    } catch (e) {
      captureSentryError(e, { msg: 'useRouteCompany - company load - ERROR' })
      setError(e)
    } finally {
      setLoading(false)
    }
  }, [variables])
  useEffect(() => {
    let newCompanyId
    if (useQueryParam) {
      const companyIdParam = new URLSearchParams(search).get('companyId')
      if (companyIdParam) {
        newCompanyId = companyIdParam
      }
    } else if (routeCompanyId) {
      newCompanyId = routeCompanyId
    }
    if (newCompanyId && newCompanyId !== companyId) {
      setCompanyId(newCompanyId)
      setDataRequired(true)
    }
  }, [useQueryParam, search, routeCompanyId, companyId])
  useEffect(() => {
    if (!loading && dataRequired && companyId) fetchCompany(companyId)
  }, [fetchCompany, companyId, loading, dataRequired])

  return { ...companyChecks, loading, error }
}
export default useRouteCompany
