import { Component } from 'react'

export class LearnerCourseBase extends Component {
  constructor (props) {
    super(props)

    this.completedTimer = null
  }

  startCompletionTimer () {
    const { slide: { id }, setSlideCompleted, waitMS } = this.props

    clearTimeout(this.completedTimer)

    if (waitMS > 0) {
      this.completedTimer = setTimeout(() => {
        setSlideCompleted(id)
      }, waitMS)
    }
  }
}
