import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import _isFunction from 'lodash/isFunction'

import MutationFormErrors from './MutationFormErrors'

class MutationFormInput extends Component {
  constructor (props) {
    super(props)

    this.input = React.createRef()

    this.state = {
      hasReceivedFocus: false
    }

    this.focus = this.focus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  handleChange (event) {
    const { name, value } = event.target
    this.props.onChange(name, value)
  }

  handleBlur (event) {
    if (_isFunction(this.props.onBlur)) {
      const { name, value } = event.target
      this.props.onBlur(name, value)
    }
  }

  handleFocus () {
    this.setState({ hasReceivedFocus: true })
  }

  handleKeyDown (event) {
    if (event.keyCode === 13 && this.props.disableSubmitOnEnter) {
      event.preventDefault()
    }
  }

  focus () {
    if (this.input.current) this.input.current.focus()
  }

  renderInput () {
    const { id, type = 'text', required, placeholder, autofill, value, min, max, step, addonAfter, addonBefore, disabled, allowClear, prefix } = this.props

    // Prevent browser autofil of password
    const autoComplete = type === 'password' && !autofill ? 'new-password' : undefined
    const readOnly = !autofill ? !this.state.hasReceivedFocus : undefined

    return (
      <Input
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        name={id}
        ref={this.input}
        {...{ value, required, type, placeholder, autoComplete, readOnly, min, max, step, addonAfter, addonBefore, disabled, allowClear, prefix }}
      />
    )
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, extra, formItemStyle, errors = [], required, className } = this.props
    const showErrors = errors.length > 0

    return (
      <Form.Item
        className={className} label={label} extra={extra} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        {this.renderInput()}
      </Form.Item>
    )
  }
}

MutationFormInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  autofill: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  extra: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.func]),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  addonAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  addonBefore: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  allowClear: PropTypes.bool,
  formItemStyle: PropTypes.object,
  disabled: PropTypes.bool,
  disableSubmitOnEnter: PropTypes.bool
}

MutationFormInput.defaultProps = {
  id: '',
  type: 'text',
  label: null,
  required: false,
  placeholder: '',
  autofill: true,
  value: '',
  extra: null,
  onChange: () => {},
  disabled: false,
  disableSubmitOnEnter: false
}

export default MutationFormInput
