import React, { useCallback } from 'react'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import DeleteConfirm from './DeleteConfirm'
import { UNENROLL_FROM_GAP_ANALYSIS } from '../Queries/Courses'
import { invalidateLearnersAndLearnerDataQueryCache, mapStringArrayToLi, UsecureError } from '../../helpers'

const trOpt = { scope: 'modals.unenrolLearnersFromGapAnalysisConfirm' }

const UnenrolLearnersFromGapAnalysisConfirm = React.forwardRef(({ refetchQueries, update = invalidateLearnersAndLearnerDataQueryCache, ...actionConfirmProps }, ref) => {
  const processResult = useCallback(({ result, ids }) => {
    const success = _get(result, 'data.unenrollLearnersFromGapAnalysis') === true
    if (!success) {
      throw new UsecureError(I18n.t('notEnrolledMessage', { ...trOpt, count: ids.length }))
    }
    return success
  }, [])

  return (
    <DeleteConfirm
      mutation={UNENROLL_FROM_GAP_ANALYSIS}
      variableName='learnerIds'
      {...{ update, refetchQueries, trOpt, ref, processResult }}
      skipDefaultErrorForExceptions
      deletedItems={mapStringArrayToLi(I18n.t('actions', trOpt))}
      {...actionConfirmProps}
    />
  )
})

export default UnenrolLearnersFromGapAnalysisConfirm
