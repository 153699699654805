import React from 'react'
import _isFunction from 'lodash/isFunction'
import _isBoolean from 'lodash/isBoolean'
import _isNil from 'lodash/isNil'
import { Link } from 'react-router-dom'
import { PreviewTag } from '../components/common'
import isRouteAllowed from './isRouteAllowed'
import { MenuItem, SubMenu, TopMenuItemTitle } from '../components/Nav/NavBar'
export const renderSubMenuItem = (subMenu, key, session, settings) => {
  const { title, float, children = [], component: SubMenuComponent = SubMenu } = subMenu
  const childItems = children.map(item => renderMenuItem({ ...item, sub: true }, session, settings)).filter(item => !_isNil(item))
  if (childItems.length > 0) {
    return (
      <SubMenuComponent
        key={key}
        popupClassName={`usecure-nav-${key}`}
        title={<TopMenuItemTitle>{title}</TopMenuItemTitle>}
        {...{ float }}
      >{childItems}
      </SubMenuComponent>
    )
  }
}
const isAllowed = (menuItem, session, settings) => {
  const { link, isAllowed } = menuItem
  let allowed = true
  if (link) {
    allowed = isRouteAllowed(session, settings, link, 'endUser')
  }
  if (allowed) {
    if (_isBoolean(isAllowed)) {
      allowed = isAllowed
    } else if (_isFunction(isAllowed)) {
      allowed = isAllowed({ menuItem, session, settings, sessionType: 'endUser' })
    }
  }
  return allowed
}
export const renderMenuItem = (item, session, settings) => {
  if (!isAllowed(item, session, settings)) {
    return
  }

  const { title, link, key, content, externalLink, onClick, float, sub = false, preview } = item
  const autoheight = item.autoheight ? 'true' : 'false'
  const previewTag = preview ? <PreviewTag /> : null
  const titleContent = sub
    ? (
      <>
        {title}
        {previewTag}
      </>
    )
    : <TopMenuItemTitle>{title}{previewTag}</TopMenuItemTitle>

  if (_isFunction(onClick)) {
    return (
      <MenuItem autoheight={autoheight} key={link} float={float}>
        <div className='usecure-menu-item' onClick={onClick}>{titleContent}</div>
      </MenuItem>
    )
  } else if (link) {
    return (
      <MenuItem autoheight={autoheight} key={link} float={float}>
        <Link to={link}>{titleContent}</Link>
      </MenuItem>
    )
  } else if (externalLink) {
    return (
      <MenuItem autoheight={autoheight} key={externalLink} float={float}>
        <a href={externalLink} target='_blank' rel='noopener noreferrer'>{titleContent}</a>
      </MenuItem>
    )
  } else if (key && content) {
    return (
      <MenuItem autoheight={autoheight} key={key} float={float}>{content}</MenuItem>
    )
  }
}
