import React from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import moment from 'moment'

import { renderParagraphsFragmentFromArray, renderListFragmentFromArray } from '../../../helpers'
import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'

const trOpt = { scope: 'settings.reportEmail' }

const _SubjectExtra = ({ className, isMSP = false }) => {
  return (
    <div {...{ className }}>
      {
        isMSP
          ? renderParagraphsFragmentFromArray(I18n.t('mspSubjectTokensIntro', trOpt))
          : <p>{I18n.t('subjectTokensIntro', trOpt)}</p>
      }
      <ul>
        {renderListFragmentFromArray(I18n.t(isMSP ? 'mspSubjectTokens' : 'subjectTokens', { ...trOpt, currentYear: moment().year() }))}
      </ul>
    </div>
  )
}
const SubjectExtra = styled(_SubjectExtra)`
  margin-top: 5px;

  p {
    font-family: unset;
    margin-bottom: 5px;
  }
  ul {
    padding-left: 22px;
  }
`

class ReportEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'reports'
    this.showSentTestButton = true

    const { accountType, defaultTenant } = this.props
    const subjectFields = [{ id: 'subject', extra: <SubjectExtra /> }]
    if (accountType === 'msp' && !defaultTenant) {
      // Show subject for customer report emails to MSP Admins
      subjectFields.push({ id: 'mspSubject', extra: <SubjectExtra isMSP /> })
    }
    this.generateSubjectFields(subjectFields, trOpt)
  }

  get headerId () {
    return 'settings-report-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(ReportEmail)
