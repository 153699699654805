import React, { useCallback } from 'react'
import { Card, Modal, Radio } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import { renderListFromArray } from '../../helpers'
import RecommendedTag from '../common/RecommendedTag'

export const AuthModal = styled(Modal)`
  max-width: 800px;
`

export const AuthModalWrap = styled.div`
  overflow: hidden;
`

export const AuthModalTitle = styled.span`
  color: rgba(0,0,0,0.85);
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  overflow: hidden;
`

export const AuthModalContent = styled.div`
  color: rgba(0,0,0,0.65);
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  font-size: 14px;
  gap: 15px;
  margin-top: 8px;

  ul {
    padding-left: 20px;
  }
`

export const AuthModalFooter = styled.div`
  float: right;
  margin-top: 24px;

  & > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const TaggedOptionLabel = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-tag {
    position: relative;
    top: -5px;
  }
`
const RecommendedLabel = styled.span`
  font-weight: 500;
`

const AuthRadioCard = styled(Card)`
  width: 50%;

  ul {
    margin: 5px 0 10px;
  }
`

const AuthRadio = styled(Radio)`
  span.ant-radio + * {
    display: inline-block;
  }
`

const CurrentAuthMessage = styled.div`
  font-weight: 500;
`

export const AuthRadioOption = ({ type, recommended = false, checked = false, isExistingValue = false, onChange: onChangeProp = () => {}, trOpt, disabled = false, extra }) => {
  const cardTrOpt = { scope: `${trOpt.scope}.${type}` }
  const labelText = I18n.t('label', cardTrOpt)

  const onChange = useCallback(() => {
    onChangeProp(type)
  }, [type, onChangeProp])

  return (
    <AuthRadioCard onClick={onChange}>
      <AuthRadio {...{ checked, onChange, disabled }}>
        {
          recommended
            ? (
              <TaggedOptionLabel>
                <RecommendedLabel>{labelText}</RecommendedLabel>
                <RecommendedTag />
              </TaggedOptionLabel>
            )
            : labelText
        }
      </AuthRadio>
      {renderListFromArray(I18n.t('info', cardTrOpt))}
      {isExistingValue && <CurrentAuthMessage>{I18n.t('currentMethod', trOpt)}</CurrentAuthMessage>}
      {extra}
    </AuthRadioCard>
  )
}
