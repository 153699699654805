import React, { useState } from 'react'
import { DatePicker, Table } from 'antd'
import I18n from 'i18n-js'
import { ResponsiveLine } from '@nivo/line'
import { BrandColours } from '../../helpers/Graphs'
import { useQuery } from 'react-apollo'
import { DASHBOARD_CHART_DATA } from '../../components/Queries/Reports'
import { Loader } from '../../components/common'
import { formatDate } from '../../helpers/datetime'
import IntercomHeader from '../../components/IntercomHeader'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'
import { BasicTooltip } from '@nivo/tooltip'

const trOpt = { scope: 'reports.dashboard' }

const { RangePicker } = DatePicker

const DashboardChart = () => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const [dates, setDates] = useState([])
  const {
    loading, data: {
      dashboardChartData: {
        dashboardChartScores = [],
        dashboardChartSummaryData = {}
      } = {}

    } = {}, refetch
  } = useQuery(DASHBOARD_CHART_DATA, {
    variables: { fromDate: dates[0], toDate: dates[1] }
  })
  const {
    totalUBreachRiskScore,
    totalUPhishRiskScore,
    totalResults,
    totalRiskScore,
    totalULearnRiskScore,
    maxULearnRiskScore,
    minULearnRiskScore,
    maxUPhishRiskScore,
    minUPhishRiskScore,
    maxUBreachRiskScore,
    minUBreachRiskScore,
    maxRiskScore,
    minRiskScore
  } = dashboardChartSummaryData

  const handleDateChange = (date, dateString) => {
    setDates(dateString)
    refetch()
  }

  const data = [
    {
      key: '1',
      name: I18n.t('company', trOpt),
      averageScore: Math.round(totalRiskScore / totalResults),
      maxScore: maxRiskScore,
      minScore: minRiskScore
    }
  ]
  if (hasAllSessionPermissions([permissions.BREACH_REPORT])) {
    data.push({
      key: '2',
      name: I18n.t('common.uBreach'),
      averageScore: Math.round(totalUBreachRiskScore / totalResults),
      maxScore: maxUBreachRiskScore,
      minScore: minUBreachRiskScore
    })
  }
  if (hasAllSessionPermissions([permissions.COURSE_REPORT])) {
    data.push({
      key: '3',
      name: I18n.t('common.uLearn'),
      averageScore: Math.round(totalULearnRiskScore / totalResults),
      maxScore: maxULearnRiskScore,
      minScore: minULearnRiskScore
    })
  }
  if (hasAllSessionPermissions([permissions.SIMULATION_REPORT])) {
    data.push({
      key: '4',
      name: I18n.t('common.uPhish'),
      averageScore: Math.round(totalUPhishRiskScore / totalResults),
      maxScore: maxUPhishRiskScore,
      minScore: minUPhishRiskScore
    })
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IntercomHeader Size='h4' id='risk-score-history-header' style={{ margin: 0 }}>{I18n.t('riskScoreHistory', trOpt)}</IntercomHeader>
        <RangePicker style={{ top: '25px', width: '30%' }} onChange={handleDateChange} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ height: '500px', width: '70%' }}>
          {loading
            ? <Loader />
            : (
              <ResponsiveLine
                colors={BrandColours}
                data={(dashboardChartScores || []).map(({ id, ...scoreData }) => ({
                  ...scoreData,
                  id: id === 'overall' ? I18n.t('company', trOpt) : I18n.t(id, { scope: 'common' })
                }))}
                curve='monotoneX'
                enableGridX={false}
                margin={{ top: 50, right: 70, bottom: 70, left: 80 }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Date',
                  legendOffset: 65,
                  legendPosition: 'middle',
                  format: value => formatDate(value)
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: I18n.t('common.riskScore'),
                  legendOffset: -70,
                  legendPosition: 'middle'
                }}
                yScale={{
                  type: 'linear',
                  min: 0
                }}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                  useUTC: false,
                  precision: 'day'
                }}
                xFormat='time:%Y-%m-%d'
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh
                enablePoints={false}
                legends={[
                  {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 54,
                    translateY: -50,
                    itemsSpacing: 70,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
                tooltip={({ point }) => (
                  <BasicTooltip
                    id={<span>{formatDate(point.data.xFormatted)}: {point.data.yFormatted}</span>}
                    enableChip
                    color={point.serieColor}
                  />
                )}
              />
            )}
        </div>
        <Table
          style={{ height: '350px', width: '30%' }}
          dataSource={data}
          columns={[{
            title: I18n.t('name', trOpt),
            dataIndex: 'name',
            key: 'name'
          },
          {
            title: I18n.t('averageScore', trOpt),
            dataIndex: 'averageScore',
            key: 'averageScore'
          },
          {
            title: I18n.t('minScore', trOpt),
            dataIndex: 'minScore',
            key: 'minScore'
          },
          {
            title: I18n.t('maxScore', trOpt),
            dataIndex: 'maxScore',
            key: 'maxScore'
          }
          ]}
          pagination={false}
        />
      </div>
    </>
  )
}

export default DashboardChart
