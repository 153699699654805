import React, { useState, useEffect, useCallback } from 'react'
import I18n from 'i18n-js'
import { useParams, useLocation, generatePath, useHistory } from 'react-router-dom'
import { Button } from 'antd'
import _isNil from 'lodash/isNil'

import { ShortLoader } from '../../components/common/Loading'
import { Row, Column } from '../../components/EndUserPortal/GridFlexiLayout'
import { SignInHeader } from '../../components/SignIn'
import { ErrorAlerts } from '../../components/common'
import { UsecureError, addDelay } from '../../helpers'
import routes from '../../constants/routes'
import { useGlobalState, useRouteCompany } from '../../hooks'
import { creators as sessionActions } from '../../state/actions/session'
import { creators as settingsActions } from '../../state/actions/settings'
import { creators as viewActions } from '../../state/actions/view'
import { captureSentryError } from '../../helpers/sentry'
import { getSessionAndSettingsFromLearnerMe } from '../../helpers/getLearnerMe'
import endUserClient from '../../apollo-client/endUserClient'
import { setEndUserSessionToken } from '../../helpers/session'
import { withAppTitleAndIcons } from '../../hocs'
import { LoadingMessage } from '../../components/EndUserPortal/SignIn'

const trOpt = { scope: 'endUserPortal.signIn' }

const TARGET_TO_ROUTE_TO_TARGET_MAP = {
  '/learn': routes.PORTAL_LEARN,
  '/policy': routes.PORTAL_POLICY,
  '/breach': routes.PORTAL_BREACH,
  '/settings': routes.PORTAL_SETTINGS
}

const goBackToStart = ({ history, targetPath, companyId } = {}) => {
  history.push(
    generatePath(
      TARGET_TO_ROUTE_TO_TARGET_MAP[targetPath] ?? routes.PORTAL_HOME,
      { companyId }
    )
  )
}

const EndUserSAMLResponse = () => {
  const { loading: queryLoader, error: companyError, company, settings } = useRouteCompany({ useQueryParam: true })
  const [status, setStatus] = useState('init')
  const [companyId, setCompanyId] = useState(null)
  const [error, setError] = useState(null)
  const routeParams = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const { endUserSessionUpdate, updateSettings, setLoadingVisible, setTheme } = useGlobalState(
    undefined,
    useCallback(dispatch => ({
      endUserSessionUpdate: session => {
        dispatch(sessionActions.endUserUpdate(session))
        dispatch(viewActions.loading(false))
      },
      updateSettings: settings => dispatch(settingsActions.update(settings)),
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    }), [])
  )

  // "On Mount" hook
  useEffect(() => {
    if (status !== 'ready') return

    const { status: statusParam } = routeParams
    const queryParams = new URLSearchParams(search)
    const token = queryParams.get('token')
    const errorCode = queryParams.get('errorCode')
    const companyId = queryParams.get('companyId')

    if (companyId) {
      setCompanyId(companyId)
    }

    if (statusParam === 'success' && token && companyId) {
      // Initialise end user session and navigate to home page or route indicated by targetPath param
      setStatus('success')
      addDelay({
        delay: 1500,
        action: async () => {
          // Set token
          setEndUserSessionToken(token)
          // get session and settings
          const { session, settings } = await getSessionAndSettingsFromLearnerMe(endUserClient)
          // Set session
          endUserSessionUpdate(session)
          updateSettings(settings)
        },
        complete: () => {
          goBackToStart({ history, targetPath: queryParams.get('targetPath'), companyId })
        },
        failure: error => {
          captureSentryError(error, { msg: 'End User Portal - SSO SAML Auth Response - ERROR' })
          // EUP-SIF - Session Initialisation Failure
          setError(new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode: 'SAML-EUP-SIF' }))
          setStatus('fail')
        }
      })
    } else if (statusParam === 'fail' && errorCode) {
      // Show failure state with error code for known error
      setError(new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode }))
      setStatus('fail')
    } else {
      // Show failure state without error code
      setStatus('fail')
    }
  }, [status, routeParams, search, history, endUserSessionUpdate, updateSettings])

  useEffect(() => {
    if (status === 'init' && settings && !_isNil(company)) {
      setTheme({
        ...(settings || {}),
        parentDefaultSettings: company.parentCompany?.settings?.defaultTenantSettings
      })
      updateSettings({ ...settings, ready: true })
      setLoadingVisible(false)
      setStatus('ready')
    }
  }, [setLoadingVisible, updateSettings, settings, setTheme, company, status])

  useEffect(() => {
    if (companyError) {
      // EUP-NCF - End User Portal, No Company Found
      setError(new UsecureError(I18n.t('common.noCompanyWithTheSuppliedIdExists'), { errorCode: 'EUP-NCF' }))
      setStatus('fail')
    }
  }, [companyError])

  useEffect(() => {
    if (status === 'init' && !new URLSearchParams(search).get('companyId')) {
      setStatus('ready')
    }
  }, [search, status])

  useEffect(() => {
    setLoadingVisible(queryLoader)
  }, [queryLoader, setLoadingVisible])

  const onLoginRetryClick = useCallback(() => {
    goBackToStart({ history, targetPath: new URLSearchParams(search).get('targetPath'), companyId })
  }, [history, companyId, search])

  if (status === 'init') return null

  return (
    <Row className='full-height justify align' justify='space-around' alignItems='center' alignContent='center'>
      <Column className='align' align-items='center' align-content='center' flex='0 0 30em' style={{ padding: '2em' }}>
        <SignInHeader bottomMargin='2em' allowThemeLogo={!_isNil(settings)}>
          {I18n.t('common.portalLogin')}
        </SignInHeader>
        {status !== 'fail' && (
          <>
            <LoadingMessage>{I18n.t('samlSSOLoadingMessage', trOpt)}</LoadingMessage>
            <ShortLoader />
          </>
        )}
        {(status === 'fail') && (
          <>
            <ErrorAlerts error={error || new Error(I18n.t('samlSSOError', trOpt))} defaultError={I18n.t('samlSSOError', trOpt)} includeErrorCode />
            <Button onClick={onLoginRetryClick} icon='redo'>{I18n.t('loginRetryButton', trOpt)}</Button>
          </>
        )}
      </Column>
    </Row>
  )
}

export default withAppTitleAndIcons({ isSignInPage: true })(EndUserSAMLResponse)
