import React from 'react'
import styled from 'styled-components'

import MutationForm from '../MutationForm/MutationForm'

export const CreateSimulationFormFieldInfo = styled.span`
  color: ${({ theme }) => theme.primary};
  font-style: italic;
`

// This is a stub as MutationForm has been updated to allow mutation less use
// The previous version of this component extended MutationForm to modify its onSubmit handler
const CreateSimulationForm = React.forwardRef((props, ref) => (
  <MutationForm ref={ref} {...props} disableSubmitOnEnter disableSubmitIfInvalid={false} />
))

export default CreateSimulationForm
