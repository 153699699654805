import _isFunction from 'lodash/isFunction'

const addDelay = async ({ start = Date.now(), delay = 800, action = () => {}, complete, failure }) => {
  let result
  let error
  try {
    result = await action()
  } catch (e) {
    error = e
  }

  return new Promise((resolve, reject) => {
    const mutationTime = Date.now() - start
    const finish = () => {
      if (error && _isFunction(failure)) {
        failure(error)
        resolve()
      } else if (error) {
        reject(error)
      } else if (_isFunction(complete)) {
        complete(result)
        resolve()
      } else {
        resolve(result)
      }
    }
    if (mutationTime >= delay) {
      finish()
    } else {
      setTimeout(() => finish(), delay - mutationTime)
    }
  })
}

export default addDelay
