export const FREQUENCY_OPTIONS = [
  'month',
  'quarter',
  'year'
]
export const REPORT_PERIOD_TYPES = ['custom', ...FREQUENCY_OPTIONS]

export const REPORT_TYPE_CONTENT_VIEW_ROUTE_ID_MAP = {
  performanceReport: 'PERFORMANCE_REPORT_CONTENT_VIEW',
  breachReport: 'BREACH_REPORT_CONTENT_VIEW',
  domainScanReport: 'DOMAIN_SCAN_REPORT_CONTENT_VIEW'
}
