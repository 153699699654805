import React, { useState, useCallback, useMemo } from 'react'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import { Button, SlateComponents } from '../common/SlateComponents'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { Upload, message, Form, Select, Button as AntButton, Dropdown, Menu, Icon } from 'antd'
import _get from 'lodash/get'
// import { CompactPicker } from 'react-color'

import CompactPicker from './CustomCompactColorPicker'
import MutationFormEmailBodyPreview, { emailContentStyle } from './MutationFormEmailBodyPreview'
import { apiUrl } from '../../apollo-client/common'
import { getSessionToken } from '../../helpers/session'

const { Option } = Select
const trOpt = { scope: 'mutationForm.mutationFormEmailBody' }
const editorTrOpt = { scope: 'common.slateEditor' }

const EditorContainer = styled(Editor)`
  min-height: 200px;

  ${emailContentStyle}
`

const DEFAULT_NODE = 'paragraph'
const ALIGN_BLOCK_TYPES = ['align-left', 'align-center', 'align-right', 'align-justify']
const WRAP_BLOCK_TYPES = [...ALIGN_BLOCK_TYPES, 'bulleted-list', 'numbered-list']

const EditorPreview = styled.div`
  background-color: #f4f4f4;
  padding: 1.15rem;
  margin-bottom: 15px;
`

function insertImage (editor, src, target) {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'image',
    data: { src }
  })
}

const schema = {
  blocks: {
    image: {
      isVoid: true
    }
  }
}

const initialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: ''
          }
        ]
      }
    ]
  }
})

const PickerPopover = styled.div`
  top: 30px;
  position: absolute;
  z-index: 2;
`
const PickerCover = styled.div`
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
`

const PickerButtonIcon = styled.i`
  color: ${({ color }) => color || 'inherit'};
`

const ColorPickerMarkButton = ({ type, active, color, icon, onClick = () => {}, onChange = () => {} }) => {
  const [showPicker, updateShowPicker] = useState(false)
  const onMouseDown = useCallback(event => {
    event.preventDefault()
    updateShowPicker(true)
    onClick(type)
  }, [onClick, type])
  const onChangeComplete = useCallback(color => onChange(type, color.hex), [type, onChange])
  const closePicker = () => updateShowPicker(false)

  return (
    <div>
      <Button
        active={active || showPicker}
        onMouseDown={onMouseDown}
      >
        <PickerButtonIcon className={`fa fa-${icon}`} color={color} />
      </Button>
      {
        showPicker ? (
          <PickerPopover>
            <PickerCover onClick={closePicker} />
            <CompactPicker
              styles={{ default: { compact: { width: '140px' }, wrap: { transform: 'translateX(-50%)' } } }}
              colors={[
                '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
                '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
              ]}
              color={color} onChangeComplete={onChangeComplete}
            />
          </PickerPopover>
        ) : null
      }
    </div>
  )
}

const StyleBlockDropdown = ({ types = [], activeType = 'reset-block', onSelect: onSelectProp = () => {} }) => {
  const onSelect = useCallback(type => onSelectProp(type), [onSelectProp])
  const options = useMemo(() => types.map(({ value, label, element }) => (
    <Option value={value} key={value}>
      {element ? React.createElement(element, null, label) : label}
    </Option>
  )), [types])

  return (
    <div>
      <Select value={activeType} onSelect={onSelect} style={{ width: 140 }}>
        {options}
      </Select>
    </div>
  )
}

const VariableMenuItem = ({ variable, onClick: onClickProp = () => {} }) => {
  const onClick = useCallback(() => onClickProp(variable), [onClickProp, variable])
  return (
    <span onClick={onClick}>{variable}</span>
  )
}

class MutationFormEmailBody extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value ? Value.fromJSON(props.value) : initialValue,
      selectionOnAction: null
    }

    this.handleBlockType = this.handleBlockType.bind(this)
    this.handleVariableClick = this.handleVariableClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onClickBlock = this.onClickBlock.bind(this)
    this.onClickImage = this.onClickImage.bind(this)
    this.onClickMark = this.onClickMark.bind(this)
    this.handleColorMarkClick = this.handleColorMarkClick.bind(this)
    this.handleColorMarkChange = this.handleColorMarkChange.bind(this)
    this.handleImageUpload = this.handleImageUpload.bind(this)
    this.ref = this.ref.bind(this)
    this.renderBlock = this.renderBlock.bind(this)
    this.renderMark = this.renderMark.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  renderMarkButton (type, icon) {
    const isActive = this.hasMark(type)

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickMark(event, type)}
      >
        <i className={`fa fa-${icon}`} />
      </Button>
    )
  }

  renderColorMarkButton (type, icon) {
    const isActive = this.hasMark(type)
    // Get mark and set icon font color to match data
    let color
    if (isActive) {
      const typeMark = _get(this.state, 'value.activeMarks', []).find(mark => mark.type === type)
      color = typeMark ? typeMark.data.get('color') : undefined
    }

    return (
      <ColorPickerMarkButton
        active={isActive}
        {...{ type, icon, color }}
        onClick={this.handleColorMarkClick}
        onChange={this.handleColorMarkChange}
      />
    )
  }

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasMark (type) {
    const { value } = this.state
    return value.activeMarks.some(mark => mark.type === type)
  }

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasBlock (type) {
    const { value } = this.state
    return value.blocks.some(node => node.type === type)
  }

  /**
   * Store a reference to the `editor`.
   *
   * @param {Editor} editor
   */

  ref (editor) {
    this.editor = editor
  }

  handleChange ({ value }) {
    this.setState({ value })
    this.props.onChange(this.props.id, value)
  }

  refresh () {
    const { value } = this.props
    this.setState({ value: value ? Value.fromJSON(value) : initialValue })
  }

  renderBlock (props, editor, next) {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'paragraph':
        return <p {...attributes}>{children}</p>
      case 'quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
      case 'heading-three':
        return <h3 {...attributes}>{children}</h3>
      case 'heading-four':
        return <h4 {...attributes}>{children}</h4>
      case 'heading-five':
        return <h5 {...attributes}>{children}</h5>
      case 'heading-six':
        return <h6 {...attributes}>{children}</h6>
      case 'list-item':
      case 'list-item-child':
        return <li {...attributes}>{children}</li>
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      case 'image': {
        const src = node.data.get('src')
        return <img {...attributes} src={src} alt='' />
      }
      case 'align-left':
      case 'align-center':
      case 'align-right':
      case 'align-justify': {
        const textAlign = node.type.replace('align-', '')
        return <div {...attributes} style={{ textAlign }}>{children}</div>
      }
      default:
        return next()
    }
  }

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderMark (props, editor, next) {
    const { children, mark, attributes } = props
    const { type, data = new Map() } = mark || {}

    switch (type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>
      case 'italic':
        return <em {...attributes}>{children}</em>
      case 'underlined':
        return <u {...attributes}>{children}</u>
      case 'strikethrough':
        return <span style={{ textDecoration: 'line-through' }} {...attributes}>{children}</span>
      case 'background':
      case 'color': {
        const color = data.get('color')
        const colorProp = type === 'background' ? 'backgroundColor' : 'color'
        return color ? <span style={{ [colorProp]: color }} {...attributes}>{children}</span> : null
      }
      default:
        return next()
    }
  }

  /**
   * Render a block-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderBlockButton (type, icon) {
    let isActive = this.hasBlock(type) || this.isWrappedIn(type)
    // align-left should be active unless block is wrapped in another align type
    if (type === 'align-left' && !this.isWrappedIn(ALIGN_BLOCK_TYPES)) {
      isActive = true
    }

    let iconComponent = null
    if (icon) {
      iconComponent = <i className={`fa fa-${icon}`} />
    } else if (type === 'heading-one') {
      // TODO Find an actual icon
      iconComponent = <span>H1</span>
    } else if (type === 'heading-two') {
      // TODO Find an actual icon
      iconComponent = <span>H2</span>
    }

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickBlock(event, type)}
      >
        {iconComponent}
      </Button>
    )
  }

  /**
   * Render a dropdown-toggling toolbar button.
   *
   * @return {Element}
   */

  renderStyleDropdown () {
    const styleTypes = [
      { value: 'heading-one', label: I18n.t('heading1', editorTrOpt), element: 'h1' },
      { value: 'heading-two', label: I18n.t('heading2', editorTrOpt), element: 'h2' },
      { value: 'heading-three', label: I18n.t('heading3', editorTrOpt), element: 'h3' },
      { value: 'heading-four', label: I18n.t('heading4', editorTrOpt), element: 'h4' },
      { value: 'heading-five', label: I18n.t('heading5', editorTrOpt), element: 'h5' },
      { value: 'heading-six', label: I18n.t('heading6', editorTrOpt), element: 'h6' },
      { value: 'reset-block', label: I18n.t('normal', editorTrOpt) }
    ]
    const { value: activeType = 'reset-block' } = styleTypes.find(({ value: type }) => this.hasBlock(type)) || 'reset-block'

    return (
      <StyleBlockDropdown types={styleTypes} activeType={activeType} onSelect={this.handleBlockType} />
    )
  }

  /**
   * Renders a dropdown for inserting variable text
   */
  renderVariableDropdown () {
    const menu = (
      <Menu>
        {
          ['%first_name%', '%last_name%', '%full_name%', I18n.t('uPhish.emailTemplateForm.contentMergeTags.link')].map(variable => (
            <Menu.Item key={variable}>
              <VariableMenuItem variable={variable} onClick={this.handleVariableClick} />
            </Menu.Item>
          ))
        }
      </Menu>
    )

    return (
      <Dropdown overlay={menu} placement='bottomLeft'>
        <AntButton>{I18n.t('mutationForm.common.insertVariable')} <Icon type='down' /></AntButton>
      </Dropdown>
    )
  }

  handleVariableClick (text) {
    this.insertText(text)
  }

  insertText (text) {
    this.editor.insertText(text)
  }

  /**
   * When a mark button is clicked, toggle the current mark.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickMark (event, type) {
    event.preventDefault()
    this.editor.toggleMark(type)
  }

  handleColorMarkClick () {
    const selection = _get(this.editor, 'value.selection')
    if (selection) {
      // Record current selection for reinstatement upon color selection
      this.selectionOnAction = selection.toJSON()
    }
  }

  removeMark (type) {
    this.editor.value.marks
      .filter(mark => mark.type === type)
      .forEach(mark => this.editor.removeMark(mark))
  }

  handleColorMarkChange (type, color) {
    this.removeMark(type)
    this.editor.addMark({
      type,
      data: {
        color
      }
    })
    if (this.selectionOnAction) {
      // Reinstate selection
      // TODO Revisit this as it doesn't always work
      this.editor.select(this.selectionOnAction)
      delete this.selectionOnAction
    }
  }

  isWrappedIn (types) {
    const document = _get(this, 'editor.value.document')
    const blocks = _get(this, 'editor.value.blocks')
    let isWrapped = false

    types = Array.isArray(types) ? types : [types]

    if (document && blocks) {
      isWrapped = blocks.some(node => {
        const parent = document.getParent(node.key)
        return parent && types.includes(parent.type)
      })
    }

    return isWrapped
  }

  /**
   * Unwraps the types provided from a block if the parent matches them
   * @param {String[]} types
   */
  unwrapBlock (types = [], selectedOnly = false) {
    if (!(types && types.length > 0)) {
      return
    }

    const { editor } = this
    const { value } = editor
    const { blocks, document, selection } = value
    blocks
      .reduce((wrapTypes, node) => {
        const parent = document.getParent(node.key)
        if (parent) {
          if (types.includes(parent.type) &&
            !wrapTypes.includes(parent.type)) {
            wrapTypes.push(parent.type)
          }
        }
        return wrapTypes
      }, [])
      .forEach(type => selectedOnly ? editor.unwrapBlockAtRange(selection, type) : editor.unwrapBlock(type))
  }

  /**
   * When a block button is clicked, toggle the block type.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickBlock (event, type) {
    event.preventDefault()
    this.handleBlockType(type)
  }

  handleBlockType (type) {
    const { editor } = this
    const { value } = editor
    const { document, selection } = value

    if (type === 'image') {
      return this.onClickImage()
    }

    if (type === 'remove-format' || type === 'reset-block') {
      // Remove formatting
      // Reset selected block to default node
      editor.setBlocksAtRange(selection, DEFAULT_NODE)
      if (type === 'remove-format') {
        // Clear marks
        value.marks.forEach(mark => editor.removeMark(mark))
        // Remove Alignment and list wrappings
        this.unwrapBlock(WRAP_BLOCK_TYPES, true)
      }
    } else if (type.indexOf('align-') === 0) {
      const isActive = this.isWrappedIn(type)
      if (!isActive) {
        this.unwrapBlock(ALIGN_BLOCK_TYPES)
        editor.wrapBlock(type)
      }
    } else if (type !== 'bulleted-list' && type !== 'numbered-list') {
      // Handle everything but list buttons.
      const isActive = this.hasBlock(type)
      const isList = this.hasBlock('list-item')

      if (isList) {
        editor.setBlocks(isActive ? DEFAULT_NODE : type).unwrapBlock('bulleted-list').unwrapBlock('numbered-list')
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item')
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor.setBlocks(DEFAULT_NODE).unwrapBlock('bulleted-list').unwrapBlock('numbered-list')
      } else if (isList) {
        editor.unwrapBlock(
          type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
        ).wrapBlock(type)
      } else {
        editor.setBlocks('list-item').wrapBlock(type)
      }
    }
  }

  onClickImage () {
    document.getElementById('courseWysiwyg').click()
  }

  handleImageUpload (info) {
    if (info.file.status === 'done') {
      message.success(I18n.t('imageUploadSuccessMessage', { ...editorTrOpt, file: info.file.name }))
      this.editor.command(insertImage, info.file.response.url)
    } else if (info.file.status === 'error') {
      message.error(I18n.t('imageUploadErrorMessage', { ...editorTrOpt, file: info.file.name }))
    }
  }

  // JG: Slate was ignoring text is entered with an input method editor. Here we are listening for the compositionend event and forcing slate to insert the text at the current cursor location.
  handleCompositionEnd (event, editor, next) {
    editor.insertText(event.data)
    next()
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    // TODO action shouldn't be hardcoded
    return (
      <>
        <Form.Item label={this.props.label} required={this.props.required}>
          <Upload name='courseWysiwyg' onChange={this.handleImageUpload} style={{ display: 'none' }} showUploadList={false} action={`${apiUrl}/upload-course-wysiwyg`} headers={{ 'x-token': getSessionToken() }}>
            <Button id='courseWysiwyg' />
          </Upload>
          <SlateComponents>
            {this.renderVariableDropdown()}
            {this.renderMarkButton('bold', 'bold')}
            {this.renderMarkButton('italic', 'italic')}
            {this.renderMarkButton('underlined', 'underline')}
            {this.renderMarkButton('strikethrough', 'strikethrough')}
            {/* TODO Heading icons */}
            {this.renderBlockButton('heading-one')}
            {this.renderBlockButton('heading-two')}
            {this.renderBlockButton('block-quote', 'quote-left')}
            {this.renderBlockButton('numbered-list', 'list-ol')}
            {this.renderBlockButton('bulleted-list', 'list-ul')}
            {this.renderBlockButton('image', 'image')}
            {this.renderStyleDropdown()}
            {this.renderColorMarkButton('color', 'eye-dropper')}
            {this.renderColorMarkButton('background', 'highlighter')}
            {this.renderBlockButton('align-left', 'align-left')}
            {this.renderBlockButton('align-center', 'align-center')}
            {this.renderBlockButton('align-right', 'align-right')}
            {this.renderBlockButton('align-justify', 'align-justify')}
            {this.renderBlockButton('remove-format', 'remove-format')}
          </SlateComponents>
          <EditorContainer
            value={this.state.value} onChange={this.handleChange} ref={this.ref} renderBlock={this.renderBlock}
            renderMark={this.renderMark} schema={schema} onCompositionEnd={this.handleCompositionEnd}
          />
        </Form.Item>
        <EditorPreview>
          <h4>{I18n.t('emailPreview', trOpt)}</h4>
          <MutationFormEmailBodyPreview content={this.state.value.toJSON()} />
        </EditorPreview>
      </>
    )
  }
}

export default MutationFormEmailBody
