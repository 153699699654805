import React from 'react'
import styled from 'styled-components'
import { getSeverityDetail } from '../../helpers/uBreachV2'

const Severity = styled.div`
  ::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color};
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block
  }
`

const SeverityField = ({ severity }) => {
  const { color, label } = getSeverityDetail(severity) || {}
  if (!color || !label) return null

  return <Severity {...{ color }}>{label}</Severity>
}

export default SeverityField
