import I18n from 'i18n-js'

import SettingsForm from './SettingsForm'
import { withConsumer } from '../../hocs'
import { GET_SUPPORTED_DOMAINS } from '../Queries/Companies'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const trOpt = { scope: 'settings.preferredDomain' }

class PreferredDomain extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'appDomain'
    this.defaultValue = 'usecure.io'
    this.setInitialFormValuesOnMount = true

    this.state = {
      supportedDomains: []
    }
  }

  get headerId () {
    return 'settings-preferred-domain-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    return [
      {
        id: DEFAULT_LANGUAGE, // Sets company.settings.appDomain.en
        type: 'select',
        label: I18n.t('preferredDomainField', trOpt),
        formItemStyle: { maxWidth: 250 },
        options: this.state.supportedDomains.map(domain => ({ value: domain, label: domain })),
        defaultValue: this.defaultValue
      }
    ]
  }

  async componentDidMount () {
    const { data: { supportedDomains = [] } = {} } = await this.props.client.query({ query: GET_SUPPORTED_DOMAINS })
    await this.updateState({ supportedDomains })
    await super.componentDidMount()
  }
}

export default withConsumer(PreferredDomain)
