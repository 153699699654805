import React from 'react'
import { Popover, Tag } from 'antd'
import styled, { withTheme } from 'styled-components'
import I18n from 'i18n-js'

import { PolicyVersionTag } from './ViewPolicyVersionField'
import RiskScoreIndicator from '../../components/Learners/RiskScoreIndicator'
import TooltipIcon from '../common/TooltipIcon'

const viewTrOpt = { scope: 'uPolicy.viewPolicyUsers' }
const trOpt = { scope: `${viewTrOpt.scope}.statusInfo` }

const StatusTitleCell = styled.div`
  width: 60px;

  .anticon {
    color: #bfbfbf;
    font-size: 12px;
    margin-left: 5px;
  }

  h4 {
    font-size: 3px;
  }
`

const StatusInfoContainer = styled.div`
  margin-bottom: 10px;
`

const RagInfoAll = () => (
  <StatusInfoContainer>
    <h3>{I18n.t('title', trOpt)}</h3>
    <h4>
      <RiskScoreIndicator text={I18n.t('common.notSent')} value='transparent' />
    </h4>
    <p>{I18n.t('all.notSent', trOpt)}</p>
    <h4>
      <RiskScoreIndicator text={I18n.t('common.pending')} value='red' />
    </h4>
    <p>{I18n.t('common.pending', trOpt)}</p>
    <h4>
      <RiskScoreIndicator text={I18n.t('common.visited')} value='amber' />
    </h4>
    <p>{I18n.t('common.visited', trOpt)}</p>
    <h4>
      <RiskScoreIndicator text={I18n.t('uPolicy.common.signed')} value='green' />
    </h4>
    <p>{I18n.t('common.signed', trOpt)}</p>
  </StatusInfoContainer>
)

const RagInfoVersion = () => (
  <StatusInfoContainer>
    <h3>{I18n.t('title', trOpt)}</h3>
    <h4>
      <RiskScoreIndicator text={I18n.t('common.notSent')} value='transparent' />
    </h4>
    <p>{I18n.t('version.notSent', trOpt)}</p>
    <h4>
      <RiskScoreIndicator text={I18n.t('uPolicy.common.signed')} value='green' />
    </h4>
    <p>{I18n.t('version.signed', trOpt)}</p>
  </StatusInfoContainer>
)

const _StatusText = withTheme(({ className, status, theme }) => (
  <div {...{ className }}>
    {status === 'all' && <RagInfoAll />}
    {status === 'version' && <RagInfoVersion />}
    <>
      <h3>{I18n.t('signedManually', trOpt)} <Tag key='offline' color={theme.orange}>{I18n.t('manual', viewTrOpt)}</Tag></h3>
      <p>{I18n.t('signedManuallyCopy', trOpt)}</p>
    </>
    {status !== 'version' && (
      <>
        <h3>{I18n.t('versionNumberTag', trOpt)} <PolicyVersionTag versionNo='vX.X' /></h3>
        <p>{I18n.t('versionNumberTagCopy', trOpt)}</p>
      </>
    )}
  </div>
))
const StatusText = styled(_StatusText)`
  display: inline-block;
  width: 600px;

  h3 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  h4 {
    font-size: 14px;
    margin-bottom: 0;
  }
  p {
    font-family: ${props => props.theme.baseFont};
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const StatusTitle = ({ status, theme }) => (
  <StatusTitleCell>
    <span>{I18n.t('common.status')}</span>
    <Popover
      content={<StatusText status={status} />}
    >
      <span><TooltipIcon /></span>
    </Popover>
  </StatusTitleCell>
)
// AntD Table Columns name can't react components hence the multiple functions
export const StatusTitleAll = () => <StatusTitle status='all' />
export const StatusTitleVersion = () => <StatusTitle status='version' />
