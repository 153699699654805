import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Card, message } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isString from 'lodash/isString'

import { GET_SIMULATION_FOR_UPDATE } from '../../components/Queries/uPhish'
import CreateSimulation from './CreateSimulation'
import { LoadingBlock, ErrorAlerts } from '../../components/common'
import routes from '../../constants/routes'
import { getUpdateSimulationProps } from '../../helpers/uPhish'

const trOpt = { scope: 'uPhish.updateSimulation' }

const UpdateSimulation = ({ pageId = 'updateSimulation', title = I18n.t('uPhish.common.updateSimulation'), clone = false, update = true, ...props }) => {
  const id = _get(props, 'match.params.simulation_id')
  const { loading, error, data: { simulation: rawSimulation = {} } = {} } = useQuery(GET_SIMULATION_FOR_UPDATE, {
    variables: { id },
    skip: !id
  })

  // If status isn't pending redirect to simulations page
  const { history } = props
  useEffect(() => {
    if (update) {
      const status = _get(rawSimulation, 'status')
      if (_isString(status) && status !== 'pending') {
        message.error(I18n.t('thisSimulationCanNoLongerBeEdited', trOpt))
        history.push(routes.UPHISH_SIMS)
      }
    }
  }, [rawSimulation, history, update])

  const { name } = rawSimulation || {}
  const pageTitle = `${title}${update ? ` - ${name}` : ''}`

  if (loading) return <LoadingBlock fullScreen loading={loading} />
  if (error) {
    return (
      <Card>
        <h1>{I18n.t('common.uPhish')} - {title}</h1>
        <ErrorAlerts {...{ error }} defaultError={I18n.t('uPhish.common.errors.thisSimulationCouldNotBeLoaded')} />
      </Card>
    )
  }

  return (
    <CreateSimulation
      simulationId={id}
      id={`uPhish-${!update ? 'clone' : 'update'}-simulation-header`}
      title={pageTitle}
      {...{ update, clone, pageId }}
      {...getUpdateSimulationProps(rawSimulation, clone && !update ? 'clone' : 'update')}
      {...props}
    />
  )
}

export default withRouter(UpdateSimulation)
