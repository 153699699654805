import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Badge, Tag } from 'antd'
import I18n from 'i18n-js'

import { CONTENT_TYPE_NAMES } from './common'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const CourseContentEditorSlideWrap = styled.div`
    background-color: ${({ theme, dragging }) => dragging ? '#d5e9f1' : theme.white};
    margin-bottom: 10px;
    padding: 10px 0;
    position: relative;
    top: -10px;
`

const CourseContentEditorSlideHeaderWrap = styled.div`
    display: flex;
`

const CourseContentEditorSlideDetailWrap = styled.div`
    flex: 1;
`

const SlideNumType = styled.div`
  margin-bottom: 5px;
`

const SlideType = styled.span`
  font-size: 16px;
  font-weight: bold;
  vertical-align: top;
`

const SlideNumber = styled(Badge)`
  margin-right: 5px;

  .ant-badge-count {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};
  }
`

const SlideId = styled.div`
  .ant-tag {
    margin-left: 5px;
  }
`

const CourseContentEditorSlide = ({ item: slide, index, dragging, locale, theme }) => {
  const { slideId, title, type } = slide
  const slideNumber = index + 1
  const slideType = CONTENT_TYPE_NAMES[type] || '<TYPE_NAME>'

  return (
    <CourseContentEditorSlideWrap dragging={dragging}>
      <CourseContentEditorSlideHeaderWrap>
        <CourseContentEditorSlideDetailWrap>
          <SlideNumType>
            <SlideNumber count={slideNumber} />
            <SlideType>{slideType}</SlideType>
          </SlideNumType>
          <span>{title}</span>
        </CourseContentEditorSlideDetailWrap>
        <SlideId>
          {I18n.t('editCourse.editCourseChecklistItem.slideId')}
          <Tag>{slideId}</Tag>
        </SlideId>
      </CourseContentEditorSlideHeaderWrap>
    </CourseContentEditorSlideWrap>
  )
}

CourseContentEditorSlide.propTypes = {
  courseId: PropTypes.string,
  dragging: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  duplicate: PropTypes.func,
  index: PropTypes.number,
  locale: PropTypes.string,
  moveDown: PropTypes.func,
  moveUp: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string
  }),
  remove: PropTypes.func
}

CourseContentEditorSlide.defaultProps = {
  courseId: null,
  dragging: false,
  dragHandleProps: {},
  duplicate: () => { },
  index: -1,
  locale: DEFAULT_LANGUAGE,
  moveDown: () => { },
  moveUp: () => { },
  item: {
    id: null,
    type: 'material',
    title: null,
    content: null
  },
  remove: () => { }
}

export default CourseContentEditorSlide
