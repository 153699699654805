import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'
import { Table } from 'antd'
import DataIterator from './DataIterator'
import _debounce from 'lodash/debounce'
import { processTableData } from '../../helpers'

const EndUserTable = ({ tableData, columns, tableProps, dataIteratorProps, searchFilterText, trOpt }) => {
  const { updatePagination, updateSorter, updateFilters, sortBy = [], pagination } = tableProps
  const [localTableData, setTableData] = useState(tableData)
  const { type: screenTypeClass } = useWindowSize()
  const isMobile = ['xs', 'sm'].includes(screenTypeClass)

  const dataIteratorData = useMemo(() => {
    return processTableData(columns, localTableData)
  }, [localTableData, columns])

  const applyLearnerFilters = useCallback(searchFilterText => {
    let localFunctionData = [...tableData]
    if (searchFilterText) {
      const filterFields = sortBy
      localFunctionData = localFunctionData.filter(resultObj =>
        filterFields.some(field => {
          const value = resultObj[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }
    setTableData(localFunctionData)
  }, [tableData, sortBy])

  const debouncedApplyLearnerFilters = useCallback(_debounce(applyLearnerFilters, 500), [applyLearnerFilters])

  useEffect(() => {
    debouncedApplyLearnerFilters(searchFilterText)
  }, [debouncedApplyLearnerFilters, searchFilterText])

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    updatePagination(pagination)
    updateFilters(filters)
    updateSorter(sorter)
  }, [updatePagination, updateSorter, updateFilters])

  const tableProperties = {
    rowKey: 'id',
    dataSource: localTableData,
    columns: columns,
    onChange: handleTableChange,
    pagination: pagination
  }
  const dataIteratorProperties = {
    ...dataIteratorProps,
    items: dataIteratorData,
    trOpt: trOpt
  }
  return (
    <>
      {!isMobile ? (
        <Table {...tableProperties} />
      ) : (
        <DataIterator {...dataIteratorProperties} />
      )}
    </>
  )
}

export default EndUserTable
