import React, { useCallback, useRef } from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'

import SendTestSimulationEmailConfirm from '../Modals/SendTestSimulationEmailConfirm'

const SendTestEmailButton = ({ disabled, variables }) => {
  const sendTestModalRef = useRef(null)
  const onClick = useCallback(() => {
    if (sendTestModalRef.current) {
      sendTestModalRef.current.open({ variables })
    }
  }, [sendTestModalRef, variables])

  return (
    <>
      <Button icon='mail' disabled={disabled} onClick={onClick}>{I18n.t('uPhish.common.sendTestEmail')}</Button>
      <SendTestSimulationEmailConfirm ref={sendTestModalRef} />
    </>
  )
}

export default SendTestEmailButton
