import React, { useCallback, useState, useMemo, useImperativeHandle, useEffect, useRef } from 'react'
import { Button, Icon, Modal, message, Form, Input } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'
import _isArray from 'lodash/isArray'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'

import { showErrors } from '../../helpers'

const actionTrOpt = { scope: 'modals.actionConfirm' }

const ConfirmModalWrap = styled.div`
  overflow: hidden;
`

const ConfirmModalTitle = styled.span`
  color: rgba(0,0,0,0.85);
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  overflow: hidden;
`

const ConfirmModalContent = styled.div`
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  margin-left: 38px;
  margin-top: 8px;

  ul {
    padding-left: 20px;
  }
`

const ConfirmModalIcon = styled(Icon)`
  color: #faad14;
  float: left;
  font-size: 22px;
  margin-right: 16px;
`

const ConfirmModalFooter = styled.div`
  float: right;
  margin-top: 24px;

  & > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const ActionConfirm = React.forwardRef(({
  visible: visibleProp, setVisible: setVisibleProp, mutation, ids: idsProp, variableName = 'ids', variables = {}, refetchQueries,
  update,
  title, body: bodyProp, label, successMessage, errorMessage, trOpt = actionTrOpt, processResult, skipDefaultErrorForExceptions = false,
  onClose = () => {}
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [ids, setIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [executeMutation] = useMutation(mutation, {
    variables: variableName ? { [variableName]: ids, ...variables } : variables,
    refetchQueries,
    update
  })

  // This components supports both external state management and being opened through a ref
  // These effects transfers the external state to internal state via props
  useEffect(() => {
    if (_isBoolean(visibleProp)) {
      setVisible(visibleProp)
    }
  }, [visibleProp])
  useEffect(() => {
    if (_isArray(idsProp)) {
      setIds(idsProp)
    }
  }, [idsProp])

  const updateVisible = useCallback(visible => {
    if (_isFunction(setVisibleProp)) setVisibleProp(visible)
    setVisible(visible)
  }, [setVisibleProp])

  useImperativeHandle(ref, () => ({
    open: ids => {
      setIds(ids)
      updateVisible(true)
    }
  }), [updateVisible])

  const [toAction, setToAction] = useState('')
  const valid = useMemo(() => {
    return ids.length > 0 && toAction.trim() === `${ids.length}`
  }, [ids, toAction])
  const onChange = useCallback(event => {
    const { value } = event.target
    setToAction(value)
  }, [setToAction])
  const afterClose = useCallback(() => {
    setToAction('')
    setLoading(false)
    // Reset ids unless managed externally
    if (!_isArray(idsProp)) {
      setIds([])
    }
    updateVisible(false)
    onClose()
  }, [updateVisible, idsProp, onClose])
  const onCancel = useCallback(() => updateVisible(false), [updateVisible])
  const onOk = useCallback(async () => {
    if (!valid || loading) {
      return
    }

    let success = false
    let error
    try {
      setLoading(true)
      const result = await executeMutation()
      if (_isFunction(processResult)) {
        const outcome = await processResult({ result, ids })
        success = _isBoolean(outcome) ? outcome : true
      } else {
        success = true
      }

      if (success) {
        updateVisible(false)
        const successMessageText = _isFunction(successMessage) ? successMessage(ids.length, result)
          : (successMessage ||
            I18n.t('successMessage', {
              ...trOpt, count: ids.length, defaultValue: I18n.t('successMessage', { ...actionTrOpt, count: ids.length })
            })
          )
        if (successMessageText) {
          message.success(successMessageText)
        }
      }
    } catch (e) {
      error = e
    }
    if (!success || error) {
      const errorMessageText = _isFunction(errorMessage) ? errorMessage(ids.length)
        : (errorMessage ||
          I18n.t('errorMessage', {
            ...trOpt, count: ids.length, defaultValue: I18n.t('errorMessage', { ...actionTrOpt, count: ids.length })
          })
        )
      showErrors(error || errorMessageText, errorMessageText, {
        processor: function () {
          // Always include default message if there are errors present unless they came from the client and skipDefaultErrorForExceptions is true
          if (!_isEmpty(this.errors) && !(skipDefaultErrorForExceptions && this.errors.every(e => e.isUsecureClient))) {
            this.errors.unshift(errorMessageText)
          }
        }
      })
    }
    setLoading(false)
  }, [
    executeMutation, ids, updateVisible, valid, loading, successMessage, errorMessage, trOpt, processResult, skipDefaultErrorForExceptions
  ])
  const onKeyUp = useCallback(event => {
    if (event.keyCode === 13) {
      onOk()
    }
  }, [onOk])

  const titleText = _isFunction(title) ? title(ids.length)
    : (title ||
      I18n.t('title', {
        ...trOpt, count: ids.length, defaultValue: I18n.t('title', { ...actionTrOpt, count: ids.length })
      })
    )
  const labelText = _isFunction(label) ? label(ids.length)
    : (label ||
      I18n.t('label', { ...trOpt, defaultValue: I18n.t('label', actionTrOpt) })
    )

  const inputRef = useRef(null)
  useEffect(() => {
    if (visible && inputRef.current) {
      inputRef.current.focus()
    } else if (visible) {
      // Allow 500ms for input to render
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 500)
    }
  }, [visible, inputRef])

  const body = _isFunction(bodyProp) ? bodyProp(ids.length) : bodyProp

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      maskClosable={false}
      destroyOnClose
      afterClose={afterClose}
    >
      <ConfirmModalWrap>
        <ConfirmModalIcon type='question-circle' />
        <ConfirmModalTitle>{titleText}</ConfirmModalTitle>
        <ConfirmModalContent>
          {body}
          <Form.Item
            label={labelText}
            validateStatus={valid ? 'success' : undefined}
          >
            <Input ref={inputRef} size='large' value={toAction} placeholder={ids.length} onChange={onChange} onKeyUp={onKeyUp} disabled={loading} />
          </Form.Item>
        </ConfirmModalContent>
        <ConfirmModalFooter>
          <Button disabled={loading} onClick={onCancel}>{I18n.t('common.no')}</Button>
          <Button type='primary' loading={loading} disabled={!valid} onClick={onOk}>{I18n.t('common.yes')}</Button>
        </ConfirmModalFooter>
      </ConfirmModalWrap>
    </Modal>
  )
})

export default ActionConfirm
