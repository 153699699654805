import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'

import MutationForm from '../MutationForm/MutationForm'
import { GET_COMPANY_FOR_UPGRADE_TRIAL, UPGRADE_TRIAL } from '../Queries/Companies'
import { withConsumer } from '../../hocs/withConsumer'
import { LoadingBlock } from '../common'

const trOpt = { scope: 'modals.upgradeTrialModal' }

const EditModal = styled(Modal)`
  max-width: 800px;
  top: 50px;
`

const UpgradeTrialModal = React.forwardRef(({ client, refetchQueries = [], onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [parentCompanyId, setParentCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [companyPlan, setCompanyPlan] = useState(null)
  const [companySettings, setCompanySettings] = useState(null)

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async (companyId) => {
      setVisible(true)
      setLoading(true)
      const result = await client.query({
        query: GET_COMPANY_FOR_UPGRADE_TRIAL,
        variables: { id: companyId }
      })
      const company = _get(result, 'data.company')
      const { id, name, plan, settings, parentCompanyId } = company
      setCompanyId(id)
      setCompanyName(name)
      setCompanyPlan(plan)
      setCompanySettings(settings)
      setParentCompanyId(parentCompanyId)
      setLoading(false)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setCompanyId(null)
    setCompanyName(null)
    setCompanyPlan(null)
    setCompanySettings(null)
    setParentCompanyId(null)
    resetForm()
    onClose()
  }

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  let title = I18n.t('title', trOpt)
  if (companyName) {
    title = I18n.t('titleName', { ...trOpt, name: companyName })
  } else {
    title = I18n.t('title', trOpt)
  }

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={title}
      afterClose={afterClose}
      width='70%'
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <MutationForm
        mutation={UPGRADE_TRIAL}
        variables={{ companyId }}
        onSuccess={onSuccess}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('submitLabel', trOpt)}
        fields={[{
          id: 'domainLock',
          label: I18n.t('modals.editCompanyModal.domainLock'),
          type: 'switch',
          defaultValue: _get(companyPlan, 'domainLock', true),
          required: false
        }, {
          id: 'learnerLimit',
          label: I18n.t('modals.editCompanyModal.learnerLimit'),
          type: 'number',
          defaultValue: _get(companySettings, 'learnerLimit'),
          required: _isNil(parentCompanyId)
        }]}
        ref={form}
        refetchQueries={refetchQueries}
        skipResetFieldsOnSubmit
      />
    </EditModal>
  )
})

export default withConsumer({ useHooks: true })(UpgradeTrialModal)
