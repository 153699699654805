import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
// import FlipCardComponent from '@kennethormandy/react-flipcard'
import FlipCardComponent from './ReactFlipCardIEFix'
import { BREAKPOINT } from '../../constants/style'
import { Icon } from 'antd'

// import '@kennethormandy/react-flipcard/dist/Flipcard.css'

const trOpt = { scope: 'learnerCourse.learnerCourseCard' }

const FlipCardHolder = styled.div`
  height: 300px;
  width: 100%;

  @media (max-width: ${BREAKPOINT}) {
    height: 200px;
  }

  .Flipcard, .Flipcard-flipper {
    height: 100%;
  }
  .Flipcard-front, .Flipcard-back {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
`

const FlipCardSide = styled.div`
  align-items: center;
  border: solid 1px #d0d0d0;
  border-top: 1px solid ${({ theme }) => theme.primary};
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 5px;
  width: 100%;
`

const FlipCardTitle = styled.h4`
  text-align: center;
`

const FlipCardBackground = styled.div`
  background-image: url('${props => props.src}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
`

const FlipCardDetail = styled.div`
  font-size: 12px;
  font-style: italic;
  bottom: 0;
  position: absolute;
  right: 5%;
`

const LearnerCourseCard = ({ title, background, className }) => {
  const [flipped, setFlipped] = useState(false)
  const onClick = useCallback(() => setFlipped(!flipped), [flipped, setFlipped])

  return (
    <FlipCardHolder className={className}>
      <FlipCardComponent {...{ onClick, flipped }} type='revolving-door'>
        <FlipCardSide>
          <FlipCardBackground src={background} />
          <FlipCardDetail>{I18n.t('clickToFlip', trOpt)} <Icon type='sync' /></FlipCardDetail>
        </FlipCardSide>
        <FlipCardSide>
          <FlipCardTitle>{title}</FlipCardTitle>
          <FlipCardDetail>{I18n.t('clickToFlip', trOpt)} <Icon type='sync' /></FlipCardDetail>
        </FlipCardSide>
      </FlipCardComponent>
    </FlipCardHolder>
  )
}

export default LearnerCourseCard
