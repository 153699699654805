import React, { Component } from 'react'
import styled from 'styled-components'
import interact from 'interactjs'
import { Manager, Reference, Popper } from 'react-popper'
import { Button, Form, Input, Select } from 'antd'
import { lighten } from 'polished'
import I18n from 'i18n-js'

import { FontAwesomeIcon } from '../common'

const { TextArea } = Input
const { Option } = Select
const trOpt = { scope: 'editCourse.editCourseAnnotatedImagePoint' }

const InteractDrag = styled.div`
  background-color: ${({ theme }) => theme.primary};
  opacity: ${props => props.active ? 1 : 0.6};
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  border-radius: 50%;
  touch-action: none;
  width: 2.2vw;
  max-width: 30px;
  height: 2.2vw;
  max-height: 30px;
  position: absolute;
  cursor: move;
  border: solid 2px ${({ theme }) => lighten(0.2, theme.primary)};
`

const AntPopover = styled.div`
  transform-origin: ${props => props.origin};
  transition: opacity 0.2s, transform 0.2s;
  opacity: ${props => props.open ? 1 : 0};
  transform: ${props => `scale(${props.open ? 1 : 0})`};
`

const AntPopoverInnerContent = styled.div`
  position: relative;
`

const AntPopoverClose = styled.div`
  position: absolute;
  top: 10px;
  right: 13px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`

const AntPopoverFormItem = styled(Form.Item)`
  margin-bottom: 10px;
`

const AlignmentSelect = styled(Select)`
  width: 100%;
`
export const PLACEMENT_OPTION_KEYS = {
  T: 'top',
  TL: 'topLeft',
  TR: 'topRight',
  B: 'bottom',
  BL: 'bottomLeft',
  BR: 'bottomRight',
  L: 'left',
  LT: 'leftTop',
  LB: 'leftBottom',
  R: 'right',
  RT: 'rightTop',
  RB: 'rightBottom'
}

export const PLACEMENT_PREVIEW_MAP = {
  T: 'top',
  TL: 'top-start',
  TR: 'top-end',
  B: 'bottom',
  BL: 'bottom-start',
  BR: 'bottom-end',
  L: 'left',
  LT: 'left-start',
  LB: 'left-end',
  R: 'right',
  RT: 'right-start',
  RB: 'right-end'
}

export const ANT_PLACEMENT_CLASS_SUFFIX_MAP = {
  'top-start': 'topLeft',
  'top-end': 'topRight',
  'bottom-start': 'bottomLeft',
  'bottom-end': 'bottomRight',
  'left-start': 'leftTop',
  'left-end': 'leftBottom',
  'right-start': 'rightTop',
  'right-end': 'rightBottom'
}

class EditCourseAnnotatedImagePoint extends Component {
  constructor (props) {
    super(props)

    this.interactDrag = React.createRef()

    this.state = {
      ready: false,
      active: false,
      open: false,
      wasOpen: false,
      dragging: false,
      hover: false,
      hoverOpen: false
    }

    this.onClick = this.onClick.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onDragMove = this.onDragMove.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onPlacementChange = this.onPlacementChange.bind(this)
    this.onPopoverClick = this.onPopoverClick.bind(this)
    this.onRemoveClick = this.onRemoveClick.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
    this.renderEditorPopover = this.renderEditorPopover.bind(this)
    this.renderPreviewPopover = this.renderPreviewPopover.bind(this)
  }

  get placement () {
    const { placement = 'T' } = this.props
    return PLACEMENT_PREVIEW_MAP[placement]
  }

  onDragStart (e) {
    if (this.state.open) {
      this.setState({ dragging: true, wasOpen: true }, () => {
        setTimeout(() => this.setState({ open: false }), 200)
      })
    } else {
      this.setState({ dragging: true, wasOpen: false })
    }
    this.props.onStart(this.props.id)
  }

  onDragMove (e) {
    const { dx, dy } = e
    this.props.onUpdate(this.props.id, dx, dy)
  }

  onDragEnd () {
    if (this.state.wasOpen) {
      this.setState({ dragging: false }, () => {
        setTimeout(() => this.setState({ open: true, wasOpen: false }), 200)
      })
    } else {
      this.setState({ dragging: false })
    }
    this.props.onEnd(this.props.id)
  }

  onClick (e) {
    e.stopPropagation()
    this.props.onClick(this.props.id)
  }

  onMouseEnter () {
    this.setState({ hover: true }, () => this.setState({ hoverOpen: true }))
  }

  onMouseLeave () {
    this.setState({ hoverOpen: false }, () => {
      setTimeout(() => this.setState({ hover: false }), 200)
    })
  }

  onPopoverClick (e) {
    e.stopPropagation()
  }

  onTextChange (e) {
    this.props.onTextChange(this.props.id, e.currentTarget.value)
  }

  onPlacementChange (value) {
    this.props.onPlacementChange(this.props.id, value)
  }

  onRemoveClick () {
    this.props.onRemoveClick(this.props.id)
  }

  startInteract () {
    if (this.interactDrag && this.interactDrag.current) {
      interact(this.interactDrag.current)
        .draggable({
          onstart: this.onDragStart,
          onmove: this.onDragMove,
          onend: this.onDragEnd,
          restrict: {
            restriction: 'parent',
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
          }
        })

      this.setState({ ready: true })
    }
  }

  componentDidUpdate (prevProps) {
    const { active } = this.props
    const { active: prevActive } = prevProps

    if (active !== prevActive) {
      if (active) {
        this.setState({ active: true }, () => {
          this.setState({ open: true })
        })
      } else {
        this.setState({ open: false }, () => {
          setTimeout(() => this.setState({ active: false }), 200)
        })
      }
    }

    if (!this.state.ready) {
      this.startInteract()
    }
  }

  renderPopover (content, open = this.state.open, { ref, style, placement, arrowProps }) {
    const halfArrowSize = 6

    let transformOriginX = 'center'
    let transformOriginY = 'bottom'

    if (/^bottom/.test(placement)) {
      transformOriginY = 'top'
    }
    if (/^(top|bottom)/.test(placement)) {
      transformOriginX = `${arrowProps.style.left + halfArrowSize}px`
    }

    if (/^left/.test(placement)) {
      transformOriginX = 'right'
    }
    if (/^right/.test(placement)) {
      transformOriginX = 'left'
    }
    if (/^(left|right)/.test(placement)) {
      transformOriginY = `${arrowProps.style.top + halfArrowSize}px`
    }

    const antPlacementClassSuffix = ANT_PLACEMENT_CLASS_SUFFIX_MAP[placement] || placement

    return (
      <div ref={ref} style={{ ...style, zIndex: 100 }} data-placement={placement} onClick={this.onPopoverClick}>
        <AntPopover open={open} origin={`${transformOriginX} ${transformOriginY}`} className={`ant-popover-placement-${antPlacementClassSuffix}`}>
          <div className='ant-popover-content'>
            {/* Overriding transform on arrow .ant-popover-placement-* adds a translateX transform which isn't required */}
            <div className='ant-popover-arrow' ref={arrowProps.ref} style={{ ...arrowProps.style, transform: 'rotate(45deg)' }} />
            <div className='ant-popover-inner' role='tooltip'>
              <div>
                <AntPopoverInnerContent className='ant-popover-inner-content'>
                  {content}
                </AntPopoverInnerContent>
              </div>
            </div>
          </div>
        </AntPopover>
      </div>
    )
  }

  renderEditorPopover (popoverProps) {
    const { text = '', placement = 'top' } = this.props

    const content = (
      <>
        <AntPopoverClose onClick={this.onClick}>
          <FontAwesomeIcon icon='times' />
        </AntPopoverClose>
        <AntPopoverFormItem label={I18n.t('pointAnnotation', trOpt)}>
          <TextArea value={text} onChange={this.onTextChange} />
        </AntPopoverFormItem>
        <AntPopoverFormItem label={I18n.t('pointAnnotationPlacement', trOpt)}>
          <AlignmentSelect value={placement} onChange={this.onPlacementChange}>
            {
              Object.keys(PLACEMENT_OPTION_KEYS).map(value => (
                <Option key={`placement-${value}`} value={value}>{I18n.t(PLACEMENT_OPTION_KEYS[value], trOpt)}</Option>
              ))
            }
          </AlignmentSelect>
        </AntPopoverFormItem>
        <Button type='danger' block onClick={this.onRemoveClick}>{I18n.t('removePoint', trOpt)}</Button>
      </>
    )

    return this.renderPopover(content, this.state.open, popoverProps)
  }

  renderPreviewPopover (popoverProps) {
    const { text } = this.props

    const content = (
      <p style={{
        whiteSpace: 'pre-wrap',
        fontStyle: text && text.length > 0 ? 'normal' : 'italic'
      }}
      >{text || I18n.t('noTextAdded', trOpt)}
      </p>
    )

    return this.renderPopover(content, true, popoverProps)
  }

  render () {
    const { posX, posY, hide } = this.props
    const { active, dragging, hover } = this.state

    if (hide) {
      return null
    }

    const showEditor = active && !dragging
    const showPreview = hover && !active && !dragging

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <InteractDrag
              className='interact-drag'
              ref={el => {
                this.interactDrag.current = el
                ref(el)
              }}
              onClick={this.onClick}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              active={active || dragging || hover}
              style={{
                top: `${posY}px`,
                left: `${posX}px`
              }}
            />
          )}
        </Reference>
        {
          showEditor
            ? (
              <Popper placement='top' modifiers={{ preventOverflow: false }}>
                {this.renderEditorPopover}
              </Popper>
            )
            : null
        }
        {
          showPreview
            ? (
              <Popper placement={this.placement} modifiers={{ preventOverflow: false }}>
                {this.renderPreviewPopover}
              </Popper>
            )
            : null
        }
      </Manager>
    )
  }
}

export default EditCourseAnnotatedImagePoint
