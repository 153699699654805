import React, { useCallback, useMemo, useRef } from 'react'
import { Modal as _Modal, Alert, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import MutationForm from '../MutationForm/MutationForm'
import { ENROL_ON_COURSE, ENROL_ON_GAP_ANALYSIS } from '../Queries/Courses'
import { GET_LEARNERS } from '../Queries/Learners'
import { LoadingBlock, ErrorAlerts } from '../common'
import routes from '../../constants/routes'
import { processEnrolLearnersOnGapAnalysisResult } from '../../helpers/enrolLearnersOnGapAnalysis'
import { renderToString, invalidateLearnersAndLearnerDataQueryCache } from '../../helpers'
import ModalHeaderId from './ModalHeaderId'

const trOpt = { scope: 'modals.enrolLearnersOnCourseModal' }

const Modal = styled(_Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-title {
    margin-right: 15px;
  }
  .ant-alert {
    margin-bottom: 10px;
  }
`

const EnrolLearnersOnCourseModal = ({ courseId, courseName, visible = false, setVisible = () => {}, isGapAnalysis = false }) => {
  const { loading, error, data } = useQuery(GET_LEARNERS, { skip: !visible })
  const { learners = [] } = data || {}

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const closeModal = useCallback(() => {
    setVisible(false)
    resetForm()
  }, [setVisible, resetForm])

  const fields = useMemo(() => {
    const options = learners.map(({ id: value, name: label }) => ({ value, label }))
    options.sort((a, b) => a.label.localeCompare(b.label))
    return [{
      id: 'learnerIds',
      label: I18n.t('selectedUsers', trOpt),
      type: 'multiSelect',
      options,
      multiple: true,
      required: true
    }]
  }, [learners])

  const onSuccess = useCallback(result => {
    if (isGapAnalysis) {
      processEnrolLearnersOnGapAnalysisResult(result)
      if (_get(result, 'data.enrollLearnersOnGapAnalysis.success', false)) {
        closeModal()
      }
      return
    }
    const { data: { enrollLearnersOnCourses: { success = false, completed = [], learnerIds = [] } = {} } = {} } = result || {}
    const learnerCount = learnerIds.length
    const completedCount = completed.find(({ id }) => id === courseId)?.learners.length || 0

    if (completedCount === learnerCount) {
      message.success(I18n.t('successMessage', { ...trOpt, name: courseName, count: learnerCount }))
    } else if (completedCount === 0) {
      message.error(I18n.t('noneEnrolledError', { ...trOpt, name: courseName, count: learnerCount }))
    } else {
      message.warning(I18n.t('someEnrolledMessage', { ...trOpt, name: courseName, count: learnerCount, completedCount }))
    }

    if (success) {
      closeModal()
    }
  }, [closeModal, courseId, courseName, isGapAnalysis])

  return (
    <Modal
      title={<ModalHeaderId idText='enrol-user-course-modal-header' text={courseName ? I18n.t('title', { ...trOpt, name: courseName }) : I18n.t('titleCourse', trOpt)} />}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      {...{ visible }}
    >
      <ErrorAlerts {...{ error }} defaultError={I18n.t('common.usersLoadError')} />
      <LoadingBlock fullScreen={false} loading={loading} />
      {
        !loading && !error && learners.length === 0 && (
          <Alert
            showIcon type='info'
            message={I18n.t('noUsersTitle', trOpt)}
            description={
              <p
                style={{ marginBottom: 0 }} className='base-font'
              >
                {I18n.t('noUsersMessage', trOpt)}
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('goToUsersPrompt', {
                      ...trOpt,
                      link: renderToString(<a className='usecure-manual-rr-link' href={routes.LEARNERS}>{I18n.t('common.clickHereInline')}</a>)
                    })
                  }}
                />
              </p>
            }
          />
        )
      }
      <MutationForm
        ref={form}
        mutation={isGapAnalysis ? ENROL_ON_GAP_ANALYSIS : ENROL_ON_COURSE}
        variables={{ courseIds: [courseId] }}
        {...{ fields, onSuccess }}
        onFailure={I18n.t('errorMessage', { ...trOpt, name: courseName })}
        submitLabel={I18n.t('learners.enrolOnCourseModal.enrol')}
        skipResetFieldsOnSubmit
        update={invalidateLearnersAndLearnerDataQueryCache}
      />
    </Modal>
  )
}

export default EnrolLearnersOnCourseModal
