import React, { useMemo } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import useIsPrinting from '../../hooks/useIsPrinting'
import { BrandColours } from '../../helpers/Graphs'

const PerformanceReportBarChart = ({
  data = [],
  keys = [],
  indexBy,
  bottomLegend = '',
  leftLegend = '',
  colors = BrandColours,
  isStatic = true,
  ...rest
}) => {
  const isPrinting = useIsPrinting()

  const chartProps = useMemo(() => ({
    data,
    indexBy: indexBy,
    keys: keys,
    animate: isPrinting ? false : isStatic,
    isInteractive: isPrinting ? false : isStatic,
    margin: { top: 0, right: 130, bottom: 50, left: 200 },
    layout: 'horizontal',
    groupMode: 'grouped',
    padding: 0.1,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: bottomLegend,
      legendPosition: 'middle',
      legendOffset: 32
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: leftLegend,
      legendPosition: 'middle',
      legendOffset: -60
    },
    colors,
    enableGridX: true,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: '#ffffff',
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        symbolShape: 'circle',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20
      }
    ],
    ...(isPrinting ? {
      width: 724 / 0.9
    } : {}),
    ...rest
  }), [data, indexBy, keys, bottomLegend, leftLegend, colors, isStatic, rest, isPrinting])

  return (
    <ResponsiveBar
      data={data}
      {...chartProps}
    />
  )
}
export default PerformanceReportBarChart
