import React from 'react'
import I18n from 'i18n-js'
import { compose } from 'recompose'
import { Link, withRouter, generatePath } from 'react-router-dom'
import { getSessionAndSettings } from '../../state/selectors'
import { withTheme } from 'styled-components'
import routes from '../../constants/routes'
import { connect } from '../../hocs'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import { Icon } from 'antd'
import { renderSubMenuItem, renderMenuItem } from '../../helpers/navBar'
import { LogoMenuItem, Logo, StyledMenu, NavBarImageIcon, UserSubMenuTitle, UserNameAndLevel } from '../Nav/NavBar'
import EndUserSignOut from '../EndUserSignOut'

const NavBar = ({ session, settings, match }) => {
  const userName = _get(session, 'name')
  const companyName = _get(session, 'companyName')

  const getPath = ({ route }) => {
    try {
      return generatePath(route, { companyId: session.companyId })
    } catch (e) {
      console.log(e)
    }
  }

  const mainMenu = [
    {
      title: I18n.t('nav.navBar.home'),
      link: getPath({ route: routes.PORTAL_HOME })
    },
    {
      title: I18n.t('nav.navBar.learn'),
      link: getPath({ route: routes.PORTAL_LEARN })
    },
    {
      title: I18n.t('uPolicy.common.policy'),
      link: getPath({ route: routes.PORTAL_POLICY })
    },
    {
      title: I18n.t('common.breach'),
      link: getPath({ route: routes.PORTAL_BREACH })
    }
  ]

  const settingsMenu = {
    title: (
      <UserSubMenuTitle showDivider={false}>
        <NavBarImageIcon>
          <Icon type='user' />
        </NavBarImageIcon>
        {/* <SettingsMenuTitle>{I18n.t('settings', trOpt)}</SettingsMenuTitle> */}
        <UserNameAndLevel>
          <span className='usecure-user-name'>{userName}</span>
          <span className='usecure-company-name'>{companyName}</span>
        </UserNameAndLevel>
      </UserSubMenuTitle>
    ),
    float: 'right',
    children: [
      {
        title: I18n.t('nav.navBar.settings'),
        link: getPath({ route: routes.PORTAL_SETTINGS })
      },
      {
        key: 'logout',
        content: (
          <EndUserSignOut className='usecure-menu-item' {...{ session }} />
        )
      }
    ]
  }
  return (
    <>
      <StyledMenu
        mode='horizontal'
        subMenuCloseDelay={0.01}
        subMenuOpenDelay={0.2}
        selectedKeys={[match.url]}
      >
        <LogoMenuItem key='logo'>
          <Link to={() => getPath({ route: routes.PORTAL_HOME })}>
            <Logo />
          </Link>
        </LogoMenuItem>
        {mainMenu.map((item, index) => {
          const { key, children = [] } = item
          if (children.length > 0) return renderSubMenuItem(item, key || index, session, settings)
          const menuItem = renderMenuItem(item, session, settings)
          return menuItem
        }).filter(menuItem => !_isNil(menuItem))}
        {renderSubMenuItem(settingsMenu, 'settings', session, settings)}
      </StyledMenu>
    </>
  )
}

export default compose(
  connect(
    getSessionAndSettings
  ),
  withRouter,
  withTheme
)(NavBar)
