import React from 'react'
import { Icon } from 'antd'
import styled from 'styled-components'
import { trendColors, trendIcons } from '../common/PerformanceReport'

const TableRowWrap = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  line-height: 1;
`

const TableRowRankCell = styled.div`
  width: 40px;
  margin-right: 10px;
  display: flex;
`

const TableRowRankIcon = styled(Icon)`
  color: ${props => props.color};
  margin-left: 5px;
  margin-top: -2px;
`

const TableRowValueCell = styled.div`
  width: 40px;
  margin-left: 10px; 
`

const TableRowLabelCell = styled.div`
  position: relative;
  flex-grow: 1;
  align-items: center;
`

const TableRowLabelCellBar = styled.div`
  position: absolute;
  background-color: rgba(71, 180, 241, 0.2);
  height: 100%;
  width: ${props => props.width}%;
  border-radius: 5px;
`

const TableRowLabelCellText = styled.div`
position: relative;
height: 100%;
padding: 10px;
`

const TableRow = ({ rowData, max }) => {
  return (
    <TableRowWrap>
      <TableRowRankCell>
        <div>
          <strong>{rowData.rank}</strong>
        </div>
        <TableRowRankIcon
          color={trendColors[rowData.change]}
          type={trendIcons[rowData.change]}
        />
      </TableRowRankCell>
      <TableRowLabelCell>
        <TableRowLabelCellBar width={(rowData.value / max) * 100} />
        <TableRowLabelCellText>{rowData.label}</TableRowLabelCellText>
      </TableRowLabelCell>
      <TableRowValueCell>
        <strong>{rowData.value}</strong>
      </TableRowValueCell>
    </TableRowWrap>
  )
}

const RankedTable = ({ data = [] }) => {
  // Calculate max value for bar
  // The largest value in the data will take up all the available space
  // Everything else will be proportional to that
  const maxValue = Math.max(...data.map(row => row.value))

  return (
    <div>
      {data.map(rowData => <TableRow key={rowData.label} {...{ rowData, max: maxValue }} />)}
    </div>
  )
}
export default RankedTable
