import React, { useState, useMemo } from 'react'
import { Card, Layout } from 'antd'
import styled from 'styled-components'

import { ChangeDetails as Details, ChangePassword as Password, ChangeSecurity as Security, ChangeLanguage as Language } from '../components/AccountSettings'
import AccountSettingsSidebar from '../components/AccountSettings/AccountSettingsSidebar'
const { Content } = Layout

const UserDetails = styled.div`

  &> * {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
`

const ACCOUNT_SETTINGS_COMPONENTS = {
  Details,
  Password,
  Security,
  Language
}

const AccountSettings = () => {
  const [activeContent, updateActiveContent] = useState('Security')
  const content = useMemo(() => {
    let Content = ACCOUNT_SETTINGS_COMPONENTS[activeContent] || null
    if (Content) {
      Content = <Content />
    }

    return Content
  }, [activeContent])

  return (
    <Card>
      <Content style={{ padding: '0' }}>
        <Layout style={{ padding: '24px 0', background: '#fff' }}>
          <AccountSettingsSidebar {...{ activeContent, updateActiveContent }} />
          <Content style={{ padding: '0 24px', minHeight: '75vh' }}>
            <UserDetails style={{ maxWidth: activeContent === 'Security' ? '600px' : '400px' }}> {content}</UserDetails>
          </Content>
        </Layout>
      </Content>
    </Card>
  )
}

export default AccountSettings
