import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Icon, Tooltip } from 'antd'
import { transparentize } from 'polished'
import I18n from 'i18n-js'

import { RoundedCard, Column, Row } from '../EndUserPortal/GridFlexiLayout'
import { trendColors } from '../common/PerformanceReport'
import LineChart from '../Graphs/LineChart'
import TooltipIcon from '../common/TooltipIcon'
import useIsPrinting from '../../hooks/useIsPrinting'

const noMarginBottom = css`margin-bottom: 0px;`

const PrintableRoundedCard = styled(RoundedCard)`
  height: 100%;
  @media print {
    .ant-card-body {
      padding-bottom: 16px !important;
      padding-top: 16px !important;; 
    }
  }
`

const CardBodyWrapper = styled(Column)`
  margin-left: ${({ withIcon }) => withIcon ? '10px' : '0px'};
`
const IconIndicator = styled.div`
  position: absolute;
  top: 0px;
  left: -40px;
  background-color: ${({ colour }) => colour};
  border-radius: 40px;
  height: ${({ screenType }) => ['xs', 'sm', 'md'].includes(screenType) ? '30px' : '40px'};
  width: ${({ screenType }) => ['xs', 'sm', 'md'].includes(screenType) ? '30px' : '40px'};
  text-align: center;
  color: white;
  &> i {
    position: relative;
    top: 20%;
    font-size: ${({ screenType }) => ['xs', 'sm', 'md'].includes(screenType) ? '18px' : '24px'};
  }
`
const CardTitle = styled.h4`
  font-weight: normal;
  @media print {
    margin-bottom: 0px;
  }
  font-size: ${({ scale }) => scale ? `${scale * 1.1}` : '1.1'}rem;
`
const CardData = styled.h2`
  ${noMarginBottom}
  font-weight: normal;
  font-size: ${({ scale }) => scale ? `${scale * 1.4}` : '1.4'}rem;
`
const CardDataDenominator = styled.span`
  font-size: 14px;
  margin-left: 5px;
`
const DataCaption = styled.small`line-height: 15px;`
export const Chip = styled.small`
  background-color: ${({ colour }) => transparentize(0.5, colour || 'green')};
  display: inline-block;
  line-height: 15px;
  text-align: center;
  color: white;
  padding: 3px 10px 0 10px;
  border-radius: 25px;
  height: fit-content;
`
const ChipIcon = styled.span`
  vertical-align: top;
`

const GraphWrapper = styled.div`
  flex-grow: 2; 
  height: 25px;
  width: 'inherit';
`
/**
 * @String title
 * @String Data
 * @String caption
 * @String percentageChange
 * @Object Trend: { isUp: @Bool, type: ['positive', 'negative', 'neutral'] }
 * <TrendCard title='uPhish Risk Score' data='140/350' caption='Previous: 300' percentageChange='10%' trend={{ isUp: true, type: 'positive' }} />
 * <TrendCard title='Total Users' data='1677' caption='Previous: 1230' trend={{ isUp: true, type: 'positive' }} chartData={dummyData} percentageChange='5%' />
 */

const TrendCard = ({
  title,
  data,
  caption,
  percentageChange,
  trend: { description, direction },
  chartData = false,
  screenType,
  withIcon = true,
  trOpt,
  denominator,
  withPrevious = true,
  printScale = 1,
  tooltip
}) => {
  const [trendTheme, setTrendTheme] = useState({ colour: '', arrow: 'line', thumb: 'line' })
  useEffect(() => {
    setTrendTheme({
      arrow: direction === 'up' ? 'caret-up' : 'caret-down',
      thumb: description === 'positive' ? 'like' : 'dislike',
      colour: trendColors[description] || 'grey'
    })
  }, [description, direction])

  const isPrinting = useIsPrinting()

  return (
    <PrintableRoundedCard flex='1'>
      {chartData ? (
        <Column>
          <Row className='justify' justify='space-between'>
            <CardTitle>{title}</CardTitle>
            {percentageChange && <Chip colour={trendTheme.colour}>{description !== 'neutral' && <Icon type={trendTheme.arrow} />}{percentageChange}%</Chip>}
          </Row>
          <Row wrap='nowrap'>
            <CardData>{data}</CardData>
            <GraphWrapper>
              <LineChart
                data={chartData} colours={trendTheme.colour}
                yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
                margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                curve='natural'
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableGridX={false}
                enableGridY={false}
                pointSize={6}
                lineWidth={2}
                pointColor={{ theme: 'background' }}
              />
            </GraphWrapper>
          </Row>
        </Column>
      ) : (
        <Row className='align' alignContent='stretch' style={{ position: 'relative' }}>
          {withIcon && description !== 'neutral' && (
            <IconIndicator colour={trendTheme.colour} screenType={screenType}>
              <Icon type={trendTheme.thumb} />
            </IconIndicator>)}
          <CardBodyWrapper gap='0px' flex='1' withIcon={withIcon && description !== 'neutral'}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CardTitle scale={isPrinting ? printScale : 1}>
                {title}
              </CardTitle>
              {tooltip && !isPrinting && (
                <Tooltip title={tooltip}>
                  <span><TooltipIcon /></span>
                </Tooltip>
              )}
            </div>
            {tooltip && isPrinting && (
              <DataCaption style={{ marginBottom: '0.5rem' }}>
                {tooltip}
              </DataCaption>
            )}
            <CardData scale={isPrinting ? printScale : 1}>
              {data}
              {denominator && <CardDataDenominator>/ {denominator}</CardDataDenominator>}
            </CardData>
            {withPrevious && (
              <Row className='justify align' justify='space-between' alignContent='flex-end'>
                <DataCaption>{(caption || caption === 0) && <span>{I18n.t('previousValueLabel', trOpt)} {caption}</span>}</DataCaption>
                {(percentageChange || percentageChange === 0) && <Chip colour={trendTheme.colour}>{direction !== 'neutral' && <ChipIcon><Icon type={trendTheme.arrow} /></ChipIcon>} {percentageChange}%</Chip>}
              </Row>
            )}
          </CardBodyWrapper>
        </Row>
      )}
    </PrintableRoundedCard>
  )
}

export default TrendCard
