import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Button, message, Modal, Card, Empty } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { useApolloClient } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'

import { GET_COMPANY_FOR_UPDATE, RESET_RISK_REPORT, RISK_REPORT_UBREACH_COMPLETE_CHECK, DELETE_RISK_REPORT } from '../Queries/Companies'
import { QUEUE_BREACH_SCAN } from '../Queries/uBreach'
import { modalConfirmAsync, renderParagraphsFragmentFromArray, showErrors } from '../../helpers'
import { LoadingBlock } from '../common'
import { isRiskReportAtStatus } from '../../helpers/company'

const trOpt = { scope: 'modals.uServiceAdminTools' }

const isUBreachEnabled = company => {
  const { accountType, settings } = company
  if (accountType === 'prospect') {
    return isRiskReportAtStatus(company.riskReport?.status, 'ubreach_started')
  }
  return settings?.uBreachEnabled !== false
}

const hasRiskReportReportStarted = company => {
  return isRiskReportAtStatus(company.riskReport?.status, 'domain_started')
}

const ALL_ACTIONS = {
  queueBreachScan: {
    mutation: QUEUE_BREACH_SCAN,
    mutationName: 'queueCompanyBreachScan',
    visible: isUBreachEnabled,
    successMessageKey: 'actions.queueBreachScan.successMessage',
    errorMessageKey: 'actions.queueBreachScan.errorMessage'
  },
  queueBreachRescan: {
    mutation: QUEUE_BREACH_SCAN,
    mutationName: 'queueCompanyBreachScan',
    visible: isUBreachEnabled,
    variables: { rescan: true },
    successMessageKey: 'actions.queueBreachRescan.successMessage',
    errorMessageKey: 'actions.queueBreachRescan.errorMessage'
  },
  riskReportUBreachComplete: {
    mutation: RISK_REPORT_UBREACH_COMPLETE_CHECK,
    mutationName: 'riskReportUBreachCompleteCheck',
    visible: company => company.riskReport?.status === 'ubreach_started',
    successMessageKey: 'actions.riskReportUBreachComplete.successMessage',
    errorMessageKey: 'actions.riskReportUBreachComplete.errorMessage'
  },
  resetRiskReport: {
    mutation: RESET_RISK_REPORT,
    mutationName: 'resetRiskReport',
    visible: hasRiskReportReportStarted,
    successMessageKey: 'actions.resetRiskReport.successMessage',
    errorMessageKey: 'actions.resetRiskReport.errorMessage'
  },
  deleteRiskReport: {
    mutation: DELETE_RISK_REPORT,
    mutationName: 'deleteRiskReport',
    visible: company => company.accountType === 'tenant' && hasRiskReportReportStarted(company),
    successMessageKey: 'actions.deleteRiskReport.successMessage',
    errorMessageKey: 'actions.deleteRiskReport.errorMessage'
  }
}

const _AdminToolCard = ({ className, actionId, onClick: onClickProp }) => {
  const toolTrOpt = { scope: `${trOpt.scope}.actions.${actionId}` }
  const onClick = useCallback(() => onClickProp(actionId), [onClickProp, actionId])
  const description = I18n.t('description', toolTrOpt)
  return (
    <Card
      className={className}
      type='inner'
      title={I18n.tWithProductNames('title', toolTrOpt)}
      extra={
        <Button type='primary' onClick={onClick}>{I18n.t('actionButtonLabel', trOpt)}</Button>
      }
    >
      {renderParagraphsFragmentFromArray(_isString(description) ? [description] : description)}
    </Card>
  )
}

const AdminToolCard = styled(_AdminToolCard)`
  p {
    font-family: unset;
  }
`

const EditModal = styled(Modal)`
  max-width: 800px;
  max-height: calc(100vh - 65px);
  top: 50px;

  .ant-modal-content {
    height: 100%;
    max-height: calc(100vh - 70px);
  }

  .ant-modal-body {
    max-height: calc(100vh - 148px);
    overflow: auto;
  }

  ${AdminToolCard} {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
`

const UServiceAdminToolsModal = React.forwardRef(({ refetchQueries = [], onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [company, setCompany] = useState(null)
  const [actionIds, setActionIds] = useState([])
  const client = useApolloClient()

  useImperativeHandle(ref, () => ({
    open: async (companyId) => {
      try {
        setVisible(true)
        setLoading(true)
        const result = await client.query({
          query: GET_COMPANY_FOR_UPDATE,
          variables: { id: companyId },
          fetchPolicy: 'network-only'
        })
        const company = _get(result, 'data.company')
        if (!company) throw new Error(I18n.t('loadErrorMessage', trOpt))
        setCompanyId(companyId)
        setCompany(company)
        setActionIds(Object.entries(ALL_ACTIONS).reduce((acc, [actionId, action]) => {
          if (_isFunction(action.visible) ? action.visible(company) : true) {
            acc.push(actionId)
          }
          return acc
        }, []))
      } catch (e) {
        showErrors(e, I18n.t('loadErrorMessage', trOpt))
        setVisible(false)
      } finally {
        setLoading(false)
      }
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setLoading(false)
    setCompanyId(null)
    setCompany(null)
    setActionIds([])
    onClose()
  }

  const onCardClick = useCallback(async actionId => {
    if ((await modalConfirmAsync()) !== true) return
    const { mutation, mutationName, variables, successMessageKey = 'successMessage', errorMessageKey = 'errorMessage' } = ALL_ACTIONS[actionId]
    try {
      setLoading(true)
      const result = await client.mutate({
        mutation,
        variables: { companyId, ...(variables || {}) },
        refetchQueries
      })
      if (_get(result, ['data', mutationName]) === true) {
        message.success(I18n.tWithProductNames(successMessageKey, trOpt))
        closeModal()
      } else {
        message.error(I18n.tWithProductNames(errorMessageKey, trOpt))
      }
    } catch (e) {
      showErrors(e, I18n.tWithProductNames(errorMessageKey, trOpt))
    } finally {
      setLoading(false)
    }
  }, [client, refetchQueries, companyId, closeModal])

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={company?.name ? I18n.t('titleName', { ...trOpt, name: company.name }) : I18n.t('title', trOpt)}
      afterClose={afterClose}
      width='70%'
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <div>
        {!loading && actionIds.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
        {
          actionIds.map(actionId =>
            <AdminToolCard key={actionId} actionId={actionId} onClick={onCardClick} />
          )
        }
      </div>
    </EditModal>
  )
})

export default UServiceAdminToolsModal
