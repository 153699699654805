import React, { useCallback } from 'react'
import { compose } from 'recompose'
import { generatePath } from 'react-router-dom'
import withConsumer from './withConsumer'
import connect from './connect'
import { endUserSignOut, signOut } from '../helpers/SignOut'
import { creators as sessionActions } from '../state/actions/session'
import { creators as settingsActions } from '../state/actions/settings'
import { creators as viewActions } from '../state/actions/view'
import _get from 'lodash/get'
import { resetPosthog } from '../helpers/posthog'

const withLogout = ({ redirectRoute, sessionType = 'admin' } = {}) => Component => ({
  client, resetSession, resetSettings, fullyResetPageTitle, resetFavicon, companyId, ...props
}) => {
  const logOut = useCallback((priorityRoute = null) => {
    (sessionType === 'admin') ? signOut(client, redirectRoute) : endUserSignOut(client, priorityRoute || generatePath(redirectRoute, { companyId }))
    resetSession()
    resetSettings()
    fullyResetPageTitle()
    resetFavicon()
    resetPosthog()
  }, [client, resetSession, resetSettings, fullyResetPageTitle, resetFavicon, companyId])

  return (
    <Component {...{ logOut, ...props }} />
  )
}

const composedWithLogout = spec => compose(
  withConsumer,
  connect(
    state => ({ companyId: _get(state, 'session.companyId') }),
    dispatch => ({
      resetSession: () => dispatch(sessionActions.reset()),
      resetSettings: () => dispatch(settingsActions.reset()),
      fullyResetPageTitle: () => dispatch(viewActions.resetTitles()),
      resetFavicon: () => dispatch(viewActions.resetFavicon())
    })
  ),
  withLogout(spec)
)

export default composedWithLogout
