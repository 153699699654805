import React, { useCallback, useState } from 'react'
import { Button, Modal, message } from 'antd'
import _isFunction from 'lodash/isFunction'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { showErrors } from '../../helpers'
const i18nBase = 'common.mutationButton'

const MutationButton = ({
  className,
  children = 'BUTTON', mutation, variables, icon, block = false,
  disabled = false,
  onResponse: onResponseFn,
  onError: onErrorFn,
  errorMessage = I18n.t(`${i18nBase}.anErrorOccurredCompletingThisAction`),
  confirm,
  confirmTitle = I18n.t(`${i18nBase}.areYouSureYouWishToContinue`),
  confirmContent,
  confirmOkText = I18n.t('common.yes'),
  confirmCancelText = I18n.t('common.no')
}) => {
  const [loading, updateLoading] = useState(false)

  const mutate = useCallback(async () => {
    updateLoading(true)
    try {
      const res = await authenticatedClient.mutate({
        mutation,
        variables
      })
      if (_isFunction(onResponseFn)) {
        onResponseFn(res)
      } else {
        message.success(I18n.t(`${i18nBase}.thisActionCompletedSuccessfully`))
      }
    } catch (e) {
      console.error('MutationButton.onClick - error', typeof e, e)
      if (_isFunction(onErrorFn)) {
        onErrorFn(e)
      } else {
        showErrors(e, errorMessage)
      }
    }
    updateLoading(false)
  }, [mutation, variables, onResponseFn, onErrorFn, errorMessage, updateLoading])
  const onClick = useCallback(async () => {
    if (confirm) {
      Modal.confirm({
        title: confirmTitle,
        content: confirmContent,
        okText: confirmOkText,
        cancelText: confirmCancelText,
        async onOk () {
          return mutate()
        }
      })
    } else {
      return mutate()
    }
  }, [mutate, confirm, confirmTitle, confirmContent, confirmOkText, confirmCancelText])

  return (
    <Button
      {...{ className, loading, onClick, disabled, block, icon }}
    >{children}
    </Button>
  )
}

export default MutationButton
