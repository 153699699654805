import React from 'react'
import { compose } from 'recompose'

import { LearnerCourseMutation, LearnerCourseConnect } from './LearnerCourse'
import { withConsumer } from '../hocs'

// The LearnerCourse view for live courses is wrapped in an ApolloProvider that uses an unauthenticated client via the withProvider HOC
// Preview courses require an authenticated client so this view is wrapped by withProvider instead using the ApolloProvider as the admin app
const LearnerCoursePreview = props => <LearnerCourseMutation preview {...props} />

export default compose(
  LearnerCourseConnect,
  withConsumer
)(LearnerCoursePreview)
