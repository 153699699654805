import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Card, Button, message } from 'antd'
import I18n from 'i18n-js'
import { useMutation, useQuery } from 'react-apollo'
import { ContentWrap } from '../../components/common'
import { GET_COMPANY_BILLING, UPGRADE_UBREACH_PRO } from '../../components/Queries/Companies'
import { displayMoney, mapStringArrayToLi, modalConfirmAsync, showErrors } from '../../helpers'
import { useStateValue } from '../../state'
import { withRefreshSessionState } from '../../hocs'
import useGlobalState from '../../hooks/useGlobalState'
import selectors from '../../state/selectors'
import { UBREACH_PRO_UPGRADE_HELP_CENTRE_URL } from '../../constants/uBreach'

const trOpt = { scope: 'upgrade' }

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`

const Buttons = styled.div`
  display: flex;
  column-gap: 10px;
`

const UpgradeButton = withRefreshSessionState(({ refreshSessionState }) => {
  const [{ settings: { uBreachProEnabled } }] = useStateValue()
  const [upgradeUBreachPro] = useMutation(UPGRADE_UBREACH_PRO)
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    const confirm = await modalConfirmAsync({
      title: I18n.t('confirmationTitle', trOpt)
    })

    if (!confirm) return

    try {
      setIsLoading(true)
      const result = await upgradeUBreachPro()

      if (result?.data?.upgradeMSPUBreachPro === true) {
        message.success(I18n.t('successMessage', trOpt))
        await refreshSessionState()
      } else {
        message.error(I18n.t('errorMessage', trOpt))
      }
    } catch (e) {
      showErrors(e, I18n.t('errorMessage', trOpt))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      type='primary'
      onClick={onClick}
      loading={isLoading}
      disabled={uBreachProEnabled}
    >
      {I18n.tWithProductNames(uBreachProEnabled ? 'upgraded' : 'upgrade', trOpt)}
    </Button>
  )
})

export const UbreachProUpgrade = () => {
  const billing = useQuery(GET_COMPANY_BILLING)
  const { accountType, parentCompanyId, billingType } = useGlobalState(
    state => {
      const { parentCompanyId, accountType, billingType } = selectors.session.get(state)
      return { parentCompanyId, accountType, billingType }
    }
  )

  const isInteractive = useMemo(() => accountType === 'msp' && billingType === 'auto' && !parentCompanyId, [accountType, parentCompanyId, billingType])

  return (
    <ContentWrap>
      <Grid>
        <Card>
          <h1>{I18n.tWithProductNames('uBreachPro.title', trOpt)}</h1>
          <p>{I18n.tWithProductNames('uBreachPro.description', trOpt)}</p>
          <ul>
            {mapStringArrayToLi(I18n.tWithProductNames('uBreachPro.features', trOpt))}
            <li><a href={UBREACH_PRO_UPGRADE_HELP_CENTRE_URL} target='_blank' rel='noopener noreferrer'>{I18n.t('viewAllFeatures', trOpt)}</a></li>
          </ul>
          {isInteractive && (
            <>
              {billing.loading && <span>{I18n.t('common.loading')}</span>}
              {billing.error && <span>{I18n.t('common.anErrorOccurred')}</span>}
              {!billing.data?.company.uBreachProPricing && <p>{I18n.t('pricingUnavailable', trOpt)}</p>}
              {billing.data?.company.uBreachProPricing && (
                <>
                  <p>{I18n.tWithProductNames('upgradeOptions', trOpt)}</p>
                  <ul>
                    {billing.data.company.uBreachProPricing.tiers.map(({ upTo, flatAmount }, index) => {
                      const priceValue = displayMoney(flatAmount, billing.data.company.uBreachProPricing.currency, true)
                      const price = I18n.t('perMonth', { ...trOpt, value: priceValue })
                      const users = upTo === -1 ? I18n.t('unlimitedDomains', trOpt) : I18n.t('xDomains', { ...trOpt, value: upTo })
                      return <li key={index}>{users} - {price}</li>
                    })}
                  </ul>
                  <p>{I18n.t('billedOnYourUsualInvoiceDate', trOpt)}</p>
                </>
              )}
            </>
          )}

          <Buttons>
            {isInteractive ? <UpgradeButton /> : <Button type='primary' id='ubreach-upgrade-request-upgrade-btn'>{I18n.t('requestUpgrade', trOpt)}</Button>}
            <a href={UBREACH_PRO_UPGRADE_HELP_CENTRE_URL} target='_blank' rel='noopener noreferrer'>
              <Button>{I18n.t('learnMore', trOpt)}</Button>
            </a>
          </Buttons>
        </Card>

        <Card>
          <h2>{I18n.t('haveAQuestion', trOpt)}</h2>
          <p>{I18n.t('chatWithUsAbout', trOpt)}</p>

          <Button type='primary' id='ubreach-upgrade-chat-btn'>{I18n.t('chatWithUs', trOpt)}</Button>
        </Card>
      </Grid>
    </ContentWrap>
  )
}
