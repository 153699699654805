import _pick from 'lodash/pick'

export const get = state => state.view
export const getView = get
export const viewLoadingPlain = state => get(state).loading
export const viewLoading = state => ({ loading: get(state).loading })
export const viewTitle = state => get(state).title
export const viewAppIcon = state => get(state).appIcon
export const viewFavicon16 = state => get(state).favicon16
export const viewFavicon32 = state => get(state).favicon32
export const viewFavicon180 = state => get(state).favicon180
export const viewSafariTabIcon = state => get(state).safariTabIcon
export const viewTitleAndIcons = state => _pick(get(state), ['title', 'appIcon', 'favicon16', 'favicon32', 'favicon180', 'safariTabIcon'])

export default {
  get,
  getView,
  viewLoading,
  loading: viewLoadingPlain,
  title: viewTitle,
  appIcon: viewAppIcon,
  favicon16: viewFavicon16,
  favicon32: viewFavicon32,
  favicon180: viewFavicon180,
  safariTabIcon: viewSafariTabIcon,
  titleAndIcons: viewTitleAndIcons
}
