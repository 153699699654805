import { CURRENCIES_INFO } from '../constants/billing'

const displayMoney = (cost, currency, hideDecimal = false) => {
  const { places = 2 } = CURRENCIES_INFO[currency]

  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    maximumFractionDigits: hideDecimal ? 0 : undefined,
    minimumFractionDigits: hideDecimal ? 0 : undefined
  }).format(cost / Math.pow(10, places))
}

export default displayMoney
