import {withTheme} from "styled-components";
import {Tag} from "antd";
import I18n from "i18n-js";
import React from "react";

export const PolicyVersionTag = withTheme(({ major = false, theme, isOwner, isLive, hasDraft, versionNo, lastMajorVersionNo }) => {
    if (major) {
        return (
            <>
                {lastMajorVersionNo && <Tag color={theme.green}>{lastMajorVersionNo}</Tag>}
                {
                    isOwner && hasDraft &&
                    <Tag color={theme.orange}>{I18n.t('uPolicy.common.inDraft')}</Tag>
                }
            </>
        )
    }

    let color = theme.primary
    if (!isLive && hasDraft) {
        color = theme.orange
    } else if (isLive && versionNo === lastMajorVersionNo) {
        color = theme.green
    }
    return (
        <>
            {versionNo && <Tag color={color}>{versionNo}</Tag>}
            {
                isOwner && hasDraft && isLive &&
                <Tag color={theme.orange}>{I18n.t('uPolicy.common.inDraft')}</Tag>
            }
        </>
    )
})

export default PolicyVersionTag