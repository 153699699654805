import { useCallback, useState } from 'react'
import mime from 'mime-types'

import { getAbsoluteURL, getDataURL } from '../helpers'

const useManifest = () => {
  const [href, setHref] = useState(null)

  const setManifest = useCallback(({
    useDefaultIcons, pageTitle, icon, themeColor = '#000000', backgroundColor = '#1e7be4'
  }) => {
    let href = null
    if ((useDefaultIcons || icon) && pageTitle && themeColor && backgroundColor) {
      const manifest = {
        name: pageTitle,
        short_name: pageTitle,
        start_url: `${window.location.origin}/`,
        display: 'standalone',
        theme_color: themeColor,
        background_color: backgroundColor
      }

      if (useDefaultIcons) {
        manifest.icons = [
          {
            src: `${window.location.origin}/android-chrome-192x192.png`,
            sizes: '192x192',
            type: mime.types.png
          },
          {
            src: `${window.location.origin}/android-chrome-512x512.png`,
            sizes: '512x512',
            type: mime.types.png
          }
        ]
      } else {
        manifest.icons = [{
          src: getAbsoluteURL(icon), // Assumes the icon is an svg
          sizes: '72x72 96x96 120x120 128x128 144x144 152x152 180x180 192x192 384x384 512x512'
        }]
      }

      // Based on this - https://stackoverflow.com/a/57856162
      href = getDataURL(manifest)
    }
    setHref(href)
  }, [])

  return [href, setManifest]
}

export default useManifest
