/* global FileReader */
import React, { useCallback } from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _isFunction from 'lodash/isFunction'

import { renderToString } from '../../helpers'
import useTheme from '../../hooks/useTheme'

export const DragAndDropTextFileReaderFooter = styled.div`
  border-top: ${({ theme }) => theme.primary} solid 1px;
  margin-top: 3px;
`

const DragAndDropTextFileReader = ({
  className, children, onFileRead,
  footerTrKey = 'common.dropFileUploadFooter', footerLinkTrKey = 'common.clickHereInline'
}) => {
  const theme = useTheme()
  const onFileDrop = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()

    if (!_isFunction(onFileRead)) return

    const [file] = e?.dataTransfer?.files ?? []
    var reader = new FileReader()
    reader.onload = e => {
      const { result } = e?.target ?? {}
      if (result) {
        onFileRead(result)
      }
    }
    reader.readAsText(file, 'UTF-8')
  }, [onFileRead])

  const onFooterClick = useCallback(e => {
    if (!(_isFunction(onFileRead) && e.target.classList.contains('usecure-drop-file-link'))) return

    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = async function () {
      const [file] = Array.from(this.files)
      const fileText = await file.text()
      onFileRead(fileText)
    }
    input.click()
  }, [onFileRead])

  return (
    <div onDrop={onFileDrop} {...{ className }}>
      {children}
      <DragAndDropTextFileReaderFooter
        onClick={onFooterClick}
        dangerouslySetInnerHTML={{
          __html: I18n.t(footerTrKey, {
            link: renderToString(<span className='usecure-drop-file-link' style={{ color: theme.primary, cursor: 'pointer' }}>{I18n.t(footerLinkTrKey)}</span>)
          })
        }}
      />
    </div>
  )
}

export default DragAndDropTextFileReader
