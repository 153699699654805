import styled from "styled-components";

export default styled.div`
  text-align: ${props => props.footerAlign};

  & > * {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
`