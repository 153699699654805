import React from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { GET_RECOMMENDED_SIMULATION } from '../../components/Queries/uPhish'
import CreateSimulation from './CreateSimulation'
import { getUpdateSimulationProps } from '../../helpers/uPhish'
import { LoadingBlock, ErrorAlerts } from '../../components/common'

const CreateSimulationFromRecommended = props => {
  const id = _get(props, 'match.params.simulation_id')

  const { loading, error, data: { recommendedSimulation: rawSimulation = {} } = {} } = useQuery(GET_RECOMMENDED_SIMULATION, {
    variables: { id },
    skip: !id
  })

  if (loading) return <LoadingBlock fullScreen loading={loading} />
  if (error) {
    return (
      <Card>
        <h1>{I18n.t('common.uPhish')} - {I18n.t('uPhish.common.createSimulation')}</h1>
        <ErrorAlerts {...{ error }} defaultError={I18n.t('uPhish.common.errors.thisSimulationCouldNotBeLoaded')} />
      </Card>
    )
  }

  return (
    <CreateSimulation simulationId={id} id='uPhish-create-from-rec-simulation-header' pageId='createSimulationFromRecommended' {...getUpdateSimulationProps(rawSimulation, 'createRec')} clone {...props} />
  )
}

export default withRouter(CreateSimulationFromRecommended)
