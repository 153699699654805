import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import I18n from 'i18n-js'

import DragAndDropList from './DragAndDropList'
import EditCourseCarouselSlide from './EditCourseCarouselSlide'
import EditCourseSlate from './EditCourseSlate'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const trOpt = { scope: 'editCourse.editCourseCarousel' }

class EditCourseCarousel extends Component {
  constructor (props) {
    super(props)

    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.updateSlides = this.updateSlides.bind(this)
    this.updateIntro = this.updateIntro.bind(this)

    this.slideTemplate = {
      id: null,
      image: null
    }
  }

  handleTitleChange (event) {
    this.props.updateTitle(event.target.value)
  }

  updateIntro ({ intro }) {
    this.updateContent({ intro })
  }

  updateContent (update) {
    this.props.updateContent({ ...(this.props.content || {}), ...update })
  }

  updateSlides (slides) {
    this.updateContent({ slides })
  }

  render () {
    const { title, content, locale, slideId, updateId } = this.props
    const { slides } = content || {}

    return (
      <div>
        <Form.Item label={I18n.t('editCourse.common.title')}>
          <Input
            onChange={this.handleTitleChange}
            value={title}
          />
        </Form.Item>

        <Form.Item label={I18n.t('editCourse.common.intro')}>
          <EditCourseSlate updateContent={this.updateIntro} {...{ locale, content, slideId, updateId }} />
        </Form.Item>

        <DragAndDropList
          list={slides || []}
          title={I18n.t('slides', trOpt)}
          itemName={I18n.t('slide', trOpt)}
          itemComponent={EditCourseCarouselSlide}
          itemTemplate={this.slideTemplate}
          updateList={this.updateSlides}
        />
      </div>
    )
  }
}

EditCourseCarousel.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseCarousel.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseCarousel
