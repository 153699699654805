import React, { useCallback } from 'react'
import { Button } from 'antd'
import { generatePath } from 'react-router-dom'
import I18n from 'i18n-js'
import routes from '../../constants/routes'

const RedirectToPortalButton = ({ endUserSessionType, companyId, trOpt = { scope: 'endUserPortal.common' }, route: routeProp, buttonText: buttonTextProp }) => {
  let buttonText = 'goToPortal'
  if (buttonTextProp) {
    buttonText = buttonTextProp
  } else if (['full', 'limited'].includes(endUserSessionType)) {
    buttonText = 'goBackToPortal'
  }

  const onClick = useCallback(() => {
    let route = routes.PORTAL_HOME
    if (routeProp) {
      route = routeProp
    } else if (endUserSessionType === 'limited') {
      route = routes.OUTSTANDING_ACTIVITIES
    }
    window.location.assign(generatePath(route, { companyId }))
  }, [endUserSessionType, routeProp, companyId])

  return (
    <Button onClick={onClick}>{I18n.t(buttonText, trOpt)}</Button>
  )
}

export default RedirectToPortalButton
