import React, { Component, useCallback, useEffect, useState } from 'react'
import { Card, Empty, Descriptions, Pagination, Switch, Tag, Alert } from 'antd'
import styled, { withTheme } from 'styled-components'
import I18n from 'i18n-js'
import { compose } from 'recompose'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { connect, withConsumer, withRenderDelay } from '../../hocs'
import selectors from '../../state/selectors'
import { GET_GROUP_TREE } from '../Queries/Groups'
import { LoadingBlock, ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts } from '../common'
import { getGroupAllowListCounts, isUUID } from '../../helpers'

const GroupCard = styled.div`
  .ant-card {
    height: 100%;
  }

  .sync-smry-group__header {
    display: flex;
    justify-content: space-between;
  }
  .sync-smry-group__title {
    hyphens: auto;
    padding-right: 3px;
  }
  .sync-smry-group__group-memb-container {
    margin-top: 5px;
  }

  .ant-descriptions-row > td {
    padding-bottom: 3px;
  }

  .sync-smry-group__map-tags {
    margin-top: 10px;

    .ant-tag {
      margin: 0 5px 5px 0; 
    }
  }
`

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`

const GroupsScroller = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`

const WhiteListSwitchContainer = styled.div`
  .sync-smry-wl-switch__label {
    display: inline-block;
    line-height: 1.5;
    margin-left: 15px;
  }
`

// modal is 90% screen width
const GROUP_SCALE_FACTOR = 1 / 0.9
const Groups = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: -5px;
  position: relative;

  ${GroupCard} {
    padding: 0 5px 5px;
    
    @media (max-width: ${575 * GROUP_SCALE_FACTOR}px) {
      width: 100%;
    }
    @media (min-width: ${576 * GROUP_SCALE_FACTOR}px) {
      width: 50%;
    }
    @media (min-width: ${992 * GROUP_SCALE_FACTOR}px) {
    width: ${100 / 3}%;
    }
    @media (min-width: ${1200 * GROUP_SCALE_FACTOR}px) {
      width: 25%;
    }
    @media (min-width: ${1600 * GROUP_SCALE_FACTOR}px) {
      width: 20%;
    }
    @media (min-width: ${2000 * GROUP_SCALE_FACTOR}px) {
      width: ${100 / 6}%;
    }
  }
`

const PaginationContainer = styled.div`
  margin: 20px 0 5px;
  text-align: right;

  .ant-pagination-options-size-changer.ant-select {
    margin-right: 7px;
  }
`

const CountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  &> .ant-tag {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};
    font-size: 14px;
    margin-left: 8px;
    margin-right: 0;
    padding: 3px 10px;
  }
`

const GroupsPager = ({
  loading,
  groups = [], includeGroups, includeGroupMembWhitelist, includeGroupMembMapping,
  selectAllGroups, selectAllGroupMembWhitelist, groupWhitelist, groupMembWhitelist, groupTypes, trOpt
}) => {
  const [searchText, setSearchText] = useState('')
  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])
  const [restrictToWhitelist, setRestrictToWhitelist] = useState(true)
  const onWhitelistChange = useCallback(checked => setRestrictToWhitelist(checked), [setRestrictToWhitelist])

  const [searchGroups, setSearchGroups] = useState(groups)
  useEffect(() => {
    const searchTerm = searchText.toLowerCase().trim()
    let searchGroups = [...groups]

    if (isUUID(searchTerm)) {
      const matchGroup = groups.find(({ id }) => String(id).toLowerCase() === searchTerm)
      searchGroups = matchGroup ? [matchGroup] : []
    } else if (searchTerm) {
      searchGroups = groups.filter(({ name }) =>
        name && String(name).toLowerCase().includes(searchTerm))
    }

    if (restrictToWhitelist) {
      searchGroups = searchGroups.filter(({ inGroups, inGroupMemb, groupMembMap = [] }) => {
        return inGroups || inGroupMemb || !_isEmpty(groupMembMap)
      })
    }

    setSearchGroups(searchGroups)
  }, [searchText, restrictToWhitelist, groups, setSearchGroups])

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page)
  }, [setCurrentPage])
  const onShowSizeChange = useCallback((current, pageSize) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }, [setPageSize])

  const [groupPage, setGroupPage] = useState([])
  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const groupPage = searchGroups.slice(startIndex, endIndex)
    setGroupPage(groupPage)
  }, [searchGroups, currentPage, pageSize, setGroupPage])
  const pageCount = Math.ceil(searchGroups.length / pageSize)
  const maxPageCount = Math.ceil(searchGroups.length / 50)

  useEffect(() => {
    const pageCount = Math.ceil(searchGroups.length / pageSize)
    if (currentPage > pageCount) {
      setCurrentPage(1)
    }
  }, [searchGroups, pageSize, currentPage, setCurrentPage])

  const showPagination = maxPageCount > 1

  const [showRestrictToWhitelist, setShowRestrictToWhitelist] = useState(false)
  useEffect(() => {
    const showRestrictToWhitelist = (includeGroups && !selectAllGroups) ||
      (!includeGroups && includeGroupMembWhitelist && !selectAllGroupMembWhitelist) ||
      (includeGroups && includeGroupMembWhitelist && !(selectAllGroups || selectAllGroupMembWhitelist))
    setShowRestrictToWhitelist(showRestrictToWhitelist)
    if (!showRestrictToWhitelist) {
      setRestrictToWhitelist(false)
    }
  }, [setShowRestrictToWhitelist, setRestrictToWhitelist, includeGroups, includeGroupMembWhitelist, includeGroupMembMapping, selectAllGroups, selectAllGroupMembWhitelist])

  let restrictToKey
  if (includeGroups && includeGroupMembWhitelist) {
    restrictToKey = 'restrictToGroupsAndGroupsUsers'
  } else if (includeGroups) {
    restrictToKey = 'restrictToGroups'
  } else if (includeGroupMembWhitelist) {
    restrictToKey = 'restrictToGroupUsers'
  }

  const [groupCount, setGroupCount] = useState(0) // Synced Groups count
  const [groupMembCount, setGroupMembCount] = useState(0) // Groups Users are synced from count
  useEffect(() => {
    const { groupCount, groupMembCount } = getGroupAllowListCounts({ totalGroupCount: groups.length, groupWhitelist, groupMembWhitelist, includeGroups, includeGroupMembWhitelist, selectAllGroups, selectAllGroupMembWhitelist })
    setGroupCount(groupCount)
    setGroupMembCount(groupMembCount)
  }, [groups, groupWhitelist, groupMembWhitelist, includeGroups, includeGroupMembWhitelist, selectAllGroups, selectAllGroupMembWhitelist])

  return (
    loading ? null
      : (
        <GroupContainer>
          <CountContainer>
            {includeGroups && <Tag>{I18n.t('groupsIncludedCount', { ...trOpt, count: groupCount })}</Tag>}
            {includeGroupMembWhitelist && <Tag>{I18n.t('groupsUsersCount', { ...trOpt, count: groupMembCount })}</Tag>}
          </CountContainer>
          <ListHeader>
            <ListHeaderPanel align='left'>
              {
                showRestrictToWhitelist &&
                  <WhiteListSwitchContainer>
                    <Switch checked={restrictToWhitelist} onChange={onWhitelistChange} />
                    <span className='sync-smry-wl-switch__label'>{I18n.t(restrictToKey, trOpt)}</span>
                  </WhiteListSwitchContainer>
              }
            </ListHeaderPanel>
            <ListHeaderPanel align='right'>
              <SearchBar
                placeholder={I18n.t('sync.common.searchGroups')}
                value={searchText}
                allowClear
                onChange={onSearchChange}
              />
            </ListHeaderPanel>
          </ListHeader>
          <GroupsScroller>
            {_isEmpty(searchGroups) && showRestrictToWhitelist && restrictToWhitelist && (
              <Alert
                type='warning' showIcon
                message={I18n.t('noGroupsInAllowLists.title', trOpt)}
                description={I18n.t('noGroupsInAllowLists.description', trOpt)}
              />
            )}
            {_isEmpty(searchGroups) && !(showRestrictToWhitelist && restrictToWhitelist) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {!_isEmpty(searchGroups) && (
              <Groups>
                {
                  groupPage.map(group => (
                    <Group
                      key={group.id}
                      {...{ group, includeGroups, includeGroupMembWhitelist, includeGroupMembMapping, groupTypes, trOpt }}
                    />
                  ))
                }
              </Groups>
            )}
          </GroupsScroller>
          {
            showPagination &&
              <PaginationContainer>
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  pageSizeOptions={['50', '100', '250']}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={onPageChange}
                  total={searchGroups.length}
                  showQuickJumper={pageCount > 10}
                  showTotal={(total, range) => I18n.t('sync.common.groupPaginationTotal', { rangeStart: range[0], rangeEnd: range[1], total })}
                />
              </PaginationContainer>
          }
        </GroupContainer>
      )
  )
}

const GroupTypeTag = withTheme(({ theme, type, groupTypes }) => (
  <div>
    <Tag color={theme.primary}>
      {groupTypes[type] || type}
    </Tag>
  </div>
))

const Group = withRenderDelay(({
  group, hiddenClassName,
  includeGroups = false, includeGroupMembWhitelist = false, includeGroupMembMapping = false,
  groupTypes, trOpt
}) => {
  const { name, type, inGroups, inGroupMemb, groupMembMap = [] } = group
  const inGroupMembChecked = inGroupMemb || inGroups
  const yes = I18n.t('common.yes')
  const no = I18n.t('common.no')

  return (
    <GroupCard className={hiddenClassName}>
      <Card size='small'>
        <div className='sync-smry-group__header'>
          <h4 className='sync-smry-group__title'>{name}</h4>
          <GroupTypeTag {...{ type, groupTypes }} />
        </div>
        {
          (includeGroups || includeGroupMembWhitelist) &&
            <Descriptions>
              {includeGroups && <Descriptions.Item span={3} label={I18n.t('groupIncludedInSync', trOpt)}>{inGroups ? yes : no}</Descriptions.Item>}
              {includeGroupMembWhitelist && <Descriptions.Item span={3} label={I18n.t('groupsUsersIncludedInSync', trOpt)}>{inGroupMembChecked ? yes : no}</Descriptions.Item>}
            </Descriptions>
        }
        {
          includeGroupMembMapping && !_isEmpty(groupMembMap) && (
            <div className='sync-smry-group__map-tags'>
              <h5>{I18n.t('sync.common.mapToExistingGroups')}</h5>
              <div>
                {
                  groupMembMap.map(({ name }, index) => (
                    <Tag key={index} closable={false}>{name}</Tag>
                  ))
                }
              </div>
            </div>
          )
        }
      </Card>
    </GroupCard>
  )
})

class SyncConfigSummaryGroups extends Component {
  constructor (props) {
    super(props)

    this.state = {
      usecureError: null,
      syncError: null,
      loadingSync: true,
      loadingUsecure: true,
      groups: [],
      usecureGroups: []
    }
  }

  get hasGroupConfig () {
    const settingsKey = this.getSettingsKey()

    const {
      [settingsKey]: {
        includeGroups,
        includeGroupMembWhitelist,
        includeGroupMembMapping
      }
    } = this.props
    return includeGroups || includeGroupMembWhitelist || includeGroupMembMapping
  }

  get loading () {
    const { loadingSync, loadingUsecure } = this.state
    return loadingSync || loadingUsecure
  }

  get error () {
    const { usecureError, syncError } = this.state
    const error = [usecureError, syncError].filter(e => !_isNil(e))
    return _isEmpty(error) ? null : error
  }

  updateState (stateUpdate) {
    return new Promise(resolve => this.setState(stateUpdate, resolve))
  }

  async loadUsecureGroups () {
    try {
      await this.updateState({ loadingUsecure: true })
      const { data: { groupTree: usecureGroups = [] } = {} } = await this.props.client.query({ query: GET_GROUP_TREE })
      return this.updateState({ usecureGroups, usecureError: null, loadingUsecure: false })
    } catch (e) {
      console.error('SyncConfigSummaryGroups.loadUsecureGroups - Error', e)
      return this.updateState({ usecureError: e, loadingUsecure: false })
    }
  }

  async loadSyncGroupsUsingSingleQuery (variables) {
    const { data: { [this.props.groupsQueryKey]: groups = [] } = {} } = await this.props.client.query({ query: this.props.groupsQuery, variables })
    return groups
  }

  async loadSyncGroupUsingPagination (variables) {
    const { data: { [this.props.groupsQueryKey]: response } = {} } = await this.props.client.query({ query: this.props.groupsQuery, variables })
    const { groups = [], nextPageUrl, accessToken } = response || {}

    if (nextPageUrl) {
      const pageGroups = await this.loadSyncGroupUsingPagination({ ...variables, pageUrl: nextPageUrl, accessToken })
      return [...groups, ...pageGroups]
    }
    return groups
  }

  async loadSyncGroups () {
    const settingsKey = this.getSettingsKey()

    try {
      await this.updateState({ loadingSync: true })
      const {
        [settingsKey]: {
          groupType: type,
          selectAllGroups, groupWhitelist = [], selectAllGroupMembWhitelist, groupMembWhitelist = [],
          includeGroups, includeGroupMembWhitelist, includeGroupMembMapping
        } = {},
        usePaginatedGroupLoad
      } = this.props

      let rawGroups = []
      if (usePaginatedGroupLoad) {
        rawGroups = await this.loadSyncGroupUsingPagination({ type })
      } else {
        rawGroups = await this.loadSyncGroupsUsingSingleQuery({ type })
      }

      const groupMembMap = this.getGroupMembershipMap()
      const groups = rawGroups.map(group => ({
        ...group,
        inGroups: includeGroups && (selectAllGroups === true || groupWhitelist.includes(group.id)),
        inGroupMemb: includeGroupMembWhitelist && (selectAllGroupMembWhitelist === true || groupMembWhitelist.includes(group.id)),
        groupMembMap: (includeGroupMembWhitelist && includeGroupMembMapping && _isArray(groupMembMap[group.id])) ? groupMembMap[group.id] : []
      }))
      groups.sort((a, b) => a.name.localeCompare(b.name))

      return this.updateState({
        groups,
        loadingSync: false,
        syncError: null
      })
    } catch (e) {
      console.error('SyncConfigSummaryGroups.loadSyncGroups - Error', e)
      return this.updateState({ syncError: e, loadingSync: false })
    }
  }

  getGroupMembershipMap () {
    const settingsKey = this.getSettingsKey()

    const { [settingsKey]: { includeGroupMembMapping, groupMembMap = {} } = {} } = this.props
    if (!includeGroupMembMapping) {
      return {}
    }

    const groupIds = Object.keys(groupMembMap)
    const hasGroupMembMap = groupIds.length > 0 && groupIds.some(groupId => _isArray(groupMembMap[groupId]) && groupMembMap[groupId].length > 0)
    if (!hasGroupMembMap) {
      return {}
    }

    const usecureGroupMap = this.getUsecureGroupMap(this.state.usecureGroups, true)

    return groupIds.reduce((map, groupId) => {
      let groupMap = groupMembMap[groupId]
      if (_isArray(groupMap) && groupMap.length > 0) {
        groupMap = groupMap.map(usecureGroupId => usecureGroupMap[usecureGroupId])
          .filter(group => !_isNil(group))
        if (groupMap.length > 0) {
          map[groupId] = groupMap
        }
      }
      return map
    }, {})
  }

  getUsecureGroupMap (groups, excludeSync = false) {
    const groupMap = groups.reduce((groupMap, { id, name, syncRecordId, syncType, children = [] }) => {
      if (!excludeSync || _isNil(syncRecordId) || _isNil(syncType)) {
        groupMap[id] = { id, name }
      }
      return {
        ...groupMap,
        ...this.getUsecureGroupMap(children, excludeSync)
      }
    }, {})

    return groupMap
  }

  async loadGroups () {
    if (this.hasGroupConfig) {
      await this.updateState({ loadingUsecure: true, loadingSync: true })
      await this.loadUsecureGroups()
      await this.loadSyncGroups()
    }
  }

  async componentDidMount () {
    return this.loadGroups()
  }

  async componentWillUpdate (prevProps) {
    const settingsKey = this.getSettingsKey()

    const { [settingsKey]: syncSettings } = this.props
    const { [settingsKey]: prevSyncSettings } = prevProps
    const diffProps = ['groupType', 'selectAllGroups', 'groupWhitelist', 'selectAllGroupMembWhitelist', 'groupMembWhitelist', 'includeGroups', 'includeGroupMembWhitelist', 'includeGroupMembMapping']
    const groupConfig = _pick(syncSettings, diffProps)
    const prevGroupConfig = _pick(prevSyncSettings, diffProps)

    if (!_isEqual(groupConfig, prevGroupConfig)) {
      return this.loadGroups()
    }
  }

  getSettingsKey () {
    const {
      syncType
    } = this.props

    switch (syncType) {
      case 'microsoft':
        return 'office365'
      case 'google':
        return 'googleSync'
      default:
        return syncType
    }
  }

  render () {
    const {
      trOpt = { scope: 'sync.syncConfigSummaryGroups' },
      groupTypes
    } = this.props
    if (!this.hasGroupConfig) {
      return null
    }

    const settingsKey = this.getSettingsKey()

    const settings = _get(this.props, settingsKey, {})

    const { error } = this
    if (error) {
      return (
        <ErrorAlerts
          {...{ error }}
          defaultError={I18n.t('groupConfigError', trOpt)}
        />
      )
    }

    return (
      <>
        <LoadingBlock fullScreen={false} loading={this.loading} />
        <GroupsPager groups={this.state.groups} loading={this.loading} {...{ trOpt, groupTypes, settings }} {...settings} />
      </>
    )
  }
}

export default compose(
  connect(
    state => _pick(selectors.settings.get(state), ['googleSync', 'office365'])
  ),
  withConsumer
)(SyncConfigSummaryGroups)
