import React, { useState, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { css } from 'emotion'
import { Editor, getEventTransfer } from 'slate-react'
import { Value } from 'slate'
import styled from 'styled-components'
import { Upload, message, Form, Select, Modal, Button as AntButton } from 'antd'
import Lists from '@convertkit/slate-lists'
import isUrl from 'is-url'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { Button, SlateComponents, Menu } from '../common/SlateComponents'
import CompactPicker from './CustomCompactColorPicker'
import { apiUrl } from '../../apollo-client/common'
import { getSessionToken } from '../../helpers/session'

const trOpt = { scope: 'mutationForm.mutationFormSlate' }
const editorTrOpt = { scope: 'common.slateEditor' }
const { Option } = Select

const EditorContainer = styled(Editor)`
  min-height: 200px;
  line-height: 1.42;
  max-height: 50vh;
  overflow-y: auto;

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    color: #4c4e4d;
    line-height: 1.1;
  }  
  
  h1 {
      font-size: 32px
  }
  
  h2 {
    font-size: 30px;
  }
  
  h3 {
    font-size: 28px;
  }
  
  h4 {
    font-size: 26px;
  }
  
  h5 {
    font-size: 24px;
  }

  img {
    max-width: 100%;
  }

  b, strong {
    font-weight: bold;
  }
  
  p {
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    color: rgb(124, 112, 107);
  }
  
  ul {
    margin-bottom:1.8em;
  }

  ul > li > ul {
    margin-bottom: 0;
  }
  
  ul li {    
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px;
    font-weight: 400;

    &> span {
      display: inline-block;
      vertical-align: top;
    }
  }
  
  ol {
    counter-reset: ol-counter;
    margin-left: 25px;
    
    li {
      list-style-type: none;
      margin-bottom: 1.75rem !important;
      padding-left: 15px;
      position: relative;
      
      span {
        font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
        font-size: 20px;
        line-height: 1.4;
        font-weight: 400;
        color: rgb(124,112,107);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        background-color: ${({ theme }) => theme.primary};
        color: white;
        content: counters(ol-counter, ".") " ";
        counter-increment: ol-counter;
        height: 2rem;
        left: -40px;
        padding: 0.15rem;
        position: absolute;
        text-align: center;
        top: -0.1rem;
        min-width: 2rem;
      }
    }
  }
  
  blockquote {
    border: none;
    box-sizing: border-box;
    color: #4c4e4d;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 60px 30px;
    position: relative;
    quotes: "\\201C" "\\201D" "\\2018" "\\2019";
    text-align: center;
    width: 95%;
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 2.6em;
    line-height: 1.4;
    font-weight: 400;
    
    
    :before, :after {
      color: #4c4e4d;
      font-family: 'Impact', sans-serif !important;
      font-size: 7rem;
      font-style: normal;
      height: 60px;
      line-height: 1;
      position: absolute;
      width: 60px;
    }
    
    :before {
      content: open-quote;
      top: 0;
      left: 5px;
    }
    
    :after {
      content: close-quote;
      bottom: 0;
      right: 5px;
    }
  }

`

const DEFAULT_NODE = 'paragraph'
const ALIGN_BLOCK_TYPES = ['align-left', 'align-center', 'align-right', 'align-justify']
const WRAP_BLOCK_TYPES = [...ALIGN_BLOCK_TYPES, 'bulleted-list', 'numbered-list']

const MarkButton = ({ editor, type, icon }) => {
  const { value } = editor
  const isActive = value.activeMarks.some(mark => mark.type === type)

  return (
    <Button
      active={isActive}
      onMouseDown={event => {
        event.preventDefault()
        editor.toggleMark(type)
      }}
    >
      <i className={`fa fa-${icon}`} />
    </Button>
  )
}

// Menu appears on Hover
const HoverMenu = React.forwardRef(({ editor }, ref) => {
  const root = window.document.getElementById('root')
  return ReactDOM.createPortal(
    <Menu
      ref={ref}
      className={css`
        padding: 8px 7px 6px;
        position: absolute;
        z-index: 1;
        top: -10000px;
        left: -10000px;
        margin-top: -6px;
        opacity: 0;
        background-color: #222;
        border-radius: 4px;
        transition: opacity 0.75s;
      `}
    >
      <MarkButton editor={editor} type='bold' icon='bold' />
      <MarkButton editor={editor} type='italic' icon='italic' />
      <MarkButton editor={editor} type='underlined' icon='underline' />
      <MarkButton editor={editor} type='strikethrough' icon='strikethrough' />
    </Menu>,
    root
  )
})

function insertImage (editor, src, target) {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'image',
    data: { src }
  })
}

/**
 * A change helper to standardize wrapping links.
 *
 * @param {Editor} editor
 * @param {String} href
 */

function wrapLink (editor, href) {
  editor.wrapInline({
    type: 'link',
    data: { href }
  })

  editor.moveToEnd()
}

/**
 * A change helper to standardize unwrapping links.
 *
 * @param {Editor} editor
 */

function unwrapLink (editor) {
  editor.unwrapInline('link')
}

const schema = {
  blocks: {
    image: {
      isVoid: true
    }
  }
}

const initialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: ''
          }
        ]
      }
    ]
  }
})

const plugins = [Lists({
  blocks: {
    ordered_list: 'ordered-list',
    unordered_list: 'unordered-list',
    list_item: 'list-item'
  },
  classNames: {
    ordered_list: 'ordered-list',
    unordered_list: 'unordered-list',
    list_item: 'list-item'
  }
})]

const PickerPopover = styled.div`
  top: 30px;
  position: absolute;
  z-index: 2;
`
const PickerCover = styled.div`
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
`

const PickerButtonIcon = styled.i`
  color: ${({ color }) => color || 'inherit'};
`

const ColorPickerMarkButton = ({ type, active, color, icon, onClick = () => { }, onChange = () => { } }) => {
  const [showPicker, updateShowPicker] = useState(false)
  const onMouseDown = useCallback(event => {
    event.preventDefault()
    updateShowPicker(true)
    onClick(type)
  }, [onClick, type])
  const onChangeComplete = useCallback(color => onChange(type, color.hex), [type, onChange])
  const closePicker = () => updateShowPicker(false)

  return (
    <div>
      <Button
        active={active || showPicker}
        onMouseDown={onMouseDown}
      >
        <PickerButtonIcon className={`fa fa-${icon}`} color={color} />
      </Button>
      {
        showPicker ? (
          <PickerPopover>
            <PickerCover onClick={closePicker} />
            <CompactPicker
              styles={{ default: { compact: { width: '140px' }, wrap: { transform: 'translateX(-50%)' } } }}
              colors={[
                '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
                '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
              ]}
              color={color} onChangeComplete={onChangeComplete}
            />
          </PickerPopover>
        ) : null
      }
    </div>
  )
}

const StyleBlockDropdown = ({ types = [], activeType = 'reset-block', onSelect: onSelectProp = () => { } }) => {
  const onSelect = useCallback(type => onSelectProp(type), [onSelectProp])
  const options = useMemo(() => types.map(({ value, label, element }) => (
    <Option value={value} key={value}>
      {element ? React.createElement(element, null, label) : label}
    </Option>
  )), [types])

  return (
    <div>
      <Select value={activeType} onSelect={onSelect} style={{ width: 140 }}>
        {options}
      </Select>
    </div>
  )
}

const ColorMark = ({ type, data, attributes, children }) => {
  const color = data.get('color')
  const colorProp = type === 'background' ? 'backgroundColor' : 'color'
  return color ? <span style={{ [colorProp]: color }} {...attributes}>{children}</span> : null
}

class MutationFormSlate extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value ? Value.fromJSON(props.value) : initialValue,
      selectionOnAction: null
    }

    this.menuRef = React.createRef()
    this.handleBlockType = this.handleBlockType.bind(this)
    this.handleHelpClick = this.handleHelpClick.bind(this)
    this.insertText = this.insertText.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onClickBlock = this.onClickBlock.bind(this)
    this.onClickImage = this.onClickImage.bind(this)
    this.handleClickLink = this.handleClickLink.bind(this)
    this.onClickMark = this.onClickMark.bind(this)
    this.handleClickColorMark = this.handleClickColorMark.bind(this)
    this.handleColorMarkChange = this.handleColorMarkChange.bind(this)
    this.handleImageUpload = this.handleImageUpload.bind(this)
    this.handleShortcutFormatting = this.handleShortcutFormatting.bind(this)
    this.handlePaste = this.handlePaste.bind(this)
    this.ref = this.ref.bind(this)
    this.renderBlock = this.renderBlock.bind(this)
    this.renderInline = this.renderInline.bind(this)
    this.renderMark = this.renderMark.bind(this)
    this.toggleOrderedList = this.toggleOrderedList.bind(this)
    this.toggleUnorderedList = this.toggleUnorderedList.bind(this)
    this.hasLinks = this.hasLinks.bind(this)
    this.renderEditor = this.renderEditor.bind(this)
    this.reset = this.reset.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
  }

  componentDidMount () {
    this.updateMenu()
  }

  componentDidUpdate () {
    this.updateMenu()
  }

  reset () {
    this.setState({ value: this.props.value ? Value.fromJSON(this.props.value) : initialValue })
  }

  scrollToEditorBottom () {
    return this.props.editorEndRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  handleOnSelect (event, editor, next) {
    event.preventDefault()
    event.stopPropagation()
    this.scrollToEditorBottom()
    next()
  }

  handleHelpClick () {
    const { keyboardShortcutsIntro = I18n.t('keyboardShortcutsIntro', trOpt) } = this.props

    Modal.info({
      title: I18n.t('help.keyboardShortcuts', editorTrOpt),
      content: (
        <div>
          <p>{keyboardShortcutsIntro}</p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>B</AntButton>: <strong>{I18n.t('help.bold', editorTrOpt)}</strong></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>I</AntButton>: <i>{I18n.t('help.italic', editorTrOpt)}</i></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>S</AntButton>: <strike>{I18n.t('help.strikethrough', editorTrOpt)}</strike></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>U</AntButton>: <u>{I18n.t('help.underlined', editorTrOpt)}</u></p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>H</AntButton>: {I18n.t('help.toggleHeaders', editorTrOpt)}</p>
          <p><AntButton type='primary'>CTRL</AntButton> <AntButton type='primary'>Q</AntButton>: {I18n.t('help.quote', editorTrOpt)}</p>
        </div>
      )
    })
  }

  renderMarkButton (type, icon) {
    const isActive = this.hasMark(type)

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickMark(event, type)}
      >
        <i className={`fa fa-${icon}`} />
      </Button>
    )
  }

  renderHelpButton () {
    return (
      <Button onMouseDown={null} onClick={this.handleHelpClick}>
        <i className='fa fa-question-circle' />
      </Button>
    )
  }

  renderLinkButton (icon) {
    const isActive = this.hasLinks()

    return (
      <Button
        active={isActive}
        onMouseDown={this.handleClickLink}
      >
        <i className={`fa fa-${icon}`} />
      </Button>
    )
  }

  renderColorMarkButton (type, icon) {
    const isActive = this.hasMark(type)
    // Get mark and set icon font color to match data
    let color
    if (isActive) {
      const typeMark = _get(this.state, 'value.activeMarks', []).find(mark => mark.type === type)
      color = typeMark ? typeMark.data.get('color') : undefined
    }

    return (
      <ColorPickerMarkButton
        active={isActive}
        {...{ type, icon, color }}
        onClick={this.handleClickColorMark}
        onChange={this.handleColorMarkChange}
      />
    )
  }

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasMark (type) {
    const { value } = this.state
    return value.activeMarks.some(mark => mark.type === type)
  }

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasBlock (type) {
    const { value } = this.state
    return value.blocks.some(node => node.type === type)
  }

  /**
   * Check whether the current selection has a link in it.
   *
   * @return {Boolean} hasLinks
   */

  hasLinks () {
    const { value } = this.state
    return value.inlines.some(inline => inline.type === 'link')
  }

  /**
   * Store a reference to the `editor`.
   *
   * @param {Editor} editor
   */

  ref (editor) {
    this.editor = editor
  }

  handleChange ({ value }) {
    this.setState({ value })
    this.props.onChange(this.props.id, value)
  }

  renderBlock (props, editor, next) {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'paragraph':
        return <p {...attributes}>{children}</p>
      case 'quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
      case 'heading-three':
        return <h3 {...attributes}>{children}</h3>
      case 'heading-four':
        return <h4 {...attributes}>{children}</h4>
      case 'heading-five':
        return <h5 {...attributes}>{children}</h5>
      case 'heading-six':
        return <h6 {...attributes}>{children}</h6>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      case 'list-item-child':
        return <div {...attributes}>{children}</div>
      case 'ordered-list':
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      case 'image':
        return <img {...attributes} src={node.data.get('src')} alt='' />
      case 'align-left':
      case 'align-center':
      case 'align-right':
      case 'align-justify':
        return <div {...attributes} style={{ textAlign: node.type.replace('align-', '') }}>{children}</div>
      default:
        return next()
    }
  }

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderMark (props, editor, next) {
    const { children, mark, attributes } = props
    const { type, data = new Map() } = mark || {}

    switch (type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>
      case 'italic':
        return <em {...attributes}>{children}</em>
      case 'underlined':
        return <u {...attributes}>{children}</u>
      case 'strikethrough':
        return <span style={{ textDecoration: 'line-through' }} {...attributes}>{children}</span>
      case 'background':
      case 'color':
        return <ColorMark {...{ type, data, attributes, children }} />
      default:
        return next()
    }
  }

  /**
   * Render a Slate inline.
   *
   * @param {Object} props
   * @param {Editor} editor
   * @param {Function} next
   * @return {Element}
   */

  renderInline (props, editor, next) {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'link': {
        const { data } = node
        const href = data.get('href')
        return (
          <a {...attributes} href={href} target='_blank' rel='noopener noreferrer'>
            {children}
          </a>
        )
      }

      default: {
        return next()
      }
    }
  }

  /**
   * When clicking a link, if the selection has a link in it, remove the link.
   * Otherwise, add a new link with an href and text.
   *
   * @param {Event} event
   */

  handleClickLink (event) {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const hasLinks = this.hasLinks()

    if (hasLinks) {
      editor.command(unwrapLink)
    } else if (value.selection.isExpanded) {
      const href = window.prompt(I18n.t('enterLinkUrl', editorTrOpt))

      if (href == null) {
        return
      }

      editor.command(wrapLink, href)
    } else {
      const href = window.prompt(I18n.t('enterLinkUrl', editorTrOpt))

      if (href == null) {
        return
      }

      const text = window.prompt(I18n.t('enterLinkText', editorTrOpt))

      if (text == null) {
        return
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapLink, href)
    }
  }

  /**
   * Render a block-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @param {Function} event
   * @return {Element}
   */

  renderBlockButton (type, icon, event = null) {
    let isActive = this.hasBlock(type) || this.isWrappedIn(type)
    // align-left should be active unless block is wrapped in another align type
    if (type === 'align-left' && !this.isWrappedIn(ALIGN_BLOCK_TYPES)) {
      isActive = true
    }

    let iconComponent = null
    if (icon) {
      iconComponent = <i className={`fa fa-${icon}`} />
    } else if (type === 'heading-one') {
      // TODO Find an actual icon
      iconComponent = <span>H1</span>
    } else if (type === 'heading-two') {
      // TODO Find an actual icon
      iconComponent = <span>H2</span>
    }

    if (event) {
      return (
        <Button
          active={isActive}
          onMouseDown={event}
        >
          {iconComponent}
        </Button>
      )
    } else {
      return (
        <Button
          active={isActive}
          onMouseDown={event => this.onClickBlock(event, type)}
        >
          {iconComponent}
        </Button>
      )
    }
  }

  /**
   * Render a dropdown-toggling toolbar button.
   *
   * @return {Element}
   */

  renderStyleDropdown () {
    const styleTypes = [
      { value: 'heading-one', label: I18n.t('heading1', editorTrOpt), element: 'h1' },
      { value: 'heading-two', label: I18n.t('heading2', editorTrOpt), element: 'h2' },
      { value: 'heading-three', label: I18n.t('heading3', editorTrOpt), element: 'h3' },
      { value: 'heading-four', label: I18n.t('heading4', editorTrOpt), element: 'h4' },
      { value: 'heading-five', label: I18n.t('heading5', editorTrOpt), element: 'h5' },
      { value: 'heading-six', label: I18n.t('heading6', editorTrOpt), element: 'h6' },
      { value: 'reset-block', label: I18n.t('normal', editorTrOpt) }
    ]
    const { value: activeType = 'reset-block' } = styleTypes.find(({ value: type }) => this.hasBlock(type)) || 'reset-block'

    return (
      <StyleBlockDropdown types={styleTypes} activeType={activeType} onSelect={this.handleBlockType} />
    )
  }

  insertText (text) {
    this.editor.insertText(text)
  }

  /**
   * When a mark button is clicked, toggle the current mark.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickMark (event, type) {
    event.preventDefault()
    this.editor.toggleMark(type)
  }

  handleClickColorMark () {
    const selection = _get(this.editor, 'value.selection')
    if (selection) {
      // Record current selection for reinstatement upon color selection
      this.selectionOnAction = selection.toJSON()
    }
  }

  removeMark (type) {
    this.editor.value.marks
      .filter(mark => mark.type === type)
      .forEach(mark => this.editor.removeMark(mark))
  }

  handleColorMarkChange (type, color) {
    this.removeMark(type)
    this.editor.addMark({
      type,
      data: {
        color
      }
    })
    if (this.selectionOnAction) {
      // Reinstate selection
      // TODO Revisit this as it doesn't always work
      this.editor.select(this.selectionOnAction)
      delete this.selectionOnAction
    }
  }

  /**
   * On paste, if the text is a link, wrap the selection in a link.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @param {Function} next
   */

  handlePaste (event, editor, next) {
    if (editor.value.selection.isCollapsed) return next()

    const transfer = getEventTransfer(event)
    const { type, text } = transfer
    if (type !== 'text' && type !== 'html') return next()
    if (!isUrl(text)) return next()

    if (this.hasLinks()) {
      editor.command(unwrapLink)
    }

    editor.command(wrapLink, text)
  }

  isWrappedIn (types) {
    const document = _get(this, 'editor.value.document')
    const blocks = _get(this, 'editor.value.blocks')
    let isWrapped = false

    types = Array.isArray(types) ? types : [types]

    if (document && blocks) {
      isWrapped = blocks.some(node => {
        const parent = document.getParent(node.key)
        return parent && types.includes(parent.type)
      })
    }

    return isWrapped
  }

  /**
   * Unwraps the types provided from a block if the parent matches them
   * @param {String[]} types
   */
  unwrapBlock (types = [], selectedOnly = false) {
    if (!(types && types.length > 0)) {
      return
    }

    const { editor } = this
    const { value } = editor
    const { blocks, document, selection } = value
    blocks
      .reduce((wrapTypes, node) => {
        const parent = document.getParent(node.key)
        if (parent) {
          if (types.includes(parent.type) &&
            !wrapTypes.includes(parent.type)) {
            wrapTypes.push(parent.type)
          }
        }
        return wrapTypes
      }, [])
      .forEach(type => selectedOnly ? editor.unwrapBlockAtRange(selection, type) : editor.unwrapBlock(type))
  }

  /**
   * When a block button is clicked, toggle the block type.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickBlock (event, type) {
    event.preventDefault()
    this.handleBlockType(type)
  }

  handleBlockType (type) {
    const { editor } = this
    const { value } = editor
    const { document, selection } = value

    if (type === 'image') {
      return this.onClickImage()
    }

    if (type === 'remove-format' || type === 'reset-block') {
      // Remove formatting
      // Reset selected block to default node
      editor.setBlocksAtRange(selection, DEFAULT_NODE)
      if (type === 'remove-format') {
        // Clear marks
        value.marks.forEach(mark => editor.removeMark(mark))
        // Remove Alignment and list wrappings
        this.unwrapBlock(WRAP_BLOCK_TYPES, true)
      }
    } else if (type.indexOf('align-') === 0) {
      const isActive = this.isWrappedIn(type)
      if (!isActive) {
        this.unwrapBlock(ALIGN_BLOCK_TYPES)
        editor.wrapBlock(type)
      }
    } else if (type !== 'bulleted-list' && type !== 'numbered-list') {
      // Handle everything but list buttons.
      const isActive = this.hasBlock(type)
      const isList = this.hasBlock('list-item')

      if (isList) {
        editor.setBlocks(isActive ? DEFAULT_NODE : type).unwrapBlock('bulleted-list').unwrapBlock('numbered-list')
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item')
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor.setBlocks(DEFAULT_NODE).unwrapBlock('bulleted-list').unwrapBlock('numbered-list')
      } else if (isList) {
        editor.unwrapBlock(
          type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
        ).wrapBlock(type)
      } else {
        editor.setBlocks('list-item').wrapBlock(type)
      }
    }
  }

  onClickImage () {
    document.getElementById('courseWysiwyg').click()
  }

  handleImageUpload (info) {
    if (info.file.status === 'done') {
      message.success(I18n.t('imageUploadSuccessMessage', { ...editorTrOpt, file: info.file.name }))
      this.editor.command(insertImage, info.file.response.url)
    } else if (info.file.status === 'error') {
      message.error(I18n.t('imageUploadErrorMessage', { ...editorTrOpt, file: info.file.name }))
    }
  }

  toggleUnorderedList (event) {
    event.preventDefault()
    this.editor.toggleList()
  }

  toggleOrderedList (event) {
    event.preventDefault()
    this.editor.toggleList({ type: 'ordered-list' })
  }

  indent (event) {
    event.preventDefault()
    this.editor.current.increaseListItemDepth()
  }

  outdent (event) {
    event.preventDefault()
    this.editor.current.decreaseListItemDepth()
  }

  handleShortcutFormatting (event, editor, next) {
    if (!event.ctrlKey) return next()

    switch (event.key) {
      case 'b':
        // Stops browsers handling the shortcut
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('bold')
        break
      case 'i':
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('italic')
        break
      case 'u':
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('underlined')
        break
      case 's':
        event.preventDefault()
        event.stopPropagation()
        editor.toggleMark('strikethrough')
        break
      case 'q':
        event.preventDefault()
        event.stopPropagation()
        editor.setBlocks('block-quote')
        break
      case 'h':
        event.preventDefault()
        event.stopPropagation()
        this.formatHeadingShortcut(editor)
        break
      default:
        next()
    }
  }

  // JG: Slate was ignoring text is entered with an input method editor. Here we are listening for the compositionend event and forcing slate to insert the text at the current cursor location.
  handleCompositionEnd (event, editor, next) {
    editor.insertText(event.data)
    next()
  }

  formatHeadingShortcut (editor) {
    const text = editor.value.blocks.some(block => ['heading-one', 'heading-two', 'paragraph'].includes(block.type))
    if (text) {
      const headerOne = editor.value.blocks.some(block => block.type === 'heading-one')
      const headerTwo = editor.value.blocks.some(block => block.type === 'heading-two')
      const paragraph = editor.value.blocks.some(block => block.type === 'paragraph')

      if (paragraph) editor.setBlocks('heading-one')
      if (headerOne) editor.setBlocks('heading-two')
      if (headerTwo) editor.setBlocks('paragraph')
    }
  }

  updateMenu () {
    const menu = this.menuRef.current
    if (!menu) return

    const { value } = this.state
    const { fragment, selection } = value

    if (selection.isBlurred || selection.isCollapsed || fragment.text === '') {
      menu.removeAttribute('style')
      return
    }

    const native = window.getSelection()
    const range = native.getRangeAt(0)
    const rect = range.getBoundingClientRect()
    menu.style.opacity = 1
    menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`

    menu.style.left = `${rect.left +
      window.pageXOffset -
      menu.offsetWidth / 2 +
      rect.width / 2}px`
  }

  /**
   * Render the editor.
   *
   * @param {Object} props
   * @param {Function} next
   * @param {Editor} editor
   * @return {Element}
   */

  renderEditor (props, editor, next) {
    const children = next()

    return (
      <>
        {children}
        <HoverMenu ref={this.menuRef} editor={editor} />
      </>
    )
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    // TODO action shouldn't be hardcoded
    return (
      <>
        <Form.Item label={this.props.label}>
          <Upload name='courseWysiwyg' onChange={this.handleImageUpload} style={{ display: 'none' }} showUploadList={false} action={`${apiUrl}/upload-course-wysiwyg`} headers={{ 'x-token': getSessionToken() }}>
            <Button id='courseWysiwyg' />
          </Upload>
          <SlateComponents>
            {this.renderMarkButton('bold', 'bold')}
            {this.renderMarkButton('italic', 'italic')}
            {this.renderMarkButton('underlined', 'underline')}
            {this.renderMarkButton('strikethrough', 'strikethrough')}
            {this.renderLinkButton('link')}

            {/* TODO Heading icons */}
            {this.renderBlockButton('heading-one')}
            {this.renderBlockButton('heading-two')}
            {this.renderBlockButton('block-quote', 'quote-left')}
            {this.renderBlockButton('ordered-list', 'list-ol', this.toggleOrderedList)}
            {this.renderBlockButton('unordered-list', 'list-ul', this.toggleUnorderedList)}
            {this.renderBlockButton('image', 'image')}
            {this.renderStyleDropdown()}
            {this.renderColorMarkButton('color', 'eye-dropper')}
            {this.renderColorMarkButton('background', 'highlighter')}
            {this.renderBlockButton('align-left', 'align-left')}
            {this.renderBlockButton('align-center', 'align-center')}
            {this.renderBlockButton('align-right', 'align-right')}
            {this.renderBlockButton('align-justify', 'align-justify')}
            {this.renderBlockButton('remove-format', 'remove-format')}
            {this.renderHelpButton()}
          </SlateComponents>
          <EditorContainer
            ref={this.ref}
            schema={schema}
            plugins={plugins}
            value={this.state.value}
            onChange={this.handleChange}
            onKeyDown={this.handleShortcutFormatting}
            onPaste={this.handlePaste}
            renderEditor={this.renderEditor}
            renderBlock={this.renderBlock}
            renderMark={this.renderMark}
            renderInline={this.renderInline}
            onCompositionEnd={this.handleCompositionEnd}
            onSelect={this.handleOnSelect}
          />
        </Form.Item>
      </>
    )
  }
}

export default MutationFormSlate
