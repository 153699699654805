import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { BrandColours } from '../../helpers/Graphs'

const LineChart = ({ data = [], animate = false, ...rest }) => {
  const chartProps = {
    colors: BrandColours,
    curve: 'monotoneX',
    lineWidth: 4,
    animate: animate,
    enableGridX: false,
    enableArea: true,
    margin: {
      top: 40,
      right: 80,
      bottom: 80,
      left: 80
    },
    yScale: { type: 'linear', stacked: false, min: 0, max: 100 },
    pointSize: 10,
    pointBorderWidth: 2,
    pointBorderColor: { from: 'seriesColor' },
    pointLabel: 'y',
    pointLabelYOffset: -12,
    useMesh: true,
    ...rest
  }
  return (
    <ResponsiveLine
      data={data}
      {...chartProps}
    />
  )
}

export default LineChart
