import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button as _Button } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import { navigateToApp } from '../../helpers/signIn'

const Button = styled(_Button)`
  display: block;
  margin: 0 auto;
`

const GoToLoginButton = () => {
  const history = useHistory()
  // Clicking the button will take the user to login page (i.e. routes.HOME) after verifying their email, setting their first password or performing a password reset.
  // In the unlikely event the user was logged in already, this will open the home page instead.
  // At the time of writing there is a ticket to invalidate existing sessions when user's password is updated. The "already logged in" edge case should be covered by that.
  const onClick = useCallback(() => navigateToApp({ history }), [history])

  return (
    <Button type='primary' ghosted onClick={onClick}>{I18n.t('signIn.goToLogin')}</Button>
  )
}

export default GoToLoginButton
