import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Badge, Button, Card, Checkbox, Modal, Switch, message } from 'antd'
import I18n from 'i18n-js'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import styled from 'styled-components'
import _get from 'lodash/get'
import _intersection from 'lodash/intersection'
import _uniq from 'lodash/uniq'

import { addDelay, showErrors } from '../../helpers'
import { LanguageDropdown, LoadingBlock } from '../common'
import { CLONE_SOURCE_LOCALE_SLIDES, GET_COURSE_SLIDES, GET_COURSE_SLIDE_LOCALES } from '../Queries/Courses'
import { CONTENT_TYPE_NAMES } from '../EditCourse/common'
import { LANGUAGE_CODES } from '../../constants/languages'

const trOpt = { scope: 'modals.cloneSourceLocaleCourseSlidesModal' }

const FormContainer = styled.div``
const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 0px;
`
const SlideContainer = styled.div`
  border: 1px solid #e8e8e8;
  margin-bottom: 15px;
  max-height: 400px;
  overflow-y: auto;
  padding: 5px;

  .ant-card {
    margin-bottom: 5px;
  }
  .ant-card-body {
    padding: 12px;
  }
`

const SwitchLabel = styled.span`
  display: inline-block;
  line-height: 1.5;
  margin-left: 15px;
`

const CheckboxSlideContainer = styled.div`
  align-items: flex-start;
  display: flex;
`

const CheckboxSlideWrap = styled.div`
  background-color: ${({ theme }) => theme.white};
  flex: 1;
  padding-left: 10px;
`

const CheckboxSlideHeaderWrap = styled.div`
  display: flex;
`

const CheckboxSlideDetailWrap = styled.div`
  flex: 1;
`

const SlideNumType = styled.div`
  margin-bottom: 5px;
`

const SlideType = styled.span`
  font-size: 16px;
  font-weight: bold;
  vertical-align: top;
`

const SlideNumber = styled(Badge)`
  margin-right: 5px;

  .ant-badge-count {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};
  }
`

const SelectAllCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`
const CheckboxSlideCheckbox = styled(Checkbox)`
  margin-top: 3px;
`

const CloneButton = styled(Button)`
  display: block;
  margin: 10px auto 0;
`

const LanguageDropdownContainer = styled.div`
  ${LanguageDropdown} {
    display: block;
    margin-bottom: 15px;
    text-align: left;
    width: 100%;

    .usecure-language-dropdown-value {
      width: calc(100% - 10px);
    }
  }
`

const CheckboxSlide = ({ slide, checked, onChange, index }) => {
  const { id, title, type } = slide
  const slideNumber = index + 1
  const slideType = CONTENT_TYPE_NAMES[type] || '<TYPE_NAME>'

  return (
    <Card>
      <CheckboxSlideContainer>
        <CheckboxSlideCheckbox name={id} {...{ checked, onChange }} />
        <CheckboxSlideWrap>
          <CheckboxSlideHeaderWrap>
            <CheckboxSlideDetailWrap>
              <SlideNumType>
                <SlideNumber count={slideNumber} />
                <SlideType>{slideType}</SlideType>
              </SlideNumType>
              <span>{title}</span>
            </CheckboxSlideDetailWrap>
          </CheckboxSlideHeaderWrap>
        </CheckboxSlideWrap>
      </CheckboxSlideContainer>
    </Card>
  )
}

const CloneSourceLocaleCourseSlidesModal = React.forwardRef(({ courseId, courseSourceLocale, locale, refetchSlides = () => {}, refetchQueries = [], afterClose: afterCloseProp = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sourceSlides, setSourceSlides] = useState([])
  const [hasExistingSlides, setHasExistingSlides] = useState([])
  const [ids, setIds] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [deleteExistingSlides, setDeleteExistingSlides] = useState(false)
  const [overwriteSlides, setOverwriteSlides] = useState(false)
  const [sourceLocale, setSourceLocale] = useState(null)
  const [languageOptions, setLanguageOptions] = useState([])

  const client = useApolloClient()
  const [cloneSlides] = useMutation(CLONE_SOURCE_LOCALE_SLIDES)
  const loadSlides = useCallback(async locale => {
    const result = await client.query({
      query: GET_COURSE_SLIDES,
      variables: { courseId, locale },
      fetchPolicy: 'no-cache'
    })
    return _get(result, 'data.course.slides') || []
  }, [client, courseId])
  const loadLanguageOptions = useCallback(async () => {
    const result = await client.query({
      query: GET_COURSE_SLIDE_LOCALES,
      variables: { courseId },
      fetchPolicy: 'no-cache'
    })
    return _intersection(
      (_get(result, 'data.course.slideLocales') || []),
      LANGUAGE_CODES
    )
      .filter(slideLocale => slideLocale !== locale) // Excluded locale being edited i.e the target locale
  }, [client, courseId, locale])

  const initialiseModal = useCallback(async () => {
    setLoading(true)
    setVisible(true)

    const languageOptions = await loadLanguageOptions()
    if (languageOptions.length === 0) throw new Error(I18n.t('noLocalesFoundError', trOpt))

    let sourceLocale
    if (courseSourceLocale === locale) {
      // If the course's source language is being edited, use first language option that isn't the source language
      const sourceLocaleOpt = languageOptions.find(code => code !== courseSourceLocale)
      if (sourceLocaleOpt) {
        sourceLocale = sourceLocaleOpt
      }
    } else if (languageOptions.some(code => code === courseSourceLocale)) {
      // Use the source language is present in the dropdown options
      sourceLocale = courseSourceLocale
    } else {
      // Use first language option
      sourceLocale = languageOptions[0]
    }
    if (!sourceLocale) throw new Error(I18n.t('noLocalesFoundError', trOpt))

    const sourceSlides = await loadSlides(sourceLocale)
    const localeSlides = await loadSlides(locale)

    if (sourceSlides.length === 0) throw new Error(I18n.t('noSlidesFoundError', trOpt)) // Safety error as language option initialisation will fail before this

    setSourceLocale(sourceLocale)
    setLanguageOptions(languageOptions)
    setSourceSlides(sourceSlides)
    setSelectAll(true)
    setIds(sourceSlides.map(s => s.id))
    setDeleteExistingSlides(false)
    setOverwriteSlides(false)
    setHasExistingSlides(localeSlides.length > 0)
  }, [locale, courseSourceLocale, loadSlides, loadLanguageOptions])

  useImperativeHandle(ref, () => ({
    // Artificial delay on initialisation unless it took longer than articifial delay
    open: () => addDelay({
      delay: 800,
      action: initialiseModal,
      complete: () => {
        setLoading(false)
      },
      failure: e => {
        showErrors(e, I18n.t('loadError', trOpt))
        setVisible(false)
      }
    })
  }), [initialiseModal])

  const onSelectAllChange = useCallback(e => {
    const { checked } = e.target
    setSelectAll(checked)
    setIds(checked ? sourceSlides.map(s => s.id) : [])
  }, [sourceSlides])

  const onSlideSelectChange = useCallback(e => {
    const { name: slideId, checked } = e.target
    let updatedIds = [...ids]
    if (checked) {
      updatedIds.push(slideId)
    } else {
      updatedIds = updatedIds.filter(id => id !== slideId)
    }
    updatedIds = _uniq(updatedIds)
    setSelectAll(updatedIds.length === sourceSlides.length)
    setIds(updatedIds)
  }, [ids, sourceSlides])

  const onDeleteExistingChange = checked => setDeleteExistingSlides(checked)
  const onOverwriteExistingChange = checked => setOverwriteSlides(checked)

  const onSourceLocaleChange = useCallback(async locale => {
    try {
      setVisible(true)
      setLoading(true)

      setSourceLocale(locale)
      const sourceSlides = await loadSlides(locale)

      if (sourceSlides.length === 0) throw new Error(I18n.t('noSlidesFoundError', trOpt))

      setSourceSlides(sourceSlides)
      setSelectAll(true)
      setIds(sourceSlides.map(s => s.id))
      setDeleteExistingSlides(false)
      setOverwriteSlides(false)
      setLoading(false)
    } catch (e) {
      showErrors(e, I18n.t('loadError', trOpt))
    }
  }, [loadSlides])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    setLoading(false)
    setSourceLocale(null)
    setSourceSlides([])
    setSelectAll(false)
    setIds([])
    setDeleteExistingSlides(false)
    setHasExistingSlides(false)
    setLanguageOptions([])
    afterCloseProp()
  }, [afterCloseProp])

  const onSaveClick = useCallback(async () => {
    setLoading(true)
    try {
      await cloneSlides({
        variables: { courseId, sourceLocale, targetLocale: locale, ids, deleteExistingSlides, overwriteSlides },
        refetchQueries
      })
      await refetchSlides()
      message.success(I18n.t('successMessage', { count: ids.length, ...trOpt }))
      closeModal()
    } catch (e) {
      showErrors(e, I18n.t('errorMessage', { count: ids.length, ...trOpt }))
    }
    setLoading(false)
  }, [
    cloneSlides, refetchSlides, refetchQueries, closeModal,
    courseId, sourceLocale, locale, ids, deleteExistingSlides,
    overwriteSlides
  ])

  return (
    <Modal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={I18n.t('title', trOpt)}
      afterClose={afterClose}
      maskClosable={false}
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <FormContainer>
        <LanguageDropdownContainer>
          <label>{I18n.t('courses.common.locale')}</label>
          <LanguageDropdown value={sourceLocale} onChange={onSourceLocaleChange} languageCodes={languageOptions} />
        </LanguageDropdownContainer>
        <SelectAllCheckbox
          onChange={onSelectAllChange}
          checked={selectAll}
          indeterminate={!selectAll && ids.length > 0}
        >
          {I18n.t('selectAll', trOpt)}
        </SelectAllCheckbox>
        <SlideContainer>
          {
            sourceSlides.map((slide, index) => (
              <CheckboxSlide key={slide.id} onChange={onSlideSelectChange} checked={selectAll || ids.includes(slide.id)} {...{ slide, index }} />
            ))
          }
        </SlideContainer>
        {hasExistingSlides && (
          <>
            <SwitchContainer>
              <Switch
                onChange={onOverwriteExistingChange}
                checked={overwriteSlides}
              />
              <SwitchLabel>{I18n.t('overwriteExistingSlides', trOpt)}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                onChange={onDeleteExistingChange}
                checked={deleteExistingSlides}
              />
              <SwitchLabel>{I18n.t('deleteAllExistingSlides', trOpt)}</SwitchLabel>
            </SwitchContainer>
          </>
        )}
        <CloneButton type='primary' disabled={loading || !(selectAll || ids.length > 0)} icon='copy' onClick={onSaveClick}>
          {I18n.t('buttonLabel', { ...trOpt, count: ids.length > 0 })}
        </CloneButton>
      </FormContainer>
    </Modal>
  )
})

export default CloneSourceLocaleCourseSlidesModal
