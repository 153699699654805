import React, { useRef, useCallback } from 'react'
import { Card, Button, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _isFunction from 'lodash/isFunction'

import { Loader } from '../common'

const { Meta } = Card
const trOpt = { scope: 'accountSettings.recoveryKeys' }

const SecretList = styled.ol`
padding: 10px 0;
display: grid;
grid-template-columns: repeat(2, auto);
row-gap: 5px;
justify-content: space-around;
justify-items: flex-start;
font-size: 16px;
font-weight: 700;
`

const RecoveryKeysCard = ({ title, alerts, setAllowNextStep, recoveryKeys, loading = false }) => {
  const recoveryKeysRef = useRef(null)

  // Download, Print or Copy Recovery Codes
  // An admin user must perform one of these 3 actions to progress to the QR code scan step of MFA setup or perform a "Last Recovery Code Used" regen after auth
  // Each of these actions sets allowNextStep to true which enables progress in component/view using this component to display recovery codes
  // An admin user can use this component to download/copy/print their codes without generating new codes

  // Download recovery codes in a text file
  const downloadTxtFile = useCallback(() => {
    const element = document.createElement('a')
    const newKeys = recoveryKeys.map(item => {
      return item + '\r\n'
    })
    const file = new Blob(newKeys, { type: 'text/plain' }) // eslint-disable-line no-undef
    element.href = URL.createObjectURL(file)
    element.download = 'usecure_2fa_recovery_keys.txt'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
    if (_isFunction(setAllowNextStep)) setAllowNextStep(true)
  }, [recoveryKeys, setAllowNextStep])

  // Print recovery codes to... the past?
  const printFile = useReactToPrint({
    content: useCallback(() => recoveryKeysRef.current, [recoveryKeysRef]),
    pageStyle: () => {}
  })
  const handlePrint = useCallback(() => {
    printFile()
    if (_isFunction(setAllowNextStep)) setAllowNextStep(true)
  }, [printFile, setAllowNextStep])

  // Copy recovery codes to clipboard
  const onCopy = useCallback(() => {
    message.success(I18n.t('copiedToClipboard', trOpt))
    if (_isFunction(setAllowNextStep)) setAllowNextStep(true)
  }, [setAllowNextStep])

  return (
    <Card>
      <Meta
        title={title}
        description={I18n.t('recoveryCodesAreUsedToAccess', trOpt)}
      />
      {alerts}
      {recoveryKeys?.length > 0 && (
        <>
          <SecretList ref={recoveryKeysRef}>
            {loading && <Loader />}
            {recoveryKeys.map((item, key) => <li key={key}>{item}</li>)}
          </SecretList>
          <div style={{ paddingBottom: '25px', width: '70%', display: 'flex', justifyContent: 'space-between', margin: '0 auto' }}>
            <Button
              icon='download'
              style={{ minWidth: '30%', fontWeight: '500' }}
              onClick={downloadTxtFile}
            >
              {I18n.t('download', trOpt)}
            </Button>
            <Button
              icon='printer'
              style={{ minWidth: '30%', fontWeight: '500' }}
              onClick={handlePrint}
            >
              {I18n.t('print', trOpt)}
            </Button>
            <CopyToClipboard
              text={recoveryKeys.join(',\r\n')}
              onCopy={onCopy}
            >
              <Button
                icon='copy'
                style={{ minWidth: '30%', fontWeight: '500' }}
              >
                {I18n.t('copy', trOpt)}
              </Button>
            </CopyToClipboard>
          </div>
        </>
      )}
      <p><span style={{ fontWeight: 600 }}>{I18n.t('treatYourRecoveryCodes', trOpt)}</span> {I18n.t('recoveryCodePasswordManager', trOpt)}</p>
    </Card>
  )
}

export default RecoveryKeysCard
