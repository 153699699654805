import React, { useCallback, useRef, useState, useImperativeHandle, useEffect, useMemo } from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { Button, Modal, Form, Input, message } from 'antd'
import { useApolloClient } from 'react-apollo'

import { START_MESSAGE_INJECTION_TEST, GET_MESSAGE_INJECTION_TEST_STATUS } from '../Queries/Settings'
import QueueJobStatusHandler from '../../helpers/QueueJobStatusHandler'
import useGlobalState from '../../hooks/useGlobalState'
import selectors from '../../state/selectors'
import { showErrors, validateEmail } from '../../helpers'

const trOpt = { scope: 'settings.messageInjection.testUtility' }
const FAILED_TEST_STATUSES = ['failed', 'messageDataFailed', 'authFailed', 'requestFailed', 'serviceFailed', 'jobFailed', 'jobTimeout']

const TestOutputModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const TestBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const EmailInput = styled(Input)`
  margin-bottom: 10px;
`

const MessageInjectionTestModal = React.forwardRef((props, ref) => {
  const client = useApolloClient()
  const { userEmail } = useGlobalState(
    state => {
      const { email: userEmail } = selectors.session.get(state)
      return { userEmail }
    }
  )
  const [visible, setVisible] = useState(false)
  const [service, setService] = useState(null)
  const [status, setStatus] = useState(null)
  const [email, setEmail] = useState(null)
  const [jobId, setJobId] = useState(null)
  const [errorMessages, setErrorMessages] = useState(null)

  const queueJobStatusHandler = useMemo(() => {
    return new QueueJobStatusHandler({
      mutation: START_MESSAGE_INJECTION_TEST,
      mutationName: 'startMessageInjectionTest',
      query: GET_MESSAGE_INJECTION_TEST_STATUS,
      queryName: 'messageInjectionTestStatus',
      client
    })
  }, [client])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    setService(null)
    setStatus(null)
    setEmail(null)
    setErrorMessages(null)
    setJobId(null)
    if (queueJobStatusHandler) {
      queueJobStatusHandler.cancel()
    }
  }, [queueJobStatusHandler])

  useImperativeHandle(ref, () => ({
    open: (service = 'default') => {
      setService(service)
      setStatus('pending')
      setEmail(userEmail)
      setVisible(true)
    }
  }), [userEmail])

  const inputRef = useRef(null)
  useEffect(() => {
    if (visible && inputRef.current) {
      inputRef.current.focus()
    } else if (visible) {
      // Allow 500ms for input to render
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 500)
    }
  }, [visible, inputRef])

  const onEmailChange = useCallback(e => {
    setEmail(e.currentTarget.value || null)
  }, [])

  const onSendClick = useCallback(async () => {
    if (!validateEmail(email)) {
      message.error(I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError'))
      return
    }

    setStatus('waiting')
    let status = 'failed'
    let errorMessages = null
    try {
      const jobResult = await queueJobStatusHandler.execute({ recipient: email, service }, { onJobStart: setJobId })
      status = jobResult.status
      errorMessages = jobResult.errorMessages ?? null
    } catch (e) {
      console.error('MessageInjection.onSendClick - ERROR', e)
      showErrors(e, I18n.t('errorMessage', trOpt))
    }
    setStatus(status)
    setErrorMessages(errorMessages)
  }, [email, service, queueJobStatusHandler])

  const onKeyUp = useCallback(event => {
    if (event.keyCode === 13) {
      onSendClick()
    }
  }, [onSendClick])

  const testFailed = FAILED_TEST_STATUSES.includes(status)

  const buttons = []
  if (status === 'pending') {
    buttons.push(
      <Button key='close' onClick={closeModal}>{I18n.t('common.cancel')}</Button>,
      <Button key='send' type='primary' icon='mail' onClick={onSendClick}>{I18n.t('uPhish.common.sendTestEmail')}</Button>
    )
  } else if (status === 'waiting') {
    buttons.push(<Button key='close' type='danger' onClick={closeModal}>{I18n.t('common.cancel')}</Button>)
  } else if (status === 'completed' || testFailed) {
    buttons.push(<Button key='close' type='primary' onClick={closeModal}>{I18n.t('common.ok')}</Button>)
  }

  let statusKey
  if (['serviceFailed', 'requestFailed', 'authFailed'].includes(status)) {
    statusKey = `status.${status}.${service}`
  } else if (status === 'jobFailed') {
    // jobFailed is useful for debugging but not particularly informative to the user
    // We show the same message as 'failed' for that reason
    statusKey = 'status.failed'
  } else {
    statusKey = `status.${status}`
  }

  // Using send_test_sim_email as the input id means that autofill will pick up the same emails used on uPhish email tests in the past (Nice UX win)
  return (
    <TestOutputModal
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      afterClose={afterClose}
      width='70%'
      closable={false}
      maskClosable={false}
      title={I18n.t(`title.${service}`, trOpt)}
    >
      {statusKey && (
        <p className='base-font'>{I18n.t(statusKey, trOpt)}</p>
      )}
      {status === 'pending' && (
        <Form.Item
          label={I18n.t('recipient', trOpt)}
          extra={I18n.t(`recipientExtra.${service}`, trOpt)}
        >
          <EmailInput
            id='send_test_sim_email' ref={inputRef} value={email}
            onChange={onEmailChange} onKeyUp={onKeyUp} allowClear
          />
        </Form.Item>
      )}
      {testFailed && errorMessages?.length > 0 && (
        <div>
          <h4>{I18n.t('errorLog', trOpt)}</h4>
          <ul>
            {errorMessages.map((msg, index) => (<li key={index}>{msg}</li>))}
          </ul>
        </div>
      )}
      {jobId && <p className='base-font'>{I18n.t('jobIdTag', { ...trOpt, jobId })}</p>}
      {buttons.length > 0 && (
        <TestBtnContainer>{buttons}</TestBtnContainer>
      )}
    </TestOutputModal>
  )
})

export default MessageInjectionTestModal
