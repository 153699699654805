import I18n from 'i18n-js'

import SettingsForm from '../SettingsForm'
import { permissions } from '../../../constants/permissions'

const trOpt = { scope: 'settings.compromiseMessage' }
const uPhishTrOpt = { scope: 'settings.uPhish' }

class CompromiseMessage extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'simulationCompromiseMessage',
      'simulationCompromiseImage'
    ]
    Object.defineProperty(this, 'defaultValue', {
      get: () => ({
        simulationCompromiseMessage: I18n.t('defaultSimulationCompromiseMessage', uPhishTrOpt),
        simulationCompromiseImage: 'https://s3-eu-west-1.amazonaws.com/usecure/images/favicon.png'
      })
    })
    this.requiredUpdatePermissions = [permissions.SETTINGS_UPHISH_UPDATE]
  }

  get headerId () {
    return 'settings-compromise-message-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', uPhishTrOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const { settings = {} } = this.props
    const {
      simulationCompromiseMessage,
      simulationCompromiseImage
    } = {
      ...this.defaultValue,
      ...settings
    }

    return [
      {
        id: 'simulationCompromiseImage',
        type: 'image',
        label: I18n.t('simulationCompromiseImage', uPhishTrOpt),
        defaultValue: simulationCompromiseImage,
        formItemStyle: { maxWidth: '400px' },
        required: true
      }, {
        id: 'simulationCompromiseMessage',
        type: 'textarea',
        label: I18n.t('simulationCompromiseMessage', uPhishTrOpt),
        defaultValue: simulationCompromiseMessage,
        formItemStyle: { maxWidth: 800 },
        textAreaStyle: { minHeight: 150, textAlign: 'center', padding: 8 },
        required: true
      }
    ]
  }
}

export default CompromiseMessage
