import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { Progress } from 'antd'

const load7 = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`

export const Loader = styled.div`
  color: ${({ theme, useDefaultTheme }) => useDefaultTheme ? theme.defaultPrimary : theme.primary};
  font-size: 5px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  
  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${load7} 1.8s infinite ease-in-out;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
  }
`

export const ShortLoader = styled(Loader)`
  margin: 0 auto 2.5rem;
`

const LoaderProgress = styled(Progress)`
  .ant-progress-text {
    margin-top: 5px;
  }

  &.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: rgba(0, 0, 0, 0.65);
  }
`

const StyledLoadingBlock = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  min-height: ${({ height = 'unset' }) => height};

  z-index: ${({ fullScreen }) => fullScreen ? 9999 : 800};

  position: ${({ fullScreen }) => fullScreen ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  width: 100${({ fullScreen }) => fullScreen ? 'vw' : '%'};
  height: 100${({ fullScreen }) => fullScreen ? 'vh' : '%'};
  padding-top: ${({ showMenu }) => showMenu ? '74px' : 0};
`

const LoadingBlockContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

const _LoaderContainer = ({ className, children }) => <div {...{ className }}>{children}</div>

export const LoaderContainer = styled(_LoaderContainer)`
  opacity: ${({ visible }) => visible ? 1 : 0};
  transition: opacity ${({ loading }) => loading ? '0.4s' : '0.1s'} ease-in-out;
  font-size: 24px;
  letter-spacing: 0.05em;
  font-weight: 500;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(10px);
`

export class LoadingBlock extends Component {
  constructor (props) {
    super(props)

    this.state = {
      visible: false,
      loaderVisible: false
    }
  }

  setLoaderVisibility () {
    if (this._isMounted) {
      const { loading } = this.props
      this.setState({
        visible: loading
      }, () => setTimeout(() => {
        if (this._isMounted) {
          this.setState({ loaderVisible: loading })
        }
      }, 75))
    }
  }

  componentDidMount () {
    this._isMounted = true
    if (this.props.loading) {
      this.setLoaderVisibility()
    }
  }

  componentDidUpdate (prevProps) {
    const { loading } = this.props
    const { loading: prevLoading } = prevProps
    if (loading !== prevLoading) {
      this.setLoaderVisibility()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { loading = false, fullScreen = true, useContainer = false, showMenu = false, useDefaultTheme, showProgress, progress = 0, theme, className } = this.props
    const { visible, loaderVisible } = this.state

    const block = (
      <StyledLoadingBlock {...{ className, visible, fullScreen: fullScreen && !useContainer, showMenu }}>
        <LoaderContainer {...{ visible: loaderVisible, loading }}>
          {(
            showProgress
              ? <LoaderProgress type='circle' percent={progress} format={percent => `${percent}%`} strokeColor={theme ? theme.primary : null} />
              : <Loader {...{ useDefaultTheme }} />
          )}
        </LoaderContainer>
      </StyledLoadingBlock>
    )

    if (useContainer) {
      return (
        <LoadingBlockContainer>{block}</LoadingBlockContainer>
      )
    }

    return block
  }
}
