import React, {useCallback} from 'react'
import styled from 'styled-components'
import {Checkbox, Form, Input} from 'antd'
import I18n from 'i18n-js'

import {FontAwesomeIcon} from '../common'
import EditCourseListItem from './EditCourseListItem'

const trOpt = { scope: 'editCourse.editCourseMultipleChoice'}

const CorrectIcon = styled(FontAwesomeIcon)`
  color: ${({ correct }) => correct ? 'green' : 'red'};
  line-height: 21px;
  margin-right: 5px;
`

const AnswerItem = ({ item }) => {
  const { text, correct } = item
  return (
    <div style={{ display: 'flex' }}>
      <CorrectIcon correct={correct} icon={`thumbs-${correct ? 'up' : 'down'}`} />
      <span style={{ fontStyle: (text ? 'normal' : 'italic') }}>{text || I18n.t('noAnswerEntered', trOpt)}</span>
    </div>
  )
}
const AnswerForm = ({ item, onChange: onChangeProp }) => {
  const { text, correct, feedback } = item
  const onChange = useCallback(event => {
    const { name, type, value, checked } = event.target
    onChangeProp(name, type === 'checkbox' ? checked : value)
  }, [onChangeProp])

  return (
    <React.Fragment>
      <Form.Item label={I18n.t('answer', trOpt)}>
        <Input
          name='text'
          onChange={onChange}
          value={text}
        />
      </Form.Item>
      <Form.Item>
        <Checkbox
          name='correct'
          onChange={onChange}
          checked={correct}
        >{I18n.t('correct', trOpt)}</Checkbox>
      </Form.Item>
      <Form.Item label={I18n.t('feedback', trOpt)}>
        <Input
          name='feedback'
          onChange={onChange}
          value={feedback}
        />
      </Form.Item>
    </React.Fragment>
  )
}

const EditCourseMultipleChoiceAnswer = props => {
  return (
    <EditCourseListItem
      {...props}
      modalProps={{
        width: '90%',
        style: { maxWidth: 1100 }
      }}
      itemComponent={AnswerItem}
      formComponent={AnswerForm}
    />
  )
}

export default EditCourseMultipleChoiceAnswer
