import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Upload, Icon, message, Button } from 'antd'
import bytes from 'bytes'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isString from 'lodash/isString'
import _isNumber from 'lodash/isNumber'

import { apiUrl } from '../../apollo-client/common'
import MutationFormErrors from './MutationFormErrors'
import { getSessionToken } from '../../helpers/session'

const trOpt = { scope: 'mutationForm.mutationFormDocumentUpload' }

const UploaderContainer = styled(Upload)`
  .ant-upload {
    width:50%;
  }
`

class MutationFormFileUpload extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hasReceivedFocus: false,
      loading: false,
      fileList: [],
      touched: _isArray(props.value) && props.value.length > 0
    }

    this.beforeUpload = this.beforeUpload.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  validate (value = this.props.value, errors = [], { submitting = false } = {}) {
    if (submitting) {
      this.updateTouched()
    }

    return errors
  }

  updateTouched () {
    if (!this.state.touched) {
      this.setState({ touched: true })
    }
  }

  // Returns size limit in bytes
  get sizeLimit () {
    const { sizeLimit } = this.props
    return _isString(sizeLimit) ? bytes(sizeLimit) : null
  }

  beforeUpload (file) {
    const acceptedFileExtensions = this.props.accept.replace('.', '').split(',')
    const fileExtension = file.name.split('.').pop()

    // checks against passed in accepted extensions
    const isValidFileExtension = acceptedFileExtensions.includes(fileExtension)
    // checks against passed in mime types
    const isValidFileMimeType = this.props.fileTypes.includes(file.type)

    const { sizeLimit } = this
    let withinLimit = true

    if (!isValidFileExtension || !isValidFileMimeType) {
      message.error(this.props.fileTypeError || I18n.t('fileTypeError', {
        // generate a string like 'PNG, JPG, and GIF' that gets inperpolated into the fileExtensions token
        fileExtensions: acceptedFileExtensions.join(', ').toUpperCase().replace(/, ([^,]*)$/, ' and $1'),
        ...trOpt
      }))
      return false
    }

    if (_isNumber(sizeLimit) && sizeLimit >= 0) {
      withinLimit = file.size <= sizeLimit
      if (!withinLimit) {
        message.error(I18n.t('mutationForm.common.fileSizeError', { size: bytes(sizeLimit) }))
      }
    }
    return withinLimit
  }

  handleChange (info) {
    const { file: { status } = {} } = info || {}
    let fileList = [...info.fileList]
    let loading = false

    // 1. Limit the number of uploaded files
    // Only to show most recent uploaded file, and old ones will be replaced by the new
    fileList = fileList.slice(-1)

    if (status === 'done') {
      this.props.onChange(this.props.id, fileList[0].response.url)
    } else if (status === 'removed') {
      this.props.onChange(this.props.id, null)
    } else if (status === 'uploading') {
      loading = true
    }
    this.setState({ fileList, touched: true, loading })
  }

  get fileList () {
    const { loading, fileList } = this.state
    if (loading) {
      return fileList
    }

    const { value } = this.props
    if (!value) {
      return []
    }

    const urlParts = value.split('/')
    const name = decodeURI(urlParts[urlParts.length - 1]).replace(/^([\d\w]{8})-([\d\w]{4})-([\d\w]{4})-([\d\w]{4})-([\d\w]{12})__/, '')
    const uid = -1
    return [{
      uid,
      name,
      status: 'done',
      url: value
    }]
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, label, action, formItemStyle, disabled, errors = [], required, accept, extra } = this.props
    const showErrors = errors.length > 0

    return (
      <Form.Item
        label={label} style={formItemStyle} required={required}
        extra={extra}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <UploaderContainer
          id={id}
          name='fileUpload'
          className='fileUpload'
          action={action}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          fileList={this.fileList}
          headers={{ 'x-token': getSessionToken() }}
          showUploadList={{ showPreviewIcon: false }}
          disabled={disabled}
          accept={accept}
        >
          <Button>
            <Icon type='upload' /> {I18n.t('common.upload')}
          </Button>
        </UploaderContainer>
      </Form.Item>
    )
  }
}

MutationFormFileUpload.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  action: PropTypes.string,
  formItemStyle: PropTypes.object,
  accept: PropTypes.string,
  sizeLimit: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.func]),
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  fileTypeError: PropTypes.string
}
MutationFormFileUpload.defaultProps = {
  id: '',
  type: 'text',
  label: null,
  onChange: () => {},
  action: `${apiUrl}/upload-policy`,
  sizeLimit: '2MB'
}

export default MutationFormFileUpload
