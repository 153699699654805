import React, { useState } from 'react'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import useTheme from '../../hooks/useTheme'
import { Pagination, Card } from 'antd'
import { Column } from './GridFlexiLayout'
import { TableTag, setStatusTagColour } from './DashboardComponents'
import styled from 'styled-components'
const MobileFriendlyPagination = styled(Pagination)`
  .ant-pagination-options {
    display: inline-block;
  }
  @media only screen and (max-width: 576px) {
    .ant-pagination-options {
      display: inline-block;
    }
  }
`
const DataIteratorCard = ({ record, action, cardTemplate, theme, trOpt }) => {
  return (
    <Card
      title={record[cardTemplate.title.key]}
      actions={[action]}
    >
      <Column className='align' alignItems='flex-start'>
        {_isArray(cardTemplate.body) && cardTemplate.body.map((item) => {
          switch (item.type) {
            case 'text':
              return <p key={item.key}>{item.title}: {record[item.key] || '- -'}</p>
            case 'tag':
              return _isArray(record[item.key])
                ? record[item.key].map((data, index) => (
                  <TableTag
                    key={index}
                    color='primary'
                    status={data}
                    trOpt={trOpt}
                  />
                ))
                : (
                  <TableTag
                    key={item.key}
                    color={setStatusTagColour(record[item.key], theme)}
                    status={record.status}
                    trOpt={item.trOpt}
                  />
                )
            default: return <p key={item.key}>{I18n.t(record[item.key], trOpt)}:{record[item.key]}</p>
          }
        })}
      </Column>
    </Card>
  )
}
const DataIterator = ({ items, defaultPageSize = 10, learnerName, cardTemplate, cardAction, learnerId, trOpt }) => {
  const theme = useTheme()
  const [globalPageSize, updateGlobalPageSize] = useState(defaultPageSize)
  const [pagination, updatePagination] = useState({ current: 1, minIndex: 0, maxIndex: globalPageSize })
  function onShowSizeChange (current, pageSize) {
    updateGlobalPageSize(pageSize)
    handleChange(1, pageSize)
  }

  const handleChange = (page, pageSize) => {
    updatePagination(
      {
        current: page,
        minIndex: (page - 1) * pageSize,
        maxIndex: page * pageSize
      }
    )
  }

  return (
    <>
      <Column>
        {items.map((record, index) => {
          return (index >= pagination.minIndex && index < pagination.maxIndex) && (
            <div key={record.id}><DataIteratorCard record={record} action={cardAction({ action: record.action, record, learnerName, theme, learnerId })} cardTemplate={cardTemplate} theme={theme} trOpt={trOpt} /></div>
          )
        })}
        <MobileFriendlyPagination
          pageSize={globalPageSize}
          current={pagination.current}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          total={items.length}
          onChange={handleChange}
          pageSizeOptions={['5', '10', '20', '30']}
        />
      </Column>
    </>
  )
}
export default DataIterator
