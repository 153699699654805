import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Alert, Icon } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { BREAKPOINT, MIN_BREAKPOINT } from '../../constants/style'
import { ContentBox, CardTitle } from './common'
import { Radio, ProgressButton } from '../common'
import { LearnerCourseBase } from './LearnerCourseBase'

export const ChooseMultiple = styled.div`
  font-size: 0.7em;
  font-weight: 400;
  margin-top: 1em;
`

const Answers = styled.div`
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 1.3em;
  flex-grow: 1;
`

const AnswerWrap = styled.div`
  &:not(:last-child) {
      margin-bottom: .6em;
  }
  
  .ant-alert-with-description .ant-alert-message {
    font-size: 18px;
  }
  .ant-alert-description {
    font-size: 16px
  }
  
  .ant-alert-success {
    border: 2px solid ${props => props.theme.success};
  }
  .ant-alert-success .ant-alert-icon {
    color: ${props => props.theme.success};
  }
`
const AnswerInputWrap = styled.div`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  line-height: 1;
`

const AnswerText = styled.div`
  padding-left: .7em;
  padding-top: .28em;
  line-height: 1.1;
  max-width: 29em;
  color: ${props => props.theme.darkGrey};
  @media (min-width: ${MIN_BREAKPOINT}) {
    font-size: 26px;
  }
  @media (max-width: ${BREAKPOINT}) {
    font-size: 18px;
  }
`

const AnswerRadio = styled(Radio)`
  flex: 0 0 2.2em;
`

const ANSWER_ALERT_MESSAGES = {
  correct: 'correctAnswer',
  incorrect: 'incorrectAnswer',
  missed: 'missedAnswer',
  wasCorrect: 'wasCorrectAnswer',
  wasCorrectMulti: 'wasCorrectMultiAnswer'
}
const ANSWER_ALERT_TYPES = {
  correct: 'success',
  incorrect: 'error',
  missed: 'warning',
  wasCorrect: 'warning',
  wasCorrectMulti: 'warning'
}
const ANSWER_CHECK_COLORS = {
  correct: 'success',
  wasCorrect: 'missed',
  wasCorrectMulti: 'missed',
  incorrect: 'failure',
  missed: 'missed'
}
const ANSWER_ICONS = {
  correct: 'thumbs-up',
  incorrect: 'thumbs-down',
  missed: 'info-circle',
  wasCorrect: 'info-circle',
  wasCorrectMulti: 'info-circle'
}

const ExpandButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
`

const AnswerFeedbackContainer = styled.div`
  margin: 8px 0 24.5px;
  color: #999;
  font-size: 16px;
  line-height: 1.4;
  width: 100%;

  ${ExpandButton} {
    display: none;
  }

  .ant-alert {
    padding: 15px 15px 15px 64px;
  }

  .ant-alert-message {
    font-size: 16px;
  }

  .ant-alert-icon {
    font-size: 24px;
  }

  .ant-alert-icon.fa-info-circle {
    top: 12.5px;
  }

  ${({ expanded }) => expanded ? '' : css`
  .ant-alert-with-description .ant-alert-message {
    margin-bottom: 0;
  }
  .ant-alert-description {
    height: 0;
    visibility: hidden;
  }
  `}
  ${({ collapsible }) => !collapsible ? '' : css`
  cursor: pointer;

  ${ExpandButton} {
    display: block;
  }

  .ant-alert-with-description .ant-alert-message, .ant-alert-message {
    font-size: 16px;
  }
  .ant-alert-description {
    font-size: 14px;
  }
  `}
`

const AnswerFeedback = ({ feedbackType, feedback }) => {
  const collapsible = ['wasCorrect', 'wasCorrectMulti', 'missed'].includes(feedbackType) && feedback && feedback.length > 0
  const [expanded, setExpanded] = useState(!collapsible)

  const onClick = useCallback(() => {
    setExpanded(collapsible ? !expanded : true)
  }, [collapsible, expanded])

  return (
    <AnswerFeedbackContainer {...{ expanded, collapsible }}>
      <div onClick={onClick}>
        <Alert
          message={
            <div>
              <span>{I18n.t(`learnerCourse.${ANSWER_ALERT_MESSAGES[feedbackType]}`)}</span>
              <ExpandButton>
                <Icon type={expanded ? 'up' : 'down'} />
              </ExpandButton>
            </div>
          }
          type={ANSWER_ALERT_TYPES[feedbackType]}
          icon={<i className={`fas fa-${ANSWER_ICONS[feedbackType]}`} />}
          showIcon
          description={feedback}
        />
      </div>
    </AnswerFeedbackContainer>
  )
}

const LearnerCourseAnswer = ({ id, text, answerIds, correct, feedback, isGapAnalysis = false, submitted, touched, allowMultipleAnswers, allowMultipleNoneCorrect, onChange: onChangeProp = () => {} }) => {
  const onClick = useCallback(() => onChangeProp(id), [onChangeProp, id])

  const submittedAnswer = answerIds.includes(id)
  const radioProps = { hideCheck: !touched }
  let alert = null
  if (submitted && !isGapAnalysis) {
    let feedbackType
    if (submittedAnswer && correct) {
      // The user's picked this answer and it was correct
      feedbackType = 'correct'
    } else if (submittedAnswer) {
      // The user's picked this answer and it was incorrect
      feedbackType = 'incorrect'
    } else if (allowMultipleAnswers && !allowMultipleNoneCorrect && correct) {
      // The user missed this correct 'allowMultipleAnswers' answer but selected at least one correct answer
      feedbackType = 'missed'
    } else if (allowMultipleAnswers && allowMultipleNoneCorrect && correct) {
      // The user didn't pick any of the correct answers on an 'allowMultipleAnswers' question and this is a correct answer
      feedbackType = 'wasCorrectMulti'
    } else if (correct) {
      // The user didn't pick this answer but it was a correct one
      feedbackType = 'wasCorrect'
    }

    if (feedbackType) {
      radioProps.checked = true
      radioProps.color = ANSWER_CHECK_COLORS[feedbackType]
      radioProps.correct = correct

      // Only display a feedback alert if this feedback or if this was an unselected correct answer
      if ((feedback && feedback.length > 0) || ['wasCorrect', 'wasCorrectMulti', 'missed'].includes(feedbackType)) {
        alert = <AnswerFeedback {...{ feedbackType, feedback }} />
      }
    }
  } else {
    radioProps.checked = submittedAnswer
  }

  return (
    <AnswerWrap onClick={onClick}>
      <AnswerInputWrap>
        <AnswerRadio {...radioProps} />
        <AnswerText>{text}</AnswerText>
      </AnswerInputWrap>
      {alert}
    </AnswerWrap>
  )
}

class LearnerCourseMultipleChoice extends LearnerCourseBase {
  constructor (props) {
    super(props)

    this.completedTimer = null

    this.state = {
      answer: null,
      answerIds: [],
      touched: false,
      answered: false,
      submitted: false
    }

    this.advanceSlideOnSpacePress = this.advanceSlideOnSpacePress.bind(this)
    this.answer = this.answer.bind(this)
    this.canProgress = this.canProgress.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleProgressClick = this.handleProgressClick.bind(this)
    this.updateStateUsingExistingAnswer = this.updateStateUsingExistingAnswer.bind(this)
    this.updateAnswer = this.updateAnswer.bind(this)
    this.updateAnswered = this.updateAnswered.bind(this)
  }

  componentDidMount () {
    this._isMounted = true
    this.updateStateUsingExistingAnswer(false)
    this.startCompletionTimer()
  }

  componentDidUpdate (prevProps) {
    const { courseId, slide: { id } = {}, answer } = this.props
    const { courseId: prevCourseId, slide: { id: prevId } = {}, answer: prevAnswer } = prevProps

    if (id !== prevId) {
      this.startCompletionTimer()
    }

    // Update state based upon incoming answer prop
    if (answer !== null && answer !== undefined && (prevAnswer === null || prevAnswer === undefined)) {
      this.updateStateUsingExistingAnswer(true)
    } else if ((id && prevId && id !== prevId) || (courseId && prevCourseId && courseId !== prevCourseId)) {
      if (answer) {
        // Question has already been answered, restore answered state
        this.updateStateUsingExistingAnswer(false)
      } else {
        // Reset component on slide transition to another multiple choice slide
        this.setState({
          answer: null,
          answerIds: [],
          touched: false,
          submitted: false,
          waiting: false
        }, this.updateAnswered)
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    clearTimeout(this.completedTimer)
  }

  get allowMultipleAnswers () {
    return _get(this.props, 'slide.content.allowMultipleAnswers', false)
  }

  updateStateUsingExistingAnswer (allowAdvance = false) {
    const { answer, isGapAnalysis } = this.props
    if (answer !== null && answer !== undefined) {
      const stateUpdate = {
        answer: answer.answer,
        answerIds: answer.answers || [],
        submitted: true,
        touched: true
      }
      if (!isGapAnalysis || !allowAdvance) {
        // Stay on this slide
        this.setState(stateUpdate)
      } else {
        // Advance to next slide
        stateUpdate.waiting = false
        this.setState(stateUpdate, () => this.props.goToNextSlide())
      }
    }
  }

  get answered () {
    return this.allowMultipleAnswers ? this.state.answerIds.length > 0 : this.state.answerIds.length === 1
  }

  updateAnswered () {
    this.setState({ answered: this.answered })
  }

  updateAnswer (answer, touched = false) {
    if (!this.state.submitted && !this.state.waiting) {
      let { answerIds } = this.state
      const allowMultipleAnswers = this.allowMultipleAnswers
      const alreadySelected = answerIds.includes(answer)

      if (allowMultipleAnswers && alreadySelected) {
        answerIds = answerIds.filter(answerId => answerId !== answer)
      } else if (allowMultipleAnswers) {
        answerIds.push(answer)
      } else {
        answerIds = alreadySelected ? [] : [answer]
      }

      this.setState({
        answerIds,
        touched
      }, this.updateAnswered)
    }
  }

  answer () {
    if (this.answered && !this.state.waiting) {
      const update = {
        action: 'answer',
        questionId: this.props.slide.id,
        answerIds: this.state.answerIds
      }
      if (this.props.isGapAnalysis) {
        update.courseId = this.props.slide.courseId
      }
      this.setState({ waiting: true }, async () => {
        await this.props.update(update)
        if (this._isMounted) {
          this.setState({ waiting: false })
        }
      })
    }
  }

  handleChange (answer) {
    this.updateAnswer(answer, true)
  }

  canProgress () {
    return this.state.submitted && !this.state.waiting
  }

  handleProgressClick () {
    if (!this.state.waiting) {
      if (this.state.submitted) {
        this.props.goToNextSlide()
      } else {
        this.answer()
      }
    }
  }

  advanceSlideOnSpacePress () {
    if (this.answered && !this.state.submitted) {
      this.answer()
      return false
    }
    return this.state.submitted
  }

  render () {
    const { allowMultipleAnswers } = this
    const { answerIds, answer, submitted, touched, answered, waiting } = this.state
    const { canGoPrevSlide, goToPrevSlide, slide: { id, content, deleted = false } } = this.props
    const { question, answers = [] } = content || {}

    let title = question
    if (deleted) {
      title = `${I18n.t('learnerCourse.deletedQuestionMessage')}\n\n${I18n.t('learnerCourse.deletedQuestionPrompt')}`
    }

    const answersSubmitted = submitted && !waiting
    // No correct answers selected for an 'allowMultipleAnswers' question
    const allowMultipleNoneCorrect = answersSubmitted && allowMultipleAnswers ? !answers.some(answer => answer.correct && answerIds.includes(answer.id)) : false

    return (
      <ContentBox
        material innerKey={id}
        buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
        buttonsRight={
          <ProgressButton
            disabled={!(answered || submitted)}
            onClick={this.handleProgressClick}
            label={submitted ? 'common.next' : 'common.submit'}
            icon={submitted ? 'arrow-right' : 'check'}
          />
        }
      >
        <CardTitle>
          {title}
          {
            !answer && allowMultipleAnswers
              ? <ChooseMultiple>{I18n.t('learnerCourse.chooseMultiple')}</ChooseMultiple>
              : null
          }
        </CardTitle>
        <Answers>
          {answers.map((answerDefinition, index) => (
            <LearnerCourseAnswer
              key={index} submitted={answersSubmitted}
              isGapAnalysis={this.props.isGapAnalysis}
              onChange={this.handleChange}
              {...{ ...answerDefinition, allowMultipleAnswers, answerIds, touched, allowMultipleNoneCorrect }}
            />
          ))}
        </Answers>
      </ContentBox>
    )
  }
}

LearnerCourseMultipleChoice.propTypes = {
  answer: PropTypes.object,
  slide: PropTypes.object,
  courseId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  goToNextSlide: PropTypes.func,
  isGapAnalysis: PropTypes.bool,
  update: PropTypes.func
}

LearnerCourseMultipleChoice.defaultProps = {
  answer: null,
  slide: {},
  courseId: null,
  goToNextSlide: () => {},
  isGapAnalysis: false,
  update: () => {}
}

export default LearnerCourseMultipleChoice
