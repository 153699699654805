import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import I18n from 'i18n-js'
import { message } from 'antd'
import _isFunction from 'lodash/isFunction'

import useGlobalState from '../../hooks/useGlobalState'
import { creators as sessionActions } from '../../state/actions/session'
import { mfaToken as getMFAToken } from '../../state/selectors/session'
import { creators as viewActions } from '../../state/actions/view'
import { MFA_SIGN_IN, VERIFY_RECOVERY_KEY, REGENERATE_RECOVERY_KEYS } from '../../components/Queries/Users'
import { SignInContainer, SignInHeader, RecoveryKeysRegenOnLastKey, BackLink } from '../../components/SignIn'
import AuthenticationSteps from '../../components/SignIn/AuthenticationSteps'
import { UsecureError, getErrorCode, showErrors } from '../../helpers'
import routes from '../../constants/routes'
import { withRefreshSessionState } from '../../hocs'
import { postSessionChannelMessage, setSessionToken } from '../../helpers/session'
import { ErrorAlerts } from '../../components/common'
import { navigateToApp } from '../../helpers/signIn'

const trOpt = { scope: 'signIn' }
const DEFAULT_ERROR_TR_KEY = `${trOpt.scope}.failedAuthenticatorVerification`

const MultiFactorAuth = ({ refreshSessionState }) => {
  const [status, setStatus] = useState('init')
  const [mfaToken, setMFAToken] = useState(null)
  const [authCode, setAuthCode] = useState(null)
  const [recoveryKey, setRecoveryKey] = useState(null)
  const [targetPath, setTargetPath] = useState(null)
  const [error, setError] = useState(null)
  const { search } = useLocation()
  const history = useHistory()
  const [mfaSignIn] = useMutation(MFA_SIGN_IN)
  const [verifyRecoveryKey] = useMutation(VERIFY_RECOVERY_KEY)
  const [regenerateRecoveryKeys] = useMutation(REGENERATE_RECOVERY_KEYS)

  const { mfaToken: stateMFAToken, setLoadingVisible, resetMFAToken } = useGlobalState(
    useCallback(state => ({
      mfaToken: getMFAToken(state)
    }), []),
    useCallback(dispatch => ({
      resetMFAToken: () => dispatch(sessionActions.updateMFAToken(null)),
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    }), [])
  )

  // "On Mount" hook
  useEffect(() => {
    if (status !== 'init') return

    const queryMFAToken = new URLSearchParams(search).get('token')
    const mfaToken = queryMFAToken ?? stateMFAToken
    if (mfaToken) {
      setStatus('authReady')
      setMFAToken(mfaToken)
      // Clear MFA token for URL and global state to reduce chances of reuse
      if (queryMFAToken) {
        // Clear query params
        history.push(routes.MFA_SIGN_IN)
      }
      if (stateMFAToken) {
        resetMFAToken()
      }
    } else {
      // No auth attempt detected as there's no MFA token in URL or global state
      setError(null) // use default error
      setStatus('fail')
    }
    setLoadingVisible(false)
  }, [status, search, stateMFAToken, setLoadingVisible, history, resetMFAToken])

  // Initialise session in response to successful auth
  const initialiseSession = useCallback(async ({ token, targetPath, redirect = true } = {}) => {
    resetMFAToken()
    // Set admin session token
    setSessionToken(token)
    // Initialise session & settings global state
    await refreshSessionState(false)
    // Broadcast new session refresh instruction to other tabs
    postSessionChannelMessage('sessionRefresh')
    if (redirect) {
      // Navigate to home page or desired page
      navigateToApp({ history, targetPath })
    }
  }, [history, refreshSessionState, resetMFAToken])

  // Shared function for calling MFA auth mutations and handling the response
  const performAuth = useCallback(async ({
    flow, executeMutation, mutationName, variables, processResponse, afterSessionInit
  }) => {
    let exitStatus = `${flow}Ready`
    try {
      setLoadingVisible(true)
      setStatus(`${flow}Waiting`)

      const result = await executeMutation({ variables })
      const response = result?.data?.[mutationName] ?? {}
      const { token, targetPath } = response
      if (token) {
        let sessionOpt = { token, targetPath, redirect: true }
        if (_isFunction(processResponse)) {
          ({ sessionOpt, exitStatus } = processResponse({ response, sessionOpt, exitStatus }))
        }
        await initialiseSession(sessionOpt)
        if (_isFunction(afterSessionInit)) {
          await afterSessionInit({ response, exitStatus })
        }
      } else {
        throw new UsecureError(I18n.t(DEFAULT_ERROR_TR_KEY), { level: 'silent', errorCode: 'AUTH_CODE_ERR' })
      }
    } catch (e) {
      // mfa token can no longer be used - show failed state with server error copy
      if (['TOKEN_INVALID', 'NO_USER'].includes(getErrorCode(e))) {
        exitStatus = 'fail'
        setError(e)
      } else {
        showErrors(e, I18n.t(DEFAULT_ERROR_TR_KEY))
      }
    } finally {
      setLoadingVisible(false)
      setStatus(exitStatus)
    }
  }, [setLoadingVisible, initialiseSession])

  // Authenticator Code Entry - Main Flow
  const goToAuthCodeEntry = useCallback(() => {
    setStatus('authReady')
  }, [])
  const onAuthCodeChange = useCallback(e => {
    setAuthCode(e.target?.value)
  }, [])
  const onAuthSubmit = useCallback(async () => {
    if (authCode?.length !== 6) {
      message.error(I18n.t('pleaseEnterYour6DigitTwoFactorAuthenticationCode', trOpt))
      return
    }

    return performAuth({
      flow: 'auth',
      executeMutation: mfaSignIn,
      mutationName: 'mfaSignIn',
      variables: { mfaToken, authCode }
    })
  }, [performAuth, authCode, mfaSignIn, mfaToken])

  // Recovery Code Entry - Secondary Auth Flow
  const goToRecoveryCodeEntry = useCallback(() => {
    setStatus('recoveryReady')
  }, [])
  const onRecoveryKeyChange = useCallback(e => {
    setRecoveryKey(e.target?.value)
  }, [])
  const onRecoveryKeySubmit = useCallback(async () => {
    if (!recoveryKey) {
      message.error(I18n.t('pleaseEnterYourRecoveryKey', trOpt))
      return
    }

    return performAuth({
      flow: 'recovery',
      executeMutation: verifyRecoveryKey,
      mutationName: 'verifyRecoveryKey',
      variables: { mfaToken, recoveryKey },
      processResponse: ({ response, sessionOpt, exitStatus }) => {
        // The user has used all of their recovery codes and needs to regenerate a new set before going into the system
        if (response?.usedAll === true) {
          sessionOpt.redirect = false
          exitStatus = 'showRegeneratedRecoveryKeys'
        }
        return { sessionOpt, exitStatus }
      },
      afterSessionInit: async ({ exitStatus, response }) => {
        // The user is being redirected to recovery codes screen to download, copy or view their new codes as they've just used their last one
        // The mutation below will generate a new set of keys and assumes the user is auth'd
        if (exitStatus === 'showRegeneratedRecoveryKeys') {
          // targetPath sets the route the recovery code screen will navigate after the user obtains their codes if the MFA token has one.
          if (response?.targetPath) setTargetPath(response.targetPath)
          await regenerateRecoveryKeys()
        }
      }
    })
  }, [performAuth, recoveryKey, verifyRecoveryKey, mfaToken, regenerateRecoveryKeys])

  // Failure state - MFA Token is invalid or expired
  const onBackToLoginClick = useCallback(() => {
    // This will navigate to routes.HOME which will show the login page when unauth'd
    navigateToApp({ history })
  }, [history])

  return (
    <SignInContainer newRecoveryKeys={status === 'showRegeneratedRecoveryKeys'}>
      <SignInHeader bottomMargin={['recoveryReady', 'recoveryWaiting', 'fail'].includes(status) ? '1.2em' : undefined}>{I18n.t('twoFactorAuthentication', trOpt)}</SignInHeader>
      {['authReady', 'authWaiting'].includes(status) && (
        <AuthenticationSteps
          icon='mobile'
          type='authentication'
          onInputChange={onAuthCodeChange}
          input={authCode}
          handleSubmit={onAuthSubmit}
          optionClick={goToRecoveryCodeEntry}
          title={I18n.t('authenticationCode', trOpt)}
          details={I18n.t('openTheTwoFactorAuthenticationApp', trOpt)}
        />
      )}
      {['recoveryReady', 'recoveryWaiting'].includes(status) && (
        <>
          <BackLink type='link' onClick={goToAuthCodeEntry}>{I18n.t('common.goBack')}</BackLink>
          <AuthenticationSteps
            icon='key'
            type='recovery'
            input={recoveryKey}
            onInputChange={onRecoveryKeyChange}
            handleSubmit={onRecoveryKeySubmit}
            title={I18n.t('recoveryCode', trOpt)}
            details={I18n.t('youCanEnterOneOfYourRecoveryCodes', trOpt)}
          />
        </>
      )}
      {status === 'showRegeneratedRecoveryKeys' && <RecoveryKeysRegenOnLastKey {...{ targetPath }} />}
      {status === 'fail' && (
        <>
          <BackLink type='link' onClick={onBackToLoginClick}>{I18n.t('forgottenPasswordLink.backToLogin', trOpt)}</BackLink>
          <ErrorAlerts error={error} defaultError={I18n.t(DEFAULT_ERROR_TR_KEY)} forceDefaultError />
        </>
      )}
    </SignInContainer>
  )
}

export default withRefreshSessionState(
  MultiFactorAuth
)
