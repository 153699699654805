import gql from 'graphql-tag'

export const GET_UBREACH_DATA = gql`
    query($excludeInactiveUsers: Boolean) {
        uBreachData(excludeInactiveUsers: $excludeInactiveUsers) {
            learnerCount
            breached
            notBreached
            services {
                name
                count
            }
            lastCheck
        }
    }
`

export const QUEUE_BREACH_SCAN = gql`
  mutation($companyId: ID!, $rescan: Boolean) {
    queueCompanyBreachScan(companyId: $companyId, rescan: $rescan)
  }
`

export const GET_BREACHES_DATA = gql`
  query {
    breachesData {
      breachedUsers {
        email
        domain
        learnerId
        firstName
        lastName
        inactive
        breaches {
          name
          title
          breachedData {
            type
            value
          }
          resolved
          hasExposedInfoData
        }
      }
      breachedServices {
        name
        title
        breachDate
        addedDate
        modifiedDate
        logo
        description
        dataClasses (returnIds: true)
        category
      }
      learnerCounts {
        all
        active
      }
    }
  }
`

export const MARK_BREACHES_RESOLVED = gql`
  mutation($records: [BreachResolution]) {
    markBreachesResolved(records: $records)
  }
`
