import React from 'react'
import { Alert } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import I18n from 'i18n-js'

import { connect } from '../../hocs'

const trOpt = { scope: 'main.freeTrialMessage' }

const FreeTrialMessageAlert = styled(Alert)`
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`

const FreeTrialMessage = ({ expires = null, planValid = true }) => {
  let message
  let type

  const freeTrialDays = Math.ceil(moment().diff(expires, 'day', true) * -1)
  if (planValid && freeTrialDays === 0) {
    message = I18n.t('expiredMessage', trOpt)
    type = 'warning'
  } else if (planValid && freeTrialDays < 3) {
    message = I18n.t('remainingMessage', { ...trOpt, count: freeTrialDays })
    type = 'info'
  }

  if (!(message && type)) {
    return null
  }

  return (
    <FreeTrialMessageAlert {...{ message, type }} showIcon banner />
  )
}

const planSelector = state => {
  const { session: { expires = null, planValid = true } = {} } = state || {}
  return { expires, planValid }
}

export default connect(
  planSelector
)(FreeTrialMessage)
