import session from './session'
import settings from './settings'
import view from './view'

export const getSessionAndSettings = state => ({
  session: session.get(state),
  settings: settings.get(state)
})

export {
  session,
  settings,
  view
}

export default {
  getSessionAndSettings,
  session,
  settings,
  view
}
