import React, { useCallback } from 'react'
import _get from 'lodash/get'
import { Button } from 'antd'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'

import { UNASSUME_COMPANY_USER } from '../Queries/Users'
import { withConsumer } from '../../hocs'
import routes from '../../constants/routes'
import { showErrors } from '../../helpers'
import { setSessionToken, postSessionChannelMessage } from '../../helpers/session'

const trOpt = { scope: 'nav.unassumeCompanyUser' }

export const isUnassumeCompanyUserAllowed = ({ session }) => session.inDisguise

const UnassumeCompanyUser = ({ client, label = I18n.t('revert', trOpt), me = false }) => {
  const [unassumeRole, { loading }] = useMutation(UNASSUME_COMPANY_USER)
  const onClick = useCallback(async () => {
    try {
      const result = await unassumeRole({ variables: { me } })
      const token = _get(result, 'data.unassumeCompanyUser.token')
      if (!token) {
        throw new Error(I18n.t('uService.noTokenReturned'))
      }
      // Set token
      setSessionToken(token)
      // Tell other tabs/windows to reload
      postSessionChannelMessage('sessionReloadPage')
      // Refresh the page
      window.location.href = routes.USERVICE
    } catch (e) {
      showErrors(e, I18n.t('errorMessage', trOpt))
    }
  }, [unassumeRole, me])

  return (
    <Button type='primary' icon='login' loading={loading} onClick={onClick} style={{ display: 'block', marginBottom: 5 }}>{label}</Button>
  )
}

export default withConsumer(UnassumeCompanyUser)
