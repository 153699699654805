import React, { useState, useEffect, useCallback } from 'react'
import { Alert, Button, Checkbox, Row, Collapse, Modal } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { compose } from 'recompose'
import { useQuery, useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import Bowser from 'bowser'

import routes from '../../constants/routes'
import { GET_PENDING_POLICIES, VISIT_POLICY, SIGN_POLICY } from '../../components/Queries/uPolicy'
import { withLoadingBuffer, withProvider, connect, withAppTitleAndIcons, withLearnerThemeDataQuery } from '../../hocs'
import { IntroDetail, IntroWrap } from '../../components/LearnerCourse/LearnerCourseIntro'
import { MainWrap, TitleWrap, TitleLogo, CourseTitle } from '../../components/LearnerCourse/LearnerCourse'
import { PendingCol, PendingCard } from '../../components/LearnerCourse/LearnerCourseFinished'
import LearnerCourseHolding from '../../components/LearnerCourse/LearnerCourseHolding'
import { renderContent } from '../../components/LearnerCourse/common'
import { LoadingBlock, ErrorAlerts } from '../../components/common'
import _PolicyPDFViewer from '../../components/Policies/PolicyPDFViewer'
import { BREAKPOINT } from '../../constants/style'
import { getBrowserLocale } from '../../helpers/locale'
import { creators as sessionActions } from '../../state/actions/session'
import { creators as settingsActions } from '../../state/actions/settings'
import RedirectToPortalButton from '../../components/EndUserPortal/RedirectToPortalButton'
import { getEndUserSessionType } from '../../helpers/endUserSession'
import { captureSentryError } from '../../helpers/sentry'
import { identifyUnauthenticatedLearnerWithPosthog } from '../../helpers/posthog'

const trOpt = { scope: 'uPolicy.learnerPolicy' }
const END_USER_PORTAL = window.__USECURE_CONFIG__.REACT_APP_END_USER_PORTAL === 'true'

const { Panel } = Collapse

const browser = Bowser.getParser(window.navigator.userAgent)
const USE_PLAIN_CONFIRM = browser.satisfies({
  ie: '<=11'
})

const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const PolicyContent = styled.div`
  margin-top: 30px;
  h1, h2, h3, h4, h5 {
    font-weight: 300;
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    color: #4c4e4d;
    line-height: 1.1;
    white-space: pre-line;
  }

  h1 {
      font-size: 32px
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 24px;
  }

 p {
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    color: rgb(124, 112, 107);
  }

  ul {
    margin-bottom:1.8em;
  }

  ul > li > ul {
    margin-bottom: 0;
  }

  ul li {
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px;
    font-weight: 400;

    white-space: normal;

    &> span {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }

  ol {
    counter-reset: ol-counter;
    margin-left: 25px;

    li {
      list-style-type: none;
      margin-bottom: 1.75rem !important;
      padding-left: 15px;
      position: relative;

      span {
        font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
        font-size: 20px;
        line-height: 1.4;
        font-weight: 400;
        color: rgb(124,112,107);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        background-color: ${({ theme }) => theme.primary};
        color: white;
        content: counters(ol-counter, ".") " ";
        counter-increment: ol-counter;
        height: 2rem;
        left: -40px;
        padding: 0.15rem;
        position: absolute;
        text-align: center;
        top: -0.1rem;
        width: 2rem;
      }
    }
  }

  blockquote {
    border: none;
    box-sizing: border-box;
    color: #4c4e4d;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 60px 30px;
    position: relative;
    quotes: "\\201C" "\\201D" "\\2018" "\\2019";
    text-align: center;
    width: 95%;
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 2.6em;
    line-height: 1.4;
    font-weight: 400;


    :before, :after {
      color: #4c4e4d;
      font-family: 'Impact', sans-serif !important;
      font-size: 7rem;
      font-style: normal;
      height: 60px;
      line-height: 1;
      position: absolute;
      width: 60px;
    }

    :before {
      content: open-quote;
      top: 0;
      left: 5px;
    }

    :after {
      content: close-quote;
      bottom: 0;
      right: 5px;
    }
  }
`

const PolicyBodyContainer = styled.div`
  align-self: center;
  flex: 1;
  overflow: auto;
  padding: 10px 0;
  width: 100%;
`
const PolicyContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
`

const PolicyPDFViewer = styled(_PolicyPDFViewer)`
  margin: 0 auto;
  max-width: 1100px;
`

const PolicyBody = ({ type, document, file }) => (
  <PolicyBodyContainer>
    {type === 'editor' && document && (
      <PolicyContentContainer>
        <PolicyContent dangerouslySetInnerHTML={{ __html: renderContent(JSON.stringify(document)) }} />
      </PolicyContentContainer>
    )}
    {type === 'pdf' && file && <PolicyPDFViewer {...{ file }} height='100%' />}
  </PolicyBodyContainer>
)

const SignPolicy = styled.div`
  border-top: 1px solid #e8e8e8;;
  padding: 20px 0 40px;
  text-align: center;

  @media (max-width: ${BREAKPOINT}) {
    padding: 0 0 20px;
  }
`

const SignedPolicyAlert = styled(Alert)`
  text-align: center;
`

const SignCheckbox = styled(Checkbox)`
  font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-width: 0 3px 3px 0;
  }

  .ant-checkbox-inner {
    height: 25px;
    width: 25px;

    &:after {
      width: 8px;
      height: 15px;
    }
  }

  @media (max-width: ${BREAKPOINT}) {
    font-size: 14px;
  }
`

const LearnerPolicyError = () => {
  return (
    <MainWrap>
      <IntroWrap>
        <IntroDetail>
          <h1>{I18n.t('anErrorOccurredLoadingThePolicy', trOpt)}</h1>
          <h3>
            {I18n.t('thisIsEitherDueToAnIncorrectLink', trOpt)}
            <br />
            {I18n.t('common.pleaseContactSupport')}
          </h3>
        </IntroDetail>
      </IntroWrap>
    </MainWrap>
  )
}

export const PendingPolicies = ({ pendingPolicies = [], learnerId }) => {
  return (
    <Row gutter={16}>
      {
        pendingPolicies.map((policy, index) => (
          <PendingCol xs={{ span: 24 }} lg={{ span: 8 }} key={index}>
            <PendingCard
              hideBody
              title={policy.name}
              extra={
                <Link to={generatePath(routes.LEARNER_POLICY, { learnerId, policyId: policy.id })}>{I18n.t('reviewPolicyLink', trOpt)}</Link>
              }
            />
          </PendingCol>
        ))
      }
    </Row>
  )
}

const LearnerPolicySigned = ({ learnerId, type, document, file }) => {
  const { loading, error, data: { pendingLearnerPolicies } = {} } = useQuery(GET_PENDING_POLICIES, { variables: { id: learnerId } })
  if (loading) return <LoadingBlock fullScreen loading={loading} />
  if (error) return <ErrorAlerts {...{ error }} defaultError={I18n.t('failedToLoadOutstandingPolicies', trOpt)} />

  return (
    <>
      {pendingLearnerPolicies.length >= 1 && (
        <Collapse accordion>
          <Panel header={I18n.t('youHaveOutstandingPolicies', trOpt)}>
            <PendingPolicies pendingPolicies={pendingLearnerPolicies} learnerId={learnerId} />
          </Panel>
        </Collapse>
      )}
      <SignedPolicyAlert message={I18n.t('youHaveSignedThisPolicy', trOpt)} type='success' />
      <PolicyBody {...{ type, document, file }} />
    </>
  )
}

const PolicyBodyContent = compose(
  withAppTitleAndIcons({ noAuth: true }),
  withLearnerThemeDataQuery()
)(({
  status, loading, learnerId, type, document,
  file, onCheckedChange, checked, onSignClick, companyId
}) => {
  let content
  if (!loading) {
    switch (status) {
      case 'new':
      case 'visited':
      case 'opened':
        content = (
          <>
            <PolicyBody {...{ type, document, file }} />
            <SignPolicy>
              <div style={{ margin: '20px 0' }}>
                <SignCheckbox
                  onChange={onCheckedChange}
                  checked={checked}
                >{I18n.t('iHaveReadUnderstoodAndAgree', trOpt)}
                </SignCheckbox>
              </div>
              <div>
                <Button type='primary' size='large' onClick={onSignClick} disabled={!checked}>{I18n.t('common.continue')}</Button>
              </div>
            </SignPolicy>
          </>
        )
        break
      case 'signed':
        content = <LearnerPolicySigned {...{ learnerId, type, document, file }} />
        break
      case 'plan_invalid':
        content = (
          <MainWrap>
            <LearnerCourseHolding {...{ companyId }} />
          </MainWrap>
        )
        break
      case 'error':
        content = <LearnerPolicyError />
        break
      default:
        content = null
        break
    }
  }
  return content || null
})

const LearnerPolicy = ({ match, locale, setLocale, setThemeGlobalSettingsState }) => {
  const [loading, setLoading] = useState(true)
  const [visitStatus, setVisitStatus] = useState('start')
  const [id, updateId] = useState()
  const [policyId, updatePolicyId] = useState()
  const [learnerId, updateLearnerId] = useState(null)
  const [companyId, updateCompanyId] = useState(null)
  const [status, updateStatus] = useState('new')
  const [title, updateTitle] = useState(null)
  const [type, updateType] = useState(null)
  const [document, updateDocument] = useState(null)
  const [file, updateFile] = useState(null)
  const [settings, updateSettings] = useState(null)
  const [checked, updateChecked] = useState(false)
  const [endUserSessionType, setEndUserSessionType] = useState('none')

  const showRedirectToPortal = endUserSessionType === 'limited' || (END_USER_PORTAL && (settings && settings.endUserPortalEnabled === true))
  // Set locale from browser locale on mount
  useEffect(() => setLocale(getBrowserLocale()), [setLocale])

  const [executeVisit] = useMutation(VISIT_POLICY, {
    onCompleted: (data) => {
      identifyUnauthenticatedLearnerWithPosthog(data.visitPolicy)
    }
  })
  const [executeSign] = useMutation(SIGN_POLICY)

  const callMutation = useCallback(async ({ executeMutation, mutationName, id, learnerId, policyId, refetchQueries }) => {
    let success = false
    setLoading(true)
    try {
      const { data: { [mutationName]: result } = {} } = await executeMutation({
        variables: {
          id,
          learnerId,
          policyId
        },
        refetchQueries
      })

      const { id: newId, status, policyId: newPolicyId, learnerId: newLearnerId, policyTitle, type, document, file, settings, companyId } = result || {}
      if (!['new', 'visited', 'opened', 'signed', 'plan_invalid'].includes(status)) {
        throw Error('unexpected status')
      }

      const endUserSessionType = await getEndUserSessionType()
      setEndUserSessionType(endUserSessionType)

      updateId(newId)
      updatePolicyId(newPolicyId)
      updateLearnerId(newLearnerId)
      updateCompanyId(companyId)
      updateStatus(status)
      updateTitle(policyTitle)
      updateType(type)
      updateDocument(document)
      updateFile(file)
      updateSettings(settings)
      success = true
    } catch (e) {
      captureSentryError(e)
      console.error('LearnerPolicy.callMutation - error', e)
      updateStatus('error')
    }
    setLoading(false)
    return success
  }, [])

  const callVisit = useCallback(
    async (learnerId, policyId) => {
      const success = await callMutation({ executeMutation: executeVisit, mutationName: 'visitPolicy', learnerId, policyId })
      if (success) {
        setVisitStatus('complete')
      }
    }, [callMutation, executeVisit])

  const callSign = useCallback(async () => {
    if (checked) {
      callMutation({ executeMutation: executeSign, mutationName: 'signPolicy', id, refetchQueries: [{ query: GET_PENDING_POLICIES, variables: { id: learnerId } }] })
    }
  }, [callMutation, executeSign, id, checked, learnerId])

  const onSignClick = useCallback(async () => {
    if (type === 'pdf' && USE_PLAIN_CONFIRM) {
      const ready = window.confirm(`${I18n.t('pleaseClickTheButtonBelow', trOpt)}\n${I18n.t('thisConfirmationWillBeRecorded', trOpt)}`)
      return ready ? callSign() : null
    }
    Modal.confirm({
      title: I18n.t('pleaseClickTheButtonBelow', trOpt),
      content: I18n.t('thisConfirmationWillBeRecorded', trOpt),
      okText: I18n.t('iUnderstand', trOpt),
      cancelText: I18n.t('common.cancel'),
      onOk: async () => callSign()
    })
  }, [callSign, type])

  useEffect(() => {
    if (!match) return
    const { params: { learnerId: routeLearnerId = null, policyId: routePolicyId = null } = {} } = match
    if (
      routeLearnerId && routePolicyId && (
        visitStatus === 'start' || // Initial load of policy by recording a visit
        (visitStatus !== 'waiting' && (policyId !== routePolicyId || learnerId !== routeLearnerId)) // Subsequent load of another policy e.g. from the outstanding policies section
      )
    ) {
      setVisitStatus('waiting')
      updateChecked(false)
      callVisit(routeLearnerId, routePolicyId)
    }
  }, [match, policyId, learnerId, callVisit, visitStatus])

  const onCheckedChange = useCallback((e) => updateChecked(e.target.checked), [updateChecked])

  useEffect(() => {
    // Update page title and icons settings global state
    if (visitStatus === 'complete') {
      setThemeGlobalSettingsState(settings)
    }
  }, [visitStatus, settings, setThemeGlobalSettingsState])

  return (
    <>
      <LoadingBlock loading={loading} />
      <PolicyContainer>
        <TitleWrap>
          <TitleLogo loading={loading} />
          <CourseTitle>
            <div>{I18n.t('policyReview', trOpt)}{title && ` - ${title}`}</div>
            {showRedirectToPortal && (
              <RedirectToPortalButton {...{ companyId, endUserSessionType }} />
            )}
          </CourseTitle>
        </TitleWrap>
        <PolicyBodyContent
          {...{
            status,
            locale,
            learnerId,
            type,
            loading,
            document,
            file,
            onCheckedChange,
            checked,
            onSignClick,
            companyId
          }}
        />
      </PolicyContainer>
    </>
  )
}

export default compose(
  withLoadingBuffer,
  withProvider(),
  connect(
    state => ({ locale: state.session.locale }),
    dispatch => ({
      setThemeGlobalSettingsState: settings => dispatch(settingsActions.updateTheme(settings)),
      setLocale: locale => dispatch(sessionActions.updateLocaleOnly(locale))
    })
  )
)(LearnerPolicy)
