import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Empty, Pagination, Tag, Tooltip } from 'antd'
import styled, { css } from 'styled-components'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import SyncSetupGroup, { SyncSetupGroupCard } from './SyncSetupGroup'
import { buildTree } from '../Learners/helpers'
import { ListHeader, ListHeaderPanel, SearchBar } from '../common'
import { getGroupAllowListCounts, isUUID } from '../../helpers'
import TooltipIcon from '../common/TooltipIcon'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;

  h3 {
    margin-bottom: 0;
  }
`

const SelectAllContainer = styled.div`
  ${({ align = 'left' }) => {
    if (align !== 'left') {
      return ''
    }

    return css`
      .ant-checkbox-wrapper {
        margin-right: 8px;
      }
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    `
  }}
`

const GroupContainer = styled.div`
  border: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 12px;
`

const GroupsScroller = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`

const Groups = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: -5px;
  position: relative;

  ${SyncSetupGroupCard} {
    padding: 0 5px 5px;
    @media (max-width: 575px) {
      width: 100%;
    }
    @media (min-width: 576px) {
      width: 50%;
    }
    @media (min-width: 992px) {
    width: ${100 / 3}%;
    }
    @media (min-width: 1200px) {
      width: 25%;
    }
    @media (min-width: 1600px) {
      width: 20%;
    }
    @media (min-width: 2000px) {
      width: ${100 / 6}%;
    }
  }
`

const PaginationContainer = styled.div`
  margin: 20px 0 5px;
  text-align: right;

  .ant-pagination-options-size-changer.ant-select {
    margin-right: 7px;
  }
`

const CountContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  &> .ant-tag {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};
    font-size: 14px;
    margin-left: 8px;
    margin-right: 0;
    padding: 3px 10px;
  }
`

const isSyncGroup = group => !_isNil(group.syncRecordId) && !_isNil(group.syncType)

const SyncSetupGroups = ({
  footer, groups, usecureGroups, loading,
  selectAllGroups = false, selectAllGroupMembWhitelist = false,
  onSelectAllChange = () => {},
  onMultiSelectChange = () => {},
  includeGroups = false, includeGroupMembWhitelist = false,
  trOpt = { scope: 'syncSetup.syncSetupGroups' },
  groupTypes = [],
  ...groupProps
}) => {
  const { hasUsecureGroups, usecureGroupTreeOptions } = useMemo(() => ({
    hasUsecureGroups: _isArray(usecureGroups) && usecureGroups.length > 0,
    usecureGroupTreeOptions: buildTree(usecureGroups, null, false, isSyncGroup)
  }), [usecureGroups])

  const onSelectAllGroupsChange = useCallback(e => onSelectAllChange('selectAllGroups', e.target.checked), [onSelectAllChange])
  const onSelectAllGroupMembWhitelistChange = useCallback(e => onSelectAllChange('selectAllGroupMembWhitelist', e.target.checked), [onSelectAllChange])

  const [searchText, setSearchText] = useState('')
  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])
  const [searchGroups, setSearchGroups] = useState(groups)
  useEffect(() => {
    if (!searchText) {
      return setSearchGroups([...groups])
    }
    const searchTerm = searchText.toLowerCase().trim()

    if (isUUID(searchTerm)) {
      const matchGroup = groups.find(({ id }) => String(id).toLowerCase() === searchTerm)
      return setSearchGroups(matchGroup ? [matchGroup] : [])
    }

    return setSearchGroups(groups.filter(({ name }) =>
      name && String(name).toLowerCase().includes(searchTerm))
    )
  }, [searchText, groups, setSearchGroups])

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page)
  }, [setCurrentPage])
  const onShowSizeChange = useCallback((current, pageSize) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }, [setPageSize])

  const [groupPage, setGroupPage] = useState([])
  const [groupPageSelectAllGroups, setGroupPageSelectAllGroups] = useState(false)
  const [groupPageSelectAllGroupMembWhitelist, setGroupPageSelectAllGroupMembWhitelist] = useState(false)
  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const groupPage = searchGroups.slice(startIndex, endIndex)
    setGroupPage(groupPage)
    setGroupPageSelectAllGroups(groupPage.every(group => group.inGroups === true))
    setGroupPageSelectAllGroupMembWhitelist(groupPage.every(group => group.inGroupMemb === true))
  }, [searchGroups, currentPage, pageSize, setGroupPage, setGroupPageSelectAllGroups, setGroupPageSelectAllGroupMembWhitelist])
  const pageCount = Math.ceil(searchGroups.length / pageSize)
  const maxPageCount = Math.ceil(searchGroups.length / 50)
  const showGroupPageSelectAll = pageCount > (searchText ? 0 : 1)

  const onGroupPageSelectAllChange = useCallback((prop, value) => {
    const groupPageIds = groupPage.map(({ id }) => id)
    onMultiSelectChange(groupPageIds, prop, value)
  }, [groupPage, onMultiSelectChange])
  const onGroupPageSelectAllGroupsChange = useCallback(e => onGroupPageSelectAllChange('inGroups', e.target.checked), [onGroupPageSelectAllChange])
  const onGroupPageSelectAllGroupMembWhitelistChange = useCallback(e => onGroupPageSelectAllChange('inGroupMemb', e.target.checked), [onGroupPageSelectAllChange])

  useEffect(() => {
    const pageCount = Math.ceil(searchGroups.length / pageSize)
    if (currentPage > pageCount) {
      setCurrentPage(1)
    }
  }, [searchGroups, pageSize, currentPage, setCurrentPage])

  const [groupCount, setGroupCount] = useState(0) // Synced Groups count
  const [groupMembCount, setGroupMembCount] = useState(0) // Groups Users are synced from count
  useEffect(() => {
    const { groupWhitelist, groupMembWhitelist } = groups.reduce((acc, group) => {
      const { id, inGroups, inGroupMemb } = group
      if (includeGroups && !selectAllGroups && inGroups) {
        acc.groupWhitelist.push(id)
      }
      if (includeGroupMembWhitelist && !selectAllGroupMembWhitelist && inGroupMemb) {
        acc.groupMembWhitelist.push(id)
      }
      return acc
    }, { groupWhitelist: [], groupMembWhitelist: [] })
    const { groupCount, groupMembCount } = getGroupAllowListCounts({ totalGroupCount: groups.length, groupWhitelist, groupMembWhitelist, includeGroups, includeGroupMembWhitelist, selectAllGroups, selectAllGroupMembWhitelist })
    setGroupCount(groupCount)
    setGroupMembCount(groupMembCount)
  }, [groups, includeGroups, includeGroupMembWhitelist, selectAllGroups, selectAllGroupMembWhitelist])

  return (
    <ContentContainer>
      <h3>{I18n.t('sync.common.groupConfiguration')}</h3>
      <Header>
        {
          !loading &&
            <>
              <SelectAllContainer align='right'>
                {
                  includeGroups && (
                    <Checkbox checked={selectAllGroups} onChange={onSelectAllGroupsChange}>
                      <span>{I18n.t('includeAllGroupsInSync', { ...trOpt, count: groups.length })} </span>
                      <Tooltip
                        title={
                          <p>
                            {I18n.t('includeAllGroupsInSyncTooltip.autoSync', trOpt)}
                            <br />
                            {I18n.t('includeAllGroupsInSyncTooltip.configureManually', trOpt)}
                          </p>
                        }
                        placement='rightTop'
                      >
                        <span><TooltipIcon /></span>
                      </Tooltip>
                    </Checkbox>
                  )
                }
                {
                  includeGroupMembWhitelist && (
                    <Checkbox
                      checked={selectAllGroupMembWhitelist || selectAllGroups}
                      disabled={selectAllGroups}
                      onChange={onSelectAllGroupMembWhitelistChange}
                    >
                      <span>{I18n.t('includeUsersFromAllGroupsInSync', { ...trOpt, count: groups.length })} </span>
                      <Tooltip
                        title={
                          <p>
                            {I18n.t('includeUsersFromAllGroupsInSyncTooltip.autoSync', trOpt)}
                            <br />
                            {I18n.t('includeUsersFromAllGroupsInSyncTooltip.configureManually', trOpt)}
                          </p>
                        }
                        placement='rightTop'
                      >
                        <span><TooltipIcon /></span>
                      </Tooltip>
                    </Checkbox>
                  )
                }
              </SelectAllContainer>
              {(includeGroups || includeGroupMembWhitelist) && (
                <CountContainer>
                  {includeGroups && <Tag>{I18n.t('groupsIncludedCount', { ...trOpt, count: groupCount })}</Tag>}
                  {includeGroupMembWhitelist && <Tag>{I18n.t('groupsUsersCount', { ...trOpt, count: groupMembCount })}</Tag>}
                </CountContainer>
              )}
            </>
        }
      </Header>
      {
        !loading &&
          <>
            <GroupContainer>
              <ListHeader>
                <ListHeaderPanel align='left'>
                  {
                    showGroupPageSelectAll &&
                      <SelectAllContainer>
                        {
                          includeGroups && (
                            <Checkbox checked={groupPageSelectAllGroups} onChange={onGroupPageSelectAllGroupsChange}>
                              <span>{I18n.t('includeGroupsBelowInSync', { ...trOpt, count: groupPage.length })} </span>
                            </Checkbox>
                          )
                        }
                        {
                          includeGroupMembWhitelist && (
                            <Checkbox
                              checked={groupPageSelectAllGroupMembWhitelist || groupPageSelectAllGroups}
                              disabled={groupPageSelectAllGroups}
                              onChange={onGroupPageSelectAllGroupMembWhitelistChange}
                            >
                              <span>{I18n.t('includeUsersFromGroupsBelowInSync', { ...trOpt, count: groupPage.length })} </span>
                            </Checkbox>
                          )
                        }
                      </SelectAllContainer>
                  }
                </ListHeaderPanel>
                <ListHeaderPanel align='right' width={305}>
                  <SearchBar
                    placeholder={I18n.t('sync.common.searchGroups')}
                    value={searchText}
                    allowClear
                    onChange={onSearchChange}
                  />
                </ListHeaderPanel>
              </ListHeader>
              <GroupsScroller>
                {
                  _isEmpty(searchGroups)
                    ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    : (
                      <Groups>
                        {
                          groupPage.map((group, index) => (
                            <SyncSetupGroup
                              key={group.id}
                              groupTypes={groupTypes}
                              {...{
                                group,
                                usecureGroupTreeOptions,
                                hasUsecureGroups,
                                includeGroups,
                                includeGroupMembWhitelist,
                                ...groupProps
                              }}
                            />
                          ))
                        }
                      </Groups>
                    )
                }
              </GroupsScroller>
              {
                maxPageCount > 1 &&
                  <PaginationContainer>
                    <Pagination
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      pageSizeOptions={['50', '100', '250']}
                      pageSize={pageSize}
                      current={currentPage}
                      onChange={onPageChange}
                      total={searchGroups.length}
                      showQuickJumper={pageCount > 10}
                      showTotal={(total, range) => I18n.t('sync.common.groupPaginationTotal', { rangeStart: range[0], rangeEnd: range[1], total })}
                    />
                  </PaginationContainer>
              }
            </GroupContainer>
          </>
      }
    </ContentContainer>
  )
}

export default SyncSetupGroups
