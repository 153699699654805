/* global localStorage */
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Button, Icon } from 'antd'
import { useMutation, useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import styled, { withTheme } from 'styled-components'
import { rgba, transparentize, lighten } from 'polished'
import moment from 'moment'
import _Slider from 'react-slick'
import { NetworkStatus } from 'apollo-client'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'

import { assumeCompanyUser } from '../../helpers/company'
import { ASSUME_COMPANY_USER } from '../Queries/Users'
import { advanceRiskReport } from '../../helpers/riskReport'
import { ADVANCE_RISK_REPORT, GET_USERVICE_PRIORITY_TASKS } from '../Queries/Companies'
import { NEXT_RISK_REPORT_STATUS } from '../../constants/company'
import { FontAwesomeIcon } from '../common'
import routes from '../../constants/routes'
import RiskReportStatus from './RiskReportStatus'
import UpgradeProspectModal from '../Modals/UpgradeProspectModal'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import { QUERY_POLL_INTERVAL } from '../../constants/apolloClient'

const _SliderIcon = ({ type, className, style, onClick }) => <Icon {...{ className, style, type, onClick }} />
const SliderIcon = styled(_SliderIcon)`
  color: ${({ theme }) => theme.primary};
  font-size: 24px;

  &:hover, &:active, &:focus {
    color: ${({ theme }) => lighten(0.25, theme.primary)};
  }

  &.slick-disabled {
    opacity: 0.25;
    &:hover, &:active, &:focus {
      color: ${({ theme }) => theme.primary};
    }
  }
`

const Slider = styled(_Slider)`
  .slick-dots {
    bottom: -30px;

    li button:before {
      color: ${({ theme }) => theme.primary};
    }
  }

  /* This left aligns the task slides */
  .slick-track {
    margin-left: 0;
  }
`

const PriorityTaskSlider = ({ children }) => (
  <Slider {...{
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    prevArrow: <SliderIcon type='left-circle' />,
    nextArrow: <SliderIcon type='right-circle' />,
    responsive: [{
      breakpoint: 1680,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }, {
      breakpoint: 1360,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 830,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  }}
  >
    {children}
  </Slider>
)

const PriorityTaskBox = styled.div`
  text-align: center;

  &> div {
    align-items: center;
    background: ${({ theme }) => theme.white};
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 20px 10px;

    &> div {
      width: 100%;
    }
  }
  .prospect-task-box {
    &-desc {
      margin-top: 15px;
    }
    &-age {
      margin-top: 10px;
    }
  }

  ${RiskReportStatus} {
    margin-top: 10px;
  }

  .ant-btn {
    margin-top: 10px;
  }
`

// Allows button label to wrap onto a new line - Required in French to avoid overflow issues
const PriorityTaskButton = styled(Button)`
  height: auto;
  line-height: 1.75 !important;
  padding: 5px 15px 1px;
  white-space: normal;
`

const PriorityTask = ({ company, onClick: onClickProp = () => {}, theme }) => {
  const { name, status, statusLabel, mspTask, nextAction, advance, login, taskAge } = company
  const onClick = useCallback(() => onClickProp(company), [company, onClickProp])

  return (
    <PriorityTaskBox>
      <div>
        <div>
          <div>{name}</div>
          <div>{statusLabel}</div>
          {mspTask !== 'MISSING' && <div className='prospect-task-box-desc'>{mspTask}</div>}
        </div>
        <div>
          <PriorityTaskButton type='primary' disabled={!(advance || login)} onClick={onClick}>{nextAction}</PriorityTaskButton>
          <RiskReportStatus {...{ status }} />
          <div className='prospect-task-box-age'>{I18n.t('uService.priorityTaskAge', { taskAge })}</div>
        </div>
      </div>
    </PriorityTaskBox>
  )
}

const PriorityTasksWrap = styled.div`
  margin: 0 auto 30px;
  max-width: 1500px;
  padding: 0 50px;
  width: 100%;
`

const PriorityTasksCard = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid #e8e8e8;
  border-bottom-width: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;

  .prospect-task {
    &-heading {
      font-size: 18px;
      margin-top: 5px;
      user-select: none;

      .anticon {
        margin-right: 15px;
      }

      span {
        line-height: 1;
      }
    }
    &-badge {
      align-items: center;
      background-color: ${({ theme }) => rgba(theme.primary, 0.34)};
      border-radius: 40px;
      color: ${({ theme }) => theme.primary};
      display: flex;
      flex: 0 0 40px;
      height: 40px;
      justify-content: center;
      user-select: none;
      width: 40px;

      i, &-count {
        font-size: 18px;
      }
      &-count {
        font-weight: bold;
        line-height: 1;
        margin-top: 5px;
      }
    }
  }
`

const _PriorityTasksContainer = ({ className, children }) => (
  <div {...{ className }}>
    <div>{children}</div>
  </div>
)
const PriorityTaskContainer = styled(_PriorityTasksContainer)`
  border: 1px solid #e8e8e8;
  border-top-width: 0;
  max-height: ${({ showAll }) => showAll ? 400 : 0}px;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;

  &> div {
    background-color: ${transparentize(0.4, '#C4C4C4')};
    padding: 25px 40px 40px;
  }

  ${PriorityTaskBox} {
    height: ${({ boxHeight = 280 }) => boxHeight}px;
    padding: 0 10px;
  }
`

const PriorityTasks = ({ theme, companyId, userId, accountType, refetchQueries, locale = DEFAULT_LANGUAGE, viewVariables, pollingEnabled = true }) => {
  const { loading: queryLoading, error, networkStatus, data } = useQuery(GET_USERVICE_PRIORITY_TASKS, {
    variables: viewVariables,
    pollInterval: pollingEnabled ? QUERY_POLL_INTERVAL : 0,
    notifyOnNetworkStatusChange: true,
    skip: !viewVariables
  })
  const loading = queryLoading && networkStatus !== NetworkStatus.poll
  const [executeAdvanceRiskReport] = useMutation(ADVANCE_RISK_REPORT, { refetchQueries })
  const [executeAssumeCompanyUser] = useMutation(ASSUME_COMPANY_USER)
  const upgradeProspectModalRef = useRef(null)
  const openUpgradeProspect = useCallback(companyId => {
    if (upgradeProspectModalRef.current) {
      upgradeProspectModalRef.current.open(companyId)
    }
  }, [upgradeProspectModalRef])
  const onProspectClick = useCallback(async company => {
    const { id, name, status, advance, login, accountType: companyAccountType } = company
    if (advance) {
      return status === 'trial_requested' ? openUpgradeProspect(id) : advanceRiskReport(executeAdvanceRiskReport, id, name, status, true)
    } else if (login) {
      return assumeCompanyUser({
        executeAssumeCompanyUser,
        company,
        accountType,
        assumeStorageId: `uServiceAssume|${companyId}|${userId}`,
        admin: true,
        targetRoute: companyAccountType === 'tenant' ? routes.RISK_REPORT_PREVIEW : null
      })
    }
  }, [userId, companyId, accountType, executeAssumeCompanyUser, executeAdvanceRiskReport, openUpgradeProspect])

  const [prospects, setProspects] = useState([])
  useEffect(() => {
    const { uServiceCompanyRiskReportTasks: allCompanies } = data || {}
    let prospects = []
    if (_isArray(allCompanies) && !_isEmpty(allCompanies)) {
      const now = moment()
      prospects = allCompanies
        .reduce((acc, company) => {
          const status = _get(company, 'riskReport.status')
          const { id, name, activeLearnerCount: learnerCount, accountType } = company
          const nextStatus = NEXT_RISK_REPORT_STATUS[status]
          let advance = ['new', 'domain_complete', 'ubreach_complete', 'uphish_finished', 'uphish_complete', 'trial_requested'].includes(status)
          let login = ['ubreach_requested', 'uphish_requested'].includes(status)
          let nextAction = I18n.t(`riskReport.mspTaskAction.${status}`, { defaults: [{ message: 'Advance Prospect' }] })
          let mspTask = I18n.t(`riskReport.mspTaskStatus.${status}`, { defaults: [{ message: 'MISSING' }] })
          if (status === 'ubreach_requested' && learnerCount > 0) {
            advance = true
            login = false
            nextAction = I18n.t('riskReport.mspTaskAction.ubreach_ready', { defaults: [{ message: 'Advance Prospect' }] })
            mspTask = I18n.t('riskReport.mspTaskStatus.ubreach_ready', { defaults: [{ message: 'MISSING' }] })
          }

          const lastActivity = _get(company, 'riskReport.lastActivity') || new Date().toISOString()

          acc.push({
            id,
            name,
            status,
            statusLabel: I18n.t(`riskReport.status.${status}`, { defaults: [{ message: status }] }),
            nextStatus,
            nextAction,
            mspTask,
            advance,
            login,
            learnerCount,
            accountType,
            lastActivity,
            taskAge: moment.duration(moment(lastActivity).diff(now)).humanize(true)
          })

          return acc
        }, [])

      prospects.sort((a, b) => {
        const aMoment = moment(a.lastActivity)
        const bMoment = moment(b.lastActivity)
        if (aMoment.isAfter(bMoment)) {
          return 1
        } else if (aMoment.isBefore(bMoment)) {
          return -1
        }
        return 0
      })
    }
    setProspects(prospects)
  }, [data, companyId])

  const [showAll, setShowAll] = useState(false)
  const onClick = () => {
    if (prospects.length > 0) {
      setShowAll(!showAll)
    }
  }

  const [storageId, setStorageId] = useState(null)
  useEffect(() => {
    if (companyId && userId) {
      setStorageId(`uServicePriorityTasks|${companyId}|${userId}`)
    }
  }, [userId, companyId])
  useEffect(() => {
    try {
      let pageData = {
        showAll: false
      }
      const pageDataString = localStorage.getItem(storageId)
      if (pageDataString) {
        pageData = { ...pageData, ...JSON.parse(pageDataString) }
      }
      Object.keys(pageData).forEach(key => {
        const value = pageData[key]
        switch (key) {
          case 'showAll':
            setShowAll(value)
            break
          default:
            break
        }
      })
    } catch (e) { }
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    let pageData = {}
    try {
      const pageDataString = localStorage.getItem(storageId)
      if (pageDataString) {
        pageData = JSON.parse(pageDataString)
      }
      pageData = { ...pageData, ...data }
      localStorage.setItem(storageId, JSON.stringify(pageData))
    } catch (e) { }
  }, [storageId])

  useEffect(() => {
    updateLocalStorage({ showAll })
  }, [updateLocalStorage, showAll])

  const [boxHeight, setBoxHeight] = useState(280)
  useEffect(() => {
    let boxHeight = 280
    if (['fr', 'fr-CA', 'es'].includes(locale)) {
      boxHeight = 310
    } else if (['de'].includes(locale)) {
      boxHeight = 320
    }
    setBoxHeight(boxHeight)
  }, [locale])

  return (
    <>
      <PriorityTasksWrap>
        <PriorityTasksCard onClick={onClick}>
          <div className='prospect-task-heading'>
            {prospects.length > 0 && <Icon type={showAll ? 'down' : 'right'} />}
            <span>{I18n.t('uService.priorityTasks')}</span>
          </div>
          <div className='prospect-task-badge'>
            {loading && <Icon type='loading' />}
            {error && !loading && <Icon type='exclamation-circle' />}
            {
              !loading && !error && (
                prospects.length > 0
                  ? <div className='prospect-task-badge-count'>{prospects.length}</div>
                  : <FontAwesomeIcon icon='check' />
              )
            }
          </div>
        </PriorityTasksCard>
        {prospects.length > 0 && (
          <PriorityTaskContainer {...{ showAll, boxHeight }}>
            <PriorityTaskSlider>
              {prospects.map(company => <PriorityTask key={company.id} onClick={onProspectClick} {...{ company, theme }} />)}
            </PriorityTaskSlider>
          </PriorityTaskContainer>
        )}
        <PriorityTaskSlider />
      </PriorityTasksWrap>
      <UpgradeProspectModal
        ref={upgradeProspectModalRef}
        refetchQueries={refetchQueries}
      />
    </>
  )
}

export default withTheme(PriorityTasks)
