import React, { useState, useCallback, useMemo, useImperativeHandle } from 'react'
import { Modal, Tabs } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'

import { connect } from '../../../hocs'
import selectors from '../../../state/selectors'
import BreachesView from '../../uBreach/BreachesView'
import UserBreachesView from '../../uBreach/UserBreachesView'
import ViewRecordCard from '../../common/ViewRecordCard'
import { ModalPager } from '../../../components/common'

const { TabPane } = Tabs

const uBreachTrOpt = { scope: 'uBreach' }

const BreachesModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const ModalScroller = styled.div`
  height: 100%;
  overflow: auto;
`

const DomainDetails = ({ domain, breachedServices = [], breachedUsers = [], refetchQueries, handleRefreshClick }) => {
  const [tabKey, setTabKey] = useState('breaches')

  const tabs = useMemo(() => {
    if (!domain) return []
    const tabs = []
    tabs.push({
      tab: I18n.t('breaches', uBreachTrOpt),
      key: 'breaches',
      content: (
        <BreachesView {...{ domain, breachedServices, breachedUsers, refetchQueries, handleRefreshClick }} />
      )
    })
    tabs.push({
      tab: I18n.t('users', uBreachTrOpt),
      key: 'users',
      content: <UserBreachesView {...{ domain, breachedServices, breachedUsers, refetchQueries, handleRefreshClick }} />
    })
    return tabs
  }, [domain, breachedServices, breachedUsers, refetchQueries, handleRefreshClick])

  return (
    <ModalScroller>
      <ViewRecordCard>
        <div className='view-record__body'>
          <div className='view-record__header'>
            <div>
              <h1>{domain}</h1>
            </div>
          </div>
          <Tabs activeKey={tabKey} onChange={setTabKey}>
            {
              tabs.map(({ tab, key, content }) => (
                <TabPane key={key} tab={tab}>
                  {content}
                </TabPane>
              ))
            }
          </Tabs>
        </div>
      </ViewRecordCard>
    </ModalScroller>
  )
}

const DomainDetailsModal = React.forwardRef(({ breachedServices = [], breachedUsers = [], refetchQueries, handleRefreshClick }, ref) => {
  const [visible, setVisible] = useState(false)
  const [domain, setDomain] = useState(null)
  const [pager, setPager] = useState({})

  useImperativeHandle(ref, () => ({
    open: async ({ record: { domain }, pager }) => {
      setDomain(domain)
      setPager(pager)
      setVisible(true)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setDomain(null)
    setPager({})
  }

  return (
    <BreachesModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      afterClose={afterClose}
      width='90%'
      bodyStyle={{ height: 'calc(100% - 50px)' }}
      title={<ModalPager {...pager} />}
    >
      <DomainDetails {...{ domain, breachedServices, breachedUsers, refetchQueries, handleRefreshClick }} />
    </BreachesModal>
  )
})

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['planValid']),
    ..._pick(selectors.settings.get(state), ['uBreachProSettings'])
  })
)(DomainDetailsModal)
