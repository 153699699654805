import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Divider, Icon, Progress, Tooltip } from 'antd'
import I18n from 'i18n-js'
import TooltipIcon from '../common/TooltipIcon'
import IntercomHeader from '../IntercomHeader'

const StyledProgress = styled(Progress)`
& .ant-progress-inner {
  border-radius: 0
}
`

// JG: When the percentage on a gauge is 100%, antd sets the text color to green.
// There seems to be no nice way to overide this. This is a workaround to set
// the text color to black.
const StyledProgressGauge = styled(Progress)`
.ant-progress-text {
  color: rgba(0, 0, 0, 0.65) !important;
}
`

const DashboardStatistic = ({ id, isEnabled = true, hasLink, isValue, hoverText, loading, isGauge, value, contentArea, title, link, percentage, percentageChange, percentageDescription, extraTitle, extraData, color, getPopupContainer }) => {
  let strokeColor
  let gaugeColor

  if (color) {
    gaugeColor = color
    strokeColor = color
  } else {
    if (percentage <= 25) {
      gaugeColor = '#5EB77E'
      strokeColor = '#f75959'
    } else if (percentage > 25 && percentage < 75) {
      gaugeColor = '#F8DC4A'
      strokeColor = '#F8DC4A'
    } else if (percentage >= 75) {
      gaugeColor = '#f75959'
      strokeColor = '#5EB77E'
    } else {
      strokeColor = '#377B59'
    }
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IntercomHeader id={id} Size='h4' style={{ margin: 0 }}>{title}</IntercomHeader>
        {hasLink && isEnabled && (<Link to={link}><Icon type='right-circle' style={{ cursor: 'pointer' }} /></Link>)}
        {hoverText && (
          <Tooltip placement='left' title={hoverText} {...{ getPopupContainer }}>
            <span><TooltipIcon /></span>
          </Tooltip>)}
      </div>
      {
        isEnabled && isValue && ((
          <h1 style={{ fontSize: '46px', textAlign: 'center', margin: 0, marginTop: '20px' }}>{value}</h1>
        ))
      }
      {isEnabled && isGauge && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <StyledProgressGauge strokeLinecap='square' type='dashboard' percent={percentage} width={90} strokeColor={gaugeColor} format={(percent) => I18n.t('common.percentage', { value: percent })} />
        </div>
      )}
      {
        isEnabled && !isValue && !isGauge && (
          <div style={{ margin: '30px 0 0px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ marginBottom: 0, fontSize: '30px' }}>{percentage || 0}%
              </h1>
              {percentageChange && (
                <p style={{ marginBottom: 0, color: percentageChange[0] === '+' ? '#377B59' : '#D12D34', marginLeft: '5px' }}>{percentageChange}%</p>
              )}
            </div>

            <StyledProgress
              strokeColor={strokeColor}
              percent={percentage}
              showInfo={false}
              strokeLinecap='square'
            />
            <p style={{ margin: 0 }}>{percentageDescription}</p>
          </div>
        )
      }
      {!isEnabled && (<Icon type='exclamation-circle' style={{ fontSize: '60px', color: 'rgba(0, 0, 0, 0.2)' }} />)}
      <div>
        <Divider style={{ marginTop: '25px', marginBottom: '10px' }} />
        {isEnabled ? (<p style={{ margin: 0 }}>{extraTitle} - {extraData}</p>) : <p style={{ margin: 0 }}>{I18n.t('common.disabled')}</p>}
      </div>
    </div>
  )
}

export default DashboardStatistic
