import { Modal, message } from 'antd'
import I18n from 'i18n-js'

import { SEND_ALL_SIMULATION_EMAILS } from '../Queries/uPhish'
import { UPHISH_SIMULATION_PERFORMANCE } from '../Queries/Reports'
import authenticatedClient from '../../apollo-client/authenticatedClient'
import { showErrors } from '../../helpers'

const trOpt = { scope: 'modals.sendAllSimulationEmailsConfirm' }

const { confirm } = Modal

const sendAllSimulationEmailsConfirm = async ({ simulationIds = [], client = authenticatedClient, excludeInactiveUsers = false }) => {
  confirm({
    title: I18n.t('prompt', { ...trOpt, count: simulationIds.length }),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        const { data: { sendAllSimulationEmails: success } = {} } = await client.mutate({
          mutation: SEND_ALL_SIMULATION_EMAILS,
          variables: { simulationIds },
          refetchQueries: [{
            query: UPHISH_SIMULATION_PERFORMANCE,
            variables: { excludeInactiveUsers }
          }]
        })
        if (!success) {
          throw new Error()
        }
        message.success(I18n.t('successMessage', { ...trOpt, count: simulationIds.length }))
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', { ...trOpt, count: simulationIds.length }))
      }
    }
  })
}

export default sendAllSimulationEmailsConfirm
