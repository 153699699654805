import gql from 'graphql-tag'

export const GET_PENDING_COURSES = gql`
  query ($courseResultId: ID, $locale: LanguageCode) {
    pendingLearnerCourses (courseResultId: $courseResultId, locale: $locale) {
      id
      name
      description
    }
  }
`

export const LEARNER_COURSE_GRAPHQL_FIELDS = `
id
status
learnerId
learnerName
companyId
courseId
courseTitle
courseDescription
courseIcon
courseBackgroundImage
isGapAnalysis
content
answers {
  courseId
  questionId
  answers
  correct
}
retries
score
settings
availableLanguages
locale
preferredLocale
finishDate
`

export const GET_LEARNER_COURSE = gql`
  query ($learnerId: ID, $courseId: ID!, $locale: String, $preview: Boolean, $fromBuilder: Boolean){
    getLearnerCourse (learnerId: $learnerId, courseId: $courseId, locale: $locale, preview: $preview, fromBuilder: $fromBuilder) {
      ${LEARNER_COURSE_GRAPHQL_FIELDS}
    }
  }
`

export const START_LEARNER_COURSE = gql`
      mutation($id: ID, $preview: Boolean, $learnerId:ID, $courseId: ID!, $fromBuilder: Boolean) {
        startCourse(id: $id, preview: $preview, learnerId: $learnerId, courseId: $courseId, fromBuilder: $fromBuilder) {
          ${LEARNER_COURSE_GRAPHQL_FIELDS}
        }
      }
    `
export const ANSWER_LEARNER_COURSE_QUESTION = gql`
      mutation($id: ID, $preview: Boolean, $learnerId:ID, $courseId: ID!, $fromBuilder: Boolean, $questionId: String!, $answerIds: [String]!) {
        answerCourseQuestion(id: $id, preview: $preview, learnerId: $learnerId, courseId: $courseId, fromBuilder: $fromBuilder, questionId: $questionId, answerIds: $answerIds) {
          ${LEARNER_COURSE_GRAPHQL_FIELDS}
        }
      }
    `
export const RETRY_LEARNER_COURSE = gql`
      mutation($id: ID, $preview: Boolean, $learnerId:ID, $courseId: ID!, $fromBuilder: Boolean) {
        retryCourse(id: $id, preview: $preview, learnerId: $learnerId, courseId: $courseId, fromBuilder: $fromBuilder) {
          ${LEARNER_COURSE_GRAPHQL_FIELDS}
        }
      }
    `
export const FINISH_LEARNER_COURSE = gql`
      mutation($id: ID, $preview: Boolean, $learnerId:ID, $courseId: ID!, $fromBuilder: Boolean) {
        finishCourse(id: $id, preview: $preview, learnerId: $learnerId, courseId: $courseId, fromBuilder: $fromBuilder) {
          ${LEARNER_COURSE_GRAPHQL_FIELDS}
        }
      }
    `

export const LEARNER_COURSE_UPDATED_SUBSCRIPTION = gql`
  subscription onLearnerCourseUpdated($id: ID, $learnerId: ID, $courseId: ID!, $locale: String, $preview: Boolean, $fromBuilder: Boolean) {
    learnerCourseUpdated (id: $id, learnerId: $learnerId, courseId: $courseId, locale: $locale, preview: $preview, fromBuilder: $fromBuilder) {
      ${LEARNER_COURSE_GRAPHQL_FIELDS}
    }
  }
`

export const EXTEND_COURSE_PREVIEW = gql`
  mutation ($courseId: ID!) {
    extendPreviewCourseResult(courseId: $courseId)
  }
`
export const EXTEND_GAP_ANALYSIS_PREVIEW = gql`
  mutation {
    extendPreviewGapAnalysisResult
  }
`
export const RESET_COURSE_PREVIEW = gql`
  mutation ($courseId: ID!) {
    resetPreviewCourseResult(courseId: $courseId)
  }
`
export const RESET_GAP_ANALYSIS_PREVIEW = gql`
  mutation {
    resetPreviewGapAnalysisResult
  }
`
