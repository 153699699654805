import gql from 'graphql-tag'

export const GET_LEARNER_THEME_DATA = gql`
  query($id: ID!) {
    getLearnerThemeData(id: $id)
  }
`

export const GET_LEARNER_MSP_NAME = gql`
  query($id: ID!) {
    getLearnerMspName(id: $id)
  }
`

export const VALIDATE_ENROL_COURSE_UPLOAD = gql`
  query ($courseId: ID!, $data: [[String]]!) {
    validateEnrolCourseUpload(courseId: $courseId, data: $data) {
      learners {
        id
        name
        email
        courseResults(courseId: $courseId) {
          courseId
          enrollDate
          startDate
          finishDate
          retries
          score
        }
      }
      errors
    }
  }
`

export const GET_ENROL_COURSE_LEARNER = gql`
  query ($courseId: ID!, $email: String!) {
    learner (email: $email) {
      id
      name
      email
      courseResults(courseId: $courseId) {
        courseId
        enrollDate
        startDate
        finishDate
        retries
        score
      }
    }
  }
`
