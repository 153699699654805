import React, { Component } from 'react'
import { Form, Input } from 'antd'
import I18n from 'i18n-js'
import PropTypes from 'prop-types'
import _get from 'lodash/get'

import EditCourseChecklistItem from './EditCourseChecklistItem'
import EditCourseSlate from './EditCourseSlate'
import DragAndDropList from './DragAndDropList'
import { CONTENT_TYPE_NAMES } from './common'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const trOpt = { scope: 'editCourse.editCourseChecklist' }

class EditCourseChecklist extends Component {
  constructor (props) {
    super(props)

    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.updateChecklistItems = this.updateChecklistItems.bind(this)
    this.updateIntro = this.updateIntro.bind(this)

    this.checklistItemTemplate = {
      id: null,
      title: '',
      slideId: ''
    }
  }

  get checklistItems () {
    return _get(this.props, 'content.checklistItems') || []
  }

  get slideOptions () {
    const { slideList = [] } = this.props
    return slideList.map(({ slideId, title, type }, index) => {
      const label = `${index + 1}. ${CONTENT_TYPE_NAMES[type]}${title ? ` - ${title}` : ''}`
      return { value: slideId, label }
    })
  }

  handleTitleChange (event) {
    this.props.updateTitle(event.target.value)
  }

  updateIntro ({ intro }) {
    this.updateContent({ intro })
  }

  updateContent (update) {
    this.props.updateContent({ ...(this.props.content || {}), ...update })
  }

  updateChecklistItems (checklistItems) {
    this.updateContent({ checklistItems })
  }

  render () {
    const { title, content, locale, slideId, updateId } = this.props

    return (
      <div>
        <Form.Item label={I18n.t('editCourse.common.title')}>
          <Input
            onChange={this.handleTitleChange}
            value={title}
          />
        </Form.Item>

        <Form.Item label={I18n.t('editCourse.common.intro')}>
          <EditCourseSlate updateContent={this.updateIntro} {...{ locale, content, slideId, updateId }} />
        </Form.Item>
        <DragAndDropList
          list={this.checklistItems}
          itemName={I18n.t('checklistItem', trOpt)}
          itemComponent={EditCourseChecklistItem}
          itemTemplate={this.checklistItemTemplate}
          updateList={this.updateChecklistItems}
          updateInProgress={this.props.updateInProgress}
          id={this.props.slideId}
          slideOptions={this.slideOptions}
        />
      </div>
    )
  }
}

EditCourseChecklist.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseChecklist.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseChecklist
