import React, { useImperativeHandle, useState, useCallback, useMemo, useRef } from 'react'
import moment from 'moment'
import { message, Modal } from 'antd'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import I18n from 'i18n-js'
import queryString from 'query-string'

import routes from '../../constants/routes'

import { validateEmail, waitForRefRender } from '../../helpers'
import { LoadingBlock } from '../common'
import { GET_SCHEDULED_REPORT_REPORTING_PERIODS, SEND_SCHEDULED_REPORT } from '../Queries/Reports'
import { withConsumer } from '../../hocs/withConsumer'
import MutationForm from '../MutationForm/MutationForm'
import { openReport } from '../../helpers/reports'

const ViewModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const ViewScheduledReportModal = React.forwardRef(({
  client,
  companyId,
  useCompanyId,
  onDownloadReport = () => {}
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reportingPeriods, setReportingPeriods] = useState([])
  const [scheduledReport, setScheduledReport] = useState(null)
  const [action, setAction] = useState('view')

  const form = useRef(null)

  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  const setInitialFormValues = useCallback(async (values) => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  const trOpt = useMemo(() => {
    switch (action) {
      case 'view':
        return { scope: 'modals.viewScheduledReportModal' }
      case 'download':
        return { scope: 'modals.downloadScheduledReportModal' }
      case 'send':
        return { scope: 'modals.sendScheduledReportModal' }
      default:
        return { scope: 'modals.viewScheduledReportModal' }
    }
  }, [action])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setReportingPeriods([])
    setScheduledReport(null)
    setAction('view')
    resetForm()
  }

  const onSuccess = useCallback(() => {
    if (action !== 'download') message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal, action, trOpt])

  useImperativeHandle(ref, () => ({
    open: async (scheduledReport, action = 'view') => {
      setVisible(true)
      setLoading(true)
      const { id } = scheduledReport

      const periodsResult = await client.query({
        query: GET_SCHEDULED_REPORT_REPORTING_PERIODS,
        variables: { id, companyId }
      })
      const reportingPeriods = _get(periodsResult, 'data.scheduledReportReportingPeriods').sort(
        (a, b) => moment(a.fromDate) > moment(b.fromDate) ? -1 : 1
      )
      setReportingPeriods(reportingPeriods)

      await waitForRefRender(form)
      await setInitialFormValues({
        ...(scheduledReport ? { recipients: _get(scheduledReport, 'recipients', []) } : {}),
        ...(reportingPeriods.length > 0 ? { reportingPeriod: `${reportingPeriods[0].fromDate}|${reportingPeriods[0].toDate}` } : {})
      })

      setScheduledReport(scheduledReport)
      setAction(action)
      setLoading(false)
    }
  }), [form, setInitialFormValues, companyId, client])

  const fields = useMemo(() => [
    {
      id: 'reportingPeriod',
      label: I18n.t('reports.common.reportingPeriod'),
      type: 'select',
      required: true,
      options: reportingPeriods.map((period) => ({
        value: `${period.fromDate}|${period.toDate}`,
        label: I18n.t(`reports.common.reportingPeriods.${scheduledReport?.frequency}`, {
          fromMonth: moment(period.fromDate).format('MMMM'),
          fromYear: moment(period.fromDate).format('YYYY'),
          quarter: moment(period.fromDate).quarter()
        })
      }))
    },
    {
      id: 'recipients',
      label: I18n.t('reports.reportCentre.common.recipients'),
      type: 'textTags',
      required: true,
      validateTag: value => {
        if (!validateEmail(value)) {
          return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
        }
      },
      defaultValue: [],
      visible: action === 'send'
    }
  ], [scheduledReport, reportingPeriods, action])

  const mutateValues = useCallback(values => {
    const { reportingPeriod } = values
    const [fromDate, toDate] = reportingPeriod.split('|')

    return {
      ..._omit(values, ['reportingPeriod']),
      id: scheduledReport.id,
      fromDate,
      toDate
    }
  }, [scheduledReport])

  const getViewLink = useCallback(({ fromDate, toDate }) => {
    const { reportType, parameters: { reportSections: sections, granularity } } = scheduledReport
    let route
    switch (reportType) {
      case 'performanceReport':
        route = routes.PERFORMANCE_REPORT
        break
      case 'breachReport':
        route = routes.BREACH_REPORT
        break
      default:
        route = null
    }

    if (!route) return null

    const query = {
      ...(useCompanyId ? { companyId } : {}),
      ...(sections ? { sections } : {}),
      ...(granularity ? { granularity } : {}),
      fromDate,
      toDate
    }

    return `${generatePath(route)}?${queryString.stringify(query, { skipNull: true, skipEmptyString: true, arrayFormat: 'comma' })}`
  }, [scheduledReport, companyId, useCompanyId])

  const openReportFromScheduledReport = useCallback(({ fromDate, toDate }) => {
    const { parameters: { reportSections: sections, granularity }, reportType } = scheduledReport
    openReport({
      ...(useCompanyId ? { companyId } : {}),
      fromDate,
      toDate,
      sections,
      granularity,
      reportType
    }, { openInNewTab: true })
  }, [scheduledReport, companyId, useCompanyId])

  const viewReport = useCallback((values) => {
    const { reportingPeriod } = values
    const [fromDate, toDate] = reportingPeriod.split('|')

    if (['performanceReport', 'breachReport'].includes(scheduledReport.reportType)) {
      return openReportFromScheduledReport({ fromDate, toDate })
    }

    window.open(getViewLink({ fromDate, toDate }), '_blank')
  }, [getViewLink, openReportFromScheduledReport, scheduledReport])

  const onSubmit = useCallback((values) => {
    if (action === 'view') {
      viewReport(values)
    } else if (action === 'download') {
      const { reportingPeriod } = values
      const [fromDate, toDate] = reportingPeriod.split('|')
      const { id: scheduledReportId, name, frequency: period, reportType } = scheduledReport

      onDownloadReport({
        ...(useCompanyId ? { companyId } : {}),
        scheduledReportId,
        name,
        period,
        reportType,
        fromDate,
        toDate
      })
    }
  }, [action, viewReport, scheduledReport, onDownloadReport, useCompanyId, companyId])

  return (
    <ViewModal
      title={I18n.t('title', { ...trOpt, name: scheduledReport && scheduledReport.name })}
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      afterClose={afterClose}
      width='70%'
    >
      <LoadingBlock {...{ loading }} fullScreen={false} />
      <MutationForm
        ref={form}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('submitLabel', trOpt)}
        {...{
          fields,
          onSuccess,
          mutateValues,
          ...(action !== 'send' ? { onSubmit } : {}),
          ...(action === 'send' ? {
            mutation: SEND_SCHEDULED_REPORT,
            variables: { companyId }
          } : {})
        }}
      />
    </ViewModal>
  )
})

export default withConsumer({ useHooks: true })(ViewScheduledReportModal)
