import gql from 'graphql-tag'

const policyFields = `
id
name
type @include(if: $includeDocument)
document @include(if: $includeDocument)
file @include(if: $includeDocument)
draftType @include(if: $includeDraftDocument)
draftDocument @include(if: $includeDraftDocument)
draftFile @include(if: $includeDraftDocument)
majorVersion @skip(if: $basicOnly)
minorVersion @skip(if: $basicOnly)
template @skip(if: $basicOnly)
tile @skip(if: $basicOnly)
category @skip(if: $basicOnly)
locales @skip(if: $basicOnly)
supportedLocales @skip(if: $basicOnly)
ownerName @skip(if: $basicOnly)
ownerEmail @skip(if: $basicOnly)
public @skip(if: $basicOnly)
excludesInactiveUsers @include(if: $withResults)
livePolicyDocument @include(if: $includeLivePolicyDocument) {
  id
  type @include(if: $includeDocument)
  document @include(if: $includeDocument)
  file @include(if: $includeDocument)
  majorVersion
  minorVersion
  major
  publishedAt
}
draftPolicyDocument @include(if: $includeDraftPolicyDocument) {
  id
  type @include(if: $includeDraftDocument)
  document @include(if: $includeDraftDocument)
  file @include(if: $includeDraftDocument)
  majorVersion
  minorVersion
  major
  publishedAt
}
lastMajorPolicyDocument @include(if: $includeLastMajorPolicyDocument) {
  id
  majorVersion
  minorVersion
  major
  publishedAt
}
documents @include(if: $includePolicyDocuments) {
  id
  status
  type @include(if: $includeDocument)
  document @include(if: $includeDocument)
  file @include(if: $includeDocument)
  majorVersion
  minorVersion
  major
  publishedAt
}
results @include(if: $withResults) {
  learnerId
  learner @include(if: $includeResultLearner) {
    id
    name
    email
    learnerId
  }
  id
  createdAt
  visit
  open
  signed
  versionSent
  versionVisited
  versionSigned
  signedOffline
}
companyId
company @include(if: $withCompany) {
  id
  name
}
global @include(if: $withAccess)
companies @include(if: $withAccess) {
  id
  name
}
signatureSettings @skip(if: $basicOnly) {
  type
  startDate
  intervalUnit
  intervalLength
  useDefault
}
updateAllowed
deleteAllowed
`

const policyMutationFields = `
id
name
type
document
file
draftType
draftDocument
draftFile
template
category
locales
signatureSettings {
  type
  startDate
  intervalUnit
  intervalLength
  useDefault
}
`

const accessMutationFields = `
global
companyId
companies {
  id
  name
}
`

export const GET_POLICIES = gql`
  query (
    $publishedOnly: Boolean,
    $basicOnly: Boolean = false, $withCompany: Boolean = false, $withResults: Boolean = false, $includeResultLearner: Boolean = false, $withAccess: Boolean = false,
    $includeDocument: Boolean = true, $includeLivePolicyDocument: Boolean = false, $includeDraftDocument: Boolean = true, $includeDraftPolicyDocument: Boolean = false,
    $includePolicyDocuments: Boolean = false, $includeLastMajorPolicyDocument: Boolean = false,
    $excludeInactiveUsers: Boolean
  ) {
    policies(publishedOnly: $publishedOnly, excludeInactiveUsers: $excludeInactiveUsers) { 
      ${policyFields}
    }
  }
`

export const GET_POLICY_TEMPLATES = gql`
  query (
    $restrictToOwn: Boolean, $restrictToPlan: Boolean, $publishedOnly: Boolean, $forUpdate: Boolean = false,
    $basicOnly: Boolean = false, $withCompany: Boolean = false, $withResults: Boolean = false, $includeResultLearner: Boolean = false, $withAccess: Boolean = false,
    $includeDocument: Boolean = true, $includeLivePolicyDocument: Boolean = false, $includeDraftDocument: Boolean = true, $includeDraftPolicyDocument: Boolean = false,
    $includePolicyDocuments: Boolean = false, $includeLastMajorPolicyDocument: Boolean = false
  ) {
    policyTemplates(restrictToOwn: $restrictToOwn, restrictToPlan: $restrictToPlan, publishedOnly: $publishedOnly, forUpdate: $forUpdate) {
      ${policyFields}
    }
  }
`

export const GET_POLICY = gql`
  query(
    $id: ID!, $publishedOnly: Boolean,
    $basicOnly: Boolean = false, $withCompany: Boolean = false, $withResults: Boolean = false, $includeResultLearner: Boolean = false, $withAccess: Boolean = false,
    $includeDocument: Boolean = true, $includeLivePolicyDocument: Boolean = false, $includeDraftDocument: Boolean = true, $includeDraftPolicyDocument: Boolean = false,
    $includePolicyDocuments: Boolean = false, $includeLastMajorPolicyDocument: Boolean = false,
    $excludeInactiveUsers: Boolean
  ) {
    policy(id: $id, publishedOnly: $publishedOnly, excludeInactiveUsers: $excludeInactiveUsers) {
      ${policyFields}
    }
  }
`

export const GET_POLICY_TEMPLATE = gql`
  query(
    $id: ID!, $forUpdate: Boolean, $publishedOnly: Boolean,
    $basicOnly: Boolean = false, $withCompany: Boolean = false, $withResults: Boolean = false, $includeResultLearner: Boolean = false, $withAccess: Boolean = false,
    $includeDocument: Boolean = true, $includeLivePolicyDocument: Boolean = false, $includeDraftDocument: Boolean = true, $includeDraftPolicyDocument: Boolean = false,
    $includePolicyDocuments: Boolean = false, $includeLastMajorPolicyDocument: Boolean = false
  ) {
    policyTemplate(id: $id, forUpdate: $forUpdate, publishedOnly: $publishedOnly) {
      ${policyFields}
    }
  }
`

export const CREATE_POLICY = gql`
  mutation(
    $name: String!, $type: PolicyDocumentType!, $document: String, $file: String, $templatePolicyId: ID, $category: PolicyCategory,
    $action: PolicyCreateAction!, $majorVersion: Int, $minorVersion: Int, $signatureSettings: PolicySignatureSettingsInput, $resend: Boolean,
    $ownerName: String, $ownerEmail: String, $public: Boolean
  ) {
    createPolicy(
      name: $name, type: $type, document: $document, file: $file, templatePolicyId: $templatePolicyId,
      category: $category, action: $action, majorVersion: $majorVersion, minorVersion: $minorVersion, signatureSettings: $signatureSettings,
      resend: $resend, ownerName: $ownerName, ownerEmail: $ownerEmail, public: $public
    ) {
      ${policyMutationFields}
      ${accessMutationFields}
    }
  }
`

export const CREATE_POLICY_TEMPLATE = gql`
  mutation(
    $name: String!, $type: PolicyDocumentType!, $document: String, $file: String, $global: Boolean , $companies: [ID!], $companyId: ID, $category: PolicyCategory, $tile: String,
    $locales: [LanguageCode!], $action: PolicyCreateAction!
  ) {
    createPolicyTemplate(
      name: $name, type: $type, document: $document, file: $file, global: $global, companies: $companies, companyId: $companyId, category: $category,
      tile: $tile, locales: $locales, action: $action
    ) {
      ${policyMutationFields}
      ${accessMutationFields}
    }
  }
`

export const UPDATE_POLICY = gql`
  mutation(
    $id: ID!, $name: String!, $type: PolicyDocumentType!, $document: String, $file: String, $category: PolicyCategory,
    $major: Boolean, $action: PolicyUpdateAction!, $signatureSettings: PolicySignatureSettingsInput, $resend: Boolean,
    $ownerName: String, $ownerEmail: String, $public: Boolean, $majorVersion: Int, $minorVersion: Int
  ) {
    updatePolicy(
      id: $id, name: $name, type: $type, document: $document, file: $file, category: $category,
      major: $major, action: $action, signatureSettings: $signatureSettings, resend: $resend, ownerName: $ownerName, ownerEmail: $ownerEmail, public: $public, majorVersion: $majorVersion, minorVersion: $minorVersion
    ) {
      ${policyMutationFields}
      ${accessMutationFields}
    }
  }
`

export const UPDATE_POLICY_TEMPLATE = gql`
  mutation(
    $id: ID!, $name: String!, $type: PolicyDocumentType!, $document: String, $file: String, $global: Boolean, $companies: [ID!], $companyId: ID, $category: PolicyCategory, $tile: String,
    $locales: [LanguageCode!], $action: PolicyUpdateAction!
  ) {
    updatePolicyTemplate(
      id: $id, name: $name, type: $type, document: $document, file: $file, global: $global, companies: $companies, companyId: $companyId, category: $category,
      tile: $tile, locales: $locales, action: $action
    ) {
      ${policyMutationFields}
      ${accessMutationFields}
    }
  }
`

export const UPDATE_POLICY_TEMPLATE_LANGUAGES = gql`
  mutation($ids: [ID!]!, $action: languageBulkUpdateAction, $locales: [LanguageCode!]!) {
    updatePolicyTemplateLanguages(ids: $ids, action: $action, locales: $locales) {
      ${policyMutationFields}
      ${accessMutationFields}
    }
  }
`

export const SEND_POLICY = gql`
  mutation($policyIds:[ID!]!, $recipients: PolicyRecipients!) {
    sendPolicy(policyIds: $policyIds, recipients: $recipients) {
      success
      policyIds
      learnerIds
      completed {
        id
        learners {
          id,
          reason,
          action
        }
      }
      failures {
        id
        learners {
          id,
          reason,
          action
        }
      }
    }
  }
`

export const PUBLISH_DRAFT_POLICY = gql`
  mutation($id: ID!, $major: Boolean, $majorVersion: Int, $minorVersion: Int, $resend: Boolean) {
    publishDraftPolicy(id: $id, major: $major, majorVersion: $majorVersion, minorVersion: $minorVersion, resend: $resend) {
      ${policyMutationFields}
    }
  }
`

export const PUBLISH_DRAFT_POLICY_TEMPLATE = gql`
  mutation($id: ID!) {
    publishDraftPolicyTemplate(id: $id) {
      ${policyMutationFields}
    }
  }
`

export const DISCARD_DRAFT_POLICY = gql`
  mutation($id: ID!) {
    discardDraftPolicy(id: $id) {
      ${policyMutationFields}
    }
  }
`

export const DISCARD_DRAFT_POLICY_TEMPLATE = gql`
  mutation($id: ID!) {
    discardDraftPolicyTemplate(id: $id) {
      ${policyMutationFields}
    }
  }
`

export const DELETE_POLICIES = gql`
  mutation($policyIds: [ID!]!) {
    deletePolicies(policyIds: $policyIds)
  }
`

export const DELETE_POLICY_TEMPLATES = gql`
  mutation($policyIds: [ID!]!) {
    deletePolicyTemplates(policyIds: $policyIds)
  }
`

export const GET_PENDING_POLICIES = gql`
  query ($id: ID!) {
    pendingLearnerPolicies(id: $id) {
      id
      name
    }
  }
`

export const DUPLICATE_POLICY = gql`
  mutation ($id: ID!, $name: String) {
    duplicatePolicy (id: $id, name: $name) {
      ${policyMutationFields}
    }
  }
`

export const DUPLICATE_POLICY_TEMPLATE = gql`
  mutation ($id: ID!, $name: String, $companyId: ID) {
    duplicatePolicyTemplate (id: $id, name: $name, companyId: $companyId) {
      ${policyMutationFields}
    }
  }
`

const LEARNER_POLICY_FIELDS = `
id
status
learnerId
learnerName
policyId
policyTitle
type
document
file
settings
companyId
`

export const VISIT_POLICY = gql`
  mutation ($id: ID, $learnerId: ID, $policyId: ID) {
    visitPolicy(id: $id, learnerId: $learnerId, policyId: $policyId) {
      ${LEARNER_POLICY_FIELDS}
    }
  }
`

export const SIGN_POLICY = gql`
  mutation ($id: ID, $learnerId: ID, $policyId: ID) {
    signPolicy(id: $id, learnerId: $learnerId, policyId: $policyId) {
      ${LEARNER_POLICY_FIELDS}
    }
  }
`

export const SIGN_POLICY_OFFLINE = gql`
  mutation($policyId:ID!, $learnerIds:[ID!]!) {
    signPolicyOffline (policyId: $policyId, learnerIds: $learnerIds)
  }
`

export const POLICY_TEMPLATES = gql`
query {
  policyTemplatesByCategory {
    category
    policies {
      id
      name
      tile
      category
      supportedLocales
    }
  }
}
`

export const SEND_POLICIES = gql`
  mutation($policyIds:[ID!]!, $learnerIds:[ID!]!) {
    sendPoliciesToLearners (policyIds: $policyIds, learnerIds: $learnerIds) {
      success
      policyIds
      learnerIds
      completed {
        id
        learners {
          id,
          reason,
          action
        }
      }
      failures {
        id
        learners {
          id,
          reason,
          action
        }
      }
    }
  }
`

export const GET_LEARNER_POLICIES = gql`
  query ($learnerId: ID!) {
    learner (id: $learnerId) {
      policyResults {
        policyId
        signed
      }
    }
  }
`

const SIGN_POLICY_OFFLINE_LEARNER_FIELDS = `
id
name
email
learnerId
policyResults (policyId: $policyId) {
  visit
  open
  signed
  versionSent
  versionVisited
  versionSigned
  signedOffline
}
`

export const VALIDATE_SIGN_POLICY_OFFLINE_UPLOAD = gql`
  query ($policyId: ID!, $data: [[String!]!]!) {
    validateSignPolicyOfflineUpload(data: $data) {
      learners {
        ${SIGN_POLICY_OFFLINE_LEARNER_FIELDS}
      }
      errors
    }
  }
`

export const GET_SIGN_POLICY_OFFLINE_LEARNER = gql`
  query ($policyId: ID!, $learnerId: String, $email: String) {
    learner (learnerId: $learnerId, email: $email) {
      ${SIGN_POLICY_OFFLINE_LEARNER_FIELDS}
    }
  }
`
const PUBLIC_POLICY_FIELDS = `
id
name
category
majorVersion
minorVersion
template
signatureSettings {
  type
  startDate
  intervalUnit
  intervalLength
  useDefault
}
livePolicyDocument {
  id
  majorVersion
  minorVersion
  major
  publishedAt
}
lastMajorPolicyDocument {
  id
  majorVersion
  minorVersion
  major
  publishedAt
}
signatureSettings {
  type
  startDate
  intervalUnit
  intervalLength
  useDefault
}
`

export const GET_PUBLIC_POLICIES = gql`
  query ($key: ID!) {
    publicPolicies(key: $key) {
      companyName
      mspName
      policies {
        ${PUBLIC_POLICY_FIELDS}
      }
      settings
    }
  }
`

export const GET_PUBLIC_POLICY = gql`
  query ($key: ID!, $id: ID!) {
    publicPolicy(key: $key, id: $id) {
      policy {
        ${PUBLIC_POLICY_FIELDS}
        documents {
          id
          status
          type
          document
          file
          majorVersion
          minorVersion
          major
          publishedAt
        }
      }
      settings
    }
  }
`

export const SEND_POLICY_REMINDERS_NOW = gql`
  mutation {
    sendPolicyReminders
  }
`
export const REMOVE_LEARNERS_FROM_POLICY = gql`
  mutation($policyIds: [ID!]!, $learnerIds: [ID!]!) {
    removeLearnersFromPolicy(policyIds: $policyIds, learnerIds: $learnerIds)
  }
`
