import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'

const LinkButton = styled(Button)`
  padding: 0;
`

/**
 * Displays a button styled as a link or plain text
 * @param {string} text - Text to display as link or plain text
 * @param {Function} [handleClick] - Function to call when link is clicked. If not provided, text is displayed as plain text
 * @param {Object} [clickArgs] - Arguments to pass to handleClick
 */
const LinkButtonOrText = ({ text, handleClick, clickArgs }) => {
  if (!handleClick) return text
  const onClick = () => handleClick(clickArgs)

  return (
    <LinkButton type='link' onClick={onClick}>{text}</LinkButton>
  )
}

export default LinkButtonOrText
