import moment from 'moment'
import _padStart from 'lodash/padStart'
import _toString from 'lodash/toString'

const padTime = value => _padStart(_toString(value), 2, '0')

/**
 * Returns a formatted timestamp string in an mm:ss or HH:mm:ss format for the number of seconds passed in e.g. 90 returns "01:30"; 30 returns "00:30"; 5400 returns "01:30:00"
 * This function is intended for our video players which typically present short form content e.g. 2 or 3 minutes.
 * Some of our clients have longer videos which are 1 to 2 hours long. That's the only reason we return up to HH:mm:ss with no handling of time units above hours.
 * @param {Number} timeInSeconds - Number of seconds in the time interval
 * @param {Object} options - Options object
 * @param {Boolean} options.forceHours - Force use of a HH:mm:ss timestamp
 * @returns {String} - The formatted timestamp string in an mm:ss or HH:mm:ss format
 */
export const getVideoTimestampFromSeconds = (timeInSeconds, { forceHours = false } = {}) => {
  const duration = moment.duration(timeInSeconds, 'seconds')
  const hours = Math.floor(duration.asHours()) // Using asHours in the unlikely event that a seconds interval over 1 day is passed in.
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  return `${(hours > 0 || forceHours) ? `${padTime(hours)}:` : ''}${padTime(minutes)}:${padTime(seconds)}`
}

/**
 * Returns a formatted timestamp string in an mm:ss or HH:mm:ss format for the number of seconds passed in to match the same units as a video's duration.
 * @param {Number} timeInSeconds - Number of seconds in the time interval
 * @param {Number} duration - Duration of the video in seconds. The timestamp will be padded to match this value. If it's over 1 hour (i.e. 3600) then HH:mm:ss timestamp format will be used
 * @returns {String} - The formatted timestamp string in an mm:ss or HH:mm:ss format
 */
export const getVideoTimestampFromSecondsMatchingDuration = (timeInSeconds, duration) =>
  getVideoTimestampFromSeconds(timeInSeconds, { forceHours: duration >= 3600 })
