import React, { useEffect, useState, useMemo, useCallback } from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { useQuery } from 'react-apollo'
import { compose } from 'recompose'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { Link, generatePath } from 'react-router-dom'

import { PAGE_X_PAD_DESKTOP_REM, PAGE_X_PAD_MOBILE_REM, BREAKPOINT } from '../../constants/style'
import { REPORT_ME } from '../../components/Queries/Reports'
import { connect, withProvider, withAppTitleAndIcons } from '../../hocs'
import { creators as sessionActions } from '../../state/actions/session'
import { creators as settingsActions } from '../../state/actions/settings'
import { creators as viewActions } from '../../state/actions/view'
import reportAccessClient from '../../apollo-client/reportAccessClient'
import { getBrowserLocale } from '../../helpers/locale'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import { REPORT_TYPE_CONTENT_VIEW_ROUTE_ID_MAP } from '../../constants/reports'
import routes from '../../constants/routes'
import { useReportAccessToken } from '../../hooks'
import ExportServiceClient from '../../helpers/ExportServiceClient'
import StatusIndicator from '../../components/Reports/DownloadReportStatusIndicator'
import { REPORT_CENTRE_PREVIEW } from '../../constants/environment'
import { PreviewTag } from '../../components/common'
import { formatDate } from '../../helpers/datetime'
import { captureSentryError } from '../../helpers/sentry'

const PUPPETEER_MICROSERVICE_URL = window.__USECURE_CONFIG__.REACT_APP_PUPPETEER_MICROSERVICE_URL

const trOpt = { scope: 'reports.downloadReport' }

const TITLE_HEIGHT_DESKTOP_REM = 3.5

const _TitleLogo = ({ loading = false, className }) => (
  <div {...{ className }}>
    {
      loading
        ? <div className='title-logo-placeholder' />
        : <div className='title-logo-image' />
    }
  </div>
)

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #eeeeee;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: ${BREAKPOINT}) {
    font-size: .85em;
  }
`

export const TitleWrap = styled.div`
  height: auto;
  padding-bottom: 0.7rem;
  z-index: 500;
  
  @media (min-width: ${BREAKPOINT}) {
    height: ${TITLE_HEIGHT_DESKTOP_REM}rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.07);
    padding-bottom: 0;
  }
  color: ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.nav};

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const TitleLogo = styled(_TitleLogo)`
  .title-logo-image {
    background-image: url('${({ theme }) => theme.appThemeLogo}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 3.3em;
    width: 6em;
    position: relative;
    @media (min-width: ${BREAKPOINT}) {
      left: ${PAGE_X_PAD_DESKTOP_REM}rem;
    }
    left:${PAGE_X_PAD_MOBILE_REM}rem;
  }

  .title-logo-placeholder {
    height: 1.5em;
  }
`

export const MainWrap = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: ${BREAKPOINT}) {
    padding: 0 ${PAGE_X_PAD_DESKTOP_REM}rem;
    margin-top: 2rem;
  }
  padding: 0 ${PAGE_X_PAD_MOBILE_REM}rem;
  margin-top: 2rem;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${BREAKPOINT}) {
    width: 800px;
  }
  background-color: #fff;
  padding: 30px 40px;
  text-align: center;
`

const DownloadReport = ({ match, setLoadingVisible, setTheme, updateSession, setLocale }) => {
  const [reportType, setReportType] = useState(match.params.reportType)
  const [status, setStatus] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const { reportAccessToken } = useReportAccessToken()

  const { data: { reportMe } = {}, loading } = useQuery(REPORT_ME, {
    skip: !reportAccessToken,
    onCompleted: (data) => {
      if (!_isEmpty(data)) {
        const { reportMe } = data
        setTheme({
          ...(_get(reportMe, 'company.settings') || {}),
          parentDefaultSettings: _get(reportMe, 'company.parentCompany.settings.defaultTenantSettings')
        })
        updateSession({
          mspName: _get(reportMe, 'company.parentCompany.name'),
          locale: getBrowserLocale(null) || _get(reportMe, 'company.locale') || DEFAULT_LANGUAGE
        })
        setReportType(_get(reportMe, 'reportType'))
      }
    },
    onError: (err) => {
      setErrorMessage(err.message.replace('GraphQL error: ', ''))
      captureSentryError(err, { msg: 'Download Report - Access Token Verification - ERROR' })
    }
  })

  // Set initial language from browser
  useEffect(() => setLocale(getBrowserLocale()), [setLocale])

  useEffect(() => {
    setLoadingVisible(loading)
  }, [loading, setLoadingVisible])

  const onStart = useCallback(({ status, errorMessage }) => {
    setStatus(status)
    setErrorMessage(errorMessage)
  }, [setStatus, setErrorMessage])

  const onFail = useCallback(({ errorMessage, originalError }) => {
    setStatus('failed')
    setErrorMessage(errorMessage)
    // Only capture if original error is present as that will be a exception rather than an expected fail state
    if (originalError) captureSentryError(originalError)
  }, [setStatus, setErrorMessage])

  const onStatusChange = useCallback(({ status }) => {
    setStatus(status)
  }, [setStatus])

  const onFinish = useCallback(() => {
    setStatus('downloaded')
  }, [setStatus])

  const puppeteerServiceClient = useMemo(() => {
    if (!reportAccessToken || !reportMe) return null

    const { fromDate, toDate, reportType, domain } = reportMe
    const fileNameKey = `fileNames.${reportType}`

    const fileNameParams = {
      defaultValue: 'report'
    }

    if (reportType === 'domainScanReport') {
      fileNameParams.domain = domain
    } else {
      fileNameParams.fromDate = fromDate
      fileNameParams.toDate = toDate
    }

    const fileName = `${I18n.t(fileNameKey, { ...trOpt, ...fileNameParams })}.pdf`
    return new ExportServiceClient({
      reportAccessToken,
      onStart,
      onStatusChange,
      onFail,
      onFinish,
      fileType: 'pdf',
      fileName,
      baseURL: PUPPETEER_MICROSERVICE_URL
    })
  }, [reportAccessToken, reportMe, onStart, onStatusChange, onFail, onFinish])

  useEffect(() => {
    if (errorMessage || loading || !puppeteerServiceClient) return
    puppeteerServiceClient.execute()
  }, [errorMessage, loading, puppeteerServiceClient])

  const contentViewLink = useMemo(() => {
    if (!reportType || !reportAccessToken) return null

    const routeKey = REPORT_TYPE_CONTENT_VIEW_ROUTE_ID_MAP[reportType]
    if (!routeKey) return null

    try {
      return generatePath(routes[routeKey], { token: reportAccessToken })
    } catch (err) {
      return null
    }
  }, [reportType, reportAccessToken])

  return (
    <>
      <Wrap>
        <TitleWrap>
          <TitleLogo loading={loading} />
        </TitleWrap>
        <MainWrap>
          <Container>
            <h1 style={{ marginBottom: 0 }}>
              {I18n.t(`titles.${reportType || 'default'}`, { ...trOpt, defaults: { scope: 'titles.default' } })}
              {reportMe && REPORT_CENTRE_PREVIEW && <PreviewTag header />}
            </h1>
            {reportMe && reportMe.fromDate && reportMe.toDate && (
              <h3>{formatDate(reportMe.fromDate)} - {formatDate(reportMe.toDate)}</h3>
            )}
            <StatusIndicator {...{ status, loading, errorMessage, trOpt }} />
            {contentViewLink && (
              <div>
                <p><Link to={contentViewLink} target='_blank'>{I18n.t('viewOnline', trOpt)}</Link></p>
              </div>
            )}
          </Container>
        </MainWrap>
      </Wrap>
    </>
  )
}

export default compose(
  connect(
    undefined,
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      updateSession: session => dispatch(sessionActions.noAuthUpdate(session)),
      setLocale: locale => dispatch(sessionActions.updateLocaleOnly(locale))
    })
  ),
  withProvider(reportAccessClient),
  withAppTitleAndIcons({ noAuth: true })
)(DownloadReport)
