import queryString from 'query-string'
import I18n from 'i18n-js'
import _isInteger from 'lodash/isInteger'
import _uniq from 'lodash/uniq'

import authenticatedClient from '../apollo-client/authenticatedClient'
import { UPDATE_M365_AUTH, UPDATE_OFFICE_365_SETTINGS } from '../components/Queries/Companies'
import { showErrors } from './ErrorHandler'
import routes from '../constants/routes'

const trOpt = { scope: 'office365.helpers' }
const USE_DELEGATED_AUTH = window.__USECURE_CONFIG__.REACT_APP_OFFICE_365_DELEGATED_AUTH === 'true'

class Office365 {
  constructor ({ clientId, redirectUri, tenantId = 'common', scopes = [] }) {
    this.clientId = clientId
    this.tenantId = tenantId
    this.redirectUri = redirectUri
    this.scopes = scopes
  }

  get authority () {
    return `https://login.microsoftonline.com/${this.tenantId}`
  }

  get scopeList () {
    return this.scopes.join(' ')
  }

  get offlineScopeList () {
    const scopes = [...this.scopes]
    // Add offline_access scope if missing as refresh token will not be supplied without it
    if (!scopes.includes('offline_access')) {
      scopes.unshift('offline_access')
    }
    return scopes.join(' ')
  }

  getAuthorizeUrl (state) {
    return `${this.authority}/oauth2/v2.0/authorize?${queryString.stringify({
      client_id: this.clientId,
      response_type: 'code',
      redirect_uri: this.redirectUri,
      scope: this.offlineScopeList,
      response_mode: 'query',
      prompt: 'select_account',
      state
    })}`
  }

  getAdminConsentUrl (state) {
    return `${this.authority}/adminconsent?${queryString.stringify({
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
      state
    })}`
  }
}

export const SYNC_DEFAULT_SCOPES = [
  'https://graph.microsoft.com/user.read.all',
  'https://graph.microsoft.com/group.read.all'
]

export class SyncOffice365 extends Office365 {
  constructor (tenantId) {
    super({
      tenantId,
      clientId: window.__USECURE_CONFIG__.REACT_APP_OFFICE_365_CLIENT_ID,
      redirectUri: `${window.location.origin}${routes.OFFICE_365_SETUP}`,
      scopes: SYNC_DEFAULT_SCOPES
    })
  }

  getDefaultAuthorizeUrl () {
    if (USE_DELEGATED_AUTH) {
      return this.getAuthorizeUrl()
    }
    return this.getAdminConsentUrl()
  }

  async updateAuthConfig ({ authCode, adminConsent, tenantId } = {}) {
    let variables = { app: 'sync' }
    if (adminConsent === 'True' || adminConsent === true) {
      variables = { ...variables, authType: 'admin', tenantId }
    } else {
      variables = { ...variables, authType: 'delegated', authCode, redirectUri: this.redirectUri }
    }
    try {
      return authenticatedClient.mutate({
        mutation: UPDATE_M365_AUTH,
        variables
      })
    } catch (e) {
      console.error('Office365.updateAuthConfig - error', typeof e, e)
      showErrors(e, I18n.t('theOffice365AuthenticationProcessFailed', trOpt))
    }
  }

  async updateSettings (settings) {
    try {
      return authenticatedClient.mutate({
        mutation: UPDATE_OFFICE_365_SETTINGS,
        variables: { settings }
      })
    } catch (e) {
      console.error('Office365.updateSettings - error', typeof e, e)
      showErrors(e, I18n.t('yourOffice365IntegrationSettings', trOpt))
    }
  }
}

export const syncOffice365 = new SyncOffice365() // Support the existing delegated permissions auth flow

export const getGroupAllowListCounts = ({
  groupWhitelist, groupMembWhitelist, includeGroups, includeGroupMembWhitelist, selectAllGroups, selectAllGroupMembWhitelist,
  totalGroupCount
}) => {
  const all = _isInteger(totalGroupCount) ? totalGroupCount : I18n.t('common.all')
  let groupCount = 0
  let groupMembCount = 0

  if (includeGroups) {
    if (selectAllGroups) {
      groupCount = all
      if (includeGroupMembWhitelist) {
        groupMembCount = all
      }
    } else {
      groupCount = (groupWhitelist || []).length
      if (includeGroupMembWhitelist) {
        if (selectAllGroupMembWhitelist) {
          groupMembCount = all
        } else {
          // Any included groups will have their users included too
          groupMembCount = _uniq([...(groupWhitelist || []), ...(groupMembWhitelist || [])]).length
        }
      }
    }
  } else if (includeGroupMembWhitelist) {
    if (selectAllGroupMembWhitelist) {
      groupMembCount = all
    } else {
      groupMembCount = (groupMembWhitelist || []).length
    }
  }

  return {
    groupCount,
    groupMembCount
  }
}

export default Office365
