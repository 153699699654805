import React, { useCallback, useImperativeHandle, useState, useEffect } from 'react'
import { Button, Form, message, Modal, Select } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'

import { UPDATE_USERVICE_COMPANY_SETTING, GET_COMPANY_FOR_UPDATE } from '../Queries/Companies'
import { showErrors } from '../../helpers'
import { withConsumer } from '../../hocs/withConsumer'
import { LoadingBlock } from '../common'
import { SETTING_DELETE_VALUE } from '../../constants/settings'

const { Option } = Select

const trOpt = { scope: 'modals.setCompanyFeatureFlagModal' }
const flagTypeTrOpt = { scope: `${trOpt.scope}.featureFlag.types` }

const EditModal = styled(Modal)`
  max-width: 800px;
  top: 50px;
`

const USERVICE_FEATURE_FLAGS = window.__USECURE_CONFIG__.REACT_APP_USERVICE_FEATURE_FLAGS
const FLAGS = _isString(USERVICE_FEATURE_FLAGS) && !_isEmpty(USERVICE_FEATURE_FLAGS) ? USERVICE_FEATURE_FLAGS.split(',') : []
const { flags, flagTypes } = FLAGS.reduce((acc, flagId) => {
  const id = window.__USECURE_CONFIG__[`REACT_APP_${flagId}_FLAG_ID`]
  const type = window.__USECURE_CONFIG__[`REACT_APP_${flagId}_FLAG_TYPE`]
  if (id && type) {
    acc.flags.push(id)
    acc.flagTypes[id] = type
  }

  return acc
}, { flags: [], flagTypes: {} })

const SetCompanyFeatureFlagModal = React.forwardRef(({ client, refetchQueries = [], onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [accountType, setAccountType] = useState(null)
  const [companyFlagSettings, setCompanyFlagSettings] = useState({})
  const [valueOptions, setValueOptions] = useState([])
  const [featureFlagId, setFeatureFlagId] = useState(null)
  const [featureFlagValue, setFeatureFlagValue] = useState(null)

  useImperativeHandle(ref, () => ({
    open: async (companyId) => {
      setVisible(true)
      setLoading(true)
      const result = await client.query({
        query: GET_COMPANY_FOR_UPDATE,
        variables: { id: companyId }
      })
      const company = _get(result, 'data.company')
      const { id, name, accountType } = company
      setCompanyId(id)
      setCompanyName(name)
      setAccountType(accountType)
      setFeatureFlagId(flags[0])
      setCompanyFlagSettings(flags.reduce((acc, id) => {
        let value = _get(company, ['settings', id])
        value = _isNil(value) ? SETTING_DELETE_VALUE : value
        if (_isBoolean(value)) {
          value = value ? 'true' : 'false'
        }
        acc[id] = value
        return acc
      }, {}))
      setLoading(false)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setCompanyId(null)
    setCompanyName(null)
    setFeatureFlagId(null)
    setFeatureFlagValue(null)
    setCompanyFlagSettings({})
    onClose()
  }

  const onFlagIdChange = value => setFeatureFlagId(value)
  const onFlagValueChange = value => setFeatureFlagValue(value)

  useEffect(() => {
    const flagType = flagTypes[featureFlagId] || 'simple'
    let valueOptions = []
    if (flagType === 'full' && ['distributor', 'msp'].includes(accountType)) {
      valueOptions = [{
        value: SETTING_DELETE_VALUE, label: I18n.t('default', flagTypeTrOpt)
      }, {
        value: 'true', label: I18n.t('enabledFull', flagTypeTrOpt)
      }, {
        value: 'all', label: I18n.t('all', flagTypeTrOpt)
      }, {
        value: 'false', label: I18n.t('disabled', flagTypeTrOpt)
      }]
      if (accountType === 'distributor') {
        valueOptions.splice(1, 0, {
          value: 'msp', label: I18n.t('msp', flagTypeTrOpt)
        })
      }
    }

    if (flagType === 'simple' || flagType === 'partnerCascade') {
      valueOptions = [{
        value: SETTING_DELETE_VALUE, label: I18n.t('default', flagTypeTrOpt)
      }, {
        value: 'true', label: I18n.t('enabled', flagTypeTrOpt)
      }, {
        value: 'false', label: I18n.t('disabled', flagTypeTrOpt)
      }]

      if (flagType === 'partnerCascade' && (accountType === 'msp' || accountType === 'distributor')) {
        valueOptions.splice(1, 0, {
          value: 'self', label: I18n.t('enabledFull', flagTypeTrOpt)
        })
      }
    }

    setValueOptions(valueOptions)
    let value = companyFlagSettings[featureFlagId] || SETTING_DELETE_VALUE
    if (
      (value === 'all' && !valueOptions.some(({ value: opt }) => opt === 'all')) ||
      (value === 'msp' && !valueOptions.some(({ value: opt }) => opt === 'msp')) ||
      (value === 'self' && !valueOptions.some(({ value: opt }) => opt === 'self'))
    ) {
      value = 'true'
    }
    setFeatureFlagValue(value)
  }, [featureFlagId, companyFlagSettings, accountType])

  const [updateFeatureFlag] = useMutation(UPDATE_USERVICE_COMPANY_SETTING)

  const handleSubmit = useCallback(async e => {
    e.preventDefault()

    setLoading(true)
    try {
      let setting
      if (featureFlagValue === 'true') {
        setting = true
      } else if (featureFlagValue === 'false') {
        setting = false
      } else {
        setting = featureFlagValue
      }

      await updateFeatureFlag({
        variables: { companyId, settingId: featureFlagId, setting },
        refetchQueries
      })
      message.success(I18n.t('successMessage', trOpt))
      closeModal()
    } catch (e) {
      showErrors(e, I18n.t('errorMessage', trOpt))
    }
    setLoading(false)
  }, [updateFeatureFlag, refetchQueries, closeModal, featureFlagId, featureFlagValue, companyId])

  let title = I18n.t('title', trOpt)
  if (companyName) {
    title = I18n.t('titleName', { ...trOpt, name: companyName })
  }

  return (
    <EditModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={title}
      afterClose={afterClose}
      width='70%'
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <Form onSubmit={handleSubmit}>
        <Form.Item label={I18n.t('featureFlag.label', trOpt)}>
          <Select value={featureFlagId} onChange={onFlagIdChange} disabled={flags.length === 0}>
            {
              flags.map(id => (<Option key={id} value={id}>{I18n.t(id, trOpt)}</Option>))
            }
          </Select>
        </Form.Item>
        <Form.Item label={I18n.t('flagValueLabel', trOpt)}>
          <Select value={featureFlagValue} onChange={onFlagValueChange} disabled={valueOptions.length === 0}>
            {
              valueOptions.map(({ value, label }) => <Option key={value} value={value}>{label}</Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {I18n.t('common.save')}
          </Button>
        </Form.Item>
      </Form>
    </EditModal>
  )
})

export const HAS_FEATURE_FLAGS = flags.length > 0

export default withConsumer({ useHooks: true })(SetCompanyFeatureFlagModal)
