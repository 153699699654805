import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { message } from 'antd'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { buildTree } from '../Learners/helpers'
import MutationForm from '../MutationForm/MutationForm'
import { CREATE_LEARNER, EDIT_LEARNER, GET_LEARNER, GET_LEARNERS } from '../Queries/Learners'
import { GET_GROUP_TREE } from '../Queries/Groups'
import { invalidateLearnersQueryCache, showErrors } from '../../helpers'
import { ErrorAlerts, LoadingBlock } from '../common'
import { DEFAULT_LANGUAGE, LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { getSettings } from '../../state/selectors/settings'
import { session } from '../../state/selectors'
import { connect } from '../../hocs'
import { OverflowModal, ModalFormScrollContainer } from './common'
import useGlobalState from '../../hooks/useGlobalState'

const trOpt = { scope: 'modals.editLearnerModal' }

const EditLearnerModal = React.forwardRef(({ refetchQueries, onClose = () => {} }, ref) => {
  const [visible, setVisible] = useState(false)
  const [learnerLoading, setLearnerLoading] = useState(false)
  const [learnerId, setLearnerId] = useState(null)
  const [learnerName, setLearnerName] = useState(null)
  const client = useApolloClient()
  const { enableLearnerAutoEnrolExclude = false, uLearn, companyLocale: companyDefaultLocale } = useGlobalState(
    state => ({
      ..._pick(session.get(state), ['companyLocale']),
      ..._pick(getSettings(state), ['uLearn', 'enableLearnerAutoEnrolExclude'])
    })
  )

  const { loading: groupsLoading, error: getGroupsError, data: getGroupsData } = useQuery(GET_GROUP_TREE, {
    skip: !visible
  })
  const { loading: managersLoading, error: getManagersError, data: getManagersData } = useQuery(GET_LEARNERS, {
    skip: !visible
  })

  const managersOptions = useMemo(() => {
    if (getManagersData) {
      const { learners } = getManagersData
      const managers = learnerId ? learners.filter(learner => learner.id !== learnerId) : learners
      return managers.map(learner => ({ value: learner.id, label: `${learner.name} (${learner.email || learner.learnerId})` })).sort((a, b) => a.label.localeCompare(b.label))
    } else {
      return []
    }
  }, [getManagersData, learnerId])

  const groupTreeOptions = useMemo(() => {
    const { groupTree = [] } = getGroupsData || {}
    return buildTree(groupTree)
  }, [getGroupsData])

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const setInitialFormValues = useCallback(async values => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async learnerId => {
      setVisible(true)
      if (learnerId) {
        setLearnerId(learnerId)
        setLearnerLoading(true)

        try {
          const result = await client.query({
            query: GET_LEARNER,
            variables: { id: learnerId }
          })
          const learner = _get(result, 'data.learner')
          if (learner) {
            await setInitialFormValues({
              firstName: learner.firstName,
              lastName: learner.lastName,
              learnerEmailToggle: learner.email ? 'email' : 'id',
              email: learner.email,
              learnerId: learner.learnerId,
              managerId: learner.managerId,
              locale: learner.locale,
              groupIds: learner.groups ? learner.groups.map(group => group.id) : [],
              excludedFromAutoEnrol: learner.excludedFromAutoEnrol
            })
            setLearnerName(learner.name)
            setLearnerLoading(false)
          }
        } catch (e) {
          setVisible(false)
          showErrors(e, I18n.t('userError', trOpt))
        }
      }
    }
  }), [client, setInitialFormValues])

  const closeModal = useCallback(() => {
    setVisible(false)
    setLearnerLoading(false)
    setLearnerId(null)
    setLearnerName(null)
    resetForm()
    onClose()
  }, [setVisible, resetForm, onClose])

  const fields = [{
    id: 'firstName',
    label: I18n.t('common.fields.firstName'),
    placeholder: I18n.t('common.fields.firstName'),
    required: true
  }, {
    id: 'lastName',
    label: I18n.t('common.fields.lastName'),
    placeholder: I18n.t('common.fields.lastName'),
    required: true
  }, {
    id: 'learnerEmailToggle',
    type: 'radio',
    label: I18n.t('learnerEmailToggle', trOpt),
    options: [
      { value: 'email', label: I18n.t('common.fields.email') },
      { value: 'id', label: I18n.t('userId', trOpt) }
    ],
    defaultValue: 'email'
  }, {
    id: 'email',
    type: 'email',
    label: I18n.t('common.fields.email'),
    placeholder: I18n.t('common.fields.email'),
    required: true,
    autofill: false,
    visible: (values) => values.learnerEmailToggle === 'email'
  }, {
    id: 'learnerId',
    label: I18n.t('userId', trOpt),
    type: 'text',
    placeholder: I18n.t('userId', trOpt),
    required: true,
    visible: (values) => values.learnerEmailToggle === 'id'
  }, {
    id: 'managerId',
    label: I18n.t('manager', trOpt),
    type: 'select',
    placeholder: I18n.t('manager', trOpt),
    options: managersOptions,
    allowClear: true
  }, {
    id: 'locale',
    label: I18n.t('common.preferredLanguage'),
    defaultValue: companyDefaultLocale || DEFAULT_LANGUAGE,
    type: 'select',
    options: LANGUAGE_SELECT_OPTIONS,
    sortOptions: true
  }, {
    id: 'groupIds',
    type: 'treeSelect',
    label: I18n.t('groups', trOpt),
    treeData: groupTreeOptions,
    multiple: true
  }]

  if (uLearn && enableLearnerAutoEnrolExclude) {
    fields.push({
      id: 'excludedFromAutoEnrol',
      type: 'switch',
      label: I18n.t('excludedFromAutoEnrol', trOpt)
    })
  }

  const onSuccess = useCallback(() => {
    message.success(I18n.t('common.updateUserSuccess'))
    closeModal()
  }, [closeModal])

  const mutateValues = (values) => {
    if (values.learnerEmailToggle === 'id') {
      return { ...values, email: null }
    }
    if (values.learnerEmailToggle === 'email') {
      return { ...values, learnerId: null }
    }
    return values
  }

  let title = I18n.t('common.addUser')
  if (learnerId && learnerName) {
    title = I18n.t('common.editUserName', { name: learnerName })
  } else if (learnerId) {
    title = I18n.t('common.editUser')
  }

  return (
    <OverflowModal
      visible={visible}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
    >
      <h2>{title}</h2>
      <LoadingBlock loading={managersLoading || groupsLoading || learnerLoading} fullScreen={false} />
      {getGroupsError && <ErrorAlerts error={getGroupsError} defaultError={I18n.t('common.groupsLoadError')} />}
      {getManagersError && <ErrorAlerts error={getManagersError} defaultError={I18n.t('managerLoadError', trOpt)} />}
      {!(getManagersError || getGroupsError) && (
        <MutationForm
          mutation={learnerId ? EDIT_LEARNER : CREATE_LEARNER}
          onSuccess={onSuccess}
          failureMessage={learnerId ? I18n.t('errorMessage', trOpt) : I18n.t('common.createUserError')}
          submitLabel={I18n.t('common.save')}
          fields={fields}
          ref={form}
          variables={{ id: learnerId }}
          mutateValues={mutateValues}
          refetchQueries={refetchQueries}
          update={invalidateLearnersQueryCache}
          scrollContainer={ModalFormScrollContainer}
        />
      )}
    </OverflowModal>
  )
})

export default connect(
  state => _pick(getSettings(state), ['uLearn', 'enableLearnerAutoEnrolExclude'])
)(EditLearnerModal)
