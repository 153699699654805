import React from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { Button } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { BannerMessage } from '../common'
import { BREAKPOINT } from '../../constants/style'
import routes from '../../constants/routes'

const trOpt = { scope: 'uBreach.uBreachProUpgradeAlert' }

const UpgradeUBreachProAlertMessage = styled(BannerMessage)`
  display: flex;
  justify-content: center;

  @media (max-width: ${BREAKPOINT}) {
    margin-top: 0.7rem;
  }

  .ant-alert-icon {
    line-height: 1;
    top: 8px;
  }
  .ant-btn {
    margin-left: 5px;
  }
`
const UpgradeUBreachProAlert = ({ selfService }) => {
  return (
    <UpgradeUBreachProAlertMessage
      type='info'
      message={
        <>
          <span>{I18n.tWithProductNames('title', trOpt)} - {I18n.t('description', trOpt)}</span>
          <Link to={generatePath(routes.UBREACH_UPGRADE)}>
            <Button>{I18n.t(selfService ? 'upgradeButton' : 'learnMoreButton', trOpt)}</Button>
          </Link>
        </>
      }
    />
  )
}

export default UpgradeUBreachProAlert
