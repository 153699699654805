import queryString from 'query-string'
import I18n from 'i18n-js'
import _isNumber from 'lodash/isNumber'
import _round from 'lodash/round'
import _isFunction from 'lodash/isFunction'

import routes from '../constants/routes'
import history from '../constants/history'
import { formatDate } from './datetime'

const trOpt = { scope: 'reports.common' }

export const getValueText = ({ value, isPercentage = false, noValueText = I18n.t('noDataAvailable', trOpt) }) => {
  if (!_isNumber(value)) return noValueText
  return isPercentage ? I18n.t('common.percentage', { value }) : value
}

export const getValueChange = ({ initial, final, isPercentage = false }) => {
  if (isPercentage) {
    return Math.abs(final - initial) // Because it is already a percentage- it makes sense just to calculate the difference between the two numbers
  }
  // this calculates the percentage increase between the initial and final value
  let percentageChange = (final - initial) / initial * 100
  if (!isFinite(percentageChange)) percentageChange = 100
  if (initial === 0 && final === 0) percentageChange = 0
  return percentageChange
}

/** Transfoms data received from performanceReport resolver into format used by BaseReport when creating TrendCards */
export const transformCardData = ({
  value = I18n.t('reports.noData'),
  previousValue = I18n.t('reports.noData'),
  trend: { direction = 'neutral', description = 'neutral' } = {},
  withIcon = true,
  withPrevious = true,
  isPercentage = false,
  isNeutral = false,
  tooltip,
  printScale = 1
}) => {
  const percentageChange = !_isNumber(value) || !_isNumber(previousValue) ? 0 : _round(getValueChange({
    initial: previousValue,
    final: value,
    isPercentage
  }), 1)

  return {
    percentageChange: percentageChange || '-',
    value: value,
    previousValue: previousValue,
    trend: {
      direction,
      description: isNeutral ? 'neutral' : description
    },
    withIcon,
    withPrevious,
    isPercentage,
    tooltip,
    printScale
  }
}

/** Transforms data received from performanceReport resolver into format used by BaseReport when creating area charts */
export const transformAreaChartData = ({ data, granularity, isPercentage = false }) => {
  if (!data) return { skip: true }

  const { id, values, trend: { description } } = data

  // Don't show a chart if there is no data for any of the dates
  if (!values.some(({ value }) => _isNumber(value))) return { skip: true }

  const axisFormatLeft = isPercentage ? v => I18n.t('common.percentage', { value: v }) : v => v

  return {
    data: [{
      id: id,
      data: values?.map(({ date, value }) => ({
        x: formatDate(date, { includeYear: granularity !== 'week', includeDay: granularity === 'week' }),
        y: _isNumber(value) ? value : 0
      }))
    }],
    trend: description,
    type: 'area',
    isPercentage,
    options: {
      axisFormat: { left: axisFormatLeft }
    }
  }
}

/** Transforms data received from performanceReport resolver into format used by BaseReport when creating line charts */
export const transformLineChartData = ({ data, granularity, isPercentage = false, trOpt }) => {
  if (!data) return { skip: true }

  const transformedData = data.map(({ id, values }) => {
    return {
      id: I18n.t(id, trOpt),
      data: values?.map(({ date, value }) => ({
        x: formatDate(date, { includeYear: granularity !== 'week', includeDay: granularity === 'week' }),
        y: _isNumber(value) ? value : 0
      }))
    }
  })
  return {
    data: transformedData,
    type: 'line',
    isPercentage
  }
}

/** Transforms data received from performanceReport resolver into format used by BaseReport when creating bar charts */
export const transformBarChartData = ({ data = [], keys = [], trOpt }) => {
  if (!data || data.length === 0) return { skip: true }

  return {
    data: data.map((item) => {
      const { id, label, values } = item

      const transformedValues = keys.reduce((acc, key) => {
        const { value } = values.find((val) => val.key === key) || {}
        return {
          ...acc,
          // Nivo only allows the key name to be used in the legend
          [I18n.t(key, trOpt)]: _isNumber(value) ? value : 0
        }
      }, {})

      return {
        id,
        label,
        ...transformedValues
      }
    }),
    options: {
      indexBy: 'label',
      // Set the new keys to match the I18n translations
      keys: keys.map(id => I18n.t(id, trOpt))
    },
    type: 'bar'
  }
}

/** Transforms data received from performanceReport resolver into format used by BaseReport when creating pie charts */
export const transformPieChartData = ({ data, trOpt, formatLabel }) => {
  if (!data) return { skip: true }
  // Don't show a chart if there is no data for any of the categories
  if (!data.some(({ value }) => _isNumber(value) && value > 0)) return { skip: true }

  const transformedData = data.map((item) => {
    const { id, value } = item

    return {
      id: I18n.t(id, { ...trOpt, value }),
      value: _isNumber(value) ? value : 0,
      label: _isFunction(formatLabel) ? formatLabel(item) : I18n.t(id, { ...trOpt, value })
    }
  })

  return {
    data: transformedData,
    type: 'pie'
  }
}

/** Transforms data received from performanceReport resolver into format used by BaseReport when creating tables */
export const transformTableChartData = ({ data, columns = [], sorter }) => {
  if (!(data?.length > 0)) return { skip: true }

  const transformedData = data.map((item) => {
    const { id, label, values } = item

    const transformedValues = values.reduce((acc, item) => {
      const { key, value } = item
      return {
        ...acc,
        [key]: _isNumber(value) ? value : 0
      }
    }, {})

    return {
      key: id,
      id,
      label,
      ...transformedValues
    }
  })
  if (sorter) {
    transformedData.sort(sorter)
  }
  return {
    data: transformedData,
    type: 'table',
    options: { columns }
  }
}

export const getTitleTextWithPeriod = ({ periodType, key, trOpt }) =>
  I18n.t(periodType, { scope: `${trOpt.scope}.${key}` })

export const openReport = ({ fromDate, toDate, granularity, companyId, sections, reportType } = {}, { openInNewTab = false } = {}) => {
  const reportRoutes = {
    performanceReport: routes.PERFORMANCE_REPORT,
    breachReport: routes.BREACH_REPORT
  }

  if (!reportRoutes[reportType]) throw new Error(I18n.t('reports.errorOccuredOpeningReport'))

  const query = { companyId, fromDate, toDate }
  if (granularity) query.granularity = granularity
  if (sections) query.sections = sections

  const reportURL = `${reportRoutes[reportType]}?${queryString.stringify(query, { skipNull: true, skipEmptyString: true, arrayFormat: 'comma' })}`
  if (openInNewTab) {
    window.open(reportURL, '_blank')
  } else {
    history.push(reportURL)
  }
}
