import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Icon, Alert } from 'antd'
import I18n from 'i18n-js'
import { connect } from '../../hocs'
import _get from 'lodash/get'
import { creators as viewActions } from '../../state/actions/view'
import { END_USER_LINK_REQUEST } from '../Queries/Learners'
import MutationForm from '../MutationForm/MutationForm'
import { showErrors } from '../../helpers'
import { captureSentryError } from '../../helpers/sentry'

const trOptCommon = { scope: 'endUserPortal.common' }

const PrimaryText = styled.h2`
  color: ${props => props.theme.primary} !important;
`
const MessageWrapper = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
`
const BigIcon = styled(Icon)`
  font-size: 40pt;
  margin-bottom: 0.5em;
  color: ${props => props.theme.primary} !important;
`

const MagicLinkForm = ({ setLoadingVisible }) => {
  const [showForm, setShowForm] = useState(true)

  const onSuccess = (queryResult) => {
    const linkRequestComplete = _get(queryResult, 'data.endUserLinkRequest')
    if (linkRequestComplete === true) {
      setShowForm(!linkRequestComplete)
    }
    setLoadingVisible(false)
  }
  const onSubmit = () => {
    setLoadingVisible(true)
  }
  const onFailure = useCallback(e => {
    captureSentryError(e, { msg: 'End User Portal - Magic Link Request - ERROR' })
    showErrors(e, I18n.t('invalidEmailError', trOptCommon))
    setLoadingVisible(false)
  }, [setLoadingVisible])

  return (
    <>
      {showForm ? (
        <>
          <MutationForm
            mutation={END_USER_LINK_REQUEST}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onFailure={onFailure}
            btnBlock
            submitLabel={I18n.t('common.login')}
            fields={[
              { id: 'email', placeholder: I18n.t('common.fields.email'), type: 'email', required: true }
            ]}
          />
          <br />
          <Alert
            description={I18n.t('signIn.magicLinkAlertDescription')}
            type='info'
            showIcon
          />
        </>
      ) : (
        <MessageWrapper>
          <BigIcon type='mail' />
          <PrimaryText>{I18n.t('signIn.magicLinkDoneMsg')}</PrimaryText>
        </MessageWrapper>
      )}
    </>
  )
}

export default connect(
  undefined,
  dispatch => ({
    setLoadingVisible: loading => dispatch(viewActions.loading(loading))
  })
)(MagicLinkForm)
