import React, { useCallback } from 'react'
import { withRouter, generatePath } from 'react-router-dom'
import { Modal, message } from 'antd'
import _get from 'lodash/get'
import I18n from 'i18n-js'

import { EditCourseForm } from '../components/EditCourse'
import routes from '../constants/routes'

const trOpt = { scope: 'createCourse' }

const confirm = Modal.confirm

const CreateCourse = ({ history }) => {
  const onSuccess = useCallback(result => {
    const courseId = _get(result, 'data.createCourse.id')
    confirm({
      title: I18n.t('wouldYouLikeToStartCreatingSlides', trOpt),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk () {
        if (courseId) {
          history.push(generatePath(routes.BUILDER_EDIT_SLIDES, { course_id: courseId }))
        } else {
          message.error(I18n.t('common.anErrorOccurred'))
          history.push(routes.BUILDER)
        }
      },
      onCancel () {
        history.push(routes.BUILDER)
      }
    })
  }, [history])
  return (
    <EditCourseForm {...{ onSuccess }} />
  )
}

export default withRouter(CreateCourse)
