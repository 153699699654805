/* global localStorage */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import styled, { css } from 'styled-components'
import { Collapse, Icon, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _max from 'lodash/max'
import _pick from 'lodash/pick'

import { connect } from '../../hocs'
import { getSession } from '../../state/selectors/session'
import { getSettings } from '../../state/selectors/settings'
import { ContentWrap, ErrorAlerts, LoadingBlock, PreviewTag } from '../../components/common'
import { Column, ConditionalFlexContainer as _ConditionalFlexContainer } from '../../components/EndUserPortal/GridFlexiLayout'
import { useWindowSize } from '../../hooks/useWindowSize'
import PerformanceReports from '../ReportCentre/PerformanceReports'
import ScheduledReports from '../ReportCentre/ScheduledReports'
import ExcelReports from '../ReportCentre/ExcelReports'
import UBreachProUsageReport from '../ReportCentre/UBreachProUsageReport'
import BreachReports from '../ReportCentre/BreachReports'
import { GET_COMPANIES_BY_ACCOUNT_TYPE } from '../../components/Queries/Companies'
import { REPORT_CENTRE_PREVIEW } from '../../constants/environment'
import IntercomHeader from '../../components/IntercomHeader'
import { permissions } from '../../constants/permissions'
import { useHasSessionPermission } from '../../hooks'

const trOpt = { scope: 'reports.reportCentre' }
const CUSTOMER_SELECT_WIDTH = 150
const CUSTOMER_NAME_FONT_LENGTH_TO_PX = 9.4

const { Panel } = Collapse
const { Option } = Select
const ConditionalFlexContainer = styled(_ConditionalFlexContainer)`
  flex-wrap: wrap;

  &> * {
    max-width: 100%;
  }
`
const ReportCentreHeader = styled.div`
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;

  label {
    margin-right: 5px;
  }

  h1 {
    margin-bottom: 0px;
  }
`
const MobileHeaderPanel = styled(Panel)`
  padding: 5px 0;

  h1 {
    margin-bottom: 0;
    position: relative;
    top: 3px;
  }
`
const ReportContainer = styled(Column)`
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  overflow-x: auto;
`
const reportCentreViews = {
  performanceReports: {
    get label () { return I18n.t('reportsList.performanceReport.title', trOpt) },
    component: PerformanceReports
  },
  scheduledReports: {
    get label () { return I18n.t('scheduledReports.title', trOpt) },
    component: ScheduledReports
  },
  excelReports: {
    get label () { return I18n.t('excelReports.title', trOpt) },
    component: ExcelReports
  },
  breachReports: {
    get label () { return I18n.t('reportsList.breachReport.title', trOpt) },
    component: BreachReports
  },
  uBreachProReport: {
    get label () { return I18n.tWithProductNames('uBreachProReport.title', trOpt) },
    component: UBreachProUsageReport
  }
}

const _ReportCentreViewItem = ({ className, id, label, setReportCentreView = () => {} }) => {
  const onClick = useCallback(() => setReportCentreView(id), [id, setReportCentreView])
  return (
    <div {...{ className, onClick }}>{label}</div>
  )
}
// This styling mirrors Ant Design's Sider component
const ReportCentreViewItem = styled(_ReportCentreViewItem)`
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin: 4px 0 8px 0;
  padding-left: 10px;
  position: relative;
  
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  
  ${({ active, theme }) => active ? css`
  color: ${({ theme }) => theme.primary};
  &:after {
    border-right: 3px solid ${theme.primary};
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }
  ` : ''}
`

const ReportCentreViewList = ({ isMobile, reportCentreView, setReportCentreView, settings, isOwnCompany, accountType, uBreachProEnabled }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const views = useMemo(() => {
    const views = _pick(reportCentreViews, ['performanceReports', 'scheduledReports', 'excelReports'])

    if (uBreachProEnabled === true && isOwnCompany && (accountType === 'msp' || accountType === 'distributor') && hasAllSessionPermissions([permissions.SETTINGS_UBREACH_READ, permissions.BILLING_READ])) views.uBreachProReport = reportCentreViews.uBreachProReport
    if (uBreachProEnabled === true && (isOwnCompany ? hasAllSessionPermissions([permissions.BREACH_REPORT]) : hasAllSessionPermissions([permissions.COMPANY_IMPERSONATE]))) views.breachReports = reportCentreViews.breachReports
    return views
  }, [isOwnCompany, accountType, uBreachProEnabled, hasAllSessionPermissions])

  // Ensure the currently selected reportCentreView is a valid view, if not set to the first view
  const viewKeys = Object.keys(views)
  if (!viewKeys.includes(reportCentreView)) setReportCentreView(viewKeys[0])

  return (
    <ReportContainer flex={isMobile ? '1' : '0 0 25%'}> {/* Column */}
      {Object.entries(views).map(([id, { label }]) => (
        <ReportCentreViewItem
          key={id}
          {...{ id, label, setReportCentreView }}
          active={reportCentreView === id}
        />
      ))}
    </ReportContainer>
  )
}

const CustomerSelect = styled(Select)`
  max-width: ${({ isMobile }) => isMobile ? '100%' : '350px'};
`
const CustomerErrorAlerts = styled(ErrorAlerts)`
  flex-basis: 100%;
  margin-top: 5px;

  .ant-alert:last-child {
    margin-bottom: 0px;
  }
`

const _CustomerSelector = ({
  className, isMobile, companyId, onChange, customerSelectWidth, customerOptions, companiesError, companiesLoading
}) => (
  <>
    <div {...{ className }}>
      <CustomerSelect
        value={companyId}
        onChange={onChange}
        optionFilterProp='label'
        style={{ width: isMobile ? '100%' : customerSelectWidth }}
        isMobile={isMobile}
      >
        {customerOptions.map(({ value, label }) => (
          <Option key={value} value={value}>{label}</Option>
        ))}
      </CustomerSelect>
    </div>
    <CustomerErrorAlerts
      error={companiesError}
      defaultError={I18n.t('yourAccountTypesCouldNotBeLoaded', { ...trOpt, accountType: I18n.t('common.accountTypesPlural.tenant') })}
    />
    <LoadingBlock loading={companiesLoading} />
  </>
)
const CustomerSelector = styled(_CustomerSelector)`
  margin-bottom: ${({ isMobile }) => isMobile ? '10px' : '0'};
`

const ReportCentre = ({ companyId: sessionCompanyId, accountType, planValid: sessionPlanValid, settings: sessionSettings, uBreachProEnabled: sessionUBreachProEnabled, userId, platformAccess }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const [reportCentreView, setReportCentreView] = useState(null)
  const [companyId, setCompanyId] = useState(sessionCompanyId)
  const [customerOptions, setCustomerOptions] = useState([])
  const [planValid, setPlanValid] = useState(sessionPlanValid)
  const [companyPlanValidMap, setCompanyPlanValidMap] = useState({})
  const [settings, setSettings] = useState(sessionSettings)
  const [companySettingsMap, setCompanySettingsMap] = useState({})
  const [uBreachProEnabled, setUBreachProEnabled] = useState(sessionUBreachProEnabled)
  const [companyUBreachProEnabledMap, setCompanyUBreachProEnabledMap] = useState({})
  const [customerSelectWidth, setCustomerSelectWidth] = useState(150)
  const { type: screenTypeClass } = useWindowSize()
  // md is included as the mobile is a better fit for that screen size
  const isMobile = ['xs', 'sm', 'md'].includes(screenTypeClass)

  const showCustomerSelector = accountType === 'msp' && hasAllSessionPermissions([permissions.COMPANY_IMPERSONATE])
  const { loading: companiesLoading, error: companiesError, data: { companies: companiesData } = {} } = useQuery(GET_COMPANIES_BY_ACCOUNT_TYPE, {
    skip: !showCustomerSelector,
    variables: {
      accountType: 'tenant',
      withPlanValid: true,
      withSettings: true,
      withUBreachProEnabled: true
    }
  })

  const content = useMemo(() => {
    if (!reportCentreView) return null
    let Content = reportCentreViews[reportCentreView].component || null
    if (Content) {
      Content = (
        <Content
          {...{
            companyId,
            accountType,
            planValid,
            settings,
            uBreachProEnabled
          }}
        />
      )
    }

    return Content
  }, [reportCentreView, companyId, accountType, planValid, settings, uBreachProEnabled])

  // Local storage persistence of reportCentreView and companyId (i.e. msp customer selected)
  const storageId = useMemo(() => `reportCentre|${sessionCompanyId}|${userId}`, [userId, sessionCompanyId])
  useEffect(() => {
    try {
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        const listFilter = JSON.parse(listFilterString)
        Object.keys(listFilter).forEach(key => {
          const value = listFilter[key]
          switch (key) {
            case 'reportCentreView':
              setReportCentreView(value)
              break
            case 'companyId':
              setCompanyId(value)
              break
            default:
              break
          }
        })
      } else {
        setReportCentreView('performanceReports')
      }
    } catch (e) {}
  }, [storageId])
  const updateLocalStorage = useCallback((data = {}) => {
    if (!storageId) {
      return
    }

    let viewFilter = {}
    try {
      const viewFilterString = localStorage.getItem(storageId)
      if (viewFilterString) {
        viewFilter = JSON.parse(viewFilterString)
      }
      viewFilter = { ...viewFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(viewFilter))
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      companyId,
      reportCentreView
    })
  }, [updateLocalStorage, companyId, reportCentreView])

  useEffect(() => {
    let customerOptions = []
    let companyPlanValidMap = {}
    let companySettingsMap = {}
    let companyUBreachProEnabledMap = {}
    let customerSelectWidth = CUSTOMER_SELECT_WIDTH
    if (showCustomerSelector) {
      if (platformAccess) customerOptions.push({ value: sessionCompanyId, label: I18n.t('yourNFRAccount', trOpt) })
      if (_isArray(companiesData) && !_isEmpty(companiesData)) {
        customerOptions = [
          ...customerOptions,
          ...companiesData.map(({ id: value, name: label }) => ({ value, label }))
            .sort((a, b) => a.label.localeCompare(b.label))
        ];

        ({ companyPlanValidMap, companySettingsMap, companyUBreachProEnabledMap } = companiesData.reduce((acc, company) => {
          acc.companyPlanValidMap[company.id] = _get(company, 'plan.valid') === true
          acc.companySettingsMap[company.id] = _get(company, 'settings', {})
          acc.companyUBreachProEnabledMap[company.id] = _get(company, 'uBreachProEnabled', false)
          return acc
        }, {
          companyPlanValidMap: {},
          companySettingsMap: {},
          companyUBreachProEnabledMap: {}
        }))
      }
      customerSelectWidth = _max([
        CUSTOMER_SELECT_WIDTH,
        ...customerOptions.map(opt => opt.label.length * CUSTOMER_NAME_FONT_LENGTH_TO_PX)
      ])
    }
    setCustomerOptions(customerOptions)
    setCompanyPlanValidMap(companyPlanValidMap)
    setCompanySettingsMap(companySettingsMap)
    setCompanyUBreachProEnabledMap(companyUBreachProEnabledMap)
    setCustomerSelectWidth(customerSelectWidth + 15)
  }, [showCustomerSelector, sessionCompanyId, platformAccess, companiesData])

  useEffect(() => {
    if (showCustomerSelector && companyId !== sessionCompanyId) {
      setPlanValid(companyPlanValidMap[companyId] === true)
      setSettings(companySettingsMap[companyId] || {})
      setUBreachProEnabled(companyUBreachProEnabledMap[companyId] === true)
    } else {
      setPlanValid(sessionPlanValid)
      setSettings(sessionSettings)
      setUBreachProEnabled(sessionUBreachProEnabled)
    }
  }, [showCustomerSelector, companyId, sessionCompanyId, sessionPlanValid, sessionSettings, companyPlanValidMap, companySettingsMap, companyUBreachProEnabledMap, sessionUBreachProEnabled])

  const isOwnCompany = companyId === sessionCompanyId
  const viewList = <ReportCentreViewList {...{ isMobile, reportCentreView, setReportCentreView, settings, isOwnCompany, showCustomerSelector, uBreachProEnabled, accountType }} />
  const customerSelector = showCustomerSelector ? (
    <CustomerSelector
      {...{ isMobile, companyId, customerSelectWidth, customerOptions, companiesError, companiesLoading }}
      onChange={companyId => setCompanyId(companyId)}
    />
  ) : null

  return (
    <ContentWrap>
      <ConditionalFlexContainer isRow={!isMobile}>
        {!isMobile && (
          <ReportCentreHeader>
            <IntercomHeader Size='h1' id='report-centre-header'>{I18n.t('title', trOpt)}{REPORT_CENTRE_PREVIEW && <PreviewTag header />}</IntercomHeader>
            {customerSelector}
          </ReportCentreHeader>
        )}
        {/* left side */}
        {isMobile ? (
          <Collapse bordered={false} expandIcon={() => <Icon type='menu' />} expandIconPosition='right'>
            <MobileHeaderPanel
              key='1'
              header={<IntercomHeader Size='h1' id='report-centre-header'>{I18n.t('title', trOpt)}{REPORT_CENTRE_PREVIEW && <PreviewTag header />}</IntercomHeader>}
            >
              {customerSelector}
              {viewList}
            </MobileHeaderPanel>
          </Collapse>
        ) : viewList}
        {/* right side */}
        <ReportContainer className='' flex='1'> {/* Column */}
          {content}
        </ReportContainer>
      </ConditionalFlexContainer>
    </ContentWrap>
  )
}
export default compose(
  connect(
    state => ({
      ..._pick(getSession(state), ['userId', 'companyId', 'accountType', 'planValid']),
      ..._pick(getSettings(state), ['platformAccess', 'uBreachProEnabled']),
      settings: state.settings
    })
  )
)(ReportCentre)
