import React from 'react'
import I18n from 'i18n-js'

import ItemTag from './ItemTag'

const RecommendedTag = ({ className, tooltipTitle }) => (
  <ItemTag
    {...{ className, tooltipTitle }}
    label={I18n.t('common.recommended')}
  />
)

export default RecommendedTag
