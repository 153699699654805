import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Plain from 'slate-plain-serializer'
import { message } from 'antd'
import I18n from 'i18n-js'
import _isFunction from 'lodash/isFunction'
import _isBoolean from 'lodash/isBoolean'
import _isPlainObject from 'lodash/isPlainObject'
import _isString from 'lodash/isString'
import _isNil from 'lodash/isNil'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isArray from 'lodash/isArray'
import _pick from 'lodash/pick'
import _isEqual from 'lodash/isEqual'

import MutationFormElement from './MutationFormElement'
import { showErrors } from '../../helpers'
import { withConsumer } from '../../hocs'

const trOpt = { scope: 'mutationForm.mutationForm' }

const FIELD_TYPE_DEFAULTS = {
  learnerAndGroupSelect: {
    learners: [],
    groups: []
  },
  checkbox: false,
  switch: false,
  number: null,
  select: ({ multiple }) => multiple ? [] : null,
  treeSelect: ({ multiple }) => multiple ? [] : null,
  dateRange: null,
  textTags: []
}

const getFieldDefault = field => {
  let fieldTypeDefault = FIELD_TYPE_DEFAULTS[field.type]
  fieldTypeDefault = !_isNil(fieldTypeDefault) && _isPlainObject(fieldTypeDefault) ? _cloneDeep(fieldTypeDefault) : fieldTypeDefault
  fieldTypeDefault = _isFunction(fieldTypeDefault) ? fieldTypeDefault(field) : fieldTypeDefault
  const defaultValue = [field.defaultValue, fieldTypeDefault, ''].find((value, index) => value !== undefined)
  // Force boolean value for binary field type
  if ((field.type === 'switch' || field.type === 'checkbox')) {
    return defaultValue === true
  }
  return defaultValue
}

class MutationForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeTab: props.defaultTab || null,
      values: {},
      errors: {},
      changed: {},
      loading: false
    }

    this.element = React.createRef()

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onFailure = this.onFailure.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setField = this.setField.bind(this)
    this.updateActiveTab = this.updateActiveTab.bind(this)
    this.hasChanged = this.hasChanged.bind(this)
  }

  componentDidMount () {
    this._isMounted = true

    if (this.props.focusOnMount) {
      this.focusOnFirstField()
    }
  }

  setState (updaterOrStateChange, callback) {
    if (this._isMounted) {
      super.setState(updaterOrStateChange, callback)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { fields = [] } = nextProps
    const values = fields.reduce((values, field) => {
      if (field.type === 'textWithSelect') {
        return {
          ...values,
          [field.input.id]: _has(prevState, ['values', field.input.id]) ? _get(prevState, ['values', field.input.id]) : getFieldDefault(field.input),
          [field.select.id]: _has(prevState, ['values', field.select.id]) ? _get(prevState, ['values', field.select.id]) : getFieldDefault(field.select)
        }
      }
      if (field.type === 'collapse') {
        for (const subField of field.fields) {
          values[subField.id] = _has(prevState, ['values', subField.id]) ? _get(prevState, ['values', subField.id]) : getFieldDefault(subField)
        }
        return values
      }
      if (field.id) {
        const stateHasValue = _has(prevState, ['values', field.id])
        let value = stateHasValue ? _get(prevState, ['values', field.id]) : getFieldDefault(field)
        // Preserve boolean value
        if (stateHasValue && ['switch', 'checkbox'].includes(field.type)) {
          value = prevState.values[field.id]
          value = typeof value === 'boolean' ? value : getFieldDefault(field)
        }
        return { ...values, [field.id]: value }
      }
      return values
    }, {})
    return {
      values
    }
  }

  async updateState (update) {
    return new Promise(resolve => this.setState(update, () => resolve()))
  }

  updateActiveTab (id) {
    this.setState({ activeTab: id })
    this.props.onTabChange(id)
  }

  // TODO Expand error messages generated and display them in each type
  getFieldErrors (field, value, opt) {
    const { values } = this.state
    value = typeof value === 'undefined' ? values[field.id] : value
    const errors = []

    this.getFieldRequiredError(field, value, errors)
    this.getFieldComponentErrors(field, value, errors, opt)
    this.getFieldValidateErrors(field, value, errors, opt)

    return errors
  }

  isFieldRequired (field) {
    const { required } = field
    return _isFunction(required) ? required(this.state.values) : (_isBoolean(required) ? required : false)
  }

  isFieldVisible (field) {
    const { visible } = field
    return _isFunction(visible) ? visible(this.state.values) : (_isBoolean(visible) ? visible : true)
  }

  isFieldValidationRequired (field) {
    const visible = this.isFieldVisible(field)
    const { validWhenHidden = true } = field
    return visible || !validWhenHidden
  }

  isFieldBlank (field, value) {
    value = typeof value === 'undefined' ? this.state.values[field.id] : value
    let populated = true
    if (field.type === 'emailBody') {
      populated = value && Plain.serialize(value)
    } else if (field.type === 'multiSelect' || field.type === 'textTags') {
      populated = value && Array.isArray(value) && value.length > 0
    } else if (field.type === 'learnerAndGroupSelect') {
      const { groups = [], learners = [] } = value || {}
      populated = groups.length > 0 || learners.length > 0
    } else if (field.type === 'number') {
      populated = !(value === null || value === undefined || value === '' || isNaN(value))
    } else if (field.type === 'unlayer') {
      populated = Boolean(value && value.design && value.html && value.encoded)
    } else {
      populated = value && value !== ''
    }

    return !populated
  }

  getFieldRequiredError (field, value, errors) {
    value = typeof value === 'undefined' ? this.state.values[field.id] : value
    if (this.isFieldRequired(field) && this.isFieldBlank(field)) {
      errors.push(field.requiredError || I18n.t('requiredFieldError', trOpt))
    }
  }

  getFieldComponentErrors (field, value, errors, opt) {
    if (this.element.current) {
      this.element.current.getFieldComponentErrors(field, value, errors, opt)
    }
  }

  getFieldValidateErrors (field, value, errors, opt) {
    const { validate } = field
    if (_isFunction(validate)) {
      validate(field, value, errors, opt, this.state.values)
    }
    return errors
  }

  getFieldErrorState (field, opt) {
    const { mutate, changedOnly } = opt || {}
    const requiresErrorCheck = !changedOnly || this.state.changed[field.id]
    let value = this.state.values[field.id]

    let errors = []
    if ((requiresErrorCheck || field.alwaysShowValidateErrors) && mutate && typeof field.mutateValue === 'function') {
      value = field.mutateValue(value, this.state.values)
    }
    if (requiresErrorCheck) {
      errors = this.getFieldErrors(field, value, opt)
    } else if (field.alwaysShowValidateErrors) {
      errors = this.getFieldValidateErrors(field, value, [], opt)
    }
    return errors
  }

  getErrors (opt) {
    return this.props.fields.reduce((errors, field) => {
      if (field.type === 'textWithSelect') {
        errors[field.input.id] = this.getFieldErrorState(field.input, opt)
        errors[field.select.id] = this.getFieldErrorState(field.select, opt)
        return errors
      }
      if (field.type === 'collapse') {
        for (const subField of field.fields) {
          errors[subField.id] = this.getFieldErrorState(subField, opt)
        }
        return errors
      }

      errors[field.id] = this.getFieldErrorState(field, opt)
      return errors
    }, {})
  }

  isFieldValid (field) {
    return this.isFieldValidationRequired(field) ? this.getFieldErrors(field).length === 0 : true
  }

  get valid () {
    return this.props.fields.every(field => {
      if (field.type === 'textWithSelect') {
        const required = this.isFieldRequired(field)
        return !this.isFieldValidationRequired(field) ||
        (
          this.isFieldValid({ ...field.input, required }) &&
          this.isFieldValid({ ...field.select, required })
        )
      }
      if (field.type === 'collapse') {
        return field.fields.every(f => this.isFieldValid(f))
      }

      return this.isFieldValid(field)
    })
  }

  updateValuesFromField (values, field, submitting) {
    let value = this.state.values[field.id]

    if (submitting) {
      if (_isFunction(field.mutateValue)) {
        value = field.mutateValue(value, this.state.values)
      }
      if (field.type === 'unlayer' && value) {
        value = value.encoded
      }
      if (field.spreadObjectValue) {
        return { ...values, ...value }
      }
    }

    return { ...values, [field.id]: value }
  }

  getValues (submitting = false) {
    return this.props.fields.reduce((values, field) => {
      if (field.type === 'textWithSelect') {
        values = this.updateValuesFromField(values, field.input, submitting)
        values = this.updateValuesFromField(values, field.select, submitting)
        return values
      }
      if (field.type === 'collapse') {
        for (const subField of field.fields) {
          values = this.updateValuesFromField(values, subField, submitting)
        }
        return values
      }

      return this.updateValuesFromField(values, field, submitting)
    }, {})
  }

  get values () {
    return this.getValues()
  }

  get variableValues () {
    return this.getValues(true)
  }

  get variables () {
    const { valuesObjectName: packName, mutateValues } = this.props
    let values = this.variableValues
    if (_isFunction(mutateValues)) {
      values = mutateValues(values)
    }
    // Pack values into another object so we can update things like company settings
    if (packName) {
      values = { [packName]: values }
    }

    return { ...values, ...this.props.variables }
  }

  get errors () {
    return this.state.errors
  }

  // TODO Replace with async version
  resetFields () {
    this.setState({
      values: this.getDefaultValues(),
      errors: {},
      changed: {}
    })
  }

  async resetFieldsAsync () {
    return this.updateState({
      values: this.getDefaultValues(),
      errors: {},
      changed: {}
    })
  }

  async reset () {
    await this.resetFieldsAsync()
    await this.resetFieldElements()
  }

  resetFieldElements () {
    this.callFunctionOnFieldElements('reset')
  }

  refreshFieldElements () {
    this.callFunctionOnFieldElements('refresh')
  }

  callFunctionOnFieldElements (funcName) {
    if (this.element.current) {
      this.element.current[funcName]()
    }
  }

  async updateLinkFields (name) {
    let linkValues = this.state.values[name] || []
    linkValues = _isArray(linkValues) ? linkValues : [linkValues]
    const linkedFields = this.props.fields.filter(field => field.linkField === name && ['select', 'multiSelect'].includes(field.type))
    for (const linkedField of linkedFields) {
      let values = this.state.values[linkedField.id] || []
      values = _isArray(values) ? values : [values]
      if (linkValues.length === 0) {
        return this.updateFieldValue(linkedField.id, linkedField.type === 'multiSelect' ? [] : '')
      } else {
        const validOptions = linkedField.options.filter(option => {
          const { linkFieldValue } = option
          if (_isArray(linkFieldValue)) {
            return linkValues.some(linkValue => linkFieldValue.includes(linkValue))
          }
          return linkValues.includes(linkFieldValue)
        }).map(option => option.value)
        values = values.filter(value => validOptions.includes(value))
        return this.updateFieldValue(linkedField.id, linkedField.type === 'multiSelect' ? values : (values[0] || ''))
      }
    }
  }

  getField (id) {
    let targetField
    this.props.fields.some(field => {
      if (field.type === 'textWithSelect') {
        if (field.input.id === id) {
          targetField = field.input
        } else if (field.select.id === id) {
          targetField = field.select
        }
      } else if (field.id === id) {
        targetField = field
      } else if (field.type === 'collapse') {
        targetField = field.fields.find(f => f.id === id)
      }

      return targetField
    })
    return targetField
  }

  getFieldValue (id) {
    return _get(this.state, `values[${id}]`)
  }

  getDefaultValues () {
    return this.props.fields.reduce((values, field) => {
      if (field.type === 'textWithSelect' && field.input?.id && field.select?.id) {
        return {
          ...values,
          [field.input.id]: getFieldDefault(field.input),
          [field.select.id]: getFieldDefault(field.select)
        }
      }
      if (field.type === 'collapse') {
        for (const subField of field.fields) {
          values[subField.id] = getFieldDefault(subField)
        }
        return values
      }
      if (field.id) {
        return { ...values, [field.id]: getFieldDefault(field) }
      }
      return values
    }, {})
  }

  async setInitialValues (values) {
    await this.updateState({
      errors: {},
      changed: {}
    })
    await this.replaceValues({
      ...this.getDefaultValues(),
      ...values
    }, true)
  }

  async replaceValues (values, skipChanged) {
    for (const [name, value] of Object.entries(values)) {
      await this.updateFieldValue(name, value, skipChanged)
    }
    await this.resetFieldElements()
  }

  async setField (name, value) {
    await this.onChange(name, value)
  }

  async onChange (name, value) {
    if (this.getField(name)) {
      await this.updateFieldValue(name, value)
      await this.updateLinkFields(name)
      return this.updateFormErrors({ changedOnly: true })
    }
  }

  async handleChange (name, value) {
    return this.onChange(name, value)
  }

  hasChanged ({ includeAllFields = false } = {}) {
    const { hasChangedExcludedFieldIds } = this.props
    return Object.entries(this.state.changed)
      .some(([fieldId, value]) => value === true && (includeAllFields || !hasChangedExcludedFieldIds.includes(fieldId)))
  }

  async updateFieldValue (name, value, skipChanged = false) {
    const field = this.getField(name)
    if (field) {
      const currentValue = this.state.values[name]
      const stateUpdate = {
        values: {
          ...this.state.values,
          [name]: value
        },
        errors: {
          ...this.state.errors,
          [name]: []
        }
      }

      if (skipChanged !== true) {
        stateUpdate.changed = {
          ...this.state.changed,
          [name]: !_isEqual(currentValue, value)
        }
      }
      await this.updateState(stateUpdate)
      if (skipChanged !== true) {
        this.props.onChange(name, value)
      }
    }
  }

  async updateFormErrors (opt) {
    return this.updateState({ errors: this.getErrors(opt) })
  }

  focusOnFirstField () {
    const firstField = this.props.fields[0]
    let firstFieldId
    if (firstField?.type === 'textWithSelect') {
      firstFieldId = firstField.input.id
    } else if (firstField) {
      firstFieldId = firstField.id
    }

    if (firstFieldId) {
      this.focusOnField(firstFieldId)
    }
  }

  focusOnField (fieldId) {
    if (this.element.current) {
      this.element.current.focusOnField(fieldId)
    }
  }

  async onSubmit (event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    const { isValidBeforeSubmit, onSubmit, onSuccess, skipResetFieldsOnSubmit = false, invalidMessage, mutation } = this.props

    if (!(this.valid && isValidBeforeSubmit(this))) {
      if (invalidMessage) message.error(invalidMessage)
      this.setState({ errors: this.getErrors({ submitting: true }) })
      return
    }

    try {
      const { values, variables } = this
      await onSubmit(values, this.state.errors, variables)
      this.setState({ loading: true })
      let result
      if (mutation) {
        result = await this.props.client.mutate({
          mutation,
          variables,
          ..._pick(this.props, ['refetchQueries', 'update'])
        })
      }
      onSuccess(result)
      if (!skipResetFieldsOnSubmit) {
        await this.resetFieldsAsync()
      }
    } catch (e) {
      this.onFailure(e)
    }
    this.setState({ loading: false })
  }

  async handleSubmit (event, mutate) {
    return this.onSubmit(event, mutate)
  }

  onFailure (e) {
    if (this.props.silentFailure) return
    const { onFailure, failureMessage } = this.props
    if (_isFunction(onFailure)) {
      onFailure(e)
    } else {
      showErrors(e, _isString(onFailure) ? onFailure : failureMessage)
    }
  }

  render () {
    const { style, className } = this.props
    const { loading, activeTab } = this.state
    return (
      <div {...{ style, className }}>
        <MutationFormElement
          ref={this.element}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          updateActiveTab={this.updateActiveTab}
          {..._pick(this.props, [
            'fields', 'tabs', 'forceTabRender',
            'submitLabel', 'submitIcon',
            'footer', 'footerAlign', 'disableSubmitIfInvalid', 'disableSubmitOnEnter', 'footerProps', 'btnBlock', 'disableSubmit', 'scrollContainer', 'disabled',
            'readOnly'
          ])}
          {...{ loading, values: this.values, valid: this.valid, errors: this.errors, activeTab }}
          changed={this.state.changed}
        />
      </div>
    )
  }
}

MutationForm.propTypes = {
  disableSubmitIfInvalid: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object),
  tabs: PropTypes.arrayOf(PropTypes.object),
  isValidBeforeSubmit: PropTypes.func,
  mutation: PropTypes.object,
  onChange: PropTypes.func,
  onFailure: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  failureMessage: PropTypes.string,
  invalidMessage: PropTypes.string,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  submitLabel: PropTypes.string,
  variables: PropTypes.object,
  valuesObjectName: PropTypes.string,
  disableSubmitOnEnter: PropTypes.bool,
  btnBlock: PropTypes.bool,
  onTabChange: PropTypes.func,
  hasChangedExcludedFieldIds: PropTypes.arrayOf(PropTypes.string),
  scrollContainer: PropTypes.elementType,
  focusOnMount: PropTypes.bool,
  silentFailure: PropTypes.bool,
  skipResetFieldsOnSubmit: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
}

MutationForm.defaultProps = {
  disableSubmitIfInvalid: true,
  fields: [],
  tabs: [],
  isValidBeforeSubmit: () => true,
  onChange: () => {},
  onSubmit: () => {},
  onSuccess: () => {},
  get failureMessage () { return I18n.t('common.anErrorOccurred') },
  get invalidMessage () { return I18n.t('invalidMessage', trOpt) },
  get submitLabel () { return I18n.t('common.submit') },
  variables: {},
  valuesObjectName: null,
  disableSubmitOnEnter: false,
  onTabChange: () => {},
  btnBlock: false,
  hasChangedExcludedFieldIds: [],
  scrollContainer: null,
  focusOnMount: false,
  silentFailure: false,
  skipResetFieldsOnSubmit: false,
  disabled: false,
  readOnly: false
}

export default withConsumer(MutationForm)
