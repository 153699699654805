class UsecureError extends Error {
  constructor (message, { level, errorCode, data } = {}, ...args) {
    super(message, ...args)

    this.errorCode = errorCode || 'usecure_client_error'
    this.level = level
    this.isUsecure = true
    this.isUsecureClient = true
    this.data = data
  }
}

export default UsecureError
