import { useCallback } from 'react'
import { useStateValue } from '../state'

/**
 * Hook to check if the current session has a particular permission. This is a wrapper around the hasPermission helper function, and will automatically use the permissions from the current session.
 * Returns an object with two functions:
 * - hasSessionPermission: checks against session.permissions
 * - hasSessionPermissionOriginally: checks against session.originalPermissions (this is set if a user is impersonating another user)
 * @returns {Object} hasSessionPermission, hasSessionPermissionOriginally
 */
import { hasAllPermissions, hasAnyPermission } from '../helpers/hasPermission'

const useHasSessionPermission = () => {
  const [{ session: { permissions: availablePermisisons = [], originalPermissions = null } = {} }] = useStateValue()

  const hasAnySessionPermission = useCallback((requiredPermissions = []) => hasAnyPermission({ availablePermisisons, requiredPermissions }), [availablePermisisons])
  const hasAllSessionPermissions = useCallback((requiredPermissions = []) => hasAllPermissions({ availablePermisisons, requiredPermissions }), [availablePermisisons])

  const hasAnySessionPermissionOriginally = useCallback((requiredPermissions = []) => originalPermissions !== null ? hasAnyPermission({ availablePermisisons: originalPermissions, requiredPermissions }) : false, [originalPermissions])
  const hasAllSessionPermissionsOriginally = useCallback((requiredPermissions = []) => originalPermissions !== null ? hasAllPermissions({ availablePermisisons: originalPermissions, requiredPermissions }) : false, [originalPermissions])

  return {
    hasAnySessionPermission,
    hasAllSessionPermissions,
    hasAnySessionPermissionOriginally,
    hasAllSessionPermissionsOriginally
  }
}

export default useHasSessionPermission
