import LearnerCourseAnnotatedImage from './LearnerCourseAnnotatedImage'
import LearnerCourseCards from './LearnerCourseCards'
import LearnerCourseCarousel from './LearnerCourseCarousel'
import LearnerCourseMaterial from './LearnerCourseMaterial'
import LearnerCourseChecklist from './LearnerCourseChecklist'
import LearnerCourseMultipleChoice from './LearnerCourseMultipleChoice'
import LearnerCourseVimeo from './LearnerCourseVimeo'

export { default as LearnerCourseDisabled } from './LearnerCourseDisabled'
export { default as LearnerCourseError } from './LearnerCourseError'
export { default as LearnerCourseFinished } from './LearnerCourseFinished'
export { default as LearnerCourseHolding } from './LearnerCourseHolding'
export { default as LearnerCourseIntro } from './LearnerCourseIntro'
export { default as LearnerCourseRetry } from './LearnerCourseRetry'
export { default as LearnerCourseSlate } from './LearnerCourseSlate'

export {
  LearnerCourseAnnotatedImage,
  LearnerCourseCards,
  LearnerCourseCarousel,
  LearnerCourseMaterial,
  LearnerCourseChecklist,
  LearnerCourseMultipleChoice
}
export const CONTENT_TYPE_COMPONENTS = {
  material: LearnerCourseMaterial,
  multipleChoice: LearnerCourseMultipleChoice,
  annotatedImage: LearnerCourseAnnotatedImage,
  cards: LearnerCourseCards,
  carousel: LearnerCourseCarousel,
  vimeo: LearnerCourseVimeo,
  checklist: LearnerCourseChecklist
}
