import React, { useCallback } from 'react'
import { Button, Input } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

const ColumnSearchContainer = styled.div`
  max-width: 300px;
  min-width: 190px;
  padding: 8px;
`

const ColumnSearchInput = styled(Input)`
  display: block;
  margin-bottom: 8px;
`

const ColumnSearchButtons = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-btn {
    width: calc(50% - 4px);
    &> span, &> i {
      position: relative;
      top: 2px;
    }
  }
`

const SearchFilterDropdown = React.forwardRef(({ title, setSelectedKeys, selectedKeys, confirm, clearFilters }, ref) => {
  const handleChange = useCallback(e => {
    setSelectedKeys(e.target.value ? [e.target.value] : [])
  }, [setSelectedKeys])
  const handleSearch = useCallback(() => confirm(), [confirm])
  const handleReset = useCallback(() => clearFilters(), [clearFilters])

  return (
    <ColumnSearchContainer>
      <ColumnSearchInput
        ref={ref}
        placeholder={`${I18n.t('common.search')} ${title}`}
        value={selectedKeys ? selectedKeys[0] : null}
        onChange={handleChange}
        onPressEnter={handleSearch}
      />
      <ColumnSearchButtons>
        <Button
          type='primary'
          size='small'
          icon='search'
          onClick={handleSearch}
        >
          {I18n.t('common.search')}
        </Button>
        <Button
          type='primary'
          ghost
          size='small'
          icon='undo'
          onClick={handleReset}
        >
          {I18n.t('common.reset')}
        </Button>
      </ColumnSearchButtons>
    </ColumnSearchContainer>
  )
})

export default SearchFilterDropdown
