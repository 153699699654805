import React from 'react'
import styled from 'styled-components'
import { Button, Icon } from 'antd'
import I18n from 'i18n-js'

import { IntroDetail, IntroWrap } from './LearnerCourseIntro'

const trOpt = { scope: 'learnerCourse.learnerCourseDisabled' }

const Container = styled.div`
  text-align: center;
`

// TODO Get new urls for the hubpsot iframe
const LearnerCourseDisabled = () => {
  return (
    <IntroWrap>
      <IntroDetail>
        <Container>
          <h1>{I18n.t('thisFeatureIsCurrentlyDisabled', trOpt)} <Icon type='frown' /></h1>
          <p>{I18n.t('unfortunatelyTheAbilityToTakeCourses', trOpt)}</p>
          <p>{I18n.t('ifYouWouldLikeToDiscussThisMatter', trOpt)}</p>
          <Button type='primary' icon='message' size='large'>{I18n.t('chatWithUsNow', trOpt)}</Button>
        </Container>
      </IntroDetail>
    </IntroWrap>
  )
}

export default LearnerCourseDisabled
