import React, { useCallback } from 'react'
import { Button, Modal, Icon } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

const trOpt = { scope: 'editCourse.saveConfirmModal' }

const SaveConfirmModalWrap = styled.div`
  overflow: auto;
`

export const SaveConfirmModalTitle = styled.span`
  color: rgba(0,0,0,0.85);
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  overflow: hidden;
`

export const SaveConfirmModalContent = styled.div`
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  margin-left: 38px;
  margin-top: 8px;
`

export const SaveConfirmModalIcon = styled(Icon)`
  color: #faad14;
  float: left;
  font-size: 22px;
  margin-right: 16px;
`

const SaveConfirmModalFooter = styled.div`
  float: right;
  margin-top: 24px;right 

  & > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const SaveConfirmModal = ({ item, visible, onCancel, onYes, onNo }) => {
  const onYesClick = useCallback(() => onYes(), [onYes])
  const onNoClick = useCallback(() => onNo(), [onNo])
  const onCancelClick = useCallback(() => onCancel(), [onCancel])

  const title = item ? I18n.t('wouldYouLikeToSaveBeforeLeaving', trOpt) : I18n.t('wouldYouLikeToSaveThisItemBeforeLeaving', { ...trOpt, item })

  return (
    <Modal {...{ title: null, visible, onCancel }} footer={null} closable={false} maskClosable={false}>
      <SaveConfirmModalWrap>
        <SaveConfirmModalIcon type='question-circle' />
        <SaveConfirmModalTitle>{title}</SaveConfirmModalTitle>
        <SaveConfirmModalContent>{I18n.t('ifYouDontSaveYourChangesWillBeLost', trOpt)}</SaveConfirmModalContent>
        <SaveConfirmModalFooter>
          <Button key='cancel' onClick={onCancelClick}>{I18n.t('common.cancel')}</Button>
          <Button key='no' onClick={onNoClick}>{I18n.t('common.no')}</Button>
          <Button key='yes' onClick={onYesClick}>{I18n.t('common.yes')}</Button>
        </SaveConfirmModalFooter>
      </SaveConfirmModalWrap>
    </Modal>
  )
}

export default SaveConfirmModal
