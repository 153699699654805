import React from 'react'
import { Card, Descriptions } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { ListHeader, ListHeaderPanel } from '../common'

const DetailContainer = styled.div`
  padding-bottom: 10px;

  .ant-descriptions-item {
    vertical-align: top;

    &> span {
      display: block;
    }
  }

  .hide-label-item {
    &> .ant-descriptions-item-colon::after {
      content: none;
    }
  }
`

const DetailDescriptions = styled(Descriptions)`
  .ant-descriptions-row:nth-child(odd) > td {
    padding-bottom: 0;
  }
`

const ViewEmailTemplateAttachmentDetails = ({ template: { attachmentFile, attachmentName } }) => {
  const hasAttachment = typeof attachmentFile === 'string'

  return (
    <DetailContainer>
      <DetailDescriptions column={1} layout='vertical'>
        <Descriptions.Item label={I18n.t('uPhish.common.includeAttachment')}>
          {I18n.t(`common.${hasAttachment ? 'yes' : 'no'}`)}
        </Descriptions.Item>

        {hasAttachment && (
          <>
            <Descriptions.Item label={I18n.t('uPhish.common.attachmentName')}>{attachmentName}</Descriptions.Item>

            <Descriptions.Item className='hide-label-item'>
              <ListHeader>
                <ListHeaderPanel>
                  <h3>{I18n.t('uPhish.common.attachmentFile')}</h3>
                </ListHeaderPanel>
              </ListHeader>
              <Card>
                <iframe
                  style={{
                    margin: '10px 0 0 0',
                    border: '1px solid rgb(217, 217, 217)'
                  }}
                  width={900}
                  height={600}
                  frameBorder={0}
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${attachmentFile}`}
                  title={I18n.t('uPhish.common.documentPreview')}
                />
              </Card>
            </Descriptions.Item>
          </>
        )}
      </DetailDescriptions>
    </DetailContainer>
  )
}

export default ViewEmailTemplateAttachmentDetails
