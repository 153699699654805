import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Button } from 'antd'
import styled from 'styled-components'
import ms from 'ms'
import _get from 'lodash/get'
import _isString from 'lodash/isString'

import { EXTEND_COURSE_PREVIEW, EXTEND_GAP_ANALYSIS_PREVIEW, RESET_COURSE_PREVIEW, RESET_GAP_ANALYSIS_PREVIEW } from '../Queries/LearnerCourses'
import I18n from 'i18n-js'
import { modalConfirmAsync, modalWarningAsync, showErrors } from '../../helpers'
import { BannerMessage } from '../common'
import { BREAKPOINT } from '../../constants/style'

const trOpt = { scope: 'learnerCourse.previewBanner' }
const POLL_INTERVAL = ms(_isString(window.__USECURE_CONFIG__.REACT_APP_COURSE_PREVIEW_POLL_INTERVAL) ? window.__USECURE_CONFIG__.REACT_APP_COURSE_PREVIEW_POLL_INTERVAL : '30s')

const PreviewBannerMessage = styled(BannerMessage)`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  z-index: 1000;

  @media (max-width: ${BREAKPOINT}) {
    margin-top: 0.7rem;
  }

  .ant-alert-icon {
    line-height: 1;
    top: 8px;
  }
  .ant-btn {
    margin-left: 5px;
  }
`

const CoursePreviewBannerAndKeepAlive = ({ isGapAnalysis, courseId }) => {
  const [failed, setFailed] = useState(false)
  const [failCount, setFailCount] = useState(0)

  const [extendCoursePreview] = useMutation(EXTEND_COURSE_PREVIEW)
  const [extendGapAnalysisPreview] = useMutation(EXTEND_GAP_ANALYSIS_PREVIEW)
  const [resetCoursePreview] = useMutation(RESET_COURSE_PREVIEW)
  const [resetGapAnalysisPreview] = useMutation(RESET_GAP_ANALYSIS_PREVIEW)

  const resetPreview = useCallback(async () => {
    try {
      if (isGapAnalysis) {
        await resetGapAnalysisPreview()
      } else if (courseId) {
        await resetCoursePreview({ variables: { courseId } })
      }
      window.location.reload()
    } catch (e) {
      showErrors(e)
    }
  }, [courseId, isGapAnalysis, resetCoursePreview, resetGapAnalysisPreview])
  const modalConfirmResetPreview = useCallback(async () => {
    const confirmed = await modalConfirmAsync()
    if (confirmed) {
      await resetPreview()
    }
  }, [resetPreview])
  const modalWarningResetPreview = useCallback(async () => {
    setFailed(true)
    await modalWarningAsync({
      title: I18n.t('extendErrorMessageTitle', trOpt),
      content: I18n.t('extendErrorMessageContent', trOpt)
    })
    await resetPreview()
  }, [resetPreview])

  const extendPreview = useCallback(async () => {
    if (failed) return
    try {
      let success = false
      if (isGapAnalysis) {
        const result = await extendGapAnalysisPreview()
        success = _get(result, 'data.extendPreviewGapAnalysisResult') === true
      } else if (courseId) {
        const result = await extendCoursePreview({ variables: { courseId } })
        success = _get(result, 'data.extendPreviewCourseResult') === true
      }
      if (!success) {
        await modalWarningResetPreview()
      }
    } catch (e) {
      // Fail silently on single mutation
      console.error('CoursePreviewBannerAndKeepAlive.extendPreview', e)
      setFailCount(prevCount => prevCount + 1)
    }
  }, [failed, courseId, isGapAnalysis, extendCoursePreview, extendGapAnalysisPreview, modalWarningResetPreview])

  // Poll extend mutation to keep preview result cache entry alive
  useEffect(() => {
    const intv = setInterval(extendPreview, POLL_INTERVAL)
    return () => {
      clearInterval(intv)
    }
  }, [extendPreview])

  // Show error modal after 3 mutation failures or if it the mutation returns false
  useEffect(() => {
    if (failCount >= 3 && !failed) {
      modalWarningResetPreview()
    }
  }, [failCount, failed, modalWarningResetPreview])

  return (
    <PreviewBannerMessage
      type='info'
      message={(
        <>
          <span>{I18n.t('message', trOpt)}</span>
          <Button onClick={modalConfirmResetPreview}>{I18n.t('resetCourse', trOpt)}</Button>
        </>
      )}
    />
  )
}

export default CoursePreviewBannerAndKeepAlive
