import React from 'react'
import _isFunction from 'lodash/isFunction'
import _memoize from 'lodash/memoize'

import { useStateValue } from '../state'

// This is a React Context/Hooks API based implementation of React Redux's Connect API

const connect = (_mapStateToProps, _mapDispatchToProps) => Component => {
  const mapStateToProps = _isFunction(_mapStateToProps) ? _memoize(_mapStateToProps) : undefined
  const mapDispatchToProps = _isFunction(_mapDispatchToProps) ? _memoize(_mapDispatchToProps) : undefined
  const Connect = ({ forwardRef, ...props }) => {
    const [state, dispatch] = useStateValue()
    const stateProps = mapStateToProps ? mapStateToProps(state, props) : {}
    const dispatchProps = mapDispatchToProps ? mapDispatchToProps(dispatch, props) : {}

    return (
      <Component ref={forwardRef} {...props} {...stateProps} {...dispatchProps} />
    )
  }

  return React.forwardRef((props, ref) => (
    <Connect {...props} forwardRef={ref} />
  ))
}

export default connect
