import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'

/*
 * Expects groupTree
 * Returns object for antd tree
 */
export const buildTree = (data, disabledId = null, disableChildGroups = false, isGroupDisabled) => data.map((item) => {
  let disabled = false
  const { id, name, learnerCount, children } = item
  if (_isFunction(isGroupDisabled)) {
    disabled = Boolean(isGroupDisabled(item))
  } else if (item.id === disabledId || disableChildGroups) {
    disabled = true
  }

  if (_isArray(children)) {
    return (
      {
        title: name,
        key: id,
        value: id,
        // children: buildTree(children, null, disabled, isGroupDisabled),
        children: buildTree(children, disabledId, disabled, isGroupDisabled),
        learnerCount,
        disabled
      }
    )
  }
  return {
    title: item.name, key: item.id, value: item.id, disabled: disabled
  }
})
