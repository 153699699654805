import sessionReducer, { initialState as initialSession } from '../reducers/session'
import settingsReducer, { initialState as initialSettings } from '../reducers/settings'
import viewReducer, { initialState as initialView } from '../reducers/view'

export const initialState = {
  session: initialSession,
  settings: initialSettings,
  view: initialView
}

export default ({ queryHistory, session, settings, view }, action) => {
  // console.log('ACTION', action)
  return {
    session: sessionReducer(session, action),
    settings: settingsReducer(settings, action),
    view: viewReducer(view, action)
  }
}
