import React, { useMemo } from 'react'
import { Tabs, Row } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import UserTable from './uLearnUserTable'
import CourseUserTable from './uLearnCourseUserTable'
import CourseTable from './uLearnCourseTable'
import useTheme from '../../../hooks/useTheme'
import DownloadCertificateBtn from '../../../components/common/DownloadCertificateBtn'
import ExportServiceDownloadButton from '../../../components/common/ExportServiceDownloadButton'
import { sanitiseString } from '../../../helpers/sanitise'
import { permissions } from '../../../constants/permissions'
import { useHasSessionPermission } from '../../../hooks'

const trOpt = { scope: 'reports.uLearnReport' }

const { TabPane } = Tabs

const PaddedBottomRow = styled(Row)`
 margin-bottom: 15px;
`
const ULearnTable = ({ learnerTableData, courseTableData, learnerId, courseId, learnerName, courseName, isGapAnalysis, excludeInactiveUsersInReports }) => {
  const theme = useTheme()
  const jobData = useMemo(() => {
    return {
      reportType: 'uLearnReportExport',
      learnerId,
      courseId,
      isGapAnalysis,
      excludeInactiveUsers: excludeInactiveUsersInReports === true
    }
  }, [learnerId, courseId, isGapAnalysis, excludeInactiveUsersInReports])

  const { hasAllSessionPermissions } = useHasSessionPermission()

  if (!learnerId && !courseId && !isGapAnalysis) {
    const exportButton = <ExportServiceDownloadButton fileName='courses-report.xlsx' fileType='xlsx' {...{ jobData }} />
    return (
      <>
        <Tabs defaultActiveKey='1' type='card' tabBarExtraContent={exportButton}>
          <TabPane tab={I18n.t('users', trOpt)} key='1'>
            <UserTable learnerTableData={learnerTableData} />
          </TabPane>
          <TabPane tab={I18n.t('courses', trOpt)} key='2'>
            <CourseTable courseTableData={courseTableData} />
          </TabPane>
        </Tabs>
      </>
    )
  }
  if (courseId || isGapAnalysis) {
    const exportFilename = courseName ? `${sanitiseString(courseName, '-')}.xlsx` : 'course-report.xlsx'
    return (
      <>
        <PaddedBottomRow type='flex' justify='space-between'>
          <h4 style={{ marginBottom: '15px' }}>{I18n.t('learners', trOpt)}</h4>
          {<ExportServiceDownloadButton fileName={exportFilename} fileType='xlsx' {...{ jobData }} />}
        </PaddedBottomRow>
        <CourseUserTable {...{ learnerTableData, isGapAnalysis }} />
      </>
    )
  }
  if (learnerId) {
    const exportFilename = learnerName ? `${sanitiseString(learnerName, '-')}-courses.xlsx` : 'learner-report.xlsx'
    const completedCourses = courseTableData ? courseTableData.reduce((result, current) => {
      if (current.finishDate != null && current.startDate !== null && !current.isGapAnalysis) {
        result.push({
          name: current.courseName,
          score: current.score,
          finishDate: current.finishDate,
          learnerName: learnerName
        })
      }
      return result
    }, []) : []
    return (
      <>
        <PaddedBottomRow type='flex' justify='space-between'>
          <h4>{I18n.t('courses', trOpt)}</h4>
          <div style={{ gap: '10px', display: 'flex' }}>
            {<ExportServiceDownloadButton fileName={exportFilename} fileType='xlsx' {...{ jobData }} />}
            {hasAllSessionPermissions([permissions.COURSE_DOWNLOAD_CERTIFICATE]) && !_isEmpty(completedCourses) && <DownloadCertificateBtn btnTextKey='downloadAllCertificates' logoSrc={theme.appThemeLogo} ribbonColour={theme.primary} courses={completedCourses} type='primary' />}
          </div>
        </PaddedBottomRow>
        <CourseTable courseTableData={courseTableData} hasLearnerId={!!learnerId} learnerName={learnerName} />
      </>
    )
  }
}

export default ULearnTable
