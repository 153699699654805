import React, { useCallback, useMemo } from 'react'
import { Form, Input, Select, Tag } from 'antd'
import I18n from 'i18n-js'

import EditCourseListItem from './EditCourseListItem'

const { Option } = Select
const trOpt = { scope: 'editCourse.editCourseChecklistItem' }

const ChecklistItem = ({ item, slideOptions }) => {
  const { title, slideId } = item
  const slideName = useMemo(() => {
    if (slideId) {
      const selectedSlideOption = slideOptions.find(({ value }) => value === slideId)
      if (selectedSlideOption) {
        return selectedSlideOption.label
      } else {
        return slideId
      }
    }
  }, [slideId, slideOptions])

  return (
    <div>
      <h3>{title || I18n.t('noTitleSet', trOpt)}</h3>
      {I18n.t('slideId', trOpt)} <Tag>{slideName}</Tag>
    </div>
  )
}
const ChecklistForm = ({ item, id, slideOptions, onChange: onChangeProp }) => {
  const { title, slideId } = item
  const onChange = useCallback(event => {
    const { name, value } = event.target
    onChangeProp(name, value)
  }, [onChangeProp])
  const onSlideIdChange = useCallback(value => {
    onChangeProp('slideId', value)
  }, [onChangeProp])

  const options = useMemo(() =>
    slideOptions.map(({ value, label }, index) => (
      <Option key={index} value={value} disabled={value === id}>{label}</Option>
    )), [id, slideOptions])

  return (
    <>
      <>
        <Form.Item label={I18n.t('itemText', trOpt)}>
          <Input
            name='title'
            onChange={onChange}
            value={title}
          />
        </Form.Item>
        <Form.Item label={I18n.t('slideId', trOpt)}>
          <Select
            style={{ width: '100%' }}
            name='slideId'
            value={slideId}
            onChange={onSlideIdChange}
            allowClear
          >
            {options}
          </Select>
        </Form.Item>
      </>
    </>
  )
}

const EditCourseChecklistItem = props => {
  return (
    <EditCourseListItem
      {...props}
      itemComponent={ChecklistItem}
      formComponent={ChecklistForm}
    />
  )
}

export default EditCourseChecklistItem
