import React, { useCallback, useState, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Button, Form, Input, Tag } from 'antd'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isInteger from 'lodash/isInteger'

import MutationFormErrors from './MutationFormErrors'

const trOpt = { scope: 'mutationForm.mutationFormTextTags' }
const AddTagContainer = styled.div`
  display: flex;

  .ant-btn {
    margin-left: 5px;
    margin-top: 4px;
  }
`

const AddTagInputContainer = styled.div`
  flex: 1;
  max-width: 500px;

  .ant-form-explain {
    margin-top: 5px;
  }
`

const _TextTag = withTheme(({ index, children, closable = true, onClose, theme, className }) => {
  const onCloseClick = useCallback(e => onClose(index, e), [onClose, index])

  return (
    <Tag
      visible
      className={className}
      color={theme.primary}
      closable={closable}
      onClose={onCloseClick}
    >
      {children}
    </Tag>
  )
})

const TextTag = styled(_TextTag)`
  font-size: 14px;
  margin-top: 8px;
  padding: 6px 7px 3px;
`

const TagContainer = styled.div`
  max-height: 300px;
  max-width: 800px;
  overflow: auto;
`

const RemoveAllContainer = styled.div`
  margin-top: 20px;
  max-width: 800px;
  text-align: center;
`

// Intended for use with MutationForm
const MutationFormTextTags = React.forwardRef(({
  id, value = [], onChange = () => {}, errors = [], visible = false,
  label, extra, formItemStyle, required, placeholder, autofill, addonAfter, addonBefore, disabled,
  removeLabel, validateTag, emptyError, duplicateError, notAddedError, maxValues, maxValuesError
}, ref) => {
  const [inputValue, setInputValue] = useState(null)
  const [error, setError] = useState(null)
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false)

  const values = _isArray(value) ? value : []

  const handleChange = useCallback(event => {
    const inputValue = event.target.value
    setInputValue(inputValue)
    // The input value is empty, re-add the values to the form
    // this will trigger validation and clear the a 'notAddedError'
    if (!(inputValue || '').trim()) onChange(id, [...values])
    setError(null)
  }, [id, onChange, values])

  const handleFocus = useCallback(() => setHasReceivedFocus(true), [])

  useImperativeHandle(ref, () => ({
    validate: (formValues, errors) => {
      // Fail validation if we have an 'unadded' value
      if (inputValue && inputValue.trim().length > 0) {
        errors.push(notAddedError)
      }
      // Fail validation if we have too many values
      if (_isInteger(maxValues) && values.length > maxValues) {
        errors.push(maxValuesError)
      }
      return errors
    }
  }), [inputValue, notAddedError, maxValues, maxValuesError, values])

  const addTag = useCallback(() => {
    const value = (inputValue || '').trim()
    let error
    if (value.length === 0) {
      error = emptyError
    } else if (values.includes(inputValue)) {
      error = duplicateError
    } else if (_isInteger(maxValues) && values.length >= maxValues) {
      error = maxValuesError
    } else if (_isFunction(validateTag)) {
      error = validateTag(inputValue)
    }
    if (error) {
      setError(error)
    } else {
      onChange(id, [...values, inputValue])
      setInputValue(null)
      setError(null)
    }
  }, [onChange, id, values, inputValue, validateTag, emptyError, duplicateError, maxValues, maxValuesError])
  const handleKeyDown = useCallback(event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      addTag()
    }
  }, [addTag])

  const removeTag = useCallback(index => {
    const newValues = [...values]
    newValues.splice(index, 1)
    onChange(id, [...newValues])
  }, [onChange, id, values])
  const removeAllTags = useCallback(() => onChange(id, []), [onChange, id])

  if (!visible) {
    return null
  }

  const showErrors = errors.length > 0
  // Prevent browser autofil of password
  const readOnly = !autofill ? hasReceivedFocus : undefined

  return (
    <Form.Item
      label={label} style={formItemStyle} required={required}
      validateStatus={showErrors ? 'error' : undefined}
      help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
    >
      <AddTagContainer>
        <AddTagInputContainer className={error ? 'has-error' : undefined}>
          <Input
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            name={id}
            type='text'
            value={inputValue}
            allowClear
            {...{ placeholder, readOnly, addonAfter, addonBefore, disabled }}
          />
          {error && <div className='ant-form-explain'>{error}</div>}
        </AddTagInputContainer>
        <Button icon='plus' type='primary' onClick={addTag} disabled={disabled}>{I18n.t('common.add')}</Button>
      </AddTagContainer>
      {extra && <div className='ant-form-extra'>{extra}</div>}
      <TagContainer>
        {values.map((value, index) => (
          <TextTag key={index} index={index} closable={!disabled} onClose={removeTag}>{value}</TextTag>
        ))}
      </TagContainer>
      {!_isEmpty(values) && (
        <RemoveAllContainer>
          <Button type='danger' onClick={removeAllTags} disabled={disabled}>{removeLabel}</Button>
        </RemoveAllContainer>
      )}
    </Form.Item>
  )
})

MutationFormTextTags.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  autofill: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  extra: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.func]),
  addonAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  addonBefore: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  formItemStyle: PropTypes.object,
  disabled: PropTypes.bool,
  disableSubmitOnEnter: PropTypes.bool,
  maxValues: PropTypes.number,
  removeLabel: PropTypes.string,
  validateTag: PropTypes.func,
  emptyError: PropTypes.string,
  duplicateError: PropTypes.string,
  notAddedError: PropTypes.string,
  maxValuesError: PropTypes.string
}

MutationFormTextTags.defaultProps = {
  id: '',
  label: null,
  required: false,
  placeholder: '',
  autofill: true,
  value: [],
  extra: null,
  onChange: () => {},
  disabled: false,
  disableSubmitOnEnter: false,
  maxValues: null,
  get removeLabel () { return I18n.t('removeLabel', trOpt) },
  get emptyError () { return I18n.t('emptyError', trOpt) },
  get duplicateError () { return I18n.t('duplicateError', trOpt) },
  get notAddedError () { return I18n.t('notAddedError', trOpt) },
  get maxValuesError () { return I18n.t('maxValuesError', trOpt) }
}

export default MutationFormTextTags
