import React from 'react'
import I18n from 'i18n-js'

import { DELETE_POLICIES, DELETE_POLICY_TEMPLATES } from '../Queries/uPolicy'
import DeleteConfirm from './DeleteConfirm'

const DeletePolicyConfirm = ({ visible = false, setVisible = () => {}, type, policyIds = [], refetchQueries }) => {
  const isTemplate = type === 'template'

  const trOpt = { scope: `modals.${isTemplate ? 'deletePolicyTemplateConfirm' : 'deletePolicyConfirm'}` }

  return (
    <DeleteConfirm
      {...{ visible, setVisible, refetchQueries }}
      mutation={isTemplate ? DELETE_POLICY_TEMPLATES : DELETE_POLICIES}
      variableName='policyIds'
      ids={policyIds}
      trOpt={trOpt}
      deletedItems={isTemplate ? null : [
        <li key={0}>{I18n.t('deletedItem1', { ...trOpt, count: policyIds.length })}</li>,
        <li key={1}>{I18n.t('deletedItem2', { ...trOpt, count: policyIds.length })}</li>
      ]}
    />
  )
}

export default DeletePolicyConfirm
