import React, { useState, useEffect, useCallback } from 'react'
import I18n from 'i18n-js'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from 'antd'
import styled from 'styled-components'

import { ShortLoader } from '../../components/common/Loading'
import { Row, Column } from '../../components/EndUserPortal/GridFlexiLayout'
import { SignInHeader } from '../../components/SignIn'
import { ErrorAlerts } from '../../components/common'
import { UsecureError } from '../../helpers'
import { useGlobalState } from '../../hooks'
import { creators as viewActions } from '../../state/actions/view'
import { navigateToApp } from '../../helpers/signIn'

const trOpt = { scope: 'signIn' }

const LoadingMessage = styled.h1`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`

// This is a generic SAML failure response page when usecure-v2/server cannot provide any context for the failure when redirecting back to the app.
// It's rare that users will see this page as most failures will route to AdminUserSAMLResponse or EndUserSAMLResponse.
const SAMLAuthFailure = () => {
  const [status, setStatus] = useState('ready')
  const [error, setError] = useState(null)
  const { search } = useLocation()
  const history = useHistory()
  const { setLoadingVisible } = useGlobalState(
    undefined,
    useCallback(dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    }), [])
  )

  // "On Mount" hook
  useEffect(() => {
    if (status !== 'ready') return

    setLoadingVisible(false)

    const queryParams = new URLSearchParams(search)
    const errorCode = queryParams.get('errorCode')

    if (errorCode) {
      // Show failure state with error code for known error
      setError(new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode }))
      setStatus('fail')
    } else {
      // Show failure state without error code
      setStatus('fail')
    }
  }, [status, search, setLoadingVisible])

  const onLoginRetryClick = useCallback(() => {
    navigateToApp({ history, targetPath: new URLSearchParams(search).get('targetPath') })
  }, [history, search])

  return (
    <Row className='full-height justify align' justify='space-around' alignItems='center' alignContent='center'>
      <Column className='align' align-items='center' align-content='center' flex='0 0 30em' style={{ padding: '2em' }}>
        <SignInHeader bottomMargin='2em'>
          {I18n.t('common.login')}
        </SignInHeader>
        {status !== 'fail' && (
          <>
            <LoadingMessage>{I18n.t('samlSSOLoadingMessage', trOpt)}</LoadingMessage>
            <ShortLoader />
          </>
        )}
        {status === 'fail' && (
          <>
            <Button onClick={onLoginRetryClick} icon='redo'>{I18n.t('forgottenPasswordLink.backToLogin', trOpt)}</Button>
            <ErrorAlerts error={error || new Error(I18n.t('samlSSOError', trOpt))} defaultError={I18n.t('samlSSOError', trOpt)} includeErrorCode />
          </>
        )}
      </Column>
    </Row>
  )
}

export default SAMLAuthFailure
