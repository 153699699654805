import React from 'react'
import { generatePath } from 'react-router-dom'
import I18n from 'i18n-js'
import { DescriptionContainer, ActionContainer, CopyToClipboard } from '../common'
import routes from '../../../constants/routes'
const trOpt = { scope: 'settings.endUserPortal.settingsAccessPortal' }

const PortalLogin = React.forwardRef(({ appOrigin, visible, companyId }, ref) => {
  const path = generatePath(routes.PORTAL_HOME, { companyId })
  const link = `${appOrigin}${path}`
  return visible ? (
    <div>
      <DescriptionContainer>
        <p>{I18n.t('description', trOpt)}</p>
        <a href={link} target='_blank' rel='noopener noreferrer'>{link}</a>
      </DescriptionContainer>
      <ActionContainer>
        <CopyToClipboard value={link} />
      </ActionContainer>
    </div>
  ) : null
})

export default PortalLogin
