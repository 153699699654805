import React from 'react'
import I18n from 'i18n-js'

import DeleteConfirm from './DeleteConfirm'
import { DELETE_COURSES } from '../Queries/Courses'

import { getCoursesRefetchQueries } from '../../helpers/courses'

import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'modals.deleteCourseConfirm' }

const DeleteCourseConfirm = ({ visible = false, setVisible = () => {}, courseIds = [] }) => {
  const count = courseIds.length
  const { hasAllSessionPermissions } = useHasSessionPermission()
  return (
    <DeleteConfirm
      {...{ visible, setVisible, trOpt }}
      mutation={DELETE_COURSES}
      refetchQueries={getCoursesRefetchQueries(hasAllSessionPermissions([permissions.COURSE_SUPER_UPDATE]))}
      variableName='courseIds'
      ids={courseIds}
      deletedItems={[
        <li key={0}>{I18n.t('deletedItem1', { ...trOpt, count })}</li>,
        <li key={1}>{I18n.t('deletedItem2', { ...trOpt, count })}</li>
      ]}
    />
  )
}

export default DeleteCourseConfirm
