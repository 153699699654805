import React from 'react'
import styled from 'styled-components'

import { renderContent } from './common'

const LearnerCourseSlateContainer = styled.div`
  img {
    max-width: 60%;
    height: auto !important;
    margin: 2.8em auto;
    display: table;
  }
  
  p {
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    color: rgb(124, 112, 107);
  }
  
  ul li {
    list-style: none;
    margin-bottom: 1.8em;
    line-height: 30px;
    margin-left: -25px;
    
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 20px !important;
    font-weight: 400;

    white-space: normal;

    &> span {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }
  
  blockquote {
    border: none;
    box-sizing: border-box;
    color: #4c4e4d;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 60px 30px;
    position: relative;
    quotes: "\\201C" "\\201D" "\\2018" "\\2019";
    text-align: center;
    width: 95%;
    font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
    font-size: 2.6em;
    line-height: 1.4;
    font-weight: 400;
    
    :before, :after {
      color: #4c4e4d;
      font-family: 'Impact', sans-serif !important;
      font-size: 7rem;
      font-style: normal;
      height: 60px;
      line-height: 1;
      position: absolute;
      width: 60px;
    }
    
    :before {
      content: open-quote;
      top: 0;
      left: 5px;
    }
    
    :after {
      content: close-quote;
      bottom: 0;
      right: 5px;
    }
  }
  
  ul li:before {
    content: "•";
    color: ${({ theme }) => theme.primary};
    display: inline-block;
    font-size: 46px;
    margin-right: 15px !important;
    vertical-align: top;
  }
  
  ol {
    counter-reset: ol-counter;
    margin-left: 25px;
    
    li {
      counter-increment: ol-counter;
      list-style-type: none;
      margin-bottom: 1.75rem !important;
      padding-left: 15px;
      position: relative;
      
      span {
        font-family: Georgia,Cambria,"Times New Roman",Times,serif !important;
        font-size: 20px;
        line-height: 1.4;
        font-weight: 400;
        color: rgb(124,112,107);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        background-color: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.secondary};
        content: counter(ol-counter);
        height: 2rem;
        left: -40px;
        padding: 0.15rem;
        position: absolute;
        text-align: center;
        top: -0.1rem;
        width: 2rem;
      }
    }
  }
`

const LearnerCourseSlate = ({ content }) => {
  if (!content) {
    return null
  }

  return (
    <LearnerCourseSlateContainer dangerouslySetInnerHTML={{ __html: renderContent(content) }} />
  )
}

export default LearnerCourseSlate
