import React from 'react'
import { Switch } from 'react-router-dom'
import routes from '../../constants/routes'
import { compose } from 'recompose'
import { connect } from '../../hocs'
import NavBar from '../../components/EndUserPortal/NavBar'
import { getSessionAndSettings } from '../../state/selectors'
import EndUserProtectedRoute from '../../components/EndUserProtectedRoute'
import { Home, LearnerDashboard, PolicyDashboard, Settings, BreachDashboard } from './index'
import { StyledLayout, StyledHeader, StyledContent } from '../Main'
const Main = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <NavBar />
      </StyledHeader>
      <StyledContent>
        <Switch>
          <EndUserProtectedRoute
            exact
            path={routes.PORTAL_HOME}
            component={Home}
          />
          <EndUserProtectedRoute
            exact
            path={routes.PORTAL_LEARN}
            component={LearnerDashboard}
          />
          <EndUserProtectedRoute
            exact
            path={routes.PORTAL_POLICY}
            component={PolicyDashboard}
          />
          <EndUserProtectedRoute
            exact
            path={routes.PORTAL_BREACH}
            component={BreachDashboard}
          />
          <EndUserProtectedRoute
            exact
            path={routes.PORTAL_SETTINGS}
            component={Settings}
          />
        </Switch>
      </StyledContent>
    </StyledLayout>
  )
}

export default compose(
  connect(
    getSessionAndSettings
  )
)(Main)
