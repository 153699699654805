import React from 'react'
import I18n from 'i18n-js'
import { useQuery } from '@apollo/react-hooks'
import _pick from 'lodash/pick'

import routes from '../../constants/routes'
import { BannerMessage } from '../common'
import { GET_USERS } from '../Queries/Users'
import { connect } from '../../hocs'
import { session, settings } from '../../state/selectors'
import { renderToString } from '../../helpers'

const MSPTeamMessage = ({ accountType, enableProspects = false }) => {
  const isMSP = accountType === 'msp' && enableProspects
  const { loading, data } = useQuery(GET_USERS, { skip: !isMSP })
  if (loading || !isMSP) {
    return null
  }

  const { users = [] } = data || {}
  const hasProspectCommsType = users.some(user => user.commsType === 'All' || user.commsType === 'Sales')
  if (hasProspectCommsType) {
    return null
  }

  return (
    <BannerMessage
      type='info'
      message={
        <span
          dangerouslySetInnerHTML={{
            __html: I18n.t('uService.mspTeamMessage', {
              link: renderToString(<a className='usecure-manual-rr-link' href={routes.TEAM}>{I18n.t('nav.navBar.team')}</a>)
            })
          }}
        />
      }
    />
  )
}

export default connect(
  state => ({
    ..._pick(session.get(state), ['accountType']),
    ..._pick(settings.get(state), ['enableProspects'])
  })
)(MSPTeamMessage)
