import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Radio } from 'antd'

import MutationFormErrors from './MutationFormErrors'

class MutationFormRadio extends Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange (event) {
    this.props.onChange(this.props.id, event.target.value)
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, buttons, options, value, buttonStyle, errors = [], required, disabled, extra } = this.props
    const radioProps = { onChange: this.onChange, value, buttonStyle, disabled }
    const showErrors = errors.length > 0
    let children = null
    if (buttons) {
      children = options.map(({ value, label, disabled }) => (<Radio.Button key={value} {...{ value, disabled }}>{label}</Radio.Button>))
    } else {
      radioProps.options = options
    }

    return (
      <Form.Item
        label={label} required={required} extra={extra}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <Radio.Group {...radioProps}>{children}</Radio.Group>
      </Form.Item>
    )
  }
}

MutationFormRadio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    label: PropTypes.string,
    disabled: PropTypes.bool
  })),
  onChange: PropTypes.func,
  buttons: PropTypes.bool,
  buttonStyle: PropTypes.string
}

MutationFormRadio.defaultProps = {
  id: '',
  label: null,
  onChange: () => {},
  value: false,
  options: [],
  buttons: false,
  buttonStyle: 'solid'
}

export default MutationFormRadio
