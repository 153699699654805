import React from 'react'
import I18n from 'i18n-js'
import styled, { css } from 'styled-components'

import ItemTag from './ItemTag'

const trOpt = { scope: 'common.previewTag' }

const _PreviewTag = ({ className, tooltipTitle, useTooltip = true }) => (
  <ItemTag
    {...{ className }}
    label={I18n.t('common.preview')}
    tooltipTitle={tooltipTitle || (useTooltip ? I18n.t('tooltip', trOpt) : null)}
  />
)
const PreviewTag = styled(_PreviewTag)`
  margin-left: 10px;
  ${({ header }) => header
    ? css`
  position: relative;
  top: -2px;
    `
    : ''
  }
`

export default PreviewTag
