import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Checkbox, DatePicker, Form, InputNumber, Select } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import I18n from 'i18n-js'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'
import _isInteger from 'lodash/isInteger'
import _isString from 'lodash/isString'
import _toInteger from 'lodash/toInteger'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import MutationFormErrors from '../MutationForm/MutationFormErrors'
import { SIGNATURE_TYPE_OPTIONS } from '../../constants/uPolicy'
import { generateSignatureSettingsObject } from '../../helpers/Policies'
import { formatDate, getMomentFormatString } from '../../helpers/datetime'
import { permissions } from '../../constants/permissions'
import { useHasSessionPermission } from '../../hooks'

const trOpt = { scope: 'uPolicy.policySignatureSettingsField' }
const { Option } = Select

const Wrapper = styled.div`
  margin-top: ${({ updatingCompanySettings }) => updatingCompanySettings ? '15px' : 0};
`

const InfoCard = styled(Card)`
  flex: 1;
`

const SettingsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  max-width: 1200px;
  max-width: ${({ updatingCompanySettings }) => updatingCompanySettings ? '800px' : '1200px'};
`

const FormContainer = styled.div`
  max-width: 700px;
  padding-right: 20px;
  width: 50%;
`

const TypeFormItem = styled(Form.Item)`
  .ant-select {
    width: 175px
  }
`

const IntervalContainer = styled.div`
  display: flex;
  margin-bottom: 4px;

  .ant-input-number {
    width: 60px;
  }

  .ant-select {
    margin-left: 5px;
    width: 90px;
  }
`

const CheckboxFormItem = styled(Form.Item)`
  display: inline-block;
`

const infoTrOpt = { scope: `${trOpt.scope}.signatureInfo` }
export const PolicySignatureInfo = ({ type, startDate, intervalLength, intervalUnit, updatingCompanySettings = false }) => {
  const listTrOpt = { scope: `${infoTrOpt.scope}.${updatingCompanySettings ? 'company' : 'policy'}` }
  if (intervalUnit && (type === 'fixed' || type === 'lastSignature')) {
    listTrOpt.interval = I18n.t(`common.duration.value.${intervalUnit}`, { count: intervalLength })
  }
  if (type === 'fixed' && startDate) {
    startDate = _isString(startDate) ? moment(startDate) : startDate
    listTrOpt.startDate = formatDate(startDate)
    if (intervalUnit && intervalLength) {
      const today = moment()
      if (startDate.isBefore(today, 'day')) {
        const diff = Math.floor(today.diff(startDate, intervalUnit) / intervalLength)
        const prevBatch = moment(startDate).add(diff * intervalLength, intervalUnit)
        listTrOpt.nextBatch = (today.isSame(prevBatch, 'day') ? prevBatch : formatDate(moment(prevBatch).add(intervalLength, intervalUnit)))
      } else {
        listTrOpt.nextBatch = formatDate(startDate)
      }
    }
  }
  const commonListTrOpt = { ...listTrOpt, scope: `${infoTrOpt.scope}.common` }

  const list = []
  if (type === 'none') {
    list.push('notSentAutomatically', 'sendAnyPoliciesManually')
  } else if (type === 'fixed' && startDate && intervalUnit && intervalLength) {
    list.push('sendAutoPoliciesFixedTermNewUsers', 'sendAutoPoliciesFixedTermExistingUsers', 'sendAutoPoliciesFixedTerm', 'resignOnDate')
  } else if (type === 'lastSignature' || type === 'newUsers') {
    list.push('sendAutoPoliciesNewUsers', 'sendAutoPoliciesExistingUsers')
    if (type === 'lastSignature' && intervalUnit && intervalLength) {
      list.push('lastSignature')
    }
    if (type === 'newUsers') {
      list.push('sendAutoPoliciesOnce', 'resignManually')
    }
  } else {
    list.push('notSentAutomatically', 'sendAnyPoliciesManually')
  }

  return (
    <InfoCard title={I18n.t('title', listTrOpt)}>
      <ul>{list.map(key => <li key={key}>{I18n.t(key, ['resignOnDate', 'resignManually'].includes(key) ? commonListTrOpt : listTrOpt)}</li>)}</ul>
    </InfoCard>
  )
}

// Intended for use with MutationForm
export const PolicySignatureSettingsValidator = (field, value, errors, opt) => {
  const { type, intervalLength, intervalUnit, startDate, useDefault, setDefault } = value || {}
  if (useDefault && !setDefault) {
    // Using company default settings so no validation required
    return
  }
  if (type === 'fixed' || type === 'lastSignature') {
    if (!(_isInteger(intervalLength) && intervalLength > 0)) {
      errors.push({ field: 'interval', message: I18n.t('invalidPeriodError', trOpt) })
    }
    if (!['months', 'years'].includes(intervalUnit)) {
      errors.push({ field: 'interval', message: I18n.t('emptyDurationTypeError', trOpt) })
    }
  }
  if (type === 'fixed' && !startDate) {
    errors.push({ field: 'startDate', message: I18n.t('emptyStartDateError', trOpt) })
  }
  if (!type) {
    errors.push({ field: 'type', message: I18n.t('emptySignatureTypeError', trOpt) })
  }
}

export const PolicySignatureSettingsMutateValue = value => {
  value = value || {}
  return {
    ...value,
    setDefault: _isBoolean(value.setDefault) ? value.setDefault : false,
    useDefault: _isBoolean(value.useDefault) ? value.useDefault : false
  }
}

const getFieldErrors = (field, errors = []) => {
  return errors.filter(e => e.field === field).map(e => e.message)
}

// Intended for use with MutationForm
export const PolicySignatureSettingsField = React.forwardRef(({
  id, value, defaultValue, policySignatureSettings: companyPolicySignatureSettings, onChange = () => {}, errors = [], updatingCompanySettings = false, disabled = false
}, ref) => {
  const [type, setType] = useState('none')
  const [intervalLength, setIntervalLength] = useState(1)
  const [intervalUnit, setIntervalUnit] = useState('years')
  const [startDate, setStartDate] = useState(moment().month(0).date(1))
  const [useDefault, setUseDefault] = useState(false)
  const [setDefault, setSetDefault] = useState(false)
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const onTypeChange = useCallback(value => setType(value), [])
  const onLengthChange = useCallback(value => {
    setIntervalLength(_toInteger(value))
  }, [setIntervalLength])
  const onUnitChange = useCallback(value => setIntervalUnit(value), [])
  const onUseDefaultChange = useCallback(event => setUseDefault(event.target.checked), [])
  const onSetDefaultChange = useCallback(event => setSetDefault(event.target.checked), [])
  const onStartDateChange = useCallback(value => setStartDate(value), [])

  const firstRun = !updatingCompanySettings && _isNil(companyPolicySignatureSettings) // No company level settings are in place
  const canUpdateSettings = hasAllSessionPermissions([permissions.SETTINGS_UPOLICY_UPDATE])

  useEffect(() => {
    if (!updatingCompanySettings) {
      const setDefaultValue = firstRun && canUpdateSettings ? setDefault : false
      if (setDefaultValue !== setDefault) {
        setSetDefault(setDefaultValue)
      }
      const useDefaultValue = firstRun && canUpdateSettings ? setDefaultValue : useDefault
      if (useDefaultValue !== useDefault) {
        setUseDefault(useDefaultValue)
      }
    }
  }, [useDefault, setDefault, firstRun, companyPolicySignatureSettings, updatingCompanySettings, canUpdateSettings])
  useEffect(() => {
    if (!firstRun && !updatingCompanySettings && useDefault) {
      // Show default settings
      const { type, intervalLength, intervalUnit, startDate } = companyPolicySignatureSettings
      setType(type)
      if (type === 'fixed' || type === 'lastSignature') {
        setIntervalLength(intervalLength)
        setIntervalUnit(intervalUnit)
      }
      if (type === 'fixed') {
        setStartDate(startDate ? moment(startDate, 'YYYY-MM-DD') : moment().month(0).date(1))
      }
    }
  }, [useDefault, firstRun, companyPolicySignatureSettings, updatingCompanySettings])

  const [initialised, setInitialised] = useState(false)
  useEffect(() => {
    let value = {}
    if (!updatingCompanySettings) {
      value.useDefault = useDefault
      if (firstRun && canUpdateSettings) {
        value.setDefault = setDefault
      }
    }
    if (firstRun || !useDefault) {
      value = {
        ...value,
        ...generateSignatureSettingsObject({
          type,
          intervalLength,
          intervalUnit,
          startDate
        })
      }
    }
    if (initialised) {
      onChange(id, value)
    }
  }, [onChange, initialised, id, type, intervalLength, intervalUnit, useDefault, setDefault, startDate, updatingCompanySettings, firstRun, canUpdateSettings])

  // Set state from value or defaultValue
  useEffect(() => {
    if (!initialised) {
      setInitialised(true)
      const { type = 'none', intervalLength = 1, intervalUnit = 'years', useDefault = false, setDefault = false, startDate } = value || defaultValue || {}
      setType(type)
      setIntervalLength(intervalLength)
      setIntervalUnit(intervalUnit)
      setUseDefault(useDefault)
      setSetDefault(setDefault)
      setStartDate(startDate ? moment(startDate, 'YYYY-MM-DD') : moment().month(0).date(1))
    }
  }, [initialised, value, defaultValue])
  const reset = useCallback(() => {
    setInitialised(false)
  }, [])
  useImperativeHandle(ref, () => ({
    reset
  }), [reset])

  const typeErrors = getFieldErrors('type', errors)
  const intervalErrors = getFieldErrors('interval', errors)
  const startDateErrors = getFieldErrors('startDate', errors)
  return (
    <Wrapper updatingCompanySettings={updatingCompanySettings}>
      <SettingsContainer updatingCompanySettings={updatingCompanySettings}>
        <FormContainer>
          <TypeFormItem
            label={I18n.t('uPolicy.common.signatureType')}
            required
            validateStatus={!_isEmpty(typeErrors) ? 'error' : undefined}
            help={<MutationFormErrors visible={!_isEmpty(typeErrors)} errors={typeErrors} />}
          >
            <Select
              onChange={onTypeChange}
              value={type}
              required
              disabled={(!firstRun && useDefault) || disabled}
            >
              {SIGNATURE_TYPE_OPTIONS.map(({ value, label }) => (
                <Option key={value} value={value}>{label}</Option>
              ))}
            </Select>
          </TypeFormItem>
          {
            type === 'fixed' &&
              <Form.Item
                label={I18n.t('uPolicy.common.signatureCycleStartDate')}
                required
                validateStatus={!_isEmpty(startDateErrors) ? 'error' : undefined}
                help={<MutationFormErrors visible={!_isEmpty(startDateErrors)} errors={startDateErrors} />}
              >
                <DatePicker
                  value={startDate}
                  onChange={onStartDateChange}
                  format={getMomentFormatString({ type: 'date' })}
                  allowClear
                  disabled={(!firstRun && useDefault) || disabled}
                />
              </Form.Item>
          }
          {
            (type === 'fixed' || type === 'lastSignature') &&
              <Form.Item
                label={I18n.t(type === 'lastSignature' ? 'resignAfter' : 'signatureFrequency', { scope: 'uPolicy.common' })}
                required
                validateStatus={!_isEmpty(intervalErrors) ? 'error' : undefined}
                help={<MutationFormErrors visible={!_isEmpty(intervalErrors)} errors={intervalErrors} />}
              >
                <IntervalContainer>
                  <InputNumber
                    step={1} precision={0} min={1} value={intervalLength} onChange={onLengthChange}
                    disabled={(!firstRun && useDefault) || disabled}
                  />
                  <Select
                    onChange={onUnitChange}
                    value={intervalUnit}
                    disabled={(!firstRun && useDefault) || disabled}
                  >
                    <Option value='months'>{I18n.t('common.duration.pluralized.months', { count: intervalLength || 0 })}</Option>
                    <Option value='years'>{I18n.t('common.duration.pluralized.years', { count: intervalLength || 0 })}</Option>
                  </Select>
                </IntervalContainer>
              </Form.Item>
          }
          {
            !updatingCompanySettings && !firstRun && (
              <CheckboxFormItem
                label={I18n.t('useDefaultSettings', trOpt)}
                labelAlign='left' labelCol={{ span: 23 }} wrapperCol={{ span: 1 }}
              >
                <Checkbox
                  onChange={onUseDefaultChange}
                  checked={useDefault}
                  disabled={disabled}
                />
              </CheckboxFormItem>
            )
          }
          {
            firstRun && canUpdateSettings && (
              <CheckboxFormItem
                label={I18n.t('setDefaultSettings', trOpt)}
                labelAlign='left' labelCol={{ span: 23 }} wrapperCol={{ span: 1 }}
              >
                <Checkbox
                  onChange={onSetDefaultChange}
                  checked={setDefault}
                  disabled={disabled}
                />
              </CheckboxFormItem>
            )
          }
        </FormContainer>
        <PolicySignatureInfo {...{ type, startDate, intervalLength, intervalUnit, updatingCompanySettings }} />
      </SettingsContainer>
    </Wrapper>
  )
})

export default connect(
  state => _pick(selectors.settings.get(state), ['policySignatureSettings'])
)(PolicySignatureSettingsField)
