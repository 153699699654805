import React from 'react'
import { Tag, Tooltip } from 'antd'
import styled, { withTheme } from 'styled-components'

const StyledTag = styled(Tag)`
  color: ${({ theme }) => theme.secondary};
  margin-left: 5px;
  padding-top: 4px;
`

const ItemTag = ({ className, label = '', tooltipTitle, theme }) => {
  const tag = <StyledTag {...{ className }} color={theme.primary}>{label}</StyledTag>
  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} placement='bottom'>
        {tag}
      </Tooltip>
    )
  }
  return tag
}

export default withTheme(ItemTag)
