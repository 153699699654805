import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { dataIdFromObject } from './caching'
import wsLink from './wsLink'
import timezoneLink from './timezoneLink'

const terminatingLink = wsLink

const link = ApolloLink.from([timezoneLink, terminatingLink])

// Shared but should probably be separate
const cache = new InMemoryCache({
  dataIdFromObject
})

const client = new ApolloClient({
  link,
  cache
})

export default client
