import React, { useMemo } from 'react'
import { Alert, Card, Progress } from 'antd'
import styled, { withTheme } from 'styled-components'
import I18n from 'i18n-js'

import InteractionsChart from '../../components/uPhish/InteractionsChart'
import TrendsChart from '../../components/uPhish/TrendsChart'

const trOpt = { scope: 'uPhish.viewSimulationResults' }

const ViewSimulationHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`

const EmailsSent = styled.div`
  display: inline-block;
  text-align: right;
  width: 100px;

  .ant-progress {
    text-align: left;

    &> div {
      align-items: center;
      display: flex;
    }
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }

  .ant-progress-text {
    width: auto;

    i {
      color: ${({ theme }) => theme.cta || 'inherit'};
    }
  }
`

const PendingAlert = styled(Alert)`
  margin-bottom: 15px;
  text-align: center;
`

const FullWidthCard = styled(Card)`
  .ant-card-body {
    height: 400px;
  }
`

const SmallCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 350px;
  width: ${100 / 3}%;

  .ant-card-body {
    flex: 1;
  }
`
const SmallCardContainer = styled.div`
  display: flex;
  margin-top: 15px;

  ${SmallCard} {
    margin: 0 7.5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`
const StyledRate = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`

const StyledRateValue = styled.span`
  color: ${({ color }) => color || 'inherit'};
  font-size: 6rem;
  letter-spacing: 0.28px;

  &> * {
    color: ${({ theme }) => theme.darkGrey};
    font-size: 45%;
  }
`

const Rate = ({ percentage, footer, color }) => {
  return (
    <StyledRate>
      <StyledRateValue {...{ color }}>{percentage}<span>%</span></StyledRateValue>
      {footer ? <div>{footer}</div> : null}
    </StyledRate>
  )
}

const ViewSimulationResults = ({ id, simulation, theme, showEmailsSent = true, showPhishAlert = false }) => {
  const { status, queue = [], results = [] } = simulation || {}

  const { emailSent, eventPercentages } = useMemo(() => {
    const learnersCount = queue.length + results.length
    const emailSent = (results.length / learnersCount) * 100
    const eventTotals = results.reduce((events, { open, compromise, visit, report }) => {
      if (open) {
        events.open += 1
      }
      if (visit) {
        events.visit += 1
      }
      if (compromise) {
        events.compromise += 1
      }
      if (report) {
        events.report += 1
      }

      return events
    }, {
      open: 0,
      visit: 0,
      compromise: 0,
      report: 0
    })
    const eventPercentages = Object.keys(eventTotals).reduce((percentages, event) => {
      percentages[event] = learnersCount === 0 ? 0 : Math.floor((eventTotals[event] / learnersCount) * 100)
      return percentages
    }, {
      open: 0,
      visit: 0,
      compromise: 0,
      report: 0
    })
    if (!showPhishAlert) {
      delete eventPercentages.report
    }

    return { emailSent, eventPercentages }
  }, [queue, results, showPhishAlert])

  return (
    <>
      {
        showEmailsSent && (
          <ViewSimulationHeader>
            <EmailsSent>
              <h6>{I18n.t('emailsSent', trOpt)}</h6>
              <Progress percent={Math.round(emailSent)} strokeColor={theme.cta || ''} />
            </EmailsSent>
          </ViewSimulationHeader>
        )
      }
      {status === 'pending' ? <PendingAlert type='warning' message={I18n.t('simulationResultsWillBeVisible', trOpt)} /> : null}
      <FullWidthCard title={I18n.t('simulationInteractions', trOpt)}>
        <InteractionsChart {...{ simulation, showPhishAlert }} />
      </FullWidthCard>
      <SmallCardContainer>
        <SmallCard title={I18n.t('openRate', trOpt)}>
          <Rate percentage={eventPercentages.open} footer={I18n.t('ofUsersOpenedAnEmail', trOpt)} color={theme.simulationOpen} />
        </SmallCard>
        <SmallCard title={I18n.t('compromiseRate', trOpt)}>
          <Rate percentage={eventPercentages.compromise} footer={I18n.t('ofUsersRevealedInformation', trOpt)} color={theme.simulationCompromise} />
        </SmallCard>
        <SmallCard
          title={I18n.t('trends', trOpt)}
          bodyStyle={{ height: '293px' }}
        >
          <TrendsChart {...{ eventPercentages, showPhishAlert }} />
        </SmallCard>
      </SmallCardContainer>
    </>
  )
}

export default withTheme(ViewSimulationResults)
