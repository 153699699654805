import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Descriptions, message } from 'antd'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { generatePath } from 'react-router-dom'
import _isInteger from 'lodash/isInteger'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'

import EmailPreview from '../../components/MutationForm/MutationFormEmailBodyPreview'
import _TemplateTile from '../../components/uPhish/TemplateTile'
import SendTestEmailButton from '../../components/uPhish/SendTestEmailButton'
import UnlayerIframe from '../../components/uPhish/UnlayerIframe'
import { generateSimulationLandingPageLink } from '../../helpers/uPhish'
import { GET_COURSE_BASIC } from '../../components/Queries/Courses'
import CourseTile from '../../components/Courses/CourseTile'
import routes from '../../constants/routes'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { formatDateTime } from '../../helpers/datetime'
import { DELIVERY_METHODS, UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP } from '../../constants/uPhish'
import { ENABLE_MESSAGE_INJECTION } from '../../constants/environment'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uPhish.viewSimulationDetails' }
const commonTrOpt = { scope: 'uPhish.common' }

const TemplateTile = styled(_TemplateTile)`
  width: 300px;

  .ant-card {
    margin: 0 0 25px;
  }
`

const SimDescriptions = styled(Descriptions)`
  .ant-descriptions-row {
    vertical-align: top;

    .view-sim-detail_img {
      padding-bottom: 20px;
      padding-right: 20px;

      img {
        width: 100%;
      }
    }

    .view-sim-detail_swatch {
      .ant-descriptions-item-content {
        display: block;
        padding-right: 20px;
      }
    }
  }
`

const DetailContainer = styled.div`
  margin-bottom: 25px;
`

const CourseContainer = styled.div`
  max-width: 300px;
  padding: 20px 0;
`

const ViewSimulationDetails = ({ simulation, companyId: userCompanyId }) => {
  const { id: simulationId, attackType, startDate, endDate, landingPage, emailTemplate, config, autoPhish } = simulation
  const { spread, workingHours, sender, subject, link, content: freeformContent, legacy: legacyContent, courseId, deliveryMethod, attachmentFile: configAttachmentFile, attachmentName: configAttachmentName } = config || {}
  const { hasAllSessionPermissions } = useHasSessionPermission()

  let customSender = false
  let senderName
  let senderEmail
  if (_isString(sender)) {
    senderEmail = sender
  } else if (_isObject(sender)) {
    const { custom, name, email, prefix, domain } = sender
    customSender = custom
    if (_isString(name) && name.length > 0) {
      senderName = name
    }

    if (custom === true) {
      senderEmail = email
    } else {
      senderEmail = `${prefix}@${domain}`
    }
  }

  const { name: emailTemplateName, custom: customEmail, content: emailTemplateContent, legacy: legacyTemplate, attachmentFile: defaultAttachmentFile, attachmentName: defaultAttachmentName } = emailTemplate || {}
  let emailContent
  let showPreview = false
  if (customEmail && emailTemplateContent) {
    emailContent = legacyTemplate ? emailTemplateContent : null
    showPreview = !legacyTemplate
  } else if (!emailTemplate && freeformContent) {
    emailContent = legacyContent ? freeformContent : null
    showPreview = !legacyContent
  }

  const attachmentFile = configAttachmentFile || defaultAttachmentFile
  const attachmentName = configAttachmentName || defaultAttachmentName

  const [landingLink, setLandingLink] = useState(null)
  useEffect(() => setLandingLink(generateSimulationLandingPageLink(simulation)), [simulation, setLandingLink])
  const onLandingLinkClick = useCallback(() => {
    if (landingLink) {
      window.open(landingLink, '_blank')
    }
  }, [landingLink])

  const { data: { course } = {} } = useQuery(GET_COURSE_BASIC, {
    variables: { courseId },
    skip: !courseId
  })
  if (course && course.companyId) {
    course.company = { id: course.companyId }
  }

  const performCourseAction = useCallback((action, courseId) => {
    switch (action) {
      case 'preview':
        window.open(generatePath(routes.BUILDER_PREVIEW_COURSE, { course_id: courseId }), '_blank')
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [])

  return (
    <div>
      <h3>{I18n.t('common.configuration')}</h3>
      <SimDescriptions layout='vertical'>
        <Descriptions.Item label={I18n.t('attackType', commonTrOpt)}>{I18n.t(`uPhish.common.attackTypes.${UPHISH_ATTACK_TYPE_TRANSLATION_ID_MAP[attackType]}`)}</Descriptions.Item>
        <Descriptions.Item label={I18n.t('uPhish.common.startDate')}>{formatDateTime(startDate)}</Descriptions.Item>
        {endDate && <Descriptions.Item label={I18n.t('uPhish.common.endDate')}>{formatDateTime(endDate)}</Descriptions.Item>}
        {_isInteger(spread) && <Descriptions.Item label={I18n.t('spread', trOpt)}>{I18n.t(`common.duration.value.${autoPhish ? 'weeks' : 'hours'}`, { count: spread })}</Descriptions.Item>}
        <Descriptions.Item label={I18n.t('restrictToWorkingHours', trOpt)}>{I18n.t(`common.${workingHours ? 'yes' : 'no'}`)}</Descriptions.Item>
        {ENABLE_MESSAGE_INJECTION && <Descriptions.Item label={I18n.t('preferredDeliveryMethod', commonTrOpt)}>{DELIVERY_METHODS[deliveryMethod || 'smtp']}</Descriptions.Item>}
        {(attackType === 'attachment' || attackType === 'attachmentPage') && [
          <Descriptions.Item key='attachmentName' label={I18n.t('attachmentName', commonTrOpt)}>{attachmentName}</Descriptions.Item>,
          <Descriptions.Item key='attachmentFile' label={I18n.t('attachmentFile', commonTrOpt)}>
            <Button href={`https://view.officeapps.live.com/op/embed.aspx?src=${attachmentFile}`} target='_blank'>{I18n.t('viewAttachment', trOpt)}</Button>
          </Descriptions.Item>
        ]}
      </SimDescriptions>
      <h3>{I18n.t('uPhish.common.landingPage')}</h3>
      <SimDescriptions layout='vertical'>
        <Descriptions.Item label={I18n.t('landingPageURL', trOpt)}>{link}</Descriptions.Item>
      </SimDescriptions>
      {landingPage && <TemplateTile template={landingPage} onClick={onLandingLinkClick} />}
      {
        landingPage && landingLink && (
          <DetailContainer>
            <Button onClick={onLandingLinkClick}>{I18n.t('viewLandingPage', commonTrOpt)}</Button>
          </DetailContainer>
        )
      }
      <h3>{I18n.t('common.fields.email')}</h3>
      <SimDescriptions layout='vertical'>
        {senderName && <Descriptions.Item label={I18n.t('senderName', commonTrOpt)}>{senderName}</Descriptions.Item>}
        <Descriptions.Item label={I18n.t('senderEmailAddress', commonTrOpt)}>{senderEmail}</Descriptions.Item>
        <Descriptions.Item label={I18n.t('uPhish.common.usingCustomSender')}>{I18n.t(`common.${customSender ? 'yes' : 'no'}`)}</Descriptions.Item>
        <Descriptions.Item label={I18n.t('common.fields.emailSubject')}>{subject}</Descriptions.Item>
      </SimDescriptions>
      {(emailTemplate || freeformContent) && <h3>{I18n.t('emailTemplate', commonTrOpt)}{customEmail && emailTemplateName ? `: ${emailTemplateName}` : ''}</h3>}
      {emailTemplate && !customEmail && <TemplateTile template={emailTemplate} />}
      {
        (emailContent || showPreview) && (
          <DetailContainer>
            <Card>
              {
                showPreview
                  ? <UnlayerIframe {...{ simulationId }} />
                  : <EmailPreview content={emailContent} data={{ link: landingLink }} openLinksInNewTab />
              }
            </Card>
          </DetailContainer>
        )
      }
      {(emailTemplate || freeformContent) && hasAllSessionPermissions([permissions.SIMULATION_EMAIL_TEST]) && (
        <SendTestEmailButton variables={{ simulationId, deliveryMethod: deliveryMethod || 'smtp' }} />
      )}
      {
        course && (
          <CourseContainer>
            <h3>{I18n.t('inlineTrainingCourse', commonTrOpt)}</h3>
            <CourseTile {...course} userCompanyId={userCompanyId} actions={['preview']} performAction={performCourseAction} />
          </CourseContainer>
        )
      }
    </div>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['companyId'])
  })
)(ViewSimulationDetails)
