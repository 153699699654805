import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { message, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _isNil from 'lodash/isNil'

import { GET_COMPANIES } from '../Queries/Companies'
import { DUPLICATE_POLICY, DUPLICATE_POLICY_TEMPLATE } from '../Queries/uPolicy'
import MutationForm from '../MutationForm/MutationForm'

import { ErrorAlerts, LoadingBlock } from '../common'
import { invalidatePoliciesQueryCache } from '../../helpers'
import ModalHeaderId from './ModalHeaderId'

import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const DuplicatePolicyModal = ({
  policy = {}, type, visible, setVisible = () => {}, afterClose = () => {}, refetchQueries
}) => {
  const isTemplate = type === 'template'
  const trOpt = { scope: isTemplate ? 'modals.duplicatePolicyTemplateModal' : 'modals.duplicatePolicyModal' }
  const { hasAnySessionPermission } = useHasSessionPermission()
  const canSetCompanyAccess = isTemplate && hasAnySessionPermission([permissions.POLICY_TEMPLATE_SUPER_ACCESS_CONTROL, permissions.POLICY_TEMPLATE_SUPER_CREATE])

  const { loading, error, data } = useQuery(GET_COMPANIES, { skip: !(visible && canSetCompanyAccess) })
  const { companies } = data || {}

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  useEffect(() => {
    resetForm()
  }, [policy, resetForm])

  const { id, name, companyId } = policy || {}

  const fields = useMemo(() => {
    // Having a duplicate trOpt reduces the number of times this function is executed
    const trOpt = { scope: isTemplate ? 'modals.duplicatePolicyTemplateModal' : 'modals.duplicatePolicyModal' }
    const fields = [{
      id: 'name',
      label: I18n.t('name', { ...trOpt, name }),
      required: true,
      defaultValue: I18n.t('common.duplicateDefaultName', { name })
    }]
    if (canSetCompanyAccess) {
      fields.push({
        label: I18n.t('common.accessControl.ownedBy'),
        id: 'companyId',
        type: 'select',
        required: true,
        options: [
          { value: 'usecure', label: I18n.t('common.managedByUsecure') },
          ...(
            (companies || [])
              .map(({ id: value, name: label }) => ({ value, label }))
              .sort((a, b) => a.label.localeCompare(b.label))
          )
        ],
        defaultValue: companyId || 'usecure',
        mutateValue: value => value === 'usecure' ? null : value
      })
    }

    return fields
  }, [name, companyId, companies, canSetCompanyAccess, isTemplate])

  const closeModal = useCallback(() => {
    setVisible(false)
    resetForm()
  }, [setVisible, resetForm])
  if (_isNil(policy)) {
    visible = false
  }
  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal, trOpt])

  return (
    <Modal
      title={<ModalHeaderId idText={`uPolicy-duplicate${isTemplate ? '-template-' : '-'}modal-header`} text={I18n.t('common.duplicateTitle', { name })} />}
      footer={null}
      onCancel={closeModal}
      {...{ visible, afterClose }}
    >
      <LoadingBlock loading={loading} fullScreen={false} />
      {error && <ErrorAlerts {...{ error }} />}
      {!error && (
        <MutationForm
          mutation={isTemplate ? DUPLICATE_POLICY_TEMPLATE : DUPLICATE_POLICY}
          variables={{ id }}
          submitLabel={I18n.t('common.saveDuplicate')}
          {...{ fields, onSuccess, refetchQueries }}
          failureMessage={I18n.t('errorMessage', trOpt)}
          ref={form}
          update={invalidatePoliciesQueryCache}
          skipResetFieldsOnSubmit
        />
      )}
    </Modal>
  )
}

export default DuplicatePolicyModal
